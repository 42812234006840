import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/75.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethL, TextNumberL } from 'component/ToothChart/style'
import useWindowSize from 'utils/useWindowSize';

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginBottom: 'auto',
    top: '1.4%',
    maxWidth: '65px',
}))

export default function Teeth41(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)

    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 58.09, height: 100, viewBox: "0 0 58.09 106.91" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);
    const [width] = useWindowSize()
    useEffect(() => {
        const operative = props.operative || ``
        const valColor = props.color || colors.themeSecondColor
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })


        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }

        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        return setStyleSvg({ width: 58.09, height: 100, viewBox: "0 0 58.09 106.91" })
    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <TextNumberL>75</TextNumberL>
            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q4} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <DivTeethL className={`md div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray position='L' />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge width={width > 1440 ? 70 : 100} position='L' />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12346">

                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ""}

                            <path
                                data-name="Path 5583"
                                d="M2.6374 5.26405L0.695069 18.8402C0.666874 19.0452 0.718077 19.2531 0.838202 19.4216C8.95899 30.8571 11.119 44.1103 11.5242 57.3938C11.9987 72.9501 7.15497 103.638 13.5088 104.97C18.4909 106.015 28.8447 -15.5977 49.3691 103.371C49.7512 105.583 53.2983 105.349 54.6992 103.596C56.7754 101.002 56.7014 97.8604 56.85 94.9275C57.5977 80.2687 55.3822 65.794 53.6746 51.3103C52.419 40.713 51.819 30.2172 54.4129 19.7307C55.4654 15.4844 55.6129 11.1641 54.0344 7.00092C52.4651 2.83778 49.9082 0.24391 44.8956 1.57322C40.9725 2.60704 37.1876 -1.28848 32.0552 0.917682C32.0366 0.927104 32.0091 0.927112 31.9905 0.936133C31.6575 1.07888 31.3338 1.24223 31.0212 1.42527C30.7905 1.55457 30.5601 1.70232 30.3196 1.85929C30.9288 1.78552 31.5011 1.739 32.0734 1.70232H32.0825C35.9044 1.47158 38.8028 2.11769 40.6307 3.98245C41.8491 5.21935 42.5969 6.99189 42.8278 9.40112C43.3815 15.1145 40.6677 18.5952 34.9628 19.1952C31.7042 19.536 28.5564 19.9059 25.713 21.641C19.9244 25.1866 8.5334 20.3772 7.20429 13.694C6.63375 10.8305 6.6123 7.86252 6.37334 4.88737L6.59386 4.25409C7.53907 3.24873 8.47827 2.2462 9.96415 0.650064L10.0471 0.557844C10.1301 0.474649 10.2133 0.382434 10.2963 0.290018C6.84385 1.17809 4.58496 2.762 2.82544 4.84066C2.72423 4.96132 2.65906 5.10806 2.6374 5.26405Z"
                                transform={width > 1440 ? "translate(3 -13)" : "translate(0 -5)"}
                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />

                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5583"
                                    d="M2.6374 5.26405L0.695069 18.8402C0.666874 19.0452 0.718077 19.2531 0.838202 19.4216C8.95899 30.8571 11.119 44.1103 11.5242 57.3938C11.9987 72.9501 7.15497 103.638 13.5088 104.97C18.4909 106.015 28.8447 -15.5977 49.3691 103.371C49.7512 105.583 53.2983 105.349 54.6992 103.596C56.7754 101.002 56.7014 97.8604 56.85 94.9275C57.5977 80.2687 55.3822 65.794 53.6746 51.3103C52.419 40.713 51.819 30.2172 54.4129 19.7307C55.4654 15.4844 55.6129 11.1641 54.0344 7.00092C52.4651 2.83778 49.9082 0.24391 44.8956 1.57322C40.9725 2.60704 37.1876 -1.28848 32.0552 0.917682C32.0366 0.927104 32.0091 0.927112 31.9905 0.936133C31.6575 1.07888 31.3338 1.24223 31.0212 1.42527C30.7905 1.55457 30.5601 1.70232 30.3196 1.85929C30.9288 1.78552 31.5011 1.739 32.0734 1.70232H32.0825C35.9044 1.47158 38.8028 2.11769 40.6307 3.98245C41.8491 5.21935 42.5969 6.99189 42.8278 9.40112C43.3815 15.1145 40.6677 18.5952 34.9628 19.1952C31.7042 19.536 28.5564 19.9059 25.713 21.641C19.9244 25.1866 8.5334 20.3772 7.20429 13.694C6.63375 10.8305 6.6123 7.86252 6.37334 4.88737L6.59386 4.25409C7.53907 3.24873 8.47827 2.2462 9.96415 0.650064L10.0471 0.557844C10.1301 0.474649 10.2133 0.382434 10.2963 0.290018C6.84385 1.17809 4.58496 2.762 2.82544 4.84066C2.72423 4.96132 2.65906 5.10806 2.6374 5.26405Z"
                                    transform={width > 1440 ? "translate(3 -13)" : "translate(0 -5)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}
                            <g data-name="Group 12346">
                                <path
                                    data-name="Path 5587"
                                    d="M55.7352 24.8656C55.7352 24.8656 61.649 12.2881 55.7352 5.59549C49.8213 -1.09716 46.1283 1.30946 43.8205 0.567322C41.5127 -0.174814 33.7233 -0.103843 33.1179 1.59192C32.5125 3.28769 29.2815 3.47973 26.9737 2.69149C24.6659 1.90325 21.2042 -0.280857 17.7425 0.118677C14.2808 0.518212 6.81276 2.31842 5.74206 4.67232C4.67135 7.02623 -1.19096 11.818 1.6988 22.2079C1.6988 22.2079 9.43268 31.6458 8.74207 35.3659C8.74207 35.3659 20.0503 48.5203 26.9737 41.5969C33.8971 34.6735 37.1281 48.162 42.2051 43.6102C47.2822 39.0583 55.4361 40.7297 55.7352 24.8656Z"
                                    transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5588"
                                    d="M53.9482 25.3256C53.9482 25.3256 59.3641 13.8063 53.9482 7.67674C48.5324 1.54721 45.1499 3.75156 43.0361 3.07237C40.9224 2.39318 33.7893 2.45654 33.2344 4.01017C32.6795 5.5638 29.7212 5.73921 27.6072 5.01752C25.4933 4.29583 22.3233 2.29415 19.1533 2.6606C15.9833 3.02706 9.14287 4.67593 8.16278 6.83137C7.18268 8.98681 1.81353 13.3763 4.46032 22.8907C4.46032 22.8907 11.5423 31.5347 10.9102 34.9419C10.9102 34.9419 21.2664 46.9887 27.6074 40.6486C33.9485 34.3086 36.907 46.6609 41.5575 42.493C46.208 38.325 53.675 39.8544 53.9482 25.3256Z"
                                    transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5589"
                                    d="M50.5077 24.9139C50.5077 24.9139 54.9681 15.427 50.5077 10.3785C46.0473 5.33014 43.2612 7.14578 41.5211 6.58648C39.781 6.02717 33.9053 6.07969 33.4484 7.35908C32.9915 8.63848 30.5544 8.78241 28.8134 8.18802C27.0723 7.59363 24.4618 5.94578 21.8513 6.24749C19.2408 6.54919 13.607 7.90736 12.7991 9.68232C11.9912 11.4573 7.57068 15.0725 9.75018 22.9087C9.75018 22.9087 15.5838 30.0279 15.0626 32.8333C15.0626 32.8333 23.5914 42.7565 28.8134 37.5338C34.0354 32.3112 36.4725 42.4854 40.3024 39.0522C44.1324 35.619 50.2824 36.8793 50.5077 24.9139Z"
                                    transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />

                                {(isToothRoot && props.type === 'treatment') ?
                                    <>
                                        <path data-name="Path 6558" d="M34.5273 1.74794C34.5373 2.00951 35.9702 9.72628 36.1864 15.8499C36.5016 24.7978 42.0346 66.3176 41.7996 66.6079C42.9339 60.2223 36.4569 27.8601 36.1864 21.3801C35.8065 15.1061 32.6688 2.00111 32.6328 1.74783C32.8833 1.75668 34.2647 1.68932 34.5273 1.74794Z" transform={width > 1440 ? "translate(13 18)" : "translate(11 28)"} style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }} />
                                        <path data-name="Path 6559" d="M8.2874 2.85449C8.27744 3.11607 6.54034 21.3409 6.32466 27.464C6.00944 36.4119 2.12286 67.2777 2.35789 67.5697C2.3319 67.7986 7.53363 24.189 8.09495 14.9253C8.47432 8.65237 9.3315 3.15147 9.36744 2.89818C9.11693 2.90648 8.52133 2.85449 8.2874 2.85449Z" transform={width > 1440 ? "translate(15 18)" : "translate(12 28)"} style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }} />
                                    </>
                                    : ''}
                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <g data-name="Group 9472">
                                            <path data-name="Path 6581" d="m33.013 7.5-26.767 72" transform="translate(-4.317 -5.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                            <path data-name="Path 6582" d="m7.012 7.5 26.776 72" transform="translate(-4.317 -5.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                        </g>
                                    </>
                                    : ''}

                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <defs>
                                            <linearGradient id="paint0_linear_4107_155334" x1="31.9698" y1="18.0364" x2="29.0579" y2="34.657" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4107_155334" x1="31.9932" y1="30.5564" x2="31.9932" y2="24.392" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>
                                        <g data-name="Group 9369">
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M4.46333 7.08523L2.55042 20.4557C2.52266 20.6575 2.57308 20.8623 2.69139 21.0282C10.6891 32.2905 12.8164 45.3429 13.2155 58.4251C13.6828 73.7457 8.91244 103.969 15.17 105.281C20.0766 106.31 30.2735 -13.4605 50.4869 103.706C50.8632 105.884 54.3566 105.653 55.7362 103.928C57.781 101.372 57.7082 98.2785 57.8545 95.3901C58.5909 80.9533 56.4089 66.698 54.7271 52.4338C53.4906 41.997 52.8997 31.6603 55.4543 21.3327C56.4908 17.1507 56.6361 12.8958 55.0815 8.79577C53.5361 4.69572 51.0178 2.14115 46.0812 3.45032C42.2175 4.46847 38.49 0.631984 33.4354 2.80472C33.417 2.81399 33.39 2.814 33.3716 2.82289C33.0437 2.96347 32.7249 3.12435 32.417 3.30461C32.1898 3.43195 31.9629 3.57747 31.726 3.73205C32.326 3.6594 32.8897 3.61359 33.4533 3.57746H33.4622C37.2262 3.35022 40.0807 3.98654 41.8809 5.82305C43.0809 7.0412 43.8173 8.78688 44.0447 11.1596C44.59 16.7864 41.9174 20.2144 36.2989 20.8053C33.0897 21.1409 29.9896 21.5052 27.1892 23.214C21.4884 26.7059 10.27 21.9694 8.96101 15.3874C8.39912 12.5673 8.37799 9.64433 8.14265 6.71425L8.35983 6.09056C9.29072 5.10045 10.2157 4.1131 11.6791 2.54115L11.7608 2.45033C11.8425 2.3684 11.9245 2.27758 12.0062 2.18656C8.60603 3.06118 6.38137 4.62109 4.64852 6.66825C4.54884 6.78709 4.48466 6.9316 4.46333 7.08523Z" fill="#E3D1B4" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M15.1641 1.87986C19.0351 5.40638 22.7162 8.76014 26.4402 12.152C28.313 10.4703 27.8994 8.47466 27.9973 6.16984C23.7926 4.759 20.2136 1.4388 15.1641 1.87986Z" fill="#E3D1B4" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M31.9102 3.1685C37.1877 1.63466 41.6406 3.19377 45.5471 6.80499L31.9102 3.1685Z" fill="#E3D1B4" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M13.7266 2.25977C19.6704 2.90793 24.5306 5.07434 26.4546 11.3509L13.7266 2.25977Z" fill="#E3D1B4" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M54.7833 24.4195C54.7833 24.4195 60.6075 12.0327 54.7833 5.44146C48.9591 -1.14979 45.322 1.22037 43.0492 0.489478C40.7763 -0.241412 33.1049 -0.171517 32.5087 1.49855C31.9124 3.16862 28.7305 3.35776 26.4576 2.58146C24.1848 1.80516 20.7756 -0.345849 17.3663 0.0476318C13.9571 0.441112 6.60217 2.21404 5.54769 4.53228C4.49321 6.85051 -1.28027 11.5697 1.5657 21.8022C1.5657 21.8022 9.18239 31.097 8.50224 34.7608C8.50224 34.7608 19.6391 47.7158 26.4576 40.8973C33.2761 34.0789 36.4581 47.363 41.4583 42.8801C46.4584 38.3973 54.4887 40.0433 54.7833 24.4195Z" fill="#EFEDE7" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M53.0194 24.8721C53.0194 24.8721 58.3532 13.5273 53.0194 7.49069C47.6856 1.45403 44.3544 3.62498 42.2726 2.95608C40.1909 2.28719 33.1659 2.34958 32.6194 3.87967C32.073 5.40977 29.1595 5.58251 27.0775 4.87176C24.9956 4.16101 21.8736 2.18965 18.7517 2.55056C15.6297 2.91146 8.89295 4.53534 7.92771 6.65812C6.96246 8.7809 1.67466 13.1038 4.28135 22.4741C4.28135 22.4741 11.256 30.9872 10.6335 34.3427C10.6335 34.3427 20.8328 46.2069 27.0777 39.963C33.3227 33.719 36.2364 45.8841 40.8164 41.7794C45.3964 37.6746 52.7503 39.1808 53.0194 24.8721Z" fill="#F7F7F7" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M49.635 24.4665C49.635 24.4665 54.0278 15.1232 49.635 10.1513C45.2421 5.17943 42.4982 6.96756 40.7845 6.41672C39.0708 5.86589 33.2841 5.91762 32.8342 7.17762C32.3842 8.43763 29.984 8.57939 28.2694 7.994C26.5547 7.40862 23.9837 5.78574 21.4128 6.08287C18.8418 6.38001 13.2934 7.7176 12.4978 9.46565C11.7021 11.2137 7.34856 14.7742 9.49503 22.4916C9.49503 22.4916 15.2403 29.5029 14.727 32.2658C14.727 32.2658 23.1265 42.0386 28.2694 36.8952C33.4122 31.7517 35.8124 41.7717 39.5843 38.3905C43.3563 35.0093 49.4131 36.2506 49.635 24.4665Z" fill="white" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M38.5523 13H25.4477C23.5436 13 22 14.5436 22 16.4477V29.5523C22 31.4564 23.5436 33 25.4477 33H38.5523C40.4564 33 42 31.4564 42 29.5523V16.4477C42 14.5436 40.4564 13 38.5523 13Z" fill="#C1C4C5" />
                                            {/* <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M38.5537 13.8623H25.449C24.021 13.8623 22.8633 15.02 22.8633 16.4481V29.5527C22.8633 30.9808 24.021 32.1385 25.449 32.1385H38.5537C39.9818 32.1385 41.1394 30.9808 41.1394 29.5527V16.4481C41.1394 15.02 39.9818 13.8623 38.5537 13.8623Z" stroke="#C1C4C5" /> */}
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M30.4903 32.1048C30.1455 32.7289 33.3794 32.4203 35.8049 32.3893C39.4146 32.3134 41.4746 33.0512 41.4401 24.7182C41.5338 21.7613 41.4422 18.8014 41.166 15.8559C40.2507 14.9664 41.4073 24.882 39.0646 29.2433C36.8547 32.3927 31.0815 31.436 30.4903 32.1048Z" fill="#B0B1B1" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M35.0776 16.4746H28.1822C27.7062 16.4746 27.3203 16.8605 27.3203 17.3365V19.0604C27.3203 19.5364 27.7062 19.9223 28.1822 19.9223H35.0776C35.5536 19.9223 35.9395 19.5364 35.9395 19.0604V17.3365C35.9395 16.8605 35.5536 16.4746 35.0776 16.4746Z" fill="#9E9E9E" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M35.4174 16.4746H28.5221C28.0461 16.4746 27.6602 16.8605 27.6602 17.3365V19.0604C27.6602 19.5364 28.0461 19.9223 28.5221 19.9223H35.4174C35.8935 19.9223 36.2794 19.5364 36.2794 19.0604V17.3365C36.2794 16.8605 35.8935 16.4746 35.4174 16.4746Z" fill="url(#paint0_linear_4107_155334)" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M35.4409 24.5771H28.5455C28.0695 24.5771 27.6836 24.963 27.6836 25.4391V27.1629C27.6836 27.6389 28.0695 28.0248 28.5455 28.0248H35.4409C35.9169 28.0248 36.3028 27.6389 36.3028 27.1629V25.4391C36.3028 24.963 35.9169 24.5771 35.4409 24.5771Z" fill="#CBCCCC" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M35.4409 24.2334H28.5455C28.0695 24.2334 27.6836 24.6193 27.6836 25.0953V26.8192C27.6836 27.2952 28.0695 27.6811 28.5455 27.6811H35.4409C35.9169 27.6811 36.3028 27.2952 36.3028 26.8192V25.0953C36.3028 24.6193 35.9169 24.2334 35.4409 24.2334Z" fill="url(#paint1_linear_4107_155334)" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M1.3125 20.457C14.4355 21.1567 25.5304 23.562 55.8514 20.6129" stroke="#B5B7B7" strokeLinecap="round" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M1.3125 19.9258C13.5125 20.691 28.2209 23.0679 55.8514 19.9499" stroke="#BABBBB" strokeLinecap="round" />
                                        </g>
                                    </> || ""}
                            </g>
                        </g>
                    </svg>}
            </DivTeethL>
        </Div>
    )
}
