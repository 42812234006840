import { Box, Collapse } from "@mui/material"
import { colors } from "constants/theme";
import { useEffect, useState } from "react"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export type ContentDataTopicProps = {
    title: string | null;
    sub: string[];
}

export type ContentDataProps = {
    title: string;
    topic: ContentDataTopicProps[]
}

export type ContentUpdateProps = {
    contentData: ContentDataProps[]
}

export const ContentUpdate = (props: ContentUpdateProps) => {
    const { contentData } = props

    return (
        <Box sx={{ padding: '0 1rem', display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {contentData.map((item, index: number) => {
                return (
                    <Box key={index}>
                        <Box sx={{ fontSize: '24px', fontWeight: 'bolder' }}>
                            {item.title}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', paddingTop: "16px" }}>
                            {item.topic.map((data, count: number) => {
                                return (
                                    <RenderCollapse key={count} data={data} />
                                )
                            })}
                        </Box>
                    </Box>
                )
            })}
        </Box>
    )
}

type RenderCollapseProps = {
    key: number
    data: ContentDataTopicProps
}

const RenderCollapse = (props: RenderCollapseProps) => {
    const { data } = props
    const [open, setOpen] = useState<boolean>(false)
    return (
        <Box >
            {data?.title &&
                <Box
                    onClick={() => setOpen(!open)}
                    sx={{
                        backgroundColor: colors.themeSecondColor10,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 16px'
                    }}
                >
                    <Box sx={{ fontSize: '20px', fontWeight: '500' }}>
                        {data.title}
                    </Box>
                    <Box sx={{ transform: `rotate(${open ? 180 : 0}deg)`, cursor: 'pointer' }}>
                        <ExpandMoreIcon sx={{ backgroundColor: colors.white, border: `0.5px solid ${colors.textLightGray}`, borderRadius: '50px', color: colors.themeMainColor }} />
                    </Box>
                </Box>
                || <></>
            }
            <Collapse in={open}>
                <Box component={'ul'} sx={{ marginTop: '1rem' }}   >
                    {data?.sub.map((info: string, number: number) => {
                        return (
                            <Box component={'li'} key={number}>
                                {info}
                            </Box>
                        )
                    })}
                </Box>
            </Collapse>
        </Box>
    )
}