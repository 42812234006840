import { styled } from "@mui/material/styles"
import { Button } from '@mui/material'
import { colors } from "constants/theme"

export const TitleFirst = styled('div')(({ theme }) => ({
    fontSize: '18px',
    fontWeight: 600
}))

export const titleSecond = styled('div')(({ theme }) => ({
    fontSize: '16px'
}))

export const hr = styled('div')(({ theme }) => ({
    width: '100%'
}))

export const bgMsg = styled('p')(({ theme }) => ({
    padding: '0 8px 1px',
    borderRadius: '8px',
    border: `solid 1px ${colors.themeMainColor}`,
    backgroundColor: colors.themeMainColor,
    color: colors.white
}))

export const img = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    backgroundColor: 'transparent !important',
    '&:not(.active):hover': {
        svg: {
            g: {
                path: {
                    stroke: `${colors.themeSecondColor} !important`
                }
            }
        }
    },
}))

export const ButtonAddPrefix = styled(Button)(({ theme }) => ({
    fontSize: 16,
    color: colors.themeSecondColor,
    backgroundColor: 'transparent !important',
    fontWeight: 400,
    marginTop: '0.75rem',
    '&:hover': {
        backgroundColor: 'transparent !important',
        fontWeight: 600
    },
    '&.Mui-disabled': {
        color: colors.disabledGray
    }
}))
