import { Box, Chip } from '@mui/material'
import { styled } from '@mui/material/styles'

/** CONSTANTS */
import { colors } from 'constants/theme'

export const LineMessage = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    '.MuiGrid-container': {
      '.MuiGrid-root': {
        maxHeight: '50%',
        '&:first-of-type': {
          borderBottom: `1px solid ${colors.lightGray}`,
          paddingBottom: '1.5rem'
        },
        '&:last-of-type': {
          paddingTop: '1rem'
        }
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    '.MuiGrid-container': {
      marginTop: 0,
      '.MuiGrid-root': {
        '&:first-of-type': {
          maxHeight: '100%',
          paddingTop: 0,
          paddingBottom: 0,
          borderBottom: 'none'
        }
      }
    }
  }
}))

export const ChatRoom = styled(Box)(({ theme }) => ({
  border: `1px solid ${colors.black12}`,
  borderRadius: 4,
  backgroundColor: colors.white
}))

export const ChatDate = styled(Chip)(({ theme }) => ({
  position: 'relative',
  left: '50%',
  transform: 'translateX(-50%)',
  height: 24,
  backgroundColor: colors.lightThemeMain,
  backdropFilter: 'blur(40px)',
  span: {
    fontSize: 14,
    fontWeight: 500,
    color: colors.themeMainColor
  }
}))

export const ChatMessage = styled(Box)(({ theme }) => ({
  '.MuiAvatar-root': {
    height: 48,
    width: 48,
    [theme.breakpoints.down('lg')]: {
      height: 42,
      width: 42
    }
  },
  '.title': {
    fontSize: 16,
    fontWeight: 500,
    color: colors.textPrimary
  },
  '.subtitle': {
    fontSize: 14,
    color: colors.disabledGray
  },
  '.MuiChip-root': {
    height: 20,
    backgroundColor: colors.themeMainColor,
    borderRadius: 5,
    fontSize: 16,
    color: colors.white
  }
}))

export const ChatNewMessage = styled(Box)(({ theme }) => ({
  '.MuiOutlinedInput-root': {
    borderRadius: '4px 0 0 4px !important',
    '.MuiOutlinedInput-input': {
      '&.Mui-disabled': {
        '&::placeholder': {
          textFillColor: colors.black60
        }
      }
    }
  },
  button: {
    borderRadius: '0 4px 4px 0 !important',
    boxShadow: 'none !important',
    minWidth: 58,
    '&:disabled': {
      backgroundColor: `${colors.disabledGray} !important`
    }
  }
}))

export const ResponsiveTable = styled('div')(({ theme }) => ({
  maxHeight: '100%',
  '.MuiPaper-root': {
    minHeight: 'unset !important',
    maxHeight: '100%'
  },
  '.MuiTable-root': {
    minWidth: 'unset',
    width: '100%',
    tableLayout: 'fixed',
    '.MuiTableHead-root': {
      border: 'none !important',
      '.MuiTableCell-root': {
        fontWeight: 400,
        padding: 12,
        position: 'sticky',
        top: 0,
        zIndex: 5,
        border: 'none !important'
      },
      '.MuiTableCell-root:nth-of-type(1)': {
        width: '15%',
        borderTopLeftRadius: 4
      },
      '.MuiTableCell-root:nth-of-type(2)': {},
      '.MuiTableCell-root:nth-of-type(3)': {
        width: '20%',
        textAlign: 'center',
        borderTopRightRadius: 4
      }
    },
    '.MuiTableBody-root': {
      position: 'relative',
      zIndex: 0,
      '.MuiTableCell-root': {
        fontWeight: 400,
        padding: 10,
        backgroundColor: colors.white,
        verticalAlign: 'top'
      },
      '.MuiOutlinedInput-root, .MuiOutlinedInput-root input': {
        height: 35
      },
      '.new-field': {
        '.MuiTableCell-root:not(:nth-of-type(2))': {
          paddingTop: 16
        }
      }
    }
  },
  '.MuiTableFooter-root': {
    display: 'none'
  },
  '.row-add-new-message': {
    '.btn-add-new-message': {
      padding: '0 0 0 14px',
      fontSize: 16,
      color: colors.themeSecondColor,
      backgroundColor: 'transparent !important',
      fontWeight: 400,
      span: {
        fontSize: 20
      },
      '&:hover': {
        fontWeight: 600
      }
    },
    '.MuiTableCell-root:hover': {
      cursor: 'pointer',
      '.btn-add-new-message': {
        fontWeight: 600
      }
    }
  }
}))

export const FragmentTab = styled('div')(({ theme }) => ({
  borderBottom: `2px solid ${colors.extraLightBlue}`,
  button: {
    position: 'relative',
    padding: 0,
    height: 40,
    width: 185,
    fontSize: 16,
    color: colors.textPrimary,
    border: 0,
    backgroundColor: 'transparent',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: -2,
      width: 0,
      height: 2,
      transition: 'width .3s ease'
    },
    '&.active': {
      color: colors.themeSecondColor,
      '&:before': {
        width: '100%',
        backgroundColor: colors.themeSecondColor
      }
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%'
    }
  }
}))
