import { makeStyles } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import { Card } from 'react-bootstrap'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  }
}))

export const CardHeader = styled(Card)(({ theme }) => ({
  '.MuiFormControl-root': {
    maxWidth: 'unset'
  }
}))

export default useStyles
