
import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */

/** API */

/** UTILS || SLICE */
import { showModal } from 'app/slice/modal.slice'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'

import InputTextSearch from 'component/Input/InputTextSearch'

import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import FilterSelect from 'component/Select/FilterSelect'
import TableRowExport from 'component/Pdf/TableRowExport'
import useStyles from './style'
import ModalImageDoctorNote from './modal-image-doctor-note'
import ImageDoctorNoteApi, { IFImageDoctorNoteProps } from 'api/setting/doctor-note/image-doctor-note.api'
import _ from 'lodash'



export default function ImageDoctorNote() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const classes = useStyles()

    /** PAGINATION  */
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('createdAt')
    const [sortType, setSortType] = useState('ASC')
    const [search, setSearch] = useState('')
    const [status, setStatus] = useState('ALL')

    const [imageDoctorNotes, setImageDoctorNotes] = useState<[]>([])
    const [dataModal, setDataModal] = useState<{ isShow: boolean, data: any[] }>({ isShow: false, data: [] })


    const loadData = useCallback(async () => {
        let condition: any = {}
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (search) condition = { ...condition, search: search }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

        const res = await ImageDoctorNoteApi.findAll(condition)
        if (res.status === 200) {
            setRowCount(res.headers['x-total'])
            setImageDoctorNotes(res.data)
        }
    }, [page, pageLimit, search, sortBy, sortType, status])

    useEffect(() => {
        loadData()
    }, [loadData])



    const onActive = (id: number, valueStatus: string) => {
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('IMAGE_DOCTOR_NOT.ALERT.CONFIRM_STATUS')}</p>`,
            null, (res: any) => {
                if (res) {
                    ImageDoctorNoteApi.updateStatus(id, valueStatus)
                        .then((resp) => {
                            notiSuccess(t('IMAGE_DOCTOR_NOT.MESSAGE.SUCCESS.UPDATE_STATUS'))
                            loadData()
                        })
                        .catch((e) => {
                            notiError(t('IMAGE_DOCTOR_NOT.MESSAGE.ERROR'))
                        })
                }
            }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))

    }
    const onDelete = (data: any) => {
        const { doctorNoteImageId, name }: IFImageDoctorNoteProps = data

        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('IMAGE_DOCTOR_NOT.ALERT.CONFIRM_DELETE')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('IMAGE_DOCTOR_NOT.ALERT.CONFIRM_DELETE_', {
                name,
            })}</p>`,
            (res: any) => {
                if (res) {
                    ImageDoctorNoteApi.remove(doctorNoteImageId)
                        .then((resp) => {
                            notiSuccess(t('IMAGE_DOCTOR_NOT.MESSAGE.SUCCESS.DELETE'))
                            loadData()
                        })
                        .catch((e) => {
                            notiError(t('IMAGE_DOCTOR_NOT.MESSAGE.ERROR'))
                        })
                }
            }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
        )
    }

    const onCreate = () => {
        setDataModal({ isShow: true, data: [] })
        dispatch(showModal())
    }
    const onEdit = (data: any) => {
        setDataModal({ isShow: true, data })
        dispatch(showModal())
    }

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangePage = (val: number) => {
        setPage(val)
    }

    const handleChangeRowsPerPage = (limit: number) => {
        setPageLimit(limit)
    }
    const handleChangeStatus = (statusVal: string) => {
        setStatus(statusVal)
    }
    const resetPopup = () => {
        setDataModal({ isShow: false, data: [] })
        loadData()
    }
    const headCells = [
        { id: 'no', disablePadding: false, label: '#' },
        { id: 'treatmentCourseTypeName', disablePadding: false, label: t('IMAGE_DOCTOR_NOT.TABLE.CELL.IMAGE_DOCTOR_NOTE_NAME') },
        { id: 'templateFormName', disablePadding: false, label: t('IMAGE_DOCTOR_NOT.TABLE.CELL.IMAGE_DOCTOR_NOTE_NAME_EN') },
        { id: 'status', disablePadding: false, label: t('IMAGE_DOCTOR_NOT.TABLE.CELL.STATUS') },
        { id: 'updatedBy', disablePadding: false, label: t('IMAGE_DOCTOR_NOT.TABLE.CELL.UPDATED_BY') },
        { id: 'action', disablePadding: false, label: 'Action' }
    ]

    const renderData = (objData: IFImageDoctorNoteProps, no: number, valueExportData: boolean) => {
        const num = valueExportData === true ? no + 1 : page * pageLimit - pageLimit + no + 1
        const { doctorNoteImageId, name, doctorNoteType, updatedBy, updatedAt, status: dcTypeStatus }: IFImageDoctorNoteProps = objData
        const statusBtnActive = dcTypeStatus === 'ACTIVE' ? true : false
        const statusBtnInActive = dcTypeStatus === 'INACTIVE' ? true : false
        const objRenderData = {
            key: String(doctorNoteImageId),
            id: String(doctorNoteImageId),
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: num },
                { option: 'TEXT', align: 'left', label: doctorNoteType.typeName || '-' },
                { option: 'TEXT', align: 'left', label: name || '-' },
                { option: 'STATUS', align: 'center', label: dcTypeStatus },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: [
                        { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
                        { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
                        { option: 'DIVIDER', label: '', disabled: false },
                        { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: false },
                        { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: false }
                    ]
                }
            ]
        }
        return (
            <>
                {valueExportData === true && <TableRowExport {...objRenderData} /> ||
                    <TableRowCommon
                        {...objRenderData}
                        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
                        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
                        onedit={() => onEdit(objRenderData.obj)}
                        ondelete={() => onDelete(objRenderData.obj)}
                    />
                }
            </>
        )
    }


    return (
        <div className={classes.root}>
            <Card className="border-0 h-100">
                <HeaderCard text={t('IMAGE_DOCTOR_NOT.TITLE')} />
                <Card.Body>
                    <Card.Title className={'mb-0 pl-xl-3'}>
                        <Row className="align-items-center">
                            <Col md={8}>
                                <Row className="flax-row">
                                    <Col sm={12} md={6} xl={4} className="pt-2">
                                        <InputTextSearch
                                            keyInput={'search'}
                                            label={t('INPUT.SEARCH')}
                                            value={search}
                                            onchange={(event) => {
                                                setSearch(event.target.value)
                                                setPage(1)
                                            }}
                                            onClear={(event) => {
                                                setSearch('')
                                                setPage(1)
                                            }}
                                        />
                                    </Col>
                                    <Col sm={12} md={6} xl={4} className="pl-md-0 pt-2">
                                        <FilterSelect
                                            onchange={(event) => {
                                                const value = event.target.value
                                                if (value) {
                                                    handleChangeStatus(value)
                                                    setPage(1)
                                                }
                                            }}
                                            renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                                            label={t('STATUS.LABEL')}
                                            selectId="select-status"
                                            value={status}
                                            labelId="label-status"
                                            options={[
                                                <MenuItem key="1" value="ALL">
                                                    {t('STATUS.ALL')}
                                                </MenuItem>,
                                                <MenuItem key="2" value="ACTIVE">
                                                    {t('STATUS.ACTIVE')}
                                                </MenuItem>,
                                                <MenuItem key="3" value="INACTIVE">
                                                    {t('STATUS.INACTIVE')}
                                                </MenuItem>
                                            ]}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4} style={{ paddingLeft: 0 }} className="mt-2 mt-md-auto">
                                <div className="pr-xl-2 d-flex">
                                    <div className='ml-auto'>
                                        <ButtonCustom style={{ width: '260px' }} mode="add" onClick={onCreate} textButton={t('IMAGE_DOCTOR_NOT.BUTTON.ADD')} className="w-auto ml-auto d-flex mt-auto" btnStyle={{ height: '44px' }} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card.Title>

                    <div className={'mt-3 pl-xl-3 pr-xl-2'}>
                        <TableCustom
                            page={page}
                            pageLimit={pageLimit}
                            sortType={sortType}
                            sortBy={sortBy}
                            onSort={onRequestSort}
                            setPageLimit={handleChangeRowsPerPage}
                            setPage={handleChangePage}
                            rowCount={rowCount}
                            headCells={headCells}
                            rowsData={_.map(imageDoctorNotes, renderData)}
                        />
                    </div>
                </Card.Body>
            </Card>

            <ModalImageDoctorNote  {...dataModal} callback={loadData} resetPopup={resetPopup} />
        </div>
    )
}
