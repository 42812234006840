//style
import * as UseStyles from './usedStyles'

export default function SignaturePopup(props: any) {

    return (props.trigger) ? (
        <UseStyles.SignatureBackground >
            <UseStyles.SignaturePopupstyles>
                <UseStyles.BoxSignature >
                    {props.children}
                </UseStyles.BoxSignature>
            </UseStyles.SignaturePopupstyles>
        </UseStyles.SignatureBackground>
    ) : <></>;
}