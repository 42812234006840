import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart, TreatmentStateInterface } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/23.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethU, TextNumberU } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
  height: 'auto',
  marginTop: 'auto',
  top: '1.4%',
  left: '45.2%'
}))

export default function Teeth23(props: TeethProps) {
  const teeth = useSelector(toothChart)
  const teethNumber = props.teethNumber
  const historyToothChart = props.historyToothChart
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const isEditTootCharts: any = useSelector(isEditTootChart)

  const dispatch = useDispatch()

  const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
  const [positions, setPositions]: any = useState([])
  const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

  const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
  const [isTooth, setIsTooth]: any = useState(false)
  const [isXRay, setIsXRay]: any = useState(false)
  const [isToothRoot, setIsToothRoot]: any = useState(false)
  const [isOrthodontics, setIsOrthodontics]: any = useState(false)
  const [isExtract, setIsExtract]: any = useState(false)
  const [isFixture, setIsFixture]: any = useState(false)
  const [isBridge, setIsBridge]: any = useState(false)

  const [color, setColor]: any = useState(``)
  const [styleSvg, setStyleSvg]: any = useState({ width: 38.262, height: 93.001, viewBox: "0 0 38.262 93.001" })

  const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

  useEffect(() => {
    const operative = props.operative || ``
    const valColor = props.color || colors.themeSecondColor
    const selected = teeth[teethNumber]?.isSelect || false
    dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
    setIsSelected(selected)

    renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

    if (props.type === 'treatment') {
      renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
    }

    if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

    return setStyleSvg({ width: 32.203, height: 107.581, viewBox: "0 0 32.203 107.581" })
  }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

  useEffect(() => {
    const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
    const teethJson = treatmentTooth?.teethJson

    if (isEditTootCharts !== null) {
      if (teethJson) {
        const tooths = JSON.parse(teethJson)
        setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
        if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
      } else setIsEditTeeth(false)
    }
  }, [isEditTootCharts]);

  useEffect(() => {
    if (isSelected) {
      dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
      if (teeth[props.teethNumber]?.isSelect) {
        setIsSelected(true)
      } else {
        setIsSelected(false)
      }
    }
  }, [props.historyToothChart]);

  useEffect(() => {
    if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
  }, [isSelected, isEditTootCharts]);

  return (
    <Div>
      <DivTeethU className={`div-teeth-${props.teethNumber} `}>
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray />) || ''}
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge />) || ''}
        {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`sm teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
          <svg className={`sm ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
            <g data-name="Group 9157">
              {(isBridge && props.type === 'treatment') ?
                <g></g>
                : ""}
              <path
                data-name="Path 5257"
                d="M352.37 101.574c1.656-2.236 3.616-3.912 6.31-6.78 3.306-3.525 6.98-3.6 11.169.161 3.558 3.19 5.337 7.657 6.9 11.64a27.535 27.535 0 0 1-.87-7.65c.367-14.875-1.876-29.506-3.757-44.175-.793-6.129.238-12.194.793-18.265.226-2.5-.174-4.209-2.862-5.04-3.274 1.2-3.757 4.415-4.8 7.109-5.846 15.185-7.335 31.272-9.513 47.2a97.571 97.571 0 0 1-3.37 15.8z"
                transform="translate(-347.767 -31.464)"
                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
              />

              {(isLowerTeeth && props.type === 'treatment') ?
                <path
                  data-name="Path 6520"
                  d="M352.37 101.574c1.656-2.236 3.616-3.912 6.31-6.78 3.306-3.525 6.98-3.6 11.169.161 3.558 3.19 5.337 7.657 6.9 11.64a27.535 27.535 0 0 1-.87-7.65c.367-14.875-1.876-29.506-3.757-44.175-.793-6.129.238-12.194.793-18.265.226-2.5-.174-4.209-2.862-5.04-3.274 1.2-3.757 4.415-4.8 7.109-5.846 15.185-7.335 31.272-9.513 47.2a97.571 97.571 0 0 1-3.37 15.8z"
                  transform="translate(-347.767 -31.464)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                /> : ''}
              <g data-name="Group 9724">

                {(isFixture && props.type === 'treatment') ? <>
                  <path data-name="Path 5258" d="M379.2 159.882a36.814 36.814 0 0 0-.761-14.108 30.492 30.492 0 0 1-2.088-5.71c-1.56-3.983-3.339-8.45-6.9-11.64-4.189-3.757-7.863-3.687-11.169-.161-2.694 2.868-4.653 4.544-6.31 6.78-2 2.694-2.282 5.556-4.189 12.955a33.409 33.409 0 0 0-.414 4.554c-.021 12.107 13.068 19.09 14.608 19.825 3.403 1.623 17.065-11.595 17.223-12.495z" transform="translate(-347.368 -86.933)" style={{ fill: isTooth ? treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || color : '#efede7' }} />
                  <path data-name="Path 5259" d="M378.555 161.457a33.374 33.374 0 0 0-.69-12.788 27.65 27.65 0 0 1-1.893-5.177c-1.413-3.611-3.026-7.659-6.251-10.551-3.8-3.406-7.128-3.342-10.125-.146a77.943 77.943 0 0 0-5.719 6.146c-1.811 2.442-2.068 5.036-3.8 11.743a30.437 30.437 0 0 0-.376 4.129c-.019 10.974 11.846 17.3 13.243 17.97 3.089 1.471 15.472-10.51 15.611-11.326z" transform="translate(-348.199 -88.631)" style={{ fill: isTooth ? treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || color : '#f7f7f7' }} />
                  <path data-name="Path 5260" d="M377.849 162.653a29.566 29.566 0 0 0-.611-11.332 24.514 24.514 0 0 1-1.678-4.587c-1.252-3.2-2.682-6.787-5.54-9.351-3.365-3.018-6.316-2.961-8.972-.129a69.236 69.236 0 0 0-5.069 5.446c-1.6 2.164-1.832 4.463-3.365 10.406a26.891 26.891 0 0 0-.333 3.658c-.017 9.726 10.5 15.335 11.735 15.925 2.736 1.305 13.71-9.312 13.833-10.036z" transform="translate(-349.115 -90.314)" style={{ fill: isTooth ? treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || color : colors.white }} />
                </> : <>
                  <path
                    data-name="Path 5258"
                    d="M379.2 159.882a36.814 36.814 0 0 0-.761-14.108 30.492 30.492 0 0 1-2.088-5.71c-1.56-3.983-3.339-8.45-6.9-11.64-4.189-3.757-7.863-3.687-11.169-.161-2.694 2.868-4.653 4.544-6.31 6.78-2 2.694-2.282 5.556-4.189 12.955a33.409 33.409 0 0 0-.414 4.554c-.021 12.107 13.068 19.09 14.608 19.825 3.403 1.623 17.065-11.595 17.223-12.495z"
                    transform="translate(-347.368 -64.933)"
                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                  />
                  <path
                    data-name="Path 5259"
                    d="M378.555 161.457a33.374 33.374 0 0 0-.69-12.788 27.65 27.65 0 0 1-1.893-5.177c-1.413-3.611-3.026-7.659-6.251-10.551-3.8-3.406-7.128-3.342-10.125-.146a77.943 77.943 0 0 0-5.719 6.146c-1.811 2.442-2.068 5.036-3.8 11.743a30.437 30.437 0 0 0-.376 4.129c-.019 10.974 11.846 17.3 13.243 17.97 3.089 1.471 15.472-10.51 15.611-11.326z"
                    transform="translate(-348.199 -66.631)"
                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                  />
                  <path
                    data-name="Path 5260"
                    d="M377.849 162.653a29.566 29.566 0 0 0-.611-11.332 24.514 24.514 0 0 1-1.678-4.587c-1.252-3.2-2.682-6.787-5.54-9.351-3.365-3.018-6.316-2.961-8.972-.129a69.236 69.236 0 0 0-5.069 5.446c-1.6 2.164-1.832 4.463-3.365 10.406a26.891 26.891 0 0 0-.333 3.658c-.017 9.726 10.5 15.335 11.735 15.925 2.736 1.305 13.71-9.312 13.833-10.036z"
                    transform="translate(-349.115 -68.314)"
                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                  />
                </>}

                {(isToothRoot && props.type === 'treatment') ?
                  <path
                    data-name="Path 6541"
                    d="M6.924 53.012c-.477-7.058-.661-12.811.454-25.663.352-2.92.77-8.183 2.053-15.173 1.372-7.48 3.59-16.622 3.333-17.4-.339-1.194-2.433 9.732-3.958 22.527C6.871 33.546 5.442 53.076 5.45 53.14a4.227 4.227 0 0 1 1.474-.128z"
                    transform="translate(9.688 7.181)"
                    style={{

                      fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                      stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                    }}
                  />
                  : ''}

                {(isExtract && props.type === 'treatment') ?
                  <>
                    <g data-name="Group 9459">
                      <path data-name="Path 6581" d="m33.788-.5-27.542 80" transform="translate(-4.245 26.5)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                      <path data-name="Path 6582" d="m6.246-.5 27.542 80" transform="translate(-4.245 26.5)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                    </g>
                  </>
                  : ''}

                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                  <>
                    <defs>
                      <linearGradient id="pmtyrm12fa" x1=".5" y1=".453" x2="-1.326" y2="4.622" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#b0b1b1" />
                        <stop offset={1} stopColor="#585959" />
                      </linearGradient>
                      <linearGradient id="u42i8x13xb" x1=".5" y1="1.834" x2=".5" y2=".046" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#c1c4c5" />
                        <stop offset={1} stopColor="#9e9e9e" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9344">
                      <path data-name="Path 5257" d="M352.37 101.574c1.656-2.236 3.616-3.912 6.31-6.78 3.306-3.525 6.98-3.6 11.169.161 3.558 3.19 5.337 7.657 6.9 11.64a27.535 27.535 0 0 1-.87-7.65c.367-14.875-1.876-29.506-3.757-44.175-.793-6.129.238-12.194.793-18.265.226-2.5-.174-4.209-2.862-5.04-3.274 1.2-3.757 4.415-4.8 7.109-5.846 15.185-7.335 31.272-9.513 47.2a97.571 97.571 0 0 1-3.37 15.8z" transform="translate(-347.275 -31.464)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                      <path data-name="Path 5258" d="M379.2 159.882a36.814 36.814 0 0 0-.761-14.108 30.492 30.492 0 0 1-2.088-5.71c-1.56-3.983-3.339-8.45-6.9-11.64-4.189-3.757-7.863-3.687-11.169-.161-2.694 2.868-4.653 4.544-6.31 6.78-2 2.694-2.282 5.556-4.189 12.955a33.409 33.409 0 0 0-.414 4.554c-.021 12.107 13.068 19.09 14.608 19.825 3.403 1.623 17.065-11.595 17.223-12.495z" transform="translate(-346.876 -64.933)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5259" d="M378.555 161.457a33.374 33.374 0 0 0-.69-12.788 27.65 27.65 0 0 1-1.893-5.177c-1.413-3.611-3.026-7.659-6.251-10.551-3.8-3.406-7.128-3.342-10.125-.146a77.943 77.943 0 0 0-5.719 6.146c-1.811 2.442-2.068 5.036-3.8 11.743a30.437 30.437 0 0 0-.376 4.129c-.019 10.974 11.846 17.3 13.243 17.97 3.089 1.471 15.472-10.51 15.611-11.326z" transform="translate(-347.707 -66.631)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5260" d="M377.849 162.653a29.566 29.566 0 0 0-.611-11.332 24.514 24.514 0 0 1-1.678-4.587c-1.252-3.2-2.682-6.787-5.54-9.351-3.365-3.018-6.316-2.961-8.972-.129a69.236 69.236 0 0 0-5.069 5.446c-1.6 2.164-1.832 4.463-3.365 10.406a26.891 26.891 0 0 0-.333 3.658c-.017 9.726 10.5 15.335 11.735 15.925 2.736 1.305 13.71-9.312 13.833-10.036z" transform="translate(-348.623 -68.314)" style={{ fill: colors.white }} />
                      <g data-name="Group 9387">
                        <g data-name="Group 9380" transform="translate(-2.508 79.592)">
                          <g data-name="Rectangle 3927" transform="translate(13.517)" style={{ fill: '#c1c4c5', stroke: '#c1c4c5' }}>
                            <rect width="11.601" height="11.601" rx={2} style={{ stroke: 'none' }} />
                            <rect x=".5" y=".5" width="10.601" height="10.601" rx="1.5" style={{ fill: 'none' }} />
                          </g>
                          <path data-name="Path 6487" d="M11.579 17.547c-.2.362 1.676.183 3.083.165 2.094-.044 3.289.384 3.269-4.45a41.118 41.118 0 0 0-.159-5.141c-.531-.516.14 5.236-1.219 7.766-1.282 1.827-4.631 1.272-4.974 1.66z" transform="translate(6.863 -6.464)" style={{ fill: '#b0b1b1' }} />
                          <rect data-name="Rectangle 3931" width={5} height={2} rx=".5" transform="translate(16.806 6.215)" style={{ fill: '#cbcccc' }} />
                          <rect data-name="Rectangle 3930" width={5} height={2} rx=".5" transform="translate(16.605 2.015)" style={{ fill: '#9e9e9e' }} />
                          <rect data-name="Rectangle 3928" width={5} height={2} rx=".5" transform="translate(16.806 2.015)" style={{ fill: 'url(#pmtyrm12fa)' }} />
                          <rect data-name="Rectangle 3929" width={5} height={2} rx=".5" transform="translate(16.806 6.015)" style={{ fill: 'url(#u42i8x13xb)' }} />
                        </g>
                        <path data-name="Path 6488" d="M2.064 11.03a58.472 58.472 0 0 1 31.976-.709" transform="translate(-1.423 76.012)" style={{ strokeLinecap: 'round', stroke: '#babbbb', fill: 'none' }} />
                        <path data-name="Path 6486" d="M2.234 11.053s17.358-5.363 32.022-.787" transform="translate(-1.609 76.693)" style={{ stroke: '#b5b7b7', strokeLinecap: 'round', fill: 'none' }} />
                      </g>
                    </g>
                  </> || ""}

                {(isFixture && props.type === 'treatment') ?
                  <>
                    <defs>
                      <linearGradient id="y3q7aowyra" x1=".209" y1="-.059" x2="1.176" y2="1.286" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b4b5b5" />
                      </linearGradient>
                      <linearGradient id="1vbl48vy6b" x1="-.342" y1="-.081" x2="1.352" y2={1} gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b0b1b1" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9485">
                      <g data-name="Group 9548">
                        <g data-name="Path 6490" transform="translate(6.284)" style={{ fill: 'url(#y3q7aowyra)' }}>
                          <path d="M17.343 44.996H1.734c-.422 0-.825-.367-1.135-1.033-.322-.69-.499-1.61-.499-2.59C.845 24.493 1.818 12.58 2.991 5.97 3.682 2.075 4.427.1 5.203.1h8.671c.852 0 5.103 1.99 5.103 41.273 0 .98-.177 1.9-.499 2.59-.31.666-.713 1.033-1.135 1.033z" style={{ stroke: 'none' }} />
                          <path d="M17.343 44.896c.74 0 1.534-1.416 1.534-3.523 0-16.645-.753-28.547-2.238-35.377-.504-2.319-1.081-3.983-1.715-4.946-.486-.74-.867-.85-1.05-.85H5.203c-.406 0-1.22.752-2.114 5.788C1.917 12.593.945 24.498.2 41.378c.001 2.104.794 3.518 1.534 3.518h15.609m0 .2H1.734C.776 45.096 0 43.429 0 41.373 1.734 2.085 4.245 0 5.203 0h8.671c.958 0 5.203 2.085 5.203 41.373 0 2.056-.777 3.723-1.734 3.723z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <g data-name="Path 6489" transform="translate(4 42.63)" style={{ strokeLinejoin: 'round', fill: 'url(#1vbl48vy6b)' }}>
                          <path d="M23.198 7.569H1.16C.577 7.569.103 6.76.1 5.764.653 2.756.796 1.315.865.622.892.35.912.148.938.1h.007c.016 0 .037.002.062.004.04.004.09.01.153.01h22.038a4.489 4.489 0 0 0 .126-.002c.198 0 .21 0 .385 1.485.102.86.256 2.157.548 4.165-.002.997-.476 1.807-1.06 1.807z" style={{ stroke: 'none' }} />
                          <path d="M23.198 7.469c.517 0 .955-.777.96-1.7a142.268 142.268 0 0 1-.548-4.16C23.518.83 23.456.308 23.39.215a.404.404 0 0 0-.065-.003l-.126.001H1.16C1.1.213 1.052.21 1.013.206a7.67 7.67 0 0 0-.049.426C.895 1.326.752 2.768.2 5.772c.006.922.443 1.697.96 1.697h22.038m0 .2H1.16C.52 7.669 0 6.812 0 5.755 1.16-.555.52.013 1.16.013h22.038c.64 0 .287-.24 1.16 5.742 0 1.057-.52 1.914-1.16 1.914z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <path data-name="Path 6491" d="M5.625 21.2s18.057.9 18.962 1.957" transform="translate(.773 15.597)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6492" d="M5.649 21.2S23.1 22.161 24 23.213" transform="translate(1.06 10.345)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6493" d="M7.08 21.281s14.6.592 15.5 1.645" transform="translate(1.508 -11.973)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6584" d="M5.649 21.2S23.1 22.161 24 23.213" transform="translate(1.316 5.802)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6585" d="M6.115 21.2S23.1 22.185 24 23.237" transform="translate(1.316 -.222)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6586" d="M6.643 21.3s16.036.854 16.941 1.907" transform="translate(1.316 -6.281)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6587" d="M8.144 21.444s12.509.373 13.415 1.426" transform="translate(1.508 -17.717)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      </g>
                    </g>
                  </> : ""}
              </g>
            </g>
          </svg>}
      </DivTeethU>
      <PositionTeeth quarter={QUARTER_NAME_TYPE.Q2} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
      <TextNumberU>23</TextNumberU>
    </Div>
  )
}
