import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
export interface BanksInterface {
  bankShortname: string
  bankName: string
  bankLogo?: string
  hasTransfer: string
  hasCredit: string
  hasDebit: string
  status?: string
  image: any
}

export default class BanksApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('banks', { params: { ...props } })
  }
  static create(payload: any) {
    return this.api.post('banks', payload)
  }
  static findById(id: number): Promise<BanksInterface> {
    return this.api.get(`banks/${id}`)
  }
  static update(id: number, body: BanksInterface) {
    return this.api.patch(`banks/${id}`, body)
  }
  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`banks/${id}/status`, { id, status })
  }
  static remove(id: number) {
    return this.api.delete(`banks/${id}`)
  }
}
