import { makeStyles } from '@material-ui/core'
import { colors } from 'constants/theme'
import { styled } from '@mui/material/styles'
import { Button } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    backgroundColor: colors.white
  },
  btnAddRecommends: {}
}))

export const ButtonAddRecommend = styled(Button)(({ theme }) => ({
  padding: 0,
  fontSize: 16,
  color: colors.themeSecondColor,
  backgroundColor: 'transparent !important',
  marginBottom: '-8px',
  '&:hover': {
    fontWeight: 600
  }
}))

export default useStyles
