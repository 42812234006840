import { useState, useEffect } from 'react';
import _ from 'lodash'
import { Box, IconButton, TableRow, TableCell, Button, TextareaAutosize, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API*/

/** UTILS || SLICE */
import { treatment, setTreatmentByKey, TreatmentStateInterface } from 'app/slice/treatment.slice';
import * as modalSlice from 'app/slice/modal.slice'

/** COMPONENT */
import TableCustom from 'component/Table/TableCustom'
import ButtonCustom from 'component/Button/ButtonCustom';

/** CONSTANTS */
import { colors } from 'constants/theme'
import { icons } from 'constants/images'

/** STYLE */
import { styled } from '@mui/material/styles'
import PopupRecommend from 'component/Treatments/PopupRecommend'
import * as UseStyled from 'features/treatments/SummaryTreatment/UseStyle'

type RecommendProps = {
  popupRecommend?: () => void
}

export const ButtonAddRecommend = styled(Button)(({ theme }) => ({
  padding: '0 0 0 24px',
  fontSize: 16,
  color: colors.themeSecondColor,
  backgroundColor: 'transparent !important',
  fontWeight: 400,
  span: {
    fontSize: 20
  },
}))

export const TableWidth = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    tableLayout: 'fixed',
    minWidth: '100%',
    '.MuiTableHead-root': {
      '.MuiTableCell-head': {
        paddingLeft: 8,
        paddingRight: 8,
        '&.MuiTableCell-head:first-of-type': {
          paddingLeft: 4,
          paddingRight: 4,
          width: 40,
          textAlign: 'center'
        },
        '&.MuiTableCell-head:nth-of-type(2)': {
          width: '90%',
          textAlign: 'left',
        },
        '&.MuiTableCell-head:last-of-type': {
          paddingLeft: 4,
          paddingRight: 4,
          width: 65,
          textAlign: 'center'
        }
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-body': {
        padding: '8px',
        verticalAlign: 'middle',
        '&:first-of-type': {
          paddingLeft: 4,
          paddingRight: 4
        }
      }
    },
  },
  '.MuiTableFooter-root': {
    display: 'none'
  }
}))

type ConfigModal = {
  type?: 'Recommend' | ''
  no?: number | null
}

export default function Recommend(props: RecommendProps) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [configModal, setConfigModal] = useState<ConfigModal>({})
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const modal: boolean = useSelector(modalSlice.handleShow)

  const popupRecommend = (no: number | null) => {
    setConfigModal({ type: 'Recommend', no: no })
    dispatch(modalSlice.showModal())
  }

  const onResetPopup = () => {
    setConfigModal({})
    dispatch(modalSlice.resetModal())
  }

  const onSubmitRecommend = () => {
    dispatch(modalSlice.resetModal())
  }

  const handleDiscard = (id: number) => {
    const item = (_.filter(treatments.patientRecommends, (itemRecommends, index: number) => index !== id))
    dispatch(setTreatmentByKey({ key: 'patientRecommends', value: item }))
  }

  const headCells = [
    { id: 'no', disablePadding: false, align: 'center', label: '#' },
    { id: 'message', disablePadding: false, align: 'left', label: t('RECOMMEND_SUMMARY.TABLE.RECOMMEND_LIST') },
    { id: 'message', disablePadding: false, align: 'center', label: t('Action') }
  ]

  const addNewRecommend = () => {
    return (
      <TableRow className="row-add-new-message">
        <TableCell
          align="left"
          scope="row"
          colSpan={3}
          onClick={() => popupRecommend(null)}
          className={`cursor-pointer`}
          sx={{ '&:hover': { button: { fontWeight: 600 } } }}>
          <ButtonAddRecommend variant="text" className="d-flex align-items-baseline">
            <span className={'mr-1'}>+</span> {t('RECOMMEND_SUMMARY.TITLE.ADD_RECOMMEND')}
          </ButtonAddRecommend>
        </TableCell>
      </TableRow>
    )
  }

  const renderData = (data: any, no: number) => {
    return (
      <TableRow key={`diagnosis-${no}`}>
        <TableCell align="center" scope="row" className="py-3 align-top">
          {no + 1}
        </TableCell>
        <TableCell align="left" scope="row" className="py-2 align-top">
          <Box className="w-100 d-flex flex-column flex-sm-row">
            <Typography className="d-none d-sm-inline-block pr-4 pt-2" sx={{ flex: 'none' }}>{t('RECOMMEND_SUMMARY.TITLE.RECOMMEND')}</Typography>
            <Box className="d-flex w-100">
              <UseStyled.InputFormControl className="pl-sm-2 d-flex position-relative" sx={{ flex: 1 }}>
                <TextareaAutosize
                  name="note"
                  className={`input-form-note pe-none`}
                  value={data?.recommendText.replaceAll('\n', ' ') || ''}
                  minRows={1} />
                <Box className='input-form-note-wrap position-absolute'></Box>
                <ButtonCustom
                  onClick={() => popupRecommend(no)}
                  textButton={<img src={icons.iconFeatherEdit} alt="" style={{ width: 24 }} />}
                  className="h-100"
                  style={{ margin: 0, height: '100%' }} />
              </UseStyled.InputFormControl>
            </Box>
          </Box>
        </TableCell>
        <TableCell align="center" scope="row" className="py-3 align-top">
          <IconButton disableFocusRipple disableRipple onClick={() => handleDiscard(no)} className="p-0 align-self-start mr-2 ml-3" sx={{ backgroundColor: 'transparent' }}>
            <img src={icons.btnMinus} alt="discard" />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }

  useEffect(() => {
    if (!modal) onResetPopup()
  }, [modal]);

  return (
    <>
      <Box>
        <TableWidth className={'overflow-hidden'}>
          <TableCustom
            hidePagination
            tableMinWidth={370}
            customScroll
            page={0}
            pageLimit={0}
            sortType={'ASC'}
            sortBy={''}
            rowCount={0}
            onSort={() => 0}
            setPageLimit={() => 0}
            setPage={() => 0}
            textEmptyData={t('REGISTER.NO_ITEM')}
            headCells={headCells}
            rowsData={[
              addNewRecommend(),
              _.map(treatments.patientRecommends, (i, index: number) => {
                return renderData(i, index)
              }).reverse()
            ]}
          />
        </TableWidth>
      </Box>
      {configModal.type === 'Recommend' && (
        <PopupRecommend
          no={(configModal.no || configModal.no === 0) ? configModal.no : null}
          onReset={onResetPopup}
          onSubmit={onSubmitRecommend} />
      ) || <></>}
    </>
  )
}
