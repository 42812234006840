import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { Box, ClickAwayListener, MenuItem, TableSortLabel } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'

import { routeName } from 'routes/routes-name'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API, UTIL*/
import { getBranch } from 'utils/app.utils'
import DoctorScheduleApi from 'api/counter/doctor-schedule.api'
import ClinicApi, { BranchInterface } from 'api/master/clinic.api'
import ReportApi, { FindAllReportInterface } from 'api/report.api'

/** COMPONENT */
import Loading from 'component/Loading'
import SchedulesList from './SchedulesDoctorList'
import FucntionPrint from 'component/Print/print'
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import InputCheckbox from 'component/Input/InputCheckbox'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { APPOINTMENT_STATUS } from 'features/auth/confirm-appointment'
import { CustomFilterDoctor, ListFilterDoctors } from 'features/counter/appointment/head-appointment'

/** STYLED */
import * as UseStyled from 'features/report/useStyled'
import { dateToView } from 'utils/date.utils'
import TreatmentApi from 'api/dentists/treatment.api'
import SchedulesDoctorStaus from './SchedulesDoctorStaus'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import ButtonCustom from 'component/Button/ButtonCustom'
import { useDispatch } from 'react-redux'
import { submitModal, unSubmitModal, showModal, resetModal } from 'app/slice/modal.slice'
import { Style } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import PrintIcon from '@mui/icons-material/Print'
import { SpanClearFilter } from 'features/report/useStyled'
import { colors } from 'constants/theme'

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}

interface DentistInterface {
  dentistId: number
  dentistFullname: string
}
const headCells = [
  { id: '0', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.TIME', width: 'auto', align: 'center', class: 'text-nowrap' },
  { id: '1', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.RANG_TIME', width: 'auto', align: 'center', class: 'text-nowrap' },
  { id: '2', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.CN', width: 'auto', align: 'center', class: 'text-nowrap' },
  { id: '3', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.PT', width: 'auto', align: 'center', class: 'text-nowrap' },
  { id: '4', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.SN', width: 'auto', align: 'center', class: 'text-nowrap' },
  { id: '5', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.PATIENT_NAME', width: 'auto', align: 'left', class: 'text-nowrap' },
  { id: '6', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.PHONE', width: 'auto', align: 'left', class: 'text-nowrap' },
  { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.OPERATIVE_TYPE', width: 'auto', align: 'left', class: 'text-nowrap' },
  { id: '8', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.OPERATIVE', width: '400x', align: 'center', class: 'text-nowrap' },
  { id: '9', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.STATUS', width: 'auto', align: 'left', class: 'text-nowrap' },
  { id: '10', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.PRICE', width: 'auto', align: 'left', class: 'text-ellipsis' },
  { id: '11', disablePadding: false, labelKey: 'Lab', align: 'left', width: 'auto', class: 'text-ellipsis' },
  { id: '12', disablePadding: false, labelKey: 'X-Ray', align: 'left', width: '80px', class: 'text-ellipsis' },
  { id: '13', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.SPACIAL', width: 'auto', align: 'left', class: 'text-nowrap' },
  { id: '14', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.PRACTICE', width: 'auto', align: 'left', class: 'text-nowrap' },
  { id: '15', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.REMARK', width: 'auto', align: 'left', class: 'text-nowrap' },
  { id: '16', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.CREATE_BY', width: 'auto', align: 'center', class: 'text-nowrap' },
  { id: '17', disablePadding: false, labelKey: 'REPORT.TABLE.SCHEDULES_DOCTOR.UPDATE_BY', width: 'auto', align: 'center', class: 'text-nowrap' }
]

export default function SchedulesDoctor() {
  const { t } = useTranslation()
  const history = useHistory()
  const componentRef = useRef<HTMLDivElement>(null)
  const momentNowRange = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  const momentDate = () => momentNowRange

  const [branchId, setBranchId] = useState<number>(getBranch())
  const [branches, setBranches] = useState<any>(getBranch())
  const [rangeDate, setRangeDate] = useState<string[]>(momentDate)
  const [selectDoctors, setSelectDoctors] = useState<any[]>(['ALL'])
  const [doctors, setDoctors] = useState<Doctors[]>([])
  const [doctorsMultiple, setDoctorsMultiple] = useState<DentistInterface[]>([])
  const [rowCount, setRowCount] = useState<number>(0)
  const [sortBy, setSortBy] = useState<string>('createAt')
  const [sortType, setSortType] = useState<string>('ASC')
  const [orderedSelectedDoctors, setOrderedSelectedDoctors] = useState<any[]>([])

  const [SchedulesData, getSchedulesData] = useState<any[]>([])
  const [schedulesPage, setSchedulesPage] = useState<number>(1)
  const [schedulesTypePage, setSchedulesTypePage] = useState<number>(1)
  const [schedulesPageLimit, setSchedulesPageLimit] = useState<number>(10)
  const [operativeTypeId, setOperativeTypeId] = useState<number>(0)
  const [operativeType, setOperativeType] = useState<any>()
  const [operativeId, setOperativeId] = useState<number>(0)
  const [operative, setOperative] = useState<any>()

  const [appointmentStatus, setAppointmentStatus] = useState('ALL')

  const [checkDf, setCheckDf] = useState<boolean>(false)
  const [fetchData, setFetchData] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [appointment, setAppointment] = useState<any>()
  const [selectHeadCells, setSelectHeadCells] = useState<string[]>(headCells.map((cell) => cell.id))
  const [exportType, setExportType] = useState<string>()
  const [openFilterDoctors, setOpenFilterDoctors] = useState(false)

  const verifyPermission = async () => {
    const condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 200
    }
    ReportApi.reportList(condition).then(({ data }) => {
      const check = _.find(data, (val: any) => String(val.reportKey) === 'SCHEDULES_DOCTOR') ? true : false
      if (!check) {
        history.push(routeName.report)
        return false
      }
    })
    ClinicApi.findAllBranches()
      .then(({ data }) => setBranches(data))
      .catch((e) => {
        return
      })

    ClinicApi.findAllDoctors()
      .then((resp) => {
        setDoctors(resp.data)
      })
      .catch((e) => {
        return
      })

    DoctorScheduleApi.findAllDoctor({ branchId: branchId }).then(({ data: dataAllDoctor }) => {
      const dentists: DentistInterface[] = dataAllDoctor.map((d: any) => {
        return {
          dentistId: d.userId,
          dentistFullname: d.fullname
        }
      })
      setDoctorsMultiple(dentists)
      setSelectDoctors(['ALL', ...dentists.map((d: DentistInterface) => d.dentistId)])
    })

    return true
  }
  useEffect(() => {
    verifyPermission()
  }, [branchId])

  const renderOperativeType = () => {
    if (operativeTypeId === 0) {
      return `${t('REPORT.FILTER.OPERATIVE_TYPE')}: ${t('REPORT.REPORT_GROUP.ALL')}`
    }
    return `${t('REPORT.FILTER.OPERATIVE_TYPE')}: ${_.get(_.find(operativeType, { operativeTypeId: operativeTypeId }), 'operativeTypeName' || 'operativeTypeNameEn')} `
  }

  const renderOperative = () => {
    if (operativeTypeId === 0) {
      return `${t('REPORT.FILTER.OPERATIVE')} : ${t('REPORT.REPORT_GROUP.ALL')}`
    }
    return `${t('REPORT.FILTER.OPERATIVE')}: ${_.get(_.find(operative, { operativeId: operativeId }), 'operativeName' || 'operativeNameEn')} `
  }

  const fetchSchedules = useCallback(() => {
    let condition: FindAllReportInterface = {
      branchId: branchId,
      dateStart: rangeDate[0],
      dateEnd: rangeDate[1]
    }

    if (operativeId && operativeTypeId !== 0) condition = { ...condition, operativeId: operativeId }
    if (appointmentStatus && appointmentStatus !== 'ALL') {
      condition = { ...condition, status: appointmentStatus }
    }

    if (selectDoctors) {
      const doctor = _.filter(selectDoctors, (v: any) => v > 0).join(',')
      if (doctor) condition = { ...condition, doctorId: _.filter(selectDoctors, (v: any) => v > 0).join(',') }
    }
    if (operativeTypeId) condition = { ...condition, operativeType: operativeTypeId }
    if (branchId) condition = { ...condition, branchId: branchId }
    if (rangeDate) {
      if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    }

    ReportApi.reportSchedulesDoctor(condition)

      .then(({ headers, data }) => {
        getSchedulesData(data.rows)
        setRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
    setFetchData(false)
  }, [schedulesTypePage, schedulesPageLimit, fetchData])

  const loadDataOperativeType = useCallback(() => {
    TreatmentApi.findAllOperativeType()
      .then(({ data }) => setOperativeType(data))
      .catch(() => {
        return
      })
  }, [])

  const loadDataOperative = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const condition = {
      page: 1,
      pageLimit: 10000,
      sortType: 'asc',
      sortBy: 'operativeId',
      status: 'ACTIVE',
      operativeTypeId: e.target.value
    }
    try {
      const { data } = await TreatmentApi.findOperativeAllActive(condition)
      setOperative(data)
    } catch (error) {
      console.error('Error fetching operative data:', error)
    }
  }

  const { onDownload } = useDownloadExcel({
    tableExportRef: componentRef.current,
    filename: `${t(`REPORT.REPORT_NAME.SCHEDULES_DOCTOR`)} (${rangeDate[0]}-${rangeDate[1]})`,
    sheetName: `${t(`REPORT.REPORT_NAME.SCHEDULES_DOCTOR`)}`
  })

  const dispatch = useDispatch()

  // This function groups an array into sub-arrays of a specified size
  const groupBy = (array: any[], size: number): any[][] => {
    const groups = []
    for (let i = 0; i < array.length; i += size) {
      groups.push(array.slice(i, i + size))
    }
    return groups
  }

  const groupedHeadCells = useMemo(() => groupBy(headCells, 4), [headCells])
  const handleFilterHeadCells = useCallback(
    (e: React.ChangeEvent<{ value: unknown }>) => {
      const value = Array.isArray(e.target.value) ? e.target.value : ([e.target.value] as string[])

      if (value.includes('NULL') && value.length > 1) {
        const newValues = value.filter((item) => item !== 'NULL')
        setSelectHeadCells(newValues)
      }

      // เมื่อเลือก 'ALL' และ item อื่น ๆ
      if (value.includes('ALL') && value.length > 1) {
        const newValues = value.filter((item) => item !== 'ALL')
        setSelectHeadCells(newValues)
      }
      // เมื่อเลือก 'ALL' เท่านั้น
      else if (value.includes('ALL') && value.length === 1) {
        // Check if current state already has all items selected
        if (selectHeadCells.length === headCells.length + 1 && selectHeadCells.includes('ALL')) {
          setSelectHeadCells(['NULL'])
        } else {
          setSelectHeadCells(['ALL', ...headCells.map((cell) => cell.id)])
        }
      }
      // เมื่อไม่ได้เลือก 'ALL' แต่ item อื่น ๆ เลือกไม่ครบ
      else if (!value.includes('ALL') && value.length !== headCells.length) {
        setSelectHeadCells(value)
      }
      // เมื่อไม่ได้เลือก 'ALL' แต่ item อื่น ๆ เลือกทุกตัว
      else if (!value.includes('ALL') && value.length === headCells.length) {
        setSelectHeadCells(['ALL', ...value])
      }
    },
    [headCells, selectHeadCells]
  )

  const handleToggleCheckbox = (id: string) => {
    if (id === 'ALL' || selectHeadCells.includes(id)) {
      const newValue = selectHeadCells.includes(id) ? selectHeadCells.filter((value) => value !== id) : [...selectHeadCells, id]

      const event = {
        target: { value: newValue }
      }

      handleFilterHeadCells(event as React.ChangeEvent<{ value: unknown }>)
    } else {
      const newValue = [...selectHeadCells, id]
      const event = {
        target: { value: newValue }
      }

      handleFilterHeadCells(event as React.ChangeEvent<{ value: unknown }>)
    }
  }
  const handleFilterDoctors = (value: any) => {
    if ((value === 'ALL' && _.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)) || value === 'CLEAR') setSelectDoctors([''])
    else if (value === 'ALL') setSelectDoctors([..._.map(doctors, (d: any) => d.userId)])
    else if (_.includes(selectDoctors, value)) setSelectDoctors(_.filter(selectDoctors, (d) => d !== value))
    else setSelectDoctors([...selectDoctors, value])
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }
  const handleChangeRowsPerPage = (limit: number) => setSchedulesPageLimit(limit)
  const handleChangePage = (val: number) => setSchedulesPage(val)
  const setDoc = (data: any) => {
    const sortedSelectData = data.sort((a: any, b: any) => {
      const idA = a.dentistId
      const idB = b.dentistId
      return selectDoctors.indexOf(idA) - selectDoctors.indexOf(idB)
    })
    return sortedSelectData
  }
  useEffect(() => {
    loadDataOperativeType()
    fetchSchedules()
  }, [fetchSchedules, loadDataOperativeType])
  return (
    <div>
      <Card className={'border-0 h-100'}>
        <HeaderCard text={t(`REPORT.REPORT_NAME.SCHEDULES_DOCTOR`)} />
        <Card.Body>
          <Card.Title className={'mb-0'}>
            <Row className="mx-0">
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setBranchId(e.target.value)
                    setSchedulesPage(1)
                  }}
                  renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                  label={''}
                  selectId="select-branch"
                  labelId="label-branch"
                  value={branchId}
                  options={_.map(branches, (b: BranchInterface, index) => {
                    return (
                      <MenuItem key={b.branchId} value={b.branchId}>
                        {b.branchName || b.branchNameEn}
                      </MenuItem>
                    )
                  })}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <InputSecondNewRangePicker
                  inputHeight={32}
                  value={rangeDate || emptyRangePicker}
                  onchange={(val: any) => {
                    setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                    setSchedulesPage(1)
                    setSchedulesTypePage(1)
                  }}
                  label={''}
                />
              </Col>
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setAppointmentStatus(e.target.value)
                    setSchedulesPage(1)
                  }}
                  renderValue={() => (appointmentStatus === 'ALL' ? `${t('REPORT.FILTER.APPOINTMENT_STATUS')}: ${t('REPORT.REPORT_GROUP.ALL')}` : `${t('REPORT.FILTER.APPOINTMENT_STATUS')}: ${t(`APPOINTMENT.STATUS.${appointmentStatus}`)}`)}
                  label={''}
                  selectId="select-appointment-status"
                  labelId="label-appointment-status"
                  value={appointmentStatus}
                  options={[
                    <MenuItem key="ALL" value="ALL">
                      {t('REPORT.FILTER.ALL')}
                    </MenuItem>,
                    ...Object.values(APPOINTMENT_STATUS).map((status, index) => (
                      <MenuItem key={index} value={status}>
                        {t(`APPOINTMENT.STATUS.${status}`)}
                      </MenuItem>
                    ))
                  ]}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>
              <Col
                sm={6}
                md={4}
                xl={3}
                className={'pt-2 px-1'}
                onClick={() => {
                  setCheckDf(!checkDf)
                  setSchedulesPage(1)
                }}
              >
                <ClickAwayListener onClickAway={() => setOpenFilterDoctors(false)}>
                  <CustomFilterDoctor open={openFilterDoctors} onClick={() => setOpenFilterDoctors(true)}>
                    <AutocompleteSelect
                      open={openFilterDoctors}
                      multiple
                      labelId="label-doctors"
                      options={['ALL', ...doctors, 'CLEAR']}
                      getOptionLabel={(option) => _.find(doctors, { userId: option.userId })?.fullname || '-'}
                      renderOption={(props, option) => {
                        if (option === 'ALL') {
                          return (
                            <ListFilterDoctors {...props} key={option} component="li" value={option}>
                              <InputCheckbox
                                label={t('REPORT.REPORT_GROUP.ALL')}
                                checked={_.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)}
                                indeterminate={_.size(selectDoctors) > 1 && _.size(selectDoctors.filter((item) => typeof item === 'number')) !== _.size(doctors)}
                                className={'pe-none'}
                              />
                            </ListFilterDoctors>
                          )
                        } else if (option === 'CLEAR') {
                          return (
                            <Box {...props} key={option} component="li" value={option} sx={{ borderTop: `1px solid ${colors.lightGray}` }}>
                              <SpanClearFilter className="pe-none">{t('REPORT.FILTER.CLEAR_FILTER')}</SpanClearFilter>
                            </Box>
                          )
                        } else {
                          return (
                            <ListFilterDoctors {...props} key={option.userId} component="li" value={option.userId}>
                              <InputCheckbox label={option.fullname} checked={_.includes(selectDoctors, option.userId)} className={'pe-none'} />
                            </ListFilterDoctors>
                          )
                        }
                      }}
                      renderTags={(tagValue, getTagProps) => {
                        return (
                          <>
                            <span className="pr-1">{t('USER_DOCTOR')}:</span>
                            {!openFilterDoctors &&
                              ((_.size(tagValue.filter((item: any) => typeof item === 'number')) === _.size(doctors) && <span>{t('REPORT.REPORT_GROUP.ALL')}</span>) || (
                                <Box className="text-ellipsis">
                                  {_.map(tagValue, (option: any, index: number) => {
                                    return (
                                      <span>
                                        {_.find(doctors, { userId: option })?.fullname}
                                        {index !== _.findLastIndex(tagValue) && option && `,`}
                                      </span>
                                    )
                                  })}
                                </Box>
                              ))}
                          </>
                        )
                      }}
                      onchange={(e, value) => handleFilterDoctors(e?.target?.value || e?.target?.attributes?.value?.value)}
                      noOptionsText={t('NOT_FOUND')}
                      filterOptions={(option) => 'ALL' + option.fullname + 'CLEAR'}
                      value={selectDoctors}
                      height={32}
                      disableClearable
                    />
                  </CustomFilterDoctor>
                </ClickAwayListener>
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setOperativeTypeId(e.target.value)
                    loadDataOperative(e)
                    setSchedulesPage(1)
                  }}
                  renderValue={() => {
                    if (operativeTypeId === 0) {
                      return `${t('REPORT.FILTER.OPERATIVE_TYPE')}: ${t('REPORT.REPORT_GROUP.ALL')}`
                    }
                    return `${t('REPORT.FILTER.OPERATIVE_TYPE')}: ${_.get(_.find(operativeType, { operativeTypeId: operativeTypeId }), 'operativeTypeName')} `
                  }}
                  label={''}
                  selectId="select-operative-type"
                  labelId="label-operative-type"
                  value={operativeTypeId}
                  options={[
                    <MenuItem key={0} value={0}>
                      {t('REPORT.REPORT_GROUP.ALL')}
                    </MenuItem>,
                    ..._.map(operativeType, (b: any, index) => {
                      return (
                        <MenuItem key={b.operativeTypeId} value={b.operativeTypeId}>
                          {b.operativeTypeName}
                        </MenuItem>
                      )
                    })
                  ]}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-operative-type"
                />
              </Col>

              <Col sm={6} md={4} xl={3} className="pt-2 px-1">
                <FilterSelect
                  onchange={(e) => {
                    setOperativeId(e.target.value)
                    setSchedulesPage(1)
                  }}
                  renderValue={() => {
                    if (operativeTypeId === 0) {
                      return `${t('REPORT.FILTER.OPERATIVE')}: ${t('REPORT.REPORT_GROUP.ALL')}`
                    }
                    const selectedOperative = operative?.find((b: any) => b.operativeId === operativeId)
                    return `${t('REPORT.FILTER.OPERATIVE')}: ${selectedOperative?.operativeName ?? t('REPORT.REPORT_GROUP.ALL')}`
                  }}
                  label=""
                  selectId="select-operative"
                  labelId="label-operative"
                  value={operativeId}
                  options={[
                    <MenuItem key={0} value={0}>
                      {t('REPORT.REPORT_GROUP.ALL')}
                    </MenuItem>,
                    ...(operative || []) // Use an empty array as fallback if operative is undefined
                      .filter((b: any) => b.operativeTypeId === operativeTypeId)
                      .map((b: any) => (
                        <MenuItem key={b.operativeId} value={b.operativeId}>
                          {b.operativeName}
                        </MenuItem>
                      ))
                  ]}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-operative"
                  disabled={operativeTypeId === 0}
                />
              </Col>

              <Col md={1} xl={1} className={'pt-0'}>
                <ButtonCustom
                  onClick={() => {
                    setFetchData(true)
                    setSchedulesPage(1)
                    setLoading(true)
                  }}
                  textButton={t('INPUT.SEARCH')}
                  style={{ height: '30px', marginTop: '5px' }}
                />
              </Col>

              <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                <UseStyled.DropdownDocument>
                  <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
                    {t('REGISTER.BUTTON.PRINT_DOCUMENT')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(showModal())
                        setExportType('Excel')
                      }}
                    >
                      {t('Excel')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(showModal())
                        setExportType('PDF')
                      }}
                    >
                      {t('PDF')}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </UseStyled.DropdownDocument>
              </Col>
            </Row>
          </Card.Title>
          <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
            <SchedulesList
              selectHeadCells={headCells.map((cell) => cell.id)}
              exportFilter={{
                header: (
                  <UseStyled.Styles className="pt-3">
                    <table style={{ border: 'none', borderCollapse: 'collapse' }}>
                      <tr>
                        <td style={{ border: 'none', textAlign: 'left', fontSize: '14px' }} colSpan={8}>
                          {t(`REPORT.REPORT_NAME.SCHEDULES_DOCTOR`)}
                        </td>
                      </tr>
                      <tr style={{ fontSize: '13px' }}>
                        <td style={{ border: 'none', marginRight: '5px' }}>{t('REPORT.FILTER.BRANCH')}: </td>
                        <td style={{ border: 'none', marginRight: '5px' }}>{_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}</td>
                        <td style={{ border: 'none', marginRight: '5px' }}>{t('REPORT.FILTER.DATE_START')} </td>
                        <td style={{ border: 'none', marginRight: '5px' }}>{rangeDate[0] !== 'Invalid date' ? dateToView(rangeDate[0]) : ''}</td>
                        <td style={{ border: 'none', marginRight: '5px' }}>{t('REPORT.FILTER.DATE_END')} </td>
                        <td style={{ border: 'none', marginRight: '5px' }}>{rangeDate[1] !== 'Invalid date' ? dateToView(rangeDate[1]) : ''}</td>
                        <td style={{ border: 'none', marginRight: '5px' }}>{t('REPORT.FILTER.DOCTOR')}:</td>
                        <td style={{ border: 'none', marginRight: '5px' }}>{t('REPORT.FILTER.APPOINTMENT_STATUS')}:</td>
                        <td style={{ border: 'none', marginRight: '5px' }}>{appointmentStatus === 'ALL' ? t('REPORT.REPORT_GROUP.ALL') : `${t(`APPOINTMENT.STATUS.${appointmentStatus}`)}`}</td>
                        <td style={{ border: 'none', marginRight: '5px' }}>
                          {_.isEmpty(selectDoctors)
                            ? ` ${t('REPORT.FILTER.NO_SELECT')}`
                            : selectDoctors.filter((i) => typeof i === 'number').length === doctors.length
                            ? `${t('REPORT.FILTER.ALL')}`
                            : `${setDoc(doctors.filter((d: any) => selectDoctors.includes(d.userId)))
                                ?.map((d: any) => d.fullname)
                                .slice(0, 3) // เเสดงเเค่ 3 คนเเรก
                                .join(', ')}`}
                        </td>

                        <td style={{ border: 'none', marginRight: '5px' }}>{renderOperativeType()}</td>
                        <td style={{ border: 'none', marginRight: '5px' }}>{renderOperative()}</td>
                      </tr>
                    </table>
                  </UseStyled.Styles>
                )
              }}
              page={schedulesPage}
              pageLimit={schedulesPageLimit}
              rowCount={rowCount}
              sortBy={sortBy}
              sortType={sortType}
              data={SchedulesData}
              onRequestSort={onRequestSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              componentRef={componentRef}
            />
          </Box>
          <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
            <SchedulesDoctorStaus
              date={rangeDate}
              page={1}
              pageLimit={schedulesPageLimit}
              rowCount={rowCount}
              sortBy={sortBy}
              sortType={sortType}
              data={SchedulesData}
              onRequestSort={onRequestSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
            />
          </Box>
        </Card.Body>
      </Card>
      <ModalCustom
        footerInline={true}
        title={`${t('REPORT.TITLE.EXPORT_PDF')} ${exportType}`}
        // size={'xl'}
        fullscreen={true}
        component={
          <>
            <Col
              sm={12}
              md={12}
              xl={12}
              className={'pt-2 px-1'}
              onClick={() => {
                setCheckDf(!checkDf)
                setSchedulesPage(1)
              }}
            >
              <div></div>
              <FilterSelect
                formControlClass={'filter filter-headCells'}
                formControlStyle={{ maxWidth: 'unset !important' }}
                multiple
                fullWidth={true}
                // style={{ position: 'fixed', top: 50, zIndex: 1 }}
                renderValue={(e) => {
                  if (selectHeadCells.includes('NULL') && selectHeadCells.length === 1) return `${t('REPORT.FILTER.COLUMN_EXPORT')}: ${t('REPORT.FILTER.NO_SELECT')}${t('REPORT.FILTER.COLUMN_EXPORT')}`
                  else if (_.includes(selectHeadCells, 'ALL')) return `${t('REPORT.FILTER.COLUMN_EXPORT')}:${t(`REPORT.FILTER.ALL`)}`
                  else {
                    return `${t('REPORT.FILTER.COLUMN_EXPORT')}:${headCells
                      .filter((d: any) => selectHeadCells.includes(d.id))
                      ?.map((d: any) => t(d.labelKey))
                      .join(', ')}`
                  }
                }}
                label={t('REPORT.FILTER.COLUMN_EXPORT')}
                selectId="select-headCells"
                value={selectHeadCells}
                labelId="label-headCells"
                options={[
                  <MenuItem
                    className="py-1"
                    key="ALL"
                    value="ALL"
                    onClick={() => {
                      if (!selectHeadCells.includes('ALL')) {
                        const simulatedEvent = {
                          target: {
                            value: [...selectHeadCells, 'ALL']
                          }
                        }
                        handleFilterHeadCells(simulatedEvent as React.ChangeEvent<{ value: unknown }>)
                      }
                    }}
                  >
                    <InputCheckbox value="ALL" checked={selectHeadCells.includes('ALL') || selectHeadCells.length === headCells.length} onChange={handleFilterHeadCells} />
                    {t(`REPORT.FILTER.ALL`)}
                  </MenuItem>,
                  ...groupedHeadCells.map((group, groupIndex) => (
                    <div key={`group-${groupIndex}`} style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                      {group.map((cell: any) => (
                        <div key={cell.id} style={{ flex: 1 }}>
                          <MenuItem className="py-1" key={cell.id} value={cell.id} onClick={() => handleToggleCheckbox(cell.id)}>
                            <InputCheckbox label={''} value={cell.id} checked={selectHeadCells.includes(cell.id)} onChange={() => handleToggleCheckbox(cell.id)} />
                            {t(cell.labelKey)}
                          </MenuItem>
                        </div>
                      ))}
                    </div>
                  ))
                ]}
              />
            </Col>
            <div style={{ height: '70vh', overflow: 'auto' }}>
              <SchedulesList
                selectHeadCells={selectHeadCells}
                exportFilter={{
                  header: (
                    <UseStyled.Styles className="pt-3">
                      <table style={{ border: 'none', borderCollapse: 'collapse' }}>
                        <tr>
                          <td style={{ border: 'none', textAlign: 'left', fontSize: '16px' }} colSpan={8}>
                            {t(`REPORT.REPORT_NAME.SCHEDULES_DOCTOR`)}
                          </td>
                        </tr>
                        <tr style={{ fontSize: '14px' }}>
                          <TableSortLabel>
                            <td style={{ border: 'none', marginRight: '5px', fontWeight: '400' }}>{t('REPORT.FILTER.BRANCH')}: </td>
                            <td style={{ border: 'none', marginRight: '5px' }}>{_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}</td>
                          </TableSortLabel>
                          <TableSortLabel>
                            <td style={{ border: 'none', marginRight: '5px', fontWeight: '400' }}>{t('REPORT.FILTER.DATE_START')} </td>
                            <td style={{ border: 'none', marginRight: '5px' }}>{rangeDate[0] !== 'Invalid date' ? dateToView(rangeDate[0]) : ''}</td>
                            <td style={{ border: 'none', marginRight: '5px', fontWeight: '400' }}>{t('REPORT.FILTER.DATE_END')} </td>
                            <td style={{ border: 'none', marginRight: '5px' }}>{rangeDate[1] !== 'Invalid date' ? dateToView(rangeDate[1]) : ''}</td>
                          </TableSortLabel>
                          <TableSortLabel>
                            <td style={{ border: 'none', marginRight: '5px', fontWeight: '400' }}>{t('REPORT.FILTER.APPOINTMENT_STATUS')}:</td>
                            <td style={{ border: 'none', marginRight: '5px' }}>{appointmentStatus === 'ALL' ? t('REPORT.REPORT_GROUP.ALL') : `${t(`APPOINTMENT.STATUS.${appointmentStatus}`)}`}</td>
                          </TableSortLabel>
                          <TableSortLabel>
                            <td style={{ border: 'none', marginRight: '5px', fontWeight: '400' }}>{t('REPORT.FILTER.DOCTOR')}:</td>
                            <td style={{ border: 'none', marginRight: '5px' }}>
                              {_.isEmpty(selectDoctors)
                                ? ` ${t('REPORT.FILTER.NO_SELECT')}`
                                : selectDoctors.filter((i) => typeof i === 'number').length === doctors.length
                                ? `${t('REPORT.FILTER.ALL')}`
                                : `${setDoc(doctors.filter((d: any) => selectDoctors.includes(d.userId)))
                                    ?.map((d: any) => d.fullname)
                                    .slice(0, 3) // เเสดงเเค่ 3 คนเเรก
                                    .join(', ')}`}
                            </td>
                          </TableSortLabel>
                          <TableSortLabel>
                            <td style={{ border: 'none', marginRight: '5px' }}>{renderOperativeType()}</td>{' '}
                          </TableSortLabel>
                          <TableSortLabel>
                            <td style={{ border: 'none', marginRight: '5px' }}>{renderOperative()}</td>{' '}
                          </TableSortLabel>
                          <tr />
                        </tr>
                      </table>
                    </UseStyled.Styles>
                  )
                }}
                page={schedulesPage}
                pageLimit={schedulesPageLimit}
                rowCount={rowCount}
                sortBy={sortBy}
                sortType={sortType}
                data={SchedulesData}
                onRequestSort={onRequestSort}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
                componentRef={componentRef}
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '20px',
                width: '100%'
              }}
            >
              <span></span>
              <div style={{ display: 'flex', gap: '2px' }}>
                <ButtonCustom startIcon={<CloseIcon />} textButton={t('LAB.BUTTON.CANCEL')} type="button" variant="outlined" className="btn-icon" onClick={() => dispatch(resetModal())} />
                {exportType === 'Excel' ? (
                  <ButtonCustom startIcon={<PrintIcon />} textButton={t('BUTTON.PRINT')} type="button" variant="outlined" className="btn-icon" onClick={onDownload} />
                ) : (
                  <FucntionPrint
                    content={() => componentRef.current}
                    docTitle={t('REPORT.REPORT_NAME.SCHEDULES_DOCTOR')}
                    trigger={() => <ButtonCustom startIcon={<PrintIcon />} textButton={t('BUTTON.PRINT')} type="button" variant="outlined" className="btn-icon" />}
                    style="@page { size: 1122.519685px 793.7007874px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }}"
                  />
                )}
              </div>
            </div>
          </>
        }
      />
      <Loading show={loading} type="fullLoading" />
    </div>
  )
}
