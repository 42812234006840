import { useState, useEffect, useCallback } from 'react'
import { CloseButton, Col, Dropdown, Row } from 'react-bootstrap'
import { Grid, Box, Typography, Divider, Avatar, TableRow, TableCell, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import IndexSummaryTreatmentEmr from 'features/treatments/SummaryTreatmentEmr/IndexEmr'
import { getBranch, memberTypeOutline, checkPackage } from 'utils/app.utils'
import Button from '@mui/material/Button'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useDispatch, useSelector } from 'react-redux'
import { selectMe } from 'app/slice/user.slice'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { fileUrl, clinicTheme } from 'api/api'
import AddBoxIcon from '@mui/icons-material/AddBox'
import * as UseStyled from 'features/historyTreatments/MainMenu/OPD/UseStyle'
import { PACKAGE_ADDONS } from 'constants/package'

/** API */
import TreatmentHistoryApi from 'api/dentists/treatment-history.api'
import TreatmentApi from 'api/dentists/treatment.api'
import ClinicApi from 'api/master/clinic.api'
import ComponentApi, { Bookmark } from 'api/components-api'

/** ROUTE */
import { routeName } from 'routes/routes-name'

/** OTHER */
import { Icons, imgs } from 'constants/images'
import { colors, sidebarIcon } from 'constants/theme'
import { dateTimeToView, dateToView, timeToView } from 'utils/date.utils'
import useWindowSize from 'utils/useWindowSize'
import LabelRanking from 'component/LabelRanking'
import 'features/treatments/style.css'
import HeaderCard from 'component/CardCustom/HeaderCard'
import { ButtonToothChart, ButtonTreatmentOverview, FragmentToothChart, TxStyled } from './MainMenu/OPD/style/TxUseStyle'
import ToothChart from 'component/ToothChart/ToothChart'
import TeethAdult from 'assets/toothChart/type-teeth/teethAdult'
import TeethChild from 'assets/toothChart/type-teeth/teethChild'
import ButtonCustom from 'component/Button/ButtonCustom'
import * as modalSlice from 'app/slice/modal.slice'
import { resetModal, showModal } from 'app/slice/modal.slice'
import { TreatmentStateInterface, TreatmentStateType, setDiagnosisByKey, setEditTootChart, setEditTootChartType, setSubmitTootChart, treatment } from 'app/slice/treatment.slice'
import PopupEmrOverview from 'component/Treatments/PopupEmrOverview/PopupEmrOverview'
import IndexSummaryTreatment from './SummaryTreatment/Index'
import { TABS_NAME } from './treatmentsIndex'
import domtoimage from 'dom-to-image'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { swalWarning } from 'component/Alert/Swal'
import TreatmentHistoryManagementApi from 'api/dentists/treatment-history-management.api'
import { IconAddBox, IconBookmark } from './MainMenu/OPD/style/UseStyle'
import DiagnosisApi, { DxRecommendDataInterface } from 'api/setting/treatments/diagnosis.api'
import TableCustom from 'features/treatments/MainMenu/OPD/Table/TableCustom'
import { ButtonType, NavBtn } from './MainMenu/OPD/Dx'
import PopupAddDx from 'component/Treatments/PopupAddDx'
import IconBack from 'assets/icon/iconBack'
import PopupFollowTreatment from 'component/HistoryTreatments/PopupFollowTreatment'
import { PrintReceiptInterface } from 'api/counter/payment/payment-interface'
import PaymentApi from 'api/counter/payment/payment.api'
import Loading from 'component/Loading'
import AboutUs from 'api/admin/clinic-manage/about.api'
import HistoryTreatmentApi, { AllTreatmentHistory } from 'api/historyTreatment/historyTreatmentApi'
import MedicalCertificate from 'component/Treatments/MedicalCertificate'
import PopupTreatmentConsent from 'component/Treatments/popupTreatmentConsent'
import SSO from 'component/Treatments/SSO'
import MedicalCertificate_A5 from 'component/Treatments/MedicalCertificate_A5'
import MedicalCertificateFiveDisease from 'component/Treatments/PopupMedicalCertificateFiveDisease'
import MedicalCertificateDrivingLicense from 'component/Treatments/MedicalCertificateDrivingLicense'
import ReferralExamination from 'component/Treatments/Referral-examination'

export const TableWidth = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    tableLayout: 'fixed',
    minWidth: '100%',
    '.MuiTableHead-root': {
      '.MuiTableCell-head': {
        '&:nth-of-type(1)': {
          width: '5%'
        },
        '&:nth-of-type(2)': {
          width: '10%'
        },
        '&:nth-of-type(3)': {
          width: '70%'
        },
        '&:nth-of-type(4)': {
          width: '15%'
        },
        [theme.breakpoints.down('sm')]: {
          '&:first-of-type': {
            width: '7%',
            fontSize: 0
          },
          '&:nth-of-type(2)': {
            width: 'auto',
            fontSize: 0
          },
          '&:nth-of-type(3)': {
            width: 'auto',
            fontSize: 0
          },
          '&:last-of-type': {
            width: 90
          }
        }
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-body': {
        padding: '8px',
        verticalAlign: 'middle',
        '&:first-of-type': {
          paddingLeft: 4,
          paddingRight: 4
        },
        [theme.breakpoints.down('sm')]: {
          verticalAlign: 'top'
        }
      }
    }
  },
  '.MuiTablePagination-selectLabel, .MuiTablePagination-input': {
    display: 'none'
  },
  '&.disabled': {
    '.MuiTable-root': {
      '.MuiButton-root, .MuiIconButton-root': {
        backgroundColor: `${colors.white} !important`,
        span: {
          color: `${colors.disabledGray} !important`
        },
        path: {
          fill: `${colors.disabledGray} !important`
        }
      }
    },
    '.MuiTablePagination-root': {
      '.MuiButtonBase-root': {
        color: `${colors.disabledGray} !important`,
        borderColor: `${colors.disabledGray} !important`,
        backgroundColor: `${colors.white} !important`,
        '&.active ': {
          color: `${colors.white} !important`,
          backgroundColor: `${colors.disabledGray} !important`
        }
      }
    }
  }
}))

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 150,
  height: 150,
  maxWidth: 150,
  maxHeight: 150,
  [theme.breakpoints.up('xs')]: {
    width: 56,
    height: 56
  }
}))

export const Item = styled(Button)(({ theme }) => ({
  height: 32,
  width: '100%',
  justifyContent: 'start',
  border: 'none',
  boxShadow: 'none',
  padding: '0 1rem',
  margin: '0.25rem 0',
  color: colors.textPrimary,
  fontSize: 16,
  '&:hover, &.active': {
    backgroundColor: colors.themeSecondColor06,
    color: colors.themeSecondColor
  }
}))

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: 'none',

  marginBottom: '1.5rem !important',
  '&:first-of-type': {
    marginTop: '1rem !important'
  },
  '&:before': {
    opacity: 0
  },
  '.header-text': {
    h5: {
      fontSize: 18,
      fontWeight: 500,
      '&.required::after': {
        color: colors.statusInactive
      }
    }
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    '&:first-of-type': {
      marginTop: '1.5rem !important'
    }
  }
}))

const Head = styled(`div`)(({ theme }) => ({
  border: 'none',
  marginBottom: '1.5rem !important',
  '&:first-of-type': {
    marginTop: '1rem !important'
  },
  '&:before': {
    opacity: 0
  },
  '.header-text': {
    h5: {
      fontSize: 18,
      fontWeight: 500,
      '&.required::after': {
        color: colors.statusInactive
      }
    }
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    '&:first-of-type': {
      marginTop: '1.5rem !important'
    }
  }
}))

export const StyleToothChart = styled(`div`)(({ theme }) => ({
  width: '100%',
  justifyContent: 'center',
  [theme.breakpoints.down(1280)]: {
    maxWidth: 960
  },
  [theme.breakpoints.down(960)]: {
    maxWidth: 768
  }
}))

export const ImgToothChart = styled(`img`)(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  [theme.breakpoints.down(1600)]: {
    maxWidth: 800
  },
  [theme.breakpoints.down(1280)]: {
    maxWidth: 664
  },
  [theme.breakpoints.down(960)]: {
    maxWidth: 371
  },
  [theme.breakpoints.down(420)]: {
    maxWidth: 267
  }
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ backgroundColor: colors.white, border: `0.5px solid ${colors.textLightGray}`, borderRadius: '50px', color: colors.themeMainColor }} />} {...props} />
))(({ theme }) => ({
  height: 40,
  backgroundColor: colors.themeSecondColor10,
  '&.error': {
    backgroundColor: colors.statusInactive10
  },
  '.MuiAccordionSummary-content': {
    margin: 0
  }
}))

const Detail = styled(`div`)(({ theme }) => ({
  height: 48,
  backgroundColor: colors.themeSecondColor10,
  '&.error': {
    backgroundColor: colors.statusInactive10
  },
  '.MuiAccordionSummary-content': {
    margin: 0
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  paddingTop: 0,
  paddingBottom: 0,
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 0
  },
  '.MuiGrid-container': {
    margin: 0,
    width: '100%',
    '.MuiGrid-item': {
      width: '100%'
    }
  },
  '.input-group': {
    '.input-group-text': {
      '& + .MuiTextField-root': {
        fieldset: {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeft: 'none'
        }
      }
    },
    '&.input-group-cn': {
      '> span': {
        position: 'absolute',
        top: '50%',
        left: '1rem',
        transform: 'translateY(-50%)',
        fontSize: 16,
        color: colors.black60,
        zIndex: 1
      },
      '.MuiTextField-root': {
        marginLeft: '0 !important'
      },
      '.MuiInputBase-input': {
        paddingLeft: '2.5rem'
      }
    },
    '&.input-group-date': {
      '.ant-picker': {
        top: 5,
        transform: 'unset'
      }
    },
    '&.input-group-idCard, &.input-group-date': {
      '.MuiFormHelperText-root.Mui-error': {
        whiteSpace: 'nowrap'
      }
    }
  },
  '.MuiIconButton-root': {
    '&.Mui-disabled': {
      path: {
        fill: colors.disabledGray
      }
    }
  },
  '.btn-parent-remove': {
    paddingTop: '0.875rem',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0.5rem'
    }
  }
}))

const BoxInfo = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  wordBreak: 'break-word'
}))

const FlexBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItemsCenter: 'center',
  paddingBottom: 2,
  p: {
    '&:first-of-type': {
      paddingRight: 8,
      width: 'max-content',
      [theme.breakpoints.up('xs')]: {
        width: 210
      },
      [theme.breakpoints.up('lg')]: {
        width: 'max-content'
      }
    },
    '&:last-of-type': {
      flex: 1
    }
  }
}))

const Typo = styled(Typography)(({ theme }) => ({
  color: colors.textPrimary
}))

const ProfileAvatarMb = styled(Avatar)(({ theme }) => ({
  width: 49,
  height: 49,
  [theme.breakpoints.up('lg')]: {
    width: 56,
    height: 56
  }
}))

export const CardProfile = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  padding: '10px 16px',
  height: 80,
  borderRadius: 6,
  boxShadow: `0 3px 6px 0 ${colors.black10}`,
  border: `1px solid ${colors.extraLightGray}`,
  backgroundColor: colors.white,
  [theme.breakpoints.down('sm')]: {
    padding: '8px 16px',
    height: 122
  }
}))

type ConfigModal = {
  type?: '' | 'FollowTreatment' | 'MedicalCertificate' | 'treatmentConsent' | 'MedicalServices' | 'MedicalDrivingLicense' | 'MedicalFiveDisease' | 'MedicalCertificate_A5'
  patientId?: number
  patientCaseId?: number
  vnNumber?: string
  createdAt?: string
  treatments?: any
  diagnosis?: any
  servicePointId?: number
}

type HistoryType = {
  type?: 'Treatment' | 'HistoryTreatment' | 'RetrospectiveEdit' | 'RetrospectiveOrder'
  patientId: number
  disabled?: boolean
}

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}

type SideBar = {
  isOpen?: boolean | false
  visit?: any
  patient?: any
  ToggleSidebar?: (val: any) => void
  vnActive?: any
  handleChangeTreatment?: any
}

export default function EmrHistory(props: HistoryType) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const queryParams = new URLSearchParams(window.location.search)
  const [patientId, setPatientId] = useState<any>(queryParams.get('patientId'))
  const [configModal, setConfigModal] = useState<ConfigModal>({})
  const [type, setType] = useState<any>(queryParams.get('type'))
  const [patient, setPatient]: any[] = useState([])
  const [visit, setVisit]: any[] = useState([])
  const [data, setData]: any[] = useState([])
  const [activeFooterTab, setActiveFooterTab] = useState(0)
  const footerTabs: string[] = ['TREATMENTS.FOOTER_TAB.SUMMARY_TREATMENT', 'TREATMENTS.FOOTER_TAB.DIAGNOSIS', 'TREATMENTS.FOOTER_TAB.RECOMMEND']
  const [doctors, setDoctors] = useState<Doctors[]>([])
  const [vnActive, setVnActive] = useState<string | number>(0)
  const [vnDate, setVnDate] = useState('')
  const [imgToothChart, setImgToothChart] = useState('')
  const user = useSelector(selectMe)
  const [width, height] = useWindowSize()
  const [isOpen, setIsopen] = useState(false)
  const [isShowPopupEmrOverview, setIsShowPopupEmrOverview] = useState<boolean>(false)
  const [teethType, setTeethType] = useState('ADULT')
  const [activeTab] = useState<TABS_NAME>(TABS_NAME.OPD)
  const [loading, setLoading] = useState(false)
  const [importantSymptoms, setImportantSymptoms] = useState('')
  const [importantSymptomsDoctor, setImportantSymptomsDoctor] = useState('')
  const [servicePointId] = useState(0)
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const [diagnosis, setDiagnosis] = useState([])
  const [isShowPopupRecommend, setIsShowPopupRecommend] = useState<{ show: boolean; data: DxRecommendDataInterface[]; dx: any }>({ show: false, data: [], dx: {} })
  const [operativeTypes, setOperativeTypes] = useState([])
  const [typeSelected, setTypeSelected] = useState('All')
  const exportMedicalCertificate = false
  const [exportReferralExamination, setExportReferralExamination] = useState(false)
  const [caseDetail, setCaseDetail] = useState<any>()

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [search] = useState('')
  const [isBookmark, setIsBookmark] = useState(0)
  const [typeId, setTypeId] = useState(0)

  const branchId = getBranch()
  const [branch, setBranch] = useState<any>()

  const loadBranchInfo = () => {
    AboutUs.detailClinic(Number(branchId))
      .then(({ data }) => {
        setBranch(data)
      })
      .catch((e) => { return })
  }

  useEffect(() => {
    loadBranchInfo()
  }, [branchId])

  const loadVn = async () => {
    if (patientId) {
      const visitData = await TreatmentHistoryApi.findTreatmentHistoryVisit(Number(patientId))
      if (visitData.status === 200 && !_.isEmpty(visitData.data)) {
        const visits = _.sortBy(visitData.data, ['createdAt']).reverse()
        setVisit(visits)
        setVnActive(Number(queryParams.get('patientCaseId')) || visits[0].patientCaseId)
        setVnDate(visits[0].createdAt)
      } else setVisit([])
    }
  }

  const loadEmr = async () => {
    if (patientId) {
      const patientData = await TreatmentHistoryApi.findTreatmentHistoryEmr(Number(patientId), Number(vnActive))
      if (patientData.status === 200 && !_.isEmpty(patientData.data)) {
        setData(patientData.data)
        if (patientData.data.patientCaseInfo?.toothChart) {
          const img = patientData.data.patientCaseInfo.toothChart
          setImgToothChart(`${fileUrl}/${img.filePath}/${img.fileName}`)
        }
      } else setData([])
    }
  }

  const loadHistoryEmr = async () => {
    const condition: AllTreatmentHistory = {
      patientId: Number(patientId),
      page: page,
      pageLimit: pageLimit,
      patientCaseId: String(vnActive)
    }
    if (patientId) {
      const res = await HistoryTreatmentApi.findAllTreatmentHistory(Number(patientId), condition)
      if (res.status === 200) {
        setCaseDetail(res.data[0])
      }
    }
  }

  const loadPatient = async () => {
    if (patientId) {
      const patientData = await TreatmentApi.findPatient(Number(patientId))
      if (patientData.status === 200 && !_.isEmpty(patientData.data)) {
        setPatient(patientData.data)
        setImportantSymptoms(patientData.data.importantSymptoms)
        setImportantSymptomsDoctor(patientData.data.importantSymptomsDoctor || '')
      } else setPatient([])
    }
  }

  const loadData = useCallback(async () => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    condition = { ...condition, userId: 0 }
    if (isBookmark) condition = { ...condition, isBookmark: isBookmark }
    if (typeId) condition = { ...condition, typeId: typeSelected || typeId }
    if (user.userId) condition = { ...condition, userId: user.userId }

    const res = await TreatmentApi.findAllDiagnosis(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setDiagnosis(res.data)
    }
  }, [page, pageLimit, search, isBookmark, typeId, typeSelected])

  const loadDataOperativeType = useCallback(async () => {
    const res = await TreatmentApi.findAllDiagnosisType()
    if (res.status === 200) {
      setOperativeTypes(res.data)
    }
  }, [])

  useEffect(() => {
    if (props.patientId) {
      setPatientId(props.patientId)
    } else {
      setPatientId(queryParams.get('patientId'))
    }

    if (props.type) {
      setType(props.type)
    } else {
      setType(queryParams.get('type'))
    }
  }, [])

  const onTreatmentConsent = (patientCaseId: number, servicePointId: number) => {
    setConfigModal({ type: 'treatmentConsent', patientCaseId: patientCaseId, servicePointId: servicePointId })
    dispatch(modalSlice.showModal())
  }

  const onMedicalCertificate = (patientId: number, patientCaseId: number, createdAt: string, treatments: any, diagnosis: any) => {
    setConfigModal({ type: 'MedicalCertificate', patientId: patientId, patientCaseId: patientCaseId, createdAt: createdAt, treatments: treatments, diagnosis: diagnosis })
    dispatch(modalSlice.showModal())
  }

  const onSSO = (patientId: number, patientCaseId: number, createdAt: string, treatments: any, diagnosis: any) => {
    setConfigModal({ type: 'MedicalServices', patientId: patientId, patientCaseId: patientCaseId, createdAt: createdAt, treatments: treatments, diagnosis: diagnosis })
    dispatch(modalSlice.showModal())
  }

  const onMedicalFiveDisease = (patientId: number, patientCaseId: number, createdAt: string, treatments: any, diagnosis: any) => {
    setConfigModal({ type: 'MedicalFiveDisease', patientId: patientId, patientCaseId: patientCaseId, createdAt: createdAt, treatments: treatments, diagnosis: diagnosis })
    dispatch(modalSlice.showModal())
  }
  const onMedicalDrivingLicense = (patientId: number, patientCaseId: number, createdAt: string, treatments: any, diagnosis: any) => {
    setConfigModal({ type: 'MedicalDrivingLicense', patientId: patientId, patientCaseId: patientCaseId, createdAt: createdAt, treatments: treatments, diagnosis: diagnosis })
    dispatch(modalSlice.showModal())
  }

  const onMedicalCertificate_A5 = (patientId: number, patientDiagnosis: any) => {
    dispatch(setDiagnosisByKey({ key: 'patientDiagnosis', value: patientDiagnosis }))
    setConfigModal({ type: 'MedicalCertificate_A5', patientId: patientId })
    dispatch(modalSlice.showModal())
  }

  const onFollowTreatment = (patientId: number, patientCaseId: number, vnNumber: string) => {
    setConfigModal({ type: 'FollowTreatment', patientId: patientId, patientCaseId: patientCaseId, vnNumber: vnNumber })
    dispatch(modalSlice.showModal())
  }

  const handleRoute = (route: 'treatmentHistory' | 'paymentHistory' | 'lab' | 'x-ray') => {
    if (route === 'treatmentHistory') {
      window.location.href = `${routeName.treatmentsHistory}?patientId=${patientId}`
    } else if (route === 'paymentHistory') {
      window.location.href = routeName.payment
    } else if (route === 'lab') {
      window.location.href = routeName.labOrder
    } else if (route === 'x-ray') {
      window.location.href = routeName.xray
    }
  }
  const handleBookmark = async (id: number, bookmarkId: number) => {
    const payload: Bookmark = {
      bookmarkType: 'DIAGNOSIS',
      userId: user.userId,
      itemId: id,
      isBookmark: bookmarkId ? '0' : '1'
    }
    const resp = await ComponentApi.bookmark(payload)
    if (resp.status === 201) {
      loadData()
    }
  }

  const handleAddDx = (valueDx: any) => {
    const item = { ...valueDx, diagnosisCode: valueDx.diagnosisCode, diagnosisName: valueDx.diagnosisName, doctorId: _.get(treatments.patientDiagnosis[0], 'pinDoctorId') || user.userId }
    DiagnosisApi.findById(valueDx.diagnosisId)
      .then(({ data }: any) => {
        if (!_.isEmpty(data.diagnosisRecommends)) {
          setIsShowPopupRecommend({ show: true, data: data.diagnosisRecommends, dx: item })
          dispatch(showModal())
        } else {
          dispatch(setDiagnosisByKey({ key: 'patientDiagnosis', value: [...treatments.patientDiagnosis, item] }))
          dispatch(setSubmitTootChart(true))
        }
      })
      .catch((e) => {
        return
      })
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeType = (ele: any) => {
    const type = ele.currentTarget.getAttribute('data-value')
    setTypeSelected(type)
    setIsBookmark(0)
    setTypeId(0)
    setPage(1)
    if (type === 'BOOKMARK') setIsBookmark(1)
    else setTypeId(parseInt(type))
  }

  useEffect(() => {
    if (vnActive !== 0) {
      loadEmr()
      loadHistoryEmr()
    }
  }, [vnActive])

  useEffect(() => {
    loadData()
  }, [page, pageLimit, search, isBookmark, typeId, typeSelected])

  useEffect(() => {
    loadVn()
    loadPatient()
    loadDoctor()
    loadDataOperativeType()
  }, [patientId, typeSelected])

  const loadDoctor = async () => {
    const resp = await ClinicApi.findAllDoctors()
    if (resp.status === 200) setDoctors(resp.data)
  }
  const renderImage = (img: any) => {
    if (!_.isEmpty(img) && !_.isEmpty(img.filePath) && !_.isEmpty(img.fileName)) return `${fileUrl}/${img.filePath}/${img.fileName}`
    else return imgs.defaultAvatar
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'dxId', disablePadding: false, label: t('TREATMENTS_DX.TABLE.CELL.DX_ID') },
    { id: 'diagnosis', disablePadding: false, label: t('TREATMENTS_DX.TABLE.CELL.DIAGNOSIS') },
    { id: 'action', disablePadding: false, label: t('TREATMENTS_DX.TABLE.CELL.ACTION') }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { diagnosisId, diagnosisCode, diagnosisName, bookmarkId } = objData

    return (
      <TableRow key={diagnosisId}>
        <TableCell align="center" scope="row">
          {no}
        </TableCell>
        {(width >= 768 && (
          <>
            <TableCell align="left" scope="row">
              {diagnosisCode}
            </TableCell>
            <TableCell align="left" scope="row">
              {diagnosisName}
            </TableCell>
            <TableCell align="center" scope="row">
              <IconBookmark key={diagnosisId} className="p-0 ml-1" onClick={() => handleBookmark(diagnosisId, bookmarkId)} sx={{ backgroundColor: 'transparent !important' }}>
                {bookmarkId ? <Icons.StarActive width="24" height="24" /> : <Icons.StarInActive width="24" height="24" />}
              </IconBookmark>
              <IconAddBox key={diagnosisId} disabled={_.some(treatments.patientDiagnosis, { diagnosisId: diagnosisId })} className="p-0 ml-1" onClick={() => handleAddDx(objData)} sx={{ backgroundColor: 'transparent !important' }}>
                <AddBoxIcon style={{ width: 30, height: 30 }} />
              </IconAddBox>
            </TableCell>
          </>
        )) || (
            <>
              <TableCell align="left" scope="row" colSpan={3}>
                <Box className="d-flex justify-content-between">
                  <Box>
                    <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                      {t('TREATMENTS_DX.TABLE.CELL.DX_ID')}
                    </Typography>
                    <Typography className="d-inline">{diagnosisCode}</Typography>
                  </Box>
                  <Box>
                    <IconBookmark key={diagnosisId} className="p-0 ml-1" onClick={() => handleBookmark(diagnosisId, bookmarkId)} sx={{ backgroundColor: 'transparent !important' }}>
                      {bookmarkId ? <Icons.StarActive width="24" height="24" /> : <Icons.StarInActive width="24" height="24" />}
                    </IconBookmark>
                    <IconAddBox key={diagnosisId} className="p-0 ml-1" disabled={_.some(treatments.patientDiagnosis, { diagnosisId: diagnosisId })} onClick={() => handleAddDx(objData)} sx={{ backgroundColor: 'transparent !important' }}>
                      <AddBoxIcon style={{ width: 30, height: 30 }} />
                    </IconAddBox>
                  </Box>
                </Box>
                <Box>
                  <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                    {t('TREATMENTS_DX.TABLE.CELL.DIAGNOSIS')}
                  </Typography>
                  <Typography className="d-inline">{diagnosisName}</Typography>
                </Box>
              </TableCell>
            </>
          )}
      </TableRow>
    )
  }

  const handleChangeTreatment = (vn: any) => {
    setVnActive(vn.patientCaseId)
    setVnDate(vn.createdAt)
  }

  const onDocument = async (type: 'A5' | 'A5_ORI' | 'A5_COPY' | 'A5_EN' | 'A5_EN_ORI' | 'A5_EN_COPY' | 'A5_LANDSCAPE' | 'A5_LANDSCAPE_ORI' | 'A5_LANDSCAPE_COPY' | 'A4' | 'A4_ORI' | 'A4_COPY' | 'THERMAL') => {
    const paymentId = caseDetail.order?.payments?.orderPaymentId
    const printProp: PrintReceiptInterface = { lang: 'TH', type: 'FULL' }
    let receipt: any = ''
    setLoading(true)
    if (type === 'THERMAL') receipt = await PaymentApi.printThermal(paymentId, printProp)
    else if (type === 'A5' || type === 'A5_ORI' || type === 'A5_COPY' || type === 'A5_EN' || type === 'A5_EN_ORI' || type === 'A5_EN_COPY') {
      if (type === 'A5_ORI' || type === 'A5_EN_ORI') printProp.type = 'ORIGINAL'
      else if (type === 'A5_COPY' || type === 'A5_EN_COPY') printProp.type = 'COPY'

      if (type === 'A5_EN' || type === 'A5_EN_ORI' || type === 'A5_EN_COPY') printProp.lang = 'EN'

      receipt = await PaymentApi.printA5(paymentId, printProp)
    } else if (type === 'A5_LANDSCAPE' || type === 'A5_LANDSCAPE_ORI' || type === 'A5_LANDSCAPE_COPY') {
      printProp.orientation = 'LANDSCAPE'
      if (type === 'A5_LANDSCAPE_ORI') printProp.type = 'ORIGINAL'
      else if (type === 'A5_LANDSCAPE_COPY') printProp.type = 'COPY'
      receipt = await PaymentApi.printA5(paymentId, printProp)
    } else if (type === 'A4' || type === 'A4_ORI' || type === 'A4_COPY') {
      if (type === 'A4_ORI') printProp.type = 'ORIGINAL'
      else if (type === 'A4_COPY') printProp.type = 'COPY'
      receipt = await PaymentApi.printA4(paymentId, printProp)
    }
    setLoading(false)
    if (receipt && receipt.status === 200) return window.open(`${fileUrl}/${receipt.data}`, '_blank')
    return false
  }



  const onViewEmrOverview = () => {
    dispatch(showModal())
    dispatch(setEditTootChart(null))
    dispatch(setEditTootChartType(''))
    setIsShowPopupEmrOverview(true)
  }
  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true)
  }

  const handleEditEmr = (type: 'save' | 'cancel') => {
    if (type === 'save') {
      onSaveSummary('save')
    } else {
      loadEmr()
      loadHistoryEmr()
    }
  }
  const onHandleSaveToothChart = async () => {
    const toothChartFile = {
      patientFileId: data?.toothChart?.patientFileId || null,
      base64: '',
      fileExtension: 'png'
    }
    let node: any = ''
    if (data.teethType === 'ADULT') {
      node = document.getElementById('tooth-chart')
    } else {
      node = document.getElementById('tooth-chart-child')
    }
    if (node) {
      let base64 = await domtoimage.toPng(node)
      if (base64) {
        const re = 'data:image/png;base64,'
        base64 = base64.replace(re, "'")
        toothChartFile.base64 = base64
      }
    }

    return toothChartFile
  }

  const onSaveSummary = async (
    type:
      | 'save'
      | 'sendExam'
      | 'exportMedicalCertificate'
      | 'complete'
      | 'treatmentConsent'
      | 'onExportMedicalServices'
      | 'onExportReferralExamination'
      | 'onExportReferralDental'
      | 'onExportMedicalCertificate_A5'
      | 'saveToLab'
      | 'selectStickerProduct'
      | 'exportMedicalCertificateFiveDisease'
      | 'exportMedicalCertificateDrivingLisense'
  ) => {
    // ติดปัญหาที่ API รับค่าเกินกว่าที่ต้องการ
    setLoading(true)
    const summeryTreatments: any = []
    const summeryProducts: any = []
    const summeryDiagnosis: any = []
    const summeryRecommends: any = []

    _.map(treatments.summeryTreatments, (i: any, index: number) => {
      if (i.operativeTypeId) {
        summeryTreatments.push({
          orderTreatmentId: i.orderTreatmentId,
          orderId: i.orderId,
          tempOrderTreatmentId: i.tempOrderTreatmentId || null,
          teeth: i?.teeth || null,
          teethJson: i?.teethJson || null,
          operativeId: i.operativeId || 0,
          qty: Number(i.qty) || 0,
          price: Number(i.price) || 0,
          doctorId: _.some(doctors, { userId: i.doctorId }) ? i.doctorId : 0,
          discount: Number(i.discount) || 0,
          discountUnit: i.discountUnit || '',
          additional: Number(i.additional) || 0,
          additionalUnit: i.additionalUnit,
          dfAmount: i.dfAmount || i.cfAmount ? Number(i.dfAmount) : null,
          dfUnit: i.dfUnit || null,
          dfDiscountAmount: Number(i.dfDiscountAmount) || 0,
          dfDiscountUnit: i.dfDiscountUnit || '',
          dfAmountNet: Number(i.dfAmountNet) || 0,
          cfAmount: i.dfAmount || i.cfAmount ? Number(i.cfAmount) : null,
          cfDiscountAmount: Number(i.cfDiscountAmount) || 0,
          cfDiscountUnit: i.cfDiscountUnit || '',
          cfAmountNet: Number(i.cfAmountNet) || 0,
          dfRemark: i.dfRemark || '',
          clinicFeeId: Number(i.clinicFeeId) || null,
          total: Number(i.total) || 0,
          note: i.note || '',
          noteNextVisit: i.noteNextVisit || '',
          refType: i.refType || null,
          refId: i.refId || 0,
          nextVisit: i.nextVisit || null
        })
      }

      if (!i.operativeTypeId) {
        summeryProducts.push({
          orderProductId: i.orderProductId,
          orderId: i.orderId,
          tempOrderProductId: i.tempOrderProductId || null,
          inventoryId: i.inventoryId,
          group: i.group,
          qty: Number(i.qty),
          price: Number(i.price),
          doctorId: _.some(doctors, { userId: i.doctorId }) ? i.doctorId : 0,
          discount: Number(i.discount) || 0,
          discountUnit: i.discountUnit || 'BAHT',
          additional: Number(i.additional) || 0,
          additionalUnit: i.additionalUnit || 'BAHT',
          note: i.note || '',
          refType: i.refType || null,
          refId: i.refId || 0,
          total: Number(i.total) || 0
        })
      }
    })

    _.map(treatments.patientDiagnosis, (i: any, index: number) => {
      summeryDiagnosis.push({
        patientDiagnosisId: i.patientDiagnosisId || null,
        diagnosisId: Number(i.diagnosisId) || 0,
        doctorId: _.some(doctors, { userId: Number(i.doctorId) }) ? Number(i.doctorId) : 0,
        note: i.note || '',
        teeth: i?.teeth || null,
        teethJson: i?.teethJson || null,
        teethType: i?.teethType || data?.teethType || 'ADULT'
      })
    })

    _.map(treatments.patientRecommends, (i: any, index: number) => {
      summeryRecommends.push({
        patientRecommendId: i.patientRecommendId || null,
        recommendId: i.recommendId
      })
    })

    const summary: TreatmentStateType = {
      importantSymptoms: importantSymptoms || '',
      importantSymptomsDoctor: importantSymptomsDoctor || '',
      toothChart: await onHandleSaveToothChart(),
      teethType: treatments?.teethType || 'ADULT',
      servicePointId: servicePointId,
      summeryTreatments: summeryTreatments,
      summeryProducts: summeryProducts,
      patientDiagnosis: summeryDiagnosis,
      patientRecommends: summeryRecommends
    }

    if (_.some(summary.summeryTreatments, { qty: 0 }) || _.some(summary.summeryProducts, { qty: 0 })) {
      setLoading(false)
      return notiError(t(`TREATMENT_SUMMARY.MESSAGE.${_.some(summary.summeryTreatments, { qty: 0 }) ? 'NO_OPERATIVE_QTY' : 'NO_PRODUCT_QTY'}`), '', null, null, 'register-page')
    }

    if (_.some(summary.summeryTreatments, { doctorId: 0 })) {
      setLoading(false)
      return notiError(t('TREATMENT_SUMMARY.MESSAGE.NO_DOCTOR_IN_TREATMENT'), '', null, null, 'register-page')
    }

    if (_.some(summary.summeryProducts, { group: 'MEDICINE', doctorId: 0 })) {
      setLoading(false)
      return notiError(t('TREATMENT_SUMMARY.MESSAGE.NO_DOCTOR_IN_MEDICINE'), '', null, null, 'register-page')
    }

    if (_.some(summary.patientDiagnosis, { doctorId: 0 })) {
      setLoading(false)
      return notiError(t('TREATMENT_SUMMARY.MESSAGE.NO_DOCTOR_IN_DIAGNOSIS'), '', null, null, 'register-page')
    }

    if (type === 'treatmentConsent' && _.isEmpty(summary.patientDiagnosis) && _.isEmpty(summary.summeryProducts) && _.isEmpty(summary.summeryTreatments)) {
      setLoading(false)
      return swalWarning(t('TREATMENT_SUMMARY.MESSAGE.PLS_SELECT_TREATMENT'), '', null)
    }

    const resp = await TreatmentHistoryManagementApi.updateTreatmentHistoryEmr(Number(patientId), Number(vnActive), summary).finally(() => setLoading(false))

    if (resp.status === 200) {
      loadEmr()
      return notiSuccess(t('TREATMENT_SUMMARY.MESSAGE.SUCCESS.SAVE'), '', null, null, 'register-page')
    } else {
      return notiError(resp.response.data.message, '', null, null, 'register-page')
    }
  }

  return (
    <div style={{ overflowY: 'hidden', height: height - 100 }}>
      <Loading show={loading} type="fullLoading" />
      {type === 'RetrospectiveEdit' && <HeaderCard text={t('RETROSPECTIVE_EDIT.HEADER')} />}
      {width <= 425 && (
        <div className="p-3 " style={{ boxShadow: `0px 3px 6px ${colors.black08}` }}>
          <h5>{type === 'RetrospectiveEdit' ? t('RETROSPECTIVE_EDIT.HEADER') : t('EMR.TITLE.DEITAL')}</h5>
        </div>
      )}
      <div className="pt-3 pb-3 h-100">
        <Row className="mx-0 h-100">
          {width > 425 && (
            <Col className="col-2 px-1" style={{ minWidth: 240, borderRight: `1px solid ${colors.lightGray}`, height: height }}>
              <div className="align-content-center flex-center">
                <ProfileAvatar alt="profile" src={renderImage(patient.profile)} style={{ height: '200px', width: '200px' }} sx={{ border: `3px solid ${memberTypeOutline(patient.rank)}`, backgroundColor: colors.white }}>
                  <img src={imgs.defaultAvatar} alt="" className="w-100 h-100" />
                </ProfileAvatar>
              </div>
              <div className="align-content-center flex-center pt-4" style={{ fontWeight: 500 }}>
                {patient.firstname} {patient.lastname} {patient.displayName && (`(${patient.displayName})`)}
              </div>
              <div className={`align-content-center flex-center text-muted pb-3`}>{patient?.branchCnNumber ? `${patient?.branchCnNumber}` : ''}</div>
              <Divider className="mx-0 mt-0" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
              <div className="pt-3" style={{ fontWeight: 500, paddingLeft: '16px' }}>
                {t('EMR.FORM.DATE_TREATMENT')} ({visit.length})
              </div>
              <div style={{ height: height - (type === 'RetrospectiveEdit' ? 530 : 450), overflow: 'hidden', overflowY: 'auto' }} className="custom-scroll">
                {_.map(visit, (visitData: any, index: number) => {
                  return (
                    <Item key={visitData.patientCaseId} className={`${vnActive === visitData.patientCaseId ? 'active' : ''}`} style={{ fontWeight: 400, padding: 0 }} onClick={() => handleChangeTreatment(visitData)}>
                      <span className={'text-ellipsis'} style={{ paddingLeft: '16px' }}>
                        [{visitData.branchCode}
                        {visitData.branchId}] {dateTimeToView(visitData.createdAt)}
                      </span>
                    </Item>
                  )
                })}
              </div>
            </Col>
          )}
          <Col className="col px-2 pb-4 emr-detail" style={{ overflowY: 'scroll', height: height }}>
            {width > 425 && (
              <div className="p-3 " style={{ boxShadow: `0px 3px 6px ${colors.black08}` }}>
                <h5>{type === 'RetrospectiveEdit' ? t('RETROSPECTIVE_EDIT.HEADER') : t('EMR.TITLE.DEITAL')}</h5>
              </div>
            )}
            {width <= 425 && (
              <div>
                <CardProfile className="d-flex px-3">
                  <ProfileAvatarMb alt="profile" src={renderImage(patient.profile)} sx={{ border: `3px solid ${memberTypeOutline(patient.rank)}`, backgroundColor: colors.white }}>
                    <img src={imgs.defaultAvatar} alt="" className="w-100 h-100" />
                  </ProfileAvatarMb>
                  <Box className="ml-3 d-flex flex-column overflow-hidden" sx={{ flex: 1 }}>
                    <Box className="d-flex flex-column flex-md-row">
                      <Typography className="d-inline-block align-middle text-ellipsis" sx={{ fontSize: { sm: '18px' }, fontWeight: 600, color: colors.themeMainColor }}>
                        {patient?.branchCnNumber ? `${patient?.branchCnNumber} |` : ''} {(patient.firstname && patient.lastname && `${patient.firstname} ${patient.lastname}`) || t('REGISTER.TITLE.FULL_NAME')}
                      </Typography>
                      <Box className="d-inline-block ml-md-2">
                        <LabelRanking rank={patient.rank || 'RANKING'} />
                      </Box>
                    </Box>
                    <Typography className="mt-auto" sx={{ fontSize: '14px', color: colors.themeMainColor }}>
                      {t('EMR.FORM.DATE_VISIT')} {dateTimeToView(vnDate)}
                    </Typography>
                    <Typography className="ml-auto" sx={{ fontSize: '14px', color: colors.themeSecondColor, textDecoration: 'underline', paddingTop: '8px' }} onClick={ToggleSidebar}>
                      {t('EMR.FORM.EXTEND')}
                    </Typography>
                  </Box>
                </CardProfile>
              </div>
            )}
            <div className="pt-4 px-2">
              {width > 425 && (
                <Box>
                  <FlexBox>
                    <Typo>
                      <span style={{ fontWeight: 500 }}>VN</span> {data?.patientCaseInfo?.vnNumber || '-'}
                    </Typo>
                    <Typo>
                      <span style={{ fontWeight: 500 }}>{t('EMR.FORM.DATE')}</span> {dateToView(data?.patientCaseInfo?.createdAt)} เวลา {timeToView(data?.patientCaseInfo?.createdAt)} น.
                    </Typo>
                  </FlexBox>
                  <FlexBox>
                    <Typo>
                      <span style={{ fontWeight: 500 }}>{t('EMR.FORM.DOCTOR')}</span> {data?.doctor || '-'}
                    </Typo>
                    <Typo>
                      <span style={{ fontWeight: 500 }}>{t('EMR.FORM.BRANCE')}</span> {data?.branch?.branchName || data?.branch?.branchNameEn || '-'}
                    </Typo>
                  </FlexBox>
                </Box>
              )}
              {width <= 425 && (
                <Box>
                  <FlexBox>
                    <Typo style={{ fontWeight: 500 }}>VN </Typo>
                    <Typo>{data?.patientCaseInfo?.vnNumber || '-'}</Typo>
                  </FlexBox>
                  <FlexBox>
                    <Typo style={{ fontWeight: 500 }}>{t('EMR.FORM.DATE')}</Typo>
                    <Typo>
                      {dateToView(data?.patientCaseInfo?.createdAt)} เวลา {timeToView(data?.patientCaseInfo?.createdAt)} น.
                    </Typo>
                  </FlexBox>
                  <FlexBox>
                    <Typo style={{ fontWeight: 500 }}>{t('EMR.FORM.DOCTOR')}</Typo>
                    <Typo>{data?.doctors?.fullname || '-'}</Typo>
                  </FlexBox>
                  <FlexBox>
                    <Typo style={{ fontWeight: 500 }}>{t('EMR.FORM.BRANCE')}</Typo>
                    <Typo>{data?.branch?.branchName || data?.branch?.branchNameEn || '-'}</Typo>
                  </FlexBox>
                </Box>
              )}
            </div>
            <Row style={{ marginLeft: '-8px', marginRight: '-8px' }}>
              <Col sm={width <= 1024 ? 12 : 6}>
                <Accordion defaultExpanded={true}>
                  <AccordionSummary aria-controls="vital-sign" id="vital-sign-header">
                    <div className="text">
                      <div className="header-text">
                        <h5 className={'m-0'}>{t('EMR.TITLE.VITAL_SIGN')}</h5>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <BoxInfo className=" custom-scroll px-md-0" sx={{ maxHeight: '100%' }}>
                      <Grid container spacing={1} className="pt-2 px-xl-3">
                        <Grid item md={6} className="w-100 pt-0">
                          <FlexBox>
                            <Typo style={{ fontWeight: 500, width: width === 1440 ? '133px' : '' }}>{t('EMR.FORM.WEIGHT')}</Typo>
                            <Typo>{data?.patientCaseInfo?.weight || '-'}</Typo>
                          </FlexBox>
                          <FlexBox>
                            <Typo style={{ fontWeight: 500, width: width === 1440 ? '133px' : '' }}>{t('EMR.FORM.HEIGHT')}</Typo>
                            <Typo>{data?.patientCaseInfo?.height || '-'}</Typo>
                          </FlexBox>
                          <FlexBox>
                            <Typo style={{ fontWeight: 500, width: width === 1440 ? '133px' : '' }}>{t('EMR.FORM.SYSTOLIC_BLOOD_PRESSURE')}</Typo>
                            <Typo className="d-flex">
                              {data?.patientCaseInfo?.systolicBloodPressure || '-'}
                              <div style={{ margin: '0 12px', color: colors.textPrimary, fontSize: 15 }}>/</div>
                              {data?.patientCaseInfo?.diastolicBloodPressure || '-'}
                            </Typo>
                          </FlexBox>
                          <FlexBox>
                            <Typo style={{ fontWeight: 500, width: width === 1440 ? '133px' : '' }}>{t('EMR.FORM.WAISTLINE')}</Typo>
                            <Typo>{data?.patientCaseInfo?.waistline || '-'}</Typo>
                          </FlexBox>
                          <FlexBox>
                            <Typo style={{ fontWeight: 500, width: width === 1440 ? '133px' : '' }}>{t('EMR.FORM.TEMPERATURE')}</Typo>
                            <Typo>{data?.patientCaseInfo?.temperature || '-'}</Typo>
                          </FlexBox>
                          <FlexBox>
                            <Typo style={{ fontWeight: 500, width: width === 1440 ? '133px' : '' }}>{t('EMR.FORM.PULSE_RATE')}</Typo>
                            <Typo>{data?.patientCaseInfo?.pulseRate || '-'}</Typo>
                          </FlexBox>
                        </Grid>
                        <Grid item md={6} className="w-100 pt-0">
                          <FlexBox>
                            <Typo style={{ fontWeight: 500, width: width === 1440 ? '133px' : '' }}>{t('EMR.FORM.HEART_RATE')}</Typo>
                            <Typo>{data?.patientCaseInfo?.heartRate || '-'}</Typo>
                          </FlexBox>
                          <FlexBox>
                            <Typo style={{ fontWeight: 500, width: width === 1440 ? '133px' : '' }}>{'Reg/Irreq'}</Typo>
                            <Typo>{data?.patientCaseInfo?.regIrreq || '-'}</Typo>
                          </FlexBox>
                          <FlexBox>
                            <Typo style={{ fontWeight: 500, width: width === 1440 ? '133px' : '' }}>{'RR'}</Typo>
                            <Typo>{data?.patientCaseInfo?.rr || '-'}</Typo>
                          </FlexBox>
                          <FlexBox>
                            <Typo style={{ fontWeight: 500, width: width === 1440 ? '133px' : '' }}>{'O2Sat(%)'}</Typo>
                            <Typo>{data?.patientCaseInfo?.o2Sat || '-'}</Typo>
                          </FlexBox>
                          <FlexBox>
                            <Typo style={{ fontWeight: 500, width: width === 1440 ? '133px' : '' }}>{t('EMR.FORM.BMI')}</Typo>
                            <Typo>{data?.patientCaseInfo?.bmi || '-'}</Typo>
                          </FlexBox>
                          <FlexBox>
                            <Typo style={{ fontWeight: 500, width: width === 1440 ? '133px' : '' }}>{t('EMR.FORM.BSA')}</Typo>
                            <Typo>{data?.patientCaseInfo?.bsa || '-'}</Typo>
                          </FlexBox>
                        </Grid>
                      </Grid>
                    </BoxInfo>
                  </AccordionDetails>
                </Accordion>
              </Col>
              <Col sm={width <= 1024 ? 12 : 6}>
                <Accordion defaultExpanded={true}>
                  <AccordionSummary aria-controls="vital-sign" id="vital-sign-header">
                    <div className="text">
                      <div className="header-text">
                        <h5 className={'m-0'}>{t('EMR.TITLE.SYMPTOMS')}</h5>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <BoxInfo className=" custom-scroll px-md-0" sx={{ maxHeight: '100%' }}>
                      <Grid container spacing={1}>
                        <Grid item md={12}>
                          <FlexBox>
                            <Typo style={{ fontWeight: 500 }}>{t('EMR.FORM.PRIMARY_SYMPTOMS')}</Typo>
                            <Typo>{data?.patientCaseInfo?.importantSymptoms || '-'}</Typo>
                          </FlexBox>
                          <FlexBox>
                            <Typo style={{ fontWeight: 500 }}>{t('EMR.FORM.DOCTOR_SYMPTOMS')}</Typo>
                            <Typo>{data?.patientCaseInfo?.importantSymptomsDoctor || '-'}</Typo>
                          </FlexBox>
                        </Grid>
                      </Grid>
                    </BoxInfo>
                  </AccordionDetails>
                </Accordion>
              </Col>
            </Row>
            <Head className='tooth-chart'>
              <Detail aria-controls="vital-sign" id="vital-sign-header">
                <div className="text">
                  <div className="header-text">
                    <h5 className={'m-0 px-3'} style={{ paddingTop: '12px' }}>
                      OPD
                    </h5>
                  </div>
                </div>
              </Detail>
              <AccordionDetails>
                {clinicTheme.theme === clinicTheme.type.DENTAL && type !== 'RetrospectiveEdit' && (
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={12} className="p-2">
                      <StyleToothChart>{imgToothChart && <ImgToothChart style={{ width: '750px', height: '400px' }} src={imgToothChart} alt="" />}</StyleToothChart>
                    </Grid>
                  </Grid>
                )}
                {type === 'RetrospectiveEdit' && (
                  <>
                    <TxStyled className={`${props.disabled ? 'tx-disabled' : ''} mt-2 `}>
                      {clinicTheme.theme === clinicTheme.type.DENTAL && (
                        <FragmentToothChart className="mx-auto mb-3 mb-md-5 mb-xl-0" sx={{ flex: 'none' }}>
                          <Box className="d-none d-md-block">
                            <ToothChart type={activeFooterTab === 0 ? 'treatment' : 'dx'} teethType={teethType} patientId={Number(patientId)} isRetrospectiveOrder={false} />
                          </Box>
                          <Box className="d-flex justify-content-center">
                            <ButtonToothChart onClick={() => setTeethType('ADULT')} className="mt-0 mt-md-3 " sx={{ flex: 'none' }}>
                              <TeethAdult type={teethType} />
                            </ButtonToothChart>
                            <ButtonToothChart onClick={() => setTeethType('CHILD')} className="mt-0 mt-md-3 pl-1 pr-3" sx={{ flex: 'none' }}>
                              <TeethChild type={teethType} />
                            </ButtonToothChart>
                            <ButtonTreatmentOverview variant={'outlined'} onClick={onViewEmrOverview} size="small" className="mt-0 mt-md-3 px-3" disabled={props.disabled}>
                              {t('OPD.BUTTON.TREATMENT_OVERVIEW')}
                            </ButtonTreatmentOverview>
                          </Box>
                        </FragmentToothChart>
                      )}
                      {clinicTheme.theme !== clinicTheme.type.DENTAL && (
                        <ButtonCustom onClick={onViewEmrOverview} disabled={props.disabled} textButton={t('OPD.BUTTON.TREATMENT_OVERVIEW')} className="d-md-none w-100 px-3" style={{ margin: 0 }} btnStyle={{ height: '36px' }} />
                      )}
                    </TxStyled>
                    {activeFooterTab === 1 && (
                      <>
                        <NavBtn className={`d-flex flex-wrap custom-scroll mt-3`} style={{ maxHeight: '76px' }}>
                          <Box className="px-1 pb-2">
                            <ButtonType variant="outlined" title={t('TREATMENTS_DX.TITLE.ALL')} className={`${typeSelected === 'All' ? 'active' : ''} overflow-hidden`} data-value={'All'} onClick={handleChangeType} key={`btn-operativeTypes-all`}>
                              <span className="text-ellipsis">{t('TREATMENTS_DX.TITLE.ALL')}</span>
                            </ButtonType>
                          </Box>
                          <Box className="px-1 pb-2">
                            <ButtonType variant="outlined" title={t('BOOKMARK')} className={`${typeSelected === 'BOOKMARK' ? 'active' : ''}`} data-value={'BOOKMARK'} onClick={handleChangeType} key={`btn-operativeTypes-bookmark`}>
                              {isBookmark ? <Icons.StarActive fill={colors.white} /> : <Icons.StarInActive fill={colors.textPrimary} />}
                            </ButtonType>
                          </Box>
                          {operativeTypes.map((val: any, i) => {
                            return (
                              <Box key={`col-operative-typ-${val.diagnosisTypeId}`} className="px-1 pb-2">
                                <ButtonType
                                  variant="outlined"
                                  key={val.diagnosisTypeId}
                                  title={val.diagnosisTypeName}
                                  data-value={val.diagnosisTypeId}
                                  onClick={handleChangeType}
                                  className={`${typeSelected === val.diagnosisTypeId.toString() ? 'active' : ''} text-ellipsis`}
                                >
                                  <span className="text-ellipsis">{val.diagnosisTypeName}</span>
                                </ButtonType>
                              </Box>
                            )
                          })}
                        </NavBtn>
                        <TableWidth className={`mt-2`}>
                          <TableCustom
                            page={page}
                            pageLimit={pageLimit}
                            setPageLimit={handleChangeRowsPerPage}
                            setPage={handleChangePage}
                            rowCount={rowCount}
                            headCells={headCells}
                            rowsData={diagnosis.map((val, i) => {
                              return renderData(val, i)
                            })}
                          />
                        </TableWidth>
                      </>
                    )}
                  </>
                )}
              </AccordionDetails>
            </Head>

            {type !== 'RetrospectiveEdit' && (
              <>
                <Box className="w-100">
                  <IndexSummaryTreatmentEmr
                    userId={user.userId}
                    type={'Treatment'}
                    patient={patient}
                    tabs={footerTabs}
                    activeTab={activeFooterTab}
                    setActiveTab={(tab: number) => setActiveFooterTab(tab)}
                    diagnosis={data.diagnosis}
                    patientCaseInfo={data.patientCaseInfo}
                    recommends={data.recommends}
                    summeryProducts={data.summeryProducts}
                    summeryTreatments={data.summeryTreatments}
                    doctors={doctors}
                  />
                </Box>
                <Box className="just-space" style={{ height: '50px' }}></Box>
                <Box className="d-flex align-items-center justify-content-end mb-2" style={{ backgroundColor: 'white', position: 'fixed', bottom: -10, right: 0, width: width, zIndex: 999, height: 70 }}>
                  <Box className="d-flex align-items-center mr-3">
                    <ButtonCustom variant="outlined" className={'ml-2 ml-lg-3 d-none d-md-flex'} onClick={() => handleRoute('treatmentHistory')} startIcon={<IconBack />} textButton={t('EMR.BUTTON.HISTORY_TREATMENT')} style={{ margin: 0 }} />
                    <ButtonCustom variant="outlined" className={'ml-2 ml-lg-3 d-none d-md-flex mr-2'} onClick={() => handleRoute('paymentHistory')} startIcon={<IconBack />} textButton={t('EMR.BUTTON.HISTORY_PAYMENT')} style={{ margin: 0 }} />
                    {checkPackage(PACKAGE_ADDONS.ADDON_LAB) && (
                      <UseStyled.CustomIconButton style={{ height: '36px' }} onClick={() => handleRoute('lab')} className="mr-2 p-0">
                        <sidebarIcon.Lab />
                      </UseStyled.CustomIconButton>
                    )}
                    {checkPackage(PACKAGE_ADDONS.ADDON_X_RAY) && (
                      <UseStyled.CustomIconButton style={{ height: '36px' }} onClick={() => handleRoute('x-ray')} className="mr-2 p-0">
                        <sidebarIcon.Xray />
                      </UseStyled.CustomIconButton>
                    )}
                    <ButtonCustom
                      onClick={() => onFollowTreatment(patientId, Number(vnActive), patient.vnNumber || data?.patientCaseInfo?.vnNumber)}
                      textButton={t('HISTORY_TREATMENT.BUTTON_FOLLOW_TREATMENT')}
                      variant="outlined"
                      className={'mr-2 text-nowrap'}
                      btnStyle={{ height: '36px', backgroundColor: colors.white, fontSize: '16px' }}
                      style={{ margin: 0 }}
                    />
                    <UseStyled.DropdownDocument>
                      <Dropdown.Toggle id={`dropdown-basic${data.patientCaseId || ''}`}>{t('REGISTER.BUTTON.PRINT') + t('REGISTER.BUTTON.DOCUMENT')}</Dropdown.Toggle>
                      <Dropdown.Menu>
                        <UseStyled.DropdownTitle className="px-3">{t('HISTORY_TREATMENT.DOCUMENT.RECEIPT')}</UseStyled.DropdownTitle>
                        {branch?.receiptA5Default === '1' && <UseStyled.DropdownItem onClick={() => onDocument('A5')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5')}</UseStyled.DropdownItem>}
                        {branch?.receiptA5Original === '1' && <UseStyled.DropdownItem onClick={() => onDocument('A5_ORI')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5_ORI')}</UseStyled.DropdownItem>}
                        {branch?.receiptA5Copy === '1' && <UseStyled.DropdownItem onClick={() => onDocument('A5_COPY')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5_COPY')}</UseStyled.DropdownItem>}
                        {branch?.receiptA5DefaultEn === '1' && <UseStyled.DropdownItem onClick={() => onDocument('A5_EN')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5_EN')}</UseStyled.DropdownItem>}
                        {branch?.receiptA5OriginalEn === '1' && <UseStyled.DropdownItem onClick={() => onDocument('A5_EN_ORI')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5_EN_ORI')}</UseStyled.DropdownItem>}
                        {branch?.receiptA5CopyEn === '1' && <UseStyled.DropdownItem onClick={() => onDocument('A5_EN_COPY')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A5_EN_COPY')}</UseStyled.DropdownItem>}
                        {branch?.receiptA5DefaultLandscape === '1' && <UseStyled.DropdownItem onClick={() => onDocument('A5_LANDSCAPE')}>{t('HISTORY_TREATMENT.DOCUMENT.RECEIPT_TYPE.A5_LANDSCAPE')}</UseStyled.DropdownItem>}
                        {branch?.receiptA5OriginalLandscape === '1' && <UseStyled.DropdownItem onClick={() => onDocument('A5_LANDSCAPE_ORI')}>{t('HISTORY_TREATMENT.DOCUMENT.RECEIPT_TYPE.A5_LANDSCAPE_ORI')}</UseStyled.DropdownItem>}
                        {branch?.receiptA5CopyLandscape === '1' && <UseStyled.DropdownItem onClick={() => onDocument('A5_LANDSCAPE_COPY')}>{t('HISTORY_TREATMENT.DOCUMENT.RECEIPT_TYPE.A5_LANDSCAPE_COPY')}</UseStyled.DropdownItem>}
                        {branch?.receiptA4Default === '1' && <UseStyled.DropdownItem onClick={() => onDocument('A4')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A4')}</UseStyled.DropdownItem>}
                        {branch?.receiptA4Original === '1' && <UseStyled.DropdownItem onClick={() => onDocument('A4_ORI')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A4_ORI')}</UseStyled.DropdownItem>}
                        {branch?.receiptA4Copy === '1' && <UseStyled.DropdownItem onClick={() => onDocument('A4_COPY')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.A4_COPY')}</UseStyled.DropdownItem>}
                        {branch?.receiptThermal === '1' && <UseStyled.DropdownItem onClick={() => onDocument('THERMAL')}>{t('OVERDUE_DETAIL.DOCUMENT.RECEIPT_TYPE.THERMAL')}</UseStyled.DropdownItem>}
                        <UseStyled.DropdownTitle className="px-3">{t('HISTORY_TREATMENT.DOCUMENT.MEDICAL_DOCUMENT')}</UseStyled.DropdownTitle>
                        {branch?.doctorCertificate === '1' && <UseStyled.DropdownItem onClick={() => onMedicalCertificate(patientId, caseDetail.patientCaseId, caseDetail.createdAt, caseDetail.order?.orderTreatments, caseDetail.patientDiagnosis)}>{t('HISTORY_TREATMENT.DOCUMENT.MEDICAL_DOCUMENT_TYPE.MEDICAL_CERTIFICATE')}</UseStyled.DropdownItem>}
                        <UseStyled.DropdownItem disabled={data.patientInvoiceId ? false : true} onClick={() => onTreatmentConsent(caseDetail.patientCaseId, caseDetail.order?.orderTreatments[0]?.servicePointId || 0)}>
                          {t('HISTORY_TREATMENT.DOCUMENT.MEDICAL_DOCUMENT_TYPE.TREATMENT_CONSENT')}
                        </UseStyled.DropdownItem>
                        {branch?.medicalServiceUsageForm === '1' && (
                          <Tooltip placement={'right'} title={<p style={{ color: 'white', fontSize: '14px' }}>{t('TREATMENTS.BUTTON.SSO')}</p>}>
                            <UseStyled.DropdownItem className={'text-ellipsis overflow-hidden'} onClick={() => onSSO(patientId, caseDetail.patientCaseId, caseDetail.createdAt, caseDetail.order?.orderTreatments, caseDetail.patientDiagnosis)}>
                              {t('TREATMENTS.BUTTON.SSO')}
                            </UseStyled.DropdownItem>
                          </Tooltip>
                        )}
                        {branch?.doctorCertificateA5 === '1' && <UseStyled.DropdownItem onClick={() => onMedicalCertificate_A5(patientId, caseDetail.patientDiagnosis)}>{t('MODAL_MEDICALCERTIFICATE_A5.TITLE')}</UseStyled.DropdownItem>}
                        {branch?.doctorCertificateFor5Diseases === '1' && <UseStyled.DropdownItem onClick={() => onMedicalFiveDisease(patientId, caseDetail.patientCaseId, caseDetail.createdAt, caseDetail.order?.orderTreatments, caseDetail.patientDiagnosis)}>{t('TREATMENTS.BUTTON.MEDICAL_CERTIFICATE_5_DISEASE')}</UseStyled.DropdownItem>}
                        {branch?.driverLicenseDoctorCertificate === '1' && <UseStyled.DropdownItem onClick={() => onMedicalDrivingLicense(patientId, caseDetail.patientCaseId, caseDetail.createdAt, caseDetail.order?.orderTreatments, caseDetail.patientDiagnosis)}>{t('TREATMENTS.BUTTON.MEDICAL_CERTIFICATE_DRIVING_LICENSE')}</UseStyled.DropdownItem>}
                      </Dropdown.Menu>
                    </UseStyled.DropdownDocument>
                  </Box>
                </Box>
              </>
            )}
            {type === 'RetrospectiveEdit' && (
              <>
                <Box className={`w-100 ${activeTab === TABS_NAME.VITAL_SIGN ? 'd-none opacity-0 pe-none' : ''}`}>
                  <IndexSummaryTreatment
                    userId={user.userId}
                    type="RetrospectiveEdit"
                    tabs={footerTabs}
                    activeTab={activeFooterTab}
                    setActiveTab={(tab: number) => {
                      setActiveFooterTab(tab)
                      dispatch(setEditTootChart(null))
                      dispatch(setEditTootChartType(''))
                    }}
                    hasTreatment={'PERMISS'}
                    hasChangeTreatmentCost={'PERMISS'}
                    hasChangeTreatmentNote={'PERMISS'}
                    hasChangeProductPrice={'PERMISS'}
                    patientCaseId={patient.patientCase?.patientCaseId}
                    patientId={patient.patientId}
                    patientVN={data?.patientCaseInfo?.vnNumber}
                    patientName={`${patient.firstname} ${patient.lastname}`}
                    doctors={doctors}
                    hasDiagnosis={'PERMISS'}
                    hasChangeProductDiscountAdditional={'PERMISS'}
                    hasDeleteTreatment={'PERMISS'}
                    diagnosis={data.diagnosis}
                    recommends={data.recommends}
                    summeryProducts={data.summeryProducts}
                    summeryTreatments={data.summeryTreatments}
                    patientPhone={patient.phone}
                  />
                </Box>
                <Box className="just-space" style={{ height: '50px' }}></Box>
                <Box className="d-flex align-items-center justify-content-end mb-2" style={{ backgroundColor: 'white', position: 'fixed', bottom: -10, right: 0, width: width, zIndex: 999, height: 70 }}>
                  <Box className="d-flex align-items-center mr-3">
                    <ButtonCustom textButton={t('BUTTON.CANCEL_EDIT')} variant="outlined" onClick={() => handleEditEmr('cancel')} btnStyle={{ height: 44, marginRight: '1rem' }} />
                    <ButtonCustom textButton={t('BUTTON.SUBMIT')} onClick={() => handleEditEmr('save')} btnStyle={{ height: 44 }} />
                  </Box>
                </Box>
                <Box className="just-space" style={{ height: '80px' }}></Box>
              </>
            )}
            <Box className="just-space" style={{ height: '100px' }}></Box>
          </Col>
        </Row>
      </div>
      <SidebarBackdrop isOpen={isOpen} visit={visit} patient={patient} ToggleSidebar={ToggleSidebar} vnActive={vnActive} handleChangeTreatment={(visitValue: any) => handleChangeTreatment(visitValue)} />
      {isShowPopupEmrOverview && (
        <PopupEmrOverview
          patientId={Number(patientId)}
          onClose={() => {
            setIsShowPopupEmrOverview(false)
            dispatch(resetModal())
          }}
        />
      )}
      {isShowPopupRecommend.show && (
        <PopupAddDx type="dx" tx={{}} dx={isShowPopupRecommend.dx} data={{ dx: [], recommend: isShowPopupRecommend.data }} isShow={isShowPopupRecommend.show} setIsShow={(show: boolean) => setIsShowPopupRecommend({ show, dx: {}, data: [] })} />
      )}
      {(configModal.type === 'FollowTreatment' && <PopupFollowTreatment patientId={configModal.patientId} patientCaseId={configModal.patientCaseId} patient={patient} vnNumber={configModal.vnNumber} />) || <></>}
      {configModal.type === 'MedicalCertificate' && (
        <MedicalCertificate
          isShow={exportMedicalCertificate}
          setIsShow={(show: boolean) => setConfigModal({ type: '' })}
          patientId={configModal.patientId || 0}
          patientCaseId={configModal.patientCaseId || 0}
          createdAt={configModal.createdAt || ''}
          type={'historyTreatment'}
          treatments={configModal.treatments}
          diagnosis={configModal.diagnosis}
        />
      )}
      {configModal.type === 'treatmentConsent' && (
        <PopupTreatmentConsent type="historyTreatment" patientCaseId={configModal.patientCaseId || 0} servicePointId={configModal.servicePointId || 0} setIsShow={(show: boolean) => setConfigModal({ type: '' })} />
      )}
      {configModal.type === 'MedicalServices' && (
        <SSO
          hidePhoneNumber={user.hasShowPhoneNumber === 'UNPERMISS'}
          patient={patient}
          branchId={branchId}
          isShow={exportMedicalCertificate}
          setIsShow={(show: boolean) => setConfigModal({ type: '' })}
          patientId={configModal.patientId || 0}
          patientCaseId={configModal.patientCaseId || 0}
          createdAt={configModal.createdAt || ''}
          type={'historyTreatment'}
          treatments={configModal.treatments}
          diagnosis={configModal.diagnosis}
        />
      )}
      {configModal.type === 'MedicalCertificate_A5' && (
        <MedicalCertificate_A5 isShow={exportMedicalCertificate}
          setIsShow={(show: boolean) => setConfigModal({ type: '' })}
          patientId={configModal.patientId || 0}
          branchId={branchId}
          patient={patient} />
      )}
      {configModal.type === 'MedicalFiveDisease' && (
        <MedicalCertificateFiveDisease
          patient={patient}
          branchId={branchId}
          isShow={exportMedicalCertificate}
          setIsShow={(show: boolean) => setConfigModal({ type: '' })}
          patientId={configModal.patientId || 0}
          patientCaseId={configModal.patientCaseId || 0}
          createdAt={configModal.createdAt || ''}
          type={'historyTreatment'}
          treatments={configModal.treatments}
          diagnosis={configModal.diagnosis}
        />
      )}
      {configModal.type === 'MedicalDrivingLicense' && (
        <MedicalCertificateDrivingLicense
          patient={patient}
          branchId={branchId}
          isShow={exportMedicalCertificate}
          setIsShow={(show: boolean) => setConfigModal({ type: '' })}
          patientId={configModal.patientId || 0}
          patientCaseId={configModal.patientCaseId || 0}
          createdAt={configModal.createdAt || ''}
          type={'historyTreatment'}
          treatments={configModal.treatments}
          diagnosis={configModal.diagnosis}
        />
      )}
      {exportReferralExamination && <ReferralExamination isShow={exportMedicalCertificate} setIsShow={(show: boolean) => setExportReferralExamination(show)} patientId={patientId} branchId={branchId} patient={patient} />}
    </div>
  )
}

export function SidebarBackdrop(props: SideBar) {
  const { isOpen, visit, patient, ToggleSidebar, vnActive, handleChangeTreatment } = props
  const { t } = useTranslation()

  const renderImage = (img: any) => {
    if (!_.isEmpty(img) && !_.isEmpty(img.filePath) && !_.isEmpty(img.fileName)) return `${fileUrl}/${img.filePath}/${img.fileName}`
    else return imgs.defaultAvatar
  }

  return (
    <div>
      <div className={`sidebar ${isOpen === true ? 'active' : ''}`}>
        <div className="sd-header">
          <div className="d-flex">
            <ProfileAvatarMb alt="profile" src={renderImage(patient.profile)} sx={{ border: `3px solid ${memberTypeOutline(patient.rank)}`, backgroundColor: colors.white }}>
              <img src={imgs.defaultAvatar} alt="" className="w-100 h-100" />
            </ProfileAvatarMb>
            <Box className="ml-3 d-flex flex-column overflow-hidden" sx={{ flex: 1 }}>
              <Box className="d-flex flex-column flex-md-row">
                <Typography className="d-inline-block align-middle text-ellipsis" sx={{ fontSize: { sm: '16px' }, fontWeight: 500, color: colors.themeMainColor }}>
                  {(patient.firstname && patient.lastname && `${patient.firstname} ${patient.lastname}`) || t('REGISTER.TITLE.FULL_NAME')}
                </Typography>
                <Typography className="d-inline-block align-middle text-ellipsis  text-muted" sx={{ fontSize: { sm: '12px' } }}>
                  {patient?.branchCnNumber ? `${patient?.branchCnNumber}` : ''}
                </Typography>
              </Box>
            </Box>
          </div>
          <CloseButton style={{ position: 'absolute', top: '8px', right: '9px' }} onClick={ToggleSidebar} />
        </div>
        <div className="sd-body">
          <div className="pt-3" style={{ padding: '0px 16px', fontWeight: 500 }}>
            {t('EMR.FORM.DATE_TREATMENT')} ({visit.length})
          </div>
          <div>
            {_.map(visit, (visitData: any, index: number) => {
              return (
                <Item key={visitData.patientCaseId} className={`${vnActive === visitData.patientCaseId ? 'active' : ''}`} style={{ fontWeight: 400 }} onClick={() => handleChangeTreatment(visitData)}>
                  <span className={'text-ellipsis'} onClick={ToggleSidebar}>
                    [{visitData.branchCode}
                    {visitData.branchId}] {dateTimeToView(visitData.createdAt)}
                  </span>
                </Item>
              )
            })}
          </div>
        </div>
      </div>
      <div className={`sidebar-overlay ${isOpen === true ? 'active' : ''}`} onClick={ToggleSidebar}></div>
    </div>
  )
}
