import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { Box, Typography, TableRow, TableCell } from '@mui/material'
import { clinicTheme } from 'api/api'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import TreatmentHistoryApi from 'api/dentists/treatment-history.api'

/** COMPONENT */
import TableCustom from 'component/Table/TableCustom'
import { CreateDataType, OrderTreatmentsType, OrderProductsType } from 'component/Register/TreatmentHistory'

/** UTILS */
import { dateToView } from 'utils/date.utils'
import useWindowSize from 'utils/useWindowSize'

/** CONSTANTS */
import { numberFormat } from 'utils/app.utils'

/** STYLE */
import { styled } from '@mui/material/styles'
import { ChipDoctor, ChipRemark, ChipProduct, ChipTx } from 'component/Register/style/styleTreatmentHistory'
import 'component/Treatments/style/modal.css'

const ResponsiveTable = styled(Box)(({ theme }) => ({
  '> div': {
    borderRadius: '10px 10px 0 0'
  },
  '.MuiTable-root': {
    tableLayout: 'fixed',
    '.MuiTableHead-root': {
      '.MuiTableCell-head': {
        padding: '8px 15px',
        '&.MuiTableCell-head:nth-of-type(1)': {
          width: '7%'
        },
        '&.MuiTableCell-head:nth-of-type(3)': {
          width: '68%'
        },
        '&.MuiTableCell-head:nth-of-type(4)': {
          width: '11%',
          [theme.breakpoints.up('sm')]: {
            paddingRight: 24
          }
        },
        [theme.breakpoints.down('lg')]: {
          '&.MuiTableCell-head:nth-of-type(3)': {
            width: '63%'
          },
          '&.MuiTableCell-head:nth-of-type(4)': {
            width: '14%'
          }
        },
        [theme.breakpoints.down('md')]: {
          '&.MuiTableCell-head:nth-of-type(1)': {
            width: '10%'
          },
          '&.MuiTableCell-head:nth-of-type(3)': {
            width: '55%'
          },
          '&.MuiTableCell-head:nth-of-type(4)': {
            width: '17%'
          }
        },
        [theme.breakpoints.down('sm')]: {
          textAlign: 'left',
          '&.MuiTableCell-head:nth-of-type(2), &.MuiTableCell-head:nth-of-type(3), &.MuiTableCell-head:nth-of-type(4)': {
            fontSize: 0
          }
        },
        [theme.breakpoints.down('xs')]: {
          '&.MuiTableCell-head:nth-of-type(1)': {
            width: '15%'
          }
        }
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-body': {
        paddingTop: 8,
        [theme.breakpoints.up('sm')]: {
          '&:last-of-type': {
            paddingRight: 24
          }
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%'
    }
  },
  '.MuiToolbar-root': {
    minHeight: 'unset',
    marginTop: 16,
    padding: 0,
    '.MuiTablePagination-displayedRows + div': {
      margin: 0
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: 8
    }
  },
  '.MuiTablePagination-selectLabel, .MuiTablePagination-selectLabel + .MuiInputBase-root, .MuiTablePagination-input, .MuiTablePagination-displayedRows': {
    display: 'none'
  },
  '.MuiTableContainer-root': {
    overflowY: 'auto',
    height: '100%'
  }
}))

export interface TreatmentHistoryProps {
  patientId: number
}

type ConditionType = {
  page?: number
  pageLimit?: number
}

export default function TreatmentHistory(props: TreatmentHistoryProps) {
  const { t } = useTranslation()
  const [width] = useWindowSize()
  const patientId = props.patientId
  const [page, setPage] = useState(1)
  const pageLimit = 3
  const [rowCount, setRowCount] = useState(0)
  const [histories, setHistories] = useState([])

  const loadTreatmentHistory = useCallback(() => {
    let condition: ConditionType = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }

    TreatmentHistoryApi.findSummeryAllByPatientId(patientId, condition)
      .then((res) => {
        if (res.status === 200 && !_.isEmpty(res.data)) {
          setHistories(res.data)
          setRowCount(res.headers['x-total'])
        } else {
          setHistories([])
          setRowCount(0)
        }
      })
      .catch(() => {
        setHistories([])
        setRowCount(0)
      })
  }, [patientId, page, pageLimit])

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  useEffect(() => {
    loadTreatmentHistory()
  }, [loadTreatmentHistory])

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'message', disablePadding: false, label: t('REGISTER.TITLE.DATE_SERVICE') },
    { id: 'message', disablePadding: false, label: t('REGISTER.TITLE.SERVICE') },
    { id: 'message', disablePadding: false, align: 'right', label: t('TREATMENTS.TITLE.TOTAL_PRICE') }
  ]

  const renderData = (data: CreateDataType, no: number) => {
    const { order, createdAt } = data

    const groupOrderTreatments = (item: any) => {
      const newArr = _.chain([...item.order.orderTreatments, ...item.order.orderProducts])
        .map((i: any) => {
          return i
        })
        .groupBy('doctorFullname')
        .map((value: any, key: string) => ({ key, data: value }))
        .value()

      return newArr
    }
    const groupTreatments = groupOrderTreatments(data)

    return (
      <TableRow key={`renderData-${no}`}>
        <TableCell align="center" scope="row">
          {page * pageLimit - pageLimit + no + 1}
        </TableCell>
        <TableCell align="left" scope="row">
          {dateToView(createdAt)}
        </TableCell>
        <TableCell align="left" scope="row">
          {_.map(groupTreatments, (i: any) => {
            return (
              <>
                <Box className={`mr-2`} sx={{ marginTop: '2px' }}>
                  <ChipDoctor label={'Dr.'} size="small" />
                  <span className={'align-middle'}>{i.key}</span>
                </Box>
                {_.map(i.data, (treatment: OrderTreatmentsType & OrderProductsType, index: number) => {
                  return (
                    <>
                      <Box key={index} className={`mr-2`} sx={{ marginTop: index > 0 ? '2px' : '' }}>
                        {treatment.itemName ? <ChipProduct label={'Med./Pd.'} size="small" /> : <></>}
                        <span className={'align-middle'}>{treatment.itemName || treatment.itemNameEn || ''}</span>
                      </Box>

                      <Box key={index} className={`mr-2`} sx={{ marginTop: index > 0 ? '2px' : '' }}>
                        {treatment.operativeName || treatment.operativeNameEn ? <ChipTx label={'Tx.'} size="small" /> : <></>}
                        <span className={'align-middle'}>
                          {treatment.operativeName || treatment.operativeNameEn || ''}
                          {clinicTheme.theme === clinicTheme.type.DENTAL && (treatment.teeth ? `(${treatment.teeth})` : '')}
                        </span>
                      </Box>
                      {(treatment.note && (
                        <Box sx={{ marginTop: '2px' }}>
                          <ChipRemark label={t('Note.')} size="small" />
                          <pre className={'d-inline-block align-middle note'}>{treatment.note}</pre>
                        </Box>
                      )) || <></>}
                    </>
                  )
                })}
              </>
            )
          })}
        </TableCell>
        <TableCell align="right" scope="row">
          {numberFormat(order.totalPrice)}
        </TableCell>
      </TableRow>
    )
  }

  const renderResponsiveData = (data: CreateDataType, no: number) => {
    const { order, createdAt } = data

    return (
      <TableRow key={`renderMobileData-${no}`}>
        <TableCell align="left" scope="row" className="pr-2">
          {page * pageLimit - pageLimit + no + 1}
        </TableCell>
        <TableCell align="left" scope="row" colSpan={3} className="pl-0">
          <Box>
            <Typography className="d-inline-block pr-4" sx={{ fontWeight: 600 }}>
              {t('REGISTER.TITLE.DATE_SERVICE')}
            </Typography>
            {dateToView(createdAt)}
          </Box>
          <Box>
            <Typography sx={{ fontWeight: 600 }}>{t('REGISTER.TITLE.SERVICE')}</Typography>
            {_.map(order.orderTreatments, (treatment: OrderTreatmentsType & OrderProductsType, index: number) => {
              return (
                <>
                  <Box key={index} sx={{ marginBottom: '2px' }}>
                    {treatment.itemCode ? <ChipProduct label={t('REGISTER.TITLE.PRODUCT')} size="small" /> : <ChipDoctor label={treatment.doctorFullname} size="small" />}
                    <span className={'align-middle'} style={{ wordBreak: 'break-all' }}>
                      {treatment.operativeName || treatment.operativeNameEn || treatment.operativeTypeName || treatment.operativeTypeNameEn || treatment.itemName || treatment.itemNameEn || ''}
                      {clinicTheme.theme === clinicTheme.type.DENTAL && (treatment.teeth ? `(${treatment.teeth})` : '')}
                    </span>
                  </Box>
                  {(treatment.note && (
                    <Box sx={{ marginBottom: '2px' }}>
                      <ChipRemark label={t('REGISTER.TITLE.MORE_DETAIL')} size="small" />
                      <pre className={'d-inline-block align-middle note'} style={{ fontSize: '16px' }}>
                        {treatment.note}
                      </pre>
                    </Box>
                  )) || <></>}
                </>
              )
            })}
          </Box>
          <Box>
            <Typography className="d-inline-block pr-4" sx={{ width: '117px', fontWeight: 600 }}>
              {t('TREATMENTS.TITLE.TOTAL_PRICE')}
            </Typography>
            {numberFormat(order.totalPrice)}
          </Box>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      {(width >= 768 && (
        <ResponsiveTable className={'overflow-hidden d-none d-md-flex flex-md-column'}>
          <TableCustom
            customScroll
            page={page}
            pageLimit={pageLimit}
            sortType={'ASC'}
            sortBy={''}
            rowCount={rowCount}
            onSort={() => {
              return
            }}
            setPageLimit={() => {
              return
            }}
            setPage={handleChangePage}
            headCells={headCells}
            rowsData={_.map(histories, renderData)}
          />
        </ResponsiveTable>
      )) || (
        <ResponsiveTable className={'overflow-hidden d-flex flex-column d-md-none'}>
          <TableCustom
            customScroll
            page={page}
            pageLimit={pageLimit}
            sortType={'ASC'}
            sortBy={''}
            rowCount={rowCount}
            onSort={() => {
              return
            }}
            setPageLimit={() => {
              return
            }}
            setPage={handleChangePage}
            headCells={headCells}
            rowsData={_.map(histories, renderResponsiveData)}
          />
        </ResponsiveTable>
      )}
    </>
  )
}
