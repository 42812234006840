import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import { Card, Form } from 'react-bootstrap'
import { Box, Grid, Typography, } from '@mui/material'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** ROUTE */
import { routeName } from 'routes/routes-name'

/** UTILS || SLICE */

/** API */
import NewsApi, { NewsType, ObjImage } from 'api/application/news.api'
import { fileUrl } from 'api/api'

/** COMPONENT */
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import InputTextField from 'component/Input/InputTextField'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import InputTimePicker from 'component/Input/InputTimePicker'
import UploadImage from 'component/Upload/UploadImage'
import InputTextEditor from 'component/Input/InputTextEditor'
import { notiSuccess, notiError } from 'component/notifications/notifications'

/** STYLE */
import * as PromotionUseStyled from 'features/application/promotions/useStyled'
import { colors } from 'constants/theme'

type ErrorMessage = {
  EMPTY_SUBJECT?: string
  EMPTY_DATE_PUBLIC?: string
  EMPTY_TIME_PUBLIC?: string
  EMPTY_TIME_END?: string
  EMPTY_IMAGE?: string
  EMPTY_CONTENT?: string
}

export default function FormNews() {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()

  const objImage: ObjImage = {
    fileId: null,
    base64: '',
    type: '',
    fileExtension: '',
    fileSize: ''
  }

  const [newsId, setNewsId] = useState<number>(0);
  const [news, setNews] = useState<NewsType>(
    {
      subject: '',
      content: '',
      datePublic: '',
      timePublic: '',
      dateEnd: '',
      timeEnd: '',
      image: objImage
    })
  const [previewImage, setPreviewImage]: any = useState()
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({});
  const [isDeleteImage, setIsDeleteImage] = useState(false);

  const loadData = async (id: number) => {
    await NewsApi.findById(id).then((resp) => {
      if (!_.isEmpty(resp.data)) {
        const data: any = resp.data
        setNews({
          subject: data.subject,
          content: data.content,
          datePublic: data.datePublic,
          timePublic: data.timePublic,
          dateEnd: data.dateEnd,
          timeEnd: data.timeEnd,
          status: data.status,
          image: {
            fileId: data?.image?.fileId || 0,
            base64: data?.image?.base64 || '',
            type: data?.image?.type || '',
            fileExtension: data?.image?.fileExtension || '',
            fileSize: data?.image?.fileSize || ''
          }
        })
        setPreviewImage(`${fileUrl}/${data?.image.filePath}/${data?.image.fileName}`)
      }
    }).catch((e) => {
      return
    })
  }

  const submit = () => {
    if (!news.subject || !news.datePublic || news.datePublic === '0000-00-00' || !news.timePublic || news.timePublic === '00:00:00' || (news.dateEnd && (!news.timeEnd || news.timeEnd === '00:00:00'))) {
      return setErrorMessage({
        EMPTY_SUBJECT: !news.subject ? t('NEWS.MESSAGE.ERROR.EMPTY_SUBJECT') : '',
        EMPTY_DATE_PUBLIC: !news.datePublic || news.datePublic === '0000-00-00' ? t('NEWS.MESSAGE.ERROR.EMPTY_DATE_PUBLIC') : '',
        EMPTY_TIME_PUBLIC: !news.timePublic || news.timePublic === '00:00:00' ? t('NEWS.MESSAGE.ERROR.EMPTY_TIME_PUBLIC') : '',
        EMPTY_TIME_END: (news.dateEnd && (!news.timeEnd || news.timeEnd === '00:00:00')) ? t('NEWS.MESSAGE.ERROR.EMPTY_TIME_END') : ''
      })
    } else if ((!isDeleteImage && newsId && !news.image.fileId) || (isDeleteImage && newsId && !news.image.base64) || (!newsId && !news.image.base64)) {
      return notiError(t('NEWS.MESSAGE.ERROR.EMPTY_IMAGE'))
    } else if (!news.content || news.content === `<p></p>\n` || news.content === `<p>&nbsp;</p>\n`) {
      return notiError(t('NEWS.MESSAGE.ERROR.EMPTY_CONTENT'))
    } else {
      newsId ? update() : create()
    }
  }

  const create = async () => {
    await NewsApi.createNews(news).then((e) => {
      notiSuccess(t('NEWS.MESSAGE.SUCCESS.CREATE'))
      history.push(routeName.news)
    }).catch((e) => {
      notiError(t('NEWS.MESSAGE.ERROR.DEFAULT'))
    })
  }

  const update = async () => {
    await NewsApi.updateNews(newsId, news).then((e) => {
      notiSuccess(t('NEWS.MESSAGE.SUCCESS.UPDATE'))
      history.push(routeName.news)
    }).catch((e) => {
      notiError(t('NEWS.MESSAGE.ERROR.DEFAULT'))
    })
  }

  const uploadNewsImage = (files: any) => {
    if (files[0]) {
      /**
       * files all detail file
       * lastModified, lastModifiedDate
       * name, size, type
       */
      const file: any = new window.Image()
      file.src = window.URL.createObjectURL(files[0])
      file.onload = () => {
        if (file.width > 700 || file.height > 450) {
          return notiError(t('NEWS.FORM.IMAGE_SIZE'))
        } else {
          const rawFile = files[0]
          const fileName = rawFile.name.split('.')
          const detailImage = {
            fileId: news.image.fileId || 0,
            base64: '',
            type: '',
            fileExtension: fileName[fileName.length - 1],
            fileSize: rawFile.size.toString()
          }
          const reader: any = new FileReader()
          reader.addEventListener('load', () => {
            /** reader.result is base64 */
            const base64 = reader.result.split(',')
            setPreviewImage(reader.result)
            detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result
          })
          reader.readAsDataURL(rawFile)
          setNews({ ...news, image: detailImage })
        }
      }
    }
  }

  const clearErrorMessage = () => {
    setErrorMessage({})
  }

  useEffect(() => {
    const valueNewsId: any = location.state
    if (!_.isEmpty(valueNewsId) && valueNewsId.newsId) {
      loadData(valueNewsId.newsId)
      setNewsId(valueNewsId.newsId)
    }
  }, [location])

  return (
    <div className={`container-card`}>
      <div className="d-flex flex-column">
        <HeaderLabel
          text={`${newsId ? t('NEWS.FORM.FORM_EDIT') : t('NEWS.FORM.FORM_ADD')}`}
          navigator={{ previousTo: routeName.news, previousTitle: t('NEWS.HEADER'), currentTitle: newsId ? t('NEWS.FORM.FORM_EDIT') : t('NEWS.FORM.FORM_ADD') }}
          goBack={routeName.news}
          onSubmit={submit}
        />
        <Card className="border shadow-sm mx-4 mb-4 h-auto card">
          <Card.Body className="h-100 p-3">
            <Grid container spacing={2}>
              <Grid item xs={12} md={8} className='w-100'>
                <InputTextField
                  required
                  label={t('NEWS.FORM.TITLE')}
                  value={news.subject}
                  onchange={(e: any) => {
                    setNews({ ...news, subject: e.target.value })
                    clearErrorMessage()
                  }}
                  helperText={errorMessage.EMPTY_SUBJECT || ''}
                />
              </Grid>
              <Grid item className='w-100'>
                <Typography className='mt-2' sx={{ fontSize: 18, fontWeight: 500 }}>{t('NEWS.FORM.TITLE_DATE')}</Typography>
              </Grid>
              <Grid item className='w-100'>
                <Grid container spacing={2}>
                  <Grid item xs={7} md={5} lg={4} className='w-100'>
                    <InputSecondNewDatePicker
                      required
                      label={t('NEWS.FORM.DATE_START')}
                      value={news.datePublic && news.datePublic !== '0000-00-00' ? news.datePublic : ''}
                      onchange={(e: any) => {
                        setNews({ ...news, datePublic: moment(e).format('YYYY-MM-DD') })
                        clearErrorMessage()
                      }}
                      helperText={errorMessage.EMPTY_DATE_PUBLIC || ''}
                    />
                  </Grid>
                  <Grid item xs={5} md={3} lg={3} className='w-100'>
                    <InputTimePicker
                      required
                      key={'timePublic'}
                      label={t('NEWS.FORM.TIME_START')}
                      value={news.timePublic && news.timePublic !== '00:00:00' ? news.timePublic : ''}
                      helperText={errorMessage.EMPTY_TIME_PUBLIC || ''}
                      onChange={(e: any) => {
                        setNews({ ...news, timePublic: moment(e).format('HH:mm') })
                        clearErrorMessage()
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className='w-100'>
                <Grid container spacing={2}>
                  <Grid item xs={7} md={5} lg={4} className='w-100'>
                    <InputSecondNewDatePicker
                      allowClear
                      label={t('NEWS.FORM.DATE_END')}
                      value={news.dateEnd && news.dateEnd !== '0000-00-00' ? news.dateEnd : ''}
                      disablePast
                      datePublic={news.datePublic && news.datePublic !== '0000-00-00' ? news.datePublic : ''}
                      onchange={(e: any) => {
                        setNews({ ...news, dateEnd: moment(e).format('YYYY-MM-DD') })
                        clearErrorMessage()
                      }}
                      onClear={() => {
                        setNews({ ...news, dateEnd: '', timeEnd: '' })
                        clearErrorMessage()
                      }}
                    />
                  </Grid>
                  <Grid item xs={5} md={3} lg={3} className='w-100'>
                    <InputTimePicker
                      key={'timeEnd'}
                      label={t('NEWS.FORM.TIME_END')}
                      value={news.timeEnd && news.timeEnd !== '00:00:00' ? news.timeEnd : ''}
                      onChange={(e: any) => {
                        setNews({ ...news, timeEnd: moment(e).format('HH:mm') })
                        clearErrorMessage()
                      }}
                      helperText={errorMessage.EMPTY_TIME_END || ''}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {newsId && (
                <Grid item className='w-100'>
                  <Box className='d-flex align-items-center justify-content-between justify-content-sm-start mt-2'>
                    <Typography className='mr-3' sx={{ fontSize: 18, fontWeight: 500 }}>{t('NEWS.FORM.STATUS')}</Typography>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      onChange={() => setNews({ ...news, status: news.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' })}
                      checked={news.status === 'ACTIVE' ? true : false}
                    />
                  </Box>
                </Grid>
              ) || <></>}
              <Grid item className='w-100'>
                <Typography className='mt-2' sx={{ fontSize: 18, fontWeight: 500 }}>{t('NEWS.FORM.IMAGE')}</Typography>
              </Grid>
              <Grid item className='w-100'>
                <PromotionUseStyled.UploadImage>
                  <UploadImage
                    setValue={uploadNewsImage}
                    pathFile={previewImage}
                    canDelete
                    onDelete={() => {
                      setNews({ ...news, image: { ...objImage, fileId: news.image.fileId } })
                      setIsDeleteImage(true)
                      setPreviewImage()
                    }}
                  />
                </PromotionUseStyled.UploadImage>
                <Typography className='mt-1' sx={{ fontSize: 16, color: colors.disabledGray }}>({t('NEWS.FORM.IMAGE_SIZE')})</Typography>
              </Grid>
              <Grid item className='w-100'>
                <Typography className='mt-2' sx={{ fontSize: 18, fontWeight: 500 }}>{t('NEWS.FORM.CONTENT')}</Typography>
              </Grid>
              <Grid item className='w-100'>
                {(newsId && news.content) && (
                  <InputTextEditor
                    value={news.content}
                    setValue={(val: string) => setNews({ ...news, content: val })}
                  />
                ) || <></>}
                {(!newsId) && (
                  <InputTextEditor
                    setValue={(val: string) => setNews({ ...news, content: val })}
                  />
                ) || <></>}
              </Grid>
            </Grid>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}
