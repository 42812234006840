import React, { useEffect, useState } from 'react'
import * as UseStyled from 'features/finance/payment/style/payment-style'
import CollapseContainer from 'component/Collapse/container'
import PaymentQueue from 'features/finance/payment/payment-queue'
import PaymentList from 'features/finance/payment/payment-list'
import { getBranch } from 'utils/app.utils'
import AboutUs from 'api/admin/clinic-manage/about.api'


export default function Payment() {
  const branchId = getBranch()
  const [branch, setBranch] = useState<any>()


  const loadBranchInfo = () => {
    AboutUs.detailClinic(Number(branchId))
        .then(({ data }) => {
          setBranch(data)
        })
        .catch((e) => { return })
  }
  
  useEffect(() => {
    loadBranchInfo()
  },[branchId])

  return (
    <div className={'h-100'}>
      <UseStyled.PaymentQueueLayout className={'h-100'}>
        <CollapseContainer
          styleComponentLeft={{ height: '100%' }}
          styleComponentRight={{ height: '100%' }}
          componentLeft={
            <UseStyled.FragmentLeft className="h-100 p-3">
              <PaymentQueue branch={branch} branchId={branchId} />
            </UseStyled.FragmentLeft>
          }
          componentRight={
            <UseStyled.FragmentRight className="h-100 py-3">
              <PaymentList branchId={branchId} />
            </UseStyled.FragmentRight>
          }
        />
      </UseStyled.PaymentQueueLayout>
      {/* <ModalCustomNotification /> */}
    </div>
  )
}
