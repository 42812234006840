import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface XrayFilmTypeInterface {
  xrayFilmTypeName: string
  status: string
}

export default class XrayFilmTypeApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('xray-film-types', { params: { ...props } }).then((res) => res)
  }

  static create(playload: any) {
    return this.api.post('xray-film-types', playload)
  }

  static findById(id: number): Promise<XrayFilmTypeInterface> {
    return this.api.get(`xray-film-types/${id}`)
  }

  static update(id: number, body: XrayFilmTypeInterface) {
    return this.api.patch(`xray-film-types/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`xray-film-types/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`xray-film-types/${id}`)
  }
}
