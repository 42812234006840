import React, { useCallback, useEffect, useState } from 'react'
import { InventoryTabs } from '../form-inventory'
import { useTranslation } from 'react-i18next'
import { SORT_TYPE } from 'constants/common'
import TableCustom from 'component/Table/TableCustom'
import * as UseStyled from 'features/warehouses/inventory/styled'
import { Box, TableCell, TableRow } from '@mui/material'
import Loading from 'component/Loading'
import { numberFormat, toBuddhistYear } from 'utils/app.utils'
import TableRowCommon from 'component/Table/TableRowCommon'
import moment from 'moment'
import _ from 'lodash'
import { colors } from 'constants/theme'
import InventoryApi from 'api/warehouses/inventory.api'


const HistoryDispose = (props: InventoryTabs) => {
    const { t } = useTranslation()

    const [dataDispose, setDataDispose] = useState<any>([])
    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('date')
    const [sortType, setSortType] = useState<SORT_TYPE>(SORT_TYPE.DESC)
    // findDisposeLot

    const loadData = useCallback((id: number) => {
        setLoading(true)
        let condition: any = { id: id }
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }

        InventoryApi.findDisposeLot(id, condition)
            .then(({ data, headers }) => {
                setDataDispose(data)
                setRowCount(headers['x-total'])
            })
            .catch(() => { return })
            .finally(() => setLoading(false))
    }, [page, pageLimit])

    useEffect(() => {
        if (props.inventory?.itemId) loadData(props.inventory?.itemId)
    }, [loadData]);
    
    const handleChangeRowsPerPage = (limit: number) => {
        setPageLimit(limit)
    }

    const handleChangePage = (val: number) => {
        setPage(val)
    }

    const onRequestSort = (sortByVal: string, sortTypeVal: SORT_TYPE) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const headCells = [
        { id: 'no', disablePadding: false, label: '#',width: '80px' },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.LOT_NO'),class: 'text-nowrap',width: '200px'},
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.EXPIRE_DATE'),class: 'text-nowrap',width: '150px'},
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.COUNT_DISPOSE'),class: 'text-nowrap',width: '150px'},
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.PRICE_DISPOSE'),class: 'text-nowrap',width: '150px'},
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.TOTAL_DISPOSE'),class: 'text-nowrap',width: '150px'},
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.REMARK'),class: 'text-nowrap',width: '150px'},
        { id: 'updatedBy', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.UPDATED_BY'),class: 'text-nowrap',width: '200px' },
    ]

    const renderData = (objData: any, no: number) => {
        const { lotNo, expireDate, qty,remark,price,updatedBy,updatedAt,total } = objData

        no = page * pageLimit - pageLimit + no + 1
        const objRenderData = {
            key: no.toString(),
            id: no.toString(),
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: no },
                { option: 'TEXT', align: 'left', label: lotNo || '-' },
                { option: 'TEXT', align: 'left', label: expireDate ? toBuddhistYear(moment(expireDate), 'DD/MM/YYYY') : '-' },
                { option: 'TEXT', align: 'center', label: numberFormat(qty, 0, 0) },
                { option: 'TEXT', align: 'center', label: price || 0 },
                { option: 'TEXT', align: 'center', label: total || 0 },
                { option: 'TEXT', align: 'right', label: remark || '-' },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy , updatedAt: updatedAt || '-' } },
            ],
        }

        return <TableRowCommon {...objRenderData} />
    }

    return (
        <UseStyled.ResponsiveTable>
            <TableCustom
                page={page}
                pageLimit={pageLimit}
                sortType={sortType}
                sortBy={sortBy}
                rowCount={rowCount}
                textEmptyData={t('REGISTER.NO_ITEM')}
                onSort={onRequestSort}
                setPageLimit={handleChangeRowsPerPage}
                setPage={handleChangePage}
                headCells={headCells}
                rowsData={[
                    !loading ?
                        (
                            _.isEmpty(dataDispose) ? (
                                <TableRow>
                                    <TableCell align="center" scope="row" colSpan={_.size(headCells)}>
                                        {t('REGISTER.NO_ITEM')}
                                    </TableCell>
                                </TableRow>
                            ) :
                                _.map(dataDispose, (quantity, index: number) => {
                                    return renderData(quantity, index)
                                })
                        ) : (
                            <TableRow>
                                <TableCell align="center" scope="row" colSpan={_.size(headCells)}>
                                    <Box className='mx-auto' sx={{ maxWidth: 'max-content' }}>
                                        <Loading show={true} type='softLoading' />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )
                ]}
                tableFixedWidth
            />
        </UseStyled.ResponsiveTable>
    )
}

export default HistoryDispose