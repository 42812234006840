import { menuThirds } from 'constants/menus'
import Position from 'features/setting/personnel-managements/position/position'
import Roles from 'features/setting/personnel-managements/roles/roles'
import RoleForm from 'features/setting/personnel-managements/roles/form-roles'
import ReportPermission from 'features/setting/personnel-managements/report-permission/report-permission'
import FormReportPermission from 'features/setting/personnel-managements/report-permission/form-report-permission'

const routePersonnelManagements = [
  {
    ...menuThirds.POSITION,
    component: Position,
    appBar: true
  },
  {
    key: menuThirds.ROLE.key,
    name: menuThirds.ROLE.name,
    textName: `แก้ไข ${menuThirds.ROLE.name}`,
    active: false,
    path: menuThirds.ROLE.path + '/update',
    component: RoleForm,
    appBar: true
  },
  {
    ...menuThirds.ROLE,
    component: Roles,
    appBar: true
  },
  {
    ...menuThirds.REPORT_PERMISSION,
    component: ReportPermission,
    appBar: true
  },
  {
    ...menuThirds.REPORT_PERMISSION,
    key: menuThirds.REPORT_PERMISSION.key,
    textname: `เพิ่ม ${menuThirds.REPORT_PERMISSION.name}`,
    active: false,
    path: menuThirds.REPORT_PERMISSION.path + '/create',
    component: FormReportPermission,
    appBar: true
  },
  {
    ...menuThirds.REPORT_PERMISSION,
    key: menuThirds.REPORT_PERMISSION.key,
    textname: `แก้ไข ${menuThirds.REPORT_PERMISSION.name}`,
    active: false,
    path: menuThirds.REPORT_PERMISSION.path + '/update',
    component: FormReportPermission,
    appBar: true
  },
]

export default routePersonnelManagements
