import { useState, useEffect, useCallback } from 'react'
import { Box, TableRow, TableCell } from '@mui/material'
import { InventoryTabs } from 'features/warehouses/inventory/form-inventory'
import _ from 'lodash'
import moment from 'moment'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** ROUTE */
import { routeName } from 'routes/routes-name'

/** API */
import InventoryApi from 'api/warehouses/inventory.api'

/** COMPONENT */
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import Loading from 'component/Loading'
import StatusSelect from 'features/finance/income-expenses/statusSelector-income-expenses'

/** UTILE */
import { toBuddhistYear } from 'utils/app.utils';
import { APPROVE_STATUS } from 'api/warehouses/adjustment.api'
import { colors } from 'constants/theme'

/** STYLE */
import * as UseStyled from 'features/warehouses/inventory/styled'

export default function HistoryAdjustmentTab(props: InventoryTabs) {
    const { t } = useTranslation()

    const [historyAdjustment, setHistoryAdjustment] = useState([]);
    const [loading, setLoading] = useState(false);

    /** PAGINATION  */
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)

    const loadData = useCallback((id: number) => {
        setLoading(true)
        let condition: any = { id: id }
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }

        InventoryApi.findAdjustmentHistoryById(id, condition)
            .then(({ data, headers }) => {
                setHistoryAdjustment(data)
                setRowCount(headers['x-total'])
            })
            .catch(() => { return })
            .finally(() => setLoading(false))
    }, [page, pageLimit])

    const handleChangeRowsPerPage = (limit: number) => {
        setPageLimit(limit)
    }

    const handleChangePage = (val: number) => {
        setPage(val)
    }

    useEffect(() => {
        if (props.inventory?.itemId) loadData(props.inventory?.itemId)
    }, [loadData]);

    const headCells = [
        { id: 'no', disablePadding: false, label: '#', width: '80px' },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.DOCUMENT_DATE') },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.DOCUMENT_NUMBER') },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.LOT_NO') },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.CURRENT_QTY'), align: 'center' },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.NEW_QTY'), align: 'center' },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.ADD_QTY'), align: 'center' },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.DIFF_QTY'), align: 'center' },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.STATUS'), align: 'center', width: '110px' },
        { id: 'updatedBy', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.UPDATED_BY') },
    ]

    const renderData = (objData: any, no: number) => {
        const { adjustmentReceiptId, date, prefix, documentNo, lotNo, qtyCurrent, qtyNew, qtyAdd, qtyDiff, status, updatedBy, updatedAt, createdBy, createdAt } = objData

        no = page * pageLimit - pageLimit + no + 1
        const objRenderData = {
            key: no.toString(),
            id: no.toString(),
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: no, class: 'cursor-pointer' },
                { option: 'TEXT', align: 'left', label: date ? toBuddhistYear(moment(date), 'DD/MM/YYYY') : '-', class: 'cursor-pointer' },
                { option: 'TEXT', align: 'left', label: documentNo ? ((prefix && `${prefix}-${documentNo}`) || documentNo) : '-', class: 'cursor-pointer text-ellipsis' },
                { option: 'TEXT', align: 'left', label: lotNo || '-', class: 'cursor-pointer' },
                { option: 'TEXT', align: 'center', label: qtyCurrent || 0, class: 'cursor-pointer' },
                { option: 'TEXT', align: 'center', label: qtyNew || 0, class: 'cursor-pointer' },
                { option: 'TEXT', align: 'center', label: qtyAdd || 0, class: 'cursor-pointer' },
                { option: 'TEXT', align: 'center', label: qtyDiff || 0, class: 'cursor-pointer' },
                {
                    option: 'COMPONENT',
                    align: 'center',
                    class: 'py-1',
                    component: (
                        <Box
                            className={`mx-auto w-100 pe-none`}
                            sx={{
                                maxWidth: '85px',
                                '.MuiSelect-select': {
                                    padding: '0 !important',
                                    justifyContent: 'center !important'
                                },
                                '.MuiSvgIcon-root': {
                                    display: 'none'
                                },
                            }}>
                            <StatusSelect
                                label={''}
                                selectId="select-status"
                                value={status}
                                labelId="label-status"
                                statusSelectorBgColor={backgroundStatus(status)}
                                height={'32px'}
                            />
                        </Box>
                    )
                },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy || createdBy, updatedAt: updatedAt || createdAt } },
            ]
        }

        return <TableRowCommon  {...objRenderData} onClick={() => window.open(`${routeName.adjustment}/edit?id=${adjustmentReceiptId}`, '_blank')} />
    }

    const backgroundStatus = (status: APPROVE_STATUS) => {
        if (status === APPROVE_STATUS.APPROVE) return colors.approveStatus.approve
        else if (status === APPROVE_STATUS.PENDING) return colors.approveStatus.pending
        else if (status === APPROVE_STATUS.CANCEL) return colors.approveStatus.cancel
        else return colors.disabledLightGray
    }

    return (
        <UseStyled.ResponsiveTable>
            <TableCustom
                page={page}
                pageLimit={pageLimit}
                sortType={''}
                sortBy={''}
                rowCount={rowCount}
                textEmptyData={t('REGISTER.NO_ITEM')}
                onSort={() => { return }}
                setPageLimit={handleChangeRowsPerPage}
                setPage={handleChangePage}
                headCells={headCells}
                rowsData={[
                    !loading && (
                        _.isEmpty(historyAdjustment) && (
                            <TableRow>
                                <TableCell align="center" scope="row" colSpan={_.size(headCells)}>
                                    {t('REGISTER.NO_ITEM')}
                                </TableCell>
                            </TableRow>
                        ) ||
                        _.map(historyAdjustment, (history, index: number) => {
                            return renderData(history, index)
                        })
                    ) || (
                        <TableRow>
                            <TableCell align="center" scope="row" colSpan={_.size(headCells)}>
                                <Box className='mx-auto' sx={{ maxWidth: 'max-content' }}>
                                    <Loading show={true} type='softLoading' />
                                </Box>
                            </TableCell>
                        </TableRow>
                    )
                ]}
                tableFixedWidth
                tableMinWidth={1260}
            />
        </UseStyled.ResponsiveTable>
    )
}