import { useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { Grid, MenuItem, Typography, Box } from '@mui/material'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { CardSalesProps, handleChangeFilterSelect, renderFilterSelect } from 'features/dashboard/dashboard'
import FilterSelect from 'component/Select/FilterSelect'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'

/** UTILS || SLICE */
import { toBuddhistYear } from 'utils/app.utils'

/** API */
import { BranchInterface } from 'api/master/clinic.api'

/** STYLE */
import * as UseStyled from 'features/dashboard/useStyled'
import DashboardApi, { IFBranchesFilter, IFInventories } from 'api/dashboard.api'

export default function LowSaleProduct(props: CardSalesProps) {
  const { t } = useTranslation()

  const [branches, setBranches] = useState<BranchInterface[]>([]);
  const [branch, setBranch] = useState<(string | number)[]>(['ALL']);
  const [products, setProducts]: any = useState([]);

  const handleChangeSelectBranch = (e: any) => {
    setBranch(handleChangeFilterSelect(e))
  }

  useEffect(() => {
    setBranches(props.branches || [])
  }, [props.branches]);

  useEffect(() => {
    setBranch(props.branch || [])
  }, [props.branch]);

  const loadData = useCallback(async () => {
    const condition: IFBranchesFilter = { branchId: branch[0] === 'ALL' ? `` : branch.toString() }
    const res = await DashboardApi.findSafetyStock(condition)
    const newProducts: any = []
    setProducts([])
    if (res.status === 200) {
      const summeris: IFInventories[] = res.data
      for (const x of summeris) {
        newProducts.push({
          name: x.productName,
          qty: x.qty,
          unit: x.itemUnitName
        },)
      }
    }
    setProducts(newProducts)
  }, [branch,])

  useEffect(() => {
    loadData()
  }, [loadData])

  const headCells = [
    { id: 'text', disablePadding: false, label: t('DASHBOARD.SALE_PRODUCT_CHART.TABLE.NAME'), class: 'p-3' },
    { id: 'text', disablePadding: false, label: t('DASHBOARD.SALE_PRODUCT_CHART.TABLE.QTY'), width: '100px', class: 'p-3' },
    { id: 'text', disablePadding: false, label: t('DASHBOARD.SALE_PRODUCT_CHART.TABLE.UNIT'), width: '150px', class: 'p-3' }
  ]

  const renderData = (objData: any, no: number) => {
    const { name, qty, unit } = objData

    const objRenderData = {
      key: String(no),
      id: String(no),
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'left', label: name || '-', class: 'p-3' },
        { option: 'TEXT', align: 'left', label: qty || '-', class: 'p-3' },
        { option: 'TEXT', align: 'left', label: unit || '-', class: 'p-3' },
      ]
    }

    return <TableRowCommon {...objRenderData} />
  }

  return (
    <UseStyled.Card>
      <Box className='pt-3 px-3 px-xl-4' sx={{ borderBottom: `1px solid ${colors.lightGray}`, paddingBottom: '12px' }}>
        <Grid container spacing={1} alignItems={'center'}>
          <Grid item xs={6} md={6} lg={12} xl={7} className={'w-100'}>
            <UseStyled.Title type='title' sx={{ flex: 'none' }}>
              {t('DASHBOARD.LOW_SALE_PRODUCT')}
              <span className='pl-1 d-none d-md-inline-block'>({t('DASHBOARD.DATA_NOW_UPDATE')})</span>
            </UseStyled.Title>
          </Grid>
          <Grid item xs={6} md={6} lg={6} xl={5} className={'w-100'}>
            <FilterSelect
              multiple
              label={t('')}
              labelId="label-branch"
              selectId="select-branch"
              onchange={handleChangeSelectBranch}
              renderValue={() => `${t('DASHBOARD.FILTER.BRANCH')}: ${renderFilterSelect('branch', branches, branch) === 'ALL' ? t('STATUS.ALL') : renderFilterSelect('branch', branches, branch)}`}
              value={branch}
              options={[
                <MenuItem
                  key="ALL"
                  value="ALL"
                  sx={{
                    '&.MuiMenuItem-root.Mui-selected': {
                      pointerEvents: _.includes(branch, 'ALL') ? `none` : 'auto',
                      backgroundColor: _.includes(branch, 'ALL') ? `${colors.themeSecondColor06} !important` : 'transparent',
                      color: _.includes(branch, 'ALL') ? `${colors.themeSecondColor} !important` : `${colors.textPrimary}`
                    }
                  }}>
                  {t('STATUS.ALL')}
                </MenuItem>,
                _.map(props.branches, (val: BranchInterface, indexBranch: number) => {
                  return (
                    <MenuItem
                      key={indexBranch}
                      value={val.branchId}
                      sx={{
                        '&.MuiMenuItem-root.Mui-selected': {
                          backgroundColor: _.includes(branch, val.branchId) ? `${colors.themeSecondColor06} !important` : 'transparent',
                          color: _.includes(branch, val.branchId) ? `${colors.themeSecondColor} !important` : `${colors.textPrimary}`
                        }
                      }}>
                      {val.branchName}
                    </MenuItem>
                  )
                })
              ]}
              formControlClass={'not-max-width'}
              classesOption="style-select-doctors"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className='p-3 p-xl-4'>
        <TableCustom
          page={1}
          pageLimit={0}
          sortType={'ASC'}
          sortBy={''}
          rowCount={0}
          onSort={() => { return }}
          setPageLimit={() => { return }}
          setPage={() => { return }}
          headCells={headCells}
          rowsData={
            _.map(products, (product, index: number) => {
              return renderData(product, index)
            })
          }
          hidePagination
          tableFixedWidth
        />
        <Typography className='pt-2' sx={{ fontSize: 14, color: `${colors.textExtraLightGray} !important` }}>{t('DASHBOARD.SALE_PRODUCT_CHART.LAST_UPDATE', { date_time: toBuddhistYear(moment(), 'D MMMM YYYY HH.mm') })}</Typography>
      </Box>
    </UseStyled.Card>
  )
}
