import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface ProductSymptomInterface {
    group: string
    detail: string
    detailEn: string
    status?: string
}

export default class ProductSymptomsApi extends BaseAPI {
    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get('product-symptoms', { params: { ...props } }).then((res) => res)
    }
    static create(payload: any) {
        return this.api.post('product-symptoms', payload)
    }
    static findById(id: number): Promise<ProductSymptomInterface> {
        return this.api.get(`product-symptoms/${id}`)
    }
    static update(id: number, body: ProductSymptomInterface) {
        return this.api.patch(`product-symptoms/${id}`, body)
    }
    static updateStatus(id: number, status: string): Promise<any> {
        return this.api.patch(`product-symptoms/${id}/status`, { id, status })
    }
    static remove(id: number) {
        return this.api.delete(`product-symptoms/${id}`)
    }
}
