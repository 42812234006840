import { Box } from '@mui/material';

/** STYLE */
import { styled } from '@mui/material/styles'
import * as TreatmentsUseStyled from 'features/treatments/UseStyle'

export const LayoutHistoryTreatment = styled(Box)(({ theme }) => ({
  'div[role="history treatment tab"][name="image"]': {
    height: '100%',
    '> div': {
      height: '100%',
      '> div': {
        minHeight: 'auto',
        maxHeight: '100%',
        '.image-panel': {
          minHeight: 'auto !important'
        }
      }
    }
  },
}))

export const CustomTabs = styled(TreatmentsUseStyled.CustomTabs)(({ theme }) => ({
  '.btn-tab': {
    span: {
      lineHeight: 1.4
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 8,
      paddingRight: 8
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '20%',
    },
  }
}))
