import { makeStyles } from '@material-ui/core'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Box, Button, styled } from '@mui/material'
import { colors } from 'constants/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',

    backgroundColor: colors.white,
    '& .btn-day': {
      backgroundColor: colors.lightGray,
      color: colors.black
    },
    '& .btn-day.active.open': {
      backgroundColor: colors.themeSecondColor10,
      color: colors.themeSecondColor
    },
    '& .btn-day.active.close': {
      backgroundColor: colors.statusInactive10,
      color: colors.statusInactive
    },
    '& .ant-checkbox + span': {
      fontSize: '1rem'
    },
    '& .bank-logo': {
      width: '40px',
      height: '40px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      margin: '0.25rem',
      boxShadow: `0.25rem 0.25rem 0.25rem ${colors.disabledLightGray}`,
      border: `0.1rem solid ${colors.disabledLightGray}`
    },
    '& .bank-logo.active': {
      border: `0.15rem solid ${colors.themeSecondColor}`
    }
  },
  btnAddRecommends: {}
}))

export const UploadImage = styled('div')(({ theme }) => ({
  margin: 'auto',
  maxWidth: 250,
  '& > div.MuiFormControl-root': {
    position: 'relative',
    paddingTop: '100%',
    '& > div': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      padding: '0 !important',
      width: 'auto !important',
      height: 'auto !important',
      'img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
      }
    }
  },
  [theme.breakpoints.down('xs')]: {
    maxWidth: 180,
  }
}))

export const ButtonTab = styled('a')(({ theme }) => ({
  borderRadius: 0,
  width: 150,
  '&.error': {
    color: colors.statusInactive,
    '&:after': {}
  }
}))

export const BankType = styled('div')(({ theme }) => ({
  '.bank-logo': {
    width: '35px',
    height: '35px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin: '0.25rem',
    border: `2px solid ${colors.extraLightGray}`,
    boxShadow: 'none',
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    }
  },
  '.bank-logo.active': {
    boxShadow: `0 0 5px 0 ${colors.themeSecondColor}`,
    borderColor: colors.themeSecondColor
  }
}))

export const BtnDay = styled('div')(({ theme }) => ({
  '&.btn-day': {
    backgroundColor: colors.lightGray,
    color: colors.textPrimary,
    height: '32px',
    width: '32px',
    borderRadius: '100px',
    fontSize: '16px'
  },
  '&.btn-day.active.open': {
    backgroundColor: colors.themeSecondColor10,
    color: colors.themeSecondColor
  },
  '&.btn-day.active.close': {
    backgroundColor: colors.statusInactive10,
    color: colors.statusInactive
  },
}))

export const CustomTable = styled('div')(({ theme }) => ({
  '.MuiTable-root': {
    '.MuiTableRow-head': {
      '.MuiTableCell-head': {
        fontSize: 16,
        fontWeight: 500,
        "&.title-work": {
          color: colors.themeMainColor,
          fontSize: '18px !important',
          borderBottomColor: `${colors.lightGray} !important`,
          fontWeight: 600,
        }
      }
    },
  }
}))

export const UploadReceiptImageBranch = styled('div')(({ theme }) => ({
  '> div': {
    width: 180,
    height: 180,
    '> div:first-of-type': {
      padding: '0 !important',
      maxWidth: '180px !important',
      maxHeight: '180px !important',
      width: '180px !important',
      height: '180px !important',
      flex: 'none !important',
      'img:not(.upload-image)': {
        width: 180,
        height: 180,
        objectFit: 'contain'
      }
    }
  }
}))

export const FormFormatReceipt = styled(FormControlLabel)(({ theme }) => ({
  '.MuiTypography-root': {
    fontWeight: 500
  },
  '&.active': {
    backgroundColor: colors.themeSecondColor06,
    '.MuiTypography-root': {
      color: colors.themeSecondColor
    }
  }
}))
export const FormFormatSticker = styled(FormControlLabel)(({ theme }) => ({
  '.MuiTypography-root': {
    fontWeight: 500
  },
  '&.active': {
    backgroundColor: colors.themeSecondColor06,
    '.MuiTypography-root': {
      color: colors.themeSecondColor
    }
  }
}))

export const ImgFormatReceipt = styled('div')(({ theme }) => ({
  backgroundColor: colors.lightGray,
  width: '100%',
  paddingTop: '100%',
  position: 'relative',
  img: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  }
}))
export const ImgFormatSticker = styled('div')(({ theme }) => ({
  backgroundColor: colors.lightGray,
  width: 'auto',
  padding: '10px 5px',
  img: {
    position: 'flex',
    margin: 'auto',
    width: 250,
    height: 'auto',
    objectFit: 'contain'
  }

}))

export const PreviewReceipt = styled(Box)(({ theme }) => ({
  position: 'fixed',
  zIndex: 2000,
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  '.preview-wrap': {
    position: 'relative',
    width: '50%',
    height: '100%',
    margin: 'auto',
    padding: '1rem',
    zIndex: 2,
  },
  '.preview-backdrop': {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: colors.black16
  }
}))
export const PreviewSticker = styled(Box)(({ theme }) => ({
  position: 'fixed',
  zIndex: 2000,
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  '.preview-wrap': {
    position: 'relative',
    width: '50%',
    height: '100%',
    margin: 'auto',
    padding: '1rem',
    zIndex: 2,
    img: {
      width: '100% !important',
      height: 'auto !important',
    }
  },
  '.preview-backdrop': {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: colors.black16
  }
}))

export const ButtonAdd = styled(Button)(({ theme }) => ({
  fontSize: 16,
  color: colors.themeSecondColor,
  backgroundColor: 'transparent !important',
  fontWeight: 400,
  span: {
    fontSize: 20
  },
  '&:hover': {
    fontWeight: 600
  }
}))

export const FormSticker = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: colors.themeSecondColor10,
  padding: '8px 16px',
  marginBottom: '8px',
  '.sticker-header': {
    fontSize: '18px',
    fontWeight: '500'
  }
}))


export default useStyles
