import BaseAPI from 'api/api'
import { ProductStickerType } from 'app/slice/treatment.slice'

const path = 'treatments'

export interface AllProductType {
    group: 'ALL' | 'MEDICINE' | 'PRODUCT'
}

export interface AllProduct {
    page: number
    pageLimit: number
    branchId: number
    userId: number
    group: 'ALL' | 'MEDICINE' | 'PRODUCT'
    search?: string
    isBookmark?: string
    typeId?: number
}

export interface ProductType {
    productTypeId: number
    productTypeName: string
    productTypeNameEn: string
}

export default class TreatmentProductApi extends BaseAPI {
    static findAllProductType(props: AllProductType): Promise<any> {
        return this.api.get(`${path}/product-types`, { params: { ...props } })
    }

    static findAllProduct(props: AllProduct): Promise<any> {
        return this.api.get(`${path}/products`, { params: { ...props } })
    }

    static findAllProductOutOfStock(props: AllProduct): Promise<any> {
        return this.api.get(`${path}/products-out-stock`, { params: { ...props } })
    }

    /** STICKER */
    static findStickerById(patientCaseId: number, itemid: number): Promise<any> {
        return this.api.get(`${path}/sticker/${patientCaseId}/${itemid}`)
    }

    static updateStickerById(patientCaseId: number, itemid: number, payload: ProductStickerType): Promise<any> {
        return this.api.patch(`${path}/sticker/${patientCaseId}/${itemid}`, payload)
    }

    static findAllSticker(patientCaseId: number): Promise<any> {
        return this.api.get(`${path}/sticker/${patientCaseId}`)
    }

    static addMedicineInformation(payload: any): Promise<any> {
        return this.api.post(`${path}/medicine-information`, payload)
    }

    static editMedicineInformation(medicineInformationId: number, payload: any): Promise<any> {
        return this.api.patch(`${path}/medicine-information/${medicineInformationId}`, payload)
    }

    static getMedicineInformation(itemId: number): Promise<any> {
        return this.api.get(`${path}/medicine-information/${itemId}`)
    }
}