import { useCallback, useEffect, useState } from 'react'
import { ExportProps } from "features/report/report-constants"
import moment from "moment"
import _ from 'lodash'
import { Box, Typography, Divider, TableRow, TableCell, TableSortLabel } from '@mui/material'
import { numberFormat, toBuddhistYear } from "utils/app.utils"

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import EmptyData from "features/report/component/emptyData"

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { colors } from 'constants/theme'
import NewPatientListTotal from "./NewPatientListTotal"
import { dateTimeToView } from "utils/date.utils"

import { UseSnCode } from 'utils/useSnCode'
interface FcComponent {
    page: number
    pageLimit: number
    rowCount: number
    sortBy: string
    sortType: string
    data: any
    dataExport: any
    onRequestSort: (sortBy: string, sortType: string) => void
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
    componentRef: any
    headCellsSeconds: any
    date: any
}
export default function NewPatientList(props: FcComponent) {
    const { t } = useTranslation()

    const [snType, setSnType] = useState(false);

    const handleSnCode = useCallback(() => {
        const fetchData = async () => {
            const sn = await UseSnCode();
            setSnType(sn);
        };

        fetchData();

    }, [snType]);

    useEffect(() => {
        handleSnCode()
    }, [])
    const headCells = [
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.NEW_PATIENT.#NO', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.NEW_PATIENT.DATE', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.NEW_PATIENT.TIME', align: 'center', class: 'text-nowrap', width: '90px' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.NEW_PATIENT.BRANCH', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.NEW_PATIENT.VN_NUMBER', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.NEW_PATIENT.PATIENT_CN_NUMBER', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.NEW_PATIENT.PATIENT_SN_CODE', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.NEW_PATIENT.PATIENT_FULLNAME', align: 'center' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.NEW_PATIENT.GENDER', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.NEW_PATIENT.PHONE', align: 'center', class: 'text-nowrap', },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.NEW_PATIENT.ACCESS_CHANAL', align: 'center' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.NEW_PATIENT.FIRST_BILL', align: 'center' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.NEW_PATIENT.PAY_FIRST_BILL', align: 'center', class: 'text-nowrap' },
    ]
    if (!snType) {
        headCells.splice(6, 1)
    }
    const renderData = (data: any, no: number, isExport = false) => {
        const num = props.page * props.pageLimit - props.pageLimit + no + 1;
        const commonStyle = isExport ? { fontSize: 9 } : {};

        const columns = [
            { option: 'TEXT', align: 'center', label: num, ...commonStyle && { style: commonStyle } },
            { option: 'TEXT', align: 'left', label: data.date, ...commonStyle && { style: commonStyle } },
            { option: 'TEXT', align: 'left', label: data.time, ...commonStyle && { style: commonStyle }, class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.branchName, ...commonStyle && { style: commonStyle }, class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.cnNumber, ...commonStyle && { style: commonStyle } },
            { option: 'TEXT', align: 'left', label: data.branchCnNumber, ...commonStyle && { style: commonStyle } },
            { option: 'TEXT', align: 'left', label: data.snCode ? data.snCode : "-", ...commonStyle && { style: commonStyle } },
            { option: 'TEXT', align: 'left', label: data.fullName, ...commonStyle && { style: commonStyle } },
            { option: 'TEXT', align: 'center', label: data.gender, ...commonStyle && { style: commonStyle } },
            { option: 'TEXT', align: 'center', label: data.phone, ...commonStyle && { style: commonStyle } },
            { option: 'TEXT', align: 'center', label: data.channelName, ...commonStyle && { style: commonStyle } },
            { option: 'TEXT', align: 'left', label: data.operativeName, ...commonStyle, style: isExport ? { textAlign: 'left', fontSize: 9 } : {} },
            { option: 'TEXT', align: 'right', label: numberFormat(data.totalPrice), style: isExport ? { textAlign: 'right', fontSize: 9 } : {} }
        ];
        if (!snType) {
            columns.splice(6, 1)
        }
        return (
            <TableRowCommon
                key={num.toString()}
                id={no.toString()}
                obj={data}
                columns={columns}
            />
        );
    }



    return (
        <>
            {
                (!_.isEmpty(props.data)) && (
                    <Box>
                        <UseStyled.ResponsiveReportTable>
                            <TableCustom
                                tableMinWidth={1920}
                                page={props.page}
                                pageLimit={props.pageLimit}
                                sortType={''}
                                sortBy={''}
                                rowCount={props.rowCount}
                                onSort={props.onRequestSort}
                                setPageLimit={props.handleChangeRowsPerPage}
                                setPage={props.handleChangePage}
                                headCells={headCells}
                                rowsData={[_.map(props.data.rows, (d: any, index: number) => {
                                    return renderData(d, index)
                                }),
                                <TableRow key={props.data.rows} className='row-summary'>
                                    <TableCell align='right' scope="row" colSpan={10}>

                                    </TableCell>
                                    <TableCell align='left' scope="row" colSpan={1}>
                                        {t('REPORT.TITLE.SUM')}
                                    </TableCell>
                                    <TableCell align="right" scope="row" colSpan={3}>
                                        {numberFormat(_.sumBy(props.data.rows, 'totalPrice'))}
                                    </TableCell>
                                </TableRow>
                                ]}
                                customScroll
                            />

                        </UseStyled.ResponsiveReportTable>
                        <Box className='mt-3 pl-xl-3 pr-xl-2 pb-4'>
                            <NewPatientListTotal
                                type={"html"}
                                date={props.page}
                                page={props.page}
                                pageLimit={props.pageLimit}
                                sortType={props.sortType}
                                sortBy={props.sortBy}
                                rowCount={props.rowCount}
                                data={props.data.summary}
                                onRequestSort={props.onRequestSort}
                                handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                                handleChangePage={props.handleChangePage}
                            />
                        </Box>
                    </Box>
                ) || <EmptyData />
            }
            <div className="print-show" ref={props.componentRef}>
                <NewPatientExport
                    headCells={t(`REPORT.REPORT_NAME.NEW_PATIENT`)}
                    componant={
                        <>
                            <table>
                                <tr>
                                    <TableSortLabel >
                                        <td style={{ fontSize: 10, marginLeft: '30px' }}>{t(`REPORT.FILTER.BRANCH`)} : </td>
                                        <td style={{ fontSize: 10 }}>{props.headCellsSeconds[0]}</td>
                                    </TableSortLabel>
                                    <TableSortLabel>
                                        <td style={{ fontSize: 10 }}>{t(`REPORT.FILTER.DATE_START`)} : </td>
                                        <td style={{ fontSize: 10 }}>{props.date[0] ? toBuddhistYear(moment(props.date[0]), 'DD/MM/YYYY') : '-'}</td>
                                    </TableSortLabel>
                                    <TableSortLabel>
                                        <td style={{ fontSize: 10 }}>{t(`REPORT.FILTER.DATE_END`)} : </td>
                                        <td style={{ fontSize: 10 }}>{props.date[1] ? toBuddhistYear(moment(props.date[1]), 'DD/MM/YYYY') : '-'}</td>
                                    </TableSortLabel>
                                    <TableSortLabel>
                                        <td style={{ fontSize: 10 }}>{t('REPORT.FILTER.SERVICE_ACCESS')} : </td>
                                        <td style={{ fontSize: 10 }}>{props.headCellsSeconds[2]}</td>
                                    </TableSortLabel>
                                </tr>
                            </table>
                            {_.map(_.groupBy(props.dataExport.rows, 'dateOrder'), (data: any, index: number) => {
                                return (
                                    <Box key={index}>
                                        <Box>
                                            <UseStyled.Styles className="pt-3">
                                                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '99%', border: 'none' }}>
                                                    <thead>
                                                        <tr>
                                                            {_.map(headCells, (val: any) => {
                                                                return (
                                                                    < td style={{ textAlign: val.align, fontSize: 10, fontWeight: 300 }}> {t(val.labelKey)}</td>
                                                                )
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ fontSize: 10 }}>
                                                        {data.length > 0 && [
                                                            _.map(data, (data: any, indexData: number) => {
                                                                return renderData(data, indexData, true)
                                                            }),
                                                            <TableRow key={props.data.rows}>
                                                                <TableCell align='right' scope="row" colSpan={10}>
                                                                </TableCell>
                                                                <TableCell align='left' scope="row" colSpan={1} style={{ fontSize: 10 }}>
                                                                    {t('REPORT.TITLE.SUM')}
                                                                </TableCell>
                                                                <TableCell align="right" scope="row" colSpan={1} style={{ fontSize: 10, textAlign: 'right' }}>
                                                                    {numberFormat(_.sumBy(props.dataExport.rows, 'totalPrice'))}
                                                                </TableCell>
                                                            </TableRow>
                                                        ]
                                                        }
                                                        {!data.length && (
                                                            <td colSpan={headCells.length}>
                                                                {t('NOT_FOUND')}
                                                            </td>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </UseStyled.Styles >
                                        </Box>
                                    </Box>
                                )
                            })}
                            <Box className='mt-3 pl-xl-3 pr-xl-2 pb-4 ml-3'>
                                <NewPatientListTotal
                                    type={"export"}
                                    date={props.date}
                                    page={props.page}
                                    pageLimit={props.pageLimit}
                                    sortType={props.sortType}
                                    sortBy={props.sortBy}
                                    rowCount={props.rowCount}
                                    data={props.dataExport.summary}
                                    onRequestSort={props.onRequestSort}
                                    handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                                    handleChangePage={props.handleChangePage}
                                />
                            </Box>

                        </>

                    } />
            </div>
        </>)

}
export function NewPatientExport(props: ExportProps) {
    return (
        <>
            <div className='large' style={{ fontSize: 14 }} >
                {props.headCells}
                {props.headCellsSeconds}
            </div>
            {props.componant}
        </>
    )
}