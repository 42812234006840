import { Modal } from 'react-bootstrap'
import LoadingButton from '@mui/lab/LoadingButton'
import { colors } from 'constants/theme'
import { useDispatch, useSelector } from 'react-redux'
import { resetModal } from 'app/slice/modal.slice'
import CloseButton from 'react-bootstrap/CloseButton'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: colors.white,
    padding: '20px 15px',
    boxShadow: `0 .125rem .25rem ${colors.black08} !important`
  },
  modal: {
    '& .modal-xl': {
      maxWidth: '1500px'
    },
    '& .modal-content': {
      padding: 15,
      borderRadius: 10,
      position: 'relative'
    },
    '& .modal-title h3': {
      textAlign: 'center'
    },
    '& .css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled': {
      opacity: 0.5
    },
    '& .modal-footer': {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse'
      }
    },
    '& .modal-footer $div': {
      width: 'fit-content',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    }
  },
  containerButton: {
    display: 'flex',
    marginLeft: 'auto'
  },
  btnCancel: {
    width: '100%',
    color: `${colors.themeSecondColor} !important `,
    border: `solid 1px ${colors.themeSecondColor}  !important`
  },
  btnConfirm: {
    width: 'fit-content  !important',
    backgroundColor: `${colors.themeSecondColor} !important`,
    color: `${colors.white} !important`,
    '&:hover': {
      backgroundColor: `${colors.themeSecondColor} !important`,
      color: `${colors.white} !important`
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px !important'
    }
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20
  }
}))

type ModalCustomFormProps = {
  onSubmit: () => void
  onReset: () => void
  title: string
  textBtnConfirm?: string
  textBtnCancel?: string
  component?: any
  modalStyle?: any
  size?: 'lg' | 'sm' | 'xl'
}

export default function ModalCustomForm(props: ModalCustomFormProps) {
  const isShow = useSelector((state: any) => state.modal.isShow)
  const isSubmit = useSelector((state: any) => state.modal.isSubmit)
  const dispatch = useDispatch()
  const classes = useStyles()

  return (
    <Modal
      className={`${classes.modal} ${props.modalStyle}`}
      returnFocusAfterClose={false}
      size={props.size || 'sm'}
      show={isShow}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        className="border-0 mx-auto my-0 px-auto pb-0"
      >
        <Modal.Title id="contained-modal-title-vcenter">
          <p style={{ fontSize: '24px' }}>{props.title}</p>
        </Modal.Title>
        <CloseButton
          className={classes.closeButton}
          onClick={() => {
            dispatch(resetModal())
          }}
        />
      </Modal.Header>
      <Modal.Body>{props.component}</Modal.Body>
      <Modal.Footer className="flex-center justify-content-center  border-0">
        <div className={classes.containerButton}>
          <LoadingButton
            fullWidth
            className={`${classes.btnConfirm} btn-submit mr-2 `}
            loading={isSubmit}
            loadingPosition="start"
            disabled={isSubmit}
            onClick={() => {
              props.onSubmit()
            }}
          >
            {props.textBtnConfirm}
          </LoadingButton>
          <LoadingButton
            fullWidth
            className={`${classes.btnConfirm} `}
            loading={isSubmit}
            loadingPosition="start"
            disabled={isSubmit}
            onClick={() => {
              props.onReset()
            }}
          >
            {props.textBtnCancel}
          </LoadingButton>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
