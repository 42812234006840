import { Box } from '@mui/system'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import Paper from '@mui/material/Paper'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { TableSortLabel, makeStyles } from '@material-ui/core'
import { colors } from 'constants/theme'
import { useTheme } from '@mui/material/styles'
import InputCheckbox from 'component/Input/InputCheckbox'
import { Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Code from '@mui/icons-material/Code'
import { styled } from '@mui/material'
import { numberFormat } from 'utils/app.utils'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.borderInput
    }
  },
  table: {
    border: '1px solid transparent',
    '& .MuiTableBody-root': {
      '& .MuiTableCell-root': {
        verticalAlign: 'top',
        paddingTop: 16,
        paddingBottom: 8,
        '&.update-by': {
          paddingBottom: 0
        }
      }
    }
  },
  header: {
    borderRight: `1px solid ${colors.themeMainColor} !important`,
    borderLeft: `1px solid ${colors.themeMainColor} !important`,
    color: `${colors.white} !important`,
    '& $th': {
      backgroundColor: colors.themeMainColor,
      color: `${colors.white} !important`,
      padding: 15
      // border: `1px solid ${colors.themeMainColor} !important`
    },
    '& .MuiTableCell-root.MuiTableCell-head': {
      color: `${colors.white} !important`,
      fontSize: 16,
      fontWeight: 500,
      border: 'none',
      '&.MuiTableCell-alignRight': {
        flexDirection: 'row'
      }
    },
    '& .MuiTableSortLabel-root': {
      color: `${colors.white} !important`
    },
    '& .MuiTableSortLabel-root:hover, & .MuiTableSortLabel-active': {
      color: `${colors.white} !important`,
      fontWeight: 600
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: `${colors.white} !important`
    }
  },
  body: {
    '&.sm-data-table': {
      borderRight: `1px solid ${colors.lightGray} !important`,
      borderLeft: `1px solid ${colors.lightGray} !important`,
      height: 'auto'
    },
    '& .MuiTableCell-root': {
      borderBottomColor: `${colors.lightGray} !important`,
      fontSize: 16
    },
    '& .MuiTableRow-root:hover .MuiTableCell-root, .MuiTableRow-root-active .MuiTableCell-root': {
      background: colors.extraLightGray
    }
  },
  pagination: {
    border: 'none !important',
    paddingRight: '0 !important',
    '& p': {
      margin: 0,
      color: colors.textLightGray
    },
    '& .MuiTablePagination-select': {
      fontSize: '14px',
      color: colors.textPrimary
    }
  },
  dropdown: {
    '& .MuiCheckbox-root': {
      margin: '-9px 0'
    }
  },
  dropdownToggle: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    color: `${colors.white} !important`,
    boxShadow: 'none !important',
    '&:focus, &:hover, &:active': {
      boxShadow: 'none !important'
    },
    [theme.breakpoints.up('xs')]: {
      right: '-1.5rem !important'
    }
  },
  dropdownToggleShow: {
    backgroundColor: `${colors.white} !important`,
    zIndex: 5,
    '&:hover': {
      backgroundColor: `${colors.white} !important`
    },
    [theme.breakpoints.up('lg')]: {
      left: '-35px !important'
    }
  },
  iconButton: {
    border: `1px solid ${colors.themeSecondColor} !important`,
    color: `${colors.themeSecondColor}  !important`,
    margin: '2.5px !important',
    '&.MuiButtonBase-root:not(.Mui-disabled)': {
      opacity: 1
    },
    borderRadius: '4px !important',
    width: 29.7,
    height: 29.7,
    fontSize: '16px !important',
    '&:not(.Mui-disabled) $svg': {},
    '&.Mui-disabled': {
      opacity: 0.7
    },
    '&.MuiButtonBase-root.active': {
      backgroundColor: `${colors.themeSecondColor}  !important`,
      color: `${colors.white}  !important`
    },
    '&.MuiButtonBase-root:hover': {
      backgroundColor: `${colors.themeSecondColor}  !important`,
      color: `${colors.white}  !important`
    },
    '&.MuiButtonBase-root.active $svg': {
      color: `${colors.white}  !important`,
      width: 29.7,
      height: 29.7
    }
  }
}))

export function TablePaginationActions(props: any) {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()

  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event: any) => {
    onPageChange(event, 1)
  }

  const handleBackButtonClick = (event: any) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: any) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event: any) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage)))
  }

  const handleGoPageButtonClick = (event: any, pageClick: number) => {
    onPageChange(event, pageClick)
  }

  const renderPage = (countPage: number) => {
    const htmlPageNo: any = []
    let arrayPage: number[] = [1, 2, 3, countPage]
    if (page > 3) {
      if (page !== countPage) arrayPage = [page - 1, page, page + 1, countPage]
      else if (page === countPage) arrayPage = [page - 2, page - 1, page, countPage]
    }

    for (let index = 0; index < countPage; index++) {
      const pageRun: number = index + 1
      if (arrayPage.includes(pageRun))
        htmlPageNo.push(
          <IconButton className={`${classes.iconButton} ${index + 1 === page ? 'active' : ''}`} onClick={(e) => handleGoPageButtonClick(e, index + 1)} aria-label={String(index + 1)}>
            <div>{index + 1}</div>
          </IconButton>
        )
      else if ((pageRun === 2 && !arrayPage.includes(pageRun)) || (pageRun === countPage - 1 && !arrayPage.includes(pageRun)))
        htmlPageNo.push(
          <IconButton className={`${classes.iconButton} ${index + 1 === page ? 'active' : ''}`} aria-label={String(index + 1)}>
            <div>...</div>
          </IconButton>
        )
    }
    return htmlPageNo
  }

  return (
    <Box className="flex-end" sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton className={classes.iconButton} onClick={handleFirstPageButtonClick} disabled={page === 1} aria-label={t('TABLE.ICON.INDEX')}>
        {theme.direction === 'rtl' ? <LastPageIcon fontSize="small" /> : <FirstPageIcon fontSize="small" />}
      </IconButton>
      <IconButton className={classes.iconButton} onClick={handleBackButtonClick} disabled={page === 1} aria-label={t('TABLE.ICON.PREVIOUS')}>
        {theme.direction === 'rtl' ? <KeyboardArrowRight fontSize="small" /> : <KeyboardArrowLeft fontSize="small" />}
      </IconButton>

      {renderPage(Math.ceil(count / rowsPerPage))}
      <IconButton className={classes.iconButton} onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage)} aria-label={t('TABLE.ICON.NEXT')}>
        {theme.direction === 'rtl' ? <KeyboardArrowLeft fontSize="small" /> : <KeyboardArrowRight fontSize="small" />}
      </IconButton>
      <IconButton className={classes.iconButton} onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage)} aria-label={t('TABLE.ICON.FINAL')}>
        {theme.direction === 'rtl' ? <FirstPageIcon fontSize="small" /> : <LastPageIcon fontSize="small" />}
      </IconButton>
    </Box>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
}

export default function TableCustom(props: any) {
  const { t } = useTranslation()
  const classes = useStyles()

  const rowCount = props.rowCount || 0
  const page = props.page || 1
  const pageLimit = props.pageLimit
  const sortBy = props.sortBy
  const sortType = props.sortType
  const rowsPerPageOptions = [5, 10, 25, 100]

  const headCells = props.headCells
  const rowsData = props.rowsData
  const noHeader = props.noHeader
  const tooltip = props.tooltip
  const newArray = Array.from({ length: props.rowHeadCell || 1 }, (value, index) => index)
  const emptyRows = 0
  const handleRequestSort = (event: any, property: any) => {
    const isAsc = sortBy === property && sortType === 'ASC'
    props.onSort(property, isAsc ? 'DESC' : 'ASC')
  }

  const createSortHandler = (property: any) => (event: any) => {
    handleRequestSort(event, property)
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.setPageLimit(parseInt(event.target.value, 10))
    props.setPage(1)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.setPage(newPage)
  }

  const defaultLabelDisplayedRows = () => {
    const from = page * pageLimit - pageLimit + 1
    const to = page * pageLimit
    const count = Number(rowCount)
    return `${numberFormat(from, 0, 0)}-${count <= to ? numberFormat(count, 0, 0) : numberFormat(to, 0, 0)} ${t('PAGINATION.LIST')} ${t('PAGINATION.FROM')} ${numberFormat(count, 0, 0)} ${t('PAGINATION.LIST')}`
  }

  const handleRowsPerPageOptions = useCallback(() => {
    if (!rowsPerPageOptions.includes(pageLimit)) {
      const newData = [...rowsPerPageOptions, pageLimit].sort((a: number, b: number) => a - b)
      return newData
    } else {
      return rowsPerPageOptions
    }
  }, [])

  return (
    <>
      <TableContainer component={Paper} className={`${props.customScroll ? 'custom-scroll' : ''}`} style={{ boxShadow: 'none', minHeight: 'calc(100% - 65px)', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
        <Table size="small" className={`${classes.table}`} sx={{ minWidth: props.tableMinWidth ? props.tableMinWidth : 650, tableLayout: props.tableFixedWidth ? 'fixed' : 'auto' }}>
          <TableHead className={classes.header}>
            {newArray.map((number: number) => {
              return (
                <TableRow key={number}>
                  {props.headCells.map((headCell: any) =>
                    headCell.label === 'CHECKBOX' ? (
                      number + 1 === (headCell.row || 1) ? (
                        <TableCell colSpan={headCell.colSpan || 1} rowSpan={headCell.rowSpan || 1} key={headCell.id + number} align="center" padding={'none'} sx={{ width: headCell.width ? headCell.width : 'auto' }}>
                          <Dropdown className={classes.dropdown}>
                            <InputCheckbox onChange={headCell.onCheckAll} value="checkAll" indeterminate={headCell.indeterminate} checked={headCell.checked} />
                            <Dropdown.Toggle variant="link" bsPrefix="p-0" className={classes.dropdownToggle} disabled={headCell.disableDropdown}>
                              {!_.isEmpty(headCell.values) && <FontAwesomeIcon icon={faCaretDown} />}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className={classes.dropdownToggleShow}>
                              {headCell.values.map(
                                (objVal: any, i: number) =>
                                  (objVal.option === 'STATUS_ACTIVE' && <Dropdown.Item onClick={headCell.onActive}>{objVal.label}</Dropdown.Item>) ||
                                  (objVal.option === 'STATUS_INACTIVE' && <Dropdown.Item onClick={headCell.onInactive}>{objVal.label}</Dropdown.Item>) ||
                                  (objVal.option === 'DIVIDER' && <Dropdown.Divider />) ||
                                  (objVal.option === 'COMPONANT' && objVal.label) ||
                                  (objVal.option === 'DELETE' && (
                                    <Dropdown.Item onClick={props.onDelete}>
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                      <span className="ml-2">{objVal.label}</span>
                                    </Dropdown.Item>
                                  ))
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </TableCell>
                      ) : (
                        <></>
                      )
                    ) : number + 1 === (headCell.row || 1) ? (
                      <TableCell
                        colSpan={headCell.colSpan || 1}
                        rowSpan={headCell.rowSpan || 1}
                        key={number}
                        align={headCell.align || ((headCell.id === 'updatedBy' || headCell.id === 'action' || headCell.id === 'status' || headCell.id === 'no' || false) && 'center') || (headCell.id === 'price' && 'right') || 'left'}
                        padding={'none'}
                        sortDirection={sortBy === headCell.id ? sortType.toLowerCase() : false}
                        className={headCell?.class}
                        sx={{ width: headCell.width ? headCell.width : 'auto' }}
                      >
                        {(headCell.sortable && (
                          <TableSortLabel active={sortBy === headCell.id} direction={sortBy === headCell.id ? sortType.toLowerCase() : 'asc'} onClick={createSortHandler(headCell.id)} hideSortIcon>
                            {headCell.label || t(headCell.labelKey)}
                            {sortBy !== headCell.id && <Code className={'m-1'} sx={{ fontSize: '16px !important', transform: 'rotate(90deg)' }} />}
                          </TableSortLabel>
                        )) ||
                          headCell.label ||
                          t(headCell.labelKey)}
                      </TableCell>
                    ) : (
                      <></>
                    )
                  )}
                </TableRow>
              )
            })}
          </TableHead>
          <TableBody className={`sm-data-table ${classes.body}`}>
            {rowsData}
            {!rowsData.length && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell className="text-center align-middle py-2 cell-empty" colSpan={props.headCells.length}>
                  {props.textEmptyData ? props.textEmptyData : t('NOT_FOUND')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!props.hidePagination && (
        <TablePagination
          component="div"
          className={classes.pagination}
          rowsPerPageOptions={handleRowsPerPageOptions()}
          colSpan={headCells.length}
          count={rowCount || 0}
          rowsPerPage={pageLimit}
          page={page}
          labelRowsPerPage={t('PAGINATION.QTY_PER_PAGE')}
          SelectProps={{
            inputProps: {},
            native: true
          }}
          labelDisplayedRows={defaultLabelDisplayedRows}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          sx={{ flex: 'none' }}
        />
      )}
    </>
  )
}
TableCustom.propTypes = {
  page: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortType: PropTypes.oneOf(['ASC', 'DESC']).isRequired,
  rowCount: PropTypes.number || 0,
  headCells: PropTypes.array.isRequired,
  rowsData: PropTypes.array.isRequired,
  tooltip: PropTypes.string,
  onSort: PropTypes.func.isRequired,
  setPageLimit: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  customScroll: PropTypes.bool,
  verticalAlign: PropTypes.oneOf(['top', 'middle']),
  textEmptyData: PropTypes.string,
  hidePagination: PropTypes.bool,
  tableFixedWidth: PropTypes.bool,
  tableMinWidth: PropTypes.number,
  rowHeadCell: PropTypes.number,
  noHeader: PropTypes.bool
}

export interface ITablePaginationMiniCustomProps {
  count: number
  page: number
  rowsPerPage: number
  rowCount: number
  pageLimit: number
  headCells: any[]
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void
  setPageLimit: (val: any) => void
  setPage: (val: number) => void
}

const TablePaginationMini = styled(TablePagination)(({ theme }) => ({
  '& .MuiTablePagination-selectLabel': { display: 'none' },
  '& .MuiInputBase-root': { display: 'none' },
  [theme.breakpoints.down('sm')]: {
    '& .MuiTablePagination-displayedRows': { display: 'none' }
  }
}))

export function TablePaginationMiniCustom(props: ITablePaginationMiniCustomProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const rowCount = props.rowCount || 0
  const page = props.page || 1
  const pageLimit = props.pageLimit
  const rowsPerPageOptions = [5, 10, 25, 100]

  const headCells = props.headCells

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.setPageLimit(parseInt(event.target.value, 10))
    props.setPage(1)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.setPage(newPage)
  }

  const defaultLabelDisplayedRows = () => {
    const from = page * pageLimit - pageLimit + 1
    const to = page * pageLimit
    const count = Number(rowCount)
    return `${numberFormat(from, 0, 0)} - ${count <= to ? numberFormat(count, 0, 0) : numberFormat(to, 0, 0)} ${t('PAGINATION.LIST')} ${t('PAGINATION.FROM')} ${numberFormat(count, 0, 0)} ${t('PAGINATION.LIST')}`
  }

  const handleRowsPerPageOptions = useCallback(() => {
    if (!rowsPerPageOptions.includes(pageLimit)) {
      const newData = [...rowsPerPageOptions, pageLimit].sort((a: number, b: number) => a - b)
      return newData
    } else {
      return rowsPerPageOptions
    }
  }, [])

  return (
    <div className="row w-100">
      <TablePaginationMini
        className={classes.pagination}
        rowsPerPageOptions={handleRowsPerPageOptions()}
        colSpan={headCells.length}
        count={rowCount || 0}
        rowsPerPage={pageLimit}
        page={page}
        labelRowsPerPage={t('TABLE.PERPAGE')}
        SelectProps={{
          inputProps: {},
          native: true
        }}
        labelDisplayedRows={defaultLabelDisplayedRows}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  )
}
