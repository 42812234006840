import { numberFormat } from 'utils/app.utils';
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import _ from 'lodash'
import * as UseStyled from 'features/report/useStyled'
import { Box } from '@mui/material';

interface FcComponent {
  page: number
  pageLimit: number
  rowCount: number
  sortBy: string
  sortType: string
  data: any
  onRequestSort: (sortBy: string, sortType: string) => void
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
}

export default function IncomeOverdue(props: FcComponent) {
  const headCells = [
    { id: 'message', disablePadding: false, labelKey: '#', align: 'left', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.SUMMARY_INCOME', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.SUMMARY_CANCEL', align: 'right', class: 'text-nowrap' },
  ]

  const renderData = (data: any, no: number) => {
    const num = props.page * props.pageLimit - props.pageLimit + no + 1

    return <>
      <TableRowCommon
        key={num.toString()}
        id={no.toString()}
        obj={data}
        columns={[
          { option: 'TEXT', align: 'left', label: data.paymentChannelName || "-" },
          { option: 'TEXT', align: 'right', label: numberFormat(data.paidNormal) || "-" },
          { option: 'TEXT', align: 'right', label: numberFormat(data.paidCancel) || "-" }
        ]}
      />
    </>
  }

  return (
    <>
      <Box sx={{ maxWidth: '480px' }}>
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={props.onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headCells}
            rowsData={
              [
                _.map(props.data, (sd: any, index: number) => {
                  return (
                    renderData(sd, index)
                  )
                })
              ]
            }
            hidePagination={true}
            tableMinWidth={480}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
    </>
  )
}
