import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/setting/defaults/appointment-durations/style'

/** API */
import AppointmentDurationApi from 'api/setting/defaults/appointment-duration.api'

/** UTILS || SLICE */
import { convertDurationToMinuteOrText } from 'utils/date.utils'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import { submitModal, unSubmitModal, showModal, resetModal } from 'app/slice/modal.slice'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextField from 'component/Input/InputTextField'
import InputTextSearch from 'component/Input/InputTextSearch'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import BasicSelect from 'component/Select/BasicSelect'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import FilterSelect from 'component/Select/FilterSelect'

const initStateErrorMessage = {
  APPOINTMENT_DURATION: ``,
  IS_DUPLICATE_APPOINTMENT_DURATION: ``,
  APPOINTMENT_DURATION_STRING_BASE: ``,
  APPOINTMENT_DURATION_STRING_EMPTY: ``,
  APPOINTMENT_DURATION_MAXIMUM_LENGTH: ``,
  APPOINTMENT_DURATION_ANY_REQUIRED: ``
}
const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}
export default function AppointmentDurations() {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [appointmentDurations, setAppointmentDurations] = useState([])

  const [appointmentDurationId, setAppointmentDurationId] = useState(Number)
  const [appointmentDuration, setAppointmentDuration] = useState('5')
  const [appointmentDurationType, setAppointmentDurationType] = useState('MINUTES')
  const [appointmentDurationStatus, setAppointmentDurationStatus] = useState('')

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('ALL')

  const loadData = useCallback(async () => {

    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await AppointmentDurationApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setAppointmentDurations(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])

  useEffect(() => {
    loadData()
  }, [loadData])

  const submit = () => {
    if (!appointmentDuration) return setErrorMessage({ ...errorMessage, ...{ APPOINTMENT_DURATION: t('APPOINTMENT_DURATION.MESSAGE.APPOINTMENT_DURATION_STRING_EMPTY') } })
    if (appointmentDurationType === 'MINUTES' && Number(appointmentDuration) < 5) {
      return setErrorMessage({ ...errorMessage, ...{ APPOINTMENT_DURATION: t('APPOINTMENT_DURATION.MESSAGE.APPOINTMENT_DURATION_MORE_THAN_FIVE_MINUTES') } })
    }

    dispatch(submitModal())

    const durationMinute = convertDurationToMinuteOrText(appointmentDuration, appointmentDurationType)
    AppointmentDurationApi.create({ duration: appointmentDuration, durationType: appointmentDurationType, durationMinute })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t('APPOINTMENT_DURATION.MESSAGE.SUCCESS.CREATE'), '', null, null)
          resetForm()

          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`APPOINTMENT_DURATION.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`APPOINTMENT_DURATION.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const submitEdit = () => {
    if (!appointmentDuration) return setErrorMessage({ ...errorMessage, ...{ DURATION: t(`APPOINTMENT_DURATION.MESSAGE.APPOINTMENT_DURATION_STRING_EMPTY`) } })
    if (appointmentDurationType === 'MINUTES' && Number(appointmentDuration) < 5) {
      return setErrorMessage({ ...errorMessage, ...{ APPOINTMENT_DURATION: t('APPOINTMENT_DURATION.MESSAGE.APPOINTMENT_DURATION_MORE_THAN_FIVE_MINUTES') } })
    }

    dispatch(submitModal())
    const durationMinute = convertDurationToMinuteOrText(appointmentDuration, appointmentDurationType)
    AppointmentDurationApi.update(appointmentDurationId, {
      duration: appointmentDuration,
      durationType: appointmentDurationType,
      durationMinute: durationMinute,
      status: appointmentDurationStatus
    })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t(`APPOINTMENT_DURATION.MESSAGE.SUCCESS.UPDATE`), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          if (err.message === 'IS_DUPLICATE_APPOINTMENT_DURATION') {

            setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`APPOINTMENT_DURATION.MESSAGE.${err.message}`) } })
          } else setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`APPOINTMENT_DURATION.MESSAGE.${err.message}`) } })

          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`APPOINTMENT_DURATION.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  const resetForm = () => {
    setAppointmentDurationId(0)
    setAppointmentDuration('5')
    setAppointmentDurationType('MINUTES')
    setAppointmentDurationStatus('')
    clearErrorMessage()
  }

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('APPOINTMENT_DURATION.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          AppointmentDurationApi.updateStatus(id, valueStatus)
            .then((resp) => {
              notiSuccess(t('APPOINTMENT_DURATION.MESSAGE.SUCCESS.UPDATE_STATUS'))
              loadData()
            })
            .catch((e) => {
              notiError(t('APPOINTMENT_DURATION.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }
  const onDelete = (data: any) => {
    const { appointmentDurationId: aptDurationId, duration: aptDuration, durationType: aptDurationType } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('APPOINTMENT_DURATION.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('APPOINTMENT_DURATION.ALERT.CONFIRM_DELETE_', {
        appointmentDuration: t(`APPOINTMENT_DURATION.TABLE.CELL.${aptDurationType}`, { duration: aptDuration })
      })}</p>`,
      (res: any) => {
        if (res) {
          AppointmentDurationApi.remove(aptDurationId)
            .then((resp) => {
              notiSuccess(t('APPOINTMENT_DURATION.MESSAGE.SUCCESS.DELETE'))
              loadData()
            })
            .catch((e) => {
              notiError(t('APPOINTMENT_DURATION.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
    )
  }

  const onEdit = (data: any) => {
    const { appointmentDurationId: aptDurationId, duration: aptDuration, durationType: aptDurationType, status: aptDurationStatus } = data
    setAppointmentDurationId(aptDurationId)
    setAppointmentDuration(aptDuration)
    setAppointmentDurationType(aptDurationType)
    setAppointmentDurationStatus(aptDurationStatus)
    dispatch(showModal())
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }
  const handleChangeStatus = (statusVal: string) => {
    setStatus(statusVal)
  }
  const handleChangeDurationType = (type: string) => {
    setAppointmentDurationType(type)
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'durationMinute', disablePadding: false, label: t('APPOINTMENT_DURATION.TABLE.CELL.DURATION') },
    { id: 'status', disablePadding: false, label: t('APPOINTMENT_DURATION.TABLE.CELL.STATUS') },
    { id: 'updatedBy', disablePadding: false, label: t('APPOINTMENT_DURATION.TABLE.CELL.UPDATED_BY') },
    { id: 'action', disablePadding: false, label: 'Action' }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { appointmentDurationId: aptDurationId, duration: aptDuration, durationType: aptDurationType, updatedBy, updatedAt, status: aptDurationStatus } = objData
    const statusBtnActive = aptDurationStatus === 'ACTIVE' ? true : permissions.isUpdate.disabled
    const statusBtnInActive = aptDurationStatus === 'INACTIVE' ? true : permissions.isUpdate.disabled
    const objRenderData = {
      key: aptDurationId,
      id: aptDurationId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: t(`APPOINTMENT_DURATION.TABLE.CELL.${aptDurationType}`, { duration: aptDuration }) },
        { option: 'STATUS', align: 'center', label: aptDurationStatus },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ]
    }
    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
        onedit={() => onEdit(objRenderData.obj)}
        ondelete={() => onDelete(objRenderData.obj)}
      />
    )
  }

  return (
    <div className={classes.root}>
      <Card className="border-0 h-100">
        <HeaderCard text={t('APPOINTMENT_DURATION.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="align-items-center">
              <Col md={8}>
                <Row className="flax-row">
                  <Col sm={12} md={6} xl={4} className="pt-2">
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('INPUT.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={4} className="pl-md-0 pt-2">
                    <FilterSelect
                      onchange={(event) => {
                        const value = event.target.value
                        if (value) {
                          handleChangeStatus(value)
                          setPage(1)
                        }
                      }}
                      renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                      label={t('STATUS.LABEL')}
                      selectId="select-status"
                      value={status}
                      labelId="label-status"
                      options={[
                        <MenuItem key="1" value="ALL">
                          {t('STATUS.ALL')}
                        </MenuItem>,
                        <MenuItem key="2" value="ACTIVE">
                          {t('STATUS.ACTIVE')}
                        </MenuItem>,
                        <MenuItem key="3" value="INACTIVE">
                          {t('STATUS.INACTIVE')}
                        </MenuItem>
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mt-2 mt-md-auto">
                <div className="pr-xl-2">
                  <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => dispatch(showModal())} textButton={t('APPOINTMENT_DURATION.BUTTON.ADD')} className="w-auto ml-auto d-flex mt-auto" />
                </div>
              </Col>
            </Row>
          </Card.Title>

          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={appointmentDurations.map((val, i) => {
                return renderData(val, i)
              })}
            />
          </div>
        </Card.Body>
      </Card>

      <ModalCustom
        modalStyle={classes.modal}
        title={appointmentDurationId ? t('APPOINTMENT_DURATION.TITLE_UPDATE') : t('APPOINTMENT_DURATION.TITLE_CREATE')}
        component={
          <div className="pb-2">
            {appointmentDurationId ? (
              <div>
                <Form className="mx-0 mt-0 mb-3 p-0  d-inline-flex w-100">
                  <Form.Label className=" mr-auto">{t('INPUT.LABEL_STATUS')}</Form.Label>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    onChange={() => setAppointmentDurationStatus(appointmentDurationStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')}
                    checked={appointmentDurationStatus === 'ACTIVE' ? true : false}
                    style={{ marginLeft: 'auto' }}
                  />
                </Form>
              </div>
            ) : (
              ''
            )}
            <Row>
              <Col xs={6} className={`${classes.inputField} pr-2`}>
                <InputTextField
                  type="number"
                  onchange={(event) => {
                    setAppointmentDuration(event.target.value)
                    clearErrorMessage()
                  }}
                  value={appointmentDuration}
                  label={t('APPOINTMENT_DURATION.INPUT.DURATION')}
                  helperText={
                    errorMessage.APPOINTMENT_DURATION ||
                    errorMessage.IS_DUPLICATE_APPOINTMENT_DURATION ||
                    errorMessage.APPOINTMENT_DURATION_STRING_BASE ||
                    errorMessage.APPOINTMENT_DURATION_STRING_EMPTY ||
                    errorMessage.APPOINTMENT_DURATION_MAXIMUM_LENGTH ||
                    errorMessage.APPOINTMENT_DURATION_ANY_REQUIRED
                  }
                  required={true}
                  hideControl
                />
              </Col>
              <Col xs={6} className={`pl-2`}>
                <BasicSelect
                  fullWidth={true}
                  formControlClass={classes.formControl}
                  onchange={(event) => {
                    const value = event.target.value
                    if (value) {
                      handleChangeDurationType(value)
                      clearErrorMessage()
                    }
                  }}
                  label={t('APPOINTMENT_DURATION.INPUT.DURATION_TYPE')}
                  selectId="select-status"
                  value={appointmentDurationType}
                  labelId="appointment-duration-type"
                  options={[
                    <MenuItem key="minutes" value="MINUTES">
                      {t('TIME_DURATION_TYPE.MINUTES')}
                    </MenuItem>,
                    <MenuItem key="hours" value="HOURS">
                      {t('TIME_DURATION_TYPE.HOURS')}
                    </MenuItem>,
                    <MenuItem key="day" value="DAY">
                      {t('TIME_DURATION_TYPE.DAY')}
                    </MenuItem>
                  ]}
                />
              </Col>
            </Row>
          </div>
        }
        onReset={resetForm}
        onSubmit={appointmentDurationId ? submitEdit : submit}
        textBtnCancel={t('APPOINTMENT_DURATION.BUTTON.CANCEL')}
        textBtnConfirm={t('APPOINTMENT_DURATION.BUTTON.SAVE')}
      />
    </div>
  )
}
