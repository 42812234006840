import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import { MenuItem } from '@mui/material';
import _ from 'lodash'
import { showModal } from 'app/slice/modal.slice'
import { routeName } from 'routes/routes-name'
import { imgs } from 'constants/images';
import moment from 'moment'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** UTILS && CONSTANTS */
import UseWindowSize from 'utils/useWindowSize'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import { dateTimeToView, dateToView } from 'utils/date.utils'
import { numberToCurrencyFormat } from 'utils/app.utils'
import { UseSnCode } from 'utils/useSnCode'

/** COMPONENT */
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import SearchPatients from 'component/SearchPatients'
import BtnProfile from 'component/Treatments/BtnProfile'
import PopupPatientDetail from 'component/Treatments/PopupPatientDetail'
import InputTextSearch from 'component/Input/InputTextSearch'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import ButtonCustom from 'component/Button/ButtonCustom'
import FilterSelect from 'component/Select/FilterSelect'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { swalDelete } from 'component/Alert/Swal'
import { notiSuccess } from 'component/notifications/notifications';
import DefaultPagination from 'component/Pagination/DefaultPagination'
import FormLabOrder from 'features/lab-order/component/FormLabOrder'
import ViewLabOrder from 'features/lab-order/component/ViewLabOrder'
import CancelLabOrder from 'features/lab-order/component/CancelLabOrder'

/** API */
import { fileUrl } from 'api/api'
import TreatmentsApi from 'api/dentists/treatment.api'
import LabOrderApi, { LabOrderListInterface } from 'api/lab-order.api'
import LabCompanyApi from 'api/setting/default-labs/lab-company.api'

/** STYLE */
import * as TreatmentsUseStyled from 'features/treatments/UseStyle'
import * as UseStyled from 'features/lab-order/lab-order-style'
import { selectMe } from 'app/slice/user.slice';

export default function LabOrderPatient() {
    const { t } = useTranslation()
    const user = useSelector(selectMe)
    const location = useLocation()
    const dispatch = useDispatch()
    const [width] = UseWindowSize()
    const permissions = { isCreate: isCreate(), isDelete: isDelete(), isUpdate: isUpdate(), isView: isView() }

    const [isShowPopupDetail, setIsShowPopupDetail] = useState<boolean>(false)
    const [patientId, setPatientId] = useState<number>(0)
    const [patient, setPatient] = useState<any>({})

    const [isViewDetail, setIsViewDetail] = useState<boolean>(false)
    const [isForm, setIsForm] = useState<boolean>(false)
    const [popupCancel, setPopupCancel] = useState<boolean>(false)
    const [labOrderId, setLabOrderId] = useState<number>(0)
    const [labOrderNumber, setLabOrderNumber] = useState<string>('')

    /** FILTER */
    const [filterSearch, setFilterSearch] = useState<string>('')
    const [filterRangeDate, setFilterRangeDate] = useState<string[]>(emptyRangePicker)
    const [filterLabCompany, setFilterLabCompany] = useState<number>(0)
    const [filterLabOrderStatus, setFilterLabOrderStatus] = useState<string>('ALL')
    const [page, setPage] = useState(1)
    useEffect(() => { setPageLimit(width >= 1441 ? 10 : 3) }, [width])
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('dateOrder')
    const [sortType, setSortType] = useState('DESC')
    const history = useHistory()
    /** OPTION */
    const [labCompanyOptions, setLabCompanyOptions] = useState<any[]>([])

    /** DATA */
    const [labOrders, setLabOrders] = useState<any[]>([])

    const [snType, setSnType] = useState(false);
    useEffect(() => {
        if (location) {
            const { state }: any = location
            loadPatient(state?.patientId)

        }
    }, [location])
    const loadData = useCallback(async (pId?: number) => {
        let condition: any = {}
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (filterSearch) condition = { ...condition, search: filterSearch }
        if (filterLabCompany) condition = { ...condition, labCompanyId: filterLabCompany }
        if (filterLabOrderStatus && filterLabOrderStatus !== 'ALL') condition = { ...condition, labOrderStatus: filterLabOrderStatus }
        if (filterRangeDate) {
            if ((filterRangeDate[0] !== null || filterRangeDate[0] !== '') && (filterRangeDate[1] !== null || filterRangeDate[1] !== ''))
                condition = { ...condition, dateStart: filterRangeDate[0], dateEnd: filterRangeDate[1] }
        }
        const patients = await LabOrderApi.findAllByPatientId(pId || patientId, condition)
        if (patients.status === 200) {
            history.replace(window.location.pathname, {
                patientId: pId
            })

            setLabOrders(patients.data)

            setRowCount(patients.headers['x-total'])
        }


    }, [page, pageLimit, sortBy, sortType, filterSearch, filterRangeDate, filterLabCompany, filterLabOrderStatus, patientId])

    useEffect(() => { if (patientId) loadData(patientId) }, [page, pageLimit, sortBy, sortType, filterSearch, filterRangeDate, filterLabCompany, filterLabOrderStatus, patientId])
    useEffect(() => { loadOptions() }, [])



    const handleSnCode = useCallback(() => {
        const fetchData = async () => {
            const sn = await UseSnCode();
            setSnType(sn);
        };

        fetchData();

    }, [snType]);

    useEffect(() => {
        handleSnCode()
    }, []);

    const loadOptions = async () => {
        const condition: any = { page: 1, pageLimit: 50, status: 'ACTIVE' }
        await LabCompanyApi.findAll(condition).then(({ data }) => {
            setLabCompanyOptions(data)
        })
    }

    const loadPatient = async (id: number) => {
        if (id) {

            const patientData = await TreatmentsApi.findPatient(id)
            if (patientData.status === 200 && !_.isEmpty(patientData.data)) {
                setPatientId(patientData.data.patientId)
                setPatient(patientData.data)


            }
        }
    }


    const onView = (e: any, objVal: LabOrderListInterface) => {
        setLabOrderId(objVal.labOrderId)
        setIsViewDetail(true)
        dispatch(showModal())
    }

    const onClose = () => {
        setLabOrderId(0)
        setIsViewDetail(false)

    }

    const onCreate = () => {
        setIsForm(true)
        dispatch(showModal())
    }

    const onEdit = (objData: LabOrderListInterface) => {
        const { labOrderId: id } = objData
        setLabOrderId(id)

        dispatch(showModal())
        setIsForm(true)
    }

    const onDelete = (objData: LabOrderListInterface) => {
        const { labOrderId: id, labOrderNumber: lbNo } = objData
        swalDelete(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('LAB_ORDER.ALERT.CONFIRM_DELETE')} ${t('LAB_ORDER.MESSAGE.YESNO')}/p>`,
            `<p class="text-center mb-0 mx-auto" style="max-width: 306px">${t(`LAB_ORDER.MESSAGE.WANT_TO_DEL`)} ${t(`LAB_ORDER.MESSAGE.NO_LAB`)} ${lbNo} <br /> ${t('LAB_ORDER.MESSAGE.YESNO')}</p>`,
            (res: any) => {
                if (res) {
                    LabOrderApi.remove(id)
                        .then((resp) => {
                            notiSuccess(t('LAB_ORDER.MESSAGE.SUCCESS.DELETE'))
                            loadData()
                        })
                }
            },
            { confirmButtonText: t('LAB_ORDER.ALERT.BTN_CONFIRM_DELETE') }
        )
    }

    const onCancel = (objData: LabOrderListInterface) => {
        const { labOrderId: id, labOrderNumber: lbNo } = objData
        setLabOrderId(id)
        setLabOrderNumber(lbNo)
        dispatch(showModal())
        setPopupCancel(true)
    }
    const onReset = () => {
        setLabOrderId(0)
        setIsForm(false)
    }
    const desktopHeadCells = [
        { id: 'no', disablePadding: false, label: t('#'), class: 'text-nowrap' },
        { id: 'dateOrder', disablePadding: false, label: t('LAB_ORDER.TABLE.DATE_TIME_ORDER'), class: 'text-nowrap' },
        { id: 'labOrderNumber', disablePadding: false, label: t('LAB_ORDER.TABLE.LAB_ORDER_NUMBER'), class: 'text-nowrap' },
        { id: 'labCompanyName', disablePadding: false, label: t('LAB_ORDER.TABLE.COMPANY'), class: 'text-nowrap' },
        { id: 'dateExport', disablePadding: false, label: t('LAB_ORDER.TABLE.DATE_EXPORT'), class: 'text-nowrap' },
        { id: 'dateReceive', disablePadding: false, label: t('LAB_ORDER.TABLE.DATE_RECEIVE'), class: 'text-nowrap' },
        { id: 'dateReply', disablePadding: false, label: t('LAB_ORDER.TABLE.DATE_REPLY'), class: 'text-nowrap' },
        { id: 'patientId', disablePadding: false, label: t('APPLICATION.TABLE.CELL.CN_NUMBER'), class: 'text-nowrap' },
        { id: 'patientId', disablePadding: false, label: t('PATIENT_MANAGEMENT.TABLE.CELL.BRANCH_ID'), class: 'text-nowrap' },
        { id: 'patientId', disablePadding: false, label: t('PATIENT_MANAGEMENT.TABLE.CELL.SN_CODE'), class: 'text-nowrap' },
        { id: 'patientFullName', disablePadding: false, label: t('LAB_ORDER.TABLE.PATIENT_NAME'), class: 'text-nowrap' },
        { id: 'labOrderStatus', disablePadding: false, label: t('LAB_ORDER.TABLE.STATUS'), class: 'text-nowrap' },
        { id: 'dateBill', disablePadding: false, label: t('LAB_ORDER.TABLE.DATE_BILL'), class: 'text-nowrap' },
        { id: 'labOrderPrice', disablePadding: false, label: t('LAB_ORDER.TABLE.PRICE'), class: 'text-nowrap' },
        { id: 'staffBy', disablePadding: false, label: t('LAB_ORDER.TABLE.SAVED_BY'), class: 'text-nowrap', align: 'center' },
    ]
    if (!snType) {
        desktopHeadCells.splice(9, 1)
    }
    const tableHeadCells = [
        { id: 'action', disablePadding: false, label: t('LAB_ORDER.TABLE.ACTION'), class: 'text-nowrap' },
    ]
    const headCells = width >= 1200 ? [...desktopHeadCells, ...tableHeadCells] : [...tableHeadCells]
    const arrayLabOrderStatus: string[] = ['ORDER_LAB', 'SEND_LAB', 'RECEIVE_LAB', 'RECEIVE_BILL', 'REPLY_LAB', 'CANCEL_LAB', 'CLOSE_LAB']

    const getLabelLabOrderStatus = (status: string) => {
        let label: any = ''
        if (status === 'ORDER_LAB') label = t('LAB_ORDER.LAB_STATUS.ORDER_LAB')
        else if (status === 'SEND_LAB') label = t('LAB_ORDER.LAB_STATUS.SEND_LAB')
        else if (status === 'RECEIVE_LAB') label = t('LAB_ORDER.LAB_STATUS.RECEIVE_LAB')
        else if (status === 'RECEIVE_BILL') label = t('LAB_ORDER.LAB_STATUS.RECEIVE_BILL')
        else if (status === 'REPLY_LAB') label = t('LAB_ORDER.LAB_STATUS.REPLY_LAB')
        else if (status === 'CANCEL_LAB') label = t('LAB_ORDER.LAB_STATUS.CANCEL_LAB')
        else if (status === 'CLOSE_LAB') label = t('LAB_ORDER.LAB_STATUS.CLOSE_LAB')
        return label
    }

    const renderData = (objData: LabOrderListInterface, no: number) => {
        no = page * pageLimit - pageLimit + no + 1


        const { labOrderId: id, dateOrder, timeOrder, labOrderNumber: lbNo, labCompanyName, dateExport, dateImport, dateBill, dateReply, cnNumber, branchCnNumber, snCode, patientFullName, labOrderPrice, labOrderStatus, updatedBy, updatedAt } = objData
        const objRenderData = {
            key: String(no),
            id: String(id),
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: no },
                { option: 'TEXT', align: 'center', label: dateTimeToView(`${dateOrder} ${timeOrder}`, false) || '', class: 'text-nowrap-ellipsis' },
                { option: 'TEXT', align: 'center', label: lbNo || '', class: 'text-nowrap-ellipsis ' },
                { option: 'TEXT', align: 'left', label: labCompanyName || '', class: 'text-nowrap-ellipsis labCompanyName' },
                { option: 'TEXT', align: 'center', label: dateToView(dateExport, false) || '-', class: 'text-nowrap-ellipsis' },
                { option: 'TEXT', align: 'center', label: dateToView(dateImport, false) || '-', class: 'text-nowrap-ellipsis' },
                { option: 'TEXT', align: 'center', label: dateReply ? dateToView(dateReply, false) : '-', class: 'text-nowrap-ellipsis' },
                { option: 'TEXT', align: 'left', label: cnNumber || '-', class: 'text-nowrap-ellipsis' },
                { option: 'TEXT', align: 'left', label: branchCnNumber || '-', class: 'text-nowrap-ellipsis' },
                { option: 'TEXT', align: 'left', label: snCode || '-', class: 'text-nowrap-ellipsis' },
                { option: 'TEXT', align: 'left', label: patientFullName || '', class: 'text-nowrap-ellipsis patientFullName' },
                { option: 'TEXT', align: 'center', label: getLabelLabOrderStatus(labOrderStatus) || '', class: 'text-nowrap-ellipsis' },
                { option: 'TEXT', align: 'center', label: dateBill !== null ? dateToView(dateBill, false) : '-', class: 'text-nowrap-ellipsis' },
                { option: 'TEXT', align: 'right', label: numberToCurrencyFormat(labOrderPrice || 0), class: 'text-nowrap-ellipsis' },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy, updatedAt } },

                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: [
                        {
                            option: 'COMPONANT', label: (
                                <DropdownItem onClick={() => onCancel(objData)} disabled={permissions.isUpdate.disabled || labOrderStatus === 'CANCEL_LAB'}>
                                    <span className="ml-2 align-text-bottom">{t('LAB_ORDER.LAB_STATUS.CANCEL_LAB')}</span>
                                </DropdownItem>
                            )
                        },
                        {
                            option: 'COMPONANT', label: (
                                <DropdownItem onClick={() => { return }} disabled={true}>
                                    <span className="ml-2 align-text-bottom">{t('LAB_ORDER.LAB_STATUS.REPLY_LAB')}</span>
                                </DropdownItem>
                            )
                        },
                        { option: 'DIVIDER', label: '', disabled: false },

                        { option: 'DIVIDER', label: '', disabled: false },
                        { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled || labOrderStatus !== 'ORDER_LAB' },
                        { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled || labOrderStatus !== 'ORDER_LAB' }
                    ]
                },
            ]
        }
        if (!snType) {
            objRenderData.columns.splice(9, 1)
        }
        return <TableRowCommon rowLink={true} {...objRenderData} onClick={(e, val) => onView(e, val)} onedit={() => onEdit(objData)} ondelete={() => onDelete(objData)} />
    }

    const renderDataSlim = (objData: LabOrderListInterface, no: number) => {
        no = page * pageLimit - pageLimit + no + 1
        const { dateOrder, timeOrder, labOrderNumber: lbNo, labCompanyName, dateExport, dateImport, cnNumber, branchCnNumber, snCode, patientFullName, labOrderPrice, labOrderStatus, updatedBy, dateReply } = objData
        return (
            <UseStyled.TBody>
                <div className={'d-flex align-items-center position-relative'}>
                    <UseStyled.Cell>
                        <Row className="pl-4 mx-0">
                            <div className="position-absolute w-auto px-0" style={{ marginLeft: '-1.5rem' }}>
                                {no}
                            </div>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.DATE_TIME_ORDER')}</Col>
                            <Col xs={6} md={9} lg={6} xxl={3} className="px-1 ">{dateTimeToView(`${dateOrder} ${timeOrder}`)}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.LAB_ORDER_NUMBER')}</Col>
                            <Col xs={6} md={9} lg={6} xxl={3} className="px-1">{lbNo}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.COMPANY')}</Col>
                            <Col xs={6} md={9} lg={6} xxl={9} className="px-1">{labCompanyName}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.DATE_EXPORT')}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1">{dateToView(dateExport) || '-'}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.DATE_RECEIVE')}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1">{dateToView(dateImport) || '-'}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.DATE_REPLY')}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1">{dateReply ? dateToView(dateReply) : '-'}</Col>

                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('APPLICATION.TABLE.CELL.CN_NUMBER')}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1">{cnNumber || '-'}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('PATIENT_MANAGEMENT.TABLE.CELL.BRANCH_ID')}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1">{branchCnNumber || '-'}</Col>
                            {snType && (
                                <>
                                    <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">
                                        {t('PATIENT_MANAGEMENT.TABLE.CELL.SN_CODE')}
                                    </Col>
                                    <Col xs={6} md={3} lg={6} xxl={3} className="px-1">
                                        {snCode || '-'}
                                    </Col>
                                </>
                            )}

                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.PATIENT_NAME')}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1">{patientFullName || ''}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.STATUS')}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1">{getLabelLabOrderStatus(labOrderStatus) || ''}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.PRICE')}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1">{numberToCurrencyFormat(labOrderPrice || 0)}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.SAVED_BY')}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 text-nowrap-ellipsis">{updatedBy}</Col>
                        </Row>
                    </UseStyled.Cell>
                </div>
            </UseStyled.TBody>
        )
    }
    const renderImage = (img: any) => {
        if (!_.isEmpty(img) && !_.isEmpty(img.filePath) && !_.isEmpty(img.fileName)) return `${fileUrl} / ${img.filePath} / ${img.fileName}`
        else return imgs.defaultAvatar
    }
    const onViewHistory = () => {
        dispatch(showModal())
        setIsShowPopupDetail(true)
    }
    return (
        <div>
            <HeaderLabel text={t('LAB_ORDER.TITLE_HISTORY_LAB')} noAction={true} goBack={routeName.labOrder} navigator={{ previousTo: routeName.labOrder, previousTitle: t('LAB_ORDER.HEADER'), currentTitle: t('LAB_ORDER.HISTORY_LAB') }} />
            <div className="px-3">
                <div className="col-12 col-xl-6 mb-3">
                    <SearchPatients
                        status="ACTIVE"
                        callbackPatientId={loadPatient}
                        patient={patient}
                        patientId={patientId}

                        onClear={() => {
                            setPatientId(0)
                            setPatient('')
                            setLabOrders([])
                            history.replace(window.location.pathname, {
                            })
                        }}
                    />
                </div>
                <TreatmentsUseStyled.CardProfile className="d-flex px-3" onClick={onViewHistory}>
                    <BtnProfile profile={renderImage(patient.profile)} branchCnNumber={patient.branchCnNumber} firstName={patient.firstname} lastName={patient.lastname} rank={patient.rank} lastVisit={patient.updatedAt} />
                </TreatmentsUseStyled.CardProfile>
                <PopupPatientDetail type='lab-order' isNotShownPhone={user.hasShowPhoneNumber === 'UNPERMISS'} profile={renderImage(patient.profile)} patient={patient} showPopup={isShowPopupDetail} onClose={() => setIsShowPopupDetail(false)} />
                <div className='table-payment custom-scroll my-3' >
                    <Row className="mx-0 align-items-center">
                        <Col xs={12} sm={6} md={4} lg={3} className="mb-2 px-0 pr-sm-1">
                            <InputTextSearch keyInput={'search'} label={t('LAB_ORDER.LIST.SEARCH')} value={filterSearch}
                                onchange={(event) => {
                                    setFilterSearch(event.target.value)
                                    setPage(1)
                                }}
                                onClear={(event) => {
                                    setFilterSearch('')
                                    setPage(1)
                                }} />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} className="mb-2 px-0 pl-sm-1 pr-md-1">
                            <FilterSelect
                                fullWidth
                                renderValue={() => `${t('LAB_ORDER.LIST.COMPANY')}: ${_.find(labCompanyOptions, (labCompany: any) => labCompany.labCompanyId === filterLabCompany)?.labCompanyName || t('ALL')}`}
                                label={t('LAB_ORDER.LIST.COMPANY')}
                                selectId="select-status"
                                value={filterLabCompany}
                                onchange={(event) => {
                                    setFilterLabCompany(event.target.value)
                                    setPage(1)
                                }}
                                labelId="label-status"
                                options={[
                                    <MenuItem key={'all'} value={0}>
                                        {t('ALL')}
                                    </MenuItem>,
                                    _.map(labCompanyOptions, (labCompany: any, i: number) => (
                                        <MenuItem key={i} value={labCompany.labCompanyId}>
                                            {labCompany.labCompanyName}
                                        </MenuItem>
                                    ))
                                ]}
                                formControlStyle={{ maxWidth: 'unset !important' }}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} className="mb-2 px-0 pr-sm-1 pl-md-1 pr-md-0 pr-lg-1">
                            <FilterSelect
                                fullWidth-
                                renderValue={() => `${t('LAB_ORDER.LIST.STATUS')}: ${getLabelLabOrderStatus(_.find(arrayLabOrderStatus, (statusLab: any) => statusLab === filterLabOrderStatus) || '') || t('ALL')
                                    } `}
                                label={t('LAB_ORDER.LIST.STATUS')}
                                selectId="select-status"
                                value={filterLabOrderStatus}
                                onchange={(event) => {
                                    setFilterLabOrderStatus(event.target.value)
                                    setPage(1)
                                }}
                                labelId="label-status"
                                options={[
                                    <MenuItem key={'all'} value={0}>
                                        {t('ALL')}
                                    </MenuItem>,
                                    _.map(arrayLabOrderStatus, (statusLab: any, i: number) => (
                                        <MenuItem key={i} value={statusLab}>
                                            {getLabelLabOrderStatus(statusLab)}
                                        </MenuItem>
                                    ))
                                ]}
                                formControlStyle={{ maxWidth: 'unset !important' }}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} className="mb-2 px-0 pl-sm-1 pl-md-0 pl-lg-1">
                            <InputSecondNewRangePicker
                                value={filterRangeDate || emptyRangePicker}
                                inputHeight={32}
                                onchange={(val) => {
                                    setPage(1)
                                    if (_.isEmpty(val)) setFilterRangeDate(emptyRangePicker)
                                    else setFilterRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                                }}
                                allowClear
                                onClear={() => {
                                    setPage(1)
                                    setFilterRangeDate(emptyRangePicker)
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} className="mb-2 ml-auto px-0">
                            <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={onCreate} textButton={t('LAB_ORDER.LIST.ADD_LAB')} className="w-auto ml-auto d-flex mt-auto" />
                        </Col>
                    </Row>
                    {width >= 1441 ? (
                        <TableCustom
                            page={page}
                            pageLimit={pageLimit}
                            sortType={sortType}
                            sortBy={sortBy}
                            rowCount={rowCount}
                            onSort={(sortByVal: string, sortTypeVal: string) => {
                                setSortType(sortTypeVal)
                                setSortBy(sortByVal)
                            }}
                            setPageLimit={setPageLimit}
                            setPage={setPage}
                            headCells={headCells}
                            rowsData={_.map(labOrders, renderData)}
                        />
                    ) : (
                        <>
                            <UseStyled.THead>
                                <UseStyled.Cell>{t('#')}</UseStyled.Cell>
                            </UseStyled.THead>
                            <div className={'treatment-body'}>
                                {_.map(labOrders, renderDataSlim)}
                                <div className={'mt-2 '}>
                                    <DefaultPagination count={rowCount} allRow={rowCount} pageLimit={pageLimit} page={page} setPage={setPage} />
                                </div>
                            </div>
                        </>
                    )}

                </div>
                <ViewLabOrder labOrderId={labOrderId} showPopup={isViewDetail} onClose={onClose} />
                <FormLabOrder showForm={isForm} labOrderId={labOrderId} patient={patient} patientId={patientId} onReset={onReset} handleSuccess={loadData} />
                <CancelLabOrder isShow={popupCancel} labOrderNumber={labOrderNumber} id={labOrderId} onSuccess={loadData} onReset={() => setPopupCancel(false)} />
            </div>
        </div>
    )
}
