import { createSlice } from '@reduxjs/toolkit'

export const registerSlice = createSlice({
  name: 'formRegister',
  initialState: {
    patient: {},
    formRegister: {},
    reloadCareers: { reload: false, careeId: 0 }
  },
  reducers: {
    editPatient: (state, action) => {
      state.patient = action.payload
    },
    setPatient: (state: any, action) => {
      state.patient[action.payload.key] = action.payload.value
    },
    setForm: (state, action) => {
      state.formRegister = action.payload
    },
    setValueForm: (state, action) => {
      const formRegisterValue = { ...state.formRegister, [action.payload.key]: action.payload.value }
      state.formRegister = formRegisterValue
    },
    setReloadCareers: (state, action) => {
      state.reloadCareers = action.payload
    }
  }
})

export const formRegister = (state: any) => state.register.formRegister

export const { editPatient, setForm, setPatient, setValueForm, setReloadCareers } = registerSlice.actions

export default registerSlice.reducer
