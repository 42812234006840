import { Dispatch, createContext, useContext, useReducer } from 'react'
import moment from 'moment'
import { logout as doLogout, getSessionExpired } from 'utils/app.utils'
import { KEY_STORAGE } from 'constants/common'

export type LoginContextData = {
  isLoggedIn: boolean
  token?: string
}

export type LoginContextPayload = {
  loginData: LoginContextData
  loginDispatch: Dispatch<any>
}

export type LoginContextAction = {
  type: string
  data: LoginContextData
}

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGOUT = 'LOGOUT'

const LoginContext = createContext<LoginContextPayload>({} as LoginContextPayload)

export function loginFailed(): LoginContextAction {
  return { type: LOGIN_FAILED, data: { isLoggedIn: false } }
}

export function logout(): LoginContextAction {
  return { type: LOGOUT, data: { isLoggedIn: false } }
}

export function loginSuccess(token: string, remember: boolean): LoginContextAction {
  localStorage.setItem(KEY_STORAGE.TOKEN, token)
  if (!remember) localStorage.setItem(KEY_STORAGE.SESSION_EXPIRED, String(moment().add(1, 'days').set({ 'hour': 4, 'minute': 59 }).format('YYYY-MM-DD HH:mm')))
  return { type: LOGIN_SUCCESS, data: { isLoggedIn: true, token } }
}

export function loginReducer(state: any, action: LoginContextAction) {
  if (action.type === LOGIN_SUCCESS) return action.data
  else return { isLoggedIn: false }
}

export const checkRememberLogin = () => {
  const sessionDate = getSessionExpired()
  if (sessionDate) {
    const now = moment()
    const session = moment(sessionDate, 'YYYY-MM-DD HH:mm')
    const expired = now.isAfter(session)
    if (expired) {
      doLogout()
      window.location.reload()
    }
  }
}

const initialLoginState: LoginContextData = { isLoggedIn: false }

function LoginProvider(props: any) {
  const [state, dispatch]: [LoginContextData, any] = useReducer(loginReducer, initialLoginState)
  const data = { loginData: state, loginDispatch: dispatch }
  return <LoginContext.Provider value={data} {...props} />
}

function useLoginContext(): LoginContextPayload {
  return useContext(LoginContext)
}

export { LoginProvider, useLoginContext }
