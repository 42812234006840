import ClinicManagement from 'features/admin/clinic-management/clinic-management'

const routeClinicManagements = [
    {
        path: '/admin/clinic-managements',
        component: ClinicManagement,
        active: false,
        key: "BACKEND",
        appBar: true
    }
]

export default routeClinicManagements
