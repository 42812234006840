import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState: any = {
  payment: {
    discountPromotion: 0,
    discountTreatmentRight: 0,
    advance: 0,
    subTotal: 0,

    /** MAIN VALUE */
    prefix: '',
    totalPrice: 0,
    amountPaid: 0,
    amountReceipt: 0,
    overdue: 0,
    amountChange: 0,
    remark: '',
    orderPaymentChannels: { 1: false, 2: false, 3: false, 4: false, 5: false, 6: false }
  },
  summery: {},
  summerySub: {},
  summeryTreatmentRight: {},
  summerySubTreatmentRight: {},
  bankCredit: {},
  bankTransfer: {},
  bankDebit: {},
  selectChannel: []
}

export const patmentSlice = createSlice({
  name: 'paymentState',
  initialState,
  reducers: {
    resetStatePayment: (state: any,) => state = {
      payment: {
        discountPromotion: 0,
        discountTreatmentRight: 0,
        advance: 0,
        subTotal: 0,

        /** MAIN VALUE */
        prefix: '',
        totalPrice: 0,
        amountPaid: 0,
        amountReceipt: 0,
        overdue: 0,
        amountChange: 0,
        remark: '',
        orderPaymentChannels: { 1: false, 2: false, 3: false, 4: false, 5: false, 6: false }
      },
      summery: {},
      summerySub: {},
      summeryTreatmentRight: {},
      summerySubTreatmentRight: {},
      bankCredit: {},
      bankTransfer: {},
      bankDebit: {}
    },
    setPaymentByKey: (state: any, action: any) => {
      state.payment[action.payload.key] = action.payload.value
    },
    setPaymentChannelByKey: (state: any, action: any) => {
      state.payment.orderPaymentChannels[action.payload.key] = action.payload.value
    },
    resetPaymentChannelByKey: (state: any) => {
      state.payment.orderPaymentChannels = { 1: false, 2: false, 3: false, 4: false, 5: false, 6: false }
    },
    setSummery: (state: any, action: any) => {
      state.summery = action.payload
    },
    resetSummery: (state: any) => {
      state.summery = {}
    },
    setSummeryByKey: (state: any, action: any) => {
      if (!state.summery[action.payload.key]) state.summery[action.payload.key] = []
      state.summery[action.payload.key] = action.payload.value
    },
    setSummerySub: (state: any, action: any) => {
      state.summerySub = action.payload
    },
    setSummerySubByKey: (state: any, action: any) => {
      if (!state.summerySub[action.payload.key]) state.summerySub[action.payload.key] = []
      if (!state.summerySub[action.payload.key][action.payload.keySub]) state.summerySub[action.payload.key][action.payload.keySub] = []
      state.summerySub[action.payload.key][action.payload.keySub] = action.payload.value
      // state.summery[action.payload.key] = _.sumBy(state.summerySub[action.payload.key], 'value')
      if (action.payload.key !== 1) state.summery[action.payload.key] = _.sumBy(state.summerySub[action.payload.key], 'value')
    },
    setSummeryTreatmentRight: (state: any, action: any) => {
      state.summeryTreatmentRight = action.payload
    },
    setSummeryTreatmentRightByKey: (state: any, action: any) => {
      if (!state.summeryTreatmentRight[action.payload.key]) state.summeryTreatmentRight[action.payload.key] = []
      if (!state.summeryTreatmentRight[action.payload.key][action.payload.patientTreatmentRightId]) state.summeryTreatmentRight[action.payload.key][action.payload.patientTreatmentRightId] = []
      state.summeryTreatmentRight[action.payload.key][action.payload.patientTreatmentRightId] = action.payload.value
    },
    setSummerySubTreatmentRight: (state: any, action: any) => {
      state.summerySubTreatmentRight = action.payload
    },
    setSummerySubTreatmentRightByKey: (state: any, action: any) => {
      if (!state.summerySubTreatmentRight[action.payload.key]) state.summerySubTreatmentRight[action.payload.key] = []
      if (!state.summerySubTreatmentRight[action.payload.key][action.payload.patientTreatmentRightId]) state.summerySubTreatmentRight[action.payload.key][action.payload.patientTreatmentRightId] = []
      if (!state.summerySubTreatmentRight[action.payload.key][action.payload.patientTreatmentRightId][action.payload.keySub]) state.summerySubTreatmentRight[action.payload.key][action.payload.patientTreatmentRightId][action.payload.keySub] = []
      state.summerySubTreatmentRight[action.payload.key][action.payload.patientTreatmentRightId][action.payload.keySub] = action.payload.value

      if (!state.summeryTreatmentRight[action.payload.key]) state.summeryTreatmentRight[action.payload.key] = []
      if (!state.summeryTreatmentRight[action.payload.key][action.payload.patientTreatmentRightId]) state.summeryTreatmentRight[action.payload.key][action.payload.patientTreatmentRightId] = { isCheck: false, value: 0, isDisabled: true }
      state.summeryTreatmentRight[action.payload.key][action.payload.patientTreatmentRightId].value = _.sumBy(state.summerySubTreatmentRight[action.payload.key][action.payload.patientTreatmentRightId], 'value')
    },
    setBankDebit: (state: any, action: any) => {
      state.bankDebit = action.payload
    },
    setBankCredit: (state: any, action: any) => {
      state.bankCredit = action.payload
    },
    setBankTransfer: (state: any, action: any) => {
      state.bankTransfer = action.payload
    },
    setAmountReceipt: (state: any, action: any) => {
      state.payment.amountReceipt = action.payload
    },
    setAdvance: (state: any, action: any) => {
      state.payment.advance = action.payload.value
    },
    setSelectChannel: (state: any, action: any) => {
      state.selectChannel = action.payload
    }
  }
})
export const valPayment = (state: any) => state.payment.payment
export const orderPaymentChannels = (state: any) => state.payment.payment.orderPaymentChannels
export const selectChannel = (state: any) => state.payment.selectChannel
export const summery = (state: any) => state.payment.summery
export const summerySub = (state: any) => state.payment.summerySub
export const summeryTreatmentRight = (state: any) => state.payment.summeryTreatmentRight
export const summerySubTreatmentRight = (state: any) => state.payment.summerySubTreatmentRight
export const valBankCredit = (state: any) => state.payment.bankCredit
export const valBankTransfer = (state: any) => state.payment.bankTransfer
export const valBankDebit = (state: any) => state.payment.bankDebit

export const {
  resetStatePayment,
  resetSummery,
  setPaymentByKey,
  setPaymentChannelByKey,
  resetPaymentChannelByKey,
  setSummery,
  setSummeryByKey,
  setSummerySub,
  setSummerySubByKey,
  setSummeryTreatmentRight,
  setSummeryTreatmentRightByKey,
  setSummerySubTreatmentRight,
  setSummerySubTreatmentRightByKey,
  setBankDebit,
  setBankCredit,
  setBankTransfer,
  setAmountReceipt,
  setSelectChannel,
  setAdvance
} = patmentSlice.actions

export default patmentSlice.reducer
