import { Box, MenuItem, styled } from '@mui/material';

import page_1 from 'assets/report/df/ความสำคัญของกิจกรรม-1.png'
import page_2 from 'assets/report/df/ความสำคัญของกิจกรรม-2.png'
import page_3 from 'assets/report/df/ความสำคัญของกิจกรรม-3.png'


export const ModalReportFormulaDF = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: '999999999',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#21212161',
    '.modal-report-close': {
        position: 'absolute',
        top: '0',
        right: '0',
        padding: '16px'
    },
    '.modal-contetnt': {
        display: 'flex !important',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    }
}))

export const optionFormula: any = {
    DF_1: page_1,
    DF_2: page_2,
    DF_3: page_3,
}

type ReportFormulaDFProps = {
    img: any,
    setImg: (img: string) => void
}

export const ReportFormulaDF = (props: ReportFormulaDFProps) => {
    return (
        <>
            <ModalReportFormulaDF>
                <Box className='modal-report-close'>
                    <Box
                        sx={{
                            color: '#FFF',
                            fontSize: '24px',
                            cursor: 'pointer'
                        }}
                        onClick={() => props.setImg('')}
                    >
                        &#x2715;
                    </Box>
                </Box>
                <Box className='modal-contetnt'>
                    <Box
                        sx={{
                            maxHeight: '100vh',
                            img: {
                                maxHeight: '100vh',
                                padding: '16px 55px',
                                height: '100%',
                                width: '100%',
                                maxWidth: '1200px'
                            }
                        }}
                    >
                        <img src={props.img} />
                    </Box>
                </Box>

            </ModalReportFormulaDF>
        </>
    )
}

