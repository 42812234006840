import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { MenuItem, Divider } from '@mui/material';
import { useDispatch } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles, { CardHeader } from 'features/setting/defaults/keyword/style'

/** API */
import KeywordApi from 'api/setting/defaults/keyword.api'

/** UTILS || SLICE */
import { submitModal, unSubmitModal, showModal, resetModal } from 'app/slice/modal.slice'
import { colors } from 'constants/theme'

import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import BasicSelect from 'component/Select/BasicSelect'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import InputTextarea from 'component/Input/InputTextarea'
import FilterSelect from 'component/Select/FilterSelect'

const initStateErrorMessage = {
  KEYWORD: ``,
  IS_DUPLICATE_KEYWORD: ``,
  KEYWORD_STRING_BASE: ``,
  KEYWORD_STRING_EMPTY: ``,
  KEYWORD_MAXIMUM_LENGTH: ``,
  KEYWORD_ANY_REQUIRED: ``,

  CONTINUE_USED_REQUIRED: ``,
  USER_USED_REQUIRED: ``,
  KEYWORD_TYPE_REQUIRED: ``,
  MASSAGE_ANY_REQUIRED: ``,

  MASSAGE_STRING_BASE: '',
  MASSAGE_STRING_EMPTY: '',
  MASSAGE_MAXIMUM_LENGTH: ''
}

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

export default function Keyword() {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [keywords, setKeywords] = useState([])
  const [keywordId, setKeywordId] = useState(Number)
  const [keywordType, setKeywordType] = useState('DISEASE')
  const [word, setMessage] = useState('')
  const [keywordStatus, setKeywordStatus] = useState('')

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('ALL')
  const [filterKeywordType, setFilterKeywordType] = useState<string>('ALL')

  const keywordTypeSelect = [
    { value: 'DISEASE', label: t('KEYWORD.DISEASE') },
    { value: 'MEDICINE_USE', label: t('KEYWORD.MEDICINE_USE') },
    { value: 'BE_ALLERGIC', label: t('KEYWORD.BE_ALLERGIC') }
  ]

  const loadData = useCallback(async () => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }
    if (filterKeywordType) condition = { ...condition, keywordType: filterKeywordType === 'ALL' ? '' : filterKeywordType }

    const res = await KeywordApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setKeywords(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status, filterKeywordType])

  useEffect(() => {
    loadData()
  }, [loadData])


  const submit = () => {
    if (!keywordType || !word)
      return setErrorMessage({
        ...errorMessage,
        ...{
          KEYWORD_TYPE_REQUIRED: !keywordType ? t('กรุณาเลือกปรเภทคำ') : '',
          MASSAGE_ANY_REQUIRED: !word ? t('กรุณากรอกคำ') : ''
        }
      })

    const objCreate = {
      keywordType: keywordType,
      word: word,
      status: 'ACTIVE'
    }

    dispatch(submitModal())

    KeywordApi.create(objCreate)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t('KEYWORD.MESSAGE.SUCCESS.CREATE'), '', null, null)
          resetForm()

          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`KEYWORD.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`KEYWORD.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const submitEdit = () => {
    if (!keywordType || !word)
      return setErrorMessage({
        ...errorMessage,
        ...{
          KEYWORD_TYPE_REQUIRED: !keywordType ? t('กรุณาเลือกปรเภทคำ') : '',
          MASSAGE_ANY_REQUIRED: !word ? t('กรุณากรอกคำ') : ''
        }
      })

    const objCreate = {
      keywordType: keywordType,
      word: word,
      status: keywordStatus
    }
    dispatch(submitModal())

    KeywordApi.update(keywordId, objCreate)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t(`KEYWORD.MESSAGE.SUCCESS.UPDATE`), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          if (err.message === 'IS_DUPLICATE_KEYWORD') {

            setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`KEYWORD.MESSAGE.${err.message}`) } })
          } else setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`KEYWORD.MESSAGE.${err.message}`) } })

          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`KEYWORD.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  const resetForm = () => {
    setKeywordId(0)
    setKeywordType('DISEASE')
    setKeywordStatus('')
    setMessage('')
    clearErrorMessage()
  }

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('KEYWORD.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          KeywordApi.updateStatus(id, valueStatus)
            .then((resp) => {
              notiSuccess(t('KEYWORD.MESSAGE.SUCCESS.UPDATE_STATUS'))
              loadData()
            })
            .catch((e) => {
              notiError(t('KEYWORD.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }
  const onDelete = (data: any) => {
    const { keywordId, word: keywordMessage } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('KEYWORD.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('KEYWORD.ALERT.CONFIRM_DELETE_', {
        keyword: keywordMessage
      })}</p>`,
      (res: any) => {
        if (res) {
          KeywordApi.remove(keywordId)
            .then((resp) => {
              notiSuccess(t('KEYWORD.MESSAGE.SUCCESS.DELETE'))
              loadData()
            })
            .catch((e) => {
              notiError(t('KEYWORD.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
    )
  }

  const onEdit = (data: any) => {
    const { keywordId, keywordType, word: keywordMessage, status: keywordStatus } = data
    setKeywordId(keywordId)
    setKeywordType(keywordType)
    setKeywordStatus(keywordStatus)
    setMessage(keywordMessage)
    dispatch(showModal())
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const handleChangeStatus = (statusVal: string) => {
    setStatus(statusVal)
  }

  const handleChangeKeywordType = (valueKeywordType: string) => {
    setFilterKeywordType(valueKeywordType)
  }


  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'keywordType', disablePadding: false, label: t('KEYWORD.TABLE.CELL.TYPE'), align: 'left' },
    { id: 'word', disablePadding: false, label: t('KEYWORD.TABLE.CELL.KEYWORD') },
    { id: 'status', disablePadding: false, label: t('KEYWORD.TABLE.CELL.STATUS') },
    { id: 'updatedBy', disablePadding: false, label: t('KEYWORD.TABLE.CELL.UPDATED_BY') },
    { id: 'action', disablePadding: false, label: 'Action' }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { keywordId, keywordType, word: keywordMessage, updatedBy, updatedAt, status: keywordStatus } = objData
    const statusBtnActive = keywordStatus === 'ACTIVE' ? true : permissions.isUpdate.disabled
    const statusBtnInActive = keywordStatus === 'INACTIVE' ? true : permissions.isUpdate.disabled
    const objRenderData = {
      key: keywordId,
      id: keywordId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: t(`KEYWORD.${keywordType}`) },
        { option: 'TEXT', align: 'left', label: keywordMessage },
        { option: 'STATUS', align: 'center', label: keywordStatus },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ]
    }
    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
        onedit={() => onEdit(objRenderData.obj)}
        ondelete={() => onDelete(objRenderData.obj)}
      />
    )
  }

  return (
    <div className={classes.root}>
      <CardHeader className="border-0 h-100">
        <HeaderCard text={t('KEYWORD.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="align-items-center">
              <Col md={8}>
                <Row className="flax-row">
                  <Col sm={12} md={6} xl={3} className="pt-2">
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('INPUT.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={3} className="pl-md-0 pt-2">
                    <FilterSelect
                      onchange={(event) => {
                        const value = event.target.value
                        if (value) {
                          handleChangeStatus(value)
                        }
                      }}
                      renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                      label={t('STATUS.LABEL')}
                      selectId="select-status"
                      value={status}
                      labelId="label-status"
                      options={[
                        <MenuItem key="1" value="ALL">
                          {t('STATUS.ALL')}
                        </MenuItem>,
                        <MenuItem key="2" value="ACTIVE">
                          {t('STATUS.ACTIVE')}
                        </MenuItem>,
                        <MenuItem key="3" value="INACTIVE">
                          {t('STATUS.INACTIVE')}
                        </MenuItem>
                      ]}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={3} className="pl-xl-0 pt-2">
                    <FilterSelect
                      onchange={(event) => {
                        const value = event.target.value
                        if (value) {
                          handleChangeKeywordType(value)
                        }
                      }}
                      renderValue={() => `${t('KEYWORD.FILTER.MESSAGE_TYPE.LABEL')}: ${t(`KEYWORD.FILTER.MESSAGE_TYPE.${filterKeywordType}`)}`}
                      label={t('KEYWORD.FILTER.MESSAGE_TYPE.LABEL')}
                      selectId="select-word-type"
                      value={filterKeywordType}
                      labelId="label-word-type"
                      options={[
                        <MenuItem key="1" value="ALL">
                          {t('KEYWORD.FILTER.MESSAGE_TYPE.ALL')}
                        </MenuItem>,
                        <MenuItem key="2" value="DISEASE">
                          {t('KEYWORD.FILTER.MESSAGE_TYPE.DISEASE')}
                        </MenuItem>,
                        <MenuItem key="3" value="MEDICINE_USE">
                          {t('KEYWORD.FILTER.MESSAGE_TYPE.MEDICINE_USE')}
                        </MenuItem>,
                        <MenuItem key="4" value="BE_ALLERGIC">
                          {t('KEYWORD.FILTER.MESSAGE_TYPE.BE_ALLERGIC')}
                        </MenuItem>
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mt-2 mt-md-auto">
                <div className="pr-xl-2">
                  <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => dispatch(showModal())} textButton={t('KEYWORD.BUTTON.ADD')} className="w-auto ml-auto d-flex mt-auto" />
                </div>
              </Col>
            </Row>
          </Card.Title>

          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={keywords.map((val, i) => {
                return renderData(val, i)
              })}
            />
          </div>
        </Card.Body>
      </CardHeader>

      <ModalCustom
        size={'sm'}
        title={keywordId ? t('KEYWORD.TITLE_UPDATE') : t('KEYWORD.TITLE_CREATE')}
        component={
          <div className="pb-2">
            {keywordId ? (
              <div>
                <Form className="mx-0 mt-0 mb-3 p-0  d-inline-flex w-100">
                  <Form.Label className=" mr-auto">{t('KEYWORD.TITLE_STATUS')}</Form.Label>
                  <Form.Check type="switch" id="custom-switch" onChange={() => setKeywordStatus(keywordStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')} checked={keywordStatus === 'ACTIVE' ? true : false} style={{ marginLeft: 'auto' }} />
                </Form>
              </div>
            ) : (
              ''
            )}

            <Col>
              <BasicSelect
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    setKeywordType(value)
                    clearErrorMessage()
                  }
                }}
                required={true}
                label={t('KEYWORD.FILTER.MESSAGE_TYPE.LABEL')}
                selectId="sl-text-type"
                value={keywordType}
                labelId="label-text-type"
                options={keywordTypeSelect.map((obj: any) => {
                  return (
                    <MenuItem key={obj.value} value={obj.value}>
                      {obj.label}
                    </MenuItem>
                  )
                })}
                helperText={errorMessage.KEYWORD_TYPE_REQUIRED}
              />
            </Col>
            <Col className="mt-3">
              <Divider className="mx-0 mb-3" sx={{ backgroundColor: 'transparent', borderColor: `${colors.lightGray} !important`, opacity: 1 }} />
              <InputTextarea
                required={true}
                label={t('TEMPLATE_TEXT.INPUT.MESSAGE')}
                value={word}
                helperText={errorMessage.MASSAGE_STRING_BASE || errorMessage.MASSAGE_STRING_EMPTY || errorMessage.MASSAGE_MAXIMUM_LENGTH || errorMessage.MASSAGE_ANY_REQUIRED}
                onchange={(e) => {
                  setMessage(e.target.value)
                  clearErrorMessage()
                }}
              />
            </Col>
          </div>
        }
        onReset={resetForm}
        onSubmit={keywordId ? submitEdit : submit}
        textBtnCancel={t('KEYWORD.BUTTON.CANCEL')}
        textBtnConfirm={keywordId ? t('KEYWORD.BUTTON.UPDATE') : t('KEYWORD.BUTTON.SAVE')}
      />
    </div>
  )
}
