import { menuSeconds } from 'constants/menus'
import RecallLists from 'features/counter/recall/recall-lists'

const routeRecallLists = [
    {
        ...menuSeconds.RECALL_LISTS,
        active: false,
        appBar: true,
        component: RecallLists
    }
]

export default routeRecallLists
