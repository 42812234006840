import BaseAPI from 'api/api'

const url = `home`

export interface SendLineMessage {
  patientCaseId: number
  servicePointId: number
  message: string
}

export interface MessageTemplate {
  templateTextId: number
  message: string
}

export default class HomeApi extends BaseAPI {
  static clinicIncome(): Promise<any> {
    return this.api.get(`${url}/clinic-income`)
  }

  static listQueue(): Promise<any> {
    return this.api.get(`${url}/list-queue`)
  }

  static patientCase(patientCaseId: number): Promise<any> {
    return this.api.get(`${url}/patient-case/${patientCaseId}`)
  }

  static cancelVisit(patientCaseId: number, payload: any): Promise<any> {
    return this.api.patch(`${url}/cancel-visit/${patientCaseId}`, payload)
  }

  static allAppointment(): Promise<any> {
    return this.api.get(`${url}/list-appointment`)
  }

  static allDentists(): Promise<any> {
    return this.api.get(`${url}/list-dentists`)
  }

  static templateMessageBranch(): Promise<any> {
    return this.api.get(`${url}/template-message-branch`)
  }

  static templateMessageUser(): Promise<any> {
    return this.api.get(`${url}/template-message-user`)
  }

  static editTemplateMessage(payload: MessageTemplate[]): Promise<any> {
    return this.api.post(`${url}/template-text`, payload)
  }

  static lineMessageHistory(cnNumber: number): Promise<any> {
    return this.api.get(`${url}/line-message-histories/${cnNumber}`)
  }

  static sendLineMessage(payload: SendLineMessage): Promise<any> {
    return this.api.post(`${url}/send-line-message`, payload)
  }

  static updateStatusService(patientCaseId: number): Promise<any> {
    return this.api.patch(`${url}/patient-case/${patientCaseId}/service`)
  }
}
