import BaseAPI from 'api/api'
import { AboutUsProps, ArticleProps, ArticleSortProps, BannerProps, FilterWebContactUsProps, PriceListProps, ReviewProps, ReviewSortProps, ServiceProps, ServiceSortProps, WebContactUsProps } from './manageWeb-interface'

const banner = '/banner'
const aboutUs = '/about-us-minimal'
const service = '/providing-service'
const priceList = '/price-list'
const review = '/review'
const article = '/article'
const contactUs = '/contact-us'
const webContactUs = '/web-contact-us'

export default class ManageWebApi extends BaseAPI {
    // Banner
    static getBanner(): Promise<any> {
        return this.api.get(`${banner}`)
    }
    static saveBanner(payload: BannerProps): Promise<any> {
        return this.api.post(`${banner}`, payload)
    }
    static delBanner(id: number): Promise<any> {
        return this.api.delete(`${banner}/${id}`).then(() => {
            return "pass"
        }).catch(() => {
            return "nopass"
        })
    }
    // About-us
    static getAboutUs(): Promise<any> {
        return this.api.get(`${aboutUs}`)
    }
    static saveAboutUs(payload: AboutUsProps): Promise<any> {
        return this.api.post(`${aboutUs}`, payload).then(() => {
            return "pass"
        }).catch(() => {
            return "nopass"
        })
    }
    static delAboutUs(id: number): Promise<any> {
        return this.api.delete(`${aboutUs}/${id}`).then(() => {
            return "pass"
        }).catch(() => {
            return "nopass"
        })
    }
    // service
    static getService(): Promise<any> {
        return this.api.get(`${service}`)
    }

    static createService(payload: ServiceProps): Promise<any> {
        return this.api.post(`${service}`, payload).then(() => {
            return "pass"
        }).catch(() => {
            return "nopass"
        })
    }

    static updateService(id: number, payload: ServiceProps): Promise<any> {
        return this.api.patch(`${service}/${id}`, payload).then(() => {
            return "pass"
        }).catch(() => {
            return "nopass"
        })
    }
    static delService(id: number): Promise<any> {
        return this.api.delete(`${service}/${id}`).then(() => {
            return "pass"
        }).catch(() => {
            return "nopass"
        })
    }
    static sortService(payload: ServiceSortProps[]): Promise<any> {
        return this.api.patch(`${service}/sorting`, payload).then(() => {
            return "pass"
        }).catch(() => {
            return "nopass"
        })
    }
    // price-list
    static getPriceList(): Promise<any> {
        return this.api.get(`${priceList}`)
    }
    static savePriceList(payload: PriceListProps): Promise<any> {
        return this.api.post(`${priceList}`, payload)
    }
    static delPriceList(id: number): Promise<any> {
        return this.api.delete(`${priceList}/${id}`).then(() => {
            return "pass"
        }).catch(() => {
            return "nopass"
        })
    }
    // review
    static getReview(): Promise<any> {
        return this.api.get(`${review}`)
    }
    static createReview(payload: ReviewProps): Promise<any> {
        return this.api.post(`${review}`, payload).then(() => {
            return "pass"
        }).catch(() => {
            return "nopass"
        })
    }
    static updateReview(id: number, payload: ReviewProps): Promise<any> {
        return this.api.patch(`${review}/${id}`, payload).then(() => {
            return "pass"
        }).catch(() => {
            return "nopass"
        })
    }
    static delReview(id: number): Promise<any> {
        return this.api.delete(`${review}/${id}`).then(() => {
            return "pass"
        }).catch(() => {
            return "nopass"
        })
    }
    static sortReview(payload: ReviewSortProps[]): Promise<any> {
        return this.api.patch(`${review}/sorting`, payload).then(() => {
            return "pass"
        }).catch(() => {
            return "nopass"
        })
    }
    // Article
    static getArticle(): Promise<any> {
        return this.api.get(`${article}`)
    }
    static createArticle(payload: ArticleProps): Promise<any> {
        return this.api.post(`${article}`, payload).then(() => {
            return "pass"
        }).catch(() => {
            return "nopass"
        })
    }
    static updateArticle(id: number, payload: ArticleProps): Promise<any> {
        return this.api.patch(`${article}/${id}`, payload).then(() => {
            return "pass"
        }).catch(() => {
            return "nopass"
        })
    }
    static delArticle(id: number): Promise<any> {
        return this.api.delete(`${article}/${id}`).then(() => {
            return "pass"
        }).catch(() => {
            return "nopass"
        })
    }
    static sortArticle(payload: ArticleSortProps[]): Promise<any> {
        return this.api.patch(`${article}/sorting`, payload).then(() => {
            return "pass"
        }).catch(() => {
            return "nopass"
        })
    }
    // contact-us

    static getContactUs(): Promise<any> {
        return this.api.get(`${contactUs}`)
    }

    static getWebContactUs(branchId: number,): Promise<any> {
        return this.api.get(`${webContactUs}/${branchId}`)
    }

    static createWebContactUs(payload: WebContactUsProps): Promise<any> {
        return this.api.post(`${webContactUs}/office-time`, payload).then(() => {
            return "pass"
        }).catch(() => {
            return "nopass"
        })
    }

    static updateWebContactUs(branchId: number, payload: WebContactUsProps): Promise<any> {
        return this.api.patch(`${webContactUs}/${branchId}`, payload).then(() => {
            return "pass"
        }).catch(() => {
            return "nopass"
        })
    }

    static delWebContactUs(branchId: number): Promise<any> {
        return this.api.delete(`${webContactUs}/${branchId}`).then(() => {
            return "pass"
        }).catch(() => {
            return "nopass"
        })
    }

}