import { useEffect, useState } from 'react'
import { Box, Typography, Grid, MenuItem, Tooltip } from '@mui/material'
import _, { forEach } from 'lodash'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import PrivilegeList from 'features/finance/payment/component/popupPayment/PrivilegeList'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** UTILS */
import { numberToCurrencyFormat } from 'utils/app.utils'

/** API */
import { PaymentInterface } from 'api/counter/payment/payment-interface'
import ClinicApi from 'api/master/clinic.api'

/** STYLE */
import { styled } from '@mui/material/styles'
import * as UseStyled from 'features/finance/payment/component/popupPayment/UseStyledFormPayment'
import Bank from 'assets/icon/payment-method/bank'
import Cash from 'assets/icon/payment-method/cash'
import Credit from 'assets/icon/payment-method/credit'
import Debit from 'assets/icon/payment-method/debit'
import { fileUrl } from 'api/api'
import { useDispatch, useSelector } from 'react-redux'
import {
  summery,
  summerySub,
  setSummeryByKey,
  setSummerySubByKey,
  valBankTransfer,
  valBankCredit,
  valBankDebit,
  setBankTransfer,
  setBankCredit,
  setBankDebit,
  setPaymentChannelByKey,
  valPayment,

  summerySubTreatmentRight,
  setSummerySubTreatmentRightByKey,

  setPaymentByKey,
  resetPaymentChannelByKey,
  resetSummery,
  summeryTreatmentRight,
  setSummeryTreatmentRightByKey
} from 'app/slice/payment.slice'
import { onImgError } from 'constants/images'
import TreatmentRight from 'assets/icon/payment-method/treatmentRight'
import Advance from 'assets/icon/payment-method/advanced'
import TreatmentDiscount from './TreatmentDiscount'

import { PatientFinanceInterface } from './Index'

import BasicSelect from 'component/Select/BasicSelect'

export const ChannelBox = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  borderRadius: 4,
  border: `1px solid ${colors.lightGray}`,
  backgroundColor: colors.white,
  height: 96,
  paddingLeft: 10,
  paddingRight: 10,
  textAlign: 'center',
  '.MuiTypography-root': {
    color: colors.disabledGray
  },
  'p.channel-name': {
    width: '90%',
  },
  '&.active': {
    border: `5px solid ${colors.themeSecondColor}`,
    position: 'relative',
    '.MuiTypography-root': {
      color: colors.themeSecondColor
    }
  },
  '& .number': {
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: 24,
    minHeight: 24,
    color: colors.white,
    background: colors.themeSecondColor,
    borderRadius: '0px 0 20px',
    padding: '0px 0 0 6px',
    fontSize: 14,
    lineHeight: 'normal'
  },
  [theme.breakpoints.down('sm')]: {
    svg: {
      width: 'auto',
      height: 30
    }
  },
  [theme.breakpoints.down('xs')]: {
    margin: '0 1px',
    height: 44,
    outline: `1px solid ${colors.lightGray}`,
    border: '3px solid transparent',
    svg: {
      height: 20
    },
    '&.active': {
      border: `3px solid ${colors.themeSecondColor}`,
      outline: `1px solid transparent`
    }
  }
}))

export const ButtonBank = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  borderRadius: 4,
  border: `2px solid ${colors.lightGray}`,

  img: {
    borderRadius: 4,
    height: 30,
    width: 30,
    objectFit: 'cover'
  },
  '&.active': {
    boxShadow: `0 0 6px 0 ${colors.themeSecondColor}`,
    borderColor: colors.themeSecondColor
  }
}))

export const TypographyBankNumber = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  [theme.breakpoints.up('sm')]: {
    fontSize: 16
  }
}))

type PaymentChannelsType = {
  treatments?: any
  banks?: any[]
  balancePaid?: number
  overdue?: number
  menu?: 'PAYMENT' | 'OVERDUE' | 'RETROSPECTIVE' | 'EDIT_PAYMENT'
  patientFinance?: any[]
  patientFinanceFull: PatientFinanceInterface
  advance?: boolean
  disableIcon?: boolean
}

export default function PaymentChannels(props: PaymentChannelsType) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const objPayment: PaymentInterface = useSelector(valPayment)
  const valSummery = useSelector(summery)
  const valSummerySub = useSelector(summerySub)
  const bankCredit = useSelector(valBankCredit)
  const bankDebit = useSelector(valBankDebit)
  const bankTransfer = useSelector(valBankTransfer)
  const valSummerySubTreatmentRight = useSelector(summerySubTreatmentRight)

  /** OPTIONS */
  const [paymentChannels, setPaymentChannels]: any = useState([
    { key: 'TREATMENTRIGHT', paymentChanel: 5, isActive: false },
    { key: 'ADVANCE', paymentChanel: 6, isActive: false },
    { key: 'CASH', paymentChanel: 1, isActive: false },
    { key: 'TRANSFER', paymentChanel: 2, isActive: false },
    { key: 'CREDIT', paymentChanel: 3, isActive: false },
    { key: 'DEBIT', paymentChanel: 4, isActive: false }
  ])
  const [bankCredits, setBankCredits] = useState<any[]>([])
  const [bankDebits, setBankDebits] = useState<any[]>([])
  const [bankTransfers, setBankTransfers] = useState<any[]>([])

  /** VALUES */
  const [selectChannels, setSelectChannels]: any = useState<any[]>([])
  const { advanceBalance } = props.patientFinanceFull
  const [disabled, setIsDisabled]: any = useState<any[]>([])
  const [billPrefixes, setBillPrefixes] = useState([])
  const [editVerifySummary, setEditVerifySummary] = useState<any[]>([])
  useEffect(() => {
    setBankCredits([])
    setBankDebits([])
    setBankTransfers([])
    dispatch(setBankTransfer({}))
    dispatch(setBankCredit({}))
    dispatch(setBankDebit({}))
    if (props.banks?.length) {
      setBankTransfers([...props.banks.filter((bank: any) => bank.hasTransfer === 'SUPPORT')])
      setBankCredits([...props.banks.filter((bank: any) => bank.hasCredit === 'SUPPORT')])
      setBankDebits([...props.banks.filter((bank: any) => bank.hasDebit === 'SUPPORT')])
    }

    if (props.treatments?.orderId) {
      dispatch(resetPaymentChannelByKey())
      dispatch(resetSummery())

      _.map(editVerifySummary, (obj: any, index: any) => {
        const handleChangValue = () => {
          const valuePayment = obj.value
          const rawValuePM = valuePayment === '' ? 0 : String(valuePayment).replace(/[^0-9.]/g, '')
          const valuePM = rawValuePM ? parseFloat(rawValuePM) : 0

          const selectSumSub: any[] = valSummerySub[obj.pcId]
          const { sumPay } = calSumValSummery()

          const paid: number = valuePM

          let overPaid: any = 0
          for (const key in selectSumSub) {
            if (Object.prototype.hasOwnProperty.call(selectSumSub, key)) {
              const sub = selectSumSub[key]
              const newVal = obj.pcId === 5 ? renderSummerySubFive(sumPay, sub.id) : sumPay[obj.pcId] && sumPay[obj.pcId]?.filter((item: any) => item.refItemId === sub.id)[0]?.amountPaid
              const val: any = newVal || 0

              if (obj.pcId === 1) {
                overPaid = newVal || 0
              }
              dispatch(
                setSummerySubByKey({
                  key: obj.pcId,
                  keySub: key,
                  value: {
                    ...sub,
                    value: newVal || 0,
                    isCheck: val > 0 ? true : false,
                    isDisabled: val > 0 ? false : true
                  }
                })
              )
            }
          }
        }
        handleChangValue()
      })

      if (props.treatments?.orderPaymentChannelTreatmentRight) {
        // handleSelectPaymentChannel({key: "TREATMENTRIGHT", isActive: true, paymentChanel: 5, value: props.treatments?.orderPaymentChannelTreatmentRight})
        dispatch(setPaymentChannelByKey({ key: 5, value: true }))
        const { sumPay } = calSumValSummery()
        if (sumPay[5]) {
          dispatch(setSummeryByKey({ key: 5, value: props.treatments?.orderPaymentChannelTreatmentRight }))
          const sumAllFive: any = []
          sumPay[5].forEach((item: any, index: number) => {
            item.forEach((info: any, indexInfo: number) => {
              const newDis = {
                key: 5,
                keySub: indexInfo,
                patientTreatmentRightId: info?.patientTreatmentRightId || 0,
                value: {
                  id: info.refItemId,
                  refItemId: info.refItemId,
                  type: info.type,
                  value: info.amountPaid,
                  treatmentPrice: info.treatmentPrice || 0,
                  isCheck: info.amountPaid && info.amountPaid !== 0 ? true : false,
                  isDisabled: info.amountPaid && info.amountPaid !== 0 ? true : false,
                }
              }
              dispatch(setSummerySubTreatmentRightByKey(newDis))
              const isCheckDis = sumAllFive[index] && sumAllFive[index]?.value + info.amountPaid !== 0 ? true : false || false
              if (sumAllFive[index]) {
                sumAllFive[index] = {
                  patientTreatmentRightId: info.patientTreatmentRightId,
                  isCheck: isCheckDis,
                  isDisabled: !isCheckDis,
                  value: sumAllFive[index]?.value + info.amountPaid
                }
              } else {
                sumAllFive[index] = {
                  patientTreatmentRightId: info.patientTreatmentRightId,
                  isCheck: info.amountPaid !== 0 ? true : false,
                  isDisabled: info.amountPaid !== 0 ? false : true,
                  value: info.amountPaid
                }
              }
            })
          })
          sumAllFive?.forEach((item: any) => {
            dispatch(setSummeryTreatmentRightByKey({ key: 5, patientTreatmentRightId: item.patientTreatmentRightId, value: { isCheck: item.isCheck, isDisabled: item.isDisabled, value: item.value } }))
          })
        }
      }
      if (props.treatments?.orderPaymentChannelAdvance) {
        // handleSelectPaymentChannel({key: "ADVANCE", isActive: true, paymentChanel: 6, value: props.treatments?.orderPaymentChannelAdvance})
        dispatch(setPaymentChannelByKey({ key: 6, value: true }))
        dispatch(setSummeryByKey({ key: 6, value: props.treatments?.orderPaymentChannelAdvance }))

      }
      if (props.treatments?.orderPaymentChannel) {
        // handleSelectPaymentChannel({key: "CASH", isActive: true, paymentChanel: 1, value: props.treatments?.orderPaymentChannelAdvance})

        const val = props.treatments?.dataOrderPayment

        if (val) {
          if (val["เงินสด"]) {
            dispatch(setPaymentChannelByKey({ key: 1, value: true }))
            dispatch(setSummeryByKey({ key: 1, value: setCount("เงินสด") }))
          }
          if (val["โอน"]) {
            dispatch(setPaymentChannelByKey({ key: 2, value: true }))
            dispatch(setSummeryByKey({ key: 2, value: setCount("โอน") }))
          }
          if (val["บัตรเครดิต"]) {
            dispatch(setPaymentChannelByKey({ key: 3, value: true }))
            dispatch(setSummeryByKey({ key: 3, value: setCount("บัตรเครดิต") }))
          }
          if (val["บัตรเดบิต"]) {
            dispatch(setPaymentChannelByKey({ key: 4, value: true }))
            dispatch(setSummeryByKey({ key: 4, value: setCount("บัตรเดบิต") }))
          }
        } else {
          dispatch(setPaymentChannelByKey({ key: 1, value: true }))
          dispatch(setSummeryByKey({ key: 1, value: props.treatments?.orderPaymentChannel }))
        }
      }
    }
  }, [props.banks, props.patientFinance])

  const renderSummerySubFive = (sumPay: any, id: number) => {
    let newSum = 0
    sumPay[5].forEach((item: any) => {
      item.forEach((info: any) => {
        if (info.refItemId === id) {
          newSum += info.amountPaid
        }
      });
    })
    return newSum
  }

  const calSumValSummery = () => {
    const sum: any = {}
    const sumPay: any = {}
    const newPayFive: any = []

    const onSumFive = () => {
      const newData: any = newPayFive.map((item: any) => {
        return item.map((info: any) => {
          const newSubPayFive = sumPay[5]?.filter((item5: any) => {
            if (item5.treatmentRightId === info.treatmentRightId && item5.refItemId === info.refItemId) {
              return { ...info, amountPaid: item5.amountPaid, patientTreatmentRightId: info.patientTreatmentRightId }
            }
          })
          return newSubPayFive?.length !== 0 ? { ...info, amountPaid: newSubPayFive[0]?.amountPaid || 0 } : { ...info, amountPaid: 0 }
        })
      })
      return newData || []
    }

    if (props.menu === 'EDIT_PAYMENT') {
      const newOrderId: any = []
      props.treatments.order.orderProducts.forEach((item: any) => {
        if (item.orderProductId) {
          newOrderId.push({ refItemId: item.orderProductId, type: "ORDER_PRODUCT", })
        }
      })
      props.treatments.order.orderTreatments.forEach((item: any) => {
        if (item.orderTreatmentId) {
          newOrderId.push({ refItemId: item.orderTreatmentId, type: "ORDER_TREATMENT" })
        }
      })

      props.patientFinance?.forEach((item: any, index: number) => {
        newOrderId.forEach((info: any) => {
          if (newPayFive[index]) {
            newPayFive[index] = [...newPayFive[index], { ...info, patientTreatmentRightId: item.patientTreatmentRightId, treatmentRightId: item.treatmentRightId }]
          } else {
            newPayFive[index] = [{ ...info, patientTreatmentRightId: item.patientTreatmentRightId, treatmentRightId: item.treatmentRightId }]
          }

        })
      })

      props.treatments.orderPaymentChannels.forEach((item: any) => {
        item.orderPaymentItems.forEach((info: any) => {
          if (sum[info.refItemId]) {
            sum[info.refItemId] += info.amountPaid
          } else {
            sum[info.refItemId] = info.amountPaid
          }
          if (sumPay[item.paymentChannelId]) {
            sumPay[item.paymentChannelId] = [...sumPay[item.paymentChannelId], {
              refItemId: info.refItemId,
              amountPaid: info.amountPaid,
              treatmentRightId: item.treatmentRightId,
              patientTreatmentRightId: 0,
              refItemType: item.refItemType
            }]
          } else {
            sumPay[item.paymentChannelId] = [{ refItemId: info.refItemId, amountPaid: info.amountPaid, treatmentRightId: item.treatmentRightId, patientTreatmentRightId: 0, refItemType: item.refItemType }]
          }
        })
      })

      if (sumPay[5]) {
        sumPay[5] = onSumFive()
      }
    }
    return { sum, sumPay, newPayFive }
  }

  const setCount = (status: string) => {

    const val = props.treatments?.dataOrderPayment[status]
    if (status === "เงินสด") {
      return val
    } else if (status === "โอน") {
      let TransferBankName = 0
      const bank: any = props?.banks?.filter((bank: any) => bank.hasTransfer === 'SUPPORT')
      bank.forEach((element: any) => {
        if (val?.name.trim() === element.bankName.trim()) {
          TransferBankName = val?.amount
          dispatch(setBankTransfer(element))
        }
      });
      return TransferBankName
    } else if (status === "บัตรเดบิต" || status === "บัตรเครดิต") {
      const setBank = status === "บัตรเครดิต" ? "hasCredit" : "hasDebit"
      const setBank_sub = status === "บัตรเครดิต" ? "credit" : "debit"
      let TransferBankName = 0
      const bank: any = props?.banks?.filter((bank: any) => bank[setBank] === 'SUPPORT')
      const val_split = val?.name.split("/")
      bank.forEach((element: any) => {
        if (val_split[0].trim() === element.bankName.trim()) {
          element[setBank_sub].forEach((item: any) => {
            if (val_split[1].trim() === item.cardTypeName.trim()) {
              TransferBankName = val?.amount
              if (status === "บัตรเครดิต") {
                dispatch(setBankCredit({ ...element, ...item }))
              } else {
                dispatch(setBankDebit({ ...element, ...item }))
              }

            }
          })
        }
      });
      return TransferBankName
    } else {
      return 0
    }
  }
  // const checkActiveChannel = (key: 'TREATMENTRIGHT' | 'ADVANCE' | 'CASH' | 'TRANSFER' | 'CREDIT' | 'DEBIT') => {
  //   if (selectChannels && _.map(selectChannels, (e: any) => e.key).indexOf(key) !== -1) return true
  //   return false
  // }

  const handleSelectPaymentChannel = (channel: any) => {
    // const dataChannels = selectChannels
    let tempChannels: any = []
    channel.isActive = !channel.isActive
    if (!_.find(selectChannels, { key: channel.key })) {
      // dataChannels[channel.value] = channel.key
      let paidValue: any = 0
      if (selectChannels.length === 0) {
        if (Object.values(_.filter(valSummery, (v: any, i: any) => i !== 0)).filter((val: any) => val > 0).length === 0) {
          paidValue = props.menu === 'EDIT_PAYMENT' ? props.treatments.amountReceipt : props?.balancePaid || 0
        }
      }
      dispatch(setPaymentChannelByKey({ key: channel.paymentChanel, value: true }))
      dispatch(setSummeryByKey({ key: channel.paymentChanel, value: paidValue }))
      verifySummary(channel.paymentChanel, paidValue, props.treatments)
      tempChannels = [...selectChannels, channel]
    } else {
      tempChannels = _.filter(selectChannels, (temp: any) => {
        if (temp) {
          return temp.key !== channel.key
        }
      })

      // dataChannels[channel.value] = null
      dispatch(setPaymentChannelByKey({ key: channel.paymentChanel, value: false }))
      dispatch(setSummeryByKey({ key: channel.paymentChanel, value: 0 }))
      if (channel.paymentChanel === 5) {
        for (const ptrId in valSummerySubTreatmentRight[5]) {
          if (Object.prototype.hasOwnProperty.call(valSummerySubTreatmentRight[5], ptrId)) {
            const strSub = valSummerySubTreatmentRight[5][ptrId];
            for (const index in strSub) {
              if (Object.prototype.hasOwnProperty.call(strSub, index)) {
                const valSub = strSub[index];
                dispatch(setSummerySubTreatmentRightByKey({ key: 5, keySub: index, patientTreatmentRightId: ptrId, value: { ...valSub, isCheck: false, value: 0 } }))
              }
            }

          }
        }
        // _.map(valSummerySubTreatmentRight[5], (strSub: any[], ptrId: any) => {
        //   _.map(strSub, (valSub: any, index: any) => {
        //     dispatch(setSummerySubTreatmentRightByKey({ key: 5, keySub: index, patientTreatmentRightId: ptrId, value: { ...valSub, isCheck: false, value: 0 } }))
        //   })
        // })
      }

      if (channel.key === 'TRANSFER') dispatch(setBankTransfer({}))
      if (channel.key === 'CREDIT') dispatch(setBankCredit({}))
      if (channel.key === 'DEBIT') dispatch(setBankDebit({}))
      verifySummary(channel.paymentChanel, 0, props.treatments)
    }
    setSelectChannels(tempChannels)
  }
  useEffect(() => {
    ClinicApi.allBillPrefix().then((resBillPrefixes) => {
      setBillPrefixes(resBillPrefixes.data)
      dispatch(setPaymentByKey({ key: 'prefix', value: resBillPrefixes.data[0].prefix || '' }))
    })
    if (props.treatments?.orderId) {
      if (props.treatments?.orderPaymentChannelTreatmentRight) {
        // handleSelectPaymentChannel({key: "TREATMENTRIGHT", isActive: true, paymentChanel: 5, value: props.treatments?.orderPaymentChannelTreatmentRight})
        selectChannels.push(paymentChannels[0])
        editVerifySummary.push({ pcId: 5, value: props.treatments?.orderPaymentChannelTreatmentRight })
      }
      if (props.treatments?.orderPaymentChannelAdvance) {
        // handleSelectPaymentChannel({key: "ADVANCE", isActive: true, paymentChanel: 6, value: props.treatments?.orderPaymentChannelAdvance})
        selectChannels.push(paymentChannels[1])
        dispatch(setSummeryByKey({ key: 6, value: props.treatments?.orderPaymentChannelAdvance }))
        editVerifySummary.push({ pcId: 6, value: props.treatments?.orderPaymentChannelAdvance })

        // dispatch(setSummeryByKey({ key: 1, value: props.treatments?.orderPaymentChannelAdvance }))

      }
      if (props.treatments?.orderPaymentChannel) {
        // handleSelectPaymentChannel({key: "CASH", isActive: true, paymentChanel: 1, value: props.treatments?.orderPaymentChannelAdvance})

        // dispatch(setSummeryByKey({ key: 1, value: props.treatments?.orderPaymentChannel }))
        const val = props.treatments?.dataOrderPayment
        if (val) {
          if (val["เงินสด"]) {
            selectChannels.push(paymentChannels[2])
            editVerifySummary.push({ pcId: 1, value: val['เงินสด'] })
          }
          if (val["โอน"]) {
            selectChannels.push(paymentChannels[3])
            editVerifySummary.push({ pcId: 2, value: val['โอน']['amount'] })
          }
          if (val["บัตรเครดิต"]) {
            selectChannels.push(paymentChannels[4])
            editVerifySummary.push({ pcId: 3, value: val['บัตรเครดิต']['amount'] })
          }
          if (val["บัตรเดบิต"]) {
            selectChannels.push(paymentChannels[5])
            editVerifySummary.push({ pcId: 4, value: val['บัตรเดบิต']['amount'] })
          }
        } else {
          selectChannels.push(paymentChannels[2])
          editVerifySummary.push({ pcId: 1, value: props.treatments?.orderPaymentChannel })
        }
      }
    }
  }, [])

  useEffect(() => {
    const newSet: any = []
    _.map(paymentChannels, (paymentChannel, index) => {
      newSet.push({ ...paymentChannel, isActive: _.map(selectChannels, (e: any) => e.key).indexOf(paymentChannel.key) !== -1 ? true : false })
    })
    setPaymentChannels(newSet)

  }, [selectChannels])

  const handleSelectPaymentChannelTransfer = (bank: any) => {
    if (bankTransfer.bankId !== bank.bankId) dispatch(setBankTransfer(bank))
    else dispatch(setBankTransfer({}))
  }

  const handleSelectPaymentChannelCredit = (bank: any) => {
    if (bankCredit.bankId !== bank.bankId) dispatch(setBankCredit(bank))
    else dispatch(setBankCredit({}))
  }

  const handleSelectPaymentChannelDebit = (bank: any) => {
    if (bankDebit.bankId !== bank.bankId) dispatch(setBankDebit(bank))
    else dispatch(setBankDebit({}))
  }

  const verifySummary = (pcId: number, val: number, treatments: any) => {
    let balanceSub: number = val
    _.map(valSummerySub[pcId], (sub: any, index: any) => {
      const total: any = sub?.paidBalance || 0
      let paid: any = 0

      if (Object.values(valSummery).filter((valSum: any) => valSum > 0).length === 0) {
        if (balanceSub >= total) {
          paid = total
          balanceSub -= total
        } else {
          paid = balanceSub
          balanceSub -= balanceSub
        }
      }

      const objUpdate: any = {
        key: pcId,
        keySub: index,
        value: {
          ...sub,
          isCheck: paid > 0 ? true : false,
          isDisabled: paid > 0 ? false : true,
          value: paid
        }
      }
      dispatch(setSummerySubByKey(objUpdate))
    })
  }

  const disableTextFiled = (chanel: number) => {
    const paidBalance = _.sumBy(disabled[chanel], 'paidBalance')
    const value = _.sumBy(disabled[chanel], 'value')
    if (value === 0 && paidBalance === 0) {
      return chanel === 5 ? false : true
    } else {
      return false
    }
  }

  const checkIndex = (key: string) => {
    return _.findIndex(selectChannels, { key: key }) + 1
  }


  useEffect(() => {
    setIsDisabled(valSummerySub)
  }, [valSummerySub])

  return (
    <Box className="mb-3">
      {props.advance ?
        <Grid container spacing={2} className="mt-0">
          <Grid sm={5}>
            <UseStyled.Title className="px-md-2 px-xl-3 mt-2">
              {t('PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.TITLE')}
            </UseStyled.Title>
          </Grid>
          <Grid className='mr-2'>
            <Typography className="pl-3 d-none d-sm-block mt-2" sx={{ flex: 'none' }}>
              {t('PAYMENT.FORM_PAYMENT.RECEIPT_TYPE')}
            </Typography>
          </Grid>
          <Grid sm={2}>
            <BasicSelect
              selectId=""
              label=""
              labelId="billPrefixId"
              onchange={(e: any) => dispatch(setPaymentByKey({ key: 'prefix', value: e.target.value }))}
              value={objPayment.prefix}
              options={_.map(billPrefixes, (i: any) => (
                <MenuItem key={i.billPrefixId || i.paymentChannelId} value={i.prefix || i.defaultPrefix}>
                  {i.prefix || i.defaultPrefix}
                </MenuItem>
              ))}
            />
          </Grid>
          <Grid>
            <Typography className="pl-3 d-none d-sm-block mt-2" sx={{ flex: 'none' }}>
              {'-xxx-xx-xx-xxxxx'}
            </Typography>
          </Grid>
        </Grid>
        :
        <UseStyled.Title className="px-md-2 px-xl-3">
          {t('PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.TITLE')} <span className="text-muted fz-14 pl-2">{t('PAYMENT.FORM_PAYMENT.LEFTOVER_PAY')} : {numberToCurrencyFormat(props?.overdue || 0)} {t('CURRENCY_CHANGE.TH')}</span>{' '}
        </UseStyled.Title>
      }

      {/* <ButtonCustom textButton={t('Refresh')} onClick={refresh} className="mt-2 mt-md-3" /> */}
      <Box className="px-md-2 px-xl-3">
        <Grid container spacing={1} className="mt-0">
          {_.map(paymentChannels, (paymentChannel, index) => {
            return (
              <Grid item xs={props.advance ? 3 : 2} className="pt-3" sx={{ width: '50%' }} style={{ display: (props.advance && paymentChannel.key === 'TREATMENTRIGHT') || (props.advance && paymentChannel.key === 'ADVANCE') ? 'none' : 'block' }}>
                <ChannelBox onClick={() => handleSelectPaymentChannel(paymentChannel)} className={`d-flex align-items-center justify-content-sm-center flex-sm-column px-2 px-sm-0 text-ellipsis ${paymentChannel.isActive ? 'active' : ''}`}>
                  {checkIndex(paymentChannel.key) !== 0 && <p className={'number'}>{checkIndex(paymentChannel.key)}</p>}
                  {paymentChannel.key === 'TREATMENTRIGHT' && <TreatmentRight active={paymentChannel.isActive ? colors.themeSecondColor : ''} />}
                  {paymentChannel.key === 'ADVANCE' && <Advance active={paymentChannel.isActive ? colors.themeSecondColor : ''} />}
                  {paymentChannel.key === 'CASH' && <Cash active={paymentChannel.isActive ? colors.themeSecondColor : ''} />}
                  {paymentChannel.key === 'TRANSFER' && <Bank active={paymentChannel.isActive ? colors.themeSecondColor : ''} />}
                  {paymentChannel.key === 'CREDIT' && <Credit active={paymentChannel.isActive ? colors.themeSecondColor : ''} />}
                  {paymentChannel.key === 'DEBIT' && <Debit active={paymentChannel.isActive ? colors.themeSecondColor : ''} />}
                  <Tooltip title={t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.${paymentChannel.key}`)} arrow placement="right">
                    <Typography className="pl-2 pl-sm-0 pt-sm-1 text-ellipsis channel-name">{t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.${paymentChannel.key}`)}</Typography>
                  </Tooltip>
                </ChannelBox>
              </Grid>
            )
          })}
        </Grid>

        {_.map(selectChannels, (selectChannels, index) => {
          return (
            <>
              {selectChannels.key === 'TREATMENTRIGHT' && (
                <>
                  {props?.menu === 'PAYMENT' || props?.menu === 'EDIT_PAYMENT' || props?.menu === 'RETROSPECTIVE' || props?.menu === 'OVERDUE' ? (
                    <div className="pt-3">
                      <UseStyled.TitlePayment>
                        {`${index + 1}. `}
                        {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.${selectChannels.key}`)}
                      </UseStyled.TitlePayment>
                      <TreatmentDiscount
                        disableIcon={props.disableIcon}
                        menu={props?.menu}
                        paymentChannelId={5}
                        disableFiled={disableTextFiled(5)}
                        treatments={props.treatments}
                        treatmentRights={props?.patientFinance || []}
                        treatmentRightValue={props.treatments?.orderPaymentChannelTreatmentRight}
                        balancePaid={props.balancePaid}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {selectChannels.key === 'ADVANCE' && (
                <div className="pt-3">
                  <UseStyled.TitlePayment>
                    {`${index + 1}. `}
                    {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.${selectChannels.key}`)}
                  </UseStyled.TitlePayment>
                  <PrivilegeList
                    menu={props?.menu}
                    disableIcon={props.disableIcon}
                    balancePaid={props.patientFinanceFull.advanceBalance <= 0 ? 0 : props?.balancePaid}
                    paymentChannelId={6}
                    disableFiled={disableTextFiled(6)}
                    maxPaid={advanceBalance}
                    title={t('PAYMENT.FORM_PAYMENT.TITLE_DISCOUNT', { privilege: 'เงินล่วงหน้า', price: numberToCurrencyFormat(advanceBalance) })}
                    treatments={props.treatments}
                    discountHasExpired={advanceBalance < 1}
                    value={props.treatments?.orderPaymentChannelAdvance ? props.treatments?.orderPaymentChannelAdvance : null}
                  />
                </div>
              )}
              {selectChannels.key === 'CASH' && (
                <div className="pt-3">
                  <UseStyled.TitlePayment>
                    {`${index + 1}. `}
                    {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.${selectChannels.key}`)}
                  </UseStyled.TitlePayment>
                  <PrivilegeList
                    disableIcon={props.disableIcon}
                    value={props.treatments?.orderPaymentChannel ? props.treatments?.orderPaymentChannel : null}
                    type="PaymentChannels"
                    menu={props?.menu}
                    paymentChannelId={1}
                    title={t('PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.LIST.CASH')}
                    treatments={props.treatments}
                    disableFiled={props.advance ? false : disableTextFiled(1)}
                    advance={props.advance}
                  />
                </div>
              )}
              {selectChannels.key === 'TRANSFER' && (
                <>
                  <div className="pt-3">
                    <UseStyled.TitlePayment>
                      {`${index + 1}. `}
                      {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.${selectChannels.key}`)}{' '}
                    </UseStyled.TitlePayment>
                    <Typography className="mt-3" sx={{ fontWeight: 500 }}>
                      {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.LIST.TRANSFER`)}
                    </Typography>
                    <div className="pl-3">
                      {_.map(bankTransfers, (bank, index) => {
                        return (
                          <ButtonBank onClick={() => handleSelectPaymentChannelTransfer(bank)} className={`d-inline-block mt-3 mr-2 ${bankTransfer.bankId === bank.bankId ? 'active' : ''}`}>
                            <img src={`${fileUrl}/${bank.bankAvata}`} style={{ width: 30, height: 30 }} onError={onImgError} alt="" />
                          </ButtonBank>
                        )
                      })}
                      {bankTransfer?.bankId && (
                        <PrivilegeList
                          disableIcon={props.disableIcon}
                          menu={props?.menu}
                          paymentChannelId={2}
                          disableFiled={props.advance ? false : disableTextFiled(2)}
                          title={
                            <Box className="d-flex align-items-start align-items-md-center mx-0" sx={{ marginLeft: '-16px' }}>
                              <img src={`${fileUrl}/${bankTransfer.bankAvata}`} className="mt-1 mt-md-0" style={{ borderRadius: '4px', width: 30, height: 30 }} onError={onImgError} alt="" />
                              <Box>
                                <Typography className="d-inline-block ml-2">{bankTransfer.bankName}</Typography>
                                <TypographyBankNumber className="d-md-inline-block ml-2 ml-md-5">{t('PAYMENT.FORM_PAYMENT.TITLE_BANK', { number: bankTransfer.bankAccountNumber })}</TypographyBankNumber>
                              </Box>
                            </Box>
                          }
                          treatments={props.treatments}
                          advance={props.advance}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
              {selectChannels.key === 'CREDIT' && (
                <>
                  <div className="pt-3">
                    <UseStyled.TitlePayment>
                      {`${index + 1}. `}
                      {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.${selectChannels.key}`)}{' '}
                    </UseStyled.TitlePayment>
                    <Typography className="mt-3" sx={{ fontWeight: 500 }}>
                      {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.LIST.CREDIT`)}
                    </Typography>
                    <div className="pl-3">
                      {_.map(bankCredits, (bank, index) => {
                        return (
                          <ButtonBank onClick={() => handleSelectPaymentChannelCredit(bank)} className={`d-inline-block mt-3 mr-2 ${bankCredit.bankId === bank.bankId ? 'active' : ''}`}>
                            <img src={`${fileUrl}/${bank.bankAvata}`} style={{ width: 30, height: 30 }} onError={onImgError} alt="" />
                          </ButtonBank>
                        )
                      })}
                      {bankCredit?.bankId && (
                        <PrivilegeList
                          disableIcon={props.disableIcon}
                          menu={props?.menu}
                          paymentChannelId={3}
                          disableFiled={props.advance ? false : disableTextFiled(3)}
                          title={
                            <div>
                              <Box className="d-flex align-items-start align-items-md-center mx-0 w-100" sx={{ marginLeft: '-16px' }}>
                                <img src={`${fileUrl}/${bankCredit.bankAvata}`} className="mt-1 mt-md-0" style={{ borderRadius: '4px', width: 30, height: 30 }} onError={onImgError} alt="" />
                                <Box>
                                  <Typography className="d-inline-block ml-2">{bankCredit.bankName}</Typography>
                                </Box>
                              </Box>
                              <Box className="d-flex align-items-start align-items-md-center w-100">
                                <Box>
                                  <TypographyBankNumber className="d-md-inline-block ml-2 ml-md-5 mr-2">{t('PAYMENT.FORM_PAYMENT.TITLE_CREDIT')}</TypographyBankNumber>
                                  {_.map(bankCredit.credit, (card, index) => (
                                    <ButtonBank onClick={() => dispatch(setBankCredit({ ...bankCredit, ...card }))} className={`d-inline-block mr-2 ${bankCredit.cardTypeId === card.cardTypeId ? 'active' : ''}`} style={{}}>
                                      <img src={`${fileUrl}/${card.cardTypeLogo}`} style={{ width: 30, height: 30 }} onError={onImgError} alt="" />
                                    </ButtonBank>
                                  ))}
                                </Box>
                              </Box>
                            </div>
                          }
                          treatments={props.treatments}
                          advance={props.advance}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
              {selectChannels.key === 'DEBIT' && (
                <>
                  <div className="pt-3">
                    <UseStyled.TitlePayment>
                      {`${index + 1}. `}
                      {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.${selectChannels.key}`)}{' '}
                    </UseStyled.TitlePayment>
                    <Typography className="mt-3" sx={{ fontWeight: 500 }}>
                      {t(`PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.LIST.DEBIT`)}
                    </Typography>
                    <div className="pl-3">
                      {_.map(bankDebits, (bank, index) => {
                        return (
                          <ButtonBank onClick={() => handleSelectPaymentChannelDebit(bank)} className={`d-inline-block mt-3 mr-2 ${bankDebit.bankId === bank.bankId ? 'active' : ''}`}>
                            <img src={`${fileUrl}/${bank.bankAvata}`} style={{ width: 30, height: 30 }} onError={onImgError} alt="" />
                          </ButtonBank>
                        )
                      })}
                      {bankDebit?.bankId && (
                        <PrivilegeList
                          disableIcon={props.disableIcon}
                          menu={props?.menu}
                          paymentChannelId={4}
                          disableFiled={props.advance ? false : disableTextFiled(4)}
                          title={
                            <div>
                              <Box className="d-flex align-items-start align-items-md-center w-100" sx={{ marginLeft: '-16px' }}>
                                <img src={`${fileUrl}/${bankDebit.bankAvata}`} className="mt-1 mt-md-0" style={{ borderRadius: '4px', width: 30, height: 30 }} onError={onImgError} alt="" />
                                <Box>
                                  <Typography className="d-inline-block ml-2">{bankDebit.bankName}</Typography>
                                </Box>
                              </Box>
                              <Box className="d-flex align-items-start align-items-md-center w-100">
                                <Box>
                                  <TypographyBankNumber className="d-md-inline-block ml-2 ml-md-5 mr-2">{t('PAYMENT.FORM_PAYMENT.TITLE_CREDIT')}</TypographyBankNumber>
                                  {_.map(bankDebit.debit, (card, index) => (
                                    <ButtonBank onClick={() => dispatch(setBankDebit({ ...bankDebit, ...card }))} className={`d-inline-block mr-2 ${bankDebit.cardTypeId === card.cardTypeId ? 'active' : ''}`}>
                                      <img src={`${fileUrl}/${card.cardTypeLogo}`} style={{ width: 30, height: 30 }} onError={onImgError} alt="" />
                                    </ButtonBank>
                                  ))}
                                </Box>
                              </Box>
                            </div>
                          }
                          treatments={props.treatments}
                          advance={props.advance}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )
        })}
      </Box>
    </Box>
  )
}
