import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart, TreatmentStateInterface } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/27.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethU, TextNumberU } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
  height: 'auto',
  margin: 'auto 5px 0',
  top: '1.4%',
  left: '45.2%'
}))

export default function Teeth27(props: TeethProps) {
  const teeth = useSelector(toothChart)
  const teethNumber = props.teethNumber
  const historyToothChart = props.historyToothChart
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const isEditTootCharts: any = useSelector(isEditTootChart)

  const dispatch = useDispatch()

  const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
  const [positions, setPositions]: any = useState([])
  const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

  const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
  const [isTooth, setIsTooth]: any = useState(false)
  const [isXRay, setIsXRay]: any = useState(false)
  const [isToothRoot, setIsToothRoot]: any = useState(false)
  const [isOrthodontics, setIsOrthodontics]: any = useState(false)
  const [isExtract, setIsExtract]: any = useState(false)
  const [isFixture, setIsFixture]: any = useState(false)
  const [isBridge, setIsBridge]: any = useState(false)

  const [color, setColor]: any = useState(``)
  const [styleSvg, setStyleSvg]: any = useState({ width: 38.262, height: 93.001, viewBox: "0 0 38.262 93.001" })

  const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

  useEffect(() => {
    const operative = props.operative || ``
    const valColor = props.color || colors.themeSecondColor
    const selected = teeth[teethNumber]?.isSelect || false
    dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
    setIsSelected(selected)

    renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

    if (props.type === 'treatment') {
      renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
    }

    if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

    return setStyleSvg({ width: 40.097, height: 88.972, viewBox: "0 0 40.097 88.972" })
  }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

  useEffect(() => {
    const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
    const teethJson = treatmentTooth?.teethJson

    if (isEditTootCharts !== null) {
      if (teethJson) {
        const tooths = JSON.parse(teethJson)
        setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
        if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
      } else setIsEditTeeth(false)
    }
  }, [isEditTootCharts]);

  useEffect(() => {
    if (isSelected) {
      dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
      if (teeth[props.teethNumber]?.isSelect) {
        setIsSelected(true)
      } else {
        setIsSelected(false)
      }
    }
  }, [props.historyToothChart]);

  useEffect(() => {
    if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
  }, [isSelected, isEditTootCharts]);

  return (
    <Div>
      <DivTeethU className={`div-teeth-${props.teethNumber} `}>
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray />) || ''}
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge />) || ''}
        {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`lg teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
          <svg className={`lg ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
            <g data-name="Group 9167">
              {(isBridge && props.type === 'treatment') ?
                <g></g>
                : ""}
              <path
                data-name="Path 5285"
                d="M119.941 105.29c-.761 5.8-4.144 10.466-5.453 15.838l.619 6.908.541 6.112a19.378 19.378 0 0 0 5.053 6.816c3.964 3.266 7.044-.363 10.725-1.743 4.357 5.265 7.431 2.419 9.842-2.262 8.6-.3 13.206-10.473 8.482-20.6-3.641-7.826-3.023-15.674-2.636-23.571.535-10.651-1.1-20.981-2.43-31.39a4.556 4.556 0 0 0-.5-1.4.313.313 0 0 0-.592.078c-1.218 4.593-4.338 32.034-8.994 37.878-3.183 4-3.581-12.956-2.707-40.986.023-.713-.365-.927-.882-.5-2.293 1.908-2.779 5-3.506 7.752-3.566 13.444-5.757 27.254-7.562 41.07z"
                transform="translate(-112.929 -56.244)"
                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
              />
              {(isLowerTeeth && props.type === 'treatment') ?
                <path
                  data-name="Path 6524"
                  d="M119.941 105.29a22.677 22.677 0 0 1-.886 3.851 18.272 18.272 0 0 1 5.817-3.851 12.462 12.462 0 0 1 6.556-.737 19.992 19.992 0 0 1 5.266 2.277 12.53 12.53 0 0 1 5.815 0 9.338 9.338 0 0 1 5.292 3.882c-1.423-5.766-.986-11.833-.688-17.929.535-10.651-1.1-20.981-2.43-31.39a4.556 4.556 0 0 0-.5-1.4.313.313 0 0 0-.592.078c-1.218 4.593-4.338 32.034-8.994 37.878-3.183 4-3.581-12.956-2.707-40.986.023-.713-.365-.927-.882-.5-2.293 1.908-2.779 5-3.506 7.752-3.565 13.449-5.756 27.259-7.561 41.075z"
                  transform="translate(-112.929 -56.244)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                /> : ''}
              <g data-name="Group 9728">
                {(isFixture && props.type === 'treatment') ? <>
                  <path data-name="Path 5286" d="m147.233 137.333 4.183 11.361s2.3 8.07 0 13.065-12.13 10.473-24.053 9.99a16.131 16.131 0 0 1-14.985-10.8s-.879-14.708 6.086-25.24c0 0 8.254-8.919 17.6-2.313 0 .004 7.352-2.12 11.169 3.937z" transform="translate(-112.343 -89.835)" style={{ fill: isTooth ? treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || color : '#efede7' }} />
                  <path data-name="Path 5287" d="m145.883 141.16 3.651 9.914s2.008 7.042 0 11.4-10.585 9.139-20.99 8.718a14.077 14.077 0 0 1-13.076-9.421s-.767-12.835 5.311-22.024c0 0 7.2-7.784 15.359-2.019 0 0 6.415-1.853 9.745 3.432z" transform="translate(-112.325 -91.484)" style={{ fill: isTooth ? treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || color : '#f7f7f7' }} />
                  <path data-name="Path 5288" d="m148.327 144.83 3.273 8.77s1.8 6.232 0 10.089-9.5 8.088-18.839 7.715a12.588 12.588 0 0 1-11.737-8.337s-.688-11.358 4.767-19.491c0 0 6.465-6.889 13.785-1.787.003.003 5.761-1.637 8.751 3.041z" transform="translate(-117.889 -93.016)" style={{ fill: isTooth ? treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || color : colors.white }} />
                </> : <>
                  <path
                    data-name="Path 5286"
                    d="m147.233 137.333 4.183 11.361s2.3 8.07 0 13.065-12.13 10.473-24.053 9.99a16.131 16.131 0 0 1-14.985-10.8s-.879-14.708 6.086-25.24c0 0 8.254-8.919 17.6-2.313 0 .004 7.352-2.12 11.169 3.937z"
                    transform="translate(-112.343 -82.806)"
                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                  />
                  <path
                    data-name="Path 5287"
                    d="m145.883 141.16 3.651 9.914s2.008 7.042 0 11.4-10.585 9.139-20.99 8.718a14.077 14.077 0 0 1-13.076-9.421s-.767-12.835 5.311-22.024c0 0 7.2-7.784 15.359-2.019 0 0 6.415-1.853 9.745 3.432z"
                    transform="translate(-112.325 -84.455)"
                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                  />
                  <path
                    data-name="Path 5288"
                    d="m148.327 144.83 3.273 8.77s1.8 6.232 0 10.089-9.5 8.088-18.839 7.715a12.588 12.588 0 0 1-11.737-8.337s-.688-11.358 4.767-19.491c0 0 6.465-6.889 13.785-1.787.003.003 5.761-1.637 8.751 3.041z"
                    transform="translate(-117.889 -85.987)"
                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                  />
                </>}

                {(isToothRoot && props.type === 'treatment') ?
                  <>
                    <path data-name="Path 6546" d="M8.82 57.821c1.806-12.426-.6-29.9 4.447-44.97-.452-1.357-4.439 18.736-2.462 44.533a18.842 18.842 0 0 0-1.985.437z" transform="translate(4.448 -9.636)" style={{

                      fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                      stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                    }} />
                    <path
                      data-name="Path 6547"
                      d="M8.047 48.974c.828-5.844.547-4.922 1.094-10.687.848-12.362 1.393-28.2 1.393-30.613.754-1.218-1.643 38.145-3.206 41.191.12.007.531.093.719.109z"
                      transform="translate(20.405 .934)"
                      style={{

                        fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                        stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                      }}
                    />
                  </>
                  : ''}

                {(isExtract && props.type === 'treatment') ?
                  <>
                    <g data-name="Group 9463">
                      <path data-name="Path 6580" d="m3.246-.5 36.254 80" transform="translate(-1.246 8.529)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                      <path data-name="Path 6581" d="m39.5-.5-36.254 80" transform="translate(-1.246 8.529)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                    </g>
                  </>
                  : ''}

                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                  <>
                    <defs>
                      <linearGradient id="q5lvtuxera" x1=".5" y1=".453" x2="-1.326" y2="4.622" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#b0b1b1" />
                        <stop offset={1} stopColor="#585959" />
                      </linearGradient>
                      <linearGradient id="rwhwa4kalb" x1=".5" y1="1.834" x2=".5" y2=".046" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#c1c4c5" />
                        <stop offset={1} stopColor="#9e9e9e" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9354">
                      <path data-name="Path 5285" d="M119.941 105.29c-.761 5.8-4.144 10.466-5.453 15.838l.619 6.908.541 6.112a19.378 19.378 0 0 0 5.053 6.816c3.964 3.266 7.044-.363 10.725-1.743 4.357 5.265 7.431 2.419 9.842-2.262 8.6-.3 13.206-10.473 8.482-20.6-3.641-7.826-3.023-15.674-2.636-23.571.535-10.651-1.1-20.981-2.43-31.39a4.556 4.556 0 0 0-.5-1.4.313.313 0 0 0-.592.078c-1.218 4.593-4.338 32.034-8.994 37.878-3.183 4-3.581-12.956-2.707-40.986.023-.713-.365-.927-.882-.5-2.293 1.908-2.779 5-3.506 7.752-3.566 13.444-5.757 27.254-7.562 41.07z" transform="translate(-112.364 -56.244)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                      <path data-name="Path 5286" d="m147.233 137.333 4.183 11.361s2.3 8.07 0 13.065-12.13 10.473-24.053 9.99a16.131 16.131 0 0 1-14.985-10.8s-.879-14.708 6.086-25.24c0 0 8.254-8.919 17.6-2.313 0 .004 7.352-2.12 11.169 3.937z" transform="translate(-111.778 -82.806)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5287" d="m145.883 141.16 3.651 9.914s2.008 7.042 0 11.4-10.585 9.139-20.99 8.718a14.077 14.077 0 0 1-13.076-9.421s-.767-12.835 5.311-22.024c0 0 7.2-7.784 15.359-2.019 0 0 6.415-1.853 9.745 3.432z" transform="translate(-111.76 -84.455)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5288" d="m148.327 144.83 3.273 8.77s1.8 6.232 0 10.089-9.5 8.088-18.839 7.715a12.588 12.588 0 0 1-11.737-8.337s-.688-11.358 4.767-19.491c0 0 6.465-6.889 13.785-1.787.003.003 5.761-1.637 8.751 3.041z" transform="translate(-117.324 -85.987)" style={{ fill: colors.white }} />
                      <g data-name="Group 9391" transform="translate(1.565 62.806)">
                        <g data-name="Rectangle 3927" transform="translate(13.517)" style={{ fill: '#c1c4c5', stroke: '#c1c4c5' }}>
                          <rect width="11.601" height="11.601" rx={2} style={{ stroke: 'none' }} />
                          <rect x=".5" y=".5" width="10.601" height="10.601" rx="1.5" style={{ fill: 'none' }} />
                        </g>
                        <path data-name="Path 6487" d="M11.579 17.547c-.2.362 1.676.183 3.083.165 2.094-.044 3.289.384 3.269-4.45a41.118 41.118 0 0 0-.159-5.141c-.531-.516.14 5.236-1.219 7.766-1.282 1.827-4.631 1.272-4.974 1.66z" transform="translate(6.863 -6.465)" style={{ fill: '#b0b1b1' }} />
                        <rect data-name="Rectangle 3931" width={5} height={2} rx=".5" transform="translate(16.806 6.215)" style={{ fill: '#cbcccc' }} />
                        <rect data-name="Rectangle 3930" width={5} height={2} rx=".5" transform="translate(16.606 2.015)" style={{ fill: '#9e9e9e' }} />
                        <rect data-name="Rectangle 3928" width={5} height={2} rx=".5" transform="translate(16.806 2.015)" style={{ fill: 'url(#q5lvtuxera)' }} />
                        <rect data-name="Rectangle 3929" width={5} height={2} rx=".5" transform="translate(16.806 6.015)" style={{ fill: 'url(#rwhwa4kalb)' }} />
                        <path data-name="Path 6488" d="M-.993 12.4s19.2-8.186 39.684-.395" transform="translate(.085 -3.765)" style={{ strokeLinecap: 'round', stroke: '#babbbb', fill: 'none' }} />
                        <path data-name="Path 6486" d="M-.807 12.509s19.265-8.286 39.745-.494" transform="translate(-.101 -3.084)" style={{ stroke: '#b5b7b7', strokeLinecap: 'round', fill: 'none' }} />
                      </g>
                    </g>
                  </> || ""}

                {(isFixture && props.type === 'treatment') ?
                  <>
                    <defs>
                      <linearGradient id="wphrs9joxa" x1=".209" y1="-.059" x2="1.176" y2="1.286" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b4b5b5" />
                      </linearGradient>
                      <linearGradient id="zwcfigdujb" x1="-.342" y1="-.081" x2="1.352" y2={1} gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b0b1b1" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9495">
                      <g data-name="Group 9552">
                        <g data-name="Path 6490" transform="translate(10.671)" style={{ fill: 'url(#wphrs9joxa)' }}>
                          <path d="M17.343 44.996H1.734c-.422 0-.825-.367-1.135-1.033-.322-.69-.499-1.61-.499-2.59C.845 24.493 1.818 12.58 2.991 5.97 3.682 2.075 4.427.1 5.203.1h8.671c.852 0 5.103 1.99 5.103 41.273 0 .98-.177 1.9-.499 2.59-.31.666-.713 1.033-1.135 1.033z" style={{ stroke: 'none' }} />
                          <path d="M17.343 44.896c.74 0 1.534-1.416 1.534-3.523 0-16.645-.753-28.547-2.238-35.377-.504-2.319-1.081-3.983-1.715-4.946-.486-.74-.867-.85-1.05-.85H5.203c-.406 0-1.22.752-2.114 5.788C1.917 12.593.945 24.498.2 41.378c.001 2.104.794 3.518 1.534 3.518h15.609m0 .2H1.734C.776 45.096 0 43.429 0 41.373 1.734 2.085 4.245 0 5.203 0h8.671c.958 0 5.203 2.085 5.203 41.373 0 2.056-.777 3.723-1.734 3.723z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <g data-name="Path 6489" transform="translate(2 42.63)" style={{ strokeLinejoin: 'round', fill: 'url(#zwcfigdujb)' }}>
                          <path d="M34.685 7.569H1.735c-.898 0-1.628-.807-1.635-1.8C.926 2.761 1.14 1.32 1.243.626 1.321.1 1.33.1 1.413.1c.027 0 .061.002.1.005.06.004.132.008.221.008h33.058l.083-.001c.355 0 .362 0 .626 1.491.152.859.381 2.155.818 4.163-.005.995-.736 1.803-1.634 1.803z" style={{ stroke: 'none' }} />
                          <path d="M34.685 7.469c.84 0 1.524-.758 1.534-1.693a98.161 98.161 0 0 1-.817-4.155c-.127-.72-.197-1.115-.28-1.3-.042-.095-.048-.11-.247-.11l-.19.002H1.735c-.093 0-.168-.005-.228-.008C1.473.202 1.443.2 1.42.2c-.023.073-.049.246-.078.442-.103.694-.317 2.136-1.142 5.14.013.932.696 1.687 1.534 1.687h32.951m0 .2H1.735C.775 7.669 0 6.812 0 5.755 1.734-.555.776.013 1.734.013h32.951c.958 0 .43-.24 1.734 5.742 0 1.057-.776 1.914-1.734 1.914z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <path data-name="Path 6491" d="M5.625 21.2s18.057.9 18.962 1.957" transform="translate(5.161 15.597)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6492" d="M5.649 21.2S23.1 22.161 24 23.213" transform="translate(5.447 10.345)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6493" d="M7.08 21.281s14.6.592 15.5 1.645" transform="translate(5.895 -11.973)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6584" d="M5.649 21.2S23.1 22.161 24 23.213" transform="translate(5.703 5.802)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6585" d="M6.115 21.2S23.1 22.185 24 23.237" transform="translate(5.703 -.222)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6586" d="M6.643 21.3s16.036.854 16.941 1.907" transform="translate(5.703 -6.281)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6587" d="M8.144 21.444s12.509.373 13.415 1.426" transform="translate(5.895 -17.717)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      </g>
                    </g>
                  </> : ""}
              </g>
            </g>
          </svg>}
      </DivTeethU>
      <PositionTeeth quarter={QUARTER_NAME_TYPE.Q2} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
      <TextNumberU>27</TextNumberU>
    </Div>
  )
}
