import { useState, useEffect, useCallback } from 'react'
import { Grid, Box, Typography, Button, Avatar, Divider, TextField, ClickAwayListener } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** UTILS */
import { memberTypeOutline, toBuddhistYear, renderAge, getClinicInfo } from 'utils/app.utils'
import { handleShow, resetModal } from 'app/slice/modal.slice'

/** ROUTE */
import { routeName } from 'routes/routes-name'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { icons, imgs } from 'constants/images'
import { KEY_STORAGE } from 'constants/common'

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'
import SpecialInfo from 'component/Register/SpecialInfo'
import ButtonCustom from 'component/Button/ButtonCustom'
import LabelRanking from 'component/LabelRanking'
import TreatmentHistory, { TreatmentHistoryProps } from 'component/Treatments/TreatmentHistory'
import InputCheckbox from 'component/Input/InputCheckbox'

/** STYLE */
import { styled } from '@mui/material/styles'
import InputTextField from 'component/Input/InputTextField'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RegisterApi from 'api/counter/register.api'
import ClinicApi from 'api/master/clinic.api'
import { notiSuccess } from 'component/notifications/notifications'
import ComponentApi from 'api/components-api'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { CustomTagMultiple } from 'features/counter/register/usedStyles'
// import { Box, Button } from '@mui/material'
interface TreatmentRightsInterface {
  treatmentRightName: string
  treatmentRightNameEn: string
}

interface Patient {
  patientId: number
  cnNumber: string
  branchCnNumber: string
  firstname: string
  lastname: string
  displayName: string
  rank: string
  updatedAt: string
  specialInfo: string
  idCard: string
  dateOfBirth: string
  bloodType: string
  medicineUse: string
  treatmentRights: any
  phone: string
  gender: string
  disease: string
  beAllergic: string
  snCode?: string
}

export interface IPopupPatientDetailProps {
  profile: string
  patient: Patient
  showPopup: boolean
  onClose: () => void
  type: 'treatment' | 'payment' | 'historyTreatment' | 'overdue' | 'lab-order' | 'retrospective' | 'xray'
  isNotShownPhone?: boolean
  reRenderPatient?: () => void

}

interface PatientInfoProps {
  patient: Patient,
  isNotShowPhone?: boolean
  type?: string
  reRenderPatient?: () => void
}

const PatientDetail = styled('div')(({ theme }) => ({
  '.box-special-info': {
    [theme.breakpoints.up('sm')]: {
      '.box-special-info-tab': {
        pointerEvents: 'none'
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    '.grid-profile': {
      width: '15%'
    },
    '.grid-info': {
      width: '85%'
    }
  },
  [theme.breakpoints.down('xs')]: {
    '.grid-profile': {
      width: '20%'
    },
    '.grid-info': {
      width: '80%'
    }
  }
}))

const ProfileAvatar = styled(Box)(({ theme }) => ({
  maxWidth: 150,
  maxHeight: 150,
  '.ratio-1': {
    paddingTop: '100%',
    '.MuiAvatar-root': {
      left: 0
    }
  }
}))

export const ButtonTab = styled(Button)(({ theme }) => ({
  width: '50%',
  height: 40,
  color: colors.textPrimary,
  padding: 0,
  borderBottom: `2px solid ${colors.white}`,
  borderRadius: 0,
  fontSize: 16,
  '&.active': {
    color: colors.themeSecondColor,
    borderColor: colors.themeSecondColor
  }
}))

const StyledButtonCustom = styled(ButtonCustom)(({ theme }) => ({
  height: 32,
  backgroundColor: colors.white,
  '&:hover': {
    backgroundColor: colors.themeSecondColor06,
  },
  [theme.breakpoints.down(375)]: {
    height: 'auto',
    minWidth: 'unset !important',
    fontSize: 12
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: 50,
    paddingLeft: 4,
    paddingRight: 4
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 16
  }
}))

const BoxClosePopup = styled(Box)(({ theme }) => ({
  backgroundColor: colors.themeSecondColor10
}))

export default function PopupPatientDetail(props: IPopupPatientDetailProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const tabs: string[] = ['TREATMENTS.TABS.INFO', 'TREATMENTS.TABS.HISTORY']
  const [activeTab, setActiveTab] = useState(0)
  const modal: boolean = useSelector(handleShow)
  const valueClosePopup = localStorage.getItem(KEY_STORAGE.TREATMENT_CLOSE_POPUP)
  const [idClosePopup, setIdClosePopup] = useState<number[]>([]);

  const branch = getClinicInfo()

  const [isEdit_Sp, setIsEdit_Sp] = useState<boolean>(false)
  const [specialInfo, setSpecialInfo] = useState<string>(props?.patient?.specialInfo || "")

  const handleChangeClosePopup = (e: any) => {
    const valueCheck = e.target.checked
    const val = valueClosePopup ? JSON.parse(valueClosePopup) : []
    if (valueCheck) {
      const newValueClosePopup = [...val, props.patient.patientId]
      setIdClosePopup(newValueClosePopup)
      localStorage.setItem(KEY_STORAGE.TREATMENT_CLOSE_POPUP, JSON.stringify(newValueClosePopup))
    } else {
      const newValueClosePopup = _.filter(val, (v) => Number(v) !== Number(props.patient.patientId))
      setIdClosePopup(newValueClosePopup)
      localStorage.setItem(KEY_STORAGE.TREATMENT_CLOSE_POPUP, JSON.stringify(newValueClosePopup))
    }
  }

  useEffect(() => {
    if (modal) {
      const val = valueClosePopup ? JSON.parse(valueClosePopup) : []
      setIdClosePopup(val)
    } else dispatch(resetModal())
  }, [modal]);

  const handlePatientCode = () => {
    if (branch?.patientCodeQueue === '1') {
      return props.patient.cnNumber ?? '-'
    }
    if (branch?.patientCodeQueue === '0') {
      return props.patient.branchCnNumber ?? '-'
    }

    if (branch?.patientCodeQueue === '2') {
      return props.patient.snCode ?? ''
    }
  }

  const handleEditSpecialInfo = async (status: boolean) => {
    if (!status) {
      await ClinicApi.updatePatientInfo({ specialInfo: specialInfo }, props?.patient?.patientId)
      if (props?.reRenderPatient) props.reRenderPatient()
    }
    setIsEdit_Sp(status)
  }

  useEffect(() => {
    setSpecialInfo(props?.patient?.specialInfo || '')
  }, [props?.patient?.specialInfo])

  return props.showPopup ? (
    <ModalCustom
      size={'xl'}
      title={t('TREATMENTS.POPUP_PATIENT_INFO.TITLE')}
      alignFooter={'end'}
      fullscreen={'xxl-down'}
      closeButton
      onClose={props.onClose}
      modalStyle={`modal-treatment ${(props.type === 'treatment' || props.type === 'payment') && 'modal-treatment-info'}`}
      component={
        <PatientDetail className="overflow-hidden d-flex flex-column h-100">
          <Grid container spacing={2} className="px-3 px-lg-4">
            <Grid item sm={2} className="grid-profile">
              <ProfileAvatar>
                <Box className="ratio-1 position-relative">
                  <Avatar src={props.profile} alt="profile" className="position-absolute top-0 w-100 h-100" sx={{ border: `3px solid ${memberTypeOutline(props.patient.rank)}`, borderWidth: { lg: '5px' }, backgroundColor: colors.white }}>
                    <img src={imgs.defaultAvatar} className="w-100 h-100" alt="defaultAvatar" />
                  </Avatar>
                </Box>
              </ProfileAvatar>
            </Grid>
            <Grid item sm={10} className="grid-info pl-xl-0">
              <Box>
                <Box className="d-md-flex align-items-end align-items-lg-center justify-content-between px-xl-3">
                  <Box className="d-lg-flex">
                    <Typography className="mr-lg-2" sx={{ fontWeight: 600, color: colors.themeMainColor, fontSize: { lg: '18px' } }}>
                      {handlePatientCode() ? `${handlePatientCode()} |` : ''} {props.patient.firstname} {props.patient.lastname}{props.patient.displayName ? `( ${props.patient.displayName} )` : ''}
                    </Typography>
                    <LabelRanking rank={props.patient.rank} />
                  </Box>
                  <Typography className="mt-2 mt-lg-0" sx={{ color: colors.themeMainColor }}>
                    {t('TREATMENTS.TITLE.LAST_VISIT')} {props.patient.updatedAt ? toBuddhistYear(moment(props.patient.updatedAt), 'DD/MM/YYYY') : '-'}
                  </Typography>
                </Box>
                <Divider className="my-2 mx-0 d-none d-md-block" sx={{ backgroundColor: colors.transparent, opacity: 1, borderColor: `${colors.lightGray} !important` }} />
              </Box>
              <Box className="d-none d-md-block">
                <PatientInfo
                  type={props.type}
                  patient={props.patient}
                  isNotShowPhone={props.isNotShownPhone}
                  reRenderPatient={props.reRenderPatient}
                />
              </Box>
            </Grid>
            <Grid item xs={12} className="w-100 pt-2 d-md-none">
              <Divider className="m-0" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
            </Grid>
            <Grid item xs={12} className="w-100" sx={{ position: 'relative' }}>
              {(props.type === "treatment") &&
                <Box sx={{ position: 'absolute', right: '0', zIndex: '1', top: '10px' }}>
                  <ButtonCustom
                    onClick={(e) => {
                      e.stopPropagation()
                      handleEditSpecialInfo(!isEdit_Sp)

                    }}
                    textButton={isEdit_Sp ? t('APPOINTMENT.SAVE_SPECIALINFO') : t('APPOINTMENT.UPDATE_SPECIALINFO')}
                    className="h-100"
                    style={{ margin: 0, height: 35 }}
                  />
                </Box>
              }
              <SpecialInfo
                expanded
                key={'specialInfo'}
                name="specialInfo"
                rows={3}
                value={specialInfo}
                onChange={(event) => {
                  console.log("222 event", event.target.value)
                  setSpecialInfo(event.target.value)
                }}
                placeholder={t('REGISTER.TITLE.FIELD_SPECIAL_INFO')}
                // readOnly={!isEdit_Sp}
                disabled={!isEdit_Sp}
                expandedIconClasses="d-md-none"
              />
            </Grid>
            <Grid item xs={12} className="w-100 d-md-none">
              <Box>
                {_.map(tabs, (tab: string, index: number) => {
                  return (
                    <ButtonTab className={`${index === activeTab ? 'active' : ''}`} onClick={() => setActiveTab(index)}>
                      {t(tab)}
                    </ButtonTab>
                  )
                })}
              </Box>
            </Grid>
          </Grid>
          <Box className={`w-100 ${activeTab === 0 ? 'd-flex flex-column' : 'd-none'} d-md-none overflow-hidden px-3 px-lg-4`}>
            <Box className="mt-3 overflow-hidden">
              <PatientInfo
                patient={props.patient}
                isNotShowPhone={props.isNotShownPhone}
                reRenderPatient={props.reRenderPatient}
              />
            </Box>
          </Box>
          {props.type !== 'historyTreatment' && (
            <Box className={`w-100 ${activeTab === 1 ? 'd-flex flex-column' : 'd-none'} d-md-flex flex-md-column overflow-hidden px-3 px-lg-4`}>
              <Box className="mt-3 pt-1 d-flex flex-column overflow-hidden">
                <HistoryTreatment patientId={props.patient.patientId} />
              </Box>
            </Box>
          )}
          {(props.type === 'treatment' || props.type === 'payment') && (
            <Box className='mt-auto'>
              <BoxClosePopup className={`w-100 mt-4 py-2 px-3 px-lg-4`}>
                <InputCheckbox
                  checked={_.includes(idClosePopup, props.patient.patientId)}
                  onChange={handleChangeClosePopup}
                  label={
                    <>
                      <span>{t('TREATMENTS.TITLE_CLOSE_POPUP_INFO')}</span>
                      <span className='pl-2 pl-sm-4 d-none d-sm-inline-block'>{t('TREATMENTS.SUBTITLE_CLOSE_POPUP_INFO')}</span>
                    </>
                  } />
              </BoxClosePopup>
            </Box>
          )}
        </PatientDetail>
      }
    />
  ) : (
    <></>
  )
}
const BoxInfo = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxHeight: 100
  }
}))

const FlexBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItemsCenter: 'center',
  paddingBottom: 2,
  p: {
    '&:first-of-type': {
      paddingRight: 8,
      width: 140,
      [theme.breakpoints.up('xs')]: {
        width: 210,
      },
      [theme.breakpoints.up('lg')]: {
        width: 190
      }
    },
    '&:last-of-type': {
      flex: 1
    }
  }
}))

const Typo = styled(Typography)(({ theme }) => ({
  color: colors.textPrimary
}))

function PatientInfo(props: PatientInfoProps) {
  const { t } = useTranslation()

  const [disease, setDisease] = useState<string>('')
  const [diseaseOp, setDiseaseOp] = useState<any>([])
  const [beAllergic, setBeAllergic] = useState<string>('')
  const [beAllergicOp, setBeAllergicOp] = useState<any>([])
  const [editDeAllergic, setEditBeAllergic] = useState(false)
  const [editDisease, setEditDisease] = useState(false)

  const [medicineUseOp, setMedicineUseOp] = useState<any>([])
  const [medicineUse, setMedicineUse] = useState<any>(props?.patient?.medicineUse || "")
  const [editMedicineUse, setEditMedicineUse] = useState<boolean>(false)
  const [isMedicineUse, setIsMedicineUse] = useState<boolean>(false)

  const loadMedicineUse = useCallback(() => {
    ComponentApi.findAllMedicineUse().then((resMedicineUse) => {
      if (resMedicineUse.status === 200 && !_.isEmpty(resMedicineUse.data)) {
        setMedicineUseOp(resMedicineUse.data)
      }
    })
  }, [])

  const loadDisease = useCallback(() => {
    ComponentApi.findAllDisease().then((resDisease) => {
      if (resDisease.status === 200 && !_.isEmpty(resDisease.data)) {
        setDiseaseOp(resDisease.data)
      }
    })
  }, [])

  const loadBeAllergic = useCallback(() => {
    ComponentApi.findAllBeAllergic().then((resBeAllergic) => {
      if (resBeAllergic.status === 200 && !_.isEmpty(resBeAllergic.data)) {
        setBeAllergicOp(resBeAllergic.data)
      }
    })
  }, [])

  useEffect(() => {
    loadMedicineUse()
    loadDisease()
    loadBeAllergic()
  }, [])

  useEffect(() => {
    setDisease(String(props.patient.disease).replaceAll(',', ', '))
    setBeAllergic(String(props.patient.beAllergic).replaceAll(',', ', '))
    setMedicineUse(props?.patient?.medicineUse || "")
  }, [props.patient])

  const handleSetAge = (date: Date | null | string) => {
    return `${renderAge(date, 'year')} ${t('TIME_DURATION_TYPE.YEAR')} ${renderAge(date, 'month')} ${t('TIME_DURATION_TYPE.MONTH')} ${renderAge(date, 'day')} ${t('TIME_DURATION_TYPE.DAY')}`
  }

  const handleChangeDesease = (e: any) => {
    setDisease(e.target.value)
  }

  const handleChangeBeAllergic = (e: any) => {
    setBeAllergic(e.target.value)
  }

  const updateInfomation = async () => {
    const objPatient: any = { ..._.omit(props.patient, ['patientId', 'profile', 'signature', 'treatmentRights', 'importantSymptomsDoctor', 'lineToken', 'premissionApp', 'regisApp', 'lastToken', 'updatedAppBy', 'updatedAppAt', 'status', 'updatedBy', 'createdBy', 'createdAt', 'updatedAt', 'patientCase', 'prefix']), disease, beAllergic, medicineUse }
    await RegisterApi.update(props.patient.patientId, objPatient)
    if (props?.reRenderPatient) props.reRenderPatient()

  }

  const onInputChange = (e: any, val: any) => {
    const arr: any = _.filter(val, (newV: any, indexNewVal: number) => {
      return val.indexOf(newV) === indexNewVal
    })
    return arr.toString()
  }

  return (
    <BoxInfo className="custom-scroll px-3 px-md-0" sx={{ maxHeight: '100%' }}>
      <Grid container spacing={1} className="pt-2 px-xl-3">
        <Grid item md={6} className="w-100 pt-0">
          <FlexBox>
            <Typo>{t('TREATMENTS.FORM.ID_CARD')}</Typo>
            <Typo>{props.patient.idCard || t('REGISTER.BUTTON.NOT_SPECIFIED')}</Typo>
          </FlexBox>
          <FlexBox>
            <Typo>{t('TREATMENTS.POPUP_PATIENT_INFO.DATE_OF_BIRTH')}</Typo>
            <Typo>{props.patient.dateOfBirth ? toBuddhistYear(moment(props.patient.dateOfBirth), 'DD/MM/YYYY') : t('REGISTER.BUTTON.NOT_SPECIFIED')}</Typo>
          </FlexBox>
          <FlexBox>
            <Typo>{t('REGISTER.FORM.AGE')}</Typo>
            <Typo>{props.patient.dateOfBirth ? handleSetAge(props.patient.dateOfBirth) : t('REGISTER.BUTTON.NOT_SPECIFIED')}</Typo>
          </FlexBox>
          <FlexBox>
            <Typo>{t('REGISTER.FORM.BLOOD_TYPE')}</Typo>
            <Typo>{props.patient.bloodType === 'NOT_SPECIFIED' ? t('REGISTER.BUTTON.NOT_SPECIFIED') : props.patient.bloodType || '-'}</Typo>
          </FlexBox>
          {props.type !== 'treatment' ? <FlexBox>
            <Typo>{t('REGISTER.FORM.GENDER')}</Typo>
            <Typo>{t(`GENDER_OPTION.${props.patient.gender}`) || '-'}</Typo>
          </FlexBox> : <FlexBox>
            <Typo sx={{ width: '150px' }}>{t('REGISTER.FORM.MEDICINE_USE')}</Typo>
            <SelectCustom
              option={medicineUseOp}
              labelId={"medicine-use"}
              onInputChange={onInputChange}
              setData={setMedicineUse}
              value={medicineUse}
              disabled={editMedicineUse}
            />
            <Button
              onClick={() => {
                setEditMedicineUse(!editMedicineUse)
                if (editMedicineUse) {
                  updateInfomation()
                }
              }}
              className='p-0 m-0'>
              {!editMedicineUse ?
                <img src={icons.iconFeatherEditBlack} alt="" style={{ width: 18, fill: 'black' }} />
                : <AddCircleOutlineIcon style={{ color: '#1CB99A' }} />
              }
            </Button>
          </FlexBox>
          }
        </Grid>
        <Grid item md={6} className="w-100 pt-0">
          {!props.isNotShowPhone && < FlexBox >
            <Typo>{t('REGISTER.FORM.PHONE')}</Typo>
            <Typo>{props.patient.phone || '-'}</Typo>
          </FlexBox>}
          {props.type !== 'treatment' ? <FlexBox>
            <Typo>{t('REGISTER.FORM.MEDICINE_USE')}</Typo>
            <Typo>{String(props.patient.medicineUse).replaceAll(',', ', ') || '-'}</Typo>
          </FlexBox> : <FlexBox>
            <Typo>{t('REGISTER.FORM.GENDER')}</Typo>
            <Typo>{t(`GENDER_OPTION.${props.patient.gender}`) || '-'}</Typo>
          </FlexBox>
          }
          <FlexBox>
            <Typo>{t('REGISTER.FORM.DISEASE')}</Typo>
            {props.type === 'treatment' ? <>
              <SelectCustom
                option={diseaseOp}
                labelId={"disease"}
                onInputChange={onInputChange}
                setData={setDisease}
                value={disease}
                disabled={editDisease}
              />
              <Button onClick={() => {
                setEditDisease(!editDisease)
                if (editDisease) {
                  updateInfomation()
                }
              }} className='p-0 m-0'> {!editDisease ? <img src={icons.iconFeatherEditBlack} alt="" style={{ width: 18, fill: 'black' }} /> : <AddCircleOutlineIcon style={{ color: '#1CB99A' }} />}</Button>

            </>
              : <>{String(props.patient.disease) ? <Typo style={{ color: colors.themeThirdColor }}>{String(props.patient.disease).replaceAll(',', ', ')}</Typo> : <Typo>-</Typo>}</>}
          </FlexBox>
          <FlexBox>
            <Typo>{t('TREATMENTS.FORM.BE_ALLERGIC')}</Typo>
            {props.type === 'treatment' ? <>
              <SelectCustom
                option={beAllergicOp}
                labelId={"be-allergic"}
                onInputChange={onInputChange}
                setData={setBeAllergic}
                value={beAllergic}
                disabled={editDeAllergic}
              />
              <Button onClick={() => {
                setEditBeAllergic(!editDeAllergic)
                if (editDeAllergic) {
                  updateInfomation()
                }
              }} className='p-0 m-0'>{!editDeAllergic ? <img src={icons.iconFeatherEditBlack} alt="" style={{ width: 18 }} /> : <AddCircleOutlineIcon style={{ color: '#1CB99A' }} />}</Button></>
              :
              <>{String(props.patient.beAllergic) ? <Typo style={{ color: colors.themeThirdColor }}>{String(props.patient.beAllergic).replaceAll(',', ', ')}</Typo> : <Typo>-</Typo>}</>}


          </FlexBox>
          <FlexBox>
            <Typo>{t('REGISTER.TITLE.RIGHT_TO_TREATMENT')}</Typo>
            <Typo>
              {!_.isEmpty(props.patient.treatmentRights)
                ? _.map(props.patient.treatmentRights, (treatment: TreatmentRightsInterface, index: number) => {
                  return (
                    <Typo className="d-inline">
                      {treatment.treatmentRightName || treatment.treatmentRightNameEn}
                      {index + 1 !== _.size(props.patient.treatmentRights) ? ',' : ''}
                    </Typo>
                  )
                })
                : '-'}
            </Typo>
          </FlexBox>
        </Grid>
      </Grid>
    </BoxInfo >
  )
}

function HistoryTreatment(props: TreatmentHistoryProps) {
  const { t } = useTranslation()

  return (
    <Box className="overflow-hidden d-flex flex-column">
      <Box className="d-flex align-items-center justify-content-between overflow-hidden mb-2" sx={{ flex: 'none' }}>
        <Typography sx={{ fontSize: { md: '18px' }, fontWeight: 600, color: colors.themeMainColor }}>{t('TREATMENTS.POPUP_PATIENT_INFO.HISTORY.TITLE')}</Typography>
        <Box className="d-flex align-items-center">
          <StyledButtonCustom variant={'outlined'} textButton={t('TREATMENTS.BUTTON.APPOINTMENT_HISTORY')} className="py-0 mr-1 mr-md-2" onClick={() => window.open(`${routeName.treatmentsAppointmentHistory}?patientId=${props.patientId}`, '_blank')} />
          <StyledButtonCustom variant={'outlined'} textButton={t('TREATMENTS.BUTTON.EMR')} className="py-0" onClick={() => window.open(`${routeName.treatmentsEmrHistory}?patientId=${props.patientId}`, '_blank')} />
        </Box>
      </Box>
      <TreatmentHistory patientId={props.patientId} />
    </Box>
  )
}

type SelectCustomProps = {
  option: any
  labelId: string
  onInputChange: (e: any, val: any) => void
  setData: (data: any) => void
  value: any
  disabled: boolean
}

const SelectCustom = (props: SelectCustomProps) => {
  const { t } = useTranslation()
  return (
    <AutocompleteSelect
      multiple
      labelId={props.labelId}
      noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
      options={props.option}
      getOptionLabel={(option: any) => option}
      renderOption={(props, option: any) => {
        return (
          <Box key={option} component="li" {...props} >
            {option}
          </Box>
        )
      }}
      renderTags={(tagValue, getTagProps) => _.map(tagValue, (option: any, index: number) => <CustomTagMultiple type={props.labelId} size="small" label={option} {...getTagProps({ index })} />)}
      onchange={(e, val) => {
        const newInput = props.onInputChange(e, val)
        props.setData(newInput)
      }}
      onInputChange={(e, value: any) => {
        const text = value.split(/[,]+/)
        if (text.length > 1) {
          const val = (_.isEmpty(props.value) && []) || (typeof props.value === 'string' && props.value.split(',')) || props.value
          const item = [...val, text[0]]
          const newInput = props.onInputChange(e, item)
          props.setData(newInput)
        }
      }}
      value={(_.isEmpty(props.value) && []) || (typeof props.value === 'string' && props.value.split(',')) || props.value}
      key={props.labelId}
      disabled={!props.disabled}
      popupIcon={<span></span>}
      height={30}
      params={{
        className: 'w-50',
        sx: {
          '.MuiInputBase-root': {
            padding: '8px 30px 8px 8px !important',
            height: '100%',
          },
        }
      }}
      ariaSelectedSx={{
        backgroundColor: `${colors.themeSecondColor06} !important`,
        color: colors.themeSecondColor
      }}
      freeSolo
      disableClearable
    />
  )
}
