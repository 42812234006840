import { colors } from "constants/theme"

export default function DropdownEditActive() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19.927" height="18.6" viewBox="0 0 19.927 18.6">
            <g data-name="Component 117 – 132">
                <g data-name="Group 10713">
                    <path d="m0 11.475 8.908-8.908 3.02 3.02-8.908 8.908H0zm14.269-8.229L12.8 4.718 9.777 1.7 11.249.227a.82.82 0 0 1 1.132 0l1.888 1.888a.768.768 0 0 1 .227.566.768.768 0 0 1-.227.566z" transform="translate(5.432)" style={{ fill: colors.themeSecondColor }} />
                    <path data-name="Path 6446" d="M-5.426 13H-16v15.182H.105v-8.74" transform="translate(16.6 -10.182)" style={{ fill: 'none', stroke: colors.themeSecondColor, strokeLinejoin: 'round', strokeWidth: '1.2px' }} />
                </g>
            </g>
        </svg>
    )
}
