import BaseAPI from 'api/api'

const treatmentHistory = 'treatment-history'

export interface AllTreatmentHistory {
  patientId: number
  page: number
  pageLimit: number
  search?: string
  dateStart?: string
  dateEnd?: string
  doctorId?: number
  branchId?: number
  patientCaseId?: string
}

export interface PatientCaseType {
  treatmentFollowUpId?: number
  patientCaseId?: number
  orderTreatmentId?: number
  followUpDate1?: string
  followUpStatus1?: string
  followUpRemark1?: string
  followUpDate2?: string
  followUpStatus2?: string
  followUpRemark2?: string
  followUpDate3?: string
  followUpStatus3?: string
  followUpRemark3?: string
  followUpDate4?: string
  followUpStatus4?: string
  followUpRemark4?: string
  remark?: string
}

export default class HistoryTreatmentApi extends BaseAPI {
  static findAllTreatmentHistory(patientId: number, params: AllTreatmentHistory): Promise<any> {
    return this.api.get(`${treatmentHistory}/${patientId}`, { params: { ...params } })
  }

  static findFollowPatientCase(patientId: number, patientCaseId: number): Promise<any> {
    return this.api.get(`${treatmentHistory}/patient-treatment/${patientId}/${patientCaseId}`)
  }

  static treatmentFollowUp(patientId: number, payload: PatientCaseType): Promise<any> {
    return this.api.post(`${treatmentHistory}/${patientId}/treatment-follow-up`, payload)
  }

  static findOverview(patientId: number): Promise<any> {
    return this.api.get(`${treatmentHistory}/${patientId}/overview`)
  }
}
