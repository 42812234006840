
const Flip = (props: any) => {
    return (
        <>
            <svg
                width={props?.width || "24"}
                height={props?.height || "29"}
                viewBox="0 0 24 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.2217 8C12.2217 7.44772 12.6694 7 13.2217 7H16.9409C17.3998 7 17.7998 7.3123 17.911 7.75746L22.911 27.7575C23.0688 28.3886 22.5915 29 21.9409 29H13.2217C12.6694 29 12.2217 28.5523 12.2217 28V8Z"
                    fill="white"
                />
                <path
                    d="M10.5 8C10.5 7.72386 10.2761 7.5 10 7.5H6.28078C6.05134 7.5 5.85135 7.65615 5.79571 7.87873L0.795706 27.8787C0.716812 28.1943 0.955493 28.5 1.28078 28.5H10C10.2761 28.5 10.5 28.2761 10.5 28V8Z"
                    stroke="white"
                />
                <path
                    d="M22.25 2.06699C22.5833 2.25944 22.5833 2.74056 22.25 2.93301L20 4.23205C19.6667 4.4245 19.25 4.18394 19.25 3.79904L19.25 1.20096C19.25 0.816062 19.6667 0.575499 20 0.767949L22.25 2.06699Z"
                    fill="white"
                />
                <path
                    d="M0.75 2.93301C0.416667 2.74056 0.416666 2.25944 0.75 2.06699L3 0.767949C3.33333 0.575499 3.75 0.816061 3.75 1.20096L3.75 3.79904C3.75 4.18394 3.33333 4.4245 3 4.23205L0.75 2.93301Z"
                    fill="white"
                />
                <line x1="3" y1="2.5" x2="20" y2="2.5" stroke="white" />
            </svg>
        </>
    )
}

export default Flip