import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

const promotion = '/promotions'

export interface ObjImage {
  fileId?: number | null
  base64: string
  type: string
  fileExtension: string
  fileSize: string
}

export interface PromotionType {
  subject: string
  content: string
  datePublic: string
  timePublic: string
  dateEnd: string
  timeEnd: string
  status?: "ACTIVE" | "INACTIVE"
  image: ObjImage
}

export default class PromotionApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get(`${promotion}`, { params: { ...props } }).then((res) => res)
  }

  static findById(promotionId: number): Promise<any> {
    return this.api.get(`${promotion}/${promotionId}`).then((res) => res)
  }

  static createPromotion(payload: PromotionType): Promise<any> {
    return this.api.post(`${promotion}`, payload).then((res) => res)
  }

  static updatePromotion(promotionId: number, payload: PromotionType): Promise<any> {
    return this.api.patch(`${promotion}/${promotionId}`, payload).then((res) => res)
  }

  static updatePromotionStatus(promotionId: number, status: string): Promise<any> {
    return this.api.patch(`${promotion}/${promotionId}/status`, { status: status }).then((res) => res)
  }

  static removePromotion(promotionId: number): Promise<any> {
    return this.api.delete(`${promotion}/${promotionId}`).then((res) => res)
  }
}