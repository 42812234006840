import { useState } from 'react'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'


/** COMPONENT */
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { numberFormat } from 'utils/app.utils'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import { useDispatch } from 'react-redux'

type ViewMorProps = {
  type: 'CLINIC_FEE' | 'DIAGNOSES'
  data: any[]
  onReset?: () => void

}

export default function ViweMore(props: ViewMorProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { type, data } = props

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(5)
  const [rowCount, setRowCount] = useState(data.length)
  const [sortBy, setSortBy] = useState('date')
  const [sortType, setSortType] = useState('ASC')

  const headCellClinicFees = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'clinicFeeName', disablePadding: false, label: t('CLINIC_FEE.TABLE.CELL.CLINIC_FEE_NAME') },
    { id: 'clinicFeeNameEn', disablePadding: false, label: t('CLINIC_FEE.TABLE.CELL.CLINIC_FEE_NAME_EN') },
    { id: 'price', disablePadding: false, label: `${t('CLINIC_FEE.TABLE.CELL.CLINIC_FEE_PRICE')} (${t('PAYMENT_DETAIL.BATH')})` },
  ]

  const headCellDiagnosis = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'diagnosisCode', disablePadding: false, label: t('DIAGNOSIS.TABLE.CELL.DIAGNOSIS_CODE') },
    { id: 'diagnosisName', disablePadding: false, label: t('DIAGNOSIS.TABLE.CELL.DIAGNOSIS_NAME') },
  ]

  const renderDataClinicFee = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { clinicFeeId: cfId, clinicFeeName: cfName, clinicFeeNameEn: cfNameEn, price: cfPrice } = objData

    const objRenderData = {
      key: cfId,
      id: cfId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: cfName },
        { option: 'TEXT', align: 'left', label: cfNameEn },
        { option: 'TEXT', align: 'right', label: numberFormat(cfPrice) },
      ]
    }
    return (
      <TableRowCommon {...objRenderData} />
    )
  }

  const renderDataDiagnosis = (objData: any, no: number) => {
    const num = page * pageLimit - pageLimit + no + 1
    const { diagnosisId, diagnosisName, diagnosisCode } = objData
    const objRenderData = {
      key: diagnosisId,
      id: diagnosisId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: num },
        { option: 'TEXT', align: 'left', label: diagnosisCode },
        { option: 'TEXT', align: 'left', label: diagnosisName },
      ]
    }
    return <TableRowCommon {...objRenderData} />
  }

  return <ModalCustom
    size={'xl'}
    title={type === 'CLINIC_FEE' ? t('OPERATIVE.TITLE_CLINIC_FEES') : t('OPERATIVE.TITLE_DIAGNOSIS')}
    alignFooter={'end'}
    footerInline
    onReset={props.onReset}
    textBtnCancel={t('BUTTON.CANCEL')}
    modalStyle={'modal-popup-select-medicine'}
    component={
      <>
        <TableCustom
          page={page}
          pageLimit={pageLimit}
          sortType={sortType}
          sortBy={sortBy}
          rowCount={rowCount}
          onSort={(sortByVal: string, sortTypeVal: string) => {
            setSortType(sortTypeVal)
            setSortBy(sortByVal)
          }}
          setPageLimit={setPageLimit}
          setPage={setPage}
          headCells={type === 'CLINIC_FEE' ? headCellClinicFees : headCellDiagnosis}
          rowsData={_.map(_.filter(data, (newV: any, indexNewVal: number) => indexNewVal >= ((page - 1) * pageLimit) && indexNewVal < (page * pageLimit)), type === 'CLINIC_FEE' ? renderDataClinicFee : renderDataDiagnosis)}
        />
      </>
    }
  />
}
