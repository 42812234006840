import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Box, Divider, TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { ReportsProps, ExportProps } from "features/report/report-constants";
import { numberFormat, toBuddhistYear } from 'utils/app.utils'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import EmptyData from 'features/report/component/emptyData'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { colors } from 'constants/theme'

import LabReportListTotal from './LabReportListTotal';

import { UseSnCode } from 'utils/useSnCode'
export const dfLabConfig: ReportsProps = {
    key: 'DF_LAB',
    textName: 'DF_LAB',
    portrait: false,
    renderDataCustom: false,
    filter: {
        branch: true,
        date: true,
        rangeDate: false,
        prefix: false,
        receiptStatus: false,
        operativeType: false,
        billStatus: false,
        operativeCode: false,
        doctor: true,
        patient: false,
    },
    export: {
        pdf: true,
        excel: true
    }
}

export interface FcComponent {
    date: any
    page: number
    pageLimit: number
    rowCount: number
    sortBy: string
    sortType: string
    data: any
    onRequestSort: (sortBy: string, sortType: string) => void
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
    componentRef: any
    filter: any
}

export default function LabReportList(props: FcComponent) {
    const { t } = useTranslation()

    const [snType, setSnType] = useState(false);

    const handleSnCode = useCallback(() => {
        const fetchData = async () => {
            const sn = await UseSnCode();
            setSnType(sn);
        };

        fetchData();

    }, [snType]);

    useEffect(() => {
        handleSnCode()
    }, [])
    const headDfCells = [
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.LAB.#NO', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.LAB.DENTIST', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.LAB.PATIENI_CODE', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.LAB.SN_CODE', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.LAB.PATIENT_NAME', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.LAB.LAB_COMPANY', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.LAB.LAB_GROUP', align: 'right', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.LAB.LAB_NAME', align: 'right', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.LAB.REMARK', align: 'right', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.LAB.LAB_TX', align: 'right', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.LAB.LAB_FEE', align: 'right', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.LAB.DF_PERCENT', align: 'right', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.LAB.DF_LAB_FEE', align: 'right', class: 'text-nowrap' },

    ]

    if (!snType) {
        headDfCells.splice(3, 1)
    }
    const renderDataDF = (data: any, no: number, exportData: boolean, render: 'data' | 'sum') => {
        if (render === 'data') {
            const { branchCnNumber, snCode, dateOrder, doctorName, labClinicPercent, labClinicTotal, labCompanyName, labDoctorPercent, labDoctorTotal, labGroupName, labName, labOrderId, labOrderPrice, labRemark, operativeName, patientName } = data
            const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
            const objRenderData = {
                key: num.toString(),
                id: no.toString(),
                obj: data,
                columns: [
                    { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: num, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: doctorName || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: branchCnNumber || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: snCode || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: patientName || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: labCompanyName || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: labGroupName || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: labName || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: labRemark || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: operativeName, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(labOrderPrice || 0), class: 'text-nowrap' },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: `${Number(labDoctorPercent || 0)}%`, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(labDoctorTotal || 0), class: 'text-nowrap' },

                ]
            }
            if (!snType) {
                objRenderData.columns.splice(3, 1)
            }
            return (
                <>
                    {exportData === true && <TableRowExport {...objRenderData} /> ||
                        <TableRowCommon  {...objRenderData} />
                    }
                </>
            )
        } else {
            const objRenderData = {
                key: no.toString(),
                id: no.toString(),
                obj: data,
                columns: [
                    { option: 'MERGE', align: 'center', colSpan: snType ? 9 : 8 },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: <Typography sx={{ fontWeight: 500, fontSize: exportData ? 10 : 16 }}>{t('REPORT.TITLE.SUM')}</Typography>, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'labOrderPrice')), class: 'text-nowrap' },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: '', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'labDoctorTotal')), class: 'text-nowrap' },

                ],
                classes: 'row-summary'
            }

            return (
                <>
                    {exportData === true && <TableRowExport {...objRenderData} /> ||
                        <TableRowCommon  {...objRenderData} />
                    }
                </>
            )
        }
    }


    const sumTotal = (handInsurance: number, df: number, lab: number) => {
        if (df > handInsurance) {
            return numberFormat(df - lab)
        } else {
            return numberFormat(handInsurance - lab)
        }
    }

    return (
        <>
            {
                (!_.isEmpty(props.data)) && (
                    (
                        <Box>
                            <UseStyled.ResponsiveReportTable className='mt-2' verticalAlign='top'>
                                <TableCustom
                                    customScroll
                                    hidePagination
                                    page={props.page}
                                    pageLimit={props.pageLimit}
                                    sortType={props.sortType}
                                    sortBy={props.sortBy}
                                    rowCount={props.rowCount}
                                    onSort={props.onRequestSort}
                                    setPageLimit={props.handleChangeRowsPerPage}
                                    setPage={props.handleChangePage}
                                    headCells={headDfCells}
                                    rowsData={
                                        _.map(_.groupBy(props.data, 'dateOrder'), (data: any, index: any) => {
                                            return !_.isEmpty(data) ? [
                                                <TableRow key={`date-${index}`}>
                                                    <TableCell colSpan={_.size(headDfCells)} sx={{ fontWeight: '500 !important' }}>
                                                        <span className='mr-2'>{t('MOMENT_AT')}</span>
                                                        {toBuddhistYear(moment(index), 'DD/MM/YYYY')}
                                                    </TableCell>
                                                </TableRow>,

                                                _.map(data, (lab: any, indexTable: number) => {
                                                    return renderDataDF(lab, indexTable, false, 'data')
                                                }),
                                                renderDataDF(data, 0, false, 'sum')
                                            ] : []
                                        })
                                    }
                                />
                            </UseStyled.ResponsiveReportTable>
                            <Box className='mt-3 pl-xl-3 pr-xl-2 pb-4'>
                                <LabReportListTotal
                                    type={"html"}
                                    date={props.date}
                                    page={props.page}
                                    pageLimit={props.pageLimit}
                                    sortType={props.sortType}
                                    sortBy={props.sortBy}
                                    rowCount={props.rowCount}
                                    data={props.data}
                                    onRequestSort={props.onRequestSort}
                                    handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                                    handleChangePage={props.handleChangePage}
                                />
                            </Box>
                        </Box>

                    )
                ) || <EmptyData />
            }

            <div className="print-show" ref={props.componentRef}>
                <LabReportExport
                    filter={props.filter}
                    componant={
                        <>
                            {_.map(_.groupBy(props.data, 'dateOrder'), (data: any, index: number) => {
                                return (
                                    <Box key={index}>
                                        {index > 0 && <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />}

                                        <Box>
                                            <UseStyled.Styles className="pt-3">
                                                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            {_.map(headDfCells, (val: any) => {
                                                                return (
                                                                    < td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                                                                )
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.length > 0 && [
                                                            _.map(data, (df: any, indexData: number) => {
                                                                return renderDataDF(df, indexData, true, 'data')
                                                            }),
                                                            renderDataDF(data, 0, true, 'sum')
                                                        ]
                                                        }
                                                        {!data.length && (
                                                            <td colSpan={headDfCells.length}>
                                                                {t('REPORT.TITLE.NO_DATA')}
                                                            </td>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </UseStyled.Styles >
                                        </Box>
                                    </Box>
                                )
                            })}
                            <Box className='mt-3 pl-xl-3 pr-xl-2 pb-4 ml-3'>
                                <LabReportListTotal
                                    type={"export"}
                                    date={props.date}
                                    page={props.page}
                                    pageLimit={props.pageLimit}
                                    sortType={props.sortType}
                                    sortBy={props.sortBy}
                                    rowCount={props.rowCount}
                                    data={props.data}
                                    onRequestSort={props.onRequestSort}
                                    handleChangeRowsPerPage={props.handleChangeRowsPerPage}
                                    handleChangePage={props.handleChangePage}
                                />
                            </Box>

                        </>

                    } />
            </div>
        </>
    )
}

export function LabReportExport(props: ExportProps) {
    const { t } = useTranslation()

    const FILTER_TITLES = [
        { key: 'BRANCH', label: 'REPORT.FILTER.BRANCH' },
        { key: 'DATE_START', label: 'REPORT.FILTER.DATE_START' },
        { key: 'DATE_END', label: 'REPORT.FILTER.DATE_END' },
        { key: 'DOCTOR', label: 'REPORT.FILTER.DOCTOR' },
        { key: 'LAB_GROUP', label: 'REPORT.FILTER.LAB_GROUP' },
        { key: 'LAB_COMPANY', label: 'REPORT.FILTER.LAB_COMPANY' },
    ];

    const renderValue = (value: any, index: any) => {
        if (index === 1 || index === 2) {
            return value ? toBuddhistYear(moment(value), 'DD/MM/YYYY') : '-';
        }
        return value;
    };

    return (
        <>
            <TableHead style={{ fontWeight: 800 }} >{t('REPORT.REPORT_NAME.LAB')}</TableHead>
            <table>
                <tr>
                    {FILTER_TITLES.map((filter, index) => (
                        <TableSortLabel key={index} >
                            <td className='mr-2' style={{ fontSize: 10, fontWeight: 800 }}>{t(filter.label)} : </td>
                            <td style={{ fontSize: 10, fontWeight: 500 }}>{renderValue(props.filter[index], index)}</td>
                        </TableSortLabel >
                    ))}
                </tr>
            </table>
            {props.componant}
        </>
    )
}
