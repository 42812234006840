import { useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { Box, Grid, Typography, Divider, TableRow, TableCell, Button, IconButton, Collapse, FormControlLabel, Checkbox, Chip, ClickAwayListener } from '@mui/material'
import _, { forEach } from 'lodash'
import moment from 'moment'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** ROUTE */
import { routeName } from 'routes/routes-name'

/** API */
import AdjustmentApi, { APPROVE_STATUS, INVENTORY_STATUS, AdjustmentProps, AdjustmentItemProps } from 'api/warehouses/adjustment.api'
import MasterInventoryApi from 'api/master/masterInventory.api'

/** COMPONENT */
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import StatusSelect from 'features/finance/income-expenses/statusSelector-income-expenses'
import InputTextField from 'component/Input/InputTextField'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import InputTextarea from 'component/Input/InputTextarea'
import TableCustom from 'component/Table/TableCustom'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { notiSuccess, notiError } from 'component/notifications/notifications'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { icons } from 'constants/images'
import { getBranch, getBranchName } from 'utils/app.utils'

/** STYLE */
import * as UseStyled from 'features/warehouses/adjustment/styled'
import InputCheckbox from 'component/Input/InputCheckbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function FormAdjustment() {
    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()

    const [loading, setLoading] = useState(false);

    const [adjustmentId, setAdjustmentId] = useState<number>(0);
    const [adjustment, setAdjustment] = useState<AdjustmentProps>({
        subject: '',
        date: moment().format('YYYY-MM-DD'),
        remark: '',
        status: APPROVE_STATUS.PENDING,
        items: []
    });
    const [adjustmentStatus, setAdjustmentStatus] = useState<APPROVE_STATUS>(APPROVE_STATUS.PENDING);
    const [backgroundStatus, setBackgroundStatus] = useState(colors.disabledLightGray);

    const [products, setProducts] = useState([]);
    const [specificProduct, setSpecificProduct] = useState({});
    const [productsLoading, setProductsLoading] = useState(false);
    const [branch, setBranch] = useState<{ branchId?: number, branchName?: string }>({});

    const initStateErrorMessage = {
        EMPTY_PRODUCT: '',
        EMPTY_INVENTORY: '',
        EMPTY_LOT: '',
        EMPTY_NEW_QTY: '',
        EMPTY_DATE: '',
        EMPTY_SUBJECT: '',
        EMPTY_ADD_QTY: ''
    }
    const [errorMessage, setErrorMessage] = useState(initStateErrorMessage);

    const [selectProduct, setSelectProduct] = useState<any>([])
    const [adjustmentGroup, setAdjustmentGroup] = useState<any>({})
    const [openFlterProduct, setOpenFlterProduct] = useState<boolean>(false)
    const [freeSoloProduct, setFreeSoloProduct] = useState<boolean>(false)

    useEffect(() => {
        loadProducts()
        setBranch({ branchId: Number(getBranch()), branchName: getBranchName() || '' })
    }, []);

    const loadData = (id: number) => {
        AdjustmentApi.findById(id)
            .then(({ data }) => {
                const newData: any = []
                let null_detailLotNo: any[] = []
                let null_id: any = []
                let null_total = 0
                let null_qtyCurrent = 0
                let null_qtyAdd = 0
                let null_qtyNew = 0
                let null_qtyDiff = 0
                data?.items?.forEach((item: any, index: number) => {

                    if (item?.detail?.lotNo) {
                        newData.push(item)
                    } else {
                        null_detailLotNo.push(item)
                        null_id.push(item?.inventoryId)
                        null_qtyAdd += item?.qtyAdd || 0
                        null_total += item?.total || 0
                        null_qtyCurrent += item?.qtyCurrent || 0
                        null_qtyDiff += item?.qtyDiff || 0
                        null_qtyNew += item?.qtyNew || 0
                    }
                    if (null_detailLotNo[0]?.inventoryId && (null_detailLotNo[0]?.detail?.itemName !== item?.detail?.itemName || index === (data?.items?.length - 1))) {
                        const newInfo = {
                            ...null_detailLotNo[0],
                            inventoryId: null_id,
                            lotNo: null,
                            qtyCurrent: null_qtyCurrent,
                            detailLotNo: null_detailLotNo,
                            total: null_total,
                            qtyDiff: null_qtyDiff,
                            qtyAdd: null_qtyAdd,
                            qtyNew: null_qtyNew
                        }
                        newData.push(newInfo)

                        null_detailLotNo = []
                        null_id = []
                        null_total = 0
                        null_qtyAdd = 0
                        null_qtyCurrent = 0
                        null_qtyNew = 0
                        null_qtyDiff = 0
                    }
                })
                setAdjustment({ ...data, items: newData })
                setAdjustmentStatus(data?.status || APPROVE_STATUS.PENDING)
            })
            .catch(() => { return })
    }

    const handleProductInventoryLotNo = (data: any) => {
        const newInfo: any = []
        data.forEach((item: any) => {
            const newDataNull: any = []
            const null_id: any = []
            let null_qty = 0
            const null_detailLotNo: any[] = []
            let null_total = 0
            let newData: any = []
            item.inventory.forEach((info: any) => {
                if (info.lotNo) {
                    newData.push(info)
                } else {
                    newDataNull.push(info)
                    null_id.push(info.inventoryId)
                    null_qty += info.qty
                    null_detailLotNo.push(info)
                    null_total += info.total
                }
            })

            if (newDataNull?.length !== 0) {
                newData = newData.sort((a: any, b: any) => b.inventoryId - a.inventoryId)
                newInfo.push({
                    ...item, inventory: [
                        {
                            ...newDataNull[0],
                            inventoryId: null_id,
                            lotNo: null,
                            qty: null_qty,
                            detailLotNo: null_detailLotNo,
                            total: null_total
                        },
                        ...newData
                    ]
                })
            } else {
                newData = newData.sort((a: any, b: any) => b.inventoryId - a.inventoryId)
                newInfo.push({
                    ...item, inventory: [...newData]
                })
            }

        })
        return newInfo
    }

    const loadProducts = (search?: string) => {
        setProductsLoading(true)
        MasterInventoryApi.findAllProducts({ pageLimit: 20, search: search })
            .then(({ data }) => {
                const newInfo = data?.length !== 0 ? handleProductInventoryLotNo(data) : []
                setProducts(newInfo)
            })
            .catch(() => { return })
            .finally(() => setProductsLoading(false))
    }

    const handleChange = (value: APPROVE_STATUS | string, name: string) => {
        setErrorMessage(initStateErrorMessage)
        setAdjustment({ ...adjustment, [name]: value })
    }

    const handleProductDel = (val_inventory: any) => {
        const valueProducts = _.filter(adjustment.items, (product, index) => val_inventory.inventoryId !== product.inventoryId)
        setAdjustment({ ...adjustment, items: valueProducts })
    }

    const handleCheckProductAdjemnt = (option: any) => {
        let check = false
        for (const index in adjustment?.items) {
            const dataProduct: any = adjustment?.items[index]
            if (dataProduct?.inventoryId === option?.inventoryId ||
                (typeof dataProduct?.inventoryId === 'object' &&
                    typeof dataProduct?.inventoryId === typeof option?.inventoryId &&
                    dataProduct?.inventoryId[0] === option?.inventoryId[0])) {
                check = true
                break;
            }
        }
        return check
    }

    const handleProductAll = (val: any, clear: boolean) => {
        if (clear) {
            const selectProductAll: any = []
            val?.inventory.forEach((item: any) => {
                if (!handleCheckProductAdjemnt(item)) {
                    selectProductAll.push({
                        adjustmentReceiptItemId: null,
                        detail: { item: val, itemName: val?.productName || val?.productNameEn },
                        qtyAdd: 0,
                        qtyNew: 0,
                        inventoryId: item?.inventoryId,
                        qtyCurrent: item?.qty,
                        qtyDiff: item?.qty > 0 ? 0 - item?.qty : 0,
                        qtyDiffType: item?.qty >= 1 ? INVENTORY_STATUS.DECREASE : INVENTORY_STATUS.INCREASE,
                    })
                }

            })
            setAdjustment({ ...adjustment, items: [...adjustment?.items, ...selectProductAll] })
        }
        else {
            const valueProducts = adjustment?.items.filter((item: any) => {
                if ((item?.detail?.item?.productId || item?.detail?.itemId) !== val?.productId) {
                    return item
                }
            })
            setAdjustment({ ...adjustment, items: valueProducts })
        }
    }

    const handleProductAdd = (val: any, val_inventory: any) => {
        const index = (adjustment?.items?.length || 0)
        const newProducts: AdjustmentItemProps[] = _.merge([], adjustment?.items)
        let selectProduct: AdjustmentItemProps = {
            adjustmentReceiptItemId: null,
            detail: null,
            inventoryId: 0,
            qtyAdd: 0,
            qtyCurrent: 0,
            qtyDiff: 0,
            qtyDiffType: INVENTORY_STATUS.INCREASE,
            qtyNew: 0
        }

        selectProduct = {
            ...selectProduct,
            qtyNew: 0,
            qtyAdd: 0,
            detail: { item: val, itemName: val?.productName || val?.productNameEn },
            inventoryId: val_inventory?.inventoryId,
            qtyCurrent: val_inventory?.qty,
            qtyDiff: val_inventory?.qty > 0 ? 0 - val_inventory?.qty : 0,
            qtyDiffType: val_inventory?.qty >= 1 ? INVENTORY_STATUS.DECREASE : INVENTORY_STATUS.INCREASE,
        }
        newProducts[index] = selectProduct
        setAdjustment({ ...adjustment, items: newProducts })

    }

    const handleProductChange = (no: number, val: any, name: string, product?: any) => {
        setErrorMessage(initStateErrorMessage)
        const newProducts: AdjustmentItemProps[] = _.merge([], adjustment?.items)
        const newIndex = newProducts.findIndex((item: any) => {
            if (typeof item?.inventoryId === 'object') {
                return item?.inventoryId[0] === product?.inventoryId[0]
            }
            return item?.inventoryId === product?.inventoryId
        });
        const index = newIndex !== -1 && newIndex || no
        let selectProduct: AdjustmentItemProps = _.merge({}, newProducts[index])

        if (name === 'product-list') {
            let lotBalance = val?.inventory[0]?.lotNo ? val?.inventory[0]?.inventoryId : 0
            let qtyCurrent = val?.inventory[0]?.qty ? val?.inventory[0]?.qty : 0
            const lotNo = val?.inventory[0]?.lotNo ? val?.inventory[0]?.lotNo : 0

            if (!_.isEmpty(adjustment.items)) {
                const lotsBalance = _.filter(val?.inventory, (inv) => !_.some(adjustment.items, { inventoryId: inv.inventoryId }))
                lotBalance = lotsBalance[0]?.lotNo ? lotsBalance[0]?.inventoryId : 0
                qtyCurrent = lotsBalance[0]?.qty ? lotsBalance[0]?.qty : 0
            }

            selectProduct = {
                ...selectProduct,
                inventoryId: handleLastInventoryId(val.inventory)?.inventoryId,
                qtyCurrent: qtyCurrent,
                qtyNew: 0,
                qtyAdd: 0,
                qtyDiff: 0,
                qtyDiffType: INVENTORY_STATUS.INCREASE,
                detail: { item: val, itemName: val?.productName || val?.productNameEn }
            }
        } else if (name === 'inventoryId') {

            selectProduct = {
                ...selectProduct,
                [name]: val?.inventoryId,
                qtyCurrent: val?.qty,
                qtyDiff: val?.qty > 0 ? 0 - val?.qty : 0,
                qtyDiffType: val?.qty >= 1 ? INVENTORY_STATUS.DECREASE : INVENTORY_STATUS.INCREASE,
            }
        } else if (name === 'qtyNew') {
            const value = val === '' ? 0 : String(val).replace(/[^0-9.]/g, '')
            const diff: number = selectProduct.qtyCurrent > 0 ? Number(value) - selectProduct.qtyCurrent : selectProduct.qtyCurrent + Number(value)
            const diffType: INVENTORY_STATUS = Number(value) >= selectProduct.qtyCurrent ? INVENTORY_STATUS.INCREASE : INVENTORY_STATUS.DECREASE
            selectProduct = { ...selectProduct, qtyNew: Number(value), qtyAdd: 0, qtyDiff: diff, qtyDiffType: diffType }
        } else if (name === 'qtyAdd') {
            const value = val === '' ? 0 : String(val).replace(/[^0-9.]/g, '')
            const diff: number = Number(value) > 0 ? selectProduct.qtyCurrent + Number(value) : 0 - selectProduct.qtyCurrent
            const diffType: INVENTORY_STATUS = Number(value) > 0 ? INVENTORY_STATUS.INCREASE : INVENTORY_STATUS.DECREASE
            selectProduct = { ...selectProduct, qtyNew: 0, qtyAdd: Number(value), qtyDiff: diff, qtyDiffType: diffType }
        } else selectProduct = { ...selectProduct, [name]: val }
        newProducts[index] = selectProduct

        setAdjustment({ ...adjustment, items: newProducts })
    }

    const submit = () => {
        if (!adjustment.subject || !adjustment.date) {
            return setErrorMessage({
                ...errorMessage,
                EMPTY_SUBJECT: !adjustment.subject ? t('ADJUSTMENT.MESSAGE.ERROR.EMPTY_SUBJECT') : '',
                EMPTY_DATE: !adjustment.date ? t('ADJUSTMENT.MESSAGE.ERROR.EMPTY_DATE') : '',
            })
        }

        if (_.isEmpty(adjustment.items)) {
            return notiError(t('ADJUSTMENT.MESSAGE.ERROR.EMPTY_ITEMS'))
        }

        if (!_.isEmpty(adjustment.items) && (_.some(adjustment.items, { inventoryId: 0 }) || _.some(adjustment.items, { qtyNew: '', qtyAdd: 0 }) || _.some(adjustment.items, { adjustmentReceiptItemId: null, detail: null }))) {
            return setErrorMessage({
                ...errorMessage,
                EMPTY_INVENTORY: _.some(adjustment.items, { inventoryId: 0 }) ? t('ADJUSTMENT.MESSAGE.ERROR.EMPTY_INVENTORY') : '',
                EMPTY_NEW_QTY: _.some(adjustment.items, { qtyNew: '', qtyAdd: 0 }) ? t('ADJUSTMENT.MESSAGE.ERROR.EMPTY_NEW_QTY') : '',
                EMPTY_PRODUCT: _.some(adjustment.items, { adjustmentReceiptItemId: null, detail: null }) ? t('ADJUSTMENT.MESSAGE.ERROR.EMPTY_PRODUCT') : ''
            })
        }
        setLoading(true)
        adjustmentId ? onUpdate() : onCreate()
    }

    const handleDataSubmit = (adjustmentItem: any) => {
        const newData: any = []
        adjustmentItem.forEach((item: any) => {
            if (typeof item?.inventoryId === 'object' && item?.inventoryId?.length) {
                let numQtyNew = item?.qtyNew || 0
                let newQtyDiffAll = item?.qtyDiff || 0
                let numQtyCurrent = item?.qtyCurrent || 0
                item?.inventoryId.forEach((info: number, index: number) => {
                    const element = item?.detail?.item?.inventory[0]?.detailLotNo[index]
                    const elementQty = element?.qty || element?.qtyCurrent || 0
                    const elementQtyNew = numQtyNew < elementQty ? numQtyNew : elementQty
                    const newQtyDiff = numQtyNew - elementQty
                    numQtyCurrent = (elementQty !== 0 ? elementQtyNew : 0) - numQtyCurrent
                    numQtyNew = (elementQty !== 0) && (numQtyNew < elementQty) ? 0 : numQtyNew - elementQty
                    newQtyDiffAll = 0
                    if (item?.qtyDiffType === "DECREASE") {
                        newData.push(
                            {
                                adjustmentReceiptItemId: item?.adjustmentReceiptItemId,
                                inventoryId: info,
                                qtyAdd: 0,
                                qtyCurrent: elementQty,
                                qtyDiff: elementQty === 0 ? 0 : newQtyDiff,
                                qtyDiffType: (elementQty !== 0) && (newQtyDiff < 0) ? "DECREASE" : "INCREASE",
                                qtyNew: elementQty === 0 ? 0 : elementQtyNew
                            }
                        )
                    } else {
                        const last = item?.inventoryId?.length - 1
                        newData.push(
                            {
                                adjustmentReceiptItemId: item?.adjustmentReceiptItemId,
                                inventoryId: info,
                                qtyAdd: last === index && item?.qtyAdd ? item?.qtyAdd : 0,
                                qtyCurrent: elementQty || 0,
                                qtyDiff: last === index ? item?.qtyAdd || item?.qtyNew - (item?.qtyCurrent - (elementQty || 0)) : 0,
                                qtyDiffType: "INCREASE",
                                qtyNew: item?.qtyNew ? last === index ? item?.qtyDiff : elementQty || 0 : 0
                            }
                        )
                    }

                })
            } else {
                newData.push(_.omit(item, ['detail']))
            }
        })
        return newData
    }

    const onCreate = async () => {
        const newAdjustment: any = _.merge({}, adjustment)
        const adjustmentItem: any = _.merge([], adjustment.items)
        // adjustmentItem = _.map(adjustmentItem, (item, index) => _.omit(item, ['detail']))

        newAdjustment.items = handleDataSubmit(adjustmentItem)

        const resp = await AdjustmentApi.createAdjustment(newAdjustment)
        if (resp.status === 201) {
            setLoading(false)
            notiSuccess(t('ADJUSTMENT.MESSAGE.SUCCESS.CREATE_SUCCESS'))
            history.push(routeName.adjustment)
        } else {
            setLoading(false)
            notiError(t(`ADJUSTMENT.MESSAGE.ERROR.DEFAULT`))
        }
    }

    const onUpdate = async () => {
        let newAdjustment: any = _.merge({}, adjustment)
        newAdjustment = _.omit(newAdjustment, ['adjustmentReceiptId', 'branchId', 'prefix', 'documentNo', 'updatedBy', 'updatedAt', 'createdBy', 'createdAt'])
        let adjustmentItem: any = _.merge([], adjustment.items)
        adjustmentItem = _.map(adjustmentItem, (item, index) => _.omit(item, ['adjustmentReceiptId', 'updatedBy', 'updatedAt', 'createdBy', 'createdAt', 'detail']))
        newAdjustment.items = handleDataSubmit(adjustmentItem)

        setLoading(false)

        const resp = await AdjustmentApi.updateAdjustment(adjustmentId, newAdjustment)
        if (resp.status === 200) {
            setLoading(false)
            notiSuccess(t('ADJUSTMENT.MESSAGE.SUCCESS.UPDATE_SUCCESS'))
            history.push(routeName.adjustment)
        } else {
            setLoading(false)
            notiError(t(`ADJUSTMENT.MESSAGE.ERROR.DEFAULT`))
        }
    }

    useEffect(() => {
        const adjustmentState: any = location.state
        if (!_.isEmpty(adjustmentState) && adjustmentState.adjustmentId) {
            loadData(adjustmentState.adjustmentId)
            setAdjustmentId(adjustmentState.adjustmentId)
        } else if (!_.isEmpty(adjustmentState) && adjustmentState?.productName) {
            const name = adjustmentState?.productName
            MasterInventoryApi.findAllProducts({ pageLimit: 1, search: name })
                .then(({ data }) => {
                    if (data[0]?.productId) {
                        const item = data[0]
                        const newInfo = handleProductInventoryLotNo(data)
                        setSpecificProduct(item)
                        // setAdjustment({
                        //     ...adjustment, items: [
                        //         ...adjustment.items,
                        //         {
                        //             adjustmentReceiptItemId: null,
                        //             inventoryId: 0,
                        //             qtyCurrent: 0,
                        //             qtyNew: 0,
                        //             qtyAdd: 0,
                        //             qtyDiff: 0,
                        //             qtyDiffType: INVENTORY_STATUS.INCREASE,
                        //             detail: { item: newInfo[0], itemName: item?.productName || item?.productNameEn }
                        //         }
                        //     ]
                        // })
                        handleSelectProdcutNew(newInfo[0])
                        setSelectProduct(newInfo)
                    }
                })
                .catch(() => { return })
        } else {
            const queryParams = new URLSearchParams(window.location.search)
            const adjustmentStateId = Number(queryParams.get("id"))
            if (adjustmentStateId) loadData(adjustmentStateId)
            setAdjustmentId(adjustmentStateId)
        }
    }, [location])

    useEffect(() => {
        if (adjustment.status === APPROVE_STATUS.APPROVE) setBackgroundStatus(colors.approveStatus.approve)
        else if (adjustment.status === APPROVE_STATUS.PENDING) setBackgroundStatus(colors.approveStatus.pending)
        else if (adjustment.status === APPROVE_STATUS.CANCEL) setBackgroundStatus(colors.approveStatus.cancel)
        else setBackgroundStatus(colors.disabledLightGray)
    }, [adjustment.status]);

    const headCells = [
        { id: 'no', disablePadding: false, label: '#', width: '60px' },
        { id: 'message', disablePadding: false, label: t('ADJUSTMENT.TABLE.CELLS.PRODUCT_LIST'), width: '25%' },
        { id: 'message', disablePadding: false, label: t('ADJUSTMENT.TABLE.CELLS.LOT'), width: '200px' },
        { id: 'message', disablePadding: false, label: t('ADJUSTMENT.TABLE.CELLS.QTY'), class: 'text-nowrap' },
        { id: 'message', disablePadding: false, label: t('ADJUSTMENT.TABLE.CELLS.NEW_QTY'), class: 'text-nowrap' },
        { id: 'message', disablePadding: false, label: t('ADJUSTMENT.TABLE.CELLS.ADD_QTY'), class: 'text-nowrap' },
        { id: 'message', disablePadding: false, label: t('ADJUSTMENT.TABLE.CELLS.DIFFERENCE_QTY'), class: 'text-nowrap' },
        { id: 'action', disablePadding: false, label: (adjustment.status !== APPROVE_STATUS.APPROVE && adjustment.status !== APPROVE_STATUS.CANCEL) ? 'Action' : '', width: '70px', class: 'px-0' }
    ]

    const addNewRow = () => {
        return (
            (adjustment.status !== APPROVE_STATUS.APPROVE && adjustment.status !== APPROVE_STATUS.CANCEL) && (
                <TableRow className="row-add-new-row">
                    <TableCell
                        align="left"
                        scope="row"
                        colSpan={_.size(headCells)}
                        className={`p-2 ${(_.some(adjustment.items, { inventoryId: 0 }) || _.some(adjustment.items, { lotNo: 0 })) && '--pe-none'}`}
                        onClick={() => {
                            setErrorMessage(initStateErrorMessage)
                            if (_.some(adjustment.items, { inventoryId: 0 }) || _.some(adjustment.items, { qtyNew: '', qtyAdd: 0 }) || _.some(adjustment.items, { adjustmentReceiptItemId: null, detail: null })) {
                                setErrorMessage({
                                    ...errorMessage,
                                    EMPTY_INVENTORY: _.some(adjustment.items, { inventoryId: 0 }) ? t('ADJUSTMENT.MESSAGE.ERROR.EMPTY_INVENTORY') : '',
                                    EMPTY_NEW_QTY: _.some(adjustment.items, { qtyNew: '', qtyAdd: 0 }) ? t('ADJUSTMENT.MESSAGE.ERROR.EMPTY_NEW_QTY') : '',
                                    EMPTY_PRODUCT: _.some(adjustment.items, { adjustmentReceiptItemId: null, detail: null }) ? t('ADJUSTMENT.MESSAGE.ERROR.EMPTY_PRODUCT') : ''
                                })
                            } else {
                                setSpecificProduct({})
                                setAdjustment({ ...adjustment, items: [...adjustment.items, { adjustmentReceiptItemId: null, inventoryId: 0, qtyCurrent: 0, qtyNew: 0, qtyAdd: 0, qtyDiff: 0, qtyDiffType: INVENTORY_STATUS.INCREASE, detail: null }] })
                            }
                        }}>
                        <Button variant="text" className="btn-add-new-row d-flex align-items-baseline" disabled={adjustmentStatus === APPROVE_STATUS.APPROVE || adjustmentStatus === APPROVE_STATUS.CANCEL}>
                            <span className={'mr-1'}>+</span> {t('ADJUSTMENT.BUTTON.ADD')}
                        </Button>
                    </TableCell>
                </TableRow>
            ) || <></>
        )
    }

    const renderData = (data: AdjustmentItemProps, no: number) => {
        const renderProducts = () => {
            const alreadyProducts = data?.detail?.item || {}
            let newProduct: any = products
            if (!_.isEmpty(alreadyProducts)) newProduct = _.filter(products, (p: any) => p?.productId !== alreadyProducts?.productId)
            return [...newProduct, specificProduct, alreadyProducts]
        }

        let allInventory: number[] = []
        _.forEach(adjustment.items, (item) => { if (item.inventoryId) allInventory = [...allInventory, item.inventoryId] })

        const renderLotNo = (option: any) => {
            if (typeof option === "string") {
                return option || t('ADJUSTMENT.TABLE.LOT_EMPTY')
            } else {
                return option ? option?.lotNo || t('ADJUSTMENT.TABLE.LOT_EMPTY') : t('ADJUSTMENT.TABLE.LOT_EMPTY')
            }
        }

        return (
            <TableRow key={no}>
                <TableCell align="center" scope="row" className='pt-3'>{no + 1}</TableCell>
                <TableCell align="center" scope="row">
                    <AutocompleteSelect
                        labelId={'product-list'}
                        placeholder={t('ADJUSTMENT.FORM.PRODUCT')}
                        options={data?.adjustmentReceiptId || data?.adjustmentReceiptItemId ? [data?.detail] : renderProducts()}
                        onchange={(e, value: any) => handleProductChange(no, value, 'product-list', data)}
                        value={data?.adjustmentReceiptId || data?.adjustmentReceiptItemId ? data?.detail : _.find(renderProducts(), { productId: data?.detail?.item?.productId || null })}
                        noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                        getOptionLabel={(option: any) => option?.productName || option?.productNameEn || option?.itemName || option?.itemNameEn}
                        renderOption={(props, option: any) => {
                            if (option) {
                                return (
                                    <Box
                                        {...props}
                                        key={option?.name || option.productId}
                                        component="li"
                                        sx={{
                                            pointerEvents: _.every(option.inventory, (iv) => _.includes(allInventory, iv.inventoryId)) ? 'none !important' : 'auto !important',
                                            opacity: _.every(option.inventory, (iv) => _.includes(allInventory, iv.inventoryId)) ? .5 : 1
                                        }}>
                                        {option.productName || option.productNameEn}
                                    </Box>
                                )
                            }
                        }}
                        onOpen={() => loadProducts()}
                        inputProps={{
                            onChange: (e: any) => {
                                setProducts([])
                                setTimeout(() => { loadProducts(e.target.value) }, 1000)
                            }
                        }}
                        loading={productsLoading}
                        disabled={data?.adjustmentReceiptId || data?.adjustmentReceiptItemId ? true : false}
                        disableClearable
                        errMessage={!data?.inventoryId ? errorMessage.EMPTY_PRODUCT : ''}
                    />
                </TableCell>
                <TableCell align="center" scope="row" title={renderLotNo(data?.adjustmentReceiptId || data?.adjustmentReceiptItemId ? data?.detail?.lotNo : _.find(data?.detail?.item?.inventory, { inventoryId: data?.inventoryId }) || t('ADJUSTMENT.TABLE.LOT_EMPTY'))}>
                    <AutocompleteSelect
                        labelId={'inventoryId'}
                        placeholder={t('ADJUSTMENT.TABLE.CELLS.LOT')}
                        options={data?.adjustmentReceiptId || data?.adjustmentReceiptItemId ? [data?.detail?.lotNo] : data?.detail?.item?.inventory}
                        onchange={(e, value: any) => handleProductChange(no, value, 'inventoryId', data)}
                        value={data?.adjustmentReceiptId || data?.adjustmentReceiptItemId ? data?.detail?.lotNo || t('ADJUSTMENT.TABLE.LOT_EMPTY') : _.find(data?.detail?.item?.inventory, { inventoryId: data?.inventoryId }) || t('ADJUSTMENT.TABLE.LOT_EMPTY')}
                        noOptionsText={t('NOT_FOUND')}
                        getOptionLabel={(option: any) => renderLotNo(option)}
                        renderOption={(props, option: any) => (
                            <Box
                                {...props}
                                key={option.name}
                                component="li"
                                sx={{
                                    pointerEvents: _.some(adjustment?.items, { inventoryId: option?.inventoryId }) ? 'none !important' : 'auto !important',
                                    opacity: _.some(adjustment?.items, { inventoryId: option?.inventoryId }) ? .5 : 1
                                }}>
                                {option?.lotNo || t('ADJUSTMENT.TABLE.LOT_EMPTY')}
                            </Box>
                        )}
                        disabled={data?.adjustmentReceiptId ||
                            data?.adjustmentReceiptItemId ||
                            (!data?.adjustmentReceiptId && !data?.adjustmentReceiptItemId && !data?.detail?.item?.productId) ||
                            (adjustment.status === APPROVE_STATUS.APPROVE || adjustment.status === APPROVE_STATUS.CANCEL)
                            ? true : false}
                        disableClearable
                        errMessage={!data?.inventoryId ? errorMessage.EMPTY_INVENTORY : ''}
                        filterOptions={(option) => option?.lotNo || t('ADJUSTMENT.TABLE.LOT_EMPTY')}
                        sxPaper={{ minWidth: '150px' }}
                        sxAutoComplete={{
                            '.MuiInputBase-root': {
                                paddingRight: '5px !important'
                            }
                        }}
                    />
                </TableCell>
                <TableCell align="center" scope="row">
                    <InputTextField
                        disabled
                        label={''}
                        value={data?.qtyCurrent} />
                </TableCell>
                <TableCell align="center" scope="row">
                    <InputTextField
                        label={''}
                        value={data?.qtyNew}
                        name={'qtyNew'}
                        onchange={(e: any) => handleProductChange(no, e.target.value, 'qtyNew', data)}
                        disabled={!data?.inventoryId || data.qtyAdd || adjustmentStatus === APPROVE_STATUS.APPROVE || adjustmentStatus === APPROVE_STATUS.CANCEL ? true : false}
                        helperText={data.qtyNew === 0 && data.qtyAdd === 0 ? errorMessage.EMPTY_NEW_QTY : ''} />
                </TableCell>
                <TableCell align="center" scope="row">
                    <InputTextField
                        label={''}
                        value={data?.qtyAdd}
                        name={'qtyNew'}
                        onchange={(e: any) => handleProductChange(no, e.target.value, 'qtyAdd', data)}
                        disabled={!data?.inventoryId || data.qtyNew || adjustmentStatus === APPROVE_STATUS.APPROVE || adjustmentStatus === APPROVE_STATUS.CANCEL ? true : false}
                        helperText={data.qtyNew === 0 && data.qtyAdd === 0 ? errorMessage.EMPTY_NEW_QTY : ''} />
                </TableCell>
                <TableCell align="center" scope="row">
                    <InputTextField
                        disabled
                        label={''}
                        value={data?.qtyDiff} />
                </TableCell>
                <TableCell align="center" scope="row" sx={{ paddingTop: '13px !important' }}>
                    {
                        (adjustment.status !== APPROVE_STATUS.APPROVE && adjustment.status !== APPROVE_STATUS.CANCEL) && (
                            <IconButton
                                aria-label="trash"
                                onClick={() => {
                                    const newIndex = adjustment?.items.findIndex(item => item?.inventoryId === data?.inventoryId);
                                    const valueProducts = _.filter(adjustment.items, (product, index) => newIndex !== index)
                                    setAdjustment({ ...adjustment, items: valueProducts })
                                }}
                                sx={{ backgroundColor: 'transparent !important', padding: 0, opacity: adjustmentStatus === APPROVE_STATUS.APPROVE || adjustmentStatus === APPROVE_STATUS.CANCEL ? .4 : 1, filter: adjustmentStatus === APPROVE_STATUS.APPROVE || adjustmentStatus === APPROVE_STATUS.CANCEL ? 'grayscale(1)' : 'none' }}>
                                <img src={icons.btnTrash} alt="trash" />
                            </IconButton>
                        ) || <></>
                    }
                </TableCell>
            </TableRow>
        )
    }

    const renderDataSum = () => {
        const items = adjustment?.items

        const sumCurrent = items.reduce((res, current) => {
            return res + current.qtyCurrent
        }, 0)

        const sumNew = items.reduce((res, current) => {
            return res + current.qtyNew
        }, 0)

        const sumAdd = items.reduce((res, current) => {
            return res + current.qtyAdd
        }, 0)

        const sumDiff = items.reduce((res, current) => {
            return res + current.qtyDiff
        }, 0)

        return (
            <TableRow key={99}>
                <TableCell align="center" scope="row" className='pt-3'></TableCell>
                <TableCell align="center" scope="row"></TableCell>
                <TableCell className='text-center align-middle' align="center" scope="row">
                    <Typography >{t('ADJUSTMENT.TABLE.CELLS.TOTAL')}</Typography>
                </TableCell>
                <TableCell align="center" scope="row">
                    <InputTextField
                        disabled
                        label={''}
                        value={sumCurrent} />
                </TableCell>
                <TableCell align="center" scope="row">
                    <InputTextField
                        disabledAutoComplete
                        label={''}
                        value={sumNew}
                        disabled
                    />
                </TableCell>
                <TableCell align="center" scope="row">
                    <InputTextField
                        label={''}
                        value={sumAdd}
                        name={'qtyNew'}
                        disabled
                    />
                </TableCell>
                <TableCell align="center" scope="row">
                    <InputTextField
                        disabled
                        label={''}
                        value={sumDiff} />
                </TableCell>

            </TableRow>
        )
    }

    const handleCheckProduct = (option: any) => {
        let check = false
        for (const index in selectProduct) {
            const dataProduct = selectProduct[index]
            if (dataProduct?.productId === (option?.productId || option?.itemId)) {
                check = true
                break;
            }
        }
        return check
    }

    const renderTableProductGroup = () => {
        if (adjustment?.items) {
            const groupedData: any = {};
            adjustment?.items.forEach((item: any) => {
                const groupName = item.detail.itemName;
                if (!groupedData[groupName]) {
                    groupedData[groupName] = [];
                }
                groupedData[groupName].push(item);
            });
            setAdjustmentGroup(groupedData)
        }
    }

    const handleEditProductInven = (item: any) => {
        let productInven = {}
        let check = false
        for (const index in products) {
            const data: any = products[index]
            if (data?.productId === (item?.productId || item?.itemId)) {
                productInven = data || {}
                check = true
                break
            }
        }
        return { item: productInven, check: check }
    }

    const handleDisbledSelectProduct = (option: any) => {
        let check = false

        for (const index in adjustment?.items) {
            const data = adjustment?.items[index]
            if ((data?.detail?.item?.productId || data?.detail?.itemId) === option?.productId) {
                check = true
                break;
            }
        }

        return check
    }

    useEffect(() => {
        if (adjustment?.items && products?.length) {
            renderTableProductGroup()
            const newData: any = []
            const uniqueIds = new Set();
            const allId: any[] = []
            adjustment.items.forEach((item: any) => {
                const detailItem = item?.detail?.item || item?.detail
                const checkEdit = item?.detail?.item ? false : true
                const dataProduct = checkEdit ? handleEditProductInven(detailItem) : { item: detailItem, check: true }
                const uniqueId = dataProduct?.item?.productId || dataProduct?.item?.itemId
                !allId.includes(uniqueId) && allId.push(uniqueId)
                if (detailItem && !handleCheckProduct(detailItem) && dataProduct?.check) {
                    if (!uniqueIds.has(uniqueId)) {

                        newData.push({ ...dataProduct?.item });
                        uniqueIds.add(uniqueId);
                    }
                }
            })
            const info = [...selectProduct, ...newData]
            const newInfo: any = []
            info.forEach((item: any) => {
                if (allId.includes(item?.productId)) {
                    newInfo.push(item)
                }
            })
            setSelectProduct(newInfo)
        }
    }, [adjustment, products])

    const handleLastInventoryId = (inventory: any) => {
        let maxItem: any = null;
        let maxInventoryId = -1;
        inventory.forEach((item: any) => {
            let currentId = 0
            if (typeof item?.inventoryId === 'object') {
                currentId = (Math.max(...item?.inventoryId))
            } else {
                currentId = (item?.inventoryId)
            }

            if (currentId > maxInventoryId) {
                maxInventoryId = currentId;
                maxItem = item;
            }
        })
        return maxItem
    }

    const handleSelectProdcutNew = (dataProduct: any) => {
        if (dataProduct && !handleDisbledSelectProduct(dataProduct)) {
            const itemInventory = handleLastInventoryId(dataProduct?.inventory)
            const newData: AdjustmentItemProps[] = [{
                adjustmentReceiptItemId: null,
                inventoryId: itemInventory?.inventoryId || 0,
                qtyCurrent: itemInventory?.qty || 0,
                qtyDiff: itemInventory?.qty > 0 ? 0 - itemInventory?.qty : 0,
                qtyDiffType: itemInventory?.qty >= 1 ? INVENTORY_STATUS.DECREASE : INVENTORY_STATUS.INCREASE,
                detail: { item: dataProduct, itemName: dataProduct?.productName || dataProduct?.productNameEn },
                qtyNew: 0,
                qtyAdd: 0
            }]
            setAdjustment({ ...adjustment, items: [...adjustment.items, ...newData] })
        }
    }

    useEffect(() => {
        setFreeSoloProduct(openFlterProduct)
    }, [openFlterProduct])

    return (
        <div className={`container-card h-100`}>
            <div className={`d-flex flex-column h-100 overflow-hidden`}>
                <HeaderLabel
                    text={`${adjustmentId ? t('ADJUSTMENT.FORM.FORM_EDIT') : t('ADJUSTMENT.FORM.FORM_ADD')}`}
                    navigator={{ previousTo: routeName.adjustment, previousTitle: t('ADJUSTMENT.HEADER'), currentTitle: adjustmentId ? t('ADJUSTMENT.FORM.FORM_EDIT') : t('ADJUSTMENT.FORM.FORM_ADD') }}
                    goBack={routeName.adjustment}
                    disabledSend={adjustmentStatus === APPROVE_STATUS.APPROVE || loading}
                    onSubmit={submit}
                    inputStatus={
                        <Box
                            className={`mr-3 w-100 ${adjustmentStatus === APPROVE_STATUS.APPROVE || adjustmentStatus === APPROVE_STATUS.CANCEL ? 'pe-none' : ''}`}
                            sx={{
                                maxWidth: '105px',
                                '.MuiSelect-select': {
                                    display: 'block !important',
                                    height: 'fit-content !important'
                                }
                            }}>
                            <StatusSelect
                                onchange={(event) => {
                                    const value = event.target.value
                                    if (value) handleChange(value, 'status')
                                }}
                                label={''}
                                selectId="select-status"
                                value={adjustment.status}
                                labelId="label-status"
                                statusSelectorBgColor={backgroundStatus}
                            />
                        </Box>
                    }
                />
                <Card className={`border shadow-sm mx-4 mb-4 h-auto card h-100 overflow-hidden`}>
                    <Card.Body className="h-100 p-3 d-flex flex-column">
                        <Grid container spacing={2} className={` ${adjustmentStatus === APPROVE_STATUS.APPROVE || adjustmentStatus === APPROVE_STATUS.CANCEL ? 'pe-none' : ''}`}>
                            <Grid item sm={6} lg={3} className='w-100'>
                                <InputTextField
                                    required
                                    disabled
                                    label={t('ADJUSTMENT.FORM.BRANCH')}
                                    value={branch?.branchName} />
                            </Grid>
                            <Grid item sm={6} lg={3} className='w-100'>
                                <InputTextField
                                    required
                                    label={t('ADJUSTMENT.FORM.SUBJECT')}
                                    onchange={(e: any) => handleChange(e.target.value, 'subject')}
                                    value={adjustment.subject}
                                    disabled={adjustmentStatus === APPROVE_STATUS.APPROVE || adjustmentStatus === APPROVE_STATUS.CANCEL}
                                    helperText={errorMessage.EMPTY_SUBJECT || ''} />
                            </Grid>
                            <Grid item sm={6} lg={3} className='w-100'>
                                <InputSecondNewDatePicker
                                    required
                                    label={t('ADJUSTMENT.FORM.DATE')}
                                    onchange={(e: any) => handleChange(moment(e).format('YYYY-MM-DD'), 'date')}
                                    value={adjustment?.date || ''}
                                    disabled={adjustmentStatus === APPROVE_STATUS.APPROVE || adjustmentStatus === APPROVE_STATUS.CANCEL}
                                    helperText={errorMessage.EMPTY_DATE || ''} />
                            </Grid>
                            {adjustmentId && (
                                <Grid item lg={3} className='w-100 order-first order-xl-last'>
                                    <Typography className='text-right'>{t('ADJUSTMENT.FORM.NUMBER')} {adjustment?.documentNo ? adjustment.prefix ? `${adjustment.prefix}-${adjustment?.documentNo}` : adjustment?.documentNo : '-'}</Typography>
                                </Grid>
                            ) || <></>}
                        </Grid>
                        {adjustment?.status === APPROVE_STATUS.CANCEL && (
                            <Grid container spacing={2} className="mt-0">
                                <Grid item lg={9} className='w-100'>
                                    <InputTextarea
                                        label={t('ADJUSTMENT.FORM.REMARK')}
                                        onchange={(e: any) => handleChange(e.target.value, 'remark')}
                                        value={adjustment.remark} />
                                </Grid>
                            </Grid>
                        ) || <></>}
                        <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                        <Box className={'h-100 overflow-hidden'} sx={{ display: 'flex' }}>
                            <Box className={'h-100 overflow-hidden d-flex flex-column'} sx={{ minWidth: '350px' }}>
                                <Box>
                                    <ClickAwayListener onClickAway={() => setOpenFlterProduct(false)}>
                                        <Box onClick={() => { if (adjustmentStatus !== APPROVE_STATUS.APPROVE && adjustmentStatus !== APPROVE_STATUS.CANCEL) setOpenFlterProduct(true) }}>
                                            <AutocompleteSelect
                                                open={openFlterProduct}
                                                multiple
                                                limitTags={openFlterProduct ? undefined : 3}
                                                labelId="products"
                                                options={products}
                                                placeholder={!selectProduct?.length && t('INPUT.SEARCH') || ''}
                                                getOptionLabel={(option) => option?.productName || option?.productNameEn || option}
                                                renderOption={(props, option) => {
                                                    return (
                                                        <Box className="text-ellipsis">
                                                            <Box
                                                                {...props}
                                                                key={option?.productId}
                                                                component="li"
                                                                value={option?.productName || option?.productNameEn}
                                                                onClick={() => {
                                                                    if (handleCheckProduct(option)) {
                                                                        const valueProducts = _.filter(adjustment.items, (product, index) => (product?.detail?.item?.productId || product?.detail?.itemId) !== option?.productId)
                                                                        setAdjustment({ ...adjustment, items: valueProducts })
                                                                        setSelectProduct(_.filter(selectProduct, (d) => d?.productId !== option?.productId))
                                                                    } else {
                                                                        setSelectProduct([...selectProduct, option])
                                                                        handleSelectProdcutNew(option)
                                                                    }
                                                                    setFreeSoloProduct(false)
                                                                    setTimeout(() => { setFreeSoloProduct(true) }, 500)
                                                                }}
                                                            >
                                                                <InputCheckbox
                                                                    label={option?.productName}
                                                                    checked={handleCheckProduct(option)}
                                                                    // disabled={handleDisbledSelectProduct(option)}
                                                                    className={'pe-none'}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    )
                                                }}
                                                onOpen={() => loadProducts()}
                                                onClose={() => loadProducts()}
                                                inputProps={{
                                                    onChange: (e: any) => {
                                                        setProducts([])
                                                        setTimeout(() => { loadProducts(e.target.value) })
                                                    },
                                                }}
                                                loading={productsLoading}
                                                noOptionsText={t('NOT_FOUND')}
                                                freeSolo={freeSoloProduct}
                                                // filterOptions={(option) => option?.productName || []}
                                                value={selectProduct}
                                                height={32}
                                                disableClearable
                                                renderTags={(tagValue, getTagProps) =>
                                                    tagValue.map((option: any, index: any) => {
                                                        const label = option?.productName
                                                        return (
                                                            <div style={{ overflow: "hidden" }} key={index} >
                                                                <Chip
                                                                    label={label}
                                                                    {...getTagProps({ index })}
                                                                    // disabled={handleDisbledSelectProduct(option)}
                                                                    onDelete={() => {
                                                                        const valueProducts = _.filter(adjustment.items, (product, index) => (product?.detail?.item?.productId || product?.detail?.itemId) !== option?.productId)
                                                                        setAdjustment({ ...adjustment, items: valueProducts })
                                                                        setSelectProduct(_.filter(selectProduct, (d) => d?.productId !== option?.productId))
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                                disabled={(adjustment.status === APPROVE_STATUS.APPROVE || adjustment.status === APPROVE_STATUS.CANCEL)}
                                            />
                                        </Box>

                                    </ClickAwayListener>
                                </Box>
                                <Box className={'h-100 d-flex flex-column'} sx={{ paddingLeft: '5px', overflow: 'auto' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            color: `${colors.themeMainColor}`,
                                            padding: '24px 0',
                                            fontSize: '18px',
                                            fontWeight: '500'
                                        }}
                                    >
                                        <Box>
                                            {t("ADJUSTMENT.TABLE.LIST_PRODUCT")}
                                        </Box>
                                        <Box sx={{ width: '150px', textAlign: 'left' }}>
                                            {t("ADJUSTMENT.TABLE.TOTAL_PRODUCT")}
                                        </Box>
                                    </Box>
                                    <Box
                                        className={'h-100'}
                                        sx={{

                                            '.card-product:first-child': {
                                                borderTop: '1px solid #E5E5E5',
                                            }
                                        }}
                                    >
                                        {selectProduct.map((item: any, index: number) => {
                                            return (
                                                <Box
                                                    key={index}
                                                    className={'card-product'}
                                                    sx={{
                                                        padding: '12px',
                                                        borderTop: '0px',
                                                        borderLeft: '1px solid #E5E5E5',
                                                        borderBottom: '1px solid #E5E5E5',
                                                        borderRight: '1px solid #E5E5E5',
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <CardDetailProduct
                                                        item={item}
                                                        handleProductAdd={handleProductAdd}
                                                        itemTable={adjustment.items}
                                                        handleProductDel={handleProductDel}
                                                        handleProductAll={handleProductAll}
                                                        statusDisabled={(adjustment.status === APPROVE_STATUS.APPROVE || adjustment.status === APPROVE_STATUS.CANCEL)}
                                                    />
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                            <Divider sx={{ height: 'auto !important', width: '1px', opacity: '0.1' }} />
                            <UseStyled.ResponsiveTableForm
                                type="form"
                                className={` ${adjustmentStatus === APPROVE_STATUS.APPROVE || adjustmentStatus === APPROVE_STATUS.CANCEL ? 'pe-none' : ''}`}
                            >
                                <TableCustom
                                    page={0}
                                    pageLimit={1}
                                    sortType={''}
                                    sortBy={''}
                                    rowCount={0}
                                    onSort={() => { return }}
                                    setPageLimit={() => { return }}
                                    setPage={() => { return }}
                                    headCells={headCells}
                                    rowsData={[
                                        // addNewRow(),
                                        _.map(Object.keys(adjustmentGroup), (groupName: any, no: number) => {
                                            return (
                                                <>
                                                    {adjustmentGroup[groupName].map((product: any, index: number) => {
                                                        return renderData(product, index)
                                                    }).reverse()}
                                                </>
                                            )
                                        }).reverse(),
                                        renderDataSum()
                                    ]}
                                    tableFixedWidth
                                    tableMinWidth={900}
                                    hidePagination
                                />
                            </UseStyled.ResponsiveTableForm>
                        </Box>

                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}

type CardDetailProductProps = {
    item: any
    itemTable: any
    handleProductAdd: (item: any, itemInven: any) => void
    handleProductDel: (itemInven: any) => void
    handleProductAll: (item: any, clear: boolean) => void
    statusDisabled: boolean
}

const CardDetailProduct = (props: CardDetailProductProps) => {
    const { t } = useTranslation()
    const { item, itemTable } = props

    const [isCollapse, setIsCollapse] = useState<boolean>(false)
    const [totalPrice, setTotalPrice] = useState<number>(0)

    const [checked, setChecked] = useState<any>([]);

    const handleChangeCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (checked.length !== item?.inventory?.length) {
            const newCheck = item?.inventory.map((info: any) => {
                return info.inventoryId.toString()
            })
            setChecked(newCheck);
            props.handleProductAll(item, true)
        } else {
            setChecked([]);
            props.handleProductAll(item, false)
        }
    };

    const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>, info: any) => {
        if (event.target.checked) {
            setChecked([...checked, event.target.value.toString()]);
            props.handleProductAdd(item, info)
        } else {
            const newCheck = checked.filter((info: any) => {
                return info !== event.target.value.toString()
            })
            setChecked(newCheck)
            props.handleProductDel(info)
        }
    };

    const handleItem = useCallback(() => {
        let total = 0
        item?.inventory?.forEach((info: any) => {
            total += info?.qty || 0
        })
        setTotalPrice(total)

        const inventoryIdChecked: any = []
        for (const index in itemTable) {
            const dataTable = itemTable[index]
            if ((dataTable?.detail?.item?.productId || dataTable?.detail?.itemId) === item?.productId && dataTable?.inventoryId) {
                inventoryIdChecked.push(dataTable?.inventoryId.toString())
            }
        }

        setChecked(inventoryIdChecked)

    }, [item, itemTable])

    useEffect(() => {
        handleItem()
    }, [handleItem])

    useEffect(() => {
        setIsCollapse(false)
    }, [item])

    return (
        <>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <Box>
                    <FormControlLabel
                        label={item.productName}
                        control={
                            <InputCheckbox
                                checked={checked.length === item?.inventory?.length}
                                indeterminate={checked?.length ? checked.length !== item?.inventory?.length : false}
                                onChange={handleChangeCheckAll}

                            />
                        }
                        disabled={props.statusDisabled}
                    />
                </Box>
                <Box sx={{
                    maxWidth: '120px',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px'
                }}>
                    <Box>
                        <InputTextField
                            value={totalPrice || 0}
                            disabled
                        />
                    </Box>
                    <Box
                        onClick={() => setIsCollapse(!isCollapse)}
                        sx={{
                            width: '24px',
                            height: '24px',
                            border: '1px solid #5E5E5E',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            transform: isCollapse ? 'rotate(180deg)' : ''
                        }}
                    >
                        <ExpandMoreIcon className={'expand-more'} sx={{ color: colors.themeMainColor }} />
                    </Box>
                </Box>

            </Box>
            <Box>

            </Box>

            <Collapse in={isCollapse}>
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: '16px' }}>
                    {item?.inventory?.map((info: any, index: number) => {
                        return (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                                title={`${item?.productName} ${info?.lotNo || t('ADJUSTMENT.TABLE.LOT_EMPTY')}`}
                            >

                                <FormControlLabel
                                    label={(
                                        <Box className={'text-ellipsis'}  >
                                            {item?.productName}
                                        </Box>
                                    )}
                                    control={
                                        <InputCheckbox
                                            checked={checked.includes(info.inventoryId.toString())}
                                            onChange={(event) => handleChangeCheck(event, info)}
                                            value={info.inventoryId}

                                        />
                                    }
                                    disabled={props.statusDisabled}
                                />
                                <Box className={'text-ellipsis'} sx={{ width: '150px', textAlign: 'left' }}>
                                    {info?.lotNo || t('ADJUSTMENT.TABLE.LOT_EMPTY')}
                                </Box>
                            </Box>
                        )
                    })
                    }
                </Box>
            </Collapse>


        </>
    )
}
