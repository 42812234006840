import { useState, useEffect, useCallback } from 'react'
import { Grid, Typography, Box, MenuItem } from '@mui/material'
import 'react-multi-carousel/lib/styles.css'
import _ from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import { fileUrl } from 'api/api'
import ClinicApi, { DentistInterface } from 'api/master/clinic.api'
import TreatmentApi from 'api/dentists/treatment.api'
import BranchApi from 'api/setting/branch-service-points/branch.api'
import PrefixApi from 'api/setting/default-users/prefix.api'
import PersonnelApi from 'api/setting/personnel-managements/personnel.api'

/** UTILS || SLICE */
import { closeModal, resetModal } from 'app/slice/modal.slice'
import useWindowSize from 'utils/useWindowSize'

/** COMPONENT */
import BasicSelect from 'component/Select/BasicSelect'
import InputCheckbox from 'component/Input/InputCheckbox'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import Loading from 'component/Loading'
import InputTextField from 'component/Input/InputTextField'
import { selectMe } from 'app/slice/user.slice'




type ModalCustomProps = {
  isShow: boolean | false
  setIsShow: (show: boolean) => void
  onClose?: () => void
  patientId: number
  branchId: number
  patientCaseId?: number
  createdAt?: string
  treatments?: any
  diagnosis?: any
  patient?: any
  user?: any
  type?: 'treatment' | 'historyTreatment' | 'register'
  hidePhoneNumber?: boolean
}

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}

export default function SSO(props: ModalCustomProps) {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  // const [doctors, setDoctors] = useState<Doctors[]>([])
  const [errorDoctorSelect, setErrorDoctorSelect] = useState('')

  const [loading, setLoading] = useState(false)
  const [width] = useWindowSize()
  const user = useSelector(selectMe)
  /** FILTER */
  const [doctor, setDoctor] = useState<number>(0)
  const [date, setDate] = useState<string>(moment().format('YYYY-MM-DD'))
  const [disableDoctor, setDisableDoctor] = useState(false)

  const [doctorName, setDoctorName] = useState<any>('')

  const [branch, setBranch] = useState<any>()
  const [limit, setLimit] = useState<any>()
  const [service1, setService1] = useState<any>()
  const [service2, setService2] = useState<any>()
  const [service3, setService3] = useState<any>()
  const [service4, setService4] = useState<any>()
  const [price, setPrice] = useState<any>()
  const [useLimit, setUseLimit] = useState<any>()
  const [diffPaid, setDiffPaid] = useState<any>()
  const [checkboxService1, setCheckboxService1] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
  const [checkboxService2, setCheckboxService2] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
  const [checkboxService3, setCheckboxService3] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
  const [checkboxService4, setCheckboxService4] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
  const [showPatientSignature, setShowPatientSignature] = useState<'CHECK' | 'UNCHECK'>('CHECK')
  const [showDoctorSignature, setShowDoctorSignature] = useState<'CHECK' | 'UNCHECK'>('CHECK')
  const [phone, setPhone] = useState<any>()
  const [cityzenId, setCityzenId] = useState<any>()
  const [prefixPatient, setPrefixPatient] = useState<any>('')
  const [doctorInfo, setDoctorInfo] = useState<any>('')
  const [doctors, setDoctors] = useState<DentistInterface[]>([])

  const loadBranch = async () => {
    const res = await BranchApi.findById(props.branchId)
    if (res.status === 200) setBranch(res.data)
  }

  const loadDoctor = async () => {
    const resp = await ClinicApi.findAllDoctors()
    if (resp.status === 200) {
      const newData = resp.data.map((item: any) => {
        return {
          dentistId: item.userId,
          dentistFullname: item.fullname
        }
      })
      setDoctors(newData)
    }
  }

  const loadPrefixes = async () => {
    const res = await PrefixApi.findById(props.patient?.prefixId)
    setPrefixPatient(res.data)
  }
  const loadPrefixesDoc = async () => {
    const res = await PersonnelApi.findById(doctor)
    if (res.status === 200) setDoctorInfo(res.data?.prefix)
  }

  const loadData = useCallback(async () => {
    setLoading(false)
    const doctorsResp = await ClinicApi.findAllDentists()
    if (doctorsResp.status === 200) {
      const allDoctors: any = []

      if (props.type === 'historyTreatment') {
        _.map(props.diagnosis, (i: any) => { if (allDoctors.indexOf(i.doctorId) === -1) allDoctors.push(i.doctorId) })
        _.map(props.treatments, (i: any, index: number) => {
          if (i.orderTreatmentId) {
            if (allDoctors.indexOf(i.doctorId) === -1)
              allDoctors.push(i.doctorId)
          }
        })
      }
      else {
        _.map(props.treatments.patientDiagnosis, (i: any) => { if (allDoctors.indexOf(i.doctorId) === -1) allDoctors.push(i.doctorId) })
        _.map(props.treatments.summeryTreatments, (i: any, index: number) => {
          if (i.tempOrderTreatmentId) {
            if (allDoctors.indexOf(i.doctorId) === -1)
              allDoctors.push(i.doctorId)
          }
        })
      }
      if (props.type === 'register') {
        loadDoctor()
      }
      setDoctors(_.filter(doctorsResp.data, (i: any, index: number) => allDoctors.indexOf(i.dentistId) !== -1))
    }
  }, [props.treatments, props.diagnosis])


  useEffect(() => {
    loadData()
    loadBranch()
    loadPrefixes()
    formatCityzenId()
    formatPhone()
  }, [])

  // console.log(branch)
  useEffect(() => {
    const obj = doctors.filter((obj) => obj.dentistId === doctor)
    setDoctorName(obj[0]?.dentistFullname || '')
    loadPrefixesDoc()
  }, [doctor])

  const formatCityzenId = () => {
    const id = String(props.patient?.idCard)
    const formatId = `${id.substring(0, 5)} ${id.substring(5, 8)} ${id.substring(8, 10)} ${id.substring(10, 12)} ${id.substring(12, 13)}`
    console.log(formatId)
    setCityzenId(formatId)
  }
  const formatPhone = () => {
    const phone = String(props.patient?.phone)
    const formatPhone = `${phone.substring(0, 3)} ${phone.substring(3, 6)} ${phone.substring(6, 10)}`
    setPhone(formatPhone)
  }
  const onChangeValue = (e: any) => {
    const val = e.target.value === '' ? null : String(e.target.value).replace(/[^0-9.]/g, '')
    setLimit(val)
  }
  const onChangeService1 = (e: any) => {
    const val = e.target.value === '' ? null : String(e.target.value).replace(/[^0-9.]/g, '')
    setService1(val)
  }
  const onChangeService2 = (e: any) => {
    const val = e.target.value === '' ? null : String(e.target.value).replace(/[^0-9.]/g, '')
    setService2(val)
  }
  const onChangeService3 = (e: any) => {
    const val = e.target.value === '' ? null : String(e.target.value).replace(/[^0-9.]/g, '')
    setService3(val)
  }
  const onChangeServiceScaling4 = (e: any) => {
    const val = e.target.value === '' ? null : String(e.target.value).replace(/[^0-9.]/g, '')
    setService4(val)
  }
  const onChangePirce = (e: any) => {
    const val = e.target.value === '' ? null : String(e.target.value).replace(/[^0-9.]/g, '')
    setPrice(val)
  }
  const onChangeUseLimit = (e: any) => {
    const val = e.target.value === '' ? null : String(e.target.value).replace(/[^0-9.]/g, '')
    setUseLimit(val)
  }
  const onChangeDiffPaid = (e: any) => {
    const val = e.target.value === '' ? null : String(e.target.value).replace(/[^0-9.]/g, '')
    setDiffPaid(val)
  }


  const onPrint = async () => {
    if (!doctor && !disableDoctor) {
      setLoading(false)
      return setErrorDoctorSelect(t('MODAL_MED_CERTIFICATE.NOT_SELECT_DOCTOR'))
    }
    setLoading(true)
    const print = await TreatmentApi.printMedicalSSO(props.patientId, {
      date,
      branchId: props.branchId,
      doctorId: doctor,
      balance: limit,
      isTootExtraction: checkboxService1 === 'CHECK' ? 1 : 0,
      tootExtractioNumber: service1,
      isFillTeeth: checkboxService2 === 'CHECK' ? 1 : 0,
      fillTeethNumber: service2,
      isWisdomTeeth: checkboxService3 === 'CHECK' ? 1 : 0,
      wisdomTeethNumber: service3,
      isScalingTeeth: checkboxService4 === 'CHECK' ? 1 : 0,
      scalingTeethNumber: service4,
      amount: price,
      amountAgree: useLimit,
      amountExcess: diffPaid,
      isPatientSignature: showPatientSignature === 'CHECK' ? 1 : 0,
      isDoctorSignature: showDoctorSignature === 'CHECK' ? 1 : 0,
      lang: i18n?.language || 'th'
    }).finally(() => setLoading(false))

    if (print.status === 200) {
      window.open(`${fileUrl}/${print.data}`, '_blank')
      clearData()
      props.setIsShow(false)
      dispatch(closeModal())
    }
  }

  const clearData = () => {
    setDoctor(0)
    setDate(moment().format('YYYY-MM-DD'))
    setDisableDoctor(false)
    setLimit('')
    setService1('')
    setService2('')
    setService3('')
    setService4('')
    setPrice('')
    setUseLimit('')
    setDiffPaid('')
    setCheckboxService1('UNCHECK')
    setCheckboxService2('UNCHECK')
    setCheckboxService3('UNCHECK')
    setCheckboxService4('UNCHECK')
    setShowPatientSignature('UNCHECK')
    setShowDoctorSignature('UNCHECK')
  }

  const sendOutClinic = () => {
    setDisableDoctor(true)
    setDoctor(0)
  }

  const handleCityzenId = (cityzenId: string) => {
    if (cityzenId && !cityzenId.split(' ')?.includes("null")) {
      return cityzenId
    } else {
      return ''
    }
  }

  return (
    <ModalCustom
      title={t('SSO.TITLE')}
      alignFooter={'end'}
      size={'xl'}
      footerInline
      onClose={props.onClose}
      onSubmit={onPrint}
      onReset={() => {
        dispatch(resetModal())
        props.setIsShow(false)
      }}
      textBtnCancel={t('BUTTON.CANCEL')}
      textBtnConfirm={t('BUTTON.PRINT')}
      modalStyle={'modal-referral-examination'}
      component={
        width >= 1200 && (
          <>
            <Grid container spacing={2} className="pt-2">
              <Grid item xs={6} className="w-100">
                <Box className="d-flex align-items-center">
                  <Typography className="mr-3" sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>
                    {t('SSO.NAME_MEDICAL_FACILITY')} {branch?.hospitalName || ''}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} className="w-100">
                <Box className="d-flex align-items-center">
                  <Typography className="mr-3" sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>
                    {t('SSO.ID_MEDICAL_FACILITY')} {branch?.hospitalCode || ''}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} className="w-100">
                <InputSecondNewDatePicker required key={'date'} label={t('SSO.DATE')} value={date} onchange={(e: any) => setDate(moment(e).format('YYYY-MM-DD'))} />
              </Grid>
              <Grid item xs={6} className="w-100">
                <BasicSelect
                  required
                  name="doctorId"
                  labelId="lb-dentist"
                  selectId="sl-dentist"
                  label={t('SSO.DOCTOR')}
                  value={doctor || null}
                  options={
                    _.map(doctors, (doctorData: DentistInterface, i: number) => (
                      <MenuItem key={i} value={doctorData.dentistId}>
                        {doctorData.dentistFullname}
                      </MenuItem>
                    ))
                  }
                  onchange={(event) => {
                    setDoctor(event.target.value)
                    setErrorDoctorSelect('')
                  }}
                  classesOption="style-select-doctors-mb"
                  disabled={disableDoctor}
                  helperText={errorDoctorSelect || ''}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="pt-2">
              <Grid item xs={5} className="w-100">
                <Box className="d-flex align-items-center">
                  <Typography className="mr-3" sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>
                    {t('SSO.PATIENT_NAME')} {`${prefixPatient.prefixName}${props.patient?.firstname} ${props.patient?.lastname}`}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} className="w-100">
                <Box className="d-flex align-items-center">
                  <Typography className="ml-5" sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>
                    {t('SSO.PATIENT_CITYZEN_ID')} {handleCityzenId(cityzenId)}
                  </Typography>
                </Box>
              </Grid>
              {!props.hidePhoneNumber && <Grid item xs={3} className="w-100">
                <Box className="d-flex align-items-center">
                  <Typography className="ml-5" sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>
                    {t('SSO.PATIENT_PHONE_NUMBER')} {phone}
                  </Typography>
                </Box>
              </Grid>}
              <Grid item className="w-100">
                <Box className="d-flex align-items-center">
                  <Typography className="" sx={{ fontWeight: 500 }} style={{ lineHeight: '200%' }}>
                    {t('SSO.SERVICE_BALANCE')} <InputTextField style={{ width: '100px', marginRight: '8px', position: 'relative', top: '-5px' }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onchange={onChangeValue} value={limit} inputTextAlign={'right'} />{t('SSO.CURRENTCY')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid>
              <div className="pt-2 pl-1">
                <Typography className="" style={{ marginLeft: '-3px' }} sx={{ fontWeight: 500 }}>
                  {t('SSO.SERVICE_USE_IN')}
                </Typography>
                <div className="d-flex" style={{ width: '100%' }}>
                  <div style={{ width: '30%' }}>
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <InputCheckbox
                        label={t('SSO.SERVICE_1')}
                        className="hover-none-bg"
                        checked={checkboxService1 === 'CHECK'}
                        onChange={(e) => {
                          setCheckboxService1(e.target.checked ? 'CHECK' : 'UNCHECK')
                          if (checkboxService1 === 'UNCHECK') setService1('')
                        }}
                        value={String(checkboxService1)}
                      />
                    </div>
                    <div style={{ whiteSpace: 'nowrap', display: 'flex', alignContent: 'left' }}>
                      <InputCheckbox
                        label={t('SSO.SERVICE_2')}
                        className="hover-none-bg"
                        checked={checkboxService2 === 'CHECK'}
                        onChange={(e) => {
                          setCheckboxService2(e.target.checked ? 'CHECK' : 'UNCHECK')
                          if (checkboxService2 === 'UNCHECK') setService2('')
                        }}
                        value={String(checkboxService2)}
                      />
                    </div>
                    <div style={{ whiteSpace: 'nowrap', marginTop: '5px' }}>
                      <InputCheckbox
                        label={t('SSO.SERVICE_3')}
                        className="hover-none-bg"
                        checked={checkboxService3 === 'CHECK'}
                        onChange={(e) => {
                          setCheckboxService3(e.target.checked ? 'CHECK' : 'UNCHECK')
                          if (checkboxService3 === 'UNCHECK') setService3('')
                        }}
                        value={String(checkboxService3)}
                      />
                    </div>
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <InputCheckbox
                        label={t('SSO.SERVICE_4')}
                        className="hover-none-bg"
                        checked={checkboxService4 === 'CHECK'}
                        onChange={(e) => {
                          setCheckboxService4(e.target.checked ? 'CHECK' : 'UNCHECK')
                          if (checkboxService4 === 'UNCHECK') setService4('')
                        }}
                        value={String(checkboxService4)}
                      />
                    </div>
                  </div>
                  <div style={{ width: '100%' }}>
                    <Grid item className="w-100" style={{ display: 'flex', alignContent: 'left', visibility: checkboxService1 === 'CHECK' ? 'visible' : 'hidden' }}>
                      <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 0px' }}>
                        {t('SSO.QTY')}
                      </Typography>
                      <InputTextField style={{ width: '100px' }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onchange={onChangeService1} value={service1} inputTextAlign={'right'} />
                      <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 5px' }}>
                        {t('SSO.TEETH')}
                      </Typography>
                    </Grid>
                    <Grid item className="w-100" style={{ display: 'flex', alignContent: 'left', visibility: checkboxService2 === 'CHECK' ? 'visible' : 'hidden' }}>
                      <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 0px' }}>
                        {t('SSO.QTY')}
                      </Typography>
                      <InputTextField style={{ width: '100px' }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onchange={onChangeService2} value={service2} inputTextAlign={'right'} />
                      <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 5px' }}>
                        {t('SSO.TEETH')}
                      </Typography>
                    </Grid>
                    <Grid item className="w-100" style={{ display: 'flex', alignContent: 'left', visibility: checkboxService3 === 'CHECK' ? 'visible' : 'hidden' }}>
                      <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 0px' }}>
                        {t('SSO.QTY')}
                      </Typography>
                      <InputTextField style={{ width: '100px' }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onchange={onChangeService3} value={service3} inputTextAlign={'right'} />
                      <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 5px' }}>
                        {t('SSO.TEETH')}
                      </Typography>
                    </Grid>

                    {/* <Grid item className="w-100" style={{ display: 'flex', alignContent: 'left', visibility: checkboxService4 === 'CHECK' ? 'visible' : 'hidden' }}>
                      <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 0px' }}>
                        {t('SSO.QTY')}
                      </Typography>
                      <InputTextField style={{ width: '100px' }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onchange={onChangeServiceScaling4} value={service4} inputTextAlign={'right'} />
                      <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 5px' }}>
                        {t('SSO.TEETH')}
                      </Typography>
                    </Grid> */}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item className="w-100">
              <Grid item className="w-100" style={{ display: 'flex', alignContent: 'left' }}>
                <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 0px' }}>
                  {t('SSO.TOTAL')}
                </Typography>
                <InputTextField style={{ width: '100px' }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onchange={onChangePirce} value={price} inputTextAlign={'right'} />
                <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 5px' }}>
                  {t('SSO.CURRENTCY')}
                </Typography>
                <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 5px' }}>
                  {t('SSO.AGREE_CLINIC')}
                </Typography>
              </Grid>
              <Grid item className="w-100" style={{ display: 'flex', alignContent: 'left' }}>
                <InputTextField style={{ width: '100px' }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onchange={onChangeUseLimit} value={useLimit} inputTextAlign={'right'} />
                <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 5px' }}>
                  {t('SSO.CURRENTCY')}
                </Typography>
                <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 0px' }}>
                  {t('SSO.PAID')}
                </Typography>
                <InputTextField style={{ width: '100px' }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onchange={onChangeDiffPaid} value={diffPaid} inputTextAlign={'right'} />
                <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 5px' }}>
                  {t('SSO.CURRENTCY')}
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ borderBottom: '1px solid lightgray', paddingBottom: '30px' }}>
              <Grid style={{ whiteSpace: 'nowrap' }}>
                <InputCheckbox
                  label={t('SSO.NOT_SHOW_SIGNATURE_PATIENT')}
                  className="hover-none-bg"
                  checked={showPatientSignature === 'CHECK'}
                  onChange={(e) => setShowPatientSignature(e.target.checked ? 'CHECK' : 'UNCHECK')}
                  value={String(showPatientSignature)}
                />
                {/* visibility: showPatientSignature === 'CHECK' ? 'visible' : 'hidden' */}
                <Grid style={{ justifyContent: 'center', display: 'flex', }}>
                  <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', display: 'flex', textAlign: 'left', marginLeft: '-20px' }}>
                    {t('SSO.SIGNATURE_PATIENT')}
                  </Typography>
                </Grid>
                {/* visibility: showPatientSignature === 'CHECK' ? 'visible' : 'hidden'  */}
                <Grid style={{ justifyContent: 'center', display: 'flex' }}>
                  <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', display: 'flex', textAlign: 'left', marginLeft: '-20px', marginBottom: '-15px' }}>
                    {`${prefixPatient.prefixName}${props.patient?.firstname} ${props.patient?.lastname}`}
                  </Typography>
                </Grid>

              </Grid>
              <Grid style={{ whiteSpace: 'nowrap' }}>
                <InputCheckbox
                  label={t('SSO.NOT_SHOW_SIGNATURE_DOCTOR')}
                  className="hover-none-bg"
                  checked={showDoctorSignature === 'CHECK'}
                  onChange={(e) => setShowDoctorSignature(e.target.checked ? 'CHECK' : 'UNCHECK')}
                  value={String(showDoctorSignature)}
                />
                {/* , visibility: showDoctorSignature === 'CHECK' ? 'visible' : 'hidden' */}
                <Grid style={{ justifyContent: 'center', display: 'flex' }}>
                  <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', display: 'flex', textAlign: 'left' }}>
                    {t('SSO.SIGNATURE_DOCTOR')}
                  </Typography>
                </Grid>
                {/*  visibility: showDoctorSignature === 'CHECK' ? 'visible' : 'hidden' */}
                <Grid style={{ justifyContent: 'center', display: 'flex' }}>
                  <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', display: 'flex', textAlign: 'left', marginLeft: '-20px', marginBottom: '-15px' }}>
                    {`${doctorInfo}${doctorName}`}
                  </Typography>
                </Grid>

              </Grid>
            </Grid>
            <Grid style={{ textIndent: '50px', marginTop: '10px' }}>
              <Typography sx={{ fontWeight: 500 }} style={{}}>
                {t('SSO.GUARANTEE')}
              </Typography>
            </Grid>

            {loading && <Loading show={true} type="fullLoading" />}
          </>
        ) || (
          <>
            <Grid container spacing={2} className="pt-2">
              <Grid item xs={6} className="w-100">
                <Box className="d-flex align-items-center">
                  <Typography className="mr-3" sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>
                    {t('SSO.NAME_MEDICAL_FACILITY')} {branch?.hospitalName || ''}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} className="w-100">
                <Box className="d-flex align-items-center">
                  <Typography className="mr-3" sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>
                    {t('SSO.ID_MEDICAL_FACILITY')} {branch?.hospitalCode || ''}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} className="w-100">
                <InputSecondNewDatePicker required key={'date'} label={t('SSO.DATE')} value={date} onchange={(e: any) => setDate(moment(e).format('YYYY-MM-DD'))} />
              </Grid>
              <Grid item xs={6} className="w-100">
                <BasicSelect
                  required
                  name="doctorId"
                  labelId="lb-dentist"
                  selectId="sl-dentist"
                  label={t('SSO.DOCTOR')}
                  value={doctor || null}
                  options={
                    _.map(doctors, (doctorData: DentistInterface, i: number) => (
                      <MenuItem key={i} value={doctorData.dentistId}>
                        {doctorData.dentistFullname}
                      </MenuItem>
                    ))
                  }


                  onchange={(event) => {
                    setDoctor(event.target.value)
                    setErrorDoctorSelect('')
                  }}
                  classesOption="style-select-doctors-mb"
                  disabled={disableDoctor}
                  helperText={errorDoctorSelect || ''}
                />
              </Grid>
            </Grid>
            <Grid item className="w-100">
              <Box className="d-flex align-items-center">
                <Typography className="mr-3" sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>
                  ๑.{t('SSO.PATIENT_NAME')} {`${prefixPatient.prefixName}${props.patient?.firstname} ${props.patient?.lastname}`}
                </Typography>
              </Box>
            </Grid>
            <Grid item className="w-100">
              <Box className="d-flex align-items-start">
                <Typography className="ml-3" sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>
                  {t('SSO.PATIENT_CITYZEN_ID')} {cityzenId}
                </Typography>
              </Box>
            </Grid>
            <Grid item className="w-100">
              <Box className="d-flex align-items-start">
                <Typography className="ml-3" sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>
                  {t('SSO.PATIENT_PHONE_NUMBER')} {phone}
                </Typography>
              </Box>
            </Grid>
            <Grid item className="w-100">
              <Box className="d-flex align-items-center">
                <Typography className="" sx={{ fontWeight: 500 }} style={{ lineHeight: '200%' }}>
                  ๒.{t('SSO.SERVICE_BALANCE')}
                </Typography>
              </Box>
            </Grid>
            <Grid item className="w-100" style={{ height: '0px', width: '100px', justifyContent: 'start', marginTop: '25px', display: 'flex', padding: '10px' }}>
              <InputTextField style={{ width: '100px', marginTop: '-37px', marginLeft: '0px' }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onchange={onChangeValue} value={limit} inputTextAlign={'right'} />
              <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', marginTop: '-25px', marginLeft: '5px' }}>
                {t('SSO.CURRENTCY')}
              </Typography>
            </Grid>

            <Grid>
              <div className="pt-2 pl-1">
                <Typography className="" style={{ marginLeft: '-3px' }} sx={{ fontWeight: 500 }}>
                  ๓.{t('SSO.SERVICE_USE_IN')}
                </Typography>
                <div className="d-flex" style={{ width: '100%' }}>
                  <div style={{ width: '30%' }}>
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <InputCheckbox
                        label={t('SSO.SERVICE_1')}
                        className="hover-none-bg"
                        checked={checkboxService1 === 'CHECK'}
                        onChange={(e) => {
                          setCheckboxService1(e.target.checked ? 'CHECK' : 'UNCHECK')
                          if (checkboxService1 === 'UNCHECK') setService1('')
                        }}
                        value={String(checkboxService1)}
                      />
                    </div>
                    <div style={{ whiteSpace: 'nowrap', display: 'flex', alignContent: 'left' }}>
                      <InputCheckbox
                        label={t('SSO.SERVICE_2')}
                        className="hover-none-bg"
                        checked={checkboxService2 === 'CHECK'}
                        onChange={(e) => {
                          setCheckboxService2(e.target.checked ? 'CHECK' : 'UNCHECK')
                          if (checkboxService2 === 'UNCHECK') setService2('')
                        }}
                        value={String(checkboxService2)}
                      />
                    </div>
                    <div style={{ whiteSpace: 'nowrap', marginTop: '5px' }}>
                      <InputCheckbox
                        label={t('SSO.SERVICE_3')}
                        className="hover-none-bg"
                        checked={checkboxService3 === 'CHECK'}
                        onChange={(e) => {
                          setCheckboxService3(e.target.checked ? 'CHECK' : 'UNCHECK')
                          if (checkboxService3 === 'UNCHECK') setService3('')
                        }}
                        value={String(checkboxService3)}
                      />
                    </div>

                    <div style={{ whiteSpace: 'nowrap', marginTop: '5px' }}>
                      <InputCheckbox
                        label={t('SSO.SERVICE_4')}
                        className="hover-none-bg"
                        checked={checkboxService4 === 'CHECK'}
                        onChange={(e) => {
                          setCheckboxService3(e.target.checked ? 'CHECK' : 'UNCHECK')
                          if (checkboxService4 === 'UNCHECK') setService3('')
                        }}
                        value={String(checkboxService4)}
                      />
                    </div>

                  </div>
                  <div style={{ width: '100%' }}>
                    <Grid item className="w-100" style={{ display: 'flex', alignContent: 'left', visibility: checkboxService1 === 'CHECK' ? 'visible' : 'hidden' }}>
                      <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 0px' }}>
                        {t('SSO.QTY')}
                      </Typography>
                      <InputTextField style={{ width: '100px' }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onchange={onChangeService1} value={service1} inputTextAlign={'right'} />
                      <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 5px' }}>
                        {t('SSO.TEETH')}
                      </Typography>
                    </Grid>
                    <Grid item className="w-100" style={{ display: 'flex', alignContent: 'left', visibility: checkboxService2 === 'CHECK' ? 'visible' : 'hidden' }}>
                      <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 0px' }}>
                        {t('SSO.QTY')}
                      </Typography>
                      <InputTextField style={{ width: '100px' }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onchange={onChangeService2} value={service2} inputTextAlign={'right'} />
                      <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 5px' }}>
                        {t('SSO.TEETH')}
                      </Typography>
                    </Grid>
                    <Grid item className="w-100" style={{ display: 'flex', alignContent: 'left', visibility: checkboxService3 === 'CHECK' ? 'visible' : 'hidden' }}>
                      <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 0px' }}>
                        {t('SSO.QTY')}
                      </Typography>
                      <InputTextField style={{ width: '100px' }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onchange={onChangeService3} value={service3} inputTextAlign={'right'} />
                      <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 5px' }}>
                        {t('SSO.TEETH')}
                      </Typography>
                    </Grid>

                    {/* <Grid item className="w-100" style={{ display: 'flex', alignContent: 'left', visibility: checkboxService4 === 'CHECK' ? 'visible' : 'hidden' }}>
                      <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 0px' }}>
                        {t('SSO.QTY')}
                      </Typography>
                      <InputTextField style={{ width: '100px' }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onchange={onChangeServiceScaling4} value={service4} inputTextAlign={'right'} />
                      <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 5px' }}>
                    {t('SSO.TEETH')}
                      </Typography>
                    </Grid> */}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item className="w-100">
              <Grid item className="w-100" style={{ display: 'flex', alignContent: 'left' }}>
                <Box className="d-flex align-items-center">
                  <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 3px' }}>
                    {t('SSO.TOTAL')}
                  </Typography>
                </Box>
                <Box className="d-flex align-items-center">
                  <InputTextField style={{ width: '100px' }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onchange={onChangePirce} value={price} inputTextAlign={'right'} />
                  <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 5px' }}>
                    {t('SSO.CURRENTCY')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item className="w-100" style={{ display: 'flex', alignContent: 'left' }}>
                <Box className="d-flex align-items-center">
                  <Typography sx={{ fontWeight: 500 }} style={{ margin: '7px 10px 0 5px', lineHeight: '200%' }}>
                    {t('SSO.AGREE_CLINIC')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item className="w-100" style={{ display: 'flex', alignContent: 'left' }}>
                <Box className="d-flex align-items-center" style={{ marginLeft: '150px', marginTop: '-35px' }}>
                  <InputTextField style={{ width: '100px' }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onchange={onChangeUseLimit} value={useLimit} inputTextAlign={'right'} />
                  <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 5px' }}>
                    {t('SSO.CURRENTCY')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item className="w-100" style={{ display: 'flex', alignContent: 'left' }}>
                <Box className="d-flex align-items-center">
                  <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 0px' }}>
                    {t('SSO.PAID')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item className="w-100" style={{ display: 'flex', alignContent: 'left' }}>
                <Box className="d-flex align-items-center">
                  <InputTextField style={{ width: '100px' }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onchange={onChangeDiffPaid} value={diffPaid} inputTextAlign={'right'} />
                  <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', margin: '7px 10px 0 5px' }}>
                    {t('SSO.CURRENTCY')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid style={{ borderBottom: '1px solid lightgray', paddingBottom: '30px' }}>
              <Grid style={{ whiteSpace: 'nowrap' }}>
                <InputCheckbox
                  label={t('SSO.NOT_SHOW_SIGNATURE_PATIENT')}
                  className="hover-none-bg"
                  checked={showPatientSignature === 'CHECK'}
                  onChange={(e) => setShowPatientSignature(e.target.checked ? 'CHECK' : 'UNCHECK')}
                  value={String(showPatientSignature)}
                />
                {/* visibility: showPatientSignature === 'CHECK' ? 'visible' : 'hidden' */}
                <Grid style={{ justifyContent: 'center', display: 'flex' }}>
                  <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', display: 'flex', textAlign: 'left', marginLeft: '-20px' }}>
                    {t('SSO.SIGNATURE_PATIENT')}
                  </Typography>
                </Grid>
                {/* visibility: showPatientSignature === 'CHECK' ? 'visible' : 'hidden' */}
                <Grid style={{ justifyContent: 'center', display: 'flex' }}>
                  <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', display: 'flex', textAlign: 'left', marginLeft: '-20px', marginBottom: '-15px' }}>
                    {`${prefixPatient.prefixName}${props.patient?.firstname} ${props.patient?.lastname} `}
                  </Typography>
                </Grid>
              </Grid>
              <Grid style={{ whiteSpace: 'nowrap' }}>
                <InputCheckbox
                  label={t('SSO.NOT_SHOW_SIGNATURE_DOCTOR')}
                  className="hover-none-bg"
                  checked={showDoctorSignature === 'CHECK'}
                  onChange={(e) => setShowDoctorSignature(e.target.checked ? 'CHECK' : 'UNCHECK')}
                  value={String(showDoctorSignature)}
                />
                {/* , visibility: showDoctorSignature === 'CHECK' ? 'visible' : 'hidden' */}
                <Grid style={{ justifyContent: 'center', display: 'flex' }}>
                  <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', display: 'flex', textAlign: 'left' }}>
                    {t('SSO.SIGNATURE_DOCTOR')}
                  </Typography>
                </Grid>
                {/* visibility: showDoctorSignature === 'CHECK' ? 'visible' : 'hidden'  */}
                <Grid style={{ justifyContent: 'center', display: 'flex' }}>
                  <Typography sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap', display: 'flex', textAlign: 'left', marginLeft: '-20px', marginBottom: '-15px' }}>
                    {`${doctorInfo}${doctorName}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{ textIndent: '50px', marginTop: '10px' }}>
              <Typography sx={{ fontWeight: 500 }} style={{}}>
                {t('SSO.GUARANTEE')}
              </Typography>
            </Grid>

            {loading && <Loading show={true} type="fullLoading" />}
          </>
        )
      }
    />
  )
}
