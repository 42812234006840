import { menuSeconds, menuInside } from 'constants/menus'

/** COMPONENT */
import TreatmentsQueue from 'features/treatments/Queue/TreatmentsQueue'
import Treatments from 'features/treatments/treatmentsIndex'
import AppointmentHistory from 'features/treatments/AppointmentHistory'
import EmrHistory from 'features/treatments/EmrHistory'
import HistoryTreatments from 'features/historyTreatments/historyTreatments'

const routeTreatments = [
  {
    ...menuSeconds.DOCTOR_TREATMENTS,
    component: TreatmentsQueue,
    appBar: true
  },
  {
    ...menuInside.DOCTOR_TREATMENTS_FORM,
    component: Treatments,
    appBar: true
  },
  {
    ...menuInside.DOCTOR_APPOINTMENTS_HISTORY,
    component: AppointmentHistory,
    appBar: true
  },
  {
    ...menuInside.DOCTOR_EMR_HISTORY,
    component: EmrHistory,
    appBar: true
  },
  {
    ...menuSeconds.TREATMENT_HISTORY,
    component: HistoryTreatments,
    appBar: true
  }
]

export default routeTreatments
