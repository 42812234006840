import BaseAPI from "api/api"

export interface FindAllOverdueProps {
    page?: number
    pageLimit?: number
    sortType?: number
    sortBy?: string
    search?: string
    dateStart?: string
    dateEnd?: string
}

export interface AdvanceListInterface {
    transectionAdvanceId: number
    branchId: number
    prefix: string
    advanceNumber: string
    advanceType: 'INCREASE' | 'DECREASE' | 'MODIFY_INCREASE' | 'MODIFY_DECREASE'
    patientId: number
    patient: any
    patientCaseId: number
    date: Date
    currencyId: number
    exchangeRate: number
    amountTotal: number
    amountBefore: number
    amountAfter: number
    statusOrder: 'NORMAL' | 'CANCEL'
    remark: string
    cancelRemark: string
    cancelCode: string
    cancelBy: string
    updatedBy: string
    updatedAt: Date
    createdBy: string
    createdAt: Date
    paymentChannelText?: string
    advanceItems: AdvanceListItemInterface[]
    cnNumber: string
    branchCnNumber: string
    patientFullName: string
    advanceBalance: number
}

export interface AdvanceListItemInterface {
    transectionAdvanceItemId: number
    transectionAdvanceId: number
    paymentChannelId: number
    bankId: number
    bankName: string
    bankAccountNumber: string
    cardTypeId: number
    cardTypeName: string
    amountRaw: number
    amount: number
    userId: number
    createdBy: string
    createdAt: Date
}

/** REQUEST */
export interface AdvanceRequest {
    billPrefixId?: string
    patientId: number
    amountTotal: number
    remark: string,
    date: string,
    time: string,
    advanceItems: AdvanceItemsRequest[]
}
export interface AdvanceItemsRequest {
    paymentChannelId: number
    bankId: number | null
    cardTypeId: number | null
    amountRaw: number
    amount: number
}

const path = 'advances'
export default class AdvanceApi extends BaseAPI {
    static findAll(props: FindAllOverdueProps): Promise<any> {
        return this.api.get(`${path}`, { params: { ...props } })
    }

    static create(payload: any): Promise<any> {
        return this.api.post(`${path}`, payload)
    }

    static cancel(id: number, payload: any): Promise<any> {
        return this.api.patch(`${path}/${id}/cancel`, { ...payload })
    }

    static printA5(id: number, props: any): Promise<any> {
        return this.api.get(`${path}/${id}/receipt`, { params: { ...props } })
    }
}