import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface BranchInterface {
  branchName: string
  branchNameEn?: string
  status: string
}

export default class BranchApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('branches', { params: { ...props } }).then((res) => res)
  }

  static create(playload: any) {
    return this.api.post('branches', playload)
  }

  static findById(id: number): Promise<any> {
    return this.api.get(`branches/${id}`)
  }

  static update(id: number, body: BranchInterface) {
    return this.api.patch(`branches/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`branches/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`branches/${id}`)
  }
}
