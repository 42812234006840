import { useCallback, useEffect, useRef, useState } from 'react'
import { Card, Col, Row, Dropdown, FormControl } from 'react-bootstrap'
import { routeName } from 'routes/routes-name'
import { useHistory } from 'react-router-dom'
import { Box, ClickAwayListener, FormControlLabel, MenuItem, Radio, RadioGroup } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'
import { colors } from 'constants/theme'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import { getBranch } from 'utils/app.utils'
import DoctorScheduleApi from 'api/counter/doctor-schedule.api'
import ReportApi, { FindAllReportInterface } from 'api/report.api'
import ClinicApi, { BranchInterface, BillInterface } from 'api/master/clinic.api'

/** COMPONENT */
import Loading from 'component/Loading'
import FucntionPrint from 'component/Print/print'
import RemunerationList from './RemunerationList'
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import InputCheckbox from 'component/Input/InputCheckbox'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import InputRadio from 'component/Input/InputRadio'
import useStyles from 'features/counter/doctor-schedule/style'
import { CustomFilterDoctor, ListFilterDoctors } from 'features/counter/appointment/head-appointment'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { SpanClearFilter } from 'features/report/useStyled'
import { KEY_STORAGE } from 'constants/common'

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}

interface DentistInterface {
  dentistId: number
  dentistFullname: string
}

export default function Remuneration() {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const componentRef = useRef<HTMLDivElement>(null)
  const momentNowRange = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  const momentDate = () => momentNowRange

  const [selectedOptionPrice, setSelectedOptionPrice] = useState<any>(localStorage.getItem(KEY_STORAGE.REPORT_CONDITION) || 'TREATMENT')
  const [branchId, setBranchId] = useState<any>(getBranch())
  const [branches, setBranches] = useState<any[]>([])
  const [rowCount, setRowCount] = useState<number>(0)
  const [sortBy, setSortBy] = useState<string>('createAt')
  const [sortType, setSortType] = useState<string>('ASC')
  const [rangeDate, setRangeDate] = useState<string[]>(momentDate)

  const [prefix, setPrefix] = useState<string>('ALL')
  const [receiptPrefix, setReceiptPrefix] = useState<any[]>([])

  const [doctorsMultiple, setDoctorsMultiple] = useState<DentistInterface[]>([])
  const [selectDoctors, setSelectDoctors] = useState<any[]>(['ALL'])
  const [doctors, setDoctors] = useState<Doctors[]>([])
  const [openFilterDoctors, setOpenFilterDoctors] = useState(false)

  const [remunerationData, getRemunerationData] = useState<any[]>([])
  const [remunerationPage, setRemunerationPage] = useState<number>(1)
  const [remunerationLimitPage, setRemunerationLimitPage] = useState<number>(10)
  const [remuretionTypePage, setRemurationTypePage] = useState<number>(1)

  const setDoc = (data: any) => {
    const sortedSelectData = data.sort((a: any, b: any) => {
      const idA = a.dentistId
      const idB = b.dentistId
      return selectDoctors.indexOf(idA) - selectDoctors.indexOf(idB)
    })
    return sortedSelectData
  }
  const filter = [
    branchId === 0 ? `${t(`REPORT.FILTER.ALL`)}` : `${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`,
    rangeDate[0],
    rangeDate[1],
    prefix === 'ALL' ? `${t(`REPORT.FILTER.ALL`)}` : prefix,
    _.isEmpty(selectDoctors)
      ? ` ${t('REPORT.FILTER.NO_SELECT')}`
      : selectDoctors.filter((i) => typeof i === 'number').length === doctors.length
      ? `${t('REPORT.FILTER.ALL')}`
      : `${setDoc(doctors.filter((d: any) => selectDoctors.includes(d.userId)))
          ?.map((d: any) => d.fullname)
          .slice(0, 3) // เเสดงเเค่ 3 คนเเรก
          .join(', ')}`
  ]

  const [loading, setLoading] = useState<boolean>(true)
  const [checked, setChecked] = useState<boolean>(false)
  const verifyPermission = async () => {
    const condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 200
    }
    ReportApi.reportList(condition).then(({ data }) => {
      const check = _.find(data, (val: any) => String(val.reportKey) === 'REMUNERATION') ? true : false
      if (!check) {
        history.push(routeName.report)
        return false
      }
    })

    ClinicApi.findAllBranches()
      .then(({ data }) => setBranches(data))
      .catch((e) => {
        return
      })

    ClinicApi.allBillPrefix()
      .then(({ data }) => setReceiptPrefix(data))
      .catch((e) => {
        return
      })

    ClinicApi.findAllDoctors()
      .then((resp) => {
        setDoctors(resp.data)
      })
      .catch((e) => {
        return
      })

    DoctorScheduleApi.findAllDoctor({ branchId: branchId }).then(({ data: dataAllDoctor }) => {
      const dentists: DentistInterface[] = dataAllDoctor.map((d: any) => {
        return {
          dentistId: d.userId,
          dentistFullname: d.fullname
        }
      })
      setDoctorsMultiple(dentists)
      setSelectDoctors(['ALL', ...dentists.map((d: DentistInterface) => d.dentistId)])
    })

    return true
  }

  useEffect(() => {
    verifyPermission()
  }, [branchId])

  const fetchRemuneration = useCallback(() => {
    let condition: FindAllReportInterface = {
      page: remunerationPage,
      pageLimit: remunerationLimitPage,
      branchId: branchId,
      dateStart: rangeDate[0],
      dateEnd: rangeDate[1],
      optionPrice: selectedOptionPrice
    }

    if (doctors) {
      const doctor = _.filter(selectDoctors, (v: any) => v > 0).join(',')
      if (doctor) condition = { ...condition, doctorId: _.filter(selectDoctors, (v: any) => v > 0).join(',') }
    }
    if (prefix) condition = { ...condition, prefix: prefix === 'ALL' ? '' : prefix }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (rangeDate) {
      if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    }

    ReportApi.reportRemuneration(condition)
      .then(({ headers, data }) => {
        getRemunerationData(data)
        setRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [remunerationLimitPage, remunerationPage, remuretionTypePage, sortBy, sortType, branchId, prefix, selectDoctors, doctors, rangeDate, selectedOptionPrice])

  const { onDownload } = useDownloadExcel({
    tableExportRef: componentRef.current,
    filename: `${t(`REPORT.REPORT_NAME.REMUNERATION`)} (${rangeDate[0]}-${rangeDate[1]})`,
    sheetName: `${t(`REPORT.REPORT_NAME.REMUNERATION`)}`
  })

  const handleFilterDoctors = (value: any) => {
    if ((value === 'ALL' && _.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)) || value === 'CLEAR') setSelectDoctors([''])
    else if (value === 'ALL') setSelectDoctors([..._.map(doctors, (d: any) => d.userId)])
    else if (_.includes(selectDoctors, value)) setSelectDoctors(_.filter(selectDoctors, (d) => d !== value))
    else setSelectDoctors([...selectDoctors, value])
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangeRowsPerPage = (limit: number) => setRemunerationLimitPage(limit)
  const handleChangePage = (val: number) => setRemunerationPage(val)

  /** UseEfect */
  useEffect(() => {
    fetchRemuneration()
  }, [fetchRemuneration])

  return (
    <>
      <Card className={'border-0 h-100'}>
        <HeaderCard text={t(`REPORT.REPORT_NAME.REMUNERATION`)} />
        <Card.Body>
          <Card.Title className={'mb-0'}>
            <Row className="mx-0">
              <RadioGroup
                row
                value={selectedOptionPrice}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="d-inline"
                onChange={(e) => {
                  localStorage.setItem(KEY_STORAGE.REPORT_CONDITION, e.target.value.toString())
                  setSelectedOptionPrice(e.target.value)
                }}
              >
                <FormControlLabel value="TREATMENT" control={<InputRadio />} label={t('REPORT.FILTER.PRICE_OF_TREATMENT')} />
                <FormControlLabel value="PAID" control={<InputRadio />} label={t('REPORT.FILTER.ACTUAL_PRICE_PAID')} />
              </RadioGroup>
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setBranchId(e.target.value)
                    setRemunerationPage(1)
                  }}
                  renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                  label={''}
                  selectId="select-branch"
                  labelId="label-branch"
                  value={branchId}
                  options={_.map(branches, (b: BranchInterface, index) => {
                    return (
                      <MenuItem key={b.branchId} value={b.branchId}>
                        {b.branchName || b.branchNameEn}
                      </MenuItem>
                    )
                  })}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <InputSecondNewRangePicker
                  inputHeight={32}
                  value={rangeDate || emptyRangePicker}
                  onchange={(val: any) => {
                    setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                    setRemunerationPage(1)
                    setRemunerationLimitPage(1)
                  }}
                  label={''}
                />
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setPrefix(e.target.value)
                    setRemunerationPage(1)
                    setRemurationTypePage(1)
                  }}
                  renderValue={() => `${t('REPORT.FILTER.RECEIPT_TYPE')}: ${prefix === 'ALL' ? t(`REPORT.FILTER.ALL`) : prefix}`}
                  label={''}
                  selectId="select-prefix"
                  labelId="label-prefix"
                  value={prefix}
                  options={[
                    <MenuItem key="0" value="ALL">
                      {t('REPORT.FILTER.ALL')}
                    </MenuItem>,
                    _.map(receiptPrefix, (data: BillInterface) => {
                      return (
                        <MenuItem key={data.billPrefixId} value={data.prefix}>
                          {data.prefix}
                        </MenuItem>
                      )
                    })
                  ]}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>

              <Col
                sm={6}
                md={4}
                xl={3}
                className={'pt-2 px-1'}
                onClick={() => {
                  setChecked(!checked)
                  setRemunerationPage(1)
                }}
              >
                <ClickAwayListener onClickAway={() => setOpenFilterDoctors(false)}>
                  <CustomFilterDoctor open={openFilterDoctors} onClick={() => setOpenFilterDoctors(true)}>
                    <AutocompleteSelect
                      open={openFilterDoctors}
                      multiple
                      labelId="label-doctors"
                      options={['ALL', ...doctors, 'CLEAR']}
                      getOptionLabel={(option) => _.find(doctors, { userId: option.userId })?.fullname || '-'}
                      renderOption={(props, option) => {
                        if (option === 'ALL') {
                          return (
                            <ListFilterDoctors {...props} key={option} component="li" value={option}>
                              <InputCheckbox
                                label={t('REPORT.REPORT_GROUP.ALL')}
                                checked={_.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)}
                                indeterminate={_.size(selectDoctors) > 1 && _.size(selectDoctors.filter((item) => typeof item === 'number')) !== _.size(doctors)}
                                className={'pe-none'}
                              />
                            </ListFilterDoctors>
                          )
                        } else if (option === 'CLEAR') {
                          return (
                            <Box {...props} key={option} component="li" value={option} sx={{ borderTop: `1px solid ${colors.lightGray}` }}>
                              <SpanClearFilter className="pe-none">{t('REPORT.FILTER.CLEAR_FILTER')}</SpanClearFilter>
                            </Box>
                          )
                        } else {
                          return (
                            <ListFilterDoctors {...props} key={option.userId} component="li" value={option.userId}>
                              <InputCheckbox label={option.fullname} checked={_.includes(selectDoctors, option.userId)} className={'pe-none'} />
                            </ListFilterDoctors>
                          )
                        }
                      }}
                      renderTags={(tagValue, getTagProps) => {
                        return (
                          <>
                            <span className="pr-1">{t('USER_DOCTOR')}:</span>
                            {!openFilterDoctors &&
                              ((_.size(tagValue.filter((item: any) => typeof item === 'number')) === _.size(doctors) && <span>{t('REPORT.REPORT_GROUP.ALL')}</span>) || (
                                <Box className="text-ellipsis">
                                  {_.map(tagValue, (option: any, index: number) => {
                                    return (
                                      <span>
                                        {_.find(doctors, { userId: option })?.fullname}
                                        {index !== _.findLastIndex(tagValue) && option && `,`}
                                      </span>
                                    )
                                  })}
                                </Box>
                              ))}
                          </>
                        )
                      }}
                      onchange={(e, value) => handleFilterDoctors(e?.target?.value || e?.target?.attributes?.value?.value)}
                      noOptionsText={t('NOT_FOUND')}
                      filterOptions={(option) => 'ALL' + option.fullname + 'CLEAR'}
                      value={selectDoctors}
                      height={32}
                      disableClearable
                    />
                  </CustomFilterDoctor>
                </ClickAwayListener>
              </Col>
            </Row>
            <Row className="mx-0">
              <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                <UseStyled.DropdownDocument>
                  <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
                    {t('REGISTER.BUTTON.PRINT_DOCUMENT')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={onDownload}>{t('Excel')}</Dropdown.Item>
                    <FucntionPrint
                      content={() => componentRef.current}
                      removeIfram
                      trigger={() => <Dropdown.Item>{t('PDF')}</Dropdown.Item>}
                      style={`@page { size: 1122.519685px 793.7007874px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                    />
                  </Dropdown.Menu>
                </UseStyled.DropdownDocument>
              </Col>
            </Row>
          </Card.Title>
          <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
            <RemunerationList
              filter={filter}
              page={remunerationPage}
              pageLimit={remunerationLimitPage}
              rowCount={rowCount}
              sortBy={sortBy}
              sortType={sortType}
              data={remunerationData}
              doctors={doctorsMultiple}
              onRequestSort={onRequestSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              filterDateStart={rangeDate[0]}
              filterDateEnd={rangeDate[1]}
              componentRef={componentRef}
            />
          </Box>
        </Card.Body>
        <Loading show={loading} type="fullLoading" />
      </Card>
    </>
  )
}
