export default function Close() {
    return (
        <svg xmlns="https://www.w3.org/2000/svg" width="19.39" height="20.241" viewBox="0 0 19.39 20.241">
            <g data-name="Component 37 – 129">
                <path data-name="Line 94" style={{ fill: 'none', stroke: '#fff', strokeLinecap: 'round', strokeWidth: '3px' }} d="m0 0 15.106 16" transform="translate(2.121 2.121)" />
                <path data-name="Path 6336" d="M14.649-.5-.5 15.5" transform="translate(2.621 2.621)" style={{ fill: 'none', stroke: '#fff', strokeLinecap: 'round', strokeWidth: '3px' }} />
            </g>
        </svg>
    )
}
