import { useCallback, useEffect, useState } from 'react'
import { styled, Tooltip, Typography } from '@mui/material';
import _ from 'lodash'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import AppointmentApi, { AppointmentInterface } from 'api/counter/appointment.api'
import ClinicApi, { DentistInterface } from 'api/master/clinic.api'
import InputTextSearch from 'component/Input/InputTextSearch'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom, { TablePaginationMiniCustom } from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { numberToCurrencyFormat, checkPackage, getBranch } from 'utils/app.utils'
import { dateToView } from 'utils/date.utils'
import moment from 'moment'
import InputRangePicker from 'component/Input/InputRangePicker'
import UseWindowSize from 'utils/useWindowSize'
import { PACKAGE_ADDONS } from 'constants/package'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { CustomFilterDoctor, ListFilterDoctors } from 'features/counter/appointment/head-appointment'
import ButtonCustom from 'component/Button/ButtonCustom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Image } from 'react-bootstrap'
import { icons } from 'constants/images';
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { ThemeIcon, colors } from 'constants/theme'
import { BoxStatus, TextStatus } from 'features/home/UseStyled';
import InputSecondNewRangePicker from 'component/Input/InputSecondNewRangePicker';


export interface IViewHistoryProps {
  onClose: () => void
  onEdit?: (appointmentId: number, isEditSpecialInfo: boolean) => void
}

/** STYLE CUSTOM */
const TableConfig = styled('div')(({ theme }) => ({
  td: { height: '48px' },
  '.MuiTableBody-root': {
    '.MuiTableCell-root': {
      verticalAlign: 'top !important',
      paddingBottom: '12px !important'
    }
  },
  '& .appointmentOperatives': { maxWidth: '200px' },
  '& .practice': { minWidth: '300px', maxWidth: '400px' },
  '& .remark': { minWidth: '300px', maxWidth: '400px' },
  '.MuiPaper-root': {
    maxHeight: "calc(100vh - 230px)",
    overflow: "auto"
  },
  // '.MuiPaper-root::-webkit-scrollbar': {
  //   width: '0px',
  //   height: '0px',
  // },
  '.MuiPaper-root table thead': {
    backgroundColor: `${colors.themeMainColor} !important`,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  '.MuiPaper-root table thead tr th': {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  }
}))

const THead = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: colors.themeMainColor,
  borderRadius: '8px 8px 0 0',
  padding: '0.5rem 1.5rem',
  fontSize: 16,
  color: colors.white
}))

const TBody = styled('div')(({ theme }) => ({
  border: `1px solid ${colors.lightGray}`,
  borderTopWidth: 0,
  color: colors.textPrimary,
  fontSize: 16,
  padding: '0.625rem 1.5rem',
  '.expand-more': {
    backgroundColor: colors.white,
    border: `1px solid ${colors.textLightGray}`,
    color: colors.themeMainColor,
    borderRadius: 100,
    fontSize: 22
  }
}))

const Cell = styled('div')(({ theme }) => ({ '&:first-of-type': { marginRight: '1.25rem', width: '100%' } }))

export default function ViewHistory(props: IViewHistoryProps) {
  const { t } = useTranslation()

  const [doctors, setDoctors] = useState<DentistInterface[]>([])
  const [appointments, setAppointments] = useState<AppointmentInterface[]>([])
  const [width] = UseWindowSize()
  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(width > 1200 ? 3 : 50)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('date')
  const [sortType, setSortType] = useState('DESC')
  const [status, setStatus] = useState<boolean>(true)

  /** FILTER */
  const [search, setSearch] = useState('')
  const [doctor, setDoctor] = useState<number>(0)
  const [rangeDate, setRangeDate] = useState<string[]>(['', ''])

  const [fetch, setFetch] = useState<boolean>(false);
  const [conditionLoad, setConditionLoad] = useState<any>({})

  /** VARIABLE */
  const statusIcon: any = {
    NORMAL: { icon: <FontAwesomeIcon style={{ color: colors.white }} icon={faUser} />, color: colors.appointmentStatus.statusNormal, label: t('APPOINTMENT.STATUS.NORMAL'), disabled: false },
    CANCEL: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconCancel} />, color: colors.appointmentStatus.statusCancel, label: t('APPOINTMENT.STATUS.CANCEL'), disabled: false },
    CAN_NOT_CONTACT: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconNotConnect} />, color: colors.appointmentStatus.statusUnConnect, label: t('APPOINTMENT.STATUS.CAN_NOT_CONTACT'), disabled: false },
    PUT_OFF: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconChangeSchedule} />, color: colors.appointmentStatus.statusChange, label: t('APPOINTMENT.STATUS.PUT_OFF'), disabled: false },
    CONFIRM: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconEnter} />, color: colors.appointmentStatus.statusEnter, label: t('APPOINTMENT.STATUS.CONFIRM'), disabled: false },
    ARRIVED: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconUserCheckTrue} />, color: colors.appointmentStatus.statusArrived, label: t('APPOINTMENT.STATUS.ARRIVED'), disabled: false },
    NOT_YET: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconUserCheckFalse} />, color: colors.appointmentStatus.statusUnArrived, label: t('APPOINTMENT.STATUS.NOT_YET'), disabled: false },
    PAYMENT: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconPendingPayment} />, color: colors.appointmentStatus.statusWaitPayment, label: t('APPOINTMENT.STATUS.PENDING_PAYMENT'), disabled: true },
    PENDING_PAYMENT: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconWaitPayment} />, color: colors.appointmentStatus.statusPayment, label: t('APPOINTMENT.STATUS.PAYMENT'), disabled: true },
    PENDING_SERVICE: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconCounterCheckIn} />, color: colors.appointmentStatus.statusCounterCheckIn, label: t('APPOINTMENT.STATUS.PENDING_SERVICE'), disabled: true },
    SERVICE: { icon: <Image style={{ height: '17px' }} src={icons.appointment.iconService} />, color: colors.appointmentStatus.statusService, label: t('APPOINTMENT.STATUS.SERVICE'), disabled: true },
    DONE: { icon: <Image style={{ height: '15px' }} src={ThemeIcon.DoneTreatment} />, color: colors.appointmentStatus.statusDone, label: t('APPOINTMENT.STATUS.DONE'), disabled: true },
  }

  const handleDateSend = (date: any, num: number) => {
    if (status || date === undefined) {
      return moment().add(num, 'months').format('YYYY-MM-DD')
    } else {
      return date
    }
  }

  const handleConditionLoad = (dataCondition: any, statusPage?: boolean) => {
    if (statusPage) {
      return { ...conditionLoad, page: page, pageLimit: pageLimit }
    }

    if (fetch || status) {
      return dataCondition
    } else {
      return conditionLoad
    }
  }

  const loadDataAppointment = async (statusPage?: boolean) => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (search) condition = { ...condition, search: search }
    if (doctor) condition = { ...condition, doctorId: doctor }
    if (rangeDate) condition = { ...condition, dateStart: handleDateSend(rangeDate[0], -3), dateEnd: handleDateSend(rangeDate[1], 3) }
    if (getBranch()) condition = { ...condition, branchId: getBranch() }
    await AppointmentApi.findAll(handleConditionLoad(condition, statusPage)).then(({ data, headers }) => {
      if (data) {
        const newData: any = data.sort((a: any, b: any) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        });
        setRowCount(headers['x-total'])
        setAppointments(newData)
        if (fetch || status) {
          setConditionLoad(condition)
        }
        setStatus(false)
      }
    }).finally(() => {
      setFetch(false)
    })
  }

  useEffect(() => {
    loadDataAppointment()
  }, [fetch])

  useEffect(() => {
    loadDataAppointment(true)
  }, [page, pageLimit])

  const searchCilck = (() => {
    setPage(1)
    setFetch(true);
  })

  const loadData = useCallback(() => { ClinicApi.findAllDentists().then(({ data }) => setDoctors(data)) }, [])
  useEffect(() => { loadData() }, [])


  const headCellsLab = checkPackage(PACKAGE_ADDONS.ADDON_LAB) ? [{ id: 'hasLab', disablePadding: false, label: t('APPOINTMENT.HISTORY.LAB') }] : []
  const headCells = [
    { id: 'no', disablePadding: false, label: t('#'), class: 'text-nowrap' },
    { id: 'branchId', disablePadding: false, label: t('APPOINTMENT.HISTORY.BRANCH'), class: 'text-nowrap' },
    { id: 'date', disablePadding: false, label: t('APPOINTMENT.HISTORY.APPOINTMENT_DATE'), class: 'text-nowrap' },
    { id: 'timeStart', disablePadding: false, label: t('APPOINTMENT.HISTORY.TIME_START'), class: 'text-nowrap' },
    { id: 'durationMinute', disablePadding: false, label: t('APPOINTMENT.HISTORY.APPOINTMENT_DURATION'), class: 'text-nowrap' },
    { id: 'cnNumber', disablePadding: false, label: t('APPOINTMENT.HISTORY.CN_NUMBER'), class: 'text-nowrap' },
    { id: 'branchId', disablePadding: false, label: t('PATIENT_MANAGEMENT.TABLE.CELL.BRANCH_ID'), class: 'text-nowrap' },
    { id: 'patientFullname', disablePadding: false, label: t('APPOINTMENT.HISTORY.PATIENT_NAME'), class: 'text-nowrap' },
    { id: 'phone', disablePadding: false, label: t('APPOINTMENT.HISTORY.PHONE'), class: 'text-nowrap' },
    { id: 'doctorFullname', disablePadding: false, label: t('APPOINTMENT.HISTORY.DOCTOR'), class: 'text-nowrap' },
    { id: 'appointmentOperatives', disablePadding: false, label: t('APPOINTMENT.HISTORY.SERVICE'), class: 'text-nowrap ', style: { maxHeight: '30px' } },
    { id: 'statusAppointment', disablePadding: false, label: t('APPOINTMENT.HISTORY.STATUS'), class: 'text-nowrap' },
    { id: 'price', disablePadding: false, label: t('APPOINTMENT.HISTORY.PRICE'), class: 'text-nowrap' },
    { id: 'hasLab', disablePadding: false, label: t('APPOINTMENT.HISTORY.LAB'), class: 'text-nowrap' },
    { id: 'hasXray', disablePadding: false, label: t('APPOINTMENT.HISTORY.XRAY'), class: 'text-nowrap' },
    { id: 'practice', disablePadding: false, label: t('APPOINTMENT.HISTORY.PRACTICE'), class: 'text-nowrap practice' },
    { id: 'remark', disablePadding: false, label: t('APPOINTMENT.HISTORY.REMARK'), class: 'text-nowrap' },
    // ...headCellsLab,
    { id: 'createdBy', align: "center", disablePadding: false, label: t('APPOINTMENT.HISTORY.CREATED_NEW'), class: 'text-nowrap' },
    { id: 'updatedBy', align: "center", disablePadding: false, label: t('APPOINTMENT.HISTORY.UPDATED_NEW'), class: 'text-nowrap' },
    { id: 'hasLab', disablePadding: false, label: t('APPOINTMENT.HISTORY.ACTION') },
  ]

  const getDurationTextByTimeMinute = (minute: number) => {
    if (minute >= 60) return `${Number(minute / 60)} ${t('TIME_DURATION_TYPE.HOURS')} ` + (minute % 60 > 0 ? `${minute % 60} ${t('TIME_DURATION_TYPE.MINUTES')}` : '')
    else return `${minute} ${t('TIME_DURATION_TYPE.MINUTES')}`
  }

  const renderDataSlim = (objData: AppointmentInterface, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { appointmentId, branchName, date, timeStart, durationMinute, cnNumber, branchCnNumber, patientFullname, doctorFullname, appointmentOperatives, statusAppointment, price, practice, remark, hasLab } = objData
    const isEditSpecialInfo = statusAppointment === "DONE" ? true : false
    return (
      <TBody>
        <div className={'d-flex align-items-center'}>
          <Cell>
            <Row className="pl-4 mx-0">
              <div className="position-absolute w-auto px-0" style={{ marginLeft: '-1.5rem' }}>
                {no}
              </div>
              <Col xs={6} sm={3} className="font-weight-bold">
                {t('APPOINTMENT.HISTORY.BRANCH')}
              </Col>
              <Col xs={6} sm={3}>
                {branchName}
              </Col>
              <Col xs={6} sm={4} className="font-weight-bold">
                {t('APPOINTMENT.HISTORY.APPOINTMENT_DATE')}
              </Col>
              <Col xs={6} sm={2}>
                {dateToView(date)}
              </Col>

              <Col xs={6} sm={3} className="font-weight-bold">
                {t('APPOINTMENT.HISTORY.TIME_START')}
              </Col>
              <Col xs={6} sm={3}>{`${timeStart.replace(':00', '').replace(':', '.')} ${t('APPOINTMENT.MIN')}`}</Col>
              <Col xs={6} sm={4} className="font-weight-bold">
                {t('APPOINTMENT.HISTORY.APPOINTMENT_DURATION')}
              </Col>
              <Col xs={6} sm={2}>
                {getDurationTextByTimeMinute(durationMinute)}
              </Col>

              <Col xs={6} sm={3} className="font-weight-bold">
                {t('APPOINTMENT.HISTORY.CN_NUMBER')}
              </Col>
              <Col xs={6} sm={3}>
                {cnNumber || '-'}
              </Col>
              <Col xs={6} sm={4} className="font-weight-bold">
                {t('PATIENT_MANAGEMENT.TABLE.CELL.BRANCH_ID')}
              </Col>
              <Col xs={6} sm={2}>
                {branchCnNumber || '-'}
              </Col>

              <Col xs={6} sm={3} className="font-weight-bold">
                {t('APPOINTMENT.HISTORY.PATIENT_NAME')}
              </Col>
              <Col xs={6} sm={9}>
                {patientFullname || '-'}
              </Col>

              <Col xs={6} sm={3} className="font-weight-bold">
                {t('APPOINTMENT.HISTORY.DOCTOR')}
              </Col>
              <Col xs={6} sm={9}>
                {doctorFullname || '-'}
              </Col>

              <Col xs={6} sm={3} className="font-weight-bold">
                {t('APPOINTMENT.HISTORY.SERVICE')}
              </Col>
              <Col xs={6} sm={9}>

                <Tooltip title={String(_.map(appointmentOperatives, (op: any) => op.operativeName || op.operativeTypeName).join(', '))} placement="left">
                  <div>{String(_.map(appointmentOperatives, (op: any) => op.operativeName || op.operativeTypeName).join(', '))}</div>
                </Tooltip>
              </Col>

              <Col xs={6} sm={3} className="font-weight-bold">
                {t('APPOINTMENT.HISTORY.STATUS')}
              </Col>
              <Col xs={6} sm={3}>
                {renderAppointStatus(statusAppointment)}
              </Col>
              <Col xs={6} sm={4} className="font-weight-bold">
                {t('APPOINTMENT.HISTORY.PRICE')}
              </Col>
              <Col xs={6} sm={2}>
                {numberToCurrencyFormat(price)}
              </Col>

              <Col xs={6} sm={3} className="font-weight-bold">
                {t('APPOINTMENT.HISTORY.PRACTICE')}
              </Col>
              <Col xs={6} sm={9} className={'hard-break'}>
                {practice || '-'}
              </Col>

              <Col xs={6} sm={3} className="font-weight-bold">
                {t('APPOINTMENT.HISTORY.REMARK')}
              </Col>
              <Col xs={6} sm={9} className={'hard-break'}>
                {remark || '-'}
              </Col>
              {checkPackage(PACKAGE_ADDONS.ADDON_LAB) && (
                <>
                  <Col xs={6} sm={3} className="font-weight-bold">
                    {t('APPOINTMENT.HISTORY.LAB')}
                  </Col>
                  <Col xs={6} sm={3} className="-font-weight-bold">
                    {t(`${hasLab}`)}
                  </Col>
                </>
              )}
              <Col xs={6} sm={3} className="font-weight-bold">
                {t('APPOINTMENT.HISTORY.ACTION')}
              </Col>
              <Col xs={6} sm={3} className="-font-weight-bold">
                <img src={icons.dropdownEdit} alt="dropdownEdit" className="align-baseline cursor-pointer mx-1" onClick={() => props.onEdit && props.onEdit(appointmentId, isEditSpecialInfo)} />
              </Col>
            </Row>
          </Cell>
        </div>
      </TBody>
    )
  }

  const renderAppointStatus = (status: string) => {
    return <BoxStatus style={{ marginLeft: '5px' }} bg={statusIcon[status]?.color || ''}>
      {statusIcon[status]?.icon || ''}
      <TextStatus  >
        {statusIcon[status]?.label || ''}
      </TextStatus>
    </BoxStatus>
  }

  const renderData = (objData: AppointmentInterface, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { appointmentId, branchName, date, timeStart, durationMinute, cnNumber,
      branchCnNumber, patientFullname, phone, doctorFullname, appointmentOperatives,
      statusAppointment, price, practice, remark, hasLab, hasXray, createdBy, createdAt, updatedBy, updatedAt } = objData
    const isEditSpecialInfo = statusAppointment === "DONE" ? true : false
    const objRenderData = {
      key: String(appointmentId),
      id: String(appointmentId),
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'center', label: branchName || '', class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'left', label: dateToView(date), class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'center', label: `${timeStart.replace(':00', '').replace(':', '.')} น.`, class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'center', label: getDurationTextByTimeMinute(durationMinute) },
        { option: 'TEXT', align: 'left', label: cnNumber || '-' },
        { option: 'TEXT', align: 'left', label: branchCnNumber || '-' },
        { option: 'TEXT', align: 'left', label: patientFullname, class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'left', label: phone, class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'left', label: doctorFullname, class: 'text-nowrap-ellipsis' },
        // { option: 'TEXT', align: 'left', label: String(_.map(appointmentOperatives, (op: any) => op.operativeName || op.operativeTypeName).join(', ')), class: 'text-nowrap-ellipsis appointmentOperatives' },
        {
          option: 'COMPONENT', align: 'left', component: (<div>
            <Tooltip title={String(_.map(appointmentOperatives, (op: any) => op.operativeName || op.operativeTypeName).join(', '))} placement='bottom'>
              <div>{String(_.map(appointmentOperatives, (op: any) => op.operativeName || op.operativeTypeName).join(', '))}</div>
            </Tooltip>
          </div>)
          , class: 'text-nowrap-ellipsis appointmentOperatives'
        },
        { option: 'TEXT', align: 'left', label: renderAppointStatus(statusAppointment), class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'right', label: numberToCurrencyFormat(price) },
        { option: 'TEXT', align: 'left', label: hasLab === "NOT_HAS" ? t('APPOINTMENT.NOT_HAS') : t('APPOINTMENT.HAS'), class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'left', label: hasXray === "NOT_HAS" ? t('APPOINTMENT.NOT_HAS') : t('APPOINTMENT.HAS'), class: 'text-nowrap-ellipsis' },
        { option: 'TEXT', align: 'left', label: practice, class: '-text-nowrap-ellipsis practice hard-break' },
        { option: 'TEXT', align: 'left', label: remark, class: '-text-nowrap-ellipsis remark hard-break' },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: createdBy, updatedAt: createdAt }, class: 'text-nowrap-ellipsis' },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt }, class: 'text-nowrap-ellipsis' },
        // checkPackage(PACKAGE_ADDONS.ADDON_LAB) && { option: 'TEXT', align: 'center', label: t(`${hasLab}`) },
        {
          option: 'COMPONENT', align: 'center', component:
            <img src={icons.dropdownEdit} alt="dropdownEdit" className="align-baseline cursor-pointer mx-1" onClick={() => props.onEdit && props.onEdit(appointmentId, isEditSpecialInfo)} />

        }
      ]
    }
    return <TableRowCommon {...objRenderData} />
  }

  return <ModalCustom
    size={'xl'}
    title={t('APPOINTMENT.HISTORY.TITLE')}
    alignFooter={'end'}
    fullscreen
    closeButton
    onClose={props.onClose}
    component={
      <>
        <Row className="mb-2 mx-0">
          <Col xs={12} md={12} xl={4} className="px-1 mb-2">
            <InputTextSearch
              keyInput={'search'}
              label={t('APPOINTMENT.FORM.SEARCH')}
              value={search}
              onchange={(event) => {
                setSearch(event.target.value)
                setPage(1)
              }}
              onClear={(event) => {
                setSearch('')
                setPage(1)
              }} />
          </Col>
          <Col xs={12} md={6} xl={4} className="px-1 mb-2">
            <CustomFilterDoctor className='position-relative' >
              <Typography className='position-absolute edit-filter-doctor'>{t('USER_DOCTOR')}:</Typography>
              <AutocompleteSelect
                labelId="select-dentist"
                options={doctors}
                getOptionLabel={(option) => option.dentistFullname || t('ALL')}
                renderOption={(props, option) => {
                  return (
                    <ListFilterDoctors {...props} key={option.dentistId} component="li">
                      {option.dentistFullname}
                    </ListFilterDoctors>
                  )
                }}
                onchange={(e, value) => setDoctor(value?.dentistId)}
                noOptionsText={t('NOT_FOUND')}
                filterOptions={(option) => option.dentistFullname}
                value={_.find(doctors, { dentistId: doctor }) || ''}
                height={31.99}
                disableClearable={!doctor}
              />
            </CustomFilterDoctor>
          </Col>
          <Col xs={12} md={6} xl={3} className="px-1 mb-2">
            <InputSecondNewRangePicker value={rangeDate} inputHeight={32} onchange={(val: any) => {
              setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
              setPage(1)
            }} />
          </Col>
          <Col xs={12} md={6} xl={1} className="px-1 mb-2">
            <ButtonCustom style={{ height: '30px' }} onClick={() => searchCilck()} textButton={t('INPUT.SEARCH')} />
          </Col>
        </Row>
        <div className="d-none d-xl-block">
          <TableConfig>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              rowCount={rowCount}
              onSort={(sortByVal: string, sortTypeVal: string) => {
                setSortType(sortTypeVal)
                setSortBy(sortByVal)
              }}
              setPageLimit={setPageLimit}
              setPage={setPage}
              headCells={headCells}
              rowsData={_.map(appointments, (val, i) => renderData(val, i))}
            />
          </TableConfig>
        </div>
        <div className="d-block d-xl-none">
          <THead>
            <Cell>{t('#')}</Cell>
          </THead>
          <div className={'treatment-body'}>
            {_.map(appointments, renderDataSlim)}
            <div className={'mt-2'}>
              <TablePaginationMiniCustom
                count={rowCount || 0}
                page={page}
                rowsPerPage={pageLimit}
                rowCount={rowCount}
                pageLimit={pageLimit}
                headCells={headCells}
                onPageChange={(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage)}
                setPageLimit={(event: any) => {
                  setPageLimit(parseInt(event.target.value, 10))
                  setPage(1)
                }}
                setPage={setPage}
              />
            </div>
          </div>
        </div>
      </>
    }
  />
}
