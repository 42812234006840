import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart, TreatmentStateInterface } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/38.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethL, TextNumberL } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
  height: 'auto',
  marginBottom: 'auto',
  top: '1.4%'
}))

export default function Teeth38(props: TeethProps) {
  const teeth = useSelector(toothChart)
  const teethNumber = props.teethNumber
  const historyToothChart = props.historyToothChart
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const isEditTootCharts: any = useSelector(isEditTootChart)

  const dispatch = useDispatch()

  const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
  const [positions, setPositions]: any = useState([])
  const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

  const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
  const [isTooth, setIsTooth]: any = useState(false)
  const [isXRay, setIsXRay]: any = useState(false)
  const [isToothRoot, setIsToothRoot]: any = useState(false)
  const [isOrthodontics, setIsOrthodontics]: any = useState(false)
  const [isExtract, setIsExtract]: any = useState(false)
  const [isFixture, setIsFixture]: any = useState(false)
  const [isBridge, setIsBridge]: any = useState(false)

  const [color, setColor]: any = useState(``)
  const [styleSvg, setStyleSvg]: any = useState({ width: 38.262, height: 93.001, viewBox: "0 0 38.262 93.001" })

  const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

  useEffect(() => {
    const operative = props.operative || ``
    const valColor = props.color || colors.themeSecondColor
    const selected = teeth[teethNumber]?.isSelect || false
    dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
    setIsSelected(selected)

    renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

    if (props.type === 'treatment') {
      renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
    }

    if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

    return setStyleSvg({ width: "36.936", height: "67.495", viewBox: "0 0 36.936 67.495" })
  }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

  useEffect(() => {
    const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
    const teethJson = treatmentTooth?.teethJson

    if (isEditTootCharts !== null) {
      if (teethJson) {
        const tooths = JSON.parse(teethJson)
        setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
        if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
      } else setIsEditTeeth(false)
    }
  }, [isEditTootCharts]);

  useEffect(() => {
    if (isSelected) {
      dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
      if (teeth[props.teethNumber]?.isSelect) {
        setIsSelected(true)
      } else {
        setIsSelected(false)
      }
    }
  }, [props.historyToothChart]);

  useEffect(() => {
    if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
  }, [isSelected, isEditTootCharts]);

  return (
    <Div>
      <TextNumberL>38</TextNumberL>
      <PositionTeeth quarter={QUARTER_NAME_TYPE.Q3} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
      <DivTeethL className={`div-teeth-${props.teethNumber} `}>
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray position='L' />) || ''}
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge position='L' />) || ''}
        {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`lg teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
          <svg className={`lg ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
            <g data-name="Group 9185">

              {(isBridge && props.type === 'treatment') ?
                <g></g>
                : ""}

              <path
                data-name="Path 5244"
                d="M98.122 566.687c1.266-3 1.213-6.287 2.177-9.47 1.972 2.632 2.456 6.072 4.268 8.58a2.927 2.927 0 0 0 5.146-.608 10.847 10.847 0 0 0 .518-2.211A179 179 0 0 0 112.5 544.8c.406-8.786 3.168-16.816 6.542-24.753 1.546-3.636 3.049-7.957.253-10.964-3.426-3.684-8.013-6.095-13.5-2.713-2.418 1.491-4.891 1.2-7.284-1.061-3.468-3.283-6.968-1.728-10 1.031-1.571 1.429-2.889 3.136-4.588 5.012 3.348 15.89.072 32.8 5.624 48.727.964 2.766 1.72 5.762 4.091 7.737a2.891 2.891 0 0 0 4.484-1.129z"
                transform="translate(-83.926 -500.97)"
                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
              />

              {(isLowerTeeth && props.type === 'treatment') ?
                <path
                  data-name="Path 6509"
                  d="M98.122 566.687c1.266-3 1.213-6.287 2.177-9.47 1.972 2.632 2.456 6.072 4.268 8.58a2.927 2.927 0 0 0 5.146-.608 10.847 10.847 0 0 0 .518-2.211A179 179 0 0 0 112.5 544.8c.406-8.786 3.168-16.816 6.542-24.753a25.071 25.071 0 0 0 1.661-5.074c-.592 1.695-.936 2.492-2.28 3.08-1.4.434-4.422-.088-6.516.068-1.531 0-2.84.112-5.139.064-3.234.139-6.992.139-8.7 0a57.275 57.275 0 0 1-7.42-1.716 19.991 19.991 0 0 1-5.387-2.747A5.223 5.223 0 0 1 84.1 512.2c3.1 15.448-.034 32.145 5.452 47.879.964 2.766 1.72 5.762 4.091 7.737a2.891 2.891 0 0 0 4.479-1.129z"
                  transform="translate(-83.926 -500.97)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                /> : ''}

              <g data-name="Group 5040">
                <path
                  data-name="Path 5247"
                  d="M84.008 509.186a1.133 1.133 0 0 1 .027-.15.225.225 0 0 0 .014-.064c.2-.865.548-1.429.568-1.68 3.458-7.954 11.286-10.038 16.321-4.867 2.994 3.076 5.222-.706 8.633-.617 8.121.213 10.727 5.8 10.762 5.893a8.6 8.6 0 0 1 .431 2.092 15.728 15.728 0 0 1 .095 1.636c.047 2.9-.751 6.024-5.048 5.409a.915.915 0 0 1-.2-.021c-.447-.072-1.99-.086-3.972-.064-3.8.036-9.229.179-11.6.172a34.655 34.655 0 0 1-11.679-2.737c-2.585-1.157-4.493-2.623-4.439-4.281v-.035a5.267 5.267 0 0 1 .087-.686z"
                  transform="translate(-83.926 -499.596)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                />
                <path
                  data-name="Path 5248"
                  d="M86.53 509.946a.993.993 0 0 1 .024-.135.217.217 0 0 0 .012-.059c.177-.782.5-1.292.514-1.519 3.127-7.193 10.207-9.078 14.761-4.4 2.708 2.781 4.722-.639 7.808-.558 7.344.193 9.7 5.244 9.733 5.329a7.766 7.766 0 0 1 .389 1.892 14.234 14.234 0 0 1 .086 1.481c.043 2.624-.679 5.448-4.565 4.892a.832.832 0 0 1-.178-.019c-.4-.065-1.8-.078-3.592-.059-3.439.032-8.347.162-10.489.155a31.338 31.338 0 0 1-10.562-2.476c-2.338-1.047-4.064-2.372-4.015-3.871v-.032a4.761 4.761 0 0 1 .074-.621z"
                  transform="translate(-84.553 -500.192)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                />
                <path
                  data-name="Path 5249"
                  d="M96.314 511.888a.725.725 0 0 1 .019-.1.146.146 0 0 0 .009-.043c.133-.586.371-.968.385-1.137 2.342-5.387 7.645-6.8 11.055-3.3 2.028 2.082 3.537-.479 5.848-.418 5.5.144 7.266 3.928 7.289 3.991a5.807 5.807 0 0 1 .292 1.417 10.622 10.622 0 0 1 .064 1.108c.032 1.965-.509 4.08-3.419 3.665a.637.637 0 0 1-.133-.015c-.3-.048-1.347-.058-2.69-.044-2.575.024-6.252.121-7.855.117a23.466 23.466 0 0 1-7.911-1.854c-1.751-.784-3.043-1.776-3.007-2.9v-.024a3.51 3.51 0 0 1 .054-.463z"
                  transform="translate(-92.288 -501.656)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                />
              </g>

              {(isToothRoot && props.type === 'treatment') ?
                <>
                  <path
                    data-name="Path 6578"
                    d="M18.654.083c-1.683 4.776-3.954 16.773-2.771 29.584 1.078 14.217 2.346 16.9 2.059 17.256.032.281-1.127-2.746-2.99-17.256-1.392-11.122.006-25.775 1.807-30.013.905.146.692.211 1.895.429z"
                    transform="translate(-6.371 17.324)"
                    style={{

                      fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                      stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                    }}
                  />
                  <path
                    data-name="Path 6579"
                    d="M18.623.333c-1.031 5-1.859 16.851-.681 29.661 1.078 14.217.287 16.572 0 16.929.032.281.909-2.418-.953-16.929C15.6 18.872 16.234 6.011 16.607.411c.73.008 1.5-.094 2.016-.078z"
                    transform="translate(5.629 17.324)"
                    style={{

                      fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                      stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                    }}
                  />
                </> : ''}

              {(isExtract && props.type === 'treatment') ?
                <>
                  <g data-name="Group 9480">
                    <path data-name="Path 6581" d="m38.246 7.215-40 68.285" transform="translate(3.807 -5.163)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                    <path data-name="Path 6583" d="m-1.754 7.215 40 68.285" transform="translate(3.807 -5.163)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                  </g>
                </>
                : ''}

              {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                <>
                  <defs>
                    <linearGradient id="0kocfmoeca" x1=".5" y1=".453" x2="-1.326" y2="4.622" gradientUnits="objectBoundingBox">
                      <stop offset={0} stopColor="#b0b1b1" />
                      <stop offset={1} stopColor="#585959" />
                    </linearGradient>
                    <linearGradient id="j7e93iq5yb" x1=".5" y1="1.834" x2=".5" y2=".046" gradientUnits="objectBoundingBox">
                      <stop offset={0} stopColor="#c1c4c5" />
                      <stop offset={1} stopColor="#9e9e9e" />
                    </linearGradient>
                  </defs>
                  <g data-name="Group 9372">
                    <path data-name="Path 5244" d="M98.122 566.687c1.266-3 1.213-6.287 2.177-9.47 1.972 2.632 2.456 6.072 4.268 8.58a2.927 2.927 0 0 0 5.146-.608 10.847 10.847 0 0 0 .518-2.211A179 179 0 0 0 112.5 544.8c.406-8.786 3.168-16.816 6.542-24.753 1.546-3.636 3.049-7.957.253-10.964-3.426-3.684-8.013-6.095-13.5-2.713-2.418 1.491-4.891 1.2-7.284-1.061-3.468-3.283-6.968-1.728-10 1.031-1.571 1.429-2.889 3.136-4.588 5.012 3.348 15.89.072 32.8 5.624 48.727.964 2.766 1.72 5.762 4.091 7.737a2.891 2.891 0 0 0 4.484-1.129z" transform="translate(-83.926 -500.97)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                    <path data-name="Path 5245" d="M98.283 509.239c3.927 7.394 19.077 7.281 22.49 2.807-3.21-5.762-6.318-2.606-9.218.2-4.394-.711-3.952-8.565-9.978-6.35a4.5 4.5 0 0 0-3.294 3.343z" transform="translate(-93.091 -501.696)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                    <path data-name="Path 5246" d="M100.083 510.332c.409-3.271 2.588-5.009 5.491-5.75 2.876-.734 5.042.7 6.408 3.185 1.2 2.194 2.751 2.038 4.532.947a9.567 9.567 0 0 1 6.449-1.6" transform="translate(-96.441 -501.3)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                    <g data-name="Group 5040">
                      <path data-name="Path 5247" d="M84.008 509.186a1.133 1.133 0 0 1 .027-.15.225.225 0 0 0 .014-.064c.2-.865.548-1.429.568-1.68 3.458-7.954 11.286-10.038 16.321-4.867 2.994 3.076 5.222-.706 8.633-.617 8.121.213 10.727 5.8 10.762 5.893a8.6 8.6 0 0 1 .431 2.092 15.728 15.728 0 0 1 .095 1.636c.047 2.9-.751 6.024-5.048 5.409a.915.915 0 0 1-.2-.021c-.447-.072-1.99-.086-3.972-.064-3.8.036-9.229.179-11.6.172a34.655 34.655 0 0 1-11.679-2.737c-2.585-1.157-4.493-2.623-4.439-4.281v-.035a5.267 5.267 0 0 1 .087-.686z" transform="translate(-83.926 -499.596)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5248" d="M86.53 509.946a.993.993 0 0 1 .024-.135.217.217 0 0 0 .012-.059c.177-.782.5-1.292.514-1.519 3.127-7.193 10.207-9.078 14.761-4.4 2.708 2.781 4.722-.639 7.808-.558 7.344.193 9.7 5.244 9.733 5.329a7.766 7.766 0 0 1 .389 1.892 14.234 14.234 0 0 1 .086 1.481c.043 2.624-.679 5.448-4.565 4.892a.832.832 0 0 1-.178-.019c-.4-.065-1.8-.078-3.592-.059-3.439.032-8.347.162-10.489.155a31.338 31.338 0 0 1-10.562-2.476c-2.338-1.047-4.064-2.372-4.015-3.871v-.032a4.761 4.761 0 0 1 .074-.621z" transform="translate(-84.553 -500.192)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5249" d="M96.314 511.888a.725.725 0 0 1 .019-.1.146.146 0 0 0 .009-.043c.133-.586.371-.968.385-1.137 2.342-5.387 7.645-6.8 11.055-3.3 2.028 2.082 3.537-.479 5.848-.418 5.5.144 7.266 3.928 7.289 3.991a5.807 5.807 0 0 1 .292 1.417 10.622 10.622 0 0 1 .064 1.108c.032 1.965-.509 4.08-3.419 3.665a.637.637 0 0 1-.133-.015c-.3-.048-1.347-.058-2.69-.044-2.575.024-6.252.121-7.855.117a23.466 23.466 0 0 1-7.911-1.854c-1.751-.784-3.043-1.776-3.007-2.9v-.024a3.51 3.51 0 0 1 .054-.463z" transform="translate(-92.288 -501.656)" style={{ fill: colors.white }} />
                      <g data-name="Group 9411">
                        <g data-name="Group 9394" transform="rotate(10.02 -19.697 80.005)">
                          <g data-name="Rectangle 3927" style={{ fill: '#c1c4c5', stroke: '#c1c4c5' }}>
                            <rect width="11.602" height="11.602" rx={2} style={{ stroke: 'none' }} />
                            <rect x=".5" y=".5" width="10.602" height="10.602" rx="1.5" style={{ fill: 'none' }} />
                          </g>
                          <path data-name="Path 6487" d="M11.579 17.547c-.2.362 1.676.183 3.083.165 2.094-.044 3.289.384 3.269-4.45a41.118 41.118 0 0 0-.159-5.141c-.531-.516.14 5.236-1.219 7.766-1.282 1.827-4.631 1.272-4.974 1.66z" transform="translate(-6.653 -6.464)" style={{ fill: '#b0b1b1' }} />
                          <rect data-name="Rectangle 3931" width={5} height={2} rx=".5" transform="translate(3.289 7.215)" style={{ fill: '#cbcccc' }} />
                          <rect data-name="Rectangle 3930" width={5} height={2} rx=".5" transform="translate(3.089 3.015)" style={{ fill: '#9e9e9e' }} />
                          <rect data-name="Rectangle 3928" width={5} height={2} rx=".5" transform="translate(3.289 3.015)" style={{ fill: 'url(#0kocfmoeca)' }} />
                          <rect data-name="Rectangle 3929" width={5} height={2} rx=".5" transform="translate(3.289 7.015)" style={{ fill: 'url(#j7e93iq5yb)' }} />
                        </g>
                        <path data-name="Path 6488" d="M1.6 9.574c6.917.581 13.917 5.556 36.129-1.115" transform="rotate(10.02 17.213 6.031)" style={{ strokeLinecap: 'round', stroke: '#b5b7b7', fill: 'none' }} />
                        <path data-name="Path 6486" d="M1.915 9.64c9.759 2.058 16.908 4.5 35.9-1.147" transform="rotate(10.02 21.248 5.315)" style={{ stroke: '#babbbb', strokeLinecap: 'round', fill: 'none' }} />
                      </g>
                    </g>
                  </g>
                </>
                || ""}

              {(isFixture && props.type === 'treatment') ?
                <>
                  <defs>
                    <linearGradient id="0at7glapja" x1=".209" y1="1.059" x2="1.176" y2="-.286" gradientUnits="objectBoundingBox">
                      <stop offset={0} stopColor="#e5e5e5" />
                      <stop offset={1} stopColor="#b4b5b5" />
                    </linearGradient>
                    <linearGradient id="qnweu0gzab" x1="-.342" y1="1.081" x2="1.352" gradientUnits="objectBoundingBox">
                      <stop offset={0} stopColor="#e5e5e5" />
                      <stop offset={1} stopColor="#b0b1b1" />
                    </linearGradient>
                  </defs>
                  <g data-name="Group 9513">
                    <path data-name="Path 5245" d="M98.283 509.239c3.927 7.394 19.077 7.281 22.49 2.807-3.21-5.762-6.318-2.606-9.218.2-4.394-.711-3.952-8.565-9.978-6.35a4.5 4.5 0 0 0-3.294 3.343z" transform="translate(-92.091 -499.21)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                    <path data-name="Path 5246" d="M100.083 510.332c.409-3.271 2.588-5.009 5.491-5.75 2.876-.734 5.042.7 6.408 3.185 1.2 2.194 2.751 2.038 4.532.947a9.567 9.567 0 0 1 6.449-1.6" transform="translate(-95.441 -498.814)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                    <g data-name="Group 5040">
                      <path data-name="Path 5247" d="M84.008 509.186a1.133 1.133 0 0 1 .027-.15.225.225 0 0 0 .014-.064c.2-.865.548-1.429.568-1.68 3.458-7.954 11.286-10.038 16.321-4.867 2.994 3.076 5.222-.706 8.633-.617 8.121.213 10.727 5.8 10.762 5.893a8.6 8.6 0 0 1 .431 2.092 15.728 15.728 0 0 1 .095 1.636c.047 2.9-.636 8.342-4.933 7.727-.068 0-.5-.538-.567-.552-.447-.072-1.734-1.873-3.717-1.851-3.8.036-9.229.179-11.6.172a34.655 34.655 0 0 1-11.679-2.737c-2.585-1.157-5.157 2.036-5.1.378-.642 4.418.705-5.166.745-5.38z" transform="rotate(-8 -3493.075 845.502)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5248" d="M86.53 509.946a.993.993 0 0 1 .024-.135.217.217 0 0 0 .012-.059c.177-.782.5-1.292.514-1.519 3.127-7.193 10.207-9.078 14.761-4.4 2.708 2.781 4.722-.639 7.808-.558 7.344.193 9.7 5.244 9.733 5.329a7.766 7.766 0 0 1 .389 1.892 14.234 14.234 0 0 1 .086 1.481c.043 2.624-.041 8.184-3.926 7.628-.061 0-1.319-1-1.381-1.014-.4-.065-1.235-1.819-3.028-1.8-3.439.032-8.347.162-10.489.155a31.338 31.338 0 0 1-10.562-2.476c-2.338-1.047-4.44.244-4.391-1.255l-.142.944c.012-.22.555-4.019.592-4.213z" transform="rotate(-8 -3497.023 850.284)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5249" d="M96.314 511.888a.725.725 0 0 1 .019-.1.146.146 0 0 0 .009-.043c.133-.586.371-.968.385-1.137 2.342-5.387 7.645-6.8 11.055-3.3 2.028 2.082 3.537-.479 5.848-.418 5.5.144 7.266 3.928 7.289 3.991a5.807 5.807 0 0 1 .292 1.417 10.622 10.622 0 0 1 .064 1.108c.032 1.965-.509 4.08-3.419 3.665a.637.637 0 0 1-.133-.015c-.3-.048-1.347-.058-2.69-.044-2.575.024-6.252.121-7.855.117a23.466 23.466 0 0 1-7.911-1.854c-1.751-.784-3.043-1.776-3.007-2.9v-.024a3.51 3.51 0 0 1 .054-.463z" transform="rotate(-8 -3503.624 906.324)" style={{ fill: colors.white }} />
                    </g>
                    <g data-name="Group 9537">
                      <g data-name="Path 6490" transform="translate(10.946 22.75)" style={{ fill: 'url(#0at7glapja)' }}>
                        <path d="M13.874 44.996H5.203c-.776 0-1.52-1.975-2.212-5.87C1.818 32.515.845 20.603.1 3.718c0-.976.177-1.896.499-2.586C.909.467 1.312.1 1.734.1h15.609c.422 0 .825.367 1.135 1.033.322.69.499 1.61.499 2.59 0 39.283-4.25 41.273-5.103 41.273z" style={{ stroke: 'none' }} />
                        <path d="M1.734.2C.994.2.201 1.614.2 3.719c.745 16.879 1.717 28.784 2.89 35.389.893 5.036 1.707 5.788 2.113 5.788h8.671c.183 0 .564-.11 1.05-.85.634-.963 1.211-2.627 1.715-4.946 1.485-6.83 2.238-18.732 2.238-35.377 0-2.107-.793-3.523-1.534-3.523H1.734m0-.2h15.609c.957 0 1.734 1.667 1.734 3.723 0 39.288-4.245 41.373-5.203 41.373H5.203c-.958 0-3.469-2.085-5.203-41.373C0 1.667.776 0 1.734 0z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                      </g>
                      <g data-name="Path 6489" transform="translate(0 17.547)" style={{ strokeLinejoin: 'round', fill: 'url(#qnweu0gzab)' }}>
                        <path d="M1.602 7.569c-.113 0-.118 0-.207-.53C1.28 6.348 1.035 4.906.1 1.9.108.906.942.1 1.965.1H39.31c1.025 0 1.86.807 1.866 1.802-.496 2.006-.756 3.303-.928 4.162-.3 1.493-.307 1.493-.722 1.493h-.094l-.122-.001H1.965c-.1 0-.182.004-.248.008-.045.003-.084.005-.115.005z" style={{ stroke: 'none' }} />
                        <path d="M1.965.2C1.002.2.217.953.2 1.884c.934 3.003 1.177 4.445 1.294 5.14.034.202.064.38.09.445h.018c.028 0 .066-.002.11-.005.067-.004.15-.008.253-.008H39.31l.216.001c.24 0 .26-.02.309-.118.092-.183.171-.578.315-1.295.172-.858.432-2.152.926-4.154-.013-.934-.8-1.69-1.766-1.69H1.965m0-.2H39.31c1.086 0 1.966.857 1.966 1.914-1.478 5.981-.88 5.742-1.966 5.742H1.965c-1.085 0 0 .568-1.965-5.742C0 .857.88 0 1.965 0z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                      </g>
                      <path data-name="Path 6491" d="M5.625 23.16s18.057-.9 18.962-1.957" transform="translate(5.435 7.886)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6492" d="M5.649 23.213S23.1 22.255 24 21.2" transform="translate(5.722 13.085)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6493" d="M7.08 22.925s14.6-.592 15.5-1.645" transform="translate(6.17 35.612)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6584" d="M5.649 23.213S23.1 22.255 24 21.2" transform="translate(5.978 17.628)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6585" d="M6.115 23.237S23.1 22.255 24 21.2" transform="translate(5.978 23.628)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6586" d="M6.643 23.2s16.036-.854 16.941-1.907" transform="translate(5.978 29.628)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6587" d="M8.144 22.87s12.509-.373 13.415-1.426" transform="translate(6.169 41.25)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                    </g>
                  </g>
                </> : ""}
            </g>
          </svg>}
      </DivTeethL>
    </Div >
  )
}
