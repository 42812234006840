import IconProps from './iconProps'
import { colors } from 'constants/theme'

export default function Application(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <defs></defs>
      <g id="app" transform="translate(3.826 4)">
        <g id="Group_5564" data-name="Group 5564" transform="translate(0.175 -0.001)">
          <g id="Group_5563" data-name="Group 5563">
            <g id="Group_6919" data-name="Group 6919">
              <path id="Path_6267" data-name="Path 6267" className="cls-1" d="M.656,1.294v8.47H9.939V.772H.656Z" style={{ fill: '#fff' }} />
              <path id="Path_6268" data-name="Path 6268" className="cls-1" d="M.656,1.294v8.47H9.939V.772H.656Z" transform="translate(14)" style={{ fill: '#fff' }} />
              <path id="Path_6269" data-name="Path 6269" className="cls-1" d="M.656,1.294v8.47H9.939V.772H.656Z" transform="translate(14 14)" style={{ fill: '#fff' }} />
              <path id="Path_6270" data-name="Path 6270" className="cls-1" d="M.656,1.294v8.47H9.939V.772H.656Z" transform="translate(0 14)" style={{ fill: '#fff' }} />
            </g>
            <path
              id="Path_5708"
              data-name="Path 5708"
              className="cls-2"
              style={{ fill: props.color || colors.themeSecondColor, stroke: props.color || colors.themeSecondColor, strokeWidth: '1px' }}
              d="M8.934,0H1.576A1.578,1.578,0,0,0,0,1.577V8.934a1.579,1.579,0,0,0,1.577,1.577H8.934a1.579,1.579,0,0,0,1.577-1.577V1.577A1.578,1.578,0,0,0,8.934,0ZM9.46,8.934a.526.526,0,0,1-.526.526H1.577a.526.526,0,0,1-.526-.526V1.577a.526.526,0,0,1,.525-.526H8.934a.526.526,0,0,1,.526.526V8.934Z"
            />
            <path
              id="Path_5709"
              data-name="Path 5709"
              className="cls-2"
              style={{ fill: props.color || colors.themeSecondColor, stroke: props.color || colors.themeSecondColor, strokeWidth: '1px' }}
              d="M298.326,0h-7.358a1.579,1.579,0,0,0-1.577,1.577V8.934a1.579,1.579,0,0,0,1.577,1.577h7.358A1.579,1.579,0,0,0,299.9,8.934V1.577A1.579,1.579,0,0,0,298.326,0Zm.526,8.934a.526.526,0,0,1-.526.526h-7.358a.526.526,0,0,1-.526-.526V1.577a.526.526,0,0,1,.526-.526h7.358a.526.526,0,0,1,.526.526Z"
              transform="translate(-275.728)"
            />
            <path
              id="Path_5710"
              data-name="Path 5710"
              className="cls-2"
              style={{ fill: props.color || colors.themeSecondColor, stroke: props.color || colors.themeSecondColor, strokeWidth: '1px' }}
              d="M8.934,289.391H1.576A1.578,1.578,0,0,0,0,290.968v7.358A1.579,1.579,0,0,0,1.577,299.9H8.934a1.579,1.579,0,0,0,1.577-1.577v-7.358A1.578,1.578,0,0,0,8.934,289.391Zm.526,8.934a.526.526,0,0,1-.526.526H1.577a.526.526,0,0,1-.526-.526v-7.358a.526.526,0,0,1,.525-.525H8.934a.526.526,0,0,1,.526.525v7.358Z"
              transform="translate(0 -275.727)"
            />
            <path
              id="Path_5711"
              data-name="Path 5711"
              className="cls-2"
              style={{ fill: props.color || colors.themeSecondColor, stroke: props.color || colors.themeSecondColor, strokeWidth: '1px' }}
              d="M298.326,289.391h-7.358a1.578,1.578,0,0,0-1.577,1.577v7.358a1.579,1.579,0,0,0,1.577,1.577h7.358a1.579,1.579,0,0,0,1.577-1.577v-7.357A1.579,1.579,0,0,0,298.326,289.391Zm.526,8.934a.526.526,0,0,1-.526.526h-7.358a.526.526,0,0,1-.526-.526v-7.358a.526.526,0,0,1,.526-.525h7.358a.526.526,0,0,1,.526.525Z"
              transform="translate(-275.728 -275.727)"
            />
          </g>
        </g>
        <rect id="Rectangle_3866" data-name="Rectangle 3866" className="cls-3" width="32" height="32" transform="translate(-3.826 -4)" style={{ fill: 'none' }} />
      </g>
    </svg>
  )
}
