import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { Box, Grid, Typography } from '@mui/material'

import ClinicApi from 'api/master/clinic.api'
import TreatmentSummaryApi, { CommissionProps, GetCommissionProps } from 'api/dentists/treatment-summary.api'

import ModalCustom from 'component/ModalCustom/ModalCustom'
import InputTextField from 'component/Input/InputTextField'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'

import InputTextarea from 'component/Input/InputTextarea'
import TableCustom from 'component/Table/TableCustom'
import { numberFormat } from 'utils/app.utils'
import TableRowCommon, { DropdownItem } from 'component/Table/TableRowCommon'

import { useDispatch, useSelector } from 'react-redux'
import { selectMe } from 'app/slice/user.slice'
import { Icons, icons } from 'constants/images'
import { closeModal, resetModal } from 'app/slice/modal.slice'

import { TreatmentStateInterface, setTreatmentByKey, treatment } from 'app/slice/treatment.slice'
import { notiError } from 'component/notifications/notifications'
import { Dropdown } from 'react-bootstrap'
import ButtonCustom from 'component/Button/ButtonCustom'


export interface POPUPCommissionProps {
    type: "treatment" | "medicine" | "product"
    code: string
    itemName: string
    qty: number
    price: number
    sum: number
    tempOrderId?: number
    operativeId?: number
}

type PopupCommissionProps = {
    onSave?: (val: 'save') => void
    data: any
    onReset: () => void
    hasCommission?: "1" | "0"
}

export default function PopupCommission(props: PopupCommissionProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const data = props?.data
    const [employee, setEmployee] = useState([])
    const [employeeSelect, setEmployeeSelect] = useState<any>({})
    const [commission, setCommission] = useState<number>(0)
    const [remark, setRemark] = useState("")
    const [isEditTable, setIsEditTable] = useState<boolean>(false)
    const [dataCom, setDataCom] = useState<any>([])
    const [employeeEdit, setEmployeeEdit] = useState<number>(0)

    const user = useSelector(selectMe)
    const treatments: TreatmentStateInterface = useSelector(treatment)

    const [comPage, setComPage] = useState<number>(1);
    const [comPageLimit, setComPageLimit] = useState<number>(10);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortBy, setSortBy] = useState<string>('createAt');
    const [sortType, setSortType] = useState<string>('ASC');

    const [scrollTopBody, setScrollTopBody] = useState<number>(0);

    const handleChangeRowsPerPage = (limit: number) => setComPageLimit(limit)

    const handleChangePage = (val: number) => setComPage(val)

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const loadDefaultData = useCallback(async () => {
        let dataEmployee: any = []
        await ClinicApi.findUsers().then((res: any) => {
            setEmployee(res.data)
            dataEmployee = res.data
        }).catch((e) => { return })
        if (props.hasCommission === "1") {
            const objSend: GetCommissionProps = {
                refId: data?.tempOrderId || 0,
                refType: data?.type === "treatment" ? "TEMP_ORDER_TREATMENT" : "TEMP_ORDER_PRODUCT"
            }
            await TreatmentSummaryApi.getCommission(objSend).then((res: any) => {
                const dataGetCom = res.data.map((item: any) => {
                    const userFulName = _.filter(dataEmployee, { userId: item.userId })
                    const newData = {
                        fullname: userFulName[0].fullname,
                        position: item.position,
                        userId: item.userId,
                        price: item.price,
                        remark: item.remark,
                        updateBy: item.createdBy,
                        updateDate: item.createdAt
                    }
                    return newData
                })
                setDataCom(dataGetCom);
            }).catch((e) => { return })
        }
    }, [])


    useEffect(() => {
        loadDefaultData()
    }, [])

    const resetInput = () => {
        setEmployeeEdit(0)
        setEmployeeSelect({})
        setCommission(0)
        setRemark("")
        setIsEditTable(false)
    }

    const handleCommission = (status: "add" | "edit") => {
        if (employeeSelect.userId) {
            const employ = {
                fullname: employeeSelect.fullname,
                position: employeeSelect.position,
                userId: employeeSelect.userId
            }
            const info = {
                ...employ,
                price: Number(commission),
                remark: remark,
                updateBy: user.fullname,
                updateDate: new Date()
            }
            if (status === "add") {
                setDataCom((item: any) => {
                    if (item.length === 0) {
                        return [info];
                    } else {
                        return [...item, info];
                    }
                });
                resetInput()
            } else {
                const setData = dataCom.filter((item: any) => item.userId !== employeeEdit)
                let newData: any = []
                newData.push(info)
                newData = [...newData, ...setData]
                setDataCom(newData);
                resetInput()
            }

        }
    }

    useEffect(() => {
        setRowCount(dataCom.length)
    }, [dataCom])

    const [sum, setSum] = useState<boolean>(false)

    const onSubmit = async () => {
        const objUser = dataCom.map((item: any) => {
            const newData = {
                userId: item.userId || 0,
                price: Number(item.price) || 0,
                remark: item.remark
            }
            return newData
        })
        const objSend: CommissionProps = {
            refId: data?.tempOrderId || 0,
            refType: data?.operativeId ? "TEMP_ORDER_TREATMENT" : "TEMP_ORDER_PRODUCT",
            user: objUser
        }

        if (props.hasCommission === "1") {
            await TreatmentSummaryApi.updateCommission(objSend).then(async (res: any) => {
                if (res.status === 200) {
                    await setDataCommisson()
                    setSum(true)
                    dispatch(closeModal())
                    props.onReset()
                }
            }).catch((err) => { return })
        } else {
            if (objSend.user.length === 0) {
                notiError(t('TREATMENT_SUMMARY.POPUP.COMMISSION.ERROR.EMPTY_COM'))
            } else {
                await TreatmentSummaryApi.setCommission(objSend).then(async (res: any) => {
                    if (res.status === 201) {
                        await setDataCommisson()
                        setSum(true)
                        dispatch(closeModal())
                        props.onReset()
                    }
                }).catch((err) => { return })
            }
        }


    }

    useEffect(() => {
        if (sum && props?.onSave) {
            props?.onSave('save')
            setSum(false)
        }
    }, [sum])

    const setDataCommisson = async () => {
        const setData: any = treatments.summeryTreatments
        const newData = await setData.map((item: any) => {
            if (item.tempOrderTreatmentId === data.tempOrderId || item.tempOrderProductId === data.tempOrderId) {
                return { ...item, commission: sumAllCommisson() }
            } else {
                return item
            }
        })
        dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: newData }))
    }

    const sumAllCommisson = () => {
        const sum = dataCom.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.price, 0);
        return sum
    }

    const handlePageTable = () => {
        const page = comPage
        const pageLimit = comPageLimit
        const startIndex = (page - 1) * pageLimit;
        const endIndex = startIndex + pageLimit;
        const currentPageData = dataCom.slice(startIndex, endIndex);
        return currentPageData
    }

    const handleDelCom = (val: any) => {
        setDataCom((data: any) => {
            const newData = data.filter((item: any) => item !== val);
            return newData;
        });
    }

    const handleEditCom = (val: any) => {
        const employ = {
            fullname: val.fullname,
            position: val.position,
            userId: val.userId
        }
        setEmployeeEdit(val.userId)
        setEmployeeSelect(employ)
        setCommission(val.price)
        setRemark(val.remark)
        setIsEditTable(true)
    }

    const handleFloatCommision = (input: number) => {
        if (input?.toString()?.length && input?.toString()[input?.toString()?.length - 1] === '.') {
            const newInput: number = input
            return newInput
        } else {
            return Number(input)
        }
    }

    const headCells = [
        { id: 'no', disablePadding: false, label: t('#'), class: 'text-nowrap' },
        { id: 'employee', disablePadding: false, label: t('TREATMENT_SUMMARY.POPUP.COMMISSION.SALER'), class: 'text-nowrap' },
        { id: 'position', disablePadding: false, label: t('TREATMENT_SUMMARY.POPUP.COMMISSION.POSITION'), class: 'text-nowrap' },
        { id: 'price', disablePadding: false, label: t('TREATMENT_SUMMARY.POPUP.COMMISSION.COMMISSION'), class: 'text-nowrap' },
        { id: 'remark', disablePadding: false, label: t('TREATMENT_SUMMARY.POPUP.COMMISSION.REMARK'), class: '' },
        { id: 'updateBy', disablePadding: false, align: 'center', label: t('TREATMENT_SUMMARY.POPUP.COMMISSION.UPDATED_BY'), class: 'text-nowrap' },
        { id: 'action', disablePadding: false, align: 'center', label: 'Action' },
    ]

    const renderData = (data: any, no: number) => {

        const { fullname, position, price, remark, updateBy, updateDate } = data
        const num = comPage * comPageLimit - comPageLimit + no + 1
        const objRenderData = {
            key: num.toString(),
            id: no.toString(),
            obj: data,
            columns: [
                { option: 'TEXT', align: 'center', label: num, class: 'text-nowrap', style: { width: "200px" } },
                { option: 'TEXT', align: 'left', label: fullname || '-', class: 'text-nowrap' },
                { option: 'TEXT', align: 'left', label: position || '-', class: 'text-nowrap' },
                { option: 'TEXT', align: 'left', label: numberFormat(price) || 0, class: 'text-nowrap' },
                { option: 'TEXT', align: 'left', label: remark || '-', class: '' },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updateBy, updatedAt: updateDate } || '-', class: 'text-nowrap' },
                {
                    option: 'COMPONENT',
                    align: 'center',
                    label: 'action',
                    class: 'text-nowrap',
                    component: (
                        <Dropdown>
                            <Dropdown.Toggle variant="link" bsPrefix="p-0  " className="btn-table-action text-black-50 shadow-none">
                                <Icons.Meatball />
                            </Dropdown.Toggle>
                            {/* position-fixed dropdown-table-transformNone */}
                            <Dropdown.Menu
                                className="dropdown-table-action position-fixed dropdown-table-transformNone "
                                style={{ translate: `0 -${scrollTopBody}px` }}
                            >

                                <DropdownItem onClick={() => handleEditCom(data)} >
                                    <img src={icons.dropdownEdit} alt="" className="align-baseline" />
                                    <span className="ml-2 align-text-bottom">{t('BUTTON.EDIT')}</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => handleDelCom(data)} >
                                    <img src={icons.dropdownTrash} alt="" className="align-baseline" />
                                    <span className="ml-2 align-text-bottom">{t('BUTTON.DELETE_')}</span>
                                </DropdownItem>

                            </Dropdown.Menu>
                        </Dropdown>
                    )
                },
            ]
        }

        return (
            <>
                <TableRowCommon  {...objRenderData} />
            </>
        )
    }
    return (
        <>
            <ModalCustom
                title={t('TREATMENT_SUMMARY.POPUP.COMMISSION.TITLE')}
                alignFooter={'end'}
                size={'lg'}
                textBtnCancel={t('BUTTON.CANCEL')}
                disabledCancel={isEditTable}
                textBtnConfirm={t('BUTTON.SAVE')}
                disabledSubmit={isEditTable}
                onReset={() => {
                    dispatch(resetModal())
                    props.onReset()
                }}
                onSubmit={onSubmit}
                component={<>
                    <Box
                        onScroll={(e: any) => setScrollTopBody(e?.target?.scrollTop)}
                        className="modal-commisson-body custom-scroll"
                        sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 206px)' }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} className={'w-100'}>
                                <Typography className="d-sm-inline-block" sx={{ fontWeight: 500 }}>
                                    {`${t('TREATMENT_SUMMARY.POPUP.COMMISSION.OPERATIVE_ID')}: ${data?.code || ""}`}
                                </Typography>
                                <Typography className="d-sm-inline-block pl-sm-5" sx={{ fontWeight: 500 }}>
                                    {`${t('TREATMENT_SUMMARY.POPUP.COMMISSION.OPERATIVE_NAME')}: ${data?.itemName || ""}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} className={'w-100'}>
                                <InputTextField
                                    disabled
                                    value={data.price}
                                    label={`${t('TREATMENT_SUMMARY.POPUP.COMMISSION.PRICEPERBATH')} (${t('CURRENCY_CHANGE.TH')})`}
                                    inputTextAlign="left"
                                />
                            </Grid>
                            <Grid item xs={6} className={'w-100'}>
                                <InputTextField
                                    disabled
                                    value={data.qty}
                                    label={`${t('TREATMENT_SUMMARY.POPUP.COMMISSION.COUNT')}`}
                                    inputTextAlign="left"
                                />
                            </Grid>
                            <Grid item xs={12} className={'w-100'}>
                                <InputTextField
                                    disabled
                                    value={data.sum}
                                    label={`${t('TREATMENT_SUMMARY.POPUP.COMMISSION.PRICE_TOTAL')} (${t('CURRENCY_CHANGE.TH')})`}
                                    inputTextAlign="left"
                                />
                            </Grid>
                            <Grid item xs={12} className={'w-100'}>
                                <Typography className="mt-2" sx={{ fontWeight: 500 }}>
                                    {t('TREATMENT_SUMMARY.POPUP.COMMISSION.COMMISSION_SALE')}
                                </Typography>
                            </Grid>
                            <Grid item lg={12} className='' style={{ width: "100%" }}>
                                <AutocompleteSelect
                                    inputLabel={t('TREATMENT_SUMMARY.POPUP.COMMISSION.SALER')}
                                    labelId="productUnitId"
                                    noOptionsText={t('NOT_FOUND')}
                                    // disabled={isEditTable}
                                    options={employee}
                                    getOptionLabel={(option: any) => option.fullname}
                                    renderOption={(props, option: any) => {
                                        return (
                                            <Box component="li"
                                                {...props}
                                                key={`${props.key}${props["data-option-index"]}`}
                                                style={{
                                                    justifyContent: 'space-between',
                                                    display: _.some(dataCom, { userId: option.userId }) ? 'none' : 'flex'
                                                }}>
                                                {option.fullname}
                                            </Box>
                                        )
                                    }}
                                    onchange={(e, value: any) => {
                                        setEmployeeSelect(value)
                                    }}
                                    value={employeeSelect?.fullname ? _.find(employee, { userId: employeeSelect?.userId }) || '' : null}
                                />
                            </Grid>
                            <Grid item xs={12} className={'w-100'}>
                                <InputTextField
                                    value={commission}
                                    label={`${t('TREATMENT_SUMMARY.POPUP.COMMISSION.COMMISSION_PRICE')} (${t('CURRENCY_CHANGE.TH')})`}
                                    inputTextAlign="left"
                                    onchange={(e) => {
                                        const inputValue = e.target.value;
                                        const pattern = /^[0-9.]+$/;
                                        if (pattern.test(inputValue) && !isNaN(inputValue)) {
                                            const value: number = inputValue;
                                            const nonNegativeValue = value >= 0 ? handleFloatCommision(value) : 0;
                                            setCommission(nonNegativeValue);
                                        } else {
                                            const newData = e.target.value === "" ? 0 : commission
                                            setCommission(newData);
                                        }

                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} className={'w-100'}>
                                <InputTextarea
                                    label={t('MODAL_MEDICALCERTIFICATE_A5.FORM_COMMENT')}
                                    onchange={(e: any) => {
                                        setRemark(e.target.value)
                                    }}
                                    value={remark}
                                />
                            </Grid>
                            <Grid item xs={12} className="pl-4 text-right">
                                {!isEditTable &&
                                    <ButtonCustom
                                        style={{ height: "48px" }}
                                        onClick={() => { handleCommission("add") }}
                                        textButton={
                                            <div>
                                                <span className='mr-1' style={{ fontSize: "23px" }}>+</span>
                                                <span style={{ fontSize: "16px" }}>{t('TREATMENT_SUMMARY.POPUP.COMMISSION.ADD_COMMISSION')}</span>
                                            </div>
                                        }
                                    />
                                }
                                {isEditTable &&
                                    <div className='d-flex justify-content-end align-items-center'>
                                        <ButtonCustom
                                            variant="outlined"
                                            style={{ height: "48px", margin: "0px 5px 0 0" }}
                                            onClick={() => { resetInput() }}
                                            textButton={
                                                <div>
                                                    <span style={{ fontSize: "16px" }}>{t('TREATMENT_SUMMARY.POPUP.COMMISSION.CANCEL_FIX_COMMISSION')}</span>
                                                </div>
                                            }
                                        />
                                        <ButtonCustom
                                            style={{ height: "48px", margin: "0px" }}
                                            onClick={() => { handleCommission("edit") }}
                                            textButton={
                                                <div>
                                                    <span style={{ fontSize: "16px" }}>{t('TREATMENT_SUMMARY.POPUP.COMMISSION.SAVE_FIX_COMMISSION')}</span>
                                                </div>
                                            }
                                        />
                                    </div>

                                }
                            </Grid>
                            <Grid item xs={12} className={'w-100'}>
                                <TableCustom
                                    customScroll
                                    // hidePagination
                                    page={comPage}
                                    pageLimit={comPageLimit}
                                    sortType={sortType}
                                    sortBy={sortBy}
                                    rowCount={rowCount}
                                    onSort={onRequestSort}
                                    setPageLimit={handleChangeRowsPerPage}
                                    setPage={handleChangePage}
                                    headCells={headCells}
                                    rowsData={
                                        handlePageTable().map((item: any, index: number) => {
                                            return renderData(item, index)
                                        })
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </>}
            />
        </>
    )
}

