
const Seletor = (props: any) => {
    return (
        <>
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 9L0.00480915 0.75L12.9952 0.750001L6.5 9Z" fill="white" />
            </svg>
        </>
    )
}

export default Seletor