import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ExportProps } from 'features/report/report-constants';
import TableRowExport from 'component/Pdf/TableRowExport'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { Box, MenuItem, TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import ClinicApi, { BranchInterface, BillInterface } from 'api/master/clinic.api'
import ReportApi, { FindAllReportInterface } from 'api/report.api'

/** COMPONENT */
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import InputSecondNewDatePicker, { PICKER_VIEWS } from 'component/Input/InputSecondNewDatePicker'
import Loading from 'component/Loading'

/** REPORT */
import IncomeMonthlyList from './IncomeMonthlyList'

/** CONSTANTS */
import { getBranch, numberFormat, numberNonDigit, toBuddhistYear } from 'utils/app.utils'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import FucntionPrint from "component/Print/print"
import { dateToView } from 'utils/date.utils';

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { routeName } from 'routes/routes-name'
import IncomeMonthlyTotal from './incomeMonthlyTotal';


export default function IncomeMonthlyReport() {
  const { t } = useTranslation()
  const history = useHistory()
  const componentRef = useRef<HTMLDivElement>(null)
  const momentNow = moment().format('YYYY-MM-DD')
  const [loading, setLoading] = useState<boolean>(true);
  const [branches, setBranches] = useState<any[]>([])

  const [branchId, setBranchId] = useState<any>(getBranch())
  const [date, setDate] = useState<string>(momentNow)
  const [prefix, setPrefix] = useState<string>('ALL')
  const [receiptPrefix, setReceiptPrefix] = useState<any[]>([])

  const [incomePage, setIncomePage] = useState<number>(1)
  const [incomePageLimit, setIncomePageLimit] = useState<number>(10)
  const [incomeRowCount, setIncomeRowCount] = useState<number>(0)
  const [incomeSortBy, setIncomeSortBy] = useState<string>('createAt')
  const [incomeSortType, setIncomeSortType] = useState<string>('ASC')
  const [incomeData, setIncomeData] = useState<any[]>([])
  const [incomeExport, setIncomeExportData] = useState<any[]>([])

  const verifyPermission = async () => {
    const condition: FindAllReportInterface = {
      page: 1, pageLimit: 200
    }
    ReportApi.reportList(condition)
      .then(({ data }) => {
        const check = _.find(data, (val: any) => String(val.reportKey) === 'INCOME_SIMPLE_MONTHLY') ? true : false
        if (!check) {
          history.push(routeName.report)
          return false
        }
      })

    /** MASTER DATA */
    ClinicApi.findAllBranches()
      .then(({ data }) => setBranches(data))
      .catch((e) => { return })

    ClinicApi.allBillPrefix()
      .then(({ data }) => setReceiptPrefix(data))
      .catch((e) => { return })

    return true
  }

  useEffect(() => {
    verifyPermission()
  }, [])

  /** MEDICINE */
  const fetchIncome = useCallback(() => {
    let condition: FindAllReportInterface = {
      page: incomePage, pageLimit: incomePageLimit,
      branchId, prefix, date: date || momentNow
    }
    if (incomeSortType) condition = { ...condition, sortType: incomeSortType }
    if (incomeSortBy) condition = { ...condition, sortBy: incomeSortBy }

    ReportApi.reportIncomeMonthly(condition)
      .then(({ headers, data }) => {
        setIncomeData(data)
        setIncomeRowCount(headers['x-total'])
      }).catch((e) => setLoading(false))
      .finally(() => setLoading(false))

  }, [incomePage, incomePageLimit, incomeSortType, incomeSortBy, branchId, date, prefix])


  const filter = [
    `${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`,
    date,
    prefix === 'ALL' ? t(`REPORT.FILTER.ALL`) : prefix

  ]


  const onChangeIncomeSort = (sortByVal: string, sortTypeVal: string) => {
    setIncomeSortType(sortTypeVal)
    setIncomeSortBy(sortByVal)
  }

  const onChangeIncomeRowPerPage = (limit: number) => setIncomePageLimit(limit)

  const onChangeIncomePage = (val: number) => setIncomePage(val)

  const loadIncomeExport = useCallback(() => {
    const condition: FindAllReportInterface = {
      page: 1, pageLimit: 10000,
      branchId, prefix, date: date || momentNow,
      export: 1
    }

    ReportApi.reportIncomeMonthly(condition)
      .then(({ data }) => {
        setIncomeExportData(data)
      }).catch((e) => setLoading(false))
      .finally(() => setLoading(false))

  }, [branchId, date, prefix])

  const renderIncomeExport = (data: any, no: number) => {
    const num = no + 1
    return <>
      <TableRowExport
        key={num.toString()}
        id={no.toString()}
        obj={data}
        columns={[{ option: 'TEXT', align: 'left', label: dateToView(data.paidDate) || "-" },
        { option: 'TEXT', align: 'right', label: data.countPaid || "-" },
        { option: 'TEXT', align: 'right', label: numberNonDigit(data.countPaidCancel) || "-" },
        { option: 'TEXT', align: 'right', label: numberFormat(data.cancelPaid) || "-" },
        { option: 'TEXT', align: 'right', label: numberNonDigit(data.countPaidNormal) || "-" },
        { option: 'TEXT', align: 'right', label: data.cashPaid ? numberFormat(data.cashPaid) : "-" },
        { option: 'TEXT', align: 'right', label: data.transferPaid ? numberFormat(data.transferPaid) : "-" },
        { option: 'TEXT', align: 'right', label: data.creditPaid ? numberFormat(data.creditPaid) : "-" },
        { option: 'TEXT', align: 'right', label: data.debitPaid ? numberFormat(data.debitPaid) : "-" },
        { option: 'TEXT', align: 'right', label: data.advancePaid ? numberFormat(data.advancePaid) : "-" },
        { option: 'TEXT', align: 'right', label: data.treamentRightPaid ? numberFormat(data.treamentRightPaid) : "-" },
        { option: 'TEXT', align: 'right', label: numberFormat(data.totalPaid) || "-" },
        { option: 'TEXT', align: 'right', label: numberFormat(data.overDue) || "-" }
        ]}
      />
    </>
  }

  const renderSumIncomeExport = (data: any, no = 1) => {
    const num = no + 1
    return <>
      <TableRowExport
        key={num.toString()}
        id={no.toString()}
        obj={data}
        columns={[
          { option: 'TEXT', align: 'right', label: <Typography sx={{ fontWeight: 500, fontSize: 10 }}>{t('REPORT.TITLE.SUM')}</Typography>, class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberNonDigit(_.sumBy(data, (item: any) => Number(item.countPaid))) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberNonDigit(_.sumBy(data, (item: any) => Number(item.countPaidCancel))) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (item: any) => Number(item.cancelPaid))) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberNonDigit(_.sumBy(data, (item: any) => Number(item.countPaidNormal))) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'cashPaid')) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'transferPaid')) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'creditPaid')) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'debitPaid')) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'advancePaid')) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'treamentRightPaid')) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'totalPaid')) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'overDue')) || '-', class: 'text-nowrap' }
        ]}
      />
    </>
  }

  const renderSummaryIncomeExport = (data: any, no = 1) => {
    const num = no + 1
    return <>
      <TableRowExport
        key={num.toString()}
        id={no.toString()}
        obj={data}
        columns={[
          { option: 'TEXT', align: 'right', label: numberNonDigit(_.sumBy(data, (item: any) => Number(item.countPaid))) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberNonDigit(_.sumBy(data, (item: any) => Number(item.countPaidCancel))) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (item: any) => Number(item.cancelPaid))) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberNonDigit(_.sumBy(data, (item: any) => Number(item.countPaidNormal))) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'cashPaid')) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'transferPaid')) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'creditPaid')) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'debitPaid')) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'advancePaid')) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'treamentRightPaid')) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'totalPaid')) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'overDue')) || '-', class: 'text-nowrap' }
        ]}
      />
    </>
  }


  /** UseEffect */
  useEffect(() => {
    fetchIncome()
  }, [fetchIncome])

  useEffect(() => {
    loadIncomeExport()
  }, [loadIncomeExport])

  const { onDownload } = useDownloadExcel({
    tableExportRef: componentRef.current,
    filename: `${t(`REPORT.REPORT_NAME.INCOME_SIMPLE_MONTHLY`)} (${date}})`,
    sheetName: `${t(`REPORT.REPORT_NAME.INCOME_SIMPLE_MONTHLY`)}`
  })

  const headCells = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.DATE', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.QTY_RECEIPT', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.QTY_RECEIPT_CANCEL', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.NET_CANCEL', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.QTY_RECEIPT_NORMAL', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.CASH', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.TRANSFER', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.CREDIT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.DEBIT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.ADVANCE', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.TREATMENT_RIGHT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.TOTAL', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.OVERDUE', align: 'right', class: 'text-nowrap' }
  ]

  return (
    <Card className={'border-0 h-100'}>
      <HeaderCard text={t(`REPORT.REPORT_NAME.INCOME_SIMPLE_MONTHLY`)} />
      <Card.Body>
        <Card.Title className={'mb-0'}>
          <Row className='mx-0'>
            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
              <FilterSelect
                onchange={(e) => {
                  setBranchId(e.target.value)
                  setIncomePage(1)
                }}
                renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                label={''}
                selectId="select-branch"
                labelId="label-branch"
                value={branchId}
                options={
                  _.map(branches, (b: BranchInterface, index) => {
                    return (
                      <MenuItem key={b.branchId} value={b.branchId}>
                        {b.branchName || b.branchNameEn}
                      </MenuItem>
                    )
                  })
                }
                formControlStyle={{ maxWidth: 'unset !important' }}
                classesOption="style-select-doctors"
              />
            </Col>
            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
              <FilterSelect
                onchange={(e) => {
                  setPrefix(e.target.value)
                  setIncomePage(1)
                }}
                renderValue={() => `${t('REPORT.FILTER.RECEIPT_TYPE')}: ${prefix === 'ALL' ? t(`REPORT.FILTER.ALL`) : prefix} `}
                label={''}
                selectId="select-prefix"
                labelId="label-prefix"
                value={prefix}
                options={
                  [
                    <MenuItem key="0" value="ALL">
                      {t('REPORT.FILTER.ALL')}
                    </MenuItem>,
                    _.map(receiptPrefix, (rcf: BillInterface) => {
                      return (
                        <MenuItem key={rcf.billPrefixId} value={rcf.prefix}>
                          {rcf.prefix}
                        </MenuItem>
                      )
                    })
                  ]
                }
                formControlStyle={{ maxWidth: 'unset !important' }}
                classesOption="style-select-doctors"
              />
            </Col>
            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
              <InputSecondNewDatePicker
                dateFormat={`MMMM YYYY`}
                placeholder={t('REPORT.FILTER.DATE')}
                label={''}
                inputHeight={32}
                value={date}
                onchange={(e: any) => {
                  setDate(moment(e).format('YYYY-MM-DD'))
                  setIncomePage(1)
                }}
                views={PICKER_VIEWS.MONTH}
              />
            </Col>
            <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
              <UseStyled.DropdownDocument>
                <Dropdown.Toggle id="dropdown-basic" className='ml-xl-auto'>
                  {t('PRINT_DOCUMENT')}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={onDownload}>
                    {t('Excel')}
                  </Dropdown.Item>
                  <FucntionPrint
                    content={() => componentRef.current}
                    removeIfram
                    trigger={() => <Dropdown.Item >
                      {t('PDF')}
                    </Dropdown.Item>}
                    style={`@page { size: 1122.519685px 793.7007874px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                  />
                </Dropdown.Menu>
              </UseStyled.DropdownDocument>
            </Col>
          </Row>
        </Card.Title>

        <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
          <IncomeMonthlyList
            page={incomePage}
            pageLimit={incomePageLimit}
            rowCount={incomeRowCount}
            sortBy={incomeSortBy}
            sortType={incomeSortType}
            data={incomeData}
            onRequestSort={onChangeIncomeSort}
            handleChangeRowsPerPage={onChangeIncomeRowPerPage}
            handleChangePage={onChangeIncomePage} />
        </Box>

        <Box>
          <Typography className='pb-1' sx={{ fontWeight: 500 }}>{t('REPORT.TITLE.INCOME_SIMPLE_MONTHLY_TOTAL')}</Typography>
          <IncomeMonthlyTotal
            page={incomePage}
            pageLimit={incomePageLimit}
            rowCount={incomeRowCount}
            sortBy={incomeSortBy}
            sortType={incomeSortType}
            data={incomeExport}
            onRequestSort={onChangeIncomeSort}
            handleChangeRowsPerPage={onChangeIncomeRowPerPage}
            handleChangePage={onChangeIncomePage} />
        </Box>
      </Card.Body>
      <Loading show={loading} type='fullLoading' />

      <div className="print-show" ref={componentRef}>
        <ExportData
          filter={filter}
          headCells={headCells}
          rowsDataSeconds={incomeExport}
          rowsData={
            [
              _.map(incomeExport, (d: any, index: number) => {
                return renderIncomeExport(d, index)
              }),
              renderSumIncomeExport(incomeExport),
              renderSummaryIncomeExport(incomeExport)
            ]
          } />
      </div>
    </Card>
  )
}

export function ExportData(props: ExportProps) {
  const { t } = useTranslation()
  const head = props.headCells
  const rowsDataSeconds = props.rowsDataSeconds
  const headCells = head.filter((headCell: any) => headCell.id !== 'action')

  const FILTER_TITLES = [
    { key: 'BRANCH', label: 'REPORT.FILTER.BRANCH' },
    { key: 'MONTH', label: 'REPORT.FILTER.MONTH' },
    { key: 'RECEIPT_TYPE', label: 'REPORT.FILTER.RECEIPT_TYPE' },
  ];
  type DataItem = {
    countPaid: string;
    countPaidNormal: string;
    countPaidCancel: string;
  };
  const headCellsTotal = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.QTY_RECEIPT', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.QTY_RECEIPT_CANCEL', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.NET_CANCEL', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.QTY_RECEIPT_NORMAL', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.CASH', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.TRANSFER', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.CREDIT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.DEBIT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.ADVANCE', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.TREATMENT_RIGHT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.TOTAL', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.OVERDUE', align: 'right', class: 'text-nowrap' }
  ]

  const renderValue = (value: any, index: any) => {
    if (index === 1) {
      return toBuddhistYear(moment(value), 'MMMM YYYY');
    }
    return value;
  };

  return (
    <>
      <TableHead style={{ fontWeight: 800 }} >{t('REPORT.REPORT_NAME.INCOME_SIMPLE_MONTHLY')}</TableHead>
      <table>
        <tr>
          {FILTER_TITLES.map((filter, index) => (
            <TableSortLabel key={index} >
              <td className='mr-2' style={{ fontSize: 10, fontWeight: 800 }}>{t(filter.label)} : </td>
              <td style={{ fontSize: 10, fontWeight: 500 }}>{renderValue(props.filter[index], index)}</td>
            </TableSortLabel >
          ))}
        </tr>
      </table>
      <UseStyled.Styles className="pt-3">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            <tr>
              {_.map(headCells, (val: any, index: number) => {
                return (
                  < td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {props.rowsData.slice(0, props.rowsData.length - 1).map((row: any, index: number) => (row))}
            {!props.rowsData.length && (
              <td colSpan={headCells.length} style={{ fontSize: 10, }}>
                {t('REPORT.TITLE.NO_DATA')}
              </td>
            )}
          </tbody>
        </table>
      </UseStyled.Styles >


      <Typography className='pt-5' sx={{ fontWeight: 500 }}>{t('REPORT.TITLE.INCOME_SIMPLE_MONTHLY_TOTAL')}</Typography>
      <UseStyled.Styles className="pt-1">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            <tr>
              {_.map(headCells, (val: any, index: number) => {
                return index !== 0 && (
                  < td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {props.rowsData[props.rowsData.length - 1]}
          </tbody>
        </table>
      </UseStyled.Styles >
    </>
  )
}