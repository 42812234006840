import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface ExpertInterface {
  expertName: string
  expertNameEn: string
  status: string
}

export default class ExpertApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('experts', { params: { ...props } }).then((res) => res)
  }

  static create(expertName: string, expertNameEn: string) {
    return this.api.post('experts', { expertName, expertNameEn })
  }

  static findById(id: number): Promise<ExpertInterface> {
    return this.api.get(`experts/${id}`)
  }

  static update(id: number, body: ExpertInterface) {
    return this.api.patch(`experts/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`experts/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`experts/${id}`)
  }
}
