import { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row, Dropdown } from 'react-bootstrap'
import { Box, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import ReceiveLab from 'features/lab-order/component/ReceiveLab'

/** API */
import LabOrderApi, { LabOrderListInterface } from 'api/lab-order.api'

/** COMPONENT */
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import InputTextSearch from 'component/Input/InputTextSearch'
import TableCustom, { TablePaginationMiniCustom } from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import ViewLabOrder from 'features/lab-order/component/ViewLabOrder'

/** STYLE */
import { colors } from 'constants/theme'
import * as UseStyled from 'features/lab-order/lab-order-style'

/** UTILS & CONSTANT */
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import { routeName } from 'routes/routes-name'
import { numberToCurrencyFormat } from 'utils/app.utils'
import UseWindowSize from 'utils/useWindowSize'
import { dateTimeToView, dateToView } from 'utils/date.utils'
import { handleShow, showModal, resetModal } from 'app/slice/modal.slice'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import LabCompanyApi from 'api/setting/default-labs/lab-company.api'
import FilterSelect from 'component/Select/FilterSelect'
import { MenuItem } from '@mui/material'
import ButtonCustom from 'component/Button/ButtonCustom'
import FormLabOrder from 'features/lab-order/component/FormLabOrder'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { swalDelete } from 'component/Alert/Swal'
import CancelLabOrder from 'features/lab-order/component/CancelLabOrder'
import { icons, Icons } from 'constants/images'
import { fileUrl } from 'api/api'
import Loading from 'component/Loading'
import { expired } from 'app/slice/expired.slice'
import RejectLabOrder from './component/RejectLabOrder'
import { UseSnCode } from 'utils/useSnCode'
import ClinicApi from 'api/master/clinic.api'
import FunctionPrint from 'component/Print/print'
import PDFLabOrder from 'component/Pdf/PDFLabOrder'

const arrayLabOrderStatus: string[] = ['ORDER_LAB', 'SEND_LAB', 'RECEIVE_LAB', 'REPLY_LAB', 'RECEIVE_BILL', 'CANCEL_LAB', 'CLOSE_LAB',]// 'REPLY_LAB'
export interface ILabOrderListProps {
    date?: string
    valReload: any
    reload: () => void
    reloadQueue?: (va: boolean) => void
}
export default function LabOrderList(props: ILabOrderListProps) {
    const { t } = useTranslation()
    const modal = useSelector(handleShow)
    const history = useHistory()
    const dispatch = useDispatch()
    const expire = useSelector(expired)
    const [width] = UseWindowSize()
    const [loading, setLoading] = useState<boolean>(false)
    const permissions = { isCreate: isCreate(), isDelete: isDelete(), isUpdate: isUpdate(), isView: isView() }

    const [isViewDetail, setIsViewDetail] = useState<boolean>(false)
    const [isForm, setIsForm] = useState<boolean>(false)
    const [popupCancel, setPopupCancel] = useState<boolean>(false)
    const [popupReject, setPopupReject] = useState<boolean>(false)
    const [labOrderId, setLabOrderId] = useState<number>(0)
    const [labOrderNumber, setLabOrderNumber] = useState<string>('')
    const [labOrderStatus, setLabOrderStatus] = useState('')
    const [patientId, setPatientId] = useState(0)

    /** FILTER */
    const [filterSearch, setFilterSearch] = useState<string>('')
    const [filterRangeDate, setFilterRangeDate] = useState<string[]>(emptyRangePicker)
    const [filterLabCompany, setFilterLabCompany] = useState<number>(0)
    const [filterLabOrderStatus, setFilterLabOrderStatus] = useState<string>('ALL')
    const [page, setPage] = useState(1)
    useEffect(() => { setPageLimit(width > 1440 ? 10 : 3) }, [width])
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('dateOrder')
    const [sortType, setSortType] = useState('DESC')
    const [doctorOptions, setDoctorOptions] = useState<any[]>([])
    const [filterDoctorId, setFilterDoctorId] = useState<number>(0)

    /** OPTION */
    const [labCompanyOptions, setLabCompanyOptions] = useState<any[]>([])

    /** DATA */
    const [labOrders, setLabOrders] = useState<any[]>([])
    const [popupReceive, setPopupReceive] = useState<boolean>(false)
    const [dateReceive, setDateReceive] = useState<string | null>(null)

    const [snType, setSnType] = useState(false);

    const [pdfLabOrder, setPdfLabOrder] = useState<boolean>(false)
    const [pdfDataLabOrder, setPdfDataLabOrder] = useState<any>({})
    const pdfLabOrderRef = useRef<HTMLDivElement>(null)
    const buttonRef = useRef<HTMLDivElement>(null)

    const handleSnCode = useCallback(() => {
        const fetchData = async () => {
            const sn = await UseSnCode();
            setSnType(sn);
        };

        fetchData();

    }, [snType]);

    useEffect(() => {
        handleSnCode()
    }, []);

    const loadData = useCallback(async () => {
        setLoading(true)
        let condition: any = {}
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (filterSearch) condition = { ...condition, search: filterSearch }
        if (filterLabCompany) condition = { ...condition, labCompanyId: filterLabCompany }
        if (filterLabOrderStatus && filterLabOrderStatus !== 'ALL') condition = { ...condition, labOrderStatus: filterLabOrderStatus }
        if (filterRangeDate) {
            if ((filterRangeDate[0] !== null || filterRangeDate[0] !== '') && (filterRangeDate[1] !== null || filterRangeDate[1] !== ''))
                condition = { ...condition, dateStart: filterRangeDate[0], dateEnd: filterRangeDate[1] }
        }
        if (filterDoctorId) {
            condition = { ...condition, doctorId: filterDoctorId }
        }

        const patients = await LabOrderApi.findAll(condition).finally(() => setLoading(false))
        if (patients.status === 200) {
            setLabOrders(patients.data)
            setRowCount(patients.headers['x-total'])
        }
        props.reload()
        setLabOrderId(0)
        setIsForm(false)
        setPopupCancel(false)
    }, [props.valReload, page, pageLimit, sortBy, sortType, filterSearch, filterRangeDate, filterLabCompany, filterLabOrderStatus, filterDoctorId])

    const loadOptions = async () => {
        const condition: any = { page: 1, pageLimit: 50, status: 'ACTIVE' }
        await LabCompanyApi.findAll(condition).then(({ data }) => {
            setLabCompanyOptions(data)
        })
        await ClinicApi.findAllDoctors().then(({ data }) => {
            setDoctorOptions(data)
        })
    }

    useEffect(() => {
        loadData()
    }, [loadData])

    useEffect(() => { loadOptions() }, [])

    const onView = (e: any, objVal: LabOrderListInterface) => {
        setLabOrderId(objVal.labOrderId)
        setIsViewDetail(true)
        dispatch(showModal())
    }

    const onClose = () => {
        setLabOrderId(0)
        setIsViewDetail(false)

    }

    const onCreate = () => {
        setIsForm(true)
        dispatch(showModal())
    }

    const onEdit = (objData: LabOrderListInterface) => {
        const { labOrderId: id } = objData
        setLabOrderId(id)
        dispatch(showModal())
        setIsForm(true)
    }

    const onDelete = (objData: LabOrderListInterface) => {
        const { labOrderId: id, labOrderNumber: labOrderNum } = objData
        swalDelete(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('LAB_ORDER.ALERT.CONFIRM_DELETE')} ${t('LAB_ORDER.MESSAGE.YESNO')}</p>`,
            `<p class="text-center mb-0 mx-auto" style="max-width: 306px">${t('LAB_ORDER.MESSAGE.DEL_SUB')} ${labOrderNum} <br /> ${t('LAB_ORDER.MESSAGE.YESNO')}</p>`,
            (res: any) => {
                if (res) {
                    LabOrderApi.remove(id)
                        .then((resp) => (notiSuccess(t('LAB_ORDER.MESSAGE.SUCCESS.DELETE'), '', null, () => loadData())))
                        .catch((e) => (notiError(t('LAB_ORDER.MESSAGE.ERROR'))))
                }
            },
            { confirmButtonText: t('LAB_ORDER.ALERT.BTN_CONFIRM_DELETE'), width: '450px', cancelButtonText: t('BUTTON.CANCEL') }
        )
    }

    const onCancel = (objData: LabOrderListInterface) => {
        const { labOrderId: id, labOrderNumber: lbNo } = objData
        setLabOrderId(id)
        setLabOrderNumber(lbNo)
        dispatch(showModal())
        setPopupCancel(true)
    }

    const onReject = (objData: LabOrderListInterface) => {
        const { labOrderId: id, labOrderNumber: lbNo, labOrderStatus, userId, patientId, labCompanyId, labGroupId } = objData
        setLabOrderId(id)
        setLabOrderNumber(lbNo)
        setLabOrderStatus(getLabelLabOrderStatus(labOrderStatus) || '-')
        setPatientId(patientId)
        dispatch(showModal())
        setPopupReject(true)
    }

    const reset = async (objData: LabOrderListInterface) => {
        const { labOrderId: id } = objData
        await LabOrderApi.reset(id).then((res) => {
            if (res.status !== undefined && res.status === 200) {
                notiSuccess(t(`LAB_ORDER.MESSAGE.SUCCESS.RESET_LAB`), '', null, null, 'register-page')
            }
        }).finally(() => { loadData() })
    }

    const onReset = () => {
        setLabOrderId(0)
        setIsForm(false)
        setPopupCancel(false)
        setDateReceive(null)
        setPopupReceive(false)
        dispatch(resetModal())
    }

    const onViewHistory = (objData: LabOrderListInterface) => {
        history.push(routeName.labOrder + '/view-history', objData)
    }

    const onPrintLabDoc = async (valueLabOrderId: number) => {
        setLoading(true)
        const receipt = await LabOrderApi.printLabDocument(valueLabOrderId)

        if (receipt?.status === 200) {
            setPdfDataLabOrder(receipt.data)

            setTimeout(() => {
                buttonRef.current?.click()
                setLoading(false)
            }, 0)
            return
        }

        setLoading(false)
        // if (receipt && receipt.status === 200) return window.open(`${fileUrl}/${receipt.data}`, '_blank')
    }

    const desktopHeadCells = [
        { id: 'no', disablePadding: false, label: t('#'), class: 'text-nowrap' },
        { id: 'dateOrder', disablePadding: false, label: t('LAB_ORDER.TABLE.DATE_TIME_ORDER'), class: 'text-nowrap' },
        { id: 'labOrderNumber', disablePadding: false, label: t('LAB_ORDER.TABLE.LAB_ORDER_NUMBER'), class: 'text-nowrap' },
        { id: 'labCompanyName', disablePadding: false, label: t('LAB_ORDER.TABLE.COMPANY'), class: 'text-nowrap' },
        { id: 'dateExport', disablePadding: false, label: t('LAB_ORDER.TABLE.DATE_EXPORT'), class: 'text-nowrap' },
        { id: 'dateReceive', disablePadding: false, label: t('LAB_ORDER.TABLE.DATE_RECEIVE'), class: 'text-nowrap' },
        { id: 'message', disablePadding: false, label: t('APPLICATION.TABLE.CELL.CN_NUMBER'), class: 'text-nowrap' },
        { id: 'branchId', disablePadding: false, label: t('PATIENT_MANAGEMENT.TABLE.CELL.BRANCH_ID'), class: 'text-nowrap' },
        { id: 'branchId', disablePadding: false, label: t('PATIENT_MANAGEMENT.TABLE.CELL.SN_CODE'), class: 'text-nowrap' },
        { id: 'patientFullName', disablePadding: false, label: t('LAB_ORDER.TABLE.PATIENT_NAME'), class: 'text-nowrap' },
        { id: 'doctorFullName', disablePadding: false, label: t('LAB_ORDER.TABLE.DOCTOR_NAME'), class: 'text-nowrap' },
        { id: 'labOrderStatus', disablePadding: false, label: t('LAB_ORDER.TABLE.STATUS'), class: 'text-nowrap' },
        { id: 'labOrderPrice', disablePadding: false, label: t('LAB_ORDER.TABLE.PRICE'), class: 'text-nowrap' },
        { id: 'staffBy', disablePadding: false, label: t('LAB_ORDER.TABLE.SAVED_BY'), class: 'text-nowrap' },
    ]
    if (!snType) {
        desktopHeadCells.splice(8, 1)
    }
    const tableHeadCells = [
        { id: 'action', disablePadding: false, label: t('LAB_ORDER.TABLE.ACTION'), class: 'text-nowrap' },
    ]

    const headCells = width >= 1200 ? [...desktopHeadCells, ...tableHeadCells] : [...tableHeadCells]

    const getLabelLabOrderStatus = (status: string) => {
        let label: any = ''
        if (status === 'ORDER_LAB') label = t('LAB_ORDER.LAB_STATUS.ORDER_LAB')
        else if (status === 'SEND_LAB') label = t('LAB_ORDER.LAB_STATUS.SEND_LAB')
        else if (status === 'RECEIVE_LAB') label = t('LAB_ORDER.LAB_STATUS.RECEIVE_LAB')
        else if (status === 'RECEIVE_BILL') label = t('LAB_ORDER.LAB_STATUS.RECEIVE_BILL')
        else if (status === 'REPLY_LAB') label = t('LAB_ORDER.LAB_STATUS.REPLY_LAB')
        else if (status === 'CANCEL_LAB') label = t('LAB_ORDER.LAB_STATUS.CANCEL_LAB')
        else if (status === 'CLOSE_LAB') label = t('LAB_ORDER.LAB_STATUS.CLOSE_LAB')
        return label
    }

    const onReceive = (objData: LabOrderListInterface) => {
        const { labOrderId: id, labOrderNumber, dateReceive: dateRe, labOrderStatus: status } = objData
        if (status === 'RECEIVE_BILL') {
            notiError('LAB_ORDER.MESSAGE.ERROR.RECEIVE_BILL')
            return false
        }
        setLabOrderId(id)
        setDateReceive(dateRe)

        dispatch(showModal())
        setPopupReceive(true)
    }

    const renderData = (objData: LabOrderListInterface, no: number) => {
        no = page * pageLimit - pageLimit + no + 1
        const { labOrderId: id, dateOrder, timeOrder, labOrderNumber: labOrderNumberList, labCompanyName, dateExport, dateImport, dateBill, cnNumber, branchCnNumber, snCode, patientFullName, labOrderPrice, labOrderStatus, updatedBy, updatedAt, doctorFullName } = objData

        const receiveLab = labOrderStatus === 'ORDER_LAB' || labOrderStatus === 'RECEIVE_LAB' || labOrderStatus === 'REPLY_LAB' ? [
            {
                option: 'COMPONANT',
                label: (
                    <DropdownItem onClick={() => onReceive(objData)} disabled={permissions.isUpdate.disabled}>
                        <span className="ml-2 align-text-bottom">{t('LAB_ORDER.QUEUE.RECEIVE_LABS')}</span>
                    </DropdownItem>
                )
            },
            { option: 'DIVIDER', label: '', disabled: false },
        ] : []

        const objRenderData = {
            key: String(no),
            id: String(id),
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: no },
                { option: 'TEXT', align: 'center', label: dateTimeToView(`${dateOrder} ${timeOrder}`, false) || '-', class: 'text-nowrap-ellipsis' },
                { option: 'TEXT', align: 'center', label: labOrderNumberList || '-', class: 'text-nowrap-ellipsis ' },
                { option: 'TEXT', align: 'left', label: labCompanyName || '-', class: 'text-nowrap-ellipsis labCompanyName' },
                { option: 'TEXT', align: 'center', label: dateToView(dateExport, false) || '-', class: 'text-nowrap-ellipsis' },
                { option: 'TEXT', align: 'center', label: dateToView(dateImport, false) || '-', class: 'text-nowrap-ellipsis' },
                { option: 'TEXT', align: 'left', label: cnNumber || '-', class: 'text-nowrap-ellipsis' },
                { option: 'TEXT', align: 'left', label: branchCnNumber || '-', class: 'text-nowrap-ellipsis' },
                { option: 'TEXT', align: 'left', label: snCode || '-', class: 'text-nowrap-ellipsis' },
                { option: 'TEXT', align: 'left', label: patientFullName || '-', class: 'text-nowrap-ellipsis patientFullName' },
                { option: 'TEXT', align: 'left', label: doctorFullName || '-', class: 'text-nowrap-ellipsis ' },
                { option: 'TEXT', align: 'center', label: getLabelLabOrderStatus(labOrderStatus) || '-', class: 'text-nowrap-ellipsis' },
                { option: 'TEXT', align: 'right', label: numberToCurrencyFormat(labOrderPrice || 0), class: 'text-nowrap-ellipsis' },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy, updatedAt } },
                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: [
                        ...receiveLab,
                        {
                            option: 'COMPONANT', label: (
                                <DropdownItem onClick={() => onReject(objData)} disabled={permissions.isUpdate.disabled || !['ORDER_LAB', 'SEND_LAB', 'RECEIVE_LAB', 'CLOSE_LAB'].includes(labOrderStatus)}>
                                    <span className="ml-2 align-text-bottom">{t('LAB_ORDER.LAB_STATUS.BOUNCE_LAB')}</span>
                                </DropdownItem>
                            )
                        },
                        {
                            option: 'COMPONANT', label: (
                                <DropdownItem onClick={() => reset(objData)} disabled={permissions.isUpdate.disabled || labOrderStatus !== 'CANCEL_LAB'} >
                                    <span className="ml-2 align-text-bottom">{t('LAB_ORDER.LAB_STATUS.RESET_LAB')}</span>
                                </DropdownItem >
                            )
                        },
                        {
                            option: 'COMPONANT', label: (
                                <DropdownItem onClick={() => onCancel(objData)} disabled={permissions.isUpdate.disabled || labOrderStatus === 'CANCEL_LAB'} >
                                    <span className="ml-2 align-text-bottom">{t('LAB_ORDER.LAB_STATUS.CANCEL_LAB')}</span>
                                </DropdownItem >
                            )
                        },
                        { option: 'DIVIDER', label: '', disabled: false },
                        {
                            option: 'COMPONANT', label: (
                                <DropdownItem onClick={() => onViewHistory(objData)} disabled={permissions.isUpdate.disabled}>
                                    <span className="ml-2 align-text-bottom">{t('LAB_ORDER.LAB_STATUS.VIEW_HISTORY_LAB')}</span>
                                </DropdownItem>
                            )
                        },
                        {
                            option: 'COMPONANT', label: (
                                <DropdownItem onClick={() => onPrintLabDoc(objData.labOrderId)} disabled={permissions.isUpdate.disabled}>
                                    <span className="ml-2 align-text-bottom">{t('LAB_ORDER.LAB_STATUS.PRINT_LAB')}</span>
                                </DropdownItem>
                            )
                        },
                        { option: 'DIVIDER', label: '', disabled: false },
                        { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled || labOrderStatus !== 'ORDER_LAB' },
                        { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled || labOrderStatus !== 'ORDER_LAB' }
                    ]
                },
            ]
        }
        if (!snType) {
            objRenderData.columns.splice(8, 1)
        }
        return <TableRowCommon rowLink={true} {...objRenderData} onClick={(e, val) => onView(e, val)} onedit={() => onEdit(objData)} ondelete={() => onDelete(objData)} />
    }

    const renderDataSlim = (objData: LabOrderListInterface, no: number) => {
        no = page * pageLimit - pageLimit + no + 1
        const { dateOrder, timeOrder, labOrderNumber: labOrderNumberList, labCompanyName, dateExport, dateImport, dateBill, cnNumber, branchCnNumber, snCode, patientFullName, labOrderPrice, labOrderStatus, updatedBy } = objData
        return (
            <UseStyled.TBody>
                <div className={'d-flex align-items-center position-relative'}>
                    <UseStyled.Cell>
                        <Row className="pl-4 mx-0">
                            <div className="position-absolute w-auto px-0" style={{ marginLeft: '-1.5rem' }}>
                                {no}
                            </div>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.DATE_TIME_ORDER')}</Col>
                            <Col xs={6} md={9} lg={6} xxl={3} className="px-1 ">{dateTimeToView(`${dateOrder} ${timeOrder}`)}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.LAB_ORDER_NUMBER')}</Col>
                            <Col xs={6} md={9} lg={6} xxl={3} className="px-1"><span style={{ cursor: 'pointer', color: colors.themeSecondColor, textDecoration: 'underline' }} onClick={(e: any) => onView(e, objData)}>{labOrderNumberList}</span></Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.COMPANY')}</Col>
                            <Col xs={6} md={9} lg={6} xxl={9} className="px-1">{labCompanyName}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.DATE_EXPORT')}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1">{dateToView(dateExport) || '-'}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.DATE_RECEIVE')}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1">{dateToView(dateImport) || '-'}</Col>

                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('APPLICATION.TABLE.CELL.CN_NUMBER')}</Col>
                            <Col xs={6} md={9} lg={6} xxl={9} className="px-1">{cnNumber || '-'}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('PATIENT_MANAGEMENT.TABLE.CELL.BRANCH_ID')}</Col>
                            <Col xs={6} md={9} lg={6} xxl={9} className="px-1">{branchCnNumber || '-'}</Col>


                            {snType && (
                                <>
                                    <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('PATIENT_MANAGEMENT.TABLE.CELL.SN_CODE')}</Col>
                                    <Col xs={6} md={9} lg={6} xxl={9} className="px-1">{snCode || '-'}</Col>
                                </>
                            )}
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.PATIENT_NAME')}</Col>
                            <Col xs={6} md={9} lg={6} xxl={9} className="px-1">{patientFullName || ''}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.STATUS')}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1">{getLabelLabOrderStatus(labOrderStatus) || ''}</Col>

                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.PRICE')}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1">{numberToCurrencyFormat(labOrderPrice || 0)}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.SAVED_BY')}</Col>
                            <Col xs={6} md={9} lg={6} xxl={3} className="px-1 text-nowrap-ellipsis">{updatedBy}</Col>
                            <Col xs={6} md={3} lg={6} xxl={3} className="px-1 fontweght-bold">{t('LAB_ORDER.TABLE.ACTION')}</Col>
                            <Col xs={6} md={9} lg={6} xxl={3} className="px-1 text-nowrap-ellipsis">
                                <Dropdown>
                                    <Dropdown.Toggle variant="link" bsPrefix="p-0  " className="btn-table-action text-black-50 shadow-none">
                                        <Icons.Meatball />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-table-action position-fixed">
                                        {
                                            labOrderStatus === 'ORDER_LAB' || labOrderStatus === 'RECEIVE_LAB' ? (
                                                <>
                                                    <DropdownItem onClick={() => onReceive(objData)} disabled={permissions.isUpdate.disabled}>
                                                        <span className="ml-2 align-text-bottom">{t('LAB_ORDER.QUEUE.RECEIVE_LABS')}</span>
                                                    </DropdownItem>
                                                    <Dropdown.Divider style={{ backgroundColor: 'transparent', borderColor: colors.lightGray, opacity: 1 }} />
                                                </>
                                            ) : <></>
                                        }
                                        <DropdownItem onClick={() => onCancel(objData)} disabled={permissions.isUpdate.disabled}>
                                            <span className="ml-2 align-text-bottom">{t('LAB_ORDER.LAB_STATUS.RESET_LAB')}</span>
                                        </DropdownItem>
                                        <DropdownItem onClick={() => onViewHistory(objData)} disabled={permissions.isUpdate.disabled}>
                                            <span className="ml-2 align-text-bottom">{t('LAB_ORDER.LAB_STATUS.VIEW_HISTORY_LAB')}</span>
                                        </DropdownItem>
                                        <DropdownItem onClick={() => onPrintLabDoc(objData.labOrderId)} disabled={permissions.isUpdate.disabled}>
                                            <span className="ml-2 align-text-bottom">{t('LAB_ORDER.LAB_STATUS.PRINT_LAB')}</span>
                                        </DropdownItem>
                                        <Dropdown.Divider style={{ backgroundColor: 'transparent', borderColor: colors.lightGray, opacity: 1 }} />
                                        <DropdownItem onClick={() => onEdit(objData)} disabled={permissions.isUpdate.disabled || labOrderStatus !== 'ORDER_LAB'}>
                                            <img src={icons.dropdownEdit} alt="" className="align-baseline" />
                                            <span className="ml-2 align-text-bottom">{t(`BUTTON.EDIT`)}</span>
                                        </DropdownItem>
                                        <DropdownItem onClick={() => onDelete(objData)} disabled={permissions.isUpdate.disabled || labOrderStatus !== 'ORDER_LAB'}>
                                            <img src={icons.dropdownTrash} alt="" className="align-baseline" />
                                            <span className="ml-2 align-text-bottom">{t(`BUTTON.DELETE`)}</span>
                                        </DropdownItem>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                    </UseStyled.Cell>
                </div>
            </UseStyled.TBody>
        )
    }

    useEffect(() => { if (!modal) onReset() }, [modal]);

    return (
        <>
            <Loading show={loading} type="fullLoading" />
            <UseStyled.TableConfig expiredAlert={expire} className="h-100 d-flex flex-column overflow-hidden">
                <div className="px-3 py-2 -sticky-top" style={{ boxShadow: `0px 3px 6px ${colors.black08}` }}>
                    <h5>{t('LAB_ORDER.HEADER')}</h5>
                </div>
                <div className='p-sm-2 p-xxl-3 h-100 d-flex flex-column overflow-hidden'>
                    <Grid container spacing={1}>
                        <Grid item xs={6} xl={4} className='w-100'>
                            <InputTextSearch
                                keyInput={'search'}
                                label={t('LAB_ORDER.LIST.SEARCH')}
                                value={filterSearch}
                                onchange={(event) => {
                                    setFilterSearch(event.target.value)
                                    setPage(1)
                                }}
                                onClear={(event) => {
                                    setFilterSearch('')
                                    setPage(1)
                                }} />
                        </Grid>
                        <Grid item xs={6} xl={4} className='w-100'>
                            <FilterSelect
                                fullWidth
                                renderValue={() => `${t('LAB_ORDER.LIST.COMPANY')}: ${_.find(labCompanyOptions, (labCompany: any) => labCompany.labCompanyId === filterLabCompany)?.labCompanyName || t('ALL')}`}
                                label={t('LAB_ORDER.LIST.COMPANY')}
                                selectId="select-status"
                                value={filterLabCompany}
                                onchange={(event) => {
                                    setFilterLabCompany(event.target.value)
                                    setPage(1)
                                }}
                                labelId="label-status"
                                options={[
                                    <MenuItem key={'all'} value={0}>
                                        {t('ALL')}
                                    </MenuItem>,
                                    _.map(labCompanyOptions, (labCompany: any, i: number) => (
                                        <MenuItem key={i} value={labCompany.labCompanyId}>
                                            {labCompany.labCompanyName}
                                        </MenuItem>
                                    ))
                                ]}
                                formControlStyle={{ maxWidth: 'unset !important' }}
                            />
                        </Grid>
                        <Grid item xs={6} xl={4} className='w-100'>
                            <FilterSelect
                                fullWidth-
                                renderValue={() => `${t('LAB_ORDER.LIST.STATUS')}: ${getLabelLabOrderStatus(_.find(arrayLabOrderStatus, (statusLab: any) => statusLab === filterLabOrderStatus) || '') || t('ALL')}`}
                                label={t('LAB_ORDER.LIST.COMPANY')}
                                selectId="select-status"
                                value={filterLabOrderStatus}
                                onchange={(event) => {
                                    setFilterLabOrderStatus(event.target.value)
                                    setPage(1)
                                }}
                                labelId="label-status"
                                options={[
                                    <MenuItem key={'all'} value={0}>
                                        {t('ALL')}
                                    </MenuItem>,
                                    _.map(arrayLabOrderStatus, (statusLab: any, i: number) => {
                                        return statusLab !== 'CLOSE_LAB' && (
                                            <MenuItem key={i} value={statusLab}>
                                                {getLabelLabOrderStatus(statusLab)}
                                            </MenuItem> || <></>
                                        )
                                    })
                                ]}
                                formControlStyle={{ maxWidth: 'unset !important' }}
                            />
                        </Grid>
                        <Grid item xs={6} xl={4} className='w-100'>
                            <InputSecondNewRangePicker
                                value={filterRangeDate || []}
                                inputHeight={32}
                                onchange={(val: any) => {
                                    if (_.isEmpty(val)) setFilterRangeDate(emptyRangePicker)
                                    else {
                                        setFilterRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                                        setPage(1)
                                    }
                                }}
                                allowClear
                                onClear={() => setFilterRangeDate(emptyRangePicker)}
                            />
                        </Grid>
                        <Grid item xs={6} xl={4} className='w-100'>
                            <FilterSelect
                                fullWidth-
                                renderValue={() => `${t('LAB_ORDER.TABLE.DOCTOR_NAME')}: ${doctorOptions.find((item: any) => item?.userId === filterDoctorId)?.fullname || t('ALL')}`}
                                label={t('LAB_ORDER.TABLE.DOCTOR_NAME')}
                                selectId="select-status"
                                value={filterDoctorId}
                                onchange={(event) => {
                                    setFilterDoctorId(event.target.value)
                                    setPage(1)
                                }}
                                labelId="label-status"
                                options={[
                                    <MenuItem key={'all'} value={0}>
                                        {t('ALL')}
                                    </MenuItem>,
                                    _.map(doctorOptions, (item: any, i: number) => {
                                        return (
                                            <MenuItem key={i} value={item.userId}>
                                                {item?.fullname}
                                            </MenuItem>
                                        )
                                    })
                                ]}
                                formControlStyle={{ maxWidth: 'unset !important' }}
                            />
                        </Grid>
                        <Grid item xl={8} className='w-100'>
                            <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={onCreate} textButton={t('LAB_ORDER.LIST.ADD_LAB')} className="w-auto ml-auto d-flex mt-auto" />
                        </Grid>
                    </Grid>
                    <div className="table-list custom-scroll overflow-auto mt-2">
                        {width >= 1441 ? (
                            <TableCustom
                                page={page}
                                pageLimit={pageLimit}
                                sortType={sortType}
                                sortBy={sortBy}
                                rowCount={rowCount}
                                onSort={(sortByVal: string, sortTypeVal: string) => {
                                    setSortType(sortTypeVal)
                                    setSortBy(sortByVal)
                                }}
                                setPageLimit={setPageLimit}
                                setPage={setPage}
                                headCells={headCells}
                                rowsData={_.map(labOrders, renderData)}
                            />
                        ) : (
                            <>
                                <UseStyled.THead>
                                    <UseStyled.Cell>{t('#')}</UseStyled.Cell>
                                </UseStyled.THead>
                                <div className={'treatment-body'}>
                                    {_.map(labOrders, renderDataSlim)}
                                    <div className={'mt-0 '}>
                                        <TablePaginationMiniCustom
                                            count={rowCount || 0}
                                            page={page}
                                            rowsPerPage={pageLimit}
                                            rowCount={rowCount}
                                            pageLimit={pageLimit}
                                            headCells={headCells}
                                            onPageChange={(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage)}
                                            setPageLimit={(event: any) => {
                                                setPageLimit(parseInt(event.target.value, 10))
                                                setPage(1)
                                            }}
                                            setPage={setPage}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <ViewLabOrder labOrderId={labOrderId} showPopup={isViewDetail} onClose={onClose} />
                    {isForm &&
                        <FormLabOrder
                            showForm={isForm}
                            labOrderId={labOrderId}
                            onReset={onReset}
                            handleSuccess={loadData}
                            onSuccess={() => props.reloadQueue && props.reloadQueue(true)}
                        />
                    }
                    <CancelLabOrder isShow={popupCancel} labOrderNumber={labOrderNumber} id={labOrderId} onSuccess={loadData} />
                    {popupReject &&
                        <RejectLabOrder
                            showForm={popupReject}
                            patientId={patientId}
                            labOrderNumber={labOrderNumber}
                            labOrderId={labOrderId}
                            handleSuccess={loadData}
                            onReset={setPopupReject}
                            labOrderStatus={labOrderStatus}
                        />
                    }
                </div>
            </UseStyled.TableConfig>
            <ReceiveLab isShow={popupReceive} id={labOrderId} dateReceive={dateReceive} onSuccess={() => props.reloadQueue && props.reloadQueue(true)} onReset={onReset} />

            <>
                <FunctionPrint
                    content={() => pdfLabOrderRef.current}
                    docTitle={t('TX_INFORMATION.TITLE')}
                    removeIfram
                    trigger={() => <div ref={buttonRef} className={`d-none`}>print</div>}
                    style={'@page { size: 595px 420px;  margin: 0cm;  } @media print { body { -webkit-print-color-adjust: exact; } '}
                />
                <div ref={pdfLabOrderRef} className="print-show h-100 w-100">
                    <PDFLabOrder dataLabOrder={pdfDataLabOrder} />
                </div>
            </>


        </>
    )
}

