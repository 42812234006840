import { useCallback, useEffect, useState } from 'react'
import { Card, Form } from 'react-bootstrap';
import { Grid, Box, Divider } from '@mui/material';
import $ from 'jquery'
import { routeName } from 'routes/routes-name'
import ProductTypesApi from 'api/setting/default-products/product-type.api'
import { imgs, icons } from 'constants/images'
import ButtonCustom from 'component/Button/ButtonCustom'
import InputTextField from 'component/Input/InputTextField'
import { notiSuccess } from 'component/notifications/notifications';
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import InputCheckbox from 'component/Input/InputCheckbox'
import InputTextarea from 'component/Input/InputTextarea'
import ProductFormatsApi from 'api/setting/default-products/product-format.api'
import ProductUnitsApi from 'api/setting/default-products/product-unit.api'
import ProductInstructionsApi from 'api/setting/default-products/product-instruction.api'
import ProductPreservesApi from 'api/setting/default-products/product-preserve.api'
import ProductUsesApi from 'api/setting/default-products/product-use.api'
import { useParams } from 'react-router'
import MedicinesApi from 'api/warehouses/medicine.api'
import { fileUrl } from 'api/api'
import { colors } from 'constants/theme'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import { UploadImage } from 'features/warehouses/medicines/style'
import _ from 'lodash';
import { numberOnly } from 'utils/app.utils';

type RawFileType = {
  fileId?: number | null
  base64?: string | null
  fileName?: string | null
  fileExtension?: string | null
  fileSize?: string | null
}

const initStateErrorMessage = {
  PRODUCT_CODE: ``,
  PRODUCT_NAME: ``,
  PRODUCT_NAME_EN: ``,
  PRODUCT_TYPE_ID: ``,
  PRODUCT_FORMAT_ID: ``,
  PRODUCT_UNIT_ID: ``,
  SALE_PRICE: ``,
  COST_PRICE: ``,
  MIN_STOCK: ``
}

export default function FormMedicines() {
  const { t } = useTranslation()
  const { id }: any = useParams()
  const [productTypes, setProductTypes]: any = useState([])
  const [productFormats, setProductFormats] = useState([])
  const [productUnits, setProductUnits] = useState([])
  const [productUses, setProductUses] = useState([])
  const [productInstructions, setProductInstructions] = useState([])
  const [productPreserves, setProductPreserves] = useState([])
  const [productId, setProductId] = useState(Number)
  const [productCode, setProductCode] = useState('')
  const [productName, setProductName] = useState('')
  const [productNameEn, setProductNameEn] = useState('')
  const [productTypeId, setProductTypeId] = useState<number | null>(null)
  const [productFormatId, setProductFormatId] = useState<number | null>(null)
  const [productUnitId, setProductUnitId] = useState<number | null>(null)
  const [productInstructionId, setProductInstructionId] = useState<number | null>(null)
  const [productPreserveId, setProductPreserveId] = useState<number | null>(null)
  const [productUseId, setProductUseId] = useState<number | null>(null)
  const [strength, setStrength] = useState('')
  const [salePrice, setSalePrice] = useState<any>('')
  const [costPrice, setCostPrice] = useState<any>('')
  const [minStock, setMinStock] = useState<any>('')
  const [explanation, setExplanation] = useState('')
  const [showOnReceipt, setShowOnReceipt] = useState(`SHOW`)
  const [statusProduct, setStatusProduct] = useState(`ACTIVE`)
  const [warnings, setWarnings] = useState('');
  const [amounDayBeforeExpired, setAmounDayBeforeExpired] = useState<any>('');


  const [files, setFiles] = useState<RawFileType[]>([])

  const [image, setImage] = useState({
    fileId: null,
    base64: '',
    fileExtension: '',
    fileSize: ''
  })

  const [defaultProductType, setDefaultProductType] = useState({ label: ``, value: `` })
  const [defaultProductFormat, setDefaultProductFormat] = useState({ label: ``, value: `` })
  const [defaultProductUnit, setDefaultProductUnit] = useState({ label: ``, value: `` })
  const [defaultProductInstruction, setDefaultProductInstruction] = useState({ label: ``, value: `` })
  const [defaultProductPreserve, setDefaultProductPreserve] = useState({ label: ``, value: `` })
  const [defaultProductUse, setDefaultProductUse] = useState({ label: ``, value: `` })

  const [imgData, setImgData]: any = useState()
  const [filesData, setFilesData]: any = useState([])

  const [errorMessage, setErrorMessage]: any = useState(initStateErrorMessage)

  const loadData = useCallback(async () => {
    loadProductTypes()
    loadProductFormats()
    loadProductUnits()
    loadProductInstructions()
    loadProductPreserves()
    loadProductUses()
  }, [])

  useEffect(() => {
    loadData()
    if (id !== undefined) loadProductById()
  }, [loadData])

  const loadProductTypes = async (search = null) => {
    let condition: any = { group: 'MEDICINE', pageLimit: 10, status: 'ACTIVE' }
    if (search) condition = { ...condition, search: search }
    const res = await ProductTypesApi.findAll(condition)
    if (res.status === 200) {
      const newData = res.data.map((value: any) => {
        return { label: value.productTypeName, value: value.productTypeId }
      })
      setProductTypes(newData)
    }
  }

  const loadProductFormats = async (search = null) => {
    let condition: any = { group: 'MEDICINE', pageLimit: 10, status: 'ACTIVE' }
    if (search) condition = { ...condition, search: search }
    const res = await ProductFormatsApi.findAll(condition)
    if (res.status === 200) {
      const newData = res.data.map((value: any) => {
        return { label: value.productFormatName, value: value.productFormatId }
      })
      setProductFormats(newData)
    }
  }

  const loadProductUnits = async (search = null) => {
    let condition: any = { group: 'MEDICINE', pageLimit: 10, status: 'ACTIVE' }
    if (search) condition = { ...condition, search: search }
    const res = await ProductUnitsApi.findAll(condition)
    if (res.status === 200) {
      const newData = res.data.map((value: any) => {
        return { label: value.productUnitName, value: value.productUnitId }
      })
      setProductUnits(newData)
    }
  }

  const loadProductInstructions = async (search = null) => {
    let condition: any = { group: 'MEDICINE', pageLimit: 10, status: 'ACTIVE' }
    if (search) condition = { ...condition, search: search }
    const res = await ProductInstructionsApi.findAll(condition)
    if (res.status === 200) {
      const newData = res.data.map((value: any) => {
        return { label: value.productInstructionName, value: value.productInstructionId }
      })
      setProductInstructions(newData)
    }
  }

  const loadProductPreserves = async (search = null) => {
    let condition: any = { group: 'MEDICINE', pageLimit: 10, status: 'ACTIVE' }
    if (search) condition = { ...condition, search: search }
    const res = await ProductPreservesApi.findAll(condition)
    if (res.status === 200) {
      const newData = res.data.map((value: any) => {
        return { label: value.productPreserveName, value: value.productPreserveId }
      })
      setProductPreserves(newData)
    }
  }

  const loadProductUses = async (search = null) => {
    let condition: any = { group: 'MEDICINE', pageLimit: 10, status: 'ACTIVE' }
    if (search) condition = { ...condition, search: search }
    const res = await ProductUsesApi.findAll(condition)
    if (res.status === 200) {
      const newData = res.data.map((value: any) => {
        return { label: value.productUseDetail, value: value.productUseId }
      })
      setProductUses(newData)
    }
  }

  const loadProductById = async () => {
    const { status, data }: any = await MedicinesApi.findById(id)
    if (status === 200) {
      setProductId(data.productId)
      setProductCode(data.productCode)
      setProductName(data.productName)
      setProductNameEn(data.productNameEn)
      setProductTypeId(data.productTypeId)
      setProductFormatId(data.productFormatId)
      setProductUnitId(data.productUnitId)
      setProductInstructionId(data.productInstructionId)
      setProductPreserveId(data.productPreserveId)
      setProductUseId(data.productUseId)
      setStrength(data.strength)
      setSalePrice(data.salePrice)
      setCostPrice(data.costPrice)
      setMinStock(data.minStock)
      setExplanation(data.explanation)
      setShowOnReceipt(data.showOnReceipt)
      setStatusProduct(data.status)
      setWarnings(data.warnings)
      setAmounDayBeforeExpired(data.amounDayBeforeExpired)

      if (data.image) {
        setImgData(`${fileUrl}/${data.image.filePath}/${data.image.fileName}`)
        setImage({ fileId: data.image.fileId, base64: '', fileExtension: '', fileSize: '' })
      }
      if (data.files) {
        const fileArray = []
        const rawFiles = []
        for (const index in data.files) {
          const file = data.files[index]
          rawFiles.push({
            fileId: file.fileId,
            base64: '',
            fileName: file.fileName,
            fileExtension: '',
            fileSize: ''
          })
          fileArray.push({ fileName: `${file?.fileName || '-'}`, filePathFull: `${fileUrl}/${file.filePath}/${file.fileName}` })
        }
        setFiles(rawFiles)
        setFilesData(fileArray)
      }
      if (data.productType) setDefaultProductType({ label: data.productType.productTypeName, value: data.productType.productTypeId })
      if (data.productFormat) setDefaultProductFormat({ label: data.productFormat.productFormatName, value: data.productFormat.productFormatId })
      if (data.productUnit) setDefaultProductUnit({ label: data.productUnit.productUnitName, value: data.productUnit.productUnitId })
      if (data.productInstruction) setDefaultProductInstruction({ label: data.productInstruction.productInstructionName, value: data.productInstruction.productInstructionId })
      if (data.productPreserve) setDefaultProductPreserve({ label: data.productPreserve.productPreserveName, value: data.productPreserve.productPreserveId })
      if (data.productUse) setDefaultProductUse({ label: data.productUse.productUseDetail, value: data.productUse.productUseId })
    }
  }

  const submit = () => {
    if (!productName || !productCode || !productTypeId || costPrice.toString() === '' || salePrice.toString() === '' || minStock.toString() === '') {
      return setErrorMessage({
        PRODUCT_NAME: !productName ? t(`MEDICINE.MESSAGE.ERROR.NONE_MEDICINE_NAME`) : '',
        PRODUCT_CODE: !productCode ? t(`MEDICINE.MESSAGE.ERROR.NONE_MEDICINE_CODE`) : '',
        PRODUCT_TYPE_ID: !productTypeId ? t(`MEDICINE.MESSAGE.ERROR.NONE_MEDICINE_TYPE`) : '',
        // PRODUCT_FORMAT_ID: !productFormatId ? t(`กรุณาเลือกรูปแบบยา`) : '',
        // PRODUCT_UNIT_ID: !productUnitId ? t(`กรุณาเลือกหน่วยยา`) : '',
        COST_PRICE: costPrice.toString() === '' ? t(`MEDICINE.MESSAGE.ERROR.NONE_FUND`) : '',
        SALE_PRICE: salePrice.toString() === '' ? t(`MEDICINE.MESSAGE.ERROR.NONE_PRICE`) : '',
        MIN_STOCK: minStock.toString() === '' ? t(`MEDICINE.MESSAGE.ERROR.NONE_STOCK`) : ''
      })
    }

    const objCreate = {
      group: 'MEDICINE',
      productCode: productCode,
      productName: productName,
      productNameEn: productNameEn,
      productTypeId: productTypeId,
      productFormatId: productFormatId,
      productUnitId: productUnitId,
      productInstructionId: productInstructionId,
      productPreserveId: productPreserveId,
      productUseId: productUseId,
      strength: strength,
      salePrice: Number(salePrice),
      costPrice: Number(costPrice),
      minStock: Number(minStock),
      explanation: explanation,
      showOnReceipt: showOnReceipt,
      files: files,
      image: image,
      warnings: warnings,
      amounDayBeforeExpired: amounDayBeforeExpired
    }

    MedicinesApi.create(objCreate)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t(`MEDICINE.MESSAGE.SUCCESS.ADD_MEDICINE`))
          setTimeout(() => { window.location.href = routeName.medicine }, 1000);
        }
        else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`MEDICINE.MESSAGE.ERROR.ERROR_AGAIN`) } })
        }
      })
      .catch((e) => {
        const err = e.response.data
        if (err.message === 'IS_DUPLICATE_MEDICINE_NAME') return setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`MEDICINE.MESSAGE.ERROR.MEDICINE_USE`) } })
        else return setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`MEDICINE.MESSAGE.ERROR.MEDICINE_DUP`) } })
      })
  }

  const submitUpdate = () => {
    if (!productName || !productCode || !productTypeId || costPrice.toString() === '' || salePrice.toString() === '' || minStock.toString() === '') {
      return setErrorMessage({
        PRODUCT_NAME: !productName ? t(`MEDICINE.MESSAGE.ERROR.NONE_MEDICINE_NAME`) : '',
        PRODUCT_CODE: !productCode ? t(`MEDICINE.MESSAGE.ERROR.NONE_MEDICINE_CODE`) : '',
        PRODUCT_TYPE_ID: !productTypeId ? t(`MEDICINE.MESSAGE.ERROR.NONE_MEDICINE_TYPE`) : '',
        // PRODUCT_FORMAT_ID: !productFormatId ? t(`กรุณาเลือกรูปแบบยา`) : '',
        // PRODUCT_UNIT_ID: !productUnitId ? t(`กรุณาเลือกหน่วยยา`) : '',
        COST_PRICE: costPrice.toString() === '' ? t(`MEDICINE.MESSAGE.ERROR.NONE_FUND`) : '',
        SALE_PRICE: salePrice.toString() === '' ? t(`MEDICINE.MESSAGE.ERROR.NONE_PRICE`) : '',
        MIN_STOCK: minStock.toString() === '' ? t(`MEDICINE.MESSAGE.ERROR.NONE_STOCK`) : ''
      })
    }

    const objUpdate = {
      group: 'MEDICINE',
      productCode: productCode,
      productName: productName,
      productNameEn: productNameEn,
      productTypeId: productTypeId,
      productFormatId: productFormatId,
      productUnitId: productUnitId,
      productInstructionId: productInstructionId,
      productPreserveId: productPreserveId,
      productUseId: productUseId,
      strength: strength,
      salePrice: Number(salePrice),
      costPrice: Number(costPrice),
      minStock: Number(minStock),
      explanation: explanation,
      showOnReceipt: showOnReceipt,
      status: statusProduct,
      files: files,
      image: image,
      warnings: warnings,
      amounDayBeforeExpired: amounDayBeforeExpired
    }

    MedicinesApi.update(productId, objUpdate)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t(`MEDICINE.MESSAGE.SUCCESS.EDIT_MEDICINE`))
          loadData()
          setTimeout(() => { window.location.href = routeName.medicine }, 1000);
        }
        else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`MEDICINE.MESSAGE.ERROR.ERROR_AGAIN`) } })
        }
      })
      .catch((e) => {
        const err = e.response.data
        if (err.message === 'IS_DUPLICATE_MEDICINE_NAME') return setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`MEDICINE.MESSAGE.ERROR.MEDICINE_USE`) } })
        else return setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`MEDICINE.MESSAGE.ERROR.MEDICINE_DUP`) } })
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  const onChangePicture = (e: any) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]

      const fileName = file.name.split('.')
      const detailImage = {
        fileId: null,
        base64: '',
        fileExtension: fileName[fileName.length - 1],
        fileSize: file.size.toString()
      }
      const reader: any = new FileReader()
      reader.addEventListener('load', () => {
        const base64 = reader.result.split(',')
        setImgData(reader.result)
        detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result
      })
      reader.readAsDataURL(file)
      setImage(detailImage)
    }
  }
  const onChangeFiles = (e: any) => {
    if (e.target.files != null) {
      /**
       * e.target.files all detail file
       * lastModified, lastModifiedDate
       * name, size, type
       */
      const rawFiles: any = []
      const fileNames: any = []

      for (let index = 0; index < e.target.files.length; index++) {
        const file = e.target.files[index]
        fileNames.push({ fileName: file.name, filePathFull: null })
        const fileName = file.name.split('.')
        const detailFile = {
          fileId: null,
          base64: '',
          fileExtension: fileName[fileName.length - 1],
          fileName: file.name,
          fileSize: file.size.toString()
        }
        const reader: any = new FileReader()
        reader.addEventListener('load', () => {
          /** reader.result is base64 */
          const base64 = reader.result.split(',')
          detailFile.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result
        })
        reader.readAsDataURL(file)
        rawFiles.push(detailFile)
      }

      setFiles([...files, ...rawFiles])
      setFilesData([...filesData, ...fileNames])
    }
  }

  const hendlerDeleteFile = (refId: number) => {
    setFiles(_.filter(files, (fs, indexFs: number) => indexFs !== refId))
    setFilesData(_.filter(filesData, (fd, indexFd: number) => indexFd !== refId))
  }

  const handleShowOnReceipt = () => {
    setShowOnReceipt(showOnReceipt === 'SHOW' ? 'HIDE' : 'SHOW')
    clearErrorMessage()
    setSalePrice(0)
  }

  return (
    <div className={`h-100 container-card overflow-hidden`} style={{ backgroundColor: colors.white }}>
      <div className='h-100 d-flex flex-column'>
        <HeaderLabel
          text={productId ? t('MEDICINE.HEADER.TITLE_EDIT') : t('MEDICINE.HEADER.TITLE_ADD')}
          navigator={{ previousTo: routeName.medicine, previousTitle: t('MEDICINE.HEADER.TITLE'), currentTitle: productId ? t('MEDICINE.HEADER.TITLE_EDIT') : t('MEDICINE.HEADER.TITLE_ADD') }}
          goBack={routeName.medicine}
          onSubmit={productId ? submitUpdate : submit}
        />

        <Card className="border shadow-sm mx-4 mb-4 overflow-hidden h-100" style={{ maxWidth: '100%' }}>
          <Card.Body className="h-100 py-0 d-xl-flex">
            <Box className="overflow-auto w-100" sx={{ overflow: { lg: 'hidden !important' }, maxHeight: { lg: 'unset !important' } }} style={{ maxHeight: '100%' }}>
              <Grid container spacing={2} className="pl-3 pl-lg-0 mt-0 h-100 flex-column flex-xl-row flex-nowrap">
                <Grid item lg={2} className="p-3 w-100 d-flex flex-column" sx={{ borderRight: { lg: `1px solid ${colors.lightGray}` }, maxHeight: '100%' }}>
                  <UploadImage
                    className={`align-content-center flex-center mx-auto mb-3`}
                    onClick={() => {
                      $('#profilePic').click()
                    }}
                  >
                    <Box className='image-wrap'>
                      <div className="d-none">
                        <input id="profilePic" type="file" accept='image/*' onChange={onChangePicture} />
                      </div>
                      {imgData ? (
                        <img className={`image-preview  w-100 h-100`} alt="" src={imgData} />
                      ) : (
                        <div className={'upload-image text-center w-100 h-100 d-flex flex-column align-items-center justify-content-center'}>
                          <img src={imgs.defaultAddImage} alt="uploadImage" />
                          <p className={'pt-1 pt-sm-2 m-0'}>{t('REGISTER.UPLOAD_TREATMENT.UPLOAD_IMAGE')}</p>
                        </div>
                      )}
                    </Box>
                  </UploadImage>
                  {productId ? (
                    <div>
                      <Form className="m-0 p-0 d-flex align-items-center justify-content-center justify-content-xl-between w-100">
                        <Form.Label className="mr-3 mb-0">{t('INPUT.LABEL_STATUS')}</Form.Label>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          onChange={() => setStatusProduct(statusProduct === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')}
                          checked={statusProduct === 'ACTIVE' ? true : false}
                          className="ml-xl-auto"
                        />
                      </Form>
                      <Divider className="mx-0 my-3" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                    </div>
                  ) : (
                    ''
                  )}
                  <div className='overflow-auto custom-scroll'>
                    <p className='m-0' style={{ fontWeight: 500 }}>{t('MEDICINE.FORM.DOCUMENT_INFO')}</p>
                    <div className="mt-1">
                      <div className="d-none">
                        <input id="uploadFiles" multiple type="file" accept=".pdf" onChange={onChangeFiles} />
                      </div>
                      <ButtonCustom
                        variant="text"
                        textButton={t('MEDICINE.FORM.ADD_DOCUMENT')}
                        onClick={() => {
                          $('#uploadFiles').click()
                        }}
                        btnStyle={{ fontSize: 16 }}
                      />
                    </div>
                    {filesData &&
                      filesData.map((file: any, i: number) => (
                        <div key={i} className="d-flex align-items-center overflow-hidden">
                          <label
                            htmlFor={`contained-button-file-${i}`}
                            className={`d-flex align-items-center mr-3 text-ellipsis  ${file.filePathFull ? 'cursor-pointer' : ''}`}
                            onClick={() => file.filePathFull && window.open(file.filePathFull)}>
                            <img src={icons.attachmentLine} alt="" className={'mr-2'} />
                            <span className='text-ellipsis' style={{ textDecoration: file.filePathFull ? 'underline' : 'none' }}>{file.fileName}</span>
                          </label>
                          <img src={icons.trashOutlined} onClick={(e) => hendlerDeleteFile(i)} alt="" className="cursor-pointer ml-auto" />
                        </div>
                      ))}
                  </div>
                </Grid>
                <Grid item lg={10} className={'pl-0 pl-lg-3 py-3 w-100 h-100 overflow-hidden d-flex flex-column'}>
                  <div className='d-flex flex-column overflow-auto custom-scroll'>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={4} className="w-100">
                        <Box className='mt-2'>
                          <InputTextField
                            hookValue={true}
                            onchange={(event) => {
                              clearErrorMessage()
                              setProductName(event.target.value)
                            }}
                            label={t("MEDICINE.FORM.MEDICINE_NAME")}
                            value={productName}
                            key={`product-name`}
                            inputProps={{ maxLength: 255 }}
                            required={true}
                            helperText={errorMessage.PRODUCT_NAME || errorMessage.IS_DUPLICATE_MEDICINE_NAME || errorMessage.PRODUCT_NAME_STRING_BASE || errorMessage.PRODUCT_NAME_MAXIMUM_LENGTH || errorMessage.PRODUCT_NAME_STRING_EMPTY || errorMessage.PRODUCT_NAME_ANY_REQUIRED}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={4} className="w-100">
                        <Box className='mt-2'>
                          <InputTextField
                            hookValue={true}
                            onchange={(event) => {
                              clearErrorMessage()
                              setProductNameEn(event.target.value)
                            }}
                            label={t("MEDICINE.FORM.MEDICINE_NAME_ENG")}
                            value={productNameEn}
                            key={`product-name-en`}
                            inputProps={{ maxLength: 255 }}
                            required={false}
                            helperText={errorMessage.PRODUCT_NAME_EN || errorMessage.PRODUCT_NAME_EN_STRING_BASE || errorMessage.PRODUCT_NAME_EN_MAXIMUM_LENGTH || errorMessage.PRODUCT_NAME_EN_STRING_EMPTY || errorMessage.PRODUCT_NAME_EN_ANY_REQUIRED}
                          />
                        </Box>
                      </Grid>
                      <Grid item className="w-100">
                        <div className='ml-1'>
                          <InputCheckbox onChange={handleShowOnReceipt} checked={showOnReceipt === 'SHOW' ? true : false} label={t(`MEDICINE.FORM.SHOW_ON_RECEIPT`)} />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className="mt-0">
                      <Grid item className="w-100">
                        <Divider className="m-0" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                      </Grid>
                      <Grid item className="w-100">
                        <p className='m-0' style={{ fontSize: 18, fontWeight: 500 }}>{t('MEDICINE.FORM.MEDICINE_DETAIL')}</p>
                      </Grid>
                      <Grid item xs={6} md={4} lg={3} className="w-100">
                        <InputTextField
                          hookValue={true}
                          onchange={(event) => {
                            clearErrorMessage()
                            setProductCode(event.target.value)
                          }}
                          label={t('MEDICINE.FORM.MEDICINE_CODE')}
                          value={productCode}
                          key={`product-code`}
                          inputProps={{ maxLength: 20 }}
                          required={true}
                          helperText={errorMessage.PRODUCT_CODE || errorMessage.IS_DUPLICATE_MEDICINE_CODE || errorMessage.PRODUCT_CODE_STRING_BASE || errorMessage.PRODUCT_CODE_MAXIMUM_LENGTH || errorMessage.PRODUCT_CODE_STRING_EMPTY || errorMessage.PRODUCT_CODE_ANY_REQUIRED}
                        />
                      </Grid>
                      <Grid item xs={6} md={4} lg={3} className="w-100">
                        <AutocompleteSelect
                          labelId="product-type"
                          inputLabel={t('MEDICINE.FORM.MEDICINE_TYPE')}
                          options={productTypes}
                          onchange={(e, val) => {
                            clearErrorMessage()
                            setProductTypeId(val?.value || 0)
                            setDefaultProductType({ label: val?.label || ``, value: val?.value || `` })
                          }}
                          onInputChange={(event: any, newValue: any) => loadProductTypes(newValue)}
                          value={defaultProductType}
                          errMessage={errorMessage.PRODUCT_TYPE_ID}
                          required
                          disableClearable={!productTypeId}
                        />
                      </Grid>
                      <Grid item xs={6} md={4} lg={3} className="w-100">
                        <InputTextField
                          hookValue={true}
                          onchange={(event) => {
                            clearErrorMessage()
                            setStrength(event.target.value)
                          }}
                          label={t('MEDICINE.FORM.STRENGTH')}
                          value={strength}
                          key={`strength`}
                          inputProps={{ maxLength: 100 }}
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={6} md={4} lg={3} className="w-100">
                        <AutocompleteSelect
                          labelId="product-unit"
                          inputLabel={t('MEDICINE.FORM.MEDICINE_UNIT')}
                          options={productUnits}
                          onchange={(e, val) => {
                            clearErrorMessage()
                            setProductUnitId(val?.value || 0)
                            setDefaultProductUnit({ label: val?.label || ``, value: val?.value || `` })
                          }}
                          onInputChange={(event: any, newValue: any) => loadProductUnits(newValue)}
                          value={defaultProductUnit}
                          disableClearable={!productUnitId}
                          params={{
                            ListboxProps: {
                              sx: {
                                maxHeight: '200px'
                              }
                            }
                          }}
                        // errMessage={errorMessage.PRODUCT_UNIT_ID}
                        />
                      </Grid>
                      <Grid item xs={6} md={4} lg={3} className="w-100">
                        <AutocompleteSelect
                          labelId="product-instruction"
                          inputLabel={t('MEDICINE.FORM.MEDICINE_HOWTO')}
                          options={productInstructions}
                          getOptionLabel={(option: any) => option.label}
                          onchange={(e, val) => {
                            setProductInstructionId(val?.value || 0)
                            setDefaultProductInstruction({ label: val?.label || ``, value: val?.value || `` })
                            clearErrorMessage()
                          }}
                          onInputChange={(event: any, newValue: any) => loadProductInstructions(newValue)}
                          value={defaultProductInstruction}
                          disableClearable={!productInstructionId}
                          params={{
                            ListboxProps: {
                              sx: {
                                maxHeight: '200px'
                              }
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} md={4} lg={3} className="w-100">
                        <AutocompleteSelect
                          labelId="product-use"
                          inputLabel={t('MEDICINE.FORM.MEDICINE_USETIME')}
                          options={productUses}
                          onchange={(e, val) => {
                            setProductUseId(val?.value || 0)
                            setDefaultProductUse({ label: val?.label || ``, value: val?.value || `` })
                            clearErrorMessage()
                          }}
                          onInputChange={(event: any, newValue: any) => loadProductUses(newValue)}
                          value={defaultProductUse}
                          disableClearable={!productUseId}
                          params={{
                            ListboxProps: {
                              sx: {
                                maxHeight: '200px'
                              }
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} md={4} lg={3} className="w-100">
                        <InputTextField
                          hookValue={true}
                          onchange={(event) => {
                            clearErrorMessage()
                            setWarnings(event.target.value)
                          }}
                          label={t('MEDICINE.FORM.WARNING')}
                          value={warnings}
                          key={`warnings`}
                          inputProps={{ maxLength: 255 }}
                        />
                      </Grid>
                      <Grid item xs={6} md={4} lg={3} className="w-100">
                        <AutocompleteSelect
                          labelId="product-preserve"
                          inputLabel={t('MEDICINE.FORM.PRODUCT_PRESERVES')}
                          options={productPreserves}
                          getOptionLabel={(option: any) => option.label}
                          onchange={(e, val) => {
                            setProductPreserveId(val?.value || 0)
                            setDefaultProductPreserve({ label: val?.label || ``, value: val?.value || `` })
                            clearErrorMessage()
                          }}
                          onInputChange={(event: any, newValue: any) => loadProductPreserves(newValue)}
                          value={defaultProductPreserve}
                          disableClearable={!productPreserveId}
                        />
                      </Grid>
                      <Grid item xs={6} md={4} lg={3} className="w-100">
                        <AutocompleteSelect
                          labelId="product-format"
                          inputLabel={t('MEDICINE.FORM.PRODUCT_FORMATS')}
                          options={productFormats}
                          onchange={(e, val) => {
                            clearErrorMessage()
                            setProductFormatId(val?.value || 0)
                            setDefaultProductFormat({ label: val?.label || ``, value: val?.value || `` })
                          }}
                          onInputChange={(event: any, newValue: any) => loadProductFormats(newValue)}
                          value={defaultProductFormat}
                          // errMessage={errorMessage.PRODUCT_FORMAT_ID}
                          disableClearable={!productFormatId}
                        />
                      </Grid>
                      <Grid item xs={6} md={4} lg={3} className="w-100">
                        <InputTextField
                          hookValue={true}
                          onchange={(event) => {
                            clearErrorMessage()
                            setCostPrice(numberOnly(event))
                          }}
                          label={t('MEDICINE.FORM.PRODUCT_FUND')}
                          value={costPrice}
                          key={`cost-price`}
                          inputProps={{ maxLength: 12 }}
                          required={true}
                          helperText={errorMessage['COST_PRICE']}
                        />
                      </Grid>
                      <Grid item xs={6} md={4} lg={3} className="w-100">
                        <InputTextField
                          hookValue={true}
                          disabled={showOnReceipt === "HIDE" ? true : false}
                          onchange={(event) => {
                            clearErrorMessage()
                            setSalePrice(numberOnly(event))
                          }}
                          label={t('MEDICINE.FORM.PRODUCT_PRICE')}
                          value={salePrice}
                          key={`sale-price`}
                          inputProps={{ maxLength: 12 }}
                          required={true}
                          helperText={errorMessage['SALE_PRICE']}
                        />
                      </Grid>
                      <Grid item xs={6} md={4} lg={3} className="w-100">
                        <InputTextField
                          hookValue={true}
                          onchange={(event) => {
                            clearErrorMessage()
                            setMinStock(numberOnly(event))
                          }}
                          label={t('MEDICINE.FORM.MINSTOCK')}
                          value={minStock}
                          key={`min-stock`}
                          required={true}
                          inputProps={{ maxLength: 11 }}
                          helperText={errorMessage['MIN_STOCK']}
                        />
                      </Grid>
                      <Grid item xs={6} md={4} lg={3} className="w-100">
                        <InputTextField
                          hookValue={true}
                          onchange={(event) => {
                            const value = numberOnly(event)
                            setAmounDayBeforeExpired(value.length >= 2 ? value.slice(0, 2) : value)
                            clearErrorMessage()
                          }}
                          inputProps={{ maxLength: 2 }}
                          label={t('MEDICINE.FORM.EXPIRED')}
                          value={amounDayBeforeExpired}
                          key={`expired`}
                          hideControl
                        />
                      </Grid>
                      <Grid item className="w-100">
                        <InputTextarea
                          onchange={(event) => {
                            clearErrorMessage()
                            const inputValue = event.target.value;
                            setExplanation(inputValue)         
                          }}
                          value={explanation}
                          label={t('MEDICINE.FORM.EXPLANATION')}
                          helperText=""
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Card.Body>
        </Card>
      </div>
    </div >
  )
}
