import { useCallback, useEffect, useState } from 'react'
import { numberFormat } from 'utils/app.utils';
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import _ from 'lodash'
import * as UseStyled from 'features/report/useStyled'
import { Box, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { dateTimeToView, dateToView } from 'utils/date.utils'
import { colors } from 'constants/theme'

import { UseSnCode } from 'utils/useSnCode'
interface FcComponent {
    page: number
    pageLimit: number
    rowCount: number
    sortBy: string
    sortType: string
    data: any
    onRequestSort: (sortBy: string, sortType: string) => void
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
}

const statusColor: any = {
    PAID: { color: colors.appointmentStatus.statusEnter },
    CANCEL_VISIT: { color: colors.appointmentStatus.statusCancel },
    CANCEL_BILL: { color: '#F47C00' },
    OVERDUE: { color: colors.appointmentStatus.statusUnConnect },
}

export default function VisitList(props: FcComponent) {
    const { t } = useTranslation()


    const [snType, setSnType] = useState(false);

    const handleSnCode = useCallback(() => {
        const fetchData = async () => {
            const sn = await UseSnCode();
            setSnType(sn);
        };

        fetchData();

    }, [snType]);
    useEffect(() => {
        handleSnCode()
    }, [])
    const headCells = [
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.DATE', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.VN', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.CN_NUMBER', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.BRANCH_CN_NUMBER', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.SN_CODE', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.PATIENT_NAME', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.PHONE', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.PAYMENT_TOTAL', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.PAYMENT_PAY', align: 'center', class: 'text-nowrap' },
        { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.PAYMENT_OVERDUE', align: 'center', class: 'text-nowrap' },
        { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.APPOINTMENT_STATUS', align: 'center', class: 'text-nowrap' },

        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.SEND_BY', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.CANCEL_BY', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.REMARK_CANCEL', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.TRANSACTION_BY', align: 'center', class: 'text-nowrap' }
    ]
    if (!snType) {
        headCells.splice(4, 1)
    }
    const renderData = (data: any, no: number) => {
        const num = props.page * props.pageLimit - props.pageLimit + no + 1
        const columns = [
            { option: 'TEXT', align: 'left', label: dateToView(data.updatedAt), class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.vnNumber, class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.cnNumber || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.branchCnNumber || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.snCode || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.fullName, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.phone },
            { option: 'TEXT', style: { width: '30%' }, align: 'right', label: data.visitStatus === "CANCEL_VISIT" ? '-' : numberFormat(data.totalPrice), class: 'text-nowrap' },
            { option: 'TEXT', align: 'right', label: data.visitStatus === "CANCEL_VISIT" ? '-' : numberFormat(data.amountPaid), class: 'text-nowrap' },
            { option: 'TEXT', align: 'right', label: data.visitStatus === "CANCEL_VISIT" ? '-' : numberFormat(data.overdue), class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: t(`REPORT.VISIT_STATUS.${data.visitStatus}`), style: statusColor[data.visitStatus] || {}, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.updatedBy || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.cancelBy || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.billRemark || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.visitStatus === "CANCEL_VISIT" ? '-' : data.updatedBillBy, class: 'text-nowrap' },
        ]
        if (!snType) {
            columns.splice(4, 1)
        }
        return <>
            <TableRowCommon
                key={num.toString()}
                id={no.toString()}
                obj={data}
                columns={columns}
            />
        </>
    }

    return (
        <>
            <Box>
                < UseStyled.ResponsiveReportTable >
                    <TableCustom
                        page={props.page}
                        pageLimit={props.pageLimit}
                        sortType={props.sortType}
                        sortBy={props.sortBy}
                        rowCount={props.rowCount}
                        onSort={props.onRequestSort}
                        setPageLimit={props.handleChangeRowsPerPage}
                        setPage={props.handleChangePage}
                        headCells={headCells}
                        rowsData={[_.map(props.data.rows, (d: any, index: number) => {
                            return renderData(d, index)
                        }),
                        <TableRow key={props.data.rows} className='row-summary'>

                            <TableCell align='left' scope="row" colSpan={snType ? 7 : 6}>
                                {t('REPORT.TITLE.SUM')}
                            </TableCell>
                            <TableCell align="right" scope="row" colSpan={1}>
                                {numberFormat(_.sumBy(props.data.rows, 'totalPrice'))}
                            </TableCell>
                            <TableCell align="right" scope="row" colSpan={1}>
                                {numberFormat(_.sumBy(props.data.rows, 'amountPaid'))}
                            </TableCell>
                            <TableCell align="right" scope="row" colSpan={1}>
                                {numberFormat(_.sumBy(props.data.rows, 'overdue'))}
                            </TableCell>
                            <TableCell align='right' scope="row" colSpan={6}>

                            </TableCell>
                        </TableRow>
                        ]}
                        customScroll
                    />
                </UseStyled.ResponsiveReportTable >
            </Box>
        </>
    )
}
