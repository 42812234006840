import IconProps from './iconProps'
import { colors } from 'constants/theme'

export default function Finance(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g data-name="Group 8642">
        <path data-name="Path 6264" d="M41.431 223.006V240a7.579 7.579 0 0 0 4.256 2.344c4.7.87 12.634.426 12.954-2.344.478-4.147-.6-17.771-.6-17.771l-8.724-1.972z" style={{ fill: '#fff' }} transform="translate(-37.255 -216.578)" />
        <path
          d="M15.81 27.524c4.875 0 9.81-1.685 9.81-4.9V7.9c0-3.22-4.934-4.578-9.81-4.578s-9.554 1.2-9.554 4.421v15c-.001 3.225 4.678 4.781 9.554 4.781zm0-1.587c-4.564 0-7.81-2.077-7.81-2.942v-1.931a18.184 18.184 0 0 0 7.81 1.684 19.976 19.976 0 0 0 8.19-2v2.247c0 .864-3.627 2.942-8.19 2.942zm0-20.855c4.564 0 8.19 2.225 8.19 3.09s-3.627 2.746-8.19 2.746S8 9.625 8 8.172s3.246-3.09 7.81-3.09zM8 11.255a18.208 18.208 0 0 0 7.81 1.555c2.726 0 6.3-.527 8.19-1.555v2.493c0 .864-3.627 2.216-8.19 2.216S8 14.613 8 13.748zm0 4.9a18.208 18.208 0 0 0 7.81 1.555c2.726 0 6.3-.527 8.19-1.555v2.358c0 .864-3.627 2.23-8.19 2.23S8 19.383 8 18.518z"
          transform="translate(-3.255 -.326)"
          style={{ stroke: 'rgba(140, 104, 212,.02)', strokeWidth: '.6px', fill: props.color || colors.themeSecondColor }}
        />
        <g data-name="Ellipse 1" transform="translate(16.407 16.338)" style={{ strokeWidth: '1.6px', stroke: props.color || colors.themeSecondColor, fill: '#fff' }}>
          <circle cx="6.669" cy="6.669" r="6.669" style={{ stroke: 'none' }} />
          <circle cx="6.669" cy="6.669" r="5.869" style={{ fill: 'none' }} />
        </g>
        <path
          data-name="Path 5747"
          d="M266.909 40.532v.637a1.631 1.631 0 0 0 .544 3.17.544.544 0 1 1 0 1.088.984.984 0 0 1-.706-.411.544.544 0 1 0-.833.7 2.256 2.256 0 0 0 .995.708v.635a.544.544 0 0 0 1.088 0v-.637a1.631 1.631 0 0 0-.544-3.17.544.544 0 0 1 0-1.088.873.873 0 0 1 .588.284.544.544 0 1 0 .756-.782 2.1 2.1 0 0 0-.8-.5v-.633a.544.544 0 1 0-1.088 0zm0 0"
          transform="translate(-244.36 -20.787)"
          style={{ strokeWidth: '.2px', stroke: props.color || colors.themeSecondColor, fill: props.color || colors.themeSecondColor }}
        />
      </g>
      <path data-name="Rectangle 3863" style={{ fill: 'none' }} d="M0 0h32v32H0z" />
    </svg>
  )
}
