import { makeStyles } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import { Card } from 'react-bootstrap'
import { colors, templateImage } from 'constants/theme'

export const MainDiv = styled(`div`)(({ theme }) => ({
  '&.main-reset-password': {
    height: '100%',
    width: '100%',
    backgroundImage: `conic-gradient(from 0.25turn, #fff, ${colors.themeSecondColor30} 0.24turn, ${colors.themeSecondColor30} 0.67turn, ${colors.white30} 0.82turn, ${colors.white80}`,
    backgroundSize: 'cover',
    backgroundRepeat: ' no-repeat',
    '& .react-multi-carousel-list': {
      height: '180px !important'
    },
    '& .react-multiple-carousel__arrow--right': {
      right: 'calc(4% + -30px)'
    },
    '& .react-multiple-carousel__arrow--left': {
      left: 'calc(4% + -30px)'
    },
    '& .btn-submit': {
      fontSize: '18px',
      height: '60px'
    },
    '& .card-title': {
      fontSize: '24px',
      fontWeight: '500',
      padding: '1rem'
    },
    [theme.breakpoints.down('sm')]: {
      '& .react-multiple-carousel__arrow--right': {
        right: 'calc(4% + 1px)'
      },
      '& .react-multiple-carousel__arrow--left': {
        left: 'calc(4% + 1px)'
      }
    }
  }
}))

export const CardResetPassword = styled(Card)(({ theme }) => ({
  backgroundColor: colors.white,
  margin: 'auto',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: 380,
  width: '100%',
  borderRadius: 10,
  opacity: 1
}))

export const BgImageLeft = styled(`div`)(({ theme }) => ({
  height: '11vw',
  width: '20vw',
  position: 'fixed',
  bottom: '-1vh',
  left: '0px',
  backgroundImage: `url(${templateImage.pick.left})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  [theme.breakpoints.down('md')]: {
    width: '24vw',
    height: '13vw'
  },
  [theme.breakpoints.down('sm')]: {
    width: '38vw',
    height: '20vw'
  }
}))
export const BgImageRight = styled(`div`)(({ theme }) => ({
  height: '11vw',
  width: '20vw',
  position: 'fixed',
  bottom: '-1vh',
  right: '0px',
  backgroundImage: `url(${templateImage.pick.right})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  [theme.breakpoints.down('md')]: {
    width: '24vw',
    height: '13vw'
  },
  [theme.breakpoints.down('sm')]: {
    width: '38vw',
    height: '20vw'
  }
}))

const useStyles = makeStyles((theme) => ({

  logo: {
    height: '17vh',
    display: 'flex',
    width: 'auto',
    marginBottom: '10px',
    margin: 'auto'
  },
  licen: {
    padding: 20
  },
  cursorPointer: {
    '& $svg': {
      cursor: 'pointer'
    },
    '& $b': {
      cursor: 'pointer'
    }
  },
  position: {
    position: 'absolute'
  },

  marginCard: {
    margin: 'auto',
    maxWidth: 380
  }
}))

export default useStyles
