import { IconButton, InputAdornment, TextField } from '@mui/material'
import { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { colors } from 'constants/theme'
import HelperText from 'component/Input/HelperText'
import { styled } from '@mui/material/styles'

const CustomTextField = styled(TextField)<{ inputHeight?: any }>(({ theme, inputHeight }) => ({
  width: '100%',
  borderRadius: 4,
  '.MuiOutlinedInput-root': {
    height: inputHeight || 43,
    '.MuiOutlinedInput-notchedOutline, &.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: `${colors.black12}`
    },
    '&:hover': {
      borderColor: `${colors.black12}`,
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: `${colors.black12}`
      }
    },
    '&.Mui-focused': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: `${colors.themeSecondColor}`
      }
    },
    '&.Mui-error': {
      '.MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${colors.statusInactive} !important`
      }
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px !important'
    }
  },
  '.MuiInputLabel-root, .MuiInputLabel-root span': {
    fontSize: 16,
    color: `${colors.black60} !important`,
    '&.MuiInputLabel-root': {
      paddingLeft: 1
    },
    backgroundColor: 'transparent !important',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 3,
      top: '50.5%',
      zIndex: -1
    },
    '&[data-shrink="true"]': {
      '&.Mui-focused': {
        color: `${colors.themeSecondColor} !important`
      },
      '&:before': {
        backgroundColor: colors.white
      },
      span: {
        color: `${colors.statusInactive} !important`
      }
    }
  },
  '.MuiOutlinedInput-input': {
    height: inputHeight || 43,
    fontSize: 16,
    color: `${colors.textPrimary} !important`,
    '-webkit-text-fill-color': 'unset !important',
    padding: '0 1rem',
    '&.Mui-disabled': {
      color: `${colors.black60} !important`
    }
  },
  '&.filed-error': {
    '.MuiInputLabel-root[data-shrink="true"], .MuiInputLabel-root[data-shrink="true"] span': {
      color: `${colors.statusInactive} !important`
    }
  },
  '&.hide-control': {
    'input[type=number], input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      '-moz-appearance': 'textfield',
      '-webkit-appearance': 'none',
      appearance: 'none'
    }
  }
}))

type InputProps = {
  onchange?: (value: any) => void
  onkeyup?: (value: any) => void
  required?: boolean
  disabled?: boolean
  variant?: any
  size?: 'medium' | 'small'
  label?: string
  type?: string
  name?: string
  helperText?: string
  value?: string
  inputProps?: any
}
export default function InputPasswordField(props: InputProps) {
  const [showPassword, setShowPassword] = useState(false)

  const handleClick = () => {
    setShowPassword((prev) => !prev)
  }
  return (
    <div>
      <CustomTextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClick} edge="end">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
          ...props.inputProps
        }}
        className={`${props.helperText ? 'filed-error' : ''}`}
        onChange={props.onchange}
        onKeyUp={props.onkeyup}
        variant={props.variant || 'outlined'}
        size={props.size || 'small'}
        required={props.required}
        label={props.label}
        disabled={props.disabled}
        name={props.name}
        value={props?.value || undefined}
        type={showPassword ? 'text' : 'password'}
        error={props.helperText !== ''}
        helperText={
          props.helperText ? (
            <HelperText label={props.helperText} />
          ) : (
            ''
          )
        }
      />
    </div>
  )
}
