import { useCallback, useEffect, useRef, useState } from 'react';
import { Card, Col, Row, Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Box, MenuItem } from '@mui/material';
import moment from 'moment'
import _ from 'lodash';

import { routeName } from 'routes/routes-name';

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API, UTIL*/
import { getBranch } from 'utils/app.utils';
import DoctorScheduleApi from 'api/counter/doctor-schedule.api'
import ClinicApi, { BranchInterface } from 'api/master/clinic.api';
import ReportApi, { FindAllReportInterface } from 'api/report.api';

/** COMPONENT */
import Loading from 'component/Loading';
import NewPatientList from './NewPatientList';
import FucntionPrint from "component/Print/print";
import HeaderCard from 'component/CardCustom/HeaderCard';
import FilterSelect from 'component/Select/FilterSelect';
import InputCheckbox from 'component/Input/InputCheckbox';
import { useDownloadExcel } from 'component/Excel/hooks/useExcel';
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker';

/** STYLED */
import * as UseStyled from 'features/report/useStyled';
import { ServiceFactorInterface } from 'api/setting/default-users/service-factor.api';
import AccessChannelApi, { AccessChannelInterface } from 'api/setting/default-users/access-channel.api';
import { FindAllInterface } from 'api/api-interface';
import { TRUE_FALSE } from 'constants/status';
import ComponentApi from 'api/components-api';
import AutocompleteSelect from 'component/Select/AutocompleteSelect';
import { ExportProps } from '../report-constants';


interface AccessChannel {
    accessChannelId: number;
    channelName: string;

}
export default function Newpatient() {
    const { t } = useTranslation();
    const history = useHistory();
    const componentRef = useRef<HTMLDivElement>(null);
    const momentNowRange = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    const momentDate = () => momentNowRange

    const [branchId, setBranchId] = useState<number>(0);
    const [branches, setBranches] = useState<any[]>([]);
    const [rangeDate, setRangeDate] = useState<string[]>(momentDate);

    const [rowCount, setRowCount] = useState<number>(0);
    const [sortBy, setSortBy] = useState<string>('createAt');
    const [sortType, setSortType] = useState<string>('ASC');

    const [newPatientData, setNewPatientData] = useState<any[]>([]);
    const [newPatientDataExport, setNewPatientDataExport] = useState<any[]>([]);
    const [newPatientPage, setNewPatientPage] = useState<number>(1);
    const [newPatientTypePage, setNewPatientTypePage] = useState<number>(1);
    const [newPatientPageLimit, setNewPatientPageLimit] = useState<number>(10);

    const [accessChannel, setAccessChannel] = useState<string | null>(null);
    const [accessChannels, setAccessChannels] = useState([]);
    const [selectAccessChannel, setSelectedAccessChannel] = useState({ accessChannelId: 0, channelName: t(`REPORT.FILTER.ALL`) })
    const [accessChannelId, setAccessChannelId] = useState<number>(0);

    const [loading, setLoading] = useState<boolean>(false);





    const filter = [
        branchId === 0 ? t(`REPORT.FILTER.ALL`) : `${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`,
        rangeDate,
        accessChannelId === 0 ? t(`REPORT.FILTER.ALL`) : `${_.get(_.find(accessChannels, { accessChannelId: accessChannelId }), 'channelName', _.get(_.find(accessChannels, { accessChannelId: accessChannelId }), 'channelNameEn'))}`
    ]



    const verifyPermission = async () => {
        const condition: FindAllReportInterface = {
            page: 1, pageLimit: 200
        }
        ReportApi.reportList(condition)
            .then(({ data }) => {
                const check = _.find(data, (val: any) => String(val.reportKey) === 'NEW_PATIENT') ? true : false
                if (!check) {
                    history.push(routeName.report)
                    return false
                }
            })

        ClinicApi.findAllBranches()
            .then(({ data }) => setBranches(data))
            .catch((e) => { return })

        return true
    }

    useEffect(() => {


        verifyPermission()
    }, [branchId])

    const loadAccessChannels = useCallback((searchValue?: string) => {
        ComponentApi.findAllAccessChannels()
            .then(({ data: dataAccessChannels }) => { setAccessChannels(dataAccessChannels) })
            .catch((e) => { return });
    }, []);

    const fetchNewPatientExport = useCallback(() => {
        let condition: FindAllReportInterface = {
            page: 1, pageLimit: 10000, branchId: branchId, dateStart: rangeDate[0], dateEnd: rangeDate[1], export: 1
        }
        if (accessChannelId) condition = { ...condition, accessChannelsId: accessChannelId }
        if (branchId) condition = { ...condition, branchId: branchId }
        if (rangeDate) {
            if (rangeDate[0] !== null && rangeDate[1] !== null)
                condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
        }
        ReportApi.reportNewPatient(condition)
            .then(({ headers, data }) => {
                setNewPatientDataExport(data)
            }).catch((e) => setLoading(false))
            .finally(() => setLoading(false))
    }, [rangeDate, branchId, newPatientTypePage, newPatientPageLimit, selectAccessChannel, newPatientPage, accessChannelId])


    const fetchNewPatient = useCallback(() => {
        let condition: FindAllReportInterface = {
            page: newPatientPage, pageLimit: newPatientPageLimit, branchId: branchId, dateStart: rangeDate[0], dateEnd: rangeDate[1]
        }
        if (accessChannelId) condition = { ...condition, accessChannelsId: accessChannelId }
        if (branchId) condition = { ...condition, branchId: branchId }
        if (rangeDate) {
            if (rangeDate[0] !== null && rangeDate[1] !== null)
                condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
        }
        ReportApi.reportNewPatient(condition)
            .then(({ headers, data }) => {
                setNewPatientData(data)
                setRowCount(headers['x-total'])
            }).catch((e) => setLoading(false))
            .finally(() => setLoading(false))
    }, [rangeDate, branchId, newPatientTypePage, newPatientPageLimit, selectAccessChannel, newPatientPage, accessChannelId])

    const { onDownload } = useDownloadExcel({
        tableExportRef: componentRef.current,
        filename: `${t(`REPORT.REPORT_NAME.NEW_PATIENT`)} (${rangeDate[0]}-${rangeDate[1]})`,
        sheetName: `${t(`REPORT.REPORT_NAME.NEW_PATIENT`)}`
    })


    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }
    const handleChangeRowsPerPage = (limit: number) => setNewPatientPageLimit(limit)
    const handleChangePage = (val: number) => setNewPatientPage(val)
    useEffect(() => {
        loadAccessChannels();
        fetchNewPatientExport()
        fetchNewPatient()
    }, [fetchNewPatient, loadAccessChannels, fetchNewPatientExport])
    const [firstBillService, setFirstBillService] = useState<string | null>(null);
    return (
        <div>
            <Card className={'border-0 h-100'}>
                <HeaderCard text={t(`REPORT.REPORT_NAME.NEW_PATIENT`)} />
                <Card.Body>
                    <Card.Title className={'mb-0'}>
                        <Row className='mx-0'>
                            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                                <FilterSelect
                                    onchange={(e) => {
                                        setBranchId(e.target.value)
                                        setNewPatientPage(1)
                                    }}
                                    renderValue={() => branchId === 0 ? `${t('REPORT.FILTER.BRANCH')}: ${t('REPORT.REPORT_GROUP.ALL')}` : `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`}
                                    label={''}
                                    selectId="select-branch"
                                    labelId="label-branch"
                                    value={branchId}
                                    options={[
                                        <MenuItem key={0} value={0}>{t(`REPORT.FILTER.ALL`)}</MenuItem>,
                                        ..._.map(branches, (b: BranchInterface, index) => (
                                            <MenuItem key={b.branchId} value={b.branchId}>
                                                {b.branchName || b.branchNameEn}
                                            </MenuItem>
                                        ))
                                    ]}
                                    formControlStyle={{ maxWidth: 'unset !important' }}
                                    classesOption="style-select-doctors"
                                />
                            </Col>

                            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                                <InputSecondNewRangePicker
                                    inputHeight={32}
                                    value={rangeDate || emptyRangePicker}

                                    onchange={(val: any) => {
                                        if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                                        else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                                    }}
                                    allowClear
                                    onClear={() => setRangeDate(emptyRangePicker)}
                                    label={''}
                                />
                            </Col>

                            <Col sm={6} md={4} xl={3} className='pt-2 px-1'>
                                <FilterSelect
                                    onchange={(e) => {
                                        setAccessChannelId(e.target.value)
                                        setNewPatientPage(1)
                                    }}
                                    renderValue={() => accessChannelId === 0 ? `${t('REPORT.FILTER.SERVICE_ACCESS')}: ${t('REPORT.REPORT_GROUP.ALL')}` : `${t('REPORT.FILTER.SERVICE_ACCESS')}: ${_.get(_.find(accessChannels, { accessChannelId: accessChannelId }), 'channelName' || 'channelNameEn')} `}
                                    label={''}
                                    selectId="select-access-channels"
                                    labelId="label-access-channels"
                                    value={accessChannelId}
                                    options={[
                                        <MenuItem key={0} value={0}>{t(`REPORT.FILTER.ALL`)}</MenuItem>,
                                        ..._.map(accessChannels, (a: AccessChannel, index) => (
                                            <MenuItem key={a.accessChannelId} value={a.accessChannelId}>
                                                {a.channelName}
                                            </MenuItem>
                                        ))
                                    ]
                                    }
                                    formControlStyle={{ maxWidth: 'unset !important' }}
                                    classesOption="style-select-doctors"
                                />
                            </Col>
                            <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                                <UseStyled.DropdownDocument>
                                    <Dropdown.Toggle id="dropdown-basic" className='ml-xl-auto'>
                                        {t('PRINT_DOCUMENT')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu onClick={fetchNewPatientExport}>
                                        <Dropdown.Item onClick={onDownload}>
                                            {t('Excel')}
                                        </Dropdown.Item>
                                        <FucntionPrint
                                            content={() => componentRef.current}
                                            removeIfram
                                            trigger={() => <Dropdown.Item >
                                                {t('PDF')}
                                            </Dropdown.Item>}
                                            style={`@page { size: 1122.519685px 793.7007874px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                                        />
                                    </Dropdown.Menu>
                                </UseStyled.DropdownDocument>
                            </Col>

                        </Row>
                    </Card.Title>
                    <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
                        <NewPatientList
                            page={newPatientPage}
                            pageLimit={newPatientPageLimit}
                            rowCount={rowCount}
                            sortBy={sortBy}
                            sortType={sortType}
                            data={newPatientData}
                            dataExport={newPatientDataExport}
                            onRequestSort={onRequestSort}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleChangePage={handleChangePage}
                            componentRef={componentRef}
                            headCellsSeconds={filter}
                            date={rangeDate}
                        />

                    </Box>
                </Card.Body>
            </Card>
            <Loading show={loading} type='fullLoading' />
        </div>
    )
}
