import TreatmentQueue from 'locales/dental/en/dentist/treatmentsQueue.json'
import Treatments from 'locales/dental/en/dentist/treatments.json'
import vitalSign from 'locales/dental/en/dentist/treatmentsVital-sign.json'
import treatmentsDx from 'locales/dental/en/dentist/treatmentsDx.json'
import Summary from 'locales/dental/en/dentist/summary.json'
import Opd from 'locales/dental/en/dentist/opd.json'
import Tx from 'locales/dental/en/dentist/treatmentsTx.json'
import Product from 'locales/dental/en/dentist/treatmentsProduct.json'
import Emr from 'locales/dental/en/dentist/treatmentsEmr.json'
import TreatmentDocument from 'locales/dental/en/dentist/treatmentDocument.json'
import TxInformationBox from 'locales/dental/en/dentist/txInFormationBox.json'
import TreatmentsTxp from 'locales/dental/en/dentist/treatmentsTxp.json'
import TreatmentsTxc from 'locales/dental/en/dentist/treatmentsTxc.json'
import PdfStickerMed from 'locales/dental/en/dentist/pdfStickerMed.json'
import Xray from 'locales/dental/en/dentist/xray.json'
import PDFInvoice from 'locales/dental/en/dentist/pdfInvoice.json'

export const TreatmentEN = {
  ...TreatmentQueue,
  ...Treatments,
  ...vitalSign,
  ...treatmentsDx,
  ...Summary,
  ...Opd,
  ...Tx,
  ...Product,
  ...Emr,
  ...TreatmentDocument,
  ...TxInformationBox,
  ...TreatmentsTxp,
  ...TreatmentsTxc,
  ...PdfStickerMed,
  ...Xray,
  ...PDFInvoice
}
