import AboutUs from 'api/admin/clinic-manage/about.api';

export const UseSnCode = async () => {

    const res = await AboutUs.findAboutUs();
    if (res.status === 200) {
        if (res.data.snType === '1') { return true }
        return false
    }
    return false

}

