import { colors } from "constants/theme"

export default function Noti() {
    return (

        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0ZM11 15H9V13H11V15ZM11 11H9L8.5 5H11.5L11 11Z" style={{ fill: colors.themeSecondColor }} />
        </svg>


    )
}
