import { useEffect, useRef, useState } from 'react'
import { IconButton, Tooltip, styled } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import iconAddFile from 'assets/icon/doctor-note/add-file.svg'

import iconCreatedBy from 'assets/icon/doctor-note/iconCreatedBy.svg'
import iconCreatedAt from 'assets/icon/doctor-note/iconCreatedAt.svg'
import iconUpdateBy from 'assets/icon/doctor-note/iconUpdateBy.svg'
import iconUpdateAt from 'assets/icon/doctor-note/iconUpdateAt.svg'

import ButtonCustom from 'component/Button/ButtonCustom'
import { colors } from 'constants/theme'
import TreatmentApi, { PayloadDoctorNote } from 'api/dentists/treatment.api'
import { getBranch, toBuddhistYear } from 'utils/app.utils'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { handleShow, resetModal, showModal } from 'app/slice/modal.slice'
import { icons, onImgError } from 'constants/images'
import { swalDelete } from 'component/Alert/Swal'

import * as UseStyled from 'features/treatments/MainMenu/DoctorNote/UseStyled'
import moment from 'moment'
import { SavePopup } from './save-popup'
import useWindowSize from 'utils/useWindowSize'
import CanvasDisign from './canvas-draw'

/** STYLE */

const DivDoctorNote = styled('div')<{ brushColor?: string }>(({ theme, brushColor }) => ({
  height: '100%',
  padding: '0 0.75rem',
  '& .img-add-note': { maxWidth: '50px', maxHeight: '50px' },
  '& .list-doctor-note': {
    width: '200px',
    height: '234px',
    border: `1px solid ${colors.lightGray}`,
    '&.active': { border: `3px solid ${colors.themeSecondColor}` }
  },
  '& .palette': {
    width: 235,
    textAlign: 'center',
    '& .palette-weight': {
      cursor: 'pointer',
      width: 25,
      height: 25,
      margin: '0.05rem',
      border: `1px solid ${colors.lightGray}`,
      borderRadius: '50%',
      '&.active': { border: `1px solid ${colors.black}` },
      '& .point-size': { backgroundColor: colors.black, borderRadius: '50%' }
    },
    '& .palette-color': {
      cursor: 'pointer',
      width: 25,
      height: 25,
      margin: '0.05rem',
      border: `1px solid ${colors.lightGray}`,
      borderRadius: '50%',
      '&.active': { border: `1px solid ${colors.black}` }
    }
  },
  '& .dropdown-doctor-note': { position: 'absolute', top: 5, right: 0 },
  '.btn-pencil, .btn-more': {
    boxShadow: 'none !important'
  }
}))


interface DoctorNoteProps {
  userId?: number
  patient?: any
  hasDoctorNote: 'PERMISS' | 'UNPERMISS'
  hasCreateDoctorNote?: 'PERMISS' | 'UNPERMISS'
  hasDeleteDoctorNote?: 'PERMISS' | 'UNPERMISS'
  isHistoryTreatment?: boolean
}
type VisitType = {
  patientCaseId: number
  branchId: number
  vnNumber: string | number
  createdAt: string
}
enum TreatmentType {
  'ALL',
  'VN_NUMBER',
  'NOT_VN_NUMBER'
}

export default function IndexDoctorNote(props: DoctorNoteProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const modal: boolean = useSelector(handleShow)
  const [width] = useWindowSize()
  const [visitActive] = useState<string | number>(TreatmentType.ALL)

  const [brushColor] = useState<string>(colors.black)
  const [brushSize] = useState<number>(0)

  /** data use */

  const [isSavePopup, setIsSavePopup] = useState<boolean>(false)
  const [patientId, setPatientId] = useState<number>(1)
  const [listDoctorNote, setListDoctorNote] = useState<any[]>([])
  const [patientCaseId] = useState('')

  const [doctorNoteId, setDoctorNoteId] = useState<number>(0)
  const [doctorNoteDetail, setDoctorNoteDetail] = useState<any>()
  const [createNewNote, setCreateNewNote] = useState<any>(true)
  const [saveDoctorNote] = useState<PayloadDoctorNote>({
    patientId: 8,
    branchId: 1,
    patientCaseId: 0,
    userId: 0,
    doctorNoteName: '',
    base64Note: ''
  })
  const [showDocumentDoctorNote, setShowDocumentDoctorNote] = useState<boolean>(true)
  const [saveDoctorNoteDataURL, setSaveDoctorNoteDataURL] = useState<any>()


  const handleEditCanvas = (objEdit?: any) => {
    if (objEdit) {
      setDoctorNoteDetail(objEdit)
      setDoctorNoteId(objEdit.doctorNoteId)
    } else {
      if (doctorNoteId === 0) {
        setDoctorNoteId(1)
        setDoctorNoteId(0)
      } else {
        setDoctorNoteId(0)
      }
    }
  }

  useEffect(() => {
    const { patientId: id } = props.patient
    setPatientId(id)
    loadDoctorNote(id)
  }, [props.patient])

  useEffect(() => {
    if (!modal) {
      setIsSavePopup(false)
      dispatch(resetModal())
    }
  }, [modal])



  const handleCanvasSubmit = (dataURL: any) => {
    setSaveDoctorNoteDataURL(dataURL)
    handleSave()
  }

  const handleNewCanvas = () => {
    setDoctorNoteId(0)
    setDoctorNoteDetail(null)
  }

  const loadDoctorNote = async (pId?: number) => {
    TreatmentApi.doctorNoteList(pId || patientId).then(({ data }) => {
      setListDoctorNote(data)
    })
  }

  const openShowDocumentDoctorNote = async () => {
    showDocumentDoctorNote === true ? setShowDocumentDoctorNote(false) : setShowDocumentDoctorNote(true)
  }

  const submit = async (name: any) => {
    setIsSavePopup(false)
    if (props.patient) {
      const { patientId: valuePatientId, patientCase } = props.patient
      let patientCaseIdDefault = null
      if (patientCase) patientCaseIdDefault = patientCase.patientCaseId
      const payload: PayloadDoctorNote = {
        patientId: valuePatientId,
        patientCaseId: Number(patientCaseId) || patientCaseIdDefault,
        branchId: getBranch(),
        userId: props?.userId || 0,
        doctorNoteName: name || doctorNoteDetail?.doctorNoteName || 'New Note',
        base64Note: saveDoctorNoteDataURL || saveDoctorNote.base64Note || doctorNoteDetail.base64Note
      }
      if (doctorNoteId !== 0) {
        const tempPayload: PayloadDoctorNote = { ...payload }
        delete tempPayload.patientId
        update(tempPayload, doctorNoteId)
      } else create(payload)
    }
  }
  const create = async (payload: PayloadDoctorNote) => {
    TreatmentApi.doctorNoteStore(payload)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          loadDoctorNote()
          handleEditCanvas()
          handleNewCanvas()
          notiSuccess(t(`DOCTOR_NOTE.MESSAGE.SUCCESS.CREATE`), '', null, null, !props.isHistoryTreatment ? 'register-page' : '')
        }
      })
      .catch((e) => {
        const err = e.response.data
        notiError(t(`DOCTOR_NOTE.MESSAGE.${err.message}`), '', null, null, !props.isHistoryTreatment ? 'register-page' : '')
      })
  }

  const update = async (payload: PayloadDoctorNote, valueDoctorNoteId: number) => {
    TreatmentApi.doctorNoteUpdate(payload, valueDoctorNoteId)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          loadDoctorNote()
          handleEditCanvas()
          handleNewCanvas()
          notiSuccess(t(`DOCTOR_NOTE.MESSAGE.SUCCESS.CREATE`), '', null, null, !props.isHistoryTreatment ? 'register-page' : '')
        }
      })
      .catch((e) => {
        const err = e.response.data
        notiError(t(`DOCTOR_NOTE.MESSAGE.${err.message}`), '', null, null, !props.isHistoryTreatment ? 'register-page' : '')
      })
  }

  const detroy = async (valueDoctorNoteId: number) => {
    swalDelete(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('DOCTOR_NOTE.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="text-center  mb-0 mx-auto" style="max-width: 306px">${t(`DOCTOR_NOTE.ALERT.CONFIRM_DELETE_DETAIL`)} </p>`,
      (res: any) => {

        if (res && props.hasDeleteDoctorNote === 'PERMISS') {
          TreatmentApi.doctorNoteDelete(valueDoctorNoteId)
            .then((resp: any) => {
              if (resp.status !== undefined && resp.status === 200) {
                loadDoctorNote()
                handleEditCanvas()
                notiSuccess(t(`DOCTOR_NOTE.MESSAGE.SUCCESS.DELETE`), '', null, null, !props.isHistoryTreatment ? 'register-page' : '')
              }
            })
            .catch((e) => {
              const err = e.response.data
              notiError(t(`DOCTOR_NOTE.MESSAGE.${err.message}`), '', null, null, !props.isHistoryTreatment ? 'register-page' : '')
            })
        } else if (res && props.hasDeleteDoctorNote !== 'PERMISS') {
          notiError(t(`DOCTOR_NOTE.MESSAGE.ERROR.NOTRIGHT`))
        }
      },
      { confirmButtonText: t(`DOCTOR_NOTE.BUTTON.DEL_NOTE`), cancelButtonText: t('BUTTON.CANCEL') }
    )
  }

  const handleSave = async () => {
    dispatch(showModal())
    setIsSavePopup(true)
  }

  const handleCreateNote = async () => {
    setCreateNewNote(true)
    setShowDocumentDoctorNote(false)
  }

  const componentRef = useRef<HTMLDivElement>(null)

  const checkHeaderDrawingZoneBigSize = () => {
    if (doctorNoteDetail) {
      return (
        <Col xs={12} xl={12} className="order-2 order-xl-1 px-0" style={{ border: `0px solid ${colors.lightGray}` }}>
          <UseStyled.TreatmentListItem className="d-xl-flex flex-column">
            {doctorNoteId === 0 ? (
              <UseStyled.TypographyTitle
                className={'pb-2'}
                style={{ border: `1px solid ${colors.lightGray}`, borderStyle: 'solid none solid solid', textAlign: 'start', fontSize: '16px', backgroundColor: colors.white, minHeight: '50px', paddingTop: '15px', paddingLeft: '10px' }}
              >
                <span style={{ color: '#929292' }}>{t('DOCTOR_NOTE.WATCH_NOTE_NOW')}</span>
                <span style={{ backgroundColor: colors.lightGray, marginLeft: '10px' }}>{` ${t('DOCTOR_NOTE.NOTE_DRAFT')} `}</span>
              </UseStyled.TypographyTitle>
            ) : (
              <UseStyled.TypographyTitle
                className={'pb-2'}
                style={{ border: `1px solid ${colors.lightGray}`, borderStyle: 'solid none solid solid', textAlign: 'start', fontSize: '16px', backgroundColor: colors.white, minHeight: '50px', paddingTop: '15px', paddingLeft: '10px' }}
              >
                <span style={{ color: '#929292' }}>{t('DOCTOR_NOTE.WATCH_NOTE_NOW')}</span>
                <span>{` VN ${props.patient?.patientCase?.vnNumber} ${toBuddhistYear(moment(listDoctorNote[0]?.createdAt), 'DD/MM/YYYY')} > ${doctorNoteDetail.doctorNoteName} `}</span>
                <span style={{ color: '#929292' }}>({toBuddhistYear(moment(doctorNoteDetail.createdAt), 'DD/MM/YYYY')})</span>
              </UseStyled.TypographyTitle>
            )}
          </UseStyled.TreatmentListItem>
        </Col>
      )
    } else {
      return (createNewNote ? (
        <Col xs={12} xl={12} className="order-2 order-xl-1 px-0" style={{ border: `0px solid ${colors.lightGray}` }}>
          <UseStyled.TreatmentListItem className="d-xl-flex flex-column">
            <UseStyled.TypographyTitle
              className={'pb-2'}
              style={{ border: `1px solid ${colors.lightGray}`, borderStyle: 'solid none solid solid', textAlign: 'start', fontSize: '16px', backgroundColor: colors.white, minHeight: '50px', paddingTop: '15px', paddingLeft: '10px' }}
            >
              <span style={{ color: '#929292' }}>{t('DOCTOR_NOTE.WATCH_NOTE_NOW')}</span>
              <span style={{ backgroundColor: colors.lightGray, marginLeft: '10px' }}>{` ${t('DOCTOR_NOTE.NOTE_DRAFT')} `}</span>
            </UseStyled.TypographyTitle>
          </UseStyled.TreatmentListItem>
        </Col>
      ) : (
        <Col xs={12} xl={12} className="order-2 order-xl-1 px-0" style={{ border: `0px solid ${colors.lightGray}` }}>
          <UseStyled.TreatmentListItem className="d-xl-flex flex-column">
            <UseStyled.TypographyTitle
              className={'pb-2'}
              style={{ border: `1px solid ${colors.lightGray}`, borderStyle: 'solid none solid solid', textAlign: 'start', fontSize: '16px', backgroundColor: colors.white, minHeight: '50px', paddingTop: '15px', paddingLeft: '10px' }}
            >
              {t('DOCTOR_NOTE.WATCH_NOTE_EMPTY')}
            </UseStyled.TypographyTitle>
          </UseStyled.TreatmentListItem>
        </Col>
      ))
    }
  }

  const checkHeaderDrawingZoneSmallSize = () => {
    if (doctorNoteDetail) {
      return (
        <Col xs={12} xl={12} className="order-2 order-xl-1 px-0" style={{ border: `0px solid ${colors.lightGray}` }}>
          <UseStyled.TreatmentListItem className="d-xl-flex flex-column">
            <UseStyled.TypographyTitle
              className={'pb-2'}
              style={{
                border: `1px solid ${colors.lightGray}`,
                borderStyle: 'solid none solid solid',
                textAlign: 'start',
                fontSize: '16px',
                backgroundColor: colors.white,
                minHeight: '50px',
                paddingTop: '15px',
                paddingLeft: '10px',
                justifyContent: 'center',
                display: 'flex'
              }}
            >
              <span style={{}}>{t('DOCTOR_NOTE.ALL_PAGE')}</span>
              <span>{` (${listDoctorNote.length}) หน้า`}</span>
            </UseStyled.TypographyTitle>
          </UseStyled.TreatmentListItem>
        </Col>
      )
    } else {
      return (createNewNote ? (
        <Col xs={12} xl={12} className="order-2 order-xl-1 px-0" style={{ border: `0px solid ${colors.lightGray}` }}>
          <UseStyled.TreatmentListItem className="d-xl-flex flex-column">
            <UseStyled.TypographyTitle
              className={'pb-2'}
              style={{ border: `1px solid ${colors.lightGray}`, borderStyle: 'solid none solid solid', textAlign: 'start', fontSize: '16px', backgroundColor: colors.white, minHeight: '50px', paddingTop: '15px', paddingLeft: '10px' }}
            >
              <span style={{ color: '#929292' }}>{t('DOCTOR_NOTE.WATCH_NOTE_NOW')}</span>
              <span style={{ backgroundColor: colors.lightGray, marginLeft: '10px' }}>{` ${t('DOCTOR_NOTE.NOTE_DRAFT')} `}</span>
            </UseStyled.TypographyTitle>
          </UseStyled.TreatmentListItem>
        </Col>
      ) : (
        <Col xs={12} xl={12} className="order-2 order-xl-1 px-0" style={{ border: `0px solid ${colors.lightGray}` }}>
          <UseStyled.TreatmentListItem className="d-xl-flex flex-column">
            <UseStyled.TypographyTitle
              className={'pb-2'}
              style={{ border: `1px solid ${colors.lightGray}`, borderStyle: 'solid none solid solid', textAlign: 'start', fontSize: '16px', backgroundColor: colors.white, minHeight: '50px', paddingTop: '15px', paddingLeft: '10px' }}
            >
              {t('DOCTOR_NOTE.WATCH_NOTE_EMPTY')}
            </UseStyled.TypographyTitle>
          </UseStyled.TreatmentListItem>
        </Col>
      ))
    }
  }

  return (
    <DivDoctorNote>
      <Row style={{ minHeight: '500px' }}>
        {width >= 1200 ? (
          checkHeaderDrawingZoneBigSize()
        ) :
          checkHeaderDrawingZoneSmallSize()
        }
        {createNewNote ? (
          <Col xs={12} xl={8 + (showDocumentDoctorNote ? 0 : 3)} className={`order-2 order-xl-1 px-0 h-100 `} style={{ border: `1px solid ${colors.lightGray}` }}>
            <CanvasDisign
              submit={(dataURL: any) => handleCanvasSubmit(dataURL)}
              dataUrl={doctorNoteDetail ? doctorNoteDetail.base64Note : ''}
              doctorNoteId={doctorNoteId ? doctorNoteId : 0}
              brushColor={brushColor}
              brushSize={brushSize}
              hasDoctorNote={props.hasDoctorNote}
              hasCreateDoctorNote={props.hasCreateDoctorNote}
              isHistoryTreatment={props.isHistoryTreatment}
              loadDoctorNote={() => loadDoctorNote()}
              setNewPage={() => handleNewCanvas()}
              hasDeleteDoctorNote={props.hasDeleteDoctorNote}
            />
          </Col>
        ) : (
          <Col xs={12} xl={12} className="order-2 order-xl-1 px-0 h-100" style={{ border: `1px solid ${colors.lightGray}` }}>
            <Col style={{ height: 700 }}>
              <Col style={{ height: 650, border: `1px solid ${colors.lightGray}`, margin: '50px' }}>
                <Col style={{ textAlign: 'center', paddingTop: '250px' }}>{t('DOCTOR_NOTE.SUGGESTION')} </Col>
                <Col style={{ textAlign: 'center', paddingTop: '30px', color: colors.themeSecondColor }}>
                  <a onClick={() => handleCreateNote()}>{t('DOCTOR_NOTE.BUTTON.CREATE_NOTE')}</a>
                </Col>
              </Col>
            </Col>
          </Col>
        )}
        {showDocumentDoctorNote ? (
          <Col xl={4} className="order-1 order-xl-2 px-3 h-100 overflow-auto">
            <Row style={{ marginTop: '10px' }}>
              <Col xl={10}>
                <strong>
                  {t('DOCTOR_NOTE.ALL_PAGE')}
                  {`(${listDoctorNote.length})`}
                </strong>
              </Col>
            </Row>
            <Row className="mx-0 custom-scroll ml-5" style={{ maxHeight: '725px', overflow: 'hidden', overflowY: 'scroll', marginTop: '5px' }}>
              {(props.hasDoctorNote !== 'UNPERMISS' && !props.isHistoryTreatment) && (
                <Col style={{ height: '130px', width: '150px' }} className={`col-auto px-0 m-1 list-doctor-note ${doctorNoteId === 0 && 'active'}`} onClick={() => handleNewCanvas()}>
                  <div className="d-flex flex-center p-2 h-100">
                    <div className="text-center">
                      <img id="img-add-note" alt="" className="img-add-note" src={iconAddFile} />
                      <p className="mb-0 mt-1 fz-14 text-muted">{t('DOCTOR_NOTE.ADD_PAPER')}</p>
                    </div>
                  </div>
                </Col>
              )}
              {_.map(listDoctorNote, (doctorNote: any, i: number) => {
                return (
                  <Col style={{ height: '130px', width: '150px' }} key={i} className={`col-auto m-1 px-0 list-doctor-note ${doctorNote.doctorNoteId === doctorNoteId && 'active'}`} onClick={() => handleEditCanvas(doctorNote)}>
                    <div className="position-relative overflow-hidden h-100 w-100">
                      {(doctorNoteId === doctorNote.doctorNoteId && !props.isHistoryTreatment) && (
                        <IconButton
                          className={'btn-delete'}
                          aria-label="delete"
                          onClick={() => {
                            detroy(doctorNote.doctorNoteId)
                          }}
                          sx={{ backgroundColor: 'transparent !important', padding: 0, position: 'absolute', marginLeft: '120px' }}
                        >
                          <img src={icons.btnTrash} alt="trash" style={{ width: 24 }} />
                        </IconButton>
                      )}
                      <div ref={componentRef}>
                        <img id={`doctor-note-render-${doctorNote.doctorNoteId}`} src={doctorNote.base64Note} onError={onImgError} style={{ height: '100%', width: '100%', objectFit: 'cover' }} alt="" />
                      </div>
                    </div>
                    <Tooltip title={(<>
                      <div>{t('DOCTOR_NOTE.CREATOR')} {doctorNote.createdBy}</div>
                      <div> {t('DOCTOR_NOTE.EDITOR')} {doctorNote.updatedBy}</div></>)} placement="top">
                      <div className="position-relative" style={{ backgroundColor: 'white', marginTop: '-30px', height: '30px' }}>
                        <Row xs={12} xl={12}>
                          <Col xs={6} xl={6} style={{ fontSize: '8px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <span><img src={iconCreatedBy} />{t('DOCTOR_NOTE.CREATOR')} {doctorNote.createdBy}</span>
                          </Col>
                          <Col xs={6} xl={6} style={{ fontSize: '8px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <img src={iconCreatedAt} /> {toBuddhistYear(moment(doctorNote.createdAt), 'DD/MM/YY')}
                          </Col>
                        </Row>
                        <Row xs={12} xl={12}>
                          <Col xs={6} xl={6} style={{ fontSize: '8px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <span>
                              <img src={iconUpdateBy} />
                              {t('DOCTOR_NOTE.EDITOR')}
                              {doctorNote.updatedBy}
                            </span>
                          </Col>
                          <Col xs={6} xl={6} style={{ fontSize: '8px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <img src={iconUpdateAt} /> {toBuddhistYear(moment(doctorNote.updatedAt), 'DD/MM/YY')}
                          </Col>
                        </Row>
                      </div>
                    </Tooltip>
                  </Col>
                )
              })}
            </Row>
          </Col>
        ) : (
          <Col xl={1} className="order-1 order-xl-2 px-3 h-100 overflow-auto">
            <Row>
              <ButtonCustom variant={'outlined'} style={{ fontSize: '12px', marginTop: '5px' }} textButton={t('DOCTOR_NOTE.ALL_PAGE')} onClick={openShowDocumentDoctorNote} />
            </Row>
            {_.map(listDoctorNote, (doctorNote: any, i: number) => (
              <Col hidden style={{ height: '130px', width: '130px' }} key={i} className={`col-auto m-1 px-0 list-doctor-note ${doctorNote.doctorNoteId === doctorNoteId && 'active'}`} onClick={() => handleEditCanvas(doctorNote)}>
                <div className="position-relative overflow-hidden h-100 w-100">
                  <div ref={componentRef}>
                    <img id={`doctor-note-render-${doctorNote.doctorNoteId}`} src={doctorNote.base64Note} onError={onImgError} style={{ height: '100%', width: '100%', objectFit: 'cover' }} alt="" />
                  </div>
                </div>
              </Col>
            ))}
          </Col>
        )}
      </Row>

      <SavePopup isShow={isSavePopup} doctorNoteDetail={doctorNoteDetail} onCancel={() => setIsSavePopup(false)} onSubmit={submit} vnNumber={visitActive} />
    </DivDoctorNote>
  )
}
