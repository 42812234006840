import { useEffect, useState } from 'react';
import _ from 'lodash'
import { Box, IconButton, Typography, TableRow, TableCell } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'
import $ from 'jquery'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** UTILS || SLICE */
import { treatment, setTreatmentByKey, TreatmentStateInterface, setDiagnosisTreatments, setEditTootChart, setEditTootChartType, isEditTootChart } from 'app/slice/treatment.slice';
import useWindowSize from 'utils/useWindowSize'
import * as modalSlice from 'app/slice/modal.slice'
import { clinicTheme } from 'api/api';

/** COMPONENT */
import TableCustom from 'component/Table/TableCustom'
import InputTextarea from 'component/Input/InputTextarea'
import ButtonCustom from 'component/Button/ButtonCustom'
import PopupMessage from 'component/Treatments/PopupMessage'
import AutocompleteSelect from 'component/Select/AutocompleteSelect';
import InputTextField from 'component/Input/InputTextField';

/** STYLED */
import { icons, Icons } from 'constants/images'
import { styled } from '@mui/material/styles'
import * as UseStyled from 'features/treatments/SummaryTreatment/UseStyle'
import ClinicApi from 'api/master/clinic.api'
import { selectMe } from 'app/slice/user.slice';
import BtnMinus from 'assets/icon/BtnMinus';
import { useLocation } from 'react-router-dom';

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  width: 130,
  flex: 'none',
  [theme.breakpoints.down('xs')]: {
    width: 120
  }
}))

type IDiagnosis = {
  userId?: number
}

type ConfigModal = {
  type?: 'ViewMessage'
  itemId?: number
}

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}

export default function Diagnosis(props: IDiagnosis) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const user = useSelector(selectMe)
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const isEditTootCharts: any = useSelector(isEditTootChart)
  const [width] = useWindowSize()
  const [configModal, setConfigModal] = useState<ConfigModal>({})
  const [doctors, setDoctors] = useState<Doctors[]>([]);

  const location = useLocation();
  const [checkPath, setCheckPath] = useState<boolean>(false)
  const disabledPath = ['/treatments']

  const loadDoctor = async () => {
    const resp = await ClinicApi.findAllDoctors()
    if (resp.status === 200) setDoctors(resp.data)
  }

  useEffect(() => {
    loadDoctor()
  }, []);

  useEffect(() => {
    if (!disabledPath.includes(location?.pathname)) {
      setCheckPath(true)
    } else {
      setCheckPath(false)
    }
  }, [location])

  const handleChange = (e: any, index: number) => {
    if (e.target.name === 'doctorId' && treatments.patientDiagnosis[0].pinDoctorId && e.target.value !== treatments.patientDiagnosis[0].pinDoctorId) {
      handlePinDoctor('unPin', 0)
      dispatch(setDiagnosisTreatments({ index: index, key: e.target.name, value: e.target.value }))
    } else dispatch(setDiagnosisTreatments({ index: index, key: e.target.name, value: e.target.value }))
  }

  const handleDiscard = (id: number) => {
    const item = (_.filter(treatments.patientDiagnosis, (diagnosisItem, index: number) => index !== id))
    dispatch(setTreatmentByKey({ key: 'patientDiagnosis', value: item }))
    dispatch(setEditTootChart(null))
  }

  const onViewMessage = (id: number) => {
    setConfigModal({ type: 'ViewMessage', itemId: id })
    dispatch(modalSlice.showModal())
  }

  const onRestPopup = () => {
    setConfigModal({})
    dispatch(modalSlice.resetModal())
  }

  const handleRowHover = (type: 'onMouseEnter' | 'onMouseLeave', item: string) => {
    if (type === 'onMouseEnter') $(`#${item}`).prev().addClass('table-row-hover')
    else $(`#${item}`).prev().removeClass('table-row-hover')
  }

  const handlePinDoctor = (type: 'pin' | 'unPin', doctorId: number) => {
    const item = _.map(treatments.patientDiagnosis, (treatmentDiagnosis, index) => {
      if (type === 'pin') return { ...treatmentDiagnosis, doctorId: doctorId, pinDoctorId: doctorId }
      else return { ...treatmentDiagnosis, pinDoctorId: null }
    })
    dispatch(setTreatmentByKey({ key: 'patientDiagnosis', value: item }))
  }

  const renderDoctors = () => {
    const pinDoctor = _.find(doctors, { userId: treatments?.patientDiagnosis[0]?.pinDoctorId })
    if (pinDoctor) {
      const filterDoctor = _.filter(doctors, (d) => d.userId !== pinDoctor.userId)
      return [pinDoctor, ...filterDoctor]
    } else return doctors
  }

  const editToothChart = async (id: number) => {
    if (treatments.patientDiagnosis[id]) {
      $('.div-component').delay(500).animate({ scrollTop: 0 }, 500);
      dispatch(setEditTootChartType(treatments.patientDiagnosis[id].teethType))
      dispatch(setEditTootChart(id))
      const emrDetail = $('.emr-detail')
      const toothChart = $('.tooth-chart')
      if (emrDetail) {
        emrDetail[0]?.scrollTo({ top: toothChart[0].offsetTop, behavior: "smooth" })
      }
    }
  }

  const headCellsTooth = clinicTheme.theme === clinicTheme.type.DENTAL ? [{ id: 'message', disablePadding: false, label: t('TREATMENT_SUMMARY.TITLE.TOOTH'), align: 'left', width: width >= 1200 ? '250px' : '200px' }] : []

  const headCells = [
    { id: 'no', disablePadding: false, align: 'center', label: '#' },
    ...headCellsTooth,
    { id: 'message', disablePadding: false, align: 'center', label: t('DIAGNOSIS_SUMMARY.TITLE.CODE'), width: width >= 1200 ? '120px' : '100px' },
    { id: 'message', disablePadding: false, align: 'left', label: t('DIAGNOSIS_SUMMARY.TITLE.DIAGNOSIS') },
    { id: 'message', disablePadding: false, align: 'left', label: t('DIAGNOSIS_SUMMARY.TITLE.DIAGNOSIS_PERSON'), width: width >= 1200 ? '250px' : '180px' },
    { id: 'message', disablePadding: false, align: 'center', label: t('Action'), width: '80px' }
  ]

  const renderData = (data: any, no: number) => {
    return (
      <>
        <TableRow key={`diagnosis-${no}`} className="main-table-row">
          <TableCell align="center" scope="row" className="py-3 py-md-2">
            {no + 1}
          </TableCell>
          {(width >= 768 && (
            <>
              {clinicTheme.theme === clinicTheme.type.DENTAL && (
                <TableCell align="center" scope="row" className="py-3 py-md-2">
                  <UseStyled.InputToothChart className={`d-sm-flex w-100 text-nowrap`}>
                    <InputTextField disabled value={data?.teeth || ''} />
                    <UseStyled.BtnEditToothChart
                      disableFocusRipple
                      disableRipple
                      onClick={() => editToothChart(no)}
                      className={`p-0 ml-2 ${isEditTootCharts === no ? 'active' : ''}`}>
                      {isEditTootCharts === no ? <Icons.ToothSolid /> : <Icons.ToothOutlined />}
                    </UseStyled.BtnEditToothChart>
                  </UseStyled.InputToothChart>
                </TableCell>
              )}
              <TableCell align="center" scope="row" className="py-3 py-md-2">
                {data.diagnosisCode}
              </TableCell>
              <TableCell align="left" scope="row" className="py-3 py-md-2 text-ellipsis">
                {data.diagnosisName}
              </TableCell>
              <TableCell align="left" scope="row" className="py-3 py-md-2">
                <AutocompleteSelect
                  labelId="sl-dentist"
                  options={renderDoctors()}
                  getOptionLabel={(option) => option.fullname}
                  renderOption={(props, option) => {
                    return (
                      <UseStyled.DentistList
                        {...props}
                        key={option.userId}
                        component="li"
                        className={`d-flex align-items-center justify-content-between w-100 cursor-pointer overflow-hidden`}>
                        <span onClick={() => handleChange({ target: { name: 'doctorId', value: option.userId } }, no)} className='text-ellipsis'>{option.fullname}</span>
                        <Box className="ml-2" onClick={() => handlePinDoctor('pin', option.userId)}>
                          <Icons.MarkLine active={option.userId === treatments?.patientDiagnosis[0]?.pinDoctorId} />
                        </Box>
                      </UseStyled.DentistList>
                    )
                  }}
                  onchange={() => { return }}
                  noOptionsText={t('NOT_FOUND')}
                  filterOptions={(option) => option.fullname}
                  value={_.find(doctors, { userId: data.doctorId }) || null}
                  disableClearable
                />
              </TableCell>
              <TableCell align="center" scope="row" className="py-3 py-md-2">
                <IconButton
                  disableFocusRipple
                  disableRipple
                  onClick={() => handleDiscard(no)}
                  className="p-0 align-self-start mr-2 ml-3"
                  sx={{ backgroundColor: 'transparent' }}
                  disabled={checkPath && user.hasDeleteDiagnosis !== "PERMISS"}
                >
                  <BtnMinus fill={checkPath && user.hasDeleteDiagnosis !== "PERMISS" && '#BDBDBD'} />
                </IconButton>
              </TableCell>
            </>
          )) || (
              <TableCell align="left" scope="row" className="py-3 py-md-2" colSpan={_.size(headCells) - 1}>
                {clinicTheme.theme === clinicTheme.type.DENTAL && (
                  <Box className="d-flex justify-content-between mb-3">
                    <UseStyled.InputToothChart className={`d-sm-flex w-100 text-nowrap`}>
                      <InputTextField disabled value={data?.teeth || ''} />
                      <UseStyled.BtnEditToothChart
                        disableFocusRipple
                        disableRipple
                        onClick={() => editToothChart(no)}
                        className={`p-0 ml-2 ${isEditTootCharts === no ? 'active' : ''}`}>
                        {isEditTootCharts === no ? <Icons.ToothSolid /> : <Icons.ToothOutlined />}
                      </UseStyled.BtnEditToothChart>
                    </UseStyled.InputToothChart>
                    <IconButton
                      disableFocusRipple
                      disableRipple
                      onClick={() => handleDiscard(no)}
                      className="p-0 align-self-start mr-2 ml-3 my-auto"
                      sx={{ backgroundColor: 'transparent' }}
                      disabled={checkPath && user.hasDeleteDiagnosis !== "PERMISS"}
                    >
                      <BtnMinus fill={checkPath && user.hasDeleteDiagnosis !== "PERMISS" && '#BDBDBD'} />
                    </IconButton>
                  </Box>
                )}
                <Box className="d-flex justify-content-between">
                  <Box className="pr-2">
                    <Title className="d-inline-block pr-2">{t('DIAGNOSIS_SUMMARY.TITLE.CODE')}</Title>
                    <Typography className="d-inline">{data.diagnosisCode}</Typography>
                  </Box>
                  {clinicTheme.theme !== clinicTheme.type.DENTAL && (
                    <IconButton
                      disableFocusRipple
                      disableRipple
                      onClick={() => handleDiscard(no)}
                      className="p-0 align-self-start mr-2 ml-3"
                      sx={{ backgroundColor: 'transparent' }}
                      disabled={checkPath && user.hasDeleteDiagnosis !== "PERMISS"}
                    >
                      <BtnMinus fill={checkPath && user.hasDeleteDiagnosis !== "PERMISS" && '#BDBDBD'} />
                    </IconButton>
                  )}
                </Box>
                <Box className="mt-3 pr-2 d-flex">
                  <Title className="pr-2">{t('DIAGNOSIS_SUMMARY.TITLE.DIAGNOSIS')}</Title>
                  <Typography>{data.diagnosisName}</Typography>
                </Box>
                <Box className="mt-3 d-flex align-items-center pr-2">
                  <Title className="d-inline-block pr-2">{t('DIAGNOSIS_SUMMARY.TITLE.DIAGNOSIS_PERSON')}</Title>
                  <AutocompleteSelect
                    labelId="sl-dentist"
                    options={renderDoctors()}
                    getOptionLabel={(option) => option.fullname}
                    renderOption={(props, option) => {
                      return (
                        <UseStyled.DentistList
                          {...props}
                          key={option.userId}
                          component="li"
                          className={`d-flex align-items-center justify-content-between w-100 cursor-pointer overflow-hidden`}>
                          <span onClick={() => handleChange({ target: { name: 'doctorId', value: option.userId } }, no)} className='text-ellipsis'>{option.fullname}</span>
                          <Box className="ml-2" onClick={() => handlePinDoctor('pin', option.userId)}>
                            <Icons.MarkLine active={option.userId === treatments?.patientDiagnosis[0]?.pinDoctorId} />
                          </Box>
                        </UseStyled.DentistList>
                      )
                    }}
                    onchange={() => { return }}
                    noOptionsText={t('NOT_FOUND')}
                    filterOptions={(option) => option.fullname}
                    value={_.find(doctors, { userId: data.doctorId }) || null}
                    disableClearable
                  />
                </Box>
                <Box className="w-100 d-flex mt-3 pr-2">
                  <Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.MORE_INFO')}</Title>
                  <Box className="d-flex w-100">
                    <UseStyled.InputFormControl className="d-flex" sx={{ flex: 1 }}>
                      <InputTextarea height={64} name="note" onchange={(e: any) => handleChange(e, no)} />
                      <ButtonCustom onClick={() => onViewMessage(no)} textButton={<img src={icons.iconFeatherEdit} alt="" style={{ width: 24 }} />} className="h-100" style={{ margin: 0, height: '100%' }} />
                    </UseStyled.InputFormControl>
                  </Box>
                </Box>
              </TableCell>
            )}
        </TableRow>
        {width >= 768 && (
          <TableRow key={`diagnosis-row-${no}`} id={`diagnosis-row-${no}`} onMouseEnter={() => handleRowHover('onMouseEnter', `diagnosis-row-${no}`)} onMouseLeave={() => handleRowHover('onMouseLeave', `diagnosis-row-${no}`)}>
            <TableCell align="left" scope="row" className="pt-1"></TableCell>
            <TableCell align="left" scope="row" className="py-3 py-md-2" colSpan={_.size(headCells) - 2}>
              <Box className="w-100 d-flex">
                <Typography>{t('TREATMENT_SUMMARY.TITLE.MORE_INFO')}</Typography>
                <UseStyled.InputFormControl className="pl-2 d-flex" sx={{ flex: 1 }}>
                  <InputTextarea height={64} name="note" value={data.note} onchange={(e: any) => handleChange(e, no)} />
                  <ButtonCustom onClick={() => onViewMessage(no)} textButton={<img src={icons.iconFeatherEdit} alt="" style={{ width: 24 }} />} className="h-100" style={{ margin: 0, height: '100%' }} />
                </UseStyled.InputFormControl>
              </Box>
            </TableCell>
            <TableCell align="left" scope="row" className="pt-1"></TableCell>
          </TableRow>
        )}
      </>
    )
  }

  return (
    <>
      <UseStyled.ResponsiveSummaryDiagnosisTable className={'overflow-hidden'}>
        <TableCustom
          hidePagination
          customScroll
          page={0}
          pageLimit={0}
          sortType={'ASC'}
          sortBy={''}
          rowCount={0}
          textEmptyData={t('REGISTER.NO_ITEM')}
          onSort={() => {
            return
          }}
          setPageLimit={() => {
            return
          }}
          setPage={() => {
            return
          }}
          headCells={headCells}
          rowsData={_.map(treatments.patientDiagnosis, (i, index: number) => {
            return renderData(i, index)
          }).reverse()}
        />
      </UseStyled.ResponsiveSummaryDiagnosisTable>
      {configModal.type === 'ViewMessage' && <PopupMessage type="diagnosis" userId={props.userId} itemId={configModal.itemId} onReset={onRestPopup} />}
    </>
  )
}
