import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ServicePointArea from 'component/Appointment/ServicePointsArea'
import CardServicePoint from 'component/Appointment/CardServicePoint'
import { colors } from 'constants/theme'
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AppointmentApi from 'api/counter/appointment.api'
import { useTranslation } from 'react-i18next'
import { dateToView } from 'utils/date.utils'
import moment from 'moment'
import { getClinicInfo } from 'utils/app.utils'
import { AppointmentAppContainer, AppointmentAppCard, AppointmentAppStatus } from './style'

type AppointmentAppProps = {
    handleOpenAMApp: (status: boolean) => void
    handleApp: (item: any) => void
    dataAppAppointment: any
}

const AppointmentApp = (props: AppointmentAppProps) => {
    const { t, i18n } = useTranslation()
    const branch = getClinicInfo()

    const handlePatientCode = (item: any) => {
        if (branch?.patientCodeAppointment === '1') {
            return item?.cnNumber || '-'
        }
        if (branch?.patientCodeAppointment === '0') {
            return item?.branchCnNumber || '-'
        }
        if (branch?.patientCodeAppointment === '2') {
            return item?.snCode || '-'
        }
    }

    const handleAppointmentApp = (item: any) => {
        props.handleApp(item)
        props.handleOpenAMApp(true)
    }

    const RenderStatusApppointment = () => {
        return <AppointmentAppStatus>
            {t('APPOINTMENT.STATUS.WAITING_APPROVE_APP')}
        </AppointmentAppStatus>
    }

    return (
        <>
            <AppointmentAppContainer className={`appointment-app`} >
                <Box className={'appointment-app-content'}>
                    <ServicePointArea
                        key={111}
                        headerName={t("APPOINTMENT.APP.TITLE")}
                        serviceType={``}
                        backgroundHeader={colors.servicePoint.roomServices}
                        body={
                            props.dataAppAppointment.map((item: any, index: number) => {
                                return (
                                    <AppointmentAppCard key={index} >
                                        <Box
                                            className={'am-app-card'}
                                            onClick={() => handleAppointmentApp(item)}
                                        >
                                            <Box className={'am-app-number'}>
                                                {String(index + 1).padStart(2, '0')}
                                            </Box>
                                            <RenderStatusApppointment />
                                            <Box sx={{ height: '100%', padding: '1rem 2rem 0.5rem' }}>
                                                <Box>
                                                    {handlePatientCode(item)}
                                                </Box>
                                                <Box>
                                                    {item.patientFullname}
                                                </Box>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}>
                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '8px' }}>
                                                        <EventIcon style={{ color: colors.themeSecondColor, width: 24, height: 24 }} />
                                                        <span>
                                                            {t('APPOINTMENT.APP.DATE')}
                                                            {dateToView(item.date)}
                                                        </span>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '8px' }}>
                                                        <AccessTimeIcon style={{ color: colors.themeSecondColor, width: 24, height: 24 }} />
                                                        <span>
                                                            {moment(item?.timeStart, 'HH:mm:ss').format('HH:mm') || ''}{i18n.language === 'th' && ' น.' || ''}
                                                        </span>
                                                    </Box>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </AppointmentAppCard>)
                            })
                        }
                    />

                </Box>
            </AppointmentAppContainer>
        </>
    )
}

export default AppointmentApp