import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row } from 'react-bootstrap';
import MenuItem from '@mui/material/MenuItem'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/setting/personnel-managements/personnels/style'
import { Avatar } from '@mui/material';

/** API */
import { fileUrl } from 'api/api'
import { routeName } from 'routes/routes-name'
import PersonnelApi from 'api/setting/personnel-managements/personnel.api'
import PositionApi from 'api/setting/personnel-managements/position.api'

/** UTILS || SLICE */
import { imgs } from 'constants/images'
import { colors } from 'constants/theme'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import { getPackageKey, getPermissionActiveMenu } from 'utils/app.utils'
import { PACKAGE_TYPE, userLimit } from 'constants/package'
import { selectMe } from 'app/slice/user.slice'

/** COMPONENT */
import { swalActive, swalWarning } from 'component/Alert/Swal'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import FilterSelect from 'component/Select/FilterSelect'
import ButtonExport from 'component/Button/ButtonExport'
import TableRowExport from 'component/Pdf/TableRowExport'
import { width } from 'pdfkit/js/page';

export default function Personnels() {

  const permissions = {
    isCreate: isCreate(),
    isDelete: isDelete(),
    isUpdate: isUpdate(),
    isView: isView()
  }

  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(selectMe)

  /** DATA FILTERS */
  const [positions, setPositions] = useState<any[]>([])
  const [personnels, setPersonnels] = useState([])
  const [exportData, setExportData] = useState([])

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('ALL')
  const [position, setPosition] = useState<number | 'ALL'>('ALL')

  const loadData = useCallback(async () => {

    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }
    if (position !== 'ALL') condition = { ...condition, position: position }

    const res = await PersonnelApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setPersonnels(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status, position])

  const loadDataForExport = useCallback(async () => {
    let condition: any = {}
    condition.export = 1
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }
    if (position !== 'ALL') condition = { ...condition, position: position }
    const res = await PersonnelApi.findAll(condition)
    if (res.status === 200) {
      setExportData(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status, position])

  const loadFilters = async () => {
    const respPositions = await PositionApi.findAll({ status: 'ACTIVE' })
    if (respPositions.status === 200) {
      setPositions(respPositions.data)
    }
  }

  useEffect(() => {
    loadDataForExport()
  }, [loadDataForExport])

  useEffect(() => {
    loadFilters()
  }, [])

  useEffect(() => {
    loadData()
  }, [loadData, dispatch])

  const onCreate = async () => {
    if (!checkIsUltraPackage()) {
      checkUserLimit(
        t('PERSONNEL.MESSAGE.LIMIT_PACKAGE.TITLE'),
        t('PERSONNEL.MESSAGE.LIMIT_PACKAGE.SUBTITLE', {
          package: getPackageKey(),
          number: userLimit(getPackageKey())
        }), () => { return }, () => { history.push(routeName.personnels + '/create') })
    } else history.push(routeName.personnels + '/create')
  }

  const onActive = (id: number, name: string, valueStatus: string) => {
    if (id === user?.userId && valueStatus === 'INACTIVE') {
      return swalWarning(
        `<p class="mb-0 mx-auto" style="max-width: 325px">${t('USER.ALERT.WARNING_MY_USER')}</p>`,
        `<p class="mb-0 mx-auto" style="max-width: 325px">${t('USER.ALERT.WARNING_MY_USER_', { user: name })}</p>`)
    }

    swalActive(`<p class="mb-0 mx-auto" style="max-width: 325px">${t('USER.ALERT.CONFIRM_STATUS')}</p>`, null, async (res: any) => {
      if (res) {
        if (!checkIsUltraPackage() && valueStatus === 'ACTIVE') {
          return checkUserLimit(
            t('PERSONNEL.MESSAGE.LIMIT_PACKAGE.TITLE'),
            t('PERSONNEL.MESSAGE.LIMIT_PACKAGE.SUBTITLE', {
              package: getPackageKey(),
              number: userLimit(getPackageKey())
            }), () => { return }, () => {
              updateStatus(id, valueStatus)
            })
        } else await updateStatus(id, valueStatus)
      }
    }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }

  const updateStatus = async (id: number, valueStatus: string) => {
    await PersonnelApi.updateStatus(id, valueStatus)
      .then((res) => {
        notiSuccess(t('USER.MESSAGE.SUCCESS.UPDATE_STATUS'))
        loadData()
      })
      .catch((e) => {
        notiError(t('USER.MESSAGE.ERROR'))
      })
  }

  const onDelete = (data: any) => {
    const { userId, firstname, lastname } = data

    if (userId === user?.userId) {
      return swalWarning(
        `<p class="mb-0 mx-auto" style="max-width: 325px">${t('USER.ALERT.WARNING_DELETE_MY_USER')}</p>`,
        `<p class="mb-0 mx-auto" style="max-width: 325px">${t('USER.ALERT.WARNING_MY_USER_', { user: `${firstname} ${lastname}` })}</p>`)
    }

    swalActive(
      t('USER.ALERT.CONFIRM_DELETE'),
      t('USER.ALERT.CONFIRM_DELETE_', {
        userCode: firstname ? firstname + ' ' + lastname : ''
      }),
      (res: any) => {
        if (res) {
          PersonnelApi.remove(userId)
            .then((resp) => {
              notiSuccess(t('USER.MESSAGE.SUCCESS.DELETE'))
              loadData()
            })
            .catch((e) => {
              notiError(t('USER.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
    )
  }

  const onEdit = (data: any) => history.push(routeName.personnels + '/update', data)

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => setPage(val)

  const handleChangeRowsPerPage = (limit: number) => setPageLimit(limit)

  const handleChangeStatus = (statusVal: string) => setStatus(statusVal)

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'img', disablePadding: false, label: t('PERSONNEL.TABLE.CELL.PERSONNEL_NAME') },
    { id: 'personnelPhone', disablePadding: false, label: t('PERSONNEL.TABLE.CELL.PERSONNEL_PHONE') },
    { id: 'personnelPosition', disablePadding: false, label: t('PERSONNEL.TABLE.CELL.PERSONNEL_POSITION') },
    { id: 'personnelRole', disablePadding: false, label: t('PERSONNEL.TABLE.CELL.PERSONNEL_ROLE') },
    { id: 'personnelBranch', disablePadding: false, label: t('PERSONNEL.TABLE.CELL.PERSONNEL_BRANCH') },
    { id: 'status', disablePadding: false, label: t('USER.TABLE.CELL.STATUS') },
    { id: 'updatedBy', disablePadding: false, label: t('USER.TABLE.CELL.UPDATED_BY') },
    { id: 'action', disablePadding: false, label: 'Action' }
  ]

  const renderData = (objData: any, no: number, valueExportData: boolean) => {
    const num = valueExportData === true ? no + 1 : page * pageLimit - pageLimit + no + 1
    const { userId, profile, firstname, lastname, phone, roles, position: personalPosition, updatedBy, updatedAt, status: personalStatus, isAdmin } = objData
    const statusBtnActive = personalStatus === 'ACTIVE' ? true : permissions.isUpdate.disabled
    const statusBtnInActive = personalStatus === 'INACTIVE' ? true : permissions.isUpdate.disabled
    const renderProfile = (personalProfile: any, personalFirstName: string, isAdminStatus: boolean) => {
      let path: any = ''
      if (personalProfile?.fileName) {
        const { fileName, filePath } = personalProfile
        path = `${fileUrl}/${filePath || ''}/${fileName || ''}`
      }
      return (
        <div className="d-flex align-items-center">
          {
            !valueExportData &&
            <Avatar className="mx-0 mr-2" src={path} sx={{ backgroundColor: colors.white }}>
              <img src={imgs.defaultAvatar} className="w-100 h-100" alt="" />
            </Avatar>
          }
          <span>{personalFirstName} {lastname} {isAdminStatus && <sup className='text-nowrap'>({t('USER.TABLE.ADMIN')})</sup> || <></>}</span>
        </div>
      )
    }
    const objRenderData = {
      key: userId,
      id: userId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: num },
        { option: 'COMPONENT', align: 'left', component: renderProfile(profile, firstname, isAdmin === '1' ? true : false), width: 'auto' },
        { option: 'TEXT', align: 'left', label: phone || '-' },
        { option: 'TEXT', align: 'left', label: personalPosition?.positionName },
        { option: 'TEXT', align: 'left', label: roles?.roleName },
        { option: 'TEXT', align: 'left', label: roles?.branchName },
        { option: 'STATUS', align: 'center', label: personalStatus },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy || '-', updatedAt: updatedAt } },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ]
    }
    return (
      <>
        {valueExportData === true && <TableRowExport {...objRenderData} /> ||
          <TableRowCommon
            {...objRenderData}
            onactive={() => onActive(Number(objRenderData.id), `${firstname} ${lastname}`, 'ACTIVE')}
            oninactive={() => onActive(Number(objRenderData.id), `${firstname} ${lastname}`, 'INACTIVE')}
            onedit={() => onEdit(objRenderData.obj)}
            ondelete={() => onDelete(objRenderData.obj)}
          />
        }
      </>
    )
  }

  const filter = [
    { label: `${t('INPUT.SEARCH')}: ${search || '-'}` },
    { label: `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}` },
    { label: `${t('PERSONNEL.TABLE.CELL.PERSONNEL_POSITION')}: ${position === 'ALL' ? t(`STATUS.ALL`) : positions.find((p) => p.positionId === position)?.positionName}` },
  ]

  return (
    <div className={classes.root}>
      <Card className="border-0 h-100 overflow-auto">
        <HeaderCard text={t('PERSONNEL.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="align-items-center">
              <Col lg={8}>
                <Row className="flax-row">
                  <Col sm={12} md={5} xl={4} className="pt-2 pr-3">
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('INPUT.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Col>
                  <Col sm={12} md={7} xl={8} className="pl-md-0 pt-2">
                    <div className="d-flex flex-column flex-sm-row">
                      <FilterSelect
                        onchange={(event) => {
                          const value = event.target.value
                          if (value) {
                            handleChangeStatus(value)
                            setPage(1)
                          }
                        }}
                        renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                        label={t('STATUS.LABEL')}
                        selectId="select-status"
                        value={status}
                        labelId="label-status"
                        options={[
                          <MenuItem key="ALL" value="ALL">
                            {t('STATUS.ALL')}
                          </MenuItem>,
                          <MenuItem key="2" value="ACTIVE">
                            {t('STATUS.ACTIVE')}
                          </MenuItem>,
                          <MenuItem key="3" value="INACTIVE">
                            {t('STATUS.INACTIVE')}
                          </MenuItem>
                        ]}
                      />
                      <div className="pt-2 pt-sm-0 pl-sm-3 w-100">
                        <FilterSelect
                          label={t('STATUS.LABEL')}
                          labelId="label-status"
                          selectId="select-status"
                          value={position}
                          onchange={(event) => {
                            setPosition(event.target.value)
                            setPage(1)
                          }}
                          renderValue={() => `${t('PERSONNEL.TABLE.CELL.PERSONNEL_POSITION')}: ${position === 'ALL' ? t(`STATUS.ALL`) : positions.find((p) => p.positionId === position)?.positionName}`}
                          options={[
                            <MenuItem key="ALL" value="ALL">
                              {t('STATUS.ALL')}
                            </MenuItem>,
                            _.map(positions, (positionItem: any, i: number) => (
                              <MenuItem key={i} value={positionItem.positionId}>
                                {positionItem.positionName}
                              </MenuItem>
                            ))
                          ]}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={4} className="mt-2 mt-lg-auto">
                <div className="pr-xl-2 d-flex">
                  <div className='ml-auto'>
                    <ButtonExport headCells={headCells} portrait={false} filter={filter} fileName={t('PERSONNEL.BUTTON.PRINT_DOCUMENT')}
                      rowsData={exportData.map((val, i) => {
                        return renderData(val, i, true)
                      })} />
                  </div>
                  <div className='pl-3'>
                    <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => onCreate()} textButton={t('PERSONNEL.BUTTON.ADD')} className="w-auto ml-auto d-flex mt-auto" btnStyle={{ height: '44px' }} />
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Title>
          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={personnels.map((val, i) => {
                return renderData(val, i, false)
              })}
              tableMinWidth={1440}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export const checkUserLimit = async (title: string, subtitle: string, handleIsOverCase: () => void, handleIsNotOverCase: () => void) => {
  const user = await getUserLimit()
  if ((Number(user) + 1) > userLimit(getPackageKey())) {
    warningCreateUser(title, subtitle, () => { handleIsOverCase() })
  } else handleIsNotOverCase()
}

export const checkIsUltraPackage = () => {
  return getPackageKey() === PACKAGE_TYPE.ULTRA ? true : false
}

export const getUserLimit = async () => {
  let user = 0
  await PersonnelApi.findUserActive().then((res) => user = res.data)
  return user
}

export const warningCreateUser = (title: string, subtitle: string, callback?: any) => {
  return swalWarning(title, subtitle, callback, { allowOutsideClick: false })
}
