import { useCallback, useState, useEffect } from 'react'
import { Typography, Avatar, Chip, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import { Dropdown, Modal } from 'react-bootstrap'
import 'react-multi-carousel/lib/styles.css'
import _ from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

/** SLICK */
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import { routeName } from 'routes/routes-name'
import { fileUrl } from 'api/api'
import SendExaminationApi, { DetailAppointment, SendQueue, StatusAppointment } from 'api/master/sendExamination.api'
import ClinicApi from 'api/master/clinic.api'
import ComponentApi from 'api/components-api'

/** UTILS || SLICE */
import { resetModal } from 'app/slice/modal.slice'
import { toBuddhistYear, memberTypeOutline, getBranch } from 'utils/app.utils'
import { selectMe } from 'app/slice/user.slice'

/** CONSTANTS */
import { colors, ThemeIcon } from 'constants/theme'
import { icons, imgs } from 'constants/images'

/** COMPONENT */
import ButtonCustom from 'component/Button/ButtonCustom'
import SpecialInfo from 'component/Register/SpecialInfo'
import InputTextarea from 'component/Input/InputTextarea'
import LabelRanking from 'component/LabelRanking'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { swalCustom } from 'component/Alert/Swal'
import { notiError, notiSuccess } from 'component/notifications/notifications'


/** STYLE */
import * as UseStyled from 'component/Register/style/styleSendExamination'
import TreatmentQueueAPI, { ListQueue } from 'api/dentists/treatmentQueue.api'

type ModalCustomProps = {
  isShow: boolean | false
  setIsShow: (show: boolean) => void
  patient: any
  type: 'home' | 'register' | 'treatment' | 'payment'
  service?: any
  isHidePhoneNumber?: boolean
}

type CardProfileType = {
  firstName: string
  lastName: string
  rank: string
  phone: string
  profile: any
  doctor: string
  isHidePhoneNumber?: boolean
}

export default function SendExamination(props: ModalCustomProps) {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(selectMe)
  const [tab, setTab] = useState(0)

  const [disabled, setDisabled] = useState(false);
  const [labelStatus, setLabelStatus] = useState<any>('')
  const [remarkCancel, setRemarkCancel] = useState('')
  const [remarkAppointment, setRemarkAppointment] = useState('')
  const [newMessage, setNewMessage] = useState('')
  const [serviceRoomId, setServiceRoomId] = useState(0)
  const [serviceRooms, setServiceRooms] = useState([])
  const [treatments, setTreatments]: any = useState([])
  const [treatmentSelected, setTreatmentSelected]: any = useState([])
  const [patientTreatments, setPatientTreatments] = useState([])
  const [historyQueue, setHistoryQueue] = useState([])
  const [patient, setPatient] = useState<any>([])
  const [userId, setUserId] = useState(0)
  const [error, setError] = useState({ remarkCancel: '' })

  const branchId = Number(getBranch())

  const settingsSlick = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          speed: 500,
          rows: 2,
          slidesPerRow: 2,
          slidesToScroll: 1
        }
      }
    ]
  }

  const [loadingServicePoint, setLoadingServicePoint] = useState(true)

  /** VARIABLE */
  const statusIcon: any = {
    CANCEL: { src: icons.appointment.iconCancel, color: colors.appointmentStatus.statusCancel, label: t('APPOINTMENT.STATUS.CANCEL'), disabled: false },
    CAN_NOT_CONTACT: { src: icons.appointment.iconNotConnect, color: colors.appointmentStatus.statusUnConnect, label: t('APPOINTMENT.STATUS.CAN_NOT_CONTACT'), disabled: false },
    PUT_OFF: { src: icons.appointment.iconChangeSchedule, color: colors.appointmentStatus.statusChange, label: t('APPOINTMENT.STATUS.PUT_OFF'), disabled: false },
    CONFIRM: { src: icons.appointment.iconEnter, color: colors.appointmentStatus.statusEnter, label: t('APPOINTMENT.STATUS.CONFIRM'), disabled: false },
    ARRIVED: { src: icons.appointment.iconUserCheckTrue, color: colors.appointmentStatus.statusArrived, label: t('APPOINTMENT.STATUS.ARRIVED'), disabled: false },
    NOT_YET: { src: icons.appointment.iconUserCheckFalse, color: colors.appointmentStatus.statusUnArrived, label: t('APPOINTMENT.STATUS.NOT_YET'), disabled: false },
    PENDING_PAYMENT: { src: icons.appointment.iconWaitPayment, color: colors.appointmentStatus.statusPayment, label: t('APPOINTMENT.STATUS.PENDING_PAYMENT'), disabled: true },
    PAYMENT: { src: icons.appointment.iconPendingPayment, color: colors.appointmentStatus.statusWaitPayment, label: t('APPOINTMENT.STATUS.PAYMENT'), disabled: true },
    PENDING_SERVICE: { src: icons.appointment.iconCounterCheckIn, color: colors.appointmentStatus.statusCounterCheckIn, label: t('APPOINTMENT.STATUS.PENDING_SERVICE'), disabled: true },
    SERVICE: { src: icons.appointment.iconService, color: colors.appointmentStatus.statusService, label: t('APPOINTMENT.STATUS.SERVICE'), disabled: true },
    DONE: { src: ThemeIcon.DoneTreatment, color: colors.appointmentStatus.statusDone, label: t('APPOINTMENT.STATUS.DONE'), disabled: true },
  }

  const loadProfile = useCallback(() => {
    if (user.userId) setUserId(user.userId)
  }, [])

  const getServiceRoom = async () => {
    const rooms = await ClinicApi.serviceRoom()
    if (rooms.status === 200 && !_.isEmpty(rooms.data)) setServiceRooms(rooms.data)
    else setServiceRooms([])
  }

  const getHistoryQueue = async (patientId: number) => {
    if (props.type === 'home' || props.type === 'treatment' || props.type === 'payment') {
      const respHistory = await SendExaminationApi.homeHistoryQueue(patientId)
      handleHistoryQueue(respHistory)
    } else if (props.type === 'register') {
      const respHistoryRegister = await SendExaminationApi.registerHistoryQueue(patientId)
      handleHistoryQueue(respHistoryRegister)
    }
  }

  const handleHistoryQueue = (resp: any) => {
    if (resp.status === 200 && !_.isEmpty(resp.data)) {
      const htr: any = _.chain(resp.data)
        .map((i: any) => {
          i.dateFormat = toBuddhistYear(moment(i.createdAt), 'dd D MMM YY')
          return i
        })
        .groupBy('dateFormat')
        .map((value: any, key: string) => {
          const newValue = value?.filter((item: any, index: number) => {
            if (index === 0 ||
              index === 1 && (value[index - 1]?.servicePointIdTo !== item.servicePointIdTo && value[index + 1]?.servicePointIdTo !== item.servicePointIdTo) ||
              index !== 1 && value[index + 1]?.servicePointIdTo !== item.servicePointIdTo) {
              return item
            }
          })
          return ({ key, data: newValue })
        })
        .value()
      setHistoryQueue(htr)
    } else setHistoryQueue([])
  }

  const loadDataTreatmentItems = useCallback((search = '') => {
    setTreatments([])
    ComponentApi.findAllTreatmentItems({ search: search }).then(({ data }) => data && setTreatments([...data]))
  }, [])

  const handleStatus = (appointmentStatus: any) => {
    if (appointmentStatus === 'CANCEL') setLabelStatus('CANCEL')
    else if (appointmentStatus === 'ARRIVED') setLabelStatus('ARRIVED')
    else if (appointmentStatus === 'CAN_NOT_CONTACT') setLabelStatus('CAN_NOT_CONTACT')
    else if (appointmentStatus === 'PUT_OFF') setLabelStatus('PUT_OFF')
    else if (appointmentStatus === 'CONFIRM') setLabelStatus('CONFIRM')
    else if (appointmentStatus === 'NOT_YET') setLabelStatus('NOT_YET')
    else if (appointmentStatus === 'PENDING_PAYMENT') setLabelStatus('PENDING_PAYMENT')
    else if (appointmentStatus === 'PAYMENT') setLabelStatus('PAYMENT')
    else if (appointmentStatus === 'PENDING_SERVICE') setLabelStatus('PENDING_SERVICE')
    else if (appointmentStatus === 'SERVICE') setLabelStatus('SERVICE')
    else if (appointmentStatus === 'DONE') setLabelStatus('DONE')
  }

  const handleLoadListQueue = async () => {
    if (branchId && serviceRoomId) {
      const params: ListQueue = {
        branchId: branchId,
        servicePointId: serviceRoomId
      }

      const queue = await TreatmentQueueAPI.listQueue(params)
      if (queue.status === 200 && !_.isEmpty(queue.data)) return queue.data
      else return []
    }
  }

  const handleSortingToEnd = (service: any) => {
    const newSort = service?.cases.sort((a: any, b: any) => {
      if (a.sorting === null) {
        return 1;
      }
      if (b.sorting === null) {
        return -1;
      }
      return b.sorting - a.sorting;
    });
    const newData = newSort[0]?.sorting;
    return Number(newData) + 1 || 0
  }

  const handleSorting = async () => {
    const newService = await handleLoadListQueue()
    if (newService?.cases?.length !== 0) {
      if (serviceRoomId === patient.servicePointId) {

        const newData = await newService?.cases.filter((item: any) => {
          return item.patientCaseId === patient.patientCaseId
        })
        return props.type === 'home' ? newData[0]?.sorting || handleSortingToEnd(newService) : handleSortingToEnd(newService)
      } else {
        return handleSortingToEnd(newService)
      }
    } else {
      return 0
    }
  }

  const handleSendStatus = () => {
    if (labelStatus === patient?.appointment?.statusAppointment || props?.type !== 'home') {
      return "PENDING_SERVICE"
    } else {
      return labelStatus === "" ? "PENDING_SERVICE" : labelStatus
    }
  }

  const onSubmit = () => {
    if (labelStatus === 'CANCEL' && !remarkCancel) {
      return setError({ remarkCancel: t('REGISTER.MESSAGE.UPLOAD_TREATMENT.ERROR_SERVICE_ROOM') })
    }

    setDisabled(true)

    const templateSendQueue: SendQueue = {
      servicePointId: serviceRoomId,
      importantSymptoms: patient.importantSymptoms,
      specialInfo: patient.specialInfo,
      appointmentId: patient.appointment?.appointmentId || 0,
      message: newMessage,
      statusAppointment: handleSendStatus(),
      remark: remarkAppointment || '',
      remarkCancel: labelStatus === 'CANCEL' ? remarkCancel : '',
      treatments: patientTreatments,
    }

    if (!templateSendQueue.servicePointId || (templateSendQueue.appointmentId && !templateSendQueue.statusAppointment)) {
      return swalCustom(
        templateSendQueue.servicePointId || templateSendQueue.statusAppointment ? t('VALIDATION.ALERT.CONTINUE') : t('VALIDATION.ALERT.CANNOT_SAVE'),
        `<p class="text-left m-0" style="max-width: 424px">${t('VALIDATION.FIELD_NOT_COMPLETE')}</p>
      ${templateSendQueue.appointmentId ?
          `<div class="d-flex align-items-center pl-3 pt-2">
          <img src=${templateSendQueue.statusAppointment ? icons.checkCircle : icons.incorrectCircle} class="pr-2" />
          ${t('REGISTER.SEND_EXAMINATION.ALERT.APPOINTMENT_STATUS')}
          </div>`
          : ''
        }
    <div class="d-flex align-items-center pl-3 pt-2"><img src=${templateSendQueue.servicePointId ? icons.checkCircle : icons.incorrectCircle} class="pr-2" />${t('REGISTER.SEND_EXAMINATION.ALERT.SERVICE_ROOM')}</div>
          ${templateSendQueue.appointmentId && (templateSendQueue.servicePointId || templateSendQueue.statusAppointment) ? `<div class="pt-2 text-left" style="max-width: 300px">${t('REGISTER.SEND_EXAMINATION.ALERT.REMARK')}</div>` : ''}
    `,
        (res: any) => {
          if (res) {
            handleSubmit(templateSendQueue)
          } else {
            setDisabled(false)
          }
        },
        templateSendQueue.servicePointId || templateSendQueue.statusAppointment ? 'question' : 'warning',
        {
          confirmButton: {
            showConfirmButton: templateSendQueue.servicePointId || templateSendQueue.statusAppointment ? true : false,
            confirmButtonText: t('BUTTON.CONTINUE')
          },
          cancelButton: {
            cancelButtonText: templateSendQueue.servicePointId || templateSendQueue.statusAppointment ? t('BUTTON.CANCEL') : t('BUTTON.CLOSE')
          }
        }
      )
    } else handleSubmit(templateSendQueue)
  }

  const handleSubmit = async (templateSendQueue: SendQueue) => {
    if (templateSendQueue.servicePointId) {
      if (props.type === 'register') {
        const data = templateSendQueue.appointmentId ? { ...templateSendQueue, statusAppointment: 'ARRIVED', patientCaseId: patient.patientCaseId } : { ...templateSendQueue, patientCaseId: patient.patientCaseId }
        const sendQueueRegister = await SendExaminationApi.registerSendQueue(data)
        if (sendQueueRegister.status === 201) {
          dispatch(resetModal())
          props.setIsShow(false)
          setDisabled(false)
          notiSuccess(t('REGISTER.SEND_EXAM_SUCCESS'), '', null, null, 'register-page')
        }
      } else if (props.type === 'home') {
        const newData = templateSendQueue
        const data = { ...newData, sorting: await handleSorting() }
        SendExaminationApi.homeSendQueue(patient.patientCaseId, data).then((sendQueueHome) => {
          if (sendQueueHome.status === 201) {
            dispatch(resetModal())
            props.setIsShow(false)
            setDisabled(false)
            notiSuccess(t('REGISTER.SEND_EXAM_SUCCESS'), '', null, null, '')
          }
        }).catch((e) => {
          console.log(e);
          const message = e?.response?.data?.message
          if (message === 'VISIT_IS_DONE')
            notiError(t('REGISTER.VISIT_IS_DONE'))
          else notiError(t('REGISTER.SEND_EXAMINATION.ALERT.ERROR_CANCEL'))

        })

      } else {
        const newData = templateSendQueue
        const data = { ...newData, sorting: await handleSorting() }
        const sendQueue = await SendExaminationApi.homeSendQueue(patient.patientCaseId, data)
        if (sendQueue.status === 201) {
          if (templateSendQueue.servicePointId === patient.servicePointId) {
            dispatch(resetModal())
            props.setIsShow(false)
            setDisabled(false)
            notiSuccess(t('REGISTER.SEND_EXAM_SUCCESS'), '', null, null, 'register-page')
          } else {
            dispatch(resetModal())
            props.setIsShow(false)
            setDisabled(false)
            if (props.type === 'payment') history.push(routeName.payment)
            else history.push(routeName.treatmentsQueue)
          }
        }
      }
    } else {
      const data: StatusAppointment = {
        statusAppointment: templateSendQueue.statusAppointment || 'NORMAL',
        remarkCancel: templateSendQueue.remarkCancel,
        remark: templateSendQueue.remark
      }

      await SendExaminationApi.updateStatus(patient.appointment?.appointmentId, data)
        .then(() => {
          // dispatch(resetModal())
          // props.setIsShow(false)
        }).catch((e) => { return })

      const newData: DetailAppointment = {
        importantSymptoms: patient.importantSymptoms,
        specialInfo: patient.specialInfo,
        treatments: patientTreatments,
      }

      await SendExaminationApi.updateDetail(patient.appointment?.appointmentId, newData)
        .then(() => {
          dispatch(resetModal())
          props.setIsShow(false)
          notiSuccess(t('REGISTER.SEND_EXAM_SUCCESS'), '', null, null, '')
        }).catch((e) => { return })
    }
  }

  const renderImage = (img: any) => {
    if (!_.isEmpty(img) && !_.isEmpty(img.filePath) && !_.isEmpty(img.fileName)) return `${fileUrl}/${img.filePath}/${img.fileName}`
    else if (!_.isEmpty(img)) return `${fileUrl}/${img}`
    else return imgs.defaultAvatar
  }

  const handlePatientTreatments = (data: any) => {
    const newTreatments: any = []
    _.map(data, (val: any, index: number) => {
      newTreatments.push({ operativeTypeId: val.operativeTypeId, operativeId: val.operativeId })
    })
    setPatientTreatments(newTreatments)
  }

  const getColorOfService = (obj: any) => {
    let color = ''
    if (!_.isEmpty(obj)) {
      color = obj.pointOfService === 'COUNTER' ? colors.servicePoint.label.counter : colors.servicePoint.label.roomServices
    } else {
      color = colors.servicePoint.label.payment
    }
    return color
  }

  const handleTreatmentSelected = (data: any) => {
    const newData = treatmentSelected?.filter((item: any) => {
      return item.operativeId === data.operativeId
    })

    if (newData?.length === 0) {
      return true
    } else {
      return false
    }
  }

  const handlePatient = useCallback(async () => {
    setPatient(props.patient)
    setServiceRoomId(props.patient.servicePointId)
    getServiceRoom()
    getHistoryQueue(props.patient.patientId)

    const ptTreatments = (!_.isEmpty(props.patient.treatments) ? props.patient.treatments : !_.isEmpty(props.patient?.appointment?.appointmentOperatives) ? props.patient.appointment.appointmentOperatives : [])
    for (let index = 0; index < ptTreatments.length; index++) {
      const apOperativeType = ptTreatments[index];
      await ComponentApi.findAllTreatmentItems({ search: apOperativeType.operativeName || apOperativeType.operativeTypeName }).then(({ data }) => {
        let objOp: any = {}
        if (data && data[0])
          objOp = {
            color: data[0].color,
            operativeId: apOperativeType.operativeId,
            operativeName: apOperativeType.operativeName,
            operativeNameEn: apOperativeType.operativeNameEn,
            operativeTypeId: apOperativeType.operativeTypeId,
            operativeTypeName: apOperativeType.operativeTypeName,
            operativeTypeNameEn: apOperativeType.operativeTypeNameEn,
          }

        if (handleTreatmentSelected(objOp)) {
          setTreatmentSelected((vel: any) => [...vel, objOp])
        }

      })
    }
    loadDataTreatmentItems()

    handlePatientTreatments(ptTreatments)
    if (props.patient.appointment?.statusAppointment) handleStatus(props.patient.appointment.statusAppointment)
    if (props.patient.appointment?.remark) setRemarkAppointment(props.patient.appointment?.remark)
    if (props.patient.appointment?.statusAppointment === 'CANCEL' && props.patient.appointment?.remarkCancel) setRemarkCancel(props.patient.appointment?.remarkCancel)
  }, [props.patient])

  useEffect(() => {
    handlePatient()
  }, [props.patient])

  useEffect(() => {
    if (!_.isEmpty(serviceRooms)) setLoadingServicePoint(false)
  }, [serviceRooms])

  useEffect(() => {
    loadProfile()
  }, [user])

  const valRemarkAppointment = (val: string) => {
    if (val.charAt(0) === ',' || val.charAt(0) === ' ') {
      val = val.substring(1);
    }
    return val
  }

  return (
    <UseStyled.ModalStyle returnFocusAfterClose={false} size={'xl'} show={props.isShow} centered>
      <UseStyled.Header>
        <ThemeIcon.SendExam color={colors.white} />
        <Typography>{t('REGISTER.SEND_EXAMINATION.TITLE')}</Typography>
      </UseStyled.Header>
      <Modal.Body className={'py-3 py-md-4 px-0 px-xl-2 overflow-hidden'}>
        <UseStyled.Fragment className={'h-100'}>
          <section className={'d-block d-xl-none px-3 px-md-4'}>
            <CardProfile
              firstName={patient.firstname}
              lastName={patient.lastname}
              rank={patient.rank}
              phone={patient.phone || '-'}
              doctor={patient.appointment?.doctorFullname || '-'}
              profile={renderImage(patient.profile || patient.avatar || patient.patientProfile)}
              isHidePhoneNumber={props.isHidePhoneNumber}
            />
          </section>

          <UseStyled.FragmentTab className={'d-block d-xl-none mb-2 mb-md-3 mx-3 mx-md-4'}>
            <button className={`${tab === 0 ? 'active' : ''}`} onClick={() => setTab(0)}>
              {t('REGISTER.SEND_EXAMINATION.PATIENT_INFO')}
            </button>
            <button className={`${tab === 1 ? 'active' : ''}`} onClick={() => setTab(1)}>
              {t('REGISTER.SEND_FOR_EXAMINATION')}
            </button>
          </UseStyled.FragmentTab>

          <UseStyled.FragmentLeft className={`${tab === 0 ? 'd-flex' : 'd-none'} d-xl-block custom-scroll flex-column h-100`}>
            <section className={'d-none d-xl-block'}>
              <CardProfile
                firstName={patient.firstname}
                lastName={patient.lastname}
                rank={patient.rank}
                phone={patient.phone || '-'}
                doctor={patient.appointment?.doctorFullname || '-'}
                profile={renderImage(patient.profile || patient.avatar || patient.patientProfile)}
                isHidePhoneNumber={props.isHidePhoneNumber}
              />
            </section>
            <SpecialInfo
              key={'specialInfo'}
              name="specialInfo"
              onChange={(e: any) => {
                setPatient({ ...patient, ...{ specialInfo: e.target.value } })
              }}
              placeholder={t('REGISTER.TITLE.FIELD_SPECIAL_INFO')}
              value={patient.specialInfo || ''}
            />
            <div className={'mt-3'}>
              <UseStyled.CustomAutocompleteSelect>
                <AutocompleteSelect
                  multiple
                  labelId="lb-operative-id"
                  inputLabel={t('REGISTER.SEND_EXAMINATION.TREATMENT_LIST')}
                  noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                  options={treatments}
                  getOptionLabel={(option: any) => (option.operativeId ? option.operativeName : option.operativeTypeName)}
                  renderOption={(props, option: any) => (
                    <Box
                      key={`${option.operativeId ? `operativeId-${option.operativeId}` : `operativeTypeId-${option.operativeTypeId}`}`}
                      component="li"
                      style={{
                        pointerEvents: _.some(treatmentSelected, (i: any, index: number) => i.operativeTypeId === option.operativeTypeId && i.operativeId === option.operativeId) ? 'none' : '',
                        backgroundColor: _.some(treatmentSelected, (i: any, index: number) => i.operativeTypeId === option.operativeTypeId && i.operativeId === option.operativeId) ? colors.themeSecondColor06 : colors.white,
                        color: _.some(treatmentSelected, (i: any, index: number) => i.operativeTypeId === option.operativeTypeId && i.operativeId === option.operativeId) ? colors.themeSecondColor : null
                      }}
                      {...props}
                    >
                      <span
                        style={{
                          paddingLeft: option.operativeId ? '1rem' : ''
                        }}
                      >
                        {option.operativeId ? option.operativeName : option.operativeTypeName}
                      </span>
                    </Box>
                  )}
                  renderTags={(tagValue, getTagProps) =>
                    _.map(tagValue, (option: any, index: number) => (
                      <Tooltip title={option.operativeId ? _.get(_.find(treatmentSelected, { operativeId: option.operativeId }), 'operativeName') : _.get(_.find(treatmentSelected, { operativeTypeId: option.operativeTypeId }), 'operativeTypeName')} placement="left" key={`index-${index}`}>
                        <UseStyled.ChipTreatment
                          size="small"
                          background={option.operativeId ? _.get(_.find(treatmentSelected, { operativeId: option.operativeId }), 'color') : _.get(_.find(treatmentSelected, { operativeTypeId: option.operativeTypeId }), 'color')}
                          label={option.operativeId ? _.get(_.find(treatmentSelected, { operativeId: option.operativeId }), 'operativeName') : _.get(_.find(treatmentSelected, { operativeTypeId: option.operativeTypeId }), 'operativeTypeName')}
                          {...getTagProps({ index })}
                        />
                      </Tooltip>
                    ))
                  }
                  onchange={(e, value: any, reason: string) => {
                    setTreatmentSelected(value)
                    const newVal: any = []
                    _.map(value, (val: any, index: number) => {
                      newVal.push({ operativeTypeId: val.operativeTypeId, operativeId: val.operativeId })
                    })
                    setPatientTreatments(newVal)
                  }}
                  onInputChange={(event: any, newValue: any) => loadDataTreatmentItems(newValue)}
                  value={treatmentSelected ? treatmentSelected : []}
                  key={'treatment'}
                  height={43}
                  limitTags={2}
                />
              </UseStyled.CustomAutocompleteSelect>
            </div>
            <div className={'mt-3'}>
              <InputTextarea
                name="important-symptoms"
                key={'important-symptoms'}
                onchange={(e: any) => {
                  setPatient({ ...patient, ...{ importantSymptoms: e.target.value } })
                }}
                value={patient.importantSymptoms || ''}
                label={t('REGISTER.FORM.IMPORTANT_SYMPTOMS')}
                height={94}
              />
            </div>
            <UseStyled.AppointmentDescription className={`${_.isEmpty(patient.appointment) ? 'd-none' : 'd-block'}`}>
              <UseStyled.StyledTypography>{t('REGISTER.SEND_EXAMINATION.APPOINTMENT_DESCRIPTION')}</UseStyled.StyledTypography>
              <UseStyled.DropdownButtonStatus className={'mt-3'}>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className={`d-flex align-items-center ${!labelStatus ? 'no-status' : ''} ${serviceRoomId ? 'pe-none-' : ''}`}
                  style={{
                    backgroundColor: statusIcon[labelStatus]?.color || colors.lightGray
                  }}>
                  {labelStatus && <img src={statusIcon[`${labelStatus}`]?.src} className=" mr-2" style={{ width: 20 }} alt={labelStatus} />}
                  <Box className='text-ellipsis' title={statusIcon[`${labelStatus}`]?.label || t('APPOINTMENT.FORM.SELECT_STATUS')}>
                    {statusIcon[`${labelStatus}`]?.label || t('APPOINTMENT.FORM.SELECT_STATUS')}
                  </Box>

                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {_.map(statusIcon, (status: any, keyName: string) => {
                    return (
                      <Dropdown.Item key={keyName} onClick={() => handleStatus(keyName)}>
                        {status.label}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
                {labelStatus === 'CANCEL' && (
                  <div className={'mt-3'}>
                    <InputTextarea
                      name="remark-status"
                      key={'remark-status'}
                      onchange={(e: any) => {
                        setRemarkCancel(e.target.value)
                        setError({ remarkCancel: '' })
                      }}
                      value={remarkCancel}
                      label={t('REGISTER.SEND_EXAMINATION.REMARK_STATUS')}
                      height={94}
                      helperText={error.remarkCancel}
                    />
                  </div>
                )}
                <div className={'mt-3'}>
                  <InputTextarea
                    name="remark-appointment"
                    key={'remark-appointment'}
                    onchange={(e: any) => {
                      setRemarkAppointment(e.target.value)
                    }}
                    value={valRemarkAppointment(remarkAppointment || '')}
                    label={t('REGISTER.SEND_EXAMINATION.REMARK_APPOINTMENT')}
                    height={94}
                  />
                </div>
              </UseStyled.DropdownButtonStatus>
            </UseStyled.AppointmentDescription>
          </UseStyled.FragmentLeft>

          <UseStyled.FragmentRight className={`${tab === 1 ? 'd-flex' : 'd-none'} d-xl-flex flex-column custom-scroll h-100`}>
            <div className={'d-flex align-items-center pt-1'}>
              <div className={'d-flex align-items-center'}>
                <UseStyled.StyledTypography>{t('REGISTER.SEND_EXAMINATION.SELECT_ROOM')}</UseStyled.StyledTypography>
              </div>

              <UseStyled.StyledTypography className={'ml-auto'}>
                {t('REGISTER.SEND_EXAMINATION.QUEUE')}: {patient.queueNumber || '-'}
              </UseStyled.StyledTypography>
            </div>
            <div className={'mt-3'}>
              {!loadingServicePoint && (
                <UseStyled.Carousel>
                  <Slider {...settingsSlick} className="slick-default">
                    {_.map(serviceRooms, (room: any, index: number) => {
                      return (
                        <UseStyled.Room key={index}>
                          <div className={'position-relative room-wrap'}>
                            <Tooltip title={room[`servicePointName${i18n.language !== "th" ? "En" : ""}`]} arrow placement="right">
                              <div
                                onClick={() => {
                                  setServiceRoomId(room.servicePointId)
                                }}
                                className={`room-item position-absolute flex-md-column d-flex align-items-center justify-content-center top-0 left-0 w-100 h-100 ${serviceRoomId === room.servicePointId ? 'active' : ''}`}>
                                {room.pointOfService === 'COUNTER' ? (
                                  <ThemeIcon.Counter color={serviceRoomId === room.servicePointId ? colors.themeSecondColor : colors.disabledGray} />
                                ) : room.pointOfService === 'PAYMENT' ? (
                                  <ThemeIcon.Payment color={serviceRoomId === room.servicePointId ? colors.themeSecondColor : colors.disabledGray} />
                                ) : (
                                  <ThemeIcon.SendExam color={serviceRoomId === room.servicePointId ? colors.themeSecondColor : colors.disabledGray} />
                                )}
                                <span className={'pl-1 pl-md-0 pt-md-2 text-ellipsis text-center'} style={{ width: '80%' }}>
                                  {room[`servicePointName${i18n.language !== "th" ? "En" : ""}`]}
                                </span>
                              </div>
                            </Tooltip>
                          </div>
                        </UseStyled.Room>
                      )
                    })}
                  </Slider>
                </UseStyled.Carousel>
              )}
            </div>
            <div className={'mt-3 mt-md-4 h-100 overflow-hidden chatRoom-wrap'}>
              <UseStyled.ChatRoom className="h-100 custom-scroll">
                {_.map(historyQueue, (htr: any, index: number) => {
                  return (
                    <div>
                      <UseStyled.ChatDate label={htr.key} className={'mb-3'}></UseStyled.ChatDate>
                      {_.map(htr.data, (data: any, indexData: number) => {
                        return (
                          <UseStyled.ChatMessage className={`d-flex mb-3 ${data.userId === userId ? 'justify-content-start flex-row-reverse' : ''}`}>
                            <Avatar alt="profile" src={renderImage(data.doctorProfile)} sx={{ backgroundColor: colors.white }}>
                              <img src={imgs.defaultAvatar} alt="defaultAvatar" className="w-100 h-100" />
                            </Avatar>
                            <div className={`chat-detail ${data.userId === userId ? 'mr-2 mr-md-3' : 'ml-2 ml-md-3'}`}>
                              <div className={`d-flex align-items-center ${data.userId === userId ? 'justify-content-end' : ''}`}>
                                <Typography className="title mr-2">{t('REGISTER.SEND')}</Typography> <Chip label={data[`servicePointName${i18n.language !== "th" ? "En" : ""}To`]} sx={{ background: getColorOfService(_.find(serviceRooms, { servicePointId: data.servicePointIdTo })) }} />
                              </div>
                              {data.message && (
                                <Typography className={'text-break'} sx={{ textAlign: data.userId === userId ? 'right' : 'left', color: colors.textPrimary }}>
                                  {data.message}
                                </Typography>
                              )}
                              <Typography className="subtitle" sx={{ textAlign: data.userId === userId ? 'right' : 'left', lineHeight: 1 }}>
                                {t('REGISTER.BY')} {data.createdBy} {t('TIME')} {moment(data.createdAt).format('HH.mm')} {t('TIME_UNIT')}
                              </Typography>
                            </div>
                          </UseStyled.ChatMessage>
                        )
                      })}
                    </div>
                  )
                })}
              </UseStyled.ChatRoom>
            </div>
            <UseStyled.ChatNewMessage className={'mt-2 mt-md-3 position-relative'}>
              {!newMessage && <img src={icons.chatBubble} alt="chatBubble" className={'icon-chatBubble'} />}
              <textarea placeholder={t('REGISTER.SEND_EXAMINATION.MORE_MESSAGE')} onChange={(e: any) => setNewMessage(e.target.value)}></textarea>
            </UseStyled.ChatNewMessage>
          </UseStyled.FragmentRight>
        </UseStyled.Fragment>
      </Modal.Body>
      <UseStyled.ModalFooter className={'border-0 px-3 px-md-4 pb-3 pb-md-4 pt-0 p-xl-0 d-flex'}>
        <ButtonCustom
          variant="outlined"
          onClick={() => {
            dispatch(resetModal())
            props.setIsShow(false)
          }}
          textButton={t('REGISTER.MODAL.TEXT_CANCEL')}
          className={'btn-cancel mr-1 mr-md-2'}
          style={{ margin: 0 }}
        />
        <ButtonCustom onClick={onSubmit} disabled={disabled} textButton={t('REGISTER.BUTTON.SAVE')} className={'btn-submit ml-1 ml-md-2'} style={{ margin: 0 }} />
      </UseStyled.ModalFooter>
    </UseStyled.ModalStyle>
  )
}

function CardProfile(props: CardProfileType) {
  const { t } = useTranslation()
  return (
    <UseStyled.CardProfile className={'d-flex align-items-center mb-3 mb-md-4 mb-xl-3'}>
      <Avatar alt={'profile'} src={props.profile} sx={{ border: `5px solid ${memberTypeOutline(props.rank)}`, backgroundColor: colors.white }}>
        <img src={imgs.defaultAvatar} alt="defaultAvatar" className="w-100 h-100" />
      </Avatar>
      <div className={'pl-3 pl-md-4'}>
        <div className={'mb-1 mb-md-2'}>
          <UseStyled.StyledTypography className={'patient-name align-middle d-inline-block mr-1 mr-md-2'}>{props.firstName ? `${props.firstName} ${props.lastName}` : '-'}</UseStyled.StyledTypography>
          <LabelRanking rank={props.rank} />
        </div>
        {!props.isHidePhoneNumber && <UseStyled.StyledTypography className={'subTypo mt-1 mt-md-2'}>
          {t('REGISTER.SEND_EXAMINATION.TEL')}: {props.phone}
        </UseStyled.StyledTypography>}
        <UseStyled.StyledTypography className={'subTypo mt-1 mt-md-2'}>
          {t('REGISTER.SEND_EXAMINATION.DOCTOR')}: {props.doctor}
        </UseStyled.StyledTypography>
      </div>
    </UseStyled.CardProfile>
  )
}
