import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux'

import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import ExpenseTypesApi from 'api/setting/finances/expense-type.api'

/** STYLE */
import useStyles from 'features/setting/finances/expense-types/style'

/** UTILS || SLICE */
import { submitModal, unSubmitModal, showModal, resetModal } from 'app/slice/modal.slice'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextField from 'component/Input/InputTextField'
import InputTextSearch from 'component/Input/InputTextSearch'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import InputRadio from 'component/Input/InputRadio'

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

const initStateErrorMessage = {
  NOT_FOUND_EXPENSE_TYPE: ``,
  EXPENSE_TYPE_ALREADY_SYSTEM: ``,
  INVALID_CREATED: ``,
  INVALID_UPDATED: ``,
  IS_DUPLICATE_EXPENSE_TYPE_SHORTNAME: ``,
  IS_DUPLICATE_EXPENSE_TYPE_NAME: ``,

  EXPENSE_TYPE_NAME: ``,
  EXPENSE_TYPE_NAME_STRING_BASE: ``,
  EXPENSE_TYPE_NAME_STRING_EMPTY: ``,
  EXPENSE_TYPE_NAME_MAXIMUM_LENGTH: ``,
  EXPENSE_TYPE_NAME_ANY_REQUIRED: ``,
  EXPENSE_GROUP_STRING_VALID: ``,
  EXPENSE_GROUP_ANY_REQUIRED: ``
}
export default function ExpenseTypes() {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [expenseTypes, setExpenseTypes] = useState([])
  const [dataEdit, setDataEdit] = useState({})

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const status = 'ALL'

  const loadData = useCallback(async () => {

    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await ExpenseTypesApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setExpenseTypes(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])

  useEffect(() => {
    loadData()
  }, [loadData])

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('EXPENSE_TYPE.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          ExpenseTypesApi.updateStatus(id, valueStatus)
            .then((resp) => {
              notiSuccess(t('EXPENSE_TYPE.MESSAGE.SUCCESS.UPDATE_STATUS'))
              loadData()
            })
            .catch((e) => {
              notiError(t('EXPENSE_TYPE.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }
  const onDelete = (data: any) => {
    const { expenseTypeId: epsTypeId, expenseGroup: epsGroup } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('EXPENSE_TYPE.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('EXPENSE_TYPE.ALERT.CONFIRM_DELETE_', {
        expenseGroup: epsGroup
      })}</p>`,
      (res: any) => {
        if (res) {
          ExpenseTypesApi.remove(epsTypeId)
            .then((resp) => {
              notiSuccess(t('EXPENSE_TYPE.MESSAGE.SUCCESS.DELETE'))
              loadData()
            })
            .catch((e) => {
              notiError(t('EXPENSE_TYPE.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
    )
  }

  const onEdit = (data: any) => {
    setDataEdit(data)
    dispatch(showModal())
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'expenseTypeName', disablePadding: false, label: t('EXPENSE_TYPE.TABLE.CELL.EXPENSE_TYPE_NAME') },
    { id: 'expenseGroup', disablePadding: false, label: t('EXPENSE_TYPE.TABLE.CELL.EXPENSE_GROUP') },
    { id: 'updatedBy', disablePadding: false, label: t('EXPENSE_TYPE.TABLE.CELL.UPDATED_BY') },
    { id: 'action', disablePadding: false, label: 'Action' }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { expenseTypeId: epsTypeId, expenseTypeName: epsTypeName, expenseGroup: epsGroup, updatedBy, updatedAt } = objData
    const objRenderData = {
      key: epsTypeId,
      id: epsTypeId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: epsTypeName },
        { option: 'TEXT', align: 'left', label: epsGroup === 'INCOME' ? t('EXPENSE_TYPE.MODAL.FORM.INCOME') : t('EXPENSE_TYPE.MODAL.FORM.EXPENSE') },

        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [

            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ]
    }
    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
        onedit={() => onEdit(objRenderData.obj)}
        ondelete={() => onDelete(objRenderData.obj)}
      />
    )
  }

  return (
    <div className={classes.root}>
      <Card className="border-0 h-100">
        <HeaderCard text={t('EXPENSE_TYPE.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="align-items-center">
              <Col md={8}>
                <Row className="flax-row">
                  <Col sm={12} md={6} xl={4} className="pt-2">
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('INPUT.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mt-2 mt-md-auto">
                <div className="pr-xl-2">
                  <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => dispatch(showModal())} textButton={t('EXPENSE_TYPE.BUTTON.ADD')} className="w-auto ml-auto d-flex mt-auto" />
                </div>
              </Col>
            </Row>
          </Card.Title>

          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={expenseTypes.map((val, i) => {
                return renderData(val, i)
              })}
            />
          </div>
        </Card.Body>
      </Card>

      <ModalFormExpenseType
        loadData={loadData}
        dataEdit={dataEdit}
        closeModal={() => {
          setDataEdit({})
        }}
      />
    </div>
  )
}

type ModalFormExpenseTypeProps = {
  loadData: () => void
  dataEdit?: any
  closeModal?: () => void
  afterSave?: (data: any) => void
  expenseGroup?: string
}

export const ModalFormExpenseType = (props: ModalFormExpenseTypeProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [expenseTypeId, setExpenseTypeId] = useState(Number)
  const [expenseTypeName, setExpenseTypeName] = useState('')
  const [expenseGroup, setExpenseGroup] = useState<string>(props?.expenseGroup || 'INCOME')
  const [expenseTypeStatus, setExpenseTypeStatus] = useState('')
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

  const onEdit = () => {
    const {
      expenseTypeId: epsTypeId,
      expenseTypeName: epsTypeName,
      expenseGroup: epsGroup,
      status: epsStatus
    } = props.dataEdit

    setExpenseTypeId(epsTypeId)
    setExpenseTypeName(epsTypeName)
    setExpenseGroup(epsGroup)
    setExpenseTypeStatus(epsStatus)
  }

  useEffect(() => {
    if (props?.dataEdit) {
      onEdit()
    }
  }, [props?.dataEdit])

  useEffect(() => {
    if (props?.expenseGroup) {
      setExpenseGroup(props.expenseGroup)
    }
  }, [expenseGroup, props.expenseGroup])

  const submit = () => {
    if (!expenseTypeName) return setErrorMessage({ ...errorMessage, ...{ EXPENSE_TYPE_NAME: t(`EXPENSE_TYPE.MESSAGE.EXPENSE_TYPE_NAME`) } })
    if (!expenseGroup) return setErrorMessage({ ...errorMessage, ...{ EXPENSE_GROUP: t(`EXPENSE_TYPE.MESSAGE.EXPENSE_GROUP`) } })

    dispatch(submitModal())
    ExpenseTypesApi.create({ expenseTypeName, expenseGroup })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t(`EXPENSE_TYPE.MESSAGE.SUCCESS.CREATE`), '', null, null)
          props.loadData()
          if (props?.afterSave) {
            props.afterSave(res.data)
          }
          resetForm()
          dispatch(resetModal())
        } else {
          const err = res?.response?.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`EXPENSE_TYPE.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e?.response?.data
        setErrorMessage({ ...errorMessage, ...{ [err?.message]: t(`EXPENSE_TYPE.MESSAGE.${err?.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const submitEdit = () => {
    if (!expenseTypeName) return setErrorMessage({ ...errorMessage, ...{ EXPENSE_TYPE_CODE: t(`EXPENSE_TYPE.MESSAGE.EXPENSE_TYPE_NAME`) } })
    if (!expenseGroup) return setErrorMessage({ ...errorMessage, ...{ EXPENSE_TYPE_CODE: t(`EXPENSE_TYPE.MESSAGE.EXPENSE_GROUP`) } })

    dispatch(submitModal())
    ExpenseTypesApi.update(expenseTypeId, { expenseTypeName, expenseGroup, status: expenseTypeStatus })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t(`EXPENSE_TYPE.MESSAGE.SUCCESS.UPDATE`), '', null, null)
          props.loadData()
          resetForm()
          dispatch(resetModal())
        } else {
          const err = res?.response?.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`EXPENSE_TYPE.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e?.response?.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`EXPENSE_TYPE.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  const resetForm = () => {
    if (props?.closeModal) props.closeModal()
    dispatch(resetModal())
    setExpenseTypeId(0)
    setExpenseTypeName('')
    setExpenseGroup('INCOME')
  }

  const handleCheckBox = (status: 'INCOME' | 'EXPENSE') => {

    return status === props?.expenseGroup ? false : true
  }

  return (
    <>
      <ModalCustom
        modalStyle={classes.modal}
        title={expenseTypeId ? t('EXPENSE_TYPE.TITLE_UPDATE') : t('EXPENSE_TYPE.TITLE_CREATE')}
        component={
          <div className="pb-2">
            <Row>
              <Col>
                <FormControl className="d-inline-flex flex-center ">
                  <RadioGroup
                    row
                    aria-label="expenseGroup"
                    onChange={(event) => setExpenseGroup(event.target.value)}
                    className=" flex-row flex-center"
                    name="row-radio-buttons-group"
                    value={expenseGroup}
                  >
                    <FormLabel>{t('EXPENSE_TYPE.MODAL.FORM.EXPENSE_TYPE')}</FormLabel>
                    <FormControlLabel
                      className="ml-0"
                      checked={expenseGroup === 'INCOME'}
                      disabled={props?.expenseGroup ? handleCheckBox("INCOME") : false}
                      value="INCOME"
                      control={<InputRadio />}
                      label={t('EXPENSE_TYPE.MODAL.FORM.INCOME')}
                    />
                    <FormControlLabel
                      className="ml-0"
                      checked={expenseGroup === 'EXPENSE'}
                      disabled={props?.expenseGroup ? handleCheckBox("EXPENSE") : false}
                      value="EXPENSE"
                      control={<InputRadio />}
                      label={t('EXPENSE_TYPE.MODAL.FORM.EXPENSE')}
                    />
                  </RadioGroup>
                </FormControl>
              </Col>
              <Col sm={12}>
                <InputTextField
                  onchange={(event) => {
                    setExpenseTypeName(event.target.value)
                    clearErrorMessage()
                  }}
                  value={expenseTypeName}
                  label={t('EXPENSE_TYPE.INPUT.EXPENSE_TYPE_NAME')}
                  helperText={
                    errorMessage.EXPENSE_TYPE_NAME ||
                    errorMessage.IS_DUPLICATE_EXPENSE_TYPE_NAME ||
                    errorMessage.EXPENSE_TYPE_NAME_STRING_BASE ||
                    errorMessage.EXPENSE_TYPE_NAME_STRING_EMPTY ||
                    errorMessage.EXPENSE_TYPE_NAME_MAXIMUM_LENGTH ||
                    errorMessage.EXPENSE_TYPE_NAME_ANY_REQUIRED
                  }
                  required={true}
                  style={{ marginTop: '1rem' }}
                />
              </Col>
            </Row>
          </div>
        }
        onReset={resetForm}
        onSubmit={expenseTypeId ? submitEdit : submit}
        textBtnCancel={t('EXPENSE_TYPE.BUTTON.CANCEL')}
        textBtnConfirm={expenseTypeId ? t('EXPENSE_TYPE.BUTTON.UPDATE') : t('EXPENSE_TYPE.BUTTON.SAVE')}
      />
    </>
  )
}
