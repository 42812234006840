import { makeStyles } from '@material-ui/core'
import { colors } from 'constants/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  labelRadio: {
    textAlign: 'left',
    '&.MuiFormLabel-root.Mui-focused': {
      color: `${colors.black60} !important`
    }
  }
}))

export default useStyles
