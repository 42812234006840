import { useState, useEffect, useCallback } from 'react'
import { Grid, Typography, Box, FormControl, RadioGroup, FormControlLabel, MenuItem } from '@mui/material'
import 'react-multi-carousel/lib/styles.css'
import _ from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import { fileUrl } from 'api/api'
import TreatmentHistoryApi, { PrintDoctorCertificateProps } from 'api/dentists/treatment-history.api'
import TreatmentApi, { LastNumberProps } from 'api/dentists/treatment.api'
import ClinicApi from 'api/master/clinic.api'

/** UTILS || SLICE */
import { closeModal, resetModal } from 'app/slice/modal.slice'

/** COMPONENT */
import BasicSelect from 'component/Select/BasicSelect'
import InputCheckbox from 'component/Input/InputCheckbox'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import Loading from 'component/Loading'
import InputTextarea from 'component/Input/InputTextarea'
import InputRadio from 'component/Input/InputRadio'
import InputTextField from 'component/Input/InputTextField'
import { renderAge } from 'utils/app.utils'
import { TreatmentStateInterface, treatment } from 'app/slice/treatment.slice'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'

type ModalCustomProps = {
    isShow: boolean | false
    setIsShow: (show: boolean) => void
    onClose?: () => void
    patientId: number
    branchId: number
    patient?: any
}

type Doctors = {
    userId: number
    firstname: string
    lastname: string
    fullname: string
}

export default function MedicalCertificate_A5(props: ModalCustomProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [doctors, setDoctors] = useState<Doctors[]>([])
    const [errorDoctorSelect, setErrorDoctorSelect] = useState('')

    const [loading, setLoading] = useState(false)

    const treatments: TreatmentStateInterface = useSelector(treatment)
    const [statusCheck, setStatusCheck] = useState<boolean>(true)
    const [statusCount, setStatusCount] = useState<boolean>(true)
    const [signExaminer, setSignExaminer] = useState<boolean>(true)
    const [statusFrom, setStatusFrom] = useState<"th" | "en" | "empty">("th")
    /** FILTER */
    const [doctor, setDoctor] = useState<any>(null)
    const [no, setNo] = useState<string>("")
    const [date, setDate] = useState<string>(moment().format('YYYY-MM-DD'))
    const [dateCheck, setDateCheck] = useState<string>(moment().format('YYYY-MM-DD'))
    const [count, setCount] = useState<number>(0)
    const [dateStart, setDateStart] = useState<string>(moment().format('YYYY-MM-DD'))
    const [dateEnd, setDateEnd] = useState<string>(moment().format('YYYY-MM-DD'))
    const [fullNameTH, setFullNameTH] = useState<string>("")
    const [fullNameEN, setFullNameEN] = useState<string>("")
    const [age, setAge] = useState<string>("")
    const [commentDoctor, setCommentDoctor] = useState<string>("")
    const [note, setNote] = useState<string>("")

    const loadDoctor = async () => {
        const resp = await ClinicApi.findAllDoctors()
        if (resp.status === 200) setDoctors(resp.data)
    }

    const loadLastNumber = async () => {
        const date = moment().format('YYYY-MM')
        const obj: LastNumberProps = {
            documentKey: "DOCTOR_CERTIFICATE",
            documentDate: date
        }
        const res = await TreatmentApi.lastNumberCertificate(obj)

        if (res.status === 200) {
            const num = res.data.split("-")
            setNo(`${num[0]}-${num[1]}-${(parseInt(num[2], 10) + 1).toString().padStart(num[2].length, '0')}`)
        } else {
            setNo(`${date}-0000`)
        }
    }

    const handleSetAge = (date: string | null) => {
        if (_.isEmpty(date)) {
            return ""
        } else {
            return `${renderAge(date, 'year')} ${setValue_lang(t('TIME_DURATION_TYPE.YEAR'), "Year")} ${renderAge(date, 'month')} ${setValue_lang(t('TIME_DURATION_TYPE.MONTH'), "Month")} ${renderAge(date, 'day')} ${setValue_lang(t('TIME_DURATION_TYPE.DAY'), "Day")}`
        }
    }

    const setNameEn = (type: "TH" | "EN") => {
        const firstNameTH = props.patient?.firstname
        const lastNameTH = props.patient?.lastname
        const prefixTH = props.patient?.prefix?.prefixName

        const firstNameEN = props.patient?.firstnameEn
        const lastNameEN = props.patient?.lastnameEn
        const prefixEN = props.patient?.prefix?.prefixNameEn

        if (type === "EN" && firstNameEN !== "" && lastNameEN !== "") {
            return `${prefixEN} ${firstNameEN} ${lastNameEN}`
        } else {
            return `${prefixTH}${firstNameTH} ${lastNameTH}`
        }

    }

    useEffect(() => {
        if (props.patient) {
            loadLastNumber()
            setFullNameTH(setNameEn("TH"))
            setFullNameEN(setNameEn("EN"))
            setAge(handleSetAge(props.patient?.dateOfBirth))
        }
        loadDoctor()
    }, [])

    const onPrint = async () => {

        const { address, addressEn, cnNumber, branchCnNumber } = props?.patient || {}
        const patientDiagnosisPlus = isChecks.map((item: any) => {
            return {
                patientDiagnosisId: item.patientDiagnosisId,
                diagnosisId: item.diagnosisId,
                diagnosisName: item.diagnosisName,
            }
        })
        const systolicBloodPressure = props.patient?.systolicBloodPressure
        const diastolicBloodPressure = props.patient?.diastolicBloodPressure
        const diastolicBloodPressureCheck = () => {return diastolicBloodPressure ? `/${diastolicBloodPressure}` : ''}
        
        
        const obj: PrintDoctorCertificateProps = {
            "referenceNumber": setValue(no),
            "branchId": props?.branchId,
            "date": setValue(date),
            "doctorName": setValue(doctor?.fullname) || "",
            "doctorId": setValue(doctor?.userId) || 0,
            "certificate": setValue(doctor?.certificate) || "",
            "patientId": props?.patientId,
            "address": setValue(address) || "",
            "addressEn": setValue(addressEn) || "",
            "patientFullName": setValue(setValue_lang(fullNameTH, fullNameEN)),
            "age": setValue(age) || "",
            "cnNumber": cnNumber,
            "branchCnNumber": branchCnNumber,
            "physicalExaminationHistory": "",
            "patientDiagnosis": setValue(patientDiagnosisPlus, "send"),
            "note": setValue(note),
            "doctorOpinion": setValue(commentDoctor),
            "actualCheckupDate": !statusCheck ? "" : setValue(dateCheck),
            "actualCheckupStatus": setValue(statusCheck),
            "stayDuration": setValue(count) === "" || setValue(count) === 0 || !statusCount ? 0 : Number(setValue(count)),
            "startDate": !statusCount ? "" : setValue(dateStart),
            "endDate": !statusCount ? "" : setValue(dateEnd),
            "stayDurationStatus": setValue(statusCount),
            "signedByTheExaminer": signExaminer,
            "height": props.patient?.height || "",
            "temperature": props.patient?.temperature || "",
            "weight": props.patient?.weight || "",
            "bmi": props.patient?.bmi || "",
            "o2Sat": props.patient?.o2Sat || "",
            "pulseRate": props.patient?.pulseRate || "",
            "rr": props.patient?.rr || "",
            "bloodPressure": systolicBloodPressure ? `${systolicBloodPressure}${diastolicBloodPressureCheck()}` : ""
        }
        setLoading(true)

        const print = await TreatmentHistoryApi.printDoctorCertificate(props?.patientId, obj, setType_send()).finally(() => setLoading(false))
        if (print.status === 201) {
            window.open(`${fileUrl}/${print.data}`, '_blank')
            clearData()
            props.setIsShow(false)
            dispatch(closeModal())
        } else {
            return
        }
    }

    const setType_send = () => {
        if (statusFrom !== "en") {
            return "TH"
        } else {
            return "EN"
        }
    }

    const clearData = () => {
        setDoctor(null)
        setNo("")
        setDate(moment().format('YYYY-MM-DD'))
        setFullNameTH("")
        setFullNameEN("")
        setAge("")
        setCommentDoctor("")
        setNote("")
        setCount(0)
        setDateCheck(moment().format('YYYY-MM-DD'))
        setDateStart(moment().format('YYYY-MM-DD'))
        setDateEnd(moment().format('YYYY-MM-DD'))
    }

    const sendOutClinic = (status: "th" | "en" | "empty") => {
        setStatusFrom(status)
    }

    const setDisable_input = () => {
        if (statusFrom !== "empty") {
            return false
        } else {
            return true
        }
    }

    const setValue_lang = (infoTH: any, infoEN: any) => {
        if (statusFrom === "en") {
            return infoEN
        } else {
            return infoTH
        }
    }


    const setValue = useCallback((info: any, status?: "send") => {
        if (statusFrom !== "empty") {
            return info;
        } else {
            if (typeof info === "string") {
                return "";
            } else if (typeof info === "number") {
                return "";
            } else if (typeof info === "boolean") {
                return false
            } else {
                return status ? [] : null;
            }
        }
    }, [statusFrom]);

    useEffect(() => {
        if (statusFrom !== "empty") {
            setAge(handleSetAge(props.patient?.dateOfBirth))
        }
    }, [statusFrom])

    useEffect(() => {
        const newCount = count ? count - 1 : count
        const outputDate = moment(dateStart).add(newCount, 'days').format('YYYY-MM-DD');
        setDateEnd(outputDate)
    }, [count, dateStart])

    const [isChecks, setIsChecks] = useState([] as any)
    const [isCheckAll, setIsCheckAll] = useState('')

    const handleOnCheckAll = () => {
        const hasSome = _.some(treatments.patientDiagnosis, (i: any) => _.some(isChecks, (j: any) => i.diagnosisCode === j.diagnosisCode))
        const hasAll = _.every(treatments.patientDiagnosis, (i: any) => _.some(isChecks, (j: any) => i.diagnosisCode === j.diagnosisCode))
        if (!_.isEmpty(isChecks) && !hasAll && hasSome) setIsCheckAll('indeterminate')
        else if (!_.isEmpty(isChecks) && hasAll) setIsCheckAll('checked')
        else setIsCheckAll('')
    }

    useEffect(() => {
        if (statusFrom !== "empty") {
            handleOnCheckAll()
        }
    }, [isChecks, treatments.patientDiagnosis])

    const onCheckAll = (event: any) => {
        const { checked } = event
        const id = treatments.patientDiagnosis
        if (statusFrom !== "empty") {
            if (checked) {
                setIsChecks(Array.from(new Set([...isChecks, ...id])))
            } else {
                setIsChecks(_.filter(isChecks, (i) => !_.includes(id, i)))
            }
        }

    }

    const onCheck = (event: any) => {
        const { checked, value } = event
        const data = _.filter(treatments.patientDiagnosis, { diagnosisCode: value })
        if (statusFrom !== "empty") {
            if (checked) {
                setIsChecks([...isChecks, data[0]])
            } else {
                setIsChecks(
                    _.filter(isChecks, (i: any) => {
                        return i !== data[0]
                    })
                )
            }
        }
    }

    const headCells = [
        {
            id: 'patientSelect',
            disablePadding: false,
            label: 'CHECKBOX',
            values: [],
            width: '80px',
            indeterminate: statusFrom !== "empty" && isCheckAll === 'indeterminate' ? true : false,
            checked: statusFrom !== "empty" && isCheckAll === 'checked' ? true : false,
            onCheckAll: (event: any) => onCheckAll(event.target),
        },
        { id: 'no', disablePadding: false, align: 'center', label: '#', width: '70px' },
        { id: 'message', disablePadding: false, align: 'center', label: t('DIAGNOSIS_SUMMARY.TITLE.CODE'), width: '100px' },
        { id: 'message', disablePadding: false, align: 'left', label: t('DIAGNOSIS_SUMMARY.TITLE.DIAGNOSIS') },
    ]

    const renderData = (objData: any, no: number) => {
        no = no + 1

        const { diagnosisCode, diagnosisName } = objData
        const rowCheck = _.some(isChecks, (i: any) => i.diagnosisCode === diagnosisCode)

        const objRenderData = {
            key: diagnosisCode,
            id: diagnosisCode,
            obj: objData,
            rowSelect: statusFrom !== "empty" ? rowCheck : false,
            columns: [
                { option: 'CHECKBOX', align: 'center', label: diagnosisCode },
                { option: 'TEXT', align: 'center', label: no },
                { option: 'TEXT', align: 'left', label: diagnosisCode || '-' },
                { option: 'TEXT', align: 'left', label: diagnosisName || '-' }
            ]
        }
        return <TableRowCommon
            {...objRenderData}
            onCheck={(event) => onCheck(event.target)}
        />
    }

    return (
        <ModalCustom
            title={t('MODAL_MEDICALCERTIFICATE_A5.TITLE')}
            alignFooter={'end'}
            size={'xl'}
            footerInline
            onClose={props.onClose}
            onSubmit={onPrint}
            onReset={() => {
                dispatch(resetModal())
                props.setIsShow(false)
            }}
            textBtnCancel={t('BUTTON.CANCEL')}
            textBtnConfirm={t('BUTTON.PRINT')}
            modalStyle={'modal-referral-examination'}
            component={
                <>
                    <Grid container spacing={2} className="pt-2">
                        <Grid item className='w-100'>
                            <Box className="d-flex align-items-center">
                                <Typography className='mr-3' sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>{t('MODAL_MEDICALCERTIFICATE_A5.FORM')}</Typography>
                                <FormControl>
                                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                        <FormControlLabel value="th" control={<InputRadio checked={statusFrom === "th"} className="radio" onClick={() => sendOutClinic("th")} />} label={t('MODAL_MEDICALCERTIFICATE_A5.FORM_THAI')} />
                                        <FormControlLabel value="en" control={<InputRadio checked={statusFrom === "en"} className="radio" onClick={() => sendOutClinic("en")} />} label={t('MODAL_MEDICALCERTIFICATE_A5.FORM_EN')} />
                                        <FormControlLabel value="empty" control={<InputRadio checked={statusFrom === "empty"} onClick={() => sendOutClinic("empty")} />} label={t('MODAL_MEDICALCERTIFICATE_A5.FORM_EMPTY')} />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Grid>

                        <Grid item xs={3} className='w-100'>
                            <InputTextField
                                disabled
                                key={'no'}
                                label={t('MODAL_MEDICALCERTIFICATE_A5.FORM_NO')}
                                value={setValue(no)}
                            />
                        </Grid>
                        <Grid item xs={3} className='w-100'>
                            <InputSecondNewDatePicker
                                required={statusFrom !== "empty" ? true : false}
                                disabled={statusFrom !== "empty" ? false : true}
                                key={'date'}
                                label={t('MODAL_MEDICALCERTIFICATE_A5.FORM_DATE')}
                                value={setValue(date)}
                                onchange={(e: any) => setDate(moment(e).format('YYYY-MM-DD'))} />
                        </Grid>
                        <Grid item xs={3} className='w-100'>
                            <BasicSelect
                                name="doctorId"
                                labelId="lb-dentist"
                                selectId="sl-dentist"
                                label={t('HISTORY_APPOINTMENT.DOCTOR')}
                                value={setValue(doctor) || null}
                                options={[
                                    _.map(doctors, (doctorData: any, i: number) => (
                                        <MenuItem key={i} value={doctorData}>
                                            {doctorData.fullname}
                                        </MenuItem>
                                    ))
                                ]}
                                onchange={(event) => {
                                    setDoctor(event.target.value)
                                    setErrorDoctorSelect('')
                                }}
                                classesOption="style-select-doctors-mb"
                                disabled={statusFrom !== "empty" ? false : true}
                                helperText={errorDoctorSelect || ''}
                                allowClear={true}
                                onClear={() => setDoctor(null)}
                            />
                        </Grid>
                        <Grid item xs={3} className='w-100'>
                            <InputTextField
                                disabled
                                key={'FORM_NODOC'}
                                label={t('MODAL_MEDICALCERTIFICATE_A5.FORM_NODOC')}
                                value={setValue(doctor?.certificate) || ""}
                            />
                        </Grid>

                        <Grid item xs={3} className='w-100'>
                            <InputTextField
                                disabled
                                key={'fullNameTH'}
                                label={t('MODAL_MEDICALCERTIFICATE_A5.FORM_NAME')}
                                value={setValue(setValue_lang(fullNameTH, fullNameEN))} />
                        </Grid>

                        <Grid item xs={3} className='w-100'>
                            <InputTextField
                                disabled
                                key={'age'}
                                label={t('MODAL_MEDICALCERTIFICATE_A5.FORM_AGE')}
                                value={setValue(age)}
                            />
                        </Grid>
                        <Grid item xs={3} className='w-100'>
                            <InputTextField
                                disabled
                                key={'cnNumber'}
                                label={t('MODAL_MEDICALCERTIFICATE_A5.FORM_CN')}
                                value={setValue(props.patient?.cnNumber)}
                            />
                        </Grid>
                    </Grid>
                    <div className='pt-2 mt-2'>
                        <TableCustom
                            hidePagination
                            customScroll
                            page={0}
                            pageLimit={0}
                            sortType={'ASC'}
                            sortBy={''}
                            rowCount={0}
                            textEmptyData={t('REGISTER.NO_ITEM')}
                            onSort={() => {
                                return
                            }}
                            setPageLimit={() => {
                                return
                            }}
                            setPage={() => {
                                return
                            }}
                            headCells={headCells}
                            rowsData={_.map(treatments.patientDiagnosis, (val: any, i: number) => {
                                return renderData(val, i)
                            })}
                        />
                    </div>
                    <div className='pt-2 mt-2'>
                        <InputTextField
                            label={t('MODAL_MEDICALCERTIFICATE_A5.FORM_DIAGETEXT')}
                            onchange={(e: any) => {
                                setNote(e.target.value)
                            }}
                            disabled={setDisable_input()}
                            value={setValue(note)} />
                    </div>
                    <div className='pt-2 mt-2'>
                        <InputTextarea
                            label={t('MODAL_MEDICALCERTIFICATE_A5.FORM_COMMENT')}
                            onchange={(e: any) => {
                                setCommentDoctor(e.target.value)
                            }}
                            disabled={setDisable_input()}
                            value={setValue(commentDoctor)}
                        />
                    </div>
                    <Grid container spacing={2} className='pt-3 d-flex justify-content-start align-items-center'>
                        <div className='pt-3 pl-3'>
                            <InputCheckbox onChange={() => setStatusCheck(!statusCheck)} checked={setValue(statusCheck)} />
                        </div>
                        <Grid item xs={3} className='w-100 pl-1'>
                            <InputSecondNewDatePicker
                                disabled={setDisable_input() || !statusCheck}
                                key={'dateCheck'}
                                label={t('MODAL_MEDICALCERTIFICATE_A5.FORM_CHECK')}
                                value={!statusCheck ? "" : setValue(dateCheck)}
                                onchange={(e: any) => setDateCheck(moment(e).format('YYYY-MM-DD'))} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className='pt-3 d-flex justify-content-start align-items-center'>
                        <div className='pt-3 pl-3'>
                            <InputCheckbox onChange={() => setStatusCount(!statusCount)} checked={setValue(statusCount)} />
                        </div>
                        <Grid item xs={3} className='w-100 pl-1'>
                            <InputTextField
                                label={t('MODAL_MEDICALCERTIFICATE_A5.FORM_COUNT')}
                                onchange={(e: any) => {
                                    const inputValue = e.target.value;
                                    if (!isNaN(inputValue)) {
                                        const parsedValue = Number(inputValue);
                                        if (parsedValue >= 0) {
                                            setCount(parsedValue);
                                        }
                                    }
                                }}
                                disabled={setDisable_input() || !statusCount}
                                value={setValue(count) === "" || setValue(count) === 0 || !statusCount ? "" : Number(setValue(count))} />
                        </Grid>
                        <Grid item xs={3} className='w-100 pl-1'>
                            <InputSecondNewDatePicker
                                disabled={setDisable_input() || !statusCount}
                                key={'dateStart'}
                                label={t('MODAL_MEDICALCERTIFICATE_A5.FORM_START')}
                                value={!statusCount ? "" : setValue(dateStart)}
                                onchange={(e: any) => setDateStart(moment(e).format('YYYY-MM-DD'))} />
                        </Grid>
                        <Grid item xs={3} className='w-100 pl-1'>
                            <InputSecondNewDatePicker
                                disabled
                                key={'dateEnd'}
                                label={t('MODAL_MEDICALCERTIFICATE_A5.FORM_END')}
                                value={!statusCount ? "" : setValue(dateEnd)}
                                onchange={(e: any) => setDateEnd(moment(e).format('YYYY-MM-DD'))} />
                        </Grid>
                    </Grid>
                    <div className='pt-2 d-flex justify-content-start align-items-center'>
                        <InputCheckbox onChange={() => setSignExaminer(!signExaminer)} checked={signExaminer} />
                        <span className='ml-1'>{t('MODAL_MEDICALCERTIFICATE_A5.FORM_SIGN')}</span>
                    </div>

                    {loading && <Loading show={true} type="fullLoading" />}
                </>
            }
        />
    )
}

