import { Box, Typography, Button, Menu } from '@mui/material'
import { colors } from 'constants/theme'
import { clinicTheme } from 'api/api'

/** STYLE */
import { styled } from '@mui/material/styles'
import { CustomTabs } from 'features/treatments/SummaryTreatment/UseStyle'

export const CustomPopupEmrOverview = styled(Box)(({ theme }) => ({
  '.MuiTypography-root': {
    color: colors.textPrimary
  }
}))

export const CustomTabsEmr = styled(CustomTabs)(({ theme }) => ({
  '.MuiTab-root': {
    maxWidth: 'max-content',
    padding: '0 16px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 'max-content',
    }
  }
}))

export const TreatmentBox = styled(Box)(({ theme }) => ({
  marginTop: '1.5rem',
  padding: '0 1rem 1.5rem',
  border: `1px solid ${colors.lightGray}`,
  borderRadius: 5,
  backgroundColor: colors.white,
  [theme.breakpoints.down('sm')]: {
    padding: '0 0.5rem 1rem',
  }
}))

export const LabelDate = styled(Box)(({ theme }) => ({
  height: 40,
  maxWidth: 215,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.themeMainColor,
  color: colors.white,
  fontSize: 20,
  fontWeight: 500,
  borderBottomLeftRadius: 5,
  borderBottomRightRadius: 5,
  marginBottom: 8
}))

export const Typo = styled(Typography)<{ type?: 'title' | 'default', width?: string }>(({ theme, type, width }) => ({
  fontWeight: type === 'title' ? 500 : 400,
  width: (type === 'title' && width) || (type === 'title' && '140px') || 'auto',
  color: colors.textPrimary,
  flex: type === 'title' ? 'none' : 1
}))

export const TypoNo = styled(Typo)(({ theme }) => ({
  width: 20,
  marginRight: 4,
  flex: 'none',
  [theme.breakpoints.up('sm')]: {
    marginRight: 8,
  }
}))

export const ResponsiveTable = styled(Box)<{ type: 'tx' | 'dx' | 'txp' | 'txc' }>(({ theme, type }) => ({
  '.MuiTable-root': {
    tableLayout: 'fixed',
    minWidth: '100%',
    '.MuiTableHead-root': {
      border: 'none !important',
      '.MuiTableCell-head': {
        padding: '8px 8px 0',
        backgroundColor: 'transparent',
        color: `${colors.textPrimary} !important`,
        border: 'none !important',
        '&:first-of-type': {
          width: '35px'
        },
        '&:nth-of-type(2)': {
          width: (clinicTheme.theme !== clinicTheme.type.DENTAL && type === 'tx') ? '20%' : (type === 'dx' || type === 'txp' && '150px') || (type === 'txc' && '150px') || 'auto',
          [theme.breakpoints.down('md')]: {
            width: (clinicTheme.theme !== clinicTheme.type.DENTAL && type === 'tx') ? '20%' : type === 'dx' ? '80px' : 'auto',
          }
        },
        '&:nth-of-type(3)': {
          width: (clinicTheme.theme !== clinicTheme.type.DENTAL && type === 'tx') ? '35%' : type === 'txp' ? '100px' : 'auto'
        },
        '&:nth-of-type(4)': {
          width: (clinicTheme.theme !== clinicTheme.type.DENTAL && type === 'tx') ? '75px' : type === 'txc' ? '130px' : '350px',
          [theme.breakpoints.down(1600)]: {
            width: (clinicTheme.theme !== clinicTheme.type.DENTAL && type === 'tx') ? '75px' : '300px',
          }
        },
        '&:nth-of-type(5)': {
          width: (clinicTheme.theme !== clinicTheme.type.DENTAL && type === 'tx') ? '110px' : type === 'txp' || type === 'txc' ? '130px' : '75px'
        },
        '&:nth-of-type(6)': {
          width: (clinicTheme.theme !== clinicTheme.type.DENTAL && type === 'tx') ? '230px' : type === 'txp' || type === 'txc' ? '130px' : '110px'
        },
        '&:nth-of-type(7)': {
          width: (clinicTheme.theme !== clinicTheme.type.DENTAL && type === 'tx') ? '110px' : '230px',
          [theme.breakpoints.down(1600)]: {
            width: (clinicTheme.theme !== clinicTheme.type.DENTAL && type === 'tx') ? '110px' : '180px',
          }
        },
        '&:nth-of-type(8)': {
          width: type === 'txp' ? '130px' : '110px'
        },
        '&:nth-of-type(9)': {
          width: '110px'
        },
        '&:last-of-type': {
          width: (type === 'tx' && '110px') || (type === 'dx' && '250px') || (type === 'txp' || type === 'txc' && '130px') || 'auto',
          [theme.breakpoints.down('md')]: {
            width: (type === 'tx' && '110px') || (type === 'dx' && '200px') || 'auto',
          }
        },
      }
    },
    '.MuiTableBody-root': {
      border: 'none !important',
      '.MuiTableRow-root': {
        '&.main-row': {
          '.MuiTableCell-body': {
            borderBottom: 'none',
            paddingBottom: 0
          }
        },
        '&:hover': {
          '.MuiTableCell-body': {
            backgroundColor: 'transparent'
          }
        },
        '&:last-of-type': {
          '.MuiTableCell-body': {
            borderBottom: 'none',
            paddingBottom: 0
          }
        },
        '.MuiTableCell-body': {
          padding: '16px 8px',
          '&:first-of-type': {
            paddingLeft: 4,
            paddingRight: 4
          },
          '&.cell-empty': {
            paddingTop: '16px !important'
          }
        }
      }
    }
  },
}))

export const ToothChartImage = styled(Box)(({ theme }) => ({
  maxWidth: 764,
  [theme.breakpoints.down('lg')]: {
    maxWidth: 535,
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: 10,
    maxWidth: 312,
  }
}))

export const ButtonMore = styled(Button)(({ theme }) => ({
  minWidth: 'unset',
  width: 40,
  height: 40,
  border: `1px solid ${colors.lightGray}`,
  backgroundColor: `${colors.white} !important`,
  padding: 0,
  position: 'absolute',
  top: 0,
  right: -120,
  '&:hover': {
    borderColor: colors.disabledGray
  },
  [theme.breakpoints.down(1440)]: {
    right: -50
  },
  [theme.breakpoints.down('sm')]: {
    top: -10,
    width: 30,
    height: 30,
    right: 0
  }
}))

export const MenuBtnMore = styled(Menu)(({ theme }) => ({
  '.MuiPaper-root': {
    marginTop: '0.5rem',
    borderRadius: 3,
    mixBlendMode: 'multiply',
    backgroundBlendMode: 'multiply',
    background: `linear-gradient(to bottom, ${colors.white}, ${colors.white})`,
    boxShadow: `none !important`,
    border: `1px solid ${colors.lightGray}`
  },
  '.MuiMenuItem-root': {
    color: colors.textPrimary,
    fontSize: '16px !important',
    '&:hover': {
      background: colors.themeSecondColor06,
      color: colors.themeSecondColor
    }
  }
}))
