import React from 'react'

// type Props = {}

const MenuGroup = () => {
    return (
        <>
            <svg width="17" height="5" viewBox="0 0 17 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8.5" cy="2.5" r="2" stroke="black" />
                <circle cx="2.5" cy="2.5" r="2" stroke="black" />
                <circle cx="14.5" cy="2.5" r="2" stroke="black" />
            </svg>
        </>
    )
}

export default MenuGroup