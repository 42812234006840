import { Box } from '@mui/material';
import { colors } from 'constants/theme';

/** STYLE */
import { styled } from '@mui/material/styles'

export const ResponsiveTable = styled(Box)(({ theme }) => ({
    '.MuiTable-root': {
        tableLayout: 'fixed',
        minWidth: '100%',
        '.MuiTableHead-root': {
            '.MuiTableCell-head': {
                '&:nth-of-type(1)': {
                    width: 15
                },
                '&:nth-of-type(2)': {
                    width: 150
                },
                '&:nth-of-type(3)': {
                    width: 200
                },
                '&:nth-of-type(5)': {
                    width: 150
                },
                '&:nth-of-type(6)': {
                    width: 150
                },
                '&:nth-of-type(7)': {
                    width: 150
                },
                '&:nth-of-type(8)': {
                    width: 100
                },
                [theme.breakpoints.down('xl')]: {
                    '&:nth-of-type(1)': {
                        width: 130
                    },
                    '&:nth-of-type(2)': {
                        width: 150
                    },
                },
                [theme.breakpoints.down('lg')]: {
                    '&:not(:first-of-type)': {
                        fontSize: 0
                    },
                    '&:not(:last-of-type)': {
                        width: 'auto'
                    },
                    '&:nth-of-type(1)': {
                        width: 130
                    },
                    '&:last-of-type': {
                        width: 90
                    }
                },
                [theme.breakpoints.down('sm')]: {
                    width: 'auto',
                    '&:last-of-type': {
                        paddingLeft: 0,
                        width: 50
                    }
                }
            }
        },
        '.MuiTableBody-root': {
            '.MuiTableCell-root': {
                padding: '6px 15px',
                verticalAlign: 'middle'
            }
        }
    },
    '.MuiTablePagination-selectLabel, .MuiTablePagination-input': {
        display: 'none'
    }
}))

export const IconTable = styled('div')(({ theme }) => ({
    display: 'inline-block',
    cursor: 'pointer',
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
    'svg, img': {
        width: '1.025rem',
        height: '1.025rem',
    },
    '&.edit': {
        'svg': {
            path: {
                fill: `${colors.textPrimary} !important`,
                '&:last-of-type': {
                    fill: `${colors.transparent} !important`,
                    stroke: `${colors.textPrimary} !important`,
                }
            }
        },
        '&:hover': {
            'svg': {
                path: {
                    fill: `${colors.themeSecondColor} !important`,
                    '&:last-of-type': {
                        fill: `${colors.transparent} !important`,
                        stroke: `${colors.themeSecondColor} !important`
                    }
                }
            }
        }
    },
    '&.trash': {
        'svg': {
            path: {
                stroke: `${colors.textPrimary} !important`,
            }
        },
        '&:hover': {
            'svg': {
                path: {
                    stroke: `${colors.themeSecondColor} !important`,
                }
            }
        }
    }
}))