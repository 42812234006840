import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** STYLE */
import { styled } from '@mui/material/styles'

/** CONSTANTS */
import { colors } from 'constants/theme'

const CustomBreadcrumb = styled(Box)(({ theme }) => ({
    '.link-nav': {
        '&:hover': {
            textDecoration: 'none',
            '.MuiTypography-root': {
                color: colors.themeSecondColor,
                textDecoration: 'underline',
            }
        }
    },
    '.MuiTypography-root, span': {
        fontSize: 14,
        color: colors.textPrimary
    }
}))

export interface BreadcrumbType {
    title: string
    variable?: string
    path?: string
    onClick?: () => void
}

type IBreadcrumb = {
    breadcrumbs?: BreadcrumbType[]
    className?: string
    style?: any
}

export default function Breadcrumb(props: IBreadcrumb) {
    const { t } = useTranslation()

    return (
        <CustomBreadcrumb className={`breadcrumb d-flex align-items-center ${props.className ? props.className : ''}`} style={{ ...props.style }}>
            {_.map(props.breadcrumbs, (breadcrumb: BreadcrumbType, index: number) => {
                return (breadcrumb.path && (
                    <Box key={index} className="d-flex align-items-center">
                        <Link to={breadcrumb.path} className={'m-0 p-0 link-nav w-100'}>
                            <Typography>{t(breadcrumb.title)}{breadcrumb.variable}</Typography>
                        </Link>
                        <span className={'px-1'}>{'>'}</span>
                    </Box>
                )) || (breadcrumb.onClick && (
                    <Box key={index} className="d-flex align-items-center">
                        <a href='javascript:void(0)' onClick={breadcrumb.onClick} className={'m-0 p-0 link-nav w-100'}>
                            <Typography>{t(breadcrumb.title)}{breadcrumb.variable}</Typography>
                        </a>
                        <span className={'px-1'}>{'>'}</span>
                    </Box>
                )) || <Typography key={index}>{t(breadcrumb.title)}{breadcrumb.variable}</Typography>
            })}
        </CustomBreadcrumb>
    )
}
