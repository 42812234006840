import _ from 'lodash'
import { colors } from 'constants/theme'
import { useTranslation } from 'react-i18next'
import { numberFormat, toBuddhistYear } from 'utils/app.utils'
import { TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material'
import TableCustom from 'component/Table/TableCustom'
import * as UseStyled from 'features/report/useStyled'
import { ExportProps } from 'features/report/report-constants'
import moment from 'moment'
import { Padding } from '@mui/icons-material'

interface FcComponent {
  page: number
  pageLimit: number
  rowCount: number
  sortBy: string
  sortType: string
  data: any
  onRequestSort: (sortBy: string, sortType: string) => void
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
  componentRef: any
  cash: boolean
  df: boolean
  filter: any
  expenses: boolean
}

export default function RemittanceList(props: FcComponent) {
  const { t } = useTranslation()

  const headCells = [
    { id: 'message', disablePadding: false, width: '70%', labelKey: 'REPORT.TABLE.REMITTANCE.LIST', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMITTANCE.QTY', align: 'right', class: 'text-nowrap' }
  ]
  const header = [props.df ? 'DF' : null, props.cash ? t('REPORT.INCOME_MONTH.CASH') : null, props.expenses ? t('REPORT.FILTER.INCOME') : null].filter(Boolean).join(', ')

  const renderData = (data: any, exports: boolean) => {
    const total = data.rowDf?.length > 0 || data.rowExpenses?.length > 0 || data.rowTreatmentRight?.length > 0 ? _.sumBy(data.rowDf, 'price') + _.sumBy(data.rowExpenses, 'price') : 0
    const expenses = props.expenses ? _.sumBy(data.rowExpensesCash, 'price') + _.sumBy(data.rowExpensesTransfer, 'price') + _.sumBy(data.rowExpensesDebit, 'price') + _.sumBy(data.rowExpensesCredit, 'price') : 0
    const income = props.cash
      ? _.sumBy(data.rowIncomeCash, 'treatment')
      : _.sumBy(data.rowIncomeCash, 'treatment') +
        _.sumBy(data.rowIncomeCredit, 'treatment') +
        _.sumBy(data.rowIncomeDebit, 'treatment') +
        _.sumBy(data.rowIncomeTransfer, 'treatment') +
        _.sumBy(data.rowTreatmentRight, 'price') +
        _.sumBy(data.rowAdvance, 'price')
    const df = _.sumBy(data.rowDf, 'price')

    return !exports ? (
      <>
        {data.rowDailyBalance &&
          _.map(data.rowDailyBalance, (val: any, index: number) => {
            return (
              <>
                <TableRow>
                  <TableCell align="left" scope="row" style={{ fontWeight: '800' }}>
                    {t('REPORT.TABLE.REMITTANCE.NET_DAILY')}
                  </TableCell>
                  <TableCell align="right" scope="row">
                    {numberFormat(val.sumTreatment + val.sumProduct)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" scope="row">
                    {t('REPORT.TABLE.REMITTANCE.NET_OPERATIVE')}
                  </TableCell>
                  <TableCell align="right" scope="row">
                    {numberFormat(val.sumTreatment)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" scope="row">
                    {t('REPORT.TABLE.REMITTANCE.NET_PRODUCT')}
                  </TableCell>
                  <TableCell align="right" scope="row">
                    {numberFormat(val.sumProduct)}
                  </TableCell>
                </TableRow>

                {props.expenses && (
                  <TableRow>
                    <TableCell align="left" scope="row">
                      {t('REPORT.TABLE.REMITTANCE.ANOTHER')}
                    </TableCell>
                    <TableCell align="right" scope="row">
                      {numberFormat(0)}
                    </TableCell>
                  </TableRow>
                )}
              </>
            )
          })}

        <TableRow>
          <TableCell align="left" scope="row" sx={{ color: colors.blue, fontWeight: '800' }}>
            {t('REPORT.TABLE.REMITTANCE.CASH')}
          </TableCell>
          <TableCell align="right" scope="row" sx={{ color: colors.blue, fontWeight: '800' }}>
            {numberFormat(_.sumBy(data.rowIncomeCash, 'treatment') || 0)}
          </TableCell>
        </TableRow>
        {_.map(data.rowIncomeCash, (val: any, index: number) => {
          return (
            <>
              <TableRow>
                <TableCell align="left" scope="row" sx={{ color: colors.blue }}>
                  - {val.detail || t('REPORT.TITLE.TREATMENT_PRODUCT')}
                </TableCell>
                <TableCell align="right" scope="row" sx={{ color: colors.blue }}>
                  {numberFormat(val.treatment)}
                </TableCell>
              </TableRow>
            </>
          )
        })}

        <TableRow>
          <TableCell align="left" scope="row" sx={{ color: colors.blue, fontWeight: '800' }}>
            {t('REPORT.TABLE.REMITTANCE.TRANSFER_')}
          </TableCell>
          <TableCell align="right" scope="row" sx={{ color: colors.blue, fontWeight: '800' }}>
            {numberFormat(_.sumBy(data.rowIncomeTransfer, 'treatment') || 0)}
          </TableCell>
        </TableRow>
        {_.map(data.rowIncomeTransfer, (val: any, index: number) => {
          return (
            <>
              {val.treatment > 0 && (
                <TableRow>
                  <TableCell align="left" scope="row" sx={{ color: colors.blue }}>
                    - {val.detail}
                  </TableCell>
                  <TableCell align="right" scope="row" sx={{ color: colors.blue }}>
                    {numberFormat(val.treatment || 0)}
                  </TableCell>
                </TableRow>
              )}
            </>
          )
        })}

        <TableRow>
          <TableCell align="left" scope="row" sx={{ color: colors.blue, fontWeight: '800' }}>
            {t('REPORT.TABLE.REMITTANCE.DEBIT')}
          </TableCell>
          <TableCell align="right" scope="row" sx={{ color: colors.blue, fontWeight: '800' }}>
            {numberFormat(_.sumBy(data.rowIncomeDebit, 'treatment'))}
          </TableCell>
        </TableRow>
        {_.map(data.rowIncomeDebit, (val: any, index: number) => {
          return (
            <>
              {val.treatment > 0 && (
                <TableRow>
                  <TableCell align="left" scope="row" sx={{ color: colors.blue }}>
                    - {val.detail}
                  </TableCell>
                  <TableCell align="right" scope="row" sx={{ color: colors.blue }}>
                    {numberFormat(val.treatment)}
                  </TableCell>
                </TableRow>
              )}
            </>
          )
        })}

        <TableRow>
          <TableCell align="left" scope="row" sx={{ color: colors.blue, fontWeight: '800' }}>
            {t('REPORT.TABLE.REMITTANCE.CREDIT')}
          </TableCell>
          <TableCell align="right" scope="row" sx={{ color: colors.blue, fontWeight: '800' }}>
            {numberFormat(_.sumBy(data.rowIncomeCredit, 'treatment') || 0)}
          </TableCell>
        </TableRow>
        {_.map(data.rowIncomeCredit, (val: any, index: number) => {
          return (
            <>
              {val.treatment > 0 && (
                <TableRow>
                  <TableCell align="left" scope="row" sx={{ color: colors.blue }}>
                    - {val.detail}
                  </TableCell>
                  <TableCell align="right" scope="row" sx={{ color: colors.blue }}>
                    {numberFormat(val.treatment || 0)}
                  </TableCell>
                </TableRow>
              )}
            </>
          )
        })}
        {/* rowAdvance  */}

        <TableRow>
          <TableCell align="left" scope="row" sx={{ color: colors.blue, fontWeight: '800' }}>
            {t('REPORT.TABLE.REMITTANCE.ADVANCE')}
          </TableCell>
          <TableCell align="right" scope="row" sx={{ color: colors.blue, fontWeight: '800' }}>
            {numberFormat(_.sumBy(data.rowAdvance, 'price') || 0)}
          </TableCell>
        </TableRow>

        {/* rowTreatmentRight */}
        <TableRow>
          <TableCell align="left" scope="row" sx={{ color: colors.blue, fontWeight: '800' }}>
            {t('REPORT.TABLE.REMITTANCE.TREATMENT_RIGHT')}
          </TableCell>
          <TableCell align="right" scope="row" sx={{ color: colors.blue, fontWeight: '800' }}>
            {numberFormat(_.sumBy(data.rowTreatmentRight, 'price') || 0)}
          </TableCell>
        </TableRow>
        {_.map(data.rowTreatmentRight, (val: any, index: number) => {
          return (
            <>
              <TableRow>
                <TableCell align="left" scope="row" sx={{ color: colors.blue }}>
                  - {val.treatmentRightName}
                </TableCell>
                <TableCell align="right" scope="row" sx={{ color: colors.blue }}>
                  {numberFormat(val.price || 0)}
                </TableCell>
              </TableRow>
            </>
          )
        })}

        {/*  Expenses */}
        {props.expenses && (
          <>
            <TableRow>
              <TableCell align="left" scope="row" sx={{ color: colors.statusInactive, fontWeight: '800' }}>
                {t('REPORT.TABLE.REMITTANCE.TOTAL_EXPENSES')}
              </TableCell>
              <TableCell align="right" scope="row" sx={{ color: colors.statusInactive, fontWeight: '800' }}>
                {numberFormat(_.sumBy(data.rowExpensesCash, 'price') + _.sumBy(data.rowExpensesTransfer, 'price') + _.sumBy(data.rowExpensesDebit, 'price') + _.sumBy(data.rowExpensesCredit, 'price'))}
              </TableCell>
            </TableRow>
            <>
              <TableRow>
                <TableCell align="left" scope="row" sx={{ color: colors.statusInactive, fontWeight: '800' }}>
                  {t('REPORT.TABLE.REMITTANCE.CASH')}
                </TableCell>
                <TableCell align="right" scope="row" sx={{ color: colors.statusInactive, fontWeight: '800' }}>
                  {numberFormat(_.sumBy(data.rowExpensesCash, 'price') || 0)}
                </TableCell>
              </TableRow>
              {_.map(data.rowExpensesCash, (val: any, index: number) => {
                return (
                  <>
                    <TableRow>
                      <TableCell align="left" scope="row" sx={{ color: colors.statusInactive }}>
                        - {val.expenseTypeName}
                      </TableCell>
                      <TableCell align="right" scope="row" sx={{ color: colors.statusInactive }}>
                        {numberFormat(val.price)}
                      </TableCell>
                    </TableRow>
                  </>
                )
              })}
            </>

            <>
              <TableRow>
                <TableCell align="left" scope="row" sx={{ color: colors.statusInactive, fontWeight: '800' }}>
                  {t('REPORT.TABLE.REMITTANCE.TRANSFER_')}
                </TableCell>
                <TableCell align="right" scope="row" sx={{ color: colors.statusInactive, fontWeight: '800' }}>
                  {numberFormat(_.sumBy(data.rowExpensesTransfer, 'price') || 0)}
                </TableCell>
              </TableRow>
              {_.map(data.rowExpensesTransfer, (val: any, index: number) => {
                return (
                  <>
                    {val.price > 0 && (
                      <TableRow>
                        <TableCell align="left" scope="row" sx={{ color: colors.statusInactive }}>
                          - {val.expenseTypeName}
                        </TableCell>
                        <TableCell align="right" scope="row" sx={{ color: colors.statusInactive }}>
                          {numberFormat(val.price || 0)}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )
              })}
            </>

            <>
              <TableRow>
                <TableCell align="left" scope="row" sx={{ color: colors.statusInactive, fontWeight: '800' }}>
                  {t('REPORT.TABLE.REMITTANCE.DEBIT')}
                </TableCell>
                <TableCell align="right" scope="row" sx={{ color: colors.statusInactive, fontWeight: '800' }}>
                  {numberFormat(_.sumBy(data.rowExpensesDebit, 'price'))}
                </TableCell>
              </TableRow>
              {_.map(data.rowExpensesDebit, (val: any, index: number) => {
                return (
                  <>
                    {val.price > 0 && (
                      <TableRow>
                        <TableCell align="left" scope="row" sx={{ color: colors.statusInactive }}>
                          - {val.expenseTypeName}
                        </TableCell>
                        <TableCell align="right" scope="row" sx={{ color: colors.statusInactive }}>
                          {numberFormat(val.price)}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )
              })}
            </>

            <>
              <TableRow>
                <TableCell align="left" scope="row" sx={{ color: colors.statusInactive, fontWeight: '800' }}>
                  {t('REPORT.TABLE.REMITTANCE.CREDIT')}
                </TableCell>
                <TableCell align="right" scope="row" sx={{ color: colors.statusInactive, fontWeight: '800' }}>
                  {numberFormat(_.sumBy(data.rowExpensesCredit, 'price') || 0)}
                </TableCell>
              </TableRow>
              {_.map(data.rowExpensesCredit, (val: any, index: number) => {
                return (
                  <>
                    {val.price > 0 && (
                      <TableRow>
                        <TableCell align="left" scope="row" sx={{ color: colors.statusInactive }}>
                          - {val.expenseTypeName}
                        </TableCell>
                        <TableCell align="right" scope="row" sx={{ color: colors.statusInactive }}>
                          {numberFormat(val.price || 0)}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )
              })}
            </>
          </>
        )}

        {/* DF */}
        {props.df && (
          <TableRow>
            <TableCell align="left" scope="row" sx={{ color: colors.statusInactive, fontWeight: '800' }}>
              {t('REPORT.TABLE.REMITTANCE.SUM_FEE_DOCTOR')}
            </TableCell>
            <TableCell align="right" scope="row" sx={{ color: colors.statusInactive, fontWeight: '800' }}>
              {numberFormat(_.sumBy(data.rowDf, 'price') || 0)}
            </TableCell>
          </TableRow>
        )}
        {_.map(data.rowDf, (val: any, index: number) => {
          return (
            <>
              {val.price > 0 && (
                <TableRow>
                  <TableCell align="left" scope="row" sx={{ color: colors.statusInactive }}>
                    - {val.detail}
                  </TableCell>
                  <TableCell align="right" scope="row" sx={{ color: colors.statusInactive }}>
                    {numberFormat(val.price || 0)}{' '}
                    {val.total > 0 ? (
                      <span style={{ color: 'black' }}>
                        {' '}
                        ({t('REPORT.TITLE.TREATMENT')} {numberFormat(val.total)}){' '}
                      </span>
                    ) : null}
                  </TableCell>
                </TableRow>
              )}
            </>
          )
        })}

        <TableRow>
          <TableCell align="left" scope="row" sx={{ fontWeight: '800' }}>
            {t('REPORT.TABLE.REMITTANCE.TOTAL_INCOME_EXPENSES')}
          </TableCell>
          <TableCell align="right" scope="row" sx={{ fontWeight: '800' }}>
            {numberFormat(income - (expenses + df))}
          </TableCell>
        </TableRow>
      </>
    ) : (
      <>
        <>
          {data.rowDailyBalance &&
            _.map(data.rowDailyBalance, (val: any, index: number) => {
              return (
                <>
                  <TableRow>
                    <td style={{ color: colors.blue, fontWeight: '800', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.NET_DAILY')}</td>
                    <td style={{ color: colors.blue, fontWeight: '800', textAlign: 'right' }}>{numberFormat(val.sumTreatment + val.sumProduct)}</td>
                  </TableRow>
                  <TableRow>
                    <td style={{ color: colors.blue, fontWeight: '300', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.NET_OPERATIVE')}</td>
                    <td style={{ color: colors.blue, fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.sumTreatment)}</td>
                  </TableRow>
                  <TableRow>
                    <td style={{ color: colors.blue, fontWeight: '300', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.NET_PRODUCT')}</td>
                    <td style={{ color: colors.blue, fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.sumProduct)}</td>
                  </TableRow>
                  <TableRow>
                    <td style={{ color: colors.blue, fontWeight: '300', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.ANOTHER')}</td>
                    <td style={{ color: colors.blue, fontWeight: '300', textAlign: 'right' }}>{numberFormat(0)}</td>
                  </TableRow>
                </>
              )
            })}

          <TableRow>
            <td style={{ color: colors.blue, fontWeight: '800', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.CASH')}</td>
            <td style={{ color: colors.blue, fontWeight: '800', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowIncomeCash, 'treatment') || 0)}</td>
          </TableRow>
          {_.map(data.rowIncomeCash, (val: any, index: number) => {
            return (
              <>
                <TableRow>
                  <td style={{ color: colors.blue, fontWeight: '300', textAlign: 'left' }}> - {val.detail || t('REPORT.TITLE.TREATMENT_PRODUCT')}</td>
                  <td style={{ color: colors.blue, fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.treatment)}</td>
                </TableRow>
              </>
            )
          })}
          <TableRow>
            <td style={{ color: colors.blue, fontWeight: '800', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.TRANSFER_')}</td>
            <td style={{ color: colors.blue, fontWeight: '800', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowIncomeTransfer, 'treatment') || 0)}</td>
          </TableRow>
          {_.map(data.rowIncomeTransfer, (val: any, index: number) => {
            return (
              <>
                {val.treatment > 0 && (
                  <TableRow>
                    <td style={{ color: colors.blue, fontWeight: '300', textAlign: 'left' }}>- {val.detail}</td>
                    <td style={{ color: colors.blue, fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.treatment || 0)}</td>
                  </TableRow>
                )}
              </>
            )
          })}

          <TableRow>
            <td style={{ color: colors.blue, fontWeight: '800', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.DEBIT')}</td>
            <td style={{ color: colors.blue, fontWeight: '800', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowIncomeDebit, 'treatment'))}</td>
          </TableRow>
          {_.map(data.rowIncomeDebit, (val: any, index: number) => {
            return (
              <>
                {val.treatment > 0 && (
                  <TableRow>
                    <td style={{ color: colors.blue, fontWeight: '300', textAlign: 'left' }}>- {val.detail}</td>
                    <td style={{ color: colors.blue, fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.treatment)}</td>
                  </TableRow>
                )}
              </>
            )
          })}

          <TableRow>
            <td style={{ color: colors.blue, fontWeight: '800', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.CREDIT')}</td>
            <td style={{ color: colors.blue, fontWeight: '800', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowIncomeCredit, 'treatment') || 0)}</td>
          </TableRow>
          {_.map(data.rowIncomeCredit, (val: any, index: number) => {
            return (
              <>
                {val.treatment > 0 && (
                  <TableRow>
                    <td style={{ color: colors.blue, fontWeight: '300', textAlign: 'left' }}>- {val.detail}</td>
                    <td style={{ color: colors.blue, fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.treatment || 0)}</td>
                  </TableRow>
                )}
              </>
            )
          })}

          <TableRow>
            <td style={{ color: colors.blue, fontWeight: '800', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.ADVANCE')}</td>
            <td style={{ color: colors.blue, fontWeight: '800', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowAdvance, 'price') || 0)}</td>
          </TableRow>

          <TableRow>
            <td style={{ color: colors.blue, fontWeight: '800', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.TREATMENT_RIGHT')}</td>
            <td style={{ color: colors.blue, fontWeight: '800', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowTreatmentRight, 'price') || 0)}</td>
          </TableRow>
          {_.map(data.rowTreatmentRight, (val: any, index: number) => {
            return (
              <>
                <TableRow>
                  <td style={{ color: colors.blue, fontWeight: '300', textAlign: 'left' }}>- {val.treatmentRightName}</td>
                  <td style={{ color: colors.blue, fontWeight: '800', textAlign: 'right' }}>{numberFormat(val.price || 0)}</td>
                </TableRow>
              </>
            )
          })}

          {/*  Expenses */}
          {props.expenses && (
            <>
              <TableRow>
                <td style={{ color: colors.statusInactive, fontWeight: '800', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.TOTAL_EXPENSES')}</td>
                <td style={{ color: colors.statusInactive, fontWeight: '800', textAlign: 'right' }}>
                  {numberFormat(_.sumBy(data.rowExpensesCash, 'price') + _.sumBy(data.rowExpensesTransfer, 'price') + _.sumBy(data.rowExpensesDebit, 'price') + _.sumBy(data.rowExpensesCredit, 'price'))}
                </td>
              </TableRow>
              <>
                <TableRow>
                  <td style={{ color: colors.statusInactive, fontWeight: '800', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.CASH')}</td>
                  <td style={{ color: colors.statusInactive, fontWeight: '800', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowExpensesCash, 'price') || 0)}</td>
                </TableRow>
                {_.map(data.rowExpensesCash, (val: any, index: number) => {
                  return (
                    <>
                      <TableRow>
                        <td style={{ color: colors.statusInactive, fontWeight: '300', textAlign: 'left' }}>- {val.expenseTypeName}</td>
                        <td style={{ color: colors.statusInactive, fontWeight: '800', textAlign: 'right' }}>{numberFormat(val.price)}</td>
                      </TableRow>
                    </>
                  )
                })}
              </>

              <>
                <TableRow>
                  <td style={{ color: colors.statusInactive, fontWeight: '800', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.TRANSFER_')}</td>
                  <td style={{ color: colors.statusInactive, fontWeight: '800', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowExpensesTransfer, 'price') || 0)}</td>
                </TableRow>
                {_.map(data.rowExpensesTransfer, (val: any, index: number) => {
                  return (
                    <>
                      {val.price > 0 && (
                        <TableRow>
                          <td style={{ color: colors.statusInactive, fontWeight: '300', textAlign: 'left' }}>- {val.expenseTypeName}</td>
                          <td style={{ color: colors.statusInactive, fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.price || 0)}</td>
                        </TableRow>
                      )}
                    </>
                  )
                })}
              </>

              <>
                <TableRow>
                  <td style={{ color: colors.statusInactive, fontWeight: '800', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.DEBIT')}</td>
                  <td style={{ color: colors.statusInactive, fontWeight: '800', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowExpensesDebit, 'price'))}</td>
                </TableRow>
                {_.map(data.rowExpensesDebit, (val: any, index: number) => {
                  return (
                    <>
                      {val.price > 0 && (
                        <TableRow>
                          <td style={{ color: colors.statusInactive, fontWeight: '300', textAlign: 'left' }}>- {val.expenseTypeName}</td>
                          <td style={{ color: colors.statusInactive, fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.price)}</td>
                        </TableRow>
                      )}
                    </>
                  )
                })}
              </>

              <>
                <TableRow>
                  <td style={{ color: colors.statusInactive, fontWeight: '800', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.CREDIT')}</td>
                  <td style={{ color: colors.statusInactive, fontWeight: '800', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowExpensesCredit, 'price') || 0)}</td>
                </TableRow>
                {_.map(data.rowExpensesCredit, (val: any, index: number) => {
                  return (
                    <>
                      {val.price > 0 && (
                        <TableRow>
                          <td style={{ color: colors.statusInactive, fontWeight: '300', textAlign: 'left' }}>- {val.expenseTypeName}</td>
                          <td style={{ color: colors.statusInactive, fontWeight: '300', textAlign: 'right' }}>{numberFormat(val.price || 0)}</td>
                        </TableRow>
                      )}
                    </>
                  )
                })}
              </>
            </>
          )}
        </>

        {/* DF */}

        {props.df && (
          <TableRow>
            <td style={{ color: colors.statusInactive, fontWeight: '800', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.SUM_FEE_DOCTOR')}</td>
            <td style={{ color: colors.statusInactive, fontWeight: '800', textAlign: 'right' }}>{numberFormat(_.sumBy(data.rowDf, 'price') || 0)}</td>
          </TableRow>
        )}
        {_.map(data.rowDf, (val: any, index: number) => {
          return (
            <>
              {val.price > 0 && (
                <TableRow>
                  <td style={{ color: colors.statusInactive, fontWeight: '300', textAlign: 'left' }}>- {val.detail}</td>
                  <td style={{ color: colors.statusInactive, fontWeight: '300', textAlign: 'right' }}>
                    {numberFormat(val.price || 0)} {val.total > 0 ? <span style={{ color: 'black' }}> (ค่ารักษา {numberFormat(val.total)}) </span> : null}
                  </td>
                </TableRow>
              )}
            </>
          )
        })}

        <TableRow>
          <td style={{ fontWeight: '800', textAlign: 'left' }}>{t('REPORT.TABLE.REMITTANCE.TOTAL_INCOME_EXPENSES')}</td>
          <td style={{ fontWeight: '800', textAlign: 'right' }}>{numberFormat(income - (expenses + df))}</td>
        </TableRow>
      </>
    )
  }

  return (
    <>
      <UseStyled.ResponsiveReportTable>
        <TableCustom
          page={props.page}
          pageLimit={props.pageLimit}
          sortType={props.sortType}
          sortBy={props.sortBy}
          rowCount={props.rowCount}
          onSort={props.onRequestSort}
          setPageLimit={props.handleChangeRowsPerPage}
          setPage={props.handleChangePage}
          headCells={headCells}
          rowsData={[renderData(props.data, false)]}
          hidePagination={true}
          customScroll
        />
      </UseStyled.ResponsiveReportTable>
      <div className="print-show" ref={props.componentRef}>
        <RemittanceExport
          filter={props.filter}
          header={
            <TableHead style={{ fontWeight: 800 }}>
              {t('REPORT.REPORT_NAME.REMITTANCE')}
              {header ? ` ( ${header} )` : ''}
            </TableHead>
          }
          headCells={headCells}
          rowsData={[renderData(props.data, true)]}
        />
      </div>
    </>
  )
}

export function RemittanceExport(props: ExportProps) {
  const { t } = useTranslation()
  const head = props.headCells
  const { header, filter } = props
  const headCells = head.filter((headCell: any) => headCell.id !== 'action')
  const FILTER_TITLES = [
    { key: 'BRANCH', label: 'REPORT.FILTER.BRANCH' },
    { key: 'DATE', label: 'REPORT.FILTER.DATE' }
  ]

  const renderValue = (value: any, index: any) => {
    if (index === 1) {
      return toBuddhistYear(moment(value), 'DD/MM/YYYY')
    }
    return value
  }

  return (
    <>
      {header}
      <table>
        <tr>
          {FILTER_TITLES.map((filter, index) => (
            <TableSortLabel key={index}>
              <td className="mr-2" style={{ fontSize: 14, fontWeight: 800 }}>
                {t(filter.label)} :{' '}
              </td>
              <td style={{ fontSize: 14, fontWeight: 500 }}>{renderValue(props.filter[index], index)}</td>
            </TableSortLabel>
          ))}
        </tr>
      </table>
      <UseStyled.Styles className="pt-3">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            <tr>
              {_.map(headCells, (val: any, index: number) => {
                return <td style={{ textAlign: val.align, fontSize: 14, fontWeight: 800 }}> {t(val.labelKey)}</td>
              })}
            </tr>
          </thead>
          <tbody>
            {props.rowsData}
            {!props.rowsData.length && <td colSpan={headCells.length}>{t('REPORT.TITLE.NO_DATA')}</td>}
          </tbody>
        </table>
      </UseStyled.Styles>
    </>
  )
}
