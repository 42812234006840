import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
import { TRUE_FALSE } from 'constants/status'
/** UTIILS */

export interface AccessChannelInterface {
  channelName: string
  isOther: TRUE_FALSE
  status: string
}

export default class AccessChannelApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('access-channels', { params: { ...props } }).then((res) => res)
  }

  static create(channelName: string, isOther: TRUE_FALSE) {
    return this.api.post('access-channels', { channelName, isOther })
  }

  static findById(id: number): Promise<AccessChannelInterface> {
    return this.api.get(`access-channels/${id}`)
  }

  static update(id: number, body: AccessChannelInterface) {
    return this.api.patch(`access-channels/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`access-channels/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`access-channels/${id}`)
  }
}
