import styleds from 'styled-components'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
const Styles = styleds.div`
padding: 1px;

table {
  border-spacing: 0;
  // border: 0px solid black;

  // tr {
  //   :last-child {
  //     td {
  //       border-bottom: 0;
  //     }
  //   }
  // }

  th,
  td {
    margin: 0;
    padding: 1px;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    text-align: center;
    :last-child {
      border-right: 0;
    }
  }
} 
`
type Data = {
  headCells?: any
  rowsData?: any
  filter?: any
  filterReport?: any
  headCellsSeconds?: any
  rowsDataSeconds?: any
}

export default function ReportPdfCustom(props: Data) {
  const { t } = useTranslation()
  const head = props.headCells
  const headSeconds = props.headCellsSeconds
  const headCells = head.filter((headCell: any) => headCell.id !== 'action' && headCell.id !== 'COMPONENT')
  const headCellsSeconds = headSeconds ? headSeconds.filter((headSecondCell: any) => headSecondCell.id !== 'action' && headSecondCell.id !== 'COMPONENT') : ''
  const filter = props.filterReport
  const filters = filter ? filter.filter((val: any) => val.config !== false) : ''

  const tableAlign = (id: string) => {
    if (id === 'updatedBy' || id === 'action' || id === 'status' || id === 'no' || false ? 'center' : id === 'price') return 'right'
    else return 'left'
  }

  return (
    <>
      <div className='d-flex pr-2'>
        {props.filter &&
          _.map(props.filter, (val: any, index: number) => {
            return (
              <div className='pr-2' style={{ fontSize: 10 }}>
                {val.label}
              </div>
            )
          })
        }

        {filters && _.map(filters, (val: any, index: number) => {
          return (
            <div className='pr-2' style={{ fontSize: 10 }}>
              {val.label}
            </div>
          )
        })}
      </div>
      <Styles className="pt-3">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            {_.map(headCells, (val: any, index: number) => {
              return (
                <th
                  style={{
                    textAlign: val.align ? val.align : tableAlign(val.id),
                    fontSize: 10,
                    fontWeight: 500
                  }}>
                  {val.label || t(val.labelKey)}
                </th>
              )
            })}
          </thead>
          <tbody>
            {props.rowsData}
            {!props.rowsData.length && (
              <td colSpan={headCells.length}>
                {t('NOT_FOUND')}
              </td>
            )}
          </tbody>
        </table>
      </Styles >
      <br></br>
      {headCellsSeconds &&
        <Styles className="pt-3">
          <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
            <thead>
              {_.map(headCellsSeconds, (val: any, index: number) => {
                return (
                  <th
                    style={{
                      textAlign: val.align ? val.align : tableAlign(val.id),
                      fontSize: 10,
                      fontWeight: 500
                    }}>
                    {val.label || t(val.labelKey)}
                  </th>
                )
              })}
            </thead>
            <tbody>
              {props.rowsDataSeconds}
              {!props.rowsDataSeconds.length && (
                <td colSpan={headCellsSeconds.length}>
                  {t('NOT_FOUND')}
                </td>
              )}
            </tbody>
          </table>
        </Styles >}
    </>

  )
}

