import { Box, styled } from '@mui/material'
import ButtonCustom from 'component/Button/ButtonCustom'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectMe, selectUser } from 'app/slice/user.slice'
import { resetModal } from 'app/slice/modal.slice'
import './style.css'
import InputTextField from 'component/Input/InputTextField'
import { InitStatePasswordErrorMessageType, initStatePasswordErrorMessage } from '../form-personnel'
import PersonnelApi, { ChangePassword } from 'api/setting/personnel-managements/personnel.api'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import InputPasswordField from 'component/Input/InputPasswordField'

type PopupEditAuthProps = {
  type: 'username' | 'password'
  userId: number
  onClose?: () => void
  onReLoad?: ()=>void
}

const PopupEditAuth = (props: PopupEditAuthProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const user = useSelector(selectUser)
  const [inputNew, setInputNew] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')

  const handleErrorPassword = () => {
    const tempMessage = t('PERSONNEL.MESSAGE.LIMIT_PACKAGE.MESSAGE_PASSWORD')

    if (inputNew.length < 8) return tempMessage
    else if (inputNew.toLowerCase() === inputNew) return tempMessage
    else if (inputNew.toUpperCase() === inputNew) return tempMessage
    else if (!/\d/.test(inputNew)) return tempMessage
    else if (/[ก-๙]/.test(inputNew)) return tempMessage
    else if (user?.setup?.username && inputNew && inputNew.search(user?.setup?.username) !== -1) return tempMessage
  }

  const onSubmitPassword = () => {
    const message = handleErrorPassword()

    if (message) return setErrorMessage(message)

    const userId = props.userId
    const payload: ChangePassword = {
      password: inputNew,
      confirmPassword: inputNew
    }

    PersonnelApi.changePassword(userId, payload)
      .then(() => {
        onClosePop()
        notiSuccess(t('PERSONNEL.ALERT.PASSWORD_CHANGE_SUCCESSFUL'))
        if(props?.onReLoad) props.onReLoad()
      })
      .catch(() => {
        return
      })
  }
  // This username is already in use.
  const onSubmitUsername = () => {
    const userId = props.userId
    PersonnelApi.changeUsername(userId, inputNew)
    .then(() => {
      onClosePop()
      notiSuccess(t('PERSONNEL.ALERT.USERNAME_CHANGE_SUCCESSFUL'))
      if(props?.onReLoad) props.onReLoad()
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message
      if (errorMessage) {
        notiError(t(`PERSONNEL.ALERT.${errorMessage}`))
      }else{
        notiError(t('PERSONNEL.ALERT.ERROR'))
      }
      return
    })

  }

  const onSubmitAuth = async () => {
    if (!inputNew || inputNew === '') {
      return setErrorMessage(t(`PERSONNEL.MODAL.EMPTY_${props.type.toUpperCase()}`))
    }

    if (props.type === 'password') {
      onSubmitPassword()
    } else {
      onSubmitUsername()
    }
  }

  const onClosePop = () => {
    dispatch(resetModal())
    props?.onClose && props.onClose()
  }

  return (
    <ModalCustom
      modalStyle={'pop-edit-auth'}
      size="sm"
      title={t(`PERSONNEL.MODAL.EDIT_${props.type.toUpperCase()}`)}
      component={
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: '8px' }}>
          <Box>
            <Box sx={{ fontSize: '14px', fontWeight: '400', color: '#5A5A5A' }}>{`${t('PERSONNEL.MODAL.EMPLOYEE_ID')}: ${user?.setup?.userCode || '-'}`}</Box>
          </Box>
          <Box>
            <Box sx={{ fontSize: '14px', fontWeight: '400', color: '#5A5A5A' }}>{`${t('PERSONNEL.MODAL.USERNAME')}: ${user?.setup?.username || '-'}`}</Box>
          </Box>

          <Box sx={{ margin: '8px 0' }}>
            {props.type === 'username' ? (
              <InputTextField
                label={t(`PERSONNEL.MODAL.NEW_${props.type.toUpperCase()}`)}
                value={inputNew}
                onchange={(event) => {
                  setErrorMessage('')
                  setInputNew(event.target.value)
                }}
                helperText={errorMessage || ''}
              />
            ) : (
              <InputPasswordField
                onchange={(event) => {
                  setInputNew(event.target.value)
                }}
                value={inputNew}
                label={t(`PERSONNEL.MODAL.NEW_${props.type.toUpperCase()}`)}
                helperText={errorMessage || ''}
              />
            )}
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '8px' }}>
            <Box sx={{ width: '50%' }}>
              <ButtonCustom style={{ width: '100%' }} variant={'outlined'} textButton={t('PERSONNEL.MODAL.CANCEL')} onClick={() => onClosePop()} />
            </Box>
            <Box sx={{ width: '50%' }}>
              <ButtonCustom style={{ width: '100%' }} textButton={t('PERSONNEL.MODAL.SUBMIT')} onClick={() => onSubmitAuth()} />
            </Box>
          </Box>
        </Box>
      }
    />
  )
}

export default PopupEditAuth
