import { TableCell, TableRow, makeStyles } from '@material-ui/core'
import { dateTimeToView } from 'utils/date.utils';
import { icons } from 'constants/images'
import { colors } from 'constants/theme';

type SalaryTableRowProps = {
  key: string
  id: string
  obj: any
  columns: any
  onView?: (val: any) => void
  onedit?: (val: any) => void
  ondelete?: (val: any) => void
  noButton?: boolean
}

const useStyles = makeStyles((theme) => ({
  tableButton: {
    backgroundColor: '',
    color: `${colors.textPrimary} !important`,
    '&:hover': {
      backgroundColor: `transparent !important`,
      color: `${colors.themeSecondColor} !important`
    }
  },
  hide: {
    visibility: 'hidden'
  },
  show: {
    visibility: 'visible',
    cursor: 'text',
    color: colors.textPrimary
  },
  columeHover: {
    marginTop: '100px'
  }
}))

export default function SalaryTableRow(props: SalaryTableRowProps) {
  const classes = useStyles()

  return (
    <TableRow key={props.key} style={{ height: 'auto' }} hover={true} className={classes.columeHover}>
      {props.columns.map((column: any) => {
        return (
          (column.option === 'TEXT' && (
            <TableCell align={column.align || 'left'} scope="row" size="small">
              <p className={props.noButton ? classes.show : ''} style={{ margin: '0', width: '100%', fontSize: '16px' }}>
                {column.label}
              </p>
            </TableCell>
          )) ||
          (column.option === 'UPDATE_BY' && (
            <TableCell align={column.align || 'left'} className="update-by py-2" size="small">
              {column.label.updatedBy}
              <br />
              <span>{dateTimeToView(column.label.updatedAt, false)}</span>
            </TableCell>
          )) ||
          (column.option === 'ACTION' && (
            <TableCell style={{ width: 160 }} align={column.align || 'left'} size="small">
              <img src={icons.iconEdit} alt="iconEdit" onClick={props.onedit} style={{ cursor: 'pointer', marginRight: '10px' }} />
              <img src={icons.iconFaTrash} alt="iconFaTrash" onClick={props.ondelete} style={{ cursor: 'pointer', marginRight: '10px' }} />
            </TableCell>
          )) ||
          (column.option === 'COMPONENT' && (
            <TableCell style={{ width: 160 }} align={column.align || 'left'} size="small">
              {column.component}
            </TableCell>
          ))
        )
      })}
    </TableRow>
  )
}
