import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface ProductsInterface {
  group: string
  productCode: string
  productName: string
  productNameEn: string
  productTypeId: number | null
  productFormatId: number | null
  productUnitId: number | null
  productInstructionId: number | null
  productPreserveId: number | null
  productUseId: number | null
  strength: string
  salePrice: number
  costPrice: number
  minStock: number
  explanation: string
  showOnReceipt: string
  status?: string
  files: any
  image: any
  amounDayBeforeExpired?: number | null
}

export default class ProductsApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('products', { params: { ...props } }).then((res) => res)
  }
  static findByType(props: any): Promise<any> {
    return this.api.get('products', { params: { ...props } }).then((res) => res)
  }
  static create(payload: any) {
    return this.api.post('products', payload)
  }
  static findById(id: number): Promise<ProductsInterface> {
    return this.api.get(`products/${id}`)
  }
  static update(id: number, body: ProductsInterface) {
    return this.api.patch(`products/${id}`, body)
  }
  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`products/${id}/status`, { id, status })
  }
  static remove(id: number) {
    return this.api.delete(`products/${id}`)
  }
}
