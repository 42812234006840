import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/setting/treatment-courses/treatment-course-types/style'

/** API */
import TreatmentCourseTypeApi from 'api/setting/treatment-courses/treatment-course-type.api'

/** UTILS || SLICE */
import { submitModal, unSubmitModal, showModal, resetModal } from 'app/slice/modal.slice'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextField from 'component/Input/InputTextField'
import InputTextSearch from 'component/Input/InputTextSearch'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import FilterSelect from 'component/Select/FilterSelect'
import TableRowExport from 'component/Pdf/TableRowExport'
import ButtonExport from 'component/Button/ButtonExport'

const initStateErrorMessage = {
  TREATMENT_COURSE_TYPE_NAME: ``,
  IS_DUPLICATE_TREATMENT_COURSE_TYPE_NAME: ``,
  TREATMENT_COURSE_TYPE_NAME_STRING_BASE: ``,
  TREATMENT_COURSE_TYPE_NAME_STRING_EMPTY: ``,
  TREATMENT_COURSE_TYPE_NAME_MAXIMUM_LENGTH: ``,
  TREATMENT_COURSE_TYPE_NAME_ANY_REQUIRED: ``,

  TREATMENT_COURSE_TYPE_NAME_EN: ``,
  IS_DUPLICATE_TREATMENT_COURSE_TYPE_NAME_EN: ``,
  TREATMENT_COURSE_TYPE_NAME_EN_STRING_BASE: ``,
  TREATMENT_COURSE_TYPE_NAME_EN_STRING_EMPTY: ``,
  TREATMENT_COURSE_TYPE_NAME_EN_MAXIMUM_LENGTH: ``,
  TREATMENT_COURSE_TYPE_NAME_EN_ANY_REQUIRED: ``
}
export default function TreatmentCourseTypes() {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [treatmentCourseTypes, setTreatmentCourseTypes] = useState([])
  const [exportData, setExportData] = useState([])

  const [treatmentCourseTypeId, setTreatmentCourseTypeId] = useState(Number)
  const [treatmentCourseTypeName, setTreatmentCourseTypeName] = useState('')
  const [treatmentCourseTypeNameEn, setTreatmentCourseTypeNameEn] = useState('')
  const [treatmentCourseTypeStatus, setTreatmentCourseTypeStatus] = useState('')

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('ALL')

  const loadData = useCallback(async () => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await TreatmentCourseTypeApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setTreatmentCourseTypes(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])

  useEffect(() => {
    loadData()
  }, [loadData])

  const loadDataForExport = useCallback(async () => {
    let condition: any = {}
    condition.export = 1
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await TreatmentCourseTypeApi.findAll(condition)
    if (res.status === 200) {
      setExportData(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])

  useEffect(() => {
    loadDataForExport()
  }, [loadDataForExport])

  const submit = () => {
    if (!treatmentCourseTypeName) return setErrorMessage({ ...errorMessage, ...{ TREATMENT_COURSE_TYPE_NAME: t('TREATMENT_COURSE_TYPE.MESSAGE.TREATMENT_COURSE_TYPE_NAME') } })

    dispatch(submitModal())
    TreatmentCourseTypeApi.create({ treatmentCourseTypeName, treatmentCourseTypeNameEn })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t('TREATMENT_COURSE_TYPE.MESSAGE.SUCCESS.CREATE'), '', null, null)
          resetForm()

          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TREATMENT_COURSE_TYPE.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TREATMENT_COURSE_TYPE.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const submitEdit = () => {
    if (!treatmentCourseTypeName) return setErrorMessage({ ...errorMessage, ...{ TREATMENT_COURSE_TYPE_NAME: t(`TREATMENT_COURSE_TYPE.MESSAGE.TREATMENT_COURSE_TYPE_NAME`) } })

    dispatch(submitModal())
    TreatmentCourseTypeApi.update(treatmentCourseTypeId, {
      treatmentCourseTypeName,
      treatmentCourseTypeNameEn,
      status: treatmentCourseTypeStatus
    })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t(`TREATMENT_COURSE_TYPE.MESSAGE.SUCCESS.UPDATE`), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TREATMENT_COURSE_TYPE.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TREATMENT_COURSE_TYPE.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  const resetForm = () => {
    setTreatmentCourseTypeId(0)
    setTreatmentCourseTypeName('')
    setTreatmentCourseTypeNameEn('')
    setTreatmentCourseTypeStatus('')
    clearErrorMessage()
  }

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('TREATMENT_COURSE_TYPE.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          TreatmentCourseTypeApi.updateStatus(id, valueStatus)
            .then((resp) => {
              notiSuccess(t('TREATMENT_COURSE_TYPE.MESSAGE.SUCCESS.UPDATE_STATUS'))
              loadData()
            })
            .catch((e) => {
              notiError(t('TREATMENT_COURSE_TYPE.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }
  const onDelete = (data: any) => {
    const { treatmentCourseTypeId: txTypeId, treatmentCourseTypeName: txTypeName, treatmentCourseTypeNameEn: txTypeNameEn } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('TREATMENT_COURSE_TYPE.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('TREATMENT_COURSE_TYPE.ALERT.CONFIRM_DELETE_', {
        treatmentCourseTypeName: txTypeName,
        treatmentCourseTypeNameEn: txTypeNameEn ? ' (' + txTypeNameEn + ')' : ''
      })}</p>`,
      (res: any) => {
        if (res) {
          TreatmentCourseTypeApi.remove(txTypeId)
            .then((resp) => {
              notiSuccess(t('TREATMENT_COURSE_TYPE.MESSAGE.SUCCESS.DELETE'))
              loadData()
            })
            .catch((e) => {
              notiError(t('TREATMENT_COURSE_TYPE.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
    )
  }

  const onEdit = (data: any) => {
    const { treatmentCourseTypeId: txTypeId, treatmentCourseTypeName: txTypeName, treatmentCourseTypeNameEn: txTypeNameEn, status: txTypeStatus } = data
    setTreatmentCourseTypeId(txTypeId)
    setTreatmentCourseTypeName(txTypeName)
    setTreatmentCourseTypeNameEn(txTypeNameEn)
    setTreatmentCourseTypeStatus(txTypeStatus)
    dispatch(showModal())
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }
  const handleChangeStatus = (statusVal: string) => {
    setStatus(statusVal)
  }
  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'treatmentCourseTypeName', disablePadding: false, label: t('TREATMENT_COURSE_TYPE.TABLE.CELL.TREATMENT_COURSE_TYPE_NAME') },
    { id: 'treatmentCourseTypeNameEn', disablePadding: false, label: t('TREATMENT_COURSE_TYPE.TABLE.CELL.TREATMENT_COURSE_TYPE_NAME_EN') },
    { id: 'status', disablePadding: false, label: t('TREATMENT_COURSE_TYPE.TABLE.CELL.STATUS') },
    { id: 'updatedBy', disablePadding: false, label: t('TREATMENT_COURSE_TYPE.TABLE.CELL.UPDATED_BY') },
    { id: 'action', disablePadding: false, label: 'Action' }
  ]

  const renderData = (objData: any, no: number, valueExportData: boolean) => {
    const num = valueExportData === true ? no + 1 : page * pageLimit - pageLimit + no + 1
    const { treatmentCourseTypeId: txTypeId, treatmentCourseTypeName: txTypeName, treatmentCourseTypeNameEn: txTypeNameEn, updatedBy, updatedAt, status: txTypeStatus } = objData
    const statusBtnActive = txTypeStatus === 'ACTIVE' ? true : false
    const statusBtnInActive = txTypeStatus === 'INACTIVE' ? true : false
    const objRenderData = {
      key: txTypeId,
      id: txTypeId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: num },
        { option: 'TEXT', align: 'left', label: txTypeName || '-' },
        { option: 'TEXT', align: 'left', label: txTypeNameEn || '-' },
        { option: 'STATUS', align: 'center', label: txTypeStatus },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: false },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: false }
          ]
        }
      ]
    }
    return (
      <>
        {valueExportData === true && <TableRowExport {...objRenderData} /> ||
          <TableRowCommon
            {...objRenderData}
            onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
            oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
            onedit={() => onEdit(objRenderData.obj)}
            ondelete={() => onDelete(objRenderData.obj)}
          />
        }
      </>
    )
  }
  const filter = [
    { label: `${t('INPUT.SEARCH')}: ${search || '-'}` },
    { label: `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}` }
  ]

  return (
    <div className={classes.root}>
      <Card className="border-0 h-100">
        <HeaderCard text={t('TREATMENT_COURSE_TYPE.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="align-items-center">
              <Col md={8}>
                <Row className="flax-row">
                  <Col sm={12} md={6} xl={4} className="pt-2">
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('INPUT.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={4} className="pl-md-0 pt-2">
                    <FilterSelect
                      onchange={(event) => {
                        const value = event.target.value
                        if (value) {
                          handleChangeStatus(value)
                          setPage(1)
                        }
                      }}
                      renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                      label={t('STATUS.LABEL')}
                      selectId="select-status"
                      value={status}
                      labelId="label-status"
                      options={[
                        <MenuItem key="1" value="ALL">
                          {t('STATUS.ALL')}
                        </MenuItem>,
                        <MenuItem key="2" value="ACTIVE">
                          {t('STATUS.ACTIVE')}
                        </MenuItem>,
                        <MenuItem key="3" value="INACTIVE">
                          {t('STATUS.INACTIVE')}
                        </MenuItem>
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mt-2 mt-md-auto">
                <div className="pr-xl-2 d-flex">
                  <div className='ml-auto'>
                    <ButtonExport headCells={headCells} portrait={true} filter={filter} fileName={t('TREATMENT_COURSE_TYPE.BUTTON.TREATMENT_COURSE_TYPE')}
                      rowsData={exportData.map((val, i) => {
                        return renderData(val, i, true)
                      })} />
                  </div>
                  <div className='pl-3'>
                    <ButtonCustom mode="add" onClick={() => dispatch(showModal())} textButton={t('TREATMENT_COURSE_TYPE.BUTTON.ADD')} className="w-auto ml-auto d-flex mt-auto" btnStyle={{ height: '44px' }} />
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Title>

          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={treatmentCourseTypes.map((val, i) => {
                return renderData(val, i, false)
              })}
            />
          </div>
        </Card.Body>
      </Card>

      <ModalCustom
        title={treatmentCourseTypeId ? t('TREATMENT_COURSE_TYPE.TITLE_UPDATE') : t('TREATMENT_COURSE_TYPE.TITLE_CREATE')}
        component={
          <div className="pb-2">
            {treatmentCourseTypeId ? (
              <div>
                <Form className="mx-0 mt-0 mb-3 p-0  d-inline-flex w-100">
                  <Form.Label className=" mr-auto">{t('INPUT.LABEL_STATUS')}</Form.Label>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    onChange={() => setTreatmentCourseTypeStatus(treatmentCourseTypeStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')}
                    checked={treatmentCourseTypeStatus === 'ACTIVE' ? true : false}
                    style={{ marginLeft: 'auto' }}
                  />
                </Form>
              </div>
            ) : (
              ''
            )}

            <InputTextField
              onchange={(event) => {
                setTreatmentCourseTypeName(event.target.value)
                clearErrorMessage()
              }}
              value={treatmentCourseTypeName}
              label={t('TREATMENT_COURSE_TYPE.INPUT.TREATMENT_COURSE_TYPE_NAME')}
              helperText={
                errorMessage.TREATMENT_COURSE_TYPE_NAME ||
                errorMessage.IS_DUPLICATE_TREATMENT_COURSE_TYPE_NAME ||
                errorMessage.TREATMENT_COURSE_TYPE_NAME_STRING_BASE ||
                errorMessage.TREATMENT_COURSE_TYPE_NAME_STRING_EMPTY ||
                errorMessage.TREATMENT_COURSE_TYPE_NAME_MAXIMUM_LENGTH ||
                errorMessage.TREATMENT_COURSE_TYPE_NAME_ANY_REQUIRED
              }
              required={true}
              style={{ marginBottom: '1rem' }}
            />
            <InputTextField
              key="treatmentCourseTypeNameEn"
              onchange={(event) => {
                setTreatmentCourseTypeNameEn(event.target.value)
                clearErrorMessage()
              }}
              value={treatmentCourseTypeNameEn}
              helperText={
                errorMessage.TREATMENT_COURSE_TYPE_NAME_EN ||
                errorMessage.IS_DUPLICATE_TREATMENT_COURSE_TYPE_NAME_EN ||
                errorMessage.TREATMENT_COURSE_TYPE_NAME_EN_STRING_BASE ||
                errorMessage.TREATMENT_COURSE_TYPE_NAME_EN_STRING_EMPTY ||
                errorMessage.TREATMENT_COURSE_TYPE_NAME_EN_MAXIMUM_LENGTH ||
                errorMessage.TREATMENT_COURSE_TYPE_NAME_EN_ANY_REQUIRED
              }
              label={t('TREATMENT_COURSE_TYPE.INPUT.TREATMENT_COURSE_TYPE_NAME_EN')}
              required={false}
            />
          </div>
        }
        onReset={resetForm}
        onSubmit={treatmentCourseTypeId ? submitEdit : submit}
        textBtnCancel={t('TREATMENT_COURSE_TYPE.BUTTON.CANCEL')}
        textBtnConfirm={t('TREATMENT_COURSE_TYPE.BUTTON.SAVE')}
      />
    </div>
  )
}
