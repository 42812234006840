import { makeStyles } from '@material-ui/core'
import { colors } from 'constants/theme'
import { styled } from '@mui/material/styles'
import { Box, Button } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.borderInput
    }
  },
  header: {
    backgroundColor: colors.themeMainColor,
    color: `${colors.white} !important`,
    '& $th': {
      backgroundColor: colors.themeMainColor,
      color: `${colors.white} !important`,
      padding: 15
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: `${colors.white} !important`
    },
    '& .MuiTableCell-root.MuiTableCell-head': {
      color: `${colors.white} !important`,
      fontSize: 16
    },

    '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: `${colors.white} !important`
    }
  },
  body: {
    borderRight: `1px solid ${colors.black20} !important`,
    borderLeft: `1px solid ${colors.black20} !important`
  },
  titleFirst: {
    fontSize: '18px',
    fontWeight: 600
  },
  titleSecond: {
    fontSize: '16px'
  },
  columeHover: {
    backgroundColor: '',
    '&:hover': {
      backgroundColor: `${colors.lightGray} !important`
    }
  },
  inputDisable: {
    backgroundColor: colors.themeThirdColor
  },
  btnSidebar: {
    padding: '3px !important',
    width: 'fit-contant',
    border: `solid 1px ${colors.themeSecondColor}  !important`,
    borderRadius: '10px 0px 0px 10px !important'
  },
  btnDiscard: {
    padding: '3px !important',
    width: 'fit-contant',
    backgroundColor: `${colors.white}  `,
    border: `solid 1px ${colors.themeSecondColor}  !important`
  }
}))

export const TypeTabs = styled(Box)(({ theme }) => ({
  margin: '0 -0.25rem',
  maxHeight: 76,
  overflowY: 'auto',
  '> .MuiBox-root': {
    width: '8.33%',
    [theme.breakpoints.down('xl')]: {
      width: '10%'
    },
    [theme.breakpoints.down('lg')]: {
      width: '16.66%'
    },
    [theme.breakpoints.down('md')]: {
      width: '20%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '33.33%'
    }
  }
}))

export const ButtonType = styled(Button)(({ theme }) => ({
  width: '100%',
  height: 30,
  color: colors.textPrimary,
  borderColor: `${colors.themeSecondColor} !important`,
  '&.active': {
    backgroundColor: colors.themeSecondColor,
    color: colors.white
  }
}))

export default useStyles
