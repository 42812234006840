import { useState, useEffect, useRef, ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Tab, Typography, Button } from '@mui/material'
import CanvasDraw from 'react-canvas-draw'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import TreatmentsAPI, { SignatureAttachmentsProps } from 'api/dentists/treatment.api'
import TreatmentSummaryApi from 'api/dentists/treatment-summary.api'
import { fileUrl } from 'api/api'

/** UTILS || SLICE */
import { resetModal } from 'app/slice/modal.slice'
import { numberFormat, renderAge } from 'utils/app.utils'
import { ToastContainer } from 'react-toastify'
import { setTreatmentByKey } from 'app/slice/treatment.slice'

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'
import { notiError, notiPopup } from 'component/notifications/notifications'
import ButtonCustom from 'component/Button/ButtonCustom'
import Loading from 'component/Loading'

/** STYLE */
import * as UseStyled from 'component/Treatments/style/popupTreatmentConsentUseStyled'
import { colors } from 'constants/theme'
import { onImgError } from 'constants/images'
import { dateToView } from 'utils/date.utils'

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type PopupTreatmentConsentProps = {
  type: 'treatment' | 'historyTreatment'
  patientCaseId: number
  servicePointId: number
  setIsShow: (show: boolean) => void
  modal?: boolean
}

export default function PopupTreatmentConsent(props: PopupTreatmentConsentProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [doctorTab, setDoctorTab] = useState(0);
  const [treatments, setTreatments]: any = useState([]);
  const [treatment, setTreatment]: any = useState([]);
  const [loading, setLoading] = useState(true);
  const [pdfLoading, setPdfLoading] = useState(false);

  const canvasDoctorRef: any = useRef()
  const canvasPatientRef: any = useRef()
  const defaultSignature: SignatureAttachmentsProps = {
    doctorId: 0,
    servicePointId: props.servicePointId,
    doctorSignature: '',
    patientSignature: ''
  }
  const [signature, setSignature] = useState<SignatureAttachmentsProps>(defaultSignature);

  const loadData = async (patientCaseId: number) => {
    const resp = await TreatmentsAPI.findInvoice(patientCaseId).finally(() => setLoading(false))

    if (resp.status === 200) {
      const invoices = props.type === 'historyTreatment' ?
        _.filter(resp.data, (data) => {
          if (data.invoice) return data
        }) :
        _.filter(resp.data, (data) => {
          if (!_.isEmpty(data.summeryTreatments)) return data
        })

      setTreatments(invoices)
      setTreatment(invoices[doctorTab])
      setSignature({
        ...signature,
        doctorId: invoices[doctorTab]?.doctorId,
        doctorSignature: invoices[doctorTab]?.invoice?.doctorSignature,
        patientSignature: invoices[doctorTab]?.invoice?.patientSignature
      })
    }
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    notiPopup('clear')
    if ((signature.doctorSignature || signature.patientSignature) && (!treatment?.invoice?.doctorSignature && !treatment?.invoice?.patientSignature)) {
      return (
        notiPopup(
          'custom',
          '',
          <div className={'d-flex align-items-center justify-content-between'}>
            <span>{t(`MODAL_TREATMENT_CONSENT.MESSAGE.INPROGRESS`)}</span>
            <Button
              variant="text"
              disableRipple
              onClick={() => {
                clearCanvas('both')
                notiPopup('clear')
              }}
              sx={{ color: colors.statusWarning, fontSize: 16, fontWeight: 400, padding: 0, lineHeight: 1, backgroundColor: 'transparent !important' }}>
              {t(`MODAL_TREATMENT_CONSENT.BUTTON.DISCARD`)}
            </Button>
          </div>
        )
      )
    }
    clearCanvas('both')
    setDoctorTab(newValue);
    setTreatment(treatments[newValue])
    setSignature({
      ...signature,
      doctorId: treatments[newValue]?.doctorId,
      doctorSignature: treatments[newValue]?.invoice?.doctorSignature,
      patientSignature: treatments[newValue]?.invoice?.patientSignature
    })
  };

  const onPrint = async (invoiceId: number) => {
    if (invoiceId) {
      setPdfLoading(true)
      await TreatmentsAPI.printInvoice(invoiceId)
        .then((e) => {
          if (e.data) window.open(`${fileUrl}/${e.data}`, '_blank')
        })
        .finally(() => setPdfLoading(false))
    } else {
      onSave('print')
    }
  }

  const onSave = async (type: 'save' | 'print') => {
    await TreatmentsAPI.saveInvoice(props.patientCaseId, signature).then(async (e) => {
      clearCanvas('both')
      await loadData(props.patientCaseId)
      if (type === 'print') await onPrint(e.data.patientInvoiceId)
      notiPopup('success', t(`MODAL_TREATMENT_CONSENT.MESSAGE.SUCCESS.SAVE`))
      await handleChangeTreatmentConsent(props.patientCaseId)
    }).catch((e) => {
      notiError(t('MODAL_TREATMENT_CONSENT.MESSAGE.ERROR.DEFAULT'))
    })
  }

  const onCancel = async () => {
    await TreatmentsAPI.deleteInvoice(treatment?.invoice?.patientInvoiceId).then(async (e) => {
      clearCanvas('both')
      loadData(props.patientCaseId)
      notiPopup('success', t(`MODAL_TREATMENT_CONSENT.MESSAGE.SUCCESS.CANCEL`))
      await handleChangeTreatmentConsent(props.patientCaseId)
    }).catch((e) => {
      notiError(t('MODAL_TREATMENT_CONSENT.MESSAGE.ERROR.DEFAULT'))
    })
  }

  const onClosePopup = () => {
    dispatch(resetModal())
    props.setIsShow(false)
  }

  const showAge = (date: Date | null) => {
    return `${renderAge(date, 'year')} ${t('TIME_DURATION_TYPE.YEAR')} ${renderAge(date, 'month')} ${t('TIME_DURATION_TYPE.MONTH')} ${renderAge(date, 'day')} ${t('TIME_DURATION_TYPE.DAY')}`
  }

  const showDiagnosis = (items: any) => {
    const diagnosis: any = []
    _.map(items, (i, index) => {
      diagnosis.push(i.diagnosisName)
    })
    return !_.isEmpty(diagnosis) ? diagnosis.toString().replaceAll(',', ', ') : '-'
  }

  const showTreatment = (items: any, type: 'treatment' | 'note' | 'nextVisit') => {
    const renderTreatment: any = []
    const note: any = []
    const nextVisit: any = []
    const noteProduct: any = []

    _.map(items, (i, index) => {
      const priceTotal = `(${(numberFormat(i?.total || 0))} ${t('CURRENCY_CHANGE.TH')})`
      if (i.operativeName) renderTreatment.push(`${i.operativeName}${priceTotal}`)
      if (i.note) note.push(i.note)
      if (i.noteNextVisit) nextVisit.push(i.noteNextVisit)
    })

    if (type === 'treatment') return !_.isEmpty(renderTreatment) ? renderTreatment.toString().replaceAll(',', ', ') : '-'
    else if (type === 'note') return !_.isEmpty(note) ? note.toString().replaceAll(',', ', ') : '-'
    else return !_.isEmpty(nextVisit) ? nextVisit : '-'
  }

  const renderCanvas = (type: 'doctor' | 'patient') => {
    if (type === 'doctor') {
      if (canvasDoctorRef.current) {
        const base64 = canvasDoctorRef.current.canvasContainer.childNodes[1].toDataURL('image/jpg')
        let base64string: any = `${base64}`.split(',')
        base64string = base64string[base64string.length - 1]
        setSignature({ ...signature, doctorId: treatment.doctorId, doctorSignature: base64string })
      }
    } else {
      if (canvasPatientRef.current) {
        const base64 = canvasPatientRef.current.canvasContainer.childNodes[1].toDataURL('image/jpg')
        let base64string: any = `${base64}`.split(',')
        base64string = base64string[base64string.length - 1]
        setSignature({ ...signature, doctorId: treatment.doctorId, patientSignature: base64string })
      }
    }
  }

  const clearCanvas = (type: 'doctor' | 'patient' | 'both') => {
    if (type === 'doctor' && canvasDoctorRef.current) {
      canvasDoctorRef.current.clear()
      canvasDoctorRef.current.imgSrc = ''
      canvasDoctorRef.current.resetView()
      setSignature({ ...signature, doctorSignature: '' })
    }

    if (type === 'patient' && canvasPatientRef.current) {
      canvasPatientRef.current.clear()
      canvasPatientRef.current.imgSrc = ''
      canvasPatientRef.current.resetView()
      setSignature({ ...signature, patientSignature: '' })
    }

    if (type === 'both' && canvasDoctorRef.current && canvasPatientRef.current) {
      canvasPatientRef.current.clear()
      canvasPatientRef.current.imgSrc = ''
      canvasPatientRef.current.resetView()
      canvasDoctorRef.current.clear()
      canvasDoctorRef.current.imgSrc = ''
      canvasDoctorRef.current.resetView()
      setSignature({ ...signature, doctorSignature: '', patientSignature: '' })
    }
  }

  const handleChangeTreatmentConsent = async (patientCaseId: number) => {
    const findTry = await TreatmentSummaryApi.findTreatmentSummary(patientCaseId)
    if (findTry.status === 200) {
      const summeryProducts: any = []
      if (!_.isEmpty(findTry.data.summeryProducts)) {
        _.map(findTry.data.summeryProducts, (prod, index) => {
          summeryProducts.push({ ...prod, total: ((prod.price * prod.qty) - prod.discount) + prod.additional })
        })
      }
      dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: [...findTry.data.summeryTreatments, ...summeryProducts] }))
      dispatch(setTreatmentByKey({ key: 'patientDiagnosis', value: [...findTry.data.patientDiagnosis] }))
      dispatch(setTreatmentByKey({ key: 'patientRecommends', value: [...findTry.data.patientRecommends] }))
    }
  }

  useEffect(() => {
    if (props.patientCaseId) loadData(props.patientCaseId)
  }, [props.patientCaseId]);

  const handleTotalTreatment = () => {
    const newTotal = treatment?.summeryTreatments?.map((treatment: any) => treatment?.total || 0)
      .reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
    return `${numberFormat(newTotal)} ${t('CURRENCY_CHANGE.TH')}`
  }

  return (
    <ModalPop
      JSX={<>
        {!loading && (
          !_.isEmpty(treatments) && (
            <>
              {props?.modal !== undefined && !props?.modal &&
                <Box
                  sx={{
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: '600',
                    color: colors.themeMainColor,
                    paddingBottom: '8px'
                  }}>
                  {t('MODAL_TREATMENT_CONSENT.TITLE')}
                </Box>
              }
              <Box sx={{ borderBottom: 1, borderColor: colors.extraLightBlue }}>
                <UseStyled.DoctorTabs
                  value={doctorTab}
                  onChange={handleChangeTab}
                  aria-label="doctor-tabs"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile>
                  {
                    _.map(treatments, (trm, index: number) => {
                      return <Tab label={<span className='text-ellipsis w-100'>{`${trm?.firstname} ${trm?.lastname}`}</span>} {...a11yProps(index)} />
                    })
                  }
                </UseStyled.DoctorTabs>
              </Box>
              <ToastContainer enableMultiContainer containerId={'popup'} className={'Toast-popup'} />
              <TabPanel value={doctorTab} index={doctorTab}>
                <UseStyled.Sheet className='px-1 py-2 px-md-3 py-md-4 custom-scroll overflow-auto'>
                  <Box className='header pb-2 pb-md-4 mb-3 mb-md-4'>
                    <Box className='d-flex align-items-start pb-2 pb-md-3'>
                      <Box className='header-logo pr-1 pr-md-3'>
                        <Box className='image-wrap position-relative'>
                          <img src={treatment?.branch?.branchImage || treatment?.branch?.branchLogo} onError={onImgError} alt="" />
                        </Box>
                      </Box>
                      <Box className='header-address'>
                        <Typography sx={{ fontWeight: 500 }}>{treatment?.branch?.clinicAndBranchName || `${treatment?.branch?.clinicName || treatment?.branch?.clinicNameEn} ${t('MODAL_TREATMENT_CONSENT.SHEET.BRANCH')} ${treatment?.branch?.branchName || treatment?.branch?.branchNameEn}`}</Typography>
                        <Typography>{(treatment?.branch?.branchFullAddress || treatment?.branch?.branchFullAddressEn || '').replaceAll('undefined', '')}</Typography>
                      </Box>
                      <Box className='header-number ml-auto'>
                        {t('MODAL_TREATMENT_CONSENT.SHEET.CODE')}<span className='pl-1'>{treatment?.invoice?.invoiceNumber || 'xxxxxx'}</span>
                      </Box>
                    </Box>
                    <Typography className='header-title text-center' sx={{ fontWeight: 500 }}>{t('MODAL_TREATMENT_CONSENT.SHEET.TITLE')}</Typography>
                  </Box>
                  <Box className='body'>
                    <Box className='d-flex'>
                      <Box className='d-flex pr-2' sx={{ width: '35%' }}>
                        <UseStyled.TypoField type='title'>{t('MODAL_TREATMENT_CONSENT.SHEET.PATIENT_NAME')}</UseStyled.TypoField>
                        <UseStyled.TypoField type='body'>{`${treatment?.patientInfo?.firstname} ${treatment?.patientInfo?.lastname}`}</UseStyled.TypoField>
                      </Box>
                      {treatment?.patientInfo?.branchCnNumber && (
                        <Box className='d-flex pr-2' sx={{ width: '25%' }}>
                          <UseStyled.TypoField type='title'>{t('MODAL_TREATMENT_CONSENT.SHEET.PATIENT_BRANCH_CN_NUMBER')}</UseStyled.TypoField>
                          <UseStyled.TypoField type='body'>{treatment?.patientInfo?.branchCnNumber || ''}</UseStyled.TypoField>
                        </Box>
                      ) || <></>}
                      <Box className='d-flex pr-2' sx={{ width: '15%' }}>
                        <UseStyled.TypoField type='title'>{t('MODAL_TREATMENT_CONSENT.SHEET.PATIENT_GENDER')}</UseStyled.TypoField>
                        <UseStyled.TypoField type='body'>{treatment?.patientInfo?.gender ? t(`REGISTER.FORM.GENDER_OPTION.${treatment?.patientInfo?.gender}`) : t('REGISTER.BUTTON.NOT_SPECIFIED')}</UseStyled.TypoField>
                      </Box>
                      <Box className='d-flex' sx={{ width: '25%' }}>
                        <UseStyled.TypoField type='title'>{t('MODAL_TREATMENT_CONSENT.SHEET.PATIENT_AGE')}</UseStyled.TypoField>
                        <UseStyled.TypoField type='body'>{treatment?.patientInfo?.dateOfBirth ? showAge(treatment?.patientInfo?.dateOfBirth) : t('REGISTER.BUTTON.NOT_SPECIFIED')}</UseStyled.TypoField>
                      </Box>
                    </Box>
                    <Box className='d-flex mt-1'>
                      <UseStyled.TypoField type='title'>{t('MODAL_TREATMENT_CONSENT.SHEET.PATIENT_ADDRESS')}</UseStyled.TypoField>
                      <UseStyled.TypoField type='body'>
                        <span>{treatment?.patientInfo?.address}</span>
                        {treatment?.patientInfo?.subDistrict?.subDistrictNameTh && <span className='pl-1'>{t('MODAL_TREATMENT_CONSENT.SHEET.SUB_DISTRICT')}{treatment?.patientInfo?.subDistrict?.subDistrictNameTh}</span>}
                        {treatment?.patientInfo?.district?.districtNameTh && <span className='pl-1'>{t('MODAL_TREATMENT_CONSENT.SHEET.DISTRICT')}{treatment?.patientInfo?.district?.districtNameTh}</span>}
                        {treatment?.patientInfo?.province?.provinceNameTh && <span className='pl-1'>{t('MODAL_TREATMENT_CONSENT.SHEET.PROVINCE')}{treatment?.patientInfo?.province?.provinceNameTh}</span>}
                        {treatment?.patientInfo?.country?.countryNameTh && <span className='pl-1'>{t('MODAL_TREATMENT_CONSENT.SHEET.COUNTRY')}{treatment?.patientInfo?.country?.countryNameTh}</span>}
                        {treatment?.patientInfo?.postcode && <span className='pl-1'>{treatment?.patientInfo?.postcode}</span>}
                      </UseStyled.TypoField>
                    </Box>
                    <Box className='d-flex mt-1'>
                      <UseStyled.TypoField type='title'>{t('MODAL_TREATMENT_CONSENT.SHEET.DIAGNOSIS')}</UseStyled.TypoField>
                      <UseStyled.TypoField type='body'>{showDiagnosis(treatment?.patientDiagnosis)}</UseStyled.TypoField>
                    </Box>
                    <Box className='d-flex mt-1'>
                      <UseStyled.TypoField type='title'>{t('MODAL_TREATMENT_CONSENT.SHEET.DATE')}</UseStyled.TypoField>
                      <UseStyled.TypoField type='body'>{dateToView(new Date())}</UseStyled.TypoField>
                    </Box>
                    <Box className='d-flex mt-1'>
                      <UseStyled.TypoField type='title'>{t('MODAL_TREATMENT_CONSENT.SHEET.TREATMENTS')}</UseStyled.TypoField>
                      <UseStyled.TypoField type='body'>{showTreatment(treatment?.summeryTreatments, 'treatment')}</UseStyled.TypoField>
                    </Box>
                    <Box className='d-flex mt-1'>
                      <UseStyled.TypoField type='title'>{t('MODAL_TREATMENT_CONSENT.SHEET.TOTAL_PRICE')}</UseStyled.TypoField>
                      <UseStyled.TypoField type='body'>{handleTotalTreatment()}</UseStyled.TypoField>
                    </Box>
                    <Box className='d-flex mt-1'>
                      <Box className='d-flex pr-2' sx={{ width: '100%' }}>
                        <UseStyled.TypoField type='title'>
                          {t('MODAL_TREATMENT_CONSENT.SHEET.REMARK')}
                        </UseStyled.TypoField>
                        <UseStyled.TypoField type='body'>{`${showTreatment(treatment?.summeryTreatments, 'note')}`}</UseStyled.TypoField>
                      </Box>
                    </Box>
                    <Box className='pt-2 pt-md-3'>
                      <UseStyled.TypoField type='body' sx={{ textIndent: '50px' }}>
                        {t('MODAL_TREATMENT_CONSENT.SHEET.PARAGRAPH_I')}
                      </UseStyled.TypoField>
                      <UseStyled.TypoField type='body' sx={{ textIndent: '50px' }}>
                        {t('MODAL_TREATMENT_CONSENT.SHEET.PARAGRAPH_II')}
                      </UseStyled.TypoField>
                    </Box>
                  </Box>
                </UseStyled.Sheet>
                <Box className='mt-3 custom-scroll overflow-auto' sx={{ flex: 'none' }}>
                  <UseStyled.SignatureWrap className={`d-md-flex align-items-center ${props.type === 'historyTreatment' ? 'pe-none' : ''}`}>
                    <Box className={`signatureWrap-box w-100`}>
                      <Box className={`${treatment?.invoice?.doctorSignature ? 'pe-none' : ''}`}>
                        <UseStyled.SignBox className='mx-auto'>
                          {treatment?.invoice?.doctorSignature && (
                            <img src={`data:image/png;base64,${treatment?.invoice?.doctorSignature}`} className="w-100 h-100" alt="" />
                          ) || (
                              <Box className={`clear-box ${props.type === 'historyTreatment' ? 'd-none opacity-0' : ''}`}>
                                <ButtonCustom variant={'outlined'} textButton={t('MODAL_TREATMENT_CONSENT.BUTTON.CLEAR_CANVAS')} onClick={() => clearCanvas('doctor')} />
                              </Box>
                            )}
                          <Box className="canvas-box">
                            <CanvasDraw
                              hideGrid
                              hideInterface
                              brushRadius={2}
                              lazyRadius={2}
                              brushColor={colors.black}
                              ref={canvasDoctorRef}
                              onChange={() => renderCanvas('doctor')} />
                          </Box>
                        </UseStyled.SignBox>
                      </Box>
                      <Box className='text-center mt-2'>
                        <Typography sx={{ fontWeight: 500 }}><span className='d-md-none'>(</span>{`${treatment?.firstname} ${treatment?.lastname}`}<span className='d-md-none'>)</span></Typography>
                        <Typography className='d-none d-md-block' sx={{ fontSize: 14 }}>{t('MODAL_TREATMENT_CONSENT.SIGN.DOCTOR')}</Typography>
                        <Typography className='d-none d-md-block' sx={{ fontSize: 14 }}>Dentist{"'"}s Signature</Typography>
                      </Box>
                    </Box>
                    <Box className={`signatureWrap-box w-100 mt-3 mt-md-0`}>
                      <Box className={`${treatment?.invoice?.patientSignature ? 'pe-none' : ''}`}>
                        <UseStyled.SignBox className='mx-auto'>
                          {treatment?.invoice?.patientSignature && (
                            <img src={`data:image/png;base64,${treatment?.invoice?.patientSignature}`} className="w-100 h-100" alt="" />
                          ) || (
                              <Box className={`clear-box ${props.type === 'historyTreatment' ? 'd-none opacity-0' : ''}`}>
                                <ButtonCustom variant={'outlined'} textButton={t('MODAL_TREATMENT_CONSENT.BUTTON.CLEAR_CANVAS')} onClick={() => clearCanvas('patient')} />
                              </Box>
                            )}
                          <Box className="canvas-box">
                            <CanvasDraw
                              hideGrid
                              hideInterface
                              brushRadius={2}
                              lazyRadius={2}
                              brushColor={colors.black}
                              ref={canvasPatientRef}
                              onChange={() => renderCanvas('patient')} />
                          </Box>
                        </UseStyled.SignBox>
                      </Box>
                      <Box className='text-center mt-2'>
                        <Typography sx={{ fontWeight: 500 }}><span className='d-md-none'>(</span>{`${treatment?.patientInfo?.firstname} ${treatment?.patientInfo?.lastname}`}<span className='d-md-none'>)</span></Typography>
                        <Typography className='d-none d-md-block' sx={{ fontSize: 14 }}>{t('MODAL_TREATMENT_CONSENT.SIGN.PATIENT')}</Typography>
                        <Typography className='d-none d-md-block' sx={{ fontSize: 14 }}>Patient{"'"}s Signature</Typography>
                      </Box>
                    </Box>
                  </UseStyled.SignatureWrap>
                </Box>
              </TabPanel>
              <UseStyled.ModalFooter className='d-flex flex-column flex-md-row align-items-center justify-content-md-end'>
                <Box className='d-flex align-items-center'>
                  <Box className='pr-1 pr-md-2'>
                    <ButtonCustom
                      className={'btn-cancel'}
                      variant={'outlined'}
                      textButton={t('BUTTON.CANCEL')}
                      onClick={onClosePopup} />
                  </Box>
                  <Box className={`pl-1 pl-md-2`}>
                    <ButtonCustom
                      disabled={!signature?.doctorSignature || !signature?.patientSignature}
                      className={'btn-print'}
                      variant={'outlined'}
                      textButton={t('BUTTON.PRINT')}
                      onClick={() => onPrint(treatment?.invoice?.patientInvoiceId)} />
                  </Box>
                </Box>
                {props.type !== 'historyTreatment' && (
                  <Box className='pt-2 pt-md-0 pl-md-3'>
                    {treatment?.invoice?.doctorSignature && treatment?.invoice?.patientSignature && (
                      <ButtonCustom
                        className={'btn-discard'}
                        textButton={t('MODAL_TREATMENT_CONSENT.BUTTON.CANCEL_DOCUMENT')}
                        onClick={onCancel} />
                    ) || (
                        <ButtonCustom
                          disabled={!signature?.doctorSignature || !signature?.patientSignature}
                          className={'btn-save'}
                          textButton={t('BUTTON.SAVE')}
                          onClick={() => onSave('save')} />
                      )}
                  </Box>
                )}
              </UseStyled.ModalFooter>
              {pdfLoading && <Loading show={pdfLoading} type='fullLoading' />}
            </>
          ) || (
            <Box className='d-flex align-items-center justify-content-center h-100'>
              <Typography className='text-center'>{props.type === 'historyTreatment' ? t('REGISTER.NO_ITEM') : t('MODAL_TREATMENT_CONSENT.NO_TREATMENT')}</Typography>
            </Box>
          )
        ) || (
            <Loading show={loading} type='softLoading' className='m-auto' />
          )}
      </>
      }
      modal={props?.modal === undefined ? true : props.modal}
    />
  )
}

function a11yProps(index: number) {
  return {
    id: `doctor-tab-${index}`,
    'aria-controls': `doctor-tabpanel-${index}`,
  };
}

type ModalPopProps = {
  JSX: ReactNode;
  modal: boolean;
};

const ModalPop = ({ JSX, modal }: ModalPopProps) => {
  const { t } = useTranslation()
  if (modal) {
    return <ModalCustom
      size={'xl'}
      title={t('MODAL_TREATMENT_CONSENT.TITLE')}
      alignFooter={'end'}
      fullscreen
      footerInline
      closeButton
      modalStyle={'modal-med-certificate modal-treatment-consent'}
      component={JSX}
    />
  } else {
    return <>{JSX}</>
  }

}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`doctor-tabpanel-${index}`}
      aria-labelledby={`doctor-tab-${index}`}
      {...other}
      className="mt-3 overflow-auto d-flex flex-column"
      sx={{ overflow: { sm: 'hidden !important' } }}
    >
      {value === index && (
        <>{children}</>
      )}
    </Box>
  );
}