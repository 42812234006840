import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface ImageDoctorNoteProps {
    name: string,
    doctorNoteTypeId: number,
    fileId?: number | null
    base64: string
    fileExtension: string
    fileSize: string
    status?: string
}
export interface IFImageDoctorNoteProps {
    name: string
    doctorNoteImageId: number
    doctorNoteTypeId: number
    doctorNoteType: {
        doctorNoteTypeId: number
        typeName: string
        typeNameEn: string
    }
    image: { fileId: number, filePath: string }
    status: "ACTIVE" | "INACTIVE" | "DELETE"
    createdAt: string
    createdBy: string
    updatedAt: string
    updatedBy: string
}

export default class ImageDoctorNoteApi extends BaseAPI {

    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get('image-doctor-notes', { params: { ...props } }).then((res) => res)
    }

    static create(payload: ImageDoctorNoteProps) {
        return this.api.post('image-doctor-notes', payload)
    }

    static update(id: any, body: any) {
        return this.api.patch(`image-doctor-notes/${id}`, body)
    }

    static updateStatus(id: number, status: string): Promise<any> {
        return this.api.patch(`image-doctor-notes/${id}/status`, { id, status })
    }

    static remove(id: number) {
        return this.api.delete(`image-doctor-notes/${id}`)
    }
}
