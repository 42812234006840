import IconProps from './iconProps'
import { colors } from 'constants/theme'

export default function Clinic(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.43" height="32" viewBox="0 0 33.43 32">
      <g data-name="ckinic management">
        <g data-name="Group 8645">
          <g data-name="Group 7016">
            <path
              d="M30.466 17.5 18.313 5.383a1.736 1.736 0 0 0-2.31 0L3.379 17.5c-.368.408.484 2.635 3.813-3.5v15.553a1.39 1.39 0 0 0 1.39 1.39h16.68a1.39 1.39 0 0 0 1.39-1.39v-15.29s3.753 4.5 3.976 4 .207-.35-.163-.758zm-7.983 3.711h-4.17v4.17h-2.78v-4.17h-4.17v-2.78h4.17v-4.17h2.78v4.17h4.17z"
              transform="translate(-2.374 -1.786)"
              style={{ strokeLinecap: 'round', strokeWidth: '1.8px', stroke: props.color || colors.themeSecondColor, fill: '#fff' }}
            />
            <path data-name="Path 6311" d="M-231.371-983.477v-4.188h3.191v4.188h3.839v2.842h-4.537v4.338h-2.493v-4.737h-4.188v-2.443z" transform="translate(244.613 1000.34)" style={{ fill: props.color || colors.themeSecondColor, stroke: props.color || colors.themeSecondColor }} />
          </g>
          <g transform="translate(-448.228 -38)">
            <circle data-name="Ellipse 426" cx="4.5" cy="4.5" r="4.5" transform="translate(471.801 40)" style={{ fill: '#fff' }} />
            <path
              data-name="clock"
              d="M8.587 6.746a.455.455 0 1 1 0 .909H7.223a.912.912 0 0 1-.909-.909V4.927a.455.455 0 0 1 .909 0v1.819z"
              transform="translate(469.687 38.327)"
              style={{ strokeWidth: '.8px', fillRule: 'evenodd', fill: props.color || colors.themeSecondColor, stroke: props.color || colors.themeSecondColor }}
            />
            <path
              data-name="clock"
              d="M7.629 12.258a4.629 4.629 0 1 1 4.629-4.629 4.629 4.629 0 0 1-4.629 4.629zm3.7-4.629a3.7 3.7 0 1 0-3.7 3.7 3.7 3.7 0 0 0 3.703-3.7z"
              transform="translate(469 37)"
              style={{ strokeWidth: '.8px', fillRule: 'evenodd', fill: props.color || colors.themeSecondColor, stroke: props.color || colors.themeSecondColor }}
            />
          </g>
        </g>
        <path data-name="Rectangle 3867" transform="translate(.925)" style={{ fill: 'none' }} d="M0 0h32v32H0z" />
      </g>
    </svg>
  )
}
