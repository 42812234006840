import { colors } from 'constants/theme'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material'
import { Dropdown } from 'react-bootstrap'

export const DropdownItem = styled(Dropdown.Item)(({ theme }) => ({
  color: colors.textPrimary,
  img: {
    width: '16px !important',
    height: '16px !important',
    padding: '0 !important'
  },
  '&  span': {
    fontSize: '16px !important',
  },
  '&:hover': {
    backgroundColor: colors.themeSecondColor06,
    color: colors.themeSecondColor
  }
}))

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: 'calc(100% - 80px)',
    '& .btn-day': {
      backgroundColor: `${colors.lightGray} !important`,
      color: colors.black
    },
    '& .btn-day.active': {
      backgroundColor: `${colors.highlightSelect} !important`,
      color: colors.themeSecondColor
    },
    '& .btn-bank.active': {
      border: `solid 2px ${colors.themeSecondColor}`,
      boxShadow: `0 0 6px 0 ${colors.themeSecondColor}`
    }
  },
  titleFirst: {
    fontSize: '18px',
    fontWeight: 600
  },
  titleSecond: {
    fontSize: '16px'
  },
  menu: {
    fontSize: '16px'
  },
  dropdown: {
    width: '70%',
    backgroundColor: `${colors.white} !important `,
    color: `${colors.themeSecondColor} !important `,
    border: `solid 1px ${colors.themeSecondColor}  !important`,
    '&:hover': {
      backgroundColor: `${colors.themeSecondColor} !important`,
      color: `${colors.white} !important`
    }
  },
  bank: { height: '30px', borderRadius: '3px' },
  columeHover: {
    backgroundColor: '',
    '&:hover': {
      backgroundColor: `${colors.lightGray} !important`
    }
  },
  buttomDayactive: {},
  buttonClearCanvas: {
    position: 'absolute',
    top: '5%',
    left: '73%',
    color: colors.themeThirdColor,
    border: `1px solid ${colors.themeThirdColor}`,
    borderRadius: '4px',
    fontSize: '16px',
    padding: '6px 17px 5px 15px',
    height: ' 32px'
  },
  header: {
    backgroundColor: colors.white,
    padding: '20px 15px'
  },
  menuNavigation: {
    fontSize: 12
  },
  btnDiscard: {

    color: `${colors.white}  !important`,
    backgroundColor: `${colors.white}  !important`,
    border: `solid 1px ${colors.themeSecondColor}  !important`,
    margin: '0 !important',
    marginLeft: '0.5em !important'
  },
  btnDiscardView: {
    padding: '3px !important',
    width: 'fit-contant',
    height: '100%',
    borderRadius: '3px !important',
    backgroundColor: `${colors.white}  `,
    border: `solid 1px ${colors.themeSecondColor}  !important`,
    marginLeft: '1em !important',
    color: `${colors.themeSecondColor} !important`
  },
  btnSubmit: {

    borderRadius: '3px !important',
    margin: '0 !important',
    border: `solid 1px ${colors.themeSecondColor}  !important`
  },
  tableHeader: {
    backgroundColor: `${colors.themeMainColor}`,
    color: `${colors.white} !important`,
    '& $th': {
      backgroundColor: `${colors.themeMainColor}`,
      color: `${colors.white} !important`,
      padding: 15
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: `${colors.white} !important`
    },
    '& .MuiTableCell-root.MuiTableCell-head': {
      color: `${colors.white} !important`,
      fontSize: 16
    },

    '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: `${colors.white} !important`
    }
  },
  tableBody: {
    '&.sm-data-table': {
      borderRight: `1px solid ${colors.black03} !important`,
      borderLeft: `1px solid ${colors.black03} !important`,
      height: 'auto'
    }
  },
  tableColumnHover: {
    backgroundColor: '',
    '&:hover': {
      backgroundColor: `${colors.lightGray} !important`
    }
  },
  pendingApproval: {
    backgroundColor: colors.approveStatus.pending,
    color: colors.white
  },
  approval: {
    backgroundColor: colors.approveStatus.approve,
    color: colors.white
  },
  unapproval: {
    backgroundColor: colors.themeThirdColor,
    color: colors.white
  },
  docTitle: {
    fontSize: '1.5em',
    fontWeight: 'bolder'
  }
})

export default useStyles
