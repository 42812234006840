import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface TreatmentRightface {
  treatmentRightCode: string
  treatmentRightName: string
  treatmentRightNameEn: string
  price: number
  unit: string
  amount: number
  isDivide: string
  isDoctorFee: string
  status?: string
}

export default class TreatmentRightApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('treatment-rights', { params: { ...props } }).then((res) => res)
  }

  static create(body: TreatmentRightface) {
    return this.api.post('treatment-rights', body)
  }

  static findById(id: number): Promise<TreatmentRightface> {
    return this.api.get(`treatment-rights/${id}`)
  }

  static update(id: number, body: TreatmentRightface) {
    return this.api.patch(`treatment-rights/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`treatment-rights/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`treatment-rights/${id}`)
  }
}
