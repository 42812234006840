import { sidebarIcon as Sidebar } from 'constants/theme'
import { routeName } from 'routes/routes-name'
import { PACKAGE_ADDONS } from 'constants/package'
import { Icons } from './images'

export enum MENUS_FIRST {
  COUNTER = 'COUNTER',
  DENTIST = 'DENTIST',
  FINANCE = 'FINANCE',
  LAB = 'LAB',
  X_RAY = 'X_RAY',
  APPLICATION = 'APPLICATION',
  CLINIC_MANAGEMENT = 'CLINIC_MANAGEMENT',
  WAREHOUSES = 'WAREHOUSES',
  REPORT = 'REPORT',
  SETTING = 'SETTING',
  UPDATE = 'UPDATE',
  CUSTOM = 'CUSTOM',
  EXTRA = 'EXTRA'
}

export const menuFirsts: any = {
  WELCOME: { key: 'WELCOME', name: 'Welcome', textName: 'ยินดีต้อนรับ', icon: ``, active: false, position: 'FRONTEND' },
  COUNTER: { key: MENUS_FIRST.COUNTER, name: 'Counter', textName: 'MENU.COUNTER', icon: <Sidebar.Counter />, active: false, position: 'FRONTEND' },
  DENTIST: { key: MENUS_FIRST.DENTIST, name: 'Dentist', textName: 'MENU.DENTIST', icon: <Sidebar.Doctor />, active: false, position: 'FRONTEND', badge: false },
  FINANCE: { key: MENUS_FIRST.FINANCE, name: 'Finance', textName: 'MENU.FINANCE', icon: <Sidebar.Finance />, active: false, position: 'FRONTEND' },
  LAB: { key: MENUS_FIRST.LAB, packageKey: PACKAGE_ADDONS.ADDON_LAB, name: 'Lab', textName: 'MENU.LAB', icon: <Sidebar.Lab />, active: false, path: routeName.labOrder, position: 'FRONTEND' },
  X_RAY: { key: MENUS_FIRST.X_RAY, packageKey: PACKAGE_ADDONS.ADDON_X_RAY, name: 'X-Ray', textName: 'MENU.X_RAY', icon: <Sidebar.Xray />, active: false, path: routeName.xray, position: 'FRONTEND' },

  // APPLICATION: { key: MENUS_FIRST.APPLICATION, name: 'Application', textName: 'MENU.APPLICATION', icon: <Sidebar.Application />, active: false },
  CLINIC_MANAGEMENT: { key: MENUS_FIRST.CLINIC_MANAGEMENT, name: 'Clinic', textName: 'MENU.CLINIC_MANAGEMENT', icon: <Sidebar.Management />, active: false, path: routeName.clinicManagement },
  WAREHOUSES: { key: MENUS_FIRST.WAREHOUSES, name: 'Warehouses', textName: 'MENU.WAREHOUSES', icon: <Sidebar.Warehouses />, active: false },
  REPORT: { key: MENUS_FIRST.REPORT, name: 'Report', textName: 'MENU.REPORT', icon: <Sidebar.Report />, active: false, path: routeName.report },
  SETTING: { key: MENUS_FIRST.SETTING, name: 'Setting', textName: 'MENU.SETTING', icon: <Sidebar.Setting />, active: false },
  UPDATE: { key: MENUS_FIRST.UPDATE, name: 'Update', textName: 'MENU.UPDATE', icon: <Sidebar.Update />, active: false, path: routeName.update },

  CUSTOM: { key: MENUS_FIRST.CUSTOM, name: 'Custom', textName: 'MENU.CUSTOM', icon: <Sidebar.Custom />, active: false, path: routeName.custom },

  EXTRA:{ key: MENUS_FIRST.EXTRA, name: 'Extra', textName: 'MENU.EXTRA', icon: <Sidebar.Application /> ,active: false, path: routeName.custom },

}

export const menuSeconds: any = {
  /**  COUNTER */
  HOME: { key: 'HOME', name: 'Home', textName: 'MENU.HOME', icon: null, active: false, disabled: true, isHidden: true },
  DASHBOARD: { key: 'DASHBOARD', packageKey: PACKAGE_ADDONS.ADDON_DASHBOARD, name: 'Dashboard', textName: 'MENU.DASHBOARD', icon: null, active: false, disabled: true, isHidden: true },
  REGISTER: { key: 'REGISTER', textName: 'MENU.REGISTER', active: false, path: routeName.register },
  APPOINTMENT: { key: 'APPOINTMENT', textName: 'MENU.APPOINTMENT', active: false, path: routeName.appointment },
  DOCTOR_SCHEDULE: { key: 'DOCTOR_SCHEDULE', textName: 'MENU.DOCTOR_SCHEDULE', active: false, path: routeName.doctorSchedule },
  PATIENT_MANAGEMENT: { key: 'PATIENT_MANAGEMENT', textName: 'MENU.PATIENT_MANAGEMENT', active: false, path: routeName.patientManagement },
  DOCTOR_APPOINTMENT_SCHEDULE: { key: 'DOCTOR_APPOINTMENT_SCHEDULE', textName: 'MENU.APPOINTMENT', active: false, path: '/doctor-appointment' },
  RETROSPECTIVE_ORDER: { key: 'RETROSPECTIVE_ORDER', packageKey: PACKAGE_ADDONS.ADDON_RETROSPECTIVE_ORDER, name: 'Retrospective-order', textName: 'MENU.RETROSPECTIVE_ORDER', active: false, path: routeName.retrospectiveOrder },
  RETROSPECTIVE_EDIT: { key: 'RETROSPECTIVE_EDIT', name: 'แก้ไขข้อมูลย้อนหลัง', textName: 'MENU.RETROSPECTIVE_EDIT', active: false, path: routeName.retrospectiveEdit },
  RECALL_LISTS: { key: 'RECALL_LISTS', name: 'รายการ Recall', textName: 'MENU.RECALL_LISTS', active: false, path: routeName.recallLists },
  MANAGE_DF: { key: 'MANAGE_DF', name: 'จัดการค่า Doctor Fee', textName: 'MENU.MANAGE_DF', active: false, path: routeName.manageDf },
  MANAGE_DF_LAB_FEE: { key: 'MANAGE_DF_LAB_FEE', name: 'จัดการค่า DF Lab Fee', textName: 'MENU.MANAGE_DF_LAB_FEE', active: false, path: routeName.manageDfLab },

  /** DOCTOR */
  DOCTOR_TREATMENTS: { key: 'DOCTOR_TREATMENTS', textName: 'MENU.DOCTOR_TREATMENTS', active: false, path: routeName.treatmentsQueue, badge: false },
  TREATMENT_HISTORY: { key: 'TREATMENT_HISTORY', textName: 'MENU.TREATMENT_HISTORY', active: false, path: routeName.treatmentsHistory, badge: false },

  /** FINANCE */
  PAYMENT: { key: 'PAYMENT', textName: 'MENU.PAYMENT', active: false, path: routeName.payment },
  OVERDUE: { key: 'OVERDUE', textName: 'MENU.OVERDUE', active: false, path: routeName.overdue },
  SALARY: { key: 'SALARY', textName: 'MENU.SALARY', active: false, path: routeName.salary },
  INCOME_EXPENSES: { key: 'INCOME_EXPENSES', packageKey: PACKAGE_ADDONS.ADDON_INCOME_EXPENSES, name: 'Income Expenses', textName: 'MENU.INCOME_EXPENSES', active: false, path: routeName.incomeExpenses },
  ADVANCE: { key: 'ADVANCE', packageKey: PACKAGE_ADDONS.ADDON_ADVANCE, textName: 'MENU.ADVANCE', active: false, path: routeName.advance },

  /** APPICATION */
  REGISTER_APP: { key: 'REGISTER_APP', packageKey: PACKAGE_ADDONS.ADDON_REGISTER_APP, name: 'Register app', textName: 'MENU.REGISTER_APP', active: false, path: routeName.registerApp },
  PROMOTIONS: { key: 'PROMOTIONS', packageKey: PACKAGE_ADDONS.ADDON_PROMOTIONS, name: 'Promotions', textName: 'MENU.PROMOTIONS', active: false, path: routeName.promotions },
  NEWS: { key: 'NEWS', packageKey: PACKAGE_ADDONS.ADDON_NEWS, name: 'News', textName: 'MENU.NEWS', active: false, path: routeName.news },
  BANNER: { key: 'BANNER', packageKey: PACKAGE_ADDONS.ADDON_BANNER, name: 'Banner', textName: 'MENU.BANNER', active: false, path: routeName.banner },
  ABOUT_US: { key: 'ABOUT_US', packageKey: PACKAGE_ADDONS.ADDON_ABOUT_US, name: 'About us', textName: 'MENU.ABOUT_US', active: false, path: routeName.about_us },
  SERVICE: { key: 'SERVICE', packageKey: PACKAGE_ADDONS.ADDON_SERVICE, name: 'Service', textName: 'MENU.SERVICE', active: false, path: routeName.service },
  PRICE_LIST: { key: 'PRICE_LIST', packageKey: PACKAGE_ADDONS.ADDON_PRICE_LIST, name: 'Price list', textName: 'MENU.PRICE_LIST', active: false, path: routeName.price_list },
  REVIEW: { key: 'REVIEW', packageKey: PACKAGE_ADDONS.ADDON_REVIEW, name: 'Review', textName: 'MENU.REVIEW', active: false, path: routeName.review },
  ARTICLE: { key: 'ARTICLE', packageKey: PACKAGE_ADDONS.ADDON_ARTICLE, name: 'Article', textName: 'MENU.ARTICLE', active: false, path: routeName.article },
  CONTACT_US: { key: 'CONTACT_US', packageKey: PACKAGE_ADDONS.ADDON_CONTACT_US, name: 'Contact us', textName: 'MENU.CONTACT_US', active: false, path: routeName.contact_us },

  /** WAREHOUSE */
  MEDICINE: { key: 'MEDICINE', name: 'Medicine', textName: 'MENU.MEDICINE', active: false, path: routeName.medicine },
  PRODUCT: { key: 'PRODUCT', name: 'Product', textName: 'MENU.PRODUCT', active: false, path: routeName.product },
  IMPORT_PRODUCT: { key: 'IMPORT_PRODUCT', name: 'Import Product', textName: 'MENU.IMPORT_PRODUCT', active: false, path: routeName.importProduct },
  INVENTORY_LIST: { key: 'INVENTORY_LIST', name: 'Inventory', textName: 'MENU.INVENTORY_LIST', active: false, path: routeName.inventory },
  ADJUSTMENT: { key: 'ADJUSTMENT', name: 'Adjustment', textName: 'MENU.ADJUSTMENT', active: false, path: routeName.adjustment },

  /** SETTING */
  BRANCH_AND_SERVICE_POINT: { key: 'BRANCH_AND_SERVICE_POINT', textName: 'MENU.BRANCH_AND_SERVICE_POINT', active: false, path: '' },
  DEFAULT_USER_MANAGEMENT: { key: 'DEFAULT_USER_MANAGEMENT', textName: 'MENU.MANAGE_PERSONAL', active: false, path: '' },
  TREATMENT_RIGHT: { key: 'TREATMENT_RIGHT', textName: 'MENU.TREATMENT_RIGHT', active: false, path: routeName.treatmentRights },
  MANAGE_DOCTOR_FEE: { key: 'MANAGE_DOCTOR_FEE', textName: 'MENU.MANAGE_DOCTOR_FEE', active: false, path: '' },
  MANAGE_CREDIT_FEE: { key: 'MANAGE_CREDIT_FEE', textName: 'MENU.MANAGE_CREDIT_FEE', active: false, path: '' },
  TREATMENT: { key: 'TREATMENT', textName: 'MENU.MANAGE_TREATMENT', active: false, path: '/' },

  TREATMENT_PLAN_MANAGE: { key: '', packageKey: PACKAGE_ADDONS.ADDON_TREATMENT_PLAN_MANAGE, textName: 'MENU.TREATMENT_PLAN', active: false, path: '' },
  TREATMENT_COURSE_MANAGE: { key: '', packageKey: PACKAGE_ADDONS.ADDON_TREATMENT_COURSE_MANAGE, name: 'Treatment Course', textName: 'MENU.TREATMENT_COURSE', active: false, path: '' },
  DOCTOR_NOTE: { key: 'DOCTOR_NOTE', packageKey: PACKAGE_ADDONS.ADDON_DOCTOR_NOTE, name: 'MENU.DOCTOR_NOTE', textName: 'MENU.DOCTOR_NOTE', active: false, path: '' },
  LAB_MANAGEMENT: { key: 'LAB_MANAGEMENT', packageKey: PACKAGE_ADDONS.ADDON_LAB_MANAGEMENT, name: 'Lab Management', textName: 'MENU.MENAGE_LAB', active: false, path: '' },
  DEFAULT_PRODUCT: { key: 'DEFAULT_PRODUCT', name: 'Default Product', textName: 'MENU.MANAGE_DEFAULT_PRODUCTION', active: false, path: '' },
  FINANCES: { key: 'FINANCES', name: 'Finance', textName: 'MENU.MANAGE_FINANCE', active: false, path: '' },
  USER_MANAGEMENT: { key: 'USER_MANAGEMENT', name: 'Default User Management', textName: 'MENU.MANAGE_DEFAULT_PERSONAL', active: false, path: '' },
  DEFAULT: { key: 'DEFAULT', textName: 'MENU.OTHER_MANAGEMENT', active: false, path: '' },
  
  // EXTRA
  APPLICATION: { key: 'APPLICATION', name: 'Application', textName: 'MENU.APPLICATION_MANAGE', active: false },
  WEB_MANAGE:{key: 'WEB_MANAGE', name: 'Web Manage', textName: 'MENU.WEB_MANAGE', active: false }
}

export const menuThirds: any = {
  /*** SETTING */
  SERVICE_POINT: { key: 'SERVICE_POINT', name: 'Service Point', textName: 'MENU.SERVICE_POINT', active: false, path: routeName.servicePoint },
  BRANCH: { key: 'BRANCH', name: 'Branch', textName: 'MENU.BRANCH', active: false, path: routeName.branch },

  ROLE: { key: 'ROLE', name: 'roles', textName: 'MENU.ROLE', active: false, path: routeName.roles },
  REPORT_PERMISSION: { key: 'REPORT_PERMISSION', name: 'report permission', textName: 'MENU.REPORT_PERMISSION', active: false, path: routeName.reportPermission },
  POSITION: { key: 'POSITION', name: 'Position', textName: 'MENU.POSITION', active: false, path: routeName.positions },
  PERSONNEL: { key: 'PERSONNEL', name: 'Personals', textName: 'MENU.PERSONNEL', active: false, path: routeName.personnels },

  HAND_INSURANCES: { key: 'HAND_INSURANCES', name: 'Hand Insurance', textName: 'MENU.HAND_INSURANCES', active: false, path: routeName.handInsurances },
  TEMPLATE_DOCTOR_FEE: { key: 'TEMPLATE_DOCTOR_FEE', name: 'Template Doctor Fee', textName: 'MENU.TEMPLATE_DOCTOR_FEE', active: false, path: routeName.templateDoctorFee },
  DOCTOR_FEE: { key: 'DOCTOR_FEE', name: 'Doctor Fee', textName: 'MENU.DOCTOR_FEE', active: false, path: routeName.doctorFee },

  TEMPLATE_CREDIT_FEE: { key: 'TEMPLATE_CREDIT_FEE', name: 'Template Credit Fee', textName: 'MENU.TEMPLATE_CREDIT_FEE', active: false, path: routeName.templateCreditFee },
  CREDIT_FEE: { key: 'CREDIT_FEE', name: 'Credit Fee', textName: 'MENU.CREDIT_FEE', active: false, path: routeName.creditFee },

  CLINIC_FEE: { key: 'CLINIC_FEE', name: 'Clinic Fee', textName: 'MENU.CLINIC_FEE', active: false, path: routeName.clinicFees },
  RECOMMEND: { key: 'RECOMMEND', name: 'Recommend', textName: 'MENU.RECOMMEND', active: false, path: routeName.recommend },
  DIAGNOSIS_TYPE: { key: 'DIAGNOSIS_TYPE', name: 'Diagnosis Type', textName: 'MENU.DIAGNOSIS_TYPE', active: false, path: routeName.diagnosisType },
  DIAGNOSIS: { key: 'DIAGNOSIS', name: 'Diagnosis', textName: 'MENU.DIAGNOSIS', active: false, path: routeName.diagnosis },
  OPERATIVE_UNIT: { key: 'OPERATIVE_UNIT', name: 'Operative Unit', textName: 'MENU.OPERATIVE_UNIT', active: false, path: routeName.operativeUnits },
  OPERATIVE_TYPE: { key: 'OPERATIVE_TYPE', name: 'Operative Type', textName: 'MENU.OPERATIVE_TYPE', active: false, path: routeName.operativeTypes },
  OPERATIVE: { key: 'OPERATIVE', name: 'Operative', textName: 'MENU.OPERATIVE', active: false, path: routeName.operatives },
  XRAY_FILM_TYPE: { key: 'XRAY_FILM_TYPE', packageKey: PACKAGE_ADDONS.ADDON_XRAY_FILM_TYPE, name: 'X-Ray Film Type', textName: 'MENU.XRAY_FILM_TYPE', active: false, path: routeName.xrayFilmTypes },
  IMAGE_TYPE: { key: 'IMAGE_TYPE', name: 'Image Type', textName: 'MENU.IMAGE_TYPE', active: false, path: routeName.imageTypes },
  RECALL: { key: 'RECALL', name: 'รายการ Recall', textName: 'MENU.RECALL', active: false, path: routeName.recalls },
  TEMPLATE_DOCTOR_NOTE: { key: 'TEMPLATE_DOCTOR_NOTE', name: 'Doctor Note', textName: 'MENU.TEMPLATE_DOCTOR_NOTE', active: false, path: routeName.treatmentDoctorNote },

  TREATMENT_PLAN_TYPE: { key: 'TREATMENT_PLAN_TYPE', packageKey: PACKAGE_ADDONS.ADDON_TREATMENT_PLAN_TYPE, name: 'Treatment Plan Type', textName: 'MENU.TREATMENT_PLAN_TYPE', active: false, path: routeName.treatmentPlanTypes },
  TREATMENT_PLAN: { key: 'TREATMENT_PLAN', packageKey: PACKAGE_ADDONS.ADDON_TREATMENT_PLAN, name: 'Treatment Plan', textName: 'MENU.TREATMENT_PLAN', active: false, path: routeName.treatmentPlans },

  TREATMENT_COURSE_TYPE: { key: 'TREATMENT_COURSE_TYPE', packageKey: PACKAGE_ADDONS.ADDON_TREATMENT_COURSE_TYPE, name: 'Treatment Course Type', textName: 'MENU.TREATMENT_COURSE_TYPE', active: false, path: routeName.treatmentCourseTypes },
  TREATMENT_COURSE: { key: 'TREATMENT_COURSE', packageKey: PACKAGE_ADDONS.ADDON_TREATMENT_COURSE, name: 'Treatment Course', textName: 'MENU.TREATMENT_COURSE', active: false, path: routeName.treatmentCourse },

  DOCTOR_NOTE_TYPE_FORM: { key: 'DOCTOR_NOTE_TYPE_FORM', packageKey: PACKAGE_ADDONS.ADDON_DOCTOR_NOTE_TYPE_FORM, name: 'MENU.DOCTOR_NOTE_TYPE_FORM', textName: 'MENU.DOCTOR_NOTE_TYPE_FORM', active: false, path: routeName.doctorNoteTypeForm },
  DOCTOR_NOTE_FORM: { key: 'DOCTOR_NOTE_FORM', packageKey: PACKAGE_ADDONS.ADDON_DOCTOR_NOTE_FORM, name: 'MENU.DOCTOR_NOTE_FORM', textName: 'MENU.DOCTOR_NOTE_FORM', active: false, path: routeName.doctorNoteForm },
  DOCTOR_NOTE_TYPE_PIC: { key: 'DOCTOR_NOTE_TYPE_PIC', packageKey: PACKAGE_ADDONS.ADDON_DOCTOR_NOTE_TYPE_PIC, name: 'MENU.DOCTOR_NOTE_TYPE_PIC', textName: 'MENU.DOCTOR_NOTE_TYPE_PIC', active: false, path: routeName.doctorNoteTypePic },
  DOCTOR_NOTE_PIC: { key: 'DOCTOR_NOTE_PIC', packageKey: PACKAGE_ADDONS.ADDON_DOCTOR_NOTE_PIC, name: 'MENU.DOCTOR_NOTE_PIC', textName: 'MENU.DOCTOR_NOTE_PIC', active: false, path: routeName.doctorNotePic },

  LAB_LIST: { key: 'LAB_LIST', packageKey: PACKAGE_ADDONS.ADDON_LAB_LIST, name: 'Lab', textName: 'MENU.LAB_LIST', active: false, path: routeName.labs },
  LAB_TYPE: { key: 'LAB_TYPE', packageKey: PACKAGE_ADDONS.ADDON_LAB_TYPE, name: 'Lab Type', textName: 'MENU.LAB_TYPE', active: false, path: routeName.labType },
  LAB_GROUP: { key: 'LAB_GROUP', packageKey: PACKAGE_ADDONS.ADDON_LAB_GROUP, name: 'Lab Group', textName: 'MENU.LAB_GROUP', active: false, path: routeName.labGroup },
  LAB_COMPANY: { key: 'LAB_COMPANY', packageKey: PACKAGE_ADDONS.ADDON_LAB_COMPANY, name: 'Lab Company', textName: 'MENU.LAB_COMPANY', active: false, path: routeName.labCompany },

  MEDICINE_TYPE: { key: 'MEDICINE_TYPE', name: 'Medicine Type', textName: 'MENU.MEDICINE_TYPE', active: false, path: routeName.medicineTypes },
  MEDICINE_FORMAT: { key: 'MEDICINE_FORMAT', name: 'Medicine Format', textName: 'MENU.MEDICINE_FORMAT', active: false, path: routeName.medicineFormats },
  MEDICINE_UNIT: { key: 'MEDICINE_UNIT', name: 'Medicine Unit', textName: 'MENU.MEDICINE_UNIT', active: false, path: routeName.medicineUnits },
  MEDICINE_INSTRUCTION: { key: 'MEDICINE_INSTRUCTION', name: 'Medicine Instruction', textName: 'MENU.MEDICINE_INSTRUCTION', active: false, path: routeName.medicineInstructions },
  MEDICINE_USE: { key: 'MEDICINE_USE', name: 'Medicine Use', textName: 'MENU.MEDICINE_USE', active: false, path: routeName.productUses },
  MEDICINE_PRESERVE: { key: 'MEDICINE_PRESERVE', name: 'Medicine Preserve', textName: 'MENU.MEDICINE_PRESERVE', active: false, path: routeName.medicinePreserves },
  PRODUCT_TYPE: { key: 'PRODUCT_TYPE', name: 'Product Type', textName: 'MENU.PRODUCT_TYPE', active: false, path: routeName.productTypes },
  PRODUCT_UNIT: { key: 'PRODUCT_UNIT', name: 'Product Unit', textName: 'MENU.PRODUCT_UNIT', active: false, path: routeName.productUnits },
  PRODUCT_INSTRUCTION: { key: 'PRODUCT_INSTRUCTION', name: 'Product Instruction', textName: 'MENU.PRODUCT_INSTRUCTION', active: false, path: routeName.productInstructions },
  PRODUCT_PRESERVE: { key: 'PRODUCT_PRESERVE', name: 'Product Preserve', textName: 'MENU.PRODUCT_PRESERVE', active: false, path: routeName.productPreserves },
  MEDICINE_SYMPTOM: { key: 'MEDICINE_SYMPTOM', name: 'Medicine Symptom', textName: 'MENU.MEDICINE_SYMPTOM', active: false, path: routeName.medicineSymptoms },

  BANK: { key: 'BANK', name: 'Banks', textName: 'MENU.BANK', active: false, path: routeName.banks },
  CARD_TYPE: { key: 'CARD_TYPE', name: 'Card Types', textName: 'MENU.CARD_TYPE', active: false, path: routeName.cardTypes },
  CURRENCY: { key: 'CURRENCY', name: 'Currencies', textName: 'MENU.CURRENCY', active: false, path: routeName.currencies },
  EXPENSE_TYPE: { key: 'EXPENSE_TYPE', name: 'Expense Types', textName: 'MENU.EXPENSE_TYPE', active: false, path: routeName.expenseTypes },

  PREFIX: { key: 'PREFIX', name: 'Prefixes', textName: 'MENU.PREFIX', active: false, path: routeName.prefixes },
  CAREER: { key: 'CAREER', textName: 'MENU.CAREER', active: false, path: routeName.careers },
  EXPERT: { key: 'EXPERT', textName: 'MENU.EXPERT', active: false, path: routeName.experts },
  ACCESS_CHANNEL: { key: 'ACCESS_CHANNEL', textName: 'MENU.ACCESS_CHANNEL', active: false, path: routeName.accessChannels },
  SERVICE_FACTOR: { key: 'SERVICE_FACTOR', textName: 'MENU.SERVICE_FACTOR', active: false, path: routeName.serviceFactor },
  PATIENT_TYPE: { key: 'PATIENT_TYPE', textName: 'MENU.PATIENT_TYPE', active: false, path: routeName.patientTypes },
  FREQUENCIES_ALCOHOL: { key: 'FREQUENCIES_ALCOHOL', textName: 'MENU.FREQUENCIES_ALCOHOL', active: false, path: routeName.frequenciesAlcohols },
  FREQUENCIES_CIGARETTE: { key: 'FREQUENCIES_CIGARETTE', textName: 'MENU.FREQUENCIES_CIGARETTE', active: false, path: routeName.frequenciesCigarettes },

  TEMPLATE_TEXT: { key: 'TEMPLATE_TEXT', textName: 'MENU.TEMPLATE_TEXT', active: false, path: routeName.templateText },
  APPOINTMENT_DURATION: { key: 'APPOINTMENT_DURATION', textName: 'MENU.APPOINTMENT_DURATION', active: false, path: routeName.appointmentDurations },
  KEYWORD: { key: 'KEYWORD', textName: 'MENU.KEYWORD', active: false, path: routeName.keyword },

  TREATMENT_RIGHT: { key: menuSeconds.TREATMENT_RIGHT.key, name: 'Treatment Right', text: 'MENU.TREATMENT_RIGHT', active: false, path: routeName.treatmentRight },

  // EXTRA
  REGISTER_APP: { key: 'REGISTER_APP', packageKey: PACKAGE_ADDONS.ADDON_REGISTER_APP, name: 'Register app', textName: 'MENU.REGISTER_APP', active: false, path: routeName.registerApp },
  PROMOTIONS: { key: 'PROMOTIONS', packageKey: PACKAGE_ADDONS.ADDON_PROMOTIONS, name: 'Promotions', textName: 'MENU.PROMOTIONS', active: false, path: routeName.promotions },
  NEWS: { key: 'NEWS', packageKey: PACKAGE_ADDONS.ADDON_NEWS, name: 'News', textName: 'MENU.NEWS', active: false, path: routeName.news },
  // WEB
  BANNER: { key: 'BANNER', packageKey: PACKAGE_ADDONS.ADDON_BANNER, name: 'Banner', textName: 'MENU.BANNER', active: false, path: routeName.banner },
  ABOUT_US: { key: 'ABOUT_US', packageKey: PACKAGE_ADDONS.ADDON_ABOUT_US, name: 'About us', textName: 'MENU.ABOUT_US', active: false, path: routeName.about_us },
  SERVICE: { key: 'SERVICE', packageKey: PACKAGE_ADDONS.ADDON_SERVICE, name: 'Service', textName: 'MENU.SERVICE', active: false, path: routeName.service },
  PRICE_LIST: { key: 'PRICE_LIST', packageKey: PACKAGE_ADDONS.ADDON_PRICE_LIST, name: 'Price list', textName: 'MENU.PRICE_LIST', active: false, path: routeName.price_list },
  REVIEW: { key: 'REVIEW', packageKey: PACKAGE_ADDONS.ADDON_REVIEW, name: 'Review', textName: 'MENU.REVIEW', active: false, path: routeName.review },
  ARTICLE: { key: 'ARTICLE', packageKey: PACKAGE_ADDONS.ADDON_ARTICLE, name: 'Article', textName: 'MENU.ARTICLE', active: false, path: routeName.article },
  CONTACT_US: { key: 'CONTACT_US', packageKey: PACKAGE_ADDONS.ADDON_CONTACT_US, name: 'Contact us', textName: 'MENU.CONTACT_US', active: false, path: routeName.contact_us },
}

export const menuInside: any = {
  /** DOCTOR */
  DOCTOR_TREATMENTS_FORM: { key: menuSeconds.DOCTOR_TREATMENTS.key, textName: 'การรักษา(ฟอร์ม)', active: false, path: routeName.treatmentsForm, badge: false },
  DOCTOR_APPOINTMENTS_HISTORY: { key: menuSeconds.DOCTOR_TREATMENTS.key, textName: 'การรักษา(ประวัติการนัดหมาย)', active: false, path: routeName.treatmentsAppointmentHistory, badge: false },
  DOCTOR_EMR_HISTORY: { key: 'EMR_HISTORY', textName: 'การรักษา(ประวัติการรักษา)', active: false, path: routeName.treatmentsEmrHistory, badge: false },

  /** SETTING */
  HISTORY_DETAIL_DF: { key: menuThirds.DOCTOR_FEE.key, textName: 'รายละเอียดค่า DF/LAB', active: false, path: routeName.doctorFeeDetail, badge: false },

  /** FINANCE */
  PAYMENT_DETAIL: { key: menuSeconds.PAYMENT.key, textName: 'ชำระค่าใช้จ่าย(รายละเอียด)', active: false, path: routeName.paymentDetail, badge: false },
  OVERDUE_DETAIL: { key: menuSeconds.OVERDUE.key, textName: 'ค้างชำระ', active: false, path: routeName.overdueDetail, badge: false }
}
