import { Grid, Typography, Box, styled } from '@mui/material';

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import ButtonCustom from 'component/Button/ButtonCustom'
import { logout } from 'utils/app.utils'

/** STYLE */
import { Title } from 'features/page/style';
import { imgs } from 'constants/images'
import { templateImage } from 'constants/theme';

export const CustomTitle = styled(Title)(({ theme }) => ({
  fontSize: 28,
  [theme.breakpoints.up('xl')]: {
    fontSize: 34,
  }
}))

export const CustomImgExpired = styled(Box)(({ theme }) => ({
  maxWidth: '100%',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '375px'
  }
}))

export default function PageExpiredWebsite() {
  const { t } = useTranslation()

  return (
    <div className='h-100 p-3 d-flex align-items-center d-xl-block'>
      <Grid container justifyContent={'center'} sx={{ height: { lg: '100%' } }}>
        <Grid item lg={5} className='w-100 d-flex align-items-center justify-content-center d-flex'>
          <CustomImgExpired>
            <img src={templateImage.welcome} alt="img-imgWelcome" className={'w-100'} />
          </CustomImgExpired>
        </Grid>
        <Grid item lg={4} className='w-100 d-flex flex-column align-items-center justify-content-xl-center mt-xl-5 mt-xl-0'>
          <CustomTitle className='text-center'>{t('EXPIRED_ALERT.EXPIRED_TITLE')}</CustomTitle>
          <Typography className='mt-1' sx={{ fontSize: '18px' }}>{t('EXPIRED_ALERT.EXPIRED_SUBTITLE')}</Typography>
          <ButtonCustom
            textButton={t('ติดต่อเจ้าหน้าที่, เข้าสู่ระบบอีกครั้ง')}
            onClick={() => {
              logout()
              window.location.reload()
            }}
            className="mt-4"
            style={{ margin: 0 }} btnStyle={{ fontSize: '16px' }} />
        </Grid>
      </Grid>
    </div>
  )
}
