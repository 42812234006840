import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** ICON || IMAGE */
import closePopup from 'assets/icon/appointment/close.svg'

/** COMPONENT */

import ModalCustom from 'component/ModalCustom/ModalCustom'
import { TabContext } from '@mui/lab'
import { MenuItem, styled } from '@mui/material'
import { colors } from 'constants/theme'
import { Col, Modal, Row } from 'react-bootstrap'
import { resetModal } from 'app/slice/modal.slice'
import UploadImage from 'component/Upload/UploadImage'

import { fileUrl } from 'api/api'

import InputTextSearch from 'component/Input/InputTextSearch'
import ImageDoctorNoteType from 'api/setting/doctor-note/image-doctor-note-type.api'

import FilterSelect from 'component/Select/FilterSelect'
import ImageDoctorNoteApi from 'api/setting/doctor-note/image-doctor-note.api'
import TemplateFormsTypeApi from 'api/setting/doctor-note/template-form-type.api'
import TemplateFormsApi from 'api/setting/doctor-note/template-forms.api'
import { TablePaginationMiniCustom } from 'component/Table/TableCustom'
import { Config } from 'config'

/** STYLE */
const modalStyle = styled(Modal)(({ theme }) => ({
  zIndex: theme.zIndex.modal,
  '& .modal-content': {
    padding: '1rem 1.5rem 1.5rem',
    backdropFilter: 'saturate(1) blur(40px)',
    background: `linear-gradient(
            to bottom,
            #1CB99A 0%,
            #1CB99A 65px,
            #fff 65px,
            #fff 100%
          )`,
    border: 'none !important',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem'
    }
  },
  '& .modal-title': {
    width: '100%',
    h3: {
      textAlign: 'start',
      fontSize: 20,
      fontWeight: 600,
      color: colors.white,
      '&.header-danger': {
        color: colors.textPrimary
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 18
      }
    }
  },
  '& .css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled': {
    opacity: 0.5
  },
  '& .modal-footer': {
    display: 'flex',
    flexDirection: 'row',
    '&:not(.footer-inline)': {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    }
  },
  '.btn-close': {
    position: 'absolute',
    top: '16px',
    right: '24px',
    boxShadow: 'none !important',
    background: `url(${closePopup}) center/1em auto no-repeat`,
    opacity: 0.6,
    '&:hover': {
      opacity: 1
    },
    [theme.breakpoints.down('xs')]: {
      right: '16px'
    }
  }
}))

const DivTemplatePopup = styled('div')(({ theme }) => ({
  minHeight: 500,
  '& .template-select': {
    '&.active': { border: `2px solid ${colors.themeSecondColor}` },
    '& img': { height: 150, width: 150, objectFit: 'cover', border: `1px solid ${colors.lightGray}` }
  }
}))

const DivUploadImage = styled('div')(({ theme }) => ({
  margin: '0 0 0 0',
  maxWidth: 500,
  width: 160,
  height: 174,
  '& > div.MuiFormControl-root': {
    height: 150,
    '& > div': {
      padding: '0 !important',
      width: 'auto !important',
      height: '150px !important',
      img: {
        width: '100%',
        height: '150px',
        objectFit: 'contain'
      }
    }
  },
  [theme.breakpoints.down('xs')]: {
    maxWidth: 180
  }
}))

/** INTERFACE */
interface TemplateDoctorNote {
  url: any
  name?: any
}

export interface TemplatePopupProps {
  isShow: boolean
  canvasRef?: any
  insertMode?: 'image' | 'template'
  onInsert: (img: HTMLImageElement, insertMode: any) => void
  onCancel?: () => void
  onUploadImg?: (img: any) => void
}

export function TemplatePopup(props: TemplatePopupProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [tab] = useState('')
  const [tabForm] = useState('TEMPLATE')
  const [selectInsert, setSelectInsert] = useState<any>('')

  const [templateDoctorNotes, setTemplateDoctorNotes] = useState<TemplateDoctorNote[]>([])
  const [templateDoctorNotesTypes, setTemplateDoctorNotesTypes] = useState<any>([])
  const [imageDoctorNote, setImageDoctorNote] = useState<TemplateDoctorNote[]>([])
  const [imageDoctorNoteType, setImageDoctorNoteType] = useState<string>()
  const [bufferImageDoctorNoteType, setBufferImageDoctorNoteType] = useState<string>()
  const [imageDoctorNoteTypes, setImageDoctorNoteTypes] = useState<any>([])
  const [imageData, setImageData] = useState<any>()

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy] = useState('')
  const [sortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [status] = useState('ACTIVE')
  const [templateFormTypeId, setTemplateFormTypeId] = useState('')


  const uploadImage = (files: any) => {
    if (props.onUploadImg) {
      props.onUploadImg(files)
      cancel()
    }
  }

  useEffect(() => {
    if (props.insertMode === 'image') setPageLimit(17)
    if (props.insertMode === 'template') setPageLimit(8)
  }, [props.insertMode])

  const loadData = async (inputPage?: number) => {
    let pageLimit
    if (props.insertMode === 'image') pageLimit = 17
    if (props.insertMode === 'template') pageLimit = 8
    let condition: any = {}
    if (page) condition = { ...condition, page: inputPage || page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status }
    if (templateFormTypeId) condition = { ...condition, templateFormTypeId: templateFormTypeId }
    if (templateFormTypeId) condition = { ...condition, doctorNoteTypeId: templateFormTypeId }

    if (props.insertMode === 'image') {
      await ImageDoctorNoteApi.findAll(condition).then((res: any) => {
        if (res.status === 200) {
          setRowCount(res.headers['x-total'])
          setImageDoctorNote([...res.data.map((img: any, i: any) => ({ url: `${fileUrl}/${img.image.filePath}`, name: img.name }))])
        }
      })
      await ImageDoctorNoteType.findAll({ ...condition, page: 1, search: '' }).then((res: any) => {
        setImageDoctorNoteTypes(res.data)
      })
    } else {
      await TemplateFormsApi.findAll(condition).then((res: any) => {
        if (res.status === 200) {
          setRowCount(res.headers['x-total'])
          setTemplateDoctorNotes(res.data)
        }
      })
      await TemplateFormsTypeApi.findAll({ ...condition, page: 1, search: '' }).then((res: any) => {
        setTemplateDoctorNotesTypes(res.data)
      })
    }
    if (inputPage) {
      setPage(inputPage)
    }
  }

  const replaceUrlToBase64 = () => {
    setTimeout(() => {
      _.map(props.insertMode === 'image' ? imageDoctorNote : templateDoctorNotes, async (templateDoctorNote: any, i: any) => {
        const editImg: any = document.getElementById(`template-IMAGE_${i}`)
        if (editImg)
          toDataUrl(templateDoctorNote.url, (myBase64: any) => {
            editImg.src = myBase64
          })
      })
    }, 1000)
  }

  useEffect(() => {
    replaceUrlToBase64()
  }, [templateDoctorNotes, imageDoctorNote])

  useEffect(() => {

    setImageData(undefined)
    if (imageDoctorNoteType !== bufferImageDoctorNoteType) {
      setBufferImageDoctorNoteType(imageDoctorNoteType)
      loadData(1)
    } else {
      loadData()
    }
  }, [props.isShow, page, pageLimit, search, sortBy, sortType, status, imageDoctorNoteType])



  const toDataUrl = async (url: any, callback: any) => {
    const xhr = new XMLHttpRequest()

    xhr.onload = function () {
      const reader = new FileReader()
      reader.onloadend = function () {
        callback(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  }

  const cancel = async () => {
    setSearch('')
    setSelectInsert('')
    setImageDoctorNoteType(undefined)
    setTemplateFormTypeId('')
    setPage(1)
    dispatch(resetModal())
    return props.onCancel
  }

  const submit = async () => {
    let selecterImg = `template-${selectInsert}`
    if (tabForm === 'UPLOAD') selecterImg = `upload-image-template`
    const img: any = document.getElementById(selecterImg)
    setSearch('')
    setSelectInsert('')
    setImageDoctorNoteType(undefined)
    setTemplateFormTypeId('')
    setPage(1)
    props.onInsert(img, props.insertMode)
  }

  return props.isShow ? (
    <ModalCustom
      changeStyle={modalStyle}
      size={'xl'}
      title={props.insertMode === 'image' ? t('DOCTOR_NOTE.MODAL.TITLE_INSERT') : t('DOCTOR_NOTE.MODAL.TITLE_TEM')}
      alignFooter={'end'}
      fullscreen={'lg-down'}
      component={
        <DivTemplatePopup style={{}}>
          <TabContext value={tab}>
            <Row style={{ marginTop: '20px' }}>
              <Col>
                <InputTextSearch
                  keyInput={'search'}
                  label={t('INPUT.SEARCH')}
                  value={search}
                  onchange={(event) => {
                    setSearch(event.target.value)
                    setPage(1)
                  }}
                  onClear={(event) => {
                    setSearch('')
                    setPage(1)
                  }}
                />
              </Col>
              <Col>
                <FilterSelect
                  onchange={(event) => {
                    const value = event.target.value
                    setTemplateFormTypeId(value)

                  }}
                  renderValue={() => {
                    const checkImageDoctorNoteType = () => {
                      if (imageDoctorNoteType === ' ') {
                        return t('ALL')
                      } else {
                        return imageDoctorNoteType
                      }
                    }
                    return (imageDoctorNoteType === undefined ? `${t('TXC.TYPE')} : ${t('ALL')}` : `${t('TXC.TYPE')}  : ${checkImageDoctorNoteType()}`)
                  }
                  }
                  label={t('STATUS.LABEL')}
                  selectId="select-status"
                  value={`${status}`}
                  labelId="label-status"
                  options={[
                    <MenuItem className="py-1" key={0} value={0} onClick={() => { setImageDoctorNoteType(t('ALL')) }}>
                      {t('ALL')}
                    </MenuItem>,
                    props.insertMode === 'image'
                      ? _.map(imageDoctorNoteTypes, (imageDoc: any, i: number) => (
                        <MenuItem className="py-1" key={imageDoc.doctorNoteTypeId} value={imageDoc.doctorNoteTypeId} onClick={() => setImageDoctorNoteType(imageDoc.typeName)}>
                          {imageDoc.typeName}
                        </MenuItem>
                      ))
                      : _.map(templateDoctorNotesTypes, (imageDoc: any, i: number) => (
                        <MenuItem className="py-1" key={imageDoc.templateFormTypeId} value={imageDoc.templateFormTypeId} onClick={() => setImageDoctorNoteType(imageDoc.typeName)}>
                          {imageDoc.typeName}
                        </MenuItem>
                      ))
                  ]}
                />
              </Col>
            </Row>
            <div className="custom-scroll p-3" style={{ display: tabForm === 'TEMPLATE' ? 'block' : 'none', overflowY: 'hidden', height: props.insertMode === 'template' ? '680px' : '555px' }}>
              <Row className="mx-0">
                {props.insertMode === 'image' && (
                  <DivUploadImage>
                    <UploadImage setValue={uploadImage} pathFile={imageData} canDelete onDelete={() => setImageData(undefined)} />
                  </DivUploadImage>
                )}
                {_.map(props.insertMode === 'image' ? imageDoctorNote : templateDoctorNotes, (templateDoctorNote: any, iDn: number) => {
                  const checkSelectInsert = () => {
                    if (selectInsert === `IMAGE_${iDn}` || selectInsert === `FORM_${iDn}`) {
                      return 'active'
                    } else {
                      return ''
                    }
                  }
                  return (
                    <Col
                      className={`col-auto px-0 mx-1 `}
                      style={{ width: props.insertMode === 'image' ? '' : '24%' }}
                      onClick={() => (props.insertMode === 'image' ? setSelectInsert(`IMAGE_${iDn}`) : setSelectInsert(`FORM_${iDn}`))}
                    >
                      <Col key={iDn} style={{ height: props.insertMode === 'image' ? '' : '300px' }} className={`template-select ${checkSelectInsert()}`}>
                        {props.insertMode === 'image' ? (
                          <img id={`template-IMAGE_${iDn}`} src={templateDoctorNote.url} alt={`template-IMAGE_${iDn}`} />
                        ) : (
                          <div style={{ position: 'relative', float: 'left', height: '300px', overflow: 'hidden' }}>
                            <iframe
                              id={`template-FORM_${iDn}`}
                              style={{ minHeight: '1000vh', maxHeight: '1000vh', width: '100%', maxWidth: '120%', overflow: 'hidden' }}
                              src={`${Config.REACT_APP_IMAGE_URL}/${templateDoctorNote.image.filePath}#toolbar=0&navpanes=0`}
                            />
                            <div className={`template-select ${checkSelectInsert()}`} style={{ top: 0, left: 0, width: '100%', height: '100%', position: 'absolute' }}></div>
                          </div>
                        )}
                      </Col>
                      {templateDoctorNote.name}
                    </Col>
                  )
                })}
              </Row>
            </div>
          </TabContext>
          <div className={'mt-0 '}>
            <TablePaginationMiniCustom
              count={rowCount || 0}
              page={page}
              rowsPerPage={pageLimit}
              rowCount={rowCount}
              pageLimit={pageLimit}
              headCells={[]}
              onPageChange={(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage)}
              setPageLimit={(event: any) => {
                setPage(1)
              }}
              setPage={setPage}
            />
          </div>
        </DivTemplatePopup>
      }
      onSubmit={submit}
      onReset={cancel}
      disabledSubmit={!selectInsert}
      autoResetModal={false}
      textBtnCancel={t('DOCTOR_NOTE.BUTTON.CANCEL')}
      textBtnConfirm={t('DOCTOR_NOTE.BUTTON.SAVE_INSERT')}
    />
  ) : (
    <></>
  )
}
