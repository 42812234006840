
const Line = (props: any) => {
    return (
        <>
            <svg
                width="1"
                height={props?.height || "36"}
                viewBox="0 0 1 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <line x1="0.25" y1="0.666504" x2="0.25" y2="35.1332" stroke="white" strokeWidth="0.5" />
            </svg>
        </>
    )
}

export default Line