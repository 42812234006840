import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface DiagnosisTypeInterface {
  diagnosisTypeName: string
  diagnosisTypeNameEn?: string
  status: string
}

export default class DiagnosisTypeApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('diagnosis-types', { params: { ...props } }).then((res) => res)
  }

  static create(playload: any) {
    return this.api.post('diagnosis-types', playload)
  }

  static findById(id: number): Promise<DiagnosisTypeInterface> {
    return this.api.get(`diagnosis-types/${id}`)
  }

  static update(id: number, body: DiagnosisTypeInterface) {
    return this.api.patch(`diagnosis-types/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`diagnosis-types/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`diagnosis-types/${id}`)
  }
}
