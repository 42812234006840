import { useEffect, useState } from 'react'
import { Box, FormControl, Grid, RadioGroup, FormControlLabel, Typography, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

/** API */
import PaymentChannelsApi from 'api/setting/finances/payment-channels.api'

/** COMPONENT */
import InputTextField from 'component/Input/InputTextField'
import FilterSelect from 'component/Select/BasicSelect'
import { ButtonDeleteSimple } from 'component/Button/ButtonDelete'
import { BillPrefixesType, PaymentChannelsType, BILL_TYPE } from 'features/admin/clinic-management/clinic-management'
import InputRadio from 'component/Input/InputRadio'

/** STYLE */
import * as UseStyles from 'features/admin/clinic-management/style'
import CheckIcon from '@mui/icons-material/Check';

type FormPrefixType = {
  paymentMethods:any
  billPrefixes?: BillPrefixesType[]
  callbackBillPrefixes: (items: BillPrefixesType[]) => void
  errorMessage: {
    EMPTY_PREFIX: string
    EMPTY_CHANNELS: string
  }
  resetErrorMessage: () => void
}

export default function FormPrefix(props: FormPrefixType) {
  const { t } = useTranslation()
  const {paymentMethods} = props

  const [billPrefixes, setBillPrefixes] = useState<BillPrefixesType[]>([]);

  const handleChange = (name: 'prefix' | 'paymentChannels' | 'billType', no: number, value: any) => {
    const items: BillPrefixesType[] = _.merge([], billPrefixes)
    items[no][name] = value
    setBillPrefixes(items);
    props.callbackBillPrefixes && props.callbackBillPrefixes(items)
    props.resetErrorMessage()
  }

  const handleNewRow = () => {
    setBillPrefixes([...billPrefixes, { prefix: '', paymentChannels: [], billType: BILL_TYPE.RECEIPT }])
    props.callbackBillPrefixes && props.callbackBillPrefixes([...billPrefixes, { prefix: '', paymentChannels: [], billType: BILL_TYPE.RECEIPT }])
    props.resetErrorMessage()
  }

  const handleDiscard = (no: number) => {
    const items = _.filter(billPrefixes, (m, index) => index !== no)
    setBillPrefixes(items)
    props.callbackBillPrefixes && props.callbackBillPrefixes(items)
    props.resetErrorMessage()
  }

  const renderFilterSelect = (data: PaymentChannelsType[]) => {
    const items = _.map(data, (d) => { return d.paymentChannelName || _.get(_.find(paymentMethods, { paymentChannelId: d.paymentChannelId }), 'paymentChannelName' || 'paymentChannelNameEn') })
    return !_.isEmpty(items) ? items.toString().replaceAll(',', ', ') : []
  }

  const paymentChannels = (data: BillPrefixesType[]) => {
    let items: PaymentChannelsType[] = []
    _.map(data, (m: BillPrefixesType) => { return items = [...items, ...m.paymentChannels] })
    return items
  }

  useEffect(() => {
    setBillPrefixes(props.billPrefixes || [])
  }, [props.billPrefixes]);

  return (
    <div className="px-4 pt-4 pb-4">
      <UseStyles.TitleFirst className={`d-flex align-items-center`}>{t('CLINIC_MANAGEMENT.TITLE.SETTING_PREFIX')}</UseStyles.TitleFirst>

      {_.map(billPrefixes, (method: BillPrefixesType, index: number) => {
        return (
          <Grid key={index} container spacing={2} alignItems="start" className="mt-0">
            <Grid item sm={4} md={3} lg={2} className={`w-100 d-flex`}>
              <Typography className='pr-3 pt-2' sx={{ flex: 'none' }}>Prefix</Typography>
              <InputTextField onchange={(e: any) => handleChange('prefix', index, e.target.value)} value={method.prefix} helperText={!method.prefix ? props.errorMessage.EMPTY_PREFIX : ''} />
            </Grid>
            <Grid item xs={11} sm={7} lg={6} className={`w-100 d-flex flex-column flex-sm-row`}>
              <Typography className='pr-3 pt-sm-2 pb-1 pb-md-0' sx={{ flex: 'none' }}>{t('CLINIC_MANAGEMENT.FORM.DEFAULT_CHANNEL')}</Typography>
              <FilterSelect
                multiple
                label={t('')}
                labelId="label-prefix"
                selectId="select-prefix"
                onchange={(e: any) => {
                  if (_.some(method.paymentChannels, { paymentChannelId: _.last(e.target.value) || _.get(_.last(e.target.value), 'paymentChannelId') })) {
                    const i: any = _.filter(method.paymentChannels, (v: PaymentChannelsType) => {
                      if (v.paymentChannelId !== _.last(e.target.value) || _.get(_.last(e.target.value), 'paymentChannelId')) return v
                    })
                    handleChange('paymentChannels', index, i)
                  } else {
                    let value: PaymentChannelsType[] = []
                    _.map(e.target.value, (val: PaymentChannelsType) => {
                      value = [...value, { paymentChannelId: val.paymentChannelId | Number(val) }]
                    })
                    handleChange('paymentChannels', index, value)
                  }
                }}
                renderValue={() => renderFilterSelect(method.paymentChannels)}
                value={method.paymentChannels}
                options={
                  _.map(paymentMethods, (channel: any, indexChannel: number) => {
                    return (
                      <MenuItem
                        key={indexChannel}
                        value={channel.paymentChannelId}
                        sx={{
                          '&.MuiMenuItem-root': {
                            display: _.some(paymentChannels(billPrefixes), { paymentChannelId: channel.paymentChannelId }) && !_.some(method.paymentChannels, { paymentChannelId: channel.paymentChannelId }) ? `none !important` : 'flex !important',
                            justifyContent: 'space-between !important'
                          }
                        }}>
                        {channel.paymentChannelName || channel.paymentChannelNameEn}
                        {_.some(method.paymentChannels, { paymentChannelId: channel.paymentChannelId }) && (<CheckIcon />) || (<></>)}
                      </MenuItem>
                    )
                  })
                }
                formControlClass={'not-max-width'}
                classesOption="style-select-doctors"
              />
            </Grid>
            <Grid item className="w-auto d-none">
              <FormControl>
                <RadioGroup
                  row
                  name="row-radio-buttons-group"
                  onChange={(e: any) => handleChange('billType', index, e.target.value)}>
                  <FormControlLabel checked={method.billType === BILL_TYPE.RECEIPT} value={BILL_TYPE.RECEIPT} label={t('CLINIC_MANAGEMENT.FORM.BILL_TYPE.RECEIPT')} control={<InputRadio />} />
                  <FormControlLabel checked={method.billType === BILL_TYPE.INVOICE} value={BILL_TYPE.INVOICE} label={t('CLINIC_MANAGEMENT.FORM.BILL_TYPE.INVOICE')} control={<InputRadio />} />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item className="w-auto">
              <Box
                className={`${index === 0 ? 'pe-none' : ''}`}
                sx={{
                  marginTop: '6px',
                  filter: index === 0 ? 'grayscale(1)' : 'none',
                  opacity: index === 0 ? .6 : 1
                }}
              >
                <ButtonDeleteSimple disabled onClick={() => handleDiscard(index)} />
              </Box>
            </Grid>
          </Grid>
        )
      })}

      <UseStyles.ButtonAddPrefix
        disabled={
          _.some(billPrefixes, (method: BillPrefixesType) => !method.prefix) ||

          _.some(billPrefixes, (method: BillPrefixesType) => !method.billType)

        }
        className={`w-max d-block`}
        onClick={handleNewRow}>
        + <span className='align-middle'>{t('CLINIC_MANAGEMENT.FORM.ADD_PREFIX')}</span>
      </UseStyles.ButtonAddPrefix>
    </div>
  )
}
