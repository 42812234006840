import { useState, useEffect, useCallback } from 'react'
import { numberFormat, toBuddhistYear } from 'utils/app.utils'
import { ExportProps } from 'features/report/report-constants'
import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import _ from 'lodash'
import * as UseStyled from 'features/report/useStyled'
import { dateTimeToView } from 'utils/date.utils'
import { useTranslation } from 'react-i18next'
import { TableHead, TableSortLabel } from '@mui/material'
import moment from 'moment'

import { UseSnCode } from 'utils/useSnCode'
interface FcComponent {
    page: number
    pageLimit: number
    rowCount: number
    sortBy: string
    sortType: string
    data: any
    onRequestSort: (sortBy: string, sortType: string) => void
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
    componentRef: any
    exportData: any
    filter: any
}

export default function TreatmentRightList(props: FcComponent) {
    const [snType, setSnType] = useState(false)

    const handleSnCode = useCallback(() => {
        const fetchData = async () => {
            const sn = await UseSnCode()
            setSnType(sn)
        }

        fetchData()
    }, [snType])

    useEffect(() => {
        handleSnCode()
    }, [])
    const headCells = [
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_MONTH.#NO', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_MONTH.DATE', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_MONTH.NO_RECEIPT', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'APPLICATION.TABLE.CELL.CN_NUMBER', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'PATIENT_MANAGEMENT.TABLE.CELL.BRANCH_ID', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'PATIENT_MANAGEMENT.TABLE.CELL.SN_CODE', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_MONTH.PATIENT_NAME', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_MONTH.TREATMENT_RIGHT', align: 'left', class: 'text-nowrap' },
        { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_MONTH.TOTAL_TREAMENT', align: 'right', class: 'text-nowrap' },
        { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TREATMENT_RIGHT_MONTH.TOTAL_RECEIPT', align: 'right', class: 'text-nowrap' }
    ]
    if (!snType) {
        headCells.splice(5, 1); // Remove the item at index 5 when the condition is met
    }
    const renderData = (data: any, no: number, exportData: boolean) => {
        const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1

        const objRenderData = {
            key: num.toString(),
            id: no.toString(),
            obj: data,
            columns: [
                { option: 'TEXT', align: 'left', label: num },
                { option: 'TEXT', align: 'left', label: dateTimeToView(data.paidDateTime), class: 'text-nowrap' },
                { option: 'TEXT', align: 'left', label: data.billNo, class: 'text-nowrap' },
                { option: 'TEXT', align: 'left', label: data.cnNumber || '-' },
                { option: 'TEXT', align: 'left', label: data.branchCnNumber || '-' },
                { option: 'TEXT', align: 'left', label: data.snCode || '-' },
                { option: 'TEXT', align: 'left', label: data.patientFullname },
                { option: 'TEXT', align: 'left', label: data.paymentChannelDetail },
                { option: 'TEXT', align: 'right', label: numberFormat(data.sumTrPaid) },
                { option: 'TEXT', align: 'right', label: numberFormat(data.totalPrice) }
            ]
        }
        if (!snType) {
            objRenderData.columns.splice(5, 1)

        }
        return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    }

    return (
        <>
            <UseStyled.ResponsiveReportTable>
                <TableCustom
                    page={props.page}
                    pageLimit={props.pageLimit}
                    sortType={props.sortType}
                    sortBy={props.sortBy}
                    rowCount={props.rowCount}
                    onSort={props.onRequestSort}
                    setPageLimit={props.handleChangeRowsPerPage}
                    setPage={props.handleChangePage}
                    headCells={headCells}
                    rowsData={_.map(props.data, (d: any, index: number) => {
                        return renderData(d, index, false)
                    })}
                    customScroll
                />
            </UseStyled.ResponsiveReportTable>
            <div className="print-show" ref={props.componentRef}>
                <TreatmentRightListExport
                    filter={props.filter}
                    headCells={headCells}
                    rowsData={_.map(props.exportData, (d: any, index: number) => {
                        return renderData(d, index, true)
                    })}
                />
            </div>
        </>
    )
}

export function TreatmentRightListExport(props: ExportProps) {
    const { t } = useTranslation()
    const head = props.headCells
    const headCells = head.filter((headCell: any) => headCell.id !== 'action')

    const FILTER_TITLES = [
        { key: 'BRANCH', label: 'REPORT.FILTER.BRANCH' },
        { key: 'DATE_START', label: 'REPORT.FILTER.DATE_START' },
        { key: 'DATE_END', label: 'REPORT.FILTER.DATE_END' }
    ]

    const renderValue = (value: any, index: any) => {
        if (index === 1 || index === 2) {
            if (value) {
                return toBuddhistYear(moment(value), 'DD/MM/YYYY')
            } else {
                return '-'
            }
        }
        return value
    }

    return (
        <>
            <TableHead style={{ fontWeight: 800 }}>{t('REPORT.REPORT_NAME.TREATMENT_RIGHT_MONTH')}</TableHead>
            <table>
                <tr>
                    {FILTER_TITLES.map((filter, index) => (
                        <TableSortLabel key={index}>
                            <td className="mr-2" style={{ fontSize: 10, fontWeight: 800 }}>
                                {t(filter.label)} :{' '}
                            </td>
                            <td style={{ fontSize: 10, fontWeight: 500 }}>{renderValue(props.filter[index], index)}</td>
                        </TableSortLabel>
                    ))}
                </tr>
            </table>
            <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                    <thead>
                        <tr>
                            {_.map(headCells, (val: any, index: number) => {
                                return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {props.rowsData}
                        {!props.rowsData.length && (
                            <td colSpan={headCells.length} style={{ fontSize: 10 }}>
                                {t('REPORT.TITLE.NO_DATA')}
                            </td>
                        )}
                    </tbody>
                </table>
            </UseStyled.Styles>
        </>
    )
}
