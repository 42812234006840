
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { Box, MenuItem, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'

import { useHistory } from 'react-router-dom'
import { routeName } from 'routes/routes-name'

//component
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import FucntionPrint from "component/Print/print"
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'

//utils
import { getBranch, toBuddhistYear } from 'utils/app.utils'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'

//API
import ClinicApi, { BranchInterface } from 'api/master/clinic.api'
import Loading from 'component/Loading'
import ReportApi, { FindAllReportInterface } from 'api/report.api'
import { ExportProps } from '../report-constants'
import InputTextSearch from 'component/Input/InputTextSearch'
import ProductsApi from 'api/warehouses/product.api'
import ProductTypesApi from 'api/setting/default-products/product-type.api'
import ButtonCustom from 'component/Button/ButtonCustom'
import StockList from './stockList'
import TableRowCommon from 'component/Table/TableRowCommon'

export default function Stock() {
    const { t } = useTranslation()
    const history = useHistory();
    const momentDate = () => [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]

    const componentRef = useRef<HTMLDivElement>(null)
    const [branches, setBranches] = useState<any[]>([])
    const [product, setProduct] = useState<any[]>([])
    const [productType, setProductType] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true);

    const [page, setPage] = useState<number>(1)
    const [limit, setLimit] = useState<number>(10)
    const [search, setSearch] = useState<string>('')

    const [branchId, setBranchId] = useState<any>(0)
    const [selectProduct, setSelectProduct] = useState<any>(null)
    const [selectProductType, setSelectProductType] = useState<any>(null)

    const [rangeDate, setRangeDate] = useState<string[]>(momentDate)
    const [stockData, setStockData] = useState<any>([])

    const [rowCount, setRowCount] = useState<number>(0);
    const [exportData, setExportData] = useState<any>([])

    const [fetch, setFetch] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState('')
    const [sortType, setSortType] = useState('')

    const filter = [
        branchId === 0 ? `${t(`REPORT.FILTER.ALL`)}` : `${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`,
        rangeDate[0] ? toBuddhistYear(moment(rangeDate[0]), 'DD/MM/YYYY') : "-",
        rangeDate[1] ? toBuddhistYear(moment(rangeDate[1]), 'DD/MM/YYYY') : "-",
        selectProductType ? selectProductType.productTypeName : `${t(`REPORT.FILTER.ALL`)}`,
        selectProduct ? selectProduct.productName : `${t(`REPORT.FILTER.ALL`)}`

    ]

    const { onDownload } = useDownloadExcel({
        tableExportRef: componentRef.current,
        filename: `${t(`REPORT.REPORT_NAME.STOCK`)} ${rangeDate[0] ? `(${rangeDate[0]}-${rangeDate[1]})` : ''}`,
        sheetName: `${t(`REPORT.REPORT_NAME.STOCK`)}`
    })

    const loadStockExport = useCallback(async () => {
        let condition: any = {
            page: 1, pageLimit: 10000,
            dateStart: rangeDate[0],
            dateEnd: rangeDate[1],
            export: 1
        }
        if (branchId && branchId !== 0) {
            condition = { ...condition, branchId: branchId }
        }
        if (rangeDate) {
            if (rangeDate[0] !== null && rangeDate[1] !== null)
                condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
        }
        if (selectProduct) {
            condition.productId = selectProduct.productId
        }
        if (selectProductType) {
            if (selectProductType.productTypeId !== 0) condition.productTypeId = selectProductType.productTypeId
        }
        if (!_.isEmpty(search)) {
            condition.search = search
        }
        if (!_.isEmpty(sortBy)) {
            condition.sortBy = sortBy
        }

        if (!_.isEmpty(sortType)) {
            condition.sortType = sortType
        }


        ReportApi.reportStock(condition)
            .then(({ headers, data }) => {
                setExportData(data)
            }).catch((e) => setLoading(false))
            .finally(() => {
                setLoading(false)
                setFetch(false)
            })

    }, [fetch, sortBy, sortType])


    const fetchStock = useCallback(() => {
        setLoading(true)
        let condition: any = {
            page: page, pageLimit: limit,
            dateStart: rangeDate[0],
            dateEnd: rangeDate[1],
        }
        if (branchId && branchId !== 0) {
            condition = { ...condition, branchId: branchId }
        }
        if (rangeDate) {
            if (rangeDate[0] !== null && rangeDate[1] !== null)
                condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
        }
        if (selectProduct) {
            if (selectProduct.productId !== 0) condition.productId = selectProduct.productId
        }
        if (selectProductType) {
            if (selectProductType.productTypeId !== 0) condition.productTypeId = selectProductType.productTypeId
        }
        if (!_.isEmpty(search)) {
            condition.search = search
        }
        if (!_.isEmpty(sortBy)) {
            condition.sortBy = sortBy
        }

        if (!_.isEmpty(sortType)) {
            condition.sortType = sortType
        }

        ReportApi.reportStock(condition)
            .then(({ headers, data }) => {
                setStockData(data)
                setRowCount(headers['x-total'])
            }).catch((e) => setLoading(false))
            .finally(() => {
                setLoading(false)
                setFetch(false)
            })

    }, [page, limit, fetch, sortBy, sortType])

    const verifyPermission = async () => {
        const condition: FindAllReportInterface = {
            page: 1, pageLimit: 200
        }
        ReportApi.reportList(condition)
            .then(({ data }) => {
                const check = _.find(data, (val: any) => String(val.reportKey) === 'STOCK') ? true : false
                if (!check) {
                    history.push(routeName.report)
                    return false
                }
            })

        /** MASTER DATA */
        ClinicApi.findAllBranches()
            .then(({ data }) => setBranches(data))
            .catch((e) => { return })

        ProductsApi.findAll({ export: 1 }).then(({ data }) => {
            setProduct([{ productName: t('REPORT.REPORT_GROUP.ALL'), productId: 0 }, ...data])
        }).catch((e) => { return })

        ProductTypesApi.findAll({ export: 1 }).then(({ data }) => {
            setProductType([{ productTypeId: 0, productTypeName: t('REPORT.REPORT_GROUP.ALL') }, ...data])
        }).catch((e) => { return })

        return true
    }

    useEffect(() => {
        verifyPermission()
    }, [])

    useEffect(() => {
        fetchStock()
    }, [fetchStock])

    const headCells = [
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.#', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.DOCUMENT_NO', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.SUBJECT', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.LOT_NO', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.PRODUCT_TYPE', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.PRODUCT', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.EXPIRE_DATE', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.QTY', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.UNIT', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.COST_PRICE', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.SALE_PRICE', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.TOTAL_PRICE', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.CREATEDBY', align: 'center', class: 'text-nowrap' },
    ]

    const renderData = (data: any, no: number) => {
        return <>
            <TableRowCommon
                key={(no + 1).toString()}
                id={(no + 1).toString()}
                obj={data}
                columns={[
                    { option: 'TEXT', align: 'center', label: no + 1, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.documentNo, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.subject, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.lotNo, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.itemTypeName, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.itemName, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: moment(data.expireDate).format('DD/MM/YYYY'), class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.qty, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.itemUnitName || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.costPrice, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.salePrice, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.total, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.createdBy, class: 'text-nowrap' },
                ]} />
        </>
    }
    useEffect(() => {
        loadStockExport()
    }, [loadStockExport])

    const handleChangeRowsPerPage = (limit: number) => {
        setLimit(limit)
    }
    const handleChangePage = (val: number) => setPage(val)


    return (
        <Card className={'border-0 h-100'}>
            <HeaderCard text={t(`REPORT.REPORT_NAME.STOCK`)} />
            <Card.Body>
                <Card.Title className={'mb-0'}>
                    <Row className='mx-0'>
                        <Col sm={5} md={4} xl={3} className={'pt-2 px-1'}>
                            <InputTextSearch
                                keyInput={'search'}
                                label={t('INPUT.SEARCH')}
                                value={search}
                                onchange={(event) => {
                                    setSearch(event.target.value)
                                }}
                                onClear={(event) => {
                                    setSearch('')
                                }}
                            />      </Col>
                        <Col sm={5} md={4} xl={3} className={'pt-2 px-1'}>
                            <FilterSelect
                                onchange={(e) => {
                                    setBranchId(e.target.value)
                                }}
                                renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn') || t('REPORT.REPORT_GROUP.ALL')} `}
                                label={''}
                                selectId="select-branch"
                                labelId="label-branch"
                                value={branchId}
                                options={
                                    _.map([{ branchId: 0, branchName: t('REPORT.REPORT_GROUP.ALL') }, ...branches], (b: BranchInterface, index) => {
                                        return (
                                            <MenuItem key={b.branchId} value={b.branchId}>
                                                {b.branchName || b.branchNameEn}
                                            </MenuItem>
                                        )
                                    })
                                }
                                formControlStyle={{ maxWidth: 'unset !important' }}
                                classesOption="style-select-doctors"
                            />
                        </Col>
                        <Col sm={5} md={4} xl={3} className={'pt-2 px-1'}>
                            <FilterSelect
                                onchange={(e) => {
                                    setSelectProductType(e.target.value)
                                    setSelectProduct(null)
                                }}
                                renderValue={() => `${t('REPORT.TABLE.STOCK.PRODUCT_TYPE')} : ${selectProductType ? selectProductType.productTypeName : t('REPORT.REPORT_GROUP.ALL')}`}
                                label={''}
                                selectId="select-productType"
                                labelId="label-productType"
                                value={selectProductType || { productTypeName: t('REPORT.REPORT_GROUP.ALL'), productTypeId: 0 }}
                                options={
                                    _.map([...productType], (b: any, index) => {
                                        return (
                                            <MenuItem key={b.productTypeId} value={b}>
                                                {b.productTypeName || b.productTypeNameEn}
                                            </MenuItem>
                                        )
                                    })
                                }
                                formControlStyle={{ maxWidth: 'unset !important' }}
                                classesOption="style-select-doctors"
                            />
                        </Col>
                        <Col sm={5} md={4} xl={3} className={'pt-2 px-1'}>
                            <FilterSelect
                                onchange={(e) => {
                                    setSelectProduct(e.target.value)
                                }}
                                disabled={!(selectProductType?.productTypeId && selectProductType?.productTypeId !== 0)}
                                renderValue={() => `${t('REPORT.TABLE.STOCK.PRODUCT')}: ${selectProduct ? selectProduct.productName : t('REPORT.REPORT_GROUP.ALL')}`}
                                label={''}
                                selectId="select-product"
                                labelId="label-operativeType"
                                value={selectProduct || { productName: t('REPORT.REPORT_GROUP.ALL'), productId: 0 }}
                                options={
                                    _.map([...product].filter(item => item.productTypeId === selectProductType?.productTypeId), (b: any, index) => {
                                        return (
                                            <MenuItem key={b.productId} value={b}>
                                                {b.productName || b.operativeNameEn}
                                            </MenuItem>
                                        )
                                    })
                                }
                                formControlStyle={{ maxWidth: 'unset !important' }}
                                classesOption="style-select-doctors"
                            />
                        </Col>

                        <Col sm={5} md={4} xl={3} className={'pt-2 px-1'}>
                            <InputSecondNewRangePicker
                                inputHeight={32}
                                value={rangeDate || emptyRangePicker}
                                onchange={(val: any) => {
                                    if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                                    else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                                }}
                                allowClear
                                onClear={() => setRangeDate(emptyRangePicker)}
                                label={t('REPORT.TABLE.STOCK.EXPIRE_DATE')}
                            />
                        </Col>
                        <Col md={1} xl={1} className={'pt-0'}>
                            <ButtonCustom onClick={() => {
                                setFetch(true);
                                setPage(1);
                            }}
                                textButton={t('INPUT.SEARCH')} style={{ height: '30px', marginTop: '5px' }} />
                        </Col>

                        <Col className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                            <UseStyled.DropdownDocument>
                                <Dropdown.Toggle id="dropdown-basic" className='ml-xl-auto'>
                                    {t('PRINT_DOCUMENT')}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={onDownload}>
                                        {t('Excel')}
                                    </Dropdown.Item>
                                    <FucntionPrint
                                        content={() => componentRef.current}
                                        docTitle={t(`REPORT.REPORT_NAME.OPERATIVE_SUMMARY_MONTH`)}
                                        removeIfram
                                        trigger={() => <Dropdown.Item >
                                            {t('PDF')}
                                        </Dropdown.Item>}
                                        style={`@page { size: landscape;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                                    />
                                </Dropdown.Menu>
                            </UseStyled.DropdownDocument>
                        </Col>
                    </Row>
                </Card.Title>

                <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>

                    <StockList
                        page={page}
                        pageLimit={limit}
                        rowCount={rowCount}
                        data={stockData}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleChangePage={handleChangePage}
                        onRequestSort={(by, type) => {
                            setSortBy(by)
                            setSortType(type)
                        }}
                        sortBy={sortBy}
                        sortType={sortType}
                    ></StockList>
                </Box>

            </Card.Body>
            <Loading show={loading} type='fullLoading' />

            <div className="print-show" ref={componentRef}>
                <ExportData
                    filter={filter}
                    componant={
                        <>
                            <UseStyled.Styles className="pt-3">
                                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                                    <thead>
                                        <tr>
                                            {_.map(headCells, (val: any, index: number) => {
                                                return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {_.map(exportData, (d: any, index: number) => {
                                            return (
                                                renderData(d, index)
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </UseStyled.Styles>
                        </>
                    }
                />
            </div>
        </Card>
    )
}

export function ExportData(props: ExportProps) {
    const { t } = useTranslation()

    const FILTER_TITLES = [
        { key: 'BRANCH', label: 'REPORT.FILTER.BRANCH' },
        { key: 'DATE_START', label: 'REPORT.FILTER.DATE_START' },
        { key: 'DATE_END', label: 'REPORT.FILTER.DATE_END' },
        { key: 'PRODUCT_TYPE', label: 'REPORT.FILTER.PRODUCT_TYPE' },
        { key: 'PRODUCT', label: 'REPORT.FILTER.PRODUCT' },
    ];

    const renderValue = (value: any, index: any) => {
        if (index === 1 || index === 2) {
            return toBuddhistYear(moment(value), 'DD/MM/YYYY');
        }
        return value;
    };

    return (
        <>
            <TableHead style={{ fontWeight: 800 }} >{t('REPORT.REPORT_NAME.STOCK')}</TableHead>
            <table>
                <tr>
                    {FILTER_TITLES.map((filter, index) => (
                        <TableSortLabel key={index} >
                            <td style={{ fontSize: 10, fontWeight: 800 }}>{t(filter.label)} : </td>
                            <td style={{ fontSize: 10, fontWeight: 500 }}>{renderValue(props.filter[index], index)}</td>
                        </TableSortLabel>
                    ))}
                </tr>
            </table>
            {props.componant}
        </>)
}