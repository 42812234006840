
const GroupLine = (props: any) => {
    return (
        <><svg width="179" height="26" viewBox="0 0 179 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M89.4663 1.7334L89.4663 24.4667" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M60.1333 1.7334L60.1333 24.4667" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M30.7998 1.7334L30.7998 24.4667" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M1.46631 1.7334L1.46631 24.4667" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M118.8 1.7334L118.8 24.4667" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M148.133 1.7334L148.133 24.4667" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M177.467 1.7334L177.467 24.4667" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M95.3335 6.1333L95.3335 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M66 6.1333L66 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M36.667 6.1333L36.667 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M7.3335 6.1333L7.3335 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M124.667 6.1333L124.667 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M154 6.1333L154 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M101.2 6.1333L101.2 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M71.8667 6.1333L71.8667 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M42.5332 6.1333L42.5332 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M13.1997 6.1333L13.1997 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M130.533 6.1333L130.533 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M159.867 6.1333L159.867 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M107.067 6.1333L107.067 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M77.7334 6.1333L77.7334 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M48.4004 6.1333L48.4004 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M19.0669 6.1333L19.0669 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M136.4 6.1333L136.4 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M165.733 6.1333L165.733 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M112.934 6.1333L112.934 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M83.6001 6.1333L83.6001 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M54.2666 6.1333L54.2666 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M24.9331 6.1333L24.9331 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M142.267 6.1333L142.267 20.0666" stroke="white" strokeLinecap="round" />
            <path d="M171.6 6.1333L171.6 20.0666" stroke="white" strokeLinecap="round" />
        </svg>
        </>
    )
}

export default GroupLine