import moment from 'moment'
import _ from 'lodash'
import { Box, TableRow, TableCell } from '@mui/material'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** INTERFACE */
import { TreatmentPanelProps, ItemRender, NoItem } from 'component/Treatments/PopupEmrOverview/PopupEmrOverview'

/** COMPONENT */
import TableCustom from 'component/Table/TableCustom'

/** UTILS */
import { toBuddhistYear } from 'utils/app.utils'
import useWindowSize from 'utils/useWindowSize'
import { colors } from 'constants/theme'

/** STYLE */
import * as UseStyled from 'component/Treatments/PopupEmrOverview/UseStyled'

type RenderDataProps = {
  no: number
  item: any
}

export default function EmrDx(props: TreatmentPanelProps) {
  const { t } = useTranslation()
  const [width] = useWindowSize()

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'message', disablePadding: false, label: t('TREATMENTS_DX.TABLE.CELL.DX_ID') },
    { id: 'message', disablePadding: false, label: t('TREATMENTS_DX.TABLE.CELL.DIAGNOSIS') },
    { id: 'message', disablePadding: false, label: t('TREATMENTS_DX.TABLE.CELL.DIAGNOSIS_PERSON') },
  ]

  return (
    <>
      {
        _.map(props.treatments, (item: ItemRender, index: number) => {
          if (!_.isEmpty(item.diagnosis))
            return (
              <UseStyled.TreatmentBox key={index}>
                <UseStyled.LabelDate>
                  <span className='text-ellipsis'>{toBuddhistYear(moment(item.date), 'DD/MM/YY HH.mm')} {t('TIME_UNIT')}</span>
                </UseStyled.LabelDate>
                <Box>
                  {width >= 768 ? (
                    <UseStyled.ResponsiveTable type='dx'>
                      <TableCustom
                        hidePagination
                        page={0}
                        pageLimit={0}
                        sortType={'ASC'}
                        sortBy={''}
                        rowCount={0}
                        textEmptyData={t('REGISTER.NO_ITEM')}
                        onSort={() => { return }}
                        setPageLimit={() => { return }}
                        setPage={() => { return }}
                        headCells={headCells}
                        rowsData={
                          _.map(item.diagnosis, (diagnosis, indexDiagnosis: number) => {
                            return <RenderDataAsTable item={diagnosis} no={indexDiagnosis + 1} />
                          }).reverse()
                        }
                      />
                    </UseStyled.ResponsiveTable>
                  ) : (
                    !_.isEmpty(item.diagnosis) && (
                      _.map(item.diagnosis, (diagnosis, indexDiagnosis: number) => {
                        return (
                          <Box key={indexDiagnosis}
                            sx={{
                              '&:not(:first-of-type)': {
                                paddingTop: '2px'
                              },
                              '&:not(:last-of-type)': {
                                borderBottom: `1px solid ${colors.lightGray}`,
                                paddingBottom: '10px'
                              }
                            }}>
                            <RenderDataAsList item={diagnosis} no={indexDiagnosis + 1} />
                          </Box>
                        )
                      }).reverse()
                    ) ||
                    <NoItem />
                  )
                  }
                </Box>
              </UseStyled.TreatmentBox>
            )
        })
      }
    </>
  )
}

function RenderDataAsTable(props: RenderDataProps) {
  const { t } = useTranslation()

  return (
    <>
      <TableRow className='main-row'>
        <TableCell align='center'>{props.no}</TableCell>
        <TableCell>{props.item?.diagnosisCode || '-'}</TableCell>
        <TableCell>{props.item?.diagnosisName || '-'}</TableCell>
        <TableCell>{!_.isEmpty(props.item?.user) ? `${props.item?.user?.firstname} ${props.item?.user?.lastname}` : props.item?.createdBy || '-'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell></TableCell>
        <TableCell colSpan={3}>
          <Box className='d-flex'>
            <UseStyled.Typo type='title' className='pr-3' sx={{ width: 'max-content' }}>{t('TREATMENT_SUMMARY.TITLE.MORE_INFO')}</UseStyled.Typo>
            <UseStyled.Typo sx={{ flex: 1 }}>{props.item?.note || '-'}</UseStyled.Typo>
          </Box>
        </TableCell>
      </TableRow>
    </>
  )
}

function RenderDataAsList(props: RenderDataProps) {
  const { t } = useTranslation()

  return (
    <>
      <Box className='d-flex mt-2'>
        <UseStyled.TypoNo>{props.no}</UseStyled.TypoNo>
        <UseStyled.Typo type='title' width={'80px'}>{t('TREATMENTS_DX.TABLE.CELL.DX_ID')}</UseStyled.Typo>
        <UseStyled.Typo sx={{ flex: 1 }}>{props.item?.diagnosisCode || '-'}</UseStyled.Typo>
      </Box>
      <Box className='d-flex flex-column mt-2' sx={{ flex: 1 }}>
        <Box className='d-flex'>
          <UseStyled.TypoNo></UseStyled.TypoNo>
          <UseStyled.Typo type='title' width={'80px'}>{t('TREATMENTS_DX.TABLE.CELL.DIAGNOSIS')}</UseStyled.Typo>
        </Box>
        <Box className='d-flex mt-2'>
          <UseStyled.TypoNo></UseStyled.TypoNo>
          <UseStyled.Typo sx={{ flex: 1 }}>{props.item?.diagnosisName || '-'}</UseStyled.Typo>
        </Box>
      </Box>
      <Box className='d-flex mt-2'>
        <UseStyled.TypoNo></UseStyled.TypoNo>
        <UseStyled.Typo type='title' width={'80px'}>{t('TREATMENTS_DX.TABLE.CELL.DIAGNOSIS_PERSON')}</UseStyled.Typo>
        <UseStyled.Typo sx={{ flex: 1 }}>{!_.isEmpty(props.item?.user) ? `${props.item?.user?.firstname} ${props.item?.user?.lastname}` : props.item?.createdBy || '-'}</UseStyled.Typo>
      </Box>
      <Box className='d-flex flex-column mt-2' sx={{ flex: 1 }}>
        <Box className='d-flex'>
          <UseStyled.TypoNo></UseStyled.TypoNo>
          <UseStyled.Typo type='title' width={'100px'}>{t('TREATMENT_SUMMARY.TITLE.MORE_INFO')}</UseStyled.Typo>
        </Box>
        <Box className='d-flex mt-2'>
          <UseStyled.TypoNo></UseStyled.TypoNo>
          <UseStyled.Typo sx={{ flex: 1 }}>{props.item?.note || '-'}</UseStyled.Typo>
        </Box>
      </Box>
    </>
  )
}