import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import AdvanceApi from 'api/finances/advance.api'
import { resetModal } from 'app/slice/modal.slice'
import InputTextarea from 'component/Input/InputTextarea'
import InputTextField from 'component/Input/InputTextField'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { ModalCancel } from 'features/finance/advance/style/advance-list'

export interface ICancelAdvanceProps {
    isShow: boolean
    id: number
    advanceNumber: string
    onReset?: () => void
    handleSuccess?: () => void
}

const initStateErrorMessage = {
    USER_NAME_STRING_EMPTY: '',
    PASSWORD_STRING_EMPTY: '',
    CANCEL_REMARK_STRING_EMPTY: '',
    NOT_FOUND_USER: '',
    INVALID_PASSWORD: '',
}

export default function CancelAdvance(props: ICancelAdvanceProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [username, setUserCode] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [cancelRemark, setCancelRemark] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<any>({ ...initStateErrorMessage })

    const clearErrorMessage = async () => setErrorMessage(initStateErrorMessage)
    const resetForm = async () => {
        clearErrorMessage()
        setUserCode('')
        setPassword('')
        setCancelRemark('')
        if (props.onReset) props.onReset()
        dispatch(resetModal())
    }

    useEffect(() => {
        clearErrorMessage()
    }, [username, password, cancelRemark])

    const onDelete = (id: number, obj: any) => {
        if (!username || !password || !cancelRemark) {
            setErrorMessage({
                USER_NAME_STRING_EMPTY: !username ? t('ADVANCE.MESSAGE.ERROR.USER_NAME_STRING_EMPTY') : '',
                PASSWORD_STRING_EMPTY: !password ? t('ADVANCE.MESSAGE.ERROR.PASSWORD_STRING_EMPTY') : '',
                CANCEL_REMARK_STRING_EMPTY: !cancelRemark ? t('ADVANCE.MESSAGE.ERROR.CANCEL_REMARK_STRING_EMPTY') : '',
            })
            return false
        }

        AdvanceApi.cancel(id, { username, password, cancelRemark })
            .then((res) => {
                if (res.status !== undefined && res.status === 200) {
                    notiSuccess(t(`ADVANCE.MESSAGE.SUCCESS.CANCEL`))
                    dispatch(resetModal())
                    resetForm()
                    if (props?.handleSuccess) props.handleSuccess()
                } else {
                    const err = res.response.data
                    const errMsg: string = err?.message || ''
                    if (errMsg === 'NOT_FOUND_ORDER_ADVANCE')
                        notiError('ADVANCE.MESSAGE.ERROR.NOT_FOUND_ORDER_ADVANCE')
                    else if (errMsg === 'NOT_FOUND_USER')
                        setErrorMessage({ ...initStateErrorMessage, NOT_FOUND_USER: t('ADVANCE.MESSAGE.ERROR.NOT_FOUND_USER'), })
                    else if (errMsg === 'INVALID_PASSWORD')
                        setErrorMessage({ ...initStateErrorMessage, INVALID_PASSWORD: t('ADVANCE.MESSAGE.ERROR.INVALID_PASSWORD'), })
                    else if (errMsg === 'BANANCE_LESS_THAN_CANCEL')
                        notiError(t(`ADVANCE.MESSAGE.ERROR.BANANCE_LESS_THAN_CANCEL`))
                    else notiError(t(`ADVANCE.MESSAGE.ERROR.${errMsg}`))
                }
            })
            .catch((e) => {
                const errMsg = e?.response?.data?.message
                if (errMsg === 'NOT_FOUND_ORDER_ADVANCE')
                    notiError('ADVANCE.MESSAGE.ERROR.NOT_FOUND_ORDER_ADVANCE')
                else if (errMsg === 'NOT_FOUND_USER')
                    setErrorMessage({ ...initStateErrorMessage, NOT_FOUND_USER: t('ADVANCE.MESSAGE.ERROR.NOT_FOUND_USER'), })
                else if (errMsg === 'INVALID_PASSWORD')
                    setErrorMessage({ ...initStateErrorMessage, INVALID_PASSWORD: t('ADVANCE.MESSAGE.ERROR.INVALID_PASSWORD'), })
                else if (errMsg === 'BANANCE_LESS_THAN_CANCEL')
                    notiError(t(`ADVANCE.MESSAGE.ERROR.BANANCE_LESS_THAN_CANCEL`))
                else notiError(t(`ADVANCE.MESSAGE.ERROR.${errMsg}`))
            })
    }

    return props.isShow ? (
        <ModalCancel
            size={'lg'}
            modalStyle={'modal-cancel'}
            title={t('ADVANCE.FORM.MODAL_CANCEL')}
            alignFooter={'center'}
            component={
                <>
                    <span className="fz-14 text-muted">{t('ADVANCE.FORM.CAPTION')} {props.advanceNumber}</span>
                    <Row className="mx-0 mt-2">
                        <Col xs={12} sm={6} className="px-2 mb-3">
                            <InputTextField
                                required
                                label={t('ADVANCE.FORM.USER_NAME')}
                                type="text"
                                value={username}
                                inputProps={{ shrink: true }}
                                helperText={errorMessage?.USER_NAME_STRING_EMPTY || errorMessage?.NOT_FOUND_USER || ''}
                                onchange={(e) => setUserCode(e.target.value)}
                                disabledAutoComplete
                            />
                        </Col>
                        <Col xs={12} sm={6} className="px-2 mb-3">
                            <InputTextField
                                required
                                label={t('ADVANCE.FORM.PASSWORD')}
                                type="password"
                                value={password}
                                inputProps={{ shrink: true }}
                                helperText={errorMessage?.PASSWORD_STRING_EMPTY || errorMessage?.INVALID_PASSWORD || ''}
                                onchange={(e) => setPassword(e.target.value)}
                            />
                        </Col>
                        <Col xs={12} sm={12} className="px-2 mb-3">
                            <InputTextarea required value={cancelRemark} onchange={(event) => setCancelRemark(event.target.value)} label={t('ADVANCE.FORM.CANCEL_REMARK')} helperText={errorMessage?.CANCEL_REMARK_STRING_EMPTY || ''} />
                        </Col>
                    </Row>
                </>
            }
            id={props.id}
            textBtnDelete={t('ADVANCE.BUTTON.SAVE_MODAL')}
            onDelete={onDelete}
            autoResetModal={false}
            onReset={() => (resetForm())}
            textBtnCancel={t('APPOINTMENT.FORM.CANCEL')}
        />
    ) : <></>
}
