import BaseAPI from 'api/api'
const path = 'retrospective-order'

export interface OrderPaymentItemInterface {
    refItemId: number
    refItemType: string
    amountPaid: number
}

export interface OrderPaymentChannelInterface {
    paymentChannelId: number,
    amountPaid: number,
    bankId: number,
    cardTypeId: number,
    patientTreatmentRightId: number,
    orderPaymentItems: [OrderPaymentItemInterface]
}

export interface PaymentInterface {
    discountPromotion: number,
    discountTreatmentRight: number,
    advance: number,
    subTotal: number,

    /** MAIN VALUE */
    amountPaid: number
    overdue: number
    totalPrice: number
    amountReceipt: number
    amountChange: number
    remark: string
    orderPaymentChannels: [OrderPaymentChannelInterface]
}

export interface SummaryTreatments {
    index?: number | null
    teeth?: string
    teethJson?: string
    operativeId?: number
    qty?: number
    price?: number
    doctorId?: number
    discount?: number
    discountUnit?: 'BAHT' | 'PERCENT'
    additional?: number
    additionalUnit?: 'BAHT' | 'PERCENT'
    dfAmount?: number
    dfUnit?: 'BAHT' | 'PERCENT'
    dfDiscountAmount?: number
    dfDiscountUnit?: 'BAHT' | 'PERCENT'
    dfAmountNet?: number
    cfAmount?: number
    cfDiscountAmount?: number
    cfDiscountUnit?: 'BAHT' | 'PERCENT'
    cfAmountNet?: number
    dfRemark?: string
    clinicFeeId?: number | null
    total?: number
    note?: string
    noteNextVisit?: string
    refType: 'COURSE' | 'PLAN' | null
    refId: number | null
    nextVisit?: NextVisit
}

export interface SummaryProducts {
    index: number
    inventoryId: number
    group: 'PRODUCT' | 'MEDICINE' | 'ALL'
    qty: number
    price: number
    doctorId: number
    discount: number
    discountUnit: 'BAHT' | 'PERCENT'
    additional: number
    additionalUnit: 'BAHT' | 'PERCENT'
    note: string
    refType: 'COURSE' | 'PLAN' | null
    refId: number | null
}

export interface TreatmentNextVisit {
    operativeTypeId?: number
    operativeId?: number
}

export interface NextVisit {
    refId?: number
    date?: string
    timeStart?: string
    durationMinute?: number
    durationText?: string
    doctorId?: number
    treatment?: any
    practice?: string
    price?: number
    hasLab?: 'HAS' | 'NOT_HAS'
    hasXray?: 'HAS' | 'NOT_HAS'
    remark?: string
}

export interface RetrospectiveOrderInterface {
    date: string
    toothChart?: any
    teethType: 'ADULT' | 'CHILD' | ''
    servicePointId: number
    summeryTreatments?: SummaryTreatments[]
    summeryProducts?: SummaryProducts[]
    payment?: PaymentInterface
}

export default class RetrospectiveApi extends BaseAPI {
    static payment(patientId: number, payload: RetrospectiveOrderInterface) {
        return this.api.post(`${path}/${patientId}`, payload)
    }
}