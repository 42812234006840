import { useEffect, useState } from 'react';
import _ from 'lodash'
import { Box, Typography, TableRow, TableCell } from '@mui/material';
import $ from 'jquery'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** UTILS || SLICE */
import useWindowSize from 'utils/useWindowSize'
import { clinicTheme } from 'api/api';

/** COMPONENT */
import TableCustom from 'component/Table/TableCustom'

/** STYLE */
import { styled } from '@mui/material/styles'
import * as UseStyled from 'features/treatments/SummaryTreatmentEmr/UseStyle'
import ClinicApi from 'api/master/clinic.api'


const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  width: 130,
  flex: 'none',
  [theme.breakpoints.down('xs')]: {
    width: 120
  }
}))

type IDiagnosis = {
  userId?: number
  diagnosis?: any
}

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}


export default function DiagnosisEmr(props: IDiagnosis) {
  const { t } = useTranslation()
  const [width] = useWindowSize()
  const [doctors, setDoctors] = useState<Doctors[]>([]);


  const loadDoctor = async () => {
    const resp = await ClinicApi.findAllDoctors()
    if (resp.status === 200) setDoctors(resp.data)
  }

  const handleRowHover = (type: 'onMouseEnter' | 'onMouseLeave', item: string) => {
    if (type === 'onMouseEnter') $(`#${item}`).prev().addClass('table-row-hover')
    else $(`#${item}`).prev().removeClass('table-row-hover')
  }

  useEffect(() => {
    loadDoctor()
  }, []);

  const headCellsTooth = clinicTheme.theme === clinicTheme.type.DENTAL ? [{ id: 'message', disablePadding: false, label: t('TREATMENT_SUMMARY.TITLE.TOOTH'), align: 'left', width: '150px !important', class: 'text-left' }] : []

  const headCells = [
    { id: 'no', disablePadding: false, align: 'center', label: '#' },
    ...headCellsTooth,
    { id: 'message', disablePadding: false, align: 'left', label: t('DIAGNOSIS_SUMMARY.TITLE.CODE'), width: width >= 1200 ? '120px' : '100px', class: 'text-left' },
    { id: 'message', disablePadding: false, align: 'left', label: t('DIAGNOSIS_SUMMARY.TITLE.DIAGNOSIS'), width: 'auto !important', class: 'text-left' },
    { id: 'message', disablePadding: false, align: 'left', label: t('DIAGNOSIS_SUMMARY.TITLE.DIAGNOSIS_PERSON'), width: width >= 1200 ? '250px' : '180px', class: 'text-left' }
  ]

  const renderData = (data: any, no: number) => {
    return (
      <>
        <TableRow key={`diagnosis-${no}`} className="main-table-row">
          <TableCell align="center" scope="row" className="py-3 py-md-2">
            {no + 1}
          </TableCell>
          {(width >= 768 && (
            <>
              {clinicTheme.theme === clinicTheme.type.DENTAL && (
                <TableCell align="left" scope="row" className="py-3 py-md-2">
                  {data?.teeth || '-'}
                </TableCell>
              )}
              <TableCell align="left" scope="row" className="py-3 py-md-2">
                {data.diagnosisCode}
              </TableCell>
              <TableCell align="left" scope="row" className="py-3 py-md-2">
                {data.diagnosisName}
              </TableCell>
              <TableCell align="left" scope="row" className="py-3 py-md-2">
                {doctors.find((p: any) => p.userId === data.doctorId)?.fullname || '-'}
              </TableCell>
            </>
          )) || (
              <TableCell align="left" scope="row" className="py-3 py-md-2" colSpan={4}>
                {clinicTheme.theme === clinicTheme.type.DENTAL && (
                  <Box className="mb-3 pr-2 d-flex">
                    <Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.TOOTH')}</Title>
                    <Typography className="d-inline">{data?.teeth || '-'}</Typography>
                  </Box>
                )}
                <Box className="pr-2 d-flex">
                  <Title className="d-inline-block pr-2">{t('DIAGNOSIS_SUMMARY.TITLE.CODE')}</Title>
                  <Typography className="d-inline">{data.diagnosisCode}</Typography>
                </Box>
                <Box className="mt-3 pr-2 d-flex">
                  <Title className="pr-2">{t('DIAGNOSIS_SUMMARY.TITLE.DIAGNOSIS')}</Title>
                  <Typography>{data.diagnosisName}</Typography>
                </Box>
                <Box className="mt-3 d-flex align-items-center pr-2">
                  <Title className="d-inline-block pr-2">{t('DIAGNOSIS_SUMMARY.TITLE.DIAGNOSIS_PERSON')}</Title>
                  {doctors.find((p: any) => p.userId === data.doctorId)?.fullname}
                </Box>
                <Box className="w-100 d-flex mt-3 pr-2">
                  <Title className="d-inline-block pr-2">{t('TREATMENT_SUMMARY.TITLE.MORE_INFO')}</Title>
                  <Box className="d-flex w-100">
                    <div>{data.note ? data.note : '-'}</div>
                  </Box>
                </Box>
              </TableCell>
            )}
        </TableRow>
        {width >= 768 && (
          <TableRow key={`diagnosis-row-${no}`} id={`diagnosis-row-${no}`} onMouseEnter={() => handleRowHover('onMouseEnter', `diagnosis-row-${no}`)} onMouseLeave={() => handleRowHover('onMouseLeave', `diagnosis-row-${no}`)}>
            <TableCell align="left" scope="row" className="pt-1"></TableCell>
            <TableCell align="left" scope="row" className="py-3 py-md-2" colSpan={3}>
              <Box className="w-100 d-flex">
                <Typography>{t('TREATMENT_SUMMARY.TITLE.MORE_INFO')}</Typography>
                <div>{data.note}</div>
              </Box>
            </TableCell>
          </TableRow>
        )}
      </>
    )
  }


  return (
    <>
      <UseStyled.ResponsiveSummaryDiagnosisTable className={'overflow-hidden'}>
        <TableCustom
          hidePagination
          customScroll
          page={0}
          pageLimit={0}
          sortType={'ASC'}
          sortBy={''}
          rowCount={0}
          textEmptyData={t('REGISTER.NO_ITEM')}
          onSort={() => {
            return
          }}
          setPageLimit={() => {
            return
          }}
          setPage={() => {
            return
          }}
          headCells={headCells}
          rowsData={_.map(props.diagnosis, (i, index: number) => {
            return renderData(i, index)
          }).reverse()}
        />
      </UseStyled.ResponsiveSummaryDiagnosisTable>
    </>
  )
}
