import { styled } from '@mui/material/styles'
import { Box, Tabs, Button, Typography } from '@mui/material'
import { colors } from 'constants/theme'

export const FragmentTabs = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${colors.white}`
}))

export const ButtonTab = styled(Button)(({ theme }) => ({
  width: '50%',
  height: 40,
  color: colors.textPrimary,
  padding: 0,
  borderBottom: `1px solid transparent`,
  borderRadius: 0,
  textTransform: 'capitalize',
  '&.active': {
    color: colors.themeSecondColor,
    borderColor: colors.themeSecondColor
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: 185
  }
}))

export const DoctorTabs = styled(Tabs)(({ theme }) => ({
  '&.MuiTabs-root': {
    position: 'relative',
    minHeight: 'unset',
  },
  '.MuiTabs-indicator': {
    backgroundColor: colors.themeSecondColor,
    height: 1
  },
  '.MuiTabs-scrollButtons': {
    position: 'absolute',
    top: 0,
    left: 'auto',
    right: 0,
    bottom: 0,
    width: 20,
    zIndex: 1,
    backgroundColor: colors.white30,
    '&:first-of-type': {
      left: 0,
      right: 'auto'
    }
  },
  '.MuiTab-root': {
    padding: '0 8px',
    minHeight: 36,
    width: '50%',
    color: colors.textPrimary,
    borderRadius: 0,
    fontWeight: 400,
    textTransform: 'capitalize',
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
      minHeight: 40,
    },
    '&.Mui-selected': {
      color: colors.themeSecondColor,
    }
  }
}))

export const SignBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  maxWidth: 300,
  '> img': {
    position: 'absolute',
    top: 0,
  },
  '.canvas-box > div': {
    margin: 'auto',
    width: '100% !important',
    height: '150px !important',
    border: `1px solid ${colors.lightGray}`,
    borderRadius: '4px',
  },
  '.clear-box': {
    opacity: 1,
    position: 'absolute',
    top: 6,
    right: 6,
    zIndex: 5,
    '.MuiButton-root': {
      padding: '0 12px',
      minWidth: 'unset',
      borderColor: `${colors.themeThirdColor} !important`,
      color: `${colors.themeThirdColor} !important`,
      backgroundColor: `${colors.white} !important`,
      '&:hover': {
        opacity: 1
      }
    }
  },
  '&:hover': {
    '.clear-box': {
      opacity: 1
    }
  }
}))

export const Sheet = styled(Box)(({ theme }) => ({
  border: `1px solid ${colors.lightGray}`,
  backgroundColor: colors.white,
  overflowY: 'auto',
  borderRadius: '4px',
  minHeight: '100px',
  '*': {
    color: colors.textPrimary
  },
  '.header': {
    borderBottom: `1px solid ${colors.lightGray}`,
    '.header-logo': {
      width: '115px',
      '.image-wrap': {
        paddingTop: '75%',
        img: {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          objectPosition: 'top'
        }
      },
    },
    '.header-address': {
      '.MuiTypography-root': {
        fontSize: 14,
        '&:first-of-type': {
          fontSize: 18
        }
      }
    },
    '.header-number': {
      padding: 7,
      border: `1px solid ${colors.lightGray}`,
    },
    '.header-title': {
      fontSize: 20
    }
  },
  [theme.breakpoints.down('sm')]: {
    flex: 'none',
    '.header': {
      '.header-logo': {
        width: '45px',
      },
      '.header-address': {
        '.MuiTypography-root': {
          fontSize: 5,
          '&:first-of-type': {
            fontSize: 8
          }
        }
      },
      '.header-number': {
        padding: 3,
        fontSize: 8
      },
      '.header-title': {
        fontSize: 10
      }
    },
  }
}))

export const TypoField = styled(Typography)<{ type: 'title' | 'body' }>(({ theme, type }) => ({
  fontSize: 16,
  fontWeight: type === 'title' ? 500 : 400,
  paddingRight: type === 'title' ? 8 : 0,
  flex: type === 'title' ? 'none' : 1,
  wordBreak: 'break-word',
  [theme.breakpoints.down('sm')]: {
    fontSize: 8,
  }
}))

export const ModalFooter = styled(Box)(({ theme }) => ({
  flex: 'none',
  paddingTop: 24,
  marginTop: 'auto',
  '.MuiBox-root': {
    margin: 0
  },
  '.MuiButton-root': {
    height: 44,
    fontSize: 16,
    '&.btn-print': {
      minWidth: 80
    },
    '&.btn-discard': {
      backgroundColor: `${colors.themeThirdColor} !important`,
      '&:hover': {
        backgroundColor: `${colors.themeThirdColor} !important`,
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    '.MuiBox-root': {
      width: '100%'
    },
    '.MuiButton-root': {
      fontSize: 14,
      width: '100%'
    }
  }
}))

export const SignatureWrap = styled(Box)(({ theme }) => ({}))