import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface ClinicFeeInterface {
  clinicFeeName: string
  clinicFeeNameEn?: string
  price: number
  status: string
}

export default class ClinicFeeApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('clinic-fees', { params: { ...props } }).then((res) => res)
  }

  static create(playload: any) {
    return this.api.post('clinic-fees', playload)
  }

  static findById(id: number): Promise<ClinicFeeInterface> {
    return this.api.get(`clinic-fees/${id}`)
  }

  static update(id: number, body: ClinicFeeInterface) {
    return this.api.patch(`clinic-fees/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`clinic-fees/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`clinic-fees/${id}`)
  }
}
