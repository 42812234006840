import { makeStyles } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  inputCheckBox: {
    '& $label': {
      fontFamily: 'Kanit',
      fontSize: '12px',
      fontWeight: 500
    },
  },
  modal: {
    '& .modal-sm': {
      [theme.breakpoints.up('sm')]: {
        maxWidth: '400px !important'
      }
    }
  }
}))

export default useStyles

export const DisabledCheckBox = styled('div')(({ theme }) => ({
  '.Mui-checked.Mui-disabled .checkbox.isChecked::before': {
    background: `${colors.lightGray} !important`
  }
}))
