import { colors } from "constants/theme"

export default function PlusDoctor(props: { color: string }) {
    return (
        <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.97791 5.19727H4.35511C4.35511 17.9139 3.84395 26.0525 15.0895 26.0525C25.824 26.0525 25.3128 17.9139 25.3128 5.19727H19.69" stroke={props.color || colors.disabledGray} strokeWidth="3" strokeLinejoin="round" />
            <path d="M15.0898 27.0703C15.0898 33.6829 15.0764 40.8042 24.8019 40.8042C31.4471 40.8042 34.0029 33.1743 34.0029 32.6656" stroke={props.color || colors.disabledGray} strokeWidth="3" strokeLinejoin="round" />
            <path d="M39.1477 28.595C39.1477 31.4117 36.8511 33.7077 34.0025 33.7077C31.154 33.7077 28.8574 31.4117 28.8574 28.595C28.8574 25.7783 31.154 23.4824 34.0025 23.4824C36.8511 23.4824 39.1477 25.7783 39.1477 28.595Z" stroke={props.color || colors.disabledGray} strokeWidth="3" />
        </svg>
    )
}
