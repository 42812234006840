import { useState, useEffect, useCallback } from 'react'
import { Box, TableRow, TableCell } from '@mui/material';
import _ from 'lodash'
import moment from 'moment'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** ROUTE */
import { routeName } from 'routes/routes-name'

/** API */
import InventoryApi from 'api/warehouses/inventory.api'

/** COMPONENT */
import { InventoryTabs } from 'features/warehouses/inventory/form-inventory'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import Loading from 'component/Loading'
import { SORT_TYPE } from 'constants/common'
import { toBuddhistYear, numberFormat } from 'utils/app.utils'

/** STYLE */
import * as UseStyled from 'features/warehouses/inventory/styled'

export default function HistoryImportTab(props: InventoryTabs) {
    const { t } = useTranslation()

    const [historyImport, setHistoryImport] = useState([]);
    const [loading, setLoading] = useState(false);

    /** PAGINATION  */
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('date')
    const [sortType, setSortType] = useState<SORT_TYPE>(SORT_TYPE.DESC)

    const loadData = useCallback((id: number) => {
        setLoading(true)
        let condition: any = { itemId: id }
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }

        InventoryApi.findImportHistoryById(id, condition)
            .then(({ data, headers }) => {
                setHistoryImport(data)
                setRowCount(headers['x-total'])
            })
            .catch(() => { return })
            .finally(() => setLoading(false))
    }, [page, pageLimit, sortBy, sortType])

    const handleChangeRowsPerPage = (limit: number) => {
        setPageLimit(limit)
        setPage(1)
    }

    const handleChangePage = (page: number) => {
        setPage(page)
    }

    const renderDateExpired = (date: string, day: number) => {
        if (date && day) {
            const now = moment()
            const diff = moment(date).add('days', 1).diff(now, 'days')
            if (diff <= day) return 'date-expired'
            else return ''
        } else return ''
    }

    const onRequestSort = (sortByVal: string, sortTypeVal: SORT_TYPE) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    useEffect(() => {
        if (props.inventory?.itemId) loadData(props.inventory?.itemId)
    }, [loadData]);

    const headCells = [
        { id: 'no', disablePadding: false, label: '#', width: '70px' },
        { id: 'date', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.DOCUMENT_DATE'), width: '150px', sortable: true },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.DOCUMENT_NUMBER'), class: 'text-nowrap' },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.LOT_NO'), class: 'text-nowrap' },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.QTY'), align: 'center', width: '90px' },
        { id: 'expireDate', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.EXPIRE_DATE'), width: '150px', sortable: true },
        { id: 'price', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.PRICE'), width: '155px' },
        { id: 'price', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.TOTAL'), width: '155px' },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.STATUS'), align: 'center' },
        { id: 'updatedBy', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.UPDATED_BY') },
    ]

    const renderData = (objData: any, no: number) => {
        const { inventoryHistoryId, inventoryDocumentId, date, documentNo, lotNo, prefix, qty, salePrice, total, updatedBy, updatedAt, createdBy, createdAt, status, expireDate } = objData
        no = page * pageLimit - pageLimit + no + 1
        const objRenderData = {
            key: no.toString(),
            id: no.toString(),
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: no, class: 'cursor-pointer' },
                { option: 'TEXT', align: 'left', label: date ? toBuddhistYear(moment(date), 'DD/MM/YYYY') : '-', class: 'cursor-pointer' },
                { option: 'TEXT', align: 'left', label: `${(prefix && documentNo) ? `${prefix}-${documentNo}` : documentNo || '-'}`, class: 'cursor-pointer' },
                { option: 'TEXT', align: 'left', label: lotNo || '-', class: 'cursor-pointer' },
                { option: 'TEXT', align: 'center', label: numberFormat(qty, 0, 0) || '-', class: 'cursor-pointer' },
                { option: 'TEXT', align: 'left', label: expireDate ? toBuddhistYear(moment(expireDate), 'DD/MM/YYYY') : '-', class: `cursor-pointer ${renderDateExpired(expireDate, props.inventory.amounDayBeforeExpired || 0)}` },
                { option: 'TEXT', align: 'right', label: numberFormat(salePrice || 0), class: 'cursor-pointer' },
                { option: 'TEXT', align: 'right', label: numberFormat(total || 0), class: 'cursor-pointer' },
                { option: 'TEXT', align: 'center', label: t(`INVENTORY.TABLE.CELLS.HISTORY_IMPORT_STATUS.${status}`), class: 'cursor-pointer' },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy || createdBy, updatedAt: updatedAt || createdAt } },
            ]
        }

        return <TableRowCommon {...objRenderData} onClick={() => window.open(`${routeName.importProduct}/list?id=${inventoryDocumentId}`, '_blank')} />
    }

    return (
        <UseStyled.ResponsiveTable>
            <TableCustom
                page={page}
                pageLimit={pageLimit}
                sortType={sortType}
                sortBy={sortBy}
                rowCount={rowCount}
                textEmptyData={t('REGISTER.NO_ITEM')}
                onSort={onRequestSort}
                setPageLimit={handleChangeRowsPerPage}
                setPage={handleChangePage}
                headCells={headCells}
                rowsData={[
                    !loading ?
                        (
                            _.isEmpty(historyImport) ? (
                                <TableRow>
                                    <TableCell align="center" scope="row" colSpan={_.size(headCells)}>
                                        {t('REGISTER.NO_ITEM')}
                                    </TableCell>
                                </TableRow>
                            ) :
                                _.map(historyImport, (history, index: number) => {
                                    return renderData(history, index)
                                })
                        ) : (
                            <TableRow>
                                <TableCell align="center" scope="row" colSpan={_.size(headCells)}>
                                    <Box className='mx-auto' sx={{ maxWidth: 'max-content' }}>
                                        <Loading show={true} type='softLoading' />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )
                ]}
                tableFixedWidth
                tableMinWidth={1366}
            />
        </UseStyled.ResponsiveTable>
    )
}