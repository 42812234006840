import { useState } from 'react'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { scale } from 'constants/scale'

/** STYLE */
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'

/** ICON */
import ArrowForward from '@mui/icons-material/ChevronRight'
import ArrowBack from '@mui/icons-material/ChevronLeft'

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  '&.collapse-lg': {
    [theme.breakpoints.up('lg')]: {
      display: 'none !important'
    }
  },
  '&.collapse-xs': {
    [theme.breakpoints.up('sm')]: {
      display: 'none !important'
    }
  }
}))

const Layout = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative'
}))

const LayoutLeftSide = styled(Layout)(({ theme }) => ({
  width: scale.containerCollapse.leftSide,
  borderRight: `1px solid ${colors.lightGray}`,
  transition: 'width .5s ease',
  '&:hover': {
    '& .btn-collapse': {
      opacity: 1
    }
  },
  '&.left-isCollapse': {
    width: scale.containerCollapse.leftIsCollapse
  },
  [theme.breakpoints.down('lg')]: {
    width: `calc(${scale.containerCollapse.rightSide} - 25%)`
  },
  [theme.breakpoints.down('md')]: {
    width: `calc(${scale.containerCollapse.rightSide} - 15%)`
  },
  [theme.breakpoints.down('sm')]: {
    '&.left-isCollapse': {
      width: `calc(${scale.containerCollapse.leftIsCollapse} - 0.5rem)`
    },
    '&.right-isCollapse': {
      width: '100%'
    }
  }
}))

const LayoutRightSide = styled(Layout)(({ theme }) => ({
  width: scale.containerCollapse.rightSide,
  '&.right-width': {
    width: `calc(100% - ${scale.containerCollapse.leftIsCollapse})`,
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - (${scale.containerCollapse.leftIsCollapse} - 0.5rem))`
    }
  },
  [theme.breakpoints.down('lg')]: {
    width: `calc(${scale.containerCollapse.leftSide} + 25%)`
  },
  [theme.breakpoints.down('md')]: {
    width: `calc(${scale.containerCollapse.leftSide} + 15%)`
  },
  [theme.breakpoints.down('sm')]: {
    '&.right-isCollapse': {
      width: '1.5rem'
    }
  }
}))

const ButtonCollapse = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '1.5rem',
  right: '-1.063rem',
  backgroundColor: colors.white,
  boxShadow: `0 0 6px 0 ${colors.black16}`,
  border: `1px solid ${colors.lightGray}`,
  padding: 0,
  width: 34,
  height: 34,
  zIndex: 2,
  opacity: 0,
  transition: 'opacity .3s ease',
  '& svg': {
    fontSize: 28
  },
  '&:hover': {
    backgroundColor: colors.themeSecondColor,
    color: colors.white
  },
  '&:hover, &.active': {
    opacity: 1
  },
  [theme.breakpoints.down('lg')]: {
    opacity: 1
  }
}))

const AreaCollapse = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: '-.5rem',
  width: '1rem',
  zIndex: 1,
  '&:hover': {
    '& + .btn-collapse': {
      opacity: 1
    }
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}))

const ComponentContainer = styled('div')(({ theme }) => ({
  transition: 'opacity .5s ease'
}))

const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.white,
    color: colors.darkGray,
    boxShadow: 'none',
    border: `1px solid ${colors.lightGray}`,
    fontSize: 13,
    fontWeight: 400,
    marginBottom: '5px !important',
    padding: '2px 6px'
  }
}))

type ContainerCustomProps = {
  tooltipExpand?: string
  tooltipShorten?: string
  maxSize?: 'xl' | 'lg' | 'xs'
  componentLeft: any
  componentRight: any
  styleComponentLeft?: any
  styleComponentRight?: any
  callBackStatusCollapse?: (status: boolean) => void
  key?: any
}

export default function Container(props: ContainerCustomProps) {
  const [collapse, setCollapse] = useState(false)
  const [tooltip, setTooltip]: any[] = useState(false)

  const onCollapse = () => {
    setTooltip(false)
    setCollapse(!collapse)
    if (props.callBackStatusCollapse) props.callBackStatusCollapse(!collapse)
  }

  return (
    <>
      <Row className={`layout-collapse ${props.maxSize ? `collapse-${props.maxSize}` : 'collapse-xl'}`}>
        <LayoutLeftSide className={`layout-left-side ${collapse ? 'left-isCollapse' : 'right-isCollapse'}`}>
          <AreaCollapse onClick={onCollapse} className="area-collapse"></AreaCollapse>
          {(props.tooltipExpand && props.tooltipShorten && (
            <LightTooltip title={collapse ? props.tooltipExpand : props.tooltipShorten} placement="top-start" disableFocusListener disableTouchListener disableInteractive open={tooltip}>
              <ButtonCollapse
                className={`btn-collapse ${collapse ? 'active' : ''}`}
                onClick={onCollapse}
                onMouseEnter={() => setTooltip(true)}
                onMouseLeave={() => {
                  setTooltip(false)
                }}
              >
                {collapse ? <ArrowForward /> : <ArrowBack />}
              </ButtonCollapse>
            </LightTooltip>
          )) || (
              <ButtonCollapse className={`btn-collapse ${collapse ? 'active' : ''}`} onClick={onCollapse}>
                {collapse ? <ArrowForward /> : <ArrowBack />}
              </ButtonCollapse>
            )}

          <ComponentContainer className={`layout-left-side-container ${collapse ? 'overflow-hidden opacity-0' : ''}`} style={{ ...props.styleComponentLeft }}>
            {props.componentLeft}
          </ComponentContainer>

        </LayoutLeftSide>
        <LayoutRightSide className={`layout-right-side ${collapse ? 'right-width' : 'right-isCollapse'}`}>
          <ComponentContainer className={`layout-right-side-container ${collapse ? '' : 'd-none d-md-block'}`} style={{ ...props.styleComponentRight }}>
            {props.componentRight}
          </ComponentContainer>

        </LayoutRightSide>


      </Row>
    </>
  )
}
