import { useState, useCallback, useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem'
import _ from 'lodash'

/** API */
import PatientManagementApi from 'api/counter/patient-management/patient-management.api'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** UTILS || SLICE */
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import { UseSnCode } from 'utils/useSnCode'

/** COMPONENT */
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import FilterSelect from 'component/Select/FilterSelect'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { swalActive } from 'component/Alert/Swal'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import { Tooltip, Fade } from '@mui/material';
/** STYLE */
import { Layout as layoutstyle, ResponsiveTable as ResponsiveTablestyle } from 'features/counter/patient-management/UseStyled'
import { Layout, ResponsiveTable } from 'features/counter/patient-management/UseStyledWithSN'

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

export default function PatientManagement() {
  const { t } = useTranslation()

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('DESC')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('ALL')

  const [patients, setPatients] = useState([])
  const [isChecks, setIsChecks] = useState([] as any)
  const [isCheckAll, setIsCheckAll] = useState('')
  const [snType, setSnType] = useState(false);

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode();
      setSnType(sn);
    };

    fetchData();

  }, [snType]);

  useEffect(() => {
    handleSnCode()
  }, [])


  const loadData = useCallback(async () => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status } //fix filter status all
    const patient = await PatientManagementApi.findAll(condition)
    if (patient.status === 200) {
      setRowCount(patient.headers['x-total'])
      setPatients(patient.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])

  useEffect(() => {
    loadData()
  }, [loadData])

  const handleChangeStatus = (statusVal: string) => {
    setStatus(statusVal)
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const onActive = (id: number, valueStatus: string) => {
    const patient = _.find(patients, { patientId: id })

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('PATIENT_MANAGEMENT.ALERT.CONFIRM_STATUS')}</p>`,
      `<p class="text-left mb-0 mx-auto" style="max-width: 310px">${t(`PATIENT_MANAGEMENT.ALERT.CONFIRM_STATUS_PATIENT`, {
        firstName: _.get(patient, 'firstname'),
        lastName: _.get(patient, 'lastname'),
        status: t(`STATUS.${valueStatus}`)
      })}</p>`,
      (res: any) => {
        if (res) {
          PatientManagementApi.updateStatus(id, valueStatus)
            .then((resp) => {
              notiSuccess(t('PATIENT_MANAGEMENT.MESSAGE.SUCCESS.UPDATE_STATUS'))
              loadData()
            })
            .catch((e) => {
              notiError(t('PATIENT_MANAGEMENT.MESSAGE.ERROR'))
            })
        }
      },
      'question', t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
    )
  }

  const onActiveByCheckbox = (id: Array<number>, checkboxStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('PATIENT_MANAGEMENT.ALERT.CONFIRM_STATUS')}</p>`,
      `<p class="text-left mb-0 mx-auto" style="max-width: 310px">${t(`PATIENT_MANAGEMENT.ALERT.CONFIRM_STATUS_PATIENTS`, { length: isChecks.length })}</p>`,
      (res: any) => {
        if (res) {
          PatientManagementApi.updateStatusByCheckbox(id, checkboxStatus)
            .then((resp) => {
              notiSuccess(t('PATIENT_MANAGEMENT.MESSAGE.SUCCESS.UPDATE_STATUS'))
              loadData()
            })
            .catch((e) => {
              notiError(t('PATIENT_MANAGEMENT.MESSAGE.ERROR'))
            })
            .finally(() => {
              setIsChecks([])
            })
        }
      },
      'question', t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
    )
  }

  const onCheckAll = (event: any) => {
    const { checked } = event
    const id = _.map(patients, (i: any) => {
      return i.patientId
    })
    if (checked) {
      setIsChecks(Array.from(new Set([...isChecks, ...id])))
    } else {
      setIsChecks(_.filter(isChecks, (i) => !_.includes(id, i)))
    }
  }

  const onCheck = (event: any) => {
    const { checked, value } = event
    if (checked) {
      setIsChecks([...isChecks, Number(value)])
    } else {
      setIsChecks(
        _.filter(isChecks, (i: number) => {
          return i !== Number(value)
        })
      )
    }
  }

  const handleOnCheckAll = () => {
    const hasSome = _.some(patients, (i: any) => _.some(isChecks, (j: number) => Number(i.patientId) === Number(j)))
    const hasAll = _.every(patients, (i: any) => _.some(isChecks, (j: number) => Number(i.patientId) === Number(j)))
    if (!_.isEmpty(isChecks) && !hasAll && hasSome) setIsCheckAll('indeterminate')
    else if (!_.isEmpty(isChecks) && hasAll) setIsCheckAll('checked')
    else setIsCheckAll('')
  }

  useEffect(() => {
    handleOnCheckAll()
  }, [isChecks, patients])

  const headCells = [
    {
      id: 'patientSelect',
      disablePadding: false,
      label: 'CHECKBOX',
      values: [
        { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`) },
        { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`) }
      ],
      indeterminate: isCheckAll === 'indeterminate' && true,
      checked: isCheckAll === 'checked' && true,
      disableDropdown: _.isEmpty(isChecks),
      onCheckAll: (event: any) => onCheckAll(event.target),
      onActive: () => onActiveByCheckbox(isChecks, 'ACTIVE'),
      onInactive: () => onActiveByCheckbox(isChecks, 'INACTIVE')
    },
    { id: 'no', disablePadding: false, label: t('PATIENT_MANAGEMENT.TABLE.CELL.NO') },
    { id: 'cnNumber', disablePadding: false, label: t('PATIENT_MANAGEMENT.TABLE.CELL.PATIENT_ID') },
    { id: 'branchId', disablePadding: false, label: t('PATIENT_MANAGEMENT.TABLE.CELL.BRANCH_ID') },
    { id: 'snCode', disablePadding: false, label: t('PATIENT_MANAGEMENT.TABLE.CELL.SN_CODE') },
    { id: 'patientName', disablePadding: false, label: t('PATIENT_MANAGEMENT.TABLE.CELL.NAME') },
    { id: 'idCard', disablePadding: false, label: t('PATIENT_MANAGEMENT.TABLE.CELL.ID_CARD') },
    { id: 'phone', disablePadding: false, label: t('PATIENT_MANAGEMENT.TABLE.CELL.PHONE_NUMBER') },
    { id: 'status', disablePadding: false, label: t('PATIENT_MANAGEMENT.TABLE.CELL.STATUS') },
    { id: 'updatedBy', disablePadding: false, label: t('PATIENT_MANAGEMENT.TABLE.CELL.LAST_UPDATE') },
    { id: 'action', disablePadding: false, label: t('PATIENT_MANAGEMENT.TABLE.CELL.ACTION') }
  ]
  if (!snType) {
    headCells.splice(4, 1)
  }
  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1

    const { patientId, cnNumber, branchCnNumber, snCode, firstname, lastname, idCard, phone, createdBy, updatedBy, updatedAt, status: patientStatus } = objData
    const statusBtnActive = patientStatus === 'ACTIVE' ? true : permissions.isUpdate.disabled
    const statusBtnInActive = patientStatus === 'INACTIVE' ? true : permissions.isUpdate.disabled
    const rowCheck = _.some(isChecks, (i: number) => Number(i) === Number(patientId))

    const objRenderData = {
      key: patientId,
      id: patientId,
      obj: objData,
      rowSelect: rowCheck,
      columns: [
        { option: 'CHECKBOX', align: 'center', label: patientId },
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: cnNumber ?? '-' },
        { option: 'TEXT', align: 'left', label: branchCnNumber ?? '-' },
        { option: 'TEXT', align: 'left', label: snCode ?? '-' },
        { option: 'TEXT', align: 'left', label: `${firstname} ${lastname}` ?? '-' },
        { option: 'TEXT', align: 'left', label: idCard ? idCard : '-' },
        { option: 'TEXT', align: 'left', label: phone ?? '-' },
        { option: 'STATUS', align: 'center', label: patientStatus },
        {
          option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy ?? createdBy, updatedAt: updatedAt }
        },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive }
          ]
        }
      ]
    }
    if (!snType) {
      objRenderData.columns.splice(4, 1)
    }
    return <TableRowCommon {...objRenderData} onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')} oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')} onCheck={(event) => onCheck(event.target)} />
  }

  return (
    <>
      <Layout>
        <Card className={'border-0 h-100'}>
          <HeaderCard text={t('PATIENT_MANAGEMENT.TITLE')} />
          <Card.Body>
            <Card.Title className={'mb-0 pl-xl-3'}>
              <Row>
                <Col>
                  <Row>
                    <Col sm={12} md={6} lg={5} xl={4} xxl={3} className={'pt-2'}>
                      <InputTextSearch
                        keyInput={'search'}
                        label={t('INPUT.SEARCH')}
                        value={search}
                        onchange={(event) => {
                          setSearch(event.target.value)
                          setPage(1)
                        }}
                        onClear={(event) => {
                          setSearch('')
                          setPage(1)
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4} lg={3} xxl={2} className={'pl-md-0 pt-2'}>
                      <FilterSelect
                        onchange={(event) => {
                          const value = event.target.value
                          if (value) {
                            handleChangeStatus(value)
                            setPage(1)
                          }
                        }}
                        renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                        label={t('STATUS.LABEL')}
                        selectId="select-status"
                        value={status}
                        labelId="label-status"
                        options={[
                          <MenuItem key="1" value="ALL">
                            {t('STATUS.ALL')}
                          </MenuItem>,
                          <MenuItem key="2" value="ACTIVE">
                            {t('STATUS.ACTIVE')}
                          </MenuItem>,
                          <MenuItem key="3" value="INACTIVE">
                            {t('STATUS.INACTIVE')}
                          </MenuItem>
                        ]}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Title>
            {!snType ? (<ResponsiveTablestyle className={'mt-4 pl-xl-3 pr-xl-2'}>

              <TableCustom
                page={page}
                pageLimit={pageLimit}
                sortType={sortType}
                sortBy={sortBy}
                rowCount={rowCount}
                onSort={onRequestSort}
                setPageLimit={handleChangeRowsPerPage}
                setPage={handleChangePage}
                headCells={headCells}
                rowsData={_.map(patients, (val, i) => {
                  return renderData(val, i)
                })}
              />
            </ResponsiveTablestyle>) :
              (<ResponsiveTable className={'mt-4 pl-xl-3 pr-xl-2'}>
                <TableCustom
                  page={page}
                  pageLimit={pageLimit}
                  sortType={sortType}
                  sortBy={sortBy}
                  rowCount={rowCount}
                  onSort={onRequestSort}
                  setPageLimit={handleChangeRowsPerPage}
                  setPage={handleChangePage}
                  headCells={headCells}
                  tooltip={'UPDATE_BY'}
                  rowsData={_.map(patients, (val, i) => {
                    return renderData(val, i)
                  })}
                />
              </ResponsiveTable>)}
          </Card.Body>
        </Card>
      </Layout>
    </>
  )
}