import * as React from 'react'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { colors } from 'constants/theme'
import { styled } from '@mui/material/styles'
import 'component/Select/Style.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  '.MuiInputLabel-root, .MuiInputLabel-root span': {
    fontSize: 16,
    color: `${colors.black60} !important`,
    backgroundColor: 'transparent !important',
    '&.has-placeholder': {
      transition: 'none'
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 3,
      top: '50%',
      zIndex: -1
    },
    '&[data-shrink="true"]': {
      color: `${colors.themeSecondColor} !important`,
      '&.has-placeholder': {
        fontSize: 0
      },
      '&:before': {
        backgroundColor: colors.white
      },
      span: {
        color: `${colors.statusInactive} !important`
      }
    }
  }
}))

const CustomMultiSelect = styled(Select)(({ theme }) => ({
  '&.Mui-focused': {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: `${colors.themeSecondColor} !important`
    },
    '.MuiSelect-nativeInput': {
      '+ svg': {
        transform: 'rotate(180deg)'
      }
    }
  },
  '&.Mui-disabled': {
    '.MuiOutlinedInput-notchedOutline': {
      backgroundColor: colors.black12
    }
  },
  '.MuiSelect-select': {
    paddingTop: 10,
    paddingBottom: 10
  },
  '.MuiSelect-nativeInput': {
    height: 43,
    '+ svg': {
      position: 'absolute',
      right: 14,
      path: {
        fill: colors.black
      }
    }
  },
  '.MuiOutlinedInput-notchedOutline': {
    height: 43,
    borderColor: `${colors.black12} !important`
  }
}))

const MenuProps = {
  classes: { paper: 'style-filter-select-search' }
}

type MultipleSelectProps = {
  labelId: string
  selectId: string
  label: string
  value: any
  name?: any
  setValue: (value: any) => void
  options: any
  renderValue?: any
  fullWidth?: boolean
  disabled?: boolean
  formControlClass?: any
  register?: any
  placeholder?: string
  labelAsPlaceholder?: boolean
  required?: boolean
}

export default function MultipleSelect(props: MultipleSelectProps) {
  const [values, setValue] = React.useState<string[]>([])

  const handleChange = (event: SelectChangeEvent<typeof values>) => {
    const {
      target: { value }
    } = event

    setValue(

      typeof value === 'string' ? value.split(',') : value
    )
    props.setValue(

      typeof value === 'string' ? value.split(',') : value
    )
  }

  return (
    <div>
      <CustomFormControl fullWidth={props.fullWidth} size="small">
        <InputLabel className={`${props.labelAsPlaceholder ? 'has-placeholder' : ''}`} id={props.labelId} required={props.required}>
          {props.label}
        </InputLabel>
        <CustomMultiSelect
          fullWidth={props.fullWidth}
          labelId={props.labelId}
          multiple
          className="w-100"
          onChange={handleChange}
          id={props.selectId}
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          {...props.register}
          disabled={props.disabled}
          maxTagCount="responsive"
          MenuProps={MenuProps}
          renderValue={props.renderValue}
          onClose={() => {
            setTimeout(() => {
              const ele: any = document.activeElement
              ele.blur()
            }, 0)
          }}
          IconComponent={() => <FontAwesomeIcon icon={faCaretDown} />}
        >
          {props.options}
        </CustomMultiSelect>
      </CustomFormControl>
    </div>
  )
}
