import { useCallback, useEffect, useRef, useState } from 'react';
import { Card, Col, Row, Dropdown, ButtonGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Box, MenuItem } from '@mui/material';
import moment from 'moment'
import _ from 'lodash';

import { routeName } from 'routes/routes-name';

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API, UTIL*/
import { getBranch } from 'utils/app.utils';
import DoctorScheduleApi from 'api/counter/doctor-schedule.api'
import ClinicApi, { BranchInterface } from 'api/master/clinic.api';
import ReportApi, { FindAllReportInterface } from 'api/report.api';

/** COMPONENT */
import Loading from 'component/Loading';
import SchedulesList from './SchedulesList';
import FucntionPrint from "component/Print/print";
import HeaderCard from 'component/CardCustom/HeaderCard';
import FilterSelect from 'component/Select/FilterSelect';
import InputCheckbox from 'component/Input/InputCheckbox';
import { useDownloadExcel } from 'component/Excel/hooks/useExcel';
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker';

/** STYLED */
import * as UseStyled from 'features/report/useStyled';
import { dateToView } from 'utils/date.utils';
import { APPOINTMENT_STATUS } from 'features/auth/confirm-appointment';
import ButtonCustom from 'component/Button/ButtonCustom';
import { Margin, Padding } from '@mui/icons-material';

type Doctors = {
    userId: number
    firstname: string
    lastname: string
    fullname: string
}

interface DentistInterface {
    dentistId: number
    dentistFullname: string
}

export default function Schedules() {
    const { t } = useTranslation();
    const history = useHistory();
    const componentRef = useRef<HTMLDivElement>(null);
    const momentNowRange = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    const momentDate = () => momentNowRange

    const [branchId, setBranchId] = useState<number>(1);
    const [branches, setBranches] = useState<any>(getBranch());
    const [rangeDate, setRangeDate] = useState<string[]>(momentDate);
    const [selectDoctors, setSelectDoctors] = useState<any[]>(['ALL'])
    const [doctors, setDoctors] = useState<Doctors[]>([])
    const [doctorsMultiple, setDoctorsMultiple] = useState<DentistInterface[]>([])
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortBy, setSortBy] = useState<string>('createAt');
    const [sortType, setSortType] = useState<string>('ASC');
    const [orderedSelectedDoctors, setOrderedSelectedDoctors] = useState<any[]>([]);



    const [SchedulesData, getSchedulesData] = useState<any[]>([]);
    const [schedulesPage, setSchedulesPage] = useState<number>(1);
    const [schedulesTypePage, setSchedulesTypePage] = useState<number>(1);
    const [schedulesPageLimit, setSchedulesPageLimit] = useState<number>(10);
    const [operativeId, setOperativeId] = useState<number>(0);
    const [operativeTypeId, setOperativeTypeId] = useState<number>(0);
    const [appointmentStatus, setAppointmentStatus] = useState('ALL');


    const [checkDf, setCheckDf] = useState<boolean>(false);
    const [fetchData, setFetchData] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);


    const verifyPermission = async () => {
        const condition: FindAllReportInterface = {
            page: 1, pageLimit: 200
        }
        ReportApi.reportList(condition)
            .then(({ data }) => {
                const check = _.find(data, (val: any) => String(val.reportKey) === 'SCHEDULES') ? true : false
                if (!check) {
                    history.push(routeName.report)
                    return false
                }
            })

        ClinicApi.findAllBranches()
            .then(({ data }) => setBranches(data))
            .catch((e) => { return })

        DoctorScheduleApi.findAllDoctor({ branchId: branchId }).then(({ data: dataAllDoctor }) => {
            const dentists: DentistInterface[] = dataAllDoctor.map((d: any) => {
                return {
                    dentistId: d.userId,
                    dentistFullname: d.fullname
                }
            })
            setDoctorsMultiple(dentists)
            setSelectDoctors(['ALL', ...dentists.map((d: DentistInterface) => d.dentistId)])
        })

        return true
    }

    useEffect(() => {
        verifyPermission()
    }, [branchId])

    const fetchSchedules = () => {
        let condition: FindAllReportInterface = {
            branchId: branchId,
            dateStart: rangeDate[0],
            dateEnd: rangeDate[1],
        }

        if (operativeId && operativeTypeId !== 0) {
            condition = { ...condition, operativeId: operativeId }
        }

        if (appointmentStatus && appointmentStatus !== 'ALL') {
            condition = { ...condition, status: appointmentStatus }
        }


        if (selectDoctors) {
            const doctor = _.filter(selectDoctors, (v: any) => v > 0).join(',')
            if (doctor) {
                condition = { ...condition, doctorId: _.filter(selectDoctors, (v: any) => v > 0).join(',') }
            }
        }

        if (branchId) {
            condition = { ...condition, branchId: branchId }
        }

        if (rangeDate) {
            if (rangeDate[0] !== null && rangeDate[1] !== null) {
                condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
            }
        }

        ReportApi.reportSchedules(condition)
            .then(({ headers, data }) => {
                getSchedulesData(data.rows)
                setRowCount(headers['x-total'])
            })
            .catch((e) => setLoading(false))
            .finally(() => setLoading(false))

        setFetchData(false)
    }


    const { onDownload } = useDownloadExcel({
        tableExportRef: componentRef.current,
        filename: `${t(`REPORT.REPORT_NAME.SCHEDULES`)} (${rangeDate[0]}-${rangeDate[1]})`,
        sheetName: `${t(`REPORT.REPORT_NAME.SCHEDULES`)}`
    })

    const handleFilterDoctors = (e: any) => {
        setSchedulesPage(1);
        const value = e.target?.value;
        const valLength: number = value.length;

        // ถ้าเคยเลือก 'ALL' แต่ตอนนี้ไม่ได้เลือก 'ALL'
        if (selectDoctors.includes('ALL') === true && value.includes('ALL') === false) {
            setSelectDoctors(['']);
        }
        // ถ้าเลือก 'ALL' ในครั้งนี้ หรือเลือกแพทย์ทุกคนแต่ไม่รวม 'ALL'
        else if (value[valLength - 1] === 'ALL' || (valLength > 0 && doctors.length === valLength && !value.includes('ALL'))) {
            setSelectDoctors(['ALL', ...doctorsMultiple.map((d: DentistInterface) => d.dentistId)]);
        }

        // ถ้าเลือก 'CLEAR' หรือไม่เลือกอะไรเลย
        else if (value[valLength - 1] === '' || valLength === 0) {
            setSelectDoctors(['']);
        }
        // ถ้าเลือกแพทย์บางคน
        else {

            const selectedDoctors = value.filter((v: any) => v > 0);
            setOrderedSelectedDoctors(selectedDoctors);
            setSelectDoctors(selectedDoctors);
        }
    }

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangeRowsPerPage = (limit: number) => setSchedulesPageLimit(limit)

    const handleChangePage = (val: number) => setSchedulesPage(val)

    const setDoc = (data: any) => {
        const sortedSelectData = data.sort((a: any, b: any) => {
            const idA = a.dentistId;
            const idB = b.dentistId;
            return selectDoctors.indexOf(idA) - selectDoctors.indexOf(idB);
        });
        return sortedSelectData
    }
    useEffect(() => {
        fetchSchedules()
    }, [schedulesPage])
    const searchCilck = (() => {
        fetchSchedules()
    })


    return (
        <div>
            <Card className={'border-0 h-100'}>
                <HeaderCard text={t(`REPORT.REPORT_NAME.SCHEDULES`)} />
                <Card.Body>
                    <Card.Title className={'mb-0'}>
                        <Row className='mx-0'>
                            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                                <FilterSelect
                                    onchange={(e) => {
                                        setBranchId(e.target.value)
                                        setSchedulesPage(1)
                                    }}
                                    renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                                    label={''}
                                    selectId="select-branch"
                                    labelId="label-branch"
                                    value={branchId}
                                    options={
                                        _.map(branches, (b: BranchInterface, index) => {
                                            return (
                                                <MenuItem key={b.branchId} value={b.branchId}>
                                                    {b.branchName || b.branchNameEn}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                    formControlStyle={{ maxWidth: 'unset !important' }}
                                    classesOption="style-select-doctors"
                                />
                            </Col>

                            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                                <InputSecondNewRangePicker
                                    inputHeight={32}
                                    value={rangeDate || emptyRangePicker}
                                    onchange={(val: any) => {
                                        setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                                        setSchedulesPage(1)
                                        setSchedulesTypePage(1)
                                    }}
                                    label={''}
                                />
                            </Col>

                            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}
                                onClick={() => {
                                    setCheckDf(!checkDf)
                                    setSchedulesPage(1)
                                }}>
                                <FilterSelect
                                    formControlClass={'filter filter-doctors'}
                                    formControlStyle={{ maxWidth: 'unset !important' }}
                                    multiple
                                    fullWidth={true}
                                    onchange={handleFilterDoctors}
                                    renderValue={(e) => {
                                        if (_.isEmpty(selectDoctors) || _.includes(selectDoctors, '')) return `${t('REPORT.FILTER.DOCTOR')}: ${t('REPORT.FILTER.NO_SELECT')}${t('REPORT.FILTER.DOCTOR')}`
                                        else if (_.includes(selectDoctors, 'ALL')) return `${t('REPORT.FILTER.DOCTOR')}: ${t('REPORT.FILTER.DOCTOR')}${t(`REPORT.FILTER.ALL`)}`
                                        else {
                                            return `${t('REPORT.FILTER.DOCTOR')}: ${setDoc(doctorsMultiple
                                                .filter((d: any) => selectDoctors.includes(d.dentistId)))
                                                ?.map((d: any) => d.dentistFullname)
                                                .join(', ')}`
                                        }
                                    }}
                                    label={t('REPORT.FILTER.DOCTOR')}
                                    selectId="select-doctors"
                                    value={selectDoctors}
                                    labelId="label-doctors"
                                    options={[
                                        <MenuItem className="py-1" key="ALL" value="ALL">
                                            <InputCheckbox label={''} checked={_.find(selectDoctors, (dentistId: any) => dentistId === 'ALL') || _.size(selectDoctors) === _.size(doctorsMultiple)} />
                                            {t(`REPORT.FILTER.ALL`)}
                                        </MenuItem>,
                                        _.map(doctorsMultiple, (doctor: DentistInterface, i: number) => (
                                            <MenuItem className="py-1" key={i} value={doctor.dentistId}>
                                                <InputCheckbox label={''} checked={selectDoctors.find((dentistId: number) => dentistId === doctor.dentistId) ? true : false} />
                                                {doctor.dentistFullname}
                                            </MenuItem>
                                        )),
                                        <hr key={'hr'} className="mt-2 mb-1" />,
                                        <MenuItem className="py-1 no-bg " key="clear" value="" style={{ backgroundColor: '#fff' }} selected>
                                            <UseStyled.SpanClearFilter>{t(`REPORT.FILTER.CLEAR`)}</UseStyled.SpanClearFilter>
                                        </MenuItem>
                                    ]} />
                            </Col>
                            <Col sm={6} md={4} xl={2} className={'pt-2 px-1'}>
                                <FilterSelect
                                    onchange={(e) => {
                                        setAppointmentStatus(e.target.value)
                                        setSchedulesPage(1)
                                    }}
                                    renderValue={() => appointmentStatus === 'ALL'
                                        ? `${t('REPORT.FILTER.APPOINTMENT_STATUS')}: ${t('REPORT.FILTER.ALL')}`
                                        : `${t('REPORT.FILTER.APPOINTMENT_STATUS')}: ${t(`APPOINTMENT.STATUS.${appointmentStatus}`)}`
                                    }
                                    label={''}
                                    selectId="select-appointment-status"
                                    labelId="label-appointment-status"
                                    value={appointmentStatus}
                                    options={
                                        [
                                            <MenuItem key="ALL" value="ALL">
                                                {t('REPORT.FILTER.ALL')}
                                            </MenuItem>,
                                            ...Object.values(APPOINTMENT_STATUS).map((status, index) => (
                                                <MenuItem key={index} value={status}>
                                                    {t(`APPOINTMENT.STATUS.${status}`)}
                                                </MenuItem>
                                            ))
                                        ]
                                    }
                                    formControlStyle={{ maxWidth: 'unset !important' }}
                                    classesOption="style-select-doctors"
                                />
                            </Col>
                            <Col md={1} xl={1} className={'pt-0'}>
                                <ButtonCustom
                                    onClick={() => {
                                        setFetchData(true);
                                        setSchedulesPage(1);
                                        searchCilck()
                                    }}
                                    textButton={t('INPUT.SEARCH')} style={{ height: '30px', marginTop: '5px' }} />
                            </Col>

                            <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 '}>
                                <ButtonGroup style={{ margin: "2px", }}>

                                    <UseStyled.DropdownDocument>
                                        <Dropdown.Toggle id="dropdown-basic" className='ml-xl-auto'>
                                            {t('PRINT_DOCUMENT')}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={onDownload}>
                                                {t('Excel')}
                                            </Dropdown.Item>
                                            <FucntionPrint
                                                content={() => componentRef.current}
                                                docTitle={t(`REPORT.REPORT_NAME.SCHEDULES`)}
                                                removeIfram
                                                trigger={() => <Dropdown.Item >
                                                    {t('PDF')}
                                                </Dropdown.Item>}
                                                style={`@page { size: 1122.519685px 793.7007874px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                                            />
                                        </Dropdown.Menu>
                                    </UseStyled.DropdownDocument>
                                </ButtonGroup>

                            </Col>

                        </Row>
                    </Card.Title>
                    <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
                        <SchedulesList
                            exportFilter={{
                                header:
                                    <UseStyled.Styles className="pt-3">
                                        <table style={{ border: 'none', borderCollapse: 'collapse' }}>
                                            <tr>
                                                <td style={{ border: 'none', textAlign: 'left', fontSize: '14px' }} colSpan={8}>
                                                    {t(`REPORT.REPORT_NAME.SCHEDULES`)}
                                                </td>
                                            </tr>
                                            <tr style={{ fontSize: '13px' }}>
                                                <td style={{ border: 'none', marginRight: "5px" }}>{t('REPORT.FILTER.BRANCH')}: </td>
                                                <td style={{ border: 'none', marginRight: "5px" }}>{_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}</td>
                                                <td style={{ border: 'none', marginRight: "5px" }}>{t('REPORT.FILTER.DATE_START')} </td>
                                                <td style={{ border: 'none', marginRight: "5px" }}>{rangeDate[0] !== "Invalid date" ? dateToView(rangeDate[0]) : ""}</td>
                                                <td style={{ border: 'none', marginRight: "5px" }}>{t('REPORT.FILTER.DATE_END')} </td>
                                                <td style={{ border: 'none', marginRight: "5px" }}>{rangeDate[1] !== "Invalid date" ? dateToView(rangeDate[1]) : ""}</td>
                                                <td style={{ border: 'none', marginRight: "5px" }}>{t('REPORT.FILTER.DOCTOR')}:</td>
                                                <td style={{ border: 'none', marginRight: "5px" }}>
                                                    {
                                                        _.isEmpty(selectDoctors) || _.includes(selectDoctors, '')
                                                            ? ` ${t('REPORT.FILTER.NO_SELECT')}`
                                                            : _.includes(selectDoctors, 'ALL')
                                                                ? `${t('REPORT.FILTER.ALL')}`
                                                                : `${setDoc(doctorsMultiple
                                                                    .filter((d: any) => selectDoctors.includes(d.dentistId)))
                                                                    ?.map((d: any) => d.dentistFullname)
                                                                    .slice(0, 3)  // เเสดงเเค่ 3 คนเเรก
                                                                    .join(', ')}`
                                                    }
                                                </td>
                                            </tr>
                                        </table>
                                    </UseStyled.Styles>
                            }}
                            page={schedulesPage}
                            pageLimit={schedulesPageLimit}
                            rowCount={rowCount}
                            sortBy={sortBy}
                            sortType={sortType}
                            data={SchedulesData}
                            onRequestSort={onRequestSort}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleChangePage={handleChangePage}
                            componentRef={componentRef} />
                    </Box>
                </Card.Body>
            </Card>
            <Loading show={loading} type='fullLoading' />
        </div>
    )
}