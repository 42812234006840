import { menuSeconds } from 'constants/menus'
import RetrospectiveEdit from 'features/counter/retrospective-edit/retrospective-edit'

const routeRetrospectiveEdit = [
    {
        ...menuSeconds.RETROSPECTIVE_EDIT,
        appBar: true,
        component: RetrospectiveEdit
    }
]

export default routeRetrospectiveEdit
