import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

/** ROUTE */
import { routeName } from 'routes/routes-name'

/** API */
import TreatmentQueueAPI, { ListQueue } from 'api/dentists/treatmentQueue.api'
import HomeApi from 'api/home/home.api'
import { getBranch, getServicePoint } from 'utils/app.utils'

/** COMPONENT */
import ServicePointArea from 'component/Appointment/ServicePointsArea'
import CardServicePoint from 'component/Appointment/CardServicePoint'
import Loading from 'component/Loading'
import { swalWarning } from 'component/Alert/Swal'
import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'

type TreatmentServicePointProps = {
  setCountServicePoint?: (count: number) => void
  branch: string
}

type ServicePointType = {
  servicePointId?: number
  pointOfService?: string
  servicePointName?: string
  servicePointNameEn?: string
  cases?: string[]
}

export default function TreatmentServicePoint(props: TreatmentServicePointProps) {
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const branchId = Number(getBranch())
  const servicePointId = Number(getServicePoint())
  const [servicePoint, setServicePoint] = useState<ServicePointType>({})
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0)

  const loadQueue = async () => {
    if (branchId && servicePointId) {
      const params: ListQueue = {
        branchId: branchId,
        servicePointId
      }

      const queue = await TreatmentQueueAPI.listQueue(params)
      if (queue.status === 200 && !_.isEmpty(queue.data)) setServicePoint(queue.data)
      else setServicePoint({})
    }
  }

  const updateTreatmentStatusService = async (patientCaseId: number, patientId: number, servicePointId: number, status: string) => {
    setLoading(true)
    if (status !== "SERVICE") {
      await HomeApi.updateStatusService(patientCaseId)
        .then(() => history.push(routeName.treatmentsForm, { patientId: patientId, servicePointId: servicePointId }))
        .catch((e) => {
          if (e?.response?.data?.message === 'WRONG_STATUS_VISIT') return swalWarning(t('TREATMENT_QUEUE.ERROR.ERROR_CANNOT_CHOOSE'), t('TREATMENT_QUEUE.ERROR.ERROR_VISIT_DONE'), () => { loadQueue() })
          else return swalWarning(t('TREATMENT_QUEUE.ERROR.ALREADY_CANCEL_VISIT'), t('TREATMENT_QUEUE.ERROR.ALREADY_CANCEL_VISIT_SUB'), () => { loadQueue() })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      history.push(routeName.treatmentsForm, { patientId: patientId, servicePointId: servicePointId })
    }
  }

  useEffect(() => {
    if (props.setCountServicePoint) {
      props.setCountServicePoint(_.size(servicePoint.cases))
      setCount((_.size(servicePoint.cases)))
    }
  }, [servicePoint])

  const dataAlert = useSelector((state: any) => state.modal.dataAlert)
  useEffect(() => {
    loadQueue()
  }, [dataAlert])

  return (
    <>
      <ServicePointArea
        key={servicePoint.servicePointId}
        headerName={`${servicePoint[`servicePointName${i18n.language !== "th" ? "En" : ""}`] || servicePoint.servicePointNameEn || '-'} (${count})`}
        serviceType={`${servicePoint.pointOfService}`}
        body={_.map(_.orderBy(servicePoint.cases, ['sorting'], ['asc']), (service: any, index: number) => {
          return (
            <CardServicePoint
              key={index}
              hideFooterAction
              type="queue"
              patient={service}
              number={index}
              serviceType={`${servicePoint.pointOfService}`}
              onClick={() => updateTreatmentStatusService(service.patientCaseId, service.patientId, servicePoint.servicePointId || 0, service.status)}
              branch={props.branch}
            />
          )
        })} />
      <Loading show={loading} type={`fullLoading`} />
    </>
  )
}
