import { useCallback, useEffect, useState } from 'react'
import { ExportProps } from "features/report/report-constants";
import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import _ from 'lodash'
import * as UseStyled from 'features/report/useStyled'
import { useTranslation } from 'react-i18next'
import { toBuddhistYear } from "utils/app.utils"
import moment from 'moment'
import { UseSnCode } from 'utils/useSnCode'
export interface FcComponent {
    filter: any
    page: number
    pageLimit: number
    rowCount: number
    sortBy: string
    sortType: string
    data: any
    onRequestSort: (sortBy: string, sortType: string) => void
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
    componentRef: any
    exportData: any
}

export default function PatientList(props: FcComponent) {
    const { t } = useTranslation()

    const [snType, setSnType] = useState(false)

    const handleSnCode = useCallback(() => {
        const fetchData = async () => {
            const sn = await UseSnCode()
            setSnType(sn)
        }

        fetchData()
    }, [snType])

    useEffect(() => {
        handleSnCode()
    }, [])

    const headCells = [
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.#NO', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.PATIENT_CN_NUMBER', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.PATIENT_BRANCH_CN_NUMBER', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.PATIENT_SN_CODE', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.PATIENT_NAME', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.ID_CARD', align: 'left', class: 'text-nowrap w-100', style: { with: '500px' } },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.GENDER', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.DATE_OF_BIRTH', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.AGE', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.ETHNICITY', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.NATIONALITY', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.RELIGION', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.MARITAL', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.OCCUPATION', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.CHANNEL', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.PATIENT_TYPE', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.BLOOD_TYPE', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.DISEASE', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.MEDICINE', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.ALLERGIC', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.SYMPTOMS', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.TREATMENT_RIGHT', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.ADDRESS', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.MOBILE', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.TEL', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.LINE', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.EMAIL', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT.FACEBOOK', align: 'left', class: 'text-nowrap' }
    ]
    if (!snType) {
        headCells.splice(3, 1)

    }
    const renderData = (data: any, no: number, exportData: boolean) => {
        const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
        function checkNumber(text: any) {
            if (!text) return false
            const pattern = /^([a-z0-9])+$/i;
            return text.trim().match(pattern);
        }
        function checkNull(text: any) {
            if (!text) return '-'
            return text.trim()
        }
        const columns = [
            { option: 'TEXT', align: 'center', label: num },
            { option: 'TEXT', align: 'left', label: data.cnNumber || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.branchCnNumber || '-' },
            { option: 'TEXT', align: 'left', label: data.snCode || '-' },
            { option: 'TEXT', align: 'left', label: `${data.prefixName}${data.firstname} ${data.lastname}`, class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', format: checkNumber(data.idCard) ? "TEXT" : undefined, label: checkNull(data.idCard), class: 'text-nowrap ', style: { with: '500px' } },
            { option: 'TEXT', align: 'left', label: t(`REPORT.VALUE.PATIENT.GENDER.${data.gender}`), class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.dateOfBirth ? toBuddhistYear(moment(data.dateOfBirth), 'DD/MM/YYYY') : t(`REPORT.VALUE.PATIENT.BLOOD_TYPE.NOT_SPECIFIED`), class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.age || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.originName || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.nationalityName || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: t(`REGISTER.FORM.RELIGION_OPTION.${data.religion}`), class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: t(`REGISTER.FORM.MARITAL_OPTION.${data.maritalStatus}`), class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.careerName || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.accessChannel || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.patientTypeName || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.bloodType === 'NOT_SPECIFIED' ? t(`REPORT.VALUE.PATIENT.BLOOD_TYPE.NOT_SPECIFIED`) : data.bloodType, class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.disease || '-' },
            { option: 'TEXT', align: 'left', label: data.medicineUse || '-' },
            { option: 'TEXT', align: 'left', label: data.beAllergic || '-' },
            { option: 'TEXT', align: 'left', label: data.importantSymptoms || '-', class: 'text-ellipsis' },
            { option: 'TEXT', align: 'left', label: data.groupTreatmentRight || '-', class: 'text-ellipsis' },
            { option: 'TEXT', align: 'left', label: data.fullAddress || '-', class: 'text-ellipsis' },
            { option: 'TEXT', align: 'left', format: checkNumber(data.phone) ? "TEXT" : undefined, label: checkNull(data.phone), class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', format: checkNumber(data.telephone) ? "TEXT" : undefined, label: checkNull(data.telephone), class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.line || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.email || '-', class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.facebook || '-', class: 'text-nowrap' }
        ]
        if (!snType) {
            columns.splice(3, 1)

        }
        const objRenderData = {
            key: num.toString(),
            id: no.toString(),
            obj: data,
            columns: columns
        }

        return (
            <>
                {exportData === true && <TableRowExport {...objRenderData} /> ||
                    <TableRowCommon  {...objRenderData} />
                }
            </>
        )
    }

    return (
        <>
            <div className="print-show-disable">
                <UseStyled.ResponsiveReportTable>
                    <TableCustom
                        page={props.page}
                        pageLimit={props.pageLimit}
                        sortType={props.sortType}
                        sortBy={props.sortBy}
                        rowCount={props.rowCount}
                        onSort={props.onRequestSort}
                        setPageLimit={props.handleChangeRowsPerPage}
                        setPage={props.handleChangePage}
                        headCells={headCells}
                        rowsData={
                            _.map(props.data, (d: any, index: number) => {
                                return renderData(d, index, false)
                            })
                        }
                        customScroll
                    />
                </UseStyled.ResponsiveReportTable >
            </div>
            <div className="print-show" ref={props.componentRef}>
                <PatientExport
                    header={
                        <UseStyled.Styles className="pt-3">
                            <table style={{ border: 'none', borderCollapse: 'collapse' }}>
                                <tr>
                                    <td style={{ border: 'none', textAlign: 'left', fontSize: '14px' }}>
                                        {t(`REPORT.REPORT_NAME.PATIENT_INFORMATION`)}

                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ border: 'none' }}>{t('REPORT.FILTER.BRANCH')}: </td>
                                    <td style={{ border: 'none' }}>{props.filter.branch}</td>
                                </tr>
                                <tr></tr>
                            </table>
                        </UseStyled.Styles>
                    }
                    headCells={headCells}
                    rowsData={
                        _.map(props.exportData, (d: any, index: number) => {
                            return renderData(d, index, true)
                        })
                    } />
            </div>
        </>

    )
}

export function PatientExport(props: ExportProps) {
    const { t } = useTranslation()
    const head = props.headCells
    const headCells = head.filter((headCell: any) => headCell.id !== 'action')
    const { header } = props
    return (
        <UseStyled.Styles className="pt-3">
            {header}
            <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                <thead>
                    <tr>
                        {_.map(headCells, (val: any, index: number) => {
                            return (
                                < td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, width: '100%' }}> {t(val.labelKey)}</td>
                            )
                        })}
                    </tr>
                </thead>
                <tbody >
                    {props.rowsData}
                    {!props.rowsData.length && (
                        <td colSpan={headCells.length} style={{ fontSize: 10, }}>
                            {t('REPORT.TITLE.NO_DATA')}
                        </td>
                    )}
                </tbody>
            </table>
        </UseStyled.Styles >
    )
}