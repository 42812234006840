import BaseAPI from 'api/api'

const url = `treatment-history`

export interface CreateTreatmentImage {
  patientGalleryId?: number
  patientId: number
  vnNumber?: string | number
  imageTypeId: number
  treatmentImages?: TreatmentImages
}

export interface TreatmentImages {
  base64?: string
  fileExtension?: string
  fileName?: string
  fileSize?: string
  treatmentFileId?: number | null
  type?: string
}

export default class UploadTreatmentApi extends BaseAPI {
  static findAllImageTypes(): Promise<any> {
    return this.api.get('clinic/image-type').then((res) => res)
  }

  static findTreatmentHistory(patientId?: number): Promise<any> {
    return this.api.get(`${url}/all-vn-number/${patientId}`).then((res) => res)
  }

  static getTreatmentImage(cnNumber: string, props: any): Promise<any> {
    return this.api.get(`${url}/all-treatment-image/${cnNumber}`, { params: { ...props } }).then((res) => res)
  }

  static createTreatmentImage(payload: CreateTreatmentImage): Promise<any> {
    return this.api.post(`${url}/treatment-image`, payload).then((res) => res)
  }
}
