import { numberFormat, toBuddhistYear } from 'utils/app.utils';
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import _ from 'lodash'
import * as UseStyled from 'features/report/useStyled'
import { Box, TableCell, TableRow } from '@mui/material';
import { colors } from 'constants/theme'
import { useTranslation } from 'react-i18next'
import moment from 'moment';
interface FcComponent {
  page: number
  pageLimit: number
  rowCount: number
  sortBy: string
  sortType: string
  data: any
  dataExport: any
  onRequestSort: (sortBy: string, sortType: string) => void
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
}

export default function SaleMedicineContent(props: FcComponent) {
  const { t } = useTranslation()
  const headCells = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.#NO', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.DATE', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.CN_NUMBER', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.BRANCH_CN_NUMBER', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.FULL_NAME', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.OPERATIVE_TYPE', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.LIST', align: 'left', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.PRICE', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.QTY', align: 'center', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.DISCOUNT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.ADDITIONAL', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.TOTAL_PRICE', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.OPERATIVE_SUMMARY_MONTH.TOTAL_PAID', align: 'right', class: 'text-nowrap' }
  ]

  const renderData = (data: any, no: number) => {
    const num = props.page * props.pageLimit - props.pageLimit + no + 1
    return <>
      <TableRowCommon
        key={num.toString()}
        id={no.toString()}
        obj={data}
        columns={[{ option: 'TEXT', align: 'center', label: num },
        { option: 'TEXT', align: 'left', label: toBuddhistYear(moment(data.createdAt), 'DD/MM/YYYY HH:mm น.'), class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', label: data.cnNumber },
        { option: 'TEXT', align: 'left', label: data.branchCnNumber },
        { option: 'TEXT', align: 'left', label: data.fullName, class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', label: data.operativeTypeName, class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', label: data.operativeName, class: 'text-nowrap' },
        { option: 'TEXT', align: 'center', label: numberFormat(data.price) },
        { option: 'TEXT', align: 'right', label: data.qty },
        { option: 'TEXT', align: 'right', label: numberFormat(data.discount) },
        { option: 'TEXT', align: 'right', label: numberFormat(data.additional) },
        { option: 'TEXT', align: 'right', label: numberFormat(data.total) },
        { option: 'TEXT', align: 'right', label: numberFormat(data.amountPaid) }]} />
    </>
  }

  return (
    <>
      <Box>
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            tableMinWidth={992}
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={props.onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headCells}
            rowsData={[_.map(props.data, (d: any, index: number) => {
              return renderData(d, index)
            }),
            <TableRow key={props.dataExport.rows} className='row-summary'>
              <TableCell align='center' scope="row" colSpan={6}>
              </TableCell>
              <TableCell align='left' scope="row" colSpan={1}>
                {t('REPORT.TITLE.SUM')}
              </TableCell>
              <TableCell align="right" scope="row" colSpan={1}>
                {numberFormat(_.sumBy(props.dataExport, 'price'))}
              </TableCell>
              <TableCell align="right" scope="row" colSpan={1}>
                {numberFormat(_.sumBy(props.dataExport, 'qty'), 0)}
              </TableCell>
              <TableCell align="right" scope="row" colSpan={1}>
                {numberFormat(_.sumBy(props.dataExport, 'discount'))}
              </TableCell>
              <TableCell align="right" scope="row" colSpan={1}>
                {numberFormat(_.sumBy(props.dataExport, 'additional'))}
              </TableCell>
              <TableCell align="right" scope="row" colSpan={1}>
                {numberFormat(_.sumBy(props.dataExport, 'total'))}
              </TableCell>
              <TableCell align="right" scope="row" colSpan={1}>
                {numberFormat(_.sumBy(props.dataExport, 'amountPaid'))}
              </TableCell>
            </TableRow>
            ]}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
    </>
  )
}
