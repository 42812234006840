import { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import moment from 'moment'
import {
  Typography,
  Box,
  MenuItem,
  Divider,
  IconButton,
  Collapse,
  TableRow,
  TableCell,
  Avatar,
} from '@mui/material';

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import { fileUrl } from 'api/api'
import DoctorFeeApi, { DoctorFee } from 'api/setting/doctor-fee-managements/doctor-fee.api';
import TemplateDoctorFeeApi, { TemplateDoctorFee } from 'api/setting/doctor-fee-managements/template-doctor-fee.api';

/** UTILS || SLICE */
import { toBuddhistYear } from 'utils/app.utils'

/** COMPONENT */
import ButtonCustom from 'component/Button/ButtonCustom'
import InputTextField from 'component/Input/InputTextField'
import BasicSelect from 'component/Select/BasicSelect'
import TableCustom from 'component/Table/TableCustom'

/** STYLE */
import * as UseStyled from 'features/setting/doctor-fee-managements/doctor-fee/style'
import * as TemplateDFUseStyled from 'features/setting/doctor-fee-managements/template-doctor-fee/style'
import { imgs } from 'constants/images';
import { colors } from 'constants/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

export default function HistoryDoctorUseFee() {
  const { t } = useTranslation()
  const queryParams = new URLSearchParams(window.location.search)
  const doctorId = queryParams.get("doctorId")
  const [history, setHistory] = useState([]);
  const [doctor, setDoctor] = useState({});
  const [doctorFeeId, setDoctorFeeId] = useState(0);
  const [isShowDFList, setIsShowDFList] = useState(false)
  const units: string[] = ['PERCENT', 'BAHT']
  const [doctors, setDoctors] = useState([]);
  const [templateDoctorFee, setTemplateDoctorFee] = useState<TemplateDoctorFee>({
    templateDoctorFeeName: '',
    amount: 0,
    labDoctorAmount: 0,
    isMain: '0',
    templateDoctorFeeOperativeTypes: []
  });
  const [operativeTypes, setOperativeTypes] = useState([]);
  const [doctorFeeOperatives, setDoctorFeeOperatives] = useState<DoctorFee>({
    doctorFeeName: '',
    templateDoctorFeeId: 0,
    dateStart: '',
    amount: 0,
    labDoctorAmount: 0,
    doctorFeeUsers: [],
    doctorFeeOperativeTypes: []
  });

  const loadDoctorFeeDetail = async (valueDoctorId: number) => {
    const resp = await DoctorFeeApi.doctorFeHistory(valueDoctorId)
    if (resp.status === 200) {
      setHistory(resp.data)
      if (!_.isEmpty(resp.data) && resp.data.length) setDoctorFeeId(resp.data[0].doctorFeeId)
      else setDoctorFeeId(0)
    }
  }

  const loadDoctor = async (valueDoctorId: number) => {
    const respDoctors = await DoctorFeeApi.findAllDoctors()
    if (respDoctors.status === 200) {
      setDoctors(respDoctors.data)
      if (!_.isEmpty(respDoctors.data) && valueDoctorId) setDoctor(_.find(respDoctors.data, { doctorId: valueDoctorId }))
      else setDoctor({})
    }
  }

  const loadDoctorFee = useCallback(async () => {
    if (doctorFeeId) {
      let doctorFee = {}
      const respById = await DoctorFeeApi.findById(doctorFeeId)
      if (respById.status === 200) {
        const data = respById.data
        const doctorFeeUsers: any = []
        _.map(data.doctorFeeUsers, (dfUser) => {
          if (dfUser.doctorId) doctorFeeUsers.push({ doctorId: dfUser.doctorId })
        })

        doctorFee = {
          doctorFeeName: data.doctorFeeName,
          templateDoctorFeeId: data.templateDoctorFeeId,
          dateStart: data.dateStart,
          amount: data.amount,
          labDoctorAmount: data.labDoctorAmount,
          doctorFeeUsers: doctorFeeUsers,
          doctorFeeOperativeTypes: []
        }
      }

      const respOperative = await DoctorFeeApi.findAllWithOperatives(doctorFeeId)
      if (respOperative.status === 200) {
        setOperativeTypes(respOperative.data)
        if (!_.isEmpty(respOperative.data)) handleChangeTemplateOperative(respOperative.data, doctorFee)
      }
    }
  }, [doctorFeeId])

  const loadTemplateDoctorFee = useCallback(async () => {
    if (doctorFeeOperatives.templateDoctorFeeId) {
      const respById = await TemplateDoctorFeeApi.findById(doctorFeeOperatives.templateDoctorFeeId)
      if (respById.status === 200) setTemplateDoctorFee(respById.data)
    }
  }, [doctorFeeOperatives.templateDoctorFeeId])

  const handleChangeTemplateOperative = (operativesData: any, value?: any) => {
    const newOperativeTypes: any = []
    _.map(operativesData, (optType, index) => {
      const operative: any = []
      _.map(optType.operatives, (opt, indexOpt) => {
        operative.push({
          operativeId: opt.operativeId,
          dfAmount: Number(opt.doctorFeeOperatives?.dfAmount) || 0,
          cfAmount: Number(opt.doctorFeeOperatives?.cfAmount) || 0,
          dfUnit: Number(opt.doctorFeeOperatives?.labDoctorAmount) || 0,
          labClinicAmount: Number(opt.doctorFeeOperatives?.labClinicAmount) || 0,
        })
      })
      newOperativeTypes.push({
        operativeTypeId: optType.operativeTypeId,
        dfAmount: Number(optType.doctorFeeOperativeTypes?.dfAmount) || 0,
        cfAmount: Number(optType.doctorFeeOperativeTypes?.cfAmount) || 0,
        labDoctorAmount: Number(optType.doctorFeeOperativeTypes?.labDoctorAmount) || 0,
        labClinicAmount: Number(optType.doctorFeeOperativeTypes?.labClinicAmount) || 0,
        doctorFeeOperatives: operative
      })
    })

    const rawDoctorFeeOperatives: DoctorFee = {
      doctorFeeName: value.doctorFeeName,
      templateDoctorFeeId: value.templateDoctorFeeId,
      dateStart: value.dateStart,
      amount: value.amount,
      labDoctorAmount: value.labDoctorAmount,
      doctorFeeUsers: value.doctorFeeUsers,
      doctorFeeOperativeTypes: operativeTypes
    }
    setDoctorFeeOperatives(rawDoctorFeeOperatives)
  }

  const lastUsed = (date: string) => {
    if (date) return toBuddhistYear(moment(date), 'DD/MM/YYYY')
    else return '-'
  }

  const renderImage = (img: any) => {
    if (img) return `${fileUrl}/${img}`
    else return imgs.defaultAvatar
  }

  const renderDoctorFeeUser = (valueDoctors: any) => {
    const doctorUsed: any = []
    _.map(valueDoctors, (dc, index) => {
      doctorUsed.push(_.get(_.find(doctors, { doctorId: dc.doctorId }), 'fullname'))
    })
    return doctorUsed.toString() ? doctorUsed.toString().replaceAll(',', ', ') : '-'
  }

  useEffect(() => {
    loadDoctorFee()
  }, [loadDoctorFee]);

  useEffect(() => {
    loadTemplateDoctorFee()
  }, [loadTemplateDoctorFee]);

  useEffect(() => {
    if (doctorId) {
      loadDoctor(Number(doctorId))
      loadDoctorFeeDetail(Number(doctorId))
    }
  }, [doctorId]);

  const headCells = [
    { id: 'message', disablePadding: false, label: t('') },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.CATEGORY') },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.DF_DOCTOR'), align: 'center' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.DF_BRANCH'), align: 'center' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.UNIT'), align: 'center' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.LAB_DOCTOR'), align: 'center' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.LAB_BRANCH'), align: 'center' },
  ]

  return (
    <Box className='h-100'>
      <Box className='d-flex flex-column flex-sm-row overflow-auto' sx={{ height: { xs: '100%' }, overflow: { xs: 'hidden !important' } }}>
        <UseStyled.Header className='d-flex d-sm-none align-items-center'>
          <Typography className='px-4' sx={{ fontSize: 20, fontWeight: 500 }}>{t('DOCTOR_FEE.TITLE.HEADER_DF_DETAIL')}</Typography>
        </UseStyled.Header>

        <UseStyled.FragmentLeft className='w-100 p-3 p-sm-2'>
          <Box className='left-box w-100 d-flex flex-column h-100'>
            <UseStyled.ProfileBox className='p-3'>
              <Box className='d-flex flex-sm-column align-items-sm-center text-center'>
                <Avatar src={renderImage(_.get(history[0], 'doctorAvata'))} sx={{ backgroundColor: colors.white }}>
                  <img src={imgs.defaultAvatar} alt="" className="w-100 h-100" />
                </Avatar>
                <Box className='ml-3 ml-sm-0 w-100 overflow-hidden'>
                  <Typography className='mt-sm-3 text-ellipsis' sx={{ fontWeight: 500, paddingTop: '1px' }}>{_.get(history[0], 'fullname') || _.get(doctor, 'fullname') || '-'}</Typography>
                  <Typography className='text-ellipsis' sx={{ fontSize: 14, fontWeight: 400, color: `${colors.black60} !important` }}>{t('DOCTOR_FEE.TITLE.LAST_USED')} {lastUsed(_.get(doctor, 'lastUsed')?.dateStart)}</Typography>
                </Box>
              </Box>
              <ButtonCustom onClick={() => setIsShowDFList(true)} variant="text" textButton={t('DOCTOR_FEE.TITLE.VIEW_MORE_DF_DETAIL')} className={`d-sm-none`} style={{ textAlign: 'right' }} btnStyle={{ padding: 0, textDecoration: 'underline' }} />
            </UseStyled.ProfileBox>
            <UseStyled.DoctorFeeList className='d-none d-sm-flex flex-column overflow-hidden pb-2'>
              <Typography className='px-3 pt-3 pb-2' sx={{ fontWeight: 500 }}>{t('DOCTOR_FEE.TITLE.VIEW_DF_DETAIL')}</Typography>
              <Box className='custom-scroll' sx={{ overflowY: 'auto' }}>
                {_.map(history, (df: any, index: number) => {
                  return (
                    <UseStyled.ItemList key={index} className={`${df.doctorFeeId === doctorFeeId ? 'active' : ''} w-100 px-3 mt-2 justify-content-start`} onClick={() => { setDoctorFeeId(df.doctorFeeId) }}>
                      <span className={'text-ellipsis'}>{toBuddhistYear(moment(df.dateStart), 'DD/MM/YYYY')}</span>
                    </UseStyled.ItemList>
                  )
                })}
              </Box>
            </UseStyled.DoctorFeeList>
          </Box>
        </UseStyled.FragmentLeft>
        <UseStyled.FragmentRight className='w-100'>
          <UseStyled.Header className='d-none d-sm-flex align-items-center'>
            <Typography className='px-4' sx={{ fontSize: 20, fontWeight: 500 }}>{t('DOCTOR_FEE.TITLE.HEADER_DF_DETAIL')}</Typography>
          </UseStyled.Header>
          <Box className='pt-4 px-4'>
            <Box>
              <Box className='d-flex'>
                <Typography sx={{ flex: 'none', width: '160px', fontWeight: 500 }}>{t('DOCTOR_FEE.FORM.DF_NAME')}</Typography>
                <Typography>{doctorFeeOperatives.doctorFeeName || '-'}</Typography>
              </Box>
              <Box className='d-flex flex-column flex-xl-row'>
                <Box className='d-flex mt-1' sx={{ width: { lg: '50%', xl: '33.33%' }, flex: 'none' }}>
                  <Typography sx={{ flex: 'none', width: '160px', fontWeight: 500 }}>{t('DOCTOR_FEE.FORM.INPUT_DATE')}</Typography>
                  <Typography>{doctorFeeOperatives.dateStart ? toBuddhistYear(moment(doctorFeeOperatives.dateStart), 'DD/MM/YYYY') : '-'}</Typography>
                </Box>
                <Box className='d-flex mt-1' sx={{ width: { lg: '50%', xl: '33.33%' }, flex: 'none' }}>
                  <Typography sx={{ flex: 'none', width: '160px', fontWeight: 500 }}>{t('DOCTOR_FEE.FORM.TEMPLATE_DF')}</Typography>
                  <Typography>{templateDoctorFee.templateDoctorFeeName || '-'}</Typography>
                </Box>
              </Box>
              <Box className='d-flex mt-1'>
                <Typography sx={{ flex: 'none', width: '160px', fontWeight: 500 }}>{t('DOCTOR_FEE.FORM.DOCTOR')}</Typography>
                <Typography>{renderDoctorFeeUser(doctorFeeOperatives.doctorFeeUsers)}</Typography>
              </Box>
            </Box>
            <Box>
              <Typography className='mt-4 mb-2' sx={{ fontSize: '18px', fontWeight: 500 }}>{t('TEMPLATE_DOCTOR_FEE.TITLE.MANAGE')}</Typography>
              <Box>
                <Box className='d-flex flex-column flex-md-row'>
                  <Box className='d-flex mt-1' sx={{ width: { sm: '50%', xl: '33.33%' }, flex: 'none' }}>
                    <Typography sx={{ flex: 'none', width: '160px', fontWeight: 500 }}>{t('DOCTOR_FEE.FORM.DF_VALUE')}</Typography>
                    <Typography>{doctorFeeOperatives.amount ? `${doctorFeeOperatives.amount}%` : '-'}</Typography>
                  </Box>
                  <Box className='d-flex mt-1' sx={{ width: { sm: '50%', xl: '33.33%' }, flex: 'none' }}>
                    <Typography sx={{ flex: 'none', width: '160px', fontWeight: 500 }}>{t('DOCTOR_FEE.FORM.CF_VALUE')}</Typography>
                    <Typography>{doctorFeeOperatives.amount ? `${100 - doctorFeeOperatives.amount}%` : '-'}</Typography>
                  </Box>
                </Box>
                <Box className='d-flex flex-column flex-md-row'>
                  <Box className='d-flex mt-1' sx={{ width: { sm: '50%', xl: '33.33%' }, flex: 'none' }}>
                    <Typography sx={{ flex: 'none', width: '160px', fontWeight: 500 }}>{t('DOCTOR_FEE.FORM.LAB_DF_VALUE')}</Typography>
                    <Typography>{doctorFeeOperatives.labDoctorAmount ? `${doctorFeeOperatives.labDoctorAmount}%` : '-'}</Typography>
                  </Box>
                  <Box className='d-flex mt-1' sx={{ width: { sm: '50%', xl: '33.33%' }, flex: 'none' }}>
                    <Typography sx={{ flex: 'none', width: '160px', fontWeight: 500 }}>{t('DOCTOR_FEE.FORM.LAB_CF_VALUE')}</Typography>
                    <Typography>{doctorFeeOperatives.labDoctorAmount ? `${100 - doctorFeeOperatives.labDoctorAmount}%` : '-'}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
            <TemplateDFUseStyled.ResponsiveTable section={'default'} className="mb-4">
              <TableCustom
                page={0}
                pageLimit={0}
                sortType={'ASC'}
                sortBy={''}
                rowCount={0}
                textEmptyData={t('REGISTER.NO_ITEM')}
                onSort={() => {
                  return
                }}
                setPageLimit={() => {
                  return
                }}
                setPage={() => {
                  return
                }}
                headCells={headCells}
                rowsData={
                  _.map(operativeTypes, (temp, index) => {
                    return (
                      <RenderData
                        key={index}
                        no={index}
                        row={temp}
                        headCells={headCells}
                        units={units}
                        doctorFeeOperative={doctorFeeOperatives?.doctorFeeOperativeTypes[index]}
                      />
                    )
                  })
                }
                hidePagination
                tableFixedWidth
              />
            </TemplateDFUseStyled.ResponsiveTable>
          </Box>
        </UseStyled.FragmentRight>
      </Box >

      <UseStyled.HistoryList anchor="left" open={isShowDFList} onClose={() => setIsShowDFList(false)} className={`d-md-none`}>
        <Box className='d-flex flex-column overflow-hidden pb-2 history-list-box'>
          <UseStyled.ButtonClosePopup onClick={() => setIsShowDFList(false)} className='btn-close p-0'></UseStyled.ButtonClosePopup>
          <UseStyled.ProfileBox sx={{ paddingTop: '12px' }}>
            <Box className='d-flex text-center p-3' sx={{ borderBottom: `1px solid ${colors.lightGray}` }}>
              <Avatar src={renderImage(_.get(history[0], 'doctorAvata'))} sx={{ backgroundColor: colors.white }}>
                <img src={imgs.defaultAvatar} alt="" className="w-100 h-100" />
              </Avatar>
              <Box className='ml-3 w-100 overflow-hidden'>
                <Typography className='text-ellipsis' sx={{ fontWeight: 500, paddingTop: '1px' }}>{_.get(history[0], 'fullname') || _.get(doctor, 'fullname') || '-'}</Typography>
                <Typography className='text-ellipsis' sx={{ fontSize: 14, fontWeight: 400, color: `${colors.black60} !important` }}>{t('DOCTOR_FEE.TITLE.LAST_USED')} {lastUsed(_.get(doctor, 'lastUsed')?.dateStart)}</Typography>
              </Box>
            </Box>
          </UseStyled.ProfileBox>
          <Box className='custom-scroll mt-2' sx={{ overflowY: 'auto' }}>
            {_.map(history, (df: any, index: number) => {
              return (
                <UseStyled.ItemList key={index} className={`${df.doctorFeeId === doctorFeeId ? 'active' : ''} w-100 px-3 mb-2 justify-content-start`} onClick={() => { setDoctorFeeId(df.doctorFeeId) }}>
                  <span className={'text-ellipsis'}>{toBuddhistYear(moment(df.dateStart), 'DD/MM/YYYY')}</span>
                </UseStyled.ItemList>
              )
            })}
          </Box>
        </Box>
      </UseStyled.HistoryList>
    </Box >
  )
}

function RenderData(props: any) {
  const { t } = useTranslation()
  const { row, headCells, units, doctorFeeOperative } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell align='center'>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            className="p-0"
            sx={{ backgroundColor: 'transparent !important' }}
          >
            {open ? (
              <FontAwesomeIcon icon={faCaretDown} style={{ color: colors.textPrimary, fontSize: 20, transform: 'rotate(180deg)' }} />
            ) : (
              <FontAwesomeIcon icon={faCaretDown} style={{ color: colors.textPrimary, fontSize: 20 }} />
            )}
          </IconButton>
        </TableCell>
        <TableCell>
          {row.operativeTypeName}
        </TableCell>
        <TableCell>
          <InputTextField
            key={`dfAmount`}
            name={`dfAmount`}
            label={''}
            value={doctorFeeOperative?.dfAmount || row?.doctorFeeOperativeTypes?.dfAmount || 0}
            onchange={() => { return }}
            disabled
            type="number"
            hideControl
          />
        </TableCell>
        <TableCell>
          <InputTextField
            key={`cfAmount`}
            name={`cfAmount`}
            label={''}
            value={100 - (doctorFeeOperative?.dfAmount || row?.doctorFeeOperativeTypes?.dfAmount || 0)}
            disabled
            type="number"
            hideControl
          />
        </TableCell>
        <TableCell>
          <BasicSelect
            key={`defaultUnit`}
            labelId="lb-defaultUnit"
            selectId="sl-defaultUnit"
            label=""
            value={'PERCENT'}
            options={
              _.map(units, (unit: string, index: number) => {
                return (
                  <MenuItem key={index} value={unit}>
                    {unit === 'PERCENT' ? '%' : t('CURRENCY_CHANGE.TH')}
                  </MenuItem>
                )
              })
            }
            onchange={() => { return }}
            disabled
          />
        </TableCell>
        <TableCell>
          <InputTextField
            key={`labDoctorAmount`}
            name={`labDoctorAmount`}
            label={''}
            value={(doctorFeeOperative?.labDoctorAmount || row?.doctorFeeOperativeTypes?.labDoctorAmount || 0)}
            onchange={(e) => { return }}
            disabled
            type="number"
            hideControl
          />
        </TableCell>
        <TableCell>
          <InputTextField
            key={`labClinicAmount`}
            name={`labClinicAmount`}
            label={''}
            value={100 - (doctorFeeOperative?.labDoctorAmount || row?.doctorFeeOperativeTypes?.labDoctorAmount || 0)}
            onchange={() => { return }}
            disabled
            type="number"
            hideControl
          />
        </TableCell>
      </TableRow>
      <TableRow className='section-collapse'>
        <TableCell colSpan={7} className="p-0 border-0">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TemplateDFUseStyled.ResponsiveTable section='collapse'>
              <TableCustom
                page={0}
                pageLimit={0}
                sortType={'ASC'}
                sortBy={''}
                rowCount={0}
                textEmptyData={t('REGISTER.NO_ITEM')}
                onSort={() => {
                  return
                }}
                setPageLimit={() => {
                  return
                }}
                setPage={() => {
                  return
                }}
                headCells={headCells}
                rowsData={
                  _.map(row?.operatives || [], (opt, indexOpt) => {
                    let doctorFeeOperatives = doctorFeeOperative?.operatives || doctorFeeOperative?.doctorFeeOperatives || opt?.doctorFeeOperatives
                    if (doctorFeeOperatives && doctorFeeOperatives[indexOpt]) doctorFeeOperatives = doctorFeeOperatives[indexOpt]
                    if (!_.isEmpty(doctorFeeOperatives?.doctorFeeOperatives)) doctorFeeOperatives = doctorFeeOperatives.doctorFeeOperatives

                    return (
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          {opt.operativeName}
                        </TableCell>
                        <TableCell>
                          <InputTextField
                            key={`dfAmount`}
                            name={`dfAmount`}
                            label={''}
                            value={doctorFeeOperatives?.dfAmount || 0}
                            onchange={() => { return }}
                            type="number"
                            hideControl
                            disabled
                          />
                        </TableCell>
                        <TableCell>
                          <InputTextField
                            key={`cfAmount`}
                            name={`cfAmount`}
                            label={''}
                            value={doctorFeeOperatives?.cfAmount || 0}
                            onchange={() => { return }}
                            type="number"
                            hideControl
                            disabled
                          />
                        </TableCell>
                        <TableCell>
                          <BasicSelect
                            key={`dfUnit`}
                            name={`dfUnit`}
                            labelId="lb-defaultUnit"
                            selectId="sl-defaultUnit"
                            label=""
                            value={doctorFeeOperatives?.dfUnit || 'PERCENT'}
                            options={
                              _.map(units, (unit: string, index: number) => {
                                return (
                                  <MenuItem key={index} value={unit}>
                                    {unit === 'PERCENT' ? '%' : t('CURRENCY_CHANGE.TH')}
                                  </MenuItem>
                                )
                              })
                            }
                            onchange={() => { return }}
                            disabled
                          />
                        </TableCell>
                        <TableCell>
                          <InputTextField
                            key={`labDoctorAmount`}
                            name={`labDoctorAmount`}
                            label={''}
                            value={doctorFeeOperatives?.labDoctorAmount || 0}
                            onchange={() => { return }}
                            type="number"
                            hideControl
                            disabled
                          />
                        </TableCell>
                        <TableCell>
                          <InputTextField
                            key={`labClinicAmount`}
                            name={`labClinicAmount`}
                            label={''}
                            value={doctorFeeOperatives?.labClinicAmount || 0}
                            onchange={() => { return }}
                            disabled
                            type="number"
                            hideControl
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
                hidePagination
                tableFixedWidth
              />
            </TemplateDFUseStyled.ResponsiveTable>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}