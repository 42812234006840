import { useState, useEffect, useCallback } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import { routeName } from 'routes/routes-name'
import Paper from '@mui/material/Paper'
import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody, IconButton } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import _ from 'lodash'
import { colors } from 'constants/theme';

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'
import HeaderLabel from 'component/CardCustom/HeaderLabel'

/** STYLE */
import useStyles, { BtnAdd, ColLabGroup, HeaderTable, TitleLabType } from 'features/setting/default-labs/lab-group/style'
import LabGroupsApi from 'api/setting/default-labs/lab-group.api'
import LabTypeApi from 'api/setting/default-labs/lab-type.api'
import LabsApi from 'api/setting/default-labs/lab.api'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import InputCheckbox from 'component/Input/InputCheckbox'
import TextField from '@mui/material/TextField'
import InputTextField from 'component/Input/InputTextField'
import { icons } from 'constants/images'

interface LabsInterface {
  inputSupport: string
  labId: number
  labName: string
}

interface LabTypesInterface {
  labTypeId: number
  labTypeName: string
  labId: number
  labName: string
  inputSupport: string
}

interface LabTypesDisplayInterface {
  labTypeName: string
  labTypeId: number | null
  labTypes: LabTypesInterface[]
}

export default function FormLabGroup() {
  const { t } = useTranslation()
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()

  const [labGroupId, setLabGroupId] = useState(Number)
  const [labGroupName, setLabGroupName] = useState('')
  const [labs, setLabs] = useState<LabsInterface[]>([])
  const [labTypesDisplay, setLabTypesDisplay] = useState<LabTypesDisplayInterface[]>([])
  const [loadLabTypes, setLoadLabTypes]: any = useState([])

  const submit = () => {
    const labtypedisplaydisplayTemp = labTypesDisplay
    const labGroupDetailsTemp: any = []
    for (const lab of labtypedisplaydisplayTemp) {
      if (lab.labTypes) labGroupDetailsTemp.push(...lab.labTypes)
    }
    const labGroupDetails: any = []
    _.map(labGroupDetailsTemp, (lab: any) => {
      if (lab.labName && lab.labTypeName)
        labGroupDetails.push({ labTypeId: lab.labTypeId, labTypeName: lab.labTypeName, labId: lab.labId, labName: lab.labName })
    })
    const objCreate = { labGroupName: labGroupName, status: 'ACTIVE', labGroupDetails: labGroupDetails }

    if (!labGroupName) return notiError(t('LAB_GROUP.MESSAGE.LAB_GROUP_NAME'))
    LabGroupsApi.create(objCreate)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201)
          notiSuccess(t('LAB_GROUP.MESSAGE.SUCCESS.CREATE'), '', null, () => {
            history.push(routeName.labGroup)
          })
        else {
          const err = res.response.data
          notiError(t(`LAB_GROUP.MESSAGE.${err.message}`))

        }
      })
      .catch((e) => {
        const err = e.response.data
        notiError(t(`LAB_GROUP.MESSAGE.${err.message}`))
      })
  }

  const submitEdit = () => {
    const labtypedisplaydisplayTemp = labTypesDisplay
    const labGroupDetailsTemp: any = []
    for (const lab of labtypedisplaydisplayTemp) {
      labGroupDetailsTemp.push(...lab.labTypes)
    }
    const labGroupDetails: any = []
    _.map(labGroupDetailsTemp, (lab: any) => {
      if (lab.labName && lab.labTypeName)
        labGroupDetails.push({ labTypeId: lab.labTypeId, labTypeName: lab.labTypeName, labId: lab.labId, labName: lab.labName })
    })
    if (!labGroupName) return notiError(t('LAB_GROUP.MESSAGE.LAB_GROUP_NAME'))
    const objUpdate = { labGroupName: labGroupName, status: 'ACTIVE', labGroupDetails: labGroupDetails }

    LabGroupsApi.update(labGroupId, objUpdate)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t(`LAB_GROUP.MESSAGE.SUCCESS.UPDATE`), '', null, () => {
            history.push(routeName.labGroup)
          })
        } else {
          const err = res.response.data
          notiError(t(`LAB_GROUP.MESSAGE.${err.message}`))
        }
      })
      .catch((e) => {
        const err = e.response.data
        notiError(t(`LAB_GROUP.MESSAGE.${err.message}`))
      })
  }

  const onEdit = (data: any) => {

    setLabGroupId(data.labGroupId)
    setLabGroupName(data.labGroupName)
    const labGroupDetailsTemp = data.labGroupDetails
    const labTypeFilted: any = []
    for (const lab of labGroupDetailsTemp) {
      if (!labTypeFilted.some((e: any) => e.labTypeName === lab.labTypeName)) {
        labTypeFilted.push({ labTypeName: lab.labTypeName, labTypeId: lab.labTypeId })
      }
    }
    const tempLabTypesDisplay = []
    for (const labItem of labTypeFilted) {
      const groupLabs = labGroupDetailsTemp.filter((lab: any) => lab.labTypeName === labItem.labTypeName)
      const labType = groupLabs.map((lab: any) => ({ labTypeId: lab.labTypeId, labTypeName: lab.labTypeName, labId: lab.labId, labName: lab.labName, inputSupport: lab.inputSupport }))
      tempLabTypesDisplay.push({ labTypeName: labItem.labTypeName, labTypeId: labItem.labTypeId, labTypes: labType })
    }
    setLabTypesDisplay(tempLabTypesDisplay)
  }

  const loadLabs = useCallback(async () => {
    const res = await LabsApi.findAllActive({})
    if (res.status === 200) {
      const lab: any = []
      for (const data of res.data)
        lab.push({ labName: data.labName, labId: data.labId, inputSupport: data.inputSupport })
      setLabs(lab)
    }
  }, [])

  const loadLabTypesAPI = useCallback(async () => {
    const res = await LabTypeApi.findAll({ status: 'ACTIVE', pageLimit: 100 })
    if (res.status === 200) {
      const labType: any = []
      for (const data of res.data) {
        labType.push({ labTypeId: data.labTypeId, labTypeName: data.labTypeName })
      }
      setLoadLabTypes(labType)
    }
  }, [])

  const loadLabsEdit = async (labTypeId: number, inputLabName: string) => {
    const res = await LabTypeApi.findLabs(labTypeId)
    const labsNew: any = []
    if (res.status === 200) {
      for (const data of res.data) {
        if (data.mapType !== null) {
          labsNew.push({ labName: data.labName, labId: data.labId, inputSupport: data.inputSupport, labTypeId: data.mapType.labTypeId, labTypeName: inputLabName })
        }
      }
    }
    return labsNew
  }

  const handleLabType = (labName: any, index: number, iLabDisplay: number, labDisplay: any) => {
    const labTypesDisplayTemp: any = [...labTypesDisplay]

    if (typeof labName === 'object') {
      labTypesDisplayTemp[iLabDisplay].labTypes[index].labName = labName.labName
      labTypesDisplayTemp[iLabDisplay].labTypes[index].labId = labName.labId
      labTypesDisplayTemp[iLabDisplay].labTypes[index].inputSupport = labName.inputSupport
      labTypesDisplayTemp[iLabDisplay].labTypes[index].labTypeName = labDisplay.labTypeName
    } else {
      labTypesDisplayTemp[iLabDisplay].labTypes[index].labTypeName = labDisplay.labTypeName
      labTypesDisplayTemp[iLabDisplay].labTypes[index].labName = labName
      labTypesDisplayTemp[iLabDisplay].labTypes[index].labId = null
    }
    setLabTypesDisplay(labTypesDisplayTemp)
  }

  const handleSupportLabType = (checked: boolean, index: number, iLabDisplay: number) => {
    const labTypesDisplayTemp: any = [...labTypesDisplay]
    const check = checked === true ? 'SUPPORT' : 'NOT_SOPPORT'
    labTypesDisplayTemp[iLabDisplay].labTypes[index].inputSupport = check
    setLabTypesDisplay(labTypesDisplayTemp)
  }

  const handleDeleteLabDisplay = (index: number) => {
    const labTypesDisplayTemp = labTypesDisplay.filter((lab: any, iLab: number) => index !== iLab)
    setLabTypesDisplay(labTypesDisplayTemp)
  }

  const handleSelectTypeDisplay = (iLabDisplay: number, inputLabName: string) => {
    const index = loadLabTypes.findIndex((labType: any) => labType.labTypeName === inputLabName)
    const labTypesDisplayTemp = [...labTypesDisplay]
    if (index > 0) {
      loadLabsEdit(loadLabTypes[index].labTypeId, inputLabName).then((res) => {
        labTypesDisplayTemp[iLabDisplay].labTypes = res
        labTypesDisplayTemp[iLabDisplay].labTypeName = inputLabName
        labTypesDisplayTemp[iLabDisplay].labTypeId = loadLabTypes[index].labTypeId

        setLabTypesDisplay(labTypesDisplayTemp)
      })
    } else {
      labTypesDisplayTemp[iLabDisplay].labTypeName = inputLabName
      labTypesDisplayTemp[iLabDisplay].labTypeId = null
      setLabTypesDisplay(labTypesDisplayTemp)
    }
  }

  const handleAddLabsInLabTypesDisplay = (index: number, labDisplay: any) => {
    const labTypesDisplayTemp = [...labTypesDisplay]
    labTypesDisplayTemp[index].labTypes = [{ labName: '', labId: 1, inputSupport: 'NOT_SUPPORT', labTypeId: labDisplay.labTypeId, labTypeName: '' }, ...labTypesDisplayTemp[index].labTypes]
    setLabTypesDisplay(labTypesDisplayTemp)
  }

  const handleDeleteLabInLabTypesDisplay = (iLabDisplay: number, index: number) => {
    const labTypesDisplayTemp = [...labTypesDisplay]
    const labTypes = labTypesDisplay[iLabDisplay].labTypes.filter((lab: any, iLab: number) => index !== iLab)
    labTypesDisplayTemp[iLabDisplay].labTypes = labTypes
    setLabTypesDisplay(labTypesDisplayTemp)
  }

  useEffect(() => {
    if (location.state) {
      onEdit(location.state)
    }

    loadLabs()
    loadLabTypesAPI()
  }, [loadLabs, loadLabTypesAPI, location])
  return (
    <div className={`${classes.root} ${classes.body} container-card`}>
      <HeaderLabel
        text={labGroupId ? t('LAB_GROUP.TITLE_UPDATE') : t('LAB_GROUP.TITLE_CREATE')}
        navigator={{ previousTo: routeName.labGroup, previousTitle: t('LAB_TYPE.TITLE'), currentTitle: labGroupId ? t('LAB_GROUP.TITLE_UPDATE') : t('LAB_GROUP.TITLE_CREATE') }}
        goBack={routeName.labGroup}
        onSubmit={labGroupId ? submitEdit : submit}
      />
      <Card className="border shadow-sm  mx-4 p-4 ">
        <Card.Body className="h-100">
          <Row  >
            <ColLabGroup sm={4}>
              <InputTextField
                label={t('LAB_GROUP.INPUT.LAB_TYPE_NAME')}
                value={labGroupName}
                onchange={(e) => {
                  setLabGroupName(e.target.value)
                }}
              />
            </ColLabGroup>
          </Row>
          <Row>
            <Col>
              <div style={{ overflow: 'hidden' }}>
                <div>
                  <Col sm={12}  >
                    <TitleLabType>{t('LAB_TYPE.LIST_TYPE_LAB')}</TitleLabType>
                  </Col>
                  <Col sm={12}>
                    <BtnAdd variant='text'
                      onClick={() => {
                        setLabTypesDisplay([{ labTypeName: '', labTypeId: 1, labTypes: [] }, ...labTypesDisplay])
                      }}>+ {t('LAB_TYPE.BUTTON.ADD')}</BtnAdd>
                  </Col>
                  {labTypesDisplay.map((labDisplay: any, iLabDisplay: number) => {
                    return (
                      <Row key={iLabDisplay} className=" mt-2 ml-3">
                        <Row className="mt-2">
                          <Col sm={4}>
                            <Autocomplete
                              id="grouped-demo"
                              freeSolo
                              disableClearable
                              size={'small'}
                              options={loadLabTypes}
                              getOptionLabel={(option: any) => option.labTypeName}
                              fullWidth={true}
                              renderInput={(params) => <TextField {...params} label={t('LAB_TYPE.TYPE_LAB')} />}
                              inputValue={labDisplay.labTypeName}
                              onInputChange={(event, inputLabName) => {
                                if (event != null) {
                                  handleSelectTypeDisplay(iLabDisplay, inputLabName)
                                }
                              }}
                            />
                          </Col>
                          <Col className=' d-flex '>
                            <IconButton
                              className={'btn-delete ml-auto'}
                              aria-label="delete"
                              onClick={(e) => {
                                handleDeleteLabDisplay(iLabDisplay)
                              }}
                              sx={{ backgroundColor: 'transparent !important', padding: 0 }}
                            >
                              <img src={icons.btnTrash} alt="trash" style={{ width: 24 }} />
                            </IconButton>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <TableContainer component={Paper} className={`${classes.root}  mt-1 mb-2 `} style={{ boxShadow: 'none' }}>
                            <Table aria-label="simple table" className={`${classes.body}`}>
                              <TableHead className={classes.header}>
                                <TableRow>
                                  <TableCell key={'#'} align="center">
                                    <HeaderTable>#</HeaderTable>
                                  </TableCell>
                                  <TableCell key={'list-lab'} align="center">
                                    <HeaderTable>{t('LAB_GROUP.TABLE.CELL.LAB_NAME')}</HeaderTable>
                                  </TableCell>
                                  <TableCell key={'lab-remark'} align="center">
                                    <HeaderTable>{t('LAB_GROUP.TABLE.CELL.ADD_DATA')}</HeaderTable>
                                  </TableCell>
                                  <TableCell key={'lab-action'} align="center">
                                    <HeaderTable>Action</HeaderTable>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody className={`sm-data-table ${classes.body}`}>
                                <TableRow>
                                  <TableCell
                                    className={`${classes.columeHover}`}
                                    onClick={() => {
                                      handleAddLabsInLabTypesDisplay(iLabDisplay, labDisplay)
                                    }}
                                    colSpan={8}
                                  >
                                    <div style={{ color: colors.themeSecondColor }}>+ {t('LAB.BUTTON.ADD')}</div>
                                  </TableCell>
                                </TableRow>
                                {labDisplay.labTypes.map((lab: any, index: any) => {
                                  return (
                                    <TableRow key={`${index}`}>
                                      <TableCell>{labDisplay.labTypes.length - index}</TableCell>
                                      <TableCell>
                                        <Autocomplete
                                          id="grouped-demo"
                                          freeSolo
                                          disableClearable
                                          size={'small'}
                                          options={labs}
                                          getOptionLabel={(option: any) => option.labName}

                                          fullWidth={true}
                                          renderInput={(params) => <TextField {...params} label={t('LAB.TABLE.CELL.LIST_LAB')} />}
                                          inputValue={lab.labName}
                                          onInputChange={(event, inputLabName) => {
                                            if (event != null) {
                                              handleLabType(inputLabName, index, iLabDisplay, labDisplay)
                                            }
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell align="center">
                                        {
                                          <InputCheckbox
                                            disabled={lab.labId === null ? false : true}
                                            checked={lab.inputSupport === 'SUPPORT' ? true : false}
                                            label={''}
                                            onChange={(e) => {
                                              handleSupportLabType(e.target.checked, index, iLabDisplay)
                                            }}
                                          />
                                        }
                                      </TableCell>
                                      <TableCell align="center">
                                        <IconButton
                                          className={'btn-delete'}
                                          aria-label="delete"
                                          onClick={(e) => {
                                            handleDeleteLabInLabTypesDisplay(iLabDisplay, index)
                                          }}
                                          sx={{ backgroundColor: 'transparent !important', padding: 0 }}
                                        >
                                          <img src={icons.btnTrash} alt="trash" style={{ width: 24 }} />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  )
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Row>
                      </Row>
                    )
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}
