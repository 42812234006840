import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { Button, IconButton, Typography } from '@mui/material'
import { colors } from 'constants/theme'

export const StyleToothChart = styled(`div`)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  [theme.breakpoints.down(1600)]: {
    maxWidth: 1280,
    margin: 'auto'
  },
  [theme.breakpoints.down(1280)]: {
    maxWidth: 960
  },
  [theme.breakpoints.down(960)]: {
    maxWidth: 768
  }
}))

export const StyleHeaderToothChart = styled(`div`)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
}))

export const StyleBtnHeader = styled(`div`)(({ theme }) => ({}))

export const StyleToothChartQ1 = styled(`div`)(({ theme }) => ({
  width: '50%',
  justifyContent: 'flex-end',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  marginLeft: 'auto',
  paddingRight: '0.625rem',
  '> div': {
    paddingBottom: '1rem',
    [theme.breakpoints.down(1600)]: {
      width: '12.5%'
    }
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: -1,
    width: '100%',
    height: 1,
    backgroundColor: colors.highlightSelect,
  },
  [theme.breakpoints.down(1600)]: {
    paddingLeft: 50,
  }
}))

export const StyleToothChartQ2 = styled(`div`)(({ theme }) => ({
  width: '50%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  marginRight: 'auto',
  paddingLeft: '0.625rem',
  '> div': {
    paddingBottom: '1rem',
    [theme.breakpoints.down(1600)]: {
      width: '12.5%'
    }
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: -1,
    width: 1,
    height: '100%',
    backgroundColor: colors.highlightSelect
  },
  [theme.breakpoints.down(1600)]: {
    paddingRight: 50,
  }
}))

export const StyleToothChartQ3 = styled(`div`)(({ theme }) => ({
  width: '50%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  marginRight: 'auto',
  paddingLeft: '0.625rem',
  '> div': {
    paddingTop: '1rem',
    [theme.breakpoints.down(1600)]: {
      width: '12.5%'
    }
  },
  '&:before, &:after': {
    content: '""',
    position: 'absolute',
  },
  '&:before': {
    right: 0,
    width: '100%',
    height: 1,
    backgroundColor: colors.highlightSelect
  },
  '&:after': {
    content: '"LEFT"',
    fontWeight: 500,
    fontSize: 14,
    color: colors.themeMainColor,
    top: -10,
    right: 0
  },
  [theme.breakpoints.down(1600)]: {
    paddingRight: 50,
  }
}))

export const StyleToothChartQ4 = styled(`div`)(({ theme }) => ({
  width: '50%',
  justifyContent: 'flex-end',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  marginLeft: 'auto',
  paddingRight: '0.625rem',
  '> div': {
    paddingTop: '1rem',
    [theme.breakpoints.down(1600)]: {
      width: '12.5%'
    }
  },
  '&:before, &:after': {
    content: '""',
    position: 'absolute',
  },
  '&:before': {
    top: 0,
    bottom: 0,
    right: 0,
    width: 1,
    height: '100%',
    backgroundColor: colors.highlightSelect
  },
  '&:after': {
    content: '"RIGHT"',
    fontWeight: 500,
    fontSize: 14,
    color: colors.themeMainColor,
    top: -10,
    left: 0
  },
  [theme.breakpoints.down(1600)]: {
    paddingLeft: 50,
  }
}))

export const StyleToothChartU = styled(`div`)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around'
}))

export const StyleToothChartL = styled(`div`)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
}))

export const ImageToothChart = styled(`img`)(({ theme }) => ({
  top: 0,
  width: '100%',
  position: 'absolute'
}))

export const BtnPosition = styled(Button)(({ theme }) => ({
  minWidth: 30,
  color: colors.darkGray,
  border: `1px solid ${colors.darkGray} !important`,
  width: 'auto',
  height: 30,
  fontSize: 13,
  margin: '0 4px 10px',
  padding: '0 2px',
  borderRadius: 4,
  textTransform: 'capitalize',
  '&.active': {
    backgroundColor: colors.themeSecondColor,
    borderColor: `${colors.themeSecondColor} !important`,
    color: colors.white
  }
}))

export const BtnOperative = styled(IconButton)(({ theme }) => ({
  minWidth: 'unset',
  color: colors.darkGray,
  borderRadius: 4,
  width: 35,
  height: 44,
  margin: '0 2px',
  padding: 0,
  '& $img': {
    width: 35,
    height: 44
  },
  '& .active': {
    backgroundColor: colors.lightGray,
    color: colors.white,
  }
}))

export const DivTeethL = styled(`div`)(({ theme }) => ({
  position: 'relative',
  width: 40,
  height: 102,
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center',
  paddingLeft: 1,
  paddingRight: 1,
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: 20,
  'svg, img': {
    zIndex: 1,
    cursor: 'pointer'
  },
  '& svg.sm': {
    width: 30
  },
  '& svg.md': {
    width: 35
  },
  '& svg.lg': {
    width: 40
  },
  '& svg.active, & img.active': {
    filter: `drop-shadow(0 0 5px ${colors.themeSecondColor80})`
  },
  '& img.teeth-fixture': {
    width: '100%',
    height: 128,
    [theme.breakpoints.down(1280)]: {
      height: 92,
    },
    [theme.breakpoints.down(960)]: {
      height: 70,
    },
    [theme.breakpoints.up(1600)]: {
      height: 72
    }
  },
  [theme.breakpoints.down(1600)]: {
    width: 'auto',
    height: 167,
    '& svg': {
      width: '100% !important',
      height: '100% !important',
      '&:not(.lg)': {
        maxHeight: 167
      },
      '&.md': {
        maxHeight: 150
      }
    },
  },
  [theme.breakpoints.down(1280)]: {
    height: 117,
    '& svg': {
      '&:not(.lg)': {
        maxHeight: 117
      },
      '&.md': {
        maxHeight: 103
      }
    },
  },
  [theme.breakpoints.down(960)]: {
    height: 87,
    '& svg': {
      '&:not(.lg)': {
        maxHeight: 88
      },
      '&.md': {
        maxHeight: 77
      }
    },
  }
}))

export const TextNumberL = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  textAlign: 'center',
  marginBottom: 8,
  '&::selection': {
    background: 'transparent'
  }
}))

export const DivTeethU = styled(`div`)(({ theme }) => ({
  position: 'relative',
  width: 40,
  height: 108,
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'center',
  paddingLeft: 1,
  paddingRight: 1,
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: 20,
  'svg, img': {
    zIndex: 1,
    cursor: 'pointer'
  },
  '& svg.sm': {
    width: 35
  },
  '& svg.md': {
    width: 40
  },
  '& svg.lg': {
    width: 45
  },
  '& svg.active, & img.active': {
    filter: `drop-shadow(0 0 5px ${colors.themeSecondColor80})`
  },
  '& img.teeth-fixture': {
    width: '100%',
    height: 135,
    [theme.breakpoints.down(1280)]: {
      height: 94,
    },
    [theme.breakpoints.down(960)]: {
      height: 72,
    },
    [theme.breakpoints.up(1600)]: {
      height: 78
    }
  },
  [theme.breakpoints.down(1600)]: {
    height: 167,
    width: 'auto',
    '& svg': {
      width: '100% !important',
      height: '100% !important',
      '&:not(.lg)': {
        maxHeight: 167
      },
      '&.md': {
        maxHeight: 150
      }
    },
  },
  [theme.breakpoints.down(1280)]: {
    height: 114,
    '& svg': {
      '&:not(.lg)': {
        maxHeight: 117
      },
      '&.md': {
        maxHeight: 103
      }
    },
  },
  [theme.breakpoints.down(960)]: {
    height: 88,
    '& svg': {
      '&:not(.lg)': {
        maxHeight: 88
      },
      '&.md': {
        maxHeight: 77
      }
    },
  }
}))

export const TextNumberU = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  textAlign: 'center',
  marginTop: 8,
  '&::selection': {
    background: 'transparent'
  }
}))

const useStyles = makeStyles({})

export default useStyles
