import { makeStyles } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'
import { colors } from 'constants/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  titleFirst: {
    fontSize: '18px',
    fontWeight: 600
  },
  titleSecond: {
    fontSize: '16px'
  },
  columeHover: {
    backgroundColor: '',
    '&:hover': {
      backgroundColor: `${colors.lightGray} !important`
    }
  },
  inputDisable: {
    backgroundColor: colors.themeThirdColor
  },
  arrow: {
    marginRight: '5px',
    border: `1px solid ${colors.textLightGray}`,
    borderRadius: '100%',
    backgroundColor: colors.white
  }
}))

export const ButtonAddBranch = styled(Button)(({ theme }) => ({
  padding: '0 0 0 14px',
  fontSize: 16,
  color: colors.themeSecondColor,
  backgroundColor: 'transparent !important',
  fontWeight: 400,
  marginTop: '8px',
  span: {
    fontSize: 20
  },
  '&:hover': {
    backgroundColor: 'transparent !important',
    fontWeight: 600
  },
  '&.Mui-disabled': {
    color: colors.disabledGray
  }
}))

export default useStyles
