import { menuSeconds } from 'constants/menus'
import { routeName } from 'routes/routes-name'
import Inventory from 'features/warehouses/inventory/inventory'
import FormInventory from 'features/warehouses/inventory/form-inventory'

const routeInventory = [
    {
        ...menuSeconds.INVENTORY_LIST,
        key: menuSeconds.INVENTORY_LIST.key,
        name: menuSeconds.INVENTORY_LIST.name,
        textName: menuSeconds.INVENTORY_LIST.textName,
        active: false,
        appBar: true,
        component: Inventory
    },
    {
        ...menuSeconds.INVENTORY_LIST,
        key: menuSeconds.INVENTORY_LIST.key,
        name: menuSeconds.INVENTORY_LIST.name,
        textName: menuSeconds.INVENTORY_LIST.textName,
        active: false,
        appBar: true,
        path: routeName.inventory + '/view',
        component: FormInventory
    },
]

export default routeInventory