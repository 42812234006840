
export default function DrageMove(props: any) {
    return (
        <svg
            viewBox="0 0 24 24"
            width={props?.width || '30px'}
            height={props?.height || '30px'}
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path d="M12 3L12.7295 2.31606L12 1.53789L11.2705 2.31606L12 3ZM11 9C11 9.55228 11.4477 10 12 10C12.5523 10 13 9.55228 13 9H11ZM15.7295 5.51606L12.7295 2.31606L11.2705 3.68394L14.2705 6.88394L15.7295 5.51606ZM11.2705 2.31606L8.27046 5.51606L9.72954 6.88394L12.7295 3.68394L11.2705 2.31606ZM11 3V9H13V3H11Z"
                    fill={props?.fill || "#33363F"}>
                </path>
                <path d="M21 12L21.6839 12.7295L22.4621 12L21.6839 11.2705L21 12ZM15 11C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13L15 11ZM18.4839 15.7295L21.6839 12.7295L20.3161 11.2705L17.1161 14.2705L18.4839 15.7295ZM21.6839 11.2705L18.4839 8.27046L17.1161 9.72954L20.3161 12.7295L21.6839 11.2705ZM21 11L15 11L15 13L21 13L21 11Z"
                    fill={props?.fill || "#33363F"}>
                </path>
                <path d="M12 21L12.7295 21.6839L12 22.4621L11.2705 21.6839L12 21ZM11 15C11 14.4477 11.4477 14 12 14C12.5523 14 13 14.4477 13 15H11ZM15.7295 18.4839L12.7295 21.6839L11.2705 20.3161L14.2705 17.1161L15.7295 18.4839ZM11.2705 21.6839L8.27046 18.4839L9.72954 17.1161L12.7295 20.3161L11.2705 21.6839ZM11 21V15H13V21H11Z"
                    fill={props?.fill || "#33363F"}>
                </path>
                <path d="M3 12L2.31606 12.7295L1.53789 12L2.31606 11.2705L3 12ZM9 11C9.55228 11 10 11.4477 10 12C10 12.5523 9.55228 13 9 13L9 11ZM5.51606 15.7295L2.31606 12.7295L3.68394 11.2705L6.88394 14.2705L5.51606 15.7295ZM2.31606 11.2705L5.51606 8.27046L6.88394 9.72954L3.68394 12.7295L2.31606 11.2705ZM3 11L9 11L9 13L3 13L3 11Z"
                    fill={props?.fill || "#33363F"}>
                </path>
            </g>
        </svg>
    )
}