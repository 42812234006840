import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Box, Divider, TableSortLabel, Typography } from '@mui/material'
import { ReportsProps, ExportProps } from 'features/report/report-constants'
import { isBuddhistYear, numberFormat, toBuddhistYear } from 'utils/app.utils'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import EmptyData from 'features/report/component/emptyData'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { colors } from 'constants/theme'

import { UseSnCode } from 'utils/useSnCode'
import { SORT_TYPE } from 'constants/common'

export interface DoctorFeeComponent {
  page: number
  pageLimit: number
  rowCount: number
  data: any
  dataExport: any
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
  onRequestSort: (sortBy: string, sortType: string) => void
  setSortType: (val: SORT_TYPE) => void
  setSortBy: (val: string) => void
  sortType: string
  sortBy: string
  componentRef: any
}

export interface DoctorFeeIF {
  doctorFullname: string
  total: number
  amountPaid: number
  dfNet: number
  bankName: string
  bankAccountNumber: string
  bankAccountName: string
}

export default function DoctorFeeList(props: DoctorFeeComponent) {
  const { t } = useTranslation()

  const onRequestSort = (sortByVal: string, sortTypeVal: SORT_TYPE) => {
    props.setSortType(sortTypeVal)
    props.setSortBy(sortByVal)
    props.handleChangePage(1)
  }

  const headCells = [
    { id: '1', disablePadding: false, labelKey: 'REPORT.TABLE.DF.#NO', align: 'center', class: 'text-nowrap' },
    { id: '2', disablePadding: false, labelKey: 'ทันตแพทย์', align: 'left', class: 'text-nowrap' },
    { id: '3', disablePadding: false, labelKey: 'ค่าบริการ', align: 'right', class: 'text-nowrap' },
    { id: '4', disablePadding: false, labelKey: 'จ่ายค่ารักษา', align: 'right', class: 'text-nowrap' },
    { id: '5', disablePadding: false, labelKey: 'DF', align: 'right', class: 'text-nowrap' },
    { id: '7', disablePadding: false, labelKey: 'ธนาคาร', align: 'left', class: 'text-nowrap' },
    { id: '6', disablePadding: false, labelKey: 'ชื่อบัญชี', align: 'left', class: 'text-nowrap' },
    { id: '6', disablePadding: false, labelKey: 'เลขบัญชี', align: 'left', class: 'text-nowrap' },
  ]

  const renderData = (data: any, no: number, exportData: boolean, render: 'data' | 'sum') => {
    if (render === 'data') {
      const { doctorFullname, total, amountPaid, dfNet, bankName, bankAccountNumber, bankAccountName }: DoctorFeeIF = data
      const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
      const objRenderData = {
        key: num.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: num, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: doctorFullname || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(total || 0, 3, 3), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(amountPaid || 0, 3, 3), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(dfNet || 0, 3, 3), class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountNumber || '-', class: 'text-nowrap' },
        ]
      }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    } else {
      const objRenderData = {
        key: no.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'MERGE', align: 'center', colSpan: 1 },
          {
            option: 'TEXT',
            align: 'right',
            style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 },
            label: <Typography sx={{ fontWeight: 500, fontSize: exportData ? 10 : 16 }}>{t('REPORT.TITLE.SUM')}</Typography>,
            class: 'text-nowrap'
          },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'total') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountPaid') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'dfNet') || 0), class: 'text-nowrap' },
          { option: 'MERGE', align: 'center', colSpan: 3 }
        ],
        classes: 'row-summary'
      }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    }
  }

  return (
    <>
      <Box>
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            rowHeadCell={2}
            tableMinWidth={992}
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headCells}
            rowsData={[
              _.map(props.data, (d: any, index: number) => {
                return renderData(d, index, false, 'data')
              }),
              renderData(props.data, 0, false, 'sum')
            ]}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
      <div className="print-show" ref={props.componentRef}>
        <ExportData
          headCells={t(`REPORT.REPORT_NAME.APPOINTMENT`)}
          componant={
            <>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      {_.map(headCells, (val: any, index: number) => {
                        return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      _.map(props.dataExport, (d: any, index: number) => {
                        return renderData(d, index, true, 'data')
                      }),
                      renderData(props.data, 0, true, 'sum')
                    ]}
                  </tbody>
                </table>
              </UseStyled.Styles>
            </>
          }
        />
      </div>
    </>
  )
}

export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  )
}
