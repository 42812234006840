import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface OperativeTypeInterface {
  operativeTypeName: string
  operativeTypeNameEn?: string
  color: string
  status: string
  description?: string
  image?: {
    fileId: number
    base64: string
    type: string
    fileExtension: string
    fileSize: string
  }
}

export interface OperativeTypeCreateInterface {
  operativeTypeName: string
  operativeTypeNameEn?: string
  color: string
  description?: string
  image?: {
    fileId: number
    base64: string
    type: string
    fileExtension: string
    fileSize: string
  }
}

export interface OperativeTypeObjInterface {
  color: string
  createdAt: string
  createdBy: string
  operativeTypeId: number
  operativeTypeName: string
  operativeTypeNameEn: string
  status: string
  updatedAt: string
  updatedBy: string
}

export default class OperativeTypeApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('operative-types', { params: { ...props } }).then((res) => res)
  }
  static findAllActive(props: FindAllInterface): Promise<any> {
    return this.api.get('operative-types/all-active', { params: { ...props } }).then((res) => res)
  }

  static create(playload: OperativeTypeCreateInterface) {
    return this.api.post('operative-types', playload)
  }

  static findById(id: number): Promise<OperativeTypeInterface> {
    return this.api.get(`operative-types/${id}`)
  }

  static update(id: number, body: OperativeTypeInterface) {
    return this.api.patch(`operative-types/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`operative-types/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`operative-types/${id}`)
  }
}
