import moment from 'moment'
import _ from 'lodash'
import { Box } from '@mui/material'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import Avatar from '@mui/material/Avatar'
import ButtonCustom from 'component/Button/ButtonCustom'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { imgs, Icons } from 'constants/images';
import { PACKAGE_ADDONS } from 'constants/package'

/** API */
import { fileUrl } from 'api/api'

/** UTILS */
import { memberTypeOutline, checkPackage } from 'utils/app.utils'

/** STYLE */
import { styled } from '@mui/material/styles'
import { useCallback, useEffect, useState } from 'react'

import { Draggable } from "react-beautiful-dnd";
import DrageMove from 'assets/icon/appointment/card/drag-move'

export const Card = styled('div')(({ theme }) => ({
  margin: '1rem 1rem 0',
  borderRadius: 4,
  boxShadow: `0 3px 6px 0 ${colors.black16}`,
  backgroundColor: colors.white,
  color: colors.textPrimary,
  cursor: 'pointer',
  '& p': {
    margin: 0
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0.75rem',
    marginRight: '0.75rem'
  }
}))

export const CardBody = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: '1.5rem 1rem 0.5rem 1.25rem',
  border: `1px solid ${colors.extraLightGray}`,
  borderRadius: 4,
  minHeight: 123,
  '& .number': {
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: 27,
    minHeight: 28,
    color: colors.white,
    background: colors.themeSecondColor,
    borderRadius: '4px 0 20px',
    padding: '2px 0 0 4px',
    fontSize: 14,
    lineHeight: 'normal'
  },
  '& .queue': {
    position: 'absolute',
    top: 8,
    right: 8,
    color: colors.white,
    background: colors.themeMainColor,
    borderRadius: 2,
    padding: '1px 6.7px',
    fontSize: 14,
    lineHeight: 'normal'
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem'
  }
}))

export const CardFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  '> div': {
    width: '100%',
    '.MuiButton-outlined': {
      width: '100%',
      borderRadius: 0,
      borderColor: `${colors.extraLightGray} !important`,
      fontSize: 16,
      textTransform: 'capitalize',
      height: 49,
      img: {
        width: 16,
        height: 16
      },
      '.MuiButton-startIcon': {
        marginLeft: 0
      }
    },
    '&:first-of-type .MuiButton-outlined': {
      borderBottomLeftRadius: 4
    },
    '&:last-of-type .MuiButton-outlined': {
      borderBottomRightRadius: 4
    },
    '.btn-payment': {
      borderColor: 'transparent !important',
      paddingLeft: 0
    }
  },
  '.btn-icon-group': {
    display: 'flex',
    justifyContent: 'flex-end',
    '> div': {
      margin: '0 !important',
      '&:first-of-type .MuiButton-outlined': {
        borderRadius: '0 !important'
      }
    },
    '.btn-icon': {
      borderColor: 'transparent !important',
      minWidth: 48,
      padding: 0,
      '.MuiButton-startIcon': {
        margin: 0
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    button: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }
}))

const Tag = styled('div')(({ theme }) => ({
  display: 'inline-block',
  margin: '0.25rem 0.11rem 0',
  padding: '0 0.2rem',
  fontSize: 10,
  color: colors.white,
  backgroundColor: colors.themeThirdColor,
  borderRadius: 3,
  whiteSpace: 'nowrap'
}))

interface DataCardServicePoint {
  queueNumber: number
  cnNumber: string
  branchCnNumber: string
  snCode: string
  avatar: string
  fullname: string
  rank: string
  vnNumber: string
  createdAt: string
  treatments: any
  updatedAt: string
  hasLab?: 'HAS' | 'NOT_HAS'
  hasXray?: 'HAS' | 'NOT_HAS'
}

type CardServicePointType = {
  patient: DataCardServicePoint
  number: number
  serviceType: string
  hideFooterAction?: boolean
  type?: 'home' | 'queue'
  branch: string
  onClick?: (val: any) => void
  onCancel?: (val: any) => void
  onPayment?: (val: any) => void
  onSendMessage?: (val: any) => void
  draggableId?: string
  isDrag?: boolean
  onMouseDown?: () => void
  dragProvided?: any
}

export default function CardServicePoint(props: CardServicePointType) {
  const { t } = useTranslation()
  const [branch, setBranch] = useState<any>()

  useEffect(() => {
    setBranch(props.branch)
  }, [props.branch])


  const handlePatientCode = () => {
    if (branch?.patientCodeQueue === '1') {
      return props.patient.cnNumber ?? '-'
    }
    if (branch?.patientCodeQueue === '0') {
      return props.patient.branchCnNumber ?? '-'
    }

    if (branch?.patientCodeQueue === '2') {
      return props.patient.snCode ?? ''
    }
  }
  const renderImage = (img: any) => {
    if (!_.isEmpty(img)) return `${fileUrl}/${img}`
    else return imgs.defaultAvatar
  }

  const renderTreatments = (treatments: any) => {
    const treatmentName: string[] = []
    _.map(treatments, (treatment: any, index: number) => {
      treatmentName.push(treatment.operativeId ? treatment.operativeName : treatment.operativeTypeName)
    })

    return !_.isEmpty(treatmentName) ? treatmentName.toString().replaceAll(',', ', ') : ''
  }

  const renderDateCreate = (date: string) => {
    if (props.patient.createdAt) return `${moment(props.patient.createdAt).format('HH.mm')} ${t('TIME_UNIT')}`
    else return ''
  }

  const renderTooltip = () => {
    return `${props.patient.fullname} ${handlePatientCode()} ${t('HOME.TITLE.VN')} ${props.patient.vnNumber} ${renderDateCreate(props.patient.createdAt)} ${renderTreatments(props.patient.treatments)}`
  }

  const renderDateNow = useCallback(() => {
    const createdAt = props.patient.createdAt;
    const now = moment();

    const duration = moment.duration(now.diff(createdAt));
    const minutesAgo = duration.asMinutes();
    if (Number(minutesAgo?.toFixed(0)) <= 60 && Number(minutesAgo?.toFixed(0)) > 1) {
      return `${minutesAgo.toFixed(0)} ${t('MINUTE_AGO')}`
    } else {
      return moment(props.patient.createdAt).fromNow()
    }
  }, [props.patient.createdAt])

  return (
    <CardDraggable
      type={props?.type}
      number={props.number}
      serviceType={props.serviceType}
      draggableId={props?.draggableId}
      isDrag={props?.isDrag || false}

      hideFooterAction={props?.hideFooterAction}
      onCancel={props?.onCancel}
      onPayment={props?.onPayment}
      onSendMessage={props?.onSendMessage}

    >
      <CardBody
        className={'d-flex flex-column'}
        onClick={props.onClick}
        title={renderTooltip()}>
        <p className={'number'}>{String(props.number + 1).padStart(2, '0')}</p>
        <p className={'queue'}>{String(props.patient.queueNumber).padStart(2, '0')}</p>
        <div className={'d-flex'}>
          <Box sx={{ maxWidth: '50px' }}>
            <Avatar alt={props.patient.cnNumber} src={renderImage(props.patient.avatar)} className="mx-auto" sx={{ width: 50, height: 50, border: `3px solid ${memberTypeOutline(props.patient.rank)}`, backgroundColor: colors.white }}>
              <img alt='' src={imgs.defaultAvatar} className="w-100 h-100" />
            </Avatar>
            {props.type === 'queue' && (
              <Box className="d-flex align-items-center justify-content-center">
                {(props.patient.hasLab === 'HAS' && checkPackage(PACKAGE_ADDONS.ADDON_LAB)) && <Tag>LAB</Tag>}
                {(props.patient.hasXray === 'HAS' && checkPackage(PACKAGE_ADDONS.ADDON_X_RAY)) && <Tag>X-Ray</Tag>}
              </Box>
            )}
          </Box>
          <div className={'pl-3 overflow-hidden'}>
            <p className={'text-ellipsis text-medium'} style={{ fontWeight: 500 }}>
              {props.patient.fullname}
            </p>
            {(props.type === 'queue' && (
              <p className={'text-ellipsis'} style={{ fontSize: 14 }}>
                {handlePatientCode()}
              </p>
            )) || (
                <p
                  className={'text-ellipsis'}
                  style={{ fontSize: 12, color: colors.textExtraLightGray }}>
                  {handlePatientCode()} {t('HOME.TITLE.VN')} {props.patient.vnNumber} {renderDateCreate(props.patient.createdAt)}
                </p>
              )}
            <p
              className={'text-ellipsis pt-1'}
              style={{ fontSize: 14 }}>
              <span>{renderTreatments(props.patient.treatments)}</span>
            </p>
          </div>
        </div>
        <p className={'pt-1 text-right mt-auto'} style={{ fontSize: 12, color: colors.statusInactive }}>
          {renderDateNow()}
        </p>
      </CardBody>
    </CardDraggable>
  )
}

interface CardFooterDraggableProps {
  serviceType: string
  dragProvided?: any
  dragSnapshot?: any

  hideFooterAction?: boolean
  onCancel?: (val: any) => void
  onPayment?: (val: any) => void
  onSendMessage?: (val: any) => void

}

const CardFooterDraggable = (props: CardFooterDraggableProps) => {
  const { t } = useTranslation()

  const styleBTN = {
    '.MuiButton-outlined': {
      padding: '0'
    }
  }

  return (
    <>
      {!props.hideFooterAction && (
        <CardFooter>
          {props?.dragProvided &&
            <>
              <div
                style={{
                  width: 'fit-content',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0 8px'
                }}
                {...props?.dragProvided?.dragHandleProps}
              >
                <DrageMove
                  fill={props?.dragSnapshot?.isDragging ? colors.themeSecondColor : undefined}
                  width={'20px'}
                  height={'20px'}
                />
              </div>
            </>
          }
          {(props.serviceType !== 'PAYMENT' && (
            <>
              <ButtonCustom style={{ ...styleBTN }} startIcon={<Icons.IconLine />} textButton={t('HOME.BUTTON.SEND_MESSAGE')} type="button" variant="outlined" onClick={props.onSendMessage}></ButtonCustom>
              <ButtonCustom style={{ ...styleBTN }} startIcon={<Icons.IconClose />} textButton={t('HOME.BUTTON.CANCEL_VISIT')} type="button" variant="outlined" onClick={props.onCancel}></ButtonCustom>
            </>
          )) || (
              <>
                <ButtonCustom startIcon={<Icons.IconPayment />} textButton={t('HOME.BUTTON.PAYMENT')} type="button" variant="outlined" className={'btn-payment'} onClick={props.onPayment}></ButtonCustom>
                <div className={'btn-icon-group'}>
                  <ButtonCustom startIcon={<Icons.IconLine />} type="button" variant="outlined" className={'btn-icon'} onClick={props.onSendMessage}></ButtonCustom>
                  <ButtonCustom startIcon={<Icons.IconClose />} type="button" variant="outlined" className={'btn-icon'} onClick={props.onCancel}></ButtonCustom>
                </div>
              </>
            )}
        </CardFooter>
      )}
    </>
  )
}

interface CardDraggableProps {
  number: number
  serviceType: string
  type?: 'home' | 'queue'
  children: any
  draggableId?: string
  isDrag?: boolean

  hideFooterAction?: boolean
  onCancel?: (val: any) => void
  onPayment?: (val: any) => void
  onSendMessage?: (val: any) => void

}

const CardDraggable = (props: CardDraggableProps) => {

  if (props.type === 'home' && props?.draggableId) {
    return (
      <Draggable
        key={props?.number}
        draggableId={`${props?.draggableId}`}
        index={props?.number}
        isDragDisabled={props?.isDrag || false}
      >
        {(dragProvided, dragSnapshot) => {
          return (
            <Card
              {...dragProvided.draggableProps}
              // {...dragProvided.dragHandleProps}
              ref={dragProvided.innerRef}
            >
              {props.children}
              <CardFooterDraggable
                dragProvided={dragProvided}
                dragSnapshot={dragSnapshot}
                serviceType={props.serviceType}
                hideFooterAction={props?.hideFooterAction}
                onCancel={props?.onCancel}
                onPayment={props?.onPayment}
                onSendMessage={props?.onSendMessage}
              />
            </Card>
          )
        }}
      </Draggable>
    )
  } else {
    return (
      <Card>
        {props.children}
        <CardFooterDraggable
          serviceType={props.serviceType}
          hideFooterAction={props?.hideFooterAction}
          onCancel={props?.onCancel}
          onPayment={props?.onPayment}
          onSendMessage={props?.onSendMessage}
        />
      </Card>
    )
  }
}
