import { useCallback, useEffect, useRef, useState } from 'react';
import { Card, Col, Row, Dropdown } from 'react-bootstrap';
import { routeName } from 'routes/routes-name';
import { useHistory } from 'react-router-dom';
import { Box, MenuItem } from '@mui/material';
import _ from 'lodash';

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/**API */
import { getBranch } from 'utils/app.utils';
import ClinicApi, { BranchInterface } from 'api/master/clinic.api';
import ReportApi, { FindAllReportInterface } from 'api/report.api';

/**COMPONENT */
import Loading from 'component/Loading';
import PatientList from './PatientList';
import FucntionPrint from "component/Print/print";
import HeaderCard from 'component/CardCustom/HeaderCard';
import FilterSelect from 'component/Select/FilterSelect';
import { useDownloadExcel } from 'component/Excel/hooks/useExcel';

/**STYLE */
import * as UseStyled from 'features/report/useStyled';
import PatientTypeApi from 'api/setting/default-users/patient-type.api';
import AutocompleteSelect from 'component/Select/AutocompleteSelect';

export default function PatientInformation() {
    const { t } = useTranslation();
    const history = useHistory();
    const componentRef = useRef<HTMLDivElement>(null);

    const [branchId, setBranchId] = useState<any>(getBranch());
    const [branches, setBranches] = useState<any[]>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortBy, setSortBy] = useState<string>('createAt');
    const [sortType, setSortType] = useState<string>('ASC');

    const [treatmentRightData, getTreatmentRightData] = useState<any[]>([]);
    const [treatmentRightId, setTreatmentRightId] = useState<number>(0);

    const [patientData, setPatientData] = useState<any[]>([]);
    const [patientDataExport, setPatientDataExport] = useState<any[]>([]);
    const [patientPage, setPatientPage] = useState<number>(1);
    const [patientLimitPage, setPatientLimitPage] = useState<number>(10);

    const [loading, setLoading] = useState<boolean>(true);

    const [patientTypes, setPatientTypes] = useState<any>([])
    const [patientTypesSelcet, setPatientTypesSelcet] = useState<number | undefined>(undefined)

    const verifyPermission = async () => {
        const condition: FindAllReportInterface = {
            page: 1, pageLimit: 200
        }
        ReportApi.reportList(condition)
            .then(({ data }) => {
                const check = _.find(data, (val: any) => String(val.reportKey) === 'PATIENT_INFORMATION') ? true : false
                if (!check) {
                    history.push(routeName.report)
                    return false
                }
            })

        ClinicApi.findAllBranches()
            .then(({ data }) => setBranches(data))
            .catch((e) => { return })

        ClinicApi.findAllTreatmentRight()
            .then((resp) => { getTreatmentRightData(resp.data) })
            .catch((e) => { return })

        return true
    }

    const loadPatientType = async () => {
        const condition: any = {
            page: 1,
            pageLimit: 30,
            status: "ACTIVE"
        }

        const res = await PatientTypeApi.findAll(condition)
        if (res.status === 200) {
            setPatientTypes(res.data)
        }
    }

    useEffect(() => {
        verifyPermission()
        loadPatientType()
    }, [])

    const fetchPatientInformation = useCallback(async () => {
        await verifyPermission()
        let condition: FindAllReportInterface = {
            page: patientPage, pageLimit: patientLimitPage,
            branchId: branchId, treatmentRightId: treatmentRightId
        }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (patientTypesSelcet || patientTypesSelcet === 0) condition = { ...condition, patientTypeId: patientTypesSelcet }

        ReportApi.reportPatientInformation(condition)
            .then(({ headers, data }) => {
                setPatientData(data.rows)
                setRowCount(headers['x-total'])
            }).catch((e) => setLoading(false))
            .finally(() => setLoading(false))

    }, [branchId, patientPage, patientLimitPage, sortBy, sortType, treatmentRightId, patientTypesSelcet])

    const fetchPatientInformationExport = useCallback(async () => {
        let condition: FindAllReportInterface = {
            page: 1, pageLimit: 10000,
            branchId: branchId,
            treatmentRightId: treatmentRightId
        }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (patientTypesSelcet || patientTypesSelcet === 0) condition = { ...condition, patientTypeId: patientTypesSelcet }

        ReportApi.reportPatientInformation(condition)
            .then(({ data }) => {
                setPatientDataExport(data.rows)
            }).catch((e) => setLoading(false))
            .finally(() => setLoading(false))

    }, [branchId, sortBy, sortType, treatmentRightId, patientTypesSelcet])

    const { onDownload } = useDownloadExcel({
        tableExportRef: componentRef.current,
        filename: `${t(`REPORT.REPORT_NAME.PATIENT_INFORMATION`)}`,
        sheetName: `${t(`REPORT.REPORT_NAME.PATIENT_INFORMATION`)}`
    })

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangeRowsPerPage = (limit: number) => setPatientLimitPage(limit)

    const handleChangePage = (val: number) => setPatientPage(val)

    /** UseEffect */
    useEffect(() => {
        fetchPatientInformation()
    }, [fetchPatientInformation])

    useEffect(() => {
        fetchPatientInformationExport()
    }, [fetchPatientInformationExport])

    const renderValuePatientType = () => {
        let newType: any = patientTypesSelcet
        if (patientTypesSelcet) {
            newType = patientTypes?.filter((item: any) => patientTypesSelcet === item?.patientTypeId)[0]?.patientTypeName || ''
        }
        return `${t('REPORT.TABLE.PATIENT.PATIENT_TYPE')}: ${!newType ? newType === 0 && t('NOT_SPECIFIED') || t('ALL') : newType}`
    }

    return (
        <>
            <Card className={'border-0 h-100'}>
                <HeaderCard text={t(`REPORT.REPORT_NAME.PATIENT_INFORMATION`)} />
                <Card.Body>
                    <Card.Title className={'mb-0'}>
                        <Row className='mx-0'>
                            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                                <FilterSelect
                                    onchange={(e) => {
                                        setBranchId(e.target.value)
                                        setPatientPage(1)
                                    }}
                                    renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                                    label={''}
                                    selectId="select-branch"
                                    labelId="label-branch"
                                    value={branchId}
                                    options={
                                        _.map(branches, (b: BranchInterface, index) => {
                                            return (
                                                <MenuItem key={b.branchId} value={b.branchId}>
                                                    {b.branchName || b.branchNameEn}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                    formControlStyle={{ maxWidth: 'unset !important' }}
                                    classesOption="style-select-doctors"
                                />
                            </Col>
                            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                                <AutocompleteSelect
                                    labelId='patient-type'
                                    noOptionsText={t('NOT_FOUND')}
                                    options={[t('ALL'), t('NOT_SPECIFIED'), ...patientTypes]}
                                    getOptionLabel={(option) => option?.patientTypeName || option}
                                    height={32}
                                    onchange={(e, val) => {
                                        if (val?.patientTypeId) {
                                            setPatientTypesSelcet(val?.patientTypeId)
                                        } else {
                                            setPatientTypesSelcet(val === t('ALL') || !val ? undefined : 0)
                                        }
                                    }}
                                    value={renderValuePatientType()}
                                />
                            </Col>

                        </Row>
                        <Row className='mx-0'>
                            <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                                <UseStyled.DropdownDocument>
                                    <Dropdown.Toggle id="dropdown-basic" className='ml-xl-auto'>
                                        {t('PRINT_DOCUMENT')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={onDownload}>
                                            {t('Excel')}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </UseStyled.DropdownDocument>
                            </Col>
                        </Row>
                    </Card.Title>
                    <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
                        <PatientList
                            filter={{
                                branch: `${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`
                            }}
                            page={patientPage}
                            pageLimit={patientLimitPage}
                            rowCount={rowCount}
                            sortBy={sortBy}
                            sortType={sortType}
                            data={patientData}
                            onRequestSort={onRequestSort}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleChangePage={handleChangePage}
                            componentRef={componentRef}
                            exportData={patientDataExport} />
                    </Box>
                </Card.Body>
                <Loading show={loading} type='fullLoading' />
            </Card>
        </>
    )
}