import { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Grid, MenuItem, Box } from '@mui/material'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { CardSalesProps, handleChangeFilterSelect, renderFilterSelect } from '../dashboard'
import FilterSelect from 'component/Select/FilterSelect'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'

/** UTILS || SLICE */
import { numberFormat } from 'utils/app.utils'

/** API */
import { BranchInterface } from 'api/master/clinic.api'

/** STYLE */
import * as UseStyled from '../useStyled'
import DashboardApi, { IFBastTotalExpenses, IFSumeryFilter } from 'api/dashboard.api'

export default function TotalExpenses(props: CardSalesProps) {
    const { t } = useTranslation()

    const bg = colors.dashboard.totalExpenses

    const customBar = {
        maxBarThickness: 40,
        // barPercentage: 0.7,
        borderRadius: 6,
    }

    // data
    const [labels, setLabels]: any = useState([])
    const [labelProducts, setLabelProducts]: any = useState([])
    const [data, setData]: any = useState({
        labels: [],
        datasets: [
            {
                label: 'a',
                data: [],
                backgroundColor: bg,
                hoverBackgroundColor: bg,
                ...customBar
            },
        ]
    })
    const [branches, setBranches] = useState<BranchInterface[]>([])
    const [branch, setBranch] = useState<(string | number)[]>(['ALL'])
    const [rangeDate, setRangeDate] = useState([moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
    const [minMax, setMinMax] = useState([0, 0]);

    const handleChangeSelectBranch = (e: any) => setBranch(handleChangeFilterSelect(e))

    const loadData = useCallback(async () => {
        const condition: IFSumeryFilter = { dateStart: rangeDate[0], dateEnd: rangeDate[1], branchId: branch[0] === 'ALL' ? `` : branch.toString() }
        const res = await DashboardApi.findTotalExpenses(condition)

        if (res.status === 200) {
            const summeris: IFBastTotalExpenses[] = res.data;
            const dataLabels: string[] = [];
            const dataLabelProducts: any[] = [];
            const dataProducts: any[] = [];
            let maxBars = 0
            summeris.forEach((x, i: number) => {
                dataLabels.push(x.branchName);

                maxBars = x.expenses.length >= maxBars ? x.expenses.length : maxBars
            });

            dataProducts.push(...Array.from({ length: maxBars }, () => ({ ...customBar, data: Array.from({ length: summeris.length }, () => 0) })));
            dataLabelProducts.push(...Array.from({ length: maxBars }, () => ({ data: Array.from({ length: summeris.length }, () => 0) })));


            summeris.forEach((x, j: number) => {
                summeris[j].expenses.forEach((h, index: number) => {
                    dataProducts[index].data[j] = h.total
                    const colorsStatus = bg[j]
                    dataProducts[index].backgroundColor = colorsStatus
                    dataLabelProducts[index].data[j] = h.expenseTypeName
                })
            })
            setLabels(dataLabels);
            setLabelProducts(dataLabelProducts);
            setData({
                labels: dataLabels,
                datasets: dataProducts,
            });
        }
    }, [branch, rangeDate])

    useEffect(() => {
        loadData()
    }, [loadData])

    useEffect(() => {
        setBranches(props.branches || [])
    }, [props.branches])

    useEffect(() => {
        setRangeDate(props.rangeDate || [])
    }, [props.rangeDate])

    useEffect(() => {
        setBranch(props.branch || [])
    }, [props.branch])

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        if (!labelProducts) return numberFormat(context.raw)
                        if (!labelProducts[context.datasetIndex].data[context.dataIndex]) return numberFormat(context.raw)
                        return ` ${labelProducts[context.datasetIndex].data[context.dataIndex]}: ${numberFormat(context.raw)}`
                    },
                }
            },
        },
        scales: {
            x: {
                grid: {
                    display: true,
                    drawBorder: false,
                    drawOnChartArea: false,
                    drawTicks: false,
                    color: colors.lightGray
                },
                ticks: {
                    color: colors.textExtraLightGray,
                    fontSize: 16,
                    padding: 10,
                    maxRotation: 0,
                    minRotation: 0,
                    callback: function (val: any, index: number) {
                        const label = t('DASHBOARD.FILTER.BRANCH') + labels[val];
                        const maxLength = 10;


                        const words = label.split(' ');
                        const lines = [];
                        let currentLine = '';

                        for (const word of words) {
                            if (currentLine.length + word.length <= maxLength) {
                                currentLine += word + ' ';
                            } else {
                                lines.push(currentLine.trim());
                                currentLine = word + ' ';
                            }
                        }

                        if (currentLine.trim().length > 0) {
                            lines.push(currentLine.trim());
                        }

                        return lines;
                    },
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    color: colors.lightGray
                },
                ticks: {
                    color: colors.textExtraLightGray,
                    fontSize: 16,
                    stepSize: 5000,
                    borderRadius: 8,
                    callback: function (val: any, index: number) {
                        return numberFormat(Number(val).toFixed(0))
                    },
                }
            }
        }
    }

    return (
        <UseStyled.Card className='d-flex flex-column'>
            <Box className='pt-3 px-3 px-xl-4' sx={{ borderBottom: `1px solid ${colors.lightGray}`, paddingBottom: '12px' }}>
                <Grid container spacing={1} alignItems={'center'}>
                    <Grid item xs={12} md={4} lg={12} xl={4} className={'w-100'}>
                        <UseStyled.Title type='title' sx={{ flex: 'none' }}>{t('DASHBOARD.TOTAL_EXPENSES_TOP_FIVE')}</UseStyled.Title>
                    </Grid>
                    <Grid item xs={6} md={4} lg={6} xl={4} className={'w-100 pr-sm-2'}>
                        <FilterSelect
                            multiple
                            label={t('')}
                            labelId="label-branch"
                            selectId="select-branch"
                            onchange={handleChangeSelectBranch}
                            renderValue={() => `${t('DASHBOARD.FILTER.BRANCH')}: ${renderFilterSelect('branch', branches, branch) === 'ALL' ? t('STATUS.ALL') : renderFilterSelect('branch', branches, branch)}`}
                            value={branch}
                            options={[
                                <MenuItem
                                    key="ALL"
                                    value="ALL"
                                    sx={{
                                        '&.MuiMenuItem-root.Mui-selected': {
                                            pointerEvents: _.includes(branch, 'ALL') ? `none` : 'auto',
                                            backgroundColor: _.includes(branch, 'ALL') ? `${colors.themeSecondColor06} !important` : 'transparent',
                                            color: _.includes(branch, 'ALL') ? `${colors.themeSecondColor} !important` : `${colors.textPrimary}`
                                        }
                                    }}>
                                    {t('STATUS.ALL')}
                                </MenuItem>,
                                _.map(props.branches, (val: BranchInterface, indexBranch: number) => {
                                    return (
                                        <MenuItem
                                            key={indexBranch}
                                            value={val.branchId}
                                            sx={{
                                                '&.MuiMenuItem-root.Mui-selected': {
                                                    backgroundColor: _.includes(branch, val.branchId) ? `${colors.themeSecondColor06} !important` : 'transparent',
                                                    color: _.includes(branch, val.branchId) ? `${colors.themeSecondColor} !important` : `${colors.textPrimary}`
                                                }
                                            }}>
                                            {val.branchName}
                                        </MenuItem>
                                    )
                                })
                            ]}
                            formControlClass={'not-max-width'}
                            classesOption="style-select-doctors"
                        />
                    </Grid>
                    <Grid item xs={6} md={4} lg={6} xl={4} className={'w-100'}>
                        <InputSecondNewRangePicker
                            inputHeight={32}
                            value={rangeDate}
                            onchange={(val) => {
                                if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                                else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                            }}
                            allowClear
                            onClear={() => setRangeDate(emptyRangePicker)}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box className='p-3 px-xl-4 d-flex align-items-center h-100'>
                <Bar options={options} data={data} />
            </Box>
        </UseStyled.Card>
    )
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)