import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

const registerApp = '/register-application'

export default class RegisterAppApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get(`${registerApp}`, { params: { ...props } }).then((res) => res)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`${registerApp}/${id}/status`, { premissionApp: status }).then((res) => res)
  }

  static updateMultipleStatus(id: string[], domain: string, status: string): Promise<any> {
    return this.api.patch(`${registerApp}/status`, { patientId: id, domain: domain, premissionApp: status }).then((res) => res)
  }
}