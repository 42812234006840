import { useCallback, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { Typography, Box, MenuItem, Grid, Divider, IconButton, Collapse, TableRow, TableCell } from '@mui/material'
import { useHistory, useParams } from 'react-router'
import _ from 'lodash'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import TemplateDoctorFeeApi, { TemplateDoctorFee } from 'api/setting/doctor-fee-managements/template-doctor-fee.api'

/** ROUTE */
import { routeName } from 'routes/routes-name'

/** COMPONENT */
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import InputTextField from 'component/Input/InputTextField'
import { ButtonInfoSimple } from 'component/Button/ButtonInfo'
import BasicSelect from 'component/Select/BasicSelect'
import ButtonCustom from 'component/Button/ButtonCustom'
import TableCustom from 'component/Table/TableCustom'
import InputCheckbox from 'component/Input/InputCheckbox'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import { swalCustom } from 'component/Alert/Swal'

/** STYLE */
import * as UseStyled from 'features/setting/doctor-fee-managements/template-doctor-fee/style'
import { colors } from 'constants/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { numberFormat } from 'utils/app.utils'

type errorMessage = {
  EMPTY_DF_NAME?: string
  EMPTY_DF_AMOUNT?: string
  EMPTY_LAB_AMOUNT?: string
}

export enum DF_UNIT {
  'PERCENT' = 'PERCENT',
  'BATH' = 'BAHT'
}

export default function FormTemplateDoctorFee() {
  const { t } = useTranslation()
  const { id: templateDoctorFeeId }: any = useParams()
  const history = useHistory()

  const units: DF_UNIT[] = [DF_UNIT.PERCENT, DF_UNIT.BATH]
  const [operativeTypes, setOperativeTypes] = useState([])
  const [templateOperativeId, setTemplateOperativeId] = useState(0)
  const [templateOperatives, setTemplateOperatives] = useState<TemplateDoctorFee>({
    templateDoctorFeeName: '',
    amount: 0,
    labDoctorAmount: 0,
    isMain: '0',
    templateDoctorFeeOperativeTypes: []
  })
  const [status, setStatus] = useState('')
  const [errorMessage, setErrorMessage] = useState<errorMessage>({})

  const loadTemplateOperative = useCallback((templateDoctorFeeId?: number) => {
    let templateDoctorFee = {}
    if (templateDoctorFeeId) {
      TemplateDoctorFeeApi.findById(templateDoctorFeeId)
        .then(({ data }) => {
          setTemplateOperativeId(data.templateDoctorFeeId)
          setStatus(data.status)
          templateDoctorFee = {
            templateDoctorFeeName: data.templateDoctorFeeName,
            amount: data.amount,
            labDoctorAmount: data.labDoctorAmount,
            isMain: data.isMain,
            templateDoctorFeeOperativeTypes: []
          }
        })
        .catch((e) => {
          return
        })
    }

    TemplateDoctorFeeApi.findAllWithOperatives(templateDoctorFeeId)
      .then(({ data }) => {
        setOperativeTypes(data)
        const newData = data.map((item: any) => {
          const templateDF = item?.templateDoctorFeeOperativeTypes
          const newOperatives = item.operatives.map((info: any) => {
            if (!info?.templateDoctorFeeOperatives) {
              return { ...info, templateDoctorFeeOperatives: { ...templateDF, operativeId: info?.operativeId } }
            } else {
              return info
            }
          })

          return { ...item, operatives: newOperatives }
        })

        if (!_.isEmpty(data)) handleChangeTemplateOperative(templateDoctorFeeId ? 'edit' : 'add', templateDoctorFeeId ? newData : data, templateDoctorFee, '')
      })
      .catch((e) => {
        return
      })
  }, [])

  const handleChangeTemplateOperative = (type: 'add' | 'edit' | 'updateDF' | 'updateLab', operativesData: any, value?: any, valueUpdate?: any) => {
    const operativeTypes: any = []
    _.map(operativesData, (optType, index) => {
      let newDataOperativeType = {}
      const operative: any = []
      _.map(optType.operatives, (opt, indexOpt) => {
        let newDataOperative = {}
        if (type === 'updateDF') {
          newDataOperative = {
            operativeId: opt.operativeId,
            dfAmount: Number(valueUpdate),
            cfAmount: Number(100 - valueUpdate),
            dfUnit: DF_UNIT.PERCENT,
            labDoctorAmount: Number(value.templateDoctorFeeOperativeTypes[index].templateDoctorFeeOperatives[indexOpt].labDoctorAmount),
            labClinicAmount: Number(value.templateDoctorFeeOperativeTypes[index].templateDoctorFeeOperatives[indexOpt].labClinicAmount)
          }
        } else if (type === 'updateLab') {
          newDataOperative = {
            operativeId: opt.operativeId,
            dfAmount: Number(value.templateDoctorFeeOperativeTypes[index].templateDoctorFeeOperatives[indexOpt].dfAmount),
            cfAmount: Number(value.templateDoctorFeeOperativeTypes[index].templateDoctorFeeOperatives[indexOpt].cfAmount),
            dfUnit: opt.templateDoctorFeeOperatives?.dfUnit || DF_UNIT.PERCENT,
            labDoctorAmount: Number(valueUpdate),
            labClinicAmount: Number(100 - valueUpdate)
          }
        } else {
          newDataOperative = {
            operativeId: opt.operativeId,
            dfAmount: Number(opt.templateDoctorFeeOperatives?.dfAmount) || 0,
            cfAmount: opt.templateDoctorFeeOperatives?.dfUnit === DF_UNIT.BATH ? Number(opt?.salePrice || 0) === 0 ? 0 : Number(opt?.salePrice || 0) - Number(opt.templateDoctorFeeOperatives?.dfAmount || 0) : Number(opt.templateDoctorFeeOperatives?.cfAmount) || 100 - (Number(opt.templateDoctorFeeOperatives?.dfAmount || 0)),
            dfUnit: opt.templateDoctorFeeOperatives?.dfUnit || DF_UNIT.PERCENT,
            labDoctorAmount: Number(opt.templateDoctorFeeOperatives?.labDoctorAmount) || 0,
            labClinicAmount: Number(opt.templateDoctorFeeOperatives?.labClinicAmount) || 100 - (Number(opt.templateDoctorFeeOperatives?.labDoctorAmount) || 0)
          }
        }
        operative.push(newDataOperative)
      })

      if (type === 'updateDF') {
        newDataOperativeType = {
          operativeTypeId: optType.operativeTypeId,
          dfAmount: Number(valueUpdate),
          cfAmount: Number(100 - valueUpdate),
          labDoctorAmount: Number(value.templateDoctorFeeOperativeTypes[index].labDoctorAmount),
          labClinicAmount: Number(value.templateDoctorFeeOperativeTypes[index].labClinicAmount),
          templateDoctorFeeOperatives: operative
        }
      } else if (type === 'updateLab') {
        newDataOperativeType = {
          operativeTypeId: optType.operativeTypeId,
          dfAmount: Number(value.templateDoctorFeeOperativeTypes[index].dfAmount),
          cfAmount: Number(value.templateDoctorFeeOperativeTypes[index].cfAmount),
          labDoctorAmount: Number(valueUpdate),
          labClinicAmount: Number(100 - valueUpdate),
          templateDoctorFeeOperatives: operative
        }
      } else {
        newDataOperativeType = {
          operativeTypeId: optType.operativeTypeId,
          dfAmount: Number(optType.templateDoctorFeeOperativeTypes?.dfAmount) || 0,
          cfAmount: Number(optType.templateDoctorFeeOperativeTypes?.cfAmount) || 100 - (Number(optType.templateDoctorFeeOperativeTypes?.dfAmount) || 0),
          labDoctorAmount: Number(optType.templateDoctorFeeOperativeTypes?.labDoctorAmount) || 0,
          labClinicAmount: Number(optType.templateDoctorFeeOperativeTypes?.labClinicAmount) || 100 - (Number(optType.templateDoctorFeeOperativeTypes?.labDoctorAmount) || 0),
          templateDoctorFeeOperatives: operative
        }
      }
      operativeTypes.push(newDataOperativeType)
    })

    const rawTemplateOperatives: TemplateDoctorFee = {
      templateDoctorFeeName: value.templateDoctorFeeName || '',
      amount: type === 'updateDF' ? Number(valueUpdate) : Number(value.amount) || 0,
      labDoctorAmount: type === 'updateLab' ? Number(valueUpdate) : Number(value.labDoctorAmount) || 0,
      isMain: value.isMain || '0',
      templateDoctorFeeOperativeTypes: operativeTypes
    }


    setTemplateOperatives(rawTemplateOperatives)
  }

  const clearErrorMessage = () => {
    setErrorMessage({})
  }



  const onSubmit = () => {
    if (!templateOperatives.templateDoctorFeeName || (!templateOperatives.amount && templateOperatives.amount !== 0) || (!templateOperatives.labDoctorAmount && templateOperatives.labDoctorAmount !== 0)) {
      return setErrorMessage({
        EMPTY_DF_NAME: !templateOperatives.templateDoctorFeeName ? t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR.EMPTY_DF_NAME') : '',
        EMPTY_DF_AMOUNT: !templateOperatives.amount && templateOperatives.amount !== 0 ? t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR.EMPTY_DF_AMOUNT') : '',
        EMPTY_LAB_AMOUNT: !templateOperatives.labDoctorAmount && templateOperatives.labDoctorAmount !== 0 ? t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR.EMPTY_LAB_AMOUNT') : ''
      })
    }

    TemplateDoctorFeeApi.create(templateOperatives)
      .then(() => {
        notiSuccess(t('TEMPLATE_DOCTOR_FEE.MESSAGE.SUCCESS.CREATE'), '', null, null)
        history.push(routeName.templateDoctorFee)
      })
      .catch((e) => {
        if (e.response.data.message === 'IS_MAIN_TEMPLATE_ALREADY_EXISTS') return notiError(t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR.IS_MAIN_TEMPLATE_ALREADY_USE'), '', null, null, '')
        else if (e.response.data.message === 'IS_DUPLICATE_TEMPLATE_DOCTOR_FEE_NAME') return notiError(t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR.IS_DUPLICATE_TEMPLATE_DOCTOR_FEE_NAME'), '', null, null, '')
        else return notiError(e.response.data.message, '', null, null, '')
      })
  }

  const onSubmitEdit = () => {
    if (!templateOperatives.templateDoctorFeeName || (!templateOperatives.amount && templateOperatives.amount !== 0) || (!templateOperatives.labDoctorAmount && templateOperatives.labDoctorAmount !== 0)) {
      return setErrorMessage({
        EMPTY_DF_NAME: !templateOperatives.templateDoctorFeeName ? t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR.EMPTY_DF_NAME') : '',
        EMPTY_DF_AMOUNT: !templateOperatives.amount && templateOperatives.amount !== 0 ? t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR.EMPTY_DF_AMOUNT') : '',
        EMPTY_LAB_AMOUNT: !templateOperatives.labDoctorAmount && templateOperatives.labDoctorAmount !== 0 ? t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR.EMPTY_LAB_AMOUNT') : ''
      })
    }

    swalCustom(
      `${t('TEMPLATE_CREDIT_FEE.MESSAGE.CAN_UPDATE')} \n"${templateOperatives.templateDoctorFeeName}"`,
      `${t('TEMPLATE_CREDIT_FEE.MESSAGE.CAN_USE')}`,
      (res: any) => {
        if (res) {
          const rawTemplateOperatives = { ...templateOperatives, status: status }
          TemplateDoctorFeeApi.update(templateOperativeId, rawTemplateOperatives)
            .then(() => {
              notiSuccess(t('TEMPLATE_DOCTOR_FEE.MESSAGE.SUCCESS.UPDATE'), '', null, null)
              history.push(routeName.templateDoctorFee)
            })
            .catch((e) => {
              if (e.response.data.message === 'IS_MAIN_TEMPLATE_ALREADY_EXISTS') return notiError(t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR.IS_MAIN_TEMPLATE_ALREADY_USE'), '', null, null, '')
              else if (e.response.data.message === 'IS_DUPLICATE_TEMPLATE_DOCTOR_FEE_NAME') return notiError(t('TEMPLATE_DOCTOR_FEE.MESSAGE.ERROR.IS_DUPLICATE_TEMPLATE_DOCTOR_FEE_NAME'), '', null, null, '')
              else return notiError(e.response.data.message, '', null, null, '')
            })
        }
      },
      'question',
      {
        confirmButton: {
          confirmButtonText: t('TEMPLATE_CREDIT_FEE.BUTTON.CONFIRM')
        },
        cancelButton: {
          cancelButtonText: t('TEMPLATE_CREDIT_FEE.BUTTON.CANCEL')
        }
      }
    )
  }

  const renderValNumber = (value: any, percent?: boolean) => {
    const handleValIsNumber = (num: string) => {
      if (num === '' || Number(num) === 0) return 0

      if (num?.includes('.')) {
        const [newNum_1, newNum_2] = num.split('.')
        if ((num[num.length - 1] === '.' || num[num.length - 1] === '0') && newNum_2.length <= 2) {
          return num
        } else {
          return `${newNum_1}.${newNum_2.slice(0, 2)}`
        }

      } else {
        return Number(num)
      }
    }

    value = value === '' ? 0 : String(value).replace(/[^0-9.]/g, '')
    if (!isNaN(Number(value))) {
      value = (Number(value) >= 100 && !percent) ? 100 : handleValIsNumber(value)
    } else {
      value = 0
    }
    return value

  }

  const handleChangeDefault = (event: any) => {
    const name = event.target.name
    let value = event.target.value
    if (name === 'amount' || name === 'labDoctorAmount') {
      value = renderValNumber(value)
    }

    if (name === 'isMain') setTemplateOperatives({ ...templateOperatives, [name]: value === '0' ? '1' : '0' })
    else setTemplateOperatives({ ...templateOperatives, [name]: value })
    clearErrorMessage()
  }

  const onUpdateDefaultDF = () => {
    const dfValue = templateOperatives.amount
    handleChangeTemplateOperative('updateDF', operativeTypes, templateOperatives, dfValue)
  }

  const onUpdateDefaultLab = () => {
    const labValue = templateOperatives.labDoctorAmount
    handleChangeTemplateOperative('updateLab', operativeTypes, templateOperatives, labValue)
  }

  const onChangeValueOperativeType = (event: any, index: number) => {
    const name = event.target.name
    let value = event.target.value
    value = renderValNumber(value)

    const operativeType = _.merge({}, templateOperatives)
    let selectOperative = _.merge({}, operativeType.templateDoctorFeeOperativeTypes[index])

    if (name === 'dfAmount') selectOperative = { ...selectOperative, [name]: value, cfAmount: Number(100 - value) }
    else selectOperative = { ...selectOperative, [name]: value, labClinicAmount: Number(100 - value) }

    _.map(selectOperative.templateDoctorFeeOperatives, (opt: any) => {
      opt[name] = value
      opt.dfUnit = DF_UNIT.PERCENT
      if (name === 'dfAmount') opt.cfAmount = Number(100 - value)
      if (name === 'labDoctorAmount') opt.labClinicAmount = Number(100 - value)
    })

    operativeType.templateDoctorFeeOperativeTypes[index] = selectOperative
    setTemplateOperatives(operativeType)
  }

  const onChangeValueOperative = (event: any, typeIndex: number, index: number, operativePrice: number) => {
    const name = event.target.name
    let value = event.target.value
    if (name !== 'dfUnit') {
      value = renderValNumber(value, true)
      if (name === 'dfAmount') onChangeValueDfAmount(typeIndex, index, value, operativePrice)
      if (name === 'labDoctorAmount') onChangeValueLabAmount(typeIndex, index, value)
    } else onChangeValueDfUnit(typeIndex, index, value, operativePrice)
  }

  const calculateByPercent = (price: number, percent: number) => {
    return (Number(price) * Number(percent)) / 100
  }

  const onChangeValueDfAmount = (typeIndex: number, index: number, value: number, operativePrice: number) => {
    const operativeType = _.merge({}, templateOperatives)
    let selectOperative = _.merge({}, operativeType.templateDoctorFeeOperativeTypes[typeIndex].templateDoctorFeeOperatives[index])

    if (selectOperative.dfUnit === DF_UNIT.PERCENT) {
      const val = value >= 100 ? 100 : value
      selectOperative = { ...selectOperative, dfAmount: val, cfAmount: 100 - val }
    } else {
      // const val = value >= operativePrice ? operativePrice : value
      selectOperative = { ...selectOperative, dfAmount: value, cfAmount: operativePrice - value < 0 ? 0 : operativePrice - value }
    }

    operativeType.templateDoctorFeeOperativeTypes[typeIndex].templateDoctorFeeOperatives[index] = selectOperative
    setTemplateOperatives(operativeType)
  }

  const onChangeValueDfUnit = (typeIndex: number, index: number, value: DF_UNIT, operativePrice: number) => {
    const operativeType = _.merge({}, templateOperatives)
    const selectOperativeType = _.merge({}, operativeType.templateDoctorFeeOperativeTypes[typeIndex])
    let selectOperative = _.merge({}, selectOperativeType.templateDoctorFeeOperatives[index])
    if (value === DF_UNIT.PERCENT) {
      selectOperative = {
        ...selectOperative,
        dfUnit: value,
        dfAmount: selectOperativeType.dfAmount,
        cfAmount: selectOperativeType.cfAmount
      }
    } else {
      selectOperative = {
        ...selectOperative,
        dfUnit: value,
        dfAmount: calculateByPercent(operativePrice, selectOperativeType.dfAmount),
        cfAmount: calculateByPercent(operativePrice, selectOperativeType.cfAmount)
      }
    }
    operativeType.templateDoctorFeeOperativeTypes[typeIndex].templateDoctorFeeOperatives[index] = selectOperative
    setTemplateOperatives(operativeType)
  }

  const onChangeValueLabAmount = (typeIndex: number, index: number, value: number) => {
    const val = value >= 100 ? 100 : value
    const operativeType = _.merge({}, templateOperatives)
    let selectOperative = _.merge({}, operativeType.templateDoctorFeeOperativeTypes[typeIndex].templateDoctorFeeOperatives[index])
    selectOperative = { ...selectOperative, labDoctorAmount: val, labClinicAmount: 100 - val }
    operativeType.templateDoctorFeeOperativeTypes[typeIndex].templateDoctorFeeOperatives[index] = selectOperative
    setTemplateOperatives(operativeType)
  }

  useEffect(() => {
    loadTemplateOperative(templateDoctorFeeId)
  }, [])

  const headCells = [
    { id: 'message', disablePadding: false, label: t('') },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.CATEGORY') },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.PRICE'), align: 'right' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.DF_DOCTOR'), align: 'center' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.DF_BRANCH'), align: 'center' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.UNIT'), align: 'center' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.LAB_DOCTOR'), align: 'center' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.LAB_BRANCH'), align: 'center' }
  ]

  return (
    <div className={`container-card h-100 overflow-hidden`}>
      <div className="d-flex flex-column h-100 overflow-auto">
        <HeaderLabel
          text={templateDoctorFeeId ? t(`TEMPLATE_DOCTOR_FEE.TITLE.EDIT_TEMPLATE_DEFAULT`) : t(`TEMPLATE_DOCTOR_FEE.TITLE.ADD_TEMPLATE_DEFAULT`)}
          navigator={{
            previousTo: routeName.templateDoctorFee,
            previousTitle: t(`TEMPLATE_DOCTOR_FEE.TITLE.DEFAULT`),
            currentTitle: templateDoctorFeeId ? t(`TEMPLATE_DOCTOR_FEE.TITLE.EDIT_TEMPLATE_DEFAULT`) : t(`TEMPLATE_DOCTOR_FEE.TITLE.ADD_TEMPLATE_DEFAULT`)
          }}
          goBack={routeName.templateDoctorFee}
          onSubmit={templateDoctorFeeId ? onSubmitEdit : onSubmit}
        />
        <Card className="border shadow-sm mx-4 mb-4 card">
          <Card.Body>
            <Grid container>
              <Grid item lg={6} className="w-100">
                <Box className="mt-2">
                  <InputTextField
                    key={'templateDoctorFeeName'}
                    name={'templateDoctorFeeName'}
                    label={t('TEMPLATE_DOCTOR_FEE.FORM.TEMPLATE_NAME')}
                    value={templateOperatives.templateDoctorFeeName}
                    onchange={handleChangeDefault}
                    required={true}
                    helperText={errorMessage.EMPTY_DF_NAME || ''}
                  />
                </Box>
              </Grid>
              <Grid item className="w-100">
                <Box className="mt-3">
                  <InputCheckbox label={t('TEMPLATE_DOCTOR_FEE.FORM.USE_AS_DEFAULT_BRANCH')} name={'isMain'} checked={templateOperatives.isMain === '1' ? true : false} value={templateOperatives.isMain} onChange={handleChangeDefault} />
                </Box>
              </Grid>
              <Grid item className="w-100">
                <Box className="d-flex align-items-center mt-4">
                  <Typography className="mr-2" sx={{ fontSize: '18px', fontWeight: 600 }}>
                    {t('TEMPLATE_DOCTOR_FEE.TITLE.MANAGE')}
                  </Typography>
                  <ButtonInfoSimple text={t('TEMPLATE_DOCTOR_FEE.TITLE.TOOLTIP')} />
                </Box>
                <Box className="pl-md-3">
                  <Grid container spacing={2} className="mt-0">
                    <Grid item xs={2} lg={1} className="w-100">
                      <Typography className="pt-sm-2">{t('TEMPLATE_DOCTOR_FEE.FORM.DF_VALUE')}</Typography>
                    </Grid>
                    <Grid item xs={10} lg={11} className="w-100">
                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={3} lg={2} className="w-100">
                          <InputTextField
                            key={'amount'}
                            name={'amount'}
                            label={t('TEMPLATE_DOCTOR_FEE.FORM.DOCTOR_FEE_AMOUNT')}
                            value={templateOperatives.amount}
                            onchange={handleChangeDefault}
                            required={true}
                            hideControl
                            helperText={errorMessage.EMPTY_DF_AMOUNT || ''}
                          />
                        </Grid>
                        <Grid item xs={4} sm={3} lg={2} className="w-100 pr-sm-2">
                          <BasicSelect
                            name="defaultUnit"
                            labelId="lb-defaultUnit"
                            selectId="sl-defaultUnit"
                            label=""
                            value={DF_UNIT.PERCENT}
                            options={_.map(units, (unit: string, index: number) => {
                              return (
                                <MenuItem key={index} value={unit}>
                                  {unit === DF_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                                </MenuItem>
                              )
                            })}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={4} sm={3} lg={2} className="w-100">
                          <InputTextField key={'templateClinicFeeAmount'} name={'templateClinicFeeAmount'} label={t('TEMPLATE_DOCTOR_FEE.FORM.CLINIC_FEE_AMOUNT')} value={100 - templateOperatives.amount} disabled />
                        </Grid>
                        <Grid item xs={12} sm={3} className="w-100 pl-md-4">
                          <ButtonCustom textButton={t('TEMPLATE_DOCTOR_FEE.FORM.BUTTON_UPDATE_VALUE')} onClick={onUpdateDefaultDF} style={{ margin: 0 }} btnStyle={{ fontSize: 16, height: '43px' }} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="mt-0">
                    <Grid item xs={2} lg={1} className="w-100">
                      <Typography className="pt-sm-2">{t('TEMPLATE_DOCTOR_FEE.FORM.LAB_VALUE')}</Typography>
                    </Grid>
                    <Grid item xs={10} lg={11} className="w-100">
                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={3} lg={2} className="w-100">
                          <InputTextField
                            key={'labDoctorAmount'}
                            name={'labDoctorAmount'}
                            label={t('TEMPLATE_DOCTOR_FEE.FORM.DOCTOR_FEE_AMOUNT')}
                            value={templateOperatives.labDoctorAmount}
                            onchange={handleChangeDefault}
                            required={true}
                            hideControl
                            helperText={errorMessage.EMPTY_LAB_AMOUNT || ''}
                          />
                        </Grid>
                        <Grid item xs={4} sm={3} lg={2} className="w-100 pr-sm-2">
                          <BasicSelect
                            name="defaultUnit"
                            labelId="lb-defaultUnit"
                            selectId="sl-defaultUnit"
                            label=""
                            value={DF_UNIT.PERCENT}
                            options={_.map(units, (unit: string, index: number) => {
                              return (
                                <MenuItem key={index} value={unit}>
                                  {unit === DF_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                                </MenuItem>
                              )
                            })}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={4} sm={3} lg={2} className="w-100">
                          <InputTextField key={'templateClinicFeeLab'} name={'templateClinicFeeLab'} label={t('TEMPLATE_DOCTOR_FEE.FORM.CLINIC_FEE_AMOUNT')} value={100 - templateOperatives.labDoctorAmount} disabled />
                        </Grid>
                        <Grid item xs={12} sm={3} className="w-100 pl-md-4">
                          <ButtonCustom textButton={t('TEMPLATE_DOCTOR_FEE.FORM.BUTTON_UPDATE_VALUE')} onClick={onUpdateDefaultLab} style={{ margin: 0 }} btnStyle={{ fontSize: 16, height: '43px' }} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
            <UseStyled.ResponsiveTable section={'default'}>
              <TableCustom
                page={0}
                pageLimit={0}
                sortType={'ASC'}
                sortBy={''}
                rowCount={0}
                textEmptyData={t('REGISTER.NO_ITEM')}
                onSort={() => {
                  return
                }}
                setPageLimit={() => {
                  return
                }}
                setPage={() => {
                  return
                }}
                headCells={headCells}
                rowsData={_.map(operativeTypes, (temp, index) => {
                  return (
                    <RenderData
                      key={index}
                      no={index}
                      row={temp}
                      headCells={headCells}
                      units={units}
                      templateOperative={templateOperatives?.templateDoctorFeeOperativeTypes[index]}
                      onChangeValueOperativeType={onChangeValueOperativeType}
                      onChangeValueOperative={onChangeValueOperative}
                    />
                  )
                })}
                hidePagination
                tableFixedWidth
              />
            </UseStyled.ResponsiveTable>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

function RenderData(props: any) {
  const { t } = useTranslation()
  const { row, no, headCells, units, templateOperative } = props
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow>
        <TableCell align="center">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} className="p-0" sx={{ backgroundColor: 'transparent !important' }}>
            {open ? <FontAwesomeIcon icon={faCaretDown} style={{ color: colors.textPrimary, fontSize: 20, transform: 'rotate(180deg)' }} /> : <FontAwesomeIcon icon={faCaretDown} style={{ color: colors.textPrimary, fontSize: 20 }} />}
          </IconButton>
        </TableCell>
        <TableCell>{row.operativeTypeName}</TableCell>
        <TableCell></TableCell>
        <TableCell>
          <InputTextField
            key={`dfAmount`}
            name={`dfAmount`}
            label={''}
            value={templateOperative?.dfAmount}
            onchange={(e) => {
              if (props.onChangeValueOperativeType) props.onChangeValueOperativeType(e, no)
            }}
            disabled={open}
            hideControl
          />
        </TableCell>
        <TableCell>
          <InputTextField key={`cfAmount`} name={`cfAmount`} label={''} value={100 - templateOperative?.dfAmount} disabled type="number" hideControl />
        </TableCell>
        <TableCell>
          <BasicSelect
            key={`defaultUnit`}
            labelId="lb-defaultUnit"
            selectId="sl-defaultUnit"
            label=""
            value={DF_UNIT.PERCENT}
            options={_.map(units, (unit: string, index: number) => {
              return (
                <MenuItem key={index} value={unit}>
                  {unit === DF_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                </MenuItem>
              )
            })}
            onchange={() => {
              return
            }}
            disabled
          />
        </TableCell>
        <TableCell>
          <InputTextField
            key={`labDoctorAmount`}
            name={`labDoctorAmount`}
            label={''}
            value={templateOperative?.labDoctorAmount}
            onchange={(e) => {
              if (props.onChangeValueOperativeType) props.onChangeValueOperativeType(e, no)
            }}
            disabled={open}
            hideControl
          />
        </TableCell>
        <TableCell>
          <InputTextField key={`labClinicAmount`} name={`labClinicAmount`} label={''} value={100 - templateOperative?.labDoctorAmount} disabled hideControl />
        </TableCell>
      </TableRow>
      <TableRow className="section-collapse">
        <TableCell colSpan={_.size(headCells)} className="p-0 border-0">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <UseStyled.ResponsiveTable section="collapse">
              <TableCustom
                page={0}
                pageLimit={0}
                sortType={'ASC'}
                sortBy={''}
                rowCount={0}
                textEmptyData={t('REGISTER.NO_ITEM')}
                onSort={() => {
                  return
                }}
                setPageLimit={() => {
                  return
                }}
                setPage={() => {
                  return
                }}
                headCells={headCells}
                rowsData={_.map(row.operatives, (opt, indexOpt) => {
                  return (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>{opt.operativeName}</TableCell>
                      <TableCell align="right">{numberFormat(opt.salePrice || 0)}</TableCell>
                      <TableCell>
                        <InputTextField
                          key={`dfAmount`}
                          name={`dfAmount`}
                          label={''}
                          value={templateOperative?.templateDoctorFeeOperatives[indexOpt]?.dfAmount}
                          onchange={(e) => {
                            if (props.onChangeValueOperative) props.onChangeValueOperative(e, no, indexOpt, opt.salePrice || 0)
                          }}
                          hideControl
                        />
                      </TableCell>
                      <TableCell>
                        <InputTextField key={`cfAmount`} name={`cfAmount`} label={''} value={templateOperative?.templateDoctorFeeOperatives[indexOpt]?.cfAmount} disabled type="number" hideControl />
                      </TableCell>
                      <TableCell>
                        <BasicSelect
                          key={`dfUnit`}
                          name={`dfUnit`}
                          labelId="lb-defaultUnit"
                          selectId="sl-defaultUnit"
                          label=""
                          value={templateOperative?.templateDoctorFeeOperatives[indexOpt]?.dfUnit}
                          options={_.map(units, (unit: string, index: number) => {
                            return (
                              <MenuItem key={index} value={unit}>
                                {unit === DF_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                              </MenuItem>
                            )
                          })}
                          onchange={(e) => {
                            if (props.onChangeValueOperative) props.onChangeValueOperative(e, no, indexOpt, opt.salePrice || 0)
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <InputTextField
                          key={`labDoctorAmount`}
                          name={`labDoctorAmount`}
                          label={''}
                          value={templateOperative?.templateDoctorFeeOperatives[indexOpt]?.labDoctorAmount}
                          onchange={(e) => {
                            if (props.onChangeValueOperative) props.onChangeValueOperative(e, no, indexOpt, opt.salePrice || 0)
                          }}
                          hideControl
                        />
                      </TableCell>
                      <TableCell>
                        <InputTextField key={`labClinicAmount`} name={`labClinicAmount`} label={''} value={templateOperative?.templateDoctorFeeOperatives[indexOpt]?.labClinicAmount} disabled type="number" hideControl />
                      </TableCell>
                    </TableRow>
                  )
                })}
                hidePagination
                tableFixedWidth
              />
            </UseStyled.ResponsiveTable>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
