import { useTranslation } from "react-i18next";
import { DateObject } from "react-multi-date-picker"
import es from 'date-fns/locale/es';

export const thai_format_language = {
    name: 'thai_th',
    months: [
        ['มกราคม', 'ม.ค.'],
        ['กุมภาพันธ์', 'ก.พ.'],
        ['มีนาคม', 'มี.ค.'],
        ['เมษายน', 'เม.ย.	'],
        ['พฤษภาคม', 'พ.ค.'],
        ['มิถุนายน', 'มิ.ย.'],
        ['กรกฎาคม', 'ก.ค.'],
        ['สิงหาคม', 'ส.ค.'],
        ['กันยายน', 'ก.ย.'],
        ['ตุลาคม', 'ต.ค.'],
        ['พฤศจิกายน', 'พ.ย.'],
        ['ธันวาคม', 'ธ.ค.'],
    ],
    weekDays: [
        ['วันเสาร์', 'ส'],
        ['วันอาทิตย์', 'อา'],
        ['วันจันทร์', 'จ'],
        ['วันอังคาร', 'อ'],
        ['วันพุธ', 'พ'],
        ['วันพฤหัส', 'พฤ'],
        ['วันศุกร์', 'ศ'],
    ],
    digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
    meridiems: [
        ['ก่อนเที่ยง', 'เอเอ็ม'],
        ['หลังเที่ยง', 'พีเอ็ม'],
    ],
}
export const english_format_language = {
    name: 'english_en',
    months: [
        ['January', 'JAN'],
        ['February', 'FEB'],
        ['March', 'MAR'],
        ['April', 'APR'],
        ['May', 'MAY'],
        ['June', 'JUN'],
        ['July', 'JUL'],
        ['August', 'AUG'],
        ['September', 'SEP'],
        ['October', 'OCT'],
        ['November', 'NOV'],
        ['December', 'DEC'],
    ],
    weekDays: [
        ['Saturay', 'SAT'],
        ['Sunday', 'SUN'],
        ['Monday', 'MON'],
        ['Tuesday', 'TUE'],
        ['Wednesday', 'WED'],
        ['Thursday', 'THU'],
        ['Friday', 'FRI'],
    ],
    digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
    meridiems: [
        ['AM.', 'AM.'],
        ['PM.', 'PM.'],
    ],
}
export const thai_calendar: any = {
    name: "thai",
    startYear: 1,
    yearLength: 365,
    epoch: 1523097,
    century: 24,
    weekStartDayIndex: 1,
    getYearLength(year: number) {
        year = year - 543
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 366 : 365
    },
    getMonthLengths(isLeap: boolean) {
        return [31, isLeap ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    },
    isLeap(year: number) {
        year = year - 543
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    },
    getLeaps(currentYear: number) {
        if (currentYear === 0) return;

        let year = currentYear > 0 ? 1 : -1;

        const leaps = [],
            condition = () =>
                currentYear > 0 ? year <= currentYear : currentYear <= year,
            increase = () => (currentYear > 0 ? year++ : year--);

        while (condition()) {
            if (this.isLeap(year)) leaps.push(year);

            increase();
        }

        return leaps;
    },
    getDayOfYear({ year, month, day }: DateObject) {
        const monthLengths = this.getMonthLengths(this.isLeap(year));
        for (let i = 0; i < month.index; i++) {
            day += monthLengths[i];
        }
        const newYear = this.isLeap(year - 1) ? 1 : 0
        return day + newYear;
    },
    getAllDays(date: DateObject) {
        const { year } = date;
        return (
            this.yearLength * (year - 1) +
            this.leapsLength(year) +
            this.getDayOfYear(date)
        );
    },
    leapsLength(year: number) {
        return (
            (((year - 1) / 4) | 0) +
            (-((year - 1) / 100) | 0) +
            (((year - 1) / 400) | 0)
        );
    },
    guessYear(days: number, currentYear: number) {
        const year = ~~(days / 365.24);
        return year + (currentYear > 0 ? 1 : -1)
    },
};
export const english_calendar: any = {
    name: "english",
    startYear: 1,
    yearLength: 365,
    epoch: 1523097,
    century: 24,
    weekStartDayIndex: 1,
    getYearLength(year: number) {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 366 : 365
    },
    getMonthLengths(isLeap: boolean) {
        return [31, isLeap ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    },
    isLeap(year: number) {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    },
    getLeaps(currentYear: number) {
        if (currentYear === 0) return;

        let year = currentYear > 0 ? 1 : -1;

        const leaps = [],
            condition = () =>
                currentYear > 0 ? year <= currentYear : currentYear <= year,
            increase = () => (currentYear > 0 ? year++ : year--);

        while (condition()) {
            if (this.isLeap(year)) leaps.push(year);

            increase();
        }

        return leaps;
    },
    getDayOfYear({ year, month, day }: DateObject) {
        const monthLengths = this.getMonthLengths(this.isLeap(year));
        for (let i = 0; i < month.index; i++) {
            day += monthLengths[i];
        }
        const newYear = this.isLeap(year - 1) ? 1 : 0
        return day + newYear;
    },
    getAllDays(date: DateObject) {
        const { year } = date;
        return (
            this.yearLength * (year - 1) +
            this.leapsLength(year) +
            this.getDayOfYear(date)
        );
    },
    leapsLength(year: number) {
        return (
            (((year - 1) / 4) | 0) +
            (-((year - 1) / 100) | 0) +
            (((year - 1) / 400) | 0)
        );
    },
    guessYear(days: number, currentYear: number) {
        const year = ~~(days / 365.24);
        return year + (currentYear > 0 ? 1 : -1)
    },
};


const useCalendarFormat = () => {
    const { t, i18n } = useTranslation()

    let exportCalendat: any = {
        calendar: { ...thai_calendar },
        format_language: { ...thai_format_language }
    }

    if (i18n.language !== 'th') {
        exportCalendat = {
            calendar: 'es',
            format_language: 'es'
        }
    }

    return exportCalendat
}

export default useCalendarFormat