import { makeStyles } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'

export const DivImg = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    cursor: 'pointer',
    border: `1px solid ${colors.lightGray}`
  },
  '&.active': {
    border: `5px solid ${colors.themeSecondColor}`,
    [theme.breakpoints.down('lg')]: {
      borderWidth: 3
    }
  }
}))

export const DivUpload = styled(DivImg)(({ theme }) => ({
  backgroundImage: `repeating-linear-gradient(0deg, ${colors.lightGray}, ${colors.lightGray} 10px, transparent 10px, transparent 20px), 
    repeating-linear-gradient(90deg, ${colors.lightGray}, ${colors.lightGray} 10px, transparent 10px, transparent 20px), 
    repeating-linear-gradient(180deg, ${colors.lightGray}, ${colors.lightGray} 10px, transparent 10px, transparent 20px), 
    repeating-linear-gradient(270deg, ${colors.lightGray}, ${colors.lightGray} 10px, transparent 10px, transparent 20px) `,
  backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
  backgroundSize: '1px 100%, 100% 1px, 1px 100% , 100% 1px',
  backgroundRepeat: 'no-repeat',
  cursor: 'pointer',
  width: '100%',
  '.upload-image': {
    img: {
      width: 56,
      [theme.breakpoints.down('xs')]: {
        width: 32
      }
    },
    p: {
      fontSize: 14,
      color: colors.textExtraLightGray,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12
      }
    }
  }
}))

export const Body = styled('div')(({ theme }) => ({
  margin: '1rem 0 .5rem',
  [theme.breakpoints.down('sm')]: {
    marginBottom: 0
  }
}))
export const Header = styled('div')(({ theme }) => ({
  margin: '1rem 0 .5rem',
  [theme.breakpoints.down('sm')]: {
    marginBottom: 0
  }
}))
export const CardImg = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  canvas: {
    maxWidth: '100% !important',
    objectFit: 'contain'
  },
  '.btn-delete': {
    position: 'absolute',
    cursor: 'pointer',
    right: 8,
    bottom: 8,
    zIndex: 5,
    [theme.breakpoints.between('xs', 'sm')]: {
      img: {
        width: '30px !important'
      }
    }
  },
  '.btn-select': {
    position: 'absolute',
    cursor: 'pointer',
    right: 0,
    top: 0,
    zIndex: 5,
  }
}))


export const EmptyItem = styled('section')(({ theme }) => ({
  margin: '0 0.25rem',
  height: '100%'
}))
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  }
}))

export default useStyles
