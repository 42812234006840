import IconProps from './iconProps'
import { colors } from 'constants/theme'

export default function XRay(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g data-name="Group 8644">
        <g data-name="tooth (1)">
          <g data-name="Group 5589">
            <g data-name="Group 5588">
              <path data-name="Path 6265" d="M1.114 2.192V27.75h18.877l4.5-4.75V2.192L23.409.794H2.357z" style={{ fill: '#fff' }} transform="translate(3 2)" />
              <path
                data-name="Path 5737"
                d="M53.939 0h-20.39A2.552 2.552 0 0 0 31 2.549v23.9A2.552 2.552 0 0 0 33.549 29h16.992a.86.86 0 0 0 .6-.249l5.1-5.1a.856.856 0 0 0 .248-.6V2.549A2.552 2.552 0 0 0 53.939 0zm-2.548 26.1v-2.2h2.2zm3.4-3.9h-4.25a.85.85 0 0 0-.85.85v4.25H33.549a.851.851 0 0 1-.85-.85V2.549a.851.851 0 0 1 .85-.85h20.39a.851.851 0 0 1 .85.85z"
                transform="translate(-28 2)"
                style={{ fill: props.color || colors.themeSecondColor }}
              />
            </g>
          </g>
          <g data-name="Group 5591">
            <g data-name="Group 5590">
              <path data-name="Path 5738" d="M95.567 422h-3.654a.913.913 0 1 0 0 1.827h3.654a.913.913 0 1 0 0-1.827z" transform="translate(-84.346 -396.481)" style={{ fill: props.color || colors.themeSecondColor }} />
            </g>
          </g>
          <g data-name="Group 5593">
            <g data-name="Group 5592">
              <path data-name="Path 5739" d="M213.74 422h-1.827a.913.913 0 1 0 0 1.827h1.827a.913.913 0 1 0 0-1.827z" transform="translate(-197.923 -396.481)" style={{ fill: props.color || colors.themeSecondColor }} />
            </g>
          </g>
        </g>
        <path
          data-name="Path 5740"
          d="M247.351 159.263a10.974 10.974 0 0 0 1.528-5.793c-.046-6.164-5.116-6.453-6.564-4.667-.247.3-.686.428-.893.088-1.024-1.672-6.472-1.338-6.426 4.714a10.931 10.931 0 0 0 1.615 5.76.733.733 0 0 1 .09.543c-.364 1.682-1.1 4.215 1.006 4.194 2.249-.022 1.917-2.863 4.289-2.886 2.2-.021 2.319 2.742 4.188 2.723 1.753-.017 1.408-2.5 1.077-4.15a.734.734 0 0 1 .09-.526z"
          transform="translate(-226.227 -141.084)"
          style={{ stroke: props.color || colors.themeSecondColor, strokeMiterlimit: 10, strokeWidth: '1.5px', fill: '#fff' }}
        />
      </g>
      <path data-name="Rectangle 3865" style={{ fill: 'none' }} d="M0 0h32v32H0z" />
    </svg>
  )
}
