import { Box } from '@mui/material'
import ManageWebApi from 'api/application/manageWeb'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Loading from 'component/Loading'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import ButtonCustom from 'component/Button/ButtonCustom'
import BtnAdd from 'assets/button/BtnAdd'
import ServiceList from './ServiceList'
import ServiceForm from './ServiceForm'
import { ServiceProps } from 'api/application/manageWeb-interface'
import { swalActive, swalDelete } from 'component/Alert/Swal'


const Service = () => {
    const { t } = useTranslation()
    const t_SERVICE = "MANAGE_WEB.SERVICE"

    const [load, setLoad] = useState<boolean>(true)

    const [dataServiceAll, setDataServiceAll] = useState<any>([])

    const [dataEdit, setDataEdit] = useState<any>({})
    const [dataEditId, setDataEditId] = useState<any>({})
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [statusDel, setStatusDel] = useState<boolean>(false)
    const [statusAdd, setStatusAdd] = useState<boolean>(false)

    const loadData = async () => {
        setLoad(true)
        await ManageWebApi.getService().then((res) => {
            setDataServiceAll(res.data)
        }).catch(() => {
            return
        }).finally(() => {
            setLoad(false)
        })
    }

    useEffect(() => {
        loadData()
    }, [])

    const onEdit = (status: boolean, item?: any) => {
        if (item) {
            setDataEdit(item)
            setDataEditId(item)
        }
        if (!status) {
            setDataEdit({})
            setDataEditId({})
        }
        setIsEdit(status)
    }

    const onSave = async () => {
        setStatusAdd(true)
        const id = dataEditId?.provideServiceId || 0
        const newPayload: ServiceProps = dataEdit
        if (id === 0) {
            const res = await ManageWebApi.createService(newPayload)
            if (res === "pass") {
                onEdit(false)
                loadData()
                notiSuccess(t(`${t_SERVICE}.SAVE_SUC`))
            } else {
                notiError(t(`${t_SERVICE}.SAVE_ERROR`))
            }
        } else {
            const res = await ManageWebApi.updateService(id, newPayload)
            if (res === "pass") {
                onEdit(false)
                loadData()
                notiSuccess(t(`${t_SERVICE}.SAVE_SUC`))
            } else {
                notiError(t(`${t_SERVICE}.SAVE_ERROR`))
            }
        }
        setStatusAdd(false)
    }

    const onDel = async (id: number, nameOp: string) => {
        setStatusDel(true)
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t(t_SERVICE + '.NOTI_DEL')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t(t_SERVICE + '.NOTI_DEL_START')}${nameOp}${t(t_SERVICE + '.NOTI_DEL_END')}</p>`,
            async (res: any) => {
                if (res) {
                    if (id !== 0) {
                        const res = await ManageWebApi.delService(id)
                        if (res === "pass") {
                            notiSuccess(t(`${t_SERVICE}.DEL_SUC`))
                        } else {
                            notiError(t(`${t_SERVICE}.SAVE_ERROR`))
                        }
                    } else {
                        notiError(t(`${t_SERVICE}.SAVE_ERROR`))
                    }
                    loadData()
                }
            }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
        )
        setStatusDel(false)
    }

    return (
        <>
            <Loading show={load} />
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ fontSize: '20px', fontWeight: "600" }}>
                        {t(`${t_SERVICE}.TITLE`)}
                    </Box>
                    {!isEdit &&
                        <Box>
                            <ButtonCustom
                                startIcon={<BtnAdd width='20px' height='20px' />}
                                textButton={t(`${t_SERVICE}.BTN_CREATE`)}
                                onClick={() => { onEdit(true) }}
                            />
                        </Box>
                        ||
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ButtonCustom
                                style={{ marginRight: '4px' }}
                                variant={'outlined'}
                                textButton={t(`${t_SERVICE}.BTN_CANCEL`)}
                                onClick={() => { onEdit(false) }}
                            />
                            <ButtonCustom
                                textButton={t(`${t_SERVICE}.BTN_SAVE`)}
                                disabled={statusAdd}
                                onClick={() => { onSave() }}
                            />
                        </Box>
                    }
                </Box>
                <Box sx={{ marginTop: '32px', height: '100%', overflow: 'auto' }}>
                    {!isEdit &&
                        <ServiceList dataServiceAll={dataServiceAll} onEdit={onEdit} onDel={onDel} reLoad={loadData} />
                        ||
                        <ServiceForm dataEdit={dataEdit} setDataEdit={setDataEdit} />
                    }
                </Box>
            </Box>
        </>
    )
}

export default Service