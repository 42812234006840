import { useCallback, useEffect, useState } from 'react'
import { ExportProps } from "features/report/report-constants";
import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import _ from 'lodash'
import * as UseStyled from 'features/report/useStyled'
import { useTranslation } from 'react-i18next'
import { toBuddhistYear } from "utils/app.utils"
import moment from 'moment'
import { SORT_TYPE } from "constants/common";
import { TableSortLabel } from "@mui/material";

import { UseSnCode } from 'utils/useSnCode'
export interface FcComponent {
    filter: any
    page: number
    pageLimit: number
    rowCount: number
    sortBy: string
    sortType: string
    data: any
    onRequestSort: (sortBy: string, sortType: string) => void
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
    setSortType: (val: SORT_TYPE) => void
    setSortBy: (val: string) => void
    componentRef: any
    exportData: any
}

export default function PatientList(props: FcComponent) {
    const { t } = useTranslation()

    const [snType, setSnType] = useState(false);

    const handleSnCode = useCallback(() => {
        const fetchData = async () => {
            const sn = await UseSnCode();
            setSnType(sn);
        };

        fetchData();

    }, [snType]);
    useEffect(() => {
        handleSnCode()
    }, [])
    const headCells = [
        { id: 'patient_cases.createdAt', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_RECEIVE_SERVICE.DATE', align: 'left', class: 'text-nowrap', sortable: true },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_RECEIVE_SERVICE.LASTED', align: 'left', class: 'text-nowrap' },
        { id: 'operative_types.operativeTypeName', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_RECEIVE_SERVICE.OPERATIVE_TYPE', align: 'left', class: 'text-nowrap', sortable: true },
        { id: 'operatives.operativeName', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_RECEIVE_SERVICE.OPERATIVE', align: 'left', class: 'text-nowrap', sortable: true },
        { id: 'patient_cases.cnNumber', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_RECEIVE_SERVICE.CN_NUMBER', align: 'left', class: 'text-nowrap', sortable: true },
        { id: 'patient_cases.branchCnNumber', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_RECEIVE_SERVICE.BRANCH_CN_NUMBER', align: 'left', class: 'text-nowrap', sortable: true },
        { id: 'patient_cases.snCode', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_RECEIVE_SERVICE.SN_CODE', align: 'left', class: 'text-nowrap', sortable: true },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_RECEIVE_SERVICE.PATIENT_NAME', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_RECEIVE_SERVICE.NICKNAME', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_RECEIVE_SERVICE.DATE_OF_BIRTH', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_RECEIVE_SERVICE.AGE', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_RECEIVE_SERVICE.PHONE', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_RECEIVE_SERVICE.LINE', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_RECEIVE_SERVICE.EMAIL', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_RECEIVE_SERVICE.FACEBOOK', align: 'left', class: 'text-nowrap' }
    ]
    if (!snType) {
        headCells.splice(6, 1)
    }
    const onRequestSort = (sortByVal: string, sortTypeVal: SORT_TYPE) => {
        props.setSortType(sortTypeVal)
        props.setSortBy(sortByVal)
        props.handleChangePage(1)
    }

    const renderData = (data: any, no: number, exportData: boolean) => {
        const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
        function checkNumber(text: any) {
            if (!text) return false
            const pattern = /^([a-z0-9])+$/i;
            return text.trim().match(pattern);
        }
        function checkNull(text: any) {
            if (!text) return '-'
            return text.trim()
        }

        const objRenderData = {
            key: num.toString(),
            id: no.toString(),
            obj: data,
            columns: [
                { option: 'TEXT', align: 'left', label: toBuddhistYear(moment(data.createdAt), 'DD/MM/YYYY') || '-', class: 'text-nowrap' },
                { option: 'TEXT', align: 'left', label: data.timeElapsed, class: 'text-nowrap' },
                { option: 'TEXT', align: 'left', label: data.operativeTypeName, class: 'text-nowrap' },
                { option: 'TEXT', align: 'left', label: data.operativeName, class: 'text-nowrap ' },
                { option: 'TEXT', align: 'left', label: data.cnNumber, class: 'text-nowrap' },
                { option: 'TEXT', align: 'left', label: data.branchCnNumber ?? '-', class: 'text-nowrap' },
                { option: 'TEXT', align: 'left', label: data.snCode ?? '-', class: 'text-nowrap' },
                { option: 'TEXT', align: 'left', label: data.fullname ?? '-', class: 'text-nowrap' },
                { option: 'TEXT', align: 'center', label: data.displayName ?? '-', class: 'text-nowrap' },
                { option: 'TEXT', align: 'center', label: data.dateOfBirth ? toBuddhistYear(moment(data.dateOfBirth), 'DD/MM/YYYY') || '-' : '-', class: 'text-nowrap' },

                { option: 'TEXT', align: 'left', label: data.age ?? '-', class: 'text-nowrap' },
                { option: 'TEXT', align: 'left', format: checkNumber(data.phone) ? "TEXT" : undefined, label: checkNull(data.phone), class: 'text-nowrap' },
                { option: 'TEXT', align: 'left', label: data.line ?? '-', class: 'text-nowrap' },
                { option: 'TEXT', align: 'left', label: data.email ?? '-', class: 'text-nowrap' },
                { option: 'TEXT', align: 'left', label: data.facebook ?? '-', class: 'text-nowrap' }
            ]
        }
        if (!snType) {
            objRenderData.columns.splice(6, 1)
        }
        return (
            <>
                {exportData === true && <TableRowExport {...objRenderData} /> ||
                    <TableRowCommon  {...objRenderData} />
                }
            </>
        )
    }

    return (
        <>
            <div className="print-show-disable">
                <UseStyled.ResponsiveReportTable>
                    <TableCustom
                        page={props.page}
                        pageLimit={props.pageLimit}
                        sortType={props.sortType}
                        sortBy={props.sortBy}
                        rowCount={props.rowCount}
                        onSort={onRequestSort}
                        setPageLimit={props.handleChangeRowsPerPage}
                        setPage={props.handleChangePage}
                        headCells={headCells}
                        rowsData={
                            _.map(props.data, (d: any, index: number) => {
                                return renderData(d, index, false)
                            })
                        }
                        customScroll
                    />
                </UseStyled.ResponsiveReportTable >
            </div>
            <div className="print-show" ref={props.componentRef}>
                <PatientExport
                    header={
                        <UseStyled.Styles className="pt-3">
                            <table style={{ border: 'none', borderCollapse: 'collapse' }}>
                                <tr>
                                    <td style={{ border: 'none', textAlign: 'left', fontSize: '14px' }}>
                                        {t(`REPORT.REPORT_NAME.PATIENT_INFORMATION`)}

                                    </td>
                                </tr>
                                <tr>
                                    {props.filter().map((filter: any, index: number) => (
                                        <TableSortLabel key={index} >
                                            <td className='mr-2' style={{ textAlign: 'left', fontSize: 10, fontWeight: 800 }}>{t(filter.label)} : </td>
                                            <td style={{ textAlign: 'left', fontSize: 10, fontWeight: 500 }}>{filter.value}</td>
                                        </TableSortLabel >
                                    ))}
                                </tr>
                                <tr></tr>
                            </table>
                        </UseStyled.Styles>
                    }
                    headCells={headCells}
                    rowsData={
                        _.map(props.exportData, (d: any, index: number) => {
                            return renderData(d, index, true)
                        })
                    } />
            </div>
        </>

    )
}

export function PatientExport(props: ExportProps) {
    const { t } = useTranslation()
    const head = props.headCells
    const headCells = head.filter((headCell: any) => headCell.id !== 'action')
    const { header } = props
    return (
        <UseStyled.Styles className="pt-3">
            {header}
            <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                <thead>
                    <tr>
                        {_.map(headCells, (val: any, index: number) => {
                            return (
                                < td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, width: '100%' }}> {t(val.labelKey)}</td>
                            )
                        })}
                    </tr>
                </thead>
                <tbody >
                    {props.rowsData}
                    {!props.rowsData.length && (
                        <td colSpan={headCells.length} style={{ fontSize: 10, }}>
                            {t('REPORT.TITLE.NO_DATA')}
                        </td>
                    )}
                </tbody>
            </table>
        </UseStyled.Styles >
    )
}