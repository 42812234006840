import { useCallback, useEffect, useRef, useState } from 'react'
import Webcam from 'react-webcam'
import Box from '@mui/material/Box'
import { Dropdown } from 'react-bootstrap'
import Stack from '@mui/material/Stack'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Button, IconButton, MenuItem, Tooltip } from '@mui/material'
import { PhotoCamera } from '@mui/icons-material'
import { colors } from 'constants/theme'
import $ from 'jquery'
import CanvasDraw from 'react-canvas-draw'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import 'features/counter/register/style.css'
import * as UseStyled from 'features/counter/register/usedStyles'

/** API */
import { routeName } from 'routes/routes-name'
import { fileUrl } from 'api/api'
import RegisterApi, { ValidatePatientInfoInterface } from 'api/counter/register.api'
import CareerApi from 'api/setting/default-users/career.api'
import SendExaminationApi from 'api/master/sendExamination.api'
import ClinicApi from 'api/master/clinic.api'
import PaymentApi from 'api/counter/payment/payment.api'

/** UTILS || SLICE */
import * as modalSlice from 'app/slice/modal.slice'
import { setPatient as setValuePatient, editPatient, setForm, setReloadCareers, formRegister } from 'app/slice/register/register.slice'
import { numberFormat, checkPackage, getBranch, getClinicInfo } from 'utils/app.utils'
import { exportOPD, exportOPDCover, exportOPDEmpty, exportSSO } from 'component/Print/exportPdf'
import { PACKAGE_ADDONS } from 'constants/package'

/** CONSTANT */
import { imgs, icons, Icons } from 'constants/images'
import { MEMBERSHIP_RANKING, KEY_STORAGE } from 'constants/common'

/** COMPONENT */
import { notiError, notiSuccess } from 'component/notifications/notifications'
import SearchPatients from 'component/SearchPatients'
import ButtonCustom from 'component/Button/ButtonCustom'
import ButtonSubmit from 'component/Button/ButtonSubmit'
import FormManagement from 'features/counter/register/form-management'
import TreatmentHistory from 'component/Register/TreatmentHistory'
import FormRegister from 'features/counter/register/form-register'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import FormRetrieve from 'features/counter/register/form-retrieve'
import FormCareer from 'features/counter/register/form-career'
import SendExamination from 'component/Register/SendExamination'
import FormUploadTreatment from 'component/Register/FormUploadTreatment'
import SpecialInfo from 'component/Register/SpecialInfo'
import { swalCustom } from 'component/Alert/Swal'
import Loading from 'component/Loading'
import PopupInsertImg from './popupInsertImg'
import SignaturePopup from 'features/counter/register/popupAddSign'
import { selectMe } from 'app/slice/user.slice'
import SSO from 'component/Treatments/SSO'
import { TreatmentStateInterface, treatment } from 'app/slice/treatment.slice'
import { showModal } from 'app/slice/modal.slice'
import ReferralEaxmination from 'component/Treatments/Referral-examination'
import ReferralDental from 'component/Treatments/Referral-dental'
import TreatmentHistoryApi from 'api/dentists/treatment-history.api'
import HomeApi from 'api/home/home.api'
import AboutUs from 'api/admin/clinic-manage/about.api'
// import ManagePrint from 'component/Print/ManagePrint/ManagePrint'

export interface RankInterface {
  text: string
  key: string
  class: string
}

export type NewImageType = {
  base64: string
  type: string
  fileExtension: string
  fileSize: string
  fileName: string
}

export default function Register() {
  const initStateErrorMessage = {
    CN_NUMBER: ``,
    BRANCH_CN_NUMBER: ``,
    PREFIX_ID: ``,
    FIRSTNAME: ``,
    LASTNAME: ``,
    PHONE: ``,
    CAREER_NAME: ``,
    ID_CARD: ``,
    DATE_OF_BIRTH: ``,
    IS_DUPLICATE_CAREER_NAME: ``,
    CAREER_NAME_STRING_BASE: ``,
    CAREER_NAME_STRING_EMPTY: ``,
    CAREER_NAME_MAXIMUM_LENGTH: ``,
    CAREER_NAME_ANY_REQUIRED: ``,
    CAREER_NAME_EN: ``,
    IS_DUPLICATE_CAREER_NAME_EN: ``,
    CAREER_NAME_EN_STRING_BASE: ``,
    CAREER_NAME_EN_STRING_EMPTY: ``,
    CAREER_NAME_EN_MAXIMUM_LENGTH: ``,
    CAREER_NAME_EN_ANY_REQUIRED: ``
  }

  const dispatch = useDispatch()
  const location = useLocation()
  const { t } = useTranslation()
  const history = useHistory()
  const [patientId, setPatientId] = useState(0)

  const formRegisters = useSelector(formRegister)
  const user = useSelector(selectMe)
  const [branchInfo, setBranchInfo] = useState<any>()

  const webcamRef = useRef<Webcam>(null)

  const footerRef: any = useRef(null)
  const formPatient = useForm()
  const formModal = useForm()

  const [disabled, setDisabled] = useState(true)
  const [disabledAsNewPatient, setDisabledAsNewPatient] = useState(false)
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)
  const [errorMessageEmailTemp, setErrorMessageEmailTemp] = useState('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [configModal, setConfigModal]: any = useState({ title: '', size: '', modul: '', onReset: null, onSubmit: null, textBtnCancel: '', textBtnConfirm: null, alignFooter: '' })
  const [sendExam, setSendExam] = useState(false)
  const [uploadTreatment, setUploadTreatment] = useState(false)
  const [patient, setPatient]: any = useState([])
  const [patientFinance, setPatientFinance]: any = useState([])
  const [membershipRanking, setMembershipRanking] = useState({ key: '', text: 'RANKING MEMBER', class: '' })
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  const [branchCode, setBranchCode] = useState('')
  const [patientCase, setPatientCase] = useState([])
  const [loading, setLoading] = useState(false)
  const [treatmentRights, setTreatmentRights] = useState([])
  const [refPatient, setRefPatient] = useState([])

  /** BLOCK PAGE */
  // const { RouterPrompt, setBlockPage } = useBlockPageWarning()

  /** PROFILE */
  const [profile, setProfile] = useState({
    fileId: null,
    base64: '',
    fileExtension: '',
    fileSize: ''
  })
  const [imgData, setImgData]: any = useState()
  const [tempProfile, setTempProfile] = useState({
    fileId: null,
    base64: '',
    fileExtension: '',
    fileSize: ''
  })
  const [tempImgData, setTempImgData]: any = useState()

  /** POPUP */
  const [openpopup, setOpenpopup] = useState<boolean>(false)
  const [openWebcam, setOpenWebcam] = useState<boolean>(false)
  const [tempImageSrc, setTempImageSrc] = useState<any>(null)

  /** SIGNATUREPOPUP */
  const canvasRef: any = useRef()
  const [isopenPopup, setIsopenPopup] = useState<boolean>(false)
  const [fileName, setFileName] = useState<string>('')
  const [drawing, setDrawing] = useState<any>('')
  const [files, setFiles] = useState<any>('')
  const [uploadSignatureAttachments, setUploadSignatureAttachments] = useState<any>({
    fileId: null,
    base64: '',
    type: '',
    fileExtension: '',
    fileSize: ''
  })
  const [brushColor, setBrushColor] = useState<string>('#000000')
  const [fileSize, setFileSize] = useState<string>('')
  const [type, setType] = useState<string>('')

  const checkType: boolean = ['image/png', 'image/jpg', 'image/jpeg'].includes(type)
  const checkSize: boolean = Number(fileSize) < Number('2097152')
  const validateImageUpload: boolean = checkType && checkSize

  const [exportMedicalServices, setSSO] = useState(false)
  const [exportReferralExamination, setExportReferralExamination] = useState(false)
  const [exportReferralReferralDental, setExportReferralDental] = useState(false)
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const branchIdServicePoint = getBranch()

  const [popManagePrint, setPopManagePrint] = useState<boolean>(false)

  const [appointments, setAppointments] = useState<any>([])

  const loadBranchInfo = () => {
    AboutUs.detailClinic(Number(branchIdServicePoint))
      .then(({ data }) => {
        setBranchInfo(data)
      })
      .catch((e) => { return })
  }

  useEffect(() => {
    loadBranchInfo()
  }, [branchIdServicePoint])

  //** SIGNATURE */
  const { getRootProps: getRootSignatureProps, getInputProps: getInputSignatureProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: (acceptedFiles) => {
      uploadNewsImageSign(acceptedFiles)
      setFiles(URL.createObjectURL(acceptedFiles[0]))
    }
  })

  const renderCanvas = () => {
    if (canvasRef.current) {
      const base64 = canvasRef.current.canvasContainer.childNodes[1].toDataURL('image/jpg')
      let base64string: any = `${base64}`.split(',')
      setDrawing(base64)
      base64string = base64string[base64string.length - 1]

      setUploadSignatureAttachments({ ...uploadSignatureAttachments, base64: base64string, fileId: null, fileExtension: 'jpg', fileSize: '1MB', type: 'image/jpg' })
    }
  }
  const clearCanvas = () => {
    setUploadSignatureAttachments({
      fileId: null,
      base64: '',
      fileExtension: '',
      fileSize: '0',
      type: ''
    })

    if (canvasRef.current) {
      canvasRef.current.clear()
      canvasRef.current.imgSrc = ''
      canvasRef.current.resetView()
      renderCanvas()
      setDrawing('')
      setBrushColor('#000000')
    }
    setFiles('')
    setDrawing('')
  }

  const uploadNewsImageSign = (files: any) => {
    if (files[0]) {
      /**
       * files all detail file
       * lastModified, lastModifiedDate
       * name, size, type
       */
      const file = files[0]
      const fileName = file.name.split('.')
      const filesName = file.name
      const detailImage = {
        fileId: uploadSignatureAttachments?.fileId || null,
        base64: '',
        type: file.type.toString(),
        fileExtension: fileName[fileName.length - 1],
        fileSize: file.size.toString()
      }
      const reader: any = new FileReader()
      reader.addEventListener('load', () => {
        /** reader.result is base64 */
        const base64 = reader.result.split(',')
        setFiles(reader.result)
        detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result
        // dispatch(editDocument({ value: detailImage, key: 'signatureAttachments' }))
      })
      reader.readAsDataURL(file)
      setUploadSignatureAttachments(detailImage)
      setFileName(filesName)
      setFileSize(detailImage.fileSize)
      setType(detailImage.type)
    }
  }

  const signaturSubmit = () => {
    onSubmit('save')
    setIsopenPopup(false)
    if (drawing === '') {
      return
    } else {
      setFiles(drawing)
      setDrawing('')
    }
  }

  //** WEBCAM */
  const capture = useCallback(() => {
    const imageSrc: any = webcamRef.current?.getScreenshot()
    setTempImgData(imageSrc)
    setTempImageSrc(imageSrc)
    setTempProfile({ base64: imageSrc.replace(`data:image/jpeg;base64,`, ''), fileId: null, fileExtension: 'jpeg', fileSize: '' })
  }, [webcamRef])

  const cancelwebcam = () => {
    setOpenpopup(false)
    setTempProfile({
      fileId: null,
      base64: '',
      fileExtension: '',
      fileSize: ''
    })
    setTempImgData(null)
    setTempImageSrc(null)
  }
  const browImage = () => {
    setTempProfile({
      fileId: null,
      base64: '',
      fileExtension: '',
      fileSize: ''
    })
    setTempImgData(null)
    setTempImageSrc(null)
    setOpenWebcam(false)
  }

  const renderBaseFile = (file: any) => {
    return new Promise<NewImageType>((resolve, reject) => {
      const reader: any = new FileReader()
      reader.onload = () => {
        const base64 = reader.result.split(',')
        resolve({
          base64: base64.length > 0 ? base64[base64.length - 1] : reader.result,
          type: '',
          fileExtension: _.last(file.name.split('.')) || '',
          fileSize: file.size.toString(),
          fileName: _.first(file.name.split('.')) || ''
        })
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: ['.jpeg', '.jpg', '.png'],
    multiple: false,
    onDrop: (acceptedFiles) => {
      uploadImage(acceptedFiles)
      setTempImgData(URL.createObjectURL(acceptedFiles[0]))
    }
  })

  const getPatientId = (id: number) => setPatientId(id)

  const loadFormRegister = useCallback(() => {
    RegisterApi.formRegister().then((form) => {
      if (form.status === 200) {
        if (form.data.formRegister !== undefined) {
          form = JSON.parse(form.data.formRegister)
          dispatch(setForm(form))
        }
      }
    })
  }, [])

  const loadNewPatient = (data: any) => {
    if (data?.cnNumber && data?.patientId) loadPatient(data.patientId)
    else {
      // setBlockPage(true)
      new Promise<void>((resolve) => resolve())
        .then(loadLastCnNumber)
        .then(loadLastBranchCnNumber)
        .then(() => {
          const newPatientValue: any = {
            patientId: data.patientId,
            branchCnNumber: formPatient.getValues().branchCnNumber,
            cnNumber: formPatient.getValues().cnNumber,
            snCode: formPatient.getValues().snCode,
            firstname: data.patientFullname.split(' ')[0],
            lastname: data.patientFullname.split(' ')[1],
            phone: data.phone
          }
          setPatient(newPatientValue)
          formPatient.reset({
            patientId: newPatientValue.patientId,
            branchCnNumber: newPatientValue.branchCnNumber,
            cnNumber: newPatientValue.cnNumber,
            snCode: newPatientValue.snCode,
            firstname: newPatientValue.firstname,
            lastname: newPatientValue.lastname,
            phone: newPatientValue.phone
          })

          const rank: any = MEMBERSHIP_RANKING.find((memberRank: any) => memberRank.key === 'BRONZ')
          handleChangeRanking({ key: rank.key, text: rank.text, class: rank.class })
          dispatch(editPatient(newPatientValue))
        })
        .then(() => {
          history.replace({ ...history.location, state: {} })
        })
        .then(() => {
          setDisabledButton(false)
          setDisabled(false)
          setErrorMessageEmailTemp('')
          setDisabledAsNewPatient(false)
        })
    }
  }

  const loadPatient = async (id: number) => {
    // setBlockPage(true)
    setBranchCode('')
    setPatientFinance([])
    setLoading(true)
    setDisabledButton(false)
    await RegisterApi.findPatientById(id)
      .then((resPatient) => {
        if (resPatient.status === 200) {
          const patientData = resPatient.data

          if (patientData.profile) {
            const image = patientData.profile
            setImgData(`${fileUrl}/${image.filePath}/${image.fileName}`)
            setProfile({ fileId: image.patientFileId, base64: '', fileExtension: '', fileSize: '' })
          } else {
            setImgData()
            setProfile({ fileId: null, base64: '', fileExtension: '', fileSize: '' })
          }

          if (patientData.signature) {
            const patientSignauture = patientData.signature
            setFiles(`${fileUrl}/${patientSignauture.filePath}/${patientSignauture.fileName}`)
            setUploadSignatureAttachments({ fileId: patientSignauture.patientFileId, base64: '', fileExtension: '', type: type, fileSize: fileSize })
          } else {
            setFiles('')
            setUploadSignatureAttachments({ fileId: null, base64: '', fileExtension: '', type: '', fileSize: '' })
          }

          setPatient(patientData)
          setDisabled(false)
          setErrorMessageEmailTemp('')
          setErrorMessage(initStateErrorMessage)
          setDisabledAsNewPatient(false)

          const formTreatmentRight: any = []
          if (patientData.treatmentRights) {
            _.map(patientData.treatmentRights, (treatmentRight: any, iTreatmentRights: number) => {
              formTreatmentRight.push({
                patientTreatmentRightId: treatmentRight.patientTreatmentRightId,
                treatmentRightId: treatmentRight.treatmentRightId,
                treatmentRightName: treatmentRight.treatmentRightName,
                balance: treatmentRight.amount - treatmentRight.amountUsed,
                unit: Number(treatmentRight.isDivide) === 1 ? (_.get(treatmentRight, 'unit') === 'BAHT' && t('REGISTER.UNIT_BATH')) || '%' : t('REGISTER.UNIT_EACH')
              })
            })
          }
          const formParents: any = []
          let parent: any = []
          if (patientData.parents && patientData.parents !== 'string' && patientData.parents !== '[]') {
            parent = JSON.parse(patientData.parents)
            _.map(parent, (pr: any, iPr: number) => {
              formParents.push(pr)
            })
          }

          formPatient.reset({
            accessChannelId: patientData.accessChannelId,
            accessChannelOther: patientData.accessChannelOther || null,
            serviceFactorId: patientData.serviceFactorId,
            serviceFactorIdOther: patientData.serviceFactorOther || null,
            address: patientData.address,
            addressEn: patientData.addressEn,
            beAllergic: patientData.beAllergic,
            bloodType: patientData.bloodType,
            bmi: patientData.bmi,
            branchCnNumber: patientData.branchCnNumber,
            branchId: patientData.branchId,
            bsa: patientData.bsa,
            careerId: patientData.careerId,
            cnNumber: patientData.cnNumber,
            countryId: patientData.countryId,
            dateOfBirth: patientData.dateOfBirth,
            diastolicBloodPressure: patientData.diastolicBloodPressure,
            disease: patientData.disease,
            isPregnant: patientData.isPregnant || '',
            pregnantMonth: patientData.pregnantMonth || '',
            isRecuperate: patientData.isRecuperate || '',
            isMedicalCertificate: patientData.isMedicalCertificate || '',
            isInsure: patientData.isInsure || '',
            displayName: patientData.displayName,
            districtId: patientData.districtId,
            email: patientData.email,
            facebook: patientData.facebook,
            firstname: patientData.firstname,
            firstnameEn: patientData.firstnameEn,
            frequencyAlcohol: patientData.frequencyAlcohol,
            frequencyCigarette: patientData.frequencyCigarette,
            pregnantHistory: patientData.pregnantHistory || '',
            surgeryHistory: patientData.surgeryHistory || '',
            surgeryDate: patientData.surgeryDate || null,
            gender: patientData.gender,
            hasIdCard: patientData.hasIdCard,
            hasDateOfBirth: patientData.hasDateOfBirth,
            heartRate: patientData.heartRate,
            height: patientData.height,
            idCard: patientData.idCard,
            passport: patientData.passport,
            workPermitNumber: patientData.workPermitNumber,
            hasPassport: patientData.passport ? 'HAS' : 'NOT_HAS',
            hasWorkPermitNumber: patientData.workPermitNumber ? 'HAS' : 'NOT_HAS',
            importantSymptoms: patientData.importantSymptoms,
            symptoms: patientData.symptoms,
            lastname: patientData.lastname,
            lastnameEn: patientData.lastnameEn,
            line: patientData.line,
            lineToken: patientData.lineToken,
            maritalStatus: patientData.maritalStatus,
            medicineUse: patientData.medicineUse,
            nationalityId: patientData.nationalityId,
            o2Sat: patientData.o2Sat,
            originId: patientData.originId,
            patientId: patientData.patientId,
            patientTypeId: patientData.patientTypeId,
            phone: patientData.phone,
            postcode: patientData.postcode,
            prefixId: patientData.prefixId,
            premissionApp: patientData.premissionApp,
            profile: patientData.profile,
            provinceId: patientData.provinceId,
            pulseRate: patientData.pulseRate,
            rank: patientData.rank,
            regIrreq: patientData.regIrreq,
            regisApp: patientData.regisApp,
            religion: patientData.religion,
            rr: patientData.rr,
            specialInfo: patientData.specialInfo,
            subDistrictId: patientData.subDistrictId,
            systolicBloodPressure: patientData.systolicBloodPressure,
            telephone: patientData.telephone,
            temperature: patientData.temperature,
            waistline: patientData.waistline,
            weight: patientData.weight,
            treatmentRights: formTreatmentRight,
            parents: formParents,
            signature: patientData.signature,
            officeCountryId: patientData.officeCountryId,
            officeProvinceId: patientData.officeProvinceId,
            officeDistrictId: patientData.officeDistrictId,
            officeSubDistrictId: patientData.officeSubDistrictId,
            officePostcode: patientData.officePostcode,
            officeAddress: patientData.officeAddress
          })

          const rank: any = MEMBERSHIP_RANKING.find((memberRank: any) => memberRank.key === patientData.rank)
          handleChangeRanking({ key: rank.key, text: rank.text, class: rank.class })
          dispatch(editPatient(patientData))

          RegisterApi.findPatientFinanceByPatientId(patientData.patientId)
            .then((resPatientFinance) => {
              let finance = { advanceBalance: 0, outstandingBalance: 0 }
              if (resPatientFinance.status === 200) {
                finance = { advanceBalance: resPatientFinance.data.advanceBalance, outstandingBalance: resPatientFinance.data.outstandingBalance }
              }
              setPatientFinance(finance)
            })
            .finally(() => setLoading(false))
        }
      })
      .finally(() => setLoading(false))
  }

  function handleChange(event: any): void {
    const name = event.target.name
    const value = event.target.value
    setPatient((values: any) => ({ ...values, [name]: value }))
    formPatient.setValue(name, value)
  }

  function handleChangeRanking(rank: RankInterface): void {
    setMembershipRanking(rank)
    setPatient((values: any) => ({ ...values, rank: rank.key }))
    formPatient.setValue('rank', rank.key)
    $('#rank').val(rank.key)
  }

  const onSubmit = async (type: 'save' | 'sendExam' | 'payment' | 'appointment' | 'refer' | 'sso' | 'opd' | 'opd_empty_0' | 'opd_empty_1' | 'refDen' |'opd-cover') => {
    setDisabledButton(true)
    setDisabledAsNewPatient(false)
    const patientData = dataPatient()

    if (
      !patientData.cnNumber ||
      !patientData.branchCnNumber ||
      !patientData.prefixId ||
      !patientData.firstname ||
      !patientData.lastname ||
      !patientData.phone ||
      (!patientData.idCard && patientData.hasIdCard !== 'NOT_HAS') ||
      (!patientData.dateOfBirth && patientData.hasDateOfBirth !== 'NOT_HAS') ||
      errorMessageEmailTemp
    ) {
      let keyName = ''

      if (!patientData.prefixId) keyName = 'id="searchPrefixes"'
      else if (!patientData.firstname) keyName = 'name="firstname"'
      else if (!patientData.lastname) keyName = 'name="lastname"'
      else if (!patientData.idCard && patientData.hasIdCard !== 'NOT_HAS') keyName = 'name="idCard"'
      else if (!patientData.dateOfBirth && patientData.hasDateOfBirth !== 'NOT_HAS') keyName = 'name="field-age"'
      else if (!patientData.phone) keyName = 'name="phone"'
      else if (errorMessageEmailTemp) keyName = 'name="email"'

      if (keyName) {
        const inputs: any = document.querySelector(`input[${keyName}]`)
        inputs.focus()
      }

      setDisabledButton(false)

      return setErrorMessage({
        ...errorMessage,
        ...{
          CN_NUMBER: !patientData.cnNumber ? t('VALIDATION.CN_NUMBER') : '',
          BRANCH_CN_NUMBER: !patientData.branchCnNumber ? t('VALIDATION.BRANCH_CN_NUMBER') : '',
          PREFIX_ID: !patientData.prefixId ? t('VALIDATION.PREFIX_ID') : '',
          FIRSTNAME: !patientData.firstname ? t('VALIDATION.FIRST_NAME') : '',
          LASTNAME: !patientData.lastname ? t('VALIDATION.LAST_NAME') : '',
          PHONE: !patientData.phone ? t('VALIDATION.PHONE') : '',
          ID_CARD: !patientData.idCard && patientData.hasIdCard !== 'NOT_HAS' ? t('VALIDATION.ID_CARD') : '',
          DATE_OF_BIRTH: !patientData.dateOfBirth && patientData.hasDateOfBirth !== 'NOT_HAS' ? t('VALIDATION.DATE_OF_BIRTH') : ''
        }
      })
    }

    if (patientData.treatmentRights) {
      for (const tr of patientData.treatmentRights) {
        const resTr: any = _.find(treatmentRights, { treatmentRightId: tr.treatmentRightId })
        if (!resTr) {
          setDisabledButton(false)
          return notiError(`${t('REGISTER.MESSAGE.ERROR_RIGHT')} (${tr.treatmentRightName}) ${t('REGISTER.MESSAGE.ERROR_RIGHT_DISABLE')}`, '', null, null, 'register-page')
        }
      }
    }

    const checkValidateInfo: ValidatePatientInfoInterface = {
      firstname: patientData.firstname,
      lastname: patientData.lastname,
      phone: patientData.phone,
      patientId: patientId || patientData.patientId
    }
    const validTypes = ['save', 'sendExam', 'payment', 'appointment']
    if (validTypes.includes(type)) {
      await RegisterApi.validatePatientInfo(checkValidateInfo)
        .then(({ data }) => {
          if (data.isDuplicateName || data?.isDuplicatePhone) {
            return swalCustom(
              t('VALIDATION.ALERT.CONTINUE'),
              `
              ${(data.isDuplicateName &&
                `<div class="text-left d-flex align-items-start pt-1" style="max-width: 450px"><img src=${icons.incorrectCircle} class="pt-1 pr-2" />${t('REGISTER.FORM.FULL_NAME')}: ${patientData.firstname} ${patientData.lastname} ${t('REGISTER.MESSAGE.ISDUP')}</div>`) ||
              ``
              }
              ${(data.isDuplicatePhone && `<div class="text-left d-flex align-items-start pt-1" style="max-width: 450px"><img src=${icons.incorrectCircle} class="pt-1 pr-2" />${t('REGISTER.FORM.PHONE')}: ${patientData.phone} ${t('REGISTER.MESSAGE.ISDUP')}</div>`) || ``}
              `,
              async (res: any) => {
                if (res) onHandleSubmitForSave(patientData, type)
                else setDisabledButton(false)
              },
              'question',
              {
                confirmButton: {
                  confirmButtonText: t('BUTTON.CONTINUE')
                },
                cancelButton: {
                  cancelButtonText: t('BUTTON.CANCEL')
                }
              }
            )
          } else onHandleSubmitForSave(patientData, type)
        })
        .catch(() => {
          setDisabledButton(false)
          return
        })

      // .finally(() => setDisabledButton(false))
    } else {
      onHandleSubmitForSave(patientData, type)
    }
  }

  const onHandleSubmitForSave = async (patientData: any, type: 'save' | 'sendExam' | 'payment' | 'appointment' | 'refer' | 'sso' | 'opd' | 'opd_empty_0' | 'opd_empty_1' | 'refDen' |'opd-cover') => {
    const objPatient: any = { ..._.omit(patientData, ['patientId']), profile: profile }

    if (patientId || patientData.patientId) {
      await RegisterApi.update(patientId || patientData.patientId, objPatient)
        .then((data) => {
          // setBlockPage(false)
          if (type === 'sendExam') return onSendExam(patientData.patientId)
          else if (type === 'payment') return onSendPayment()
          else if (type === 'appointment') onSendAppointment()
          else if (type === 'refer') {
            setRefPatient(objPatient)
            setExportReferralExamination(true)
            dispatch(showModal())
          } else if (type === 'refDen') {
            setExportReferralDental(true)
            dispatch(showModal())
          } else if (type === 'opd') handleOpd(patientData.patientId)
          else if (type === 'opd_empty_0') return handleOpdEmpty(patientData.patientId, '0')
          else if (type === 'opd_empty_1') return handleOpdEmpty(patientData.patientId, '1')
          else if (type === 'opd-cover') handleOpdCover(patientData.patientId)
          else return onSuccessSubmit(patientId || patientData.patientId, type)
        })
        .catch((e) => {
          const err = e.response.data
          if (err.message === 'เลขบัตรประชาชนมีการใช้งานไปแล้ว') {
            return setErrorMessage({
              ...errorMessage,
              ...{
                ID_CARD: t('VALIDATION.ID_CARD_ALREADY_EXIST')
              }
            })
          } else {
            return notiError(err.message, '', null, null, 'register-page')
          }
        })
        .finally(() => setDisabledButton(false))
    } else {
      await RegisterApi.create(objPatient)
        .then(({ data }) => {
          setPatientId(data.patientId)
          // setBlockPage(false)
          if (type === 'sendExam') return onSendExam(data.patientId)
          else if (type === 'payment') return onSendPayment(data.patientId)
          else if (type === 'appointment') return onSendAppointment()
          else return onSuccessSubmit(data.patientId, type)
          // else return type !== 'refer' ? notiSuccess(t('REGISTER.MESSAGE.SUCCESS'), '', null, null, 'register-page') : ''
        })
        .catch((e) => {
          const err = e.response.data
          if (err.message === 'เลขบัตรประชาชนมีการใช้งานไปแล้ว') {
            return setErrorMessage({
              ...errorMessage,
              ...{
                ID_CARD: t('VALIDATION.ID_CARD_ALREADY_EXIST')
              }
            })
          } else {
            return notiError(err.message, '', null, null, 'register-page')
          }
        })
        .finally(() => setDisabledButton(false))
    }
  }

  const onSuccessSubmit = async (patientId: any, type: string) => {
    if (type === 'sso') {
      await loadPatient(patientId).then(() => {
        setSSO(true)
        dispatch(showModal())
      })
    } else {
      return type !== 'refer' ? notiSuccess(t('REGISTER.MESSAGE.SUCCESS'), '', null, null, 'register-page') : ''
    }
  }

  /** Set Data submit */
  const dataPatient = () => {
    const patientData = formPatient.getValues()
    return {
      patientId: patientData.patientId || null,
      rank: patientData.rank,
      cnNumber: patientData.cnNumber.toString(),
      branchCnNumber: patientData.branchCnNumber ? patientData.branchCnNumber.toString() : '',
      snCode: patientData.snCode || null,
      prefixId: patientData.prefixId || null,
      firstname: patientData.firstname,
      lastname: patientData.lastname,
      firstnameEn: patientData.firstnameEn,
      lastnameEn: patientData.lastnameEn,
      displayName: patientData.displayName,
      idCard: patientData.idCard,
      hasIdCard: patientData.hasIdCard,
      passport: patientData.passport || null,
      workPermitNumber: patientData.workPermitNumber || null,
      gender: patientData.gender || 'NOT_SPECIFIED',
      dateOfBirth: patientData.dateOfBirth,
      hasDateOfBirth: patientData.hasDateOfBirth,
      nationalityId: patientData.nationalityId || null,
      originId: patientData.originId || null,
      religion: patientData.religion || 'NOT_SPECIFIED',
      maritalStatus: patientData.maritalStatus || 'NOT_SPECIFIED',
      careerId: patientData.careerId || null,
      accessChannelId: patientData.accessChannelId || null,
      accessChannelOther: patientData.accessChannelOther || null,
      serviceFactorId: patientData.serviceFactorId || null,
      serviceFactorOther: patientData.serviceFactorOther || null,
      phone: patientData.phone,
      telephone: patientData.telephone,
      line: patientData.line,
      email: patientData.email,
      facebook: patientData.facebook,
      patientTypeId: patientData.patientTypeId || null,
      bloodType: patientData.bloodType || 'NOT_SPECIFIED',
      frequencyCigarette: patientData.frequencyCigarette,
      frequencyAlcohol: patientData.frequencyAlcohol,
      pregnantHistory: patientData.pregnantHistory || '',
      surgeryHistory: patientData.surgeryHistory || '',
      surgeryDate: patientData.surgeryDate || null,
      disease: patientData.disease ? patientData.disease.toString() : '',
      medicineUse: patientData.medicineUse ? patientData.medicineUse.toString() : '',
      beAllergic: patientData.beAllergic ? patientData.beAllergic.toString() : '',
      importantSymptoms: patientData.importantSymptoms,
      symptoms: patientData.symptoms,
      isPregnant: patientData.isPregnant || '',
      pregnantMonth: patientData.pregnantMonth || '',
      isRecuperate: patientData.isRecuperate || '',
      isMedicalCertificate: patientData.isMedicalCertificate || '',
      isInsure: patientData.isInsure || '',
      countryId: patientData.countryId || null,
      provinceId: patientData.provinceId || null,
      districtId: patientData.districtId || null,
      subDistrictId: patientData.subDistrictId || null,
      postcode: patientData.postcode,
      address: patientData.address,
      addressEn: patientData.addressEn,
      officeCountryId: patientData.officeCountryId || null,
      officeProvinceId: patientData.officeProvinceId || null,
      officeDistrictId: patientData.officeDistrictId || null,
      officeSubDistrictId: patientData.officeSubDistrictId || null,
      officePostcode: patientData.officePostcode,
      officeAddress: patientData.officeAddress,
      parents: JSON.stringify(patientData.parents),
      specialInfo: patientData.specialInfo,
      weight: patientData.weight,
      height: patientData.height,
      systolicBloodPressure: patientData.systolicBloodPressure,
      diastolicBloodPressure: patientData.diastolicBloodPressure,
      waistline: patientData.waistline,
      temperature: patientData.temperature,
      pulseRate: patientData.pulseRate,
      heartRate: patientData.heartRate,
      regIrreq: patientData.regIrreq,
      rr: patientData.rr,
      o2Sat: patientData.o2Sat,
      bmi: patientData.bmi,
      bsa: patientData.bsa,
      profile: profile,
      treatmentRights: patientData.treatmentRights,
      signature: uploadSignatureAttachments
    }
  }

  const uploadImage = (files: any, isTemp = true) => {
    if (files[0]) {
      /**
       * files all detail file
       * lastModified, lastModifiedDate
       * name, size, type
       */
      const file = files[0]
      if (file.name) {
        const fileName = file.name.split('.')
        const detailImage = {
          fileId: null,
          base64: '',
          fileExtension: fileName[fileName.length - 1],
          fileSize: file.size.toString()
        }
        const reader: any = new FileReader()
        reader.addEventListener('load', () => {
          /** reader.result is base64 */
          const base64 = reader.result.split(',')

          if (isTemp) setTempImgData(reader.result)
          else setImgData(reader.result)
          detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result
        })
        reader.readAsDataURL(file)
        // setProfile(detailImage)
        if (isTemp) setTempProfile(detailImage)
        else setProfile(detailImage)
      }
    }
  }

  /** Set Component Model */
  const handleFormRetrieveData = () => {
    setConfigModal({
      title: t('REGISTER.MODAL.TITLE_FORM_RETRIEVE'),
      size: 'lg',
      modul: 'FormRetrieve',
      onReset: resetFormRetrieveData,
      onSubmit: useData,
      textBtnCancel: t('REGISTER.MODAL.TEXT_CANCEL'),
      textBtnConfirm: t('REGISTER.MODAL.CONTINUE_USE_DATA'),
      alignFooter: 'end'
    })
    dispatch(modalSlice.showModal())
  }

  const handleFormCareer = () => {
    setConfigModal({
      title: t('CAREER.TITLE_CREATE'),
      size: 'sm',
      modul: `FormCareer`,
      onReset: resetFormCareer,
      onSubmit: submitCareer,
      textBtnCancel: t('CAREER.BUTTON.CANCEL'),
      textBtnConfirm: t('CAREER.BUTTON.NEW')
    })
    dispatch(modalSlice.showModal())
  }

  const handleFormManagement = () => {
    setConfigModal({
      title: t('REGISTER.MODAL.TITLE_FORM_MANAGEMENT'),
      size: 'xl',
      modul: `FormManagement`,
      onReset: () => resetModal(),
      onSubmit: submitFormManagement,
      textBtnCancel: t('REGISTER.MODAL.TEXT_CANCEL'),
      textBtnConfirm: t('REGISTER.MODAL.CONTINUE_USE'),
      alignFooter: 'end',
      modalStyle: 'modal-form-management'
    })
    dispatch(modalSlice.showModal())
  }

  /** Set Component Model */

  /** Reset Component Model */
  const resetFormRetrieveData = () => {
    formModal.setValue('retrieve', {})
    dispatch(setValuePatient({ key: 'provinceText', value: '' }))
    dispatch(setValuePatient({ key: 'districtText', value: '' }))
    dispatch(setValuePatient({ key: 'subDistrictText', value: '' }))
    dispatch(setValuePatient({ key: 'prefixText', value: '' }))
  }
  const resetFormCareer = () => {
    formModal.setValue('careerName', '')
    formModal.setValue('careerNameEn', '')
    setErrorMessage(initStateErrorMessage)
  }

  /** Reset Component Model */
  const submitFormManagement = async () => {
    const formManagement = formModal.getValues()
    dispatch(modalSlice.submitModal())
    const resCreate: any = await RegisterApi.updateFormRegister({ formRegister: JSON.stringify(formManagement) })
    if (resCreate.status !== undefined && resCreate.status === 200) {
      notiSuccess(t('REGISTER.MESSAGE.FORM_UPDATE_SUCCESS'), '', null, null, 'register-page')
      loadFormRegister()
      resetModal()
    } else {
      notiError(t('REGISTER.MESSAGE.ERROR'), '', null, null, 'register-page')
      dispatch(modalSlice.unSubmitModal())
      resetModal()
    }
  }

  /** Submit Component Model */
  const submitCareer = async () => {
    const career = formModal.getValues()
    if (career.careerName === '') return setErrorMessage({ ...errorMessage, ...{ CAREER_NAME: t('CAREER.MESSAGE.CAREER_NAME') } })
    dispatch(modalSlice.submitModal())

    await CareerApi.create(career.careerName, career.careerNameEn)
      .then((res: any) => {
        resetFormCareer()
        dispatch(modalSlice.resetModal())
        dispatch(setReloadCareers({ reload: true, careerId: res?.data?.careerId }))
        notiSuccess(t('CAREER.MESSAGE.SUCCESS.CREATE'), '', null, null, 'register-page')
      })
      .catch((err) => {
        const error = err.response.data
        setErrorMessage({ ...errorMessage, ...{ [error.message]: t(`CAREER.MESSAGE.${error.message}`) } })
        dispatch(modalSlice.unSubmitModal())
      })
  }

  const resetModal = () => {
    setConfigModal({})
    dispatch(modalSlice.resetModal())
  }

  const useData = async () => {
    const retrieve = await formModal.getValues('retrieve')
    if (retrieve.profile) {
      const retrieveProfile = retrieve.profile
      uploadImage(retrieveProfile, false)
    }

    dispatch(setValuePatient({ key: 'address', value: `${retrieve.address.trim()} ${retrieve.road.trim()} ${retrieve.soi.trim()}` }))
    let dateOfBirth = ''
    if (retrieve.dateOfBirth) {
      dateOfBirth = retrieve.dateOfBirth.split('/')
      if (dateOfBirth.length === 3) dateOfBirth = `${parseInt(dateOfBirth[2]) - 543}-${dateOfBirth[1]}-${dateOfBirth[0]}`
      else dateOfBirth = ''
    }

    dispatch(setValuePatient({ key: 'dateOfBirth', value: `${dateOfBirth}` }))
    dispatch(setValuePatient({ key: 'hasIdCard', value: 'HAS' }))
    dispatch(setValuePatient({ key: 'hasDateOfBirth', value: 'HAS' }))
    formPatient.setValue('hasIdCard', 'HAS')
    formPatient.setValue('hasDateOfBirth', 'HAS')
    // dispatch(setValuePatient({ key: 'hasDateOfBirth', value: `${dateOfBirth ? 'HAS' : 'NOT_HAS'}` }))
    dispatch(setValuePatient({ key: 'firstname', value: retrieve.firstname }))
    dispatch(setValuePatient({ key: 'lastname', value: retrieve.lastname }))
    dispatch(setValuePatient({ key: 'firstnameEn', value: retrieve.firstnameEn }))
    dispatch(setValuePatient({ key: 'lastnameEn', value: retrieve.lastnameEn }))
    dispatch(setValuePatient({ key: 'signature', value: retrieve.signature }))

    let gender = retrieve.gender
    if (gender === t('REGISTER.FORM.GENDER_OPTION.FEMALE')) gender = 'FEMALE'
    else if (gender === t('REGISTER.FORM.GENDER_OPTION.MALE')) gender = 'MALE'
    else if (gender === t('REGISTER.FORM.GENDER_OPTION.OTHER')) gender = 'OTHER'
    else gender = 'NOT_SPECIFIED'

    dispatch(setValuePatient({ key: 'gender', value: gender }))

    dispatch(setValuePatient({ key: 'idCard', value: retrieve.idCard }))

    let provinceText = '',
      districtText = '',
      subDistrictText = ''
    if (retrieve.province) provinceText = await retrieve.province.replace('จังหวัด', '').replace('จ.', '').trim()
    if (retrieve.district) districtText = await retrieve.district.replace('อำเภอ', '').replace('เขต', '').trim()
    if (retrieve.subDistrict) subDistrictText = await retrieve.subDistrict.replace('ตำบล', '').replace('แขวง', '').trim()

    dispatch(setValuePatient({ key: 'provinceText', value: provinceText }))
    dispatch(setValuePatient({ key: 'districtText', value: districtText }))
    dispatch(setValuePatient({ key: 'subDistrictText', value: subDistrictText }))
    dispatch(setValuePatient({ key: 'prefixText', value: retrieve.prefixId }))
    resetFormRetrieveData()
    dispatch(modalSlice.resetModal())
  }

  /** Submit Component Model */
  const goLineConnect = () => {
    window.open(`${routeName.register}/line-connect`)
    const data: any = {
      id: patient.patientId
    }
    localStorage.setItem(KEY_STORAGE.PATIENT, encodeURI(JSON.stringify(data)))
  }

  const handleClear = () => {
    formModal.reset({})
    formPatient.reset({})
    setPatient([])
    setPatientFinance([])
    setImgData()
    setProfile({ fileId: null, base64: '', fileExtension: '', fileSize: '' })
    setPatientId(0)
    setDisabled(true)
    setDisabledButton(false)
    setErrorMessageEmailTemp('')
    setDisabledAsNewPatient(false)
    setMembershipRanking({ key: '', text: 'RANKING MEMBER', class: '' })
    resetFormRetrieveData()
    resetFormCareer()
    dispatch(editPatient({}))
    setUploadSignatureAttachments({ fileId: null, type: type, fileExtension: '', fileSize: fileSize, base64: '' })
    setDrawing('')
    // setBlockPage(false)
  }

  const loadAllAppointment = async () => {
    const respAppointments = await HomeApi.allAppointment()
    if (respAppointments.status === 200 && !_.isEmpty(respAppointments.data)) {
      setAppointments(respAppointments.data)
    }
    else setAppointments([])
  }

  const onSendExam = async (valuePatientId: number) => {
    setDisabledButton(true)
    const patientData = dataPatient()
    const [dataAppointment] = appointments.filter((item: any) => item?.patientId === valuePatientId)
    if (!patientData.disease || !patientData.medicineUse || !patientData.beAllergic) {
      swalCustom(
        t('VALIDATION.ALERT.CONTINUE'),
        `<p class="text-left m-0" style="max-width: 424px">${t('VALIDATION.FIELD_NOT_COMPLETE')}</p>
        <div class="d-flex align-items-center pl-3 pt-1"><img src=${patientData.disease ? icons.checkCircle : icons.incorrectCircle} class="pr-2" />${t('REGISTER.FORM.DISEASE')}</div>
        <div class="d-flex align-items-center pl-3 pt-1"><img src=${patientData.medicineUse ? icons.checkCircle : icons.incorrectCircle} class="pr-2" />${t('REGISTER.FORM.MEDICINE_USE')}</div>
        <div class="d-flex align-items-center pl-3 pt-1"><img src=${patientData.beAllergic ? icons.checkCircle : icons.incorrectCircle} class="pr-2" />${t('REGISTER.FORM.BE_ALLERGIC')}</div>
        `,
        async (res: any) => {
          if (res) {
            SendExaminationApi.registerOpenVisit(valuePatientId).then(({ data }) => {
              if (!_.isEmpty(data)) {
                if (data.status === 'SERVICE') {
                  const optionButton = {
                    cancelButton: {
                      showCancelButton: false
                    },
                    confirmButton: {
                      confirmButtonText: t('BUTTON.CONTINUE')
                    },
                    DenyButton: {
                      showDenyButton: user.hasCancelTreatmentQueue === 'PERMISS' ? true : false,
                      denyButtonClassName: 'swal2-cancelTreatmentQueue',
                      denyButtonText: t('REGISTER.BUTTON.CANCEL_QUEUE_TREATMENT')
                    },
                    showCloseButton: false
                  }
                  return swalCustom(
                    t('HOME.MESSAGE.CHANGE_SERVICE.TITLE'),
                    `<p class="text-center m-0" style="max-width: 424px">${t('HOME.MESSAGE.CHANGE_SERVICE.SUBTITLE')}</p>`,
                    async (res: any) => {
                      if (res.isDenied) {
                        if (dataAppointment?.appointmentId) {
                          handleHomeOpenVisit(dataAppointment?.appointmentId)
                          return
                        }
                        // notiSuccess('ยกเลิกคิวการรักษาสำเร็จ', '', null, null, '')
                        setPatientCase(data)
                        setSendExam(true)
                        setDisabledButton(false)
                      } else {
                        return
                      }
                    },
                    'warning',
                    optionButton
                  )
                } else {
                  if (dataAppointment?.appointmentId) {
                    handleHomeOpenVisit(dataAppointment?.appointmentId)
                    return
                  }

                  setPatientCase(data)
                  setSendExam(true)
                  setDisabledButton(false)
                }
              } else {
                setPatientCase([])
                setSendExam(false)
                setDisabledButton(false)
              }
            })
          }
        },
        'question',
        {
          confirmButton: {
            confirmButtonText: t('BUTTON.CONTINUE')
          },
          cancelButton: {
            cancelButtonText: t('BUTTON.CANCEL')
          }
        }
      )
    } else {
      if (dataAppointment?.appointmentId) {
        handleHomeOpenVisit(dataAppointment?.appointmentId)
        return
      }

      const sendExamData = await SendExaminationApi.registerOpenVisit(valuePatientId)
      if (sendExamData.status === 201 && !_.isEmpty(sendExamData.data)) {
        setPatientCase(sendExamData.data)
        setSendExam(true)
        setDisabledButton(false)
      } else {
        setPatientCase([])
        setSendExam(false)
        setDisabledButton(false)
      }
    }
  }

  const handleHomeOpenVisit = async (id: number) => {
    const visit = await SendExaminationApi.homeOpenVisit(id)
    if (visit.status === 201 && !_.isEmpty(visit.data)) {
      setPatientCase(visit.data)
      setSendExam(true)
      setDisabledButton(false)
    } else {
      setPatientCase([])
      setSendExam(false)
      setDisabledButton(false)
    }
  }

  const loadLastCnNumber = () => {
    setDisabled(true)
    setErrorMessageEmailTemp('')
    RegisterApi.lastCnNumber().then((resCnNumber) => {
      if (resCnNumber.status === 200) {
        dispatch(setValuePatient({ key: 'cnNumber', value: _.get(resCnNumber.data, 'cnNumber') || '' }))
        formPatient.setValue('cnNumber', _.get(resCnNumber.data, 'cnNumber') || '')
        setDisabled(false)
      }
    })
  }

  const loadLastBranchCnNumber = () => {
    setDisabled(true)
    setErrorMessageEmailTemp('')
    RegisterApi.lastBranchCnNumber().then((resBrachCnNumber) => {
      if (resBrachCnNumber.status === 200) {
        dispatch(setValuePatient({ key: 'branchCnNumber', value: _.get(resBrachCnNumber.data, 'branchCnNumber') || '' }))
        formPatient.setValue('branchCnNumber', _.get(resBrachCnNumber.data, 'branchCnNumber') || '')
        setDisabled(false)
        setBranchCode(_.get(resBrachCnNumber.data, 'branchCode') || '')
      }
    })
  }

  const newPatient = () => {
    formModal.reset({})
    formPatient.reset({})
    loadLastCnNumber()
    loadLastBranchCnNumber()
    setPatient([])
    setImgData()
    setProfile({ fileId: null, base64: '', fileExtension: '', fileSize: '' })
    setPatientId(0)
    setDisabled(false)
    setErrorMessageEmailTemp('')
    setMembershipRanking(MEMBERSHIP_RANKING[0])
    dispatch(editPatient({}))
    setDisabledButton(false)
    setUploadSignatureAttachments({ fileId: null, type: type, fileExtension: '', fileSize: fileSize, base64: '' })
    setDrawing('')
    clearCanvas()
  }

  const cancelNewPatient = () => {
    formModal.reset({})
    formPatient.reset({})
    setPatient([])
    setImgData()
    setProfile({ fileId: null, base64: '', fileExtension: '', fileSize: '' })
    setPatientId(0)
    setErrorMessageEmailTemp('')
    setMembershipRanking({ key: '', text: 'RANKING MEMBER', class: '' })
    dispatch(editPatient({}))
    setUploadSignatureAttachments({ fileId: null, type: type, fileExtension: '', fileSize: fileSize, base64: '' })
    setDrawing('')
    setPatientFinance([])
    setErrorMessage(initStateErrorMessage)
  }

  const handleClickBtnMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseBtnMore = () => {
    setAnchorEl(null)
  }

  const loadTreatmentRights = useCallback(() => {
    ClinicApi.findAllTreatmentRight().then((resTreatmentRights) => {
      if (resTreatmentRights.status === 200) setTreatmentRights(resTreatmentRights.data)
    })
  }, [])

  useEffect(() => {
    loadAllAppointment()
  }, [])

  useEffect(() => {
    if (_.isEmpty(formRegisters)) loadFormRegister()
    if (_.isEmpty(treatmentRights)) loadTreatmentRights()
    if (patientId) {
      loadPatient(patientId)
      localStorage.removeItem(KEY_STORAGE.PATIENT)
      history.replace({ ...history.location, state: {} })
    }

    dispatch(modalSlice.closeModal())
  }, [loadFormRegister, patientId])

  useEffect(() => {
    const locationState: any = location.state
    if (!_.isEmpty(locationState) && !_.isEmpty(locationState.newPatientInfo) && !patientId) {
      loadNewPatient(locationState.newPatientInfo)
    }
  }, [location])

  const onSendPayment = (patientId = patient.patientId) => {
    setDisabledButton(true)
    SendExaminationApi.registerOpenVisit(patientId).then((sendPayment) => {
      if (sendPayment.status === 201 && !_.isEmpty(sendPayment.data)) {
        const queue = sendPayment.data
        PaymentApi.sendPayment(queue.patientCaseId)
          .then(({ data }) => {
            setDisabledButton(false)
            history.push(routeName.payment + '/create', { patientId: queue.patientId, patientCaseId: queue.patientCaseId, servicePointId: data.servicePointId })
          })
          .catch((e) => {
            const message = e?.response?.data?.message
            if (message === 'NOT_FOUND_SERVICE_POINT_PAYMENT') {
              notiError(t('REGISTER.MESSAGE.NOT_FOUND_SERVICE_POINT_PAYMENT'))
            }
            setDisabledButton(false)
          })
          .finally(() => {
            setDisabled(false)
          })
      }
    })
  }

  const onSendAppointment = () => {
    history.push(routeName.appointment, { patientId: patient.patientId, firstname: patient?.firstname || '' })
    setDisabled(false)
  }

  const viewHistoryTreatment = () => {
    history.push(routeName.treatmentsHistory, { patientId: patientId })
  }

  const exportOpd = async (valuePatientId: number) => {
    onSubmit('opd')
    // .then(() => { handleOpd(valuePatientId) })
  }

  const exportOpdEmpty = (valuePatientId: number, type: '0' | '1') => {
    if (type === '0') {
      onSubmit('opd_empty_0')
    } else {
      onSubmit('opd_empty_1')
    }
  }

  const handleOpdCover = (id: number) => {
    setLoading(true)
    exportOPDCover(id)
      .catch(() => {
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleOpd = (id: number) => {
    setLoading(true)
    exportOPD(id)
      .catch((e) => {
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleOpdEmpty = (id: number, type: '0' | '1') => {
    setLoading(true)
    const newProps = { type: type }
    exportOPDEmpty(id, newProps)
      .catch((e) => {
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const saveProfile = async () => {
    setOpenpopup(!openpopup)
    setProfile(tempProfile)
    setImgData(tempImgData)
    setTempProfile({
      fileId: null,
      base64: '',
      fileExtension: '',
      fileSize: ''
    })
    setTempImgData(null)
    setTempImageSrc(null)
  }

  const onExportMedicalServices = async () => {
    await onSubmit('sso')
  }

  const onExportReferralExamination = () => {
    onSubmit('refer')
  }

  const onExportReferralDental = async () => {
    onSubmit('refDen')
  }

  const onExportMedicalCertificateTh = async () => {
    const print = await TreatmentHistoryApi.printMedicalCertificateEmpty(patientId, { lang: 'th' }).finally(() => setLoading(false))
    if (print.status === 200) {
      window.open(`${fileUrl}/${print.data}`, '_blank')
    }
  }

  const onExportMedicalCertificateEn = async () => {
    const print = await TreatmentHistoryApi.printMedicalCertificateEmpty(patientId, { lang: 'en' }).finally(() => setLoading(false))
    if (print.status === 200) {
      window.open(`${fileUrl}/${print.data}`, '_blank')
    }
  }

  return (
    <UseStyled.ContainerRegister>
      <UseStyled.FragmentRegister>
        <FormProvider {...formPatient}>
          <UseStyled.FragmentLeft className={'custom-scroll'}>
            <div className={'d-flex d-xl-block'}>
              <SearchPatients status="ACTIVE" callbackPatientId={getPatientId} patientId={patient.patientId} onClear={handleClear} optionBelowSideBar appointment />
              <ButtonCustom
                disabled={disabledAsNewPatient}
                startIcon={<FontAwesomeIcon icon={faUserPlus} />}
                variant="outlined"
                onClick={() => {
                  newPatient()
                  setDisabledAsNewPatient(true)
                }}
                textButton={t('REGISTER.TITLE.NEW_PATIENT')}
                className="btn-new-patient d-xl-none ml-2 ml-md-3"
                style={{ margin: 0 }}
              />
            </div>

            <UseStyled.SectionProfile>
              <div className={'d-flex align-items-md-center'}>
                <UseStyled.CardProfileImg className={`card-profile personal-avatar mt-2 mt-md-0 ${disabled ? 'disabled' : ''}`}>
                  <div className={`personal-figure ${membershipRanking.class ? `border-${membershipRanking.class}` : ''}`}>
                    <input {...getInputProps()} disabled={disabled} />
                    {imgData ? <img src={imgData} alt="profile" /> : <img src={imgs.defaultAvatar} alt="default profile" />}
                    {!disabled && (
                      <IconButton className={`btn-add-profile`} aria-label="upload picture" onClick={() => setOpenpopup(true)}>
                        <PhotoCamera />
                      </IconButton>
                    )}
                  </div>
                </UseStyled.CardProfileImg>
                <PopupInsertImg trigger={openpopup}>
                  <UseStyled.AddImgGroup>
                    <div onClick={() => browImage()}>
                      <UseStyled.ButtonSelectFile {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>{t('REGISTER.FORM.CHOOSE_PIC')}</UseStyled.ButtonSelectFile>
                    </div>
                    <UseStyled.ButtonWebcam onClick={() => setOpenWebcam(!openWebcam)}>{t('REGISTER.FORM.CAPTURE')}</UseStyled.ButtonWebcam>
                  </UseStyled.AddImgGroup>
                  <div className="pt-4 w-100 d-flex justify-content-center text-center align-items-center">
                    {openWebcam === true ? (
                      <div className="d-flex justify-content-center text-center align-items-center flex-column w-100 pt-3">
                        {tempImageSrc === null ? <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" height={400} width={400} /> : <img src={tempImageSrc} alt="img.webcam" />}
                        {tempImageSrc === null ? (
                          <UseStyled.ButtonCapture onClick={() => capture()}>{t('REGISTER.FORM.CAPTURE_PIC')}</UseStyled.ButtonCapture>
                        ) : (
                          <UseStyled.ButtonCapture onClick={() => setTempImageSrc(null)}>{t('REGISTER.FORM.CAPTURE_AGAIN')}</UseStyled.ButtonCapture>
                        )}
                      </div>
                    ) : tempImgData ? (
                      <img src={tempImgData} alt="profile" />
                    ) : (
                      <img src={imgs.defaultAvatar} alt="default profile" />
                    )}
                  </div>
                  <UseStyled.Groupoptionbutton>
                    <UseStyled.ButtonCancel onClick={() => cancelwebcam()}>{t('REGISTER.BUTTON.CANCEL')}</UseStyled.ButtonCancel>
                    {(tempImageSrc || tempImgData) && <UseStyled.ButtonWebcamSubmit onClick={saveProfile}>{t('REGISTER.BUTTON.SAVE')}</UseStyled.ButtonWebcamSubmit>}
                  </UseStyled.Groupoptionbutton>
                </PopupInsertImg>
                <UseStyled.CardProfile className={'overflow-hidden w-100'}>
                  <div className={'d-flex flex-column d-md-block'}>
                    <UseStyled.PatientFullName className={'d-md-inline-block mr-md-3 mr-xl-4 mb-md-1 align-middle'}>
                      {patient.firstname ? `${patient.firstname} ${patient.lastname} ${patient.displayName ? `(${patient.displayName})` : ''}` : t('REGISTER.TITLE.FULL_NAME')}
                    </UseStyled.PatientFullName>
                    <div className={'d-md-inline-block mt-2 mt-md-0 mb-md-1 align-middle'}>
                      <input type="hidden" id="rank" value={membershipRanking.key} {...formPatient.register('rank')} />
                      <UseStyled.DropdownRank className={`d-inline mt-2 m-md-0 dropdown-rank`}>
                        <Dropdown.Toggle id="dropdown-autoClose-inside" disabled={disabled} className={`btn-sm ${membershipRanking.class}`} style={{ textTransform: 'uppercase' }}>
                          <span style={{ marginRight: '8px' }}>{membershipRanking.text}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {_.map(MEMBERSHIP_RANKING, (rank, key) => (
                            <Dropdown.Item
                              key={`item-member-rang-${key}`}
                              eventKey="1"
                              onClick={(event) => {
                                handleChangeRanking(rank)
                              }}
                            >
                              {rank.text}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </UseStyled.DropdownRank>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-end mr-4 mb-3 mt-2">
                    <ButtonCustom
                      disabled={disabled}
                      startIcon={disabled ? <img src={icons.edit_sign} alt="" /> : <Icons.ActiveEditSign />}
                      variant="outlined"
                      onClick={() => setIsopenPopup(true)}
                      textButton={files !== '' || drawing ? t('REGISTER.SIGNATURE.EDIT_SIGN') : t('REGISTER.SIGNATURE.ADD_SIGN')}
                      className="pr-2"
                      style={{ margin: 0 }}
                    />
                  </div>

                  <SignaturePopup trigger={isopenPopup} setIsopenPopup={setIsopenPopup}>
                    <div className="d-flex justify-content-center align-items-start mt-2 pt-2">
                      <h1 style={{ color: colors.themeMainColor, fontSize: 24, fontWeight: 700 }}>{files || drawing ? t('REGISTER.SIGNATURE.EDIT_SIGN') : t('REGISTER.SIGNATURE.ADD_SIGN')}</h1>
                    </div>
                    <UseStyled.SignatureDraw>
                      {!files ? (
                        <>
                          <CanvasDraw
                            style={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              border: `1px dashed ${validateImageUpload || fileSize <= '2097152' ? colors.lightGray : colors.statusInactive}`
                            }}
                            brushRadius={2}
                            lazyRadius={2}
                            hideInterface
                            hideGrid
                            brushColor={brushColor}
                            ref={canvasRef}
                            onChange={renderCanvas}
                          />
                        </>
                      ) : (
                        <>
                          <img src={files} className="-position-absolute" alt="" style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                        </>
                      )}
                      {files || drawing ? (
                        <UseStyled.TrashButton src={icons.btnTrash} alt="TrashButton" onClick={() => clearCanvas()} />
                      ) : (
                        <UseStyled.PencilButton>
                          <Icons.ActivePencil />
                        </UseStyled.PencilButton>
                      )}
                    </UseStyled.SignatureDraw>
                    {uploadSignatureAttachments?.type ? (
                      <>
                        {files ? (
                          <p className="d-flex justify-content-center pt-2" style={{ fontSize: 16, fontWeight: 400, color: validateImageUpload ? colors.disabledGray : colors.themeThirdColor }}>
                            {validateImageUpload
                              ? fileName
                              : checkSize
                                ? `${t('REGISTER.SIGNATURE.ERROR.ERROR_UPLOAD')}: ${fileName} ${t('REGISTER.SIGNATURE.ERROR.ERROR_UPLOAD.FILE')} 2 MB.`
                                : `${t('REGISTER.SIGNATURE.ERROR.ERROR_FILE')}: ${fileName} ${t('REGISTER.SIGNATURE.ERROR.ERROR_FILE_PIC')}`}
                          </p>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="d-flex justify-content-center mt-1">
                      <span className="position-absolute d-flex justify-content-center" style={{ width: 104, height: 32, backgroundColor: colors.white, color: colors.gray }}>
                        {t('REGISTER.SIGNATURE.OR')}
                      </span>
                      <div style={{ width: 536, height: 2, border: `1 px solid ${colors.lightGray}`, backgroundColor: colors.lightGray, marginTop: 8 }}></div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center flex-column mt-4">
                      <UseStyled.ButtontoaddImgSignature {...getRootSignatureProps()}>
                        <input {...getInputSignatureProps()} />
                        {t('REGISTER.SIGNATURE.UPLOAD_IMG')}
                      </UseStyled.ButtontoaddImgSignature>
                      <p style={{ fontSize: 16, fontWeight: 400, color: colors.gray, marginTop: 8 }}>{t('REGISTER.SIGNATURE.SUPPORT_SIGN')}</p>
                    </div>
                    <UseStyled.GroupButtonSignature>
                      <UseStyled.ButtoncancelSignature
                        onClick={() => {
                          setIsopenPopup(false)
                          if (files === '') {
                            clearCanvas()
                          }
                        }}
                      >
                        {t('REGISTER.SIGNATURE.CANCEL')}
                      </UseStyled.ButtoncancelSignature>
                      {drawing || validateImageUpload ? (
                        <UseStyled.ButtonsubmitSignature className="mx-3 " onClick={() => signaturSubmit()}>
                          {t('REGISTER.SIGNATURE.SAVE')}
                        </UseStyled.ButtonsubmitSignature>
                      ) : (
                        <UseStyled.ButtonsubmitSignature className="mx-3 pe-none" style={{ opacity: 0.4 }}>
                          {t('REGISTER.SIGNATURE.SAVE')}
                        </UseStyled.ButtonsubmitSignature>
                      )}
                    </UseStyled.GroupButtonSignature>
                  </SignaturePopup>
                  <div className={'d-flex align-items-center mt-2 mt-md-2'}>
                    <UseStyled.ButtonTreatment variant="outlined" onClick={viewHistoryTreatment} disabled={disabled || _.isEmpty(patient)} className={`outlined-button mr-2 mr-md-3`}>
                      {t('REGISTER.BUTTON.TREATMENT')}
                    </UseStyled.ButtonTreatment>
                    <UseStyled.ButtonTreatment variant="outlined" onClick={() => setUploadTreatment(true)} disabled={disabled || _.isEmpty(patient)} className={`outlined-button`}>
                      {t('REGISTER.BUTTON.TREATMENT_IMAGE')}
                    </UseStyled.ButtonTreatment>
                  </div>
                </UseStyled.CardProfile>
              </div>
            </UseStyled.SectionProfile>

            <section className={'pr-xl-2 mb-2'}>
              <SpecialInfo
                {...formPatient.register('specialInfo')}
                key={'specialInfo'}
                name="specialInfo"
                rows={3}
                onChange={handleChange}
                value={patient.specialInfo ? patient.specialInfo : ''}
                placeholder={t('REGISTER.TITLE.FIELD_SPECIAL_INFO')}
                disabled={disabled}
              />
            </section>

            <UseStyled.SectionTreatmentHistory>
              <p className="text-treatment-history">{t('REGISTER.TITLE.TREATMENT_HISTORY')}</p>
              <Box className={'pr-xl-2 mb-1'} sx={{ width: '100%' }}>
                <TreatmentHistory patientId={patientId} />
              </Box>
            </UseStyled.SectionTreatmentHistory>
          </UseStyled.FragmentLeft>

          <UseStyled.FragmentRight>
            <UseStyled.HeaderBar className={'d-flex align-items-center'}>
              <div className={'d-flex align-items-start justify-content-between w-100'}>
                <UseStyled.HeaderBarInfo>
                  <div className={'d-flex align-items-center mb-xl-2'}>
                    <p className={'title mb-0 mr-3'}>{t('REGISTER.TITLE.PATIENT_INFO')}</p>
                    {disabled === true ? (
                      <ButtonCustom
                        startIcon={<FontAwesomeIcon icon={faUserPlus} />}
                        variant="outlined"
                        onClick={() => {
                          newPatient()
                        }}
                        textButton={t('REGISTER.TITLE.NEW_PATIENT')}
                        className="btn-new-patient d-none d-xl-flex"
                        style={{ margin: 0 }}
                      />
                    ) : (
                      <ButtonCustom
                        startIcon={<FontAwesomeIcon icon={faUserPlus} />}
                        variant="outlined"
                        onClick={() => {
                          cancelNewPatient()
                          setDisabled(true)
                        }}
                        textButton={t('REGISTER.TITLE.CANCEL_NEW_PATIENT')}
                        className="btn-new-patient d-none d-xl-flex"
                        style={{ margin: 0 }}
                        mode={'del'}
                      />
                    )}
                  </div>
                  <div className={'d-flex align-items-md-center flex-column flex-md-row mt-1'}>
                    <p className={'mb-0 text-nowrap'} style={{ color: colors.statusInactive }}>
                      {t('REGISTER.TITLE.OUT_STANDING_BALANCE')}: {numberFormat(patientFinance.outstandingBalance || 0)} {t('REGISTER.UNIT_BATH')}
                    </p>
                    {checkPackage(PACKAGE_ADDONS.ADDON_ADVANCE) && (
                      <p className={'ml-md-4 mb-0 text-nowrap'}>
                        {t('REGISTER.TITLE.ADVANCE_BALANCE')}: {numberFormat(patientFinance.advanceBalance || 0)} {t('REGISTER.UNIT_BATH')}
                      </p>
                    )}
                  </div>
                </UseStyled.HeaderBarInfo>
                <UseStyled.HeaderBarAction className={'d-flex align-items-center'}>
                  {checkPackage(PACKAGE_ADDONS.ADDON_FORM_RETRIEVE) && (
                    <ButtonCustom className={'btn-info d-none d-md-block'} disabled={disabled} onClick={handleFormRetrieveData} variant="outlined" textButton={t('REGISTER.TITLE.FORM_RETRIEVE')} style={{ margin: 0 }} />
                  )}
                  <ButtonSubmit className={'btn-submit mt-0 ml-3 w-auto'} disabled={disabled || disabledButton} onClick={() => onSubmit('save')} textButton={t('REGISTER.BUTTON.SAVE')} style={{ margin: 0 }} />
                  <Button id="btn-more" className={'btn-more ml-3'} variant="outlined" aria-controls={open ? 'basic-menu-more' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClickBtnMore} disableRipple>
                    <Icons.Meatball />
                  </Button>
                  <UseStyled.MenuBtnMore
                    id="basic-menu-more"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseBtnMore}
                    MenuListProps={{
                      'aria-labelledby': 'btn-more'
                    }}
                  >
                    {checkPackage(PACKAGE_ADDONS.ADDON_FORM_RETRIEVE) && (
                      <MenuItem
                        disabled={disabled}
                        className={'d-flex d-md-none'}
                        onClick={() => {
                          handleFormRetrieveData()
                          handleCloseBtnMore()
                        }}
                      >
                        {t('REGISTER.TITLE.FORM_RETRIEVE')}
                      </MenuItem>
                    )}
                    {checkPackage(PACKAGE_ADDONS.ADDON_LINE_NOTIFY) && (
                      <MenuItem
                        disabled={patient.patientId ? false : true}
                        onClick={() => {
                          goLineConnect()
                          handleCloseBtnMore()
                        }}
                      >
                        {t('REGISTER.BUTTON.LINE_CONNECT')}
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => {
                        handleFormManagement()
                        handleCloseBtnMore()
                      }}
                    >
                      {t('REGISTER.MODAL.TITLE_FORM_MANAGEMENT')}
                    </MenuItem>
                    <MenuItem
                      disabled={disabled}
                      onClick={() => {
                        handleClear()
                        handleCloseBtnMore()
                      }}
                    >
                      {t('REGISTER.BUTTON.CLEAR')}
                    </MenuItem>
                  </UseStyled.MenuBtnMore>
                </UseStyled.HeaderBarAction>
              </div>
            </UseStyled.HeaderBar>
            <UseStyled.SectionForm className={'custom-scroll'}>
              <Stack>
                <FormRegister
                  disabled={disabled}
                  error={{ ...errorMessage, EMAIL_PATTERN_ERROR: errorMessageEmailTemp }}
                  resetErrorMessage={() => {
                    setErrorMessage(initStateErrorMessage)
                  }}
                  handleFormCareer={handleFormCareer}
                  branchCode={branchCode || ''}
                  emailTempError={(tempErrStatus: boolean) => setErrorMessageEmailTemp(tempErrStatus ? t('REGISTER.MESSAGE.EMAIL_PATTERN_ERROR') : '')}
                />
              </Stack>
            </UseStyled.SectionForm>
          </UseStyled.FragmentRight>
        </FormProvider>
      </UseStyled.FragmentRegister>
      <FormProvider {...formModal}>
        {configModal && (
          <ModalCustom
            {...configModal}
            component={
              <Box className="pb-2">
                {(configModal.modul === 'FormRetrieve' && <FormRetrieve />) ||
                  (configModal.modul === 'FormManagement' && <FormManagement />) ||
                  (configModal.modul === 'FormCareer' && (
                    <FormCareer
                      error={errorMessage}
                      resetErrorMessage={() => {
                        setErrorMessage(initStateErrorMessage)
                      }}
                    />
                  )) ||
                  ''}
              </Box>
            }
          />
        )}

        {sendExam && (
          <SendExamination
            isShow={sendExam}
            setIsShow={(show: boolean) => {
              setSendExam(show)
              loadPatient(patientId)
            }}
            patient={patientCase}
            type={'register'}
          />
        )}
        {uploadTreatment && <FormUploadTreatment isShow={uploadTreatment} setIsShow={(show: boolean) => setUploadTreatment(show)} patient={patient} />}
      </FormProvider>
      <UseStyled.FragmentFooter elevation={3} ref={footerRef} className={'d-flex align-items-center justify-content-between flex-wrap'}>
        <UseStyled.DropdownDocument>
          <Dropdown.Toggle disabled={disabled || _.isEmpty(patient)} id="dropdown-basic">

            <span className={'d-none d-sm-inline-block'}>{t('REGISTER.BUTTON.PRINT_DOCUMENT')}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: '320px' }}>
            <Dropdown.Item
              className={'text-ellipsis overflow-hidden'}
              onClick={() => {
                onExportMedicalServices()
              }}
            >
              <Box className={'text-ellipsis overflow-hidden'} title={t('TREATMENTS.BUTTON.SSO')}>
                {t('TREATMENTS.BUTTON.SSO')}
              </Box>
            </Dropdown.Item>

            <Dropdown.Item onClick={onExportReferralExamination}>{t('TREATMENTS.BUTTON.REFERRAL_EXAMINATION')}</Dropdown.Item>
            <Dropdown.Item onClick={onExportReferralDental}>{t('TREATMENTS.BUTTON.REFERRAL_DENTAL')}</Dropdown.Item>
            {branchInfo?.opdCardCover === "1" &&
              <Dropdown.Item onClick={() => onSubmit('opd-cover')}>
                {t('REGISTER.BUTTON.OPD_CARD_COVER')}
              </Dropdown.Item>
            }
            {branchInfo?.opdCardHospital === "1" &&
              <Dropdown.Item onClick={() => exportOpd(patient.patientId)} href="#/action-1">
                {t('REGISTER.BUTTON.OPD_CARD_HOSPITAL')}
              </Dropdown.Item>
            }

            {branchInfo?.opdCardCover === "1" &&
              <>
                <Dropdown.Item className={'text-ellipsis overflow-hidden'} onClick={() => exportOpdEmpty(patient.patientId, '0')}>
                  {t('REGISTER.BUTTON.OPD_CARD_EMPTY_1')}
                </Dropdown.Item>
                <Dropdown.Item className={'text-ellipsis overflow-hidden'} onClick={() => exportOpdEmpty(patient.patientId, '1')}>
                  {t('REGISTER.BUTTON.OPD_CARD_EMPTY_2')}
                </Dropdown.Item>
              </>
            }

            <Dropdown.Item onClick={exportSSO}>{t('REGISTER.BUTTON.SSO')}</Dropdown.Item>
            <Dropdown.Item onClick={onExportMedicalCertificateTh}>{t('TREATMENTS.BUTTON.MEDICAL_CERTIFICATE')}</Dropdown.Item>
            <Dropdown.Item onClick={onExportMedicalCertificateEn}>{t('TREATMENTS.BUTTON.MEDICAL_CERTIFICATE_EN')}</Dropdown.Item>
          </Dropdown.Menu>
        </UseStyled.DropdownDocument>
        <div className={'d-flex align-items-center'}>
          {/* <ButtonCustom
            onClick={() => {
              setPopManagePrint(true)
              dispatch(modalSlice.showModal())
            }}
            textButton={<>asdasda</>}
          /> */}
          <ButtonCustom
            disabled={disabled || disabledButton}
            variant="outlined"
            onClick={() => {
              setDisabledButton(true)
              onSubmit('payment')
            }}
            textButton={
              <span>
                <span className={'d-none d-sm-inline-block'}>{t('REGISTER.BUTTON.ORDER_PRODUCT')}</span>
              </span>
            }
            className={'ml-2 ml-sm-3 w-auto'}
          />
          <ButtonCustom
            disabled={disabled || disabledButton}
            variant="outlined"
            onClick={() => {
              setDisabledButton(true)
              onSubmit('appointment')
            }}
            textButton={t('REGISTER.BUTTON.APPOINTMENT')}
            className={'w-auto ml-2 ml-sm-3'}
          />
          <ButtonCustom disabled={disabled || disabledButton} onClick={() => onSubmit('sendExam')} textButton={t('REGISTER.BUTTON.SEND_EXAMINATION')} className={'ml-2 ml-sm-3 w-auto btn-send-exam'} />
        </div>
      </UseStyled.FragmentFooter>

      <Loading show={loading} type="fullLoading" />
      {/* <RouterPrompt /> */}

      {/* {popManagePrint &&
        (<ManagePrint isShow={popManagePrint} onClose={() => setPopManagePrint(false)} />)
      } */}
      {exportMedicalServices && <SSO isShow={exportMedicalServices} setIsShow={(show: boolean) => setSSO(show)} patientId={patient.patientId} branchId={branchIdServicePoint} patient={patient} type={'register'} user={user} treatments={treatments} />}
      {exportReferralExamination && <ReferralEaxmination isShow={exportReferralExamination} setIsShow={(show: boolean) => setExportReferralExamination(show)} patientId={patient.patientId} branchId={branchIdServicePoint} patient={refPatient} />}
      {exportReferralReferralDental && <ReferralDental isShow={exportReferralReferralDental} setIsShow={(show: boolean) => setExportReferralDental(show)} patientId={patient.patientId} branchId={branchIdServicePoint} />}
    </UseStyled.ContainerRegister>
  )
}
