import { useEffect, useState } from 'react'
import { Box, Typography, IconButton, Fade } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

/** COMPONENT */
import InputTextField from 'component/Input/InputTextField'
import TreatmentList, { CustomInputCheckbox } from 'features/finance/payment/component/popupPayment/TreatmentList'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { PaymentInterface } from 'api/counter/payment/payment-interface'

import {
  valPayment,
  summery,
  summerySub,
  setSummeryByKey,
  setSummerySubByKey,
  summeryTreatmentRight,
  setSummeryTreatmentRightByKey,
  summerySubTreatmentRight,
  setSummerySubTreatmentRightByKey,
  setPaymentChannelByKey,
  setSummery,
} from 'app/slice/payment.slice'

import * as UseStyled from 'features/finance/payment/component/popupPayment/UseStyledFormPayment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { swalWarning } from 'component/Alert/Swal'

const initErrMsg = { OVER_MAX_PAID: '', OVER_BALANCE_PAID: '' }
interface PrivilegeProps {
  // no?: number
  index?: number
  title?: any
  treatments?: any
  component?: any
  paymentChannelId: number
  patientTreatmentRightId?: number
  treatmentRight?: any
  treatmentRightValue?: any
  type?: 'TreatmentDiscount' | 'OtherDiscount' | 'PaymentChannels'
  menu?: 'PAYMENT' | 'OVERDUE' | 'RETROSPECTIVE' | 'EDIT_PAYMENT'
  balancePaid?: number
  maxPaid?: number
  treatmentInfo?: string
  discountHasExpired?: boolean
  disableFiled?: boolean
  disableIcon?: boolean
  value?: any
  advance?: boolean
  changePaid?: (val: number) => void
}

export default function PrivilegeList(props: PrivilegeProps) {
  const dispatch = useDispatch()
  const objPayment: PaymentInterface = useSelector(valPayment)
  const valSummery = useSelector(summery)
  const valSummerySub = useSelector(summerySub)
  const valSummeryTreatmentRight = useSelector(summeryTreatmentRight)
  const valSummerySubTreatmentRight = useSelector(summerySubTreatmentRight)
  const [expanded, setExpanded] = useState(false)
  const [isDisabled, setIsDisabled]: any = useState({})
  const [isChecked, setIsChecked]: any = useState({})
  const [errMsg, setErrMsg] = useState({ ...initErrMsg })
  const [valSum, setValSum]: any = useState(0)
  const [treatmentPrice, setTreatmentPrice]: any = useState(0)

  useEffect(() => {
    if (props.patientTreatmentRightId) {
      dispatch(setSummeryTreatmentRightByKey({ key: props.paymentChannelId, patientTreatmentRightId: props.patientTreatmentRightId, value: { isCheck: false, value: 0, isDisabled: true } }))
      isDisabled[props.patientTreatmentRightId] = true
      isChecked[props.patientTreatmentRightId] = false
    }
  }, [props.paymentChannelId, props.patientTreatmentRightId])

  useEffect(() => {


    _.map(props.menu === 'EDIT_PAYMENT' ? [...(props?.treatments?.order?.orderTreatments || []), ...(props?.treatments?.order?.orderProducts || [])] : props.treatments.summeryTreatments, (treatment, index) => {
      let type = ''
      let id = null
      if (treatment.tempOrderTreatmentId || treatment.orderTreatmentId) id = treatment.tempOrderTreatmentId || treatment.orderTreatmentId
      else if (treatment.tempOrderProductId || treatment.orderProductId) id = treatment.tempOrderProductId || treatment.orderProductId
      else id = parseInt(index) + 1

      const isTreatment = treatment.tempOrderTreatmentId || treatment.orderTreatmentId || treatment.operativeId ? true : false
      if (props.menu === 'RETROSPECTIVE') type = isTreatment ? 'ORDER_TREATMENT' : 'ORDER_PRODUCT'
      else if (props.menu === 'EDIT_PAYMENT') type = isTreatment ? 'ORDER_TREATMENT' : 'ORDER_PRODUCT'
      else if (props.menu === 'OVERDUE') type = isTreatment ? 'ORDER_TREATMENT' : 'ORDER_PRODUCT'
      else type = isTreatment ? 'TEMP_ORDER_TREATMENT' : 'TEMP_ORDER_PRODUCT'

      if (props.patientTreatmentRightId)
        dispatch(
          setSummerySubTreatmentRightByKey({
            key: props.paymentChannelId,
            keySub: index,
            patientTreatmentRightId: props.patientTreatmentRightId,
            value: {
              isCheck: false,
              value: 0,
              treatmentPrice: treatment.total,
              paidBalance: treatment.total,
              type: type,
              id: id,
              refItemId: id,
              isDisabled: true
            }
          })
        )
    })
  }, [props.paymentChannelId, props?.treatments?.order, props.treatments.summeryTreatments])

  const value = (index = null) => {
    if (props.patientTreatmentRightId && valSummeryTreatmentRight[props.paymentChannelId] && valSummeryTreatmentRight[props.paymentChannelId][props.patientTreatmentRightId]) return valSummeryTreatmentRight[props.paymentChannelId][props.patientTreatmentRightId].value
    else if (index && valSummerySub[props.paymentChannelId] && valSummerySub[props.paymentChannelId][index]) return valSummerySub[props.paymentChannelId][index]
    else if (valSummery[props.paymentChannelId]) return valSummery[props.paymentChannelId]
    else if (props.treatments?.orderId) {
      if (objPayment.amountReceipt === 0) return objPayment.amountReceipt
      if (objPayment.advance === 0) return objPayment.advance

      // return props.value
    }
    else return 0
  }

  const clearErrMsg = () => setErrMsg({ ...initErrMsg })

  const summaryOriginalValue = () => {
    const paymentChannel = props.treatments.orderPaymentChannel || 0
    const paymentChannelAdvance = props.treatments.orderPaymentChannelAdvance || 0
    const paymentChannelTreatmentRight = props.treatments.orderPaymentChannelTreatmentRight || 0
    const sum = paymentChannel + paymentChannelAdvance + paymentChannelTreatmentRight
    return sum
  }

  const handleChangValue = (valuePayment: string) => {
    clearErrMsg()


    const rawValuePM = valuePayment === '' ? 0 : String(valuePayment).replace(/[^0-9.]/g, '')
    let valuePM = rawValuePM ? parseFloat(rawValuePM) : 0
    if (props.paymentChannelId === 6) {
      dispatch(setPaymentChannelByKey({ key: 6, value: valuePM > 0 ? true : false }))
    }
    if (props.paymentChannelId === 6) {
      if (props?.balancePaid && props?.maxPaid) {
        valuePM = valuePM > props?.balancePaid ? props?.maxPaid : valuePM
      } else {
        valuePM = 0
      }
    }

    if (props.menu === 'EDIT_PAYMENT') {
      valuePM = Math.min(valuePM, summaryOriginalValue() + props.treatments.overdue)
    }
    const selectSumSub: any[] = valSummerySub[props.paymentChannelId]

    let paid: number = valuePM

    let overPaid: any = 0
    for (const key in selectSumSub) {
      if (Object.prototype.hasOwnProperty.call(selectSumSub, key)) {
        const sub = selectSumSub[key]
        let val: any = 0

        const balance = sub.paidBalance
        const value = sub.value

        if (balance + value <= paid) {
          val = balance + value
          paid -= balance + value
        } else {
          val = paid
          paid -= paid
        }
        if (props.paymentChannelId === 1) {
          overPaid = val
        }

        dispatch(
          setSummerySubByKey({
            key: props.paymentChannelId,
            keySub: key,
            value: {
              ...sub,
              value: val,
              isCheck: val > 0 ? true : false,
              isDisabled: val > 0 ? false : true
            }
          })
        )
      }
    }

    const sumPaidBalance = _.sumBy(selectSumSub, 'paidBalance')
    const sumValue = _.sumBy(selectSumSub, 'value')
    const sumTreatmentPrice = _.sumBy(selectSumSub, 'treatmentPrice')


    {
      props.advance
        ? dispatch(setSummeryByKey({ key: props.paymentChannelId, value: valuePM }))
        :
        dispatch(setSummeryByKey({ key: props.paymentChannelId, value: paid >= 1000 ? calCashLimit(sumValue + sumPaidBalance, 1000, sumTreatmentPrice) : valuePM }))
    }

    props?.changePaid && props?.changePaid(valuePM)
  }

  const calCashLimit = (number: number, near: number, sumTreatmentPrice: number) => {

    if (number > sumTreatmentPrice && number <= sumTreatmentPrice + 1000) return number
    else return parseInt(String(number / near)) * near + near
  }

  const handleValOther = (id: number, newIndex: number) => {
    let total = 0
    Object.values(valSummerySub).forEach((item: any, index: number) => {
      if (newIndex !== index) {
        item.map((item_2: any) => {
          if (item_2.id === id) {
            total += item_2.value
          }
        })
      }
    })
    const newTreatmentRight = valSummerySubTreatmentRight[props.paymentChannelId]
    newTreatmentRight.forEach((item: any, index: number) => {
      if (index !== props.patientTreatmentRightId) {
        newTreatmentRight[index].forEach((item_2: any) => {
          if (item_2.id === id) {
            total += item_2.value
          }
        })
      }
    })
    return total
  }

  const handleChangValueTreatmentRight = (valuePaymentTreatmentRight: string) => {

    const rawValueTR = valuePaymentTreatmentRight === '' ? 0 : String(valuePaymentTreatmentRight).replace(/[^0-9.]/g, '')
    let valueTR = rawValueTR ? parseFloat(rawValueTR) : 0
    const paymentChannelId = props.paymentChannelId
    const patientTreatmentRightId = props.patientTreatmentRightId
    if (patientTreatmentRightId) {
      const treatmentRight = props.treatmentRight
      let res = valSummeryTreatmentRight[paymentChannelId]
      if (res) res = res[patientTreatmentRightId]
      if (res.isCheck) {
        if (treatmentRight.isDivide) {
          const sumTreatmentRight = _.sumBy(valSummerySubTreatmentRight[paymentChannelId][patientTreatmentRightId], 'value')
          if (sumTreatmentRight > treatmentRight.balance || valueTR > treatmentRight.balance) return swalWarning(`ยอดเงินการใช้สิทธิ์ ${treatmentRight.treatmentRightName} ต้องไม่เกิน ${treatmentRight.balance} บาท`)
        }
      } else {
        valueTR = 0
      }
      const selectSumSub: any[] = valSummerySub[props.paymentChannelId]
      const selectSumTrSub: any[] = valSummerySubTreatmentRight[paymentChannelId][patientTreatmentRightId]

      let paid: number = valueTR
      for (const key in selectSumTrSub) {
        if (Object.prototype.hasOwnProperty.call(selectSumTrSub, key)) {
          const sub = selectSumSub[key]
          let val: any = 0

          const balance = sub.treatmentPrice
          const valOther = handleValOther(sub.id, props.paymentChannelId)

          // if (sub.paidBalance > 0) {
          if (valOther + paid > balance) {
            val = balance - valOther
            paid -= balance - valOther
          } else {
            val = paid
            paid -= paid
          }
          // }

          dispatch(
            setSummerySubTreatmentRightByKey({
              key: paymentChannelId,
              keySub: key,
              patientTreatmentRightId: patientTreatmentRightId,
              value: {
                ...sub,
                value: val,
                isCheck: true,
                isDisabled: false
              }
            })
          )
        }
      }

      dispatch(setSummeryTreatmentRightByKey({ key: paymentChannelId, patientTreatmentRightId: patientTreatmentRightId, value: { isCheck: res.isCheck, value: valueTR, isDisabled: res.isDisabled } }))
    }
    props?.changePaid && props?.changePaid(valueTR)
  }


  const hadleCheckPaymentChanelFive = (check: boolean, patientTreatmentRightId: number) => {
    if (check) {
      return true
    } else {
      const valSummery = valSummeryTreatmentRight[5]?.filter((item: any, index: number) => index !== patientTreatmentRightId)
      const isCheck = valSummery?.some((item: any) => item.isCheck)
      return isCheck
    }
  }

  const handleCheckBox = () => {
    if (props.paymentChannelId && props.patientTreatmentRightId) {
      const foreignKey = props.paymentChannelId
      const patientTreatmentRightId = props?.patientTreatmentRightId

      let sub = valSummeryTreatmentRight[foreignKey]


      if (sub) sub = sub[patientTreatmentRightId]
      const isCheck = sub.isCheck ? false : true
      let disabled = sub.isCheck ? false : true
      if (isCheck) setExpanded(true)
      else setExpanded(false)
      dispatch(setPaymentChannelByKey({ key: 5, value: hadleCheckPaymentChanelFive(isCheck, patientTreatmentRightId) }))

      const treatmentRight = props.treatmentRight
      if (treatmentRight.isDivide === '1') {
        if (isCheck) disabled = false
        else disabled = true
      } else disabled = true

      dispatch(setSummeryTreatmentRightByKey({ key: foreignKey, patientTreatmentRightId: patientTreatmentRightId, value: { isCheck: isCheck, value: sub.value, isDisabled: disabled } }))
      isChecked[patientTreatmentRightId] = isCheck
      isDisabled[patientTreatmentRightId] = disabled

      if (valSummerySubTreatmentRight[foreignKey] && valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId]) {
        if (valSummeryTreatmentRight && valSummeryTreatmentRight[foreignKey] && valSummeryTreatmentRight[foreignKey][patientTreatmentRightId] && !isChecked[patientTreatmentRightId]) {

          for (const index in valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId]) {
            if (Object.prototype.hasOwnProperty.call(valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId], index)) {
              const treatmentRightByKey = valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId]
              dispatch(
                setSummerySubTreatmentRightByKey({
                  key: foreignKey,
                  keySub: index,
                  patientTreatmentRightId: patientTreatmentRightId,
                  value: {
                    ...treatmentRightByKey[0],
                    isCheck: false,
                    value: 0,
                    treatmentPrice: treatmentRightByKey[0].treatmentPrice,
                    isDisabled: true,
                    paidBalance: sub.paidBalance
                  }
                })
              )
            }
          }

        }
      }
    }
  }

  const onRenderCheckBox = (data: any, status: 'isCheck' | 'isDisabled') => {
    if (props.paymentChannelId === 5) {
      const newCheck = props.patientTreatmentRightId && valSummeryTreatmentRight[5]?.[props.patientTreatmentRightId]
      if (newCheck) {
        return newCheck[status]
      } else {
        return status === 'isDisabled' ? false : true
      }
    } else {
      return data
    }

  }

  const checkStatus = () => {
    const newTreatmentRight = valSummerySubTreatmentRight[props.paymentChannelId]
    let sum = 0
    let treatmentPrice = 0
    _.map(newTreatmentRight, (item: any, index: number) => {
      sum += _.sumBy(item, 'value')
      treatmentPrice = _.sumBy(item, 'treatmentPrice')
    })
    if (treatmentPrice !== 0) return sum === treatmentPrice
    else return false
  }

  return (
    <>
      <Box className="d-flex align-items-start mt-3 pr-md-2 pr-xl-3">
        <Box className={`d-flex w-100 ${props.type !== 'TreatmentDiscount' ? 'pt-2' : ''}`} sx={{ paddingLeft: '2px' }}>
          {(props.type === 'TreatmentDiscount' && (
            <CustomInputCheckbox
              label={props.title}
              info={props.treatmentInfo}
              checked={props.patientTreatmentRightId ? onRenderCheckBox(isChecked[props.patientTreatmentRightId], 'isCheck') : false}
              onChange={(e) => handleCheckBox()}
              disabled={props.discountHasExpired || props.disableFiled || checkStatus()}

            />
          )) || (
              <>

                {(typeof props.title === 'string' && (
                  <Typography className="pr-2" sx={{ fontWeight: 500 }}>
                    {props.title}
                  </Typography>
                )) ||
                  props.title}
              </>
            )}
        </Box>
        <UseStyled.CustomField>
          {props.type === 'TreatmentDiscount' ? (
            <>
              <InputTextField
                disabled={props.disableFiled ? props.disableFiled : props.patientTreatmentRightId ? onRenderCheckBox(isDisabled[props.patientTreatmentRightId], 'isDisabled') : true || props.discountHasExpired}
                value={value()}
                type='number'
                financeText
                hideControl
                onchange={(e) => {
                  handleChangValueTreatmentRight(e.target.value)
                }}
                inputTextAlign="right"
              />
            </>
          ) : (
            <>
              <InputTextField
                disabled={props.discountHasExpired || props.disableFiled}
                value={value()}
                type='number'
                financeText
                hideControl
                onchange={(e) => {
                  handleChangValue(e.target.value)
                }}
                helperText={errMsg.OVER_BALANCE_PAID || errMsg.OVER_MAX_PAID || ''}
                inputTextAlign="right"
              />
            </>
          )}
          {props.disableIcon ? <></> :
            <IconButton
              disableRipple
              onClick={() => {
                if (props?.patientTreatmentRightId) {
                  if (onRenderCheckBox(isChecked[props?.patientTreatmentRightId], 'isCheck')) setExpanded(!expanded)
                } else setExpanded(!expanded)
              }}
              className={`icon-expanded ${expanded ? 'isExpanded' : ''} ${props?.patientTreatmentRightId ? (isChecked[props?.patientTreatmentRightId] && '') || 'disabled pe-none' : ''} ${props.discountHasExpired ? 'disabled pe-none' : ''} mt-1`}
            >
              <FontAwesomeIcon icon={faCaretDown} style={{ color: colors.textPrimary, fontSize: 20 }} />
            </IconButton>
          }

        </UseStyled.CustomField>
      </Box>
      <Fade in={expanded || onRenderCheckBox(isChecked[props?.patientTreatmentRightId || 0], 'isCheck')}>
        <Box className={`${expanded || onRenderCheckBox(isChecked[props?.patientTreatmentRightId || 0], 'isCheck') ? 'd-block' : 'd-none'} pl-4 pr-md-2 pr-xl-3`}>
          <TreatmentList patientTreatmentRightId={props.patientTreatmentRightId} treatmentRight={props.treatmentRight} treatments={props.treatments} paymentChannelId={props.paymentChannelId} />
        </Box>
      </Fade>
    </>
  )
}
