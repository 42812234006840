import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { routeName } from 'routes/routes-name'
import { Box, FormControlLabel, MenuItem, RadioGroup, styled } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import { getBranch } from 'utils/app.utils'
import ClinicApi, { BranchInterface } from 'api/master/clinic.api'
import ReportApi, { FindAllReportInterface } from 'api/report.api'

/** COMPONENT */
import Loading from 'component/Loading'
import RemittanceList from './remittancesList'
import FucntionPrint from 'component/Print/print'
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import InputCheckbox from 'component/Input/InputCheckbox'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import InputSecondNewDatePicker, { PICKER_VIEWS } from 'component/Input/InputSecondNewDatePicker'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import ButtonCustom from 'component/Button/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import InputRadio from 'component/Input/InputRadio'
import { colors } from 'constants/theme'

const SelectorProcess = styled(Box)(({ theme }) => ({
  '.css-16wllh2-MuiButtonBase-root-MuiButton-root.btn-style': {
    color: `${colors.disabledGray} !important`,
    border: '1px solid rgba(0, 0, 0, 0.12)!important'
  },
  '.btn-pop-process': {
    maxHeight: '32px',
    width: ' 100% !Important',
    maxWidth: '180px',
    display: 'flex !Important',
    justifyContent: 'space-between !Important',
    alignItems: 'center !Important',

    '.icon-pop': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'black'
    }
  },
  '.pop-process-open': {
    opacity: '1 !important',
    display: 'block !important'
  },
  '.pop-process-close': {
    opacity: '0',
    display: 'none'
  },
  '.pop-process-container': {
    transition: 'all 0.2s ',
    position: 'fixed',
    border: '1px solid #E5E5E5',
    backgroundColor: '#fff',
    padding: '6px 10px',
    '.pop-content': {
      padding: '8px 0px',
      fontSize: '14px',
      '.pop-content-main': {
        display: 'flex ',
        justifyContent: 'space-between ',
        alignItems: 'center '
      }
    }
  }
}))
type SelectorProcessSubProps = {
  processSubName: string
  processType: 'radio' | 'checkbox'
  isShow: boolean
}

type SelectorProcessProps = {
  processName: string
  isShow: boolean
  isProcess: boolean | undefined
  sub: SelectorProcessSubProps[]
}

export default function Remittances() {
  const { t } = useTranslation()
  const history = useHistory()
  const componentRef = useRef<HTMLDivElement>(null)
  const momentNow = moment().format('YYYY-MM-DD')

  const [branchId, setBranchId] = useState<any>(getBranch())
  const [branches, setBranches] = useState<any[]>([])
  const [rowCount, setRowCount] = useState<number>(0)
  const [sortBy, setSortBy] = useState<string>('createAt')
  const [sortType, setSortType] = useState<string>('ASC')
  const [loading, setLoading] = useState<boolean>(false)

  const [remittanceData, getRemittanceData] = useState<any[]>([])
  const [remittanceDate, setRemittanceDate] = useState<string>(momentNow)
  const [remittancePage, setRemittancePage] = useState<number>(1)
  const [remittanceLimitPage, setRemittanceLimitPage] = useState<number>(10)

  const [checkDF, setCheckDf] = useState<boolean>(false)
  const [checkCash, setCheckCash] = useState<boolean>(false)

  const [checkTreatmentRight, setCheckTreatmentRight] = useState<boolean>(false)

  const [popProcess, setPopProcess] = useState<boolean>(false)
  const ProcessRef = useRef<any>(null)

  const verifyPermission = async () => {
    const condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 200
    }
    ReportApi.reportList(condition).then(({ data }) => {
      const check = _.find(data, (val: any) => String(val.reportKey) === 'REMITTANCES') ? true : false
      if (!check) {
        history.push(routeName.report)
        return false
      }
    })

    ClinicApi.findAllBranches()
      .then(({ data }) => setBranches(data))
      .catch((e) => {
        return
      })

    return true
  }

  useEffect(() => {
    verifyPermission()
  }, [])

  const fetchRemittance = useCallback(() => {
    let condition: any = {
      page: remittancePage,
      pageLimit: remittanceLimitPage,
      branchId: branchId,
      date: remittanceDate
    }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (checkDF) condition = { ...condition, df: checkDF === true ? 1 : 0 }
    if (checkTreatmentRight) condition = { ...condition, expenses: checkTreatmentRight === true ? 1 : 0 }
    if (remittanceDate !== null) condition = { ...condition, date: remittanceDate }

    ReportApi.reportRemittances(condition)
      .then(({ headers, data }) => {
        getRemittanceData(data)
        setRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [remittancePage, remittanceLimitPage, sortBy, sortType, branchId, remittanceDate, checkDF, checkTreatmentRight])

  const filter = [branchId === 0 ? `${t(`REPORT.FILTER.ALL`)}` : `${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`, remittanceDate]

  const { onDownload } = useDownloadExcel({
    tableExportRef: componentRef.current,
    filename: `${t(`REPORT.REPORT_NAME.REMITTANCE_NEW`)} (${remittanceDate})`,
    sheetName: `${t(`REPORT.REPORT_NAME.REMITTANCE_NEW`)}`
  })

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangeRowsPerPage = (limit: number) => setRemittanceLimitPage(limit)

  const handleChangePage = (val: number) => setRemittancePage(val)

  /** UseEffect */
  useEffect(() => {
    fetchRemittance()
  }, [fetchRemittance])

  const [dataProcess, setDataProcess] = useState<SelectorProcessProps[]>([
    {
      processName: t('DF'),
      isShow: false,
      isProcess: false,
      sub: [
        {
          processSubName: t('ราคาจ่ายจริง'),
          processType: 'radio',
          isShow: true
        },
        {
          processSubName: t('ราคาหัตถการ'),
          processType: 'radio',
          isShow: false
        },
        {
          processSubName: t('Lab'),
          processType: 'checkbox',
          isShow: false
        }
      ]
    },
    {
      processName: t('สิทธิการรักษา'),
      isShow: false,
      isProcess: false,
      sub: []
    },
    {
      processName: t('รับเงินล่วงหน้า'),
      isShow: false,
      isProcess: false,
      sub: []
    },
    {
      processName: t('ใบเงินสด'),
      isShow: false,
      isProcess: undefined,
      sub: []
    },
    {
      processName: t('รายรับรายจ่าย'),
      isShow: false,
      isProcess: false,
      sub: []
    }
  ])

  const handleChangeMainProcess = (value: any, index: number, item: SelectorProcessProps) => {
    const elementName = value?.target?.name
    const elementValue = value?.target?.checked

    const newData = [...dataProcess]
    if (!elementValue && item.processName !== t('ใบเงินสด')) {
      newData[index] = { ...newData[index], [elementName]: elementValue, ['isProcess']: false }
      setDataProcess(newData)
      return
    }
    newData[index] = { ...newData[index], [elementName]: elementValue }
    setDataProcess(newData)
  }

  const handleChangeSubProcess = (value: any, indexMain: number, indexSub: number, type?: 'radio' | 'checkbox') => {
    const elementName = value?.target?.name
    const elementValue = value?.target?.checked

    const newData = [...dataProcess]
    if (type === 'radio') {
      const newDataSub = newData[indexMain]?.sub?.map((item: SelectorProcessSubProps) => {
        if (item.processType === type) {
          return { ...item, isShow: false }
        }
        return item
      })
      newDataSub[indexSub] = { ...newDataSub[indexSub], [elementName]: true }
      newData[indexMain] = { ...newData[indexMain], sub: [...newDataSub] }
      setDataProcess(newData)
      return
    }

    const newDataSub = { ...newData[indexMain] }
    newDataSub.sub[indexSub] = { ...newDataSub.sub[indexSub], [elementName]: elementValue }
    newData[indexMain] = { ...newDataSub }
    setDataProcess(newData)
  }

  const handleClickOutside = (event: any) => {
    if (ProcessRef.current && !ProcessRef.current.contains(event.target)) {
      setPopProcess(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <div>
      <Card className={'border-0 h-100'}>
        <HeaderCard text={t(`REPORT.REPORT_NAME.REMITTANCE_NEW`)} />
        <Card.Body>
          <Card.Title className={'mb-0'}>
            <Row className="mx-0">
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setBranchId(e.target.value)
                    setRemittancePage(1)
                  }}
                  renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                  label={''}
                  selectId="select-branch"
                  labelId="label-branch"
                  value={branchId}
                  options={_.map(branches, (b: BranchInterface, index: number) => {
                    return (
                      <MenuItem key={b.branchId} value={b.branchId}>
                        {b.branchName || b.branchNameEn}
                      </MenuItem>
                    )
                  })}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <InputSecondNewDatePicker
                  dateFormat={'DD/MM/YYYY'}
                  placeholder={t('REPORT.FILTER.DATE')}
                  label={''}
                  inputHeight={32}
                  value={remittanceDate}
                  onchange={(e: any) => {
                    setRemittanceDate(moment(e).format('YYYY-MM-DD'))
                    setRemittancePage(1)
                  }}
                  views={PICKER_VIEWS.DAY || PICKER_VIEWS.MONTH || PICKER_VIEWS.YEAR}
                />
              </Col>

              <Col sm={1} md={1} xl={1} className={'pt-2 px-1'}>
                <InputCheckbox
                  onChange={() => {
                    setCheckDf(!checkDF)
                    setRemittancePage(1)
                  }}
                  value="1"
                  label={t('DF')}
                  checked={checkDF}
                  style={{ marginTop: { sm: '-3px' }, marginLeft: { sm: 0 } }}
                />
              </Col>

              <Col sm={1} md={1} xl={1} className={'pt-2 px-1'}>
                <InputCheckbox
                  onChange={() => {
                    setCheckCash(!checkCash)
                    setRemittancePage(1)
                  }}
                  value="true"
                  label={t('REPORT.FILTER.CASH')}
                  checked={checkCash}
                  style={{ marginTop: { sm: '-3px' }, marginLeft: { sm: 0 } }}
                />
              </Col>

              <Col sm={2} md={2} xl={2} className={'pt-2 px-1'}>
                <InputCheckbox
                  onChange={() => {
                    setCheckTreatmentRight(!checkTreatmentRight)
                    setRemittancePage(1)
                  }}
                  value="true"
                  label={t('REPORT.FILTER.INCOME')}
                  checked={checkTreatmentRight}
                  style={{ marginTop: { sm: '-3px' }, marginLeft: { sm: 0 } }}
                />
              </Col>

              {/* <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <SelectorProcess ref={ProcessRef}>
                  <ButtonCustom
                    textButton={t('การแสดงผล')}
                    className={'btn-pop-process '}
                    variant={'outlined'}
                    endIcon={
                      <Box
                        className={'icon-pop'}
                        sx={{
                          transform: `rotate(${popProcess ? 180 : 0}deg)`,
                        }}>
                        <FontAwesomeIcon icon={faCaretDown} />
                      </Box>
                    }
                    onClick={() => setPopProcess(!popProcess)}
                  />
                  <Box
                    className={`pop-process-container ${popProcess ? 'pop-process-open' : 'pop-process-close'}`}
                  >
                    <Box>
                      {dataProcess.map((item: any, index: number) => {
                        return (
                          <Box
                            key={index}
                            className={'pop-content'}
                          >
                            <Box className={'pop-content-main'}>
                              <Box>
                                <InputCheckbox
                                  label={item.processName}
                                  checked={item.isShow}
                                  value={item.isShow}
                                  name={'isShow'}
                                  onChange={(value) => handleChangeMainProcess(value, index, item)}
                                />
                              </Box>

                              {item?.isProcess !== undefined &&
                                <Box>
                                  <InputCheckbox
                                    label={t('คำนวน')}
                                    checked={item.isProcess}
                                    disabled={!item.isShow}
                                    name={'isProcess'}
                                    onChange={(value) => handleChangeMainProcess(value, index, item)}
                                  />
                                </Box>
                              }
                            </Box>

                            {(item?.sub && item?.sub?.length > 0) &&
                              item?.sub.map((info: any, num: number) => {
                                return (
                                  <Box key={num} sx={{ marginLeft: '2rem' }}>
                                    {info?.processType === 'radio' &&
                                      <Box>
                                        <RadioGroup
                                          aria-label="pdfInvoiceTreatmentCondition"
                                          onChange={(event) => handleChangeSubProcess(event, index, num, 'radio')}
                                          className="flex-col"
                                          name="row-radio-buttons-group"
                                        >
                                          <FormControlLabel
                                            className={`ml-0 mr-2 pr-3 ${info.isShow ? 'active' : ''}`}
                                            checked={info.isShow}
                                            name={'isShow'}
                                            control={<InputRadio />}
                                            label={info?.processSubName}
                                          />
                                        </RadioGroup>
                                      </Box>
                                    }
                                    {info?.processType === 'checkbox' &&
                                      <Box>
                                        <InputCheckbox
                                          label={info?.processSubName}
                                          checked={info.isShow}
                                          // disabled={!item.isShow}
                                          name={'isShow'}
                                          onChange={(value) => handleChangeSubProcess(value, index, num)}
                                        />
                                      </Box>
                                    }
                                  </Box>
                                )
                              })
                            }
                          </Box>
                        )
                      })}
                    </Box>
                  </Box>
                </SelectorProcess>
              </Col> */}

              <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                <UseStyled.DropdownDocument>
                  <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
                    {t('REGISTER.BUTTON.PRINT_DOCUMENT')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={onDownload}>{t('Excel')}</Dropdown.Item>
                    <FucntionPrint
                      content={() => componentRef.current}
                      removeIfram
                      trigger={() => <Dropdown.Item>{t('PDF')}</Dropdown.Item>}
                      style={`@page { size: 793.7007874px 1122.519685px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                    />
                  </Dropdown.Menu>
                </UseStyled.DropdownDocument>
              </Col>
            </Row>
          </Card.Title>
          <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
            <RemittanceList
              page={remittancePage}
              pageLimit={remittanceLimitPage}
              rowCount={rowCount}
              sortBy={sortBy}
              sortType={sortType}
              data={remittanceData}
              onRequestSort={onRequestSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              componentRef={componentRef}
              cash={checkCash}
              expenses={checkTreatmentRight}
              df={checkDF}
              filter={filter}
            />
          </Box>
        </Card.Body>
      </Card>
      <Loading show={loading} type="fullLoading" />
    </div>
  )
}
