import advance from 'locales/plus/en/finance/advance.json'
import incomeExpenses from 'locales/plus/en/finance/income-expenses.json'
import overdue from 'locales/plus/en/finance/overdue.json'
import paymentQueue from 'locales/plus/en/finance/payment-queue.json'
import payment from 'locales/plus/en/finance/payment.json'
import salary from 'locales/plus/en/finance/salary.json'

export const PaymentEN = {
  ...advance,
  ...incomeExpenses,
  ...overdue,
  ...paymentQueue,
  ...payment,
  ...salary
}
