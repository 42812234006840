import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const FormData = async (data: any) => {
  return data
}

export const loadUserProfile = createAsyncThunk('user/loadProfile', async (form: any) => {
  return FormData(form)
})

export const loadUserFormSetup = createAsyncThunk('user/loadUserForm', async (form: any) => {
  return FormData(form)
})

export const loadUserFormInfo = createAsyncThunk('user/loadUserInfo', async (form: any) => {
  return FormData(form)
})

export const loadUserFormSalary = createAsyncThunk('user/loadUserSalary', async (form: any) => {
  return FormData(form)
})

export const loadUserFormEduction = createAsyncThunk('user/loadUserEducation', async (form: any) => {
  return FormData(form)
})

export const loadUserFormSchedule = createAsyncThunk('user/loadUserFormSchedule', async (form: any) => {
  return FormData(form)
})

export const loadUserFormDocument = createAsyncThunk('user/loadUserDocument', async (form: any) => {
  return FormData(form)
})

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    profile: {},
    setup: [null],
    info: [null],
    salary: [null],
    education: [null],
    schedule: [null],
    document: [null],
    loading: true,
    loadingInfo: true,
    loadingSalary: true,
    loadingEducation: true,
    loadingSchedule: true,
    loadingDocument: true,
    loadingProfile: true,
    me: [],
    branchInfo: null
  },
  reducers: {
    editUser: (state, action: any) => {
      state.setup[action.payload.key] = action.payload.value
    },
    editInfo: (state, action: any) => {
      state.info[action.payload.key] = action.payload.value
    },
    editSalary: (state, action: any) => {
      state.salary[action.payload.key] = action.payload.value
    },
    editEducation: (state, action: any) => {
      state.education[action.payload.key] = action.payload.value
    },
    editSchedule: (state, action: any) => {
      state.schedule[action.payload.key] = action.payload.value
    },
    editDocument: (state, action: any) => {
      state.document[action.payload.key] = action.payload.value
    },
    editMe: (state, action: any) => {
      state.me = action.payload.value
    },
    editBranchInfo: (state, action: any) => {
      state.branchInfo = action.payload.value
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadUserFormSetup.pending, (state) => {
        state.loading = true
      })
      .addCase(loadUserFormSetup.fulfilled, (state, action: any) => {
        state.loading = false
        state.setup = action.payload
      })
      .addCase(loadUserFormSetup.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(loadUserFormInfo.pending, (state) => {
        state.loadingInfo = true
      })
      .addCase(loadUserFormInfo.fulfilled, (state, action: any) => {
        state.loadingInfo = false
        state.info = action.payload
      })
      .addCase(loadUserFormInfo.rejected, (state, action) => {
        state.loadingInfo = false
      })
      .addCase(loadUserFormSalary.pending, (state) => {
        state.loadingSalary = true
      })
      .addCase(loadUserFormSalary.fulfilled, (state, action: any) => {
        state.loadingSalary = false
        state.salary = action.payload
      })
      .addCase(loadUserFormSalary.rejected, (state, action) => {
        state.loadingSalary = false
      })
      .addCase(loadUserFormEduction.pending, (state) => {
        state.loadingEducation = true
      })
      .addCase(loadUserFormEduction.fulfilled, (state, action: any) => {
        state.loadingEducation = false
        state.education = action.payload
      })
      .addCase(loadUserFormEduction.rejected, (state, action) => {
        state.loadingEducation = false
      })
      .addCase(loadUserFormSchedule.pending, (state) => {
        state.loadingSchedule = true
      })
      .addCase(loadUserFormSchedule.fulfilled, (state, action: any) => {
        state.loadingSchedule = false
        state.schedule = action.payload
      })
      .addCase(loadUserFormSchedule.rejected, (state, action) => {
        state.loadingSchedule = false
      })
      .addCase(loadUserFormDocument.pending, (state) => {
        state.loadingDocument = true
      })
      .addCase(loadUserFormDocument.fulfilled, (state, action: any) => {
        state.loadingDocument = false
        state.document = action.payload
      })
      .addCase(loadUserFormDocument.rejected, (state, action) => {
        state.loadingDocument = false
      })
      .addCase(loadUserProfile.pending, (state) => {
        state.loadingProfile = true
      })
      .addCase(loadUserProfile.fulfilled, (state, action: any) => {
        state.loadingProfile = false
        state.profile = action.payload
      })
      .addCase(loadUserProfile.rejected, (state, action) => {
        state.loadingProfile = false
      })
  }
})

export const selectUser = (state: any) => state.users

export const selectUserSetup = (state: any) => state.users.setup
export const selectUserInfo = (state: any) => state.users.info
export const selectUserSalary = (state: any) => state.users.salary
export const selectEducation = (state: any) => state.users.education
export const selectSchedule = (state: any) => state.users.schedule
export const selelctDocument = (state: any) => state.users.document
export const selectProfile = (state: any) => state.users.profile
export const selectMe = (state: any) => state.users.me
export const branchInfo = (state: any) => state.users.branchInfo

export const selectLoading = (state: any) => state.users.loading
export const selectLoadingInfo = (state: any) => state.users.loadingInfo
export const selectLoadingSalary = (state: any) => state.users.loadingSalary
export const selectLoadingEducation = (state: any) => state.users.loadingEducation
export const selectLoadingSchedule = (state: any) => state.users.loadingSchedule
export const selectLoadingDocument = (state: any) => state.users.loadingDocument
export const selectLoadingProfile = (state: any) => state.users.loadingProfile

export const { editUser, editInfo, editSalary, editEducation, editSchedule, editDocument, editMe, editBranchInfo } = userSlice.actions
export default userSlice.reducer
