import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface LabCompanyInterface {
  labCompanyName: string
  labCompanyNameEn?: string
  status: string
}

export default class LabCompanyApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('lab-company', { params: { ...props } }).then((res) => res)
  }

  static create(playload: any) {
    return this.api.post('lab-company', playload)
  }

  static findById(id: number): Promise<LabCompanyInterface> {
    return this.api.get(`lab-company/${id}`)
  }

  static update(id: number, body: LabCompanyInterface) {
    return this.api.patch(`lab-company/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`lab-company/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`lab-company/${id}`)
  }
}
