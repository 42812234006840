
import IconProps from "assets/icon/payment-method/iconProps"

export default function ChoosePay(props: IconProps) {
    return (
        <svg width="35" height="30" viewBox="0 0 35 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33 15H12M33 27H12M33 3H12" stroke={props.active || '#9e9e9e'} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.997 6C4.497 6 5.997 4.5 5.997 3C5.997 1.5 4.497 0 2.997 0C1.497 0 0 1.5 0 3C0 4.5 1.497 6 2.997 6ZM2.997 18C4.497 18 5.997 16.5 5.997 15C5.997 13.5 4.497 12 2.997 12C1.497 12 0 13.5 0 15C0 16.5 1.497 18 2.997 18ZM2.997 30C4.497 30 5.997 28.5 5.997 27C5.997 25.5 4.497 24 2.997 24C1.497 24 0 25.5 0 27C0 28.5 1.497 30 2.997 30Z" fill={props.active || '#9e9e9e'} />
        </svg>

    )
} 
