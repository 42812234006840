import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface ExpenseTypesInterface {
  expenseTypeName: string
  expenseGroup: string
  status?: string
}

export default class ExpenseTypesApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('expense-types', { params: { ...props } }).then((res) => res)
  }
  static create(payload: any) {
    return this.api.post('expense-types', payload)
  }
  static findById(id: number): Promise<ExpenseTypesInterface> {
    return this.api.get(`expense-types/${id}`)
  }
  static update(id: number, body: ExpenseTypesInterface) {
    return this.api.patch(`expense-types/${id}`, body)
  }
  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`expense-types/${id}/status`, { id, status })
  }
  static remove(id: number) {
    return this.api.delete(`expense-types/${id}`)
  }
}
