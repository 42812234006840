import { makeStyles } from '@material-ui/core'
import { colors } from 'constants/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    '& .btn-day': {
      backgroundColor: `${colors.lightGray} !important`,
      color: colors.black
    },
    '& .btn-day.active': {
      backgroundColor: `${colors.highlightSelect} !important`,
      color: colors.themeSecondColor
    },
    '& .btn-bank.active': {
      border: `solid 2px ${colors.themeSecondColor}`,
      boxShadow: `0 0 6px 0 ${colors.themeSecondColor}`
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100%)'
    }
  },
  titleFirst: {
    fontSize: '18px',
    fontWeight: 600
  },
  titleSecond: {
    fontSize: '16px'
  },
  menu: {
    fontSize: '16px'
  },
  dropdown: {
    width: '70%',
    backgroundColor: `${colors.white} !important `,
    color: `${colors.themeSecondColor} !important `,
    border: `solid 1px ${colors.themeSecondColor}  !important`,
    '&:hover': {
      backgroundColor: `${colors.themeSecondColor} !important`,
      color: `${colors.white} !important`
    }
  },
  bank: { height: '30px', borderRadius: '3px' },
  columeHover: {
    backgroundColor: '',
    '&:hover': {
      backgroundColor: `${colors.lightGray} !important`
    }
  },
  buttomDayactive: {},
  buttonClearCanvas: {
    position: 'absolute',
    top: '5%',
    left: '73%',
    color: colors.themeThirdColor,
    border: `1px solid ${colors.themeThirdColor}`,
    borderRadius: '4px',
    fontSize: '16px',
    padding: '6px 17px 5px 15px',
    height: ' 32px'
  }
}))

export default useStyles
