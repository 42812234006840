import { useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import FormControl from '@mui/material/FormControl'
import { icons, onImgError } from 'constants/images'
import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'

export const ButtonDelete = styled('div')(({ theme }) => ({
  position: 'absolute',
  cursor: 'pointer',
  right: 8,
  bottom: 8,
  zIndex: 5,
  backgroundColor: 'transparent !important',
  padding: 0,
  img: {
    width: '24px !important',
    height: '24px !important'
  },
}))

type UploadImageProps = {
  pathFile?: any
  setValue: (value: any) => void
  canDelete?: boolean
  onDelete?: () => void
}

const baseStyle = {
  flex: 1,
  display: 'flex',
  width: '100%',
  height: 150,
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: colors.lightGray,
  borderStyle: 'dashed',
  backgroundColor: colors.white,
  color: colors.disabledLightGray,
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer'
}

export default function UploadImage(props: UploadImageProps) {
  const { t } = useTranslation()

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: (acceptedFiles) => {
      props.setValue(acceptedFiles)

    }
  })

  const style: any = useMemo(
    () => ({
      ...baseStyle
    }),
    []
  )

  return (
    <FormControl size="small" margin="none" fullWidth sx={{ position: 'relative' }}>
      <div {...getRootProps({ isDragActive, isDragAccept, isDragReject, style: style })}>
        <input {...getInputProps()} />
        {!props.pathFile ? (
          <div style={{ width: 'auto' }} className="flex-center flex-column h-100">
            <img src={icons.iconAddPhoto} alt="iconAddPhoto" className='upload-image' style={{ width: '70px', height: '70px' }} />
            <p className="pb-0 mb-0 mt-2">{t('UPLOAD_IMAGE')}</p>
          </div>
        ) : (
          <img src={props.pathFile} alt="photoUpload" width="90%" height="90%" onError={onImgError} />
        )}
      </div>
      {(props.pathFile && props.canDelete) && (
        <ButtonDelete
          aria-label="delete"
          onClick={props.onDelete}
          className={'btn-delete'}
        >
          <img src={icons.btnTrash} alt="trash" />
        </ButtonDelete>
      )}
    </FormControl>
  )
}
