import { useCallback, useEffect, useRef, useState } from 'react'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { Box, ClickAwayListener, MenuItem } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'

import { routeName } from 'routes/routes-name'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API, UTIL*/
import { getBranch } from 'utils/app.utils'
import ClinicApi, { BranchInterface } from 'api/master/clinic.api'
import ReportApi, { FindAllReportInterface } from 'api/report.api'

/** COMPONENT */
import Loading from 'component/Loading'
import FucntionPrint from 'component/Print/print'
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import InputCheckbox from 'component/Input/InputCheckbox'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'

/** STYLED */
import * as UseStyled from 'features/report/useStyled'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import PatientsTreatmentList from './PatientsTreatmentList'
import OperativeTypeApi from 'api/setting/treatments/operative-type.api'
import OperativeApi from 'api/setting/treatments/operative.api'
import { CustomFilterDoctor, ListFilterDoctors } from 'features/counter/appointment/head-appointment'
import { SpanClearFilter } from 'features/report/useStyled'
import { colors } from 'constants/theme'
import ButtonCustom from 'component/Button/ButtonCustom'
import { notiError } from 'component/notifications/notifications'

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}

export default function PatientsTreatment() {
  const { t } = useTranslation()
  const history = useHistory()
  const componentRef = useRef<HTMLDivElement>(null)
  const momentNowRange = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  const momentDate = () => momentNowRange

  const [branchId, setBranchId] = useState<number>(1)
  const [branches, setBranches] = useState<any[]>([])

  const [rowCount, setRowCount] = useState<number>(0)
  const [sortBy, setSortBy] = useState<string>('createAt')
  const [sortType, setSortType] = useState<string>('ASC')

  const [patientsTreatmentData, setPatientsTreatmentData] = useState<any[]>([])
  const [patientsTreatmentDataExport, setPatientsTreatmentDataExport] = useState<any[]>([])
  const [patientsTreatmentPage, setPatientsTreatmentPage] = useState<number>(1)
  const [patientsTreatmentTypePage, setPatientsTreatmentTypePage] = useState<number>(1)
  const [patientsTreatmentPageLimit, setPatientsTreatmentPageLimit] = useState<number>(10)

  const [fetchData, setFetchData] = useState<boolean>(false)
  //master data
  const [operatives, setOperative] = useState<any[]>([])
  const [operativeType, setOperativeType] = useState<any[]>([])

  //filter state
  const [operativeId, setOperativeId] = useState<any>(null)
  const [operativeTypeId, setOperativeTypeId] = useState<any>(null)

  const [rangeDate, setRangeDate] = useState<string[]>([moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
  const [openFilterDoctors, setOpenFilterDoctors] = useState(false)
  const [doctors, setDoctors] = useState<Doctors[]>([])
  const [selectDoctors, setSelectDoctors] = useState<any[]>([])

  const [duplicatePatient, setDuplicatePatient] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)
  const filter = [branchId === 0 ? t(`REPORT.FILTER.ALL`) : `${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`]

  //doctor filter
  const handleFilterDoctors = (value: any) => {
    if ((value === 'ALL' && _.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)) || value === 'CLEAR') setSelectDoctors([''])
    else if (value === 'ALL') setSelectDoctors([..._.map(doctors, (d: any) => d.userId)])
    else if (_.includes(selectDoctors, value)) setSelectDoctors(_.filter(selectDoctors, (d) => d !== value))
    else setSelectDoctors([...selectDoctors, value])
  }

  const verifyPermission = async () => {
    const condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 200
    }
    ReportApi.reportList(condition).then(({ data }) => {
      const check = _.find(data, (val: any) => String(val.reportKey) === 'PATIENT_TREATMENT') ? true : false
      if (!check) {
        history.push(routeName.report)
        return false
      }
    })

    ClinicApi.findAllBranches()
      .then(({ data }) => setBranches(data))
      .catch((e) => {
        return
      })

    ClinicApi.findAllDoctors()
      .then((resp) => {
        setDoctors(resp.data)
        const userIds = resp.data.map((doctor: any) => doctor.userId)
        setSelectDoctors([...userIds])
      })
      .catch((e) => {
        return
      })

    OperativeApi.findAll({ export: 1 })
      .then(({ data }) => {
        setOperative([{ operativeName: t('REPORT.FILTER.OPERATION_ALL'), operativeId: 0 }, ...data])
      })
      .catch((e) => {
        return
      })

    OperativeTypeApi.findAll({ export: 1 })
      .then(({ data }) => {
        setOperativeType([{ operativeTypeName: t('REPORT.FILTER.TYPE_OPERATION_ALL'), operativeTypeId: 0 }, ...data])
      })
      .catch((e) => {
        return
      })
    return true
  }

  useEffect(() => {
    verifyPermission()
  }, [fetchData])

  const fetchPatientTreatmentExport = useCallback(async () => {
    setLoading(true)

    let condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 10000,
      branchId: branchId,
      export: 1,
      duplicatePatient: duplicatePatient,
      dateStart: rangeDate[0],
      dateEnd: rangeDate[1]
    }
    if (operativeTypeId?.operativeTypeId) condition = { ...condition, operativeTypeId: operativeTypeId.operativeTypeId }
    if (operativeId?.operativeId) condition = { ...condition, operativeId: operativeId.operativeId }
    if (doctors) {
      const doctor = _.filter(selectDoctors, (v: any) => v > 0).join(',')
      if (doctor) condition = { ...condition, doctorId: _.filter(selectDoctors, (v: any) => v > 0).join(',') }
    }
    await ReportApi.reportPatientTreatment(condition)
      .then(({ headers, data }) => {
        setPatientsTreatmentDataExport(data)
      })
      .catch((e) => {
        setLoading(false)
      })
      .finally(() => setLoading(false))
    setFetchData(false)
  }, [fetchData])

  const fetchPatientTreatment = useCallback(() => {
    setLoading(true)
    let condition: FindAllReportInterface = {
      page: patientsTreatmentPage,
      pageLimit: patientsTreatmentPageLimit,
      branchId: branchId,
      duplicatePatient: duplicatePatient,
      dateStart: rangeDate[0],
      dateEnd: rangeDate[1]
    }

    if (operativeTypeId?.operativeTypeId) condition = { ...condition, operativeTypeId: operativeTypeId.operativeTypeId }
    if (operativeId?.operativeId) condition = { ...condition, operativeId: operativeId.operativeId }
    if (doctors) {
      const doctor = _.filter(selectDoctors, (v: any) => v > 0).join(',')
      if (doctor) condition = { ...condition, doctorId: _.filter(selectDoctors, (v: any) => v > 0).join(',') }
    }

    ReportApi.reportPatientTreatment(condition)
      .then(({ headers, data }) => {
        setPatientsTreatmentData(data)
        setRowCount(headers['x-total'])
      })
      .catch((e) => {
        setLoading(false)
      })
      .finally(() => setLoading(false))
    setFetchData(false)
  }, [fetchData, patientsTreatmentPage, patientsTreatmentPageLimit])

  const { onDownload } = useDownloadExcel({
    tableExportRef: componentRef.current,
    filename: `${t(`REPORT.REPORT_NAME.PATIENT_TREATMENT`)}`,
    sheetName: `${t(`REPORT.REPORT_NAME.PATIENT_TREATMENT`)}`
  })

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }
  const handleChangeRowsPerPage = (limit: number) => setPatientsTreatmentPageLimit(limit)
  const handleChangePage = (val: number) => setPatientsTreatmentPage(val)
  useEffect(() => {
    fetchPatientTreatment()
    fetchPatientTreatmentExport()
  }, [fetchPatientTreatment])

  const onSubmit = () => {
    if (rangeDate[0] === '' || rangeDate[1] === '') {
      notiError(t(`REPORT.MESSAGE.INVALID_DATE`))
    } else {
      setFetchData(true)
      setLoading(true)
      setPatientsTreatmentPage(1)
    }
  }

  return (
    <div>
      <Card className={'border-0 h-100'}>
        <HeaderCard text={t(`REPORT.REPORT_NAME.PATIENT_TREATMENT`)} />
        <Card.Body>
          <Card.Title className={'mb-0'}>
            <Row className="mx-0">
              <Col sm={4} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setBranchId(e.target.value)
                    setPatientsTreatmentPage(1)
                  }}
                  renderValue={() => (branchId === 0 ? `${t('REPORT.FILTER.BRANCH')}: ${t('REPORT.REPORT_GROUP.ALL')}` : `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`)}
                  label={''}
                  selectId="select-branch"
                  labelId="label-branch"
                  value={branchId}
                  options={[
                    ..._.map(branches, (b: BranchInterface, index) => (
                      <MenuItem key={b.branchId} value={b.branchId}>
                        {b.branchName || b.branchNameEn}
                      </MenuItem>
                    ))
                  ]}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>

              {/* Doctor filter */}
              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <ClickAwayListener onClickAway={() => setOpenFilterDoctors(false)}>
                  <CustomFilterDoctor open={openFilterDoctors} onClick={() => setOpenFilterDoctors(true)}>
                    <AutocompleteSelect
                      open={openFilterDoctors}
                      multiple
                      labelId="label-doctors"
                      options={['ALL', ...doctors, 'CLEAR']}
                      getOptionLabel={(option) => _.find(doctors, { userId: option.userId })?.fullname || '-'}
                      renderOption={(props, option) => {
                        if (option === 'ALL') {
                          return (
                            <ListFilterDoctors {...props} key={option} component="li" value={option}>
                              <InputCheckbox
                                label={t('REPORT.FILTER.ALL')}
                                checked={_.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)}
                                indeterminate={_.size(selectDoctors) > 1 && _.size(selectDoctors.filter((item) => typeof item === 'number')) !== _.size(doctors)}
                                className={'pe-none'}
                              />
                            </ListFilterDoctors>
                          )
                        } else if (option === 'CLEAR') {
                          return (
                            <Box {...props} key={option} component="li" value={option} sx={{ borderTop: `1px solid ${colors.lightGray}` }}>
                              <SpanClearFilter className="pe-none">{t('REPORT.FILTER.CLEAR_FILTER')}</SpanClearFilter>
                            </Box>
                          )
                        } else {
                          return (
                            <ListFilterDoctors {...props} key={option.userId} component="li" value={option.userId}>
                              <InputCheckbox label={option.fullname} checked={_.includes(selectDoctors, option.userId)} className={'pe-none'} />
                            </ListFilterDoctors>
                          )
                        }
                      }}
                      renderTags={(tagValue, getTagProps) => {
                        return (
                          <>
                            <span className="pr-1">{t('USER_DOCTOR')}:</span>
                            {!openFilterDoctors &&
                              ((_.size(tagValue.filter((item: any) => typeof item === 'number')) === _.size(doctors) && <span>{t('REPORT.REPORT_GROUP.ALL')}</span>) || (
                                <Box className="text-ellipsis">
                                  {_.map(tagValue, (option: any, index: number) => {
                                    return (
                                      <span>
                                        {_.find(doctors, { userId: option })?.fullname}
                                        {index !== _.findLastIndex(tagValue) && option && `,`}
                                      </span>
                                    )
                                  })}
                                </Box>
                              ))}
                          </>
                        )
                      }}
                      onchange={(e, value) => handleFilterDoctors(e?.target?.value || e?.target?.attributes?.value?.value)}
                      noOptionsText={t('NOT_FOUND')}
                      filterOptions={(option) => 'ALL' + option.fullname + 'CLEAR'}
                      value={selectDoctors}
                      height={32}
                      disableClearable
                    />
                  </CustomFilterDoctor>
                </ClickAwayListener>
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <InputSecondNewRangePicker
                  inputHeight={32}
                  value={rangeDate || emptyRangePicker}
                  onchange={(val: any) => {
                    if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                    else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                  }}
                  allowClear
                  onClear={() => setRangeDate(emptyRangePicker)}
                  label={''}
                />
              </Col>

              <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                <InputCheckbox
                  onChange={() => {
                    setDuplicatePatient(!duplicatePatient)
                  }}
                  value="true"
                  label={t('REPORT.FILTER.DUPLICATE_PATIENT')}
                  checked={duplicatePatient}
                  style={{ marginTop: { sm: '-3px' }, marginLeft: { sm: 0 } }}
                />
              </Col>

              <Col sm={5} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setOperativeId({ operativeName: t('REPORT.FILTER.OPERATION_ALL'), operativeId: 0 })
                    setOperativeTypeId(e.target.value)
                  }}
                  renderValue={() => `${t('REPORT.TABLE.FOLLOW_UP.OPERATIVE_TYPE')}: ${operativeTypeId ? operativeTypeId.operativeTypeName : t('REPORT.FILTER.TYPE_OPERATION_ALL')}`}
                  label={''}
                  selectId="select-operativeType"
                  labelId="label-operativeType"
                  value={operativeTypeId || { operativeTypeName: t('REPORT.FILTER.TYPE_OPERATION_ALL'), operativeTypeId: 0 }}
                  options={_.map([...operativeType], (b: any, index) => {
                    return (
                      <MenuItem key={b.operativeTypeId} value={b}>
                        {b.operativeTypeName}
                      </MenuItem>
                    )
                  })}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>

              <Col sm={5} md={4} xl={3} className={'pt-2 px-1'}>
                <FilterSelect
                  onchange={(e) => {
                    setOperativeId(e.target.value)
                  }}
                  renderValue={() => `${t('REPORT.TABLE.FOLLOW_UP.OPERATIVE')}: ${operativeId ? operativeId.operativeName : t('REPORT.FILTER.OPERATION_ALL')}`}
                  label={''}
                  selectId="select-operative"
                  labelId="label-operative"
                  disabled={operativeTypeId ? false : true}
                  value={operativeTypeId || { operativeName: t('REPORT.FILTER.OPERATION_ALL'), operativeId: 0 }}
                  options={[
                    <MenuItem key={0} value={0}>
                      {t(`REPORT.FILTER.OPERATION_ALL`)}
                    </MenuItem>,
                    ..._.map(
                      [...operatives].filter((item) => item.operativeTypeId === operativeTypeId?.operativeTypeId),
                      (b: any, index) => {
                        return (
                          <MenuItem key={b.operativeId} value={b}>
                            {b.operativeName}
                          </MenuItem>
                        )
                      }
                    )
                  ]}
                  formControlStyle={{ maxWidth: 'unset !important' }}
                  classesOption="style-select-doctors"
                />
              </Col>
              <Col md={1} xl={1} className={'pt-0'}>
                <ButtonCustom
                  onClick={() => {
                    onSubmit()
                  }}
                  textButton={t('INPUT.SEARCH')}
                  style={{ height: '30px', marginTop: '5px' }}
                />
              </Col>

              <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                <UseStyled.DropdownDocument>
                  <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
                    {t('PRINT_DOCUMENT')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        onDownload()
                      }}
                    >
                      {t('Excel')}
                    </Dropdown.Item>
                    <FucntionPrint
                      content={() => componentRef.current}
                      removeIfram
                      trigger={() => <Dropdown.Item>{t('PDF')}</Dropdown.Item>}
                      style={`@page { size: 1122.519685px 793.7007874px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                      beforePrint={() => fetchPatientTreatmentExport()}
                    />
                  </Dropdown.Menu>
                </UseStyled.DropdownDocument>
              </Col>
            </Row>
          </Card.Title>
          <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
            <PatientsTreatmentList
              page={patientsTreatmentPage}
              pageLimit={patientsTreatmentPageLimit}
              rowCount={rowCount}
              sortBy={sortBy}
              sortType={sortType}
              data={patientsTreatmentData}
              dataExport={patientsTreatmentDataExport}
              onRequestSort={onRequestSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              componentRef={componentRef}
              headCellsSeconds={filter}
            />
          </Box>
        </Card.Body>
      </Card>
      <Loading show={loading} type="fullLoading" />
    </div>
  )
}
