import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { Button, Grid, Typography } from '@mui/material'
import { colors, templateImage } from 'constants/theme'
import { Config } from 'config'

export const CardLogin = styled(`div`)(({ theme }) => ({
  position: 'relative',
  backgroundColor: ` ${colors.bgBase} !important`,
  height: '100%',
  width: '100%',
  backgroundImage: `url(${Config.REACT_APP_BACKGROUND || templateImage.login})`,
  background: `url(${Config.REACT_APP_BACKGROUND || templateImage.login}), ${colors.bgBase}`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: Config.REACT_APP_BACKGROUND ? 'cover' : 'auto 90%',
  color: 'unset !important',
  backgroundPosition: Config.REACT_APP_BACKGROUND ? 'center' : '6% bottom',
  '& .card-text': {
    fontSize: '16px',
    textAlign: 'left'
  },
  [theme.breakpoints.down('lg')]: {
    backgroundSize: Config.REACT_APP_BACKGROUND ? 'cover' : 'auto 75%'
  },
  [theme.breakpoints.down('md')]: {
    backgroundSize: Config.REACT_APP_BACKGROUND ? 'cover' : 'auto 50%'
  },
  [theme.breakpoints.down(960)]: {
    backgroundImage: 'none',
    backgroundSize: Config.REACT_APP_BACKGROUND ? 'cover' : 'auto 25%'
  }
}))

export const CardFormLogin = styled(Grid)(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: '15%',
  maxWidth: 450,
  [theme.breakpoints.down('md')]: {
    marginRight: 'auto',
    'img[alt="Logo"]': {
      maxWidth: 200
    }
  },
  [theme.breakpoints.up('xl')]: {
    marginRight: '20%',
    textAlign: 'center'
  }
}))
export const TitalForgetPassword = styled(Typography)(({ theme }) => ({
  fontFamily: 'Kanit',
  marginTop: '24px !important',
  marginBottom: '0 !important',
  fontSize: '1.125rem !important',
  fontWeight: 600
}))
export const ButtonGoBack = styled(Button)(({ theme }) => ({
  paddingTop: 16,
  fontSize: 16,
  '&:hover': {
    backgroundColor: 'unset !important',
    textDecoration: 'underline !important'
  }
}))
export const ButtonForgetPassword = styled('a')(({ theme }) => ({
  color: colors.textPrimary,
  height: '100%',
  display: 'flex',
  margin: 'auto',
  fontSize: '16px',
  cursor: 'pointer',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    color: `${colors.themeSecondColor} !important`
  }
}))

export const Logo = styled('div')(({ theme }) => ({
  maxWidth: 250,
  margin: '0 auto 20px',
  '.logo-wrap': {
    position: 'relative',
    paddingTop: '75%',
    img: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    },
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: '16px'
  }
}))

export const License = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    paddingTop: 16
  }
}))

export const SectionWatermark = styled('div')(({ theme }) => ({
  display: Config.REACT_APP_BACKGROUND ? 'flex' : 'none',
  position: 'absolute',
  zIndex: 1,
  left: 16,
  flexDirection: 'row',
  gap: 20,
  bottom: 16,
  [theme.breakpoints.down(960)]: {
    display: 'none'
  }
}))
export const Watermark = styled('img')(({ theme }) => ({
  display: Config.REACT_APP_BACKGROUND ? 'block' : 'none',
  // position: 'absolute',
  zIndex: 1,
  left: '16px',
  bottom: '16px',
  maxWidth: 120,
  opacity: .5,
  [theme.breakpoints.down(960)]: {
    display: 'none'
  }
}))
export const CliniterWatermark = styled('img')(({ theme }) => ({
  display: Config.REACT_APP_BACKGROUND ? 'block' : 'none',
  // position: 'absolute',
  zIndex: 1,
  left: '16px',
  bottom: '16px',
  maxWidth: 120,
  height: 24,
  opacity: .5,
  [theme.breakpoints.down(960)]: {
    display: 'none'
  }
}))

const useStyles = makeStyles({
  login: {
    justifyItems: 'center',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center',
    display: 'flex'
  },
  loginText: {
    '&.login-text': {
      fontFamily: 'Kanit',
      fontSize: '18px',
      fontWeight: 500,
      marginBottom: '10px',
      marginTop: '10px'
    }
  },

  typographySuccess: {
    fontFamily: 'Kanit',
    fontSize: '16px',
    textAlign: 'left',
    marginBottom: '20px',
    color: colors.textLightGray
  },
  flex: {
    display: 'flex'
  },
  inputCheckBox: {
    '& $label': {
      fontFamily: 'Kanit',
      fontSize: '16px',
      fontWeight: 500
    },
    '& $span': {
      fontSize: '16px'
    }
  },
  card: {
    backgroundColor: colors.white,
    margin: 'auto',
    maxWidth: 400,
    width: '100%',
    borderRadius: `10px !important`,
    opacity: 1,
    '& .card-body': {
      backgroundColor: colors.white,
      borderRadius: 10
    },
    '&.forget-password .btn-submit': {
      lineHeight: '44px',
      marginTop: 14,
      boxShadow: 'none',
      fontSize: '1rem'
    }
  },
  cardBody: {
    backgroundColor: colors.white,
    margin: 'auto',
    width: '300px',
    padding: '30px 15px'
  },
  checked: {
    color: colors.themeSecondColor
  },

  license: {
    padding: 20
  }
})

export default useStyles
