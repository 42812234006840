import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface TemplateDoctorFeeOperatives {
  operativeId: number
  creditAmount: number
  creditBranch?: number
  creditUnit: "PERCENT" | "BAHT"
}

export interface TemplateCreditFeeOperativeType {
  operativeTypeId: number
  creditAmount: number
  creditBranch?: number
  templateCreditFeeOperatives: TemplateDoctorFeeOperatives[]
}
export interface TemplateCreditFee {
  templateCreditFeeName: string
  templateClinicFeeAmount?: number
  creditFee?: number
  amount: number
  isMain: '0' | '1'
  status?: string
  templateCreditFeeOperativeTypes: TemplateCreditFeeOperativeType[]
}

export default class TemplateCreditFeeApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('template-credit-fees', { params: { ...props } }).then((res) => res)
  }

  static create(payload: TemplateCreditFee) {
    return this.api.post('template-credit-fees', payload)
  }

  static findById(id: number): Promise<any> {
    return this.api.get(`template-credit-fees/${id}`)
  }

  static update(id: number, body: any) {
    return this.api.patch(`template-credit-fees/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`template-credit-fees/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`template-credit-fees/${id}`)
  }

  static findAllWithOperatives(id?: number): Promise<any> {
    return this.api.get('template-credit-fees/operatives', { params: { templateCreditFeeId: id } }).then((res) => res)
  }
}
