import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import MenuItem from '@mui/material/MenuItem'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

/** API */
import { routeName } from 'routes/routes-name'
import ImportInventoryApi from 'api/warehouses/import-inventory.api'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/warehouses/import-products/style'

/** UTILS */
import { showModal } from 'app/slice/modal.slice';
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'

/** COMPONENT */
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import FilterSelect from 'component/Select/FilterSelect'
import InputSecondNewRangePicker from 'component/Input/InputSecondNewRangePicker'
import PopupCancelImport from 'features/warehouses/import-products/popupCancelImport'
import { dateToView } from '../../../utils/date.utils';

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

const initStateErrorMessage = {
  username: '',
  password: '',
  cancelRemark: ''
}

export default function ImportProduct() {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const [documents, setDocuments] = useState([])
  const [document, setDocument]: any = useState({})
  const [popupCancel, setPopupCancel] = useState(false);

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('ALL')
  const [rangeDate, setRangeDate] = useState(['', '']);

  const loadData = useCallback(async () => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (rangeDate) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    condition = { ...condition, status: status === "ALL" ? '' : status }

    const res = await ImportInventoryApi.findAllDocument(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setDocuments(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status, rangeDate])

  useEffect(() => {
    loadData()
  }, [loadData])

  const onCreate = () => {
    window.location.href = routeName.importProduct + '/create'
  }

  const onList = (data: any) => {
    history.push(routeName.importProduct + '/list', data)
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }
  const handleChangeStatus = (statusVal: string) => {
    setStatus(statusVal)
  }

  const onCancel = (detail: any) => {
    setDocument(detail)
    dispatch(showModal())
    setPopupCancel(true)
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'date', disablePadding: false, label: t('IMPORT_PRODUCT.TABLE.DATE') },
    { id: 'documentNo', disablePadding: false, label: t('IMPORT_PRODUCT.TABLE.DOCUMENT_NO') },
    { id: 'subject', disablePadding: false, label: t('IMPORT_PRODUCT.TABLE.DOCUMENT_NAME') },
    { id: 'status', disablePadding: false, label: t('IMPORT_PRODUCT.TABLE.STATUS') },
    { id: 'updatedBy', disablePadding: false, label: t('IMPORT_PRODUCT.TABLE.UPDATEDBY') },
    { id: 'action', disablePadding: false, label: 'Action' }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { inventoryDocumentId, documentNo, prefix, date, subject, updatedBy, updatedAt, status: importProductStatus } = objData

    const statusBtnActive = importProductStatus === 'ACTIVE' ? permissions.isUpdate.disabled : true
    const objRenderData = {
      key: inventoryDocumentId,
      id: inventoryDocumentId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no, class: 'cursor-pointer' },
        { option: 'TEXT', align: 'left', label: dateToView(date), class: 'cursor-pointer' },
        { option: 'TEXT', align: 'left', label: ((prefix && documentNo) && `${prefix}-${documentNo}`) || documentNo || '-', class: 'cursor-pointer' },
        { option: 'TEXT', align: 'left', label: subject, class: 'cursor-pointer' },
        { option: 'TEXT', align: 'center', label: t(`STATUS_NO_ICON.${importProductStatus}`), class: 'cursor-pointer' },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt }, class: 'cursor-pointer' },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_INACTIVE', label: t(`IMPORT_PRODUCT.TABLE.CANCEL_DOCUMENT`), disabled: statusBtnActive }
          ]
        }
      ]
    }
    return <TableRowCommon {...objRenderData} onClick={() => onList(objRenderData.obj)} oninactive={() => onCancel(objRenderData.obj)} />
  }

  return (
    <div className={classes.root}>
      <Card className="border-0 h-100">
        <HeaderCard text={t('IMPORT_PRODUCT.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row>
              <Col lg={9}>
                <Row className="">
                  <Col sm={12} md={6} xl={4} className="pt-2">
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('INPUT.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={4} className="pl-md-0 pt-2">
                    <FilterSelect
                      onchange={(event) => {
                        const value = event.target.value
                        if (value) {
                          handleChangeStatus(value)
                          setPage(1)
                        }
                      }}
                      renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS_NO_ICON.${status}`)}`}
                      label={t('STATUS.LABEL')}
                      selectId="select-status"
                      value={status}
                      labelId="label-status"
                      options={[
                        <MenuItem key="1" value="ALL">
                          {t('STATUS.ALL')}
                        </MenuItem>,
                        <MenuItem key="2" value="ACTIVE">
                          {t('STATUS_NO_ICON.ACTIVE')}
                        </MenuItem>,
                        <MenuItem key="3" value="INACTIVE">
                          {t('STATUS_NO_ICON.INACTIVE')}
                        </MenuItem>
                      ]}
                      formControlStyle={{ maxWidth: 'unset !important' }}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={4} className="pl-xl-0 pt-2">
                    <InputSecondNewRangePicker
                      allowClear
                      value={rangeDate}
                      onchange={(value: any) => {
                        setPage(1)
                        setRangeDate([value[0] ? moment(value[0]).format('YYYY-MM-DD') : '', value[1] ? moment(value[1]).format('YYYY-MM-DD') : ''])
                      }}
                      onClear={() => {
                        setPage(1)
                        setRangeDate(['', ''])
                      }}
                      inputHeight={32}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={3} className="mt-2 mt-lg-auto">
                <div className="pr-xl-2">
                  <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => onCreate()} textButton={t('IMPORT_PRODUCT.BUTTON.ADD_IMPORT_PRODUCT')} className="w-auto ml-auto d-flex mt-auto" />
                </div>
              </Col>
            </Row>
          </Card.Title>

          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={documents.map((val, i) => {
                return renderData(val, i)
              })}
            />
          </div>
        </Card.Body>
      </Card>

      {popupCancel && (
        <PopupCancelImport
          inventoryDocumentId={document.inventoryDocumentId}
          document={{ prefix: document.prefix, documentNo: document.documentNo }}
          onCancel={(show: boolean) => {
            setPopupCancel(show)
            setDocument({})
          }}
          onAfterSubmit={(show: boolean) => {
            setPopupCancel(show)
            setDocument({})
            loadData()
          }}
        />
      )}
    </div>
  )
}
