import { makeStyles } from '@material-ui/core'
import { Col } from 'react-bootstrap'
import { styled } from '@mui/material/styles'

import { colors } from 'constants/theme'
import { Typography, Button } from '@mui/material'

export const ColLabType = styled(Col)(({ theme }) => ({
  paddingBottom: 24
}))

export const TitleLab = styled(Typography)(({ theme }) => ({
  fontWeight: 600, color: colors.themeMainColor,
  paddingBottom: '0.75rem',
  fontSize: 18
}))

export const HeaderTable = styled(Typography)(({ theme }) => ({
  fontSize: 16
}))

export const AddNewRowTable = styled(Button)(({ theme }) => ({
  padding: '0 0 0 14px',
  fontSize: 16,
  color: colors.themeSecondColor,
  backgroundColor: 'transparent !important',
  fontWeight: 400,
  span: {
    fontSize: 20,
    color: colors.themeSecondColor
  },
  '&:hover': {
    fontWeight: 600
  }
}))

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',

    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.borderInput
    }
  },
  header: {
    backgroundColor: colors.themeMainColor,
    color: `${colors.white} !important`,
    '& $th': {
      backgroundColor: colors.themeMainColor,
      color: `${colors.white} !important`,
      padding: 15
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: `${colors.white} !important`
    },
    '& .MuiTableCell-root.MuiTableCell-head': {
      color: `${colors.white} !important`,
      fontSize: 16
    },

    '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: `${colors.white} !important`
    }
  },
  body: {
    borderRight: `1px solid ${colors.black20} !important`,
    borderLeft: `1px solid ${colors.black20} !important`,
    '& .sm-data-table .MuiTableCell-root': {
      padding: '10px 10px !important'
    },
    '& .MuiTableRow-root:hover': {
      '& .MuiTableCell-body': {
        backgroundColor: `${colors.extraLightGray} !important`,
      }
    }
  },
  titleFirst: {
    fontSize: '18px',
    fontWeight: 600
  },
  titleSecond: {
    fontSize: '16px'
  },
  columnHover: {
    cursor: 'pointer',
    fontSize: '16px',
    backgroundColor: '',
    '&:hover': {
      backgroundColor: `${colors.extraLightGray} !important`,
      '& .add-new-row': {
        fontWeight: 500
      }
    }
  },
  inputDisable: {
    backgroundColor: colors.themeThirdColor
  },
  btnSidebar: {

    padding: '3px !important',
    width: 'fit-contant',
    border: `solid 1px ${colors.themeSecondColor}  !important`,
    borderRadius: '10px 0px 0px 10px !important'
  },
  btnDiscard: {

    padding: '3px !important',
    width: 'fit-contant',
    backgroundColor: `${colors.white}  `,

    border: `solid 1px ${colors.themeSecondColor}  !important`
  },
  icon: {
    '&.btn-delete': { marginRight: '5px' }
  }
}))

export default useStyles
