import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/81.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethL, TextNumberL } from 'component/ToothChart/style'
import useWindowSize from 'utils/useWindowSize';

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginBottom: 'auto',
    top: '1.4%',
    maxWidth: '65px',
}))

export default function Teeth41(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)

    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 25.91, height: 93.76, viewBox: "0 0 25.91 110" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);
    const [width] = useWindowSize()
    useEffect(() => {
        const operative = props.operative || ``
        const valColor = props.color || colors.themeSecondColor
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }

        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        return setStyleSvg({ width: 25.91, height: 93.76, viewBox: "0 0 25.91 110" })

    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <TextNumberL>81</TextNumberL>
            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q4} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <DivTeethL className={`md div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray position='L' />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge position='L' width={width > 1440 ? 70 : 100} />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12352">

                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ""}

                            <path
                                data-name="Path 5571"
                                d="M0.390625 13.8237C0.610057 15.3076 0.835993 16.7272 1.04833 18.0658C2.17829 22.4152 3.04426 26.829 3.64153 31.2829C5.27781 42.9357 5.5719 54.8386 7.17351 66.4994C8.30278 74.7488 9.95994 82.8132 12.7381 90.5221C13.5118 92.6835 14.3263 94.8288 16.797 94.0874C18.0289 93.201 18.1595 91.8777 18.2483 90.5065C19.4674 70.7245 20.4042 50.9034 22.8542 31.2759C23.6831 24.6633 24.6818 18.0749 25.9208 11.5191C27.3721 3.82559 24.6404 0.494915 17.9534 0.478763C12.8687 0.470621 7.78565 0.470621 2.70431 0.478763V1.29326C8.02957 1.29326 13.3475 1.30154 18.6726 1.28499C21.3909 1.27692 23.4708 2.65653 22.854 6.027C21.2388 14.7844 20.1992 23.8167 16.5784 31.8402C13.9769 37.598 9.92508 37.4611 6.82388 31.9454C4.18892 27.2443 2.93005 22.0107 2.46479 16.4461C2.34148 14.9459 2.56013 13.1879 0.986286 12.2528C0.67385 12.7259 0.470394 13.2625 0.390625 13.8237Z"

                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />

                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5571"
                                    d="M0.390625 13.8237C0.610057 15.3076 0.835993 16.7272 1.04833 18.0658C2.17829 22.4152 3.04426 26.829 3.64153 31.2829C5.27781 42.9357 5.5719 54.8386 7.17351 66.4994C8.30278 74.7488 9.95994 82.8132 12.7381 90.5221C13.5118 92.6835 14.3263 94.8288 16.797 94.0874C18.0289 93.201 18.1595 91.8777 18.2483 90.5065C19.4674 70.7245 20.4042 50.9034 22.8542 31.2759C23.6831 24.6633 24.6818 18.0749 25.9208 11.5191C27.3721 3.82559 24.6404 0.494915 17.9534 0.478763C12.8687 0.470621 7.78565 0.470621 2.70431 0.478763V1.29326C8.02957 1.29326 13.3475 1.30154 18.6726 1.28499C21.3909 1.27692 23.4708 2.65653 22.854 6.027C21.2388 14.7844 20.1992 23.8167 16.5784 31.8402C13.9769 37.598 9.92508 37.4611 6.82388 31.9454C4.18892 27.2443 2.93005 22.0107 2.46479 16.4461C2.34148 14.9459 2.56013 13.1879 0.986286 12.2528C0.67385 12.7259 0.470394 13.2625 0.390625 13.8237Z"

                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}
                            <g data-name="Group 12352">
                                <path
                                    data-name="Path 5572"
                                    d="M0.644258 10.5309C0.883781 12.5899 1.1367 14.5853 1.38981 16.49C1.60944 18.159 1.83518 19.7553 2.04752 21.261C3.17971 26.166 4.04538 31.1286 4.64072 36.1272C5.12593 37.1588 5.79928 38.0909 6.62624 38.8756C6.62624 38.8756 10.3905 43.1567 13.4708 42.6576C16.5509 42.1679 20.8015 41.3969 23.8534 36.118L23.8607 36.1089C23.8607 36.1089 32.2107 5.35141 25.106 1.98646C18.0085 -1.36962 3.03969 0.7981 3.03969 0.7981C3.03969 0.7981 -0.444235 1.12489 0.644258 10.5309Z"

                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5573"
                                    d="M2.26942 11.7193C2.48255 13.5534 2.70848 15.3303 2.93421 17.0273C3.12922 18.513 3.33073 19.9354 3.51924 21.2758C4.52759 25.6443 5.29877 30.0641 5.82938 34.5158C6.26129 35.4344 6.86084 36.2644 7.59724 36.9631C7.59724 36.9631 10.9496 40.7761 13.6925 40.3314C16.4364 39.8949 20.2213 39.2086 22.9396 34.5072L22.946 34.4991C22.946 34.4991 30.3827 7.10653 24.0554 4.10973C17.7342 1.12081 4.40327 3.05255 4.40327 3.05255C4.40327 3.05255 1.30069 3.34271 2.26942 11.7193Z"

                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5574"
                                    d="M3.58287 14.3235C3.77059 15.936 3.96835 17.499 4.1669 18.9901C4.33847 20.2982 4.51536 21.548 4.68121 22.7271C5.5684 26.5687 6.2466 30.4556 6.71283 34.3706C7.0926 35.1786 7.61988 35.9085 8.26756 36.523C8.26756 36.523 11.2163 39.8763 13.6291 39.4863C16.0408 39.1026 19.3705 38.4987 21.7614 34.3633L21.767 34.357C21.767 34.357 28.3066 10.2668 22.742 7.63125C17.1827 5.0028 5.45848 6.70053 5.45848 6.70053C5.45848 6.70053 2.73075 6.95641 3.58287 14.3235Z"

                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />

                                {(isToothRoot && props.type === 'treatment') ?
                                    <path
                                        data-name="Path 6569"
                                        d="M3.58988 2.06543C3.59984 2.32701 3.47928 8.44177 3.69772 14.5637C3.80003 17.4643 6.67132 54.1286 6.51095 54.326C6.52864 54.4814 3.27411 17.5484 3.09327 14.5654C2.71334 8.2919 2.38596 2.21143 2.36328 2.06709C2.77093 2.11232 3.18236 2.11176 3.58988 2.06543Z"
                                        transform="translate(10 40)"
                                        style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }}
                                    />
                                    : ''}
                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <g data-name="Group 9472">
                                            <path data-name="Path 6581" d="m33.013 7.5-26.767 72" transform="translate(-8.317 -1.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                            <path data-name="Path 6582" d="m7.012 7.5 26.776 72" transform="translate(-8.317 -1.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                        </g>
                                    </>
                                    : ''}

                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <defs>
                                            <linearGradient id="paint0_linear_4107_155444" x1="14.4809" y1="18.273" x2="12.5882" y2="29.0764" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4107_155444" x1="14.4887" y1="26.4098" x2="14.4887" y2="22.4029" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>
                                        <g data-name="Group 9369">
                                            <path d="M1.03906 14.4994C1.25517 15.9607 1.47768 17.3588 1.68681 18.6772C2.79964 22.9607 3.65249 27.3076 4.2407 31.694C5.85219 43.1702 6.14182 54.8927 7.71917 66.3769C8.83133 74.5012 10.4634 82.4434 13.1994 90.0355C13.9614 92.1642 14.7636 94.277 17.1968 93.5468C18.4101 92.6738 18.5387 91.3706 18.6262 90.0202C19.8268 70.538 20.7494 51.0172 23.1623 31.687C23.9786 25.1747 24.9621 18.6861 26.1823 12.2297C27.6117 4.65272 24.9214 1.37251 18.3358 1.3566C13.3281 1.34858 8.32204 1.34858 3.31769 1.3566V2.15876C8.56226 2.15876 13.7997 2.16691 19.044 2.15061C21.7211 2.14266 23.7695 3.50137 23.1621 6.82077C21.5714 15.4455 20.5475 24.341 16.9815 32.2428C14.4195 37.9134 10.429 37.7786 7.37484 32.3464C4.77981 27.7166 3.54001 22.5622 3.0818 17.082C2.96036 15.6045 3.17569 13.8731 1.6257 12.9523C1.318 13.4182 1.11762 13.9466 1.03906 14.4994Z" fill="#E3D1B4" />
                                            <path d="M0.305128 10.2714C0.541022 12.2992 0.790109 14.2644 1.03939 16.1403C1.25569 17.784 1.478 19.3561 1.68713 20.839C2.80216 25.6696 3.65471 30.5571 4.24103 35.4799C4.71889 36.4959 5.38204 37.4138 6.19647 38.1867C6.19647 38.1867 9.90365 42.4029 12.9373 41.9113C15.9707 41.4291 20.1569 40.6698 23.1626 35.4708L23.1698 35.4619C23.1698 35.4619 31.3933 5.17042 24.3962 1.85646C17.4063 -1.44878 2.66427 0.686101 2.66427 0.686101C2.66427 0.686101 -0.766871 1.00794 0.305128 10.2714Z" fill="#EFEDE7" />
                                            <path d="M1.89939 11.443C2.10929 13.2492 2.3318 14.9992 2.55411 16.6705C2.74617 18.1337 2.94462 19.5346 3.13027 20.8547C4.12334 25.1569 4.88284 29.5097 5.40541 33.894C5.83078 34.7987 6.42124 35.6161 7.14649 36.3042C7.14649 36.3042 10.448 40.0595 13.1494 39.6214C15.8517 39.1916 19.5792 38.5157 22.2563 33.8855L22.2627 33.8775C22.2627 33.8775 29.5867 6.90006 23.3553 3.94866C17.1299 1.00503 4.00091 2.90751 4.00091 2.90751C4.00091 2.90751 0.94534 3.19326 1.89939 11.443Z" fill="#F7F7F7" />
                                            <path d="M3.19756 14.0075C3.38243 15.5955 3.5772 17.1348 3.77274 18.6033C3.94171 19.8916 4.11592 21.1225 4.27926 22.2837C5.153 26.0671 5.82093 29.8951 6.28009 33.7508C6.6541 34.5465 7.1734 35.2654 7.81127 35.8705C7.81127 35.8705 10.7153 39.1731 13.0915 38.789C15.4668 38.4111 18.746 37.8163 21.1007 33.7436L21.1061 33.7374C21.1061 33.7374 27.5466 10.0122 22.0664 7.41659C16.5913 4.82797 5.04475 6.49998 5.04475 6.49998C5.04475 6.49998 2.35835 6.75197 3.19756 14.0075Z" fill="white" />
                                            <path d="M18.759 15H10.241C9.00333 15 8 16.0033 8 17.241V25.759C8 26.9967 9.00333 28 10.241 28H18.759C19.9967 28 21 26.9967 21 25.759V17.241C21 16.0033 19.9967 15 18.759 15Z" fill="#C1C4C5" />
                                            {/* <path d="M18.7574 15.5605H10.2393C9.31109 15.5605 8.55859 16.313 8.55859 17.2413V25.7593C8.55859 26.6876 9.31109 27.4401 10.2393 27.4401H18.7574C19.6856 27.4401 20.4381 26.6876 20.4381 25.7593V17.2413C20.4381 16.313 19.6856 15.5605 18.7574 15.5605Z" stroke="#C1C4C5" /> */}
                                            <path d="M13.5126 27.4176C13.2885 27.8232 15.3906 27.6227 16.9671 27.6025C19.3134 27.5532 20.6524 28.0328 20.63 22.6163C20.6909 20.6943 20.6314 18.7704 20.4519 16.8558C19.8569 16.2776 20.6087 22.7227 19.086 25.5576C17.6495 27.6047 13.8969 26.9829 13.5126 27.4176Z" fill="#B0B1B1" />
                                            <path d="M16.5071 17.2578H12.0251C11.7157 17.2578 11.4648 17.5086 11.4648 17.8181V18.9386C11.4648 19.248 11.7157 19.4988 12.0251 19.4988H16.5071C16.8165 19.4988 17.0673 19.248 17.0673 18.9386V17.8181C17.0673 17.5086 16.8165 17.2578 16.5071 17.2578Z" fill="#9E9E9E" />
                                            <path d="M16.7219 17.2578H12.2399C11.9305 17.2578 11.6797 17.5086 11.6797 17.8181V18.9386C11.6797 19.248 11.9305 19.4988 12.2399 19.4988H16.7219C17.0313 19.4988 17.2822 19.248 17.2822 18.9386V17.8181C17.2822 17.5086 17.0313 17.2578 16.7219 17.2578Z" fill="url(#paint0_linear_4107_155444)" />
                                            <path d="M16.7297 22.5244H12.2477C11.9383 22.5244 11.6875 22.7752 11.6875 23.0847V24.2052C11.6875 24.5146 11.9383 24.7654 12.2477 24.7654H16.7297C17.0392 24.7654 17.29 24.5146 17.29 24.2052V23.0847C17.29 22.7752 17.0392 22.5244 16.7297 22.5244Z" fill="#CBCCCC" />
                                            <path d="M16.7297 22.2998H12.2477C11.9383 22.2998 11.6875 22.5506 11.6875 22.8601V23.9805C11.6875 24.29 11.9383 24.5408 12.2477 24.5408H16.7297C17.0392 24.5408 17.29 24.29 17.29 23.9805V22.8601C17.29 22.5506 17.0392 22.2998 16.7297 22.2998Z" fill="url(#paint1_linear_4107_155444)" />
                                            <path d="M1.6875 20.0449C7.67568 20.5113 12.7384 22.1149 26.5742 20.1488" stroke="#B5B7B7" strokeLinecap="round" />
                                            <path d="M1.6875 19.5156C7.25448 20.0258 13.9661 21.6104 26.5742 19.5317" stroke="#BABBBB" strokeLinecap="round" />
                                        </g>
                                    </> || ""}
                            </g>
                        </g>
                    </svg>}
            </DivTeethL>
        </Div>
    )
}
