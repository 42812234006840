import IconProps from './iconProps'
import { colors } from 'constants/theme'

export default function Counter(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g data-name="Group 8640">
        <g data-name="Rectangle 3131" style={{ stroke: props.color || colors.themeSecondColor, strokeWidth: '2px', fill: '#fff' }} transform="translate(2)">
          <rect width="27.375" height="31.586" rx="3" style={{ stroke: 'none' }} />
          <rect x="1" y="1" width="25.375" height="29.586" rx="2" style={{ fill: 'none' }} />
        </g>
        <g data-name="Rectangle 3132" transform="translate(6.621)" style={{ stroke: props.color || colors.themeSecondColor, strokeWidth: '2px', fill: '#fff' }}>
          <rect width="18" height="7" rx="1" style={{ stroke: 'none' }} />
          <path style={{ fill: 'none' }} d="M1 1h16v5H1z" />
        </g>
        <g data-name="Icon feather-list">
          <path data-name="Path 5170" d="M12 9h12.2" transform="translate(-.542 2.066)" style={{ strokeLinecap: 'round', strokeLinejoin: 'round', fill: 'none', stroke: props.color || colors.themeSecondColor, strokeWidth: '2px' }} />
          <path data-name="Path 5171" d="M12 18h12.2" transform="translate(-.542 -.083)" style={{ strokeLinecap: 'round', strokeLinejoin: 'round', fill: 'none', stroke: props.color || colors.themeSecondColor, strokeWidth: '2px' }} />
          <path data-name="Path 5172" d="M12 27h12.2" transform="translate(-.542 -2.231)" style={{ strokeLinecap: 'round', strokeLinejoin: 'round', fill: 'none', stroke: props.color || colors.themeSecondColor, strokeWidth: '2px' }} />
          <path data-name="Path 5173" d="M4.5 9h0" transform="translate(2.266 2.066)" style={{ strokeLinecap: 'round', strokeLinejoin: 'round', fill: 'none', stroke: props.color || colors.themeSecondColor, strokeWidth: '2px' }} />
          <path data-name="Path 5174" d="M4.5 18h0" transform="translate(2.266 -.083)" style={{ strokeLinecap: 'round', strokeLinejoin: 'round', fill: 'none', stroke: props.color || colors.themeSecondColor, strokeWidth: '2px' }} />
          <path data-name="Path 5175" d="M4.5 27h0" transform="translate(2.266 -2.231)" style={{ strokeLinecap: 'round', strokeLinejoin: 'round', fill: 'none', stroke: props.color || colors.themeSecondColor, strokeWidth: '2px' }} />
        </g>
      </g>
      <path data-name="Rectangle 3861" style={{ fill: 'none' }} d="M0 0h32v32H0z" />
    </svg>
  )
}
