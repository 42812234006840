import { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { DatePicker } from 'antd'
import EventIcon from '@mui/icons-material/Event'
import _ from 'lodash'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** UTILS */
import { getMonthDropdownOptions } from 'utils/calendar-helper.utils'

/** COMPONENT */
import BasicSelect from 'component/Select/BasicSelect'
import InputSelectBy from 'component/Input/InputSelectBy'
import CheckboxSelect from 'component/Select/CheckboxSelect'
import { isBuddhistYear } from 'utils/app.utils'

const useStyles = makeStyles({
  flexContainer: {
    display: 'flex-line',
    width: '100%',
    gap: '16px'
  },
  menuNavigation: {
    fontSize: 12
  },
  btnDiscard: {
    width: '100%',
    backgroundColor: `${colors.white} !important`,
    color: `${colors.themeSecondColor} !important`,
    border: `solid 1px ${colors.themeSecondColor} !important`
  },
  arrowNext: {
    transform: 'rotate(180deg)'
  },
  shareButton: {
    fontSize: '16px !important',
    border: `1px solid ${colors.themeSecondColor} !important`,
    marginRight: '12px !important',
    color: `${colors.themeSecondColor} !important`
  },
  manageButton: {
    fontSize: '16px !important',
    border: `1px solid ${colors.themeSecondColor} !important`,
    backgroundColor: `${colors.themeSecondColor} !important`,
    color: `${colors.white} !important`
  },
  monthPicker: {
    '& .ant-picker ': {
      '&& borderless': {
        verticalAlign: 'top !important'
      }
    },
    '& .ant-picker-input ': {
      '& input:placeholder-shown': {
        display: 'none'
      },
      '& input': {
        display: 'none'
      },
      '& .ant-picker-borderless': {
        verticalAlign: 'top'
      }
    }
  },
  countDentist: {
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  containerTypeDateSelect: {
    marginLeft: 'auto'
  },
  btnTypeDateSelect: {
    fontSize: '16px',
    width: '72px',
    border: `1px solid ${colors.themeSecondColor}`,
    color: colors.themeSecondColor,
    backgroundColor: colors.white
  },
  btnTypeDateSelectOnclick: {
    fontSize: '16px',
    width: '72px',
    border: `1px solid ${colors.themeSecondColor}`,
    color: colors.white,
    backgroundColor: colors.themeSecondColor
  },
  btnFirst: {
    borderRadius: '4px 0px 0px 4px'
  },
  btnLast: {
    fontSize: '16px',
    width: '136px',
    border: `1px solid ${colors.themeSecondColor}`,
    color: colors.themeSecondColor,
    backgroundColor: colors.white,
    borderRadius: '0px 4px 4px 0px'
  },
  btnLastOnClick: {
    fontSize: '16px',
    width: '136px',
    border: `1px solid ${colors.themeSecondColor}`,
    color: colors.white,
    backgroundColor: colors.themeSecondColor,
    borderRadius: '0px 4px 4px 0px'
  },
  monthNavArrowButtons: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  arrow: {
    width: '30px',
    height: '30px',
    lineHeight: '23px',
    textAlign: 'center',
    marginTop: '5px',
    '&:hover': {
      backgroundColor: colors.extraLightGray,
      borderRadius: '50%'
    }
  },
  navigationHeader: {
    fontSize: '24px'
  },
  date: {
    fontWeight: 700,
    width: '13vw',
    display: 'flex',
    justifyContent: 'center'
  },
  containFilter: {
    gap: '10px',
    display: 'flex'
  },
  containAction: {
    display: 'flex',
    marginLeft: 'auto',
    gap: '10px'
  },
  containOnClick: {
    height: '35.5px',
    display: 'flex'
  }
})

const Header = styled('div')(({ theme }) => ({
  backgroundColor: colors.white,
  padding: '20px 15px',
  boxShadow: `0 .125rem .25rem ${colors.black08} !important`,
  [theme.breakpoints.down('md')]: {
    padding: '0px',
    paddingBottom: '16px'
  },
  '.row-type-date-select': {
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0px'
    }
  }
}))
const GridContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'grid',
  columnGap: '16px',
  gridTemplateColumns: '1fr 1fr 1fr auto',
  gridTemplateRows: '1fr ',
  [theme.breakpoints.down('md')]: {
    rowGap: '16px',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto auto auto auto'
  }
}))
const ItemOne = styled('div')(({ theme }) => ({
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('md')]: {
    padding: '16px',
    gridColumnStart: '1',
    gridRowStart: '1',
    gridColumnEnd: '3',
    backgroundColor: colors.white,
    boxShadow: `0 .125rem .25rem ${colors.black08} !important`
  }
}))
const ItemTwo = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: '8px 24px 0px 24px',
    gridColumnStart: '1',
    gridRowStart: '2',
    gridColumnEnd: '3'
  }
}))
const ItemTree = styled('div')(({ theme }) => ({
  gap: '16px',
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    padding: '0px 24px',
    gridColumnStart: '1',
    gridRowStart: '3',
    gridColumnEnd: '3'
  }
}))
const ItemFour = styled('div')(({ theme }) => ({
  justifySelf: 'right',
  [theme.breakpoints.down('md')]: {
    padding: '0px 24px',
    gridColumnStart: '2',
    gridRowStart: '4'
  }
}))
const ItemFive = styled('div')(({ theme }) => ({
  gridColumnStart: '3',
  gridColumnEnd: '5',
  gridRowStart: '2',
  [theme.breakpoints.down('md')]: {
    padding: '0px 24px',
    gridColumnStart: '1',
    gridRowStart: '4',
    gridColumnEnd: '2'
  }
}))
type HeaderAppointmentCalendarProp = {
  handleMonthNavBackButtonClick: () => void
  handleMonthNavForwardButtonClick: () => void
  handleMonthSelect: (val: any) => void
  handleMonthSelectCalendar: (val: any) => void
  handleYearSelect: (val: any) => void
  handleMonthYearSelect: (month: any, year: any) => void
  onClickAddAppointment: () => void
  onClickViewHistory: () => void
  month: number
  year: number
}

const dentists = [
  { label: 'สมรักษ์ เก่งกาจ', check: false },
  { label: 'สมใจ เก่งกาจ', check: true },
  { label: 'วีรพล เจริญผลวัฒนา', check: false },
  { label: 'เกียรติ คมคาย ', check: false },
  { label: 'อัญชิสา ศิริวงศ์วัศ', check: false }
]

const branch = [
  { branchId: 1, branchName: 'สำนักงานใหญ่' },
  { branchId: 2, branchName: 'บางแค' }
]

const typeSearchBy = [
  { label: 'เลขประจำตัว', value: 'id' },
  { label: 'เลขประจำตัวสาขา', value: 'idBranch' },
  { label: 'บัตรประชาชน', value: 'idCard' },
  { label: 'เบอร์โทร', value: 'phone' },
  { label: 'รหัสพิเศษ', value: 'snCode' }
]
const months_th = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม']
export default function HeaderAppointmentCalendar(props: HeaderAppointmentCalendarProp) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [month, setMonth] = useState('')
  const [branchAllCheck, setBranchAllCheck] = useState(false)
  const [textSearch, setTextSearch] = useState('')
  const [selectBranch, setSelectBranch] = useState('')
  const [allCheck, setAllCheck] = useState(false)
  const [dentistsObj, setDentistsObj] = useState(dentists)
  const [dayPage, setDayPage] = useState(false)
  const [monthPage, setMonthPage] = useState(false)
  const [weekPage, setWeekPage] = useState(false)
  const [overallPage, setOverallPage] = useState(false)

  const getMonth = () => {
    _.map(getMonthDropdownOptions(), ({ label, value }: any) => {
      if (value === props.month) {
        setMonth(months_th[value - 1])
      }
    })
  }

  useEffect(() => {
    getMonth()
  })

  const onClearAll = () => {
    const tempDentistsObj = dentistsObj
    for (const i in tempDentistsObj) {
      tempDentistsObj[i].check = false
    }
    setDentistsObj(tempDentistsObj)
  }
  const onChangeAllCheck = (e: any) => {
    setAllCheck(e.target.checked)
    if (e.target.checked) {
      const tempDentistsObj = dentistsObj
      for (const i in tempDentistsObj) {
        tempDentistsObj[i].check = true
      }
      setDentistsObj(tempDentistsObj)
    } else {
      const tempDentistsObj = dentistsObj
      for (const i in tempDentistsObj) {
        tempDentistsObj[i].check = false
      }
      setDentistsObj(tempDentistsObj)
    }
  }

  const handleDatePage = (page: string) => {
    if (page === 'day') {
      setDayPage(true)
      setMonthPage(false)
      setWeekPage(false)
      setOverallPage(false)
    } else if (page === 'month') {
      setDayPage(false)
      setMonthPage(true)
      setWeekPage(false)
      setOverallPage(false)
    } else if (page === 'week') {
      setDayPage(false)
      setMonthPage(false)
      setWeekPage(true)
      setOverallPage(false)
    } else if (page === 'overall') {
      setDayPage(false)
      setMonthPage(false)
      setWeekPage(false)
      setOverallPage(true)
    }
  }

  return (
    <Header>
      <GridContainer>
        <ItemOne>
          <div>
            <div className={classes.navigationHeader}>
              <div className={classes.monthNavArrowButtons}>
                <div className={classes.arrow}>
                  <ArrowBackIosNewIcon onClick={props.handleMonthNavBackButtonClick} style={{ height: '16px' }} />
                </div>
                <div className={classes.date}>{`${month} ${props.year + isBuddhistYear()}`}</div>
                <div className={classes.arrow}>
                  <ArrowBackIosNewIcon className={`${classes.arrowNext}`} onClick={props.handleMonthNavForwardButtonClick} style={{ height: '16px' }} />
                </div>
                <DatePicker
                  className={classes.monthPicker}
                  suffixIcon={<EventIcon style={{ color: colors.themeSecondColor, width: '24.9px', height: '24.9px', marginTop: '0.45vw' }} />}
                  picker="month"
                  format={'YYYY-MM'}
                  bordered={false}
                  allowClear={false}
                  style={{ verticalAlign: 'top' }}
                  onChange={(e) => {
                    const monthValue = e?.month() !== undefined ? e?.month() + 1 : 1
                    const yearValue = e?.year() !== undefined ? e?.year() : 2021
                    props.handleMonthYearSelect(monthValue, yearValue)
                  }}
                />
              </div>
            </div>
            <span className={classes.countDentist}>จำนวนนัดหมาย 12 นัด, {t('USER_DOCTOR')}เข้าเวร 5 คน</span>
          </div>
        </ItemOne>
        <ItemTwo>
          <InputSelectBy
            objTypeSearch={typeSearchBy}
            labelCheck={'ทุกสาขา'}
            checked={branchAllCheck}
            onChangeCheck={(e) => {
              setBranchAllCheck(e.target.checked)
            }}
            input={textSearch}
            onChangeText={(e) => {
              setTextSearch(e.target.value)
            }}
          />
        </ItemTwo>
        <ItemTree>
          <div style={{ width: '50%' }}>
            <CheckboxSelect
              label={t('USER_DOCTOR')}
              objDentists={dentistsObj}
              allCheck={allCheck}
              onChangeCheck={setDentistsObj}
              onClearAll={onClearAll}
              onChangeAllCheck={(e) => {
                onChangeAllCheck(e)
              }}
            />
          </div>
          <div style={{ width: '50%' }}>
            <BasicSelect
              style={{ fontSize: '16px' }}
              onchange={(event) => {
                const value = event.target.value
                if (value) {
                  setSelectBranch(value)
                }
              }}
              label="สาขา"
              selectId="branch"
              value={selectBranch}
              labelId="branch"
              options={branch.map((i: any) => {
                return (
                  <MenuItem key={i.branchId} value={i.branchName}>
                    {i.branchName}
                  </MenuItem>
                )
              })}
            />
          </div>
        </ItemTree>
        <ItemFour>
          <Button
            className={classes.shareButton}
            onClick={(e) => {
              props.onClickViewHistory()
            }}
          >
            {t('APPOINTMENT.BUTTON.VIEW_HISTORY_APP')}
          </Button>
          <Button className={classes.manageButton} onClick={props.onClickAddAppointment}>
            {t('APPOINTMENT.BUTTON.ADD')}
          </Button>
        </ItemFour>
        <ItemFive>
          <div className={classes.countDentist}>
            <div className={'row-type-date-select'}>
              <div className={classes.containerTypeDateSelect}>
                <button
                  className={`${dayPage ? classes.btnTypeDateSelectOnclick : classes.btnTypeDateSelect} ${classes.btnFirst}`}
                  onClick={(e) => {
                    handleDatePage('day')
                  }}
                >
                  วัน
                </button>
                <button
                  className={`${weekPage ? classes.btnTypeDateSelectOnclick : classes.btnTypeDateSelect}`}
                  onClick={(e) => {
                    handleDatePage('week')
                  }}
                >
                  สัปดาห์
                </button>
                <button
                  className={`${monthPage ? classes.btnTypeDateSelectOnclick : classes.btnTypeDateSelect}`}
                  onClick={(e) => {
                    handleDatePage('month')
                  }}
                >
                  เดือน
                </button>
                <button
                  className={`${overallPage ? classes.btnLastOnClick : classes.btnLast}`}
                  onClick={(e) => {
                    handleDatePage('overall')
                  }}
                >
                  ภาพรวมนัดหมาย
                </button>
              </div>
            </div>
          </div>
        </ItemFive>
      </GridContainer>
    </Header>
  )
}
