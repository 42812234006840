import { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@mui/material'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** UTILS || SLICE */
import { showModal } from 'app/slice/modal.slice'
import { selectMe } from 'app/slice/user.slice';
import { getBranch } from 'utils/app.utils'

/** API */
import BranchApi from 'api/setting/branch-service-points/branch.api'
import TreatmentQueueAPI, { ListAppointment } from 'api/dentists/treatmentQueue.api'
import AppointmentApi from 'api/counter/appointment.api'

/** CONSTANT */
import { AppointmentCardItemDayInterface } from 'features/counter/appointment/appointment-interface';
import { colors } from 'constants/theme'

/** COMPONENT */
import { FormAppointment } from 'features/counter/appointment/form-appointment'
import SectionAppointment from 'component/Appointment/GraphAppointment'
import CardAppointment from 'component/Appointment/CardAppointment'
import CardAppointmentDetail from 'component/Appointment/CardAppointmentDetail'
import { swalDelete } from 'component/Alert/Swal'
import { notiError, notiSuccess } from 'component/notifications/notifications'

import * as UseStyled from 'features/treatments/Queue/Style/StyleTreatmentsQueue'

type TreatmentAppointmentProps = {
  date?: string
  branch: string
  setCountAppointment?: (count: number) => void
}
type Appointment = {
  appointmentId: number;
  appointmentNumber: string;
  branchId: number;
  patientId: number;
  cnNumber: string;
  vnNumber: string | null;
  phone: string;
  date: string;
  durationText: string | null;
  durationMinute: number;
  doctorId: number;
  practice: string;
  price: number;
  hasLab: "HAS" | "NOT_HAS";
  hasXray: "HAS" | "NOT_HAS";
  remark: string;
  remarkCancel: string;
  statusAppointment: "NOT_YET" | "CANCEL" | "DONE" | "NOSHOW" | "BOOKING";
  branchCnNumber: string;
  snCode: string;
  patientFullname: string;
  specialInfo: string;
  doctorFullname: string;
  timeStart: string;
  appointmentOperatives: {
    operativeTypeId: number;
    operativeTypeName: string;
    operativeId: number;
    operativeName: string;
    color: string;
  }[];
};

export default function TreatmentAppointment(props: TreatmentAppointmentProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector(selectMe)

  const branchId = Number(getBranch())
  const [officeTime, setOfficeTime] = useState<any>({});
  const [clinicIsClose, setClinicIsClose] = useState(false);
  const [doctorId, setDoctorId] = useState<number>(0)
  const [timeRange, setTimeRange] = useState<string[]>([])
  const [appointments, setAppointments] = useState<Appointment[]>([])
  const [viewDetails, setViewDetails] = useState([])

  const [showForm, setShowForm] = useState<boolean>(false)
  const [appointmentId, setAppointmentId] = useState<number>()
  const [editSpecialInfo, setEditSpecialInfo] = useState<boolean>(false)
  const [branch, setBranch] = useState<any>()

  useEffect(() => {
    setBranch(props.branch)
  }, [props.branch])


  const handlePatientCode = (index: number) => {
    if (branch?.patientCodeAppointment === '1') {
      return appointments[index].cnNumber

    }
    if (branch?.patientCodeAppointment === '0') {
      return appointments[index].branchCnNumber
    }

    if (branch?.patientCodeAppointment === '2') {
      return appointments[index].snCode
    }
  }

  const loadBranch = () => {
    if (branchId) {
      BranchApi.findById(branchId)
        .then(({ data }) => setOfficeTime(data?.officeTimes || null))
        .catch(() => { return })
    }
  }

  const handleTimeBranch = async () => {
    const findDay = _.find(officeTime, { dayOfWeek: moment(props.date).locale('en').format('ddd') })
    if (findDay?.officeStatus === 'CLOSE') setClinicIsClose(true)
    else {
      setClinicIsClose(false)
      let start: any = moment(findDay?.timeIn, 'HH:mm')
      let end: any = moment(findDay?.timeOut, 'HH:mm')
      if (parseInt(start.format('mm')) > 0) start = moment(start, 'HH:mm').startOf('hour')
      if (parseInt(end.format('mm')) > 0) end = moment(end, 'HH:mm').endOf('hour').add(1, 'minute')
      const timeEnd = parseInt(end.format('HH')) === 0 ? 24 : parseInt(end.format('HH'))
      const range: string[] = []
      for (let index = parseInt(start.format('HH')); index <= timeEnd; index++) {
        range.push(`${String(index).padStart(2, '0')}:00`)
      }

      if (!_.isEmpty(range)) setTimeRange(range)
      else setTimeRange(['9.00', '10.00', '11.00', '12.00', '13.00', '14.00', '15.00', '16.00', '17.00', '18.00'])
    }
  }

  const loadAllAppointment = useCallback(async () => {
    const params: ListAppointment = {
      branchId: branchId,
      doctorId: doctorId,
      date: props.date || moment().format('YYYY-MM-DD')
    }

    const respAppointments = await TreatmentQueueAPI.listAppointment(params)
    if (respAppointments.status === 200 && !_.isEmpty(respAppointments.data)) setAppointments(respAppointments.data)
    else setAppointments([])
  }, [branchId, doctorId, props.date])

  const handleViewDetail = (val: any) => {
    const temp: any = [...viewDetails]
    if (!temp.find((tempData: any) => tempData === val)) temp.push(val)
    setViewDetails(temp)
  }

  const handleCloseDetail = (val: any) => {
    const temp: any = [...viewDetails].filter((viewDetailData: any) => viewDetailData !== val)
    setViewDetails(temp)
  }

  const onEdit = (id: number, specialInfo: boolean) => {
    dispatch(showModal())
    setAppointmentId(id)
    setShowForm(true)
    setEditSpecialInfo(specialInfo)
  }

  const onDelete = (id: number, obj: any) => {
    swalDelete(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('APPOINTMENT.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="text-left mb-0 mx-auto" style="max-width: 306px">${t(`APPOINTMENT.ALERT.CONFIRM_DELETE_`, {
        cnNumber: obj.branchCnNumber ? `${obj.branchCnNumber}` : '',
        patientFullName: obj.patientFullname,
        time: obj.timeStart ? moment(obj.timeStart, 'HH:mm').format('HH:mm') : ''
      })}</p>`,
      (res: any) => {
        if (res) {
          AppointmentApi.remove(id)
            .then((resp) => {
              notiSuccess(t('APPOINTMENT.MESSAGE.SUCCESS.DELETE'))
              loadAllAppointment()
            })
            .catch((e) => (notiError(t('APPOINTMENT.MESSAGE.ERROR'))))
        }
      },
      { confirmButtonText: (t('TREATMENT_QUEUE.BUTTON.CONFIRM_DEL_APPOINTMENT')) }
    )
  }

  useEffect(() => {
    loadBranch()
  }, []);

  useEffect(() => {
    handleTimeBranch()
    if (user.userId) setDoctorId(user.userId)
  }, [user, props.date, officeTime])

  useEffect(() => {
    if (props.setCountAppointment) props.setCountAppointment(_.size(appointments))
  }, [appointments])

  useEffect(() => {
    if (doctorId) loadAllAppointment()
  }, [loadAllAppointment])

  const groupAppointmentByHour = (apt: any) => {
    const newApt: any = _.chain(apt)
      .map((i: any) => { return i })
      .groupBy('timeStart')
      .map((value: any) => ({ data: value }))
      .value()

    return newApt
  }

  return (
    !clinicIsClose && (
      <UseStyled.CustomSectionSchedule className={'h-100 overflow-hidden'}>
        <SectionAppointment
          timeRange={timeRange}
          card={_.map(
            groupAppointmentByHour(appointments),
            (time) => {
              return time.data.map((ap: any, j: number) => {
                const allRow = _.filter(time.data, (list: any) => list.start === ap.start).length
                const conDiv = document.querySelector(".container-schedule")
                const find = appointments?.find((item: any) => item?.appointmentId === ap?.appointmentId)
                let cn = ''
                if (branch?.patientCodeAppointment === '1') {
                  cn = find?.cnNumber || ''
                }
                if (branch?.patientCodeAppointment === '0') {
                  cn = find?.branchCnNumber || ''
                }

                if (branch?.patientCodeAppointment === '2') {
                  cn = find?.snCode || ''
                }
                return (
                  <CardAppointment
                    key={j}
                    appointmentId={ap.appointmentId}
                    status={ap.statusAppointment}
                    branchCnNumber={cn}
                    colorBackground={ap.appointmentOperatives.length === 1 ? ap.appointmentOperatives[0].color : colors.white}
                    serviceText={`${_.map(ap.appointmentOperatives, (operative: any, indexOperative: number) => {
                      return `${operative.operativeId ? operative.operativeName : operative.operativeTypeName}`
                    })}`}
                    patientFullName={ap.patientFullname}
                    rangeTimeText={`${ap.timeStart} - ${moment(ap.timeStart, 'HH:mm').add(ap.durationMinute, 'minutes').format('HH:mm')}`}
                    start={ap.timeStart}
                    end={moment(ap.timeStart, 'HH:mm').add(ap.durationMinute, 'minutes').format('HH:mm')}
                    hasLab={ap.hasLab === 'HAS'}
                    hasXray={ap.hasXray === 'HAS'}
                    onViewDetail={handleViewDetail}
                    timeStart={timeRange[0]}
                    width={(conDiv?.clientWidth || 1080 )/ allRow}
                    translateX={((conDiv?.clientWidth || 1080) / allRow) * j}
                    specialInfo={ap.specialInfo}
                    type='treatment'
                    phone={ap?.phone}
                  />
                )
              })
            })}
          popup={_.map(appointments, (ap: AppointmentCardItemDayInterface, j: number) => (
            <CardAppointmentDetail
              isNotShowPhoneNumber={user.hasShowPhoneNumber === 'UNPERMISS'}
              hideMenu
              key={j}
              timeStart={timeRange[0]}
              appointmentId={ap.appointmentId}
              showPopup={_.find(viewDetails, (v) => v === ap.appointmentId) ? true : false}
              onClose={handleCloseDetail}
              onEdit={() => onEdit(ap.appointmentId, false)}
              onEditSpecialInfo={() => onEdit(ap.appointmentId, true)}
              status={ap.statusAppointment}
              branch={props.branch}
            />
          ))}
          showMinute
        ></SectionAppointment>
        <FormAppointment
          showForm={showForm}
          handleSuccess={() => loadAllAppointment()}
          appointmentId={appointmentId}
          onReset={() => {
            setAppointmentId(undefined)
            setShowForm(false)
          }}
          onDelete={onDelete}
          editSpecialInfo={editSpecialInfo}
          officeTime={officeTime} />
      </UseStyled.CustomSectionSchedule>
    ) || (
      <Typography className='text-center mx-auto' sx={{ fontWeight: 500, color: colors.textLightGray }}>{t('CLINIC_CLOSE')}</Typography>
    )
  )
}
