import IconProps from "assets/icon/payment-method/iconProps"

export default function Debit(props: IconProps) {
    return (
        <svg xmlns="https://www.w3.org/2000/svg" width="59.961" height="40.111" viewBox="0 0 59.961 40.111">
            <g data-name="Component 265">
                <path data-name="Path 4042" d="M551.794 261h-45.627a7.175 7.175 0 0 0-7.167 7.168v25.775a7.176 7.176 0 0 0 7.167 7.168h45.626a7.176 7.176 0 0 0 7.167-7.168v-25.775a7.175 7.175 0 0 0-7.166-7.168zM557 293.943c0 3.377-3.441 5.168-5.206 5.168h-45.627c-1.765 0-5.149-2.243-5.168-5.168v-15.832h56zm0-20.931h-56v-4.848c-.026-3.219 3.4-5.056 5.167-5.056h45.626c1.766 0 5.2 1.955 5.206 5.056z" transform="translate(-499 -261)" style={{ fill: props.active || '#9e9e9e' }} />
                <path data-name="Path 4043" d="M550.5 441.34h20.2c.723 0 1.309-.267 1.309-.595v-1.81c0-.329-.586-.6-1.309-.6h-20.2c-.723 0-1.309.266-1.309.6v1.81c0 .328.586.595 1.309.595zm0 0" transform="translate(-518.192 -408.229)" style={{ fill: props.active || '#9e9e9e' }} />
                <path data-name="Path 6622" d="M550.5 441.34h20.2c.723 0 1.309-.267 1.309-.595v-1.81c0-.329-.586-.6-1.309-.6h-20.2c-.723 0-1.309.266-1.309.6v1.81c0 .328.586.595 1.309.595zm0 0" transform="translate(-518.191 -413.229)" style={{ fill: props.active || '#9e9e9e' }} />
            </g>
        </svg>
    )
}
