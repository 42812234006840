import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Typography, Box, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'
import MenuItem from '@mui/material/MenuItem'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles, { ButtonAddRecommend } from 'features/setting/treatments/diagnosis/style'

/** API */
import RecommendApi from 'api/setting/treatments/recommend.api'

/** UTILS || SLICE */
import { icons } from 'constants/images'

/** COMPONENT */
import InputTextField from 'component/Input/InputTextField'
import InputTextarea from 'component/Input/InputTextarea'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import SelectRecommends from 'features/setting/treatments/diagnosis/select-recommends'
import { addOptions, resetSelected, setSelected } from 'app/slice/select.slice';
import { setDisabledBtn } from 'app/slice/button.slice'
import { appendElement } from 'app/slice/form.slice';
import DiagnosisApi from 'api/setting/treatments/diagnosis.api'
import BasicSelect from 'component/Select/BasicSelect'
import DiagnosisTypeApi from 'api/setting/treatments/diagnosis-type.api'
import { useParams } from 'react-router'
import { routeName } from 'routes/routes-name'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'

const initStateErrorMessage = {
  DIAGNOSIS_TYPE_NAME: ``,
  DIAGNOSIS_CODE: ``,
  IS_DUPLICATE_DIAGNOSIS_CODE: ``,
  DIAGNOSIS_CODE_STRING_BASE: ``,
  DIAGNOSIS_CODE_STRING_EMPTY: ``,
  DIAGNOSIS_CODE_MAXIMUM_LENGTH: ``,
  DIAGNOSIS_CODE_ANY_REQUIRED: ``,
  DIAGNOSIS_NAME: ``,
  IS_DUPLICATE_DIAGNOSIS_NAME: ``,
  DIAGNOSIS_NAME_STRING_BASE: ``,
  DIAGNOSIS_NAME_STRING_EMPTY: ``,
  DIAGNOSIS_NAME_MAXIMUM_LENGTH: ``,
  DIAGNOSIS_NAME_ANY_REQUIRED: ``
}

export default function FormDiagnosis() {
  const classes = useStyles()
  const { t } = useTranslation()

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

  const [diagnosisTypeId, setDiagnosisTypeId] = useState(Number)
  const [diagnosisCode, setDiagnosisCode] = useState('')
  const [diagnosisName, setDiagnosisName] = useState('')
  const [diagnosisStatus, setDiagnosisStatus] = useState('ACTIVE')

  const [diagnosisTypes, setDiagnosisTypes] = useState([])

  /** Recommends */
  const tempRecommends: any = []
  const [countRecommends, setCountRecommends] = useState(0)

  const selectedValue = useSelector((state: any) => state.select.value)

  const renderRecommends = useSelector((state: any) => state.forms.render)

  const [recommends, setRecommends] = useState([]);
  const [recommendSelected, setRecommendSelected]: any = useState([]);
  const [disabledBtnAdd, setDisabledBtnAdd] = useState(false);

  const { id }: any = useParams()
  const dispatch = useDispatch()

  const loadData = useCallback(async () => {
    const res = await RecommendApi.findAll({ pageLimit: 50, status: 'ACTIVE', useWith: 'ALL' })

    if (res.status === 200) {
      const newRecommends: any = []
      _.map(res.data, (nr, index) => {
        newRecommends.push({ label: nr.recommendText, value: nr.recommendId })
      })
      setRecommends(newRecommends)

    }

    const respDiagnosisTypes = await DiagnosisTypeApi.findAll({ pageLimit: 50, status: 'ACTIVE' })
    if (respDiagnosisTypes.status === 200) setDiagnosisTypes(respDiagnosisTypes.data)

    if (id !== undefined) {
      const { status, data }: any = await DiagnosisApi.findById(id)
      if (status === 200) {
        setDiagnosisCode(data.diagnosisCode)
        setDiagnosisName(data.diagnosisName)
        setDiagnosisTypeId(data.diagnosisTypeId)
        setDiagnosisStatus(data.status)

        if (!_.isEmpty(data.diagnosisRecommends)) {
          const newRecommends: any = []
          _.map(data.diagnosisRecommends, (d, index) => {
            return newRecommends.push({ label: d.recommendText, value: d.recommendId })
          })
          setRecommendSelected(newRecommends)

        } else setRecommendSelected([])
      }
    }
  }, [])

  useEffect(() => {
    loadData()
  }, [loadData])

  const submit = () => {
    if (!diagnosisTypeId || !diagnosisCode || !diagnosisName) {
      return setErrorMessage({
        ...errorMessage,
        DIAGNOSIS_TYPE_NAME: !diagnosisTypeId ? t('DIAGNOSIS.MESSAGE.DIAGNOSIS_TYPE_NAME') : '',
        DIAGNOSIS_CODE: !diagnosisCode ? t('DIAGNOSIS.MESSAGE.DIAGNOSIS_CODE') : '',
        DIAGNOSIS_NAME: !diagnosisName ? t('DIAGNOSIS.MESSAGE.DIAGNOSIS_NAME') : ''
      })
    }

    const newRecommends: any[] = []
    recommendSelected.forEach((val: any) => {
      if (val) newRecommends.push({ recommendId: val.value })
    })

    DiagnosisApi.create({ diagnosisTypeId, diagnosisCode, diagnosisName, diagnosisRecommends: newRecommends })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t('DIAGNOSIS.MESSAGE.SUCCESS.CREATE'))
          resetForm()
          loadData()
          window.location.href = routeName.diagnosis

        }
        else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`DIAGNOSIS.MESSAGE.${err.message}`) } })
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`DIAGNOSIS.MESSAGE.${err.message}`) } })
      })
  }

  const submitEdit = () => {
    if (!diagnosisTypeId || !diagnosisCode || !diagnosisName) {
      return setErrorMessage({
        ...errorMessage,
        DIAGNOSIS_TYPE_NAME: !diagnosisTypeId ? t('DIAGNOSIS.MESSAGE.DIAGNOSIS_TYPE_NAME') : '',
        DIAGNOSIS_CODE: !diagnosisCode ? t('DIAGNOSIS.MESSAGE.DIAGNOSIS_CODE') : '',
        DIAGNOSIS_NAME: !diagnosisName ? t('DIAGNOSIS.MESSAGE.DIAGNOSIS_NAME') : ''
      })
    }

    const newRecommends: any[] = []
    recommendSelected.forEach((val: any) => {
      if (val) newRecommends.push({ recommendId: val.value })
    })

    DiagnosisApi.update(id, { diagnosisTypeId, diagnosisCode, diagnosisName, diagnosisRecommends: newRecommends, status: diagnosisStatus })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t(`DIAGNOSIS.MESSAGE.SUCCESS.UPDATE`))
          resetForm()
          loadData()
          window.location.href = routeName.diagnosis
        }
        else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`DIAGNOSIS.MESSAGE.${err.message}`) } })
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`DIAGNOSIS.MESSAGE.${err.message}`) } })
      })
  }

  useEffect(() => {
    if (_.some(recommendSelected, (selected) => selected.value === '' || selected.value === 0)) setDisabledBtnAdd(true)
    else setDisabledBtnAdd(false)
  }, [recommendSelected]);

  const addRecommend = () => {
    setRecommendSelected([...recommendSelected, { label: '', value: 0 }])

    const newOptions: any[] = []
    const newValue: any = []

    _.map(selectedValue, (val: any, iValue: number) => {
      if (val !== null) newValue[iValue] = val
    })

    dispatch(resetSelected(newValue))
    _.map(tempRecommends, (recommend: any, index: number) => {
      if (newValue.indexOf(recommend.value) === -1) newOptions.push(recommend)
    })

    if (renderRecommends !== undefined && newValue[countRecommends - 1] === null) return notiError(t('RECOMMEND.INPUT.PLEASE_RECOMMEND'))

    const count = countRecommends
    dispatch(setSelected({ key: [count], value: null }))
    dispatch(addOptions({ key: [count], value: newOptions }))
    dispatch(
      appendElement({
        index: count,
        value: (
          <SelectRecommends
            key={`key-recommends-${count}`}
            index={Number(count)}
            setSearch={(value) => {
              return
            }}
            removeRecommend={removeRecommend}
          />
        )
      })
    )

    dispatch(setDisabledBtn(true))
    setCountRecommends((pre) => pre + 1)
  }

  function removeRecommend(no: number) {
    const items = _.filter(recommendSelected, (i, index: number) => index !== no)
    setRecommendSelected(items)

  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  const resetForm = () => {
    setDiagnosisTypeId(0)
    setDiagnosisCode('')
    setDiagnosisName('')
    clearErrorMessage()
  }

  return (
    <div className={`${classes.root} container-card container-card h-100 overflow-hidden`}>
      <div className="d-flex flex-column h-100 overflow-auto">
        <HeaderLabel
          text={id ? t('DIAGNOSIS.TITLE_UPDATE') : t('DIAGNOSIS.TITLE_CREATE')}
          navigator={{ previousTo: routeName.diagnosis, previousTitle: t('DIAGNOSIS.TITLE'), currentTitle: id ? t('DIAGNOSIS.TITLE_UPDATE') : t('DIAGNOSIS.TITLE_CREATE') }}
          goBack={routeName.diagnosis}
          onSubmit={id ? submitEdit : submit}
        />

        <Card className="border shadow-sm mx-4 mb-4 h-auto card">
          <div className='overflow-hidden p-4'>
            <Card.Body className="d-flex flex-column h-100 p-0">
              <Row>
                <Col xs={12}>
                  <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>{t('TREATMENTS.FOOTER_TAB.DIAGNOSIS')}</Typography>
                </Col>
                <Col sm={12} md={6} className="pt-3 pr-md-2">
                  <BasicSelect
                    onchange={(event) => {
                      const value = event.target.value
                      if (value) {
                        setDiagnosisTypeId(value)
                        clearErrorMessage()
                      }
                    }}
                    label={t('DIAGNOSIS.INPUT.DIAGNOSIS_TYPE')}
                    selectId="diagnosis-type"
                    value={diagnosisTypeId ? diagnosisTypeId : ''}
                    labelId="diagnosis-type"
                    options={diagnosisTypes.map((diagnosisType: any) => {
                      return (
                        <MenuItem key={diagnosisType.diagnosisTypeId} value={diagnosisType.diagnosisTypeId}>
                          {diagnosisType.diagnosisTypeName}
                        </MenuItem>
                      )
                    })}
                    helperText={errorMessage.DIAGNOSIS_TYPE_NAME || ''}
                    required
                  />
                </Col>
                <Col sm={12} md={6} className="pt-3 pl-md-2">
                  <InputTextField
                    key="diagnosisCode"
                    onchange={(event) => {
                      setDiagnosisCode(event.target.value)
                      clearErrorMessage()
                    }}
                    value={diagnosisCode}
                    helperText={
                      errorMessage.DIAGNOSIS_CODE ||
                      errorMessage.IS_DUPLICATE_DIAGNOSIS_CODE ||
                      errorMessage.DIAGNOSIS_CODE_STRING_BASE ||
                      errorMessage.DIAGNOSIS_CODE_STRING_EMPTY ||
                      errorMessage.DIAGNOSIS_CODE_MAXIMUM_LENGTH ||
                      errorMessage.DIAGNOSIS_CODE_ANY_REQUIRED
                    }
                    label={t('DIAGNOSIS.INPUT.DIAGNOSIS_CODE')}
                    required={true}
                  />
                </Col>
                <Col sm={12} md={6} className="pt-3 pr-md-2">
                  <InputTextarea
                    key="diagnosisName"
                    onchange={(event) => {
                      setDiagnosisName(event.target.value)
                      clearErrorMessage()
                    }}
                    value={diagnosisName}
                    helperText={
                      errorMessage.DIAGNOSIS_NAME ||
                      errorMessage.IS_DUPLICATE_DIAGNOSIS_NAME ||
                      errorMessage.DIAGNOSIS_NAME_STRING_BASE ||
                      errorMessage.DIAGNOSIS_NAME_STRING_EMPTY ||
                      errorMessage.DIAGNOSIS_NAME_MAXIMUM_LENGTH ||
                      errorMessage.DIAGNOSIS_NAME_ANY_REQUIRED
                    }
                    label={t('DIAGNOSIS.INPUT.DIAGNOSIS_NAME')}
                    required={true}
                  />
                </Col>
              </Row>
              <Row className='pt-4'>
                <Col xs={12}>
                  <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>{t('RECOMMEND.INPUT.RECOMMEND_TEXT')}</Typography>
                </Col>
                <Col xs={12} className="pt-3">
                  <ButtonAddRecommend variant="text" disabled={disabledBtnAdd} onClick={() => addRecommend()} className="d-flex" sx={{ marginBottom: '1px' }}>
                    <span className={'mr-1'}>+</span> {t('RECOMMEND.TITLE_CREATE')}
                  </ButtonAddRecommend>
                </Col>
                <Row>
                  {
                    _.map(recommendSelected, (r: any, index: number) => {
                      return (
                        <Col key={index} sm={12} className="mt-3">
                          <div className='d-flex align-items-center w-100'>
                            <p className='m-0 pr-4 text-right' style={{ width: '4%' }}>{index + 1}</p>
                            <Box className='w-100 pr-3' sx={{ width: { lg: '70% !important', xl: '60% !important' } }}>
                              <AutocompleteSelect
                                key={r.recommendId}
                                labelId={r.recommendId}
                                options={
                                  _.map(recommends, (rec: any) => {
                                    return { label: rec.label, value: rec.value }
                                  })
                                }

                                renderOption={(props, option: any) => (
                                  <Box
                                    {...props}
                                    key={option.value}
                                    component="li"
                                    sx={{
                                      pointerEvents: _.some(recommendSelected, { value: option?.value }) ? 'none !important' : 'auto !important',
                                      opacity: _.some(recommendSelected, { value: option?.value }) ? .5 : 1
                                    }}>
                                    {option.label}
                                  </Box>
                                )}
                                noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                                onchange={(event, value: any) => {
                                  if (value) {
                                    const items: any = _.merge([], recommendSelected)
                                    items[index].label = value.label
                                    items[index].value = value.value
                                    setRecommendSelected(items)

                                  }
                                }}
                                getOptionLabel={(option: any) => option.label}
                                onInputChange={(event: any, newValue: any) => {
                                  return
                                }}
                                value={r || recommends.find((v: any) => v.value === r.recommendId)}
                                isOptionEqualToValue={(option: any, value: any) => option.value === r.value}

                                disableClearable
                              />
                            </Box>
                            <IconButton disableFocusRipple disableRipple onClick={() => removeRecommend(index)} className="p-0" sx={{ backgroundColor: 'transparent' }}>
                              <img src={icons.btnMinus} alt="discard" />
                            </IconButton>
                          </div>
                        </Col>
                      )
                    }).reverse()
                  }
                </Row>
              </Row>
            </Card.Body>
          </div>
        </Card>
      </div>
    </div>
  )
}
