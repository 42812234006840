import applications from 'locales/plus/en/application/application.json'
import news from 'locales/plus/en/application/news.json'
import promotions from 'locales/plus/en/application/promotion.json'
import manageWeb from 'locales/plus/en/application/manageWeb.json'

export const ApplicationEN = {
  ...applications,
  ...news,
  ...promotions,
  ...manageWeb
}
