import { Box } from '@mui/material';

/** STYLE */
import { styled } from '@mui/material/styles'
import 'features/treatments/Queue/Style/styleTreatment.css'

/** CONSTANTS */
import { colors } from 'constants/theme'

export const TreatmentQueueLayout = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    '.layout-collapse': {
      '.layout-left-side': {
        width: '100%',
        maxWidth: 300,
        borderRight: 0,
        '.area-collapse, .btn-collapse': {
          display: 'none'
        }
      },
      '.layout-right-side': {
        width: '100%'
      },
      '.layout-left-side-container, .layout-right-side-container': {
        opacity: '1 !important'
      }
    }
  },
  [theme.breakpoints.up('md')]: {
    '.layout-collapse': {
      '.layout-left-side': {
        maxWidth: 340
      }
    }
  },
  [theme.breakpoints.up('lg')]: {
    '.layout-collapse': {
      '.layout-left-side': {
        maxWidth: 300
      }
    }
  },
  [theme.breakpoints.up('xl')]: {
    '.layout-collapse': {
      '.layout-left-side': {
        maxWidth: 356
      }
    }
  }
}))

export const FragmentLeft = styled(Box)(({ theme }) => ({
  '.slider-area': {
    flex: '0 0 100%',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 330,
      margin: 'auto',
      whiteSpace: 'nowrap'
    }
  }
}))

export const FragmentRight = styled(Box)(({ theme }) => ({
  '.btn-summary': {
    border: `1px solid ${colors.themeSecondColor}`,
    color: colors.themeSecondColor,
    padding: '2.5px 8px'
  }
}))

export const Header = styled(Box)(({ theme }) => ({
  height: 70,
  backgroundColor: colors.white,
  boxShadow: `0 3px 6px 0 ${colors.black05}`,
  backdropFilter: 'blur(40px)',
  zIndex: 1,
  [theme.breakpoints.down(370)]: {
    flexWrap: 'wrap'
  }
}))

export const SelectDate = styled(Box)(({ theme }) => ({}))

export const SummaryBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundColor: colors.white,
  boxShadow: `0 3px 6px 0 ${colors.black10}`,
  color: colors.textPrimary,
  '.close': {
    color: colors.disabledGray
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 330
  },
  '.expandable-content': {
    maxHeight: 200,
    overflow: 'hidden',
    transition: 'max-height 0.5s ease-in-out',
  },
  '.collapsed': {
    maxHeight: 0,
    paddingBottom: 0

  }
}))

export const SummaryValue = styled(Box)<{ type: string }>(({ theme, type }) => ({
  width: '100%',
  borderRadius: 4,
  padding: '0.5rem',
  backgroundColor: type === 'DF' ? colors.tag.df : colors.tag.lab,
  cursor: 'pointer',
  color: colors.white
}))

export const Arrow = styled(Box)(({ theme }) => ({
  width: 24,
  height: 24,
  borderRadius: 100,
  backgroundColor: colors.white30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transform: 'rotate(45deg)',
  svg: {
    fontSize: 16
  }
}))

export const SummaryList = styled(Box)<{ type?: string }>(({ theme, type }) => ({
  marginTop: '1rem',
  padding: '10px 1rem',
  border: `1px solid ${colors.lightGray}`,
  borderRadius: 4,
  '.MuiTypography-root': {
    lineHeight: 'normal',
    '&:first-of-type': {
      fontSize: 14,
      color: colors.themeMainColor
    },
    '&:last-of-type': {
      fontSize: 12,
      color: colors.themeMainColor09,
      paddingTop: 2
    }
  },
  svg: {
    fontSize: 20,
    color: colors.themeMainColor,
    width: type === 'appointment' ? 30 : type === 'wait' ? 38 : 28,
    height: type === 'appointment' ? 30 : type === 'wait' ? 38 : 28,
    stroke: type === 'wait' ? colors.themeMainColor : 'unset',
    path: {
      stroke: type === 'appointment' ? colors.themeMainColor : 'unset',
      '&#clock-3': {
        fill: colors.themeMainColor
      }
    }
  }
}))

export const CustomSectionSchedule = styled('div')(({ theme }) => ({
  '& .layout-schedule': {
    marginRight: '1rem',
    '.container-schedule': {
      borderRight: 0
    },
    [theme.breakpoints.down('xs')]: {
      overflow: 'auto !important',
      '.container-schedule': {
        minWidth: 400
      }
    }
  }
}))


