import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody } from '@mui/material'
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem'
import { colors } from 'constants/theme';

/** STYLE */
import useStyles from 'features/setting/personnel-managements/personnels/style'
import * as UseStyled from 'features/setting/personnel-managements/personnels/useStyles'

/** UTILS || SLICE */
import { selectLoadingEducation, editEducation, selectEducation } from 'app/slice/user.slice';

/** CONSTANT */

/** COMPONENT */
import InputTextField from 'component/Input/InputTextField'
import BasicSelect from 'component/Select/BasicSelect'
import { ButtonDeleteSimple } from 'component/Button/ButtonDelete'

const educationTypes = [
  { name: 'EDUCATION_QUALIFICATION', id_type: 1 },
  { name: 'TRAINING_COURSE', id_type: 2 },
  { name: 'CERTIFICATE', id_type: 2 }
]
interface UserBackgroundType {
  backgroundType: string
  name: string
  institute: string
  year: string
  refId: number
}


interface EducationProps {
  isEdit: boolean
}
export default function Education(props: EducationProps) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const userEducation = useSelector(selectEducation)
  const loading = useSelector(selectLoadingEducation)
  const [userBackground, setUserBackground] = useState<UserBackgroundType[]>([])
  const headTable = [t('PERSONNEL.EDUCATION.TYPE_EDUCATION'), t('PERSONNEL.EDUCATION.EDUCATION_NAME'), t('PERSONNEL.EDUCATION.INSTITUTION'), t('PERSONNEL.EDUCATION.YEAR_OF_GRADUATION'), 'Action']

  useEffect(() => {
    if (!loading) {
      if (props.isEdit) setUserBackground(userEducation.userBackgrounds)
    }
  }, [loading])

  const classes: any = useStyles()
  return (
    <div className="p-3">
      <Row className={`${classes.titleFirst} ml-1 mt-2`}>{t('PERSONNEL.EDUCATION.EDUCATION_RECORD')}</Row>
      <TableContainer component={Paper} className={`mt-3`} style={{ boxShadow: 'none', border: `1px solid ${colors.lightGray}` }}>
        <Table aria-label="simple table" sx={{ minWidth: '900px' }}>
          <TableHead>
            <TableRow>
              {headTable.map((title: string) => (
                <TableCell key={title}>
                  <div className={`${classes.titleSecond}`} style={{ fontWeight: 600, color: colors.themeMainColor }}>
                    {title}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                className={`${classes.columeHover}`}
                colSpan={5}
                onClick={() => setUserBackground([...userBackground, { refId: userBackground.length, backgroundType: '', name: '', institute: '', year: '' }])}
                sx={{
                  '&.MuiTableCell-root.MuiTableCell-body': {
                    backgroundColor: 'transparent !important'
                  }
                }}
              >
                <UseStyled.DivAdd>+ {t('PERSONNEL.EDUCATION.ADD_EDUCATION')}</UseStyled.DivAdd>
              </TableCell>
            </TableRow>
            {userBackground
              .map(({ refId, backgroundType, name, institute, year }: any) => {
                return (
                  <TableRow key={refId}>
                    <TableCell>
                      <BasicSelect
                        onchange={(event) => {
                          const value = event.target.value
                          if (value) {
                            const val: any = [...userBackground].map((object: any) => (object.refId === refId ? { ...object, backgroundType: value } : object))
                            dispatch(editEducation({ value: val, key: 'userBackgrounds' }))
                            setUserBackground(val)
                          }
                        }}
                        label={t('PERSONNEL.EDUCATION.TYPE_EDUCATION')}
                        selectId="background-type"
                        value={backgroundType}
                        labelId="background-type"
                        options={educationTypes.map(({ name: educationTypeName, id: educationTypeId }: any) => (
                          <MenuItem key={educationTypeId} value={educationTypeName}>
                            {t(`PERSONNEL.INPUT.${educationTypeName}`)}
                          </MenuItem>
                        ))}
                      />
                    </TableCell>
                    <TableCell>
                      <InputTextField
                        label={`${backgroundType ? t(`PERSONNEL.INPUT.${backgroundType}`) : t('PERSONNEL.EDUCATION.EDUCATION_QUALIFICATION')}`}
                        value={name}
                        onchange={(e) => {
                          const val: any = [...userBackground].map((object: any) => (object.refId === refId ? { ...object, name: e.target.value } : object))
                          dispatch(editEducation({ value: val, key: 'userBackgrounds' }))
                          setUserBackground(val)
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <InputTextField
                        label={t('PERSONNEL.EDUCATION.INSTITUTION')}
                        value={institute}
                        onchange={(e) => {
                          const val: any = [...userBackground].map((object: any) => (object.refId === refId ? { ...object, institute: e.target.value } : object))
                          dispatch(editEducation({ value: val, key: 'userBackgrounds' }))
                          setUserBackground(val)
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <InputTextField
                        label={t('PERSONNEL.EDUCATION.YEAR_OF_GRADUATION')}
                        value={year}
                        onchange={(e) => {
                          const val: any = [...userBackground].map((object: any) => (object.refId === refId ? { ...object, year: e.target.value } : object))
                          dispatch(editEducation({ value: val, key: 'userBackgrounds' }))
                          setUserBackground(val)
                        }}
                      />
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        const clickId: any = refId
                        const user: any = userBackground.filter(({ refId: userBgRefId }: any) => userBgRefId !== clickId)
                        setUserBackground(user)
                        dispatch(editEducation({ value: user, key: 'userBackgrounds' }))
                      }}>
                      <ButtonDeleteSimple />
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
