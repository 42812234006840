import { makeStyles } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import { TableCell, Box } from '@mui/material';
import { colors } from 'constants/theme';

export const DivContainer = styled(`div`)(({ theme }) => ({
  height: '100%'
}))
export const TitleSecond = styled(`div`)(({ theme }) => ({
  fontSize: '16px'
}))
export const TableCellHover = styled(TableCell)(({ theme }) => ({
  backgroundColor: '',
  '&:hover': {
    backgroundColor: `${colors.lightGray} !important`
  }
}))
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  titleFirst: {
    fontSize: '18px',
    fontWeight: 600
  },
  titleSecond: {
    fontSize: '16px'
  },
  columeHover: {
    backgroundColor: '',
    '&:hover': {
      backgroundColor: `${colors.lightGray} !important`
    }
  },
  inputDisable: {
    backgroundColor: colors.themeThirdColor
  }
}))

export default useStyles

export const NetPrice = styled(Box)(({ theme }) => ({
  height: 48,
  fontSize: 20,
  color: colors.themeMainColor,
  backgroundColor: colors.themeMainColor06,
  [theme.breakpoints.down('sm')]: {
    fontSize: 18
  }
}))

export const CustomTable = styled(Box)(({ theme }) => ({
  '.MuiTableContainer-root': {
    borderTopLeftRadius: '4px !important',
    borderTopRightRadius: '4px !important'
  },
  '.MuiTable-root': {
    '.MuiTableHead-root': {
      borderColor: `${colors.lightGray} !important`,
      '.MuiTableCell-head': {
        backgroundColor: colors.white,
        borderWidth: '1px 0',
        borderStyle: 'solid',
        borderColor: `${colors.lightGray} !important`,
        color: `${colors.themeMainColor} !important`,
        fontWeight: 600
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        verticalAlign: 'middle',
        paddingTop: 12,
        paddingBottom: 12
      },
      '.row-add-new-row': {
        '.btn-add-new-row': {
          padding: '0 0 0 14px',
          fontSize: 16,
          color: colors.themeSecondColor,
          backgroundColor: 'transparent !important',
          fontWeight: 400,
          span: {
            fontSize: 20,
            color: colors.themeSecondColor
          },
          '&:hover': {
            fontWeight: 600
          },
          '&.Mui-disabled, &.Mui-disabled span': {
            color: colors.disabledGray,
          }
        },
        '.MuiTableCell-root:hover': {
          cursor: 'pointer',
          '.btn-add-new-row': {
            fontWeight: 600
          }
        }
      }
    }
  }
}))
