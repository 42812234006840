import { useCallback, useEffect, useState } from 'react'
import { ExportProps } from 'features/report/report-constants'
import moment from 'moment'
import _ from 'lodash'
import { Box, Typography, Divider, TableRow, TableCell, TableSortLabel } from '@mui/material'
import { numberFormat, toBuddhistYear } from 'utils/app.utils'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import EmptyData from 'features/report/component/emptyData'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { colors } from 'constants/theme'
import { dateTimeToView } from 'utils/date.utils'

import { UseSnCode } from 'utils/useSnCode'
import PatientsTreatmentTotal from './PatientsTreatmentTotal'
import NewPatientListTotal from '../NewPatient/NewPatientListTotal'

interface FcComponent {
  page: number
  pageLimit: number
  rowCount: number
  sortBy: string
  sortType: string
  data: any
  dataExport: any
  onRequestSort: (sortBy: string, sortType: string) => void
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
  componentRef: any
  headCellsSeconds: any
}
export default function PatientsTreatmentList(props: FcComponent) {
  const { t } = useTranslation()

  const headCells = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_TREATMENT.#NO', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_TREATMENT.DATE', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_TREATMENT.CN', align: 'center', class: 'text-nowrap', width: '90px' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_TREATMENT.PT', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_TREATMENT.PATIENT_FULL_NAME', align: 'center' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_TREATMENT.DOCTOR_FULL_NAME', align: 'center' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_TREATMENT.OPERATIVE_TYPE', align: 'center' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_TREATMENT.OPERATIVE_CODE', align: 'center' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.PATIENT_TREATMENT.OPERATIVE', align: 'center' }
  ]

  const renderData = (data: any, no: number, isExport = false) => {
    const num = props.page * props.pageLimit - props.pageLimit + no + 1
    const commonStyle = isExport ? { fontSize: 9 } : {}

    const columns = [
      { option: 'TEXT', align: 'center', label: num, ...(commonStyle && { style: commonStyle }) },
      { option: 'TEXT', align: 'left', label: data.date, ...(commonStyle && { style: commonStyle }), class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: data.cn, ...(commonStyle && { style: commonStyle }), class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: data.pt, ...(commonStyle && { style: commonStyle }) },
      { option: 'TEXT', align: 'left', label: data.patientFullName, ...(commonStyle && { style: commonStyle }) },
      { option: 'TEXT', align: 'left', label: data.doctorFullName, ...(commonStyle && { style: commonStyle }) },
      { option: 'TEXT', align: 'left', label: data.operativeTypeName, ...(commonStyle && { style: commonStyle }) },
      { option: 'TEXT', align: 'left', label: data.operativeCode, ...(commonStyle && { style: commonStyle }) },
      { option: 'TEXT', align: 'left', label: data.operativeName, ...(commonStyle && { style: commonStyle }) }
    ]

    return <TableRowCommon key={num.toString()} id={no.toString()} obj={data} columns={columns} />
  }

  return (
    <>
      {(!_.isEmpty(props.data) && (
        <Box>
          <UseStyled.ResponsiveReportTable>
            <TableCustom
              tableMinWidth={1920}
              page={props.page}
              pageLimit={props.pageLimit}
              sortType={''}
              sortBy={''}
              rowCount={props.rowCount}
              onSort={props.onRequestSort}
              setPageLimit={props.handleChangeRowsPerPage}
              setPage={props.handleChangePage}
              headCells={headCells}
              rowsData={[
                _.map(props.data.rows, (d: any, index: number) => {
                  return renderData(d, index)
                })
              ]}
              customScroll
            />
          </UseStyled.ResponsiveReportTable>
        </Box>
      )) || <EmptyData />}
      <div className="print-show" ref={props.componentRef}>
        <NewPatientExport
          headCells={t(`REPORT.REPORT_NAME.PATIENT_TREATMENT`)}
          componant={
            <>
              <table>
                <tr>
                  <TableSortLabel>
                    <td style={{ fontSize: 10, marginLeft: '30px' }}>{t(`REPORT.FILTER.BRANCH`)} : </td>
                    <td style={{ fontSize: 10 }}>{props.headCellsSeconds[0]}</td>
                  </TableSortLabel>
                </tr>
              </table>
              {_.map(_.groupBy(props.dataExport.rows, 'dateOrder'), (data: any, index: number) => {
                console.log(data)
                return (
                  <Box key={index}>
                    <Box>
                      <UseStyled.Styles className="pt-3">
                        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '99%', border: 'none' }}>
                          <thead>
                            <tr>
                              {_.map(headCells, (val: any) => {
                                return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 300 }}> {t(val.labelKey)}</td>
                              })}
                            </tr>
                          </thead>
                          <tbody style={{ fontSize: 10 }}>
                            {data.length > 0 && [
                              _.map(data, (data: any, indexData: number) => {
                                return renderData(data, indexData, true)
                              })
                            ]}
                            {!data.length && <td colSpan={headCells.length}>{t('NOT_FOUND')}</td>}
                          </tbody>
                        </table>
                      </UseStyled.Styles>
                    </Box>
                  </Box>
                )
              })}
            </>
          }
        />
      </div>
    </>
  )
}
export function NewPatientExport(props: ExportProps) {
  return (
    <>
      <div className="large" style={{ fontSize: 14 }}>
        {props.headCells}
        {props.headCellsSeconds}
      </div>
      {props.componant}
    </>
  )
}
