import { menuThirds } from 'constants/menus'
import TreatmentCourseTypes from 'features/setting/treatment-courses/treatment-course-types/treatment-course-types'
import TreatmentCourse from 'features/setting/treatment-courses/treatment-course/treatment-course'
import FromTreatmentCourse from 'features/setting/treatment-courses/treatment-course/form-trement-course'

const routeTreatmentCourses = [
  {
    ...menuThirds.TREATMENT_COURSE_TYPE,
    component: TreatmentCourseTypes,
    appBar: true
  },
  {
    ...menuThirds.TREATMENT_COURSE.name,
    key: menuThirds.TREATMENT_COURSE.key,
    textname: `เพิ่ม ${menuThirds.TREATMENT_COURSE.name}`,
    active: false,
    path: menuThirds.TREATMENT_COURSE.path + '/create',
    component: FromTreatmentCourse,
    appBar: true
  },
  {
    ...menuThirds.TREATMENT_COURSE.name,
    key: menuThirds.TREATMENT_COURSE.key,
    textname: `แก้ไข ${menuThirds.TREATMENT_COURSE.name}`,
    active: false,
    path: menuThirds.TREATMENT_COURSE.path + '/update',
    component: FromTreatmentCourse,
    appBar: true
  },
  {
    ...menuThirds.TREATMENT_COURSE,
    component: TreatmentCourse,
    appBar: true
  }
]

export default routeTreatmentCourses
