import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Card, Col, Row } from 'react-bootstrap'
import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody, Paper } from '@mui/material'
import { useParams } from 'react-router'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/finance/income-expenses/style'
import { colors } from 'constants/theme'

/** API */
import IncomeExpensesApi from 'api/finances/income-expenses.api'


/** UTILS */
import { routeName } from 'routes/routes-name'
import { dateToView } from 'utils/date.utils'

/** COMPONENT */
import StatusSelect from 'features/finance/income-expenses/statusSelector-income-expenses'
import IncomeExpensesTableRow from 'features/finance/income-expenses/tableRow-income-expenses'
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import { notiSuccess, notiError } from 'component/notifications/notifications'

export default function IncomeExpensesView() {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { id }: any = useParams()

  const [net, setNet] = useState(Number)
  const [tax, setTax] = useState(Number)
  const [prefix, setPrefix] = useState('')
  const [hasTax, setHasTax] = useState('')
  const [unitTax, setUnitTax] = useState('')
  const [total, setTotal] = useState(Number)
  const [remarks, setRemarks] = useState('')
  const [itemArray, setItemArray] = useState([])
  const [documentNo, setDocumentNo] = useState('')
  const [amountTax, setAmountTax] = useState(Number)
  const [expenseGroup, setExpenseGroup] = useState('')
  const [documentDate, setDocumentDate] = useState('')
  const [documentStatus, setDocumentStatus] = useState('')
  const [paymentChannelId, setPaymentChannelId] = useState(Number)

  const [taxText, setTaxText] = useState(String)
  const [documentId, setDocumentId] = useState(String)
  const [docStatusBgColor, setDocStatusBgColor] = useState('')
  const [innerBoxTitleText, setInnerBoxTitleText] = useState('')
  const [innerBoxTableText, setInnerBoxTableText] = useState('')
  const [expenseGroupText, setExpenseGroupText] = useState(String)
  const [paymentChannelText, setPaymentChannelText] = useState(String)

  const pageLimit = 10
  const page = 1
  const headerCells = [
    { id: 'expenseTypeId', disablePadding: false, label: t('INCOME_EXPENSES.TABLE.CELL.HEADER.ID'), align: 'center' },
    { id: 'expenseTypeName', disablePadding: false, label: innerBoxTableText, align: 'left' },
    { id: 'qty', disablePadding: false, label: t('INCOME_EXPENSES.TABLE.CELL.HEADER.QTY'), align: 'right' },
    { id: 'price', disablePadding: false, label: t('INCOME_EXPENSES.TABLE.CELL.HEADER.PRICE'), align: 'right' },
    { id: 'file', disablePadding: false, label: '', align: 'right' },
    { id: 'total', disablePadding: false, label: t('INCOME_EXPENSES.TABLE.CELL.HEADER.TOTAL'), align: 'right' },
    { id: 'createdBy', disablePadding: false, label: t('INCOME_EXPENSES.TABLE.CELL.HEADER.CREATED_BY'), align: 'center' }
  ]
  const onEdit = () => history.push(`${routeName.incomeExpenses}/update/${id}`)

  const handleChangeStatus = async (newStatus: string) => {
    setDocumentStatus(newStatus)
    const res = await IncomeExpensesApi.updateDocumentStatus(id, newStatus)
    if (res.status === 200) {
      notiSuccess(t('INCOME_EXPENSES.MESSAGE.SUCCESS.UPDATE'), 'go', routeName.incomeExpenses)
    } else {
      notiError('Error Status: 400')
    }
  }

  const renderInnerText = () => {
    if (expenseGroup === 'EXPENSE') {
      setInnerBoxTitleText(t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.EXPENSE.INNER_TITLE'))
      setInnerBoxTableText(t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.EXPENSE.INNER_TABLE_HEADER'))
    } else if (expenseGroup === 'INCOME') {
      setInnerBoxTitleText(t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.INCOME.INNER_TITLE'))
      setInnerBoxTableText(t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.INCOME.INNER_TABLE_HEADER'))
    } else {
      return
    }
  }

  const changeStatusSelectBgColor = () => {
    if (documentStatus === 'PENDING_APPROVAL') {
      setDocStatusBgColor(colors.approveStatus.pending)
    } else if (documentStatus === 'APPROVAL') {
      setDocStatusBgColor(colors.approveStatus.approve)
    } else if (documentStatus === 'UNAPPROVAL') {
      setDocStatusBgColor(colors.approveStatus.cancel)
    } else {
      setDocStatusBgColor(colors.disabledLightGray)
    }
  }

  const loadDataByid = async () => {
    const { status, data }: any = await IncomeExpensesApi.findById(id)
    if (status === 200) {
      setDocumentNo(data.documentNo)
      setExpenseGroup(data.expenseGroup)
      setDocumentDate(data.documentDate)
      setHasTax(data.hasTax)
      setAmountTax(data.amountTax)
      setUnitTax(data.unitTax)
      setPaymentChannelId(data.paymentChannelId)
      setItemArray(data.incomeExpenseItems)
      setDocumentStatus(data.documentStatus)
      setNet(data.net)
      setTax(data.tax)
      setTotal(data.total)
      setPrefix(data.prefix)

      if (data.remark) {
        setRemarks(data.remark)
      } else {
        setRemarks('-')
      }

      if (data.hasTax === 'HAS') {
        if (data.unitTax === 'PERCENT') {
          const finalTax = Math.round(data.net * (data.amountTax / 100))
          setTax(finalTax)
          const finalTotal = data.net - finalTax
          setTotal(finalTotal)
        } else {
          setTax(data.amountTax)
        }
      }
    }
  }

  const renderText = () => {
    setDocumentId(prefix + documentNo)

    if (expenseGroup === 'INCOME') {
      setExpenseGroupText(t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.INCOME.TITLE'))
    } else if (expenseGroup === 'EXPENSE') {
      setExpenseGroupText(t('INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.EXPENSE.TITLE'))
    } else {
      setExpenseGroupText('-')
    }

    if (paymentChannelId) {
      setPaymentChannelText(t(`INCOME_EXPENSES.PAYMENT_CHANNEL.${paymentChannelId}`))
    } else {
      setPaymentChannelText('-')
    }


    if (hasTax === 'HAS') {
      if (unitTax === 'PERCENT') {
        setTaxText(amountTax + '%')
      } else if (unitTax === 'BAHT') {
        setTaxText(amountTax + ' บาท')
      } else {
        setTaxText(amountTax.toString())
      }
    } else {
      setTaxText('-')
    }
  }

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { incomeExpenseItemId, expenseTypeName, qty, price, total: incomeTotal, createdBy, createdAt, image } = objData
    const objRenderData = {
      key: incomeExpenseItemId,
      id: incomeExpenseItemId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: expenseTypeName },
        { option: 'TEXT', align: 'right', label: qty },
        { option: 'CURRENCY', align: 'right', label: price },
        image?.fileName ? { option: 'LINK', align: 'right', label: image?.fileName, path: `${image?.filePath}/${image?.fileName}` } : { option: 'TEXT', align: 'right', label: '' },
        { option: 'CURRENCY', align: 'right', label: incomeTotal },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: createdBy, updatedAt: createdAt } }
      ]
    }

    return <IncomeExpensesTableRow {...objRenderData} noButton={true} />
  }

  useEffect(() => {
    loadDataByid()
    renderText()
    renderInnerText()
  }, [documentNo])

  useEffect(() => {
    renderText()
  }, [documentId, tax, total])

  useEffect(() => {
    changeStatusSelectBgColor()
  }, [documentStatus])

  return (
    <div className={`container-card overflow-hidden`}>
      <div className='d-flex flex-column h-100 overflow-auto mb-4'>
        <div>
          <HeaderLabel
            text={t(`INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.${expenseGroup}.INNER_TITLE`)}
            menuNavigation={
              <>
                <Link to={routeName.incomeExpenses} className={'m-0 p-0 link-nav w-100'}>
                  {t(`INCOME_EXPENSES.TITLE`)}
                </Link>
                {` > ${t(`INCOME_EXPENSES.TABLE.CELL.EXPENSE_GROUP.${expenseGroup}.INNER_TITLE`)}`}
              </>
            }
            inputStatus={
              <>
                <Col sm={'auto'} className="" style={{ marginTop: '18px', marginRight: '18px' }}>
                  <StatusSelect
                    onchange={(event) => {
                      const value = event.target.value
                      if (value) {
                        handleChangeStatus(value)
                      }
                    }}
                    label={''}
                    selectId="select-status"
                    value={documentStatus}
                    labelId="label-status"
                    statusSelectorBgColor={docStatusBgColor}
                    disable={documentStatus === 'APPROVAL'}
                  />
                </Col>
              </>
            }
            submitText={t('SALARY.BUTTON.EDIT')}
            onSubmit={() => onEdit()}
            submitTheme='outlined'
            disabledSend={documentStatus === 'APPROVAL'}
          />
        </div>

        <Card className="border shadow-sm mx-4">
          <Card.Body className="h-100 p-0">
            <p className="text-right pt-3 px-3 m-0">
              {t('INCOME_EXPENSES.TABLE.CELL.HEADER.DOCUMENT_NO')} {documentId}
            </p>

            <div style={{ margin: '1em', borderBottom: `1px solid ${colors.lightGray}` }}>
              <Row>
                <Col md={6} xl={3}>
                  <p className="d-inline-block" style={{ width: '150px' }}>
                    {t('INCOME_EXPENSES.TABLE.CELL.HEADER.DOCUMENT_CREATION_DATE')}
                  </p>
                  <p className="d-inline-block">
                    {dateToView(documentDate)}
                  </p>
                </Col>
                <Col md={6} xl={3}>
                  <p className="d-inline-block" style={{ width: '150px' }}>
                    {t('INCOME_EXPENSES.TABLE.CELL.HEADER.EXPENSE_TYPE')}
                  </p>
                  <p className="d-inline-block">
                    {expenseGroupText}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={6} xl={3}>
                  <p className="d-inline-block" style={{ width: '150px' }}>
                    {t('INCOME_EXPENSES.INNER_TEXT.TAX')}
                  </p>
                  <p className="d-inline-block">
                    {taxText}
                  </p>
                </Col>
                <Col md={6} xl={3}>
                  <p className="d-inline-block" style={{ width: '150px' }}>
                    {t('INCOME_EXPENSES.INNER_TEXT.PAYMENT_CHANNEL')}
                  </p>
                  <p className="d-inline-block">
                    {paymentChannelText}
                  </p>
                </Col>
              </Row>
            </div>

            <div style={{ marginLeft: '1rem' }}>{innerBoxTitleText}</div>

            <div style={{ borderRadius: '8px', margin: '1rem' }}>
              <TableContainer component={Paper} className="shadow-none" style={{ minHeight: 'calc(100% - 65px)' }}>
                <Table size="small" sx={{ minWidth: 650 }}>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      {headerCells.map((headCell: any) => (
                        <TableCell key={headCell.id} align={headCell.align} padding={'none'}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className={`sm-data-table ${classes.tableBody}`}>
                    {itemArray.map((val, i) => {
                      return renderData(val, i)
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <Row className='mb-3'>
              <Col>
                <p style={{ marginLeft: '1em' }}>
                  <strong>{t('INCOME_EXPENSES.INNER_TEXT.REMARKS')}</strong>&emsp;{remarks}
                </p>
              </Col>
              <Col>
                <div>
                  <p className="d-inline-block">{t('INCOME_EXPENSES.INNER_TEXT.TOTAL')}</p>
                  <p className="d-inline-block " style={{ float: 'right', marginRight: '1em' }}>
                    {t('INCOME_EXPENSES.INNER_TEXT.BAHT')}
                  </p>
                  <p className="d-inline-block " style={{ float: 'right', marginRight: '3.5em' }}>
                    {Intl.NumberFormat('th-TH', {
                      style: 'currency',
                      currency: 'THB'
                    }).format(net)}
                  </p>
                  <br />
                  <p className="d-inline-block">{t('INCOME_EXPENSES.INNER_TEXT.TAX')}</p>
                  <p className="d-inline-block " style={{ float: 'right', marginRight: '1em' }}>
                    {t('INCOME_EXPENSES.INNER_TEXT.BAHT')}
                  </p>
                  <p className="d-inline-block " style={{ float: 'right', marginRight: '3.5em' }}>
                    {Intl.NumberFormat('th-TH', {
                      style: 'currency',
                      currency: 'THB'
                    }).format(tax)}
                  </p>
                </div>
                <div className='py-2' style={{ borderTop: `1px solid ${colors.textLightGray}`, borderBottom: `3px double ${colors.textLightGray}` }}>
                  <strong className="d-inline-block">ยอดรวมสุทธิ</strong>
                  <strong className="d-inline-block " style={{ float: 'right', marginRight: '1em' }}>
                    {t('INCOME_EXPENSES.INNER_TEXT.BAHT')}
                  </strong>
                  <strong className="d-inline-block " style={{ float: 'right', marginRight: '3.5em' }}>
                    {Intl.NumberFormat('th-TH', {
                      style: 'currency',
                      currency: 'THB'
                    }).format(total)}
                  </strong>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div >
    </div >
  )
}
