import { useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import { useFieldArray, useForm } from 'react-hook-form'
import { Link, useLocation } from 'react-router-dom'
import $ from 'jquery'
import _ from 'lodash'
import locale from 'antd/lib/locale/th_TH'

/** STYLE */
import { CustomTable, NetPrice } from 'features/finance/salary/style';
import { Divider, Box, MenuItem, TableCell, TableRow, IconButton, Button } from '@mui/material';

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** CONSTANT */
import { colors } from 'constants/theme'
import { icons } from 'constants/images'

/** API */
import { routeName } from 'routes/routes-name'
import DentistCostsApi from 'api/finances/dentist-cost.api'
import ClinicApi from 'api/master/clinic.api'

/** COMPONENT */
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import InputTextField from 'component/Input/InputTextField'
import InputTextarea from 'component/Input/InputTextarea'
import BasicSelect from 'component/Select/BasicSelect'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import StatusSelect from 'features/finance/income-expenses/statusSelector-income-expenses'
import { Card, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { fullDateToView } from 'utils/date.utils'
import { ConfigProvider } from 'antd';
import BranchApi from 'api/setting/branch-service-points/branch.api'
import PaymentChannelsApi from 'api/setting/finances/payment-channels.api'
import InputSecondNewDatePicker, { PICKER_VIEWS } from 'component/Input/InputSecondNewDatePicker'
import TableCustom from 'component/Table/TableCustom'
import { numberOnly } from 'utils/app.utils'
import { getBranch, getBranchName, getServicePointName, isBuddhistYear } from 'utils/app.utils'
type FormValuesType = {
  branchId: any
  paymentChanelId: any
  userId: any
  monthlyTemplate: any
  dentistCostItems: any[]
}

export default function FormSalary() {
  const { t } = useTranslation()
  const location = useLocation()
  const [useType] = useState('')

  const [items, setItems] = useState<TypeItemsInterface[]>([])
  const [branchId, setBranchId] = useState<number>(0)
  const [branches, setBranches] = useState([])
  const [prefix, setPrefix] = useState('')
  const [documentNo, setDocumentNo] = useState('')
  const [documentDate, setDocumentDate] = useState('')
  const [documentStatus, setDocumentStatus] = useState('IN_PROGRESS')
  const [paymentChannels, setPaymentChannels] = useState([])
  const [dentistCostId, setDentistCostId] = useState(Number)
  const [remark, setRemark] = useState('')
  const [monthlyTemplate, setMonthlyTemplate] = useState('')
  const [monthly, setMonthly] = useState('')

  const [monthlies, setMonthlies] = useState([])
  const [logs, setLogs] = useState([])
  const [users, setUsers] = useState([])
  const [total, setTotal] = useState(Number)
  const [totalSummery, setTotalSummery] = useState(0)
  const [docStatusBgColor, setDocStatusBgColor] = useState('')
  const [showEditHistory, setShowEditHistory] = useState('')
  const userId = ''
  const paymentChannelId = ''

  const [file, setFile] = useState({
    fileId: 0,
    base64: '',
    fileName: '',
    fileExtension: '',
    fileSize: ''
  })

  interface TypeItemsInterface {
    dentistCostItemId?: number
    itemName: string
    userId: number
    paidDate: string
    paymentChannelId: number
    cost: any
    file: any
  }

  const { control, getValues, setValue } = useForm<FormValuesType>({
    defaultValues: {
      branchId: branchId,
      monthlyTemplate: monthlyTemplate
    }
  })

  const formDentistCostItems = useFieldArray({
    control,
    name: 'dentistCostItems'
  })

  const documentNoId = prefix + documentNo
  const handleChangeStatus = (newStatus: string) => setDocumentStatus(newStatus)

  const changeStatusSelectBgColor = () => {
    if (documentStatus === 'PENDING_APPROVAL') {
      setDocStatusBgColor(colors.approveStatus.pending)
    } else if (documentStatus === 'APPROVAL') {
      setDocStatusBgColor(colors.approveStatus.approve)
    } else if (documentStatus === 'UNAPPROVAL') {
      setDocStatusBgColor(colors.approveStatus.cancel)
    } else {
      setDocStatusBgColor(colors.disabledLightGray)
    }
  }
  useEffect(() => {
    changeStatusSelectBgColor()
  }, [documentStatus])

  const onEdit = (data: any) => {
    setDentistCostId(data.dentistCostId)
    loadDentistCostByid(data.dentistCostId)
  }

  useEffect(() => {
    if (location.state) {
      onEdit(location.state)
    }
  }, [])

  const submit = () => {
    const dataCreate = getValues()
    if (!documentDate) return notiError(t('SALARY.MESSAGE.PLEASE_SET_DOC_DATE'))
    if (!monthly) return notiError(t('SALARY.MESSAGE.PLEASE_SET_MONTHLY'))


    const objCreate = {
      branchId: branchId === 0 ? getBranch() : branchId,
      dentistCostItems: items,
      documentDate: documentDate,
      userId: dataCreate.userId,
      paymentChannelId: dataCreate.paymentChanelId,
      documentStatus: 'PENDING_APPROVAL',
      monthlyTemplate: monthlyTemplate,
      monthly: monthly,
      remark: remark,
      total: total
    }

    DentistCostsApi.create(objCreate)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) notiSuccess(t('SALARY.MESSAGE.SUCCESS.SAVE'), 'go', routeName.salary)
        else {
          const err = res.response.data
          const str = "dentistCostItems"
          const regex = new RegExp(str, "g")
          if (err.message.match(regex)) {
            notiError(t(`SALARY.MESSAGE.COST_ITEM_REQUIRED`))
          } else {
            notiError(t(`SALARY.MESSAGE.${err.message}`))
          }
        }
      })
      .catch((e) => {
        const err = e.response.data
        const str = "dentistCostItems"
        const regex = new RegExp(str, "g")
        if (err.message.match(regex)) {
          notiError(t(`SALARY.MESSAGE.COST_ITEM_REQUIRED`))
        } else {
          notiError(t(`SALARY.MESSAGE.${err.message}`))
        }
      })
  }

  const submitUpdate = () => {
    const dataUpdate = getValues()
    if (!documentDate) return notiError(t('SALARY.MESSAGE.PLEASE_SET_DOC_DATE'))
    if (!monthly) return notiError(t('SALARY.MESSAGE.PLEASE_SET_MONTHLY'))

    const objUpdate = {
      branchId: branchId,
      dentistCostItems: items,
      documentDate: documentDate,
      userId: dataUpdate.userId,
      paymentChannelId: dataUpdate.paymentChanelId,
      documentStatus: documentStatus,
      monthlyTemplate: monthlyTemplate,
      monthly: monthly,
      remark: remark,
      total: total,
      status: 'ACTIVE'
    }
    DentistCostsApi.update(dentistCostId, objUpdate)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200)
          notiSuccess(t('SALARY.MESSAGE.SUCCESS.UPDATE'), 'go', routeName.salary, () => {
            loadBranch()
            loadPaymentChannels()
            loadAllUser()
            loadItems()
          })
        else {
          const err = res.response.data
          const str = "dentistCostItems"
          const regex = new RegExp(str, "g")
          if (err.message.match(regex)) {
            notiError(t(`SALARY.MESSAGE.COST_ITEM_REQUIRED`))
          } else {
            notiError(t(`SALARY.MESSAGE.${err.message}`))
          }
        }
      })
      .catch((e) => {
        const err = e.response.data
        const str = "dentistCostItems"
        const regex = new RegExp(str, "g")
        if (err.message.match(regex)) {
          notiError(t(`SALARY.MESSAGE.COST_ITEM_REQUIRED`))
        } else {
          notiError(t(`SALARY.MESSAGE.${err.message}`))
        }
      })
  }

  const loadItems = useCallback(async () => {

    let condition: any = {}
    if (useType) condition = { ...condition, page: useType }
    const res = await DentistCostsApi.findAll(condition)
    if (res.status === 200) {
      setItems(res.data)
    }
  }, [])

  const loadDentistCostByid = async (valueDentistCostId: number) => {
    const { status, data }: any = await DentistCostsApi.findById(valueDentistCostId)
    if (status === 200) {
      setValue('branchId', data.branchId)
      setValue('monthlyTemplate', data.monthlyTemplate)
      const temp: TypeItemsInterface[] = data.dentistCostItems.map((dct: any) => {
        return {
          dentistCostItemId: dct.dentistCostItemId,
          itemName: dct.itemName,
          userId: dct.userId,
          paidDate: dct.paidDate,
          paymentChannelId: dct.paymentChannelId,
          cost: dct.cost,
          file: dct.file ? { fileId: 0, base64: '', fileName: dct.file.fileName, fileExtension: '', fileSize: '' } : {}
        }
      })

      setItems(temp)
      setBranchId(data.branchId)
      setPrefix(data.prefix)
      setDocumentNo(data.documentNo)
      setDocumentDate(data.documentDate)
      setDocumentStatus(data.documentStatus)
      setMonthlyTemplate(data.monthlyTemplate)
      setMonthly(data.monthly)
      setRemark(data.remark)
      setFile(data.file)
      setTotal(data.total)
      setTotalSummery(data.total)
      setLogs(JSON.parse(data.logs))

      for (const item of data.dentistCostItems) {
        if (item) formDentistCostItems.prepend({ dentistCostItemId: item.dentistCostItemId, itemName: item.itemName, userId: item.userId, paidDate: item.paidDate, paymentChannelId: item.paymentChannelId, cost: item.cost, file: item.file }, { focusIndex: 1 })
      }

      if (JSON.parse(data.logs)) {
        setShowEditHistory(t('SALARY.TEXT.EDIT_HISTORY'))
      }
    }
  }

  const updateText = (val: any) => {
    let action = ''
    if (val.action === 'UPDATED') action = t('SALARY.DOCUMENT_STATUS.UPDATED')
    else if (val.action === 'CREATED') action = t('SALARY.CREATE_DOCUMENT_SALARY')
    else if (val.action === 'UPDTAED_DOCUMENT_STATUS:APPROVAL') action = t('SALARY.DOCUMENT_STATUS.APPROVAL')
    else if (val.action === 'UPDTAED_DOCUMENT_STATUS:UNAPPROVAL') action = t('SALARY.DOCUMENT_STATUS.UNAPPROVAL')
    else if (val.action === 'UPDTAED_DOCUMENT_STATUS:PENDING_APPROVAL') action = t('SALARY.DOCUMENT_STATUS.PENDING_APPROVAL')
    const originalDate = moment(val.updatedAt);
    const newDate = originalDate.add(isBuddhistYear(), 'years');
    const formattedDate = newDate.format('LLLL');
    return (
      <>
        {action}  {val.updatedBy} : {formattedDate}
      </>
    )
  }

  const loadBranch = useCallback(async () => {

    let condition: any = {}
    if (useType) condition = { ...condition, page: useType }
    const res = await BranchApi.findAll(condition)
    if (res.status === 200) {
      setBranches(res.data)
    }
  }, [])

  const loadMonthlyTemplate = async (template: string) => {

    const condition: any = { monthly: template }
    const res = await DentistCostsApi.templates(condition)
    if (res.status === 200) {
      const data = res.data[res.data.length - 1]
      const temp: TypeItemsInterface[] = data.dentistCostItems.map((dct: any) => {
        return {
          itemName: dct.itemName,
          userId: dct.userId,
          paidDate: dct.paidDate,
          paymentChannelId: dct.paymentChannelId,
          cost: dct.cost,
          file: dct.file ? { fileId: 0, base64: '', fileName: '', fileExtension: '', fileSize: '' } : {}
        }
      })

      setItems([...items, ...temp])
    }
  }

  const loadMonthlies = useCallback(async () => {

    const res = await DentistCostsApi.monthly()
    if (res.status === 200) {
      setMonthlies(res.data)
    }
  }, [])

  const loadAllUser = useCallback(async () => {

    let condition: any = {}
    if (useType) condition = { ...condition, page: useType }
    const res = await ClinicApi.findUsers(condition)

    if (res.status === 200) {
      setUsers(res.data)
    }
  }, [])

  const loadPaymentChannels = useCallback(async () => {

    let condition: any = {}
    if (useType) condition = { ...condition, page: useType }
    const res = await PaymentChannelsApi.findAll(condition)
    if (res.status === 200) {
      setPaymentChannels(res.data)
    }
  }, [])

  const headCells = [
    { id: 'no', disablePadding: false, label: '#', width: '80px' },
    { id: 'message', disablePadding: false, label: t('SALARY.TABLE.CELL.SALARY.ITEM') },
    { id: 'message', disablePadding: false, label: t('SALARY.TABLE.CELL.SALARY.USER') },
    { id: 'message', disablePadding: false, label: t('SALARY.TABLE.CELL.SALARY.PAID_DATE') },
    { id: 'message', disablePadding: false, label: t('SALARY.TABLE.CELL.SALARY.PAYMENT_CHANNEL') },
    { id: 'message', disablePadding: false, label: t('SALARY.TABLE.CELL.SALARY.TOTAL'), align: 'right' },
    { id: 'message', disablePadding: false, label: t('SALARY.TABLE.CELL.SALARY.FILE') },
    { id: 'message', disablePadding: false, label: t('SALARY.TABLE.CELL.SALARY.CREATOR'), align: 'center' },
    { id: 'message', disablePadding: false, label: t('Action'), align: 'center', width: '150px' },
  ]

  const addNewRow = () => {
    return (

      <TableRow key="add" hover={true} className="row-add-new-row">
        <TableCell onClick={() =>
          setItems([
            ...items,
            {
              itemName: '',
              userId: 0,
              paidDate: '',
              paymentChannelId: 0,
              cost: 0,
              file: file
            }
          ])}
          colSpan={_.size(headCells)}
          align="left"
          size="small"
          className="p-2">
          <Button variant="text" className="btn-add-new-row d-flex align-items-baseline">
            <span className={'mr-1'}>+</span> {t('SALARY.BUTTON.ADD_ROW')}
          </Button>
        </TableCell>
      </TableRow>
    )
  }

  useEffect(() => {
    loadBranch()
    loadMonthlies()
    loadAllUser()
    loadPaymentChannels()
  }, [])

  useEffect(() => {
    let totalCost = 0
    if (items.length) {
      totalCost = items?.map((item: TypeItemsInterface) => item.cost).reduce((a, b) => parseFloat(String(a || '0')) + parseFloat(String(b || '0'))) || 0

    }
    setTotal(totalCost)
    setTotalSummery(totalCost)
  }, [items])

  return (
    <div className={'container-card overflow-hidden'}>
      <div className='d-flex flex-column h-100 overflow-auto'>
        <Card className="border-0 h-100">
          <HeaderLabel
            text={dentistCostId ? t('SALARY.TITLE_UPDATE') : t('SALARY.TITLE_CREATE')}
            menuNavigation={
              <>
                <Link to={routeName.salary} className={'m-0 p-0 link-nav w-100'}>
                  {t('SALARY.TITLE')}
                </Link>
                {` > ${dentistCostId ? t('SALARY.TITLE_UPDATE') : t('SALARY.TITLE_CREATE')}`}
              </>
            }
            inputStatus={
              <>
                {dentistCostId ? (
                  <Col sm={'auto'} className="" style={{ marginTop: '18px', marginRight: '18px' }}>
                    <StatusSelect
                      onchange={(event) => {
                        const value = event.target.value
                        if (value) {
                          handleChangeStatus(value)
                        }
                      }}
                      label={''}
                      selectId="select-status"
                      value={documentStatus}
                      labelId="label-status"
                      statusSelectorBgColor={docStatusBgColor}
                    />
                  </Col>
                ) : (
                  <></>
                )}
              </>
            }
            goBack={routeName.salary}
            onSubmit={dentistCostId ? submitUpdate : submit}
          />

          <div className="mb-4" style={{ backgroundColor: colors.white }}>
            <Card className="border shadow-sm mx-4">
              <Card.Body className="h-100">
                <Row>
                  <Box sx={{ width: '100%', typography: 'body1' }}>
                    <Row className="mt-1">
                      <Col xs={12} sm={6} lg={8} xl={9} className="order-sm-last mb-3 mb-sm-0 text-right">
                        {t('SALARY.TEXT.DOCUMENT_NO')}
                        <span className='ml-1'>{documentNoId || '-'}</span>
                      </Col>
                      <Col xs={12} sm={6} lg={4} xl={3}>
                        <InputSecondNewDatePicker
                          key={'documentDate'}
                          required
                          label={t('SALARY.TEXT_INPUT.DOCUMENT_DATE')}
                          value={documentDate}
                          onchange={(e: any) => {
                            setDocumentDate(moment(e).format('YYYY-MM-DD'))
                          }}
                        />
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col xs={12} sm={6} lg={4} xl={3}>
                        <BasicSelect
                          onchange={(event: any) => {
                            const value = event.target.value
                            setBranchId(value)
                          }}
                          required={true}
                          name="branchId"
                          label={t('SALARY.INPUT.BRANCH')}
                          selectId="sl-branch-id"
                          value={!branchId ? getBranch() : branchId}
                          labelId="lb-branch-id"
                          options={branches.map((val: any) => {
                            return (
                              <MenuItem key={branchId} value={val.branchId}>
                                {val.branchName}
                              </MenuItem>
                            )
                          })}
                        />
                      </Col>
                      {/* getBranchName() */}
                      <Col xs={12} sm={6} lg={4} xl={3}>
                        <BasicSelect
                          onchange={(event: any) => {
                            const value = event.target.value
                            setMonthlyTemplate(value)
                            loadMonthlyTemplate(value)
                          }}
                          required={false}
                          name="monthlyTemplate"
                          label={t('SALARY.TEXT_INPUT.MONTHLY_TEMPLATE')}
                          selectId="sl-monthly-template"
                          value={monthlyTemplate}
                          labelId="lb-monthly-template"
                          options={monthlies.map((val: any) => {
                            return (
                              <MenuItem key={monthly} value={val.monthly}>
                                {fullDateToView(val.monthly)}
                              </MenuItem>
                            )
                          })}
                        />
                      </Col>
                      <Col xs={12} sm={6} lg={4} xl={3} className="mt-3 mt-lg-0">
                        <ConfigProvider locale={locale}>
                          <InputSecondNewDatePicker
                            key={'monthly'}
                            required
                            label={t('SALARY.TEXT_INPUT.MONTHLY')}
                            views={PICKER_VIEWS.MONTH}
                            // views={[PICKER_VIEWS.YEAR, PICKER_VIEWS.MONTH]}
                            dateFormat="MMMM YYYY"
                            value={monthly}
                            onchange={(e: any) => setMonthly(moment(e).format('YYYY-MM-DD'))} />
                        </ConfigProvider>
                      </Col>
                    </Row>
                  </Box>
                </Row>
                <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                <div className="mx-auto" style={{ fontSize: '18px', fontWeight: 500, color: colors.themeMainColor }}>
                  {t('SALARY.TEXT.SALARY')}
                </div>
                <CustomTable
                  className='mt-3'
                  sx={{
                    '.MuiTableContainer-root': {
                      minHeight: 'unset !important'
                    }
                  }}>
                  <TableCustom
                    hidePagination
                    page={0}
                    pageLimit={0}
                    sortType={'ASC'}
                    sortBy={''}
                    rowCount={0}
                    textEmptyData={t('REGISTER.NO_ITEM')}
                    onSort={() => { return }}
                    setPageLimit={() => { return }}
                    setPage={() => { return }}
                    headCells={headCells}
                    rowsData={[
                      addNewRow(),
                      items.map((item: TypeItemsInterface, index: number, q: any) => (
                        <TableRow key={index} hover={true}>
                          <TableCell size="small" align="center">{index + 1}</TableCell>
                          <TableCell size="small">
                            <InputTextField
                              hookValue={false}
                              type="text"
                              key={`item-name-${index}`}
                              value={item.itemName}
                              onchange={(e) => {
                                const temp: TypeItemsInterface[] = [...items]
                                temp[index] = { ...temp[index], itemName: e.target.value }
                                setItems(temp)
                              }}
                            />
                          </TableCell>
                          <TableCell size="small">
                            <BasicSelect
                              label={''}
                              selectId="user-id"
                              labelId="user-id"
                              value={item.userId}
                              options={users.map((val: any) => {
                                return (
                                  <MenuItem key={userId} value={val.userId}>
                                    {val.fullname}
                                  </MenuItem>
                                )
                              })}
                              onchange={(e) => {
                                const temp: TypeItemsInterface[] = [...items]
                                temp[index] = { ...temp[index], userId: e.target.value }
                                setItems(temp)
                              }}
                            />
                          </TableCell>
                          <TableCell size="small">
                            <InputSecondNewDatePicker
                              key={`paid-date${index}`}
                              value={item.paidDate}
                              label={''}
                              onchange={(e: any) => {
                                const temp: TypeItemsInterface[] = [...items]
                                temp[index] = { ...temp[index], paidDate: moment(e).format('YYYY-MM-DD') }
                                setItems(temp)
                              }}
                            />
                          </TableCell>
                          <TableCell size="small">
                            <BasicSelect
                              label={''}
                              selectId="payment-channel-id"
                              labelId="payment-channel-id"
                              value={item.paymentChannelId}
                              options={paymentChannels.map((val: any) => {
                                return (
                                  <MenuItem key={paymentChannelId} value={val.paymentChannelId}>
                                    {val.paymentChannelName}
                                  </MenuItem>
                                )
                              })}
                              onchange={(e) => {
                                const temp: TypeItemsInterface[] = [...items]
                                temp[index] = { ...temp[index], paymentChannelId: e.target.value }
                                setItems(temp)
                              }}
                            />
                          </TableCell>
                          <TableCell size="small" align='right'>
                            <InputTextField
                              inputTextAlign='right'
                              hookValue={false}
                              key={`cost${index}`}
                              value={item.cost}
                              onchange={(e) => {
                                const temp: TypeItemsInterface[] = [...items]
                                temp[index] = { ...temp[index], cost: numberOnly(e) }
                                setItems(temp)
                              }}
                              // helperText={'กรอกซะไอเวร'}
                              error={item.cost === ""}
                            />
                          </TableCell>
                          <TableCell size="small" align={`left`}>
                            <div className="d-flex align-items-center">
                              <div className="d-none">
                                <input
                                  id={`uploadFiles-${index}`}
                                  type="file"
                                  onChange={(e) => {
                                    if (e.target.files) {
                                      const rawFile = e.target.files[0]
                                      const fileName = rawFile.name.split('.')
                                      const detailFile = {
                                        fileId: 0,
                                        base64: '',
                                        type: '',
                                        fileExtension: fileName[fileName.length - 1],
                                        fileName: rawFile.name,
                                        fileSize: rawFile.size.toString()
                                      }
                                      const reader: any = new FileReader()
                                      reader.addEventListener('load', () => {
                                        const base64 = reader.result.split(',')
                                        detailFile.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result
                                      })
                                      reader.readAsDataURL(rawFile)
                                      const temp: TypeItemsInterface[] = [...items]
                                      temp[index] = { ...temp[index], file: detailFile }
                                      setItems(temp)
                                    }
                                  }}
                                />
                              </div>
                              <IconButton aria-label="upload-file" onClick={() => $(`#uploadFiles-${index}`).trigger("click")}>
                                <FontAwesomeIcon color={colors.themeSecondColor} icon={faPaperclip} size="sm" />
                              </IconButton>
                              {item.file?.fileName && (
                                <label className='text-ellipsis w-100' htmlFor={`contained-button-file`}>{item.file?.fileName}</label>)
                                || <></>
                              }
                            </div>
                          </TableCell>
                          <TableCell size="small">-</TableCell>
                          <TableCell size="small" align='center'>
                            <IconButton aria-label="delete" onClick={() => setItems([...items.filter((itemData: any, i: number) => i !== index)])}>
                              <img src={icons.btnMinus} alt="discard" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ]}
                    tableFixedWidth
                    tableMinWidth={1200}
                  />
                </CustomTable>
                <NetPrice className={`d-flex align-items-center justify-content-between justify-content-md-end mt-3 px-3 px-md-4`}>
                  {`${t('SALARY.TEXT_INPUT.TOTAL')}: ${Intl.NumberFormat('th-TH', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(totalSummery)} ${t('SALARY.TEXT_INPUT.BAHT')}`}
                </NetPrice>
                <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

                <div className="my-2 mx-auto">
                  <InputTextarea
                    hookValue={false}
                    type="text"
                    size="medium"
                    key={`remark`}
                    value={remark}
                    label={t('SALARY.TEXT_INPUT.REMARKS')}
                    onchange={(event) => {
                      setRemark(event.target.value)
                    }}
                    height={96}
                  />
                </div>
              </Card.Body>
            </Card>

            <div className="mt-4 ml-4" style={{ fontSize: '18px', fontWeight: 500, color: colors.themeMainColor }}>
              {showEditHistory}
            </div>
            {_.map(logs, (val: any, i) => (
              <>
                <div className="mt-2 mx-5">
                  <p className="mb-0">- {updateText(val)}</p>
                </div>
              </>
            ))}
          </div>
        </Card>
      </div>
    </div>
  )
}
