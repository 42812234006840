import applications from 'locales/plus/th/application/application.json'
import news from 'locales/plus/th/application/news.json'
import promotions from 'locales/plus/th/application/promotion.json'
import manageWeb from 'locales/plus/th/application/manageWeb.json'

export const Application = {
  ...applications,
  ...news,
  ...promotions,
  ...manageWeb
}
