import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/55.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethU, TextNumberU } from 'component/ToothChart/style'
import useWindowSize from 'utils/useWindowSize';

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginTop: 'auto',
    top: '1.4%',
    left: '45.2%',
    maxWidth: '65px',
}))

export default function Teeth55(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)

    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 55.25, height: 106.86, viewBox: "0 0 55.25 106.86" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);
    const [width] = useWindowSize()
    useEffect(() => {
        const operative = props.operative || ``
        const valColor = operative ? props.color || colors.themeSecondColor : ''
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }

        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        setStyleSvg({ width: 55.25, height: 106.86, viewBox: "0 0 55.25 106.86" })
    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <DivTeethU className={`div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge width={width > 1440 ? 70 : 100} />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`md teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`md ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12358" >
                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ''}
                            <path
                                data-name="Path 5444"
                                d="M20.2372 106.796C25.9744 104.778 31.4027 103.424 37.753 104.367C45.6552 105.532 49.2053 100.221 53.2399 95.4606L52.3081 85.4443C44.7322 75.7712 44.5893 64.6452 46.5067 53.2538C48.942 38.7991 46.2129 24.5262 45.0031 10.1891C44.9692 9.4949 44.7538 8.82176 44.3785 8.23682C43.7979 7.38336 42.3199 7.65524 42.158 8.63805C38.9272 28.2979 30.406 42.8178 29.3915 47.2634C29.2195 48.0121 27.9967 47.9936 27.8544 47.2404C26.9025 42.2169 26.9904 22.4851 32.636 3.9911C33.1683 2.24654 31.1316 0.626735 28.7518 0.529104C26.6119 0.438542 25.7833 2.023 24.55 3.26526C8.81684 19.0953 8.76849 38.4708 8.6338 57.7363C8.57037 66.697 6.93051 75.2968 3.31012 83.6248C1.58314 87.6033 1.04989 91.777 0.755274 95.9226C0.285459 102.468 12.5734 109.483 20.2372 106.796Z"
                                transform={width > 1440 ? "translate(0 10)" : "translate(0 0)"}
                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />
                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5444"
                                    d="M20.2372 106.796C25.9744 104.778 31.4027 103.424 37.753 104.367C45.6552 105.532 49.2053 100.221 53.2399 95.4606L52.3081 85.4443C44.7322 75.7712 44.5893 64.6452 46.5067 53.2538C48.942 38.7991 46.2129 24.5262 45.0031 10.1891C44.9692 9.4949 44.7538 8.82176 44.3785 8.23682C43.7979 7.38336 42.3199 7.65524 42.158 8.63805C38.9272 28.2979 30.406 42.8178 29.3915 47.2634C29.2195 48.0121 27.9967 47.9936 27.8544 47.2404C26.9025 42.2169 26.9904 22.4851 32.636 3.9911C33.1683 2.24654 31.1316 0.626735 28.7518 0.529104C26.6119 0.438542 25.7833 2.023 24.55 3.26526C8.81684 19.0953 8.76849 38.4708 8.6338 57.7363C8.57037 66.697 6.93051 75.2968 3.31012 83.6248C1.58314 87.6033 1.04989 91.777 0.755274 95.9226C0.285459 102.468 12.5734 109.483 20.2372 106.796Z"
                                    transform={width > 1440 ? "translate(0 10)" : "translate(0 0)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}

                            <g data-name="Group 12358" >
                                <path
                                    data-name="Path 5445"
                                    d="M42.4266 3.22026C44.1885 4.39826 45.4815 6.15695 46.0804 8.19009C47.3314 12.5978 50.4247 22.6819 53.2365 25.3917C56.8666 28.8881 55.6068 40.166 49.3725 43.5145C43.1383 46.863 31.4355 45.8504 29.2106 46.073C25.6783 46.4257 1.55608 53.783 0.163453 36.9161C-1.31726 18.9905 7.75252 15.8602 7.63808 9.1131C7.63808 9.1131 11.152 -2.56426 21.7323 2.55976C22.843 3.08363 24.0793 3.28191 25.2981 3.1316C26.6529 2.9715 26.9089 1.67019 30.2054 1.00607C35.8721 -0.135129 39.9227 1.50378 42.4266 3.22026Z"
                                    transform={width > 1440 ? "translate(0 75)" : "translate(0 60)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5446"
                                    d="M41.3969 4.88988C43.021 5.96782 44.2149 7.58205 44.77 9.45065C45.9261 13.496 48.7823 22.7513 51.3773 25.2385C54.7284 28.4483 53.567 38.7985 47.8098 41.8708C42.0526 44.943 31.2478 44.0156 29.1935 44.2198C25.9331 44.5437 3.65961 51.2959 2.37339 35.8153C1.00713 19.3634 9.38088 16.4908 9.27523 10.299C9.27523 10.299 12.5198 -0.419472 22.289 4.28288C23.3152 4.76417 24.4562 4.94622 25.5812 4.8081C26.8322 4.66118 27.069 3.46725 30.112 2.85758C35.3449 1.81057 39.0839 3.31421 41.3969 4.88988Z"
                                    transform={width > 1440 ? "translate(0 75)" : "translate(0 60)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5447"
                                    d="M38.5266 7.38627C39.9406 8.33159 40.9783 9.74289 41.4589 11.3744C42.4628 14.9111 44.9446 23.0025 47.2005 25.1766C50.1126 27.983 49.1034 37.0326 44.1002 39.7185C39.0969 42.4044 29.7076 41.5933 27.9228 41.772C25.089 42.0544 5.73276 47.9587 4.61544 34.424C3.42781 20.0405 10.7045 17.5294 10.613 12.1154C10.613 12.1154 13.4326 2.74468 21.9227 6.85608C22.8134 7.27682 23.8053 7.43584 24.7829 7.31463C25.8702 7.18623 26.0762 6.1419 28.7208 5.60961C33.268 4.69444 36.5172 6.00931 38.5266 7.38627Z"
                                    transform={width > 1440 ? "translate(0 75)" : "translate(0 60)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />
                                {(isToothRoot && props.type === 'treatment') ?
                                    <>
                                        <path data-name="Path 6546" d="M20.7819 58.2492C21.2235 55.1324 21.0736 55.6242 21.3654 52.5495C25.4651 36.4667 29.7318 13.7537 29.7318 12.4668C29.7318 9.26673 21.232 56.5666 20.3984 58.1911C20.4624 58.1948 20.6816 58.2407 20.7819 58.2492Z" transform={width > 1440 ? "translate(13 16)" : "translate(13 4)"} style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }} />
                                        <path data-name="Path 6547" d="M2.26562 58.8973C2.26562 51.9332 7.06562 26.0665 13.999 1.79987C13.7579 1.07614 2.26989 44.9058 3.32429 58.6642C2.96747 58.7228 2.61406 58.8006 2.26562 58.8973Z" transform={width > 1440 ? "translate(13 16)" : "translate(13 4)"} style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }} />
                                    </>
                                    : ''}
                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <path d="M49.4957 2L2 102" stroke="#B00020" strokeWidth="3px" strokeLinecap="round" />
                                        <path d="M2 2L49.4957 102" stroke="#B00020" strokeWidth="3px" strokeLinecap="round" />
                                    </>
                                    : ''}
                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <defs>
                                            <linearGradient id="paint0_linear_4126_154146" x1="26.9779" y1="79.0356" x2="24.0658" y2="95.6576" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4126_154146" x1="26.9779" y1="90.6937" x2="26.9779" y2="84.5287" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>
                                        <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M19.6188 103.538C25.1809 101.582 30.4436 100.269 36.6 101.183C44.2611 102.313 47.7028 97.1635 51.6143 92.5487L50.7109 82.8381C43.3663 73.4602 43.2277 62.6738 45.0867 51.6301C47.4476 37.6164 44.8018 23.7791 43.6289 9.87951C43.5961 9.20652 43.3873 8.55393 43.0234 7.98684C42.4605 7.15942 41.0276 7.423 40.8707 8.37582C37.7385 27.4357 29.4773 41.5125 28.4937 45.8224C28.327 46.5483 27.1415 46.5303 27.0035 45.8002C26.0807 40.9299 26.1659 21.8003 31.6393 3.87069C32.1553 2.17936 30.1808 0.608994 27.8736 0.514342C25.799 0.426544 24.9957 1.96265 23.8 3.167C8.54698 18.5139 8.50011 37.2982 8.36953 55.9757C8.30803 64.6629 6.71822 73.0003 3.20832 81.0742C1.53403 84.9312 1.01706 88.9775 0.731437 92.9966C0.27596 99.3424 12.1889 106.143 19.6188 103.538Z" fill="#E3D1B4" />
                                        <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M41.1318 61.2935C42.84 62.4355 44.0935 64.1405 44.6741 66.1116C45.887 70.3848 48.8858 80.1612 51.6119 82.7883C55.1312 86.178 53.9098 97.1117 47.8658 100.358C41.8218 103.604 30.4761 102.623 28.3192 102.838C24.8946 103.18 1.5086 110.313 0.158465 93.9609C-1.27706 76.5824 7.51593 73.5476 7.40498 67.0065C7.40498 67.0065 10.8117 55.6855 21.0691 60.6531C22.1458 61.161 23.3445 61.3532 24.526 61.2075C25.8395 61.0523 26.0877 59.7907 29.2836 59.1468C34.7773 58.0405 38.7044 59.6294 41.1318 61.2935Z" fill="#EFEDE7" />
                                        <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M40.1311 62.911C41.7057 63.956 42.8631 65.521 43.4013 67.3326C44.5221 71.2544 47.2911 80.2273 49.8069 82.6386C53.0558 85.7504 51.9298 95.7848 46.3483 98.7633C40.7668 101.742 30.2917 100.843 28.3001 101.041C25.1392 101.355 3.54544 107.901 2.29848 92.8927C0.973912 76.9428 9.09212 74.1579 8.98969 68.155C8.98969 68.155 12.1352 57.7637 21.6063 62.3225C22.6012 62.7891 23.7074 62.9656 24.798 62.8317C26.0109 62.6893 26.2404 61.5318 29.1905 60.9407C34.2638 59.9257 37.8887 61.3834 40.1311 62.911Z" fill="#F7F7F7" />
                                        <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M37.3471 65.3316C38.7179 66.248 39.7239 67.6163 40.1899 69.198C41.1631 72.6268 43.5693 80.4712 45.7563 82.5789C48.5795 85.2998 47.6011 94.0731 42.7505 96.6771C37.9 99.281 28.7973 98.4947 27.0669 98.6679C24.3195 98.9417 5.55403 104.666 4.47082 91.5442C3.31943 77.5996 10.3741 75.1652 10.2853 69.9163C10.2853 69.9163 13.0189 60.8316 21.2499 64.8176C22.1134 65.2255 23.0751 65.3796 24.0228 65.2621C25.0769 65.1376 25.2766 64.1252 27.8406 63.6091C32.249 62.7219 35.399 63.9966 37.3471 65.3316Z" fill="white" />
                                        <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M33.552 74H20.448C18.5437 74 17 75.5437 17 77.448V90.552C17 92.4563 18.5437 94 20.448 94H33.552C35.4563 94 37 92.4563 37 90.552V77.448C37 75.5437 35.4563 74 33.552 74Z" fill="#C1C4C5" />
                                        {/* <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M33.5611 74.8623H20.4571C19.0289 74.8623 17.8711 76.0201 17.8711 77.4483V90.5523C17.8711 91.9805 19.0289 93.1383 20.4571 93.1383H33.5611C34.9893 93.1383 36.1471 91.9805 36.1471 90.5523V77.4483C36.1471 76.0201 34.9893 74.8623 33.5611 74.8623Z" stroke="#C1C4C5" /> */}
                                        <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M25.4942 93.1063C25.1494 93.7303 28.3836 93.4217 30.8092 93.3907C34.4193 93.3149 36.4794 94.0527 36.445 85.719C36.5386 82.7618 36.4471 79.8017 36.1708 76.8559C35.2554 75.9663 36.4122 85.8827 34.0693 90.2444C31.8591 93.3942 26.0855 92.4373 25.4942 93.1063Z" fill="#B0B1B1" />
                                        <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M30.4259 84.7139H23.53C23.0539 84.7139 22.668 85.0998 22.668 85.5759V87.2999C22.668 87.7759 23.0539 88.1618 23.53 88.1618H30.4259C30.902 88.1618 31.2879 87.7759 31.2879 87.2999V85.5759C31.2879 85.0998 30.902 84.7139 30.4259 84.7139Z" fill="#CBCCCC" />
                                        <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M30.0822 77.4736H23.1862C22.7101 77.4736 22.3242 77.8596 22.3242 78.3356V80.0596C22.3242 80.5357 22.7101 80.9216 23.1862 80.9216H30.0822C30.5582 80.9216 30.9442 80.5357 30.9442 80.0596V78.3356C30.9442 77.8596 30.5582 77.4736 30.0822 77.4736Z" fill="#9E9E9E" />
                                        <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M30.4259 77.4736H23.53C23.0539 77.4736 22.668 77.8596 22.668 78.3356V80.0596C22.668 80.5357 23.0539 80.9216 23.53 80.9216H30.4259C30.902 80.9216 31.2879 80.5357 31.2879 80.0596V78.3356C31.2879 77.8596 30.902 77.4736 30.4259 77.4736Z" fill="url(#paint0_linear_4126_154146)" />
                                        <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M30.4259 84.3701H23.53C23.0539 84.3701 22.668 84.756 22.668 85.2321V86.9561C22.668 87.4322 23.0539 87.8181 23.53 87.8181H30.4259C30.902 87.8181 31.2879 87.4322 31.2879 86.9561V85.2321C31.2879 84.756 30.902 84.3701 30.4259 84.3701Z" fill="url(#paint1_linear_4126_154146)" />
                                        <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M0.511719 84.6684C0.511719 84.6684 22.5486 79.6968 51.5161 83.6367" stroke="#BABBBB" strokeLinecap="round" />
                                        <path transform={width > 1440 ? "translate(0 16)" : "translate(1 4)"} d="M0.511719 85.1782C17.1077 82.1831 34.6385 81.8199 51.5161 84.1215" stroke="#B5B7B7" strokeLinecap="round" />
                                    </> || ''}
                            </g>
                        </g>
                    </svg>}
            </DivTeethU>

            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q1} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <TextNumberU>55</TextNumberU>
        </Div >
    )
}
