
import FormXray from 'features/xray/form-xray'
import Xray from 'features/xray/xray'
import { menuFirsts } from 'constants/menus'
import ListXray from 'component/Xray/ListXray'

const routeXray = [
    {
        key: menuFirsts.X_RAY.key,
        packageKey: menuFirsts.X_RAY.packageKey,
        name: menuFirsts.X_RAY.name,
        textName: menuFirsts.X_RAY.name,
        active: false,
        path: menuFirsts.X_RAY.path,
        component: Xray,
        appBar: true
    },
    {
        key: menuFirsts.X_RAY.key,
        packageKey: menuFirsts.X_RAY.packageKey,
        name: menuFirsts.X_RAY.name,
        textName: `แก้ไข ${menuFirsts.X_RAY.name}`,
        active: false,
        path: menuFirsts.X_RAY.path + '/record',
        component: ListXray,
        appBar: true
    },
    {
        key: menuFirsts.X_RAY.key,
        packageKey: menuFirsts.X_RAY.packageKey,
        name: menuFirsts.X_RAY.name,
        textName: `สร้าง ${menuFirsts.X_RAY.name}`,
        active: false,
        path: menuFirsts.X_RAY.path + '/create',
        component: ListXray,
        appBar: true
    },
    {
        key: menuFirsts.X_RAY.key,
        packageKey: menuFirsts.X_RAY.packageKey,
        name: menuFirsts.X_RAY.name,
        textName: `ประวัติ ${menuFirsts.X_RAY.name}`,
        active: false,
        path: menuFirsts.X_RAY.path + '/history',
        component: ListXray,
        appBar: true
    }
]
export default routeXray
