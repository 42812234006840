import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material';
import _ from 'lodash'
import moment from 'moment'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import RecallApi from 'api/setting/treatments/recall.api';

/** UTILS || SLICE */
import { handleShow, resetModal } from 'app/slice/modal.slice';
import { toBuddhistYear } from 'utils/app.utils';

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom';
import TableRowCommon from 'component/Table/TableRowCommon';
import Loading from 'component/Loading';

/** STYLED */
import * as UseStyled from 'features/counter/recall/UseStyled'
import 'features/counter/recall/recall.css'

export interface HistoryRecallInterface {
    historyId: number
    branchCnNumber: string
    patientName: string
    lastService: string
    operative: string
    show: boolean
}

interface HistoryRecallObjInterface {
    dateRecall: string
    status: string
}

export default function HistoryRecall(props: HistoryRecallInterface) {
    const { historyId, branchCnNumber, patientName, lastService, operative } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const modal: boolean = useSelector(handleShow)
    const [history, setHistory] = useState<HistoryRecallObjInterface[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const loadHistory = () => {
        setLoading(true)
        RecallApi.historyRecall(historyId)
            .then(({ data }) => setHistory(data))
            .catch(() => { return })
            .finally(() => setLoading(false))
    }

    const headCells = [
        { id: 'id', disablePadding: false, label: t('#'), align: 'center', width: '70px' },
        { id: 'date', disablePadding: false, label: t('RECALL_LISTS.TABLE.CELLS.DATE'), class: 'p-3' }
    ]

    const renderData = (objData: HistoryRecallObjInterface, no: number) => {
        const { dateRecall, status } = objData

        const objRenderData = {
            key: String(no),
            id: String(no),
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: no + 1, class: 'px-1 py-3' },
                { option: 'TEXT', align: 'left', label: status === 'NORMAL' && (dateRecall ? `${toBuddhistYear(moment(dateRecall), 'DD/MM/YYYY HH:mm')} ${t('TIME_UNIT')}` : '-') || t('STATUS_NO_ICON.INACTIVE'), class: 'py-3' }
            ]
        }

        return <TableRowCommon {...objRenderData} />
    }

    useEffect(() => {
        if (!modal) dispatch(resetModal())
    }, [modal]);

    useEffect(() => {
        if (historyId) loadHistory()
    }, [historyId]);

    return (
        <ModalCustom
            size={'lg'}
            title={t('RECALL_LISTS.HISTORY.HEADER')}
            modalStyle={'recall-modal'}
            closeButton
            fullscreen
            component={
                <>
                    <UseStyled.Title className='pb-1'>{branchCnNumber} {patientName} {lastService} {operative}</UseStyled.Title>
                    <Box className='pt-2' sx={{ '.MuiTable-root': { minWidth: '100%' } }} >
                        {!loading && (
                            // <div className='overflow-hidden h-100' style={{ height: 100 }} >
                            <TableCustom
                                hidePagination
                                tableFixedWidth
                                page={1}
                                pageLimit={1}
                                sortType={''}
                                sortBy={''}
                                rowCount={0}
                                onSort={() => { return }}
                                setPageLimit={() => { return }}
                                setPage={() => { return }}
                                headCells={headCells}
                                rowsData={_.map(history, (val: HistoryRecallObjInterface, i) => {
                                    return renderData(val, i)
                                })}
                            //    tableMinWidth={}
                            />
                            // </div>
                        ) || (
                                <Box className='w-max mx-auto'>
                                    <Loading show type={'softLoading'} />
                                </Box>
                            )}
                    </Box>
                </>
            }
        />
    )
}
