import BaseAPI from 'api/api'

import { TreatmentStateType } from 'app/slice/treatment.slice'

const path = 'treatments'

export interface CommissionUserProps {
    userId: number
    price: number
    remark: string
}
export interface CommissionProps {
    refId: number
    refType: "ORDER_PRODUCT" | "TEMP_ORDER_PRODUCT" | "ORDER_TREATMENT" | "TEMP_ORDER_TREATMENT"
    user: CommissionUserProps[]
}

export interface GetCommissionProps {
    refId: number
    refType: "ORDER_PRODUCT" | "TEMP_ORDER_PRODUCT" | "ORDER_TREATMENT" | "TEMP_ORDER_TREATMENT"
}

export default class TreatmentSummaryApi extends BaseAPI {
    static findTreatmentSummary(patientCaseId: number): Promise<any> {
        return this.api.get(`${path}/temp-orders/${patientCaseId}`)
    }

    static treatmentSummaryStore(patientCaseId: number, payload: TreatmentStateType): Promise<any> {
        return this.api.post(`${path}/summery/${patientCaseId}`, payload)
    }
    static setTempOrderTreatment(patientCaseId: number, requestData: any): Promise<any> {
        return this.api.post(`${path}/temp-order-treatment/${patientCaseId}`, requestData)
    }
    static updateCommission(props: CommissionProps): Promise<any> {
        return this.api.patch(`${path}/commission`, { ...props })
    }
    static setCommission(props: CommissionProps): Promise<any> {
        return this.api.post(`${path}/commission`, { ...props })
    }
    static getCommission(props: GetCommissionProps): Promise<any> {
        return this.api.get(`${path}/commission`, { params: props })
    }
}