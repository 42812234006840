import Inventory from 'locales/plus/en/warehouses/inventory.json'
import Adjustment from 'locales/plus/en/warehouses/adjustment.json'
import medicine from 'locales/plus/en/warehouses/medicine.json'
import product from 'locales/plus/en/warehouses/product.json'
import importProduct from 'locales/plus/en/warehouses/import-product.json'

const Warehouses = {
  ...Inventory,
  ...Adjustment,
  ...medicine,
  ...product,
  ...importProduct
}

export default Warehouses
