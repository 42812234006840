import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/setting/treatment-courses/treatment-course-types/style'

/** API */
import ImageDoctorNoteType from 'api/setting/doctor-note/image-doctor-note-type.api';

/** UTILS || SLICE */
import { submitModal, unSubmitModal, showModal, resetModal } from 'app/slice/modal.slice'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextField from 'component/Input/InputTextField'
import InputTextSearch from 'component/Input/InputTextSearch'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import FilterSelect from 'component/Select/FilterSelect'
import TableRowExport from 'component/Pdf/TableRowExport'
import ButtonExport from 'component/Button/ButtonExport'

const initStateErrorMessage = {
    IMAGE_DOCTOR_NOTE_TYPE: ``,
    IS_DUPLICATE_IMAGE_DOCTOR_NOTE_TYPE: ``,
    IMAGE_DOCTOR_NOTE_TYPE_STRING_BASE: ``,
    IMAGE_DOCTOR_NOTE_TYPE_STRING_EMPTY: ``,
    IMAGE_DOCTOR_NOTE_TYPE_MAXIMUM_LENGTH: ``,
    IMAGE_DOCTOR_NOTE_TYPE_ANY_REQUIRED: ``,

    IMAGE_DOCTOR_NOTE_TYPE_EN: ``,
    IS_DUPLICATE_IMAGE_DOCTOR_NOTE_TYPE_EN: ``,
    IMAGE_DOCTOR_NOTE_TYPE_EN_STRING_BASE: ``,
    IMAGE_DOCTOR_NOTE_TYPE_EN_STRING_EMPTY: ``,
    IMAGE_DOCTOR_NOTE_TYPE_EN_MAXIMUM_LENGTH: ``,
    IMAGE_DOCTOR_NOTE_TYPE_EN_ANY_REQUIRED: ``
}
export default function DoctorNoteTypePic() {
    const classes = useStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [imageDoctorNoteTypes, setImageDoctorNoteTypes] = useState([])
    const [exportData, setExportData] = useState([])

    const [doctorTypePicId, setDoctorTypePicId] = useState(Number)
    const [doctorTypePicName, setDoctorTypePicName] = useState('')
    const [doctorTypePicNameEn, setDoctorTypePicNameEn] = useState('')
    const [doctorTypePicStatus, setDoctorTypePicStatus] = useState('')

    const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

    /** PAGINATION  */
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('createdAt')
    const [sortType, setSortType] = useState('ASC')
    const [search, setSearch] = useState('')
    const [status, setStatus] = useState('ALL')

    const loadData = useCallback(async () => {
        let condition: any = {}
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (search) condition = { ...condition, search: search }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

        const res = await ImageDoctorNoteType.findAll(condition)
        if (res.status === 200) {
            setRowCount(res.headers['x-total'])
            setImageDoctorNoteTypes(res.data)
        }
    }, [page, pageLimit, search, sortBy, sortType, status])

    useEffect(() => {
        loadData()
    }, [loadData])

    const loadDataForExport = useCallback(async () => {
        let condition: any = {}
        condition.export = 1
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (search) condition = { ...condition, search: search }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

        const res = await ImageDoctorNoteType.findAll(condition)
        if (res.status === 200) {
            setExportData(res.data)
        }
    }, [page, pageLimit, search, sortBy, sortType, status])

    useEffect(() => {
        loadDataForExport()
    }, [loadDataForExport])

    const submit = () => {
        if (!doctorTypePicName) return setErrorMessage({ ...errorMessage, ...{ IMAGE_DOCTOR_NOTE_TYPE: t('DOCTOR_NOT_TYPE_PIC.MESSAGE.IMAGE_DOCTOR_NOTE_TYPE') } })

        dispatch(submitModal())
        ImageDoctorNoteType.create({ typeName: doctorTypePicName, typeNameEn: doctorTypePicNameEn })
            .then((res: any) => {
                if (res.status !== undefined && res.status === 201) {
                    notiSuccess(t('DOCTOR_NOT_TYPE_PIC.MESSAGE.SUCCESS.CREATE'), '', null, null)
                    resetForm()
                    loadData()
                    dispatch(resetModal())
                } else {
                    const err = res.response.data
                    setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`DOCTOR_NOT_TYPE_PIC.MESSAGE.${err.message}`) } })
                    dispatch(unSubmitModal())
                }
            })
            .catch((e) => {
                const err = e.response.data
                setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`DOCTOR_NOT_TYPE_PIC.MESSAGE.${err.message}`) } })
                dispatch(unSubmitModal())
            })
    }

    const submitEdit = () => {
        if (!doctorTypePicName) return setErrorMessage({ ...errorMessage, ...{ IMAGE_DOCTOR_NOTE_TYPE: t(`DOCTOR_NOT_TYPE_PIC.MESSAGE.IMAGE_DOCTOR_NOTE_TYPE`) } })

        dispatch(submitModal())
        ImageDoctorNoteType.update(doctorTypePicId, {
            typeName: doctorTypePicName,
            typeNameEn: doctorTypePicNameEn,
            status: doctorTypePicStatus
        })
            .then((res: any) => {
                if (res.status !== undefined && res.status === 200) {
                    notiSuccess(t(`DOCTOR_NOT_TYPE_PIC.MESSAGE.SUCCESS.UPDATE`), '', null, null)
                    resetForm()
                    loadData()
                    dispatch(resetModal())
                } else {
                    const err = res.response.data
                    setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`DOCTOR_NOT_TYPE_PIC.MESSAGE.${err.message}`) } })
                    dispatch(unSubmitModal())
                }
            })
            .catch((e) => {
                const err = e.response.data
                setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`DOCTOR_NOT_TYPE_PIC.MESSAGE.${err.message}`) } })
                dispatch(unSubmitModal())
            })
    }

    const clearErrorMessage = () => {
        setErrorMessage(initStateErrorMessage)
    }

    const resetForm = () => {
        setDoctorTypePicId(0)
        setDoctorTypePicName('')
        setDoctorTypePicNameEn('')
        setDoctorTypePicStatus('')
        clearErrorMessage()
    }

    const onActive = (id: number, valueStatus: string) => {
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('DOCTOR_NOT_TYPE_PIC.ALERT.CONFIRM_STATUS')}</p>`,
            null, (res: any) => {
                if (res) {
                    ImageDoctorNoteType.updateStatus(id, valueStatus)
                        .then((resp) => {
                            notiSuccess(t('DOCTOR_NOT_TYPE_PIC.MESSAGE.SUCCESS.UPDATE_STATUS'))
                            loadData()
                        })
                        .catch((e) => {
                            notiError(t('DOCTOR_NOT_TYPE_PIC.MESSAGE.ERROR'))
                        })
                }
            }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
    }
    const onDelete = (data: any) => {
        const { doctorNoteTypeId, typeName, typeNameEn } = data

        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('DOCTOR_NOT_TYPE_PIC.ALERT.CONFIRM_DELETE')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('DOCTOR_NOT_TYPE_PIC.ALERT.CONFIRM_DELETE_', {
                treatmentCourseTypeName: typeName,
                doctorTypePicName: typeNameEn ? ' (' + typeNameEn + ')' : ''
            })}</p>`,
            (res: any) => {
                if (res) {
                    ImageDoctorNoteType.remove(doctorNoteTypeId)
                        .then((resp) => {
                            notiSuccess(t('DOCTOR_NOT_TYPE_PIC.MESSAGE.SUCCESS.DELETE'))
                            loadData()
                        })
                        .catch((e) => {
                            notiError(t('DOCTOR_NOT_TYPE_PIC.MESSAGE.ERROR'))
                        })
                }
            }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
        )
    }

    const onEdit = (data: any) => {
        const { doctorNoteTypeId, typeName, typeNameEn, status: dcTypeStatus } = data
        setDoctorTypePicId(doctorNoteTypeId)
        setDoctorTypePicName(typeName)
        setDoctorTypePicNameEn(typeNameEn)
        setDoctorTypePicStatus(dcTypeStatus)
        dispatch(showModal())
    }

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangePage = (val: number) => {
        setPage(val)
    }

    const handleChangeRowsPerPage = (limit: number) => {
        setPageLimit(limit)
    }
    const handleChangeStatus = (statusVal: string) => {
        setStatus(statusVal)
    }
    const headCells = [
        { id: 'no', disablePadding: false, label: '#' },
        { id: 'treatmentCourseTypeName', disablePadding: false, label: t('DOCTOR_NOT_TYPE_PIC.TABLE.CELL.IMAGE_DOCTOR_NOTE_TYPE') },
        { id: 'doctorTypePicName', disablePadding: false, label: t('DOCTOR_NOT_TYPE_PIC.TABLE.CELL.IMAGE_DOCTOR_NOTE_TYPE_EN') },
        { id: 'status', disablePadding: false, label: t('DOCTOR_NOT_TYPE_PIC.TABLE.CELL.STATUS') },
        { id: 'updatedBy', disablePadding: false, label: t('DOCTOR_NOT_TYPE_PIC.TABLE.CELL.UPDATED_BY') },
        { id: 'action', disablePadding: false, label: 'Action' }
    ]

    const renderData = (objData: any, no: number, valueExportData: boolean) => {
        const num = valueExportData === true ? no + 1 : page * pageLimit - pageLimit + no + 1
        const { doctorNoteTypeId, typeName, typeNameEn, updatedBy, updatedAt, status: dcTypeStatus } = objData
        const statusBtnActive = dcTypeStatus === 'ACTIVE' ? true : false
        const statusBtnInActive = dcTypeStatus === 'INACTIVE' ? true : false
        const objRenderData = {
            key: doctorNoteTypeId,
            id: doctorNoteTypeId,
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: num },
                { option: 'TEXT', align: 'left', label: typeName || '-' },
                { option: 'TEXT', align: 'left', label: typeNameEn || '-' },
                { option: 'STATUS', align: 'center', label: dcTypeStatus },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: [
                        { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
                        { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
                        { option: 'DIVIDER', label: '', disabled: false },
                        { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: false },
                        { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: false }
                    ]
                }
            ]
        }
        return (
            <>
                {valueExportData === true && <TableRowExport {...objRenderData} /> ||
                    <TableRowCommon
                        {...objRenderData}
                        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
                        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
                        onedit={() => onEdit(objRenderData.obj)}
                        ondelete={() => onDelete(objRenderData.obj)}
                    />
                }
            </>
        )
    }
    const filter = [
        { label: `${t('INPUT.SEARCH')}: ${search || '-'}` },
        { label: `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}` }
    ]

    return (
        <div className={classes.root}>
            <Card className="border-0 h-100">
                <HeaderCard text={t('DOCTOR_NOT_TYPE_PIC.TITLE')} />
                <Card.Body>
                    <Card.Title className={'mb-0 pl-xl-3'}>
                        <Row className="align-items-center">
                            <Col lg={7}>
                                <Row className="flax-row">
                                    <Col sm={12} md={6} xl={4} className="pt-2">
                                        <InputTextSearch
                                            keyInput={'search'}
                                            label={t('INPUT.SEARCH')}
                                            value={search}
                                            onchange={(event) => {
                                                setSearch(event.target.value)
                                                setPage(1)
                                            }}
                                            onClear={(event) => {
                                                setSearch('')
                                                setPage(1)
                                            }}
                                        />
                                    </Col>
                                    <Col sm={12} md={6} xl={4} className="pl-md-0 pt-2">
                                        <FilterSelect
                                            formControlClass='not-max-width'
                                            onchange={(event) => {
                                                const value = event.target.value
                                                if (value) {
                                                    handleChangeStatus(value)
                                                    setPage(1)
                                                }
                                            }}
                                            renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                                            label={t('STATUS.LABEL')}
                                            selectId="select-status"
                                            value={status}
                                            labelId="label-status"
                                            options={[
                                                <MenuItem key="1" value="ALL">
                                                    {t('STATUS.ALL')}
                                                </MenuItem>,
                                                <MenuItem key="2" value="ACTIVE">
                                                    {t('STATUS.ACTIVE')}
                                                </MenuItem>,
                                                <MenuItem key="3" value="INACTIVE">
                                                    {t('STATUS.INACTIVE')}
                                                </MenuItem>
                                            ]}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xm={12} lg={5} className="mt-2 mt-xm-auto">
                                <div className="pr-xl-2 d-flex ">
                                    <div className='ml-auto  '>
                                        <ButtonExport headCells={headCells} portrait={true} filter={filter} fileName={t('IMAGE_DOCTOR_NOT.INPUT.IMAGE_DOCTOR_NOTE_NAME')}
                                            rowsData={exportData.map((val, i) => {
                                                return renderData(val, i, true)
                                            })} />
                                    </div>
                                    <div className='pl-3  '>
                                        <ButtonCustom style={{ marginLeft: '-50px', width: '250px' }} mode="add" onClick={() => dispatch(showModal())} textButton={t('DOCTOR_NOT_TYPE_PIC.BUTTON.ADD')} className="w-auto ml-auto d-flex mt-auto" btnStyle={{ height: '44px' }} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card.Title>

                    <div className={'mt-3 pl-xl-3 pr-xl-2'}>
                        <TableCustom
                            page={page}
                            pageLimit={pageLimit}
                            sortType={sortType}
                            sortBy={sortBy}
                            onSort={onRequestSort}
                            setPageLimit={handleChangeRowsPerPage}
                            setPage={handleChangePage}
                            rowCount={rowCount}
                            headCells={headCells}
                            rowsData={imageDoctorNoteTypes.map((val, i) => {
                                return renderData(val, i, false)
                            })}
                        />
                    </div>
                </Card.Body>
            </Card>

            <ModalCustom
                title={doctorTypePicId ? t('DOCTOR_NOT_TYPE_PIC.TITLE_UPDATE') : t('DOCTOR_NOT_TYPE_PIC.TITLE_CREATE')}
                component={
                    <div className="pb-2">
                        {doctorTypePicId ? (
                            <div>
                                <Form className="mx-0 mt-0 mb-3 p-0  d-inline-flex w-100">
                                    <Form.Label className=" mr-auto">{t('INPUT.LABEL_STATUS')}</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        onChange={() => setDoctorTypePicStatus(doctorTypePicStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')}
                                        checked={doctorTypePicStatus === 'ACTIVE' ? true : false}
                                        style={{ marginLeft: 'auto' }}
                                    />
                                </Form>
                            </div>
                        ) : (
                            ''
                        )}

                        <InputTextField
                            onchange={(event) => {
                                setDoctorTypePicName(event.target.value)
                                clearErrorMessage()
                            }}
                            value={doctorTypePicName}
                            label={t('DOCTOR_NOT_TYPE_PIC.INPUT.IMAGE_DOCTOR_NOTE_TYPE')}
                            helperText={
                                errorMessage.IMAGE_DOCTOR_NOTE_TYPE ||
                                errorMessage.IS_DUPLICATE_IMAGE_DOCTOR_NOTE_TYPE ||
                                errorMessage.IMAGE_DOCTOR_NOTE_TYPE_STRING_BASE ||
                                errorMessage.IMAGE_DOCTOR_NOTE_TYPE_STRING_EMPTY ||
                                errorMessage.IMAGE_DOCTOR_NOTE_TYPE_MAXIMUM_LENGTH ||
                                errorMessage.IMAGE_DOCTOR_NOTE_TYPE_ANY_REQUIRED
                            }
                            required={true}
                            style={{ marginBottom: '1rem' }}
                        />
                        <InputTextField
                            key="doctorTypePicNameEn"
                            onchange={(event) => {
                                setDoctorTypePicNameEn(event.target.value)
                                clearErrorMessage()
                            }}
                            value={doctorTypePicNameEn}
                            helperText={
                                errorMessage.IMAGE_DOCTOR_NOTE_TYPE_EN ||
                                errorMessage.IS_DUPLICATE_IMAGE_DOCTOR_NOTE_TYPE_EN ||
                                errorMessage.IMAGE_DOCTOR_NOTE_TYPE_EN_STRING_BASE ||
                                errorMessage.IMAGE_DOCTOR_NOTE_TYPE_EN_STRING_EMPTY ||
                                errorMessage.IMAGE_DOCTOR_NOTE_TYPE_EN_MAXIMUM_LENGTH ||
                                errorMessage.IMAGE_DOCTOR_NOTE_TYPE_EN_ANY_REQUIRED
                            }
                            label={t('DOCTOR_NOT_TYPE_PIC.INPUT.IMAGE_DOCTOR_NOTE_TYPE_EN')}
                            required={false}
                        />
                    </div>
                }
                onReset={resetForm}
                onSubmit={doctorTypePicId ? submitEdit : submit}
                textBtnCancel={t('DOCTOR_NOT_TYPE_PIC.BUTTON.CANCEL')}
                textBtnConfirm={t('DOCTOR_NOT_TYPE_PIC.BUTTON.SAVE')}
            />
        </div>
    )
}
