import { useState, useEffect } from 'react'
import InputTimePicker from 'component/Input/InputTimePicker'
import { styled, Typography, Box, ClickAwayListener } from '@mui/material'
import { colors } from 'constants/theme'
import moment from 'moment'
import HelperText from './HelperText'

interface InputNewRangeTimePickerInterface {
  value: string[]
  onChange: (val: string[]) => void
  errMessage?: string
  disabled?: boolean
  suffixIcon?: any
  style?: any
  size?: 'small' | 'default'
  minmax?: any
}

const CustomRangeTimePicker = styled(Box)<{ size?: 'small' | 'default'; active?: boolean; error?: boolean; disabled?: boolean }>(({ theme, size, active, error, disabled }) => ({
  border: `${error ? '2px' : '1px'} solid ${(error && colors.statusInactive) || (active && colors.themeSecondColor) || colors.black12}`,
  backgroundColor: disabled ? colors.black12 : colors.white,
  borderRadius: 4,
  height: size === 'small' ? 32 : 43,
  '.range-time-picker': {
    '.has-text-field': {
      '.MuiInputBase-root': {
        height: size === 'small' ? 32 : 43,
        backgroundColor: colors.transparent,
        '.MuiOutlinedInput-input': {
          height: size === 'small' ? 32 : 43
        },
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none'
        }
      },
      '.ant-picker': {
        height: size === 'small' ? 32 : 43,
        top: 0
      }
    },
    '&.first-range-picker': {
      '.ant-picker-suffix': {
        opacity: 0
      }
    },
    '&.last-range-picker': {
      '.has-text-field': {
        '.MuiInputBase-root': {
          '.MuiOutlinedInput-input': {
            paddingRight: '36px'
          }
        }
      }
    }
  }
}))

export default function InputNewRangeTimePicker(props: InputNewRangeTimePickerInterface) {
  const [firstTimePickerOpen, setFirstTimePickerOpen] = useState(false)
  const [lastTimePickerOpen, setLastTimePickerOpen] = useState(false)

  useEffect(() => {
    firstTimePickerOpen && setLastTimePickerOpen(false)
  }, [firstTimePickerOpen])

  useEffect(() => {
    lastTimePickerOpen && setFirstTimePickerOpen(false)
  }, [lastTimePickerOpen])

  const handleMinMax = (time: 'timeStart' | 'timeEnd') => {
    if (props.minmax) {
      if (time === "timeStart") {
        const data = {
          scopeTimeStart: '00:00',
          scopeTimeEnd: props.value[1] && moment(props.value[1], 'HH:mm').format('HH:mm') !== "00:00" ? props.value[1] : '23:59'
        }
        return data
      } else {
        const data = {
          scopeTimeStart: props.value[0] && moment(props.value[0], 'HH:mm').format('HH:mm') !== "00:00" ? props.value[0] : '00:00',
          scopeTimeEnd: '23:59'
        }
        return data
      }
    } else {
      return {}
    }

  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        setFirstTimePickerOpen(false)
        setLastTimePickerOpen(false)
      }}
    >
      <Box sx={{ ...props.style }}>
        <CustomRangeTimePicker disabled={props.disabled} error={props.errMessage ? true : false} active={firstTimePickerOpen || lastTimePickerOpen} size={props.size || 'default'} className={`d-flex align-items-center ${props.disabled && 'pe-none'}`}>
          <Box onClick={() => setFirstTimePickerOpen(true)} className="range-time-picker first-range-picker w-100">
            <InputTimePicker
              open={firstTimePickerOpen}
              inputTextAlign={'left'}
              value={props.value[0] ? props.value[0] : '00:00'}
              onChange={(e: any) => {
                if (moment(e?._i, 'HH:mm').format('mm') !== moment(e).format('mm') && props.value[1] === "00:00") {
                  setLastTimePickerOpen(true)
                }

                props.onChange([moment(e).format('HH:mm'), props.value[1]])
              }}
              {...handleMinMax('timeStart')}
            />
          </Box>
          <Typography sx={{ fontSize: '16px' }}>⇀</Typography>
          <Box onClick={() => setLastTimePickerOpen(true)} className="range-time-picker last-range-picker w-100">
            <InputTimePicker
              open={lastTimePickerOpen}
              inputTextAlign={'left'}
              suffixIcon={props.suffixIcon}
              value={props.value[1] ? props.value[1] : '00:00'}
              onChange={(e: any) => {
                props.onChange([props.value[0], moment(e).format('HH:mm')])
              }}
              {...handleMinMax('timeEnd')}
            />
          </Box>
        </CustomRangeTimePicker>
        {props.errMessage && <HelperText label={props.errMessage} />}
      </Box>
    </ClickAwayListener>
  )
}
