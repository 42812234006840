import BaseAPI from 'api/api'

export interface FindAllInterface {
    clinicNameTh: string,
    clinicNameEn: string,
    phoneNo: string,
    line: string,
    website: string,
    email: string,
    package: string,
    countryId: number,
    provinceId: number,
    districtId: number,
    subDistrictId: number,
    postcode: string,
    addressTh: string,
    addressEn: string,
    hasCertificate: string,
    reBranchCnNumber: string,
    formatBranchCnNumber: string,
    notilineAppointmentCreate: string,
    notilineAppointmentUpdate: string,
    notilineAppointmentPreday: string,
    notilineAppointmentDay: string,
    notilineAppointmentStatus: string,
    cnNumber: string,
    branchCnNumbers: []
}
export default class AboutUs extends BaseAPI {
    static findAboutUs(): Promise<any> {
        return this.api.get('about-us').then((res) => res)
    }

    static updateAboutUs(props: any): Promise<any> {
        return this.api.post('about-us', props).then((res) => res)
    }

    static detailClinic(branchId: number): Promise<any> {
        return this.api.get(`clinic/${branchId}/info`).then((res) => res)
    }
}