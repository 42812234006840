import { Box, IconButton } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import InputTextField from "component/Input/InputTextField"
import InputTextarea from "component/Input/InputTextarea"
import BtnFacebook from "assets/button/BtnFacebook"
import * as UseStyles from 'features/application/manage-web/contact-us/style'
import MenuItem from '@mui/material/MenuItem'
import BasicSelect from "component/Select/BasicSelect"
import InputNewRangeTimePicker from "component/Input/InputNewRangeTimePicker"
import moment from "moment"
import { icons } from "constants/images"
import ButtonCustom from "component/Button/ButtonCustom"
import { Col, Row } from "react-bootstrap"
import BtnIG from "assets/button/BtnIG"
import BtnLine from "assets/button/BtnLine"
import BtnTiktok from "assets/button/BtnTiktok"
import BtnWebsite from "assets/button/BtnWebsite"

const initStateEditMenu = {
    contactCustomerId: 0,
    branchId: 0,
    name: '',
    email: '',
    phone: '',
    detail: '',
    addressMap: '',
    website: '',
    tiktok: '',
    facebook: '',
    line: '',
    instagram: '',
    officeTimes: []
}


type ContactUsFormProps = {
    dataEdit: any
    setDataEdit: (data: any) => void
    officeTimes: any
    setOfficeTimes: (data: any) => void
}

interface BranchOfficeTimesInterface {
    dayOfWeek: string
    timeIn: string
    timeOut: string
    officeStatus: string
}

const ContactUsForm = (props: ContactUsFormProps) => {
    console.log(props.dataEdit)
    const { t } = useTranslation()
    const t_CONTACT_US = "MANAGE_WEB.CONTACT_US"
    const objBranchTime = [
        {
            typetime: 'OPEN',
            timeStart: '00:00',
            timeEnd: '00:00',
            days: { sun: false, mon: false, tue: false, wed: false, thu: false, fri: false, sat: false }
        }
    ]
    const [dataEdit, setDataEdit] = useState<any>(props.dataEdit)
    const [branchTimes, setBranchTimes] = useState([...objBranchTime])
    const dateOfWeek = [`Mon`, `Tue`, `Wed`, `Thu`, `Fri`, `Sat`, `Sun`]
    const officeTimes = [{}]
    const officetimes = () => {
        console.log(dataEdit.officeTimes, 'uuu')
        if (dataEdit.officeTimes === undefined) {
            console.log('ddddd')
            setBranchTimes([...objBranchTime])
        } else {
            console.log('ggggg')
            const tempOfficeTime: any = []
            for (const officeTime in dataEdit.officeTimes) {
                if (Object.prototype.hasOwnProperty.call(dataEdit.officeTimes, officeTime)) {
                    const ot: any = dataEdit.officeTimes[officeTime]
                    if (!tempOfficeTime.find((tt: any) => tt.timeStart === ot.timeIn && tt.timeEnd === ot.timeOut)) {
                        tempOfficeTime.push({
                            timeStart: ot.timeIn,
                            timeEnd: ot.timeOut,
                            typetime: ot.officeStatus,
                            days: {
                                sun: ot.day.find((officeT: any) => officeT === 'Sun') ? true : false,
                                mon: ot.day.find((officeT: any) => officeT === 'Mon') ? true : false,
                                tue: ot.day.find((officeT: any) => officeT === 'Tue') ? true : false,
                                wed: ot.day.find((officeT: any) => officeT === 'Wed') ? true : false,
                                thu: ot.day.find((officeT: any) => officeT === 'Thu') ? true : false,
                                fri: ot.day.find((officeT: any) => officeT === 'Fri') ? true : false,
                                sat: ot.day.find((officeT: any) => officeT === 'Sat') ? true : false
                            }
                        })
                    }
                }
            }
            setBranchTimes(tempOfficeTime)
            props?.setOfficeTimes(tempOfficeTime)
        }
    }

    const handleChangeBranchTimeType = (value: string, index: number) => {
        const branchTimeTemp: any = [...branchTimes]
        branchTimeTemp[index].typetime = value
        setBranchTimes(branchTimeTemp)
        props?.setDataEdit({ ...dataEdit, officeTimes })
        props?.setOfficeTimes(branchTimes)
    }

    const handleChangeBranchTimeDate = (day: string, index: number) => {
        const branchTimeTemp: any = [...branchTimes]
        branchTimeTemp[index].days[day] = !branchTimeTemp[index].days[day]
        setBranchTimes(branchTimeTemp)
        props?.setDataEdit({ ...dataEdit, officeTimes })
        console.log(branchTimes, 'branchTimes')
        props?.setOfficeTimes(branchTimes)
    }

    const handleChangeBranchTime = (times: any, index: number) => {
        const branchTimeTemp: any = [...branchTimes]
        branchTimeTemp[index].timeStart = times[0]
        branchTimeTemp[index].timeEnd = times[1]
        setBranchTimes(branchTimeTemp)
        props?.setDataEdit({ ...dataEdit, officeTimes })
        console.log(branchTimes, 'branchTimes')
        props?.setOfficeTimes(branchTimes)
    }

    const addBranchTime = () => setBranchTimes([...branchTimes, ...objBranchTime])

    const deleteBranchTime = (e: any, index: number) => {
        const branchTimeTemp: any = []
        for (const iBt in branchTimes) {
            if (Object.prototype.hasOwnProperty.call(branchTimes, iBt)) {
                const element = branchTimes[iBt]
                if (Number(iBt) !== Number(index)) branchTimeTemp.push(element)
            }
        }
        setBranchTimes(branchTimeTemp)
        props.setOfficeTimes(branchTimeTemp)
    }


    useEffect(() => {
        officetimes()
    }, [])

    console.log(branchTimes, 'form', dataEdit?.name)

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingRight: '16px' }}>
                <Box sx={{ paddingTop: '8px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ paddingTop: '8px', width: '33%' }}>
                        <InputTextField
                            required
                            label={t(`${t_CONTACT_US}.NAME`)}
                            value={dataEdit?.name}
                            onchange={(e) => {
                                setDataEdit({ ...dataEdit, name: e.target.value })
                                props?.setDataEdit({ ...dataEdit, name: e.target.value })
                            }}
                        />
                    </Box>
                    <Box sx={{ paddingTop: '8px', width: '33%' }}>
                        <InputTextField
                            required
                            label={t(`${t_CONTACT_US}.EMAIL`)}
                            value={dataEdit?.email}
                            onchange={(e) => {
                                setDataEdit({ ...dataEdit, email: e.target.value })
                                props?.setDataEdit({ ...dataEdit, email: e.target.value })
                            }}
                        />
                    </Box>
                    <Box sx={{ paddingTop: '8px', width: '33%' }}>
                        <InputTextField
                            required
                            label={t(`${t_CONTACT_US}.TELEPHONE`)}
                            value={dataEdit?.phone}
                            onchange={(e) => {
                                setDataEdit({ ...dataEdit, phone: e.target.value })
                                props?.setDataEdit({ ...dataEdit, phone: e.target.value })
                            }}
                        />
                    </Box>
                </Box>
                <Box sx={{ marginTop: '8px' }}>
                    <InputTextarea
                        required
                        label={t(`${t_CONTACT_US}.FULL_ADDRESS`)}
                        value={dataEdit?.detail}
                        onchange={(e) => {
                            setDataEdit({ ...dataEdit, detail: e.target.value })
                            props?.setDataEdit({ ...dataEdit, detail: e.target.value })
                        }}
                    />
                </Box>

                <Box style={{ paddingRight: '10px' }}>
                    <Row>
                        <Col xs={12} md={10} xl={10} >
                            {branchTimes.map((branchTime: any, iBranchTime) => (
                                <Row key={`row-${iBranchTime}`} className="mt-3">
                                    <Col xs={12} md={3} xl={3} >
                                        <BasicSelect
                                            onchange={(event) => {
                                                const value = event.target.value
                                                if (value) {
                                                    handleChangeBranchTimeType(value, iBranchTime)
                                                }
                                            }}
                                            label={''}
                                            selectId="select-status"
                                            value={branchTime.typetime}
                                            labelId="label-status"
                                            options={[
                                                <MenuItem key="1" value="OPEN">
                                                    {t('BRANCH.INPUT.WORK_DATE_TYPE_OPEN')}
                                                </MenuItem>,
                                                <MenuItem key="2" value="CLOSE">
                                                    {t('BRANCH.INPUT.WORK_DATE_TYPE_CLOSE')}
                                                </MenuItem>
                                            ]}
                                        />
                                    </Col>
                                    <Col xs={12} md={4} xl={4} >
                                        {dateOfWeek.map((day, i) => (
                                            <UseStyles.BtnDay
                                                key={day}
                                                className={`btn mx-1 text-nowrap text-center px-1 btn-day d-inline-flex align-items-center justify-content-center 
                                                 ${branchTime.days[day.toLowerCase()] ? 'active' : ''}
                                                 ${branchTime.typetime === 'OPEN' ? 'open' : 'close'}`}
                                                onClick={(e) => handleChangeBranchTimeDate(day.toLowerCase(), iBranchTime)}
                                            >
                                                {t(`DAY_SHORT_${day.toUpperCase()}`)}
                                            </UseStyles.BtnDay>
                                        ))}
                                    </Col>
                                    <Col xs={12} md={3} xl={3} >
                                        <InputNewRangeTimePicker
                                            value={[moment(branchTime.timeStart, 'HH:mm').format('HH:mm'), moment(branchTime.timeEnd, 'HH:mm').format('HH:mm')]}
                                            onChange={(e) => handleChangeBranchTime(e, iBranchTime)}
                                            disabled={branchTime.typetime === 'CLOSE'}
                                            style={{ maxWidth: '300px' }}
                                        />
                                    </Col>
                                    <Col xs={12} md={1} xl={1} className="d-flex align-items-center justify-content-start">
                                        <IconButton
                                            className={'btn-delete'}
                                            disableFocusRipple
                                            disableRipple
                                            disableTouchRipple
                                            aria-label="delete"
                                            onClick={(e) => deleteBranchTime(e, iBranchTime)}
                                            sx={{
                                                backgroundColor: 'transparent !important',
                                                padding: 0,
                                                '&:disabled': {
                                                    filter: 'grayscale(1)',
                                                    opacity: 0.6
                                                }
                                            }}
                                        >
                                            <img src={icons.btnTrash} alt="trash" style={{ width: 24 }} />
                                        </IconButton>
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                        <Col xs={2} md={2} xl={2} className="mt-3" align='start'>
                            <ButtonCustom
                                mode={'add'}
                                style={{ marginRight: '-5px' }}
                                variant={'outlined'}
                                textButton={t('BRANCH.TAB.ADD_ITEM_WORK')}
                                onClick={() => addBranchTime()}
                            />
                        </Col>
                    </Row>
                </Box>
                <Box sx={{ paddingTop: '20px' }}>
                    <InputTextField
                        label={t(`${t_CONTACT_US}.IFRAME`)}
                        value={dataEdit?.addressMap}
                        onchange={(e) => {
                            setDataEdit({ ...dataEdit, addressMap: e.target.value })
                            props?.setDataEdit({ ...dataEdit, website: e.target.value })
                        }}
                    />
                </Box>
                <Box sx={{ marginTop: '16px', display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ fontWeight: '500' }}>
                        {t(`${t_CONTACT_US}.CHANNEL_CONTACT`)}
                    </Box>
                    <UseStyles.ContainerSocial>
                        <Box sx={{ width: '10%', paddingRight: '40px' }}><BtnWebsite color="#808080" width="28" height="28" /></Box>
                        <Box sx={{ width: '90%' }}>
                            <InputTextField
                                label={t(`${t_CONTACT_US}.SOCIAL.WEBSITE`)}
                                value={dataEdit?.website}
                                onchange={(e) => {
                                    setDataEdit({ ...dataEdit, website: e.target.value })
                                    props?.setDataEdit({ ...dataEdit, website: e.target.value })
                                }}
                            />
                        </Box>
                    </UseStyles.ContainerSocial>
                    <UseStyles.ContainerSocial>
                        <Box sx={{ width: '10%', paddingRight: '40px' }}><BtnFacebook color="#808080" width="28" height="28" /></Box>
                        <Box sx={{ width: '90%' }}>
                            <InputTextField
                                label={t(`${t_CONTACT_US}.SOCIAL.FACEBOOK`)}
                                value={dataEdit?.facebook}
                                onchange={(e) => {
                                    setDataEdit({ ...dataEdit, facebook: e.target.value })
                                    props?.setDataEdit({ ...dataEdit, facebook: e.target.value })
                                }}
                            />
                        </Box>
                    </UseStyles.ContainerSocial>
                    <UseStyles.ContainerSocial>
                        <Box sx={{ width: '10%', paddingRight: '40px' }}><BtnLine color="#808080" width="30" height="30" /></Box>
                        <Box sx={{ width: '90%' }}>
                            <InputTextField
                                label={t(`${t_CONTACT_US}.SOCIAL.LINE`)}
                                value={dataEdit?.line}
                                onchange={(e) => {
                                    setDataEdit({ ...dataEdit, line: e.target.value })
                                    props?.setDataEdit({ ...dataEdit, line: e.target.value })
                                }}
                            />
                        </Box>
                    </UseStyles.ContainerSocial>
                    <UseStyles.ContainerSocial>
                        <Box sx={{ width: '10%', paddingRight: '40px' }}><BtnIG color="#808080" width="32" height="32" /></Box>
                        <Box sx={{ width: '90%' }}>
                            <InputTextField
                                label={t(`${t_CONTACT_US}.SOCIAL.INSTAGRAM`)}
                                value={dataEdit?.instagram}
                                onchange={(e) => {
                                    setDataEdit({ ...dataEdit, instagram: e.target.value })
                                    props?.setDataEdit({ ...dataEdit, instagram: e.target.value })
                                }}
                            />
                        </Box>
                    </UseStyles.ContainerSocial>
                    <UseStyles.ContainerSocial >
                        <Box sx={{ width: '10%', paddingRight: '40px' }}><BtnTiktok color="#808080" /></Box>
                        <Box sx={{ width: '90%' }}>
                            <InputTextField
                                label={t(`${t_CONTACT_US}.SOCIAL.TIKTOK`)}
                                value={dataEdit?.tiktok}
                                onchange={(e) => {
                                    console.log(e.target.value, 'oooooooo')
                                    setDataEdit({ ...dataEdit, tiktok: e.target.value })
                                    props?.setDataEdit({ ...dataEdit, tiktok: e.target.value })
                                }}
                            />
                        </Box>
                    </UseStyles.ContainerSocial>
                </Box>
            </Box>
        </>
    )
}

export default ContactUsForm