import _ from 'lodash'
import { colors } from 'constants/theme'
import { useTranslation } from 'react-i18next'
import { numberFormat, toBuddhistYear } from 'utils/app.utils'
import { TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material'
import TableCustom from 'component/Table/TableCustom'
import * as UseStyled from 'features/report/useStyled'
import { ExportProps } from 'features/report/report-constants'
import moment from 'moment'

interface FcComponent {
  page: number
  pageLimit: number
  rowCount: number
  sortBy: string
  sortType: string
  data: any
  onRequestSort: (sortBy: string, sortType: string) => void
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
  componentRef: any
  cash: boolean
  df: boolean
  filter: any
  treatmentRight: boolean
}

export default function RemittanceList(props: FcComponent) {
  const { t } = useTranslation()

  const headCells = [
    { id: 'message', disablePadding: false, width: '70%', labelKey: 'REPORT.TABLE.REMITTANCE.LIST', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMITTANCE.QTY', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMITTANCE.ALL_QTY', align: 'right', class: 'text-nowrap' }
  ]
  const header = [props.df ? t('REPORT.INCOME_MONTH.DF') : null, props.cash ? t('REPORT.INCOME_MONTH.CASH') : null, props.treatmentRight ? t('REPORT.INCOME_MONTH.TREATMENT_RIGHT') : null].filter(Boolean).join(', ')

  const renderData = (data: any) => {
    /**
     * ? rowExpenses รายจ่าย
     * ? rowDf รายจ่าย
     * ? rowDailyBalance รายรับ
     * ? rowIncome รายรับ
     */
    const total = data.rowDf?.length > 0 || data.rowExpenses?.length > 0 || data.rowTreatmentRight?.length > 0 ? _.sumBy(data.rowDf, 'price') + _.sumBy(data.rowExpenses, 'price') : 0

    let sum = 0
    if (data.rowDf?.length > 0 || data.rowExpenses?.length > 0 || data.rowTreatmentRight?.length > 0)
      sum =
        _.sumBy(props.cash ? data.rowIncome.filter((d: any) => d.detail === `${t('REPORT.INCOME_MONTH.CASH')} `) : data.rowIncome, 'price') -
        (_.sumBy(data.rowDf, 'price') + _.sumBy(data.rowExpenses, 'price')) +
        _.sumBy(data.rowTreatmentRight, 'price')
    else sum = _.sumBy(data.rowIncome, 'price')

    return (
      <>
        {data.rowDailyBalance &&
          _.map(data.rowDailyBalance, (val: any, index: number) => {
            return (
              <>
                <TableRow>
                  <TableCell align="left" scope="row">
                    {t('REPORT.TABLE.REMITTANCE.NET_TOOLS')}
                  </TableCell>
                  <TableCell align="right" scope="row">
                    {numberFormat(val.sumProduct)}
                  </TableCell>
                  <TableCell align="right" scope="row">
                    {'-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" scope="row">
                    {t('REPORT.TABLE.REMITTANCE.NET_OPERATIVE')}
                  </TableCell>
                  <TableCell align="right" scope="row">
                    {numberFormat(val.sumTreatment)}
                  </TableCell>
                  <TableCell align="right" scope="row">
                    {'-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" scope="row">
                    {t('REPORT.TABLE.REMITTANCE.NET_DAILY')}
                  </TableCell>
                  <TableCell align="right" scope="row">
                    {numberFormat(val.sumAll)}
                  </TableCell>
                  <TableCell align="right" scope="row">
                    {'-'}
                  </TableCell>
                </TableRow>
              </>
            )
          })}
        {data.rowIncome &&
          _.map(data.rowIncome, (val: any, index: number) => {
            return (
              <TableRow>
                <TableCell align="left" scope="row" sx={{ color: colors.blue }}>
                  {val.detail}
                </TableCell>
                <TableCell align="right" scope="row" sx={{ color: colors.blue }}>
                  {numberFormat(val.price)}
                </TableCell>
                <TableCell align="right" scope="row" sx={{ color: colors.blue }}>
                  {numberFormat(val.total)}
                </TableCell>
              </TableRow>
            )
          })}
        <TableRow>
          <TableCell align="left" scope="row" sx={{ color: colors.blue }}>
            {t('REPORT.TABLE.REMITTANCE.TOTAL_INCOME')}
          </TableCell>
          <TableCell align="right" scope="row" sx={{ color: colors.blue }}>
            {numberFormat(_.sumBy(data.rowIncome, 'price'))}
          </TableCell>
          <TableCell align="right" scope="row" sx={{ color: colors.blue }}>
            -
          </TableCell>
        </TableRow>
        {data.rowTreatmentRight &&
          _.map(data.rowTreatmentRight, (val: any, index: number) => {
            return (
              <TableRow>
                <TableCell align="left" scope="row" sx={{ color: 'rgb(28, 185, 154)' }}>
                  {val.treatmentRightName}
                </TableCell>
                <TableCell align="right" scope="row" sx={{ color: 'rgb(28, 185, 154)' }}>
                  {numberFormat(val.price)}
                </TableCell>
                <TableCell align="right" scope="row" sx={{ color: 'rgb(28, 185, 154)' }}>
                  {numberFormat(val.total)}
                </TableCell>
              </TableRow>
            )
          })}
        <TableRow>
          <TableCell align="left" scope="row" sx={{ color: 'rgb(28, 185, 154)' }}>
            {t('REPORT.TABLE.REMITTANCE.TOTAL_INCOME')}
          </TableCell>
          <TableCell align="right" scope="row" sx={{ color: 'rgb(28, 185, 154)' }}>
            {numberFormat(_.sumBy(data.rowTreatmentRight, 'price'))}
          </TableCell>
          <TableCell align="right" scope="row" sx={{ color: 'rgb(28, 185, 154)' }}>
            -
          </TableCell>
        </TableRow>
        {data.rowDf &&
          _.map(data.rowDf, (val: any, index: number) => {
            return (
              <>
                {val.detail && (
                  <TableRow>
                    <TableCell align="left" scope="row" sx={{ color: colors.statusInactive }}>
                      {val.detail}
                    </TableCell>
                    <TableCell align="right" scope="row" sx={{ color: colors.statusInactive }}>
                      {numberFormat(val.price)}
                    </TableCell>
                    <TableCell align="right" scope="row" sx={{ color: colors.statusInactive }}>
                      {numberFormat(val.total)}
                    </TableCell>
                  </TableRow>
                )}
              </>
            )
          })}

        {data.rowExpenses &&
          _.map(data.rowExpenses, (val: any, index: number) => {
            return (
              <TableRow>
                <TableCell align="left" scope="row" sx={{ color: colors.statusInactive }}>
                  {val.expenseTypeName}
                </TableCell>
                <TableCell align="right" scope="row" sx={{ color: colors.statusInactive }}>
                  {numberFormat(val.price)}
                </TableCell>
                <TableCell align="right" scope="row" sx={{ color: colors.statusInactive }}>
                  {numberFormat(val.total)}
                </TableCell>
              </TableRow>
            )
          })}

        <TableRow>
          <TableCell align="left" scope="row" sx={{ color: colors.statusInactive }}>
            {t('REPORT.TABLE.REMITTANCE.TOTAL_EXPENSES')}
          </TableCell>
          <TableCell align="right" scope="row" sx={{ color: colors.statusInactive }}>
            {numberFormat(total)}
          </TableCell>
          <TableCell align="right" scope="row" sx={{ color: colors.statusInactive }}>
            -
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" scope="row">
            {t('REPORT.TABLE.REMITTANCE.TOTAL_INCOME_EXPENSES')}
          </TableCell>
          <TableCell align="right" scope="row">
            {numberFormat(sum)}
          </TableCell>
          <TableCell align="right" scope="row">
            -
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <>
      <UseStyled.ResponsiveReportTable>
        <TableCustom
          page={props.page}
          pageLimit={props.pageLimit}
          sortType={props.sortType}
          sortBy={props.sortBy}
          rowCount={props.rowCount}
          onSort={props.onRequestSort}
          setPageLimit={props.handleChangeRowsPerPage}
          setPage={props.handleChangePage}
          headCells={headCells}
          rowsData={[renderData(props.data)]}
          hidePagination={true}
          customScroll
        />
      </UseStyled.ResponsiveReportTable>
      <div className="print-show" ref={props.componentRef}>
        <RemittanceExport
          filter={props.filter}
          header={
            <TableHead style={{ fontWeight: 800 }}>
              {t('REPORT.REPORT_NAME.REMITTANCE')}
              {header ? ` ( ${header} )` : ''}
            </TableHead>
          }
          headCells={headCells}
          rowsData={[renderData(props.data)]}
        />
      </div>
    </>
  )
}

export function RemittanceExport(props: ExportProps) {
  const { t } = useTranslation()
  const head = props.headCells
  const { header, filter } = props
  const headCells = head.filter((headCell: any) => headCell.id !== 'action')

  const FILTER_TITLES = [
    { key: 'BRANCH', label: 'REPORT.FILTER.BRANCH' },
    { key: 'DATE', label: 'REPORT.FILTER.DATE' }
  ]

  const renderValue = (value: any, index: any) => {
    if (index === 1) {
      return toBuddhistYear(moment(value), 'DD/MM/YYYY')
    }
    return value
  }

  return (
    <>
      {header}
      <table>
        <tr>
          {FILTER_TITLES.map((filter, index) => (
            <TableSortLabel key={index}>
              <td className="mr-2" style={{ fontSize: 10, fontWeight: 800 }}>
                {t(filter.label)} :{' '}
              </td>
              <td style={{ fontSize: 10, fontWeight: 500 }}>{renderValue(props.filter[index], index)}</td>
            </TableSortLabel>
          ))}
        </tr>
      </table>
      <UseStyled.Styles className="pt-3">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            <tr>
              {_.map(headCells, (val: any, index: number) => {
                return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
              })}
            </tr>
          </thead>
          <tbody>
            {props.rowsData}
            {!props.rowsData.length && <td colSpan={headCells.length}>{t('NOT_FOUND')}</td>}
          </tbody>
        </table>
      </UseStyled.Styles>
    </>
  )
}
