import { useState, useEffect } from 'react'
import { Autocomplete, MenuItem, styled } from '@mui/material'
import { Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import { resetModal } from 'app/slice/modal.slice'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import InputTextField from 'component/Input/InputTextField'
import InputTimePicker from 'component/Input/InputTimePicker'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import InputTextarea from 'component/Input/InputTextarea'
import SearchPatients from 'component/SearchPatients'
import BasicSelect from 'component/Select/BasicSelect'
import { ButtonInfoSimple } from 'component/Button/ButtonInfo'
import InputCheckbox from 'component/Input/InputCheckbox'
import Loading from 'component/Loading'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** API */
import ClinicApi from 'api/master/clinic.api'
import LabGroupsApi from 'api/setting/default-labs/lab-group.api'
import LabCompanyApi from 'api/setting/default-labs/lab-company.api'
import LabOrderApi, { LabOrderDetailInterface, LabOrderInterface, PlayloadLabOrder, PayloadLabOrderDetails } from 'api/lab-order.api'

const initStateErrorMessage = {
  PATIENT: '',
  VN_NUMBER: '',

  VN_NUMBER_STRING_EMPTY: '',

  DATE_ORDER_STRING_EMPTY: '',
  TIME_ORDER_STRING_EMPTY: '',
  USER_ID_NUMBER_BASE: '',
  USER_ID_ANY_REQUIRED: '',
  LAB_COMPANY_ID_NUMBER_BASE: '',
  LAB_COMPANY_ID_ANY_REQUIRED: '',
  DATE_EXPORT_STRING_EMPTY: '',
  DATE_IMPORT_STRING_EMPTY: '',
  OPERATIVE: '',
  LAB_GROUP_ID_NUMBER_BASE: '',
  LAB_GROUP_ID_ANY_REQUIRED: ''
}

const AutoCompleteForm = styled(Autocomplete)(({ theme }) => ({ '& input.MuiOutlinedInput-input': { height: '30px' } }))

/** INTERFACE */
export interface FormLabOrderProps {
  showForm: boolean
  labOrderId?: number
  patientId?: number
  patientCaseId?: number
  patient?: any
  vnNumber?: string
  handleSuccess?: () => void
  onReset?: () => void
  onDelete?: (id: number, obj?: any) => void
  onSuccess?: () => void
}
export default function FormLabOrder(props: FormLabOrderProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState({ ...initStateErrorMessage })

  /** DATA LIST */
  const [visitOptions, setVisitOptions] = useState<any[]>([])
  const [doctorOptions, setDoctorOptions] = useState<any[]>([])
  const [labCompanyOptions, setLabCompanyOptions] = useState<any[]>([])
  const [operativeOptions, setOperativeOptions] = useState<any[]>([])
  const [labGroupOptions, setLabGroupOptions] = useState<any[]>([])

  /** FORM */
  const [patientId, setPatientId] = useState<number>(props.patientId || 0)
  const [patientCaseId, setPatientCaseId] = useState<number>(props?.patientCaseId || 0)
  const [patient, setPatient] = useState(props.patient || {})
  const [vnNumber, setVnNumber] = useState<string>(props.vnNumber || '')
  const [labOrderNumber, setLabOrderNumber] = useState<string>('-')
  const [labOrderStatus, setLabOrderStatus] = useState<string>('-')
  const disabledForm: boolean = patientId === 0 && vnNumber === ''
  const [dateOrder, setDateOrder] = useState<string>('')
  const [timeOrder, setTimeOrder] = useState<string>('')
  const [dateExport, setDateExport] = useState<string>('')
  const [dateImport, setDateImport] = useState<string>('')
  const [userId, setUserId] = useState<number>(0)
  const [labCompanyId, setLabCompanyId] = useState<number>(0)
  const [serviceId, setServiceId] = useState<number>(0)
  const [service, setService] = useState<any>(null)
  const [labGroupId, setLabGroupId] = useState<number>(0)
  const [labGroup, setLabGroup] = useState<any>(null)
  const [labOrderDetails, setLabOrderDetails] = useState<PayloadLabOrderDetails[]>([])
  const [remark, setRemark] = useState<string>('')

  /** LOADING */
  const [loadingOrderLab, setLoadingOrderLab] = useState<boolean>(false)
  const [loadingDataLab, setLoadingDataLab] = useState<boolean>(false)
  const [loadingCompany, setLoadingCompany] = useState<boolean>(false)
  const [loadingFormLab, setLoadingFormLab] = useState<boolean>(false)
  const [loadingDoctor, setLoadingDoctor] = useState<boolean>(false)

  const [submitBtn, setSubmitBtn] = useState<boolean>(false)

  /** FUNCTION */
  useEffect(() => {
    loadOptions()
    onSelectPatient(props?.patientId || 0)
  }, [props.showForm])

  useEffect(() => {
    getTreatmentInVisit()
  }, [props.showForm])

  useEffect(() => {
    if (props.patientCaseId) setPatientCaseId(props.patientCaseId)
  }, [props.patientCaseId])

  const loadLabOrder = async (id: number) => {
    await LabOrderApi.findById(id).then(async ({ data }) => {
      await renderEdit(data).finally(() => {
        setLoadingDataLab(false)
        setIsEdit(true)
      })
    })
  }

  const loadOptions = async () => {
    setLoadingCompany(true)
    setLoadingFormLab(true)
    setLoadingDoctor(true)

    const condition: any = { page: 1, pageLimit: 50, status: 'ACTIVE' }
    await LabGroupsApi.findAll(condition)
      .then(({ data }) => {
        setLabGroupOptions(data)
      })
      .finally(() => setLoadingFormLab(false))
    await LabCompanyApi.findAll(condition)
      .then(({ data }) => {
        setLabCompanyOptions(data)
      })
      .finally(() => setLoadingCompany(false))
    await ClinicApi.findAllDoctors()
      .then(({ data }) => {
        setDoctorOptions(data)
      })
      .finally(() => setLoadingDoctor(false))
    if (props?.labOrderId && props.showForm) {
      setLoadingOrderLab(true)
      await loadLabOrder(props?.labOrderId || 0).finally(() => setLoadingOrderLab(false))
    }
  }

  useEffect(() => {
    clearErrorMessage()
  }, [patientId, patientCaseId, vnNumber, labOrderNumber, labOrderStatus, dateOrder, timeOrder, dateExport, dateImport, userId, labCompanyId, serviceId, labGroupId, labOrderDetails])

  const renderEdit = async (objEdit: LabOrderInterface) => {
    if (props?.labOrderId && objEdit) {
      setLoadingDataLab(true)
      await ClinicApi.findPatientsById(objEdit.patientId)
        .then(({ data }) => setPatient(data))
        .catch((e) => {
          return
        })
      await onSelectPatient(objEdit.patientId)
      await onSelectVnNumber(objEdit.vnNumber)
      setPatientCaseId(objEdit.patientCaseId)
      setLabOrderNumber(objEdit.labOrderNumber)
      setLabOrderStatus(objEdit.labOrderStatus)
      setDateOrder(objEdit.dateOrder)
      setTimeOrder(objEdit.timeOrder)
      setDateExport(objEdit.dateExport)
      setDateImport(objEdit.dateImport)
      setUserId(objEdit.userId)
      setLabCompanyId(objEdit.labCompanyId)
      setServiceId(objEdit.tempOrderTreatmentId)
      const operatives: any[] = await LabOrderApi.findTreatmentVisit(objEdit.patientCaseId).then(({ data }) => data)
      setOperativeOptions(operatives)
      setService(operatives.find((op: any) => op.labOrderId === objEdit.labOrderId) || null)
      setLabGroupId(objEdit.labGroupId)
      setRemark(objEdit.remark)
      if (objEdit?.labOrderDetails) {
        const tempDetails: PayloadLabOrderDetails[] | any[] =
          objEdit?.labOrderDetails?.map((detail: LabOrderDetailInterface) => {
            return {
              labId: detail.labId,
              labTypeId: detail.labTypeId,
              inputSupport: detail.inputSupport,
              labText: detail?.labText || ''
            }
          }) || []
        setLabOrderDetails(tempDetails)
      }
    }
  }

  useEffect(() => {
    setLabGroup(_.find(labGroupOptions, (op: any) => op.labGroupId === labGroupId) || null)
  }, [labGroupId, labGroupOptions])

  const getTreatmentInVisit = (pcId?: number) => {
    const id = pcId !== undefined && pcId !== 0 ? pcId : props?.patientCaseId ? props?.patientCaseId : 0
    LabOrderApi.findTreatmentVisit(id).then(({ data }) => {
      setOperativeOptions(data)
    })
  }

  const handleCheckLab = (e: any, objLab: any) => {
    const { labId, labTypeId, inputSupport } = objLab
    const checked: boolean = e?.target?.checked || false
    const tempDetail: PayloadLabOrderDetails = { labId, labTypeId, inputSupport, labText: '' }
    if (checked) setLabOrderDetails([...labOrderDetails, tempDetail])
    else setLabOrderDetails(labOrderDetails.filter((lab: any) => !(lab.labId === labId && lab.labTypeId === labTypeId)))
  }

  const handleCheckLabAddText = (e: any, objLab: any) => {
    const { labId, labTypeId } = objLab
    const val: boolean = e?.target?.value || ''
    const tempDetail: PayloadLabOrderDetails | undefined = [...labOrderDetails].find((lab: any) => lab.labId === labId && lab.labTypeId === labTypeId)
    if (tempDetail) {
      const temp: PayloadLabOrderDetails[] = [...labOrderDetails.filter((lab: any) => !(lab.labId === labId && lab.labTypeId === labTypeId))]
      setLabOrderDetails([...temp, { ...tempDetail, labText: String(val) }])
    }
  }

  const submit = () => {
    if (!patientId || !vnNumber || !dateOrder || !timeOrder || !userId || !labCompanyId || !dateExport || !dateImport || !service || !labGroupId) {
      const msg: any = {
        PATIENT: !patientId ? t(`LAB_ORDER.MESSAGE.PATIENT`) : '',
        VN_NUMBER: !vnNumber ? t(`LAB_ORDER.MESSAGE.VN_NUMBER`) : '',
        DATE_ORDER_STRING_EMPTY: !dateOrder ? t(`LAB_ORDER.MESSAGE.DATE_ORDER_STRING_EMPTY`) : '',
        TIME_ORDER_STRING_EMPTY: !timeOrder ? t(`LAB_ORDER.MESSAGE.TIME_ORDER_STRING_EMPTY`) : '',
        USER_ID_NUMBER_BASE: !userId ? t(`LAB_ORDER.MESSAGE.USER_ID_NUMBER_BASE`) : '',
        LAB_COMPANY_ID_NUMBER_BASE: !labCompanyId ? t(`LAB_ORDER.MESSAGE.LAB_COMPANY_ID_NUMBER_BASE`) : '',
        DATE_EXPORT_STRING_EMPTY: !dateExport ? t(`LAB_ORDER.MESSAGE.DATE_EXPORT_STRING_EMPTY`) : '',
        DATE_IMPORT_STRING_EMPTY: !dateImport ? t(`LAB_ORDER.MESSAGE.DATE_IMPORT_STRING_EMPTY`) : '',
        OPERATIVE: !service ? t(`LAB_ORDER.MESSAGE.OPERATIVE`) : '',
        LAB_GROUP_ID_NUMBER_BASE: !labGroupId ? t(`LAB_ORDER.MESSAGE.LAB_GROUP_ID_NUMBER_BASE`) : ''
      }
      setErrorMessage({ ...initStateErrorMessage, ...msg })
      return false
    }
    if (labOrderDetails.length === 0) {
      notiError(t(`LAB_ORDER.MESSAGE.ERROR.ERROR_SELECT_LAB`))
      return false
    }
    const opSelect: any = _.find(operativeOptions, (op: any) => op.tempOrderTreatmentId === serviceId || op.orderTreatmentId === serviceId)
    if (!opSelect) return false
    const id = patientCaseId || props?.patientCaseId || 0
    const payload: PlayloadLabOrder = {
      patientCaseId: id,
      dateOrder,
      timeOrder,
      userId,
      labCompanyId,
      dateExport,
      dateImport,
      tempOrderTreatmentId: opSelect?.tempOrderTreatmentId || null,
      orderTreatmentId: opSelect?.orderTreatmentId || null,
      labGroupId,
      remark,
      labOrderDetails
    }
    if (props?.labOrderId) update(props.labOrderId, payload)
    else create(payload)
  }

  const create = async (payload: PlayloadLabOrder) => {
    setSubmitBtn(true)
    await LabOrderApi.create(payload)
      .then((res: any) => {
        setSubmitBtn(false)
        if (res.status !== undefined && res.status === 201) {
          if (props?.handleSuccess) props?.handleSuccess()
          dispatch(resetModal())
          resetForm()
          if (props.onSuccess) props.onSuccess()
          notiSuccess(t(`LAB_ORDER.MESSAGE.SUCCESS.CREATE`), '', null, null, 'register-page')
        } else {
          const err = res.response.data
          setErrorMessage({ ...initStateErrorMessage, [err.message]: t(`LAB_ORDER.MESSAGE.${err.message}`) })
        }
      })
      .catch((e) => {
        setSubmitBtn(false)
        const err = e.response.data
        notiError(t(`LAB_ORDER.MESSAGE.ERROR.${err.message}`))
      })
  }

  const update = async (labOrderId: number, payload: PlayloadLabOrder) => {
    await LabOrderApi.update(labOrderId, payload)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          if (props?.handleSuccess) props?.handleSuccess()
          dispatch(resetModal())
          resetForm()
          notiSuccess(t(`LAB_ORDER.MESSAGE.SUCCESS.UPDATE`), '', null, null, 'register-page')
        } else {
          const err = res.response.data
          setErrorMessage({ ...initStateErrorMessage, [err.message]: t(`LAB_ORDER.MESSAGE.${err.message}`) })
        }
      })
      .catch((e) => {
        const err = e.response.data
        notiError(t(`LAB_ORDER.MESSAGE.ERROR.${err.message}`))
      })
  }

  const resetForm = async () => {
    clearErrorMessage()
    setPatientId(0)
    setPatientCaseId(0)
    setPatient([])
    setVnNumber('')
    setLabOrderNumber('-')
    setLabOrderStatus('-')
    setDateOrder('')
    setTimeOrder('')
    setDateExport('')
    setDateImport('')
    setUserId(0)
    setLabGroupId(0)
    setLabGroup(null)
    setLabCompanyId(0)
    setServiceId(0)
    setService(null)
    setLabOrderDetails([])
    setRemark('')
    setIsEdit(false)
    setOperativeOptions([])
    setVisitOptions([])
    if (props.onReset) props.onReset()
  }

  const clearErrorMessage = async () => setErrorMessage(initStateErrorMessage)

  const onSelectPatient = async (valuePatientId: number) => {
    setPatientId(valuePatientId)
    let visits: any[] = []
    if (valuePatientId) {
      await LabOrderApi.findVisitHasOperative(valuePatientId).then(({ data }) => {
        setVisitOptions(data)
        visits = data
      })
    } else {
      setVisitOptions([])
      visits = []
    }
    if (!props.patientCaseId) await onSelectVnNumber('')
    else await onSelectVnNumber(visits.find((visit: any) => visit.patientCaseId === props.patientCaseId)?.vnNumber || '')
    setServiceId(0)
    setService(null)
  }

  const onSelectVnNumber = async (val: string) => {
    setVnNumber(val)
    if (val) {
      const pcId: any = visitOptions.find((visit: any) => visit.vnNumber === val)?.patientCaseId || 0
      getTreatmentInVisit(pcId)
      setPatientCaseId(pcId)
      setServiceId(0)
      setService(null)
    } else {
      setPatientCaseId(0)
    }
  }

  const getLabelLabOrderStatus = (status: string) => {
    let label: any = ''
    if (status === 'ORDER_LAB') label = t('LAB_ORDER.LAB_STATUS.ORDER_LAB')
    else if (status === 'SEND_LAB') label = t('LAB_ORDER.LAB_STATUS.SEND_LAB')
    else if (status === 'RECEIVE_LAB') label = t('LAB_ORDER.LAB_STATUS.RECEIVE_LAB')
    else if (status === 'RECEIVE_BILL') label = t('LAB_ORDER.LAB_STATUS.RECEIVE_BILL')
    else if (status === 'REPLY_LAB') label = t('LAB_ORDER.LAB_STATUS.REPLY_LAB')
    else if (status === 'CANCEL_LAB') label = t('LAB_ORDER.LAB_STATUS.CANCEL_LAB')
    else if (status === 'CLOSE_LAB') label = t('LAB_ORDER.LAB_STATUS.CLOSE_LAB')
    return label
  }

  return (
    (props.showForm && ((props.labOrderId && isEdit) || true) && (
      <ModalCustom
        size={'xl'}
        title={props.labOrderId ? t('LAB_ORDER.UPDATE') : t('LAB_ORDER.CREATE')}
        alignFooter={'end'}
        fullscreen={'lg-down'}
        component={
          <>
            {(!loadingOrderLab && !loadingFormLab && !loadingCompany && !loadingDoctor && !loadingDataLab && (
              <>
                {(!props.patientId || !props.patientCaseId) && (
                  <>
                    <Row className={`mx-0 pt-2 pt-lg-0`}>
                      <Col xs={12} sm={6} lg={3} className="mb-3 px-2">
                        {t('LAB_ORDER.FORM.NO_LAB')}: {labOrderNumber}
                      </Col>
                      <Col xs={12} sm={6} lg={3} className="mb-3 px-2">
                        {t('LAB_ORDER.FORM.STATUS')}: {getLabelLabOrderStatus(labOrderStatus)}
                      </Col>
                    </Row>
                    <Row className={`mx-0 pt-2 pt-lg-0`}>
                      <Col xs={12} sm={6} lg={6} className="mb-3 px-2">
                        <SearchPatients
                          status="ACTIVE"
                          bodyHeight={43}
                          callbackPatientId={onSelectPatient}
                          patient={props.patient || patient}
                          patientId={patientId}
                          disabled={props.labOrderId !== 0 ? true : false}
                          onClear={() => onSelectPatient(0)}
                          helperText={errorMessage.PATIENT || ''}
                        />
                      </Col>
                      <Col xs={12} sm={6} lg={3} className="mb-3 px-2">
                        <BasicSelect
                          disabled={disabledForm}
                          required={true}
                          label={t('VN')}
                          value={vnNumber}
                          onchange={(event: any) => onSelectVnNumber(event?.target.value || '')}
                          selectId="branch"
                          labelId="branch"
                          options={visitOptions
                            .map((visit: any, i: number) => (
                              <MenuItem key={i} value={visit.vnNumber}>
                                {visit.vnNumber}
                              </MenuItem>
                            ))
                            .reverse()}
                          helperText={errorMessage.VN_NUMBER || ''}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                <Row className={`mx-0 pt-2 pt-lg-0`}>
                  <Col xs={12} sm={6} lg={3} className="mb-3 px-2">
                    <InputSecondNewDatePicker
                      required
                      disabled={disabledForm}
                      key={'date-order'}
                      label={t('LAB_ORDER.FORM.DATE_ORDER')}
                      value={dateOrder}
                      helperText={errorMessage.DATE_ORDER_STRING_EMPTY || ''}
                      onchange={(e: any) => setDateOrder(moment(e).format('YYYY-MM-DD'))}
                    />
                  </Col>
                  <Col xs={12} sm={6} lg={3} className="mb-3 px-2">
                    <InputTimePicker
                      required={true}
                      disabled={disabledForm}
                      key={'time'}
                      label={t('LAB_ORDER.FORM.TIME_ORDER')}
                      value={timeOrder}
                      helperText={errorMessage.TIME_ORDER_STRING_EMPTY || ''}
                      onChange={(e: any) => setTimeOrder(e.format('HH:mm'))}
                    />
                  </Col>
                  <Col xs={12} sm={6} lg={3} className="mb-3 px-2">
                    <Row className="mx-0 -flex-center">
                      <Col className="px-0 col">
                        <AutoCompleteForm
                          id="doctor"
                          size="small"
                          disableClearable
                          disabled={disabledForm}
                          value={userId ? _.find(doctorOptions, { userId: userId }) : null}
                          options={doctorOptions}
                          onChange={(event, val: any) => {
                            setUserId(val.userId)
                          }}
                          getOptionLabel={(option: any) => option.fullname}
                          renderInput={(params) => <InputTextField required={true} helperText={errorMessage.USER_ID_NUMBER_BASE || errorMessage.USER_ID_ANY_REQUIRED || ''} size="small" params={params} label={t('LAB_ORDER.FORM.DOCTOR')} />}
                        />
                      </Col>
                      <Col className="pl-2 pr-0 col-auto pt-2">
                        <ButtonInfoSimple text={t('LAB_ORDER.FORM.DOCTOR_ORDER_LAB')} />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} sm={6} lg={3} className="mb-3 px-2">
                    <AutoCompleteForm
                      id="company"
                      size="small"
                      disableClearable
                      disabled={disabledForm}
                      value={labCompanyId ? _.find(labCompanyOptions, { labCompanyId: labCompanyId }) : null}
                      options={labCompanyOptions}
                      onChange={(event, val: any) => {
                        setLabCompanyId(val.labCompanyId)
                      }}
                      getOptionLabel={(option: any) => option.labCompanyName}
                      renderInput={(params) => <InputTextField required={true} helperText={errorMessage.LAB_COMPANY_ID_NUMBER_BASE || ''} size="small" params={params} label={t('LAB_ORDER.FORM.COMPANY')} />}
                    />
                  </Col>
                  <Col xs={12} sm={6} lg={3} className="mb-3 px-2">
                    <InputSecondNewDatePicker
                      required
                      disabled={disabledForm}
                      key={'date-export'}
                      label={t('LAB_ORDER.FORM.DATE_EXPORT')}
                      value={dateExport}
                      helperText={errorMessage.DATE_EXPORT_STRING_EMPTY || ''}
                      onchange={(e: any) => setDateExport(moment(e).format('YYYY-MM-DD'))}
                    />
                  </Col>
                  <Col xs={12} sm={6} lg={3} className="mb-3 px-2">
                    <InputSecondNewDatePicker
                      required
                      disabled={disabledForm}
                      key={'date-receive'}
                      label={t('LAB_ORDER.FORM.DATE_RECEIVE')}
                      value={dateImport}
                      helperText={errorMessage.DATE_IMPORT_STRING_EMPTY || ''}
                      onchange={(e: any) => setDateImport(moment(e).format('YYYY-MM-DD'))}
                    />
                  </Col>
                </Row>
                <Row className={`mx-0 pt-2 pt-lg-0`}>
                  <Col xs={12} sm={12} lg={6} className="mb-3 px-2">
                    <AutoCompleteForm
                      id="operative"
                      size="small"
                      disabled={disabledForm}
                      disableClearable
                      value={service}
                      options={operativeOptions}
                      getOptionDisabled={(option: any) => option.labOrderId !== null && option.labOrderId !== props.labOrderId}
                      onChange={(event, val: any) => {
                        setService(val)
                        if (val.tempOrderTreatmentId) setServiceId(val.tempOrderTreatmentId)
                        else setServiceId(val.orderTreatmentId)
                      }}
                      getOptionLabel={(option: any) => option.operativeName}
                      renderInput={(params) => <InputTextField required={true} helperText={errorMessage.OPERATIVE || ''} size="small" params={params} label={t('LAB_ORDER.FORM.OPERATIVE')} />}
                    />
                  </Col>
                  <Col xs={12} sm={12} lg={6} className="mb-3 px-2">
                    <AutoCompleteForm
                      id="lab-group"
                      size="small"
                      disabled={disabledForm}
                      disableClearable
                      value={labGroupId ? _.find(labGroupOptions, { labGroupId: labGroupId }) : null}
                      options={labGroupOptions}
                      onChange={(event, val: any) => {
                        setLabGroupId(val.labGroupId)
                        setLabOrderDetails([])
                      }}
                      getOptionLabel={(option: any) => option.labGroupName}
                      renderInput={(params) => <InputTextField required={true} helperText={errorMessage.LAB_GROUP_ID_NUMBER_BASE || ''} size="small" params={params} label={t('LAB_ORDER.FORM.LAB_GROUP')} />}
                    />
                  </Col>
                </Row>
                <Row className="mx-0 overflow-auto custom-scroll" style={{ maxHeight: 500, minHeight: labGroup ? 0 : 200 }}>
                  {labGroup ? (
                    <>
                      {_.map(_.groupBy(labGroup?.labGroupDetails, 'labTypeId'), (detail: any) => (
                        <Row className="mx-0">
                          {_.map(detail, (labDetail: any, i: number) => {
                            const { labId, labTypeId, inputSupport } = labDetail
                            const findDetail: PayloadLabOrderDetails | undefined = _.find([...labOrderDetails], (lab: any) => lab.labId === labId && lab.labTypeId === labTypeId)
                            const isChecked: boolean = findDetail ? true : false
                            const isLabInput: boolean = inputSupport === 'SUPPORT' ? true : false

                            return (
                              <>
                                {i === 0 && (
                                  <div className="p-2 mb-2" style={{ backgroundColor: colors.themeSecondColor10, fontSize: 18, fontWeight: 500 }}>
                                    {labDetail.labTypeName}
                                  </div>
                                )}
                                <Col className="col-12 col-sm-6">
                                  <Row className="mx-0 flex-center mb-2">
                                    <Col className="col-12 col-sm-6 px-1 mr-auto text-text-nowrap-ellipsis">
                                      <InputCheckbox checked={isChecked} label={labDetail.labName} onChange={(e) => handleCheckLab(e, labDetail)} />
                                    </Col>
                                    {isLabInput && (
                                      <Col className="col-12 col-sm-6 px-1">
                                        <InputTextField label={t('LAB_ORDER.FORM.REMARK')} value={isChecked ? findDetail?.labText : ''} disabled={!isChecked} onchange={(e) => handleCheckLabAddText(e, labDetail)} />
                                      </Col>
                                    )}
                                  </Row>
                                </Col>
                              </>
                            )
                          })}
                        </Row>
                      ))}
                    </>
                  ) : (
                    <div className="d-flex w-100">
                      <div className="flex-center text-center w-100">{t('LAB_ORDER.FORM.NONE_DATA')}</div>
                    </div>
                  )}
                </Row>
                <div className="px-2">
                  <hr />
                </div>
                <div className="mt-2 px-2">
                  <InputTextarea disabled={disabledForm} value={remark} onchange={(event) => setRemark(event.target.value)} label={t('LAB_ORDER.FORM.REMARK')} helperText={''} />
                </div>
              </>
            )) || (
                <div className="mx-auto w-max">
                  <Loading show type={'softLoading'} />
                </div>
              )}
          </>
        }
        onSubmit={submit}
        disabledSubmit={submitBtn}
        onReset={() => resetForm()}
        textBtnCancel={t('LAB_ORDER.FORM.CANCEL')}
        textBtnConfirm={props.labOrderId ? t('LAB_ORDER.FORM.SAVE') : t('LAB_ORDER.FORM.ADD')}
      />
    )) || <></>
  )
}
