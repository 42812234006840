import { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { lineConnectConfig } from 'api/api'
import { routeName } from 'routes/routes-name'
import { KEY_STORAGE } from 'constants/common'
import { getToken } from 'utils/app.utils'

/** API */
import RegisterApi, { LineNotify } from 'api/counter/register.api'

export default function LineCallback() {
  const location = useLocation()
  const history = useHistory()

  const handleCallback = async (code: string, patientId: number) => {
    const body: LineNotify = {
      code,
      patientId,
      redirectTo: lineConnectConfig.LINE_NOTI_CALLBACK_URL || 'https://cliniter.knacx.dev/register/line-connect/callback'
      // redirectTo: 'http://localhost:3000/register/line-connect/callback'
    }

    const connect = await RegisterApi.lineConnect(body)
    if (connect.status === 201) {
      if (localStorage.getItem(KEY_STORAGE.PATIENT) && getToken()) {
        history.push({
          pathname: `${routeName.register}/line-connect`,
          state: { id: patientId }
        })
      } else {
        window.location.replace('https://www.google.co.th/')
        window.close()
      }
    } else window.location.href = 'https://www.google.co.th/'
  }

  useEffect(() => {
    const state: any = location.search
    const patient_code: string | null = new URLSearchParams(state).get('code')
    const patient_id: any = new URLSearchParams(state).get('state')
    if (patient_code && patient_id) handleCallback(patient_code, patient_id)
  }, [location])

  return <div>connecting...</div>
}
