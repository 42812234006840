/** CONSTANTS */
import { colors } from 'constants/theme'

/** STYLE */
import { styled } from '@mui/material/styles'

import { Droppable } from "react-beautiful-dnd";
import React from 'react';

export const SliderItem = styled('div')(({ theme }) => ({
  flex: '0 0 323px',
  width: 323,
  maxWidth: 323,
  border: `1px solid ${colors.extraLightGray}`,
  background: colors.servicePoint.panel,
  borderRadius: 8,
  [theme.breakpoints.down('sm')]: {
    flex: '0 0 277px',
    width: 277,
    maxWidth: 277
  },
}))

export const Header = styled('div')(({ theme }) => ({
  backgroundColor: '#fff !important',
  top: '0',
  zIndex: '2',
  position: 'sticky',
  padding: '0 0.5rem',
  height: 56,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  fontSize: 20,
  fontWeight: 600,
  color: colors.white,
  'span::selection': {
    background: 'transparent'
  },
  [theme.breakpoints.down('sm')]: {
    height: 48,
    fontSize: 18
  }
}))

export const Body = styled('div')(({ theme }) => ({
  paddingBottom: 'calc(1.5rem + 200px)',
  height: '100%'

}))

type ServicePoint = {
  headerName: string
  serviceType: string
  servicePointId?: number
  body?: any
  count?: number
  type?: 'home',
  backgroundHeader?: string
}
// 
export default function ServicePointsArea(props: ServicePoint) {
  const backgroundType = (type: string) => {
    if (type === 'COUNTER') return colors.servicePoint.counter
    else if (type === 'ROOMSERVICES') return colors.servicePoint.roomServices
    else if (type === 'PAYMENT') return colors.servicePoint.payment
    else return props?.backgroundHeader || colors.extraLightGray
  }

  const headDrag = `${props?.servicePointId || ''}`

  return (
    <SliderItem className={'slider-area'}>
      <Header style={{ background: backgroundType(props.serviceType) }}>
        <span className={'text-ellipsis'}>{props.headerName} {props.count && `(${props.count})`}</span>
      </Header>
      <BodyDroppable type={props?.type} headerName={headDrag} body={props?.body} />
    </SliderItem>
  )
}

interface BodyDroppableProps {
  type?: 'home' | any
  headerName: string
  body?: any
}

const BodyDroppable = (props: BodyDroppableProps) => {
  if (props.type === 'home') {
    return (
      <Droppable droppableId={`${props.headerName}`}>
        {(dropProvided, dropSnapshot) => (
          <Body
            ref={dropProvided.innerRef}
            {...dropProvided.droppableProps}
            className={'custom-scroll'}
          >
            {props.body}
          </Body>
        )}
      </Droppable>
    )
  } else {
    return (
      <Body
        className={'custom-scroll'}
        style={{
          overflowY: 'auto',
          paddingBottom: "1.5rem",
          height: 'calc(100% - 56px)'
        }}>
        {props.body}
      </Body>
    )
  }
}