import { makeStyles } from '@material-ui/core'
import { colors } from 'constants/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  bodyCalendar: {
    '& .card-body': {
      padding: '1px'
    }
  },
  formMutiSelect: {
    '& .ant-select': {
      fontSize: '20px !important'
    }
  },
  btn: {
    width: 'fit-content',
    height: '40px',
    borderRadius: '4px',
    border: `solid 1px ${colors.themeSecondColor}`,
    backgroundColor: colors.white,
    fontSize: '16px',
    color: colors.themeSecondColor
  },
  btnDisable: {
    width: 'fit-content',
    height: '40px',
    borderRadius: '4px',
    border: `solid 1px ${colors.disabledGray}`,
    backgroundColor: colors.white,
    fontSize: '16px',
    color: colors.disabledGray
  },
  containerRadio: {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    fontSize: '16px'
  },
  radio: {
    '& .css-ahj2mt-MuiTypography-root': {
      fontSize: '16px !important'
    }
  },
  formLabel: {
    marginRight: '21px',
    fontWeight: 700
  },
  newPatient: {
    padding: '5px 0px 5px 16px',
    fontSize: '18px',
    fontWeight: 700,
    marginLeft: '30px',
    borderRadius: '4px',
    width: '100%',
    backgroundColor: colors.themeSecondColor10
  },
  dropdownColor: {
    '& .btn-primary': {
      backgroundColor: (props: any) => props.backgroundColor,
      color: colors.black
    },
    '& .btn-primary.dropdown-toggle': {
      display: 'flex',
      backgroundColor: (props: any) => props.backgroundColor,
      color: colors.black,
      '&:after': {
        marginTop: '15px'
      }
    }
  }
}))

export default useStyles
