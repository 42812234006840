import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface TreatmentPlanInterface {
  treatmentPlanId?: number
  treatmentPlanTypeId: number
  treatmentPlanName: string
  status?: string
  treatmentPlanItems: TreatmentPlanItemsInterface[]
}
export interface TreatmentPlanItemsInterface {
  itemId: number
  type: string
}

export default class TreatmentPlanApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('treatment-plans', { params: { ...props } }).then((res) => res)
  }

  static create(playload: any) {
    return this.api.post('treatment-plans', playload)
  }

  static findById(id: number): Promise<TreatmentPlanInterface> {
    return this.api.get(`treatment-plans/${id}`)
  }

  static update(id: number, body: TreatmentPlanInterface) {
    return this.api.patch(`treatment-plans/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`treatment-plans/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`treatment-plans/${id}`)
  }
}
