import BaseAPI from 'api/api'

/** INTERFACE PROPS */
interface SearchOnlyProps {
  search: string
}

interface ItemCodeProps {
  itemTypeId: number
  group: string
  search: string
}

/** INTERFACE DATA */
export interface TreatmentItemsInterface {
  operativeTypeId: number
  operativeTypeName: string
  operativeTypeNameEn: string
  color: string
  operativeId: number
  operativeName: string
  operativeNameEn: string
}

export interface Bookmark {
  bookmarkType: string
  userId: number
  itemId: number
  isBookmark: string
}

export default class ComponentApi extends BaseAPI {
  static findAllCareers(): Promise<any> {
    return this.api.get('components/careers').then((res) => res)
  }
  static findAllPrefixes(): Promise<any> {
    return this.api.get('components/prefixes').then((res) => res)
  }
  static findAllOrigins(): Promise<any> {
    return this.api.get('components/origins').then((res) => res)
  }
  static findAllNationalities(): Promise<any> {
    return this.api.get('components/nationalities').then((res) => res)
  }
  static findAllMaritalStatus(): Promise<any> {
    return this.api.get('marital-status').then((res) => res)
  }
  static findAllAccessChannels(): Promise<any> {
    return this.api.get('components/access-channels').then((res) => res)
  }
  static findAllServiceFactors(): Promise<any> {
    return this.api.get('components/service-factors').then((res) => res)
  }
  static findAllPatientType(props: any): Promise<any> {
    return this.api.get('patient-types', { params: { ...props } }).then((res) => res)
  }
  static findAllBloodType(): Promise<any> {
    return this.api.get('components/blood-types').then((res) => res)
  }
  static findAllFrequenciesCigarette(props: any): Promise<any> {
    return this.api.get('frequencies-cigarettes', { params: { ...props } }).then((res) => res)
  }
  static findAllFrequenciesAlcohols(props: any): Promise<any> {
    return this.api.get('frequencies-alcohols', { params: { ...props } }).then((res) => res)
  }
  static findAllCountries(): Promise<any> {
    return this.api.get('components/countries').then((res) => res)
  }
  static findAllProvinces(): Promise<any> {
    return this.api.get('components/provinces').then((res) => res)
  }
  static findAllDistricts(id: string): Promise<any> {
    return this.api.get(`components/districts/${id}`).then((res) => res)
  }
  static findAllSubDistricts(id: string): Promise<any> {
    return this.api.get(`components/sub-districts/${id}`).then((res) => res)
  }
  static findAllDisease(): Promise<any> {
    return this.api.get(`clinic/disease`).then((res) => res)
  }
  static findAllMedicineUse(): Promise<any> {
    return this.api.get(`clinic/medicine-use`).then((res) => res)
  }
  static findAllBeAllergic(): Promise<any> {
    return this.api.get(`clinic/be-allergic`).then((res) => res)
  }
  static findAllTreatmentItems(props: any): Promise<any> {
    return this.api.get(`components/treatment-items`, { params: { ...props } }).then((res) => res)
  }
  static findAllItemCode(props: ItemCodeProps): Promise<any> {
    return this.api.get(`components/item-code`, { params: { ...props } }).then((res) => res)
  }
  static findAllOperativeCode(): Promise<any> {
    return this.api.get(`components/operative-code`).then((res) => res)
  }
  static bookmark(payload: Bookmark): Promise<any> {
    return this.api.post(`treatments/bookmark`, payload)
  }
}
