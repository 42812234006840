import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Box, Divider, TableSortLabel, Typography } from '@mui/material'
import { ReportsProps, ExportProps } from 'features/report/report-constants'
import { isBuddhistYear, numberFormat, toBuddhistYear } from 'utils/app.utils'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import EmptyData from 'features/report/component/emptyData'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { colors } from 'constants/theme'

import { UseSnCode } from 'utils/useSnCode'
import { SORT_TYPE } from 'constants/common'

export interface DoctorFeeComponent {
  page: number
  pageLimit: number
  rowCount: number
  data: any
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
  onRequestSort: (sortBy: string, sortType: string) => void
  setSortType: (val: SORT_TYPE) => void
  setSortBy: (val: string) => void
  sortType: string
  sortBy: string
}

export default function RevenueTransactionList(props: DoctorFeeComponent) {
  const { t } = useTranslation()

  const [snType, setSnType] = useState(false)

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])

  const onRequestSort = (sortByVal: string, sortTypeVal: SORT_TYPE) => {
    props.setSortType(sortTypeVal)
    props.setSortBy(sortByVal)
    props.handleChangePage(1)
  }

  const headCells = [
    { id: 'A', disablePadding: false, labelKey: 'REPORT.TABLE.DF.#NO', align: 'center', class: 'text-nowrap' },
    { id: 'B', disablePadding: false, labelKey: 'Date', align: 'center', class: 'text-nowrap' },
    { id: 'C', disablePadding: false, labelKey: 'Receipt Status', align: 'center', class: 'text-nowrap' },
    { id: 'D', disablePadding: false, labelKey: 'Receipt No.', align: 'center', class: 'text-nowrap' },
    { id: 'E', disablePadding: false, labelKey: 'CN No.', align: 'center', class: 'text-nowrap' },
    { id: 'F', disablePadding: false, labelKey: 'Pt No.', align: 'center', class: 'text-nowrap' },
    { id: 'G', disablePadding: false, labelKey: 'Pt. Name', align: 'center', class: 'text-nowrap' },
    { id: 'H', disablePadding: false, labelKey: 'Dentist', align: 'center', class: 'text-nowrap' },
    { id: 'I', disablePadding: false, labelKey: 'Code', align: 'center', class: 'text-nowrap' },
    { id: 'J', disablePadding: false, labelKey: 'รายการ', align: 'center', class: 'text-nowrap' },
    { id: 'K', disablePadding: false, labelKey: 'ราคาต่อหน่วย', align: 'center', class: 'text-nowrap' },
    { id: 'L', disablePadding: false, labelKey: 'จำนวน', align: 'center', class: 'text-nowrap' },
    { id: 'M', disablePadding: false, labelKey: 'ราคาค่ารักษา', align: 'center', class: 'text-nowrap' },
    { id: 'N', disablePadding: false, labelKey: 'DF %', align: 'center', class: 'text-nowrap' },
    { id: 'O', disablePadding: false, labelKey: 'ยอดค่ารักษา DF', align: 'center', class: 'text-nowrap' },
    { id: 'P', disablePadding: false, labelKey: 'CF %', align: 'center', class: 'text-nowrap' },
    { id: 'Q', disablePadding: false, labelKey: 'ยอดค่ารักษา CF', align: 'center', class: 'text-nowrap' },
    { id: 'R', disablePadding: false, labelKey: 'Total Discount (THB)', align: 'center', class: 'text-nowrap' },
    { id: 'S', disablePadding: false, labelKey: 'Total Additional (THB)', align: 'center', class: 'text-nowrap' },
    { id: 'T', disablePadding: false, labelKey: 'ยอดรวมสุทธิค่ารักษา', align: 'center', class: 'text-nowrap' },
    { id: 'U', disablePadding: false, labelKey: 'จ่ายค่ารักษา', align: 'center', class: 'text-nowrap' },
    { id: 'V', disablePadding: false, labelKey: 'จ่ายค้างชำระ', align: 'center', class: 'text-nowrap' },
    { id: 'W', disablePadding: false, labelKey: 'Advance', align: 'center', class: 'text-nowrap' },
    { id: 'X', disablePadding: false, labelKey: 'ยอดรับเงินสุทธิ', align: 'center', class: 'text-nowrap' },
    { id: 'Y', disablePadding: false, labelKey: 'เงินสด', align: 'center', class: 'text-nowrap' },
    { id: 'Z', disablePadding: false, labelKey: 'เงินโอน', align: 'center', class: 'text-nowrap' },
    { id: 'AA', disablePadding: false, labelKey: 'เดบิต', align: 'center', class: 'text-nowrap' },
    { id: 'AB', disablePadding: false, labelKey: 'เครดิต', align: 'center', class: 'text-nowrap' },
    { id: 'AC', disablePadding: false, labelKey: 'voucher', align: 'center', class: 'text-nowrap' },
    { id: 'AD', disablePadding: false, labelKey: 'สิทธิการรักษา', align: 'center', class: 'text-nowrap' },
    { id: 'AE', disablePadding: false, labelKey: 'Clear Advance', align: 'center', class: 'text-nowrap' },
    { id: 'AF', disablePadding: false, labelKey: 'Adv เงินสด', align: 'center', class: 'text-nowrap' },
    { id: 'AG', disablePadding: false, labelKey: 'Adv เงินโอน', align: 'center', class: 'text-nowrap' },
    { id: 'AH', disablePadding: false, labelKey: 'Adv เดบิต', align: 'center', class: 'text-nowrap' },
    { id: 'AI', disablePadding: false, labelKey: 'Adv เครดิต', align: 'center', class: 'text-nowrap' },
    { id: 'AJ', disablePadding: false, labelKey: 'ยอดรวมเครดิต', align: 'center', class: 'text-nowrap' },
    { id: 'AK', disablePadding: false, labelKey: 'Balance', align: 'center', class: 'text-nowrap' },
    { id: 'AL', disablePadding: false, labelKey: 'Staff', align: 'center', class: 'text-nowrap' },
    { id: 'AM', disablePadding: false, labelKey: 'Remark', align: 'center', class: 'text-nowrap' },
    { id: 'AN', disablePadding: false, labelKey: 'Cancel Remark', align: 'center', class: 'text-nowrap' },
    { id: 'AO', disablePadding: false, labelKey: 'Cancel By', align: 'center', class: 'text-nowrap' }
  ]

  if (!snType) {
    // headCells.splice(3, 1)
  }
  const renderData = (data: any, no: number, exportData: boolean, render: 'data' | 'sum') => {
    if (render === 'data') {
      const { doctorFullname, total, amountPaid, dfNet, bankAccountNumber, bankAccountName } = data
      const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
      const objRenderData = {
        key: num.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: num, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: doctorFullname || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(total || 0, 3, 3), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(amountPaid || 0, 3, 3), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(dfNet || 0, 3, 3), class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountNumber || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: bankAccountName || '-', class: 'text-nowrap' }
        ]
      }
      // if (!snType) {
      //   objRenderData.columns.splice(3, 1)
      // }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    } else {
      const objRenderData = {
        key: no.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'MERGE', align: 'center', colSpan: 1 },
          {
            option: 'TEXT',
            align: 'right',
            style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 },
            label: <Typography sx={{ fontWeight: 500, fontSize: exportData ? 10 : 16 }}>{t('REPORT.TITLE.SUM')}</Typography>,
            class: 'text-nowrap'
          },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'total') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountPaid') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'dfNet') || 0), class: 'text-nowrap' },
          { option: 'MERGE', align: 'center', colSpan: 2 }
        ],
        classes: 'row-summary'
      }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    }
  }

  return (
    <>
      <Box>
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            rowHeadCell={2}
            tableMinWidth={992}
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headCells}
            rowsData={[
              _.map(props.data.rows, (d: any, index: number) => {
                return renderData(d, index, false, 'data')
              }),
              renderData(props.data.rows, 0, false, 'sum')
            ]}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
    </>
  )
}

export function DfLabExport(props: ExportProps) {
  return <>{props.componant}</>
}
