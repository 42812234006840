import { Box } from '@mui/material'
import { Dropdown } from 'react-bootstrap'

/** STYLE */
import { styled } from '@mui/material/styles'
import styleds from 'styled-components'

/** CONSTANTS */
import { colors } from 'constants/theme'

export const ResponsiveReportTable = styled(Box)<{ verticalAlign?: 'top' | 'middle' }>(({ theme, verticalAlign }) => ({
  '.MuiTable-root': {
    '.MuiTableHead-root': {
      '.MuiTableCell-head': {
        padding: '15px',
      }
    },
    '.MuiTableBody-root': {
      '.row-summary': {
        '.MuiTableCell-root': {
          backgroundColor: colors.lightGray,
          verticalAlign: 'middle !important',
          paddingTop: 10,
          paddingBottom: 10
        }
      },
      '.MuiTableCell-root': {
        paddingTop: verticalAlign === 'top' ? 16 : 10,
        paddingBottom: verticalAlign === 'top' ? 8 : 10,
        verticalAlign: `${verticalAlign} !important` || 'middle'
      }
    }
  }
}))

export const DropdownDocument = styled(Dropdown)(({ theme }) => ({
  '.dropdown-toggle': {
    backgroundColor: `${colors.white} !important`,
    color: `${colors.themeSecondColor} !important`,
    border: `1px solid ${colors.themeSecondColor} !important`,
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none !important',
    height: 44,
    '&:after': {
      marginLeft: '0.5rem'
    },
    '&[aria-expanded="true"]:after': {
      transform: 'rotate(180deg)'
    }
  },
  '.dropdown-menu': {
    marginBottom: 4,
    borderRadius: 4,
    background: colors.white,
    border: `1px solid ${colors.lightGray}`,
    '.dropdown-item': {
      '&:hover': {
        backgroundColor: colors.themeSecondColor06,
        color: colors.themeSecondColor
      }
    }
  }
}))

export const Styles = styleds.div`
  padding: 1px;

  table {
    border-spacing: 0;
    
    th,
    td {
      margin: 0;
      padding: 1px;
      border-bottom: 1px solid black;
      border-top: 1px solid black;
      text-align: center;
      :last-child {
        border-right: 0;
      }
    }
  } 
`

export const HeightAutocompleteSelect = styled('div')(({ theme }) => ({
  maxHeight: 32,
  position: 'relative',
  width: '100%',
  '.MuiOutlinedInput-root': {
    height: `32px !important`,
    padding: '0 !important',
    borderRadius: '4px 4px 4px 4px'
  },
  '.MuiOutlinedInput-input': {
    paddingLeft: '1rem !important',
    paddingRight: '2.25rem !important'
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderRadius: '0 4px 4px 0'
  },
}))

export const SpanClearFilter = styled('span')(({ theme }) => ({
  cursor: 'pointer',
  color: '#1cb99a !important',
  boxShadow: 'none !important'
}))