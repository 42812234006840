import TextField from '@mui/material/TextField'
import { colors } from 'constants/theme'
import { styled } from '@mui/material/styles'
import HelperText from 'component/Input/HelperText'
import { useEffect, useRef } from 'react'

const CustomTextField = styled(TextField)<{ inputHeight?: any }>(({ theme, inputHeight }) => ({
  width: '100%',
  borderRadius: 4,
  '.MuiOutlinedInput-root': {
    height: inputHeight || 43,
    '&:not(.Mui-disabled)': {
      backgroundColor: colors.white,
    },
    '.MuiOutlinedInput-notchedOutline, &.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: `${colors.black12}`
    },
    '&:hover': {
      borderColor: `${colors.black12}`,
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: `${colors.black12}`
      }
    },
    '&.Mui-focused': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: `${colors.themeSecondColor}`,
      }
    },
    '&.Mui-error': {
      '.MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${colors.statusInactive} !important`
      }
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px !important'
    }
  },
  '.MuiInputLabel-root, .MuiInputLabel-root span': {
    fontSize: 16,
    color: `${colors.black60} !important`,
    '&.MuiInputLabel-root': {
      paddingLeft: 1
    },
    backgroundColor: 'transparent !important',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 3,
      top: '50.5%',
      zIndex: -1
    },
    '&[data-shrink="true"]': {
      '&.Mui-focused': {
        color: `${colors.themeSecondColor} !important`
      },
      '&:before': {
        backgroundColor: colors.white
      },
      span: {
        color: `${colors.statusInactive} !important`
      }
    }
  },
  '.MuiOutlinedInput-input': {
    height: inputHeight || 43,
    fontSize: 16,
    color: `${colors.textPrimary} !important`,
    textOverflow: 'ellipsis',
    '-webkit-text-fill-color': 'unset !important',
    padding: '0 1rem',
    '&.Mui-disabled': {
      color: `${colors.black60} !important`
    },
    '&::placeholder': {
      opacity: 1,
      color: `${colors.black60} !important`
    }
  },
  '&.filed-error': {
    '.MuiInputLabel-root[data-shrink="true"], .MuiInputLabel-root[data-shrink="true"] span': {
      color: `${colors.statusInactive} !important`
    }
  },
  'input[type=number]': {
    MozAppearance: 'textfield',
    '-moz-appearance': 'textfield',
  },
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    marginLeft: '0.5rem'
  },
  '&.hide-control': {
    'input[type=number], input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      appearance: 'none',
      marginLeft: 0,
    }
  },
  '.MuiFormHelperText-root': {
    marginLeft: 0
  }
}))

type InputProps = {
  onchange?: (value: any) => void
  onkeyup?: (value: any) => void
  onkeypress?: (value: any) => void
  onblur?: (value: any) => void
  onfocus?: (value: any) => void
  required?: boolean
  disabled?: boolean
  variant?: any
  size?: 'medium' | 'small'
  label?: any
  value?: any
  type?: string
  helperText?: any
  key?: any | 'input-text'
  name?: any
  id?: any
  register?: any
  hookValue?: boolean
  hideControl?: boolean
  financeText?: boolean
  inputProps?: any
  InputLabelProps?: any
  inputTextAlign?: 'left' | 'center' | 'right'
  style?: any
  params?: any
  inputHeight?: any
  placeholder?: string
  disabledAutoComplete?: boolean
  autoSelect?: boolean
  autoSelectClick?: boolean
  className?: string
  error?: boolean
}

export default function InputTextField(props: InputProps) {
  const cusProps = props.hookValue ? { value: props.value } : { defaultValue: props.value }
  const shrink = props.value ? { shrink: true } : ''
  const inputRef = useRef<any>(null)

  useEffect(() => {
    if (props.autoSelect === true) {
      inputRef.current.select()
    }
  }, [props.autoSelect])

  const val = (v: any) => {
    if (props.financeText === true) v = `${parseFloat(v?.toFixed(2))}`
    return v
  }

  return (
    <CustomTextField
      inputRef={inputRef}
      inputHeight={props.inputHeight}
      id={props.id}
      key={`text-field-${props.key}`}
      sx={{
        '.MuiOutlinedInput-root': {
          backgroundColor: props.disabled ? colors.black12 : ''
        },
        ...props.style
      }}
      InputProps={{
        ...{ style: { textAlign: props.inputTextAlign || 'left' } },
        ...props.inputProps,
        autoComplete: props.disabledAutoComplete ? 'off' : 'on'
      }}
      InputLabelProps={{ ...props.InputLabelProps, key: props.key, ...shrink }}
      className={`${props.helperText ? 'filed-error' : ''} ${props.hideControl ? 'hide-control' : ''} ${props.className}`}
      financeText={props.financeText}
      onChange={props.onchange}
      onFocus={props.onfocus}
      onBlur={props.onblur}
      onKeyUp={props.onkeyup}
      onKeyPress={props.onkeypress}
      variant={props.variant || 'outlined'}
      required={props.required}
      name={props.name}
      {...(props.register ? props.register : cusProps)}
      {...(props.register ? { defaultValue: val(props.value), value: val(props.value) } : cusProps)}
      value={val(props.value)}
      label={props.label}
      disabled={props.disabled}
      type={props.type || 'text'}
      error={props.helperText !== '' && props.helperText !== undefined || props.error}
      {...props.params}
      size={props.size || 'small'}
      placeholder={props?.placeholder || ''}
      helperText={
        props.helperText ? (
          <HelperText label={props.helperText} />
        ) : (
          ''
        )
      }
    />
  )
}
