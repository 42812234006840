import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const formSlice = createSlice({
  name: 'form',
  initialState: {
    render: [null]
  },
  reducers: {
    appendElement: (state, action: PayloadAction<any>) => {
      state.render[action.payload.index] = action.payload.value
    },
    removeElement: (state, action: PayloadAction<any>) => {
      state.render[action.payload.index] = action.payload.value
    }
  }
})

export const { appendElement, removeElement } = formSlice.actions
export default formSlice.reducer
