import { Box, Typography, Avatar } from '@mui/material'
import moment from 'moment'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import LabelRanking from 'component/LabelRanking'

/** UTILS */
import { memberTypeOutline, toBuddhistYear } from 'utils/app.utils'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { imgs, icons } from 'constants/images'

/** STYLE */
import { styled } from '@mui/material/styles'

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 49,
  height: 49,
  [theme.breakpoints.up('lg')]: {
    width: 56,
    height: 56
  }
}))

type BtnProfileProps = {
  profile: string
  branchCnNumber: string
  firstName: string
  lastName: string
  displayName?: string
  rank: string
  lastVisit?: string
  vnNumber?: string
  disabled?: boolean
  hideArrow?: boolean
}

export default function BtnProfile(props: BtnProfileProps) {
  const { t } = useTranslation()

  const checkDisplayName = () => {
    return props.displayName ? (`(${props.displayName})`) : ``
  }
  return (
    <>
      <ProfileAvatar alt="profile" src={props.profile} sx={{ border: `3px solid ${props.disabled ? 'transparent' : memberTypeOutline(props.rank)}`, backgroundColor: colors.white }}>
        <img src={imgs.defaultAvatar} className="w-100 h-100" alt="defaultAvatar" />
      </ProfileAvatar>
      <Box className="ml-3 d-flex flex-column overflow-hidden" sx={{ flex: 1 }}>
        <Box className="d-flex flex-column flex-md-row">
          <Typography className="d-inline-block align-middle text-ellipsis" sx={{ fontSize: { sm: '18px' }, fontWeight: 600, color: colors.themeMainColor }}>
            {props.branchCnNumber ? `${props.branchCnNumber} | ` : ''}{props.firstName && props.lastName && (`${props.firstName} ${props.lastName} ${checkDisplayName()}` )|| (t('REGISTER.TITLE.FULL_NAME'))}
          </Typography>
          <Box className="d-inline-block ml-md-2">
            <LabelRanking disabled={props.disabled} rank={props.rank || 'RANKING'} />
          </Box>
        </Box>
        {props.lastVisit && (
          <Typography className="mt-auto" sx={{ fontSize: '14px', color: colors.themeMainColor }}>
            {t('TREATMENTS.TITLE.LAST_VISIT')} {props.lastVisit ? toBuddhistYear(moment(props.lastVisit), 'DD/MM/YYYY') : ''}
          </Typography>
        )}
        {props.vnNumber && (
          <Typography className="mt-auto" sx={{ fontSize: '14px', color: colors.disabledGray }}>
            {t('VN')} {props.vnNumber}
          </Typography>
        )}
      </Box>
      {!props.hideArrow && <img src={icons.arrowExpand} width={14} height={14} className={'ml-3'} alt="ArrowExpand" />}
    </>
  )
}
