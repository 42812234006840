import React, { useState, useEffect } from 'react'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** ICON || IMAGE */

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'

import { Box, styled, Tab } from '@mui/material'
import { colors } from 'constants/theme'
import { Col, Row } from 'react-bootstrap'

import ClinicApi from 'api/master/clinic.api'
import { fileUrl } from 'api/api'
import InputTextField from 'component/Input/InputTextField'
import ProductSymptomApi from 'api/setting/default-products/product-symptom'
import ProductInstructionsApi from 'api/setting/default-products/product-instruction.api'
import ProductUnitsApi from 'api/setting/default-products/product-unit.api'
import ProductUsesApi from 'api/setting/default-products/product-use.api'


/** STYLE */
const DivTemplatePopup = styled('div')(({ theme }) => ({
    minHeight: 500,
    '& .template-select': {
        '&.active': { border: `2px solid ${colors.themeSecondColor}` },
        '& img': { height: 150, width: 150, objectFit: 'cover', border: `1px solid ${colors.lightGray}` }
    }
}))

const CustomTab = styled(Tab)<{ tabForm: string; selectLabel: string }>(({ theme, tabForm, selectLabel }) => ({
    fontSize: '16px',
    color: tabForm === selectLabel ? `${colors.themeSecondColor} !important` : colors.black,
    position: 'relative',
    '.Mui-selected': { color: colors.themeSecondColor },
    '&.error': { color: colors.statusInactive, }
}))

const DivUploadImage = styled('div')(({ theme }) => ({
    margin: 'auto',
    maxWidth: 500,
    '& > div.MuiFormControl-root': {
        position: 'relative',
        paddingTop: '100%',
        '& > div': {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            padding: '0 !important',
            width: 'auto !important',
            height: 'auto !important',
            'img': {
                width: '100%',
                height: '100%',
                objectFit: 'contain'
            }
        }
    },
    [theme.breakpoints.down('xs')]: {
        maxWidth: 180,
    }
}))

/** INTERFACE */
interface TemplateDoctorNote {
    url: any
}
export interface PopupManageProductAddFormProps {
    isShow: boolean
    type: 'PRODUCT_SYMPTOM' | 'PRODUCT_USES' | 'PRODUCT_UNITS' | 'PRODUCT_INSTRUCTION'
    items: any
    onInsert?: (img: HTMLImageElement) => void
    onCancel?: () => void
    onSubmit: (data: any, type: any) => void
    maxLength?: any
}

export function PopupManageProductAddForm(props: PopupManageProductAddFormProps) {
    const { t } = useTranslation()

    const [templateDoctorNotes, setTemplateDoctorNotes] = useState<TemplateDoctorNote[]>([])
    const [name, setName] = useState<any>()
    const [nameEn, setNameEn] = useState<any>()
    const [errorName, setErrorName] = useState<any>()

    const [textLength, setTextLength] = useState<number>(0)
    const [textLengEn, setTextLengEn] = useState<number>(0)
    const [errorMaxLength, setErrorMaxLength] = useState<boolean>(false)

    const loadData = async () => {
        await ClinicApi.allTemplateDoctorNote().then((res: any) => {
            setTemplateDoctorNotes([...res.data.map((img: any, i: any) => ({ url: `${fileUrl}/${img.url}` }))])
        })
    }

    const replaceUrlToBase64 = () => {
        setTimeout(() => {
            _.map(templateDoctorNotes, async (templateDoctorNote: any, i: any) => {
                const editImg: any = document.getElementById(`template-IMAGE_${i}`)
                if (editImg)
                    toDataUrl(templateDoctorNote.url, (myBase64: any) => {
                        editImg.src = myBase64
                    })
            })
        }, 1000)
    }

    useEffect(() => { replaceUrlToBase64() }, [templateDoctorNotes])

    useEffect(() => {
        loadData()
    }, [props.isShow])

    const handleTitle: any = () => {
        if (props.type === 'PRODUCT_INSTRUCTION') {
            return `${t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.ADD_PRODUCT_INSTRUCTION')}`
        }
        if (props.type === 'PRODUCT_UNITS') {
            return `${t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.ADD_PRODUCT_UNIT')}`
        }
        if (props.type === 'PRODUCT_USES') {
            return `${t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.ADD_PRODUCT_USE')}`
        }
        if (props.type === 'PRODUCT_SYMPTOM') {
            return `${t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.ADD_SYMPTOM')}`
        }
    }

    const handleLabelTh: any = () => {
        if (props.type === 'PRODUCT_INSTRUCTION') {
            return `${t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PRODUCT_INSTRUCTION')}`
        }
        if (props.type === 'PRODUCT_UNITS') {
            return `${t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PRODUCT_UNIT')}`
        }
        if (props.type === 'PRODUCT_USES') {
            return `${t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PRODUCT_USE')}`
        }
        if (props.type === 'PRODUCT_SYMPTOM') {
            return `${t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.SYMPTOM')}`
        }
    }

    const handleLabelEn: any = () => {
        if (props.type === 'PRODUCT_INSTRUCTION') {
            return `${t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PRODUCT_INSTRUCTION_EN')}`
        }
        if (props.type === 'PRODUCT_UNITS') {
            return `${t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PRODUCT_UNIT_EN')}`
        }
        if (props.type === 'PRODUCT_USES') {
            return `${t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PRODUCT_USE_EN')}`
        }
        if (props.type === 'PRODUCT_SYMPTOM') {
            return `${t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.SYMPTOM_EN')}`
        }
    }
    const handleChangeName = (e: any) => {
        setErrorName('')
        const name = e.target.value === '' ? '' : String(e.target.value)
        const contentLength = name.length
        if (props.maxLength === 0) {
            setName(name)
            setTextLength(contentLength)
            setErrorMaxLength(false)
        } else if (contentLength > props.maxLength) {
            setErrorMaxLength(true)
        } else {
            setName(name)
            setTextLength(contentLength)
            setErrorMaxLength(false)
        }
    }
    const handleChangeNameEn = (e: any) => {
        const nameEn = e.target.value === '' ? '' : String(e.target.value)
        const contentLength = nameEn.length
        if (props.maxLength === 0) {
            setNameEn(nameEn)
            setTextLengEn(contentLength)
            setErrorMaxLength(false)
        } else if (contentLength > props.maxLength) {
            setErrorMaxLength(true)
        } else {
            setNameEn(nameEn)
            setTextLengEn(contentLength)
            setErrorMaxLength(false)
        }
    }

    const toDataUrl = async (url: any, callback: any) => {
        const xhr = new XMLHttpRequest()

        xhr.onload = function () {
            const reader = new FileReader()
            reader.onloadend = function () {
                callback(reader.result)
            }
            reader.readAsDataURL(xhr.response)
        }
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
    }


    const submit = async () => {
        if (!name) return setErrorName(t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.EMPTY_NAME'))
        if (props.type === 'PRODUCT_INSTRUCTION') {
            await ProductInstructionsApi
                .create({ group: 'MEDICINE', productInstructionName: name, productInstructionNameEn: nameEn })
                .then(({ data }) => {
                    setName('')
                    setNameEn('')
                    props.onSubmit(data, props.type)
                })
                .catch((e) => {
                    const err = e.response.data
                    console.log(err)
                    return setErrorName(t(`TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.${err.message}`))
                })
        }
        if (props.type === 'PRODUCT_UNITS') {
            await ProductUnitsApi
                .create({ group: 'MEDICINE', productUnitName: name, productUnitNameEn: nameEn })
                .then(({ data }) => {
                    setName('')
                    setNameEn('')
                    props.onSubmit(data, props.type)
                })
                .catch((e) => {
                    const err = e.response.data
                    console.log(err)
                    return setErrorName(t(`TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.${err.message}`))
                })
        }
        if (props.type === 'PRODUCT_USES') {
            await ProductUsesApi
                .create({ group: 'MEDICINE', productUseDetail: name, productUseDetailEn: nameEn })
                .then(({ data }) => {
                    setName('')
                    setNameEn('')
                    props.onSubmit(data, props.type)
                })
                .catch((e) => {
                    const err = e.response.data
                    console.log(err)
                    return setErrorName(t(`TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.${err.message}`))
                })
        }
        if (props.type === 'PRODUCT_SYMPTOM') {
            await ProductSymptomApi
                .create({ group: 'MEDICINE', detail: name, detailEn: nameEn })
                .then(({ data }) => {
                    setName('')
                    setNameEn('')
                    props.onSubmit(data, props.type)
                })
                .catch((e) => {
                    const err = e.response.data
                    console.log(err)
                    return setErrorName(t(`TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.${err.message}`))
                })
        }


    }
    return props.isShow ? (
        <ModalCustom
            size={'lg'}
            title={handleTitle()}
            alignFooter={'center'}
            fullscreen={'sm-down'}
            component={
                <Col xl={12} xs={12} style={{}}>
                    <Row style={{ marginTop: props.maxLength > 0 ? '10px' : '30px' }}>
                        {props.maxLength > 0 ? <Box sx={{ fontWeight: 400, fontSize: '10px', color: '#808080', textAlign: 'end', marginLeft: '10px' }}>{`${textLength}/${props.maxLength}`}</Box> : <></>}
                        <InputTextField
                            placeholder={''}
                            required
                            helperText={errorName}
                            label={handleLabelTh()}
                            value={name}
                            key={``}
                            onchange={handleChangeName}
                            inputTextAlign={'left'}
                            inputProps={{ maxLength: props.maxLength > 0 ? props.maxLength : 1000 }}
                        />
                    </Row>
                    <Row style={{ marginTop: props.maxLength > 0 ? '10px' : '30px' }}>
                        {props.maxLength > 0 ? <Box sx={{ fontWeight: 400, fontSize: '10px', color: '#808080', textAlign: 'end', marginLeft: '10px' }}>{`${textLengEn}/${props.maxLength}`}</Box> : <></>}
                        <InputTextField
                            placeholder={''}
                            label={handleLabelEn()}
                            value={nameEn}
                            key={``}
                            onchange={handleChangeNameEn}
                            inputTextAlign={'left'}
                            inputProps={{ maxLength: props.maxLength > 0 ? props.maxLength : 1000 }}
                        />
                    </Row>
                </Col>
            }
            onSubmit={submit}
            onReset={props.onCancel}
            autoResetModal={false}
            textBtnCancel={t('BUTTON.CANCEL')}
            textBtnConfirm={t('BUTTON.SAVE')}
        />
    ) : (
        <></>
    )
}
