import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export default class PatientManagementApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('managetment-patients', { params: { ...props } }).then((res) => res)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`managetment-patients/${id}/status`, { id, status })
  }

  static updateStatusByCheckbox(id: Array<number>, status: string) {
    return this.api.patch('managetment-patients/update-status', { patientId: id, status: status })
  }
}
