import Pagination from '@mui/material/Pagination'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { numberFormat } from 'utils/app.utils'

/** STYLE */
import { styled } from '@mui/material/styles'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

const CustomPagination = styled(Pagination)(({ theme }) => ({
  li: { '&:last-of-type': { '.MuiPaginationItem-root': { marginRight: 0 } } },
  '.MuiPaginationItem-root': {
    borderColor: colors.themeSecondColor,
    color: colors.themeSecondColor,
    margin: '0 2px',
    '&.Mui-selected, &:hover': {
      backgroundColor: `${colors.themeSecondColor} !important`,
      color: colors.white
    },
    '&.Mui-disabled': {
      opacity: 0.7
    }
  }
}))
const LabelRows = styled('span')(({ theme }) => ({ color: colors.textLightGray, fontSize: 14, marginRight: '0.375rem' }))

type DefaultPaginationProps = {
  alignment?: 'start' | 'center' | 'end'
  count: number
  pageLimit?: number
  page?: number
  allRow?: number
  start?: number
  to?: number
  setPage?: (val: any) => void
}

export default function DefaultPagination(props: DefaultPaginationProps) {
  const { t } = useTranslation()

  const defaultLabelDisplayedRows = () => {
    const _page: number = props.page || 1
    const _pageLimit: number = props.pageLimit || 1
    const _row: number = props.allRow || props.count || 1
    const from = _page * _pageLimit - _pageLimit + 1
    const to = _page * _pageLimit
    const count = Number(_row)
    return `${numberFormat(from, 0, 0)}-${count <= to ? numberFormat(count, 0, 0) : numberFormat(to, 0, 0)} ${t('PAGINATION.LIST')} ${t('PAGINATION.FROM')} ${numberFormat(count, 0, 0)} ${t('PAGINATION.LIST')}`
  }

  return (
    <div className={`d-flex align-items-center justify-content-${props.alignment || 'end'}`}>
      <LabelRows>
        {defaultLabelDisplayedRows()}
      </LabelRows>
      <CustomPagination count={props.pageLimit ? Math.ceil(props.count / props.pageLimit) : props.count} page={props.page || 1} onChange={(e, p) => props.setPage && props.setPage(p)} variant="outlined" shape="rounded" size="small" />
    </div>
  )
}
