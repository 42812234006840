import { colors } from "constants/theme"

export interface IconProp {
  fill?: string
  stroke?: string
  width?: string
  height?: string
}

export default function StarActive(props: IconProp) {
  return (
    <svg xmlns="https://www.w3.org/2000/svg" width={props.width || '20.975'} height={props.height || '20'} viewBox="0 0 20.975 20">
      <g data-name="Group 10977">
        <path
          data-name="Path 6632"
          d="m-18074-23587.971 2.559-6.291 3.117 6.8 6.52.461-5.215 4.641 1.645 6.406-6.066-3.047-6.064 3.047 1.586-6.406-5.441-4.641z"
          transform="translate(18082 23595)"
          style={{ stroke: props?.stroke || props.fill || colors.themeSecondColor, strokeLinejoin: 'round', fill: props.fill || colors.themeSecondColor }}
        />
        <path
          d="M17.877 22.4a1.405 1.405 0 0 1-.785-.241L12.37 19a.139.139 0 0 0-.158 0L7.49 22.162a1.405 1.405 0 0 1-2.14-1.526l1.55-5.468a.146.146 0 0 0-.051-.152L2.382 11.5A1.405 1.405 0 0 1 3.2 8.971l5.7-.215a.146.146 0 0 0 .127-.095l1.962-5.336a1.405 1.405 0 0 1 2.639 0l1.962 5.336a.146.146 0 0 0 .127.095l5.7.215a1.405 1.405 0 0 1 .817 2.532l-4.469 3.513a.139.139 0 0 0-.051.152l1.551 5.469a1.411 1.411 0 0 1-1.38 1.766zm-5.583-4.7a1.4 1.4 0 0 1 .779.266l4.722 3.165a.139.139 0 0 0 .215-.158l-1.551-5.469a1.4 1.4 0 0 1 .487-1.487l4.469-3.513a.139.139 0 0 0-.082-.253l-5.7-.215a1.405 1.405 0 0 1-1.266-.924l-1.959-5.331a.139.139 0 0 0-.266 0L10.2 9.11a1.405 1.405 0 0 1-1.266.924l-5.7.215a.139.139 0 0 0-.082.253l4.475 3.519a1.405 1.405 0 0 1 .487 1.487l-1.542 5.47a.133.133 0 0 0 .051.152.127.127 0 0 0 .165 0l4.722-3.165a1.405 1.405 0 0 1 .779-.234z"
          transform="translate(-1.816 -2.402)"
          style={{ fill: props.fill || colors.themeSecondColor }}
        />
      </g>
    </svg>
  )
}
