import DoctorAppointment from 'features/counter/appointment/appointment'
import { menuSeconds } from 'constants/menus'

const routeAppointment = [
  {
    ...menuSeconds.APPOINTMENT,
    component: DoctorAppointment,
    appBar: true
  }
]

export default routeAppointment
