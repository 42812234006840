import BaseAPI from 'api/api'

const register = `register`
const home = `home`
const appointments = `appointments`

export interface Treatments {
  operativeTypeId?: number
  operativeId?: number
}

export interface SendQueue {
  servicePointId: number
  importantSymptoms: string
  specialInfo: string
  appointmentId?: number
  message?: string
  statusAppointment?: string
  remark?: string
  remarkCancel?: string
  treatments?: Treatments[]
  sorting?: number
}

export interface StatusAppointment {
  statusAppointment: string
  remarkCancel?: string
  remark?: string
}

export interface DetailAppointment {
  importantSymptoms: string
  specialInfo: string
  treatments?: Treatments[]
}

export default class SendExamination extends BaseAPI {
  static registerOpenVisit(patientId: number): Promise<any> {
    return this.api.post(`${register}/open-visit`, { patientId: patientId }).then((res) => res)
  }

  static registerHistoryQueue(patientId: number): Promise<any> {
    return this.api.get(`${register}/history-queue/${patientId}`).then((res) => res)
  }

  static registerSendQueue(payload: SendQueue): Promise<any> {
    return this.api.post(`${register}/send-queue`, payload).then((res) => res)
  }

  static homeSendQueue(patientCaseId: number, payload: SendQueue): Promise<any> {
    return this.api.post(`${home}/send-queue/${patientCaseId}`, payload).then((res) => res)
  }

  static homeHistoryQueue(patientId: number): Promise<any> {
    return this.api.get(`${home}/history-queue/${patientId}`).then((res) => res)
  }

  static homeOpenVisit(appointmentId: number): Promise<any> {
    return this.api.post(`${home}/open-visit/${appointmentId}`).then((res) => res)
  }

  static updateStatus(appointmentId: number, payload: StatusAppointment): Promise<any> {
    return this.api.patch(`${appointments}/${appointmentId}/appointment-status`, payload).then((res) => res)
  }
  static updateDetail(appointmentId: number, payload: DetailAppointment): Promise<any> {
    return this.api.patch(`${appointments}/${appointmentId}/appointment-detail`, payload).then((res) => res)
  }
}
