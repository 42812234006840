import { useState, useEffect } from 'react'
import { Box, Typography, Button, TableRow, TableCell, IconButton } from '@mui/material'
import _ from 'lodash'
import { useForm, useFieldArray } from 'react-hook-form'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import AppointmentApi, { MessageTemplate } from 'api/counter/appointment.api'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { icons } from 'constants/images'

/** COMPONENT */

import TableCustom from 'component/Table/TableCustom'
import InputTextField from 'component/Input/InputTextField'
import { notiPopup } from 'component/notifications/notifications'
import { ToastContainer } from 'react-toastify'

/** STYLE */
import { styled } from '@mui/material/styles'
import { CloseButton, Modal } from 'react-bootstrap'


const FragmentTabs = styled(Box)(({ theme }) => ({
    borderBottom: `1px solid ${colors.white}`
}))

const ButtonTab = styled(Button)(({ theme }) => ({
    width: '50%',
    height: 40,
    color: colors.textPrimary,
    padding: 0,
    borderBottom: `1px solid transparent`,
    borderRadius: 0,
    '&.active': {
        color: colors.themeSecondColor,
        borderColor: colors.themeSecondColor
    },
    [theme.breakpoints.up('sm')]: {
        maxWidth: 185
    }
}))

const FragmentMessage = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        maxWidth: '50%'
    }
}))

const ResponsiveTable = styled('div')(({ theme }) => ({
    maxHeight: '100%',
    '.MuiPaper-root': {
        minHeight: 'unset !important',
        maxHeight: '100%'
    },
    '.MuiTable-root': {
        minWidth: 'unset',
        width: '100%',
        tableLayout: 'fixed',
        '.MuiTableHead-root': {
            border: 'none !important',
            '.MuiTableCell-root': {
                fontWeight: 400,
                padding: 12,
                position: 'sticky',
                top: 0,
                zIndex: 5
            },
            '.MuiTableCell-root:nth-of-type(1)': {
                width: 50,
                borderTopLeftRadius: 4
            },
            '.MuiTableCell-root:nth-of-type(2)': {},
            '.MuiTableCell-root:nth-of-type(3)': {
                width: 80,
                textAlign: 'center',
                borderTopRightRadius: 4
            }
        },
        '.MuiTableBody-root': {
            position: 'relative',
            zIndex: 0,
            '.MuiTableCell-root': {
                fontWeight: 400,
                padding: 10,
                backgroundColor: colors.white,
                verticalAlign: 'top'
            },
            '.MuiOutlinedInput-root, .MuiOutlinedInput-root input': {
                height: 35
            },
            '.new-field': {
                '.MuiTableCell-root:not(:nth-of-type(2))': {
                    paddingTop: 16
                }
            }
        }
    },
    '.MuiTableFooter-root': {
        display: 'none'
    },
    '.row-add-new-message': {
        '.btn-add-new-message': {
            padding: '0 0 0 14px',
            fontSize: 16,
            color: colors.themeSecondColor,
            backgroundColor: 'transparent !important',
            fontWeight: 400,
            span: {
                fontSize: 20,
                color: colors.themeSecondColor
            },
            '&:hover': {
                fontWeight: 600
            }
        },
        '.MuiTableCell-root:hover': {
            cursor: 'pointer',
            '.btn-add-new-message': {
                fontWeight: 600
            }
        }
    }
}))

export const ModalStyle = styled(Modal)(({ theme }) => ({
    zIndex: 2015,
    paddingLeft: '0 !important',
    '.modal-dialog': {
        maxWidth: 1152,
        [theme.breakpoints.up('xs')]: {
            height: 'calc(100% - 3.5rem)'
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            margin: '1.75rem'
        },
        [theme.breakpoints.between('sm', 'lg')]: {
            maxWidth: 712
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: 'calc(100% - 2rem)',
            height: 'calc(100% - 2rem)'
        }
    },
    '.modal-content': {
        height: '100%',
        maxHeight: '100%',
        padding: '1rem 0 1.5rem',
        borderRadius: 10,
        border: 'none !important',
        backdropFilter: 'saturate(1) blur(40px)',
        backgroundColor: colors.white90
    }
}))

export const Header = styled(Modal.Header)(({ theme }) => ({
    border: 'none !important',
    padding: 0,
    textAlign: 'center',
    margin: 'auto',
    p: {
        fontSize: 20,
        fontWeight: 600,
        color: colors.themeMainColor
    }
}))

export const Body = styled(Modal.Body)(({ theme }) => ({
    padding: '1rem 1rem 1.5rem',
    color: colors.textPrimary,
    overflow: 'hidden'
}))

export const Footer = styled(Modal.Footer)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
        flexWrap: 'nowrap'
    }
}))

export const BtnCancel = styled(Button)(({ theme }) => ({
    width: 'max-content',
    height: 44,
    fontSize: 16,
    color: `${colors.themeSecondColor} !important `,
    border: `solid 1px ${colors.themeSecondColor}  !important`,
    padding: '0 16px',
    '&.btn-default': {
        color: `${colors.textPrimary} !important `,
        border: `solid 1px ${colors.textExtraLightGray}  !important`
    },
    [theme.breakpoints.down('sm')]: {
        width: '50%',
        marginRight: '0.25rem'
    },
    [theme.breakpoints.up('sm')]: {
        marginRight: '.5rem'
    }
}))

export const BtnConfirm = styled(Button)(({ theme }) => ({
    width: 'max-content',
    height: 44,
    fontSize: 16,
    backgroundColor: `${colors.themeSecondColor} !important`,
    color: `${colors.white} !important`,
    padding: '0 16px',
    boxShadow: 'none !important',
    '&:hover': {
        backgroundColor: `${colors.themeSecondColor} !important`,
        color: `${colors.white} !important`
    },
    '&:disabled': {
        backgroundColor: `${colors.disabledGray} !important`
    },
    [theme.breakpoints.down('sm')]: {
        width: '50%',
        marginLeft: '0.25rem'
    },
    [theme.breakpoints.up('sm')]: {
        marginLeft: '0.5rem'
    }
}))

type BranchMessageProps = {
    messages: MessageTemplate[]
    addMessage?: (msg: string) => void
}

type UserMessageProps = {
    messages: MessageTemplate[]
    addMessage?: (msg: string) => void
    setTemplateUserMessage?: (message: MessageTemplate[]) => void
}

export interface IPopupMessage {
    type: string
    isShow: boolean | false
    onReset?: () => void
    setIsShow: (show: boolean) => void
    setText: (text: any) => void
}

export default function PopupMessageAppointMent(props: IPopupMessage) {
    // const dispatch = useDispatch()
    const { t } = useTranslation()

    //   const treatments: TreatmentState = useSelector(treatment)
    const tabs: string[] = ['TREATMENT_SUMMARY.POPUP.TREATMENT_MESSAGE.DEFAULT_MESSAGE', 'TREATMENT_SUMMARY.POPUP.TREATMENT_MESSAGE.USER_MESSAGE']
    const [activeTab, setActiveTab] = useState(0)

    const [branchMessage, setBranchMessage] = useState<MessageTemplate[]>([])
    const [userMessage, setUserMessage] = useState<MessageTemplate[]>([])
    const [userTemplateMessage, setUserTemplateMessage] = useState<MessageTemplate[]>([])
    const loadTemplateMessageBranch = () => {
        if (props.type === 'PRATICE') {
            AppointmentApi.templateMessagePraticeBranch()
                .then(({ data }) => {
                    setBranchMessage(data)
                })
                .catch(() => setBranchMessage([]))
        } else if (props.type === 'APP') {
            AppointmentApi.templateMessageAppBranch()
                .then(({ data }) => {
                    setBranchMessage(data)
                })
                .catch(() => setBranchMessage([]))
        } else {
            AppointmentApi.templateMessageRemarkBranch()
                .then(({ data }) => {
                    setBranchMessage(data)
                })
                .catch(() => setBranchMessage([]))
        }

    }

    const loadTemplateMessageUser = () => {
        if (props.type === 'PRATICE') {
            AppointmentApi.templateMessagePraticeUser()
                .then(({ data }) => {
                    setUserMessage(data)
                })
                .catch(() => setUserMessage([]))
        } else if (props.type === 'APP') {
            AppointmentApi.templateMessageAppUser()
                .then(({ data }) => {
                    setUserMessage(data)
                })
                .catch(() => setUserMessage([]))
        } else {
            AppointmentApi.templateMessageRemarkUser()
                .then(({ data }) => {
                    setUserMessage(data)
                })
                .catch(() => setUserMessage([]))
        }

    }

    const handleAddMessage = (message: string) => {
        if (props.setText) props.setText(message)
    }

    const handleSubmit = () => {
        notiPopup('clear')
        let template: MessageTemplate[] = []
        _.map(userTemplateMessage, (msg: MessageTemplate, index) => {
            if (msg.message) return (template = [...template, msg])
        })
        if (props.type === 'PRATICE') {
            AppointmentApi.newTemplateMessagePratice(template)
                .then(() => {
                    notiPopup('success', t(`HOME.LINE_MESSAGE.MESSAGE.SUCCESS.USER_MESSAGE`))
                    loadTemplateMessageUser()
                })
                .catch(() => { return })
        } else {
            AppointmentApi.newTemplateMessageRemark(template)
                .then(() => {
                    notiPopup('success', t(`HOME.LINE_MESSAGE.MESSAGE.SUCCESS.USER_MESSAGE`))
                    loadTemplateMessageUser()
                })
                .catch(() => { return })
        }

    }

    useEffect(() => {
        if (props.type) {
            new Promise<void>((resolve) => resolve())
                .then(loadTemplateMessageBranch)
                .then(loadTemplateMessageUser)
        }
    }, [props.type])

    const onClose = () => {
        props.setIsShow(false)
    }

    return (
        <>
            <ModalStyle returnFocusAfterClose={false} size={'xl'} show={props.isShow} aria-labelledby="contained-modal-title-vcenter" centered style={{ background: '#00000047' }}>
                <Header>
                    <Typography>{t(`APPOINTMENT.POPUP.TITLE.${props.type}`)}</Typography>
                    <CloseButton
                        style={{ position: 'absolute', top: 20, right: 20 }}
                        onClick={() => {
                            props.setIsShow(false)
                        }}
                    />
                </Header>
                <Body>
                    <Box className="pt-1 d-flex flex-column overflow-hidden" sx={{ '*': { color: colors.textPrimary }, maxHeight: '100%' }}>
                        <FragmentTabs className="d-xl-none">
                            {_.map(tabs, (tab: string, indexTab: number) => {
                                return (
                                    <ButtonTab className={`${indexTab === activeTab ? 'active' : ''}`} onClick={() => setActiveTab(indexTab)}>
                                        {t(tab)}
                                    </ButtonTab>
                                )
                            })}
                        </FragmentTabs>
                        <Box className="d-flex overflow-hidden">
                            <FragmentMessage className={`w-100 ${activeTab === 0 ? 'd-flex' : 'd-none'} d-xl-flex flex-column pr-xl-2`}>
                                <Typography className="d-none d-xl-block mb-1" sx={{ fontSize: 18, fontWeight: 500 }}>
                                    {t('APPOINTMENT.POPUP.APPOINTMENT_MESSAGE.DEFAULT_MESSAGE')}
                                </Typography>
                                <BranchMessage messages={branchMessage} addMessage={(msg: string) => handleAddMessage(msg)} />
                            </FragmentMessage>

                            <FragmentMessage className={`w-100 ${activeTab === 1 ? 'd-flex' : 'd-none'} d-xl-flex flex-column pl-xl-2`}>
                                <Typography className="d-none d-xl-block mb-1" sx={{ fontSize: 18, fontWeight: 500 }}>
                                    {t('APPOINTMENT.POPUP.APPOINTMENT_MESSAGE.USER_MESSAGE')}
                                </Typography>
                                <ToastContainer containerId={'popup'} className={'Toast-popup'} />
                                <UserMessage
                                    messages={userMessage}
                                    addMessage={(msg: string) => handleAddMessage(msg)}
                                    setTemplateUserMessage={(messages: MessageTemplate[]) => {
                                        if (!_.isEmpty(messages)) setUserTemplateMessage(messages)
                                        else setUserTemplateMessage([])
                                    }}
                                />
                            </FragmentMessage>
                        </Box>
                    </Box>
                </Body>
                <Footer className="flex-right text-right border-0 mt-4 mx-4">
                    <BtnCancel fullWidth onClick={onClose}>
                        {t('BUTTON.CANCEL')}
                    </BtnCancel>
                    <BtnConfirm fullWidth className={'btn-submit'} onClick={handleSubmit}>
                        {t('BUTTON.SAVE')}
                    </BtnConfirm>
                </Footer>
            </ModalStyle>
        </>

    )
}

function BranchMessage(props: BranchMessageProps) {
    const { t } = useTranslation()

    const handleAddMessage = (message: string) => {
        if (props.addMessage) props.addMessage(message)
    }

    const headCells = [
        { id: 'no', disablePadding: false, label: '#' },
        { id: 'message', disablePadding: false, label: t('HOME.LINE_MESSAGE.TABLE.MESSAGE') },
        { id: 'message', disablePadding: false, label: t('HOME.LINE_MESSAGE.TABLE.ACTION') }
    ]

    const renderData = (data: MessageTemplate, no: number) => {
        return (
            <TableRow key={`BranchMessage-${no}`}>
                <TableCell align="center" scope="row">
                    {props.messages?.length ? props.messages?.length - no : 0}
                </TableCell>
                <TableCell scope="row">{data.message}</TableCell>
                <TableCell align="center" scope="row">
                    <IconButton aria-label="add" onClick={() => handleAddMessage(data.message)} sx={{ backgroundColor: 'transparent !important', padding: 0 }}>
                        <img src={icons.btnAdd} alt="add" style={{ width: 24 }} />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <ResponsiveTable className={'mt-2 overflow-hidden'}>
            <TableCustom
                customScroll
                hidePagination
                page={1}
                pageLimit={0}
                sortType={'ASC'}
                sortBy={''}
                rowCount={0}
                onSort={() => {
                    return
                }}
                setPageLimit={() => {
                    return
                }}
                setPage={() => {
                    return
                }}
                headCells={headCells}
                rowsData={_.map(props.messages, (message: MessageTemplate, indexBranch) => {
                    return renderData(message, indexBranch)
                })}
            />
        </ResponsiveTable>
    )
}

function UserMessage(props: UserMessageProps) {
    const { t } = useTranslation()
    const [error, setError] = useState('')

    const { register, control, getValues } = useForm()

    const formMessage = useFieldArray({
        control,
        name: 'message'
    })

    const handleAddMessage = (message: string) => {
        if (props.addMessage) props.addMessage(message)
    }

    useEffect(() => {
        if (!_.isEmpty(props.messages)) {
            formMessage.replace([...props.messages])
            if (props.setTemplateUserMessage) props.setTemplateUserMessage([...props.messages])
        }
    }, [props.messages])

    const headCells = [
        { id: 'no', disablePadding: false, label: '#' },
        { id: 'message', disablePadding: false, label: t('HOME.LINE_MESSAGE.TABLE.MESSAGE') },
        { id: 'message', disablePadding: false, label: t('HOME.LINE_MESSAGE.TABLE.ACTION') }
    ]

    const addNewMessage = () => {
        return (
            <TableRow className="row-add-new-message">
                <TableCell
                    align="left"
                    scope="row"
                    colSpan={3}
                    onClick={() => {
                        if (_.some(getValues('message'), (msg, index) => !msg.message)) {
                            return setError(t('VALIDATION.ENTER_MESSAGE'))
                        } else formMessage.prepend({ templateTextId: null, message: '' })
                    }}
                >
                    <Button variant="text" className="btn-add-new-message d-flex align-items-baseline">
                        <span className={'mr-1'}>+</span> {t('HOME.LINE_MESSAGE.BUTTON.ADD_MESSAGE')}
                    </Button>
                </TableCell>
            </TableRow>
        )
    }

    const renderData = (data: MessageTemplate, no: number) => {
        return (
            <TableRow key={`UserMessage-${no}`} className={`${!getValues(`message.${no}.templateTextId`) ? 'new-field' : ''}`}>
                <TableCell align="center" scope="row">
                    {formMessage.fields.length - no}
                </TableCell>
                <TableCell scope="row">
                    {(!getValues(`message.${no}.templateTextId`) && (
                        <InputTextField
                            inputProps={{ maxLength: 255 }}
                            name="message"
                            key={`message.${no}.templateTextId`}
                            register={register(`message.${no}.message`, {
                                onChange: () => {
                                    if (props.setTemplateUserMessage) props.setTemplateUserMessage(getValues('message'))
                                    setError('')
                                }
                            })}
                            helperText={!getValues(`message.${no}.message`) ? error : ''}
                        />
                    )) ||
                        data.message}
                </TableCell>
                <TableCell align="center" scope="row">
                    <IconButton className="mr-1" aria-label="add" onClick={() => handleAddMessage(getValues(`message.${no}.message`))} sx={{ backgroundColor: 'transparent !important', padding: 0 }}>
                        <img src={icons.btnAdd} alt="add" style={{ width: 24 }} />
                    </IconButton>
                    <IconButton
                        className="ml-1"
                        aria-label="trash"
                        onClick={() => {
                            formMessage.remove(no)
                            if (props.setTemplateUserMessage) props.setTemplateUserMessage(getValues('message'))
                            setError('')
                        }}
                        sx={{ backgroundColor: 'transparent !important', padding: 0 }}>
                        <img src={icons.btnTrash} alt="trash" style={{ width: 24 }} />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <ResponsiveTable className={'mt-2 overflow-hidden'}>
            <TableCustom
                customScroll
                hidePagination
                page={1}
                pageLimit={0}
                sortType={'ASC'}
                sortBy={''}
                rowCount={0}
                onSort={() => {
                    return
                }}
                setPageLimit={() => {
                    return
                }}
                setPage={() => {
                    return
                }}
                headCells={headCells}
                rowsData={[
                    addNewMessage(),
                    _.map(formMessage.fields, (message: MessageTemplate, indexUser) => {
                        return renderData(message, indexUser)
                    })
                ]}
            />
        </ResponsiveTable>
    )
}
