import BaseAPI from 'api/api'

export interface LabOrderDetailInterface {
    labOrderDetailId: number,
    labOrderId: number
    labGroupId: number
    labTypeId: number
    labTypeName: string
    labId: number
    labName: string
    inputSupport: string | 'SUPPORT' | 'NOT_SUPPORT',
    labText: string
    createdBy: string
    createdAt: string
    updatedAt: string
}
export interface LabOrderInterface {
    labOrderId: number
    labOrderNumber: string
    patientId: number
    patientCaseId: number
    vnNumber: string
    dateOrder: string
    timeOrder: string
    userId: number
    labCompanyId: number
    labCompanyName: string | null
    dateExport: string
    dateImport: string
    dateReceive: string | null
    dateReceiveBill: string | null
    dateBill: string | null
    tempOrderTreatmentId: number
    orderTreatmentId: number
    labGroupId: number
    labOrderPrice: number
    labDoctorAmount: number
    labClinicAmount: number
    labUnit: "PERCENT"
    labOrderStatus: "ORDER_LAB"
    remark: string
    dateReply: string | null
    replyId: string | null
    replyCode: string | null
    replyBy: string | null
    replyRemark: string | null
    cancelRemark: string | null
    cancelCode: string | null
    cancelBy: string | null
    status: string | "ACTIVE" | "INACTIVE"
    updatedBy: string
    updatedAt: string
    createdBy: string
    createdAt: string
    labOrderDetails?: LabOrderDetailInterface[]
}
export interface LabOrderListInterface {
    labOrderId: number
    labOrderNumber: string
    patientId: number
    cnNumber: string
    branchCnNumber: string
    snCode: string
    patientCaseId: number
    dateOrder: string
    timeOrder: string
    userId: number
    labCompanyId: number
    labCompanyName: string
    dateExport: string
    dateImport: string
    dateReceive: null,
    dateReceiveBill: null,
    dateBill: null,
    tempOrderTreatmentId: number
    orderTreatmentId: number
    labGroupId: number
    labOrderPrice: number
    labDoctorAmount: number
    labClinicAmount: number
    labUnit: "PERCENT",
    labOrderStatus: string | 'ORDER_LAB' | 'SEND_LAB' | 'RECEIVE_LAB' | 'RECEIVE_BILL' | 'REPLY_LAB' | 'CANCEL_LAB' | 'CLOSE_LAB'
    remark: string
    dateReply: string | null,
    replyId: number | null,
    replyCode: string | null,
    replyBy: string | null,
    replyRemark: string | null,
    cancelRemark: string | null,
    cancelCode: string | null,
    cancelBy: string | null,
    status: "ACTIVE",
    updatedBy: string
    updatedAt: string
    createdBy: string
    createdAt: string
    patientFullName: string
    doctorFirstName?: string
    doctorFullName?: string
    doctorLastName?: string
    doctorPrefix?:string
}
export interface LabOrderAllDetailInterface extends LabOrderListInterface {
    vnNumber: number
    doctorFullname: string
    operativeName: string
    labGroupName: string
}

export interface PayloadLabOrderDetails {
    labOrderDetailId?: number,
    labTypeId: number,
    labId: number,
    inputSupport: string | 'SUPPORT' | 'NOT_SUPPORT',
    labText: string,
}
export interface PlayloadLabOrder {
    patientCaseId: number,
    dateOrder: string,
    timeOrder: string,
    userId: number,
    labCompanyId: number,
    dateExport: string,
    dateImport: string,
    tempOrderTreatmentId?: number | null,
    orderTreatmentId?: number | null,
    labGroupId: number,
    remark: string,
    labOrderDetails?: PayloadLabOrderDetails[]
}
export interface PlayloadReceive {
    dateReceive: string
    dateBill: string
    labOrderPrice: number
}

export interface AllLabByPatientType {
    patientId: number
    page: number
    pageLimit: number
    sortType: string
    sortBy: string
}

const path = 'lab-orders'

export default class LabOrderApi extends BaseAPI {

    static findAll(props: any): Promise<any> {
        return this.api.get(`${path}`, { params: { ...props } }).then((res) => res)
    }
    static findAllByPatientId(id: number, props: any): Promise<any> {
        return this.api.get(`${path}/patient/${id}`, { params: { ...props } }).then((res) => res)
    }
    static findById(id: number): Promise<any> {
        return this.api.get(`${path}/${id}`)
    }
    static findVisitHasOperative(id: number): Promise<any> {
        return this.api.get(`${path}/visit/${id}`)
    }
    static findTreatmentVisit(patientCaseId: number): Promise<any> {
        return this.api.get(`${path}/treatment-visit/${patientCaseId}`)
    }

    static queue(): Promise<any> {
        return this.api.get(`${path}/queue`).then((res) => res)
    }

    static getByPatientCase(patientCaseId: number, props: any): Promise<any> {
        return this.api.get(`${path}/patient-case/${patientCaseId}`, { params: { ...props } }).then((res) => res)
    }

    static create(payload: PlayloadLabOrder): Promise<any> {
        return this.api.post(`${path}`, payload)
    }

    static update(labOrderId: number, payload: PlayloadLabOrder): Promise<any> {
        return this.api.patch(`${path}/${labOrderId}`, payload)
    }

    static remove(id: number) {
        return this.api.delete(`${path}/${id}`)
    }

    static reset(id: number): Promise<any> {
        return this.api.patch(`${path}/${id}/reset`)
    }

    static reply(id: number, payload: any): Promise<any> {
        return this.api.patch(`${path}/${id}/reply`, { ...payload })
    }

    static cancel(id: number, payload: any): Promise<any> {
        return this.api.patch(`${path}/${id}/cancel`, { ...payload })
    }
    static send(id: number): Promise<any> {
        return this.api.patch(`${path}/${id}/send`)
    }
    static receive(id: number, payload: PlayloadReceive): Promise<any> {
        return this.api.patch(`${path}/${id}/receive`, { ...payload })
    }

    static loadAllLabByPatient(id: number, params: AllLabByPatientType): Promise<any> {
        return this.api.get(`${path}/patient/${id}`, { params: { ...params } }).then((res) => res)
    }

    static printLabDocument(id: number): Promise<any> {
        return this.api.get(`${path}/${id}/lab-document`)
    }

}
