import { numberFormat, numberNonDigit } from 'utils/app.utils';
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import _ from 'lodash'
import * as UseStyled from 'features/report/useStyled'
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { dateToView } from 'utils/date.utils';
import { colors } from 'constants/theme'

export interface FcComponent {
  page: number
  pageLimit: number
  rowCount: number
  sortBy: string
  sortType: string
  data: any
  onRequestSort: (sortBy: string, sortType: string) => void
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
}

type DataItem = {
  countPaid: string;
  countPaidNormal: string;
  countPaidCancel: string;
};

export default function SaleMedicineContent(props: FcComponent) {
  const { t } = useTranslation()

  const headCells = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.DATE', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.QTY_RECEIPT', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.QTY_RECEIPT_CANCEL', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.NET_CANCEL', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.QTY_RECEIPT_NORMAL', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.CASH', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.TRANSFER', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.CREDIT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.DEBIT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.ADVANCE', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.TREATMENT_RIGHT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.TOTAL', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_MONTH.OVERDUE', align: 'right', class: 'text-nowrap' }
  ]

  const renderData = (data: any, no: number) => {
    const num = props.page * props.pageLimit - props.pageLimit + no + 1
    return <>
      <TableRowCommon
        key={num.toString()}
        id={no.toString()}
        obj={data}
        columns={[{ option: 'TEXT', align: 'left', label: dateToView(data.paidDate) || "-" },
        { option: 'TEXT', align: 'right', label: data.countPaid || "-" },
        { option: 'TEXT', align: 'right', label: numberNonDigit(data.countPaidCancel) || "-" },
        { option: 'TEXT', align: 'right', label: numberFormat(data.cancelPaid) || "-" },
        { option: 'TEXT', align: 'right', label: numberNonDigit(data.countPaidNormal) || "-" },
        { option: 'TEXT', align: 'right', label: data.cashPaid ? numberFormat(data.cashPaid) : "-" },
        { option: 'TEXT', align: 'right', label: data.transferPaid ? numberFormat(data.transferPaid) : "-" },
        { option: 'TEXT', align: 'right', label: data.creditPaid ? numberFormat(data.creditPaid) : "-" },
        { option: 'TEXT', align: 'right', label: data.debitPaid ? numberFormat(data.debitPaid) : "-" },
        { option: 'TEXT', align: 'right', label: data.advancePaid ? numberFormat(data.advancePaid) : "-" },
        { option: 'TEXT', align: 'right', label: data.treamentRightPaid ? numberFormat(data.treamentRightPaid) : "-" },
        { option: 'TEXT', align: 'right', label: numberFormat(data.totalPaid) || "-" },
        { option: 'TEXT', align: 'right', label: numberFormat(data.overDue) || "-" }
        ]}
      />
    </>
  }

  return (
    <>
      <Box>
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            tableMinWidth={992}
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={props.onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headCells}
            rowsData={[_.map(props.data, (d: any, index: number) => {
              return renderData(d, index)
            }),
            <TableRow key={props.data} className='row-summary'>
              <TableCell align='left' scope="row" colSpan={1}>
                {t('REPORT.TITLE.SUM')}
              </TableCell>
              <TableCell align="right" scope="row" colSpan={1}>
                {_.sumBy(props.data, (item: DataItem) => Number(item.countPaid))}
              </TableCell>
              <TableCell align="right" scope="row" colSpan={1}>
                {_.sumBy(props.data, (item: DataItem) => Number(item.countPaidCancel))}
              </TableCell>
              <TableCell align="right" scope="row" colSpan={1}>
                {numberFormat(_.sumBy(props.data, 'cancelPaid'))}
              </TableCell>
              <TableCell align="right" scope="row" colSpan={1}>
                {_.sumBy(props.data, (item: DataItem) => Number(item.countPaidNormal))}
              </TableCell>
              <TableCell align="right" scope="row" colSpan={1}>
                {numberFormat(_.sumBy(props.data, 'cashPaid'))}
              </TableCell>
              <TableCell align="right" scope="row" colSpan={1}>
                {numberFormat(_.sumBy(props.data, 'transferPaid'))}
              </TableCell>
              <TableCell align="right" scope="row" colSpan={1}>
                {numberFormat(_.sumBy(props.data, 'creditPaid'))}
              </TableCell>
              <TableCell align="right" scope="row" colSpan={1}>
                {numberFormat(_.sumBy(props.data, 'debitPaid'))}
              </TableCell>
              <TableCell align="right" scope="row" colSpan={1}>
                {numberFormat(_.sumBy(props.data, 'advancePaid'))}
              </TableCell>
              <TableCell align="right" scope="row" colSpan={1}>
                {numberFormat(_.sumBy(props.data, 'treamentRightPaid'))}
              </TableCell>
              <TableCell align="right" scope="row" colSpan={1}>
                {numberFormat(_.sumBy(props.data, 'totalPaid'))}
              </TableCell>
              <TableCell align="right" scope="row" colSpan={1}>
                {numberFormat(_.sumBy(props.data, 'overDue'))}
              </TableCell>
            </TableRow>
            ]}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
    </>
  )
}
