import moment from "moment"

export const dateTimeToView = (date?: any, isFullYear = true, locales = 'th') => {
  let today: any = Date.now()
  if (date) today = new Date(date)

  if (locales === 'th') {
    const month = moment(date).format('MM')
    const year = Number(moment(date).format('YYYY')) + 543
    const dateOfData = moment(date).format('DD')
    const timeOfData = moment(date).format('HH:mm')
    const newDate = `${dateOfData}/${month}/${isFullYear ? year : year.toString().substring(2, 4)} ${timeOfData}  น.`
    return newDate
  }
  return (
    new Intl.DateTimeFormat(locales, {
      year: isFullYear ? 'numeric' : '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }).format(today) + ' น.'
  )
}
export const dateToView = (date?: any, isFullYear = true, locales = 'th') => {
  let today: any = Date.now()
  if (date) today = new Date(date)

  if (locales === 'th') {
    const month = moment(date).format('MM')
    const year = Number(moment(date).format('YYYY')) + 543
    const dateOfData = moment(date).format('DD')
    const newDate = `${dateOfData}/${month}/${isFullYear ? year : year.toString().substring(2, 4)}`
    return newDate
  }
  return new Intl.DateTimeFormat(locales, {
    year: isFullYear ? 'numeric' : '2-digit',
    month: '2-digit',
    day: '2-digit'
  }).format(today)
}

export const timeToView = (date?: any, isFullYear = true, locales = 'th') => {
  let today: any = Date.now()
  if (date) today = new Date(date)
  return new Intl.DateTimeFormat(locales, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  }).format(today)
}

export const convertDurationToMinuteOrText = (duration: any, type = '') => {
  /** CONVERT TO MINUTES */
  if (type === 'MINUTES') return Number(duration)
  else if (type === 'HOURS') {
    duration = duration.split('.')
    if (duration.length > 1) return Number(duration[0]) * 60 + Number(duration[1])
    else return Number(duration[0]) * 60
  } else if (type === 'DAY') return Number(duration) * 24 * 60

  /** CONVERT TO TEXT */
  duration = Number(duration)
  const min = Number(duration % 60)
  if (duration < 60) return duration + ' นาที'
  else if (duration >= 60 && duration < duration * 24 * 60) return Number((duration - min) / 60) + (min > 0 ? '.' + min : '') + ' ชั่วโมง'
  else return duration / 24 / 60 + ' วัน'
}

enum SHORT_DAY_OF_WEEK {
  Sun = `Sun`,
  Mon = `Mon`,
  Tue = `Tue`,
  Wed = `Wed`,
  Thu = `Thu`,
  Fri = `Fri`,
  Sat = `Sat`
}

export const dateOfWeek = (type: any = 'SHORT') => {
  return type === 'SHORT' ? SHORT_DAY_OF_WEEK : []
}

export const fullDateToView = (date?: any, isFullYear = true, locales = 'th') => {
  let today: any = Date.now()
  if (date) today = new Date(date)
  return new Intl.DateTimeFormat(locales, {
    year: 'numeric',
    month: 'long'
  }).format(today)
}
