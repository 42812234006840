import { Box } from '@mui/material'
import OperativeTypeApi from 'api/setting/treatments/operative-type.api'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentState, EditorState, convertToRaw } from "draft-js"
import htmlToDraft from "html-to-draftjs"
import draftToHtml from "draftjs-to-html"
import { Editor } from "react-draft-wysiwyg"
import UploadImage from 'component/Upload/UploadImage'
import { fileUrl } from 'api/api'
import { Form } from 'react-bootstrap'
import { notiError } from 'component/notifications/notifications'
import InputTextarea from 'component/Input/InputTextarea'
import { ContainerEditor } from '../useStyles'
import { ServiceProps } from 'api/application/manageWeb-interface'
import { ManageWebEditor } from '../ManageWeb'

type ServiceFormProps = {
    dataEdit: any
    setDataEdit: (data: any) => void
}

const ServiceForm = (props: ServiceFormProps) => {
    const { t } = useTranslation()
    const maxHeight = 1080
    const maxWidth = 1080
    const t_SERVICE = "MANAGE_WEB.SERVICE"

    const [operativeTypes, setOperativeTypes] = useState<any>([])

    const [dataEdit, setDataEdit] = useState<any>(props.dataEdit)
    const [dataEditId, setDataEditId] = useState<any>(props.dataEdit)
    const [editorState, setEditorState] = useState<any>(EditorState.createEmpty())

    const loadData = async () => {
        const respOperativeTypes = await OperativeTypeApi.findAll({ pageLimit: 100, search: '', status: 'ACTIVE' })
        if (respOperativeTypes.status === 200) {
            const options = respOperativeTypes.data.map((value: any) => {
                return { label: value.operativeTypeName, value: value.operativeTypeId }
            })
            setOperativeTypes(options)
        }
    }

    const onLoadEdit = () => {
        const data = {
            ...props.dataEdit,
            status: !props.dataEdit?.provideServiceId && "ACTIVE" || props.dataEdit?.status
        }
        setDataEdit(data)
        setDataEditId(data)
        if (data?.content) {
            const newHTML: any = data?.content
            const blocksFromHTML = htmlToDraft(newHTML);
            const content = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            setEditorState(EditorState.createWithContent(content))
        }
    }

    useEffect(() => {
        loadData()
        onLoadEdit()
    }, [])

    const uploadNewsImageSign = (files: any) => {
        if (files[0]) {
            const file = files[0];
            const fileName = file.name.split(".");
            const detailImage = {
                base64: "",
                type: dataEditId?.fileType || "",
                fileExtension: fileName[fileName.length - 1],
                fileSize: file.size.toString(),
                fileId: dataEditId?.fileId || 0,
            };
            const reader: any = new FileReader();
            reader.addEventListener("load", () => {
                const image = new Image();
                image.src = reader?.result as string;
                image.addEventListener("load", () => {
                    const width = image.width;
                    const height = image.height;

                    if (maxHeight < height || maxWidth < width) {
                        const errorWidth = maxWidth < width && ` ${t('MANAGE_WEB.BANNER.ERROR_WIDTH')} ${maxWidth} ` || ''
                        const errorHeight = maxHeight < height && ` ${t('MANAGE_WEB.BANNER.ERROR_HEIGHT')} ${maxHeight} ` || ''
                        const error = `${t('MANAGE_WEB.SERVICE.IMAGE')} ${errorWidth} ${maxHeight < height && maxWidth < width && ` ${t('MANAGE_WEB.CONNECTOR.AND')} ` || ''} ${errorHeight}`
                        notiError(error)
                    } else {
                        const base64 = reader.result.split(",");
                        detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result;
                        const newData = { ...dataEdit, ...detailImage, base64Show: reader.result, }
                        setDataEdit(newData)
                    }
                });
            });
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        renderDataSave()
    }, [editorState, dataEdit])

    const renderDataSave = () => {
        let newDataFile = {}
        if (dataEdit?.base64Show) {
            newDataFile = {
                file: {
                    fileId: dataEdit?.articleId || 0,
                    base64: dataEdit?.base64 || '',
                    type: dataEdit?.fileType || '',
                    fileExtension: dataEdit?.fileExtension || '',
                    fileSize: dataEdit?.fileSize || ''
                }
            }
        }
        const info: ServiceProps = {
            operativeTypeId: dataEdit?.operativeTypeId,
            status: dataEdit?.status,
            topic: dataEdit?.topic || '',
            subTopic: dataEdit?.subTopic || '',
            content: draftToHtml(convertToRaw(editorState?.getCurrentContent())) || '',
        }
        const newData: ServiceProps = { ...info, ...newDataFile }
        props?.setDataEdit(newData)
    }

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingRight: '16px' }}>
                <Box sx={{ paddingTop: '8px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ width: '50%' }}>
                        <AutocompleteSelect
                            labelId="operative-type"
                            key={`operative-type`}
                            inputLabel={t(`${t_SERVICE}.OPERATIVE_TYPE`)}
                            noOptionsText={t('NOT_FOUND')}
                            options={operativeTypes}
                            maxHight={'180px'}
                            onchange={(e, val) => {
                                if (val?.value) {
                                    setDataEdit({ ...dataEdit, operativeTypeId: val?.value, topic: val?.label })
                                }
                            }}
                            value={dataEdit.topic}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Form className="mx-0 mt-0 p-0  d-inline-flex w-100">
                            <Form.Label className=" mr-4 mb-0" style={{ marginTop: 'auto' }}>
                                {t(`${t_SERVICE}.LABEL_STATUS`)}
                            </Form.Label>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                onChange={() => setDataEdit({ ...dataEdit, status: dataEdit?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' })}
                                checked={dataEdit?.status === 'ACTIVE' ? true : false}
                            />
                        </Form>
                    </Box>
                </Box>
                <Box sx={{ marginTop: '16px', width: '50%' }}>
                    <Box sx={{ fontWeight: '500' }}>
                        {t(`${t_SERVICE}.UP_PIC`)}
                    </Box>
                    <Box sx={{ marginTop: '12px', height: dataEdit?.filePath || dataEdit?.base64Show ? 'auto' : '250px' }}>
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                ".MuiFormControl-root": {
                                    height: '100%'
                                }
                            }}
                        >
                            <UploadImage
                                setValue={uploadNewsImageSign}
                                pathFile={dataEdit?.base64Show || dataEdit?.filePath && `${fileUrl}/${dataEdit?.filePath}/${dataEdit?.fileName}` || ''}
                            />
                            <Box sx={{ color: "#BBBBBB", marginTop: "8px" }}>
                                {'('}{t(`${t_SERVICE}.FILESIZE`)}{` ${maxWidth} x ${maxHeight} px)`}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ marginTop: "48px" }}>
                    <InputTextarea
                        label={t(`${t_SERVICE}.LABEL_SUBTOPIC`)}
                        value={dataEdit?.subTopic}
                        onchange={(e) => {
                            setDataEdit({ ...dataEdit, subTopic: e.target.value })
                        }}
                    />
                </Box>

                <Box sx={{ marginTop: '16px' }}>
                    <Box sx={{ fontWeight: '500' }}>
                        {t(`${t_SERVICE}.DETAIL`)}
                    </Box>
                    <ContainerEditor sx={{ height: 'auto !important', '.demo-editor': { maxHeight: '500px', minHeight: '250px' } }}>
                        <ManageWebEditor editorState={editorState} setEditorState={setEditorState} />
                    </ContainerEditor>
                </Box>
            </Box>
        </>
    )
}

export default ServiceForm