import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface CareerInterface {
  careerName: string
  careerNameEn?: string
  status: string
}

export default class CareerApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('careers', { params: { ...props } }).then((res) => res)
  }

  static create(careerName: string, careerNameEn: string) {
    return this.api.post('careers', { careerName, careerNameEn })
  }

  static findById(id: number): Promise<CareerInterface> {
    return this.api.get(`careers/${id}`)
  }

  static update(id: number, body: CareerInterface) {
    return this.api.patch(`careers/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`careers/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`careers/${id}`)
  }
}
