import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface ProductUnitsInterface {
  group: string
  productUnitName: string
  productUnitNameEn: string
  status?: string
}

export interface FindAllProductUnitsInterface extends FindAllInterface {
  group: string
}

export default class ProductUnitsApi extends BaseAPI {
  static findAll(props: FindAllProductUnitsInterface): Promise<any> {
    return this.api.get('product-units', { params: { ...props } }).then((res) => res)
  }
  static create(payload: any) {
    return this.api.post('product-units', payload)
  }
  static findById(id: number): Promise<ProductUnitsInterface> {
    return this.api.get(`product-units/${id}`)
  }
  static update(id: number, body: ProductUnitsInterface) {
    return this.api.patch(`product-units/${id}`, body)
  }
  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`product-units/${id}/status`, { id, status })
  }
  static remove(id: number) {
    return this.api.delete(`product-units/${id}`)
  }
}
