import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart, TreatmentStateInterface } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/14.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethU, TextNumberU } from 'component/ToothChart/style'
const Div = styled(`div`)(({ theme }) => ({
  height: 'auto',
  marginTop: 'auto',
  top: '-0.3%',
  left: '30%'
}))

export default function Teeth14(props: TeethProps) {
  const teeth = useSelector(toothChart)
  const teethNumber = props.teethNumber
  const historyToothChart = props.historyToothChart
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const isEditTootCharts: any = useSelector(isEditTootChart)

  const dispatch = useDispatch()

  const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
  const [positions, setPositions]: any = useState([])
  const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

  const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
  const [isTooth, setIsTooth]: any = useState(false)
  const [isXRay, setIsXRay]: any = useState(false)
  const [isToothRoot, setIsToothRoot]: any = useState(false)
  const [isOrthodontics, setIsOrthodontics]: any = useState(false)
  const [isExtract, setIsExtract]: any = useState(false)
  const [isFixture, setIsFixture]: any = useState(false)
  const [isBridge, setIsBridge]: any = useState(false)

  const [color, setColor]: any = useState(``)
  const [styleSvg, setStyleSvg]: any = useState({ width: 38.262, height: 93.001, viewBox: "0 0 38.262 93.001" })

  const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

  useEffect(() => {
    const operative = props.operative || ``
    const valColor = props.color || colors.themeSecondColor
    const selected = teeth[teethNumber]?.isSelect || false
    dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
    setIsSelected(selected)

    renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

    if (props.type === 'treatment') {
      renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
    }

    if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

    return setStyleSvg({ width: 31.034, height: 99.002, viewBox: "0 0 31.034 99.002" })
  }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

  useEffect(() => {
    const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
    const teethJson = treatmentTooth?.teethJson

    if (isEditTootCharts !== null) {
      if (teethJson) {
        const tooths = JSON.parse(teethJson)
        setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
        if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
      } else setIsEditTeeth(false)
    }
  }, [isEditTootCharts]);

  useEffect(() => {
    if (isSelected) {
      dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
      if (teeth[props.teethNumber]?.isSelect) {
        setIsSelected(true)
      } else {
        setIsSelected(false)
      }
    }
  }, [props.historyToothChart]);

  useEffect(() => {
    if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
  }, [isSelected, isEditTootCharts]);

  return (
    <Div>
      <DivTeethU className={`div-teeth-${props.teethNumber} `}>
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray />) || ''}
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge />) || ''}
        {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`sm teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
          <svg className={`sm ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
            <g data-name="Group 9158">

              {(isBridge && props.type === 'treatment') ? <g></g> : ""}
              <path
                data-name="Path 5263"
                d="M326.368 122.229v.653a2.108 2.108 0 0 1-.207.93 3.3 3.3 0 0 1-1.256 1.435l-.013.013h-.006c-.006.013-.019.019-.026.032-2.862 3.848-6.026 5.936-9.951 6.374a.049.049 0 0 0-.026.013 4.713 4.713 0 0 1-2.559.638 15.218 15.218 0 0 1-7.554-2.359l-.464-.174a.109.109 0 0 1-.045.006c-.006-.013-.019-.019-.026-.032-3.3-.987-5.4-3.118-6.622-6.693a2.2 2.2 0 0 1-.113-.705v-6.13a2.124 2.124 0 0 1 .138-.748c4.806-12.821 2.476-26.622 5.637-39.469 3.038-12.366-.5-25.535 3.3-37.975a2.165 2.165 0 0 1 3.449-1.069c1.452 1.256 1.715 2.789 2.064 4.234 4.9 20.044 6.961 40.572 10.254 60.893.735 4.513 2.347 8.738 3.9 13.272a2.2 2.2 0 0 1 .119.71v.233a2.428 2.428 0 0 0 .172.848 6.831 6.831 0 0 1-.072 4.43 2.208 2.208 0 0 0-.093.64z"
                transform="translate(-296.074 -36.469)"
                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
              />
              {(isLowerTeeth && props.type === 'treatment') ?
                <path
                  data-name="Path 6514"
                  d="M323.2 109.021a24.421 24.421 0 0 1-1.484-2.875c-.562-.937-.312-1.016-.906-2.8a13.759 13.759 0 0 0-2.719-4.2 20.552 20.552 0 0 0-3.875-2.828 7.137 7.137 0 0 0-3.891-1.156 7.365 7.365 0 0 0-3.884 1.373 8.431 8.431 0 0 0-3.163 3.283 18.947 18.947 0 0 0-1.417 5.552c-.5 2.792-.686 3.464-.7 3.464a12.983 12.983 0 0 1-1.109 2.467c-.931 1.611-2.239 4.284-2.525 4.97-.031-.281-.094-.125.109-.719.906-3.016 1.311-4.311 1.938-6.719a85.74 85.74 0 0 0 1.531-13.594c.263-5.7.317-11.7 2.169-19.227 3.038-12.366-.5-25.535 3.3-37.975a2.165 2.165 0 0 1 3.449-1.069c1.452 1.256 1.715 2.789 2.064 4.234 4.9 20.044 6.961 40.572 10.254 60.893.662 4.062 3.2 11.377 3.979 13.456a71.662 71.662 0 0 0-3.12-6.53z"
                  transform="translate(-296.074 -36.469)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                /> : ''}
              <g data-name="Group 9720">
                <path
                  data-name="Path 5264"
                  d="M325.394 157.633c-1.562 5.359-12.172 10.211-15.468 10.211-3.739 0-12.8-5.54-13.29-6.584-2.827-5.962-.874-11.765 2.784-17.864 2.58-4.3.6-10.877 5.83-14.192.136-.086.268-.178.4-.275a6.586 6.586 0 0 1 7.208-.579c3.315 1.878 6.456 4.223 7.474 8.475a12.041 12.041 0 0 0 1.248 3.067c5.173 9.23 5.54 11.819 3.814 17.741z"
                  transform={(isFixture && props.type === 'treatment') ? "translate(-347.367 -85.933)" : "translate(-295.288 -68.842)"}
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                />
                <path
                  data-name="Path 5265"
                  d="M325.01 158.619c-1.462 5.018-11.395 9.56-14.481 9.56-3.5 0-11.979-5.187-12.442-6.165-2.647-5.581-.819-11.014 2.606-16.724 2.415-4.027.558-10.183 5.458-13.287q.191-.121.373-.257a6.167 6.167 0 0 1 6.749-.543c3.1 1.758 6.044 3.954 7 7.935a11.291 11.291 0 0 0 1.169 2.871c4.839 8.642 5.184 11.066 3.568 16.61z"
                  transform={(isFixture && props.type === 'treatment') ? "translate(-348.199 -87.631)" : "translate(-295.834 -69.875)"}
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                />
                <path
                  data-name="Path 5266"
                  d="M325.261 159.665c-1.333 4.575-10.389 8.716-13.2 8.716-3.192 0-10.922-4.729-11.345-5.621-2.413-5.088-.746-10.042 2.376-15.249 2.2-3.672.509-9.284 4.977-12.114.116-.073.229-.152.34-.235a5.624 5.624 0 0 1 6.153-.494c2.83 1.6 5.511 3.6 6.38 7.234a10.289 10.289 0 0 0 1.058 2.619c4.421 7.879 4.734 10.09 3.261 15.144z"
                  transform={(isFixture && props.type === 'treatment') ? "translate(-349.115 -89.314)" : "translate(-296.807 -71.131)"}
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                />
              </g>

              {(isToothRoot && props.type === 'treatment') ?
                <path data-name="Path 6563" d="M5.5 2.137c-.08-.705.193 49.105 1.229 53.391.731 0 .427.094 1.422.143C7.354 47.905 4.141 3.827 5.5 2.137z" transform="translate(7.302 2.678)" style={{

                  fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || ((isToothRoot && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                  stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || ((isToothRoot && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                }} />
                : ''}
              {(isExtract && props.type === 'treatment') ?
                <>
                  <g data-name="Group 9453">
                    <path data-name="Path 6581" d="m33.788-.5-27.542 80" transform="translate(-4.245 19.274)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                    <path data-name="Path 6582" d="m6.246-.5 27.542 80" transform="translate(-4.245 19.274)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                  </g>
                </>
                : ''}

              {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                <>
                  <defs>
                    <linearGradient id="e5nx8ebt7a" x1=".5" y1=".453" x2="-1.326" y2="4.622" gradientUnits="objectBoundingBox">
                      <stop offset={0} stopColor="#b0b1b1" />
                      <stop offset={1} stopColor="#585959" />
                    </linearGradient>
                    <linearGradient id="jcyebfkdob" x1=".5" y1="1.834" x2=".5" y2=".046" gradientUnits="objectBoundingBox">
                      <stop offset={0} stopColor="#c1c4c5" />
                      <stop offset={1} stopColor="#9e9e9e" />
                    </linearGradient>
                  </defs>
                  <g data-name="Group 9345">
                    <path data-name="Path 5263" d="M326.368 122.229v.653a2.108 2.108 0 0 1-.207.93 3.3 3.3 0 0 1-1.256 1.435l-.013.013h-.006c-.006.013-.019.019-.026.032-2.862 3.848-6.026 5.936-9.951 6.374a.049.049 0 0 0-.026.013 4.713 4.713 0 0 1-2.559.638 15.218 15.218 0 0 1-7.554-2.359l-.464-.174a.109.109 0 0 1-.045.006c-.006-.013-.019-.019-.026-.032-3.3-.987-5.4-3.118-6.622-6.693a2.2 2.2 0 0 1-.113-.705v-6.13a2.124 2.124 0 0 1 .138-.748c4.806-12.821 2.476-26.622 5.637-39.469 3.038-12.366-.5-25.535 3.3-37.975a2.165 2.165 0 0 1 3.449-1.069c1.452 1.256 1.715 2.789 2.064 4.234 4.9 20.044 6.961 40.572 10.254 60.893.735 4.513 2.347 8.738 3.9 13.272a2.2 2.2 0 0 1 .119.71v.233a2.428 2.428 0 0 0 .172.848 6.831 6.831 0 0 1-.072 4.43 2.208 2.208 0 0 0-.093.64z" transform="translate(-295.907 -36.469)" style={{ fill: '#e3d1b4' }} />
                    <path data-name="Path 5264" d="M325.394 157.633c-1.562 5.359-12.172 10.211-15.468 10.211-3.739 0-12.8-5.54-13.29-6.584-2.827-5.962-.874-11.765 2.784-17.864 2.58-4.3.6-10.877 5.83-14.192.136-.086.268-.178.4-.275a6.586 6.586 0 0 1 7.208-.579c3.315 1.878 6.456 4.223 7.474 8.475a12.041 12.041 0 0 0 1.248 3.067c5.173 9.23 5.54 11.819 3.814 17.741z" transform="translate(-295.121 -68.842)" style={{ fill: '#efede7' }} />
                    <path data-name="Path 5265" d="M325.01 158.619c-1.462 5.018-11.395 9.56-14.481 9.56-3.5 0-11.979-5.187-12.442-6.165-2.647-5.581-.819-11.014 2.606-16.724 2.415-4.027.558-10.183 5.458-13.287q.191-.121.373-.257a6.167 6.167 0 0 1 6.749-.543c3.1 1.758 6.044 3.954 7 7.935a11.291 11.291 0 0 0 1.169 2.871c4.839 8.642 5.184 11.066 3.568 16.61z" transform="translate(-295.667 -69.875)" style={{ fill: '#f7f7f7' }} />
                    <path data-name="Path 5266" d="M325.261 159.665c-1.333 4.575-10.389 8.716-13.2 8.716-3.192 0-10.922-4.729-11.345-5.621-2.413-5.088-.746-10.042 2.376-15.249 2.2-3.672.509-9.284 4.977-12.114.116-.073.229-.152.34-.235a5.624 5.624 0 0 1 6.153-.494c2.83 1.6 5.511 3.6 6.38 7.234a10.289 10.289 0 0 0 1.058 2.619c4.421 7.879 4.734 10.09 3.261 15.144z" transform="translate(-296.64 -71.131)" style={{ fill: colors.white }} />
                    <g data-name="Group 9381">
                      <g data-name="Group 9380" transform="translate(-3.833 75.366)">
                        <g data-name="Rectangle 3927" transform="translate(13.517)" style={{ fill: '#c1c4c5', stroke: '#c1c4c5' }}>
                          <rect width="11.601" height="11.601" rx={2} style={{ stroke: 'none' }} />
                          <rect x=".5" y=".5" width="10.601" height="10.601" rx="1.5" style={{ fill: 'none' }} />
                        </g>
                        <path data-name="Path 6487" d="M11.579 17.547c-.2.362 1.676.183 3.083.165 2.094-.044 3.289.384 3.269-4.45a41.118 41.118 0 0 0-.159-5.141c-.531-.516.14 5.236-1.219 7.766-1.282 1.827-4.631 1.272-4.974 1.66z" transform="translate(6.863 -6.464)" style={{ fill: '#b0b1b1' }} />
                        <rect data-name="Rectangle 3931" width={5} height={2} rx=".5" transform="translate(16.806 6.215)" style={{ fill: '#cbcccc' }} />
                        <rect data-name="Rectangle 3930" width={5} height={2} rx=".5" transform="translate(16.605 2.015)" style={{ fill: '#9e9e9e' }} />
                        <rect data-name="Rectangle 3928" width={5} height={2} rx=".5" transform="translate(16.806 2.015)" style={{ fill: 'url(#e5nx8ebt7a)' }} />
                        <rect data-name="Rectangle 3929" width={5} height={2} rx=".5" transform="translate(16.806 6.015)" style={{ fill: 'url(#jcyebfkdob)' }} />
                      </g>
                      <path data-name="Path 6488" d="M3.457 10.83S16.63 5.6 33.76 10.237" transform="translate(-2.748 71.786)" style={{ strokeLinecap: 'round', stroke: '#babbbb', fill: 'none' }} />
                      <path data-name="Path 6486" d="M3.563 10.783s15.845-5.117 30.509-.541" transform="translate(-2.934 72.467)" style={{ stroke: '#b5b7b7', strokeLinecap: 'round', fill: 'none' }} />
                    </g>
                  </g>
                </>
                || ""}

              {(isFixture && props.type === 'treatment') ?
                <>
                  <defs>
                    <linearGradient id="a9hqx8om5a" x1=".209" y1="-.059" x2="1.176" y2="1.286" gradientUnits="objectBoundingBox">
                      <stop offset={0} stopColor="#e5e5e5" />
                      <stop offset={1} stopColor="#b4b5b5" />
                    </linearGradient>
                    <linearGradient id="jqavsrz4eb" x1="-.342" y1="-.081" x2="1.352" y2={1} gradientUnits="objectBoundingBox">
                      <stop offset={0} stopColor="#e5e5e5" />
                      <stop offset={1} stopColor="#b0b1b1" />
                    </linearGradient>
                  </defs>
                  <g data-name="Group 9484">
                    <path data-name="Path 5258" d="M347.743 159.882a36.815 36.815 0 0 1 .761-14.108 30.486 30.486 0 0 0 2.088-5.71c1.56-3.983 3.339-8.45 6.9-11.64 4.189-3.757 7.863-3.687 11.169-.161 2.694 2.868 4.653 4.544 6.31 6.78 2 2.694 2.282 5.556 4.189 12.955a33.428 33.428 0 0 1 .414 4.554c.021 12.107-13.068 19.09-14.608 19.825-3.408 1.623-17.066-11.595-17.223-12.495z" transform="translate(-347.367 -85.933)" style={{ fill: '#efede7' }} />
                    <path data-name="Path 5259" d="M350.044 161.457a33.374 33.374 0 0 1 .69-12.788 27.635 27.635 0 0 0 1.893-5.177c1.413-3.611 3.026-7.659 6.251-10.551 3.8-3.406 7.128-3.342 10.125-.146a77.945 77.945 0 0 1 5.719 6.146c1.811 2.442 2.068 5.036 3.8 11.743a30.412 30.412 0 0 1 .376 4.129c.019 10.974-11.846 17.3-13.243 17.97-3.088 1.471-15.472-10.51-15.611-11.326z" transform="translate(-348.199 -87.631)" style={{ fill: '#f7f7f7' }} />
                    <path data-name="Path 5260" d="M352.583 162.653a29.564 29.564 0 0 1 .611-11.332 24.509 24.509 0 0 0 1.678-4.587c1.252-3.2 2.682-6.787 5.54-9.351 3.365-3.018 6.316-2.961 8.972-.129a69.215 69.215 0 0 1 5.069 5.446c1.6 2.164 1.832 4.463 3.365 10.406a26.882 26.882 0 0 1 .333 3.658c.017 9.726-10.5 15.335-11.735 15.925-2.735 1.305-13.709-9.312-13.833-10.036z" transform="translate(-349.115 -89.314)" style={{ fill: colors.white }} />
                    <g data-name="Group 9543">
                      <g data-name="Path 6490" transform="translate(6.284)" style={{ fill: 'url(#a9hqx8om5a)' }}>
                        <path d="M17.343 44.996H1.734c-.422 0-.825-.367-1.135-1.033-.322-.69-.499-1.61-.499-2.59C.845 24.493 1.818 12.58 2.991 5.97 3.682 2.075 4.427.1 5.203.1h8.671c.852 0 5.103 1.99 5.103 41.273 0 .98-.177 1.9-.499 2.59-.31.666-.713 1.033-1.135 1.033z" style={{ stroke: 'none' }} />
                        <path d="M17.343 44.896c.74 0 1.534-1.416 1.534-3.523 0-16.645-.753-28.547-2.238-35.377-.504-2.319-1.081-3.983-1.715-4.946-.486-.74-.867-.85-1.05-.85H5.203c-.406 0-1.22.752-2.114 5.788C1.917 12.593.945 24.498.2 41.378c.001 2.104.794 3.518 1.534 3.518h15.609m0 .2H1.734C.776 45.096 0 43.429 0 41.373 1.734 2.085 4.245 0 5.203 0h8.671c.958 0 5.203 2.085 5.203 41.373 0 2.056-.777 3.723-1.734 3.723z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                      </g>
                      <g data-name="Path 6489" transform="translate(4 42.63)" style={{ strokeLinejoin: 'round', fill: 'url(#jqavsrz4eb)' }}>
                        <path d="M23.198 7.569H1.16C.577 7.569.103 6.76.1 5.764.653 2.756.796 1.315.865.622.892.35.912.148.938.1h.007c.016 0 .037.002.062.004.04.004.09.01.153.01h22.038a4.489 4.489 0 0 0 .126-.002c.198 0 .21 0 .385 1.485.102.86.256 2.157.548 4.165-.002.997-.476 1.807-1.06 1.807z" style={{ stroke: 'none' }} />
                        <path d="M23.198 7.469c.517 0 .955-.777.96-1.7a142.268 142.268 0 0 1-.548-4.16C23.518.83 23.456.308 23.39.215a.404.404 0 0 0-.065-.003l-.126.001H1.16C1.1.213 1.052.21 1.013.206a7.67 7.67 0 0 0-.049.426C.895 1.326.752 2.768.2 5.772c.006.922.443 1.697.96 1.697h22.038m0 .2H1.16C.52 7.669 0 6.812 0 5.755 1.16-.555.52.013 1.16.013h22.038c.64 0 .287-.24 1.16 5.742 0 1.057-.52 1.914-1.16 1.914z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                      </g>
                      <path data-name="Path 6491" d="M5.625 21.2s18.057.9 18.962 1.957" transform="translate(.773 15.597)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6492" d="M5.649 21.2S23.1 22.161 24 23.213" transform="translate(1.06 10.345)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6493" d="M7.08 21.281s14.6.592 15.5 1.645" transform="translate(1.508 -11.973)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6584" d="M5.649 21.2S23.1 22.161 24 23.213" transform="translate(1.316 5.802)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6585" d="M6.115 21.2S23.1 22.185 24 23.237" transform="translate(1.316 -.222)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6586" d="M6.643 21.3s16.036.854 16.941 1.907" transform="translate(1.316 -6.281)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      <path data-name="Path 6587" d="M8.144 21.444s12.509.373 13.415 1.426" transform="translate(1.508 -17.717)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                    </g>
                  </g>
                </> : ""}
            </g>
          </svg>}
      </DivTeethU>
      <PositionTeeth quarter={QUARTER_NAME_TYPE.Q1} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
      <TextNumberU>14</TextNumberU>
    </Div>
  )
}
