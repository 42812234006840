import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router'
import { Typography, Box, MenuItem, Grid, Divider, IconButton, Collapse, TableRow, TableCell, Avatar, Chip, ClickAwayListener, styled, AutocompleteRenderInputParams } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** ROUTE */
import { routeName } from 'routes/routes-name'

/** API */
import { clinicTheme, fileUrl } from 'api/api'
import DoctorFeeApi, { DoctorFee } from 'api/setting/doctor-fee-managements/doctor-fee.api';
import TemplateDoctorFeeApi from 'api/setting/doctor-fee-managements/template-doctor-fee.api';

/** STYLE */
import * as UseStyled from 'features/setting/doctor-fee-managements/doctor-fee/style'
import * as TemplateDFUseStyled from 'features/setting/doctor-fee-managements/template-doctor-fee/style'
import { colors } from 'constants/theme'
import { imgs } from 'constants/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

/** UTILS || SLICE */
import { numberFormat, toBuddhistYear } from 'utils/app.utils'
import { DF_UNIT } from 'features/setting/doctor-fee-managements/template-doctor-fee/form-template-doctor-fee';

/** COMPONENT */
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import InputTextField from 'component/Input/InputTextField'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { ButtonInfoSimple } from 'component/Button/ButtonInfo'
import BasicSelect from 'component/Select/BasicSelect'
import ButtonCustom from 'component/Button/ButtonCustom'
import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import InputCheckbox from 'component/Input/InputCheckbox';

type errorMessage = {
  EMPTY_DF_NAME?: string
  EMPTY_DATE_START?: string
  EMPTY_TEMPLATE_DF?: string
  EMPTY_DOCTOR?: string
  EMPTY_DF_AMOUNT?: string
  EMPTY_LAB_AMOUNT?: string
}

const CustomFilterDoctor = styled(Box)<{ open?: boolean }>(({ theme, open }) => ({
  '.MuiOutlinedInput-input': {
    marginTop: '-1px',
    padding: `4px 0 0 ${clinicTheme.theme === clinicTheme.type.DENTAL ? '90px' : '60px'}  !important`
  },
  '.Mui-multiple': {
    '.MuiFormControl-root': {
      '.MuiOutlinedInput-root': {
        marginTop: 0,
        padding: '4px 0 4px 12px !important',
        // flexWrap: 'nowrap'
      },
      '.MuiOutlinedInput-input': {
        height: open ? 'auto' : 0,
        minWidth: 'unset',
        padding: '0 !important'
      }
    }
  },
  '.edit-filter-doctor': {
    zIndex: 1,
    top: '50%',
    transform: 'translateY(-50%)',
    left: '12px'
  }
}))

export default function FormDoctorFee() {
  const { t } = useTranslation()
  const { id: doctorFeeId }: any = useParams()
  const history = useHistory()

  const [doctorFees, setDoctorFees] = useState([]);

  const [doctorsId, setDoctorsId] = useState([]);
  const [doctors, setDoctors] = useState([]);

  const [doctorFeeOperativeId, setDoctorFeeOperativeId] = useState(0)
  const units: DF_UNIT[] = [DF_UNIT.PERCENT, DF_UNIT.BATH]
  const [operativeTypes, setOperativeTypes] = useState([]);
  const [doctorFeeOperatives, setDoctorFeeOperatives] = useState<DoctorFee>({
    doctorFeeName: '',
    templateDoctorFeeId: 0,
    dateStart: '',
    amount: 0,
    labDoctorAmount: 0,
    doctorFeeUsers: [],
    doctorFeeOperativeTypes: []
  });
  const [status, setStatus] = useState('');
  const [templateDoctorFees, setTemplateDoctorFees] = useState([]);
  const [errorMessage, setErrorMessage] = useState<errorMessage>({});
  const [openFilterDoctors, setOpenFilterDoctors] = useState(false)

  const [inputSearchTemplate, setInputSearchTemplate] = useState<string>('')
  const [statusTemplate, setStatusTemplate] = useState<boolean>(false)

  const onSubmitEdit = () => {
    if (!doctorFeeOperatives.doctorFeeName || !doctorFeeOperatives.dateStart || !doctorFeeOperatives.templateDoctorFeeId || _.isEmpty(doctorFeeOperatives.doctorFeeUsers) || (!doctorFeeOperatives.amount && doctorFeeOperatives.amount !== 0) || (!doctorFeeOperatives.labDoctorAmount && doctorFeeOperatives.labDoctorAmount !== 0)) {
      return setErrorMessage({
        EMPTY_DF_NAME: !doctorFeeOperatives.doctorFeeName ? t('DOCTOR_FEE.MESSAGE.ERROR.EMPTY_DF_NAME') : '',
        EMPTY_DATE_START: !doctorFeeOperatives.dateStart ? t('DOCTOR_FEE.MESSAGE.ERROR.EMPTY_DATE_START') : '',
        EMPTY_TEMPLATE_DF: !doctorFeeOperatives.templateDoctorFeeId ? t('DOCTOR_FEE.MESSAGE.ERROR.EMPTY_TEMPLATE_DF') : '',
        EMPTY_DOCTOR: _.isEmpty(doctorFeeOperatives.doctorFeeUsers) ? t('DOCTOR_FEE.MESSAGE.ERROR.EMPTY_DOCTOR') : '',
        EMPTY_DF_AMOUNT: (!doctorFeeOperatives.amount && doctorFeeOperatives.amount !== 0) ? t('DOCTOR_FEE.MESSAGE.ERROR.EMPTY_DF_AMOUNT') : '',
        EMPTY_LAB_AMOUNT: (!doctorFeeOperatives.labDoctorAmount && doctorFeeOperatives.labDoctorAmount !== 0) ? t('DOCTOR_FEE.MESSAGE.ERROR.EMPTY_LAB_AMOUNT') : ''
      })
    }

    const rawDoctorFeeOperatives = { ...doctorFeeOperatives, status: status }
    DoctorFeeApi.update(doctorFeeOperativeId, rawDoctorFeeOperatives)
      .then(() => {
        notiSuccess(t('DOCTOR_FEE.MESSAGE.SUCCESS.UPDATE'), '', null, null)
        history.push(routeName.doctorFee)
      })
      .catch((e) => {
        if (e.response.data.message === 'IS_DUPLICATE_DOCTOR_FEE_NAME') return notiError(t('DOCTOR_FEE.MESSAGE.ERROR.IS_DUPLICATE_DOCTOR_FEE_NAME'), '', null, null, '')
        else if (e.response.data.message === 'IS_DUPLICATE_DOCTOR') return notiError(t('DOCTOR_FEE.MESSAGE.ERROR.IS_DUPLICATE_DOCTOR'), '', null, null, '')
        else return notiError(e.response.data.message, '', null, null, '')
      })
  }

  const onSubmit = () => {
    if (!doctorFeeOperatives.doctorFeeName || !doctorFeeOperatives.dateStart || !doctorFeeOperatives.templateDoctorFeeId || _.isEmpty(doctorFeeOperatives.doctorFeeUsers) || (!doctorFeeOperatives.amount && doctorFeeOperatives.amount !== 0) || (!doctorFeeOperatives.labDoctorAmount && doctorFeeOperatives.labDoctorAmount !== 0)) {
      return setErrorMessage({
        EMPTY_DF_NAME: !doctorFeeOperatives.doctorFeeName ? t('DOCTOR_FEE.MESSAGE.ERROR.EMPTY_DF_NAME') : '',
        EMPTY_DATE_START: !doctorFeeOperatives.dateStart ? t('DOCTOR_FEE.MESSAGE.ERROR.EMPTY_DATE_START') : '',
        EMPTY_TEMPLATE_DF: !doctorFeeOperatives.templateDoctorFeeId ? t('DOCTOR_FEE.MESSAGE.ERROR.EMPTY_TEMPLATE_DF') : '',
        EMPTY_DOCTOR: _.isEmpty(doctorFeeOperatives.doctorFeeUsers) ? t('DOCTOR_FEE.MESSAGE.ERROR.EMPTY_DOCTOR') : '',
        EMPTY_DF_AMOUNT: (!doctorFeeOperatives.amount && doctorFeeOperatives.amount !== 0) ? t('DOCTOR_FEE.MESSAGE.ERROR.EMPTY_DF_AMOUNT') : '',
        EMPTY_LAB_AMOUNT: (!doctorFeeOperatives.labDoctorAmount && doctorFeeOperatives.labDoctorAmount !== 0) ? t('DOCTOR_FEE.MESSAGE.ERROR.EMPTY_LAB_AMOUNT') : ''
      })
    }

    DoctorFeeApi.create(doctorFeeOperatives)
      .then(() => {
        notiSuccess(t('DOCTOR_FEE.MESSAGE.SUCCESS.CREATE'), '', null, null)
        history.push(routeName.doctorFee)
      })
      .catch((e) => {
        if (e.response.data.message === 'IS_DUPLICATE_DOCTOR_FEE_NAME') return notiError(t('DOCTOR_FEE.MESSAGE.ERROR.IS_DUPLICATE_DOCTOR_FEE_NAME'), '', null, null, '')
        else if (e.response.data.message === 'IS_DUPLICATE_DOCTOR') return notiError(t('DOCTOR_FEE.MESSAGE.ERROR.IS_DUPLICATE_DOCTOR'), '', null, null, '')
        else return notiError(e.response.data.message, '', null, null, '')
      })
  }

  const loadTemplateDoctorFee = () => {
    TemplateDoctorFeeApi.findAll({ status: 'ACTIVE', pageLimit: 30, search: inputSearchTemplate }).then(({ data, headers }) => {
      setTemplateDoctorFees(data)
      if (headers['x-total'] > 30) {
        setStatusTemplate(true)
      }
    }).catch((e) => { return })
  }

  const loadDoctorFeeAll = () => {
    DoctorFeeApi.findAll({ pageLimit: 100 }).then(({ data }) => setDoctorFees(data)).catch((e) => { return })
  }

  const loadDoctor = () => {
    DoctorFeeApi.findAllDoctors()
      .then(({ data }) => {
        const rawDoctorsId: any = []
        _.map(data, (doc, index) => rawDoctorsId.push({ doctorId: doc.doctorId }))
        setDoctorsId(rawDoctorsId)
        setDoctors(data)
      })
      .catch((e) => { return })
  }

  const loadDoctorFee = () => {
    let doctorFee = {}
    DoctorFeeApi.findById(doctorFeeId)
      .then(({ data }) => {
        const doctorFeeUsers: any = []
        _.map(data.doctorFeeUsers, (doctor, index) => {
          if (doctor.doctorId) doctorFeeUsers.push({ doctorId: doctor.doctorId })
        })

        setDoctorFeeOperativeId(data.doctorFeeId)
        setStatus(data.status)
        doctorFee = {
          doctorFeeName: data.doctorFeeName,
          templateDoctorFeeId: data.templateDoctorFeeId,
          dateStart: data.dateStart,
          amount: data.amount,
          labDoctorAmount: data.labDoctorAmount,
          doctorFeeUsers: doctorFeeUsers,
          doctorFeeOperativeTypes: []
        }
      })
      .catch((e) => { return })


    DoctorFeeApi.findAllWithOperatives(doctorFeeId)
      .then(({ data }) => {
        setOperativeTypes(data)
        const newData = data.map((item: any) => {
          const templateDF = item?.doctorFeeOperativeTypes
          const newOperatives = item.operatives.map((info: any) => {
            if (!info?.doctorFeeOperatives) {
              return { ...info, doctorFeeOperatives: { ...templateDF, operativeId: info?.operativeId } }
            } else {
              return info
            }
          })

          return { ...item, operatives: newOperatives }
        })

        if (!_.isEmpty(data)) handleChangeTemplateOperative('edit', newData, doctorFee, '')
      })
      .catch((e) => { return })
  }

  const loadTemplateOperative = (templateDoctorFeeId: number) => {
    let templateDoctorFee = {}
    if (templateDoctorFeeId) {
      TemplateDoctorFeeApi.findById(templateDoctorFeeId)
        .then(({ data }) => {
          templateDoctorFee = {
            doctorFeeName: doctorFeeOperatives.doctorFeeName,
            templateDoctorFeeId: templateDoctorFeeId,
            dateStart: doctorFeeOperatives.dateStart,
            amount: data.amount,
            labDoctorAmount: data.labDoctorAmount,
            doctorFeeUsers: doctorFeeOperatives.doctorFeeUsers,
            doctorFeeOperativeTypes: []
          }
        })
        .catch((e) => { return })
    }

    TemplateDoctorFeeApi.findAllWithOperatives(templateDoctorFeeId)
      .then(({ data }) => {
        setOperativeTypes(data)
        const newData = data.map((item: any) => {
          const templateDF = item?.templateDoctorFeeOperativeTypes
          const newOperatives = item.operatives.map((info: any) => {
            if (!info?.templateDoctorFeeOperatives) {
              return { ...info, templateDoctorFeeOperatives: { ...templateDF, operativeId: info?.operativeId } }
            } else {
              return info
            }
          })

          return { ...item, operatives: newOperatives }
        })

        if (!_.isEmpty(data)) handleChangeTemplateOperative('changeTemplate', newData, templateDoctorFee, '')
      })
      .catch((e) => { return })
  }

  const handleChangeTemplateOperative = (type: 'add' | 'edit' | 'updateDF' | 'updateLab' | 'changeTemplate', operativesData: any, value?: any, valueUpdate?: any) => {
    const operativeTypes: any = []
    _.map(operativesData, (optType, index) => {
      let newDataOperativeType = {}
      const operative: any = []
      _.map(optType.operatives, (opt, indexOpt) => {
        let newDataOperative = {}
        if (type === 'changeTemplate') {
          newDataOperative = {
            operativeId: opt.operativeId,
            dfAmount: Number(opt.templateDoctorFeeOperatives?.dfAmount || 0),
            cfAmount: opt.templateDoctorFeeOperatives?.dfUnit === DF_UNIT.BATH ? 0 : Number(opt.templateDoctorFeeOperatives?.cfAmount) || (100 - (Number(opt.templateDoctorFeeOperatives?.dfAmount) || 0)),
            dfUnit: opt.templateDoctorFeeOperatives?.dfUnit || DF_UNIT.PERCENT,
            labDoctorAmount: Number(opt.templateDoctorFeeOperatives?.labDoctorAmount || 0),
            labClinicAmount: Number(opt.templateDoctorFeeOperatives?.labClinicAmount) || (100 - (Number(opt.templateDoctorFeeOperatives?.labDoctorAmount) || 0))
          }
        } else if (type === 'updateDF') {
          newDataOperative = {
            operativeId: opt.operativeId,
            dfAmount: Number(valueUpdate),
            cfAmount: Number(100 - (valueUpdate || 0)),
            dfUnit: DF_UNIT.PERCENT,
            labDoctorAmount: Number(value.doctorFeeOperativeTypes[index].doctorFeeOperatives[indexOpt].labDoctorAmount),
            labClinicAmount: Number(value.doctorFeeOperativeTypes[index].doctorFeeOperatives[indexOpt].labClinicAmount)
          }
        } else if (type === 'updateLab') {
          newDataOperative = {
            operativeId: opt.operativeId,
            dfAmount: Number(value.doctorFeeOperativeTypes[index].doctorFeeOperatives[indexOpt].dfAmount),
            cfAmount: Number(value.doctorFeeOperativeTypes[index].doctorFeeOperatives[indexOpt].cfAmount),
            dfUnit: opt.doctorFeeOperatives?.dfUnit || DF_UNIT.PERCENT,
            labDoctorAmount: Number(valueUpdate),
            labClinicAmount: Number(100 - (valueUpdate || 0))
          }
        } else {
          newDataOperative = {
            operativeId: opt.operativeId,
            dfAmount: Number(opt.doctorFeeOperatives?.dfAmount) || 0,
            cfAmount: opt.doctorFeeOperatives?.dfUnit === DF_UNIT.BATH ? Number(opt?.salePrice || 0) === 0 ? 0 : Number(opt?.salePrice || 0) - Number(opt.doctorFeeOperatives?.dfAmount || 0) : Number(opt.doctorFeeOperatives?.cfAmount) || 100 - (Number(opt.doctorFeeOperatives?.dfAmount || 0)),
            dfUnit: opt.doctorFeeOperatives?.dfUnit || DF_UNIT.PERCENT,
            labDoctorAmount: Number(opt.doctorFeeOperatives?.labDoctorAmount) || 0,
            labClinicAmount: Number(opt.doctorFeeOperatives?.labClinicAmount) || (100 - (Number(opt.doctorFeeOperatives?.labDoctorAmount) || 0))
          }
        }
        operative.push(newDataOperative)
      })

      if (type === 'changeTemplate') {
        newDataOperativeType = {
          operativeTypeId: optType.operativeTypeId,
          dfAmount: Number(optType.templateDoctorFeeOperativeTypes?.dfAmount || 0),
          cfAmount: Number(optType.templateDoctorFeeOperativeTypes?.cfAmount) || (100 - (Number(optType.templateDoctorFeeOperativeTypes?.dfAmount) || 0)),
          labDoctorAmount: Number(optType.templateDoctorFeeOperativeTypes?.labDoctorAmount || 0),
          labClinicAmount: Number(optType.templateDoctorFeeOperativeTypes?.labClinicAmount) || (100 - (Number(optType.templateDoctorFeeOperativeTypes?.labDoctorAmount) || 0)),
          doctorFeeOperatives: operative
        }
      } else if (type === 'updateDF') {
        newDataOperativeType = {
          operativeTypeId: optType.operativeTypeId,
          dfAmount: Number(valueUpdate),
          cfAmount: Number(100 - (valueUpdate || 0)),
          labDoctorAmount: Number(value.doctorFeeOperativeTypes[index].labDoctorAmount),
          labClinicAmount: Number(value.doctorFeeOperativeTypes[index].labClinicAmount),
          doctorFeeOperatives: operative
        }
      } else if (type === 'updateLab') {
        newDataOperativeType = {
          operativeTypeId: optType.operativeTypeId,
          dfAmount: Number(value.doctorFeeOperativeTypes[index].dfAmount),
          cfAmount: Number(value.doctorFeeOperativeTypes[index].cfAmount),
          labDoctorAmount: Number(valueUpdate),
          labClinicAmount: Number(100 - (valueUpdate || 0)),
          doctorFeeOperatives: operative
        }
      } else {
        newDataOperativeType = {
          operativeTypeId: optType.operativeTypeId,
          dfAmount: Number(optType.doctorFeeOperativeTypes?.dfAmount) || 0,
          cfAmount: Number(optType.doctorFeeOperativeTypes?.cfAmount) || (100 - (Number(optType.doctorFeeOperativeTypes?.dfAmount) || 0)),
          labDoctorAmount: Number(optType.doctorFeeOperativeTypes?.labDoctorAmount) || 0,
          labClinicAmount: Number(optType.doctorFeeOperativeTypes?.labClinicAmount) || (100 - (Number(optType.doctorFeeOperativeTypes?.labDoctorAmount) || 0)),
          doctorFeeOperatives: operative
        }
      }
      operativeTypes.push(newDataOperativeType)
    })

    const rawDoctorFeeOperatives: DoctorFee = {
      doctorFeeName: value.doctorFeeName,
      templateDoctorFeeId: value.templateDoctorFeeId,
      dateStart: value.dateStart,
      amount: value.amount,
      labDoctorAmount: value.labDoctorAmount,
      doctorFeeUsers: value.doctorFeeUsers,
      doctorFeeOperativeTypes: operativeTypes
    }
    setDoctorFeeOperatives(rawDoctorFeeOperatives)
  }

  const handleChangeDefault = (event: any) => {
    const name = event.target.name
    let value = event.target.value
    if (name === 'amount' || name === 'labDoctorAmount') {
      value = value === '' ? 0 : String(value).replace(/[^0-9.]/g, '')
      value = Number(value) >= 100 ? 100 : Number(value)
    }

    setDoctorFeeOperatives({ ...doctorFeeOperatives, [name]: value })
    clearErrorMessage()
  }

  const handleChangeTemplate = (e: any, value: any) => {
    setDoctorFeeOperatives({ ...doctorFeeOperatives, templateDoctorFeeId: value.templateDoctorFeeId })
    clearErrorMessage()
    loadTemplateOperative(value.templateDoctorFeeId)
  }

  const handleChangeDoctor = (e: any, value: any) => {
    setDoctorFeeOperatives({ ...doctorFeeOperatives, doctorFeeUsers: value })
    clearErrorMessage()
  }

  const onUpdateDefaultDF = () => {
    const dfValue = doctorFeeOperatives.amount
    handleChangeTemplateOperative('updateDF', operativeTypes, doctorFeeOperatives, dfValue)
  }

  const onUpdateDefaultLab = () => {
    const labValue = doctorFeeOperatives.labDoctorAmount
    handleChangeTemplateOperative('updateLab', operativeTypes, doctorFeeOperatives, labValue)
  }

  const clearErrorMessage = () => {
    setErrorMessage({})
  }

  const onChangeValueOperativeType = (event: any, index: number) => {
    const name = event.target.name
    let value = event.target.value
    value = value === '' ? 0 : String(value).replace(/[^0-9.]/g, '')
    value = Number(value) >= 100 ? 100 : Number(value)

    const operativeType = _.merge({}, doctorFeeOperatives)
    let selectOperative = _.merge({}, operativeType.doctorFeeOperativeTypes[index])

    if (name === 'dfAmount') selectOperative = { ...selectOperative, [name]: value, cfAmount: Number(100 - value) }
    else selectOperative = { ...selectOperative, [name]: value, labClinicAmount: Number(100 - value) }

    _.map(selectOperative.doctorFeeOperatives, (opt: any) => {
      opt[name] = value
      opt.dfUnit = DF_UNIT.PERCENT
      if (name === 'dfAmount') opt.cfAmount = Number(100 - (value || 0))
      if (name === 'labDoctorAmount') opt.labClinicAmount = Number(100 - (value || 0))
    })
    operativeType.doctorFeeOperativeTypes[index] = selectOperative
    setDoctorFeeOperatives(operativeType)
  }

  const onChangeValueOperative = (event: any, typeIndex: number, index: number, operativePrice: number) => {
    const name = event.target.name
    let value = event.target.value
    if (name !== 'dfUnit') {
      value = value === '' ? 0 : String(value).replace(/[^0-9.]/g, '')
      value = Number(value)
      if (name === 'dfAmount') onChangeValueDfAmount(typeIndex, index, value, operativePrice)
      if (name === 'labDoctorAmount') onChangeValueLabAmount(typeIndex, index, value)
    } else onChangeValueDfUnit(typeIndex, index, value, operativePrice)
  }

  const calculateByPercent = (price: number, percent: number) => {
    return (Number(price) * Number(percent)) / 100
  }

  const onChangeValueDfAmount = (typeIndex: number, index: number, value: number, operativePrice: number) => {
    const operativeType = _.merge({}, doctorFeeOperatives)
    let selectOperative = _.merge({}, operativeType.doctorFeeOperativeTypes[typeIndex].doctorFeeOperatives[index])

    if (selectOperative.dfUnit === DF_UNIT.PERCENT) {
      const val = value >= 100 ? 100 : value
      selectOperative = { ...selectOperative, dfAmount: val, cfAmount: 100 - val }
    } else {
      const val = value >= operativePrice && operativePrice !== 0 ? operativePrice : value
      // const val = value
      selectOperative = { ...selectOperative, dfAmount: val, cfAmount: operativePrice === 0 ? 0 : operativePrice - val }
    }

    operativeType.doctorFeeOperativeTypes[typeIndex].doctorFeeOperatives[index] = selectOperative
    setDoctorFeeOperatives(operativeType)
  }

  const onChangeValueDfUnit = (typeIndex: number, index: number, value: DF_UNIT, operativePrice: number) => {
    const operativeType = _.merge({}, doctorFeeOperatives)
    const selectOperativeType = _.merge({}, operativeType.doctorFeeOperativeTypes[typeIndex])
    let selectOperative = _.merge({}, selectOperativeType.doctorFeeOperatives[index])

    if (value === DF_UNIT.PERCENT) {
      selectOperative = {
        ...selectOperative,
        dfUnit: value,
        dfAmount: selectOperativeType.dfAmount,
        cfAmount: selectOperativeType.cfAmount
      }
    } else {
      selectOperative = {
        ...selectOperative,
        dfUnit: value,
        dfAmount: calculateByPercent(operativePrice, selectOperativeType.dfAmount),
        cfAmount: calculateByPercent(operativePrice, selectOperativeType.cfAmount),
      }
    }

    operativeType.doctorFeeOperativeTypes[typeIndex].doctorFeeOperatives[index] = selectOperative
    setDoctorFeeOperatives(operativeType)
  }

  const onChangeValueLabAmount = (typeIndex: number, index: number, value: number) => {
    const val = value >= 100 ? 100 : value
    const operativeType = _.merge({}, doctorFeeOperatives)
    let selectOperative = _.merge({}, operativeType.doctorFeeOperativeTypes[typeIndex].doctorFeeOperatives[index])
    selectOperative = { ...selectOperative, labDoctorAmount: val, labClinicAmount: 100 - val }
    operativeType.doctorFeeOperativeTypes[typeIndex].doctorFeeOperatives[index] = selectOperative
    setDoctorFeeOperatives(operativeType)
  }

  const headCells = [
    { id: 'message', disablePadding: false, label: t('') },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.CATEGORY') },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.PRICE'), align: 'right' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.DF_DOCTOR'), align: 'center' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.DF_BRANCH'), align: 'center' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.UNIT'), align: 'center' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.LAB_DOCTOR'), align: 'center' },
    { id: 'message', disablePadding: false, label: t('TEMPLATE_DOCTOR_FEE.CELLS.LAB_BRANCH'), align: 'center' },
  ]

  const doctorFeeUsed = (doctorId: number) => {
    const doctor = _.find(doctors, { doctorId: doctorId }) || {}
    const doctorFeeId = _.get(doctor, 'lastUsed')?.doctorFeeId
    const doctorFeeName: string = _.get(_.find(doctorFees, { doctorFeeId: doctorFeeId }), 'doctorFeeName')
    const date = _.get(doctor, 'lastUsed')?.dateStart
    return `${t('DOCTOR_FEE.TITLE.LAST_USED_DF', { df: doctorFeeName || '-' })} ${date ? t('DOCTOR_FEE.TITLE.LAST_USED', { date: toBuddhistYear(moment(date), 'DD/MM/YYYY') }) : ''}`
  }

  const renderImage = (img: any) => {
    if (img) return `${fileUrl}/${img}`
    else return imgs.defaultAvatar
  }

  useEffect(() => {
    loadDoctor()
    loadDoctorFeeAll()
  }, []);

  const handleSearchTemplate = (params: AutocompleteRenderInputParams) => {
    if (statusTemplate) {
      if (typeof params.inputProps.value === 'string') {
        setInputSearchTemplate(params.inputProps.value)
      } else {
        setInputSearchTemplate('')
      }
    }
  }

  useEffect(() => {
    loadTemplateDoctorFee()
  }, [inputSearchTemplate])

  useEffect(() => {
    if (doctorFeeId) loadDoctorFee()
  }, [doctorFeeId]);

  return (
    <div className={`container-card h-100 overflow-hidden`}>
      <div className="d-flex flex-column h-100 overflow-auto">
        <HeaderLabel
          text={doctorFeeId ? t(`DOCTOR_FEE.TITLE.EDIT_DEFAULT`) : t(`DOCTOR_FEE.TITLE.ADD_DEFAULT`)}
          navigator={{ previousTo: routeName.doctorFee, previousTitle: t('DOCTOR_FEE.TITLE.DEFAULT'), currentTitle: doctorFeeId ? t('DOCTOR_FEE.TITLE.EDIT_DEFAULT') : t('DOCTOR_FEE.TITLE.ADD_DEFAULT') }}
          goBack={routeName.doctorFee}
          onSubmit={doctorFeeId ? onSubmitEdit : onSubmit}
        />

        <Card className="border shadow-sm mx-4 mb-4 card">
          <Card.Body>
            <Grid container spacing={2}>
              <Grid item lg={6} className="w-100">
                <Box className="mt-2">
                  <InputTextField
                    key={'doctorFeeName'}
                    name={'doctorFeeName'}
                    label={t('DOCTOR_FEE.FORM.DF_NAME')}
                    value={doctorFeeOperatives.doctorFeeName}
                    onchange={handleChangeDefault}
                    required={true}
                    helperText={errorMessage.EMPTY_DF_NAME || ''}
                  />
                </Box>
              </Grid>
              <Grid item lg={6} className="d-none d-xl-block"></Grid>
              <Grid item sm={6} lg={4} className='w-100'>
                <InputSecondNewDatePicker
                  required
                  label={t('DOCTOR_FEE.FORM.INPUT_DATE')}
                  value={doctorFeeOperatives.dateStart}
                  onchange={(value: any) => {
                    setDoctorFeeOperatives({ ...doctorFeeOperatives, dateStart: moment(value).format('YYYY-MM-DD') })
                    clearErrorMessage()
                  }}
                  helperText={errorMessage.EMPTY_DATE_START || ''}
                />
              </Grid>
              <Grid item sm={6} lg={4} className='w-100'>
                <AutocompleteSelect
                  required
                  key={`templateDF`}
                  labelId="templateDF"
                  inputLabel={t('DOCTOR_FEE.FORM.TEMPLATE_DF')}
                  noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                  options={templateDoctorFees}
                  getOptionLabel={(option: any) => option.templateDoctorFeeName}
                  renderOption={(props, option: any) => (
                    <Box key={option.templateDoctorFeeId} component="li" {...props}>
                      {option.templateDoctorFeeName}
                    </Box>
                  )}
                  onchange={handleChangeTemplate}
                  value={doctorFeeOperatives.templateDoctorFeeId ? _.find(templateDoctorFees, { templateDoctorFeeId: doctorFeeOperatives.templateDoctorFeeId }) : null}
                  disableClearable
                  errMessage={errorMessage.EMPTY_TEMPLATE_DF || ''}
                  getInputSearch={handleSearchTemplate}
                />
              </Grid>
            </Grid>
            <Box>
              <Typography className='my-3' sx={{ fontSize: '18px', fontWeight: 600 }}>{t('DOCTOR_FEE.TITLE.DOCTOR_LIST')}</Typography>
              <Grid container spacing={2}>
                <Grid item sm={6} lg={4} className='w-100'>
                  <ClickAwayListener onClickAway={() => setOpenFilterDoctors(false)}>
                    <CustomFilterDoctor open={openFilterDoctors} onClick={() => setOpenFilterDoctors(true)}>
                      <AutocompleteSelect
                        multiple
                        required
                        open={openFilterDoctors}
                        labelId="doctorFeeUsers"
                        key={'doctorFeeUsers'}
                        inputLabel={t('DOCTOR_FEE.FORM.DOCTOR_LIST')}
                        noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                        options={doctorsId}
                        getOptionLabel={(option: any) => _.get(_.find(doctors, { doctorId: option.doctorId }), 'fullname') || '-'}
                        renderOption={(props, option: any) => {
                          return (
                            <Box
                              key={option.doctorId}
                              component="li"
                              style={{
                                pointerEvents: _.some(doctorFeeOperatives.doctorFeeUsers, (i: any, index: number) => i.doctorId === option.doctorId) ? 'none' : (_.some(doctors, (i: any, index: number) => i.doctorId === option.doctorId && (i?.lastUsed?.doctorFeeId && doctorFeeId && i?.lastUsed?.doctorFeeId !== Number(doctorFeeId) || (i?.lastUsed !== null || i?.lastUsed) && !doctorFeeId)) ? 'none' : ''),
                                backgroundColor: _.some(doctorFeeOperatives.doctorFeeUsers, (i: any, index: number) => i.doctorId === option.doctorId) ? colors.themeSecondColor06 : colors.white,
                                color: _.some(doctorFeeOperatives.doctorFeeUsers, (i: any, index: number) => i.doctorId === option.doctorId) ? colors.themeSecondColor : (_.some(doctors, (i: any, index: number) => i.doctorId === option.doctorId && (i?.lastUsed?.doctorFeeId && doctorFeeId && i?.lastUsed?.doctorFeeId !== Number(doctorFeeId) || (i?.lastUsed !== null || i?.lastUsed) && !doctorFeeId)) ? colors.disabledGray : null),
                              }}
                              {...props}
                            >
                              {/* <span>{_.get(_.find(doctors, { doctorId: option.doctorId }), 'fullname') || '-'}</span> */}
                              <InputCheckbox label={_.get(_.find(doctors, { doctorId: option.doctorId }), 'fullname') || '-'} checked={!!doctorFeeOperatives.doctorFeeUsers.find(item => item.doctorId === option.doctorId)} className={'pe-none'} />
                            </Box>
                          )
                        }}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option: any, index: any) => (
                            <div key={`index-${index}`}>
                              <Chip label={_.get(_.find(doctors, { doctorId: option.doctorId }), 'fullname') || '-'} style={{ fontSize: '1rem' }} {...getTagProps({ index })} />
                            </div>
                          ))
                        }
                        onchange={handleChangeDoctor}
                        value={!_.isEmpty(doctorFeeOperatives.doctorFeeUsers) ? doctorFeeOperatives.doctorFeeUsers : []}
                        height={43}
                        errMessage={errorMessage.EMPTY_DOCTOR || ''}
                      />
                    </CustomFilterDoctor>
                  </ClickAwayListener>
                </Grid>
              </Grid>
              {(!_.isEmpty(doctorFeeOperatives.doctorFeeUsers)) && (
                <Grid container spacing={2} className="mt-0">
                  {
                    _.map(doctorFeeOperatives.doctorFeeUsers, (doctor, indexDoctor) => {
                      return (
                        <Grid key={indexDoctor} item lg={6} xl={4} className='w-100'>
                          <UseStyled.DentistBox className='d-flex align-items-center'>
                            <Avatar src={renderImage(_.get(_.find(doctors, { doctorId: doctor.doctorId }), 'doctorAvata'))} sx={{ backgroundColor: colors.white }}>
                              <img src={imgs.defaultAvatar} className="w-100 h-100" alt="" />
                            </Avatar>
                            <Box className='mx-3 overflow-hidden'>
                              <Typography className='text-ellipsis name'>{_.get(_.find(doctors, { doctorId: doctor.doctorId }), 'fullname') || '-'}</Typography>
                              <Typography className='date mt-1'>{doctorFeeUsed(doctor.doctorId)}</Typography>
                            </Box>
                          </UseStyled.DentistBox>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              )}
            </Box>
            <Box>
              <Box className='d-flex align-items-center mt-4'>
                <Typography className='mr-2' sx={{ fontSize: '20px', fontWeight: 600 }}>{t('TEMPLATE_DOCTOR_FEE.TITLE.MANAGE')}</Typography>
                <ButtonInfoSimple text={t('TEMPLATE_DOCTOR_FEE.TITLE.TOOLTIP')} />
              </Box>
              <Box className='pl-md-3'>
                <Grid container spacing={2} className="mt-0">
                  <Grid item xs={2} lg={1} className='w-100'>
                    <Typography className='pt-sm-2'>{t('TEMPLATE_DOCTOR_FEE.FORM.DF_VALUE')}</Typography>
                  </Grid>
                  <Grid item xs={10} lg={11} className='w-100'>
                    <Grid container spacing={2}>
                      <Grid item xs={4} sm={3} lg={2} className='w-100'>
                        <InputTextField
                          key={'amount'}
                          name={'amount'}
                          label={t('TEMPLATE_DOCTOR_FEE.FORM.DOCTOR_FEE_AMOUNT')}
                          value={doctorFeeOperatives.amount || 0}
                          onchange={handleChangeDefault}
                          required={true}
                          hideControl
                          helperText={errorMessage.EMPTY_DF_AMOUNT || ''}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4} sm={3} lg={2} className='w-100 pr-sm-2'>
                        <BasicSelect
                          name="defaultUnit"
                          labelId="lb-defaultUnit"
                          selectId="sl-defaultUnit"
                          label=""
                          value={DF_UNIT.PERCENT}
                          options={
                            _.map(units, (unit: string, index: number) => {
                              return (
                                <MenuItem key={index} value={unit}>
                                  {unit === DF_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                                </MenuItem>
                              )
                            })
                          }
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4} sm={3} lg={2} className='w-100'>
                        <InputTextField
                          key={'templateClinicFeeAmount'}
                          name={'templateClinicFeeAmount'}
                          label={t('TEMPLATE_DOCTOR_FEE.FORM.CLINIC_FEE_AMOUNT')}
                          value={100 - (doctorFeeOperatives.amount || 0)}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="mt-0">
                  <Grid item xs={2} lg={1} className='w-100'>
                    <Typography className='pt-sm-2'>{t('TEMPLATE_DOCTOR_FEE.FORM.LAB_VALUE')}</Typography>
                  </Grid>
                  <Grid item xs={10} lg={11} className='w-100'>
                    <Grid container spacing={2}>
                      <Grid item xs={4} sm={3} lg={2} className='w-100'>
                        <InputTextField
                          key={'labDoctorAmount'}
                          name={'labDoctorAmount'}
                          label={t('TEMPLATE_DOCTOR_FEE.FORM.DOCTOR_FEE_AMOUNT')}
                          value={doctorFeeOperatives.labDoctorAmount || 0}
                          onchange={handleChangeDefault}
                          required={true}
                          hideControl
                          helperText={errorMessage.EMPTY_LAB_AMOUNT || ''}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4} sm={3} lg={2} className='w-100 pr-sm-2'>
                        <BasicSelect
                          name="defaultUnit"
                          labelId="lb-defaultUnit"
                          selectId="sl-defaultUnit"
                          label=""
                          value={DF_UNIT.PERCENT}
                          options={
                            _.map(units, (unit: string, index: number) => {
                              return <MenuItem key={index} value={unit}>
                                {unit === DF_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                              </MenuItem>
                            })
                          }
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4} sm={3} lg={2} className='w-100'>
                        <InputTextField
                          key={'templateClinicFeeLab'}
                          name={'templateClinicFeeLab'}
                          label={t('TEMPLATE_DOCTOR_FEE.FORM.CLINIC_FEE_AMOUNT')}
                          value={100 - (doctorFeeOperatives.labDoctorAmount || 0)}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
              <TemplateDFUseStyled.ResponsiveTable section={'default'}>
                <TableCustom
                  page={0}
                  pageLimit={0}
                  sortType={'ASC'}
                  sortBy={''}
                  rowCount={0}
                  textEmptyData={t('REGISTER.NO_ITEM')}
                  onSort={() => { return }}
                  setPageLimit={() => { return }}
                  setPage={() => { return }}
                  headCells={headCells}
                  rowsData={
                    _.map(operativeTypes, (temp, index) => {
                      return (
                        <RenderData
                          key={index}
                          no={index}
                          row={temp}
                          headCells={headCells}
                          units={units}
                          doctorFeeOperative={doctorFeeOperatives?.doctorFeeOperativeTypes[index]}
                          onChangeValueOperativeType={onChangeValueOperativeType}
                          onChangeValueOperative={onChangeValueOperative}
                        />
                      )
                    })
                  }
                  hidePagination
                  tableFixedWidth
                />
              </TemplateDFUseStyled.ResponsiveTable>
            </Box>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

function RenderData(props: any) {
  const { t } = useTranslation()
  const { row, no, headCells, units, doctorFeeOperative } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell align='center'>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            className="p-0"
            sx={{ backgroundColor: 'transparent !important' }}>
            {open ? (
              <FontAwesomeIcon icon={faCaretDown} style={{ color: colors.textPrimary, fontSize: 20, transform: 'rotate(180deg)' }} />
            ) : (
              <FontAwesomeIcon icon={faCaretDown} style={{ color: colors.textPrimary, fontSize: 20 }} />
            )}
          </IconButton>
        </TableCell>
        <TableCell>
          {row.operativeTypeName}
        </TableCell>
        <TableCell></TableCell>
        <TableCell>
          <InputTextField
            key={`dfAmount`}
            name={`dfAmount`}
            label={''}
            value={doctorFeeOperative?.dfAmount}
            onchange={(e) => {
              if (props.onChangeValueOperativeType) props.onChangeValueOperativeType(e, no)
            }}
            disabled
            hideControl
          />
        </TableCell>
        <TableCell>
          <InputTextField
            key={`cfAmount`}
            name={`cfAmount`}
            label={''}
            value={100 - (doctorFeeOperative?.dfAmount || 0)}
            disabled
            type="number"
            hideControl
          />
        </TableCell>
        <TableCell>
          <BasicSelect
            key={`defaultUnit`}
            labelId="lb-defaultUnit"
            selectId="sl-defaultUnit"
            label=""
            value={DF_UNIT.PERCENT}
            options={
              _.map(units, (unit: string, index: number) => {
                return (
                  <MenuItem key={index} value={unit}>
                    {unit === DF_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                  </MenuItem>
                )
              })
            }
            onchange={() => { return }}
            disabled
          />
        </TableCell>
        <TableCell>
          <InputTextField
            key={`labDoctorAmount`}
            name={`labDoctorAmount`}
            label={''}
            value={doctorFeeOperative?.labDoctorAmount}
            onchange={(e) => {
              if (props.onChangeValueOperativeType) props.onChangeValueOperativeType(e, no)
            }}
            disabled
            hideControl
          />
        </TableCell>
        <TableCell>
          <InputTextField
            key={`labClinicAmount`}
            name={`labClinicAmount`}
            label={''}
            value={100 - (doctorFeeOperative?.labDoctorAmount || 0)}
            disabled
            type="number"
            hideControl
          />
        </TableCell>
      </TableRow>
      <TableRow className='section-collapse'>
        <TableCell colSpan={_.size(headCells)} className="p-0 border-0">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TemplateDFUseStyled.ResponsiveTable section='collapse'>
              <TableCustom
                page={0}
                pageLimit={0}
                sortType={'ASC'}
                sortBy={''}
                rowCount={0}
                textEmptyData={t('REGISTER.NO_ITEM')}
                onSort={() => {
                  return
                }}
                setPageLimit={() => {
                  return
                }}
                setPage={() => {
                  return
                }}
                headCells={headCells}
                rowsData={
                  _.map(row.operatives, (opt, indexOpt) => {
                    return (
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          {opt.operativeName}
                        </TableCell>
                        <TableCell align='right'>{numberFormat(opt.salePrice || 0)}</TableCell>
                        <TableCell>
                          <InputTextField
                            key={`dfAmount`}
                            name={`dfAmount`}
                            label={''}
                            value={doctorFeeOperative?.doctorFeeOperatives[indexOpt]?.dfAmount}
                            onchange={(e) => {
                              if (props.onChangeValueOperative) props.onChangeValueOperative(e, no, indexOpt, opt.salePrice || 0)
                            }}
                            disabled
                            hideControl
                          />
                        </TableCell>
                        <TableCell>
                          <InputTextField
                            key={`cfAmount`}
                            name={`cfAmount`}
                            label={''}
                            value={doctorFeeOperative?.doctorFeeOperatives[indexOpt]?.cfAmount}
                            disabled
                            hideControl
                          />
                        </TableCell>
                        <TableCell>
                          <BasicSelect
                            key={`dfUnit`}
                            name={`dfUnit`}
                            labelId="lb-defaultUnit"
                            selectId="sl-defaultUnit"
                            label=""
                            value={doctorFeeOperative?.doctorFeeOperatives[indexOpt]?.dfUnit}
                            options={
                              _.map(units, (unit: string, index: number) => {
                                return (
                                  <MenuItem key={index} value={unit}>
                                    {unit === DF_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                                  </MenuItem>
                                )
                              })
                            }
                            onchange={(e) => {
                              if (props.onChangeValueOperative) props.onChangeValueOperative(e, no, indexOpt, opt.salePrice || 0)
                            }}
                            disabled
                          />
                        </TableCell>
                        <TableCell>
                          <InputTextField
                            key={`labDoctorAmount`}
                            name={`labDoctorAmount`}
                            label={''}
                            value={doctorFeeOperative?.doctorFeeOperatives[indexOpt]?.labDoctorAmount}
                            onchange={(e) => {
                              if (props.onChangeValueOperative) props.onChangeValueOperative(e, no, indexOpt, opt.salePrice || 0)
                            }}
                            disabled
                            hideControl
                          />
                        </TableCell>
                        <TableCell>
                          <InputTextField
                            key={`labClinicAmount`}
                            name={`labClinicAmount`}
                            label={''}
                            value={doctorFeeOperative?.doctorFeeOperatives[indexOpt]?.labClinicAmount}
                            disabled
                            hideControl
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
                hidePagination
                tableFixedWidth
              />
            </TemplateDFUseStyled.ResponsiveTable>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}