export default function toothSolid() {
    return (
        <svg xmlns="https://www.w3.org/2000/svg" width="24.25" height="24" viewBox="0 0 24.25 24">
            <g data-name="Edit tooth">
                <g data-name="Group 11785">
                    <path d="M250.764 156.537a11.529 11.529 0 0 0 .139-3.029c-.053-6.206-5.862-6.5-7.52-4.7-.282.306-.785.431-1.024.089-1.173-1.684-7.415-1.347-7.363 4.746a10.038 10.038 0 0 0 1.85 5.8.663.663 0 0 1 .1.547c-.417 1.693-1.256 4.243 1.152 4.223 2.577-.022 2.2-2.883 4.914-2.906 1.161-.01 1.816.573 2.735 1.573l.676-1.652s-.489 1.572.117-.287c.837-.875 2.467-2.276 3.229-3.214a14.363 14.363 0 0 0 .995-1.19z" transform="translate(-233.746 -143.849)" style={{ strokeMiterlimit: 10, strokeLinejoin: 'round', fill: '#fff', stroke: '#fff', strokeWidth: '2.5px' }} />
                    <path data-name="การรักษา" d="M250.764 156.537a11.529 11.529 0 0 0 .139-3.029c-.053-6.206-5.862-6.5-7.52-4.7-.282.306-.785.431-1.024.089-1.173-1.684-7.415-1.347-7.363 4.746a10.038 10.038 0 0 0 1.85 5.8.663.663 0 0 1 .1.547c-.417 1.693-1.256 4.243 1.152 4.223 2.577-.022 2.2-2.883 4.914-2.906 1.161-.01 1.816.573 2.735 1.573l.676-1.652s-.489 1.572.117-.287c.837-.875 2.467-2.276 3.229-3.214a14.363 14.363 0 0 0 .995-1.19z" transform="translate(-233.745 -143.849)" style={{ fill: '#1cb99a', stroke: '#1cb99a', strokeMiterlimit: 10, strokeLinejoin: 'round' }} />
                    <path data-name="Path 6723" d="M20.179 3.3a1.652 1.652 0 0 1 2.337 2.337l-7.4 7.4-3.116.781.779-3.116z" transform="translate(-.314 6.545)" style={{ strokeLinecap: 'round', strokeLinejoin: 'round', fill: '#fff', stroke: '#fff', strokeWidth: '2.5px' }} />
                    <path data-name="Path 6722" d="M19.949 3.288A1.606 1.606 0 0 1 22.22 5.56l-7.192 7.192-3.028.757.757-3.028z" transform="translate(-.005 6.636)" style={{ strokeLinecap: 'round', fill: '#1cb99a', stroke: '#1cb99a', strokeLinejoin: 'round' }} />
                </g>
                <path data-name="Rectangle 4365" style={{ fill: 'none' }} d="M0 0h24v24H0z" transform="translate(.25)" />
            </g>
        </svg>
    )
}
