import { useCallback, useState, useEffect, useRef } from 'react'
import { Divider, Grid, Typography, Box, FormControl, RadioGroup, FormControlLabel, MenuItem } from '@mui/material'
import 'react-multi-carousel/lib/styles.css'
import _ from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import InputRadio from 'component/Input/InputRadio'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import { fileUrl } from 'api/api'
import TreatmentHistoryApi from 'api/dentists/treatment-history.api'
import ClinicApi, { DentistInterface } from 'api/master/clinic.api'

/** UTILS || SLICE */
import { closeModal, resetModal } from 'app/slice/modal.slice'
import useWindowSize from "utils/useWindowSize"
import { treatment, TreatmentStateInterface } from 'app/slice/treatment.slice'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import BasicSelect from 'component/Select/BasicSelect'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import Loading from 'component/Loading'

/** STYLE */
import { styled } from '@mui/material/styles'
import * as SendExamUseStyled from 'component/Register/style/styleSendExamination'
import { selectUser } from 'app/slice/user.slice'
import { useFunctionPrint } from 'component/Print/print'
import { getClinicInfo } from 'utils/app.utils'

const CustomTable = styled(Box)<{ type: 'diagnosis' | 'treatments' }>(({ theme, type }) => ({
  '.MuiTableContainer-root': {
    height: '100% !important',
    backgroundColor: colors.transparent,
    '.MuiTable-root': {
      backgroundColor: colors.white,
      '.MuiTableHead-root': {
        '.MuiTableCell-root': {
          '&:first-of-type': {
            button: {
              display: 'none'
            }
          }
        }
      }
    }
  }
}))

type ModalCustomProps = {
  isShow: boolean | false
  setIsShow: (show: boolean) => void
  onClose?: () => void
  patientId: number
  patientCaseId: number
  createdAt: string
  treatments?: any
  diagnosis?: any
  patientRecommendId?: any
  type: 'treatment' | 'historyTreatment'
}

export default function MedicalCertificate(props: ModalCustomProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  /** SUMMARY TREATMENTS */
  const treatments: TreatmentStateInterface = useSelector(treatment)

  const user = useSelector(selectUser)
  console.log(user?.me);

  const [width] = useWindowSize()
  const [tab, setTab] = useState(0)

  const [doctors, setDoctors] = useState<DentistInterface[]>([])
  const [lang, setLang] = useState<any>('th')
  const [summeryTreatments, setSummeryTreatments] = useState([] as any)
  const [isCheckDiagnosis, setIsCheckDiagnosis] = useState<any[]>([] as any)
  const [isCheckAllDiagnosis, setIsCheckAllDiagnosis] = useState('')
  const [isCheckTreatments, setIsCheckTreatments] = useState<any[]>([] as any)
  const [isCheckAllTreatments, setIsCheckAllTreatments] = useState('')
  const [errorDoctorSelect, setErrorDoctorSelect] = useState('')

  const [isCheckRecommend, setIsCheckRecommend] = useState<any[]>([] as any)

  const [loading, setLoading] = useState(false)

  /** FILTER */
  const [doctor, setDoctor] = useState<number>(user?.me?.userId || 0)
  const [date, setDate] = useState<string>(moment().format('YYYY-MM-DD'))
  console.log(props.treatments);

  const loadData = useCallback(async () => {
    setLoading(false)
    const doctorsResp = await ClinicApi.findAllDentists()
    const allDoctors: Set<string> = new Set();
    const newTreatments: any[] = [];

    const updateDoctorsAndTreatments = (diagnosis: any[], treatments: any[]) => {
      _.map(diagnosis, (i: any) => {
        if (!allDoctors.has(i.doctorId)) {
          allDoctors.add(i.doctorId);
        }
      });

      _.map(treatments, (i: any) => {
        if (!allDoctors.has(i.doctorId)) {
          allDoctors.add(i.doctorId);
        }
        if (i.tempOrderTreatmentId || i.orderTreatmentId) {
          newTreatments.push(i);
        }
      });

      setSummeryTreatments(newTreatments);
    };

    if (props.type === 'historyTreatment') {
      updateDoctorsAndTreatments(props.diagnosis, props.treatments);
    } else {
      updateDoctorsAndTreatments(treatments.patientDiagnosis, treatments.summeryTreatments);
    }
    setDoctors(_.filter(doctorsResp.data, (i: any) => allDoctors.has(i.dentistId)));

  }, [treatments.summeryTreatments, props.treatments, props.diagnosis])

  useEffect(() => {
    setDate(moment().format('YYYY-MM-DD'))
    loadData()
  }, [loadData, props.isShow, props.patientId, props.patientCaseId, props.createdAt])

  const onCheckAll = (event: any, type: 'Diagnosis' | 'Treatments') => {
    const { checked } = event
    if (type === "Diagnosis") {
      const id = _.map(props.type === 'historyTreatment' ? props.diagnosis : treatments.patientDiagnosis, (i: any) => { return i.patientDiagnosisId })
      if (checked) setIsCheckDiagnosis(Array.from(new Set([...isCheckDiagnosis, ...id])))
      else setIsCheckDiagnosis(_.filter(isCheckDiagnosis, (i) => !_.includes(id, i)))

    } else if (type === 'Treatments') {
      const id = _.map(summeryTreatments, (i: any, index: number) => { return props.type === 'historyTreatment' ? i.orderTreatmentId : i.tempOrderTreatmentId })
      if (checked) setIsCheckTreatments(Array.from(new Set([...isCheckTreatments, ...id])))
      else setIsCheckTreatments(_.filter(isCheckTreatments, (i) => !_.includes(id, i)))
    }
  }

  const onCheck = (event: any, type: 'Diagnosis' | 'Treatments') => {
    const { checked, value } = event
    if (type === "Diagnosis") {
      if (checked) setIsCheckDiagnosis([...isCheckDiagnosis, Number(value)])
      else setIsCheckDiagnosis(_.filter(isCheckDiagnosis, (i) => { return i !== Number(value) }))
    } else if (type === 'Treatments') {
      if (checked) setIsCheckTreatments([...isCheckTreatments, Number(value)])
      else setIsCheckTreatments(_.filter(isCheckTreatments, (i) => { return i !== Number(value) }))
    }
  }

  const handleAddRecommend = async () => {
    if (props.type === 'historyTreatment') {
      const refItemIdRecomment: string[] = []
      await props?.patientRecommendId?.forEach((item: any) => {
        refItemIdRecomment.push(item.patientRecommendId.toString())
      })
      return refItemIdRecomment.join(',')
    } else {
      const refItemIdVal: string[] = []
      await summeryTreatments?.forEach((item: any) => {
        isCheckTreatments?.forEach((item_2: any) => {
          if (item['tempOrderTreatmentId'] === Number(item_2)) {
            refItemIdVal.push(item.refItemId)
          }
        })
      })
      const refItemIdRecomment: string[] = []
      await treatments?.patientRecommends?.forEach((item: any) => {
        refItemIdVal.forEach((item_2: any) => {
          if (item.refItemId.includes(item_2)) {
            refItemIdRecomment.push(item.patientRecommendId.toString())
          }
        })
      })
      return refItemIdRecomment.join(',')
    }
  }

  const checkSome = (data: any, checkValues: any, idKey: any) => {
    return _.some(data, (i) => _.some(checkValues, (j) => Number(i[idKey]) === Number(j)));
  };

  const checkEvery = (data: any, checkValues: any, idKey: any) => {
    return _.every(data, (i) => _.some(checkValues, (j) => Number(i[idKey]) === Number(j)));
  };

  const statusCheckAllDiagnosis = (checkValues: any, dataAll: any, dataSome: any) => {
    if (!_.isEmpty(checkValues) && !dataAll && dataSome) {
      return 'indeterminate'
    } else {
      return dataAll ? 'checked' : ''
    }
  }


  const handleOnCheckAll = () => {
    const patientDiagnosisData = props.type === 'historyTreatment' ? props.diagnosis : treatments.patientDiagnosis;

    const patientDiagnosisHasSome = checkSome(patientDiagnosisData, isCheckDiagnosis, 'patientDiagnosisId');
    const patientDiagnosisHasAll = checkEvery(patientDiagnosisData, isCheckDiagnosis, 'patientDiagnosisId');


    setIsCheckAllDiagnosis(statusCheckAllDiagnosis(isCheckDiagnosis, patientDiagnosisHasAll, patientDiagnosisHasSome));
    const summeryTreatmentsData = summeryTreatments;

    const summeryTreatmentsHasSome = checkSome(
      summeryTreatmentsData,
      isCheckTreatments,
      props.type === 'historyTreatment' ? 'orderTreatmentId' : 'tempOrderTreatmentId'
    );

    const summeryTreatmentsHasAll = checkEvery(
      summeryTreatmentsData,
      isCheckTreatments,
      props.type === 'historyTreatment' ? 'orderTreatmentId' : 'tempOrderTreatmentId'
    );

    setIsCheckAllTreatments(statusCheckAllDiagnosis(isCheckTreatments, summeryTreatmentsHasAll, summeryTreatmentsHasSome));
  }

  useEffect(() => {
    handleOnCheckAll()
  }, [isCheckDiagnosis, isCheckTreatments, summeryTreatments])

  const headCellsDiagnosis = [
    {
      id: 'patientSelect',
      disablePadding: false,
      label: 'CHECKBOX',
      indeterminate: isCheckAllDiagnosis === 'indeterminate',
      checked: isCheckAllDiagnosis === 'checked',
      disableDropdown: _.isEmpty(isCheckDiagnosis),
      onCheckAll: (event: any) => onCheckAll(event.target, 'Diagnosis'),
      values: [],
      width: '70px'
    },
    { id: 'no', disablePadding: false, label: t('#'), width: '60px' },
    { id: 'branchId', disablePadding: false, label: t('MODAL_MED_CERTIFICATE.TABLE.CODE'), width: '100px' },
    { id: 'cnNumber', disablePadding: false, label: t('MODAL_MED_CERTIFICATE.TABLE.DIAGNOSIS') },
  ]

  const renderDataDiagnosis = (objData: any, no: number) => {
    const { patientDiagnosisId, diagnosisCode, diagnosisName } = objData
    const rowCheck = _.some(isCheckDiagnosis, (i: number) => Number(i) === Number(patientDiagnosisId))

    const objRenderData = {
      key: patientDiagnosisId,
      id: patientDiagnosisId,
      obj: objData,
      rowSelect: rowCheck,
      columns: [
        { option: 'CHECKBOX', align: 'center', label: patientDiagnosisId },
        { option: 'TEXT', align: 'center', label: no + 1 },
        { option: 'TEXT', align: 'left', label: diagnosisCode },
        { option: 'TEXT', align: 'left', label: diagnosisName },
      ]
    }

    return <TableRowCommon {...objRenderData} onCheck={(event) => onCheck(event.target, 'Diagnosis')} />
  }

  const headCellsTreatments = width >= 768 ? [
    {
      id: 'treatment',
      disablePadding: false,
      label: 'CHECKBOX',
      indeterminate: isCheckAllTreatments === 'indeterminate',
      checked: isCheckAllTreatments === 'checked',
      disableDropdown: _.isEmpty(isCheckTreatments),
      onCheckAll: (event: any) => onCheckAll(event.target, 'Treatments'),
      values: [],
      width: '70px'
    },
    { id: 'no', disablePadding: false, label: t('#'), width: '60px' },
    { id: 'teeth', disablePadding: false, label: t('MODAL_MED_CERTIFICATE.TABLE.TOOTH'), width: '200px' },
    { id: 'type', disablePadding: false, label: t('MODAL_MED_CERTIFICATE.TABLE.TYPE'), width: '200px' },
    { id: 'name', disablePadding: false, label: t('MODAL_MED_CERTIFICATE.TABLE.SERVICE') },
  ] : [
    {
      id: 'treatment',
      disablePadding: false,
      label: 'CHECKBOX',
      indeterminate: isCheckAllTreatments === 'indeterminate',
      checked: isCheckAllTreatments === 'checked',
      disableDropdown: _.isEmpty(isCheckTreatments),
      onCheckAll: (event: any) => onCheckAll(event.target, 'Treatments'),
      values: [],
      width: '70px'
    },
    { id: 'no', disablePadding: false, label: t('#'), width: '60px' },
    { id: 'free', disablePadding: false, label: '' },
  ]

  const renderDataTreatments = (objData: any, no: number) => {
    const { teeth, operativeTypeName, operativeName, itemTypeName, itemName, tempOrderTreatmentId, orderTreatmentId } = objData
    const rowCheck = _.some(isCheckTreatments, (i: number) => Number(i) === Number(props.type === 'historyTreatment' ? orderTreatmentId : tempOrderTreatmentId))

    const objRenderData = {
      key: props.type === 'historyTreatment' ? orderTreatmentId : tempOrderTreatmentId,
      id: props.type === 'historyTreatment' ? orderTreatmentId : tempOrderTreatmentId,
      obj: objData,
      rowSelect: rowCheck,
      columns: width >= 768 ? [
        { option: 'CHECKBOX', align: 'center', label: props.type === 'historyTreatment' ? orderTreatmentId : tempOrderTreatmentId },
        { option: 'TEXT', align: 'center', label: no + 1 },
        { option: 'TEXT', align: 'left', label: teeth ? teeth.replaceAll(',', ', ') : '-' },
        { option: 'TEXT', align: 'left', label: operativeTypeName || itemTypeName },
        { option: 'TEXT', align: 'left', label: operativeName || itemName },
      ] : [
        { option: 'CHECKBOX', align: 'center', label: props.type === 'historyTreatment' ? orderTreatmentId : tempOrderTreatmentId },
        { option: 'TEXT', align: 'center', label: no + 1 },
        {
          option: 'COMPONENT',
          align: 'left',
          component: (
            <>
              <Box className='d-flex mb-1'>
                <Typography sx={{ width: 70, fontWeight: 500, flex: 'none' }}>{t('HISTORY_TREATMENT.MODAL_MEDICAL_CERTIFICATE.TOOTH')}</Typography>
                <Typography>{teeth ? teeth.replaceAll(',', ', ') : '-'}</Typography>
              </Box>
              <Box className='d-flex mb-1'>
                <Typography sx={{ width: 70, fontWeight: 500, flex: 'none' }}>{t('HISTORY_TREATMENT.MODAL_MEDICAL_CERTIFICATE.TYPE')}</Typography>
                <Typography>{operativeTypeName || itemTypeName}</Typography>
              </Box>
              <Box className='d-block'>
                <Typography className='mb-1' sx={{ fontWeight: 500, flex: 'none' }}>{t('HISTORY_TREATMENT.MODAL_MEDICAL_CERTIFICATE.LIST')}</Typography>
                <Typography>{operativeName || itemName}</Typography>
              </Box>
            </>
          )
        },
      ]
    }
    return <TableRowCommon {...objRenderData} onCheck={(event) => onCheck(event.target, 'Treatments')} />
  }
  const componentRef = useRef<HTMLDivElement>(null)
  const BranchInfo = getClinicInfo()

  const onPrint = async () => {
    if (!doctor) {
      return setErrorDoctorSelect(t('MODAL_MED_CERTIFICATE.NOT_SELECT_DOCTOR'))
    }

    const patientDiagnosisId: any = isCheckDiagnosis.join(',')
    const patientRecommendId: any = await handleAddRecommend() || ''
    const orderTreatmentId: any = props.type === 'historyTreatment' ? isCheckTreatments.join(',') : []
    const tempOrderTreatmentId: any = props.type === 'treatment' ? isCheckTreatments.join(',') : []

    setLoading(true)
    const print = await TreatmentHistoryApi.printMedicalCertificate(props.patientId, props.patientCaseId, {
      date,
      lang,
      doctorId: doctor,
      patientDiagnosisId,
      patientRecommendId,
      orderTreatmentId,
      tempOrderTreatmentId,
    }).finally(() => setLoading(false))

    if (print.status === 200) {
      window.open(`${fileUrl}/${print.data}`, '_blank')
      dispatch(closeModal())
      setIsCheckAllTreatments('')
      setIsCheckTreatments([])
      setIsCheckAllDiagnosis('')
      setIsCheckDiagnosis([])
    }
    setLoading(false)
  }


  return (
    <>
      <ModalCustom
        size={'xl'}
        title={t('MODAL_MED_CERTIFICATE.TITLE')}
        alignFooter={'end'}
        fullscreen
        footerInline
        onClose={props.onClose}
        onSubmit={onPrint}
        // submitPrint
        // printProps={{
        //   content: componentRef?.current,
        //   style: '@page { size: 297mm 420mm;  margin: 0; } @media print { body { -webkit-print-color-adjust: exact; } ',
        //   removeIfram: true,
        //   // docTitle: t('1111')
        // }}
        onReset={() => {
          dispatch(resetModal())
          props.setIsShow(false)
        }}
        textBtnCancel={t('BUTTON.CANCEL')}
        textBtnConfirm={t('BUTTON.PRINT')}
        modalStyle={'modal-med-certificate'}
        component={
          <>
            <Grid container spacing={2} className="pt-2">
              <Grid item className='w-100'>
                <Box className="d-flex align-items-center">
                  <Typography className='mr-3' sx={{ fontWeight: 500 }}>{t('MODAL_MED_CERTIFICATE.LANGUAGE')}</Typography>
                  <FormControl>
                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                      <FormControlLabel value="th" control={<InputRadio checked={lang === 'th'} className="radio" onClick={(e: any) => setLang('th')} />} label={t('BUTTON.LANGUAGE.TH')} />
                      <FormControlLabel value="en" control={<InputRadio checked={lang === 'en'} onClick={(e: any) => setLang('en')} />} label={t('BUTTON.LANGUAGE.ENG')} />
                      <FormControlLabel value="empty" control={<InputRadio checked={lang === 'empty'} onClick={(e: any) => setLang('empty')} />} label={t('BUTTON.LANGUAGE.EMPTY')} />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6} className='w-100'>
                <InputSecondNewDatePicker
                  required
                  key={'date'}
                  label={t('MODAL_MED_CERTIFICATE.DATE_EXPORT')}
                  value={date}
                  onchange={(e: any) => setDate(moment(e).format('YYYY-MM-DD'))} />
              </Grid>
              <Grid item xs={6} className='w-100'>
                <BasicSelect
                  required
                  name="doctorId"
                  labelId="lb-dentist"
                  selectId="sl-dentist"
                  label={t('HISTORY_APPOINTMENT.DOCTOR')}
                  value={doctor || null}
                  options={[
                    _.map(doctors, (doctorData: DentistInterface, i: number) => (
                      <MenuItem key={i} value={doctorData.dentistId}>
                        {doctorData.dentistFullname}
                      </MenuItem>
                    ))
                  ]}
                  onchange={(event) => {
                    setDoctor(event.target.value)
                    setErrorDoctorSelect('')
                  }}
                  classesOption="style-select-doctors-mb"
                  helperText={errorDoctorSelect || ''}
                />
              </Grid>
            </Grid>
            <Divider className="mx-0 my-3 my-md-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
            {width < 768 && (
              <SendExamUseStyled.FragmentTab className={'d-block d-xl-none mb-2 mb-md-3 mx-3 mx-md-4'}>
                <button className={`${tab === 0 ? 'active' : ''}`} onClick={() => setTab(0)}>
                  {t('MODAL_MED_CERTIFICATE.DIAGNOSIS')}
                </button>
                <button className={`${tab === 1 ? 'active' : ''}`} onClick={() => setTab(1)}>
                  {t('MODAL_MED_CERTIFICATE.TREATMENT')}
                </button>
              </SendExamUseStyled.FragmentTab>
            )}
            <Box className={`${tab === 0 ? 'd-flex' : 'd-none'} d-md-flex flex-column overflow-hidden h-100`}>
              <Typography className='mb-3 d-none d-md-block' sx={{ fontSize: 18, fontWeight: 500 }}>{t('MODAL_MED_CERTIFICATE.DIAGNOSIS')}</Typography>
              <CustomTable type={'diagnosis'} className={`h-100 overflow-hidden`}>
                <TableCustom
                  tableFixedWidth
                  hidePagination
                  page={1}
                  pageLimit={1}
                  sortType={''}
                  sortBy={''}
                  rowCount={0}
                  onSort={() => { return }}
                  setPageLimit={() => { return }}
                  setPage={() => { return }}
                  headCells={headCellsDiagnosis}
                  rowsData={_.map(props.type === 'historyTreatment' ? props.diagnosis : treatments.patientDiagnosis, (diagnosis, index: number) => {
                    return renderDataDiagnosis(diagnosis, index)
                  })}
                />
              </CustomTable>
            </Box>
            <Box className={`${tab === 1 ? 'd-flex' : 'd-none'} d-md-flex flex-column overflow-hidden h-100`}>
              <Typography className='mt-md-4 mb-3 d-none d-md-block' sx={{ fontSize: 18, fontWeight: 500 }}>{t('MODAL_MED_CERTIFICATE.TREATMENT')}</Typography>
              <CustomTable type={'treatments'} className={`h-100 overflow-hidden`}>
                <TableCustom
                  tableFixedWidth
                  hidePagination
                  page={1}
                  pageLimit={1}
                  sortType={''}
                  sortBy={''}
                  rowCount={0}
                  onSort={() => { return }}
                  setPageLimit={() => { return }}
                  setPage={() => { return }}
                  headCells={headCellsTreatments}
                  rowsData={_.map(summeryTreatments, (summaryTreatment, index: number) => {
                    return renderDataTreatments(summaryTreatment, index)
                  })}
                />
              </CustomTable>
            </Box>

            {loading && <Loading show={true} type="fullLoading" />}
          </>
        }
      />
      {/* print-show */}
      <Box
        className="print-show"
        ref={componentRef}
        sx={{
          position: 'absolute',
          top: '0',
          zIndex: 99999,
          width: '297mm',
          height: '420mm',
          backgroundColor: "#fff",
          overflow: "hidden"
        }}
      >
        <Box className={'pdf-body'} sx={{ padding: '20px', height: '100%', width: "100%" }}>
          <Box className={'pdf-title'} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box
              className={'pdf-branch-logo'}
              sx={{
                height: '100px',
                img: {
                  maxHeight: '100%'
                }
              }}>
              <img src={BranchInfo?.branchLogo || ''} />
            </Box>
            {/*  */}
            <Box className={'pdf-branch-name'} sx={{ fontWeight: "500" }}>
              {BranchInfo?.branchName || ''}
            </Box>
            <Box sx={{ margin: '16px 0', width: '200px', border: '1px solid #E5E5E5' }} />
            <Box className={'pdf-branch-address'}>
              {BranchInfo?.receiptFullAddress || ''}
            </Box>
            <Box className={'pdf-branch-phone'}>
              {BranchInfo?.branchPhone || ''}
            </Box>
            <Box sx={{ margin: '16px 0', width: '100%', border: '1px solid #E5E5E5' }} />
          </Box>

        </Box>
      </Box>
    </>
  )
}
