import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { Grid, MenuItem } from '@mui/material'
import { Col, Row } from 'react-bootstrap'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import ClinicApi, { DentistInterface } from 'api/master/clinic.api'
import TreatmentApi, { FindDoctorFeeProps } from 'api/dentists/treatment.api'
import OperativeApi from 'api/setting/treatments/operative.api'
import ManageDoctorFeeApi, { IFDoctorFeeInterface } from 'api/counter/manage-doctor-fee.api'

/** UTILS || SLICE */
import { numberToCurrencyFormat, getBranch, numberFormat } from 'utils/app.utils'
import { colors } from 'constants/theme'

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'
import InputTextField from 'component/Input/InputTextField'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import BasicSelect from 'component/Select/BasicSelect'

/** STYLE */
import * as UseStyled from './style'
import { notiError, notiSuccess } from 'component/notifications/notifications'

type FromDoctorFeeProps = {
  type: 'CLINIC_FEE' | 'DIAGNOSES'
  data: any
  onReset: () => void
}

interface IFOrderService {
  branchCnNumber: string
  branchCode: string
  cfAmount: number
  cfAmountNet: number
  cfUnit: 'BAHT' | 'PERCENT'
  cfDiscountAmount: number
  cfDiscountUnit: 'BAHT' | 'PERCENT'
  clinicFeeId: number
  clinicFeeName: string
  clinicFeePrice: number
  date: string
  dfAmount: number
  doctorId: number
  qty: number
  price: number
  discount: number
  discountUnit: 'BAHT' | 'PERCENT'
  additional: number
  additionalUnit: 'BAHT' | 'PERCENT'
  dfAmountNet: number
  dfDiscountAmount: number
  dfDiscountUnit: 'BAHT' | 'PERCENT'
  dfRemark: string
  dfUnit: 'BAHT' | 'PERCENT'
  doctorFullname: string
  labOrderId: number
  labOrderNumber: string
  labOrderPrice: number
  operativeId: number
  operativeName: string
  operativeTypeName: string
  operativeUnitName: string
  orderTreatmentId: number
  patientsFullname: string
  total: number
  updatedAt: string
  updatedBy: string
}
interface IFDiscountInterface {
  cfDiscountAmount: number
  cfDiscountUnit: 'BAHT' | 'PERCENT'
  discount: number
  discountUnit: 'BAHT' | 'PERCENT'
  additional: number
  additionalUnit: 'BAHT' | 'PERCENT'
  dfDiscountAmount: number
  dfDiscountUnit: 'BAHT' | 'PERCENT'
}
interface IFPatientInterface {
  branchCnNumber: string
  branchCode: string
  patientsFullname: string
}
export default function FromDoctorFee(props: FromDoctorFeeProps) {
  const { t } = useTranslation()
  const [orderService, setOrderService] = useState<IFOrderService>(props.data)
  /** TREATMENT DETAIL */

  const [patient, setPatient] = useState<IFPatientInterface>({ branchCode: orderService.branchCode, branchCnNumber: orderService.branchCnNumber, patientsFullname: orderService.patientsFullname })
  const [discountCurrent, setDiscountCurrent] = useState<IFDiscountInterface>({
    cfDiscountAmount: orderService.cfDiscountAmount,
    cfDiscountUnit: orderService.cfDiscountUnit,
    discount: orderService.discount,
    discountUnit: orderService.discountUnit,
    additional: orderService.additional,
    additionalUnit: orderService.additionalUnit,
    dfDiscountAmount: orderService.dfDiscountAmount,
    dfDiscountUnit: orderService.dfDiscountUnit
  })
  const [dataDefault, setDataDefault] = useState<IFDoctorFeeInterface>({
    qty: orderService.qty,
    dfAmount: orderService.dfAmount,
    dfAmountNet: orderService.dfAmountNet,
    dfUnit: orderService.dfUnit,
    cfAmount: orderService.cfAmount,
    cfAmountNet: orderService.cfAmountNet,
    cfUnit: orderService.dfUnit,
    dfRemark: orderService.dfRemark,
    doctorId: orderService.doctorId,
    clinicFeeId: orderService.clinicFeeId
  })
  const [dataUpdate, setDataUpdate] = useState<IFDoctorFeeInterface>({
    qty: orderService.qty,
    dfAmount: orderService.dfAmount,
    dfAmountNet: orderService.dfAmountNet,
    dfUnit: orderService.dfUnit,
    cfAmount: orderService.cfAmount,
    cfAmountNet: orderService.cfAmountNet,
    cfUnit: orderService.dfUnit,
    dfRemark: orderService.dfRemark,
    doctorId: orderService.doctorId,
    clinicFeeId: orderService.clinicFeeId
  })
  const [dataCurrent, setDataCurrent] = useState<IFDoctorFeeInterface>({
    qty: orderService.qty,
    dfAmount: orderService.dfAmount,
    dfAmountNet: orderService.dfAmountNet,
    dfUnit: orderService.dfUnit,
    cfAmount: orderService.cfAmount,
    cfAmountNet: orderService.cfAmountNet,
    cfUnit: orderService.dfUnit,
    dfRemark: orderService.dfRemark,
    doctorId: orderService.doctorId,
    clinicFeeId: orderService.clinicFeeId
  })

  const units: string[] = ['PERCENT', 'BAHT']

  const [doctors, setDoctors] = useState<DentistInterface[]>([])
  const [clinicFees, setClinicFees] = useState<any[]>([])

  const loadData = useCallback(() => {
    ClinicApi.findAllDentists().then(({ data }) => setDoctors([{ dentistId: 0, dentistFullname: '' }, ...data]))
  }, [])

  const loadClinicFees = useCallback(() => {
    OperativeApi.findById(orderService.operativeId).then(({ data }: any) => data && setClinicFees(data.operativeClinicFees))
  }, [orderService.operativeId])

  const loadDoctorFee = async (doctorId: number, type: 'change' | 'default') => {
    const condition: FindDoctorFeeProps = {
      operativeId: orderService.operativeId,
      branchId: Number(getBranch()),
      doctorId: doctorId
    }
    const resp = await TreatmentApi.findDoctorFee(condition)
    if (resp.status === 200 && resp.data) {
      if (type === 'change') resp.data && setDataUpdate((val) => ({ ...val, doctorId, dfAmount: resp.data.dfAmount, cfAmount: resp.data.cfAmount, dfUnit: resp.data.dfUnit, cfUnit: resp.data.dfUnit }))
      resp.data && setDataDefault((val) => ({ ...val, dfAmount: resp.data.dfAmount, cfAmount: resp.data.cfAmount, dfUnit: resp.data.dfUnit, cfUnit: resp.data.dfUnit }))
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    calculate()
  }, [dataUpdate.cfAmount, dataUpdate.dfAmount])

  useEffect(() => {
    loadClinicFees()
  }, [loadClinicFees])

  useEffect(() => {
    const orderService = { ...props.data, cfAmount: props.data?.cfAmount > 0 ? props.data?.cfAmount : 0, cfAmountNet: props.data?.cfAmountNet > 0 ? props.data?.cfAmountNet : 0 }
    setOrderService(orderService)
    console.log(props.data)

    /** TREATMENT DETAIL */

    setPatient({ branchCode: orderService.branchCode, branchCnNumber: orderService.branchCnNumber, patientsFullname: orderService.patientsFullname })
    setDiscountCurrent({
      cfDiscountAmount: orderService.cfDiscountAmount,
      cfDiscountUnit: orderService.cfDiscountUnit,
      discount: orderService.discount,
      discountUnit: orderService.discountUnit,
      additional: orderService.additional,
      additionalUnit: orderService.additionalUnit,
      dfDiscountAmount: orderService.dfDiscountAmount,
      dfDiscountUnit: orderService.dfDiscountUnit
    })
    setDataDefault({
      qty: orderService.qty,
      dfAmount: orderService.dfAmount,
      dfAmountNet: orderService.dfAmountNet,
      dfUnit: orderService.dfUnit,
      cfAmount: orderService.cfAmount,
      cfAmountNet: orderService.cfAmountNet,
      cfUnit: orderService.dfUnit,
      dfRemark: orderService.dfRemark,
      doctorId: orderService.doctorId,
      clinicFeeId: orderService.clinicFeeId
    })
    setDataUpdate({
      qty: orderService.qty,
      dfAmount: orderService.dfAmount,
      dfAmountNet: orderService.dfAmountNet,
      dfUnit: orderService.dfUnit,
      cfAmount: orderService.cfAmount,
      cfAmountNet: orderService.cfAmountNet,
      cfUnit: orderService.dfUnit,
      dfRemark: orderService.dfRemark,
      doctorId: orderService.doctorId,
      clinicFeeId: orderService.clinicFeeId
    })
    setDataCurrent({
      qty: orderService.qty,
      dfAmount: orderService.dfAmount,
      dfAmountNet: orderService.dfAmountNet,
      dfUnit: orderService.dfUnit,
      cfAmount: orderService.cfAmount,
      cfAmountNet: orderService.cfAmountNet,
      cfUnit: orderService.dfUnit,
      dfRemark: orderService.dfRemark,
      doctorId: orderService.doctorId,
      clinicFeeId: orderService.clinicFeeId
    })
    loadDoctorFee(orderService.doctorId, 'default')
  }, [props.data])

  const handleChang = (key: 'dfAmount' | 'dfUnit' | 'cfAmount', value: any) => {
    if (key === 'dfAmount') {
      let totalDf = value
      let totalCf = 0
      if (dataUpdate.dfUnit === 'PERCENT') {
        totalDf = totalDf > 100 ? 100 : totalDf
        totalCf = 100 - totalDf
      } else if (Number(orderService.total) > 0) totalCf = isMoreThanZero(Number(orderService.price) - totalDf)

      setDataUpdate({ ...dataUpdate, dfAmount: totalDf, cfAmount: totalCf })
    } else if (key === 'dfUnit') {
      let totalDf = dataUpdate.dfAmount
      let totalCf = 0
      if (value === 'PERCENT') {
        totalDf = totalDf > 100 ? 100 : totalDf
        totalCf = 100 - totalDf
      } else if (Number(orderService.total) > 0) totalCf = isMoreThanZero(Number(orderService.price) - totalDf)

      setDataUpdate({ ...dataUpdate, dfAmount: totalDf, cfAmount: totalCf, dfUnit: value, cfUnit: value })
    } else if (key === 'cfAmount') {
      let totalDf = 0
      let totalCf = value
      if (dataUpdate.dfUnit === 'PERCENT') {
        totalCf = totalCf > 100 ? 100 : totalCf
        totalDf = 100 - totalCf
      } else if (Number(orderService.total) > 0) totalDf = isMoreThanZero(Number(orderService.price) - totalCf)

      setDataUpdate({ ...dataUpdate, dfAmount: totalDf, cfAmount: totalCf })
    }
  }
  const calculate = () => {
    let totalDf = 0,
      totalCf = 0
    if (dataUpdate.cfUnit === 'BAHT') {
      totalDf = Number(dataUpdate.dfAmount) * Number(orderService.qty)
      totalCf = isMoreThanZero(Number(dataUpdate.cfAmount) * Number(orderService.qty))
    } else {
      totalDf = (Number(dataUpdate.dfAmount) / 100) * Number(orderService.total)
      totalCf = (Number(dataUpdate.cfAmount) / 100) * Number(orderService.total)
    }
    setDataUpdate({ ...dataUpdate, dfAmountNet: totalDf, cfAmountNet: totalCf })
  }

  const submit = () => {
    const { qty, ...data } = dataUpdate
    if (data.doctorId) {
      ManageDoctorFeeApi.update(orderService.orderTreatmentId, data)
        .then((res: any) => {
          if (res.status === 200) {
            notiSuccess(t(`MANAGE_DOCTOR_FEE.MESSAGE.EDIT_SUCCESS`), '', null, () => {
              props.onReset()
            })
          } else {
            const err = res.response?.data
            notiError(t(`${err.message}`))
          }
        })
        .catch((e) => {
          const err = e.response.data
          notiError(t(`${err.message}`))
        })
    } else {
      notiError(t(`HOME.TITLE.PLEASE_SELECT_DOCTOR`))
    }
  }

  const isMoreThanZero = (number: number) => {
    return number > 0 ? number : 0
  }

  return (
    <ModalCustom
      size={'xl'}
      title={t('MANAGE_DOCTOR_FEE.EDIT.TITLE')}
      alignFooter={'end'}
      // footerInline
      onSubmit={submit}
      onReset={props.onReset}
      textBtnConfirm={t('BUTTON.SAVE')}
      textBtnCancel={t('BUTTON.CANCEL')}
      component={
        <>
          <UseStyled.Body>
            <div>
              <Grid container spacing={2}>
                {/* ข้อมูลรายการ */}
                <Grid item lg={5} sm={12} spacing={2} className="p-2">
                  <Grid container spacing={2}>
                    <Grid item md={4} lg={3} xs={6}>
                      {t('MANAGE_DOCTOR_FEE.EDIT.FORM_LIST.OPERATIVE_NAME')}
                    </Grid>
                    <Grid item md={8} lg={9} xs={6}>
                      {orderService.operativeName}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={4} lg={3} xs={6}>
                      {t('MANAGE_DOCTOR_FEE.EDIT.FORM_LIST.PT')}
                    </Grid>
                    <Grid item md={8} lg={9} xs={6}>
                      {patient.branchCode}
                      {patient.branchCnNumber}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={4} lg={3} xs={6}>
                      {t('MANAGE_DOCTOR_FEE.EDIT.FORM_LIST.PATIENT_NAME')}
                    </Grid>
                    <Grid item md={8} lg={9} xs={6}>
                      {patient.patientsFullname}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={4} lg={3} xs={6}>
                      {t('MANAGE_DOCTOR_FEE.EDIT.FORM_LIST.DOCTOR_NAME')}
                    </Grid>
                    <Grid item md={8} lg={9} xs={6}>
                      {orderService.doctorFullname}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={4} lg={3} xs={6}>
                      {t('MANAGE_DOCTOR_FEE.EDIT.FORM_LIST.PRICE')}
                    </Grid>
                    <Grid item md={8} lg={9} xs={6} className="text-right">
                      {numberFormat(orderService.price)} บาท
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={4} lg={3} xs={6}>
                      {t('MANAGE_DOCTOR_FEE.EDIT.FORM_LIST.COUNT')}
                    </Grid>
                    <Grid item md={8} lg={9} xs={6}>
                      {orderService.qty} {orderService.operativeUnitName}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={4} lg={3} xs={6}>
                      {t('MANAGE_DOCTOR_FEE.EDIT.FORM_LIST.DISCOUNT')}
                    </Grid>
                    <Grid item md={8} lg={9} xs={6} className="text-right">
                      {numberFormat(discountCurrent.discount || 0)} {discountCurrent.discountUnit === 'BAHT' ? t('CURRENCY_CHANGE.TH') : '%'}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={4} lg={3} xs={6}>
                      {t('MANAGE_DOCTOR_FEE.EDIT.FORM_LIST.ADDITIONAL')}
                    </Grid>
                    <Grid item md={8} lg={9} xs={6} className="text-right">
                      {numberFormat(discountCurrent.additional || 0)} {discountCurrent.additionalUnit === 'BAHT' ? t('CURRENCY_CHANGE.TH') : '%'}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={4} lg={3} xs={6}>
                      {t('MANAGE_DOCTOR_FEE.EDIT.FORM_LIST.TOTAL_TREATMENT')}
                    </Grid>
                    <Grid item md={8} lg={9} xs={6} className="text-right">
                      {numberFormat(orderService.total || 0)} {t('CURRENCY_CHANGE.TH')}
                    </Grid>
                  </Grid>
                </Grid>
                {/* section df ปัจจุบัน */}
                <Grid item className="p-2 border " lg={7} sm={12} spacing={2}>
                  <Grid container>
                    <Grid item xs={12}>
                      <b>{t('MANAGE_DOCTOR_FEE.EDIT.FORM_DF.TITLE')}</b>
                    </Grid>
                    <Grid item md={6} sm={6} spacing={2}>
                      <Grid container spacing={2} className="p-2">
                        <Grid item className="text-right" xs={12}>
                          <Grid item className="text-right" xs={12}>
                            {t('MANAGE_DOCTOR_FEE.EDIT.FORM_DF.DF_FEE')}
                          </Grid>
                          <Grid container>
                            <Grid item xs={6}>
                              {' '}
                              {t('MANAGE_DOCTOR_FEE.EDIT.FORM_DF.DF')} :{' '}
                            </Grid>
                            <Grid item xs={6}>
                              {dataCurrent.dfUnit === 'BAHT' ? numberFormat(dataCurrent.dfAmount) : dataCurrent.dfAmount} {dataCurrent.dfUnit === 'BAHT' ? t('CURRENCY_CHANGE.TH') : '%'}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={6}>
                              {' '}
                              {t('MANAGE_DOCTOR_FEE.EDIT.FORM_DF.DISCOUNT')} :{' '}
                            </Grid>
                            <Grid item xs={6}>
                              {numberFormat(Number(discountCurrent.dfDiscountAmount), discountCurrent.dfDiscountUnit === 'BAHT' ? 2 : 0)} {discountCurrent.dfDiscountUnit === 'BAHT' ? t('CURRENCY_CHANGE.TH') : '%'}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={6}>
                              {' '}
                              {t('MANAGE_DOCTOR_FEE.EDIT.FORM_DF.DF_NET')} :{' '}
                            </Grid>
                            <Grid item xs={6}>
                              {' '}
                              {numberFormat(dataCurrent.dfAmountNet)} {t('CURRENCY_CHANGE.TH')}{' '}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      md={6}
                      sm={6}
                      spacing={2}
                      sx={{
                        borderLeft: '1px solid',
                        borderLeftColor: `${colors.themeMainColor} !important`
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item className="text-right" xs={12}>
                          <Grid item xs={12}>
                            {t('MANAGE_DOCTOR_FEE.EDIT.FORM_CF.CF_FEE')}
                          </Grid>
                          <Grid container>
                            <Grid item xs={6}>
                              {' '}
                              {t('MANAGE_DOCTOR_FEE.EDIT.FORM_CF.CF')} :{' '}
                            </Grid>
                            <Grid item xs={6}>
                              {dataCurrent.dfUnit === 'BAHT' ? numberFormat(dataCurrent.cfAmount) : dataCurrent.cfAmount} {dataCurrent.dfUnit === 'BAHT' ? t('CURRENCY_CHANGE.TH') : '%'}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={6}>
                              {' '}
                              {t('MANAGE_DOCTOR_FEE.EDIT.FORM_CF.DISCOUNT')} :{' '}
                            </Grid>
                            <Grid item xs={6}>
                              {discountCurrent.cfDiscountUnit === 'BAHT' ? numberFormat(discountCurrent.cfDiscountAmount) : discountCurrent.cfDiscountAmount} {discountCurrent.cfDiscountUnit === 'BAHT' ? t('CURRENCY_CHANGE.TH') : '%'}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={6}>
                              {' '}
                              {t('MANAGE_DOCTOR_FEE.EDIT.FORM_CF.CF_NET')} :{' '}
                            </Grid>
                            <Grid item xs={6}>
                              {numberFormat(dataCurrent.cfAmountNet)} {t('CURRENCY_CHANGE.TH')}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      {t('MANAGE_DOCTOR_FEE.EDIT.FORM_REMARK.TITLE')} : {dataCurrent.dfRemark || '-'}
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          {t('MANAGE_DOCTOR_FEE.EDIT.FORM_REMARK.REDUCED_AMOUNT')} :
                        </Grid>
                        <Grid item xs={6} className="p-2 text-right">
                          {numberFormat(isMoreThanZero(orderService.price * orderService.qty - (dataCurrent.dfAmountNet + dataCurrent.cfAmountNet)))} {t('CURRENCY_CHANGE.TH')}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          {t('MANAGE_DOCTOR_FEE.EDIT.FORM_REMARK.RECEIVED_AMOUNT')} :
                        </Grid>
                        <Grid item xs={6} className="p-2 text-right">
                          {numberFormat(dataCurrent.dfAmountNet + dataCurrent.cfAmountNet)} {t('CURRENCY_CHANGE.TH')}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* section แก้ไข DF */}
                <Grid item lg={12} sm={12} spacing={2}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container className="p-2 border-top">
                        <Grid item lg={12} sm={12} spacing={2}>
                          <UseStyled.Title>
                            {t('MANAGE_DOCTOR_FEE.EDIT.FORM_DF.TOTAL_PRICE')} {numberToCurrencyFormat(orderService.total)} {t('CURRENCY_CHANGE.TH')}
                          </UseStyled.Title>
                        </Grid>
                        <Grid item lg={12} sm={12} spacing={2}>
                          <UseStyled.SubTitle>{t('MANAGE_DOCTOR_FEE.EDIT.FORM_DF.FIX_DOCTOR_DFCF')}</UseStyled.SubTitle>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6} maxWidth={250} className="py-2">
                      <AutocompleteSelect
                        labelId="sl-dentist"
                        options={doctors}
                        getOptionLabel={(option: DentistInterface) => option.dentistFullname}
                        onchange={(e, value) => {
                          loadDoctorFee(value?.dentistId || 0, 'change')
                        }}
                        noOptionsText={t('NOT_FOUND')}
                        filterOptions={(option) => option.dentistFullname}
                        value={_.find(doctors, { dentistId: dataUpdate.doctorId }) || ''}
                        onOpen={() => setDataUpdate({ ...dataUpdate, doctorId: 0 })}
                        disableClearable
                      />
                    </Grid>
                    <Grid item xs={12} className="w-100">
                      <BasicSelect
                        name="clinicFeeId"
                        labelId="lb-clinic-fee"
                        selectId="sl-clinic-fee"
                        label={t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.CLINIC_FEE')}
                        value={dataUpdate.clinicFeeId}
                        options={_.map(clinicFees, (clinic: any, index: number) => {
                          return (
                            <MenuItem key={index} value={clinic.clinicFeeId}>
                              {clinic.clinicFeeText} ({clinic.price} {t('CURRENCY_CHANGE.TH')})
                            </MenuItem>
                          )
                        })}
                        onchange={(event) => {
                          const value = event.target.value
                          if (value) setDataUpdate({ ...dataUpdate, clinicFeeId: value })
                        }}
                        allowClear
                        onClear={() => setDataUpdate({ ...dataUpdate, clinicFeeId: 0 })}
                      />
                    </Grid>
                    <Grid container className="border">
                      <Grid item md={6} sm={6} spacing={2}>
                        <Grid container spacing={2} className="p-2">
                          <Grid item className="text-right" xs={12}>
                            <Grid item className="text-right" xs={12}>
                              {t('MANAGE_DOCTOR_FEE.EDIT.FORM_DF.DF_FEE')}
                            </Grid>
                            <Grid container>
                              <Grid item xs={6}>
                                {' '}
                                {t('MANAGE_DOCTOR_FEE.EDIT.FORM_DF.DEFAULT')} :{' '}
                              </Grid>
                              <Grid item xs={6}>
                                {dataDefault.dfUnit === 'BAHT' ? numberFormat(dataDefault.dfAmount) : dataDefault.dfAmount} {dataDefault.dfUnit === 'BAHT' ? t('CURRENCY_CHANGE.TH') : '%'}
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={6}>
                                {' '}
                                {t('MANAGE_DOCTOR_FEE.EDIT.FORM_DF.CURRENT')} :{' '}
                              </Grid>
                              <Grid item xs={6}>
                                {dataCurrent.dfUnit === 'BAHT' ? numberFormat(dataCurrent.dfAmount) : dataCurrent.dfAmount} {dataCurrent.dfUnit === 'BAHT' ? t('CURRENCY_CHANGE.TH') : '%'}
                              </Grid>
                            </Grid>
                            <Grid container className="align-items-center justify-content-center">
                              <Grid item xs={6}>
                                {' '}
                                {t('MANAGE_DOCTOR_FEE.EDIT.FORM_DF.FIX_DF')} :{' '}
                              </Grid>
                              <Grid item xs={6}>
                                <Row>
                                  <Col>
                                    <InputTextField key={`newDfAmount`} name={`newDfAmount`} label={''} value={dataUpdate.dfAmount} onchange={(e: any) => handleChang('dfAmount', e.target.value)} type="number" hideControl />
                                  </Col>
                                  <Col sm={6} md={6}>
                                    <BasicSelect
                                      key={`new-df-unit`}
                                      labelId="lb-new-df-unit"
                                      selectId="sl-new-df-unit"
                                      label=""
                                      value={dataUpdate.dfUnit}
                                      options={_.map(units, (unit: string, index: number) => {
                                        return (
                                          <MenuItem key={index} value={unit}>
                                            {' '}
                                            {unit === 'PERCENT' ? '%' : t('CURRENCY_CHANGE.TH')}{' '}
                                          </MenuItem>
                                        )
                                      })}
                                      onchange={(event) => {
                                        const value = event.target.value
                                        if (value) handleChang('dfUnit', value)
                                      }}
                                    />
                                    {dataUpdate.dfUnit === 'BAHT' ? `ต่อ${orderService.operativeUnitName || 'ครั้ง'}` : ''}
                                  </Col>
                                </Row>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={6}>
                                {' '}
                                {t('MANAGE_DOCTOR_FEE.EDIT.FORM_DF.DF_NET')} :{' '}
                              </Grid>
                              <Grid item xs={6}>
                                {' '}
                                {numberFormat(dataUpdate.dfAmountNet)} {t('CURRENCY_CHANGE.TH')}{' '}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        md={6}
                        sm={6}
                        spacing={2}
                        className="p-2"
                        sx={{
                          borderLeft: '1px solid',
                          borderLeftColor: `${colors.themeMainColor} !important`
                        }}
                      >
                        <Grid container spacing={2} className="p-2">
                          <Grid item className="text-right" xs={12}>
                            <Grid item xs={12}>
                              {t('MANAGE_DOCTOR_FEE.EDIT.FORM_CF.CF_FEE')}
                            </Grid>
                            <Grid container>
                              <Grid item xs={6}>
                                {' '}
                                {t('MANAGE_DOCTOR_FEE.EDIT.FORM_CF.DEFAULT')} :{' '}
                              </Grid>
                              <Grid item xs={6}>
                                {dataDefault.dfUnit === 'BAHT' ? numberFormat(isMoreThanZero(dataDefault.cfAmount)) : isMoreThanZero(dataDefault.cfAmount)} {dataDefault.dfUnit === 'BAHT' ? t('CURRENCY_CHANGE.TH') : '%'}
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={6}>
                                {' '}
                                {t('MANAGE_DOCTOR_FEE.EDIT.FORM_CF.CURRENT')} :{' '}
                              </Grid>
                              <Grid item xs={6}>
                                {dataCurrent.cfUnit === 'BAHT' ? numberFormat(dataCurrent.cfAmount) : dataCurrent.cfAmount} {dataCurrent.cfUnit === 'BAHT' ? t('CURRENCY_CHANGE.TH') : '%'}
                              </Grid>
                            </Grid>
                            <Grid container className="align-items-center justify-content-center">
                              <Grid item xs={6}>
                                {' '}
                                {t('MANAGE_DOCTOR_FEE.EDIT.FORM_CF.FIX_CF')} :{' '}
                              </Grid>
                              <Grid item xs={6}>
                                <Row>
                                  <Col>
                                    <InputTextField key={`newCfAmount`} name={`newCfAmount`} label={''} value={dataUpdate.cfAmount} onchange={(e: any) => handleChang('cfAmount', e.target.value)} type="number" hideControl />
                                  </Col>
                                  <Col sm={6} md={6}>
                                    <BasicSelect
                                      disabled
                                      key={`new-cf-unit`}
                                      labelId="lb-new-cf-unit"
                                      selectId="sl-new-cf-unit"
                                      label=""
                                      value={dataUpdate.cfUnit}
                                      options={_.map(units, (unit: string, index: number) => {
                                        return (
                                          <MenuItem key={index} value={unit}>
                                            {' '}
                                            {unit === 'PERCENT' ? '%' : t('CURRENCY_CHANGE.TH')}{' '}
                                          </MenuItem>
                                        )
                                      })}
                                      onchange={(event) => {
                                        const value = event.target.value
                                        if (value) setDataUpdate({ ...dataUpdate, cfUnit: value })
                                      }}
                                    />
                                    {dataUpdate.cfUnit === 'BAHT' ? `ต่อ${orderService.operativeUnitName || 'ครั้ง'}` : ''}
                                  </Col>
                                </Row>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={6}>
                                {' '}
                                {t('MANAGE_DOCTOR_FEE.EDIT.FORM_CF.CF_NET')} :{' '}
                              </Grid>
                              <Grid item xs={6}>
                                {numberFormat(dataUpdate.cfAmountNet)} {t('CURRENCY_CHANGE.TH')}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      {t('MANAGE_DOCTOR_FEE.EDIT.FORM_REMARK.TITLE')} : {dataUpdate.dfRemark || '-'}
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          {t('MANAGE_DOCTOR_FEE.EDIT.FORM_REMARK.REDUCED_AMOUNT')} :
                        </Grid>
                        <Grid item xs={6} className="  text-right">
                          {numberFormat(isMoreThanZero(orderService.price * orderService.qty - (dataUpdate.dfAmountNet + dataUpdate.cfAmountNet)))} {t('CURRENCY_CHANGE.TH')}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          {t('MANAGE_DOCTOR_FEE.EDIT.FORM_REMARK.RECEIVED_AMOUNT')} :
                        </Grid>
                        <Grid item xs={6} className="  text-right">
                          {numberFormat(dataUpdate.dfAmountNet + dataUpdate.cfAmountNet)} {t('CURRENCY_CHANGE.TH')}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </UseStyled.Body>
        </>
      }
    />
  )
}
