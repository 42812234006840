import { useRef, useState, useEffect, useCallback } from 'react';
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { routeName } from 'routes/routes-name'
import { useHistory } from 'react-router-dom';
import { Box, MenuItem } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next';

/** API */
import ReportApi, { FindAllReportInterface } from 'api/report.api';
import ClinicApi, { BranchInterface } from 'api/master/clinic.api';

/** CONSTANT */
import { getBranch } from 'utils/app.utils';

/** COMPONENT */
import Loading from 'component/Loading';
import FucntionPrint from "component/Print/print";
import TreatmentRightList from './TreatmentRightList';
import HeaderCard from 'component/CardCustom/HeaderCard';
import FilterSelect from 'component/Select/FilterSelect';
import { useDownloadExcel } from 'component/Excel/hooks/useExcel';
import InputSecondNewRangePicker from 'component/Input/InputSecondNewRangePicker';

/** STYLE */
import * as UseStyled from 'features/report/useStyled';
import SearchPatients from 'component/SearchPatients';
import ButtonCustom from 'component/Button/ButtonCustom';
import { features } from 'process';
import InputTextSearch from 'component/Input/InputTextSearch';
import { width } from 'pdfkit/js/page';

export default function TreatmentRight() {
    const { t } = useTranslation();
    const history = useHistory()
    const componentRef = useRef<HTMLDivElement>(null)
    const emptyRangePicker = ['', '']
    const momentNowRange = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    const momentDate = () => momentNowRange

    const [branches, setBranches] = useState<any[]>([])
    const [branchId, setBranchId] = useState<any>(getBranch());
    const [treatmentRightData, setTreatmentRightdata] = useState<any[]>([])
    const [exportDataTreatmentRight, setExportDataTreatmentRight] = useState<any[]>([])
    const [rowCountTreatment, setRowCountTreatment] = useState<number>(0)
    const [pageTreatment, setPageTreatment] = useState<number>(1)
    const [pageLimitTreatment, setPageLimitTreatment] = useState<number>(10)
    const [sortBy, setSortBy] = useState<string>('createAt');
    const [sortType, setSortType] = useState<string>('ASC');
    const [rangeDate, setRangeDate] = useState<string[]>(momentDate);
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState('');
    const [fetch, setFetch] = useState<boolean>(false)
    const verifyPermission = async () => {
        const condition: FindAllReportInterface = {
            page: 1, pageLimit: 200
        }
        ReportApi.reportList(condition)
            .then(({ data }) => {
                const check = _.find(data, (val: any) => String(val.reportKey) === 'TREATMENT_RIGHT_MONTH') ? true : false
                if (!check) {
                    history.push(routeName.report)
                    return false
                }
            })

        ClinicApi.findAllBranches()
            .then(({ data }) => setBranches(data))
            .catch((e) => { return })

        return true
    }

    useEffect(() => {
        verifyPermission()
    }, [])

    const fetchTreatmentRight = useCallback(() => {
        let condition: FindAllReportInterface = {
            page: pageTreatment, pageLimit: pageLimitTreatment, branchId,
        }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (sortBy) condition = { ...condition, sortBy: sortBy }

        if (rangeDate[0] !== null && rangeDate[1] !== null)
            condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
        if (search) condition = { ...condition, search: search }

        ReportApi.reportTreatmentRightMonth(condition)
            .then(({ headers, data }) => {
                setTreatmentRightdata(data.rows)
                setRowCountTreatment(headers['x-total'])
            }).catch((e) => setLoading(false))
            .finally(() => {
                setLoading(false)
                setFetch(false)
            })

    }, [pageTreatment, pageLimitTreatment, sortType, sortBy, fetch])


    const fetchTreatmentRightExport = useCallback(() => {
        let condition: FindAllReportInterface = {
            page: 1, pageLimit: 10000,
            sortType: sortType, sortBy: sortBy, branchId, export: 1
        }
        if (search) condition = { ...condition, search: search }
        if (rangeDate[0] !== null && rangeDate[1] !== null)
            condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }

        ReportApi.reportTreatmentRightMonth(condition)
            .then(({ data }) => {
                setExportDataTreatmentRight(data.rows)
            }).catch((e) => setLoading(false))
            .finally(() => {
                setLoading(false)
                setFetch(false)
            })

    }, [pageTreatment, pageLimitTreatment, sortType, sortBy, fetch])

    const filter = [
        branchId === 0 ? `${t(`REPORT.FILTER.ALL`)}` : `${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`,
        rangeDate[0],
        rangeDate[1],
    ]
    const { onDownload } = useDownloadExcel({
        tableExportRef: componentRef.current,
        filename: `${t(`REPORT.REPORT_NAME.TREATMENT_RIGHT_MONTH`)} ${rangeDate[0] ? `(${rangeDate[0]}-${rangeDate[1]})` : ''} `,
        sheetName: `${t(`REPORT.REPORT_NAME.TREATMENT_RIGHT_MONTH`)}`
    })

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangeRowsPerPage = (limit: number) => setPageLimitTreatment(limit)

    const handleChangePage = (val: number) => setPageTreatment(val)

    // UseEffect//
    useEffect(() => {
        fetchTreatmentRight()
    }, [fetchTreatmentRight])

    useEffect(() => {
        fetchTreatmentRightExport()
    }, [fetchTreatmentRightExport])

    return (
        <div>
            <Card className={'border-0 h-100'}>
                <HeaderCard text={t(`REPORT.REPORT_NAME.TREATMENT_RIGHT_MONTH`)} />
                <Card.Body>
                    <Card.Title className={'mb-0'}>
                        <Row className='mx-0'>
                            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                                <InputTextSearch
                                    keyInput={'search'}
                                    label={t('INPUT.SEARCH')}
                                    value={search}
                                    onchange={(event) => {
                                        setSearch(event.target.value)
                                        setPageTreatment(1)
                                    }}
                                    onClear={(event) => {
                                        setSearch('')
                                        setPageTreatment(1)
                                    }}
                                />
                            </Col>
                            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                                <FilterSelect
                                    onchange={(e) => {
                                        setBranchId(e.target.value)
                                        setPageTreatment(1)
                                    }}
                                    renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                                    label={''}
                                    selectId="select-branch"
                                    labelId="label-branch"
                                    value={branchId}
                                    options={
                                        _.map(branches, (b: BranchInterface) => {
                                            return (
                                                <MenuItem key={b.branchId} value={b.branchId}>
                                                    {b.branchName || b.branchNameEn}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                    formControlStyle={{ maxWidth: 'unset !important' }}
                                    classesOption="style-select-doctors"
                                />
                            </Col>

                            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
                                <InputSecondNewRangePicker
                                    inputHeight={32}
                                    value={rangeDate || emptyRangePicker}
                                    onchange={(val: any) => {
                                        if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                                        else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                                        setPageTreatment(1)
                                    }}
                                    label={''}
                                    allowClear
                                    onClear={() => setRangeDate(emptyRangePicker)}

                                />
                            </Col>
                            <Col sm={6} md={1} xl={1} className={'px-1'}>
                                <ButtonCustom onClick={() => {
                                    setFetch(true);
                                    setPageTreatment(1);
                                }}
                                    textButton={t('INPUT.SEARCH')} style={{ height: '30px', marginTop: '5px', width: '100%' }}
                                />
                            </Col>
                            <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
                                <UseStyled.DropdownDocument>
                                    <Dropdown.Toggle id="dropdown-basic" className='ml-xl-auto'>
                                        {t('PRINT_DOCUMENT')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={onDownload}>
                                            {t('Excel')}
                                        </Dropdown.Item>
                                        <FucntionPrint
                                            content={() => componentRef.current}
                                            docTitle={t(`REPORT.REPORT_NAME.TREATMENT_RIGHT_MONTH`)}
                                            removeIfram
                                            trigger={() => <Dropdown.Item >
                                                {t('PDF')}
                                            </Dropdown.Item>}
                                            style={`@page { size: 793.7007874px 1122.519685px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                                        />
                                    </Dropdown.Menu>
                                </UseStyled.DropdownDocument>
                            </Col>
                        </Row>
                    </Card.Title>
                    <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
                        <TreatmentRightList
                            filter={filter}
                            page={pageTreatment}
                            pageLimit={pageLimitTreatment}
                            rowCount={rowCountTreatment}
                            sortBy={sortBy}
                            sortType={sortType}
                            data={treatmentRightData}
                            onRequestSort={onRequestSort}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleChangePage={handleChangePage}
                            componentRef={componentRef}
                            exportData={exportDataTreatmentRight} />
                    </Box>
                </Card.Body>
            </Card >
            <Loading show={loading} type='fullLoading' />
        </div>
    )
}