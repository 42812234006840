import { setToothChart as setToothChartSlice } from "app/slice/treatment.slice"
import $ from 'jquery';
import { TEETH_OPERATIVES } from "component/ToothChart/ToothChart";
import _ from "lodash";
import store from "app/store";

export const OPTION_TOOTHS = [
    'U', 'Ant-U', 'Post-U', 'Q1', 'Q2', 'L', 'Ant-L', 'Post-L', 'Q3', 'Q4',
    "18", "17", "16", "15", "14", "13", "12", "11", "21", "22", "23", "24", "25", "26", "27", "28",
    "38", "37", "36", "35", "34", "33", "32", "31", "41", "42", "43", "44", "45", "46", "47", "48"
]

export function handleSelected(teeth: any, dispatch: any, setIsSelected: any, teethNumber: any, setPositions: any) {
    let selected: any = teeth[teethNumber]?.isSelect
    if (selected) selected = false
    else selected = true
    dispatch(setToothChartSlice({ key: teethNumber, value: { ...teeth[teethNumber], teeth: teethNumber, isSelect: selected } }))
    setIsSelected(selected)
    if (selected) $(`.div-teeth-${teethNumber} svg`).addClass('active')
    else {
        $(`.div-teeth-${teethNumber} svg`).removeClass('active')
        setPositions([])
    }
}

export function handlePositionSelected(teeth: any, isSelected: any, teethNumber: any, dispatch: any, setToothChart: any, setPositions: any, position: any) {
    if (isSelected) {
        dispatch(setToothChartSlice({ key: teethNumber, value: { ...teeth[teethNumber], teeth: teethNumber, positions: position } }))
        setPositions(position)
    } else {
        dispatch(setToothChartSlice({ key: teethNumber, value: { ...teeth[teethNumber], teeth: teethNumber, positions: [] } }))
        setPositions([])
    }
}

export function renderOperative(isSelected: any, dispatch: any, toothObj: { teethNumber: any, teeth: any, color: any, operative: any, setColor: any, setIsLowerTeeth: any, setIsXRay: any, setIsToothRoot: any, setIsOrthodontics: any, setIsExtract: any, setIsFixture: any, setIsBridge: any, setIsTooth: any }) {
    toothObj.setIsLowerTeeth(false)
    toothObj.setIsXRay(false)
    toothObj.setIsToothRoot(false)
    toothObj.setIsOrthodontics(false)
    toothObj.setIsExtract(false)
    toothObj.setIsFixture(false)
    toothObj.setIsBridge(false)
    toothObj.setIsTooth(false)

    if (isSelected) {
        toothObj.setColor(toothObj.color)
        dispatch(setToothChartSlice({ key: toothObj.teethNumber, value: { ...toothObj.teeth[toothObj.teethNumber], teeth: toothObj.teethNumber, operative: toothObj.operative, color: toothObj.color } }))
        if (toothObj.operative === TEETH_OPERATIVES.LOWER_TEETH) toothObj.setIsLowerTeeth(true)
        else if (toothObj.operative === TEETH_OPERATIVES.X_RAY) toothObj.setIsXRay(true)
        else if (toothObj.operative === TEETH_OPERATIVES.TOOTH_ROOT) toothObj.setIsToothRoot(true)
        else if (toothObj.operative === TEETH_OPERATIVES.ORTHODONTICS) toothObj.setIsOrthodontics(true)
        else if (toothObj.operative === TEETH_OPERATIVES.EXTRACT) toothObj.setIsExtract(true)
        else if (toothObj.operative === TEETH_OPERATIVES.FIXTURE) toothObj.setIsFixture(true)
        else if (toothObj.operative === TEETH_OPERATIVES.BRIDGE) toothObj.setIsBridge(true)
        else if (toothObj.operative === TEETH_OPERATIVES.TOOTH) toothObj.setIsTooth(true)
    } else {
        toothObj.setColor(null)
        dispatch(setToothChartSlice({ key: toothObj.teethNumber, value: { ...toothObj.teeth[toothObj.teethNumber], teeth: toothObj.teethNumber, operative: ``, color: `` } }))
    }
}

export function renderOperativeHistory(isSelected: any, toothObj: { teethNumber: any, historyToothChart: any, setTreatmentHistory: any, setIsLowerTeeth: any, setIsXRay: any, setIsToothRoot: any, setIsOrthodontics: any, setIsExtract: any, setIsFixture: any, setIsBridge: any, setIsTooth: any, setPositions: any, isEditTootCharts: any }) {
    const newArray: any = []
    const state: any = store.getState().treatment
    const teeth = state.toothChart
    const toothChartPosition: any = state.toothChartPositions

    if (!isSelected) {
        _.map(toothObj.historyToothChart, (selectedHistory: any, indexData: number) => {
            const result = _.find(selectedHistory, function (n) {
                if (n.teeth.toString() === toothObj.teethNumber.toString()) return true;
            });

            if (result) {
                const operative = result.operative
                if (operative === TEETH_OPERATIVES.LOWER_TEETH) toothObj.setIsLowerTeeth(true)
                else if (operative === TEETH_OPERATIVES.X_RAY) toothObj.setIsXRay(true)
                else if (operative === TEETH_OPERATIVES.TOOTH_ROOT) toothObj.setIsToothRoot(true)
                else if (operative === TEETH_OPERATIVES.ORTHODONTICS) toothObj.setIsOrthodontics(true)
                else if (operative === TEETH_OPERATIVES.EXTRACT) toothObj.setIsExtract(true)
                else if (operative === TEETH_OPERATIVES.FIXTURE) toothObj.setIsFixture(true)
                else if (operative === TEETH_OPERATIVES.BRIDGE) toothObj.setIsBridge(true)
                else if (operative === TEETH_OPERATIVES.TOOTH) toothObj.setIsTooth(true)
                newArray[operative] = { ...result, teeth: result.teeth.toString() }
                if (toothObj.isEditTootCharts === null) toothObj.setPositions(result?.positions)
            }
        })

        let teethPosition: any = {}
        _.map(toothObj.historyToothChart, (selectedHistory: any, indexData: number) => {
            if (_.find(selectedHistory, { teeth: Number(toothObj.teethNumber) })) teethPosition = _.find(selectedHistory, { teeth: Number(toothObj.teethNumber) })
        })

        if (toothChartPosition.includes(Number(toothObj.teethNumber))) {
            toothObj.setPositions(teeth[Number(toothObj.teethNumber)]?.positions || [])
        } else {
            toothObj.setPositions(teethPosition.positions)
        }
    }

    toothObj.setTreatmentHistory(newArray)
}
