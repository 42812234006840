import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart, TreatmentStateInterface } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/43.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethL, TextNumberL } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
  height: 'auto',
  marginBottom: 'auto',
  top: '1.4%'
}))

export default function Teeth43(props: TeethProps) {
  const teeth = useSelector(toothChart)
  const teethNumber = props.teethNumber
  const historyToothChart = props.historyToothChart
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const isEditTootCharts: any = useSelector(isEditTootChart)

  const dispatch = useDispatch()

  const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
  const [positions, setPositions]: any = useState([])
  const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

  const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
  const [isTooth, setIsTooth]: any = useState(false)
  const [isXRay, setIsXRay]: any = useState(false)
  const [isToothRoot, setIsToothRoot]: any = useState(false)
  const [isOrthodontics, setIsOrthodontics]: any = useState(false)
  const [isExtract, setIsExtract]: any = useState(false)
  const [isFixture, setIsFixture]: any = useState(false)
  const [isBridge, setIsBridge]: any = useState(false)

  const [color, setColor]: any = useState(``)
  const [styleSvg, setStyleSvg]: any = useState({ width: 38.262, height: 93.001, viewBox: "0 0 38.262 93.001" })

  const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

  useEffect(() => {
    const operative = props.operative || ``
    const valColor = props.color || colors.themeSecondColor
    const selected = teeth[teethNumber]?.isSelect || false
    dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
    setIsSelected(selected)

    renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

    if (props.type === 'treatment') {
      renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
    }

    if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

    return setStyleSvg({ width: "31.289", height: "101.234", viewBox: "0 0 31.289 101.234" })
  }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

  useEffect(() => {
    const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
    const teethJson = treatmentTooth?.teethJson

    if (isEditTootCharts !== null) {
      if (teethJson) {
        const tooths = JSON.parse(teethJson)
        setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
        if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
      } else setIsEditTeeth(false)
    }
  }, [isEditTootCharts]);

  useEffect(() => {
    if (isSelected) {
      dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
      if (teeth[props.teethNumber]?.isSelect) {
        setIsSelected(true)
      } else {
        setIsSelected(false)
      }
    }
  }, [props.historyToothChart]);

  useEffect(() => {
    if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
  }, [isSelected, isEditTootCharts]);

  return (
    <Div>
      <TextNumberL>43</TextNumberL>
      <PositionTeeth quarter={QUARTER_NAME_TYPE.Q4} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
      <DivTeethL className={`div-teeth-${props.teethNumber} `}>
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray position='L' />) || ''}
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge position='L' />) || ''}
        {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`sm teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
          <svg className={`sm ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
            <g data-name="Group 9180">
              {(isBridge && props.type === 'treatment') ?
                <g></g>
                : ''}
              <path
                data-name="Path 5363"
                d="M392.464 592.5a1.972 1.972 0 0 1-3.463.6 4.827 4.827 0 0 1-.786-3.139c.954-19.477-5.221-38.226-5.743-57.548-.155-5.627-.148-11.279-1.334-16.751h.006l-.213-3.662a1.969 1.969 0 0 1 1.965-2.083h1.034a1.967 1.967 0 0 1 1.926 1.575c1.119 5.422 3.55 11.135 6.221 13.552a1.957 1.957 0 0 0 2.006.359c7.026-2.673 9.49-9.558 13.069-15.486q.184-.319.387-.638a1.351 1.351 0 0 1 .122.638c.419 7.323-11.261 70.062-15.197 82.583z"
                transform="translate(-380.007 -492.666)"
                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
              />

              {(isLowerTeeth && props.type === 'treatment') ?
                <path
                  data-name="Path 6499"
                  d="M392.464 592.5a1.972 1.972 0 0 1-3.463.6 4.827 4.827 0 0 1-.786-3.139c.954-19.477-5.221-38.226-5.743-57.548-.1-3.539-.163-7.453-.5-11.269.219.938 1.172 4.313 4.364 5.605a5.89 5.89 0 0 0 3.167.238c.922-.234 1.605-.561 2.609-.844a4.935 4.935 0 0 1 2.109-.3c2.344.547 2.688 1.266 3.627 1.616 2.451 1.462 4.451 1.259 6.388-.194.25-.312.707-.692 1.063-1.125.313-.625.244-.8.7-2-2.478 16.494-9.599 55.839-13.535 68.36z"
                  transform="translate(-380.007 -492.666)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                /> : ''}

              <g data-name="Group 9707">
                <path
                  data-name="Path 5364"
                  d="M384.722 517.033a7.838 7.838 0 0 1-3.2-4.869c-.915-4.156-3.019-13.308-1.482-18.421 2.136-7.11 3.609-6.036 9.883-8.573 3.865-1.563 6.714-1.933 7.949-1.5 4.089 1.422 6.23 3.432 9.8 5.243 3.819 1.939 5.385 3.464-2.8 27.977 0 0-2.2 3.121-5.427 2.315s-4.512-3.545-8.218-2.095c-2.596 1.012-4.506 1.264-6.505-.077z"
                  transform="translate(-379.498 -483.5)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                />
                <path
                  data-name="Path 5365"
                  d="M386.3 517.646a6.957 6.957 0 0 1-2.836-4.319c-.811-3.688-2.678-11.809-1.315-16.345 1.895-6.308 3.2-5.355 8.769-7.607 3.429-1.387 5.957-1.716 7.053-1.334 3.628 1.261 5.528 3.045 8.693 4.651 3.388 1.72 4.778 3.074-2.487 24.823 0 0-1.956 2.769-4.815 2.055s-4-3.145-7.291-1.859c-2.303.9-3.997 1.124-5.771-.065z"
                  transform="translate(-380.268 -485.062)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                />
                <path
                  data-name="Path 5366"
                  d="M389.062 518a6.163 6.163 0 0 1-2.512-3.827c-.719-3.267-2.373-10.461-1.166-14.48 1.679-5.589 2.838-4.745 7.769-6.739 3.038-1.228 5.277-1.52 6.248-1.182 3.214 1.118 4.9 2.7 7.7 4.121 3 1.524 4.233 2.723-2.2 21.991 0 0-1.733 2.453-4.266 1.82s-3.547-2.787-6.459-1.647c-2.041.8-3.542.998-5.114-.057z"
                  transform="translate(-381.438 -486.396)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                />

                {(isToothRoot && props.type === 'treatment') ?
                  <path
                    data-name="Path 6566"
                    d="M8 10.389c-.018.473.39 31.01 0 42.083-.185 5.242-1.832 19.9-1.545 20.254-.032.281 1.864-14.861 2.19-20.254.687-11.344 1.6-41.944 1.636-42.208A3.848 3.848 0 0 0 8 10.389z"
                    transform="translate(4.395 23.506)"
                    style={{

                      fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                      stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                    }}
                  />
                  : ''}
                {(isExtract && props.type === 'treatment') ?
                  <>
                    <g data-name="Group 9470">
                      <path data-name="Path 6581" d="m33.013 7.5-26.767 72" transform="translate(-4.316 .285)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                      <path data-name="Path 6582" d="m7.012 7.5 26.776 72" transform="translate(-4.316 .285)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                    </g>
                  </>
                  : ''}

                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                  <>
                    <defs>
                      <linearGradient id="xer24sd2ia" x1=".5" y1=".453" x2="-1.326" y2="4.622" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#b0b1b1" />
                        <stop offset={1} stopColor="#585959" />
                      </linearGradient>
                      <linearGradient id="lb18hb853b" x1=".5" y1="1.834" x2=".5" y2=".046" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#c1c4c5" />
                        <stop offset={1} stopColor="#9e9e9e" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9367">
                      <path data-name="Path 5363" d="M392.464 592.5a1.972 1.972 0 0 1-3.463.6 4.827 4.827 0 0 1-.786-3.139c.954-19.477-5.221-38.226-5.743-57.548-.155-5.627-.148-11.279-1.334-16.751h.006l-.213-3.662a1.969 1.969 0 0 1 1.965-2.083h1.034a1.967 1.967 0 0 1 1.926 1.575c1.119 5.422 3.55 11.135 6.221 13.552a1.957 1.957 0 0 0 2.006.359c7.026-2.673 9.49-9.558 13.069-15.486q.184-.319.387-.638a1.351 1.351 0 0 1 .122.638c.419 7.323-11.261 70.062-15.197 82.583z" transform="translate(-379.685 -492.666)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                      <path data-name="Path 5364" d="M384.722 517.033a7.838 7.838 0 0 1-3.2-4.869c-.915-4.156-3.019-13.308-1.482-18.421 2.136-7.11 3.609-6.036 9.883-8.573 3.865-1.563 6.714-1.933 7.949-1.5 4.089 1.422 6.23 3.432 9.8 5.243 3.819 1.939 5.385 3.464-2.8 27.977 0 0-2.2 3.121-5.427 2.315s-4.512-3.545-8.218-2.095c-2.596 1.012-4.506 1.264-6.505-.077z" transform="translate(-379.176 -483.5)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5365" d="M386.3 517.646a6.957 6.957 0 0 1-2.836-4.319c-.811-3.688-2.678-11.809-1.315-16.345 1.895-6.308 3.2-5.355 8.769-7.607 3.429-1.387 5.957-1.716 7.053-1.334 3.628 1.261 5.528 3.045 8.693 4.651 3.388 1.72 4.778 3.074-2.487 24.823 0 0-1.956 2.769-4.815 2.055s-4-3.145-7.291-1.859c-2.303.9-3.997 1.124-5.771-.065z" transform="translate(-379.946 -485.062)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5366" d="M389.062 518a6.163 6.163 0 0 1-2.512-3.827c-.719-3.267-2.373-10.461-1.166-14.48 1.679-5.589 2.838-4.745 7.769-6.739 3.038-1.228 5.277-1.52 6.248-1.182 3.214 1.118 4.9 2.7 7.7 4.121 3 1.524 4.233 2.723-2.2 21.991 0 0-1.733 2.453-4.266 1.82s-3.547-2.787-6.459-1.647c-2.041.8-3.542.998-5.114-.057z" transform="translate(-381.116 -486.396)" style={{ fill: colors.white }} />
                      <g data-name="Group 9401">
                        <g data-name="Group 9396" transform="translate(9.604 15.077)">
                          <g data-name="Rectangle 3927" style={{ fill: '#c1c4c5', stroke: '#c1c4c5' }}>
                            <rect width="11.602" height="11.602" rx={2} style={{ stroke: 'none' }} />
                            <rect x=".5" y=".5" width="10.602" height="10.602" rx="1.5" style={{ fill: 'none' }} />
                          </g>
                          <path data-name="Path 6487" d="M11.579 17.547c-.2.362 1.676.183 3.083.165 2.094-.044 3.289.384 3.269-4.45a41.119 41.119 0 0 0-.159-5.141c-.531-.516.14 5.236-1.219 7.766-1.282 1.827-4.631 1.272-4.974 1.66z" transform="translate(-6.653 -6.464)" style={{ fill: '#b0b1b1' }} />
                          <rect data-name="Rectangle 3930" width={5} height={2} rx=".5" transform="translate(3.089 2.015)" style={{ fill: '#9e9e9e' }} />
                          <rect data-name="Rectangle 3928" width={5} height={2} rx=".5" transform="translate(3.289 2.015)" style={{ fill: 'url(#xer24sd2ia)' }} />
                          <g data-name="Group 9397" transform="translate(0 .5)">
                            <rect data-name="Rectangle 3931" width={5} height={2} rx=".5" transform="translate(3.289 6.215)" style={{ fill: '#cbcccc' }} />
                            <rect data-name="Rectangle 3929" width={5} height={2} rx=".5" transform="translate(3.289 6.015)" style={{ fill: 'url(#lb18hb853b)' }} />
                          </g>
                        </g>
                        <path data-name="Path 6488" d="M3.461 10.334c7.072.79 13.051 3.506 29.391.176" transform="translate(-2.827 8.886)" style={{ strokeLinecap: 'round', stroke: '#b5b7b7', fill: 'none' }} />
                        <path data-name="Path 6486" d="M3.568 10.454c6.624.792 14.61 3.252 29.612.025" transform="translate(-3.013 8.062)" style={{ stroke: '#babbbb', strokeLinecap: 'round', fill: 'none' }} />
                      </g>
                    </g>
                  </> || ""}

                {(isFixture && props.type === 'treatment') ?
                  <>
                    <defs>
                      <linearGradient id="gjfwy4dl1a" x1=".209" y1="1.059" x2="1.176" y2="-.286" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b4b5b5" />
                      </linearGradient>
                      <linearGradient id="8mwjit89bb" x1="-.342" y1="1.081" x2="1.352" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b0b1b1" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9508">
                      <path data-name="Path 5364" d="M384.722 517.033a7.838 7.838 0 0 1-3.2-4.869c-.915-4.156-3.019-13.308-1.482-18.421 2.136-7.11 3.609-6.036 9.883-8.573 3.865-1.563 6.714-1.933 7.949-1.5 4.089 1.422 6.23 3.432 9.8 5.243 3.819 1.939 5.385 3.464-2.8 27.977 0 0-2.2 3.121-5.427 2.315s-4.512-3.545-8.218-2.095c-2.596 1.012-4.506 1.264-6.505-.077z" transform="translate(-379.498 -483.5)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5365" d="M386.3 517.646a6.957 6.957 0 0 1-2.836-4.319c-.811-3.688-2.678-11.809-1.315-16.345 1.895-6.308 3.2-5.355 8.769-7.607 3.429-1.387 5.957-1.716 7.053-1.334 3.628 1.261 5.528 3.045 8.693 4.651 3.388 1.72 4.778 3.074-2.487 24.823 0 0-1.956 2.769-4.815 2.055s-4-3.145-7.291-1.859c-2.303.9-3.997 1.124-5.771-.065z" transform="translate(-380.268 -485.062)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5366" d="M389.062 518a6.163 6.163 0 0 1-2.512-3.827c-.719-3.267-2.373-10.461-1.166-14.48 1.679-5.589 2.838-4.745 7.769-6.739 3.038-1.228 5.277-1.52 6.248-1.182 3.214 1.118 4.9 2.7 7.7 4.121 3 1.524 4.233 2.723-2.2 21.991 0 0-1.733 2.453-4.266 1.82s-3.547-2.787-6.459-1.647c-2.041.8-3.542.998-5.114-.057z" transform="translate(-381.438 -486.396)" style={{ fill: colors.white }} />
                      <g data-name="Group 9532">
                        <g data-name="Path 6490" transform="translate(4.55 34.704)" style={{ fill: 'url(#gjfwy4dl1a)' }}>
                          <path d="M13.874 44.996H5.203c-.776 0-1.52-1.975-2.212-5.87C1.818 32.515.845 20.603.1 3.718c0-.976.177-1.896.499-2.586C.909.467 1.312.1 1.734.1h15.609c.422 0 .825.367 1.135 1.033.322.69.499 1.61.499 2.59 0 39.283-4.25 41.273-5.103 41.273z" style={{ stroke: 'none' }} />
                          <path d="M1.734.2C.994.2.201 1.614.2 3.719c.745 16.879 1.717 28.784 2.89 35.389.893 5.036 1.707 5.788 2.113 5.788h8.671c.183 0 .564-.11 1.05-.85.634-.963 1.211-2.627 1.715-4.946 1.485-6.83 2.238-18.732 2.238-35.377 0-2.107-.793-3.523-1.534-3.523H1.734m0-.2h15.609c.957 0 1.734 1.667 1.734 3.723 0 39.288-4.245 41.373-5.203 41.373H5.203c-.958 0-3.469-2.085-5.203-41.373C0 1.667.776 0 1.734 0z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <g data-name="Path 6489" transform="translate(2.266 29.501)" style={{ strokeLinejoin: 'round', fill: 'url(#8mwjit89bb)' }}>
                          <path d="M.945 7.569H.938C.912 7.52.892 7.32.865 7.047.795 6.354.653 4.913.1 1.905.103.909.577.1 1.16.1h22.038c.583 0 1.057.81 1.06 1.807-.293 2.008-.447 3.306-.549 4.164-.175 1.486-.187 1.486-.385 1.486a3.548 3.548 0 0 1-.126-.001H1.16c-.062 0-.112.005-.153.008-.025.003-.046.005-.062.005z" style={{ stroke: 'none' }} />
                          <path d="M1.16.2C.643.2.206.975.2 1.896c.552 3.005.695 4.447.764 5.14.019.186.034.341.049.427.039-.003.088-.007.147-.007h22.038l.126.001a.404.404 0 0 0 .064-.003c.068-.093.13-.614.222-1.394.102-.859.255-2.155.547-4.16-.004-.923-.442-1.7-.96-1.7H1.16m0-.2h22.038c.64 0 1.16.857 1.16 1.914-.873 5.981-.52 5.742-1.16 5.742H1.16c-.64 0 0 .568-1.16-5.742C0 .857.52 0 1.16 0z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <path data-name="Path 6491" d="M5.625 23.16s18.057-.9 18.962-1.957" transform="translate(-.961 19.84)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6492" d="M5.649 23.213S23.1 22.255 24 21.2" transform="translate(-.674 25.039)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6493" d="M7.08 22.925s14.6-.592 15.5-1.645" transform="translate(-.226 47.566)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6584" d="M5.649 23.213S23.1 22.255 24 21.2" transform="translate(-.418 29.582)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6585" d="M6.115 23.237S23.1 22.255 24 21.2" transform="translate(-.418 35.582)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6586" d="M6.643 23.2s16.036-.854 16.941-1.907" transform="translate(-.418 41.582)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6587" d="M8.144 22.87s12.509-.373 13.415-1.426" transform="translate(-.227 53.204)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      </g>
                    </g>
                  </> : ""}
              </g>
            </g>
          </svg>}
      </DivTeethL>
    </Div>
  )
}
