import { colors } from "constants/theme"

export default function DentalDoctor(props: { color: string }) {
  return (
    <svg data-name="Group 5467" xmlns="https://www.w3.org/2000/svg" width="34.449" height="23" viewBox="0 0 34.449 23">
      <ellipse data-name="Ellipse 35" cx="17.103" cy="1.076" rx="17.103" ry="1.076" transform="translate(.243 20.848)" style={{ opacity: 0.14, fill: props.color || colors.disabledGray }} />
      <path data-name="Rectangle 3231" transform="rotate(-45 23.625 -7.1)" style={{ fill: props.color || colors.disabledGray }} d="M0 0h2.871v8.122H0z" />
      <g data-name="Group 5375" transform="translate(0 1.24)">
        <rect data-name="Rectangle 3232" width="15.474" height="2.301" rx="1.15" transform="rotate(53 .202 5.947)" style={{ fill: props.color || colors.disabledGray }} />
        <path data-name="Path 5678" d="m109.992 194.392-12.613-16.868a.292.292 0 0 0-.409-.056.292.292 0 0 0-.056.41l12.613 16.868a.293.293 0 0 0 .409.056.292.292 0 0 0 .056-.41z" transform="translate(-96.854 -177.409)" style={{ fill: props.color || colors.disabledGray }} />
      </g>
      <rect data-name="Rectangle 3233" width="25.23" height="1.269" rx=".635" transform="translate(6.553 20.608)" style={{ fill: props.color || colors.disabledGray }} />
      <path data-name="Path 5679" d="M174.587 356.828v.035l.5.5a.625.625 0 0 0 .122.012h23.96a.633.633 0 0 0 .626-.547z" transform="translate(-168.025 -335.498)" style={{ opacity: 0.14, fill: props.color || colors.disabledGray }} />
      <path data-name="Path 5680" d="M223.451 320.2a11.992 11.992 0 0 0-8.209 3.219.524.524 0 0 0 .357.907h10.208a.523.523 0 0 0 .523-.549c-.058-1.125-.463-3.577-2.879-3.577z" transform="translate(-201.595 -303.715)" style={{ fill: props.color || colors.disabledGray }} />
      <path data-name="Path 5681" d="m215.353 343.747-.111.1a.524.524 0 0 0 .357.907h10.208a.523.523 0 0 0 .523-.549 6.343 6.343 0 0 0-.04-.46z" transform="translate(-201.595 -324.148)" style={{ opacity: 0.14, fill: props.color || colors.disabledGray }} />
      <path
        data-name="Path 5682"
        d="M187.394 286.88v1.883a1.516 1.516 0 0 0 1.516 1.516h8.19a1.516 1.516 0 0 0 1.516-1.516v-1.883a.524.524 0 0 0-.524-.524h-10.174a.524.524 0 0 0-.524.524z"
        transform="translate(-177.069 -273.215)"
        style={{ fill: props.color || colors.disabledGray }}
      />
      <path data-name="Path 5683" d="M187.394 304.109v.057a1.516 1.516 0 0 0 1.516 1.516h8.19a1.516 1.516 0 0 0 1.516-1.516v-.057z" transform="translate(-177.069 -288.725)" style={{ opacity: 0.14, fill: props.color || colors.disabledGray }} />
      <path
        data-name="Path 5684"
        d="M198.522 269.239h-10.6a.524.524 0 0 0-.524.524v1.218a.524.524 0 0 0 .524.524h10.6a.524.524 0 0 1 .192.036l6.921 2.728a.524.524 0 0 0 .638-.212l.626-1.015a.524.524 0 0 0-.26-.764l-7.927-3a.524.524 0 0 0-.19-.039z"
        transform="translate(-177.813 -258.113)"
        style={{ fill: props.color || colors.disabledGray }}
      />
      <g data-name="Group 5376">
        <path
          data-name="Path 5685"
          d="m103.765 169.948-1.336-1.709a.524.524 0 0 0-.729-.095l-1 .758a.524.524 0 0 0-.1.74l1.336 1.709a.524.524 0 0 0 .729.094l1-.758a.523.523 0 0 0 .1-.739z"
          transform="translate(-100.013 -168.038)"
          style={{ fill: props.color || colors.disabledGray }}
        />
      </g>
      <path data-name="Rectangle 3234" transform="translate(12.643 9.084)" style={{ fill: props.color || colors.disabledGray }} d="M0 0h1.316v2.675H0z" />
      <path data-name="Path 5687" d="M.6 0h10.329a.6.6 0 0 1 .6.6.6.6 0 0 1-.6.6H.6A.6.6 0 0 1 0 .6.6.6 0 0 1 .6 0z" transform="translate(10.126 8.244)" style={{ fill: props.color || colors.disabledGray }} />
    </svg>
  )
}
