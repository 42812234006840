import { Box } from '@mui/material';

/** STYLE */
import { styled } from '@mui/material/styles'

export const RetrospectiveOrder = styled(Box)(({ theme }) => ({}))

export const InputDate = styled(Box)(({ theme }) => ({
  maxWidth: 368,
  '.Mui-disabled': {
    backgroundColor: 'transparent !important'
  },
  [theme.breakpoints.up('xs')]: {
    maxWidth: 368
  }
}))
