import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart, TreatmentStateInterface } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/31.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethL, TextNumberL } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
  height: 'auto',
  marginBottom: 'auto',
  top: '1.4%'
}))

export default function Teeth31(props: TeethProps) {
  const teeth = useSelector(toothChart)
  const teethNumber = props.teethNumber
  const historyToothChart = props.historyToothChart
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const isEditTootCharts: any = useSelector(isEditTootChart)

  const dispatch = useDispatch()

  const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
  const [positions, setPositions]: any = useState([])
  const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

  const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
  const [isTooth, setIsTooth]: any = useState(false)
  const [isXRay, setIsXRay]: any = useState(false)
  const [isToothRoot, setIsToothRoot]: any = useState(false)
  const [isOrthodontics, setIsOrthodontics]: any = useState(false)
  const [isExtract, setIsExtract]: any = useState(false)
  const [isFixture, setIsFixture]: any = useState(false)
  const [isBridge, setIsBridge]: any = useState(false)

  const [color, setColor]: any = useState(``)
  const [styleSvg, setStyleSvg]: any = useState({ width: 38.262, height: 93.001, viewBox: "0 0 38.262 93.001" })

  const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

  useEffect(() => {
    const operative = props.operative || ``
    const valColor = props.color || colors.themeSecondColor
    const selected = teeth[teethNumber]?.isSelect || false
    dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
    setIsSelected(selected)

    renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

    if (props.type === 'treatment') {
      renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
    }

    if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

    return setStyleSvg({ width: 25.93, height: 75.907, viewBox: "0 0 25.93 75.907" })
  }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

  useEffect(() => {
    const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
    const teethJson = treatmentTooth?.teethJson

    if (isEditTootCharts !== null) {
      if (teethJson) {
        const tooths = JSON.parse(teethJson)
        setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
        if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
      } else setIsEditTeeth(false)
    }
  }, [isEditTootCharts]);

  useEffect(() => {
    if (isSelected) {
      dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
      if (teeth[props.teethNumber]?.isSelect) {
        setIsSelected(true)
      } else {
        setIsSelected(false)
      }
    }
  }, [props.historyToothChart]);

  useEffect(() => {
    if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
  }, [isSelected, isEditTootCharts]);

  return (
    <Div>
      <TextNumberL>31</TextNumberL>
      <PositionTeeth quarter={QUARTER_NAME_TYPE.Q3} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
      <DivTeethL className={`div-teeth-${props.teethNumber} `}>
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray position='L' />) || ''}
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge position='L' />) || ''}
        {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`md teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
          <svg className={`md ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
            <g data-name="Group 9183">
              {(isBridge && props.type === 'treatment') ?
                <g></g>
                : ""}
              <path
                data-name="Path 5367"
                d="M474.007 502.726c.206 1.186.419 2.32.619 3.39a77.358 77.358 0 0 1 2.443 10.564c1.54 9.313 1.818 18.826 3.326 28.146a82.2 82.2 0 0 0 5.24 19.2c.728 1.727 1.5 3.442 3.822 2.849a3.157 3.157 0 0 0 1.366-2.862c1.147-15.81 2.03-31.652 4.338-47.339.78-5.285 1.721-10.551 2.887-15.79 1.366-6.149-1.205-8.81-7.5-8.823q-7.183-.01-14.36 0v.651c5.014 0 10.022.006 15.036-.006 2.559-.006 4.518 1.1 3.938 3.79-1.521 7-2.5 14.218-5.91 20.631-2.449 4.6-6.265 4.492-9.184.084a26.731 26.731 0 0 1-4.105-12.387c-.116-1.2.09-2.6-1.392-3.351a2.8 2.8 0 0 0-.564 1.253z"
                transform="translate(-473.105 -491.088)"
                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
              />

              {(isLowerTeeth && props.type === 'treatment') ?
                <path
                  data-name="Path 6502"
                  d="M477.068 516.68c1.54 9.313 1.818 18.826 3.326 28.146a82.2 82.2 0 0 0 5.24 19.2c.728 1.727 1.5 3.442 3.822 2.849a3.157 3.157 0 0 0 1.366-2.862c1.147-15.81 2.03-31.652 4.338-47.339a9.794 9.794 0 0 1-2.554 2.37 14.907 14.907 0 0 1-4.234 1.828c-1.672.234-2.594.438-3.766.5a10.162 10.162 0 0 1-4.5-1.875 11.31 11.31 0 0 1-3.038-2.817z"
                  transform="translate(-473.105 -491.088)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                /> : ''}
              <g data-name="Group 9710">
                <path
                  data-name="Path 5368"
                  d="M473.224 497.957c.226 1.463.464 2.881.7 4.234.206 1.186.419 2.32.619 3.39a77.341 77.341 0 0 1 2.443 10.564 7.1 7.1 0 0 0 1.869 1.953s3.545 3.042 6.445 2.688 6.9-.9 9.777-4.647l.006-.007s7.863-21.855 1.173-24.246-20.779-.844-20.779-.844-3.277.231-2.253 6.915z"
                  transform="translate(-473.025 -490.552)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                />
                <path
                  data-name="Path 5369"
                  d="M475.2 499.715c.2 1.3.413 2.565.625 3.771.184 1.056.373 2.066.551 3.019a68.958 68.958 0 0 1 2.176 9.408 6.312 6.312 0 0 0 1.664 1.739s3.157 2.71 5.74 2.394 6.147-.8 8.707-4.138l.006-.006s7-19.464 1.045-21.594-18.505-.751-18.505-.751-2.927.206-2.009 6.158z"
                  transform="translate(-473.466 -491.466)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                />
                <path
                  data-name="Path 5370"
                  d="M477.542 503.023c.177 1.146.363 2.256.55 3.316.162.929.329 1.818.485 2.655a60.6 60.6 0 0 1 1.914 8.273 5.558 5.558 0 0 0 1.464 1.53s2.777 2.382 5.048 2.1 5.407-.7 7.658-3.639l.005-.005s6.158-17.118.918-18.99-16.275-.661-16.275-.661-2.57.186-1.767 5.421z"
                  transform="translate(-474.576 -492.924)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                />

                {(isToothRoot && props.type === 'treatment') ?
                  <path
                    data-name="Path 6569"
                    d="M8.639 10.676c.018.473-.2 11.53.195 22.6.185 5.245 1.666 17.297 1.376 17.654.032.281-2.142-12.257-2.469-17.651-.687-11.344-1.279-22.339-1.32-22.6a9.936 9.936 0 0 0 2.218-.003z"
                    transform="translate(4.629 19.96)"
                    style={{

                      fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                      stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                    }}
                  />
                  : ''}

                {(isExtract && props.type === 'treatment') ?
                  <>
                    <g data-name="Group 9473">
                      <path data-name="Path 6581" d="m33.013 7.5-26.767 72" transform="translate(-4.316 -5.261)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                      <path data-name="Path 6582" d="m7.012 7.5 26.776 72" transform="translate(-4.316 -5.261)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                    </g>
                  </>
                  : ''}

                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                  <>
                    <defs>
                      <style dangerouslySetInnerHTML={{ __html: "\n      .cls-1 {\n        fill: #e3d1b4;\n      }\n\n      .cls-2 {\n        fill: #efede7;\n      }\n\n      .cls-3 {\n        fill: #f7f7f7;\n      }\n\n      .cls-4 {\n        fill: #fff;\n      }\n\n      .cls-5 {\n        fill: #c1c4c5;\n        stroke: #c1c4c5;\n      }\n\n      .cls-6 {\n        fill: #b0b1b1;\n      }\n\n      .cls-7 {\n        fill: #9e9e9e;\n      }\n\n      .cls-8 {\n        fill: url(#linear-gradient);\n      }\n\n      .cls-9 {\n        fill: #cbcccc;\n      }\n\n      .cls-10 {\n        fill: url(#linear-gradient-2);\n      }\n\n      .cls-11, .cls-12, .cls-14 {\n        fill: none;\n      }\n\n      .cls-11 {\n        stroke: #b5b7b7;\n      }\n\n      .cls-11, .cls-12 {\n        stroke-linecap: round;\n      }\n\n      .cls-12 {\n        stroke: #babbbb;\n      }\n\n      .cls-13 {\n        stroke: none;\n      }\n    " }} />
                      <linearGradient id="linear-gradient" x1="0.5" y1="0.453" x2="-1.326" y2="4.622" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#b0b1b1" />
                        <stop offset={1} stopColor="#585959" />
                      </linearGradient>
                      <linearGradient id="linear-gradient-2" x1="0.5" y1="1.834" x2="0.5" y2="0.046" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#c1c4c5" />
                        <stop offset={1} stopColor="#9e9e9e" />
                      </linearGradient>
                    </defs>
                    <g id="Group_9370" data-name="Group 9370" transform="translate(-447.264 -224.075)">
                      <path id="Path_5367" data-name="Path 5367" className="cls-1" d="M474.007,502.726c.206,1.186.419,2.32.619,3.39a77.358,77.358,0,0,1,2.443,10.564c1.54,9.313,1.818,18.826,3.326,28.146a82.2,82.2,0,0,0,5.24,19.2c.728,1.727,1.5,3.442,3.822,2.849a3.157,3.157,0,0,0,1.366-2.862c1.147-15.81,2.03-31.652,4.338-47.339.78-5.285,1.721-10.551,2.887-15.79,1.366-6.149-1.205-8.81-7.5-8.823q-7.183-.01-14.36,0v.651c5.014,0,10.022.006,15.036-.006,2.559-.006,4.518,1.1,3.938,3.79-1.521,7-2.5,14.218-5.91,20.631-2.449,4.6-6.265,4.492-9.184.084a26.731,26.731,0,0,1-4.105-12.387c-.116-1.2.09-2.6-1.392-3.351A2.8,2.8,0,0,0,474.007,502.726Z" transform="translate(-25.841 -267.013)" />
                      <path id="Path_5368" data-name="Path 5368" className="cls-2" d="M473.224,497.957c.226,1.463.464,2.881.7,4.234.206,1.186.419,2.32.619,3.39a77.341,77.341,0,0,1,2.443,10.564,7.1,7.1,0,0,0,1.869,1.953s3.545,3.042,6.445,2.688,6.9-.9,9.777-4.647l.006-.007s7.863-21.855,1.173-24.246-20.779-.844-20.779-.844S472.2,491.273,473.224,497.957Z" transform="translate(-25.761 -266.477)" />
                      <path id="Path_5369" data-name="Path 5369" className="cls-3" d="M475.2,499.715c.2,1.3.413,2.565.625,3.771.184,1.056.373,2.066.551,3.019a68.958,68.958,0,0,1,2.176,9.408,6.312,6.312,0,0,0,1.664,1.739s3.157,2.71,5.74,2.394,6.147-.8,8.707-4.138l.006-.006s7-19.464,1.045-21.594-18.505-.751-18.505-.751S474.282,493.763,475.2,499.715Z" transform="translate(-26.202 -267.391)" />
                      <path id="Path_5370" data-name="Path 5370" className="cls-4" d="M477.542,503.023c.177,1.146.363,2.256.55,3.316.162.929.329,1.818.485,2.655a60.6,60.6,0,0,1,1.914,8.273,5.558,5.558,0,0,0,1.464,1.53s2.777,2.382,5.048,2.1,5.407-.7,7.658-3.639l.005-.005s6.158-17.118.918-18.99-16.275-.661-16.275-.661S476.739,497.788,477.542,503.023Z" transform="translate(-27.312 -268.849)" />
                      <g id="Group_9404" data-name="Group 9404" transform="translate(441.075 234.091)">
                        <g id="Group_9396" data-name="Group 9396" transform="translate(13.424 0.515)">
                          <g id="Rectangle_3927" data-name="Rectangle 3927" className="cls-5">
                            <rect className="cls-13" width="11.602" height="11.602" rx={2} />
                            <rect className="cls-14" x="0.5" y="0.5" width="10.602" height="10.602" rx="1.5" />
                          </g>
                          <path id="Path_6487" data-name="Path 6487" className="cls-6" d="M11.579,17.547c-.2.362,1.676.183,3.083.165,2.094-.044,3.289.384,3.269-4.45a41.119,41.119,0,0,0-.159-5.141c-.531-.516.14,5.236-1.219,7.766C15.271,17.714,11.922,17.159,11.579,17.547Z" transform="translate(-6.653 -6.464)" />
                          <rect id="Rectangle_3930" data-name="Rectangle 3930" className="cls-7" width={5} height={2} rx="0.5" transform="translate(3.089 2.015)" />
                          <rect id="Rectangle_3928" data-name="Rectangle 3928" className="cls-8" width={5} height={2} rx="0.5" transform="translate(3.289 2.015)" />
                          <g id="Group_9397" data-name="Group 9397" transform="translate(0 0.5)">
                            <rect id="Rectangle_3931" data-name="Rectangle 3931" className="cls-9" width={5} height={2} rx="0.5" transform="translate(3.289 6.215)" />
                            <rect id="Rectangle_3929" data-name="Rectangle 3929" className="cls-10" width={5} height={2} rx="0.5" transform="translate(3.289 6.015)" />
                          </g>
                        </g>
                        <path id="Path_6488" data-name="Path 6488" className="cls-11" d="M6.62,10.793c5.2.951,8.1,2.819,24.05.005" transform="translate(0.993 -5.676)" />
                        <path id="Path_6486" data-name="Path 6486" className="cls-12" d="M6.688,10.8c2.5.446,9.231,3.227,24.232,0" transform="translate(0.807 -6.5)" />
                      </g>
                    </g>
                  </> || ""}

                {(isFixture && props.type === 'treatment') ?
                  <>
                    <defs>
                      <linearGradient id="9l872219ua" x1=".209" y1="1.059" x2="1.176" y2="-.286" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b4b5b5" />
                      </linearGradient>
                      <linearGradient id="yucfvmrtgb" x1="-.342" y1="1.081" x2="1.352" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b0b1b1" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9511">
                      <path data-name="Path 5368" d="M473.224 497.957c.226 1.463.464 2.881.7 4.234.206 1.186.419 2.32.619 3.39a77.341 77.341 0 0 1 2.443 10.564 7.1 7.1 0 0 0 1.869 1.953s3.545 3.042 6.445 2.688 6.9-.9 9.777-4.647l.006-.007s7.863-21.855 1.173-24.246-20.779-.844-20.779-.844-3.277.231-2.253 6.915z" transform="translate(-473.025 -490.552)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5369" d="M475.2 499.715c.2 1.3.413 2.565.625 3.771.184 1.056.373 2.066.551 3.019a68.958 68.958 0 0 1 2.176 9.408 6.312 6.312 0 0 0 1.664 1.739s3.157 2.71 5.74 2.394 6.147-.8 8.707-4.138l.006-.006s7-19.464 1.045-21.594-18.505-.751-18.505-.751-2.927.206-2.009 6.158z" transform="translate(-473.466 -491.466)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5370" d="M477.542 503.023c.177 1.146.363 2.256.55 3.316.162.929.329 1.818.485 2.655a60.6 60.6 0 0 1 1.914 8.273 5.558 5.558 0 0 0 1.464 1.53s2.777 2.382 5.048 2.1 5.407-.7 7.658-3.639l.005-.005s6.158-17.118.918-18.99-16.275-.661-16.275-.661-2.57.186-1.767 5.421z" transform="translate(-474.576 -492.924)" style={{ fill: colors.white }} />
                      <g data-name="Group 9525">
                        <g data-name="Path 6490" transform="translate(3.285 30.159)" style={{ fill: 'url(#9l872219ua)' }}>
                          <path d="M13.874 44.996H5.203c-.776 0-1.52-1.975-2.212-5.87C1.818 32.515.845 20.603.1 3.718c0-.976.177-1.896.499-2.586C.909.467 1.312.1 1.734.1h15.609c.422 0 .825.367 1.135 1.033.322.69.499 1.61.499 2.59 0 39.283-4.25 41.273-5.103 41.273z" style={{ stroke: 'none' }} />
                          <path d="M1.734.2C.994.2.201 1.614.2 3.719c.745 16.879 1.717 28.784 2.89 35.389.893 5.036 1.707 5.788 2.113 5.788h8.671c.183 0 .564-.11 1.05-.85.634-.963 1.211-2.627 1.715-4.946 1.485-6.83 2.238-18.732 2.238-35.377 0-2.107-.793-3.523-1.534-3.523H1.734m0-.2h15.609c.957 0 1.734 1.667 1.734 3.723 0 39.288-4.245 41.373-5.203 41.373H5.203c-.958 0-3.469-2.085-5.203-41.373C0 1.667.776 0 1.734 0z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <g data-name="Path 6489" transform="translate(1.001 24.956)" style={{ strokeLinejoin: 'round', fill: 'url(#yucfvmrtgb)' }}>
                          <path d="M.945 7.569H.938C.912 7.52.892 7.32.865 7.047.795 6.354.653 4.913.1 1.905.103.909.577.1 1.16.1h22.038c.583 0 1.057.81 1.06 1.807-.293 2.008-.447 3.306-.549 4.164-.175 1.486-.187 1.486-.385 1.486a3.548 3.548 0 0 1-.126-.001H1.16c-.062 0-.112.005-.153.008-.025.003-.046.005-.062.005z" style={{ stroke: 'none' }} />
                          <path d="M1.16.2C.643.2.206.975.2 1.896c.552 3.005.695 4.447.764 5.14.019.186.034.341.049.427.039-.003.088-.007.147-.007h22.038l.126.001a.404.404 0 0 0 .064-.003c.068-.093.13-.614.222-1.394.102-.859.255-2.155.547-4.16-.004-.923-.442-1.7-.96-1.7H1.16m0-.2h22.038c.64 0 1.16.857 1.16 1.914-.873 5.981-.52 5.742-1.16 5.742H1.16c-.64 0 0 .568-1.16-5.742C0 .857.52 0 1.16 0z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <path data-name="Path 6491" d="M5.625 23.16s18.057-.9 18.962-1.957" transform="translate(-2.226 15.295)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6492" d="M5.649 23.213S23.1 22.255 24 21.2" transform="translate(-1.939 20.494)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6493" d="M7.08 22.925s14.6-.592 15.5-1.645" transform="translate(-1.491 43.021)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6584" d="M5.649 23.213S23.1 22.255 24 21.2" transform="translate(-1.683 25.037)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6585" d="M6.115 23.237S23.1 22.255 24 21.2" transform="translate(-1.683 31.037)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6586" d="M6.643 23.2s16.036-.854 16.941-1.907" transform="translate(-1.683 37.037)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6587" d="M8.144 22.87s12.509-.373 13.415-1.426" transform="translate(-1.492 48.659)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      </g>
                    </g>
                  </> : ""}
              </g>
            </g>
          </svg>}
      </DivTeethL>
    </Div>
  )
}
