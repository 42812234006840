import { Radio, styled } from '@mui/material'
import { colors } from 'constants/theme'

const CustomRadio = styled(Radio)(({ theme }) => ({
    '&.Mui-checked': {
        color: `${colors.themeSecondColor} !important`
    }
}))

export default function InputRadio(props: any) {
    return (
        <CustomRadio {...props} />
    )
}