
import { useCallback, useEffect, useState } from 'react'


import { useDispatch } from 'react-redux'
import $ from 'jquery'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import * as UseStyled from './style'

/** API */

/** UTILS || SLICE */

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import InputTextField from 'component/Input/InputTextField'
import { Grid } from '@mui/material'
import { imgs } from 'constants/images'
import UseWindowSize from 'utils/useWindowSize'
import ImageDoctorNoteType from 'api/setting/doctor-note/image-doctor-note-type.api'
import _ from 'lodash'
import HelperText from 'component/Input/HelperText'

import ImageDoctorNoteApi, { IFImageDoctorNoteProps, ImageDoctorNoteProps } from 'api/setting/doctor-note/image-doctor-note.api'
import { resetModal, unSubmitModal } from 'app/slice/modal.slice'
import { notiSuccess } from 'component/notifications/notifications'
import { fileUrl } from 'api/api'

type IFModal = {
    data: any
    resetPopup: () => void
    callback: () => void
}
type IFImageDoctorNoteType = {
    doctorNoteTypeId: number
    typeName: string
    typeNameEn: string
}
type IFServictPoint = {
    servicePointId: number
    servicePointName: string
}

const initStateErrorMessage = {
    IMAGE_DOCTOR_NOTE: '',
    IMAGE_DOCTOR_NOTE_NAME: '',
    IS_DUPLICATE_IMAGE_DOCTOR_NOTE: '',
    IMAGE_DOCTOR_NOTE_TYPE_ID: '',
}

export default function ModalImageDoctorNote(props: IFModal) {
    const { t } = useTranslation()
    const [height] = UseWindowSize()
    const dispatch = useDispatch()

    const [imgData, setImgData] = useState<string | null>()
    const [image, setImage] = useState<{
        fileId: number | null,
        base64: string,
        fileExtension: string,
        fileSize: string
    }>({
        fileId: null,
        base64: '',
        fileExtension: '',
        fileSize: ''
    })

    const [imageDoctorNoteId, setImageDoctorNoteId] = useState<number | null>(0)
    const [imageDoctorNoteName, setImageDoctorNoteName] = useState<string | null>()
    const [imageDoctorNoteTypeId, setImageDoctorNoteTypeId] = useState<number | null>()
    const [imageDoctorNoteTypes, setImageDoctorNoteTypes] = useState<IFImageDoctorNoteType[]>([])

    const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

    const loadData = useCallback(async () => {
        const condition = { page: 1, pageLimit: 100, status: "ACTIVE", export: 1 }
        const res = await ImageDoctorNoteType.findAll(condition)
        if (res.status === 200) setImageDoctorNoteTypes(res.data)
    }, [props.data])

    useEffect(() => {
        loadData()
    }, [loadData])

    useEffect(() => {
        if (props.data?.doctorNoteImageId) {
            const { doctorNoteImageId, name, doctorNoteTypeId, image, }: IFImageDoctorNoteProps = props.data
            setImageDoctorNoteId(doctorNoteImageId)
            setImageDoctorNoteName(name)
            setImageDoctorNoteTypeId(doctorNoteTypeId)
            setImgData(`${fileUrl}/${image.filePath} `)
            setImage({ fileId: image.fileId, base64: '', fileExtension: '', fileSize: '' })
        }
    }, [props.data])

    const submit = () => {
        if (!imageDoctorNoteName) return setErrorMessage({ ...errorMessage, ...{ IMAGE_DOCTOR_NOTE_NAME: t('IMAGE_DOCTOR_NOT.MESSAGE.IMAGE_DOCTOR_NOTE_NAME') } })
        if (!imageDoctorNoteTypeId) return setErrorMessage({ ...errorMessage, ...{ IMAGE_DOCTOR_NOTE_TYPE_ID: t('IMAGE_DOCTOR_NOT.MESSAGE.IMAGE_DOCTOR_NOTE_TYPE_ID') } })
        if (!image.base64 && !image.fileId) return setErrorMessage({ ...errorMessage, ...{ IMAGE_DOCTOR_NOTE: t('IMAGE_DOCTOR_NOT.MESSAGE.IMAGE_DOCTOR_NOTE') } })

        const objCreate: ImageDoctorNoteProps = { name: imageDoctorNoteName, doctorNoteTypeId: imageDoctorNoteTypeId, ...image }
        ImageDoctorNoteApi.create(objCreate).then((res: any) => {
            if (res.status !== undefined && res.status === 201) {
                notiSuccess(t('IMAGE_DOCTOR_NOT.MESSAGE.SUCCESS.CREATE'), '', null, null)
                resetForm()
                loadData()
                dispatch(resetModal())
            } else {
                const err = res.response.data
                setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`IMAGE_DOCTOR_NOT.MESSAGE.${err.message}`) } })
                dispatch(unSubmitModal())
            }
        })
            .catch((e) => {
                const err = e.response.data
                setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`IMAGE_DOCTOR_NOT.MESSAGE.${err.message}`) } })
                dispatch(unSubmitModal())
            })
    }

    const submitEdit = () => {
        if (!imageDoctorNoteName) return setErrorMessage({ ...errorMessage, ...{ IMAGE_DOCTOR_NOTE_NAME: t('IMAGE_DOCTOR_NOT.MESSAGE.IMAGE_DOCTOR_NOTE_NAME') } })
        if (!imageDoctorNoteTypeId) return setErrorMessage({ ...errorMessage, ...{ IMAGE_DOCTOR_NOTE_TYPE_ID: t('IMAGE_DOCTOR_NOT.MESSAGE.IMAGE_DOCTOR_NOTE_TYPE_ID') } })
        if (!image.base64 && !image.fileId) return setErrorMessage({ ...errorMessage, ...{ IMAGE_DOCTOR_NOTE: t('IMAGE_DOCTOR_NOT.MESSAGE.IMAGE_DOCTOR_NOTE') } })

        const objCreate: ImageDoctorNoteProps = { name: imageDoctorNoteName, doctorNoteTypeId: imageDoctorNoteTypeId, ...image }
        ImageDoctorNoteApi.update(imageDoctorNoteId, objCreate).then((res: any) => {
            if (res.status !== undefined && res.status === 200) {
                notiSuccess(t('IMAGE_DOCTOR_NOT.MESSAGE.SUCCESS.UPDATE'), '', null, null)
                resetForm()
                loadData()
                dispatch(resetModal())
            } else {
                const err = res.response.data
                setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`IMAGE_DOCTOR_NOT.MESSAGE.${err.message}`) } })
                dispatch(unSubmitModal())
            }
        })
            .catch((e) => {
                const err = e.response.data
                setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`IMAGE_DOCTOR_NOT.MESSAGE.${err.message}`) } })
                dispatch(unSubmitModal())
            })
    }

    const clearErrorMessage = () => {
        setErrorMessage(initStateErrorMessage)
    }

    const resetForm = () => {
        setImageDoctorNoteId(0)
        setImageDoctorNoteName('')
        setImageDoctorNoteTypeId(0)
        setImageDoctorNoteTypes([])
        setImgData(null)
        setImage({
            fileId: null,
            base64: '',
            fileExtension: '',
            fileSize: ''
        })
        props.resetPopup()
        clearErrorMessage()
    }

    const onChangePicture = (e: any) => {
        if (e.target.files[0]) {
            /**
             * e.target.files all detail file
             * lastModified, lastModifiedDate
             * name, size, type
             */
            const file = e.target.files[0]

            const fileName = file.name.split('.')
            const detailImage = {
                fileId: null,
                base64: '',
                fileExtension: fileName[fileName.length - 1],
                fileSize: file.size.toString()
            }
            const reader: any = new FileReader()
            reader.addEventListener('load', () => {
                /** reader.result is base64 */
                const base64 = reader.result.split(',')
                setImgData(reader.result)
                detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result
            })
            reader.readAsDataURL(file)
            setImage(detailImage)
        }
    }
    return <ModalCustom
        size={'xl'}
        title={imageDoctorNoteId ? t('IMAGE_DOCTOR_NOT.TITLE_UPDATE') : t('IMAGE_DOCTOR_NOT.TITLE_CREATE')}
        component={
            <UseStyled.Body>
                <Grid container spacing={2}  >
                    <Grid item md={4} lg={3} xs={6}>
                        <InputTextField
                            required={true}
                            key={`image-name`}
                            name={`image-name`}
                            label={t('IMAGE_DOCTOR_NOT.INPUT.IMAGE_DOCTOR_NOTE_NAME_EN')}
                            value={imageDoctorNoteName}
                            onchange={(e: any) => {
                                setImageDoctorNoteName(e.target.value)
                                clearErrorMessage()
                            }}
                            helperText={
                                errorMessage.IMAGE_DOCTOR_NOTE_NAME ||
                                errorMessage.IS_DUPLICATE_IMAGE_DOCTOR_NOTE
                            }
                        />
                    </Grid>
                    <Grid item md={4} lg={3} xs={6}>
                        <AutocompleteSelect
                            labelId="sl-image-doctor-note-type"
                            required={true}
                            inputLabel={t('IMAGE_DOCTOR_NOT.OPTION.IMAGE_DOCTOR_NOTE_TYPE')}
                            options={imageDoctorNoteTypes}
                            getOptionLabel={(option: IFImageDoctorNoteType) => option.typeName}
                            onchange={(e, value: IFImageDoctorNoteType) => {
                                setImageDoctorNoteTypeId(value.doctorNoteTypeId)
                                clearErrorMessage()
                            }}
                            noOptionsText={t('NOT_FOUND')}
                            filterOptions={(option: IFImageDoctorNoteType) => option.typeName}
                            value={_.find(imageDoctorNoteTypes, (type: IFImageDoctorNoteType) => String(type.doctorNoteTypeId) === String(imageDoctorNoteTypeId)) || null}
                            onOpen={() => setImageDoctorNoteTypeId(0)}
                            disableClearable
                            errMessage={errorMessage.IMAGE_DOCTOR_NOTE_TYPE_ID}
                        />
                    </Grid>
                    <Grid item md={12} lg={12} xs={12}>
                        {errorMessage.IMAGE_DOCTOR_NOTE && <HelperText label={errorMessage.IMAGE_DOCTOR_NOTE} />}
                        <section style={{ height: height * (50 / 100), width: '100%' }}>
                            <UseStyled.CardImg  >
                                <UseStyled.DivUpload
                                    onClick={() => {
                                        $('#profilePic').trigger("click")
                                    }}
                                    className={'d-flex align-items-center overflow-auto justify-content-center'}
                                >
                                    <div className="d-none">
                                        <input id="profilePic" type="file" accept='image/*' onChange={onChangePicture} />
                                    </div>
                                    {imgData ? (
                                        <img className={`image-preview w-auto h-100`} alt="" src={imgData} />
                                    ) : (
                                        <div className={'upload-image text-center '}>
                                            <img src={imgs.defaultAddImage} alt="uploadImage" style={{ border: 'unset' }} />
                                            <p className={'pt-1 pt-sm-2 m-0'}>{t('REGISTER.UPLOAD_TREATMENT.UPLOAD_IMAGE')}</p>
                                        </div>
                                    )}
                                    {/* </Box> */}
                                </UseStyled.DivUpload>
                            </UseStyled.CardImg>
                        </section>
                    </Grid>
                </Grid>
            </UseStyled.Body>
        }
        onReset={resetForm}
        onSubmit={imageDoctorNoteId ? submitEdit : submit}
        textBtnCancel={t('IMAGE_DOCTOR_NOT.BUTTON.CANCEL')}
        textBtnConfirm={t('IMAGE_DOCTOR_NOT.BUTTON.SAVE')}
    />
}
