import { Card } from 'react-bootstrap'
import { Divider, Grid, Typography } from '@mui/material'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import InputTextField from 'component/Input/InputTextField'
import ButtonCustom from 'component/Button/ButtonCustom'

/** STYLE */
import useStyles, { ButtonGoBack, TitalForgetPassword } from 'features/auth/style'

type Props = {
  onClick?: (value: any) => void
  submit?: (value: any) => void
  onChange?: (value: any) => void
  helperText?: string
  isSucceeded: boolean
  loading: boolean
}
export default function ForgetPassword(props: Props) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Card className={`shadow ${classes.card} forget-password`}>
      <Card.Body>
        <Card.Title>
          <TitalForgetPassword>{t('FORGET_PASSWORD.TITLE')}</TitalForgetPassword>
        </Card.Title>
        <Card.Body>
          {!props.isSucceeded ? (
            <>
              <Grid>
                <Card.Text>{`${t('FORGET_PASSWORD.LABEL')}`}</Card.Text>
              </Grid>
              <Grid>
                <br />
                <InputTextField onchange={props.onChange} disabled={props.loading} label={t('FORGET_PASSWORD.INPUT.EMAIL')} helperText={props.helperText} />
                <br />
              </Grid>
              <Grid className="pb-2">
                <ButtonCustom type="submit" disabled={props.loading} onClick={props.submit} textButton={t('FORGET_PASSWORD.BUTTON.SUBMIT')} btnStyle={{ fontSize: '16px', marginTop: '24px', width: '100%' }} />
              </Grid>
            </>
          ) : (
            <Typography className={classes.typographySuccess}>{t('FORGET_PASSWORD.MESSAGE.SUCCESS')}</Typography>
          )}
        </Card.Body>
        <Divider className="my-0" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
        <ButtonGoBack onClick={props.onClick} disabled={props.loading} style={{ color: props.loading ? colors.disabledGray : colors.themeSecondColor }}>
          {t('FORGET_PASSWORD.BUTTON.GO_LOGIN')}
        </ButtonGoBack>
      </Card.Body>
    </Card>
  )
}
