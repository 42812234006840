import _ from 'lodash'
import moment from 'moment'
import { Box, Typography, } from '@mui/material'
import { useTranslation } from 'react-i18next'
/** TRANSLATION */
/** COMPONENT */
import { isBuddhistYear, numberFormat, toBuddhistYear } from "utils/app.utils"
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
interface FcComponent {
    page: number
    pageLimit: number
    rowCount: number
    data: any
    sortBy: string,
    sortType: string,
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
    onRequestSort: (sortBy: string, sortType: string) => void
}

export default function StockList(props: FcComponent) {
    const { t } = useTranslation()
    const headCells = [
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.#', align: 'center', class: 'text-nowrap' },
        { id: 'documentNo', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.DOCUMENT_NO', align: 'center', class: 'text-nowrap', sortable: true },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.SUBJECT', align: 'center', class: 'text-nowrap' },
        { id: 'lotNo', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.LOT_NO', align: 'center', class: 'text-nowrap', sortable: true },
        { id: 'productTypeName', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.PRODUCT_TYPE', align: 'center', class: 'text-nowrap', sortable: true },
        { id: 'productName', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.PRODUCT', align: 'center', class: 'text-nowrap', sortable: true },
        { id: 'expireDate', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.EXPIRE_DATE', align: 'center', class: 'text-nowrap', sortable: true },
        { id: 'qty', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.QTY', align: 'center', class: 'text-nowrap', sortable: true },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.UNIT', align: 'center', class: 'text-nowrap', },
        { id: 'costPrice', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.COST_PRICE', align: 'center', class: 'text-nowrap', sortable: true },
        { id: 'salePrice', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.SALE_PRICE', align: 'center', class: 'text-nowrap', sortable: true },
        { id: 'totalPrice', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.TOTAL_PRICE', align: 'center', class: 'text-nowrap', sortable: true },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.STOCK.CREATEDBY', align: 'center', class: 'text-nowrap' },
    ]


    const renderData = (data: any, no: number) => {
        const num = props.page * props.pageLimit - props.pageLimit + no + 1
        return <>
            <TableRowCommon
                key={num.toString()}
                id={no.toString()}
                obj={data}
                columns={[
                    { option: 'TEXT', align: 'center', label: num, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.documentNo, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.subject, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.lotNo, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.itemTypeName, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', label: data.itemName, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.expireDate ? moment(data.expireDate).add(isBuddhistYear(), 'y').format('DD/MM/YYYY') : '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.qty, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: data.itemUnitName || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: numberFormat(data.costPrice), class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: numberFormat(data.salePrice), class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', label: numberFormat(data.total), class: 'text-nowrap' },
                    { option: 'UPDATE_BY', align: 'center', label: { updatedBy: data.createdBy, updatedAt: data.createdAt }, class: 'text-nowrap' },
                ]} />
        </>
    }

    return <>
        <Box>
            <Typography style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '10px' }}>{t('REPORT.TABLE.STOCK.PRODUCT_LIST')}</Typography>
            <UseStyled.ResponsiveReportTable>
                <TableCustom
                    tableMinWidth={992}
                    page={props.page}
                    pageLimit={props.pageLimit}
                    sortType={props.sortType}
                    sortBy={props.sortBy}
                    rowCount={props.rowCount}
                    onSort={props.onRequestSort}
                    setPageLimit={props.handleChangeRowsPerPage}
                    setPage={props.handleChangePage}
                    headCells={headCells}
                    rowsData={_.map(props.data, (d: any, index: number) => {
                        return renderData(d, index)
                    })}
                    customScroll
                />
            </UseStyled.ResponsiveReportTable>
        </Box>
    </>
}