import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/setting/treatments/diagnosis/style'

/** API */
import { routeName } from 'routes/routes-name'
import DiagnosisApi from 'api/setting/treatments/diagnosis.api'

/** UTILS || SLICE */
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import FilterSelect from 'component/Select/FilterSelect'
import ButtonExport from 'component/Button/ButtonExport'
import TableRowExport from 'component/Pdf/TableRowExport'
import DiagnosisTypeApi from 'api/setting/treatments/diagnosis-type.api'

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

type DiaTypeProps = {
  diagnosisTypeId: number
  diagnosisTypeName: string
  diagnosisTypeNameEn: string
  status: 'ACTIVE' | 'INACTIVE'
}
export default function Diagnosis() {
  const classes = useStyles()
  const { t } = useTranslation()

  const [diagnosisTypes, setDiagnosis] = useState([])
  const [exportData, setExportData] = useState([])

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('ALL')

  const [diagnosisTypesOp, setDiagnosisTypesOp] = useState<DiaTypeProps[]>([])

  const loadDiagnosisTypes = async (search = '') => {
    const condition = { search: search, pageLimit: 50, status: 'ACTIVE' }
    const respDiagnosisTypes = await DiagnosisTypeApi.findAll(condition)
    if (respDiagnosisTypes.status === 200) setDiagnosisTypesOp(respDiagnosisTypes.data)
  }

  useEffect(() => {
    loadDiagnosisTypes()
  }, [])

  const loadData = useCallback(async () => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await DiagnosisApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setDiagnosis(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])

  useEffect(() => {
    loadData()
  }, [loadData])

  const loadDataForExport = useCallback(async () => {
    let condition: any = {}
    condition.export = 1
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await DiagnosisApi.findAll(condition)
    if (res.status === 200) {
      setExportData(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])

  useEffect(() => {
    loadDataForExport()
  }, [loadDataForExport])

  const onCreate = () => {
    window.location.href = routeName.diagnosis + '/create'
  }
  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('DIAGNOSIS.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          DiagnosisApi.updateStatus(id, valueStatus)
            .then((resp) => {
              notiSuccess(t('DIAGNOSIS.MESSAGE.SUCCESS.UPDATE_STATUS'))
              loadData()
            })
            .catch((e) => {
              notiError(t('DIAGNOSIS.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }
  const onDelete = (data: any) => {
    const { diagnosisId, diagnosisName } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('DIAGNOSIS.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('DIAGNOSIS.ALERT.CONFIRM_DELETE_', {
        diagnosisName: diagnosisName
      })}</p>`,
      (res: any) => {
        if (res) {
          DiagnosisApi.remove(diagnosisId)
            .then((resp) => {
              notiSuccess(t('DIAGNOSIS.MESSAGE.SUCCESS.DELETE'))
              loadData()
            })
            .catch((e) => {
              notiError(t('DIAGNOSIS.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
    )
  }

  const onEdit = (data: any) => {
    window.location.href = routeName.diagnosis + `/${data.diagnosisId}`
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }
  const handleChangeStatus = (statusVal: string) => {
    setStatus(statusVal)
  }
  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'diagnosisCode', disablePadding: false, label: t('DIAGNOSIS.TABLE.CELL.DIAGNOSIS_CODE'), align: 'center' },
    { id: 'diagnosisType', disablePadding: false, label: t('DIAGNOSIS.INPUT.DIAGNOSIS_TYPE') },
    { id: 'diagnosisName', disablePadding: false, label: t('DIAGNOSIS.TABLE.CELL.DIAGNOSIS_NAME') },
    { id: 'diagnosisRecommends', disablePadding: false, label: t('DIAGNOSIS.TABLE.CELL.DIAGNOSIS_RECOMMEND') },
    { id: 'status', disablePadding: false, label: t('DIAGNOSIS.TABLE.CELL.STATUS') },
    { id: 'updatedBy', disablePadding: false, label: t('DIAGNOSIS.TABLE.CELL.UPDATED_BY') },
    { id: 'action', disablePadding: false, label: 'Action' }
  ]

  const renderData = (objData: any, no: number, valueExportData: boolean) => {
    const num = valueExportData === true ? no + 1 : page * pageLimit - pageLimit + no + 1
    const { diagnosisId, diagnosisName, updatedBy, updatedAt, status: dgoStatus, diagnosisRecommends, diagnosisCode, diagnosisTypeId } = objData
    const statusBtnActive = dgoStatus === 'ACTIVE' ? true : permissions.isUpdate.disabled
    const statusBtnInActive = dgoStatus === 'INACTIVE' ? true : permissions.isUpdate.disabled

    const [diaType]: DiaTypeProps[] = diagnosisTypesOp.filter((item: DiaTypeProps) => item?.diagnosisTypeId === diagnosisTypeId)

    const objRenderData = {
      key: diagnosisId,
      id: diagnosisId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: num },
        { option: 'TEXT', align: 'center', label: diagnosisCode, },
        { option: 'TEXT', align: 'left', label: diaType?.diagnosisTypeName || diagnosisTypeId },
        { option: 'TEXT', align: 'left', label: diagnosisName },
        { option: 'TEXT', align: 'center', label: diagnosisRecommends ? diagnosisRecommends.length : 0 },
        { option: 'STATUS', align: 'center', label: dgoStatus },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ]
    }
    return (
      <>
        {valueExportData === true && <TableRowExport {...objRenderData} /> ||
          <TableRowCommon
            {...objRenderData}
            onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
            oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
            onedit={() => onEdit(objRenderData.obj)}
            ondelete={() => onDelete(objRenderData.obj)}
          />
        }
      </>
    )
  }

  const filter = [
    { label: `${t('INPUT.SEARCH')}: ${search || '-'}` },
    { label: `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}` }
  ]

  return (
    <div className={classes.root}>
      <Card className="border-0 h-100">
        <HeaderCard text={t('DIAGNOSIS.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="align-items-center">
              <Col md={8}>
                <Row className="flax-row">
                  <Col sm={12} md={6} xl={4} className="pt-2">
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('INPUT.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={4} className="pl-md-0 pt-2">
                    <FilterSelect
                      onchange={(event) => {
                        const value = event.target.value
                        if (value) {
                          handleChangeStatus(value)
                          setPage(1)
                        }
                      }}
                      renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                      label={t('STATUS.LABEL')}
                      selectId="select-status"
                      value={status}
                      labelId="label-status"
                      options={[
                        <MenuItem key="1" value="ALL">
                          {t('STATUS.ALL')}
                        </MenuItem>,
                        <MenuItem key="2" value="ACTIVE">
                          {t('STATUS.ACTIVE')}
                        </MenuItem>,
                        <MenuItem key="3" value="INACTIVE">
                          {t('STATUS.INACTIVE')}
                        </MenuItem>
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mt-2 mt-md-auto">
                <div className="pr-xl-2 d-flex">
                  <div className='ml-auto'>
                    <ButtonExport headCells={headCells} portrait={true} filter={filter} fileName={t('DIAGNOSIS.LIST')}
                      rowsData={exportData.map((val, i) => {
                        return renderData(val, i, true)
                      })} />
                  </div>
                  <div className='pl-3'>
                    <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => onCreate()} textButton={t('DIAGNOSIS.BUTTON.ADD')} className="w-auto ml-auto d-flex mt-auto" btnStyle={{ height: '44px' }} />
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Title>

          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={diagnosisTypes.map((val, i) => {
                return renderData(val, i, false)
              })}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}
