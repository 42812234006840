import BaseAPI from 'api/api'

export interface FindAllInterface {
  patientCaseId: number
  date: any
}
export default class Customs extends BaseAPI {
  static update(props: any): Promise<any> {
    return this.api.patch('treatment-history/custom-date', props).then((res) => res)
  }
}
