import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import LabOrderApi from 'api/lab-order.api'
import { resetModal } from 'app/slice/modal.slice'
import { colors } from 'constants/theme'
import 'features/lab-order/style.css'

/** COMPNENT */
import InputDatePicker from 'component/Input/InputDatePicker'
import InputTextField from 'component/Input/InputTextField'
import InputCheckbox from 'component/Input/InputCheckbox'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { numberOnly } from 'utils/app.utils';

export interface IReceiveLabProps {
    isShow: boolean
    id: number
    labOrderNumber?: string
    dateReceive?: string | null
    onReset?: () => void
    onSuccess?: () => void
}
const initStateErrorMessage = {
    USER_CODE_STRING_EMPTY: '',
    PASSWORD_STRING_EMPTY: '',
    CANCEL_REMARK_STRING_EMPTY: '',

    NOT_FOUND_USER: '',

    DATE_RECEIVE: '',
    DATE_BILL: '',
    PRICE: '',
}
export default function ReceiveLab(props: IReceiveLabProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [dateReceive, setDateReceive] = useState<string>(props?.dateReceive || '')
    const [receiveBill, setReceiveBill] = useState<boolean>(false)
    const [dateReceiveBill, setDateReceiveBill] = useState<string>('')
    const [price, setPrice] = useState<any>(0)

    const [errorMessage, setErrorMessage] = useState<any>({ ...initStateErrorMessage })

    const clearErrorMessage = async () => setErrorMessage(initStateErrorMessage)
    const resetForm = async () => {
        clearErrorMessage()
        setDateReceive('')
        setDateReceiveBill('')
        setPrice(0)
        setReceiveBill(false)
        if (props.onReset) props.onReset()
        dispatch(resetModal())
    }
    useEffect(() => {
        if (props?.dateReceive) setDateReceive(props?.dateReceive)
    }, [props?.dateReceive])
    useEffect(() => { clearErrorMessage() }, [dateReceive, receiveBill, dateReceiveBill, price])

    const onSubmit = (id: number) => {
        if (!dateReceive || receiveBill ? (!dateReceiveBill || price === '') : false) {
            setErrorMessage({
                DATE_RECEIVE: !dateReceive ? t('LAB_ORDER.MESSAGE.ERROR.DATE_RECEIVE') : '',
                DATE_BILL: !dateReceiveBill && receiveBill ? t('LAB_ORDER.MESSAGE.ERROR.DATE_BILL') : '',
                PRICE: price === '' && receiveBill ? t('LAB_ORDER.MESSAGE.ERROR.PRICE') : '',
            })
            return false
        }

        LabOrderApi.receive(id, { dateReceive, dateBill: dateReceiveBill, labOrderPrice: price })
            .then((res) => {
                notiSuccess(t(`LAB_ORDER.MESSAGE.SUCCESS.RECEIVE`))
                dispatch(resetModal())
                resetForm()
                if (props.onSuccess) props.onSuccess()
            })
            .catch((e) => (notiError(t('LAB_ORDER.MESSAGE.ERROR.CAN_NOT_RECEIVE'))))
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReceiveBill(event.target.checked)
        setDateReceiveBill('')
        setPrice(0)
    }

    return props.isShow ? (
        <ModalCustom
            size={'lg'}
            modalStyle={'modal-receive-lab'}
            title={t('LAB_ORDER.TITLE_RECEIVE_LAB')}
            alignFooter={'center'}
            // modalTheme={'danger'}
            component={
                <Grid container spacing={2} className="mb-3">
                    <Grid item className='w-100'>
                        <InputCheckbox
                            disabled
                            id={`receive-lab`}
                            key={`receive-lab`}
                            checked
                            label={t('LAB.RECEIVE_LAB')}
                            style={{ '.MuiCheckbox-root': { marginRight: '4px !important' }, '&.Mui-disabled .MuiFormControlLabel-label': { color: colors.textPrimary } }} />
                    </Grid>
                    <Grid item md={6} className='w-100 pt-2'>
                        <InputDatePicker
                            required={true}
                            // disabled={props?.dateReceive !== null}
                            key={'dateReceive'}
                            label={t('LAB.DATE_RECEIVE_LAB')}
                            dateFormat="DD/MM/YY"
                            value={dateReceive}
                            helperText={errorMessage.DATE_RECEIVE || ''}
                            onChange={(e: any) => setDateReceive(e.format('YYYY-MM-DD'))}
                        />
                    </Grid>
                    <Grid item className='w-100 pt-2'>
                        <Box className='d-sm-flex align-items-center'>
                            <InputCheckbox
                                id={`receive-receipt`}
                                key={`receive-receipt`}
                                checked={receiveBill}
                                onChange={handleChange}
                                label={t('LAB.RECEIVE_RECEIPT')}
                                style={{ marginRight: '8px', '.MuiCheckbox-root': { marginRight: '4px !important' } }} />
                            <Typography className='mt-sm-0' sx={{ color: colors.themeThirdColor, marginTop: '-8px' }}>{t('LAB.RECEIVE_RECEIPT_SUB')}</Typography>
                        </Box>
                    </Grid>
                    <Grid item md={6} className='w-100 pt-2'>
                        <InputDatePicker
                            required={true}
                            disabled={!receiveBill}
                            key={'dateReceiveBill'}
                            label={t('LAB.DATE_RECEIVE_RECEIPT')}
                            dateFormat="DD/MM/YY"
                            value={dateReceiveBill || ''}
                            helperText={errorMessage.DATE_BILL || ''}
                            onChange={(e: any) => setDateReceiveBill(e.format('YYYY-MM-DD'))}
                        />
                    </Grid>
                    <Grid item md={6} className='w-100 pt-lg-2'>
                        <InputTextField
                            required
                            label={t('LAB.LAB_PRICE')}
                            disabled={!receiveBill}
                            value={price}
                            inputProps={{ shrink: true, }}
                            helperText={errorMessage.PRICE || ''}
                            onchange={(e) => setPrice(numberOnly(e))}
                        />
                    </Grid>
                </Grid>
            }
            id={props.id}
            textBtnConfirm={t('LAB.BUTTON.SUBMIT')}
            onSubmit={() => onSubmit(props.id)}
            autoResetModal={false}
            onReset={() => (resetForm())}
            textBtnCancel={t('APPOINTMENT.FORM.CANCEL')}
        />
    ) : <></>
}