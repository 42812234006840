import _ from 'lodash'
import { MenuItem, FormControl, InputLabel, Select } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { colors } from 'constants/theme'
import { styled } from '@mui/material/styles'
import 'component/Select/Style.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import HelperText from 'component/Input/HelperText'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  '.MuiOutlinedInput-root': {
    backgroundColor: colors.white
  },
  '.MuiInputLabel-root, .MuiInputLabel-root span': {
    fontSize: 16,
    color: `${colors.black60} !important`,
    '-webkit-text-fill-color': 'unset !important',
    backgroundColor: 'transparent !important',
    '&:before': { content: '""', position: 'absolute', width: '100%', height: 3, top: '50%', zIndex: -1 },
    '&[data-shrink="true"]': {
      '&:before': { backgroundColor: colors.white },
      span: { color: `${colors.statusInactive} !important` },
      '&.Mui-focused': {
        color: `${colors.themeSecondColor} !important`
      }
    }
  },
  '&.filed-error': {
    '.MuiInputLabel-root[data-shrink="true"], .MuiInputLabel-root[data-shrink="true"] span': { color: `${colors.statusInactive} !important` },
    '.MuiOutlinedInput-notchedOutline': { border: `2px solid ${colors.statusInactive} !important` },
    '.Mui-focused .MuiOutlinedInput-notchedOutline': { border: `2px solid ${colors.statusInactive} !important` }
  },
  '&.filed-allow-clear': {
    '.MuiSelect-select': {
      paddingRight: 52
    },
    '.icon-clear-value': {
      position: 'absolute',
      top: 10,
      right: 28
    }
  }
}))

const CustomSelect = styled(Select)<{ inputHeight?: any; inputTextAlign?: 'left' | 'center' | 'right' }>(({ theme, inputHeight, inputTextAlign }) => ({
  '&.Mui-focused': {
    '.MuiOutlinedInput-notchedOutline': { borderWidth: '1px !important', borderColor: `${colors.themeSecondColor} !important` },
    '.MuiSelect-nativeInput': { '+ svg': { transform: 'rotate(180deg)' } }
  },
  '&.Mui-disabled': {
    '.MuiOutlinedInput-notchedOutline': {
      backgroundColor: colors.black12
    }
  },
  '.MuiSelect-select': {
    paddingTop: inputHeight ? 5 : 10,
    paddingBottom: inputHeight ? 5 : 10,
    textAlign: inputTextAlign || 'left',
    '&.Mui-disabled': {
      color: colors.black60,
      '-webkit-text-fill-color': 'unset'
    }
  },
  '.MuiSelect-nativeInput': {
    height: inputHeight || 43,
    '+ svg': {
      position: 'absolute',
      right: 14,
      path: {
        fill: colors.black
      }
    }
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: `${colors.black12} !important`,
    legend: {
      width: 0
    }
  }
}))

type BasicSelectProps = {
  labelId: string
  selectId: string
  label: string
  value?: any
  options: any
  disabled?: boolean
  fullWidth?: boolean
  multiple?: boolean
  required?: boolean
  formControlClass?: any
  register?: any
  name?: any
  defaultValue?: any
  style?: any
  helperText?: string
  onchange?: (value: any) => void
  classesOption?: string
  renderValue?: (value: any) => void
  inputHeight?: any
  inputTextAlign?: 'left' | 'center' | 'right'
  allowClear?: boolean
  onClear?: (val: any) => void
}

export default function BasicSelect(props: BasicSelectProps) {
  const { t } = useTranslation()

  return (
    <CustomFormControl
      className={`${props.formControlClass} ${props.helperText ? 'filed-error' : ''} ${props.allowClear && props.value ? 'filed-allow-clear' : ''}`}
      size="small"
      margin="none"
      fullWidth={props.fullWidth !== undefined ? props.fullWidth : true}
      disabled={props.disabled}
      error={true}
    >
      <InputLabel size="small" style={{ ...props.style }} id={props.labelId} required={props.required}>{`${props.label}`}</InputLabel>
      <CustomSelect
        inputHeight={props.inputHeight}
        inputTextAlign={props.inputTextAlign}
        MenuProps={{
          classes: { paper: `style-filter-select-search custom-scroll style-basic-select style-select-doctors ${props.classesOption}` },
          PaperProps: {
            sx: {
              '.MuiMenuItem-root': {
                '&:hover': {
                  backgroundColor: `${colors.themeSecondColor06} !important`,
                  color: colors.themeSecondColor
                }
              }
            }
          }
        }}
        defaultValue={props.defaultValue}
        style={{ ...props.style }}
        {...props.register}
        name={props.name}
        disabled={props.disabled}
        labelId={props.labelId}
        id={props.selectId}
        value={props.value}
        renderValue={props.renderValue}
        label={props.label}
        onChange={props.onchange}
        onClose={() => {
          setTimeout(() => {
            const ele: any = document.activeElement
            ele.blur()
          }, 0)
        }}
        multiple={props.multiple || false}
        IconComponent={() => <FontAwesomeIcon icon={faCaretDown} />}
      >
        {!_.isEmpty(props.options) ? (
          props.options
        ) : (
          <MenuItem
            value={''}
            className="pe-none text-center"
            sx={{
              '&.text-center': {
                justifyContent: 'center !important',
                paddingTop: '0 !important',
                paddingBottom: '0 !important'
              }
            }}
          >
            {t('REGISTER.FORM.NOT_FOUND')}
          </MenuItem>
        )}
      </CustomSelect>
      {props.allowClear && props.value ? <ClearIcon className="icon-clear-value cursor-pointer" onClick={props.onClear} /> : <></>}
      {props.helperText ? (
        <p className="mb-0" style={{ marginRight: '14px' }}>
          <HelperText label={props.helperText} />
        </p>
      ) : (
        ''
      )}
    </CustomFormControl>
  )
}
