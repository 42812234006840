import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { Box, MenuItem, Divider, TableSortLabel, ClickAwayListener } from '@mui/material'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'

import { useHistory } from 'react-router-dom'
import { routeName } from 'routes/routes-name'

//component
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import FucntionPrint from 'component/Print/print'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import CommissionList from './commissionList'
import CommissionSummary from './commissionSummary'
import { Tooltip, Fade } from '@mui/material'
//utils
import { getBranch, isBuddhistYear, toBuddhistYear } from 'utils/app.utils'
import { UseSnCode } from 'utils/useSnCode'
/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { colors } from 'constants/theme'

//API
import ClinicApi, { BranchInterface, DentistInterface } from 'api/master/clinic.api'
import Loading from 'component/Loading'
import InputCheckbox from 'component/Input/InputCheckbox'
import ReportApi, { FindAllReportInterface } from 'api/report.api'
import DoctorScheduleApi from 'api/counter/doctor-schedule.api'
import { ExportProps } from '../report-constants'
import TableRowExport from 'component/Pdf/TableRowExport'
import InputTextSearch from 'component/Input/InputTextSearch'
import OperativeApi from 'api/setting/treatments/operative.api'
import ButtonCustom from 'component/Button/ButtonCustom'
import { CustomFilterDoctor, ListFilterDoctors } from 'features/counter/appointment/head-appointment'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { SpanClearFilter } from 'features/report/useStyled'
type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}

export default function Commission() {
  const { t } = useTranslation()
  const history = useHistory()
  const momentDate = () => [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]

  const componentRef = useRef<HTMLDivElement>(null)
  const [branches, setBranches] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const [opertatives, setOperative] = useState<any[]>([])
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)

  const [search, setSearch] = useState<string>('')
  const [branchId, setBranchId] = useState<any>(getBranch())
  const [rangeDate, setRangeDate] = useState<string[]>(momentDate)

  const [doctorsMultiple, setDoctorsMultiple] = useState<DentistInterface[]>([])
  const [selectDoctors, setSelectDoctors] = useState<any[]>([])
  const [receiptPrefix, setReceiptPrefix] = useState<any[]>([])
  const [billPrefixId, setBillPrefixId] = useState(0)
  const [doctors, setDoctors] = useState<Doctors[]>([])
  const [commissionData, getCommissionData] = useState<any>({ rows: [] })
  const [commissionExportData, setCommissionExportData] = useState<any>({ rows: [] })
  const [rowCount, setRowCount] = useState<number>(0)

  const [operativeId, setOperativeId] = useState<any>(null)
  const [checked, setChecked] = useState<boolean>(false)
  const [fetch, setFetch] = useState<boolean>(false)

  const [snType, setSnType] = useState<boolean>(false)

  const filterSelectOperativeName = useRef<HTMLDivElement>(null)
  const [filterSelectOperativeNameWidth, setFilterSelectOperativeNameWidth] = useState<number>(0)
  const [openFilterDoctors, setOpenFilterDoctors] = useState(false)

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])

  useEffect(() => {
    if (filterSelectOperativeName.current) {
      const width: any = filterSelectOperativeName.current.clientWidth

      setFilterSelectOperativeNameWidth(width)
    }
  }, [filterSelectOperativeName.current, filterSelectOperativeName?.current?.clientWidth])
  const setDoc = (data: any) => {
    const sortedSelectData = data.sort((a: any, b: any) => {
      const idA = a.dentistId
      const idB = b.dentistId
      return selectDoctors.indexOf(idA) - selectDoctors.indexOf(idB)
    })
    return sortedSelectData
  }
  const handleFilterDoctors = (value: any) => {
    if ((value === 'ALL' && _.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)) || value === 'CLEAR') setSelectDoctors([''])
    else if (value === 'ALL') setSelectDoctors([..._.map(doctors, (d: any) => d.userId)])
    else if (_.includes(selectDoctors, value)) setSelectDoctors(_.filter(selectDoctors, (d) => d !== value))
    else setSelectDoctors([...selectDoctors, value])
  }

  const getAllFilter = () => {
    return [
      {
        label: t('REPORT.FILTER.BRANCH'),
        value: _.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')
      },
      {
        label: t('REPORT.FILTER.RECEIPT_TYPE'),
        value: billPrefixId ? receiptPrefix.find((item) => item.billPrefixId === billPrefixId).prefix : t('REPORT.REPORT_GROUP.ALL')
      },
      {
        label: t('REPORT.FILTER.DATE_START'),
        value: rangeDate[0] ? toBuddhistYear(moment(rangeDate[0]), 'DD/MM/YYYY') : '-'
      },
      {
        label: t('REPORT.FILTER.DATE_END'),
        value: rangeDate[1] ? toBuddhistYear(moment(rangeDate[1]), 'DD/MM/YYYY') : '-'
      },
      {
        label: t('REPORT.FILTER.DOCTOR_SALE'),
        value: _.isEmpty(selectDoctors)
          ? ` ${t('REPORT.FILTER.NO_SELECT')}`
          : selectDoctors.filter((i) => typeof i === 'number').length === doctors.length
            ? `${t('REPORT.FILTER.ALL')}`
            : `${setDoc(doctors.filter((d: any) => selectDoctors.includes(d.userId)))
              ?.map((d: any) => d.fullname)
              .slice(0, 3) // เเสดงเเค่ 3 คนเเรก
              .join(', ')}`
      },
      {
        label: t('REPORT.FILTER.OPERATIVE_PRODUCT'),
        value: operativeId ? operativeId.operativeName : t('REPORT.REPORT_GROUP.ALL')
      }
    ]
  }
  const { onDownload } = useDownloadExcel({
    tableExportRef: componentRef.current,
    filename: `${t(`REPORT.REPORT_NAME.COMMISSION`)} (${rangeDate[0]}-${rangeDate[1]})`,
    sheetName: `${t(`REPORT.REPORT_NAME.COMMISSION`)}`
  })

  const loadCommissionExport = useCallback(async () => {
    let condition: any = {
      page: 1,
      pageLimit: 10000,
      branchId: branchId,
      dateStart: rangeDate[0],
      dateEnd: rangeDate[1],
      export: 1
    }
    if (doctors) {
      const doctor = _.filter(selectDoctors, (v: any) => v > 0).join(',')
      if (doctor) condition = { ...condition, userId: _.filter(selectDoctors, (v: any) => v > 0).join(',') }
    }

    if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }

    if (billPrefixId) condition = { ...condition, paymentTypeId: receiptPrefix.find((item) => item.billPrefixId === billPrefixId).prefix }
    if (operativeId?.operativeId) condition = { ...condition, operativeId: operativeId.operativeId }
    if (search) {
      condition = condition = { ...condition, search: search }
    }

    ReportApi.reportCommission(condition)
      .then(({ data }) => {
        setCommissionExportData(data)
      })
      .catch((e) => setLoading(false))
      .finally(() => {
        setLoading(false)
        setFetch(false)
      })
  }, [page, limit, fetch, doctors])

  const fetchCommission = useCallback(() => {
    setLoading(true)
    let condition: any = {
      page: page,
      pageLimit: limit,
      branchId: branchId,
      dateStart: rangeDate[0],
      dateEnd: rangeDate[1]
    }
    if (search) {
      condition = condition = { ...condition, search: search }
    }
    if (doctors) {
      const doctor = _.filter(selectDoctors, (v: any) => v > 0).join(',')
      if (doctor) condition = { ...condition, userId: _.filter(selectDoctors, (v: any) => v > 0).join(',') }
    }

    if (rangeDate) {
      if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    }
    if (billPrefixId) condition = { ...condition, paymentTypeId: receiptPrefix.find((item) => item.billPrefixId === billPrefixId).prefix }
    if (operativeId?.operativeId) condition = { ...condition, operativeId: operativeId.operativeId }

    ReportApi.reportCommission(condition)
      .then(({ headers, data }) => {
        getCommissionData(data)
        setRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => {
        setLoading(false)
        setFetch(false)
      })
  }, [page, limit, fetch, doctors])

  const verifyPermission = async () => {
    const condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 200
    }
    ReportApi.reportList(condition).then(({ data }) => {
      const check = _.find(data, (val: any) => String(val.reportKey) === 'COMMISSION') ? true : false
      if (!check) {
        history.push(routeName.report)
        return false
      }
    })
    /** MASTER DATA */
    ClinicApi.findAllBranches()
      .then(({ data }) => setBranches(data))
      .catch((e) => {
        return
      })

    DoctorScheduleApi.findAllDoctor({ branchId: branchId }).then(({ data: dataAllDoctor }) => {
      const dentists: DentistInterface[] = dataAllDoctor.map((d: any) => {
        return {
          dentistId: d.userId,
          dentistFullname: d.fullname
        }
      })
      setDoctorsMultiple(dentists)
      setSelectDoctors(['ALL', ...dentists.map((d: DentistInterface) => d.dentistId)])
    })

    OperativeApi.findAll({ export: 1 })
      .then(({ data }) => {
        setOperative([{ operativeName: t('REPORT.REPORT_GROUP.ALL'), operativeId: 0 }, ...data])
      })
      .catch((e) => {
        return
      })

    ClinicApi.allBillPrefix()
      .then((resp) => setReceiptPrefix([{ billPrefixId: 0, prefix: t('REPORT.REPORT_GROUP.ALL') }, ...resp.data]))
      .catch((e) => {
        return
      })

    ClinicApi.findAllDoctors()
      .then((resp) => {
        setDoctors([...resp.data])
      })
      .catch((e) => {
        return
      })

    return true
  }

  useEffect(() => {
    verifyPermission()
  }, [])

  useEffect(() => {
    fetchCommission()
  }, [fetchCommission])

  useEffect(() => {
    loadCommissionExport()
  }, [loadCommissionExport])

  const handleChangeRowsPerPage = (limit: number) => {
    setLimit(limit)
  }
  const handleChangePage = (val: number) => setPage(val)
  const onRequestSort = () => {
    // setSortType(sortTypeVal)
    // setSortBy(sortByVal)
  }

  const headCells = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.#NO', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.BILL_ID', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.DATETIME', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.USER_NAME', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.CN', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.SN', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.PATIENT_NAME', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.LIST', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.PRICE', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.QTY', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.TOTAL', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.PAID', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.COMMISSION', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.REMARK', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.CREATEDBY', align: 'center', class: 'text-nowrap' }
  ]

  if (!snType) {
    headCells.splice(5, 1)
  }

  const renderCommissionExport = (data: any, no = 1) => {
    const num = no + 1
    const columns = [
      { option: 'TEXT', align: 'center', label: num },
      { option: 'TEXT', align: 'left', label: data.paymentNumber },
      { option: 'TEXT', align: 'left', label: moment(data.dataTime).add(isBuddhistYear(), 'y').format('DD/MM/YYYY HH:mm น.') },
      { option: 'TEXT', align: 'left', label: data.userFullName },
      { option: 'TEXT', align: 'center', label: data.cnNumber },
      { option: 'TEXT', align: 'center', label: data.snCode },
      { option: 'TEXT', align: 'left', label: data.patientFullName },
      { option: 'TEXT', align: 'left', label: data.list },
      { option: 'TEXT', align: 'right', label: data.price },
      { option: 'TEXT', align: 'center', label: data.qty },
      { option: 'TEXT', align: 'right', label: data.total },
      { option: 'TEXT', align: 'right', label: data.amountPaid },
      { option: 'TEXT', align: 'right', label: data.commission },
      { option: 'TEXT', align: 'left', label: data.remark },
      { option: 'TEXT', align: 'left', label: data.createdBy }
    ]
    if (!snType) {
      columns.splice(5, 1)
    }
    return (
      <>
        <TableRowExport key={num.toString()} id={no.toString()} obj={data} columns={columns} />
      </>
    )
  }

  return (
    <Card className={'border-0 h-100'}>
      <HeaderCard text={t(`REPORT.REPORT_NAME.COMMISSION`)} />
      <Card.Body>
        <Card.Title className={'mb-0'}>
          <Row className="mx-0">
            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
              <InputTextSearch
                keyInput={'search'}
                label={t('INPUT.SEARCH')}
                value={search}
                onchange={(event) => {
                  setSearch(event.target.value)
                }}
                onClear={(event) => {
                  setSearch('')
                }}
              />
            </Col>
            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
              <FilterSelect
                onchange={(e) => {
                  setBranchId(e.target.value)
                }}
                renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                label={''}
                selectId="select-branch"
                labelId="label-branch"
                value={branchId}
                options={_.map(branches, (b: BranchInterface, index) => {
                  return (
                    <MenuItem key={b.branchId} value={b.branchId}>
                      {b.branchName || b.branchNameEn}
                    </MenuItem>
                  )
                })}
                formControlStyle={{ maxWidth: 'unset !important' }}
                classesOption="style-select-doctors"
              />
            </Col>
            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
              <FilterSelect
                onchange={(e) => {
                  setBillPrefixId(e.target.value)
                }}
                renderValue={() => `${t('REPORT.FILTER.RECEIPT_TYPE')}: ${billPrefixId ? receiptPrefix.find((item) => item.billPrefixId === billPrefixId).prefix : t('REPORT.REPORT_GROUP.ALL')} `}
                label={''}
                selectId="select-branch"
                labelId="label-branch"
                value={billPrefixId}
                options={_.map(receiptPrefix, (b, index) => {
                  return (
                    <MenuItem key={b.billPrefixId} value={b.billPrefixId}>
                      {b.prefix}
                    </MenuItem>
                  )
                })}
                formControlStyle={{ maxWidth: 'unset !important' }}
                classesOption="style-select-doctors"
              />
            </Col>
            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
              <FilterSelect
                onchange={(e) => {
                  setOperativeId(e.target.value)
                }}
                renderValue={() => `${t('REPORT.FILTER.OPERATIVE_PRODUCT')}: ${operativeId ? operativeId.operativeName : t('REPORT.REPORT_GROUP.ALL')} `}
                label={''}
                selectId="select-branch"
                labelId="label-branch"
                value={operativeId || { operativeName: t('REPORT.REPORT_GROUP.ALL'), operativeId: 0 }}
                options={_.map(opertatives, (b, index) => {
                  return (
                    <Tooltip title={b.operativeName || b.operativeNameEn} TransitionComponent={Fade} placement="top" PopperProps={{ disablePortal: true }}>
                      <Box component="div" className={'px-1'} sx={{ textOverflow: 'ellipsis', width: filterSelectOperativeNameWidth }}>
                        <MenuItem sx={{ overflow: 'hidden' }} key={b.operativeId} value={b}>
                          {b.operativeName || b.operativeNameEn}
                        </MenuItem>
                      </Box>
                    </Tooltip>
                  )
                })}
                formControlStyle={{ maxWidth: 'unset !important' }}
                classesOption="style-select-doctors"
              />
            </Col>
            <Col
              sm={6}
              md={4}
              xl={3}
              className={'pt-2 px-1'}
              ref={filterSelectOperativeName}
              onClick={() => {
                setChecked(!checked)
                setPage(1)
              }}
            >
              <ClickAwayListener onClickAway={() => setOpenFilterDoctors(false)}>
                <CustomFilterDoctor open={openFilterDoctors} onClick={() => setOpenFilterDoctors(true)}>
                  <AutocompleteSelect
                    open={openFilterDoctors}
                    multiple
                    labelId="label-doctors"
                    options={['ALL', ...doctors, 'CLEAR']}
                    getOptionLabel={(option) => _.find(doctors, { userId: option.userId })?.fullname || '-'}
                    renderOption={(props, option) => {
                      if (option === 'ALL') {
                        return (
                          <ListFilterDoctors {...props} key={option} component="li" value={option}>
                            <InputCheckbox
                              label={t('REPORT.REPORT_GROUP.ALL')}
                              checked={_.size(selectDoctors.filter((item) => typeof item === 'number')) === _.size(doctors)}
                              indeterminate={_.size(selectDoctors) > 1 && _.size(selectDoctors.filter((item) => typeof item === 'number')) !== _.size(doctors)}
                              className={'pe-none'}
                            />
                          </ListFilterDoctors>
                        )
                      } else if (option === 'CLEAR') {
                        return (
                          <Box {...props} key={option} component="li" value={option} sx={{ borderTop: `1px solid ${colors.lightGray}` }}>
                            <SpanClearFilter className="pe-none">{t('REPORT.FILTER.CLEAR_FILTER')}</SpanClearFilter>
                          </Box>
                        )
                      } else {
                        return (
                          <ListFilterDoctors {...props} key={option.userId} component="li" value={option.userId}>
                            <InputCheckbox label={option.fullname} checked={_.includes(selectDoctors, option.userId)} className={'pe-none'} />
                          </ListFilterDoctors>
                        )
                      }
                    }}
                    renderTags={(tagValue, getTagProps) => {
                      return (
                        <>
                          <span className="pr-1">{t('USER_DOCTOR')}:</span>
                          {!openFilterDoctors &&
                            ((_.size(tagValue.filter((item: any) => typeof item === 'number')) === _.size(doctors) && <span>{t('REPORT.REPORT_GROUP.ALL')}</span>) || (
                              <Box className="text-ellipsis">
                                {_.map(tagValue, (option: any, index: number) => {
                                  return (
                                    <span>
                                      {_.find(doctors, { userId: option })?.fullname}
                                      {index !== _.findLastIndex(tagValue) && option && `,`}
                                    </span>
                                  )
                                })}
                              </Box>
                            ))}
                        </>
                      )
                    }}
                    onchange={(e, value) => handleFilterDoctors(e?.target?.value || e?.target?.attributes?.value?.value)}
                    noOptionsText={t('NOT_FOUND')}
                    filterOptions={(option) => 'ALL' + option.fullname + 'CLEAR'}
                    value={selectDoctors}
                    height={32}
                    disableClearable
                  />
                </CustomFilterDoctor>
              </ClickAwayListener>
            </Col>

            <Col sm={6} md={4} xl={3} className={'pt-2 px-1'}>
              <InputSecondNewRangePicker
                inputHeight={32}
                value={rangeDate || emptyRangePicker}
                onchange={(val: any) => {
                  if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                  else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                }}
                allowClear
                onClear={() => setRangeDate(emptyRangePicker)}
                label={''}
              />
            </Col>
            <Col md={1} xl={1} className={'pt-0'}>
              <ButtonCustom
                onClick={() => {
                  setFetch(true)
                  setPage(1)
                  setLoading(true)
                }}
                textButton={t('INPUT.SEARCH')}
                style={{ height: '30px', marginTop: '5px' }}
              />
            </Col>
            <Col md={4} xl={3} className={'d-xl-flex justify-content-xl-end ml-xl-auto pt-2 px-1'}>
              <UseStyled.DropdownDocument>
                <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
                  {t('REGISTER.BUTTON.PRINT_DOCUMENT')}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={onDownload}>{t('Excel')}</Dropdown.Item>
                  <FucntionPrint
                    content={() => componentRef.current}
                    docTitle={t(`REPORT.REPORT_NAME.OPERATIVE_SUMMARY_MONTH`)}
                    removeIfram
                    trigger={() => <Dropdown.Item>{t('PDF')}</Dropdown.Item>}
                    style={`@page { size: landscape;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                  />
                </Dropdown.Menu>
              </UseStyled.DropdownDocument>
            </Col>
          </Row>
        </Card.Title>

        <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
          <CommissionList page={page} pageLimit={limit} rowCount={rowCount} data={commissionData} handleChangeRowsPerPage={handleChangeRowsPerPage} handleChangePage={handleChangePage} onRequestSort={onRequestSort} />
        </Box>
        <Divider className="mx-0 mt-3 mb-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
        <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
          <CommissionSummary filterDate={rangeDate} page={page} pageLimit={limit} rowCount={rowCount} data={commissionData} handleChangeRowsPerPage={handleChangeRowsPerPage} handleChangePage={handleChangePage} onRequestSort={onRequestSort} />
        </Box>
      </Card.Body>
      <Loading show={loading} type="fullLoading" />

      <div className="print-show" ref={componentRef}>
        <ExportData
          componant={
            <>
              <span style={{ fontWeight: 'bold' }}>{t(`REPORT.REPORT_NAME.COMMISSION`)}</span>
              <table>
                <tr>
                  {getAllFilter().map((filter: any, index: number) => (
                    <TableSortLabel key={index}>
                      <td className="mr-2" style={{ textAlign: 'left', fontSize: 10, fontWeight: 800 }}>
                        {t(filter.label)} :{' '}
                      </td>
                      <td style={{ textAlign: 'left', fontSize: 10, fontWeight: 500 }}>{filter.value}</td>
                    </TableSortLabel>
                  ))}
                </tr>
              </table>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      {_.map(headCells, (val: any, index: number) => {
                        return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(commissionExportData.rows, (d: any, index: number) => {
                      return renderCommissionExport(d, index)
                    })}
                  </tbody>
                </table>
              </UseStyled.Styles>
            </>
          }
        />
      </div>
    </Card>
  )
}

export function ExportData(props: ExportProps) {
  return <>{props.componant}</>
}
