import { menuSeconds } from 'constants/menus'
import ImportProductForm from 'features/warehouses/import-products/import-product-form'
import ImportProduct from 'features/warehouses/import-products/import-products'
import ImportProductList from 'features/warehouses/import-products/import-list-product'

const routeImportProduct = [
  {
    key: menuSeconds.IMPORT_PRODUCT.key,
    name: menuSeconds.IMPORT_PRODUCT.name,
    textName: 'เพิ่มรายการสินค้า',
    active: false,
    path: menuSeconds.IMPORT_PRODUCT.path + '/create',
    component: ImportProductForm,
    appBar: true
  },
  {
    key: menuSeconds.IMPORT_PRODUCT.key,
    name: menuSeconds.IMPORT_PRODUCT.name,
    textName: 'แก้ไขรายการสินค้า',
    active: false,
    path: menuSeconds.IMPORT_PRODUCT.path + '/update',
    component: ImportProductForm,
    appBar: true
  },
  {
    key: menuSeconds.IMPORT_PRODUCT.key,
    name: menuSeconds.IMPORT_PRODUCT.name,
    textName: 'รายละเอียดรายการนําเข้า',
    active: false,
    path: menuSeconds.IMPORT_PRODUCT.path + '/list',
    component: ImportProductList,
    appBar: true
  },
  {
    ...menuSeconds.IMPORT_PRODUCT,
    component: ImportProduct,
    appBar: true
  }
]

export default routeImportProduct
