
const Rotate = (props: any) => {
    return (
        <>
            <svg
                width={props?.width || "24"}
                height={props?.height || "24"}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.46715 12.8675C1.79307 15.3433 3.014 17.6161 4.90139 19.2602C6.78878 20.9044 9.21323 21.8072 11.7209 21.7998C14.2286 21.7923 16.6476 20.875 18.5251 19.2197C20.4025 17.5643 21.6098 15.2843 21.9208 12.8066C22.2319 10.3288 21.6254 7.82307 20.2149 5.75845C18.8044 3.69384 16.6866 2.21188 14.2579 1.58999C11.8292 0.968091 9.25617 1.2489 7.02042 2.37984C4.78467 3.51078 3.03948 5.41432 2.11155 7.73414M1.46715 1.31748L1.46715 7.73414L7.91114 7.73414"
                    stroke="white"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    )
}

export default Rotate