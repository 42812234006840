import { BLOOD_TYPE, GENDER, IS_HAS, MARITAL_STATUS, RELIGION, TRUE_FALSE } from 'constants/status'
import BaseAPI from 'api/api'

export interface PatientInterface {
  rank: string
  cnNumber: string
  branchCnNumber: string
  prefixId: number
  firstname: string
  lastname: string
  firstnameEn: string
  lastnameEn: string
  displayName: string
  idCard: string
  hasIdCard: IS_HAS
  passport: string
  workPermitNumber: string
  gender: GENDER
  dateOfBirth: Date
  nationalityId: number
  originId: number
  religion: RELIGION
  maritalStatus: MARITAL_STATUS
  careerId: number
  accessChannelId: number
  accessChannelOther: string
  serviceFactorId: number
  erviceFactorOther: string
  phone: string
  telephone: string
  line: string
  email: string
  facebook: string
  patientTypeId: number
  bloodType: BLOOD_TYPE
  frequencyCigarette: string
  frequencyAlcohol: string
  pregnantHistory: IS_HAS
  surgeryHistory: IS_HAS
  surgeryDate: Date | ''
  disease: string
  pregnantMonth: string
  isPregnant: TRUE_FALSE
  isRecuperate: TRUE_FALSE
  isMedicalCertificate: TRUE_FALSE
  isInsure: TRUE_FALSE
  medicineUse: string
  beAllergic: string
  importantSymptoms: string
  symptoms: string
  countryId: number
  provinceId: number
  districtId: number
  subDistrictId: number
  postcode: string
  address: string
  parents: string
  specialInfo: string
  weight: string
  height: string
  systolicBloodPressure: string
  diastolicBloodPressure: string
  waistline: string
  temperature: string
  pulseRate: string
  heartRate: string
  regIrreq: string
  rr: string
  o2Sat: string
  bmi: string
  bsa: string
  profile: any
  signature?: any
}

export interface FormRegisterInterface {
  formRegister: string
}

export interface LineNotify {
  code: string
  patientId: number
  redirectTo: string
}

export interface ValidatePatientInfoInterface {
  firstname: string
  lastname: string
  phone: string
  patientId?: number
}

const url = `register`
export default class RegisterApi extends BaseAPI {
  static lastCnNumber() {
    return this.api.get(`${url}/last-cn-number`)
  }
  static lastBranchCnNumber(): Promise<any> {
    return this.api.get(`${url}/last-branch-cn-number`)
  }
  static findPatientById(patientId?: any): Promise<any> {
    return this.api.get(`${url}/patient/${patientId}`)
  }
  static findPatientFinanceByPatientId(patientId?: any): Promise<any> {
    return this.api.get(`${url}/patient-finance/${patientId}`)
  }
  static create(payload: PatientInterface): Promise<any> {
    return this.api.post(`${url}`, payload)
  }
  static update(patientId: number, payload: PatientInterface): Promise<any> {
    return this.api.patch(`${url}/${patientId}`, payload)
  }
  static formRegister(): Promise<any> {
    return this.api.get(`${url}/form-register`)
  }
  static updateFormRegister(payload: FormRegisterInterface): Promise<any> {
    return this.api.patch(`${url}/form-register`, payload)
  }
  static lineConnect(payload: LineNotify): Promise<any> {
    return this.api.post(`connect-line-notify`, payload)
  }
  static disconnectLineConnect(patientId: number): Promise<any> {
    return this.api.patch(`${url}/disconnect-line-notify/${patientId}`)
  }
  static validatePatientInfo(info: ValidatePatientInfoInterface): Promise<any> {
    return this.api.get(`${url}/validate-patient`, { params: { ...info } })
  }
}
