import { colors } from "constants/theme"

export default function SignOut(props: { color?: string }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.247" height="12.5" viewBox="0 0 16.247 12.5">
            <g id="bx-log-out-circle" transform="translate(.4 .25)">
                <path id="Path_5750" data-name="Path 5750" d="m3 14.894 3.618 2.894v-2.17h6.512v-1.447H6.618V12z" transform="translate(-3 -9.381)" style={{ fill: props.color || colors.themeSecondColor, stroke: props.color || colors.themeSecondColor, strokeWidth: .5 }} />
                <path id="Path_5751" data-name="Path 5751" d="M14.2 4.5a5.958 5.958 0 0 0-4.245 1.756l.945.944a4.667 4.667 0 1 1 0 6.6l-.943.943A6 6 0 1 0 14.2 4.5z" transform="translate(-4.6 -4.498)" style={{ fill: props.color || colors.themeSecondColor, stroke: props.color || colors.themeSecondColor, strokeWidth: .5 }} />
            </g>
        </svg>
    )
}
