import { useState, useEffect, useCallback } from 'react'
import { Grid, Box, Typography, MenuItem } from '@mui/material';
import { Col, Row } from 'react-bootstrap'
import moment from 'moment'
import _ from 'lodash'
import { fileUrl } from 'api/api'
/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import ClinicApi, { BranchInterface } from 'api/master/clinic.api'

import FilterSelect from 'component/Select/FilterSelect'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'

import UseWindowSize from 'utils/useWindowSize'

/** STYLE */
import { styled } from '@mui/material/styles'
import TreatmentHistoryApi from 'api/dentists/treatment-history.api'
import { dateToView, timeToView } from 'utils/date.utils'
import ButtonCustom from 'component/Button/ButtonCustom';
const CustomTable = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    '.MuiTableHead-root': {
      '.MuiTableCell-head': {
        '&:last-of-type': {
          padding: 15
        }
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        paddingTop: 15,
        paddingBottom: 15,
        '&:first-of-type': {
          paddingLeft: 4,
          paddingRight: 4,
        }
      }
    },
    [theme.breakpoints.down('lg')]: {
      minWidth: '100%',
      '.MuiTableBody-root': {
        '.MuiTableCell-root': {
          verticalAlign: 'top !important',
        }
      }
    }
  }
}))

type DocumentHistoryType = {
  patientId?: number
}

enum documentGroup {
  APPOINTMENT = 'APPOINTMENT',
  MEDICAL_CERTIFICATE = 'MEDICAL_CERTIFICATE',
  OPD = 'OPD',
  SSO = 'SSO',
  LAB_ORDER = 'LAB_ORDER',
  REFERRAL_EXAMINATION = 'REFERRAL_EXAMINATION',
  INVOICE = 'INVOICE'
}

export default function DocumentHistory(props: DocumentHistoryType) {
  const { t } = useTranslation()
  const [width] = UseWindowSize()
  const momentNowRange = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]

  /** DATA */
  const [branches, setBranches] = useState<BranchInterface[]>([]);
  const [historyDocuments, setHistoryDocuments] = useState([]);

  /** FILTER */
  const search = ''
  const [branchId, setBranchId] = useState<number | string>('ALL')
  const [document, setDocument] = useState<documentGroup | string>('ALL');
  const [rangeDate, setRangeDate] = useState<string[]>(momentNowRange)

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')

  const loadDefaultData = async () => {
    await ClinicApi.findAllBranches().then((resp) => { setBranches(resp.data) }).catch((e) => { return })
  }

  const loadData = useCallback(async () => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (search) condition = { ...condition, search: search }
    if (branchId) condition = { ...condition, branchId: branchId === 'ALL' ? '' : branchId }
    if (document) condition = { ...condition, docuemntKey: document === 'ALL' ? '' : document }
    if (rangeDate) {
      if (rangeDate[0] !== null && rangeDate[1] !== null)
        condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    }
    const resp = await TreatmentHistoryApi.findAllHistoryDocument(Number(props.patientId), condition)
    if (resp.status === 200) {
      setHistoryDocuments(resp.data)
      setRowCount(resp.headers['x-total'])
    }
  }, [page, pageLimit, sortBy, sortType, search, branchId, document, rangeDate])

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  useEffect(() => { loadData() }, [loadData]);
  useEffect(() => { loadDefaultData() }, [])
  useEffect(() => { setPageLimit(width >= 1024 ? 10 : 3) }, [width]);


  const exportPDF = (url: string) => {
    window.open(`${fileUrl}/${url}`, '_blank')
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: '#', width: '80px' },
    { id: 'message', disablePadding: false, label: t('HISTORY_TREATMENT.TABLE.BRANCH'), width: '200px' },
    { id: 'message', disablePadding: false, label: t('HISTORY_TREATMENT.TABLE.DATE_PRINT'), width: '150px' },
    { id: 'message', disablePadding: false, label: t('HISTORY_TREATMENT.TABLE.TIME_PRINT'), width: '150px' },
    { id: 'message', disablePadding: false, label: t('HISTORY_TREATMENT.TABLE.NAME_PRINT') },
    { id: 'message', disablePadding: false, label: t('HISTORY_TREATMENT.TABLE.NAME_DOCUMENT') },
    { id: 'message', disablePadding: false, label: t('HISTORY_TREATMENT.TABLE.ACTION'), align: 'center', width: '200px' },
  ]
  const headMobileCells = [
    { id: 'no', disablePadding: false, label: '#', width: '50px' },
    { id: 'message', disablePadding: false, label: '' },
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { branchId: branchIdValue, createdAt, createdBy, documentName, url } = objData
    const objRenderData = {
      key: no.toString(),
      id: no.toString(),
      obj: objData,
      columns: width >= 1200 ? [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: _.get(_.find(branches, { branchId: branchIdValue }), 'branchName' || 'branchNameEn') || '-', class: 'text-ellipsis' },
        { option: 'TEXT', align: 'left', label: createdAt ? dateToView(createdAt) : '-' },
        { option: 'TEXT', align: 'left', label: createdAt ? timeToView(createdAt) : '-' },
        { option: 'TEXT', align: 'left', label: createdBy || '-', class: 'text-ellipsis' },
        { option: 'TEXT', align: 'left', label: documentName || '-', class: 'text-ellipsis' },
        {
          option: 'TEXT', align: 'center', label: <Box className={`pl-1`}>
            <ButtonCustom onClick={() => {
              exportPDF(`${url || '-'}`)
            }}
              className={'btn-print'}
              variant={'outlined'}
              textButton={t('BUTTON.PRINT')}
              style={{ width: '120px' }} />
          </Box>, class: 'text-ellipsis'
        },
      ] : [
        { option: 'TEXT', align: 'center', label: no },
        {
          option: 'COMPONENT', align: 'left', component: (
            <Grid container spacing={1}>
              <Grid item xs={6} className='w-100'>
                <Box className='d-flex'>
                  <Typography sx={{ fontWeight: 500, width: '120px' }}>{t('HISTORY_TREATMENT.TABLE.BRANCH')}</Typography>
                  <Typography>{_.get(_.find(branches, { branchId: branchIdValue }), 'branchName' || 'branchNameEn') || '-'}</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} className='w-100'>
                <Box className='d-flex'>
                  <Typography sx={{ fontWeight: 500, width: '120px' }}>{t('HISTORY_TREATMENT.TABLE.DATE_PRINT')}</Typography>
                  <Typography>{createdAt ? dateToView(createdAt) : '-'}</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} className='w-100'>
                <Box className='d-flex'>
                  <Typography sx={{ fontWeight: 500, width: '120px' }}>{t('HISTORY_TREATMENT.TABLE.TIME_PRINT')}</Typography>
                  <Typography>{createdAt ? timeToView(createdAt) : '-'}</Typography>
                </Box>
              </Grid>
              <Grid item sm={6} className='w-100'>
                <Box className='d-flex'>
                  <Typography sx={{ fontWeight: 500, width: '120px' }}>{t('HISTORY_TREATMENT.TABLE.NAME_PRINT')}</Typography>
                  <Typography>{createdBy || '-'}</Typography>
                </Box>
              </Grid>
              <Grid item sm={6} className='w-100'>
                <Box className='d-flex'>
                  <Typography sx={{ fontWeight: 500, width: '120px' }}>{t('HISTORY_TREATMENT.TABLE.NAME_DOCUMENT')}</Typography>
                  <Typography>{documentName || '-'}</Typography>
                </Box>
              </Grid>
              <Grid item sm={6} className='w-100'>
                <Box className='d-flex'>
                  <ButtonCustom onClick={() => {
                    exportPDF(`${url || '-'}`)
                  }}
                    className={'btn-print'}
                    variant={'outlined'}
                    textButton={t('BUTTON.PRINT')}
                    style={{ width: '120px' }} />
                </Box>
              </Grid>

            </Grid>
          )
        }
      ]
    }

    return <TableRowCommon {...objRenderData} />
  }

  return (
    <Box>
      <Row className="mx-0">
        <Col xs={12} sm={6} lg={4} xl={3} className="px-1 pl-sm-2 pr-lg-2 mb-3">
          <FilterSelect
            onchange={(e) => {
              setBranchId(e.target.value)
              setPage(1)
            }}
            renderValue={() => `${t('HISTORY_TREATMENT.FILTER.BRANCH')}: ${branchId === 'ALL' ? t('HISTORY_TREATMENT.FILTER.ALL') : _.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
            label={''}
            selectId="select-branch"
            labelId="label-branch"
            value={branchId}
            options={[
              <MenuItem key="0" value={"ALL"}>
                {t('HISTORY_TREATMENT.FILTER.ALL')}
              </MenuItem>,
              _.map(branches, (b: BranchInterface, index: number) => {
                return (
                  <MenuItem key={b.branchId} value={b.branchId}>
                    {b.branchName || b.branchNameEn}
                  </MenuItem>
                )
              })
            ]}
            formControlStyle={{
              minWidth: '100% !important',
              maxWidth: '100% !important'
            }}
            classesOption="style-select-doctors"
          />
        </Col>
        <Col xs={12} sm={6} lg={4} xl={3} className="px-1 pr-sm-2 pl-lg-2 pr-lg-1 pr-xl-2 mb-3">
          <FilterSelect
            onchange={(e) => {
              setDocument(e.target.value)
              setPage(1)
            }}
            renderValue={() => `${t('HISTORY_TREATMENT.FILTER.DOCUMENT')}: ${document === 'ALL' ? t('HISTORY_TREATMENT.FILTER.ALL') : t(`HISTORY_TREATMENT.DOCUMENT_GROUP.${document}`)} `}
            label={''}
            selectId="select-branch"
            labelId="label-branch"
            value={document}
            options={[
              <MenuItem key="0" value={'ALL'}>
                {t('HISTORY_TREATMENT.FILTER.ALL')}
              </MenuItem>,
              _.map(documentGroup, (doc, index) => {
                return (
                  <MenuItem key={index} value={doc}>
                    {t(`HISTORY_TREATMENT.DOCUMENT_GROUP.${doc}`)}
                  </MenuItem>
                )
              })
            ]}
            formControlStyle={{
              minWidth: '100% !important',
              maxWidth: '100% !important'
            }}
            classesOption="style-select-doctors"
          />
        </Col>
        <Col xs={12} sm={6} lg={4} className="px-1 pl-sm-2 pl-lg-1 pr-lg-2 pl-xl-2 pr-xl-0 mb-3">
          <Box sx={{ maxWidth: { lg: '350px' } }}>
            <InputSecondNewRangePicker
              value={rangeDate || []}
              inputHeight={32}
              onchange={(val: any) => {
                if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                else {
                  setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                  setPage(1)
                }
              }}
              allowClear
              onClear={() => setRangeDate(emptyRangePicker)}
            />
          </Box>
        </Col>
      </Row>
      <CustomTable>
        <TableCustom
          tableFixedWidth
          page={page}
          pageLimit={pageLimit}
          sortType={sortType}
          sortBy={sortBy}
          rowCount={rowCount}
          onSort={onRequestSort}
          setPageLimit={handleChangeRowsPerPage}
          setPage={handleChangePage}
          headCells={width >= 1200 ? headCells : headMobileCells}
          rowsData={(
            _.map([...historyDocuments], (item, index: number) => {
              return renderData(item, index)
            })
          )}
        />
      </CustomTable>
    </Box>
  )
}
