
type IconProps = {
    style?: any
    type?: string
}

export default function TeethChild(props: IconProps) {
    const color = props.type === 'CHILD' ? '#1CB99A' : '#707070'
    return (
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="34" height="34" rx="3.5" fill={`${color}`} stroke={`${color}`} />
            <g clipPath="url(#clip0_3576_146657)">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.8332 11.5883C13.3524 11.2801 13.9672 11.125 14.6678 11.125C15.3591 11.125 15.9484 11.4742 16.355 11.715C16.6993 11.9205 17.0846 12.1875 17.5011 12.1875C17.9176 12.1875 18.3029 11.9198 18.6472 11.715C19.0545 11.4749 19.6431 11.125 20.3344 11.125C21.0357 11.125 21.6505 11.2801 22.169 11.5883C22.6875 11.8964 23.0707 12.3348 23.342 12.8406C23.8733 13.8294 23.9937 15.1016 23.9207 16.3398C23.8464 17.597 23.5666 18.9117 23.2117 20.055C22.8589 21.189 22.4155 22.2069 21.9863 22.8515C21.4933 23.5903 20.8083 23.9579 20.0823 23.8559C19.4157 23.7624 18.8979 23.2942 18.6387 22.775C18.3617 22.221 18.2335 21.6027 17.9906 21.0339C17.8701 20.7534 17.7547 20.555 17.647 20.4353C17.5337 20.3107 17.465 20.3142 17.3559 20.4353C17.2475 20.555 17.1321 20.7534 17.0116 21.0339C16.7687 21.6027 16.6412 22.221 16.3642 22.775C16.1043 23.2942 15.5865 23.7624 14.9206 23.8559C14.1939 23.9579 13.5089 23.5903 13.0159 22.8515C12.5867 22.2069 12.1433 21.189 11.7905 20.055C11.4356 18.9117 11.1559 17.597 11.0822 16.3405C11.0085 15.1009 11.1289 13.8294 11.6602 12.8406C11.9315 12.3348 12.3147 11.8964 12.8332 11.5883Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_3576_146657">
                    <rect width="17" height="17" fill="white" transform="translate(9 9)" />
                </clipPath>
            </defs>
        </svg>)
}
