import _ from 'lodash'

const months_th = ['', 'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม']
const months_en = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const dateOfWeekNoTh = ['', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์', 'อาทิตย์']
const dateOfWeekNoEn = ['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

const dateOfWeekTh = { SUN: `อาทิตย์`, MON: `จันทร์`, TUE: `อังคาร`, WED: `พุธ`, THU: `พฤหัสบดี`, FRI: `ศุกร์`, SAT: `เสาร์` }
const dateOfWeekEn = { SUN: `Sunday`, MON: `Monday`, TUE: `Tuesday`, WED: `Wednesday`, THU: `Thursday`, FRI: `Friday`, SAT: `Saturday` }

const getNameMonthByNo = (no: number, lang = 'th') => {
  return lang === 'th' ? months_th[no] : months_en[no]
}

const getNameDateOfWeekByNo = (no: number, lang = 'th') => {
  return lang === 'th' ? dateOfWeekNoTh[no] : dateOfWeekNoEn[no]
}
const getNameDateOfWeekByShort = (key: string, lang = 'th') => {
  return _.get(lang === 'th' ? dateOfWeekTh : dateOfWeekEn, key.toUpperCase()) || ''
}


export {
  //** VARIABLE */
  months_th,
  //** MATHOD */
  getNameMonthByNo,
  getNameDateOfWeekByNo,
  getNameDateOfWeekByShort
}
