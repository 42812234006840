import { useState, useEffect } from 'react'
import { Box, Grid, Typography, MenuItem } from '@mui/material'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API*/
import TreatmentApi, { FindDoctorFeeProps } from 'api/dentists/treatment.api'
import OperativeApi from 'api/setting/treatments/operative.api'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** UTILS || SLICE */
import { numberFormat, getBranch } from 'utils/app.utils'
import { treatment, setTreatmentByKey, TreatmentStateInterface } from 'app/slice/treatment.slice'
import * as modalSlice from 'app/slice/modal.slice'

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'
import InputTextField from 'component/Input/InputTextField'
import InputTextarea from 'component/Input/InputTextarea'
import BasicSelect from 'component/Select/BasicSelect'
import Loading from 'component/Loading'

/** STYLE */
import { styled } from '@mui/material/styles'
import 'component/Treatments/style/modal.css'
import { AnyAaaaRecord } from 'dns'

export enum CURRENCY_UNIT {
  BAHT = 'BAHT',
  PERCENT = 'PERCENT'
}

const Title = styled(Typography)(({ theme }) => ({
  color: colors.themeMainColor,
  [theme.breakpoints.up(375)]: {
    fontSize: 18
  }
}))

const CustomLoading = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: colors.backdrop,
  zIndex: 2016
}))

export interface IPopupManageTreatmentCourse {
  itemId?: number
  userId?: number
  operative: any
  onSubmit?: () => void
  onReset?: () => void
}

export interface ClinicFeeType {
  clinicFeeId: number
  clinicFeeText: string
  operativeClinicFeeId: number
  operativeId: number
  price: number
}

export interface IOperative {
  price: number
  qty: number
  discount: number
  discountUnit: CURRENCY_UNIT
  additional: number
  additionalUnit: CURRENCY_UNIT
  dfUnit: CURRENCY_UNIT
  dfAmount: number
  dfDiscountAmount: number
  dfDiscountUnit: CURRENCY_UNIT
  dfAmountNet: number
  cfAmount: number
  cfDiscountAmount: number
  cfDiscountUnit: CURRENCY_UNIT
  cfAmountNet: number
  dfRemark?: string
  total: number
  doctorFeeAmount?: number
  clinicFeeAmount?: number
  clinicFeeId?: number
  clinicFeeName?: string
  clinicFeeNameEn?: string
  clinicFeePrice?: number
}

export default function PopupManageTreatmentCourse(props: IPopupManageTreatmentCourse) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const modal: boolean = useSelector(modalSlice.handleShow)
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const [loading, setLoading] = useState(false)
  const [doctorFee, setDoctorFee]: any = useState({})
  const [operative, setOperative]: any = useState({})
  const units: CURRENCY_UNIT[] = [CURRENCY_UNIT.PERCENT, CURRENCY_UNIT.BAHT]

  const [normalPrice, setNormalPrice] = useState(0)

  const [discountAmount, setDiscountAmount] = useState(0)
  const [amount, setAmount] = useState(0)

  const [df, setDf] = useState(0)
  const [cf, setCf] = useState(0)
  const [dfDiscountAmount, setDfDiscountAmount] = useState(0)
  const [cfDiscountAmount, setCfDiscountAmount] = useState(0)

  const [clinicFees, setClinicFees] = useState<any>([])

  const [disabled, setDisabled] = useState(false)

  const [errorMessage, setErrorMessage] = useState({
    PRICE: '',
    QTY: ''
  })

  const [isFocus, setIsFocus] = useState<string>('')

  const loadDoctorFee = async (doctorId: number, operativeId: number) => {
    if (operativeId) {
      setLoading(true)
      const condition: FindDoctorFeeProps = {
        operativeId: operativeId,
        branchId: Number(getBranch()),
        doctorId: doctorId
      }
      const resp = await TreatmentApi.findDoctorFee(condition)
      if (resp.status === 200) setDoctorFee(resp.data)
    }
  }

  const loadOperativeById = async (operativeId: number) => {
    if (operativeId) {
      const resp: any = await OperativeApi.findById(operativeId).finally(() => setLoading(false))
      if (resp.status === 200) setClinicFees(resp.data?.operativeClinicFees)
    }
  }

  useEffect(() => {
    if (clinicFees[0]) {
      setOperative({
        ...operative,
        clinicFeeId: clinicFees[0]?.clinicFeeId,
        clinicFeeName: clinicFees[0]?.clinicFeeText,
        clinicFeePrice: clinicFees[0]?.price
      })
    }
  }, [clinicFees])

  const handleSubmit = () => {
    if ((!operative.price && operative.price !== 0) || !operative.qty) {
      return setErrorMessage({ PRICE: !operative.price ? t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.ERROR.EMPTY_PRICE') : '', QTY: !operative.qty ? t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.ERROR.EMPTY_QTY') : '' })
    }
    const operatives = _.merge([], treatments.summeryTreatments)

    if (props.itemId || props.itemId === 0) operatives[props.itemId] = operative
    dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: operatives }))
    dispatch(modalSlice.resetModal())
  }

  const handleChange = (e: any) => {
    const name = e.target.name
    const checkNaN = () => {
      if (!isNaN(Number(String(e.target.value).replace(/[^0-9.]/g, '')))) {
        if (name === 'qty') {
          return Number(String(e.target.value).replace(/[^0-9.]/g, ''))
        }
        return String(e.target.value).replace(/[^0-9.]/g, '')
      } else {
        return Number('')
      }
    }
    const value = name === 'price' || name === 'qty' || name === 'discount' || name === 'additional' || name === 'dfDiscountAmount' || name === 'cfDiscountAmount' ? checkNaN() : e.target.value

    setErrorMessage({ PRICE: '', QTY: '' })
    if (
      (name === 'discount' && operative.discountUnit === CURRENCY_UNIT.BAHT) ||
      (name === 'additional' && operative.additionalUnit === CURRENCY_UNIT.BAHT) ||
      (name === 'dfDiscountAmount' && operative.dfDiscountUnit === CURRENCY_UNIT.BAHT) ||
      (name === 'cfDiscountAmount' && operative.cfDiscountUnit === CURRENCY_UNIT.BAHT)
    ) {
      return handleChangeDiscountPriceCurrency(name, value)
    } else if (
      (name === 'discount' && operative.discountUnit === CURRENCY_UNIT.PERCENT) ||
      (name === 'additional' && operative.additionalUnit === CURRENCY_UNIT.PERCENT) ||
      (name === 'dfDiscountAmount' && operative.dfDiscountUnit === CURRENCY_UNIT.PERCENT) ||
      (name === 'cfDiscountAmount' && operative.cfDiscountUnit === CURRENCY_UNIT.PERCENT)
    ) {
      return handleChangeDiscountPercent(name, value)
    } else if ((name === 'discountUnit' || name === 'additionalUnit' || name === 'dfDiscountUnit' || name === 'cfDiscountUnit') && value === CURRENCY_UNIT.PERCENT) {
      return handleChangeDiscountUnitToPercent(name, value)
    } else setOperative({ ...operative, [name]: e.target.type === 'number' && value < 0 && value !== '' ? (name === 'qty' && 1) || 0 : value })
  }

  const handleChangeDiscountPriceCurrency = (name: 'discount' | 'additional' | 'dfDiscountAmount' | 'cfDiscountAmount', val: number | string) => {
    const preValue = String(val).replace(/[^0-9.]/g, '') || ''
    const checkNaN = () => {
      if (!isNaN(Number(preValue))) {
        return preValue
      } else {
        return Number('')
      }
    }
    const value = preValue !== '' ? checkNaN() : Number('')
    let obj = {}
    if (name === 'discount') {
      const additional = operative?.additional || 0
      const total = operative?.price * operative?.qty + additional || 0
      obj = { discount: value >= total ? total : value }
    } else if (name === 'dfDiscountAmount') {
      obj = { [name]: Number(value) >= df ? df : value }
    } else if (name === 'cfDiscountAmount') {
      obj = { [name]: Number(value) >= cf ? cf : value }
    } else obj = { [name]: value }

    setOperative({ ...operative, ...obj })
  }

  const handleChangeDiscountPercent = (name: 'discount' | 'additional' | 'dfDiscountAmount' | 'cfDiscountAmount', val: number | string) => {
    const preValue = String(val).replace(/[^0-9.]/g, '') || ''
    const value = preValue !== '' ? preValue : Number('')
    setOperative({ ...operative, [name]: Number(value) >= 100 ? 100 : value })
  }

  const handleChangeDiscountUnitToPercent = (name: 'discountUnit' | 'additionalUnit' | 'dfDiscountUnit' | 'cfDiscountUnit', value: CURRENCY_UNIT.PERCENT) => {
    let obj = {}
    if (name === 'discountUnit') obj = { [name]: value, discount: operative?.discount >= 100 ? 100 : operative?.discount }
    else if (name === 'dfDiscountUnit') obj = { [name]: value, dfDiscountAmount: operative?.dfDiscountAmount >= 100 ? 100 : operative?.dfDiscountAmount }
    else if (name === 'cfDiscountUnit') obj = { [name]: value, cfDiscountAmount: operative?.cfDiscountAmount >= 100 ? 100 : operative?.cfDiscountAmount }
    else obj = { [name]: value, additional: operative?.additional >= 100 ? 100 : operative?.additional }

    setOperative({ ...operative, ...obj })
  }

  useEffect(() => {
    setDisabled(true)
    const price = Number(operative.price)
    const qty = Number(operative.qty)
    const newNormalPrice = price * qty
    setNormalPrice(newNormalPrice)

    const rawDiscount = Number(operative.discount)
    const rawDiscountUnit = operative.discountUnit
    const rawAdditional = Number(operative.additional)
    const rawAdditionalUnit = operative.additionalUnit
    let rawDiscountAmount = 0
    let rawAdditionalAmount = 0
    if (rawDiscountUnit === CURRENCY_UNIT.PERCENT) rawDiscountAmount = (newNormalPrice * rawDiscount) / 100
    if (rawDiscountUnit === CURRENCY_UNIT.BAHT) rawDiscountAmount = rawDiscount
    if (rawAdditionalUnit === CURRENCY_UNIT.PERCENT) rawAdditionalAmount = (newNormalPrice * rawAdditional) / 100
    if (rawAdditionalUnit === CURRENCY_UNIT.BAHT) rawAdditionalAmount = rawAdditional

    const newAmount = newNormalPrice - rawDiscountAmount + rawAdditionalAmount
    setAmount(newAmount)
    setDiscountAmount(rawDiscountAmount)

    let newDF = 0
    let newCF = 0
    let total = newAmount
    let dfAmount = doctorFee.dfAmount;
    let cfAmount = doctorFee.cfAmount
    let doctorFeeAmount = doctorFee.dfAmount
    let clinicFeeAmount = doctorFee.clinicFeeAmount
    let dfUnit = doctorFee.dfUnit
    let dfAmounNet = doctorFee.dfAmounNet
    if (price === 0 && doctorFee.dfUnit !== CURRENCY_UNIT.PERCENT) {
      const sumDf = doctorFee.dfAmount
      newDF = sumDf
      newCF = 0
      setDf(sumDf * qty)
      setCf(0)

      dfAmounNet = sumDf * qty
      dfAmount = sumDf
      cfAmount = 0
      doctorFeeAmount = sumDf
      clinicFeeAmount = 0
      dfUnit = doctorFee.dfUnit
    } else if (doctorFee.dfUnit === CURRENCY_UNIT.PERCENT) {
      const sumDf = (newAmount / 100) * doctorFee.dfAmount
      const sumCf = (newAmount / 100) * doctorFee.cfAmount
      newDF = sumDf
      newCF = sumCf

      setDf(sumDf)
      setCf(sumCf)

      dfAmount = doctorFee.dfAmount
      cfAmount = doctorFee.cfAmount
      doctorFeeAmount = doctorFee.dfAmount
      clinicFeeAmount = doctorFee.cfAmount
      dfUnit = doctorFee.dfUnit
    } else {
      newDF = doctorFee.dfAmount * qty
      newCF = newAmount - (doctorFee.dfAmount * qty)

      setDf(newDF)
      setCf(newAmount - (doctorFee.dfAmount * qty))

      dfAmount = doctorFee.dfAmount
      cfAmount = doctorFee.cfAmount
      doctorFeeAmount = doctorFee.dfAmount
      clinicFeeAmount = doctorFee.cfAmount
      dfUnit = doctorFee.dfUnit
    }


    let rawDfDiscountAmount = 0
    let rawCfDiscountAmount = 0

    if (operative.dfDiscountUnit === CURRENCY_UNIT.PERCENT) {
      rawDfDiscountAmount = (Number(newDF) * Number(operative.dfDiscountAmount)) / 100
      setDfDiscountAmount(rawDfDiscountAmount)
    } else if (operative.dfDiscountUnit === CURRENCY_UNIT.BAHT) {
      rawDfDiscountAmount = Number(operative.dfDiscountAmount)
      setDfDiscountAmount(rawDfDiscountAmount)
    }

    if (operative.cfDiscountUnit === CURRENCY_UNIT.PERCENT) {
      rawCfDiscountAmount = (Number(newCF) * Number(operative.cfDiscountAmount)) / 100
      setCfDiscountAmount(rawCfDiscountAmount)
    } else if (operative.cfDiscountUnit === CURRENCY_UNIT.BAHT) {
      rawCfDiscountAmount = Number(operative.cfDiscountAmount)
      setCfDiscountAmount(Number(operative.cfDiscountAmount))
    }
    const dfAmountNet = newDF - rawDfDiscountAmount
    const cfAmountNet = newCF - rawCfDiscountAmount

    if (price === 0 && doctorFee.dfUnit !== CURRENCY_UNIT.PERCENT) {
      total = newAmount
    } else {
      total = newAmount - rawDfDiscountAmount - rawCfDiscountAmount
    }

    setOperative({
      ...operative, total: total >= 0 ? total : 0,
      dfAmountNet: dfAmountNet, cfAmountNet: cfAmountNet,
      dfAmount,
      cfAmount,
      doctorFeeAmount,
      clinicFeeAmount,
      dfUnit,
      dfAmounNet,
    })
    setDisabled(false)
  }, [operative.price, operative.qty, operative.discount, operative.discountUnit, operative.additional, operative.additionalUnit, doctorFee, operative.dfDiscountAmount, operative.dfDiscountUnit, operative.cfDiscountAmount, operative.cfDiscountUnit])

  // useEffect(() => {
  //   const rawDfAmount = Number(df)
  //   const rawDfDiscountAmount = Number(dfDiscountAmount)
  //   const rawCfAmount = Number(cf)
  //   const rawCfDiscountAmount = Number(cfDiscountAmount)
  //   const dfAmountNet = rawDfAmount - rawDfDiscountAmount
  //   const cfAmountNet = rawCfAmount - rawCfDiscountAmount
  //   setOperative({ ...operative, dfAmountNet: dfAmountNet, cfAmountNet: cfAmountNet })
  // }, [df, cf, dfDiscountAmount, cfDiscountAmount, amount])

  useEffect(() => {
    if (!operative.price || operative.price === 0) setOperative({ ...operative, discount: 0 })
  }, [operative.price])

  useEffect(() => {
    if (modal && props.userId) {
      setOperative(props.operative)
      loadDoctorFee(props.userId, props.operative?.operativeId)
      loadOperativeById(props.operative?.operativeId)
    } else props.onReset && props.onReset()
  }, [modal])

  const handleBlurInput = () => {
    if (operative[isFocus]) {
      const [num, numDot] = operative[isFocus].toString().split('.')
      if (numDot && numDot.length === 1) {
        operative[isFocus] = `${operative[isFocus]}0`
      }
    }
    setIsFocus('')
  }

  const handleFloatCommision = (input: any) => {
    if (input?.toString()?.length && input?.toString()[input?.toString()?.length - 1] === '.') {
      const newInput: number = input
      return newInput
    } else {
      return Number(input)
    }
  }

  return (
    (!loading && (
      <ModalCustom
        size={'xl'}
        title={t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.TITLE')}
        alignFooter={'end'}
        fullscreen={'xxl-down'}
        footerInline
        textBtnCancel={t('BUTTON.CANCEL')}
        textBtnConfirm={t('BUTTON.SAVE')}
        onReset={props.onReset}
        onSubmit={handleSubmit}
        modalStyle={`modal-treatment-course ${disabled ? 'disabled-modal-footer' : ''}`}
        component={
          <>
            <Box className="custom-scroll" sx={{ overflowY: 'auto' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} className={'w-100'}>
                  <Typography className="d-sm-inline-block" sx={{ fontWeight: 500 }}>
                    {t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.OPERATIVE_ID')}: {operative.operativeCode || '-'}
                  </Typography>
                  <Typography className="d-sm-inline-block pl-sm-5" sx={{ fontWeight: 500 }}>
                    {t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.OPERATIVE_NAME')}: {operative.operativeName || operative.operativeNameEn || '-'}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={'w-100'}>
                  <InputTextField
                    name="price"
                    onchange={handleChange}
                    value={operative.price}
                    onfocus={() => setIsFocus('price')}
                    onblur={() => handleBlurInput()}
                    label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.PRICE_OF_UNIT')} (${t('CURRENCY_CHANGE.TH')})`}
                    inputTextAlign="left"
                    hideControl
                    helperText={errorMessage.PRICE || ''}
                  />
                </Grid>
                <Grid item xs={6} className={'w-100'}>
                  <InputTextField name="qty" onchange={handleChange} value={operative.qty} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.QUANTITY_OF_UNIT')}`} inputTextAlign="left" hideControl helperText={errorMessage.QTY || ''} />
                </Grid>
                <Grid item xs={12} className={'w-100'}>
                  <InputTextField disabled name="treatment" value={numberFormat(normalPrice || 0)} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.PRICE_OF_TREATMENT')} (${t('CURRENCY_CHANGE.TH')})`} inputTextAlign="left" hideControl />
                </Grid>
                <Grid item xs={12} className={'w-100'}>
                  <Typography className="mt-2" sx={{ fontWeight: 500 }}>
                    {t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.DISCOUNT_ADDON')}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={'w-100'}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ width: '50%' }}>
                      <InputTextField name="discount" onchange={handleChange} value={operative.discount} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.DISCOUNT')}`} inputTextAlign="left" hideControl />
                    </Grid>
                    <Grid item xs={6} sx={{ width: '50%' }}>
                      <BasicSelect
                        name="discountUnit"
                        labelId="lb-unit"
                        selectId="sl-unit"
                        label=""
                        value={operative.discountUnit || ''}
                        options={_.map(units, (unit: string, index: number) => {
                          return (
                            <MenuItem key={index} value={unit}>
                              {unit === CURRENCY_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                            </MenuItem>
                          )
                        })}
                        onchange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} className={'w-100'}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ width: '50%' }}>
                      <InputTextField name="additional" onchange={handleChange} value={operative.additional} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.ADDON')}`} inputTextAlign="left" hideControl />
                    </Grid>
                    <Grid item xs={6} sx={{ width: '50%' }}>
                      <BasicSelect
                        name="additionalUnit"
                        labelId="lb-unit"
                        selectId="sl-unit"
                        label=""
                        value={operative.additionalUnit || ''}
                        options={_.map(units, (unit: string, index: number) => {
                          return (
                            <MenuItem key={index} value={unit}>
                              {unit === CURRENCY_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                            </MenuItem>
                          )
                        })}
                        onchange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} className={'w-100'}>
                  <InputTextField disabled name="total" value={numberFormat(amount || 0)} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.TOTAL')}`} inputTextAlign="left" hideControl />
                </Grid>

                <Grid item xs={12} className={'w-100 mb-2'}>
                  <Typography className="mt-2" sx={{ fontWeight: 500 }}>
                    {t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.SALARY')}
                  </Typography>
                </Grid>

                <Grid item xs={6} className={'w-100 pt-2 pr-sm-2 pr-xl-3'} sx={{ borderRight: { xs: `1px solid ${colors.lightGray}` } }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} className="w-100">
                      <InputTextField
                        disabled
                        name="df"
                        value={numberFormat(df || 0)}
                        label={`${t(`DF ${operative.dfAmount} ${operative.dfUnit === 'PERCENT' ? `%` : `${t('CURRENCY_CHANGE.TH')}`}`)}`}
                        inputTextAlign="left"
                        hideControl
                      />
                    </Grid>
                    <Grid item lg={4} sx={{ width: '50%' }}>
                      <InputTextField name="dfDiscountAmount" onchange={handleChange} value={operative.dfDiscountAmount} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.DISCOUNT')}`} inputTextAlign="left" hideControl />
                    </Grid>
                    <Grid item lg={4} sx={{ width: '50%' }}>
                      <BasicSelect
                        name="dfDiscountUnit"
                        labelId="lb-unit"
                        selectId="sl-unit"
                        label=""
                        value={operative.dfDiscountUnit || ''}
                        options={_.map(units, (unit: string, index: number) => {
                          return (
                            <MenuItem key={index} value={unit}>
                              {unit === CURRENCY_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                            </MenuItem>
                          )
                        })}
                        onchange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={4} className="w-100">
                      <InputTextField disabled name="df-discount" value={numberFormat(dfDiscountAmount || 0)} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.DISCOUNT')} (${t('CURRENCY_CHANGE.TH')})`} inputTextAlign="left" hideControl />
                    </Grid>
                    <Grid item xs={12} className="w-100">
                      <InputTextField disabled name="df-balance" value={numberFormat(operative.dfAmountNet || 0)} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.BALANCE')}`} inputTextAlign="left" hideControl />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6} className={'w-100 pt-2 pl-sm-2 pl-xl-3'}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} className="w-100">
                      <InputTextField
                        disabled
                        name="cfAmount"
                        value={numberFormat(cf || 0)}
                        label={`${t(`CF ${operative.cfAmount} ${operative.dfUnit === 'PERCENT' ? `% ` : `${t('CURRENCY_CHANGE.TH')}`}`)}`}
                        inputTextAlign="left"
                        hideControl
                      />
                    </Grid>
                    <Grid item lg={4} sx={{ width: '50%' }}>
                      <InputTextField name="cfDiscountAmount" onchange={handleChange} value={operative.cfDiscountAmount} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.DISCOUNT')}`} inputTextAlign="left" hideControl />
                    </Grid>
                    <Grid item lg={4} sx={{ width: '50%' }}>
                      <BasicSelect
                        name="cfDiscountUnit"
                        labelId="lb-unit"
                        selectId="sl-unit"
                        label=""
                        value={operative.cfDiscountUnit || ''}
                        options={_.map(units, (unit: string, index: number) => {
                          return (
                            <MenuItem key={index} value={unit}>
                              {unit === CURRENCY_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                            </MenuItem>
                          )
                        })}
                        onchange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={4} className="w-100">
                      <InputTextField disabled name="cf-discount" value={numberFormat(cfDiscountAmount || 0)} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.DISCOUNT')} (${t('CURRENCY_CHANGE.TH')})`} inputTextAlign="left" hideControl />
                    </Grid>
                    <Grid item xs={12} className="w-100">
                      <InputTextField disabled name="cf-balance" value={numberFormat(operative.cfAmountNet || 0)} label={`${t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.BALANCE')}`} inputTextAlign="left" hideControl />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} className={'w-100'}>
                  <Typography className="mt-2" sx={{ fontWeight: 500 }}>
                    {t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.CLINIC_FEE')}
                  </Typography>
                </Grid>
                <Grid item xs={12} className="w-100">
                  <BasicSelect
                    name="clinicFeeId"
                    labelId="lb-clinic-fee"
                    selectId="sl-clinic-fee"
                    label={t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.CLINIC_FEE')}
                    value={operative.clinicFeeId || ''}
                    options={_.map(clinicFees, (clinic: ClinicFeeType, index: number) => {
                      return (
                        <MenuItem key={index} value={clinic.clinicFeeId}>
                          {clinic.clinicFeeText} ({clinic.price} {t('CURRENCY_CHANGE.TH')})
                        </MenuItem>
                      )
                    })}
                    onchange={(e: any) =>
                      setOperative({
                        ...operative,
                        clinicFeeId: e.target.value,
                        clinicFeeName: _.get(_.find(clinicFees, { clinicFeeId: e.target.value }), 'clinicFeeText'),
                        clinicFeePrice: _.get(_.find(clinicFees, { clinicFeeId: e.target.value }), 'price')
                      })
                    }
                    allowClear
                    onClear={() => setOperative(_.omit(operative, ['clinicFeeId', 'clinicFeeName', 'clinicFeeNameEn']))}
                  />
                </Grid>
                <Grid item xs={12} className="w-100">
                  <InputTextarea name="dfRemark" label={t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.REMARK')} value={operative.dfRemark} onchange={handleChange} height={94} />
                </Grid>
              </Grid>
            </Box>

            <Box className="py-3">
              <Grid container spacing={1}>
                <Grid item xs={4} sm={5} className="pt-0 d-none d-sm-block"></Grid>
                <Grid item xs={4} sm={4} className="pt-0" sx={{ width: '50%' }}>
                  <Title className="d-inline-block">
                    {t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.TOTAL_DISCOUNT')} ({t('CURRENCY_CHANGE.TH')}):
                  </Title>
                </Grid>
                <Grid item xs={4} sm={3} className="pt-0 text-right" sx={{ width: '50%' }}>
                  <Title className="d-inline-block">{numberFormat(discountAmount + dfDiscountAmount + cfDiscountAmount || 0)}</Title>
                </Grid>
                <Grid item xs={4} sm={5} className="pt-0 d-none d-sm-block"></Grid>
                <Grid item xs={4} sm={4} className="pt-0" sx={{ width: '50%' }}>
                  <Title className="d-inline-block">
                    {t('TREATMENT_SUMMARY.POPUP.TREATMENT_COURSE.TOTAL_NET')} ({t('CURRENCY_CHANGE.TH')}):
                  </Title>
                </Grid>
                <Grid item xs={4} sm={3} className="pt-0 text-right" sx={{ width: '50%' }}>
                  <Title className="d-inline-block">{numberFormat(operative.total)}</Title>
                </Grid>
              </Grid>
            </Box>
          </>
        }
      />
    )) || (
      <CustomLoading className="d-flex align-items-center justify-content-center">
        <Loading show={true} type="fullLoading" />
      </CustomLoading>
    )
  )
}
