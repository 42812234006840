import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart, TreatmentStateInterface } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/27.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethU, TextNumberU } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
  height: 'auto',
  marginTop: 'auto',
  top: '1.4%',
  left: '45.2%'
}))

export default function Teeth28(props: TeethProps) {
  const teeth = useSelector(toothChart)
  const teethNumber = props.teethNumber
  const historyToothChart = props.historyToothChart
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const isEditTootCharts: any = useSelector(isEditTootChart)

  const dispatch = useDispatch()

  const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
  const [positions, setPositions]: any = useState([])
  const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

  const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
  const [isTooth, setIsTooth]: any = useState(false)
  const [isXRay, setIsXRay]: any = useState(false)
  const [isToothRoot, setIsToothRoot]: any = useState(false)
  const [isOrthodontics, setIsOrthodontics]: any = useState(false)
  const [isExtract, setIsExtract]: any = useState(false)
  const [isFixture, setIsFixture]: any = useState(false)
  const [isBridge, setIsBridge]: any = useState(false)

  const [color, setColor]: any = useState(``)
  const [styleSvg, setStyleSvg]: any = useState({ width: 38.262, height: 93.001, viewBox: "0 0 38.262 93.001" })

  const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

  useEffect(() => {
    const operative = props.operative || ``
    const valColor = props.color || colors.themeSecondColor
    const selected = teeth[teethNumber]?.isSelect || false
    dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
    setIsSelected(selected)

    renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

    if (props.type === 'treatment') {
      renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
    }

    if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

    return setStyleSvg({ width: 37.81, height: 79.463, viewBox: "0 0 37.81 79.463" })
  }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

  useEffect(() => {
    const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
    const teethJson = treatmentTooth?.teethJson

    if (isEditTootCharts !== null) {
      if (teethJson) {
        const tooths = JSON.parse(teethJson)
        setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
        if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
      } else setIsEditTeeth(false)
    }
  }, [isEditTootCharts]);

  useEffect(() => {
    if (isSelected) {
      dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
      if (teeth[props.teethNumber]?.isSelect) {
        setIsSelected(true)
      } else {
        setIsSelected(false)
      }
    }
  }, [props.historyToothChart]);

  useEffect(() => {
    if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
  }, [isSelected, isEditTootCharts]);

  return (
    <Div>
      <DivTeethU className={`div-teeth-${props.teethNumber} `}>
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray />) || ''}
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge />) || ''}
        {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`lg teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
          <svg className={`lg ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
            <g data-name="Group 9155">
              {(isBridge && props.type === 'treatment') ?
                <g></g>
                : ""}
              <path
                data-name="Path 5251"
                d="M56.663 108.271c-.761 5.253-4.834 20.605-4.834 20.605l.541 5.536a17.8 17.8 0 0 0 5.053 6.174c3.964 2.958 7.044-.329 10.725-1.579 4.357 4.769 7.431 2.191 9.842-2.05 8.6-.271 13.206-9.487 8.482-18.665-3.641-7.09-3.023-14.2-2.636-21.353.535-9.648-1.1-19.007-2.43-28.436a3.874 3.874 0 0 0-.5-1.269.322.322 0 0 0-.592.07c-.545 1.863-2.84 9.1-4.162 9.365-1.007.2-1.087-10.191-2.336-10.191-1.571 0-6.076 23.4-5.2-1.988.023-.647-.365-.84-.882-.451-2.293 1.729-2.778 4.531-3.505 7.023a268.633 268.633 0 0 0-7.566 37.209z"
                transform="translate(-51.48 -63.84)"
                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
              />

              {(isLowerTeeth && props.type === 'treatment') ?
                <path
                  data-name="Path 6525"
                  d="M56.663 108.271c-.2 1.4-.787 4.106-1.385 6.746.609-.859.7-1.594 6.219-3.109 4.641-.891 3.406-.484 6.656-.719a51.017 51.017 0 0 1 8.7.953 15.761 15.761 0 0 1 5.109 1.5 16.871 16.871 0 0 1 3.453 2.266c-2.406-5.953-1.927-12.607-1.583-18.967.535-9.648-1.1-19.007-2.43-28.436a3.874 3.874 0 0 0-.5-1.269.322.322 0 0 0-.592.07c-.545 1.863-2.84 9.1-4.162 9.365-1.007.2-1.087-10.191-2.336-10.191-1.571 0-6.076 23.4-5.2-1.988.023-.647-.365-.84-.882-.451-2.293 1.729-2.778 4.531-3.505 7.023a268.633 268.633 0 0 0-7.562 37.207z"
                  transform="translate(-51.48 -63.84)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                /> : ''}

              <g data-name="Group 9729">
                <path
                  data-name="Path 5252"
                  d="M52.451 164.887c3.3 2.662 5.387 3.948 8.218 3.73a14.464 14.464 0 0 1 4.361.342c3.883.9 7.39.517 11.98-1.189a12.786 12.786 0 0 1 3.021-.748c8.05-.9 10.321-12.311 6.06-21.467q-.492-1.06-.866-2.113c-.9-2.516-6-4.416-6-4.416s-11.19-3.01-18.712-1-5.759 2.516-7.315 6.33c-4.534 11.103-2.648 19.005-.747 20.531z"
                  transform="translate(-50.392 -89.966)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                />
                <path
                  data-name="Path 5253"
                  d="M56.274 167.162c2.887 2.324 4.718 3.447 7.2 3.257a12.709 12.709 0 0 1 3.82.3c3.4.784 6.473.452 10.493-1.036a11.245 11.245 0 0 1 2.646-.652c7.05-.786 9.039-10.743 5.3-18.737q-.431-.924-.759-1.843c-.788-2.2-5.26-3.856-5.26-3.856s-9.8-2.629-16.39-.876-5.045 2.2-6.406 5.524c-3.963 9.69-2.309 16.587-.644 17.919z"
                  transform="translate(-52.406 -92.021)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                />
                <path
                  data-name="Path 5254"
                  d="M63.009 168.151c2.4 1.966 3.921 2.916 5.982 2.753a10.384 10.384 0 0 1 3.176.25 14.054 14.054 0 0 0 8.725-.887 9.2 9.2 0 0 1 2.2-.554c5.863-.672 7.525-9.111 4.426-15.881q-.358-.783-.63-1.562c-.654-1.86-4.37-3.262-4.37-3.262s-8.149-2.219-13.628-.728-4.2 1.865-5.331 4.686c-3.303 8.214-1.934 14.058-.55 15.185z"
                  transform="translate(-58.41 -93.675)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                />

                {(isToothRoot && props.type === 'treatment') ?
                  <>
                    <path
                      data-name="Path 6548"
                      d="M9.162 49.189c.069-3.06.521-5.328.838-11.19.848-12.362.706-28.9.706-31.306.019-.351-.4 37.774-2.528 42.309a4.424 4.424 0 0 1 .984.187z"
                      transform="translate(18.435 -.962)"
                      style={{

                        fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                        stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                      }}
                    />
                    <path
                      data-name="Path 6549"
                      d="M4.761 48.48a87.319 87.319 0 0 1 .583-12.38C7.308 23.649 10 5.816 10 4.1c-.019-.351-5.681 39.647-3.827 44.076a4.521 4.521 0 0 0-1.412.304z"
                      transform="translate(5.664 -.962)"
                      style={{

                        fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                        stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                      }}
                    />
                    <path
                      data-name="Path 6550"
                      d="M7.506 48.069c.069-3.06-.143-10.985 0-16.873C8.354 18.834 9.912 9.4 9.912 6.987c.011-.194-1.094 11.475-2.406 22.527 0 .008-.578 16.393-1.531 18.422.395.027.87.133 1.531.133z"
                      transform="translate(12.261 -.962)"
                      style={{

                        fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                        stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                      }}
                    /></>
                  : ''}

                {(isExtract && props.type === 'treatment') ?
                  <>
                    <g data-name="Group 9464">
                      <path data-name="Path 6580" d="m3.246-.5 36.254 80" transform="translate(-1.26 7.633)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                      <path data-name="Path 6581" d="m39.5-.5-36.254 80" transform="translate(-1.26 7.633)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                    </g>
                  </>
                  : ''}

                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                  <>
                    <defs>
                      <linearGradient id="wofyitbwqa" x1=".5" y1=".453" x2="-1.326" y2="4.622" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#b0b1b1" />
                        <stop offset={1} stopColor="#585959" />
                      </linearGradient>
                      <linearGradient id="7mjk78j11b" x1=".5" y1="1.834" x2=".5" y2=".046" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#c1c4c5" />
                        <stop offset={1} stopColor="#9e9e9e" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9342">
                      <path data-name="Path 5251" d="M56.663 108.271c-.761 5.253-4.834 20.605-4.834 20.605l.541 5.536a17.8 17.8 0 0 0 5.053 6.174c3.964 2.958 7.044-.329 10.725-1.579 4.357 4.769 7.431 2.191 9.842-2.05 8.6-.271 13.206-9.487 8.482-18.665-3.641-7.09-3.023-14.2-2.636-21.353.535-9.648-1.1-19.007-2.43-28.436a3.874 3.874 0 0 0-.5-1.269.322.322 0 0 0-.592.07c-.545 1.863-2.84 9.1-4.162 9.365-1.007.2-1.087-10.191-2.336-10.191-1.571 0-6.076 23.4-5.2-1.988.023-.647-.365-.84-.882-.451-2.293 1.729-2.778 4.531-3.505 7.023a268.633 268.633 0 0 0-7.566 37.209z" transform="translate(-51.059 -63.84)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                      <path data-name="Path 5252" d="M52.451 164.887c3.3 2.662 5.387 3.948 8.218 3.73a14.464 14.464 0 0 1 4.361.342c3.883.9 7.39.517 11.98-1.189a12.786 12.786 0 0 1 3.021-.748c8.05-.9 10.321-12.311 6.06-21.467q-.492-1.06-.866-2.113c-.9-2.516-6-4.416-6-4.416s-11.19-3.01-18.712-1-5.759 2.516-7.315 6.33c-4.534 11.103-2.648 19.005-.747 20.531z" transform="translate(-49.971 -89.966)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5253" d="M56.274 167.162c2.887 2.324 4.718 3.447 7.2 3.257a12.709 12.709 0 0 1 3.82.3c3.4.784 6.473.452 10.493-1.036a11.245 11.245 0 0 1 2.646-.652c7.05-.786 9.039-10.743 5.3-18.737q-.431-.924-.759-1.843c-.788-2.2-5.26-3.856-5.26-3.856s-9.8-2.629-16.39-.876-5.045 2.2-6.406 5.524c-3.963 9.69-2.309 16.587-.644 17.919z" transform="translate(-51.985 -92.021)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5254" d="M63.009 168.151c2.4 1.966 3.921 2.916 5.982 2.753a10.384 10.384 0 0 1 3.176.25 14.054 14.054 0 0 0 8.725-.887 9.2 9.2 0 0 1 2.2-.554c5.863-.672 7.525-9.111 4.426-15.881q-.358-.783-.63-1.562c-.654-1.86-4.37-3.262-4.37-3.262s-8.149-2.219-13.628-.728-4.2 1.865-5.331 4.686c-3.303 8.214-1.934 14.058-.55 15.185z" transform="translate(-57.989 -93.675)" style={{ fill: colors.white }} />
                      <g data-name="Group 9392" transform="translate(.422 57.91)">
                        <g data-name="Rectangle 3927" transform="translate(13.517)" style={{ fill: '#c1c4c5', stroke: '#c1c4c5' }}>
                          <rect width="11.601" height="11.601" rx={2} style={{ stroke: 'none' }} />
                          <rect x=".5" y=".5" width="10.601" height="10.601" rx="1.5" style={{ fill: 'none' }} />
                        </g>
                        <path data-name="Path 6487" d="M11.579 17.547c-.2.362 1.676.183 3.083.165 2.094-.044 3.289.384 3.269-4.45a41.118 41.118 0 0 0-.159-5.141c-.531-.516.14 5.236-1.219 7.766-1.282 1.827-4.631 1.272-4.974 1.66z" transform="translate(6.863 -6.465)" style={{ fill: '#b0b1b1' }} />
                        <rect data-name="Rectangle 3931" width={5} height={2} rx=".5" transform="translate(16.806 6.215)" style={{ fill: '#cbcccc' }} />
                        <rect data-name="Rectangle 3930" width={5} height={2} rx=".5" transform="translate(16.606 2.015)" style={{ fill: '#9e9e9e' }} />
                        <rect data-name="Rectangle 3928" width={5} height={2} rx=".5" transform="translate(16.806 2.015)" style={{ fill: 'url(#wofyitbwqa)' }} />
                        <rect data-name="Rectangle 3929" width={5} height={2} rx=".5" transform="translate(16.806 6.015)" style={{ fill: 'url(#7mjk78j11b)' }} />
                        <path data-name="Path 6488" d="M.158 11.874a51.718 51.718 0 0 1 37.567-.188" transform="translate(.085 -3.765)" style={{ strokeLinecap: 'round', stroke: '#babbbb', fill: 'none' }} />
                        <path data-name="Path 6486" d="M.391 11.9a51.122 51.122 0 0 1 37.422-.234" transform="translate(-.101 -3.084)" style={{ stroke: '#b5b7b7', strokeLinecap: 'round', fill: 'none' }} />
                      </g>
                    </g>
                  </> || ""}

                {(isFixture && props.type === 'treatment') ?
                  <>
                    <defs>
                      <linearGradient id="3mm03ht8la" x1=".209" y1="-.059" x2="1.176" y2="1.286" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b4b5b5" />
                      </linearGradient>
                      <linearGradient id="rm2ks38vzb" x1="-.342" y1="-.081" x2="1.352" y2={1} gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b0b1b1" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9483">
                      <path data-name="Path 5252" d="M52.451 164.887c3.3 2.662 5.387 3.948 8.218 3.73a14.464 14.464 0 0 1 4.361.342c3.883.9 7.39.517 11.98-1.189a12.786 12.786 0 0 1 3.021-.748c8.05-.9 10.321-12.311 6.06-21.467q-.492-1.06-.866-2.113c-.9-2.516-6-4.416-6-4.416s-11.19-3.01-18.712-1-5.759 2.516-7.315 6.33c-4.534 11.103-2.648 19.005-.747 20.531z" transform="translate(-50.392 -93.099)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5253" d="M56.274 167.162c2.887 2.324 4.718 3.447 7.2 3.257a12.709 12.709 0 0 1 3.82.3c3.4.784 6.473.452 10.493-1.036a11.245 11.245 0 0 1 2.646-.652c7.05-.786 9.039-10.743 5.3-18.737q-.431-.924-.759-1.843c-.788-2.2-5.26-3.856-5.26-3.856s-9.8-2.629-16.39-.876-5.045 2.2-6.406 5.524c-3.963 9.69-2.309 16.587-.644 17.919z" transform="translate(-52.406 -95.154)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5254" d="M63.009 168.151c2.4 1.966 3.921 2.916 5.982 2.753a10.384 10.384 0 0 1 3.176.25 14.054 14.054 0 0 0 8.725-.887 9.2 9.2 0 0 1 2.2-.554c5.863-.672 7.525-9.111 4.426-15.881q-.358-.783-.63-1.562c-.654-1.86-4.37-3.262-4.37-3.262s-8.149-2.219-13.628-.728-4.2 1.865-5.331 4.686c-3.303 8.214-1.934 14.058-.55 15.185z" transform="translate(-58.41 -96.808)" style={{ fill: colors.white }} />
                      <g data-name="Group 9553">
                        <g data-name="Path 6490" transform="translate(9.672)" style={{ fill: 'url(#3mm03ht8la)' }}>
                          <path d="M17.343 44.996H1.734c-.422 0-.825-.367-1.135-1.033-.322-.69-.499-1.61-.499-2.59C.845 24.493 1.818 12.58 2.991 5.97 3.682 2.075 4.427.1 5.203.1h8.671c.852 0 5.103 1.99 5.103 41.273 0 .98-.177 1.9-.499 2.59-.31.666-.713 1.033-1.135 1.033z" style={{ stroke: 'none' }} />
                          <path d="M17.343 44.896c.74 0 1.534-1.416 1.534-3.523 0-16.645-.753-28.547-2.238-35.377-.504-2.319-1.081-3.983-1.715-4.946-.486-.74-.867-.85-1.05-.85H5.203c-.406 0-1.22.752-2.114 5.788C1.917 12.593.945 24.498.2 41.378c.001 2.104.794 3.518 1.534 3.518h15.609m0 .2H1.734C.776 45.096 0 43.429 0 41.373 1.734 2.085 4.245 0 5.203 0h8.671c.958 0 5.203 2.085 5.203 41.373 0 2.056-.777 3.723-1.734 3.723z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <g data-name="Path 6489" transform="translate(1.001 42.63)" style={{ strokeLinejoin: 'round', fill: 'url(#rm2ks38vzb)' }}>
                          <path d="M34.685 7.569H1.735c-.898 0-1.628-.807-1.635-1.8C.926 2.761 1.14 1.32 1.243.626 1.321.1 1.33.1 1.413.1c.027 0 .061.002.1.005.06.004.132.008.221.008h33.058l.083-.001c.355 0 .362 0 .626 1.491.152.859.381 2.155.818 4.163-.005.995-.736 1.803-1.634 1.803z" style={{ stroke: 'none' }} />
                          <path d="M34.685 7.469c.84 0 1.524-.758 1.534-1.693a98.161 98.161 0 0 1-.817-4.155c-.127-.72-.197-1.115-.28-1.3-.042-.095-.048-.11-.247-.11l-.19.002H1.735c-.093 0-.168-.005-.228-.008C1.473.202 1.443.2 1.42.2c-.023.073-.049.246-.078.442-.103.694-.317 2.136-1.142 5.14.013.932.696 1.687 1.534 1.687h32.951m0 .2H1.735C.775 7.669 0 6.812 0 5.755 1.734-.555.776.013 1.734.013h32.951c.958 0 .43-.24 1.734 5.742 0 1.057-.776 1.914-1.734 1.914z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <path data-name="Path 6491" d="M5.625 21.2s18.057.9 18.962 1.957" transform="translate(4.162 15.597)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6492" d="M5.649 21.2S23.1 22.161 24 23.213" transform="translate(4.448 10.345)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6493" d="M7.08 21.281s14.6.592 15.5 1.645" transform="translate(4.896 -11.973)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6584" d="M5.649 21.2S23.1 22.161 24 23.213" transform="translate(4.704 5.802)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6585" d="M6.115 21.2S23.1 22.185 24 23.237" transform="translate(4.704 -.222)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6586" d="M6.643 21.3s16.036.854 16.941 1.907" transform="translate(4.704 -6.281)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6587" d="M8.144 21.444s12.509.373 13.415 1.426" transform="translate(4.896 -17.717)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      </g>
                    </g>
                  </> : ""}
              </g>
            </g>
          </svg>}
      </DivTeethU>
      <PositionTeeth quarter={QUARTER_NAME_TYPE.Q2} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
      <TextNumberU>28</TextNumberU>
    </Div>
  )
}
