import { useState, useEffect } from 'react'
import { Grid, Typography, Box, FormControl, RadioGroup, FormControlLabel, MenuItem } from '@mui/material'
import 'react-multi-carousel/lib/styles.css'
import _ from 'lodash'
import moment from 'moment'
import { useDispatch } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import { fileUrl } from 'api/api'
import TreatmentHistoryApi from 'api/dentists/treatment-history.api'
import ClinicApi from 'api/master/clinic.api'

/** UTILS || SLICE */
import { closeModal, resetModal } from 'app/slice/modal.slice'

/** COMPONENT */
import BasicSelect from 'component/Select/BasicSelect'
import InputCheckbox from 'component/Input/InputCheckbox'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import Loading from 'component/Loading'
import InputTextarea from 'component/Input/InputTextarea'
import InputRadio from 'component/Input/InputRadio'

type ModalCustomProps = {
    isShow: boolean | false
    setIsShow: (show: boolean) => void
    onClose?: () => void
    patientId: number
    branchId: number
}

type Doctors = {
    userId: number
    firstname: string
    lastname: string
    fullname: string
}

export default function ReferralDental(props: ModalCustomProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [doctors, setDoctors] = useState<Doctors[]>([])
    const [errorDoctorSelect, setErrorDoctorSelect] = useState('')

    const [loading, setLoading] = useState(false)

    /** FILTER */
    const [doctor, setDoctor] = useState<number>(0)
    const [date, setDate] = useState<string>(moment().format('YYYY-MM-DD'))
    const [disableDoctor, setDisableDoctor] = useState(false)
    const [xray, setXray] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
    const [opg, setOpg] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
    const [latCaph, setLatCaph] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
    const [other, setOther] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
    const [continueTreatment, setContinueTreatment] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
    const [consult, setConsult] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
    const [detailOther, setDetailOther] = useState('')
    const [dentalHistory, setDentalHistory] = useState('')

    const loadDoctor = async () => {
        const resp = await ClinicApi.findAllDoctors()
        if (resp.status === 200) setDoctors(resp.data)
    }

    useEffect(() => {
        loadDoctor()
    }, [])

    useEffect(() => {
        if (!xray) {
            setOpg('UNCHECK')
            setLatCaph('UNCHECK')
            setOther('UNCHECK')
            setDetailOther('')
        }
    }, [xray])

    const onPrint = async () => {
        if (!doctor && !disableDoctor) {
            setLoading(false)
            return setErrorDoctorSelect(t('MODAL_MED_CERTIFICATE.NOT_SELECT_DOCTOR'))
        }
        setLoading(true)
        const print = await TreatmentHistoryApi.printReferralExamination(props.patientId, {

            date,
            branchId: props.branchId,
            doctorId: doctor,
            xray: xray,
            opg: opg,
            latCaph: latCaph,
            other: other,
            detailOther: detailOther,
            continueTreatment: continueTreatment,
            consult: consult,
            dentalHistory: dentalHistory
        }).finally(() => setLoading(false))

        if (print.status === 200) {
            //dark
            window.open(`${fileUrl}/${print.data}`, '_blank')
            clearData()
            props.setIsShow(false)
            dispatch(closeModal())
        }
    }

    const clearData = () => {
        setDoctor(0)
        setDate(moment().format('YYYY-MM-DD'))
        setDisableDoctor(false)
        setXray('UNCHECK')
        setOpg('UNCHECK')
        setLatCaph('UNCHECK')
        setOther('UNCHECK')
        setContinueTreatment('UNCHECK')
        setConsult('UNCHECK')
        setDetailOther('')
        setDentalHistory('')
    }

    const sendOutClinic = () => {
        setDisableDoctor(true)
        setDoctor(0)
    }

    const checkOther = (e: any) => {
        if (e) {
            setOther('CHECK')
        }
        else {
            setOther('UNCHECK')
            setDetailOther('')
        }
    }

    return (
        <ModalCustom
            title={t('MODAL_REFERRAL_EXAMINATION.TITLE')}
            alignFooter={'end'}
            size={'lg'}
            footerInline
            onClose={props.onClose}
            onSubmit={onPrint}
            onReset={() => {
                dispatch(resetModal())
                props.setIsShow(false)
            }}
            textBtnCancel={t('BUTTON.CANCEL')}
            textBtnConfirm={t('BUTTON.PRINT')}
            modalStyle={'modal-referral-examination'}
            component={
                <>
                    <Grid container spacing={2} className="pt-2">
                        <Grid item className='w-100'>
                            <Box className="d-flex align-items-center">
                                <Typography className='mr-3' sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>{t('MODAL_REFERRAL_EXAMINATION.SEND_PATIENT')}</Typography>
                                <FormControl>
                                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                        <FormControlLabel value="false" control={<InputRadio checked={disableDoctor === false} className="radio" onClick={(e: any) => setDisableDoctor(false)} />} label={t('MODAL_REFERRAL_EXAMINATION.SEND_IN')} />
                                        <FormControlLabel value="true" control={<InputRadio checked={disableDoctor === true} onClick={(e: any) => sendOutClinic()} />} label={t('MODAL_REFERRAL_EXAMINATION.SEND_OUT')} />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={6} className='w-100'>
                            <InputSecondNewDatePicker
                                required
                                key={'date'}
                                label={t('MODAL_REFERRAL_EXAMINATION.DATE')}
                                value={date}
                                onchange={(e: any) => setDate(moment(e).format('YYYY-MM-DD'))} />
                        </Grid>
                        <Grid item xs={6} className='w-100'>
                            <BasicSelect
                                required
                                name="doctorId"
                                labelId="lb-dentist"
                                selectId="sl-dentist"
                                label={t('HISTORY_APPOINTMENT.DOCTOR')}
                                value={doctor || null}
                                options={[
                                    _.map(doctors, (doctorData: any, i: number) => (
                                        <MenuItem key={i} value={doctorData.userId}>
                                            {doctorData.fullname}
                                        </MenuItem>
                                    ))
                                ]}
                                onchange={(event) => {
                                    setDoctor(event.target.value)
                                    setErrorDoctorSelect('')
                                }}
                                classesOption="style-select-doctors-mb"
                                disabled={disableDoctor}
                                helperText={errorDoctorSelect || ''}
                            />
                        </Grid>
                    </Grid>
                    <div className='pt-2 pl-1'>
                        <div>{t('MODAL_REFERRAL_EXAMINATION.DETAIL_TREATMENT')}</div>
                        <div className='d-flex' style={{ width: '100%' }}>
                            <div style={{ whiteSpace: 'nowrap' }}>
                                <InputCheckbox label={t('X-Ray')} className="hover-none-bg" checked={xray === 'CHECK'}
                                    onChange={(e) => setXray(e.target.checked ? 'CHECK' : 'UNCHECK')} value={String(xray)} />
                            </div>
                            <div style={{ width: '100%' }}>
                                <div>
                                    <InputCheckbox label={t('OPG')} className="hover-none-bg" checked={opg === 'CHECK'}
                                        onChange={(e) => setOpg(e.target.checked ? 'CHECK' : 'UNCHECK')} value={String(opg)} disabled={!xray} />
                                </div>
                                <div>
                                    <InputCheckbox label={t('LAt Caph')} className="hover-none-bg" checked={latCaph === 'CHECK'}
                                        onChange={(e) => setLatCaph(e.target.checked ? 'CHECK' : 'UNCHECK')} value={String(latCaph)} disabled={!xray} />

                                </div>
                                <div>
                                    <InputCheckbox label={t('MODAL_REFERRAL_EXAMINATION.MODAL.FORM_OTHER')} className="hover-none-bg" checked={other === 'CHECK'}
                                        onChange={(e) => checkOther(e.target.checked)} value={String(other)} disabled={!xray} />
                                    <InputTextarea
                                        placeholder={t('MODAL_REFERRAL_EXAMINATION.DETAIL')}
                                        onchange={(e: any) => {
                                            setDetailOther(e.target.value)
                                        }}
                                        value={detailOther}
                                        disabled={other === "UNCHECK"} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <InputCheckbox label={t('MODAL_REFERRAL_EXAMINATION.EXAMINATION')} className="hover-none-bg" checked={continueTreatment === 'CHECK'}
                                onChange={(e) => setContinueTreatment(e.target.checked ? 'CHECK' : 'UNCHECK')} value={String(continueTreatment)} />
                        </div>
                        <div>
                            <InputCheckbox label={t('MODAL_REFERRAL_EXAMINATION.CONCERN')} className="hover-none-bg" checked={consult === 'CHECK'}
                                onChange={(e) => setConsult(e.target.checked ? 'CHECK' : 'UNCHECK')} value={String(consult)} />
                        </div>
                    </div>
                    <div className='pt-2'>
                        <div>Dental history</div>
                        <InputTextarea
                            placeholder={t('MODAL_REFERRAL_EXAMINATION.DETAIL')}
                            onchange={(e: any) => {
                                setDentalHistory(e.target.value)
                            }}
                            value={dentalHistory} />
                    </div>

                    {loading && <Loading show={true} type="fullLoading" />}
                </>
            }
        />
    )
}

