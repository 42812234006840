import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useLocation, useHistory } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import { useSelector } from 'react-redux'
import { Divider, Typography, Box, IconButton, Autocomplete, TextField } from '@mui/material'
import { useParams } from 'react-router'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles, { ButtonAddDiagnosis } from 'features/setting/treatments/operatives/style'
import 'antd/dist/antd.css'

/** API */
import { routeName } from 'routes/routes-name'
import OperativeApi from 'api/setting/treatments/operative.api'
import OperativeUnitApi from 'api/setting/treatments/operative-unit.api'
import OperativeTypeApi from 'api/setting/treatments/operative-type.api'
import DiagnosisApi from 'api/setting/treatments/diagnosis.api'
import ClinicFeeApi from 'api/setting/treatments/clinic-fee.api'

/** UTILS || SLICE */
import { icons } from 'constants/images'

import InputTextField from 'component/Input/InputTextField'
import BasicSelect from 'component/Select/BasicSelect'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import _ from 'lodash'
import { colors } from 'constants/theme'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { numberFormat, numberOnly } from 'utils/app.utils'
import InputCheckbox from 'component/Input/InputCheckbox'

const initStateErrorMessage = {
  OPERATIVE_TYPE_ID: ``,

  OPERATIVE_CODE: ``,
  IS_DUPLICATE_OPERATIVE_CODE: ``,
  OPERATIVE_CODE_STRING_BASE: ``,
  OPERATIVE_CODE_STRING_EMPTY: ``,
  OPERATIVE_CODE_MAXIMUM_LENGTH: ``,
  OPERATIVE_CODE_ANY_REQUIRED: ``,

  OPERATIVE_NAME: ``,
  IS_DUPLICATE_OPERATIVE_NAME: ``,
  OPERATIVE_NAME_STRING_BASE: ``,
  OPERATIVE_NAME_STRING_EMPTY: ``,
  OPERATIVE_NAME_MAXIMUM_LENGTH: ``,
  OPERATIVE_NAME_ANY_REQUIRED: ``,

  OPERATIVE_NAME_EN: ``,
  IS_DUPLICATE_OPERATIVE_NAME_EN: ``,
  OPERATIVE_NAME_EN_STRING_BASE: ``,
  OPERATIVE_NAME_EN_STRING_EMPTY: ``,
  OPERATIVE_NAME_EN_MAXIMUM_LENGTH: ``,
  OPERATIVE_NAME_EN_ANY_REQUIRED: ``,

  OPERATIVE_COST_PRICE: ``,
  COST_PRICE_ANY_REQUIRED: ``,
  COST_PRICE_NUMBER: ``,
  OPERATIVE_SALE_PRICE: ``,
  SALE_PRICE_ANY_REQUIRED: ``,
  SALE_PRICE_NUMBER: ``,

  OPERATIVE_UNIT_ID: ``,
  OPERATIVE_DIAGNOSES_UNIQUE: ``,

  EMPTY_OPERATIVE_NAME: ``,
  EMPTY_OPERATIVE_NAME_EN: ``,
  EMPTY_OPERATIVE_CODE: ``,
  EMPTY_OPERATIVE_TYPE: ``,
  EMPTY_OPERATIVE_UNIT: ``,
  EMPTY_OPERATIVE_COST: ``,
  EMPTY_OPERATIVE_SALE: ``
}
interface DiagnosesInterface {
  diagnosisId: number | null
  diagnosisText: string
}
export default function FormOperatives() {
  const classes = useStyles()
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()

  const [initLocation, setInitLocation] = useState({})

  const [operativeUnits, setOperativeUnits]: any = useState([])
  const [operativeTypes, setOperativeTypes]: any = useState([])
  const [defaultOperativeType, setDefaultOperativeType] = useState({ label: ``, value: `` })
  const [defaultOperativeUnit, setDefaultOperativeUnit] = useState({ label: ``, value: `` })

  const [diagnoses, setDiagnoses] = useState<DiagnosesInterface[]>([])

  /** DATA OPTION */
  const [diagnosesOption, setDiagnosesOption] = useState<any[]>([])
  const [clinicFeeOptions, setClinicFeeOptions] = useState<any[]>([])
  const [clinicFeeSelected, setClinicFeeSelected] = useState<any[]>([])

  const [operativeId, setOperativeId] = useState<number>()
  const [operativeTypeId, setOperativeTypeId] = useState<number>()
  const [operativeUnitId, setOperativeUnitId] = useState<number>()
  const [operativeCode, setOperativeCode] = useState('')
  const [operativeName, setOperativeName] = useState('')
  const [operativeNameEn, setOperativeNameEn] = useState('')
  const [operativeCostPrice, setOperativeCostPrice] = useState('')
  const [operativeSalePrice, setOperativeSalePrice] = useState('')
  const [operativeClinicFee, setOperativeClinicFee] = useState<any[]>([])
  const [operativeStatus, setOperativeStatus] = useState('')
  const [disabledBtnAddDiagnosis, setDisabledBtnAddDiagnosis] = useState(false)
  const [operativeFixCode, setOperativeFixCode] = useState<'0' | '1'>('0')

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

  /** Diagnosis */
  const disabledBtnAdd = useSelector((state: any) => state.buttons.isDisabled)
  const { id }: any = useParams()

  const loadData = useCallback(async () => {
    const respDiagnosis = await DiagnosisApi.findAll({ pageLimit: 100, search: '', status: 'ACTIVE' })
    if (respDiagnosis.status === 200) {
      setDiagnosesOption(respDiagnosis.data)
    }
  }, [])

  useEffect(() => {
    loadData()
    handleChangeClinicFee()
  }, [loadData])

  const handleChangeOperativeUnit = useCallback(async (search = '') => {
    const respOperativeUnits = await OperativeUnitApi.findAll({ pageLimit: 100, search: search, status: 'ACTIVE' })
    if (respOperativeUnits.status === 200) {
      const options = respOperativeUnits.data.map((value: any) => {
        return { label: value.operativeUnitName, value: value.operativeUnitId }
      })
      setOperativeUnits(options)
    }
  }, [])

  const handleChangeOperativeType = useCallback(async (search = '') => {
    const respOperativeTypes = await OperativeTypeApi.findAll({ pageLimit: 100, search: search, status: 'ACTIVE' })
    if (respOperativeTypes.status === 200) {
      const options = respOperativeTypes.data.map((value: any) => {
        return { label: value.operativeTypeName, value: value.operativeTypeId }
      })
      setOperativeTypes(options)
    }
  }, [])

  const handleChangeClinicFee = useCallback(async (search = '') => {
    const respClinicFees = await ClinicFeeApi.findAll({ pageLimit: 100, search: search, status: 'ACTIVE' })
    if (respClinicFees.status === 200) {
      const options = respClinicFees.data.map((value: any) => {
        return { label: value.clinicFeeName, value: value.clinicFeeId, price: Number(value.price || 0) }
      })
      setClinicFeeOptions(options)
    }
  }, [])

  useEffect(() => {
    setInitLocation(location)
    const { state }: any = initLocation
    if (state) onEdit(state)
    else {
      handleChangeOperativeUnit()
      handleChangeOperativeType()
      handleChangeClinicFee()
    }
  }, [location, initLocation])

  const onEdit = async (objData: any) => {
    if (objData) {
      setOperativeId(objData.operativeId)
      setOperativeTypeId(objData.operativeTypeId)
      setOperativeUnitId(objData.operativeUnitId)
      setOperativeCode(objData.operativeCode)
      setOperativeName(objData.operativeName)
      setOperativeNameEn(objData.operativeNameEn)
      setOperativeCostPrice(objData.costPrice)
      setOperativeSalePrice(objData.salePrice)
      setOperativeStatus(objData.status)
      setOperativeFixCode(objData.fixCode)

      if (objData.operativeClinicFees.length) {
        setOperativeClinicFee(objData.operativeClinicFees.map(({ clinicFeeId }: any) => clinicFeeId))
        setClinicFeeSelected(
          objData.operativeClinicFees.map((value: any) => {
            return { label: value.clinicFeeText, value: value.clinicFeeId, price: Number(value.price || 0) }
          })
        )
      }
      if (objData.operativeDiagnoses.length) {
        const temp: DiagnosesInterface[] = objData.operativeDiagnoses.map((di: any) => ({ diagnosisId: di.diagnosisId, diagnosisText: di.diagnosisText }))
        setDiagnoses(temp)
      }
      await handleChangeOperativeUnit(objData?.operative_unit?.operativeUnitName || '')
      await handleChangeOperativeType(objData?.operative_type?.operativeTypeName || '')

      if (objData?.operative_unit) setDefaultOperativeUnit({ label: objData?.operative_unit?.operativeUnitName || '', value: objData?.operative_unit?.operativeUnitName || '' })

      if (objData?.operative_type) setDefaultOperativeType({ label: objData?.operative_type?.operativeTypeName || '', value: objData?.operative_type?.operativeTypeName || '' })
    }
  }

  const submit = () => {
    if (!operativeName || !operativeCode || !operativeTypeId || !operativeUnitId || operativeCostPrice === '' || operativeSalePrice === '') {
      return setErrorMessage({
        ...errorMessage,
        ...{
          EMPTY_OPERATIVE_NAME: !operativeName ? t('OPERATIVE.MESSAGE.OPERATIVE_NAME') : '',
          EMPTY_OPERATIVE_CODE: !operativeCode ? t('OPERATIVE.MESSAGE.OPERATIVE_CODE') : '',
          EMPTY_OPERATIVE_TYPE: !operativeTypeId ? t('OPERATIVE_TYPE.MESSAGE.OPERATIVE_TYPE_NAME') : '',
          EMPTY_OPERATIVE_UNIT: !operativeUnitId ? t('OPERATIVE_UNIT.MESSAGE.OPERATIVE_UNIT_NAME') : '',
          EMPTY_OPERATIVE_COST: !operativeCostPrice ? t('OPERATIVE.MESSAGE.OPERATIVE_COST_PRICE') : '',
          EMPTY_OPERATIVE_SALE: !operativeSalePrice ? t('OPERATIVE.MESSAGE.OPERATIVE_SALE_PRICE') : ''
        }
      })
    }

    const clinicFees: any[] = []
    const tempClinicFees: any[] = []
    for (const val of operativeClinicFee) {
      if (tempClinicFees.indexOf(val) === -1) {
        tempClinicFees.push(val)
        clinicFees.push({ clinicFeeId: val })
      }
    }

    const objSave: any = {
      operativeTypeId,
      operativeCode,
      operativeName,
      operativeNameEn,
      costPrice: operativeCostPrice,
      salePrice: operativeSalePrice,
      operativeUnitId,
      operativeDiagnoses: diagnoses,
      operativeClinicFees: clinicFees,
      fixCode: operativeFixCode
    }

    if (!operativeTypeId) return setErrorMessage({ ...errorMessage, ...{ OPERATIVE_TYPE_ID: t('OPERATIVE.MESSAGE.OPERATIVE_TYPE_ID') } })
    if (!operativeCode) return setErrorMessage({ ...errorMessage, ...{ OPERATIVE_CODE: t('OPERATIVE.MESSAGE.OPERATIVE_CODE') } })
    if (!operativeName) return setErrorMessage({ ...errorMessage, ...{ OPERATIVE_NAME: t('OPERATIVE.MESSAGE.OPERATIVE_NAME') } })
    if (operativeCostPrice === '') return setErrorMessage({ ...errorMessage, ...{ OPERATIVE_COST_PRICE: t('OPERATIVE.MESSAGE.OPERATIVE_COST_PRICE') } })
    if (operativeSalePrice === '') return setErrorMessage({ ...errorMessage, ...{ OPERATIVE_SALE_PRICE: t('OPERATIVE.MESSAGE.OPERATIVE_SALE_PRICE') } })
    if (!operativeUnitId) return setErrorMessage({ ...errorMessage, ...{ OPERATIVE_UNIT_ID: t('OPERATIVE.MESSAGE.OPERATIVE_UNIT_ID') } })
    if (diagnoses.length) {
      if (diagnoses.map((dg: DiagnosesInterface) => dg.diagnosisId).includes(null)) return notiError(t('OPERATIVE.MESSAGE.DIAGNOSES_NUMBER'))
      let newDiagnoses: any = []
      _.forEach(diagnoses, (d) => {
        if (_.find(diagnosesOption, { diagnosisId: d.diagnosisId })) newDiagnoses = [...newDiagnoses, d]
      })
      objSave.operativeDiagnoses = newDiagnoses
    }

    if (operativeId) update(operativeId, objSave)
    else create(objSave)
  }

  const create = (payload: any) => {
    OperativeApi.create(payload)
      .then((res: any) => {
        notiSuccess(t('OPERATIVE.MESSAGE.SUCCESS.CREATE'), '', null, () => loadData())
        history.push(routeName.operatives)
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`OPERATIVE.MESSAGE.${err.message}`) } })
      })
  }

  const update = (updateId: any, payload: any) => {
    if (!operativeName || !operativeCode || !operativeTypeId || !operativeUnitId || operativeCostPrice === '' || operativeSalePrice === '') {
      return setErrorMessage({
        ...errorMessage,
        ...{
          EMPTY_OPERATIVE_NAME: !operativeName ? t('OPERATIVE.MESSAGE.OPERATIVE_NAME') : '',
          EMPTY_OPERATIVE_CODE: !operativeCode ? t('OPERATIVE.MESSAGE.OPERATIVE_CODE') : '',
          EMPTY_OPERATIVE_TYPE: !operativeTypeId ? t('OPERATIVE_TYPE.MESSAGE.OPERATIVE_TYPE_NAME') : '',
          EMPTY_OPERATIVE_UNIT: !operativeUnitId ? t('OPERATIVE_UNIT.MESSAGE.OPERATIVE_UNIT_NAME') : '',
          EMPTY_OPERATIVE_COST: !operativeCostPrice ? t('OPERATIVE.MESSAGE.OPERATIVE_COST_PRICE') : '',
          EMPTY_OPERATIVE_SALE: !operativeSalePrice ? t('OPERATIVE.MESSAGE.OPERATIVE_SALE_PRICE') : ''
        }
      })
    }

    payload.status = operativeStatus
    OperativeApi.update(Number(updateId), payload)
      .then((res: any) => {
        notiSuccess(t(`OPERATIVE.MESSAGE.SUCCESS.UPDATE`), '', null, () => {
          loadData()
        })
        history.push(routeName.operatives)
      })
      .catch((e) => {
        const err = e.response.data
        if (err.message === 'OPERATIVE_DIAGNOSES_UNIQUE') return notiError(t(`OPERATIVE.MESSAGE.${err.message}`))
        else setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`OPERATIVE.MESSAGE.${err.message}`) } })
      })
  }

  useEffect(() => {
    if (_.some(diagnoses, (diagnosis: any) => diagnosis.diagnosisId === '' || diagnosis.diagnosisId === 0 || diagnosis.diagnosisId === null)) {
      setDisabledBtnAddDiagnosis(true)
    } else {
      setDisabledBtnAddDiagnosis(false)
    }
  }, [diagnoses])

  const addDiagnosis = () => {
    const temp: DiagnosesInterface[] = [...diagnoses]
    temp.push({ diagnosisId: null, diagnosisText: '' })
    setDiagnoses(temp)
  }

  const deleteDiagnoses = (index: any) => {
    setDiagnoses(_.filter(diagnoses, (d: any, i: any) => i !== index))
  }

  const handleSelectDiagnosis = (val: any, index: any) => {
    const di: any = diagnosesOption.find((opt) => opt.diagnosisId === val?.value)
    if (di) {
      const temp: DiagnosesInterface[] = [...diagnoses]
      temp[index] = { diagnosisId: di.diagnosisId, diagnosisText: di.diagnosisName }
      setDiagnoses(temp)
    }
  }

  const clearErrorMessage = () => setErrorMessage(initStateErrorMessage)

  return (
    <div className={`${classes.root} container-card container-card h-100 overflow-hidden`}>
      <div className="d-flex flex-column h-100">
        <HeaderLabel
          onSubmit={submit}
          text={id ? t('OPERATIVE.TITLE_UPDATE') : t('OPERATIVE.TITLE_CREATE')}
          navigator={{ previousTo: routeName.operatives, previousTitle: t('OPERATIVE.TITLE'), currentTitle: id ? t('OPERATIVE.TITLE_UPDATE') : t('OPERATIVE.TITLE_CREATE') }}
          goBack={routeName.operatives}
        />

        <Card className="border shadow-sm mx-4 mb-4 h-100 overflow-hidden card" style={{ maxHeight: '100%' }}>
          <div className="overflow-hidden p-4 h-100">
            <Card.Body className="d-flex flex-column h-100 custom-scroll p-0" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
              <Row style={{ marginLeft: '-8px', marginRight: '-8px' }}>
                <Col sm={12} md={6} lg={4} className="pt-3 pt-md-2 px-md-2">
                  <InputTextField
                    key="operativeName"
                    onchange={(event) => {
                      setOperativeName(event.target.value)
                      clearErrorMessage()
                    }}
                    value={operativeName}
                    helperText={
                      errorMessage.OPERATIVE_NAME ||
                      errorMessage.IS_DUPLICATE_OPERATIVE_NAME ||
                      errorMessage.OPERATIVE_NAME_STRING_BASE ||
                      errorMessage.OPERATIVE_NAME_STRING_EMPTY ||
                      errorMessage.OPERATIVE_NAME_MAXIMUM_LENGTH ||
                      errorMessage.OPERATIVE_NAME_ANY_REQUIRED ||
                      errorMessage.EMPTY_OPERATIVE_NAME
                    }
                    label={t('OPERATIVE.INPUT.OPERATIVE_NAME')}
                    required={true}
                  />
                </Col>
                <Col sm={12} md={6} lg={4} className="pt-3 pt-md-2 px-md-2">
                  <InputTextField
                    key="operativeNameEn"
                    onchange={(event) => {
                      setOperativeNameEn(event.target.value)
                      clearErrorMessage()
                    }}
                    value={operativeNameEn}
                    helperText={errorMessage.IS_DUPLICATE_OPERATIVE_NAME_EN}
                    label={t('OPERATIVE.INPUT.OPERATIVE_NAME_EN')}
                  />
                </Col>
                <Col sm={12} md={6} lg={4} className="pt-3 pt-md-2 px-md-2 d-flex align-items-center">
                  <InputCheckbox checked={operativeFixCode === '1'} label={t('OPERATIVE.INPUT.FIXCODE')} onChange={() => setOperativeFixCode(operativeFixCode === '0' ? '1' : '0')} />
                </Col>

                <Divider className="my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important`, marginLeft: '-8px !important', marginRight: '-8px !important' }} />

                <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>{t('OPERATIVE.OPERATIVE_DETAIL')}</Typography>

                <Col sm={12} md={6} lg={4} className="pt-3 px-md-2">
                  <InputTextField
                    key="operativeCode"
                    onchange={(event) => {
                      setOperativeCode(event.target.value)
                      clearErrorMessage()
                    }}
                    value={operativeCode}
                    helperText={
                      errorMessage.OPERATIVE_CODE ||
                      errorMessage.IS_DUPLICATE_OPERATIVE_CODE ||
                      errorMessage.OPERATIVE_CODE_STRING_BASE ||
                      errorMessage.OPERATIVE_CODE_STRING_EMPTY ||
                      errorMessage.OPERATIVE_CODE_MAXIMUM_LENGTH ||
                      errorMessage.OPERATIVE_CODE_ANY_REQUIRED ||
                      errorMessage.EMPTY_OPERATIVE_CODE
                    }
                    label={t('OPERATIVE.INPUT.OPERATIVE_CODE')}
                    required={true}
                  />
                </Col>
                <Col sm={12} md={6} lg={4} className="pt-3 px-md-2">
                  <AutocompleteSelect
                    labelId="operative-type"
                    key={`operative-type`}
                    inputLabel={t('OPERATIVE.INPUT.OPERATIVE_TYPE')}
                    noOptionsText={t('OPERATIVE.MESSAGE.NO_DATA')}
                    options={operativeTypes}
                    maxHight={'180px'}
                    onchange={(e, val) => {
                      clearErrorMessage()
                      if (val) {
                        const { name, value }: any = val
                        setOperativeTypeId(value)
                        setDefaultOperativeType(val)
                      }
                    }}
                    onInputChange={(event: any, newValue: any) => handleChangeOperativeType(newValue)}
                    onOpen={(e: any) => {
                      handleChangeOperativeType('')
                    }}
                    disableClearable
                    required
                    errMessage={errorMessage.OPERATIVE_TYPE_ID || errorMessage.EMPTY_OPERATIVE_TYPE}
                    value={defaultOperativeType}
                  />
                </Col>
                <Col sm={12} md={6} lg={4} className="pt-3 px-md-2">
                  <AutocompleteSelect
                    labelId="operative-unit"
                    key={`operative-unit`}
                    inputLabel={t('OPERATIVE.INPUT.OPERATIVE_UNIT')}
                    noOptionsText={t('OPERATIVE.MESSAGE.NO_DATA')}
                    options={operativeUnits}
                    maxHight={'180px'}
                    onchange={(e, val) => {
                      clearErrorMessage()
                      if (val) {
                        const { name, value }: any = val
                        setOperativeUnitId(value)
                        setDefaultOperativeUnit(val)
                      }
                    }}
                    onInputChange={(event: any, newValue: any) => handleChangeOperativeUnit(newValue)}
                    onOpen={(e: any) => {
                      handleChangeOperativeUnit('')
                    }}
                    disableClearable
                    required
                    errMessage={errorMessage.OPERATIVE_UNIT_ID || errorMessage.EMPTY_OPERATIVE_UNIT}
                    value={defaultOperativeUnit}
                  />
                </Col>
                <Col sm={12} md={6} lg={4} className="pt-3 px-md-2">
                  <InputTextField
                    key="operativeCostPrice"
                    onchange={(event) => {
                      setOperativeCostPrice(numberOnly(event))
                      clearErrorMessage()
                    }}
                    value={operativeCostPrice}
                    helperText={errorMessage.OPERATIVE_COST_PRICE || errorMessage.COST_PRICE_ANY_REQUIRED || errorMessage.COST_PRICE_NUMBER || errorMessage.EMPTY_OPERATIVE_COST}
                    label={t('OPERATIVE.INPUT.OPERATIVE_COST_PRICE')}
                    required={true}
                  />
                </Col>
                <Col sm={12} md={6} lg={4} className="pt-3 px-md-2">
                  <InputTextField
                    key="operativeSalePrice"
                    onchange={(event) => {
                      setOperativeSalePrice(numberOnly(event))
                      clearErrorMessage()
                    }}
                    value={operativeSalePrice}
                    helperText={errorMessage.OPERATIVE_SALE_PRICE || errorMessage.SALE_PRICE_ANY_REQUIRED || errorMessage.SALE_PRICE_NUMBER || errorMessage.EMPTY_OPERATIVE_SALE}
                    label={t('OPERATIVE.INPUT.OPERATIVE_SALE_PRICE')}
                    required={true}
                  />
                </Col>
                <Col sm={12} md={6} lg={4} className="pt-3 px-md-2">
                  <AutocompleteSelect
                    multiple
                    labelId="label-clinic-fees"
                    inputLabel={t('OPERATIVE.INPUT.OPERATIVE_CLINIC_FEES')}
                    noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                    options={clinicFeeOptions}
                    getOptionLabel={(option: any) => `${option.label} (${numberFormat(option.price, 0, 0)} ${t('CURRENCY_CHANGE.TH')})`}
                    renderOption={(props, option: any) => (
                      <Box
                        key={option.value}
                        component="li"
                        {...props}
                        sx={{
                          pointerEvents: _.find(clinicFeeSelected, { value: option.value }) ? 'none' : 'auto',
                          color: _.find(clinicFeeSelected, { value: option.value }) ? colors.disabledGray : colors.textPrimary,
                          svg: {
                            filter: _.find(clinicFeeSelected, { value: option.value }) ? 'grayscale(1)' : 'grayscale(0)'
                          }
                        }}
                      >
                        {`${option.label} (${numberFormat(option.price, 0, 0)} ${t('CURRENCY_CHANGE.TH')})`}
                      </Box>
                    )}
                    onchange={(e, val) => {
                      setClinicFeeSelected(val)
                      if (val) {
                        setOperativeClinicFee(val.map(({ value }: any) => value))
                      }
                    }}
                    onInputChange={(event: any, newValue: any) => handleChangeClinicFee(newValue)}
                    value={clinicFeeSelected}
                    key={`clinic-fee-id`}
                    popupIcon={<span></span>}
                    // disableClearable
                    clearOnBlur
                    clearOnEscape
                  />
                </Col>
              </Row>

              <Row className="mx-0 mt-4">
                <Typography className="mb-3" sx={{ fontSize: '18px', fontWeight: 600 }}>
                  {t('DIAGNOSIS.INPUT.DIAGNOSIS_NAME')}
                </Typography>
                <Col sm={12} xl={3} md={5}>
                  <ButtonAddDiagnosis variant="text" disabled={disabledBtnAdd || disabledBtnAddDiagnosis} onClick={() => addDiagnosis()} className="d-flex" sx={{ marginBottom: '1px' }}>
                    <span className={'mr-1'}>+</span> {t('DIAGNOSIS.TITLE_CREATE')}
                  </ButtonAddDiagnosis>
                </Col>
                <Row className="mx-0 mt-3">
                  {diagnoses
                    .map((di: DiagnosesInterface, iDi: number) => (
                      <Col key={iDi} sm={12} className="px-0">
                        <div className="d-flex align-items-center w-100 mt-3">
                          <p className="m-0 pr-2" style={{ width: '40px', flex: 'none' }}>
                            {iDi + 1}
                          </p>
                          <Box className="w-100 pr-3" sx={{ width: { lg: '70% !important', xl: '60% !important' }, '#operative-unit-listbox': { height: 140 } }}>
                            <AutocompleteSelect
                              required={true}
                              onchange={(event, value) => {
                                handleSelectDiagnosis(value, iDi)
                                clearErrorMessage()
                              }}
                              inputLabel={t('DIAGNOSIS.INPUT.DIAGNOSIS_NAME')}
                              labelId="operative-unit"
                              value={di.diagnosisText || ''}
                              options={diagnosesOption.map((option: any) => ({
                                value: option.diagnosisId,
                                label: option.diagnosisName || ''
                              }))}
                            />
                          </Box>
                          <IconButton disableFocusRipple disableRipple onClick={() => deleteDiagnoses(iDi)} className="p-0" sx={{ backgroundColor: 'transparent' }}>
                            <img src={icons.btnMinus} alt="discard" />
                          </IconButton>
                        </div>
                      </Col>
                    ))
                    .reverse()}
                </Row>
              </Row>
            </Card.Body>
          </div>
        </Card>
      </div>
    </div>
  )
}
