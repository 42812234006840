import { colors } from "constants/theme"

export default function New() {
    return (

        <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.1641 0.832031H1.83073C1.04448 0.832031 0.421146 1.46245 0.421146 2.2487L0.414062 10.7487C0.414062 11.5349 1.04448 12.1654 1.83073 12.1654H13.1641C13.9503 12.1654 14.5807 11.5349 14.5807 10.7487V2.2487C14.5807 1.46245 13.9503 0.832031 13.1641 0.832031ZM5.01823 8.6237H4.16823L2.36198 6.14453V8.6237H1.47656V4.3737H2.36198L4.13281 6.85286V4.3737H5.01823V8.6237ZM8.5599 5.2662H6.78906V6.05953H8.5599V6.95203H6.78906V7.73828H8.5599V8.6237H5.72656V4.3737H8.5599V5.2662ZM13.5182 7.91536C13.5182 8.30495 13.1995 8.6237 12.8099 8.6237H9.97656C9.58698 8.6237 9.26823 8.30495 9.26823 7.91536V4.3737H10.1536V7.56828H10.9541V5.07495H11.8395V7.5612H12.6328V4.3737H13.5182V7.91536Z" style={{ fill: colors.themeSecondColor }} />
        </svg>

    )
}
