

import { colors } from "constants/theme"

export default function IconPayment() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.748" height="16.705" viewBox="0 0 16.748 16.705">
            <path data-name="Path 6264" d="M41.431 221.948V232.4a4.661 4.661 0 0 0 2.617 1.441c2.892.535 7.77.262 7.966-1.441.294-2.55-.367-10.929-.367-10.929l-5.365-1.213z" transform="translate(-40.408 -219.54)" style={{ fill: colors.white }} />
            <path d="M12.131 18.208c3 0 6.033-1.036 6.033-3.016v-9.05c0-1.98-3.034-2.815-6.033-2.815s-5.876.739-5.876 2.719v9.224c0 1.981 2.878 2.938 5.876 2.938zm0-.976c-2.807 0-4.8-1.277-4.8-1.809v-1.188a11.183 11.183 0 0 0 4.8 1.035 12.285 12.285 0 0 0 5.037-1.23v1.382c0 .532-2.23 1.81-5.037 1.81zm0-12.825c2.807 0 5.037 1.368 5.037 1.9S14.938 8 12.131 8s-4.8-.795-4.8-1.689 1.993-1.905 4.8-1.905zm-4.8 3.8a11.2 11.2 0 0 0 4.8.956 12.32 12.32 0 0 0 5.037-.963v1.536c0 .531-2.23 1.363-5.037 1.363s-4.8-.831-4.8-1.363zm0 3.016a11.2 11.2 0 0 0 4.8.956 12.32 12.32 0 0 0 5.037-.956v1.45c0 .531-2.23 1.372-5.037 1.372s-4.8-.84-4.8-1.372z" transform="translate(-5.955 -3.027)" style={{ stroke: 'rgba(28,185,154,.02)', strokeWidth: '.6px', fill: colors.themeSecondColor }} />
            <g data-name="Ellipse 1" transform="translate(8.545 8.503)" style={{ stroke: colors.themeSecondColor, fill: colors.white }}>
                <circle cx="4.101" cy="4.101" r="4.101" style={{ stroke: 'none' }} />
                <circle cx="4.101" cy="4.101" r="3.601" style={{ fill: 'none' }} />
            </g>
            <path data-name="Path 5747" d="M266.477 40.323v.392a1 1 0 0 0 .334 1.949.334.334 0 1 1 0 .669.605.605 0 0 1-.434-.253.334.334 0 1 0-.512.43 1.387 1.387 0 0 0 .612.436v.39a.334.334 0 1 0 .669 0v-.392a1 1 0 0 0-.334-1.949.334.334 0 0 1 0-.669.537.537 0 0 1 .362.175.334.334 0 1 0 .465-.481 1.292 1.292 0 0 0-.493-.308v-.39a.334.334 0 1 0-.669 0zm0 0" transform="translate(-254.154 -29.726)" style={{ strokeWidth: '.2px', stroke: colors.themeSecondColor, fill: colors.themeSecondColor }} />
        </svg>
    )
}
