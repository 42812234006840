import { styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import LabOrderApi from 'api/lab-order.api'
import { resetModal } from 'app/slice/modal.slice'
import InputTextarea from 'component/Input/InputTextarea'
import InputTextField from 'component/Input/InputTextField'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import { notiError, notiSuccess } from 'component/notifications/notifications'

export const ModalCancel = styled(ModalCustom)(({ theme }) => ({
    '& .modal-dialog': { width: '600px', maxWidth: 600 }
}))

export interface ICancelLabOrderProps {
    isShow: boolean
    id: number
    labOrderNumber?: string
    onReset?: () => void
    onSuccess?: () => void
}
const initStateErrorMessage = {
    USER_NAME_STRING_EMPTY: '',
    PASSWORD_STRING_EMPTY: '',
    CANCEL_REMARK_STRING_EMPTY: '',

    NOT_FOUND_USER: '',
}
export default function CancelLabOrder(props: ICancelLabOrderProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [username, setUserCode] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [cancelRemark, setCancelRemark] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<any>({ ...initStateErrorMessage })

    const clearErrorMessage = async () => setErrorMessage(initStateErrorMessage)
    const resetForm = async () => {
        clearErrorMessage()
        setUserCode('')
        setPassword('')
        setCancelRemark('')
        if (props.onReset) props.onReset()
        dispatch(resetModal())
    }

    useEffect(() => {
        clearErrorMessage()
    }, [username, password, cancelRemark])

    const onDelete = (id: number, obj: any) => {
        if (!username || !password || !cancelRemark) {
            setErrorMessage({
                USER_NAME_STRING_EMPTY: !username ? t('LAB_ORDER.MESSAGE.ERROR.USER_NAME_STRING_EMPTY') : '',
                PASSWORD_STRING_EMPTY: !password ? t('LAB_ORDER.MESSAGE.ERROR.PASSWORD_STRING_EMPTY') : '',
                CANCEL_REMARK_STRING_EMPTY: !cancelRemark ? t('LAB_ORDER.MESSAGE.ERROR.CANCEL_REMARK_STRING_EMPTY') : '',
            })
            return false
        }

        LabOrderApi.cancel(id, { username, password, cancelRemark })
            .then((res) => {

                if (res.status !== undefined && res.status === 200) {
                    notiSuccess(t(`LAB_ORDER.MESSAGE.SUCCESS.CANCEL`))
                    dispatch(resetModal())
                    resetForm()
                    if (props.onSuccess) props.onSuccess()

                } else {
                    const err = res.response.data
                    const errMsg: string = err?.message || ''

                    if (errMsg === 'NOT_FOUND_ORDER_PAYMENT')
                        notiError('LAB_ORDER.MESSAGE.ERROR.NOT_FOUND_ORDER_PAYMENT')
                    else if (errMsg === 'NOT_FOUND_USER')
                        setErrorMessage({
                            ...initStateErrorMessage,
                            NOT_FOUND_USER: t('LAB_ORDER.MESSAGE.ERROR.NOT_FOUND_USER'),
                        })

                }
            })
            .catch((e) => {
                const message = e?.response?.data?.message
                if (message === 'NOT_FOUND_USER' || message === 'INVALID_PASSWORD') {
                    setErrorMessage({
                        ...initStateErrorMessage,
                        NOT_FOUND_USER: message === 'NOT_FOUND_USER' ? t('LAB_ORDER.MESSAGE.ERROR.NOT_FOUND_USER') : '',
                        INVALID_PASSWORD: message === 'INVALID_PASSWORD' ? t('LAB_ORDER.MESSAGE.ERROR.INVALID_PASSWORD') : ''
                    })
                } else {
                    notiError(t('LAB_ORDER.MESSAGE.ERROR.DONT_HAVE_PERMISSION_TO_DELETE'))
                    setErrorMessage({
                        USER_NAME_STRING_EMPTY: t('LAB_ORDER.MESSAGE.ERROR.DONT_HAVE_PERMISSION_TO_DELETE')
                    })
                }
            })
    }

    return props.isShow ? (
        <ModalCancel
            size={'lg'}
            modalStyle={'modal-cancel'}
            title={t('LAB_ORDER.TITLE_CANCEL_LAB')}
            onClose={() => console.log("222 close")}
            alignFooter={'center'}
            component={
                <>
                    <span className="fz-14 text-muted">{t('LAB_ORDER.FORM.CAPTION')} {props.labOrderNumber}</span>
                    <Row className="mx-0 mt-2">
                        <Col xs={12} sm={6} className="px-2 mb-3">
                            <InputTextField
                                required
                                label={t('LAB_ORDER.FORM.USER_NAME')}
                                type="text"
                                value={username}
                                inputProps={{ shrink: true }}
                                helperText={errorMessage?.USER_NAME_STRING_EMPTY || errorMessage?.NOT_FOUND_USER || ''}
                                onchange={(e) => setUserCode(e.target.value)}
                                disabledAutoComplete
                            />
                        </Col>
                        <Col xs={12} sm={6} className="px-2 mb-3">
                            <InputTextField
                                required
                                label={t('LAB_ORDER.FORM.PASSWORD')}
                                type="password"
                                value={password}
                                inputProps={{ shrink: true }}
                                helperText={errorMessage?.PASSWORD_STRING_EMPTY || errorMessage?.INVALID_PASSWORD || ''}
                                onchange={(e) => setPassword(e.target.value)}
                            />
                        </Col>
                        <Col xs={12} sm={12} className="px-2 mb-3">
                            <InputTextarea required value={cancelRemark} onchange={(event) => setCancelRemark(event.target.value)} label={t('LAB_ORDER.FORM.CANCEL_REMARK')} helperText={errorMessage?.CANCEL_REMARK_STRING_EMPTY || ''} />
                        </Col>
                    </Row>
                </>
            }
            id={props.id}
            textBtnDelete={t('LAB_ORDER.BUTTON.CONFIRM')}
            onDelete={onDelete}
            autoResetModal={false}
            onReset={() => (resetForm())}
            textBtnCancel={t('APPOINTMENT.FORM.CANCEL')}
        />
    ) : <></>
}
