import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'
import { Typography, Chip } from '@mui/material'
import { Modal, Dropdown } from 'react-bootstrap'

export const ModalStyle = styled(Modal)(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  zIndex: theme.zIndex.modal,
  '.modal-dialog': {
    overflow: 'hidden',
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1572
    },
    [theme.breakpoints.up('xs')]: {
      height: 'calc(100% - 3.5rem)'
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      maxWidth: 'calc(100% - 3.5rem)'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: '1rem'
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(100% - 2rem)',
      height: 'calc(100% - 2rem)'
    }
  },
  '.modal-content': {
    height: '100%',
    maxHeight: '100%',
    borderRadius: 10,
    border: 0,
    backdropFilter: 'saturate(1) blur(40px)',
    backgroundColor: colors.white90
  }
}))

export const Fragment = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  maxHeight: '100%',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column'
  }
}))

export const FragmentTab = styled('div')(({ theme }) => ({
  borderBottom: `2px solid ${colors.extraLightBlue}`,
  button: {
    position: 'relative',
    padding: 0,
    height: 40,
    width: 185,
    fontSize: 16,
    color: colors.textPrimary,
    border: 0,
    backgroundColor: 'transparent',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: -2,
      width: 0,
      height: 2,
      transition: 'width .3s ease'
    },
    '&.active': {
      color: colors.themeSecondColor,
      '&:before': {
        width: '100%',
        backgroundColor: colors.themeSecondColor
      }
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%'
    }
  }
}))

export const FragmentLeft = styled('div')(({ theme }) => ({
  width: '100%',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  overflowY: 'auto',
  [theme.breakpoints.up('sm')]: {
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem'
  },
  [theme.breakpoints.up('lg')]: {
    width: '40%',
    borderRight: `1px solid ${colors.lightGray}`
  },
  [theme.breakpoints.up('xl')]: {
    width: '38%',
    paddingLeft: '2rem',
    paddingRight: '2rem'
  }
}))

export const FragmentRight = styled('div')(({ theme }) => ({
  width: '100%',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  overflowY: 'auto',
  '.chatRoom-wrap': {
    minHeight: 200
  },
  [theme.breakpoints.up('sm')]: {
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem'
  },
  [theme.breakpoints.up('lg')]: {
    width: '60%',
    maxHeight: 'calc(100% - (44px + 24px))'

  },
  [theme.breakpoints.up('xl')]: {
    width: '62%',
    paddingLeft: '2rem',
    paddingRight: '2rem'
  },
  [theme.breakpoints.down('lg')]: {
    '.chatRoom-wrap': {
      minHeight: 300,
      maxHeight: 300
    }
  }
}))

export const Header = styled(Modal.Header)(({ theme }) => ({
  width: '100%',
  height: 72,
  padding: '0 1.5rem',
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  backgroundColor: `${colors.themeMainColor} !important`,
  justifyContent: 'start',
  p: {
    fontSize: 24,
    fontWeight: 600,
    color: colors.white,
    marginLeft: 6
  },
  img: {
    width: 40
  },
  svg: {
    width: 36,
    height: 36
  },
  [theme.breakpoints.down('sm')]: {
    height: 52,
    p: {
      fontSize: 18
    }
  }
}))

export const CardProfile = styled('div')(({ theme }) => ({
  '.MuiAvatar-root': {
    width: 110,
    height: 110,
    [theme.breakpoints.down('lg')]: {
      width: 88,
      height: 88
    },
    [theme.breakpoints.down('sm')]: {
      width: 46,
      height: 46,
      borderWidth: 3
    }
  }
}))

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 600,
  color: colors.textPrimary,
  lineHeight: 1.2,
  '&.patient-name': {
    color: colors.themeMainColor
  },
  '&.subTypo': {
    fontWeight: 400,
    fontSize: 16
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
    '&.subTypo': {
      fontSize: 14
    }
  }
}))

export const AppointmentDescription = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${colors.lightGray}`,
  margin: '1rem -0.5rem 0',
  padding: '1.4rem 0.5rem 0'
}))

export const DropdownButtonStatus = styled(Dropdown)(({ theme }) => ({
  '.dropdown-toggle': {
    boxShadow: 'none !important',
    border: 'none !important',
    fontSize: 16,
    width: 144,
    height: 40,
    padding: '0 0.625rem 0 1rem',
    '&.no-status': {
      color: colors.textPrimary
    },
    '&:after': {
      marginLeft: 'auto'
    },
    '&[aria-expanded="true"]:after': {
      transform: 'rotate(180deg)'
    }
  },
  '.dropdown-menu.show': {
    borderRadius: 3,
    borderColor: colors.lightGray,
    marginTop: 5,
    '.dropdown-item': {
      '&:hover, &:focus': {
        backgroundColor: colors.themeSecondColor06,
        color: colors.themeSecondColor
      }
    }
  }
}))

export const ChatRoom = styled('div')(({ theme }) => ({
  height: 400,
  position: 'relative',
  borderRadius: 4,
  border: `1px solid ${colors.black12}`,
  padding: '1.125rem 1.5rem',
  overflowY: 'auto',
  backgroundColor: colors.white,
  [theme.breakpoints.down('lg')]: {
    height: 200
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0.5rem'
  }
}))

export const ChatDate = styled(Chip)(({ theme }) => ({
  position: 'relative',
  left: '50%',
  transform: 'translateX(-50%)',
  height: 24,
  backgroundColor: colors.lightThemeMain,
  backdropFilter: 'blur(40px)',
  span: {
    fontSize: 14,
    fontWeight: 500,
    color: colors.themeMainColor
  }
}))

export const ChatMessage = styled('div')(({ theme }) => ({
  '.MuiAvatar-root': {
    height: 48,
    width: 48,
    [theme.breakpoints.down('lg')]: {
      height: 42,
      width: 42
    }
  },
  '.title': {
    fontSize: 16,
    fontWeight: 500,
    color: colors.textPrimary
  },
  '.subtitle': {
    fontSize: 14,
    color: colors.disabledGray
  },
  '.MuiChip-root': {
    height: 20,

    borderRadius: 5,
    fontSize: 16,
    color: colors.white
  }
}))

export const ChatNewMessage = styled('div')(({ theme }) => ({
  '.icon-chatBubble': {
    position: 'absolute',
    top: 16,
    left: 14
  },
  textarea: {
    width: '100%',
    resize: 'none',
    border: `1px solid ${colors.black12} !important`,

    backgroundColor: colors.white,
    borderRadius: 4,
    outline: 'none',
    padding: '0.625rem 0.875rem',
    height: 72,
    '&::placeholder': {
      paddingLeft: 18
    },
    [theme.breakpoints.down('sm')]: {
      height: 50
    }
  }
}))

export const ModalFooter = styled(Modal.Footer)(({ theme }) => ({
  '> div': {
    [theme.breakpoints.down('sm')]: {
      width: '50%'
    }
  },
  button: {
    height: 44,
    fontSize: 16,
    boxShadow: 'none !important',
    '&.btn-submit': {
      width: 88
    },
    [theme.breakpoints.down('sm')]: {
      width: '96% !important'
    }
  },
  [theme.breakpoints.up('lg')]: {
    position: 'absolute',
    right: 32,
    bottom: 24
  }
}))

export const CardMessage = styled('div')(({ theme }) => ({
  margin: '0 0 16px',
  marginLeft: '1rem',
  padding: '16px  18px',
  borderRadius: 4,
  border: `solid 1px ${colors.black12}`,
  backgroundColor: colors.white,
  boxShadow: 'none'
}))

export const Carousel = styled('div')(({ theme }) => ({
  margin: '0 -0.75rem',
  [theme.breakpoints.down('sm')]: {
    margin: '0 -0.125rem'
  }
}))

export const Room = styled('div')(({ theme }) => ({
  marginTop: 4,
  padding: '0 0.75rem',
  '.room-wrap': {
    height: 44,
    [theme.breakpoints.up('sm')]: {
      paddingTop: '100%'
    }
  },
  '.room-item': {
    cursor: 'pointer',
    background: colors.white,
    boxShadow: `0 0 6px 0 ${colors.black30}`,
    border: '5px solid transparent',
    borderRadius: '100%',
    svg: {
      width: 60,
      height: 40
    },
    span: {
      fontSize: 16,
      fontWeight: 500,
      color: colors.disabledGray
    },
    '&.active': {
      borderColor: colors.themeSecondColor,
      boxShadow: `0 0 6px 0 ${colors.themeSecondColor30}`,
      span: {
        color: colors.themeSecondColor
      }
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: 4,
      borderWidth: 3,
      boxShadow: `0 0 6px 0 ${colors.black16}`,
      svg: {
        width: 30,
        height: 20
      },
      span: {
        fontSize: 14
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 0.25rem'
  }
}))

export const CustomAutocompleteSelect = styled('div')(({ theme }) => ({
  '.Mui-multiple .MuiOutlinedInput-root': {
    paddingTop: '8px !important',
    '&.MuiInputBase-adornedStart': {
      paddingTop: '10px !important'
    }
  },
  'span.MuiAutocomplete-tag': {
    marginTop: 5.5,
    marginBottom: 5.5
  }
}))
export const ChipTreatment = styled(Chip)<{ background?: string }>(({ theme, background }) => ({
  height: 30,
  fontSize: 16,
  color: colors.textPrimary,
  backgroundColor: background || colors.disabledGray,
  padding: '0 4px',
  '.MuiSvgIcon-root': {
    path: {
      fill: `${colors.textPrimary} !important`
    }
  }
}))

export const CustomHelperText = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  svg: {
    marginTop: 2
  },
  path: {
    fill: colors.statusInactive
  },
  span: {
    color: colors.statusInactive,
    fontSize: 12,
    paddingLeft: 4
  }
}))
