import { useEffect, useState } from 'react'
import { Box, styled } from '@mui/material'
import LabOrderList from 'features/lab-order/lab-order-list'
import LabOrderQueue from 'features/lab-order/lab-order-queue'
import { getBranch } from 'utils/app.utils'
import AboutUs from 'api/admin/clinic-manage/about.api'

export const LabCustomComponent = styled(Box)<{ type: 'queue' | 'list' }>(({ theme, type }) => ({
  maxWidth: type === 'queue' ? '524px' : '100%'
}))

export default function LabOrder() {
  const [reloadQueue, setReloadQueue] = useState<boolean>(false)
  const [reloadList, setReloadList] = useState<boolean>(false)

  const branchId = getBranch()
  const [branch, setBranch] = useState<any>()

  const loadBranchInfo = () => {
    AboutUs.detailClinic(Number(branchId))
      .then(({ data }) => {
        setBranch(data)
      })
      .catch((e) => {
        return
      })
  }

  useEffect(() => {
    loadBranchInfo()
  }, [branchId])

  return (
    <div className="p-3 h-100">
      <Box className="d-lg-flex flex-row h-100 w-100">
        <LabCustomComponent type="queue" className="w-100 h-100" sx={{ flex: 'none' }}>
          <LabOrderQueue branch={branch} reload={() => setReloadList(!reloadList)} valReload={reloadQueue} reloadQueue={(val: boolean) => setReloadList(val)} />
        </LabCustomComponent>
        <LabCustomComponent type="list" className="w-100 overflow-hidden mt-3 mt-lg-0">
          <LabOrderList reload={() => setReloadQueue(!reloadQueue)} valReload={reloadList} reloadQueue={(val: boolean) => setReloadQueue(val)} />
        </LabCustomComponent>
      </Box>
    </div>
  )
}
