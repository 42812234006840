import { useState, useEffect } from 'react'
import moment from 'moment'
import InputTextField from 'component/Input/InputTextField'
import { styled } from '@mui/material/styles'
import localeProvider from 'antd/lib/locale/th_TH'
import locale from 'antd/es/date-picker/locale/th_TH'
import { ConfigProvider, TimePicker } from 'antd'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

/** CONSTANT */
import { colors } from 'constants/theme'

const ContentWrap = styled('div')(({ theme }) => ({
  position: 'relative',
  '&.has-text-field': {
    '.ant-picker': {
      position: 'absolute',
      top: 4,
      left: 0,
      right: 0
    },
    '.MuiFormHelperText-root.Mui-error': {
      marginTop: 2
    }
  }
}))

const CustomTimePicker = styled(TimePicker)(({ theme }) => ({
  cursor: 'pointer',
  '.ant-picker-input': {
    input: {
      fontSize: 0,
      width: 0
    },
    '.ant-picker-suffix': {
      margin: 0
    }
  },
  '& .ant-btn': {
    backgroundColor: `${colors.themeThirdColor} !important`
  },
  '&.ant-picker-disabled': {
    cursor: 'default'
  }
}))

type PickerProps = {
  key?: string
  label?: string
  disabled?: boolean
  required?: boolean
  value?: string
  onChange: any
  disableFuture?: boolean
  disableTextField?: boolean
  dropdownClassName?: string
  textFiledComponent?: any
  helperText?: any
  scopeTimeStart?: string
  scopeTimeEnd?: string
  suffixIcon?: any
  inputTextAlign?: 'left' | 'center' | 'right'
  open?: boolean
  onOpenChange?: (status: boolean) => void
  inputText?: boolean
  inputOnfocus?: () => void
  inputOnblur?: () => void
}

export default function InputTimePicker(props: PickerProps) {
  const [timeStart, setTimeStart] = useState<string>('00:00')
  const [timeEnd, setTimeEnd] = useState<string>('00:00')

  const [timeStartHour, setTimeStartHour] = useState<string>('00')
  const [timeEndHour, setTimeEndHour] = useState<string>('00')

  const [showNowTime, setShowNowTimer] = useState<boolean>(false)

  const getDisabledHours = () => {
    if (props.scopeTimeStart) {
      const minHours = []
      const maxHours = []

      for (let i = 0; i < moment(timeStart, 'HH:mm').hour(); i++) {
        minHours.push(i)
      }

      if (props.scopeTimeEnd) {
        for (let j = 23; j > moment(timeEnd, 'HH:mm').hour(); j--) {
          maxHours.push(j)
        }
      }

      return [...minHours, ...maxHours]
    } else return []
  }

  const getDisabledMinutes = (selectedHour: number) => {
    if (props.scopeTimeEnd) {
      const minMinutes = []

      if (selectedHour === moment(timeStart, 'HH:mm').hour()) {
        for (let i = 0; i < moment(timeStart, 'HH:mm').minute(); i++) {
          minMinutes.push(i)
        }
      } else if (selectedHour === moment(timeEnd, 'HH:mm').hour()) {
        for (let j = 59; j > moment(timeEnd, 'HH:mm').minute(); j--) {
          minMinutes.push(j)
        }
      }

      return [...minMinutes]
    } else return []
  }

  useEffect(() => {
    if (props.scopeTimeStart) {
      setTimeStart(props.scopeTimeStart)
      setTimeStartHour(props.scopeTimeStart.slice(0, 2))
    }
    if (props.scopeTimeEnd) {
      setTimeEnd(props.scopeTimeEnd)
      setTimeEndHour(props.scopeTimeEnd.slice(0, 2))
    }

  }, [props.scopeTimeStart, props.scopeTimeEnd])

  const handleTimeInput_1 = (time_1: any) => {

    if (Number(time_1) > Number(timeEndHour) && time_1.length > 1) {
      return timeEndHour
    } else if (Number(time_1) < Number(timeStartHour) && time_1.length > 1) {
      return timeStartHour
    } else {
      if (time_1.length > 2) {
        const text2 = time_1[1] ? time_1[1] : ''
        return `${time_1[0]}${text2}`
      } else {
        return time_1
      }
    }
  }

  const handleTimeInput_2 = (time_2: any, time_1: any) => {

    if (Number(time_2) > 55) {
      return "55"
    } else {
      if (Number(time_1) === Number(timeEndHour) && time_2.length === 2 && Number(time_2) !== 0) {
        return "00"
      }
      if (time_2.length >= 2) {
        const text2 = time_2[1] ? time_2[1] : ''
        const total = Math.round(Number(`${time_2[0]}${text2}`) / 5)
        if (String(total * 5).length === 1) {
          return `0${String(total * 5)}`
        }
        return total * 5
      } else {
        return time_2
      }
    }
  }

  const handleTimeInputTotal = (time: any) => {
    if (time && time.length > 2 && time.includes(':')) {
      const dataTime = time.split(':')
      const newTime_1 = handleTimeInput_1(dataTime[0])
      const newTime_2 = handleTimeInput_2(dataTime[1] || '', dataTime[0])
      const textAll = `${newTime_1}:${newTime_2}`
      return textAll
    } else {
      return time
    }
  }

  const handleTimeInput = (time: any) => {
    if (time && time.length === 2) {
      if (Number(time) > Number(timeEndHour)) {
        return `${timeEndHour}:`
      } else if (Number(time) < Number(timeStartHour)) {
        return `${timeStartHour}:`
      } else if (!time.includes(':')) {
        return `${time}:`
      } else {
        return time
      }
    } else {
      return handleTimeInputTotal(time)
    }
  }

  const handleOnBlurInput = (time: any, index: number) => {
    if (index === 1) {
      return handleTimeInput_1(`0${time}`)
    } else {
      return handleTimeInput_2(`0${time}`, 0)
    }

  }

  const handleOnBlur = (time: any) => {
    if (time && time.length > 2 && time.includes(':')) {
      const dataTime = time.split(':')
      if (dataTime.length === 2 && !dataTime.includes('')) {
        const newTime_1 = dataTime[0].length === 1 ? handleOnBlurInput(dataTime[0], 1) : dataTime[0]
        const newTime_2 = dataTime[1].length === 1 ? handleOnBlurInput(dataTime[1], 2) : dataTime[1]
        const textAll = `${newTime_1}:${newTime_2}`
        return textAll
      } else {
        return ''
      }
    } else {
      return ''
    }

  }

  return (
    <ContentWrap className={`${!props.disableTextField ? 'has-text-field' : ''}`}>
      {!props.disableTextField &&
        (props.textFiledComponent || (
          <InputTextField
            style={props.inputText && { zIndex: '2' }}
            inputTextAlign={props.inputTextAlign}
            required={props?.required || false}
            disabled={props.disabled}
            key={props.key}
            name={props.key}
            label={props.label}
            value={props.value ? props.value : ''}
            helperText={props.helperText}
            onfocus={() => {
              setShowNowTimer(true)
              if (props.inputOnfocus) {
                props.inputOnfocus()
              }
            }}
            onblur={() => {
              setShowNowTimer(false)
              const data = {
                target: {
                  value: handleOnBlur(props.value)
                }
              }
              props.onChange(data)
              if (props.inputOnblur) {
                props.inputOnblur()
              }
            }}
            onchange={(e) => {
              if (props.inputText) {
                const inputValue = e.target.value;
                const numericValue = inputValue.replace(/[^0-9:]/g, '');
                if (numericValue.length <= 2 && !numericValue.includes(':') && props.value && props.value.includes(':')) {
                  const data = {
                    target: {
                      value: numericValue
                    }
                  }
                  props.onChange(data)
                } else {
                  const data = {
                    target: {
                      value: handleTimeInput(numericValue)
                    }
                  }
                  props.onChange(data)
                }
              }
            }}
            inputProps={props.inputText && {
              endAdornment: (
                props.suffixIcon ||
                <AccessTimeIcon
                  style={{
                    color: props.disabled ? colors.disabledGray : colors.themeSecondColor,
                    width: 24, height: 24
                  }}
                />
              ),
            }}
          />
        ))}
      <ConfigProvider locale={localeProvider}>
        <CustomTimePicker
          open={props.inputText ? showNowTime : props.open}
          popupClassName="custom-time-picker input-time-picker"
          locale={locale}
          dropdownClassName={`custom-time-picker ${props.dropdownClassName}`}
          showNow={false}
          disabled={props.disabled}
          suffixIcon={props.suffixIcon || <AccessTimeIcon style={{ color: props.disabled ? colors.disabledGray : colors.themeSecondColor, width: 24, height: 24 }} />}
          format="HH:mm"
          value={props.value ? moment(props.value, 'HH:mm') : null}
          bordered={false}
          allowClear={false}
          onChange={props.onChange}
          onSelect={props.onChange}
          disabledHours={getDisabledHours}
          disabledMinutes={getDisabledMinutes}
          minuteStep={5}
          onOpenChange={props.onOpenChange}
        />
      </ConfigProvider>
    </ContentWrap>
  )
}
