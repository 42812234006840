import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Image, Row } from 'react-bootstrap';
import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody, Divider, Box, MenuItem, Paper } from '@mui/material'
import _ from 'lodash'
import InputTextField from 'component/Input/InputTextField'
import BasicSelect from 'component/Select/BasicSelect'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/setting/personnel-managements/personnels/style'

/** API */
import PositionApi from 'api/setting/personnel-managements/position.api'
import RolesApi from 'api/setting/personnel-managements/role.api'

/** UTILS || SLICE */
import { selectLoadingSalary, selectUserSalary, editSalary } from 'app/slice/user.slice';

/** CONSTANT */
import { colors } from 'constants/theme'
import BanksApi from 'api/setting/finances/bank.api'
import { onImgError } from 'constants/images'
import { fileUrl } from 'api/api'

const freeCreditCard = [0, 1.25, 1.375, 1.5, 2.4, 2.5, 3]

interface SelectBank {
  bankId: number
  bankName?: string
  bankAccountNumber: string
  bankAccountName: string
  src?: string
}
interface SalarynPositionProps {
  isEdit: boolean
  errorMessage?: any
  user?: any
  resetErrorMessage?: (type: string) => void
}
export default function SalarynPosition(props: SalarynPositionProps) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const userSalary = useSelector(selectUserSalary)
  const loadingsalary = useSelector(selectLoadingSalary)
  const [positions, setPositions] = useState([])
  const [roles, setRoles] = useState<any[]>([])
  const [banks, setBanks] = useState<any[]>([])
  const [tableBranceh, setTableBranchs] = useState<any[]>([])
  const [certificate, setCertificate] = useState('')
  const [creditCardFee, setCreditCardFee] = useState<number>(0)
  const [bankAccountNumber, setBankAccountNumber] = useState('')
  const [bankAccountName, setBankAccountName] = useState('')
  const [isInsure, setIsInsure] = useState('')
  const [positionId, setPositionId] = useState<number>(0)
  const [roleId, setRoleId] = useState<number>(0)
  const [salary, setSalary] = useState<number>(0)
  const [selectBank, setSelectBank] = useState<SelectBank | null>(null)
  const classes = useStyles()
  const costConditions = [
    { label: t('PERSONNEL.SALARY.STATUS.HAVE'), value: true, labelId: 1 },
    { label: t('PERSONNEL.SALARY.STATUS.NO_HAVE'), value: false, labelId: 2 }
  ]
  const loadDataPosition = useCallback(async () => {

    const positionsApi = await PositionApi.findAll({ pageLimit: 999, status: 'ACTIVE' })
    if (positionsApi.status === 200) {
      setPositions(positionsApi.data)
    }
  }, [])
  const loadDataBank = async () => {
    await BanksApi.findAll({ pageLimit: 999, status: 'ACTIVE' }).then(({ data }) => data && setBanks(data))
  }

  const loadDataRole = useCallback(async () => {

    const rolesApi = await RolesApi.findAll({ pageLimit: 999, status: 'ACTIVE' })

    if (rolesApi.status === 200) {
      setRoles(rolesApi.data)
    }
  }, [])

  const loadTableBranchsAndService = useCallback(async (valueRoleId: number) => {
    if (valueRoleId) {
      const res: any = await RolesApi.findById(valueRoleId)
      if (res.status === 200) {
        const objArr: any = _.map(_.groupBy(res.data?.roleBranches, 'branchId'), (r: any) => {
          return {
            branch: r[0]?.branches?.branchName,
            servicePoints: _.map(r, (s: any) => ({ servicePoint: s?.servicePoints?.servicePointName }))
          }
        })

        setTableBranchs(objArr)
      }
    }
  }, [roleId])

  useEffect(() => { init() }, [loadDataPosition, loadingsalary, positionId, roleId])
  const init = async () => {
    loadDataPosition()
    await loadDataBank()
    loadDataRole()
    if (!loadingsalary) {
      setBankAccountName(userSalary.bankAccountName || '')
      setBankAccountNumber(userSalary.bankAccountNumber || '')
      setCertificate(userSalary.certificate || '')
      setCreditCardFee(userSalary.creditCardFee || '')
      setIsInsure(userSalary.isInsure || '')
      setPositionId(userSalary.positionId || 0)
      setRoleId(userSalary.roleId || 0)
      loadTableBranchsAndService(userSalary.roleId || 0)
      setSalary(userSalary.salary || 0)
      const bankOptions = await BanksApi.findAll({ pageLimit: 999, status: 'ACTIVE' }).then(({ data }) => data)

      const bankObj = bankOptions.find((obj: any) => obj.bankId === userSalary.bankId)
      let bankSrc: any = ''
      if (bankObj) {
        const { image } = bankObj
        bankSrc = `${fileUrl}/${image?.filePath}/${image?.fileName}`

      }
      const tempSelectBank: SelectBank = {
        bankId: userSalary.bankId || 0,
        bankName: bankObj?.bankName || '',
        bankAccountNumber: userSalary.bankAccountNumber || '',
        bankAccountName: userSalary.bankAccountName || '',
        src: bankSrc
      }

      setSelectBank(tempSelectBank)
    }
  }

  return (
    <div className="p-3">
      <div className={classes.titleFirst}>{t('PERSONNEL.SALARY.POSITION_ROLE')}</div>
      <Row>
        <Col sm={3} className="pt-3 px-2">
          <BasicSelect
            required
            onchange={(event) => {
              const value = event.target.value
              if (value) {
                if (Number(value) !== 2) {
                  setCertificate('')
                  dispatch(editSalary({ value: '', key: 'certificate' }))
                }
                setPositionId(value)
                dispatch(editSalary({ value: value, key: 'positionId' }))
              }
              if (props.resetErrorMessage) props.resetErrorMessage('salary')
            }}
            label={t('PERSONNEL.SALARY.POSITION_NAME')}
            selectId="position"
            value={positionId || ''}
            labelId="position"
            options={positions.map((option: any, i: number) => (
              <MenuItem key={i} value={option.positionId}>
                {option.positionName || ''}
              </MenuItem>
            ))}
            helperText={props.errorMessage.POSITION}
          />
        </Col>
        <Col sm={3} className="pt-3 px-2">
          <InputTextField
            required
            label={t('PERSONNEL.SALARY.PROFESSIONAL_LICENSE_NUMBER')}
            disabled={!positionId || Number(positionId) !== 2}
            value={certificate}
            inputProps={{ maxLength: 20 }}
            onchange={(e) => {
              setCertificate(e.target.value)
              dispatch(editSalary({ value: e.target.value, key: 'certificate' }))
              if (props.resetErrorMessage) props.resetErrorMessage('salary')
            }}
            helperText={props.errorMessage.CERTIFICATE}
          />
        </Col>
        <Col sm={3} className="pt-3 px-2">
          <BasicSelect
            required
            onchange={(event) => {
              const value = event.target.value
              if (value) {
                setRoleId(value)
                dispatch(editSalary({ value: value, key: 'roleId' }))
                loadTableBranchsAndService(value)
                if (props.resetErrorMessage) props.resetErrorMessage('salary')
              }
            }}
            label={t('PERSONNEL.SALARY.ROLE_NAME')}
            selectId="role"
            value={roleId || ''}
            labelId="role"
            options={roles.map((option: any, i: number) => (
              <MenuItem key={i} value={option.roleId}>
                {option.roleName || ''}
              </MenuItem>
            ))}
            helperText={props.errorMessage.ROLE}
          />
        </Col>
      </Row>

      <Divider className="mx-0 mt-4 mb-3" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

      <Col sm={12} className={`${classes.titleFirst} mt-2`}>
        {t('PERSONNEL.SALARY.BRANCH_SERVICE')}
      </Col>
      <Row className="">
        {tableBranceh.length !== 0 ? (
          <Col sm={12} className={`${classes.titleSecond}`}>
            <TableContainer component={Paper} className={`mt-3`} style={{ boxShadow: 'none', border: `1px solid ${colors.lightGray}` }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={`${classes.titleFirst}`}>{t('PERSONNEL.SALARY.BRANCH')}</TableCell>
                    <TableCell className={`${classes.titleFirst}`}>{t('PERSONNEL.SALARY.SERVICE')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableBranceh.map((branch: any, i: number) => (
                    <TableRow key={i}>
                      <TableCell ><span className="fz-16">{i + 1}.{branch?.branch || ''}</span></TableCell>
                      <TableCell ><span className="fz-16">{branch?.servicePoints && branch.servicePoints.map((service: any) => service?.servicePoint || '').join(', ')}</span></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Col>
        ) : (
          <Col sm={12} className={`${classes.titleSecond} pt-3 px-2 pl-4`}>
            {t('PERSONNEL.SALARY.NO_DATA')}
          </Col>
        )}
      </Row>

      <Divider className="mx-0 mt-4 mb-2" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

      <Row>
        <Col sm={12} className={classes.titleFirst}>
          {t('PERSONNEL.SALARY.COMPENSATION')}
        </Col>
        <Col sm={3} className="pt-3 px-2">
          <InputTextField
            label={t('PERSONNEL.SALARY.SALARY')}
            value={salary}
            onchange={(e) => {
              const val: any = e.target.value.replace(/[^0-9.]/g, '')
              dispatch(editSalary({ value: val, key: 'salary' }))
              setSalary(val)
            }}
          />
        </Col>
        <Col sm={3} className="pt-3 px-2">
          <BasicSelect
            onchange={(event) => {
              const value = event?.target?.value || false
              dispatch(editSalary({ value: `${value ? '1' : '0'}`, key: 'isInsure' }))
              setIsInsure(`${value ? '1' : '0'}`)
            }}
            label={t('PERSONNEL.SALARY.INSURANCE')}
            selectId="cost-condition"
            value={isInsure === '1' ? true : false}
            labelId="cost-condition"
            // disabled={props?.user?.ownSchedule > 0 && isInsure === '1' ? true : false}
            options={costConditions.map(({ label, value, labelId }: any) => (
              <MenuItem key={labelId} value={value}>
                {label}
              </MenuItem>
            ))}
          />
        </Col>
        <Col sm={3} className="pt-3 px-2">
          <BasicSelect
            onchange={(event) => {
              const value = event.target.value
              dispatch(editSalary({ value: value, key: 'creditCardFee' }))
              dispatch(editSalary({ value: 'PERCENT', key: 'creditCardFeeType' }))
              setCreditCardFee(value)
            }}
            label={t('PERSONNEL.SALARY.CREDIT_CART')}
            selectId="free-creditcard"
            value={creditCardFee}
            labelId="free-creditcard"
            options={freeCreditCard.map((free: any, i: number) => (
              <MenuItem key={i} value={free}>
                {`${free} %`}
              </MenuItem>
            ))}
          />
        </Col>
      </Row>

      <Divider className="mx-0 mt-4 mb-3" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

      <Row>
        <Col sm={12} className={classes.titleFirst}>
          {t('PERSONNEL.SALARY.BANK')}
        </Col>
        <Col sm={12} className="pt-3 px-2">
          <Box className="pl-xl-3">
            {banks.map((bank: any, i: number) => {
              const { bankId, bankName, image } = bank
              const bankSrc: any = `${fileUrl}/${image?.filePath}/${image?.fileName}`
              return (
                <Image
                  className={`${classes.bank} mr-2 mb-2 p-1 ${selectBank?.bankId === bankId ? 'btn-bank active' : ''}`}
                  key={i}
                  src={`${bankSrc}`}
                  onError={onImgError}
                  onClick={() => {
                    const tempBank = { bankId, bankAccountNumber: '', bankAccountName: '', bankName, src: bankSrc }
                    dispatch(editSalary({ value: parseInt(tempBank.bankId), key: 'bankId' }))
                    dispatch(editSalary({ value: tempBank.bankAccountName, key: 'bankAccountName' }))
                    dispatch(editSalary({ value: tempBank.bankAccountNumber, key: 'bankAccountNumber' }))
                    setBankAccountName(tempBank.bankAccountName)
                    setBankAccountNumber(tempBank.bankAccountNumber)
                    if (selectBank?.bankId === bankId) setSelectBank(null)
                    else setSelectBank(tempBank)
                  }}
                />
              )
            })}
            {selectBank && (
              <Row sm={12} style={{ alignItems: 'center' }}>
                <Col xs="auto" className="pt-3 px-2">
                  <Image className={`${classes.bank} mr-2 p-1`} src={`${selectBank?.src}`} onError={onImgError} />
                  {selectBank.bankName}
                </Col>
                <Col sm={4} className="pt-3 px-2">
                  <InputTextField
                    label={t('PERSONNEL.SALARY.ACCOUNT_NAME')}
                    inputProps={{ maxLength: 20 }}
                    value={bankAccountName}
                    onchange={(e) => {
                      setBankAccountName(e.target.value)
                      dispatch(editSalary({ value: e.target.value, key: 'bankAccountName' }))
                    }}
                  />
                </Col>
                <Col sm={4} className="pt-3 px-2">
                  <InputTextField
                    label={t('PERSONNEL.SALARY.ACCOUNT_NUMBER')}
                    inputProps={{ maxLength: 20 }}
                    value={bankAccountNumber}
                    onchange={(e) => {
                      setBankAccountNumber(e.target.value)
                      dispatch(editSalary({ value: e.target.value, key: 'bankAccountNumber' }))
                    }}
                  />
                </Col>
              </Row>
            )}
          </Box>
        </Col>
      </Row>
    </div>
  );
}
