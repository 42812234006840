import React, { useEffect, useState } from 'react'
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ButtonCustom from 'component/Button/ButtonCustom';
import BtnDelFilled from 'assets/button/BtnDelFilled';
import BtnEdit from 'assets/button/BtnEdit';
import ManageWebApi from 'api/application/manageWeb';
import { notiError, notiSuccess } from 'component/notifications/notifications';
import { ContainerEditor } from '../useStyles';
import { AboutUsProps } from 'api/application/manageWeb-interface';
import { swalActive, swalDelete } from 'component/Alert/Swal';
import IconColorPicker from 'assets/icon/editor/icon-color-picker.svg';
import { ManageWebEditor } from '../ManageWeb';
import { colors } from 'constants/theme';

const AboutUs = () => {
    const { t } = useTranslation()
    const t_ABOUT_US = "MANAGE_WEB.ABOUT_US"

    const [load, setLoad] = useState<boolean>(true)

    const [editorState, setEditorState] = useState<any>(EditorState.createEmpty())
    const [contentWeb, setContentWeb] = useState<any>({})
    const [isEdit, setIsEdit] = useState<boolean>(false)

    const [contentLength, setContentLength] = useState<number>(0)
    const [errorMaxLength, setErrorMaxLength] = useState<boolean>(false)

    const loadData = async () => {
        setLoad(true)
        await ManageWebApi.getAboutUs().then((res) => {
            setContentWeb(res?.data || {})
        }).catch(() => {
            return
        }).finally(() => {
            setLoad(false)
        })
    }

    useEffect(() => {
        loadData()
    }, [])

    const onEdit = (status: boolean) => {
        setIsEdit(status)
        if (status && contentWeb?.content) {
            const newHTML: any = contentWeb?.content
            const blocksFromHTML = htmlToDraft(newHTML);
            const content = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            setEditorState(EditorState.createWithContent(content))
            const currentContent = EditorState.createWithContent(content)?.getCurrentContent();
            const currentContentLength = currentContent.getPlainText('')?.length;
            if (currentContentLength > 1000) {
                setErrorMaxLength(true)
            }
        }
        else {
            setEditorState(EditorState.createEmpty())
        }
    }

    const onDel = async () => {
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t(t_ABOUT_US + '.NOTI_DEL')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 306px">${t(t_ABOUT_US + '.NOTI_DEL')}</p>`,
            async (res: any) => {
                if (res) {
                    const resDel = await ManageWebApi.delAboutUs(contentWeb.aboutUsMinimalId)
                    if (resDel === "pass") {
                        loadData()
                        notiSuccess(t(`${t_ABOUT_US}.DEL_SUC`))
                    } else {
                        notiError(t(`${t_ABOUT_US}.SAVE_ERROR`))
                        return
                    }

                }
            }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
        )
    }

    const onSave = async () => {
        const newPayload: AboutUsProps = {
            content: draftToHtml(convertToRaw(editorState?.getCurrentContent())) || ''
        }
        if (contentWeb?.aboutUsMinimalId) {
            newPayload.aboutUsMinimalId = contentWeb.aboutUsMinimalId
        }

        const res = await ManageWebApi.saveAboutUs(newPayload)
        if (res === "pass") {
            onEdit(false)
            loadData()
            notiSuccess(t(`${t_ABOUT_US}.SAVE_SUC`))
        } else {
            notiError(t(`${t_ABOUT_US}.SAVE_ERROR`))
        }

    }

    const handleEditor = (editorState: EditorState) => {
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length;

        if (currentContentLength > 1000) {
            setErrorMaxLength(true)
        } else {
            setEditorState(editorState)
            setContentLength(currentContentLength)
            setErrorMaxLength(false)
        }
    }

    return (
        <>
            {!load &&
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ fontSize: '20px', fontWeight: "600" }}>
                            {t(`${t_ABOUT_US}.TITLE`)}
                        </Box>
                        {!contentWeb?.content && !isEdit &&
                            <Box>
                                <ButtonCustom
                                    textButton={t(`${t_ABOUT_US}.BTN_CREATE`)}
                                    onClick={() => { onEdit(true) }}
                                />
                            </Box>
                            || !isEdit &&
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ButtonCustom
                                    startIcon={<BtnEdit />}
                                    style={{ marginRight: '4px' }}
                                    variant={'outlined'}
                                    textButton={t(`${t_ABOUT_US}.BTN_EDIT`)}
                                    onClick={() => { onEdit(true) }}
                                />
                                <ButtonCustom
                                    startIcon={<BtnDelFilled color='#fff' />}
                                    mode={'del'}
                                    textButton={t(`${t_ABOUT_US}.BTN_DEL`)}
                                    onClick={() => { onDel() }}
                                />
                            </Box>
                            ||
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ButtonCustom
                                    style={{ marginRight: '4px' }}
                                    variant={'outlined'}
                                    textButton={t(`${t_ABOUT_US}.BTN_CANCEL`)}
                                    onClick={() => { onEdit(false) }}
                                />
                                <ButtonCustom
                                    disabled={errorMaxLength}
                                    textButton={t(`${t_ABOUT_US}.BTN_SAVE`)}
                                    onClick={() => { onSave() }}
                                />
                            </Box>
                        }
                    </Box>


                    {!isEdit &&
                        <Box sx={{ marginTop: '32px', height: '100%', overflow: 'auto' }}>
                            <div dangerouslySetInnerHTML={{ __html: contentWeb?.content }} />
                        </Box>
                        ||
                        <ContainerEditor>
                            <Box
                                sx={{
                                    color: errorMaxLength ? colors.statusInactive : 'black',
                                    height: '24px',
                                    padding: '0px 0px 0px 16px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Box>
                                    {errorMaxLength && t(`${t_ABOUT_US}.MAX_LENGTH`)}
                                </Box>
                                <Box>
                                    {`${contentLength}/1000`}
                                </Box>
                            </Box>
                            <ManageWebEditor
                                editorState={editorState}
                                setEditorState={handleEditor}
                            />
                        </ContainerEditor>
                    }
                </Box>
            }

        </>
    )
}

export default AboutUs