
export default function ApCardTeeth(props: { stroke?: string, fill?: string }) {
    return (
        <svg xmlns="https://www.w3.org/2000/svg" width="15.061" height="15.586" viewBox="0 0 15.061 15.586">
            <g data-name="Component 13 – 40">
                <path data-name="Path 5701" d="M247.064 157.681a8.615 8.615 0 0 0 1.492-4.99c-.045-5.31-5-5.559-6.411-4.02-.241.262-.67.368-.873.076-1-1.441-6.321-1.152-6.277 4.06a8.609 8.609 0 0 0 1.577 4.961.569.569 0 0 1 .088.468c-.355 1.449-1.071 3.631.982 3.613 2.2-.019 1.872-2.466 4.189-2.486 2.152-.018 2.266 2.362 4.091 2.346 1.712-.015 1.375-2.156 1.052-3.575a.57.57 0 0 1 .09-.453z" transform="translate(-234.245 -147.013)" style={{ fill: props.fill || '#fff', stroke: props.stroke || '#2f4269', strokeMiterlimit: 10, strokeWidth: '1.5px' }} />
            </g>
        </svg>
    )
}
