import { useEffect, useState } from 'react'
import { Grid, MenuItem, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

/** API */

/** COMPONENT */

/** STYLE */
import BasicSelect from 'component/Select/BasicSelect'
import { Col, Row } from 'react-bootstrap'
import { colors } from 'constants/theme';

export interface ApplicationInterface {
  package: "STANDARD" | "PREMIUM" | "ULTRA"
  enableApp: string
  branchDefaultRegisterApps: any[]
  callbackEnableApp: (value: any) => void
  callbackRegisterApps: (value: any) => void
}
export default function FormApplication(props: ApplicationInterface) {
  const { t } = useTranslation()
  const [enableApp, setEnableApp]: any = useState(props.enableApp);
  const [usePackage, setPackage] = useState(props.package);
  const [branchDefaultRegisterApps, setBranchDefaultRegisterApps] = useState(props.branchDefaultRegisterApps);

  useEffect(() => {
    setEnableApp(props.enableApp)
    setPackage(props.package)
    setBranchDefaultRegisterApps(props.branchDefaultRegisterApps)
  }, [props])

  return (
    <div className="px-4 pt-4 pb-4">
      {/* <UseStyles.TitleFirst className={`d-flex align-items-center`}>{t('CLINIC_MANAGEMENT.TITLE.APPLICATION')}</UseStyles.TitleFirst> */}
      <Grid container spacing={2}>
        {
          usePackage !== 'ULTRA' &&
          <Grid item xs={12} sm={6} md={4} xl={3}>
            <BasicSelect
              name="add_on_app"
              label={t('CLINIC_MANAGEMENT.LABEL.APPLICATION')}
              selectId="sl-add-on-app"
              labelId="lb-add-on-app"
              value={enableApp === '0' ? 'FALSE' : 'TRUE'}
              style={{ fontSize: '16px' }}
              options={[
                <MenuItem key="1" value="FALSE">
                  {t('CLINIC_MANAGEMENT.LABEL.CLOSE')}
                </MenuItem>,
                <MenuItem key="2" value="TRUE">
                  {t('CLINIC_MANAGEMENT.LABEL.OPEN')}
                </MenuItem>
              ]}
              onchange={(e) => {
                props.callbackEnableApp(e.target.value === "TRUE" ? "1" : "0")
              }}
            />
          </Grid>}
      </Grid>
      {usePackage !== 'ULTRA' && <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />}
      <Row>
        <Col sm={3} className="pl-4" style={{ fontWeight: 500 }}>
          {t('CLINIC_MANAGEMENT.FORM.NAME_BR')}
        </Col>
        <Col sm={3} style={{ fontWeight: 500 }}>{t('CLINIC_MANAGEMENT.FORM.AUTO_REGIS_APP')}</Col>
      </Row>
      {branchDefaultRegisterApps?.map((val: any, index: any) => {
        return (
          <Row key={index}>
            <Col sm={3} className="pt-4 pl-4">
              {val.branchName}
            </Col>
            <Col sm={4} className="pt-4">
              <BasicSelect
                disabled={enableApp === '1' ? false : true}
                name={`auto_regis_${index}`}
                label=""
                selectId={`sl-auto-regis-${index}`}
                labelId={`lb-auto-regis-${index}`}
                value={val.isDefaultRegisApp === '0' ? 'FALSE' : 'TRUE'}
                style={{ fontSize: '16px' }}
                options={[
                  <MenuItem key="1" value="FALSE">
                    {t('CLINIC_MANAGEMENT.LABEL.CLOSE')}
                  </MenuItem>,
                  <MenuItem key="2" value="TRUE">
                    {t('CLINIC_MANAGEMENT.LABEL.OPEN')}
                  </MenuItem>
                ]}
                onchange={(e) => {
                  val.isDefaultRegisApp = e.target.value === "TRUE" ? "1" : "0"
                  props.callbackRegisterApps([...branchDefaultRegisterApps])
                }}
              />
            </Col>
          </Row>
        )
      })}
    </div>
  )
}
