import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface TemplatTextInterface {
  useWith: string
  textType: string
  message: string
  status: string
}

export default class TemplateTextApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('template-text', { params: { ...props } }).then((res) => res)
  }

  static create(payload: TemplatTextInterface) {
    return this.api.post('template-text', payload)
  }

  static findById(id: number): Promise<TemplatTextInterface> {
    return this.api.get(`template-text/${id}`)
  }

  static update(id: number, body: TemplatTextInterface) {
    return this.api.patch(`template-text/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`template-text/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`template-text/${id}`)
  }
}
