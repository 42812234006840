import { colors } from 'constants/theme'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import iconEmpty from 'assets/icon/editor/icon-empty.svg'

export const CardManageWeb = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '188px',
    border: '1px solid #F3F1F1',
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
    backgroundColor: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    position: 'relative',
    '.content-card': {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        '.container-img': {
            width: '140px',
            height: '140px',
            borderRadius: '30px',
            boxShadow: '1px 1px 6px 0px rgba(0, 0, 0, 0.20)',
            overflow: 'hidden',
            'img': {
                width: '100%', height: '100%', objectFit: 'cover'
            }
        },
        '.container-des': {
            position: 'relative',
            marginLeft: '12px',
            height: '100%',
            width: 'calc(100% - 140px)',
            paddingRight: '8px',
            '.content-des-text': {
                marginTop: '8px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'left',
                display: '-webkit-box',
                WebkitLineClamp: 5,
                WebkitBoxOrient: 'vertical',
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
                hyphens: 'auto',
            }
        }
    }
}))

export const ContainerEditor = styled(Box)(({ theme }) => ({
    marginTop: '16px',
    height: '100%',
    overflow: 'hidden',
    '.demo-wrapper': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    '.demo-editor': {
        borderLeft: '1px solid #F1F1F1',
        borderRight: '1px solid #F1F1F1',
        borderBottom: '1px solid #F1F1F1',
        padding: '0 8px',
        height: 'fit-content'
    },
    '.rdw-editor-toolbar': {
        margin: '0'
    },
    '.rdw-dropdown-selectedtext': {
        color: 'black',
        border: 'none'
    },
    '.rdw-dropdown-selectedtext:hover': {
        color: 'black',
    },
    '.rdw-colorpicker-option:last-child': {
        boxShadow: "1px 2px 1px transparent inset",
        backgroundImage: `url(${iconEmpty})`,
        backgroundPosition: 'center',
        backgroundSize: '90%',
        backgroundRepeat: 'no-repeat'
    },
    '.rdw-colorpicker-option:last-child:hover': {
        boxShadow: "1px 2px 1px #BFBDBD "
    },

}))

export const BoxStarSelect = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '12px',
    '.content-star-select': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50px',
        height: '45px',
        borderRadius: '3px',
        border: '1px solid #E5E5E5',
        cursor: 'pointer',
    }
}))