import { useState, useEffect, useCallback } from 'react'
import { Grid, Typography, Box, Avatar, styled } from '@mui/material'
import 'react-multi-carousel/lib/styles.css'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import TreatmentApi from 'api/dentists/treatment.api'

import { fileUrl } from 'api/api'

/** UTILS || SLICE */
import { resetModal } from 'app/slice/modal.slice'
import { memberTypeOutline } from 'utils/app.utils'

/** COMPONENT */
import * as UseStyled from 'features/treatments/MainMenu/Image/UseStyled'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import Loading from 'component/Loading'
import TableCustom from 'component/Table/TableCustom'
import { imgs } from 'constants/images'
import { colors } from 'constants/theme'
import { dateToView } from 'utils/date.utils'
import TableRowCommon from 'component/Table/TableRowCommon'
import { TreatmentStateInterface, setTreatmentByKey, treatment } from 'app/slice/treatment.slice'
import { swalWarning } from 'component/Alert/Swal'
import HelperText from 'component/Input/HelperText'
import ClinicApi from 'api/master/clinic.api'
import TreatmentProductApi from 'api/dentists/treatmentProduct.api'

const ProfileAvatar = styled(Box)(({ theme }) => ({
  maxWidth: 150,
  maxHeight: 150,
  '.ratio-1': {
    paddingTop: '100%',
    '.MuiAvatar-root': {
      left: 0
    }
  }
}))

type ModalCustomProps = {
  isShow: boolean | false
  setIsShow: (show: boolean) => void
  onClose?: () => void
  onSuccess?: () => void
  patientId: number
  branchId: number
  index?: any
  patientCaseId?: number
  createdAt?: string
  treatments?: any
  diagnosis?: any
  patient?: any
  user?: any
  type?: 'treatment' | 'historyTreatment'
  countMed: number
}

type Doctors = {
  userId: number
  firstname: string
  lastname: string
  fullname: string
}

export default function ReMed(props: ModalCustomProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const [loading, setLoading] = useState(false)
  /** FILTER */
  const [errorObj, setErrorObj] = useState<any>()

  const [doctors, setDoctors] = useState<any[]>([])
  const [reMedDate, setReMedDate] = useState<any>([])
  const [reMedItem, setReMedItem] = useState<any>([])
  const [orderActive, setOrderActive] = useState<any>('')
  const [isChecks, setIsChecks] = useState<any[]>([] as any)
  const [isCheckAll, setIsCheckAll] = useState<any>()
  const [itemChecks, setItemChecks] = useState<any[]>([] as any)

  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('date')
  const [sortType, setSortType] = useState('DESC')
  const [page, setPage] = useState(1)

  const [medicines, setMedicines] = useState([])

  const loadDoctor = async () => {
    const resp = await ClinicApi.findAllDoctors()
    if (resp.status === 200) setDoctors(resp.data)
  }

  const loadReMedDate = async () => {
    const res = await TreatmentApi.getReMedDate(props.patientId)
    if (res.status === 200) {
      setReMedDate(res.data)
    }
  }

  const loadMedicine = async () => {
    const condition: any = { page: 1, pageLimit: props.countMed, branchId: props.branchId, userId: props?.user || 0, group: 'MEDICINE' }
    const resp = await TreatmentProductApi.findAllProduct(condition)
    if (resp.status === 200) {
      setMedicines(resp.data)
    }
  }

  useEffect(() => {
    loadReMedDate()
    loadDoctor()
    loadMedicine()
  }, [])

  const onClickReMedDate = useCallback(
    async (e: number) => {
      if (orderActive !== e) {
        setErrorObj('')
      }
      setOrderActive(e)
      const res = await TreatmentApi.getReMedItem(e, {
        page: page,
        pageLimit: pageLimit
      })
      if (res.status === 200) {
        setReMedItem(res.data)
        setRowCount(res.headers['x-total'])
      }
    },
    [page, pageLimit, sortBy, sortType]
  )

  useEffect(() => {
    onClickReMedDate(orderActive)
  }, [onClickReMedDate])

  const headCells = [
    {
      id: 'treatment',
      disablePadding: false,
      label: 'CHECKBOX',
      indeterminate: isCheckAll === 'indeterminate',
      checked: isCheckAll === 'checked',
      disableDropdown: _.isEmpty(isChecks),
      onCheckAll: (event: any) => onCheckAll(event.target),
      values: [],
      width: '70px'
    },
    { id: 'dateAt', disablePadding: false, label: t('#'), class: 'text-nowrap' },
    { id: 'paymentNumber', disablePadding: false, label: t('RE_MED.TABLE.DATE'), class: 'text-nowrap' },
    { id: 'message', disablePadding: false, label: t('RE_MED.TABLE.TYPE'), class: 'text-nowrap' },
    { id: 'branchId', disablePadding: false, label: t('RE_MED.TABLE.SERVICE'), class: 'text-nowrap pr-1' },
    { id: 'patientFullName', disablePadding: false, label: t('RE_MED.TABLE.QTY'), class: 'text-nowrap' },
    { id: 'orderPaymentChannel', disablePadding: false, label: t('RE_MED.TABLE.DOCTOR'), class: 'text-nowrap' }
  ]
  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { createdAt, itemTypeName, itemName, qty, orderProductId, sticker, itemId, doctorId } = objData
    let stickerProductUseDetail = ''
    let stickerDosage = ''
    let stickerProductUnitName = ''
    let stickerProductInstructionName = ''
    if (itemName && sticker) {
      const stickerObj: any = JSON.parse(sticker)
      const { productUseDetail, dosage, productUnitName, productInstructionName } = stickerObj
      stickerProductUseDetail = productUseDetail || ''
      stickerDosage = dosage || ''
      stickerProductUnitName = productUnitName || ''
      stickerProductInstructionName = productInstructionName || ''
    }
    const rowCheck = _.some(isChecks, (i: number) => Number(i) === Number(orderProductId))
    const errorText = _.filter(errorObj, (i: any) => i.itemId === itemId)
    const errorCount = errorText[0]?.count
    const errorMessage = errorText[0]?.message
    let nameDoctor = '-'
    _.forEach(doctors, (item: any) => {
      if (item.userId === doctorId) {
        nameDoctor = item.fullname
      }
    })
    // const errorMessageCount = errorText[0].count
    const objRenderData = {
      key: orderProductId,
      id: orderProductId,
      obj: objData,
      rowSelect: rowCheck,
      columns: [
        { option: 'CHECKBOX', align: 'center', label: orderProductId },
        { option: 'TEXT', align: 'center', label: no, class: 'px-1 text-ellipsis' },
        { option: 'TEXT', align: 'center', label: `${dateToView(createdAt)}` || '-', class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', label: itemTypeName || '-', class: 'text-nowrap' },
        {
          option: 'TEXT',
          align: 'left',
          label: (
            <Typography>
              <Typography>{itemName}</Typography>
              {errorMessage === 'expire date' ? _.some(errorObj, (i: any) => i.itemId === itemId) && <HelperText label={`${t('RE_MED.TABLE.EXPIRE')}`}></HelperText> : _.some(errorObj, (i: any) => i.itemId === itemId) && <HelperText label={`${t('RE_MED.TABLE.LEFTOVER')} : ${errorCount}`}></HelperText>}
              {stickerProductInstructionName} {stickerDosage} {stickerProductUnitName} {stickerProductUseDetail}
            </Typography>
          ),
          class: 'text-nowrap'
        },
        { option: 'TEXT', align: 'left', label: qty || '-', class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', label: nameDoctor || '-', class: 'text-nowrap' }
      ]
    }

    return <TableRowCommon {...objRenderData} onCheck={(event) => onCheck(event.target)} />
  }

  const rederError = (objData: any) => {
    const array = _.map(objData, (i: any) => {
      if (i.message === 'expire date') return `<p>${t('RE_MED.MED')} ${i.itemTypeName} ${t('RE_MED.TABLE.EXPIRE')}</p>`
      if (i.count) return `<p>${t('RE_MED.MED')} ${i.itemTypeName} ${t('RE_MED.BALNCE')} : ${i.count}</p>`
    })
    const joinedString = array.join('')
    return joinedString
  }

  const onSave = async () => {
    setLoading(true)
    setErrorObj('')
    const items: any = []
    _.map(itemChecks, (i: any) => {
      if (_.some(items, (item) => item.itemId === i.itemId)) {
        _.map(items, (item: any) => {
          if (item.itemId === i.itemId) {
            item.qty += i.qty
          }
        })
      } else {
        items.push({
          itemId: i.itemId,
          orderProductId: i.orderProductId,
          qty: i.qty,
          dotorId: props.user
        })
      }
    })

    const save = await TreatmentApi.updateReMed(props.patientId, {
      items: items
    })
      .catch((e) => {
        const err = e.response.data
        setErrorObj(err.message)
        swalWarning(`${t('RE_MED.MED_NOT_ENOUGH')}`, rederError(err.message), '', {
          confirmButtonTheme: 'danger',
          confirmButtonText: t('RE_MED.CLOSE')
        })
      })
      .finally(() => setLoading(false))

    if (save?.status === 200) {
      const itemAdd: any = []
      _.map(itemChecks, (i: any) => {
        if (_.some(itemAdd, (item) => item.itemId === i.itemId)) {
          _.map(itemAdd, (item: any) => {
            if (item.itemId === i.itemId) {
              item.qty += i.qty
            }
          })
        } else {
          const newData: any = handleMedicines(i)
          const newMed = {
            ...i,
            image: newData?.image || {},
            stock: newData?.stock || "0",
            sticker: JSON.stringify(newData)
          }
          itemAdd.push(newMed)
        }
      })
      console.log(itemAdd)
      const hasItemInSummary = _.some(treatments.summeryTreatments, (sum, index) => _.some(items, (item) => item.itemId === sum.itemId))
      const value = [...treatments.summeryTreatments, ...itemAdd]
      console.log(value)

      if (!hasItemInSummary) dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: value }))
      else {
        const summaryTreatments = _.merge([], treatments.summeryTreatments)
        _.map(itemAdd, (i: any) => {
          if (!_.some(summaryTreatments, (item: any) => item.itemId === i.itemId)) {
            return summaryTreatments.push(i)
          } else {
            _.map(
              _.filter(summaryTreatments, (item: any) => item.itemId === i.itemId),
              (obj: any) => {
                obj.qty += i.qty
              }
            )
          }
        })

        dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: summaryTreatments }))
      }
      clearData()
      dispatch(resetModal())
      props.setIsShow(false)
      return props.onClose
    }
  }

  const handleMedicines = (data: any) => {
    console.log(medicines);
    console.log(data)
    const newData: any = medicines.filter((item: any) => {
      if (item.itemName === data.itemName && item.itemId === data.itemId) {
        return item
      }
    })
    const dataSticker = {
      ...JSON.parse(data.sticker),
      image: newData[0]?.image || {},
      stock: newData[0]?.qty || "0"

    }

    return dataSticker || []
  }

  const clearData = () => {
    setOrderActive('')
    setIsCheckAll('')
    setIsChecks([])
  }

  const onCheckAll = (event: any) => {
    const { checked } = event
    const id = _.map(reMedItem, (i: any) => {
      return i.orderProductId
    })
    const obj = _.map(reMedItem, (i: any) => {
      return i
    })
    if (checked) {
      setIsChecks(Array.from(new Set([...isChecks, ...id])))
      setItemChecks(Array.from(new Set([...itemChecks, ...obj])))
    } else {
      setIsChecks(_.filter(isChecks, (i) => !_.includes(id, i)))
      setItemChecks(_.filter(itemChecks, (i) => !_.includes(obj, i)))
    }
  }

  const onCheck = (event: any) => {
    const { checked, value } = event
    const obj = _.filter(reMedItem, (i: any) => {
      return i.orderProductId === Number(value)
    })
    if (checked) {
      setIsChecks([...isChecks, Number(value)])
      setItemChecks([...itemChecks, obj[0]])
    } else {
      setIsChecks(
        _.filter(isChecks, (i: number) => {
          return i !== Number(value)
        })
      )
      setItemChecks(
        _.filter(itemChecks, (i: any) => {
          return i.orderProductId !== obj[0].orderProductId
        })
      )
    }
  }

  const handleOnCheckAll = () => {
    const hasSome = _.some(reMedItem, (i: any) => _.some(isChecks, (j: number) => Number(i.orderProductId) === Number(j)))
    const hasAll = _.every(reMedItem, (i: any) => _.some(isChecks, (j: number) => Number(i.orderProductId) === Number(j)))
    if (!_.isEmpty(isChecks) && !hasAll && hasSome) setIsCheckAll('indeterminate')
    else if (!_.isEmpty(isChecks) && hasAll) setIsCheckAll('checked')
    else setIsCheckAll('')
  }

  useEffect(() => {
    handleOnCheckAll()
  }, [isChecks, reMedItem])

  return (
    <ModalCustom
      title={t('TREATMENTS.BUTTON.RE_MED')}
      alignFooter={'end'}
      size={'xl'}
      footerInline
      onClose={props.onClose}
      onSubmit={onSave}
      onReset={() => {
        dispatch(resetModal())
        props.setIsShow(false)
      }}
      textBtnCancel={t('BUTTON.CANCEL')}
      textBtnConfirm={t('BUTTON.SAVE')}
      modalStyle={'modal-referral-examination'}
      component={
        <>
          <Grid container className="pt-2">
            <Grid item xs={3} className="w-100">
              <ProfileAvatar sx={{ margin: 'auto' }}>
                <Box className="ratio-1 position-relative ">
                  <Avatar
                    src={`${fileUrl}/${props?.patient?.profile?.filePath}/${props?.patient?.profile?.fileName}`}
                    className="position-absolute top-0 w-100 h-100"
                    alt="profile"
                    sx={{ border: `3px solid ${memberTypeOutline(props.patient.rank)}`, backgroundColor: colors.white }}
                  >
                    <img src={imgs.defaultAvatar} className="w-100 h-100" alt="defaultAvatar" />
                  </Avatar>
                </Box>
              </ProfileAvatar>
              <Typography className="mr-3" sx={{ fontWeight: 500, justifyContent: 'center', display: 'flex' }}>{`${props.patient?.firstname} ${props.patient?.lastname}`}</Typography>
              <Typography className="mr-3" sx={{ color: 'gray', fontWeight: 16, borderBottom: '1px solid black', justifyContent: 'center', display: 'flex' }}>{`CL${props.patient.cnNumber}`}</Typography>
              <Typography className="mr-3" sx={{ fontWeight: 500, marginTop: '5px' }}>{`${t('RE_MED.DATE_LIST')} (${reMedDate.length})`}</Typography>
              <div className={'custom-scroll'} style={{ overflowY: 'auto', maxHeight: '350px' }}>
                {_.map(reMedDate, (obj: any) => {
                  return (
                    <UseStyled.Item key={obj.orderId} className={`${orderActive === obj.orderId ? 'active' : ''}`} onClick={() => onClickReMedDate(obj.orderId)}>
                      <span className={'text-ellipsis'}>{dateToView(obj.date)}</span>
                    </UseStyled.Item>
                  )
                })}
              </div>
            </Grid>
            <Grid item xs={9} className="w-100">
              <TableCustom
                page={page}
                pageLimit={pageLimit}
                sortType={sortType}
                sortBy={sortBy}
                rowCount={rowCount}
                onSort={(sortByVal: string, sortTypeVal: string) => {
                  setSortType(sortTypeVal)
                  setSortBy(sortByVal)
                }}
                setPageLimit={setPageLimit}
                setPage={setPage}
                headCells={headCells}
                rowsData={_.map(reMedItem, (val, i: number) => renderData(val, i))}
              />
            </Grid>
          </Grid>

          {loading && <Loading show={true} type="fullLoading" />}
        </>
      }
    />
  )
}
