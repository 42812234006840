import { numberFormat } from 'utils/app.utils';
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import _ from 'lodash'
import * as UseStyled from 'features/report/useStyled'
import { Box, TableHead } from '@mui/material';
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles'
import { colors } from 'constants/theme'


const useStyles = makeStyles((theme) => ({
    header: {
        borderRight: `1px solid ${colors.themeMainColor} !important`,
        borderLeft: `1px solid ${colors.themeMainColor} !important`,
        color: `${colors.white} !important`,
        '& $td': {
            backgroundColor: colors.themeMainColor,
            color: `${colors.white} !important`,
            padding: 15,
        }
    },
}))



interface DataItem {
    paid: number;
    cancelBill: number;
    overdue: number;
    cancelVisit: number;
}



interface FcComponent {
    page: number
    pageLimit: number
    rowCount: number
    sortBy: string
    sortType: string
    data: any
    onRequestSort: (sortBy: string, sortType: string) => void
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
}

export default function VisitTotal(props: FcComponent) {
    const { t } = useTranslation()


    const theme = useTheme()
    const classes = useStyles()
    const headCells = [
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.VISIT.APPOINTMENT_STATUS', align: 'center', class: 'text-nowrap' },
        { id: 'price', disablePadding: false, labelKey: t('REPORT.VISIT_STATUS.PAID'), align: 'right', class: 'text-nowrap' },
        { id: 'price', disablePadding: false, labelKey: t('REPORT.VISIT_STATUS.CANCEL_BILL'), align: 'right', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: t('REPORT.VISIT_STATUS.OVERDUE'), align: 'right', class: 'text-nowrap' },
        { id: 'price', disablePadding: false, labelKey: t('REPORT.VISIT_STATUS.CANCEL_VISIT'), align: 'right', class: 'text-nowrap' },
    ]

    const renderData = (data: any, no: number, dataType: "sum" | "total") => {
        const num = props.page * props.pageLimit - props.pageLimit + no + 1;
        const label = dataType === "sum" ? t('REPORT.UNIT.QTY') : t('REPORT.UNIT.QTY_PAYMENT');
        return (
            <>
                {/* แถว */}
                <TableRowCommon
                    key={num.toString() + "_count"}
                    id={no.toString() + "_count"}
                    obj={data}
                    columns={[
                        {
                            option: 'TEXT', label: label, style: {
                                color: colors.white,
                                backgroundColor: colors.themeMainColor,
                                border: '0px',
                                borderLeft: `1px solid ${colors.themeMainColor}`
                            }, align: 'center'
                        },
                        { option: 'TEXT', align: 'center', label: dataType === 'total' ? numberFormat(data.paid) || "-" : data.paid || "-" },
                        { option: 'TEXT', align: 'center', label: dataType === 'total' ? numberFormat(data.cancelBill) || "-" : data.cancelBill || "-" },
                        { option: 'TEXT', align: 'center', label: dataType === 'total' ? numberFormat(data.overdue) || "-" : data.overdue || "-" },
                        { option: 'TEXT', align: 'center', label: dataType === 'total' ? "-" : data.cancelVisit || "-" }
                    ]}
                />
            </>
        );
    }

    return (
        <>
            <Box sx={{ maxWidth: '640px' }}>
                <UseStyled.ResponsiveReportTable>
                    <TableCustom
                        page={props.page}
                        pageLimit={props.pageLimit}
                        sortType={props.sortType}
                        sortBy={props.sortBy}
                        rowCount={props.rowCount}
                        onSort={props.onRequestSort}
                        setPageLimit={props.handleChangeRowsPerPage}
                        setPage={props.handleChangePage}
                        headCells={headCells}
                        rowsData={[
                            ...(props.data?.sum ? [renderData(props.data.sum, 1, "sum")] : []),
                            ...(props.data?.total ? [renderData(props.data.total, 2, "total")] : [])
                        ]}

                        hidePagination={true}
                        tableMinWidth={640}
                        customScroll
                    />
                </UseStyled.ResponsiveReportTable>
            </Box>
        </>
    )
}
