
export default function IconRubber(props: any) {
  return (
    <svg width="28" height="29" viewBox="-5 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.9465 5.01289L24.7215 10.6964C25.6315 11.6053 25.6315 13.055 24.7215 13.9639L13.9999 24.5371C13.1233 25.399 11.936 25.883 10.6982 25.883C9.46038 25.883 8.27307 25.399 7.39654 24.5371L3.2782 20.4758C2.3682 19.5669 2.3682 18.1172 3.2782 17.2083L15.6449 5.01289C16.5665 4.11549 18.0365 4.11549 18.9465 5.01289ZM4.9232 18.8421L9.0532 22.9034C9.9632 23.8123 11.4332 23.8123 12.3549 22.9034L16.4732 18.8421L10.6982 13.147L4.9232 18.8421Z"
        fill="black"
      />
      <line x1="1.09278e-07" y1="27.6738" x2="29" y2="27.6738" stroke={props.color} strokeWidth="2.5" />
    </svg>
  )
}
