import { menuThirds } from 'constants/menus'
import TreatmentPlanTypes from 'features/setting/treatment-plans/treatment-plan-types/treatment-plan-types'
import TreatmentPlans from 'features/setting/treatment-plans/treatment-plans/treatment-plans'
import TreatmentPlansForm from 'features/setting/treatment-plans/treatment-plans/form-treatment-plans'

const routeTreatmentPlans = [
  {
    ...menuThirds.TREATMENT_PLAN_TYPE,
    component: TreatmentPlanTypes,
    appBar: true
  },
  {
    ...menuThirds.TREATMENT_PLAN.name,
    key: menuThirds.TREATMENT_PLAN.key,
    packageKey: menuThirds.TREATMENT_PLAN.packageKey,
    textname: `เพิ่ม ${menuThirds.TREATMENT_PLAN.name}`,
    active: false,
    path: menuThirds.TREATMENT_PLAN.path + '/create',
    component: TreatmentPlansForm,
    appBar: true
  },
  {
    ...menuThirds.TREATMENT_PLAN.name,
    key: menuThirds.TREATMENT_PLAN.key,
    packageKey: menuThirds.TREATMENT_PLAN.packageKey,
    textname: `แก้ไข ${menuThirds.TREATMENT_PLAN.name}`,
    active: false,
    path: menuThirds.TREATMENT_PLAN.path + '/update',
    component: TreatmentPlansForm,
    appBar: true
  },
  {
    ...menuThirds.TREATMENT_PLAN,
    component: TreatmentPlans,
    appBar: true
  }
]

export default routeTreatmentPlans
