import { Box } from '@mui/material'

/** STYLE */
import { styled } from '@mui/material/styles'

export const ImageWrap = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingTop: '75%',
  img: {
    position: 'absolute',
    height: '100%',
    top: 0
  }
}))

export const TableResponsive = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        paddingTop: 10,
        paddingBottom: 10,
        '&:first-of-type': {
          paddingLeft: 4,
          paddingRight: 4
        },
        '&:nth-of-type(7)': {
          paddingTop: 16,
        },
        img: {
          objectFit: 'contain'
        }
      }
    }
  }
}))

export const UploadImage = styled(Box)(({ theme }) => ({
  '> div': {
    [theme.breakpoints.up(480)]: {
      maxWidth: 360
    },
    '> div:first-of-type': {
      position: 'relative',
      padding: '75% 0 0 0  !important',
      flex: 'none !important',
      'img:not(.upload-image)': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'contain'
      },
      '> div': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100% !important',
      }
    }
  }
}))