import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart, TreatmentStateInterface } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/16.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethU, TextNumberU } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
  height: 'auto',
  margin: 'auto 6px 0',
  top: '-0.4%',
  left: '17.9%'
}))

export default function Teeth16(props: TeethProps) {
  const teeth = useSelector(toothChart)
  const teethNumber = props.teethNumber
  const historyToothChart = props.historyToothChart
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const isEditTootCharts: any = useSelector(isEditTootChart)

  const dispatch = useDispatch()

  const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
  const [positions, setPositions]: any = useState([])
  const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

  const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
  const [isTooth, setIsTooth]: any = useState(false)
  const [isXRay, setIsXRay]: any = useState(false)
  const [isToothRoot, setIsToothRoot]: any = useState(false)
  const [isOrthodontics, setIsOrthodontics]: any = useState(false)
  const [isExtract, setIsExtract]: any = useState(false)
  const [isFixture, setIsFixture]: any = useState(false)
  const [isBridge, setIsBridge]: any = useState(false)

  const [color, setColor]: any = useState(``)
  const [styleSvg, setStyleSvg]: any = useState({ width: 44.748, height: 99.215, viewBox: "0 0 44.748 99.215" })

  const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

  useEffect(() => {
    const operative = props.operative || ``
    const valColor = props.color || colors.themeSecondColor
    const selected = teeth[teethNumber]?.isSelect || false
    dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
    setIsSelected(selected)

    renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

    if (props.type === 'treatment') {
      renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
    }

    if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

    return setStyleSvg({ width: 44.748, height: 99.215, viewBox: "0 0 44.748 99.215" })
  }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

  useEffect(() => {
    const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
    const teethJson = treatmentTooth?.teethJson

    if (isEditTootCharts !== null) {
      if (teethJson) {
        const tooths = JSON.parse(teethJson)
        setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
        if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
      } else setIsEditTeeth(false)
    }
  }, [isEditTootCharts]);

  useEffect(() => {
    if (isSelected) {
      dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
      if (teeth[props.teethNumber]?.isSelect) {
        setIsSelected(true)
      } else {
        setIsSelected(false)
      }
    }
  }, [props.historyToothChart]);

  useEffect(() => {
    if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
  }, [isSelected, isEditTootCharts]);

  return (
    <Div>
      <DivTeethU className={`div-teeth-${props.teethNumber} `}>
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray />) || ''}
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge />) || ''}
        {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`lg teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
          <svg className={`lg ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
            <g data-name="Group 9168">

              {(isBridge && props.type === 'treatment') ?
                <g></g>
                : ""}
              <path
                data-name="Path 5290"
                d="M206.288 138.773c-4.647-1.863-9.042-3.113-14.186-2.243-6.4 1.076-9.275-3.828-12.542-8.224l.754-9.249c6.136-8.933 6.252-19.206 4.7-29.725-1.972-13.348.238-26.528 1.218-39.766a4.03 4.03 0 0 1 .506-1.8.974.974 0 0 1 1.8.371c2.618 18.153 9.518 31.561 10.34 35.666a.632.632 0 0 0 1.245-.021c.771-4.639.7-22.86-3.872-39.936-.431-1.612 1.218-3.107 3.145-3.2 1.734-.084 2.4 1.379 3.4 2.526 12.742 14.618 12.781 32.509 12.89 50.3a67.463 67.463 0 0 0 4.312 23.9 38.211 38.211 0 0 1 2.069 11.356c.379 6.049-9.572 12.526-15.779 10.045z"
                transform="translate(-177.927 -40.643)"
                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
              />

              {(isLowerTeeth && props.type === 'treatment') ?
                <path
                  data-name="Path 6512"
                  d="M209.255 96.74c-2.782-.141-4.282 1.859-6.625 1.75-1.172.125-1.578-.953-3.453-1.75a13.02 13.02 0 0 0-7.674.033 10.583 10.583 0 0 0-5.7 4.984 64.527 64.527 0 0 0-.787-12.425c-1.972-13.348.238-26.528 1.218-39.766a4.03 4.03 0 0 1 .506-1.8.974.974 0 0 1 1.8.371c2.618 18.153 9.518 31.561 10.34 35.666a.632.632 0 0 0 1.245-.021c.771-4.639.7-22.86-3.872-39.936-.431-1.612 1.218-3.107 3.145-3.2 1.734-.084 2.4 1.379 3.4 2.526 12.742 14.618 12.781 32.509 12.89 50.3a81.224 81.224 0 0 0 .789 10.566c-.25-.969-1.609-4.281-3.187-5.609a6.186 6.186 0 0 0-4.035-1.689z"
                  transform="translate(-177.927 -40.643)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                /> : ''}
              <g data-name="Group 5190">
                <path
                  data-name="Path 5291"
                  d="M187.414 129.146a8.384 8.384 0 0 0-2.959 4.59c-1.013 4.069-3.518 13.382-5.8 15.884-2.939 3.23-1.92 13.643 3.13 16.734s14.526 2.158 16.328 2.363c2.86.326 22.4 7.12 23.525-8.455 1.2-16.553-6.146-19.443-6.054-25.673 0 0-2.845-10.783-11.414-6.052a4.686 4.686 0 0 1-2.887.528c-1.1-.148-1.305-1.349-3.975-1.962a11.346 11.346 0 0 0-9.894 2.043z"
                  transform={(isFixture && props.type === 'treatment') ? "translate(-248.253 -87.88)" : "translate(-177.026 -71.253)"}
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                />
                <path
                  data-name="Path 5292"
                  d="M189.152 131.646a7.692 7.692 0 0 0-2.733 4.212c-.935 3.736-3.248 12.282-5.351 14.578-2.714 2.964-1.773 12.522 2.889 15.359s13.413 1.98 15.076 2.169c2.641.3 20.68 6.535 21.721-7.761 1.107-15.192-5.675-17.845-5.59-23.563 0 0-2.628-9.9-10.539-5.554a4.354 4.354 0 0 1-2.666.485c-1.013-.135-1.2-1.238-3.67-1.8a10.521 10.521 0 0 0-9.137 1.875z"
                  transform={(isFixture && props.type === 'treatment') ? "translate(-248.954 -89.337)" : "translate(-177.927 -72.211)"}
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                />
                <path
                  data-name="Path 5293"
                  d="M193.445 135.374a6.731 6.731 0 0 0-2.374 3.683c-.813 3.266-2.823 10.738-4.65 12.745-2.358 2.592-1.54 10.947 2.511 13.428s11.656 1.731 13.1 1.9c2.294.261 17.971 5.713 18.876-6.785.962-13.282-4.932-15.6-4.858-20.6 0 0-2.284-8.653-9.159-4.856a3.76 3.76 0 0 1-2.316.423c-.881-.119-1.047-1.083-3.189-1.575a9.107 9.107 0 0 0-7.941 1.637z"
                  transform={(isFixture && props.type === 'treatment') ? "translate(-249.598 -90.208)" : "translate(-179.9 -73.635)"}
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                />

              </g>

              {(isToothRoot && props.type === 'treatment') ?
                <>
                  <path
                    data-name="Path 5291"
                    d="M187.414 129.146a8.384 8.384 0 0 0-2.959 4.59c-1.013 4.069-3.518 13.382-5.8 15.884-2.939 3.23-1.92 13.643 3.13 16.734s14.526 2.158 16.328 2.363c2.86.326 22.4 7.12 23.525-8.455 1.2-16.553-6.146-19.443-6.054-25.673 0 0-2.845-10.783-11.414-6.052a4.686 4.686 0 0 1-2.887.528c-1.1-.148-1.305-1.349-3.975-1.962a11.346 11.346 0 0 0-9.894 2.043z"
                    transform="translate(-177.026 -71.253)"
                    style={{ opacity: '.3', fill: 'none' }}
                  />
                  <path
                    data-name="Path 6532"
                    d="M1.3 11.322a226.36 226.36 0 0 0 1.2 24.169 144.887 144.887 0 0 0 4.068 20.761 7.376 7.376 0 0 1 .966-.11C7.041 52.951 4.383 44 3.072 35.152 1.526 24.72.83 12.549 1.3 11.322z"
                    transform="translate(8.406 -1.011)"
                    style={{

                      fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || ((isToothRoot && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                      stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || ((isToothRoot && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                    }}
                  />
                  <path
                    data-name="Path 6533"
                    d="M6.366 55.6a4.482 4.482 0 0 1 1.476.317c.018-4.775-3.882-44.9-11.908-53.391A179.908 179.908 0 0 1 2.706 25.1a156.726 156.726 0 0 1 3.66 30.5z"
                    transform="translate(25.245 -.011)"
                    style={{

                      fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || ((isToothRoot && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                      stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || ((isToothRoot && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                    }}
                  />
                </>
                : ''}

              {(isExtract && props.type === 'treatment') ?
                <>
                  <path data-name="Path 6580" d="m3.246-.5 36.254 80" transform="translate(1.755 15.584)" style={{ stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px', fill: 'none' }} />
                  <path data-name="Path 6581" d="m39.5-.5-36.254 80" transform="translate(1.755 15.584)" style={{ stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px', fill: 'none' }} />
                </>
                : ''}

              {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                <>
                  <defs>
                    <linearGradient id="8dmc5zbeha" x1=".5" y1=".453" x2="-1.326" y2="4.622" gradientUnits="objectBoundingBox">
                      <stop offset={0} stopColor="#b0b1b1" />
                      <stop offset={1} stopColor="#585959" />
                    </linearGradient>
                    <linearGradient id="e9p7wj5lsb" x1=".5" y1="1.834" x2=".5" y2=".046" gradientUnits="objectBoundingBox">
                      <stop offset={0} stopColor="#c1c4c5" />
                      <stop offset={1} stopColor="#9e9e9e" />
                    </linearGradient>
                  </defs>
                  <g data-name="Group 9355">
                    <path data-name="Path 5283" d="m185.925 168.552-.135-.348" transform="translate(-164.589 -69.385)" style={{ fill: 'none' }} />
                    <path data-name="Path 5290" d="M206.288 138.773c-4.647-1.863-9.042-3.113-14.186-2.243-6.4 1.076-9.275-3.828-12.542-8.224l.754-9.249c6.136-8.933 6.252-19.206 4.7-29.725-1.972-13.348.238-26.528 1.218-39.766a4.03 4.03 0 0 1 .506-1.8.974.974 0 0 1 1.8.371c2.618 18.153 9.518 31.561 10.34 35.666a.632.632 0 0 0 1.245-.021c.771-4.639.7-22.86-3.872-39.936-.431-1.612 1.218-3.107 3.145-3.2 1.734-.084 2.4 1.379 3.4 2.526 12.742 14.618 12.781 32.509 12.89 50.3a67.463 67.463 0 0 0 4.312 23.9 38.211 38.211 0 0 1 2.069 11.356c.379 6.049-9.572 12.526-15.779 10.045z" transform="translate(-177.496 -40.643)" style={{ fill: '#e3d1b4' }} />
                    <g data-name="Group 5190">
                      <path data-name="Path 5291" d="M187.414 129.146a8.384 8.384 0 0 0-2.959 4.59c-1.013 4.069-3.518 13.382-5.8 15.884-2.939 3.23-1.92 13.643 3.13 16.734s14.526 2.158 16.328 2.363c2.86.326 22.4 7.12 23.525-8.455 1.2-16.553-6.146-19.443-6.054-25.673 0 0-2.845-10.783-11.414-6.052a4.686 4.686 0 0 1-2.887.528c-1.1-.148-1.305-1.349-3.975-1.962a11.346 11.346 0 0 0-9.894 2.043z" transform="translate(-176.595 -71.253)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5292" d="M189.152 131.646a7.692 7.692 0 0 0-2.733 4.212c-.935 3.736-3.248 12.282-5.351 14.578-2.714 2.964-1.773 12.522 2.889 15.359s13.413 1.98 15.076 2.169c2.641.3 20.68 6.535 21.721-7.761 1.107-15.192-5.675-17.845-5.59-23.563 0 0-2.628-9.9-10.539-5.554a4.354 4.354 0 0 1-2.666.485c-1.013-.135-1.2-1.238-3.67-1.8a10.521 10.521 0 0 0-9.137 1.875z" transform="translate(-177.496 -72.211)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5293" d="M193.445 135.374a6.731 6.731 0 0 0-2.374 3.683c-.813 3.266-2.823 10.738-4.65 12.745-2.358 2.592-1.54 10.947 2.511 13.428s11.656 1.731 13.1 1.9c2.294.261 17.971 5.713 18.876-6.785.962-13.282-4.932-15.6-4.858-20.6 0 0-2.284-8.653-9.159-4.856a3.76 3.76 0 0 1-2.316.423c-.881-.119-1.047-1.083-3.189-1.575a9.107 9.107 0 0 0-7.941 1.637z" transform="translate(-179.469 -73.635)" style={{ fill: colors.white }} />
                      <g data-name="Group 9378" transform="translate(4.431 71.861)">
                        <g data-name="Rectangle 3927" transform="translate(13.517)" style={{ fill: '#c1c4c5', stroke: '#c1c4c5' }}>
                          <rect width="11.601" height="11.601" rx={2} style={{ stroke: 'none' }} />
                          <rect x=".5" y=".5" width="10.601" height="10.601" rx="1.5" style={{ fill: 'none' }} />
                        </g>
                        <path data-name="Path 6487" d="M11.579 17.547c-.2.362 1.676.183 3.083.165 2.094-.044 3.289.384 3.269-4.45a41.118 41.118 0 0 0-.159-5.141c-.531-.516.14 5.236-1.219 7.766-1.282 1.827-4.631 1.272-4.974 1.66z" transform="translate(6.863 -6.465)" style={{ fill: '#b0b1b1' }} />
                        <rect data-name="Rectangle 3931" width={5} height={2} rx=".5" transform="translate(16.806 6.215)" style={{ fill: '#cbcccc' }} />
                        <rect data-name="Rectangle 3930" width={5} height={2} rx=".5" transform="translate(16.606 2.015)" style={{ fill: '#9e9e9e' }} />
                        <rect data-name="Rectangle 3928" width={5} height={2} rx=".5" transform="translate(16.806 2.015)" style={{ fill: 'url(#8dmc5zbeha)' }} />
                        <rect data-name="Rectangle 3929" width={5} height={2} rx=".5" transform="translate(16.806 6.015)" style={{ fill: 'url(#e9p7wj5lsb)' }} />
                        <path data-name="Path 6488" d="M-3.739 13.281s23.561-8.544 44.039-.752" transform="translate(.085 -3.765)" style={{ strokeLinecap: 'round', stroke: '#babbbb', fill: 'none' }} />
                        <path data-name="Path 6486" d="M-3.69 13.321s23.733-8.544 44.212-.752" transform="translate(-.101 -3.084)" style={{ stroke: '#b5b7b7', strokeLinecap: 'round', fill: 'none' }} />
                      </g>
                    </g>
                    <path data-name="Path 5291" d="M187.414 129.146a8.384 8.384 0 0 0-2.959 4.59c-1.013 4.069-3.518 13.382-5.8 15.884-2.939 3.23-1.92 13.643 3.13 16.734s14.526 2.158 16.328 2.363c2.86.326 22.4 7.12 23.525-8.455 1.2-16.553-6.146-19.443-6.054-25.673 0 0-2.845-10.783-11.414-6.052a4.686 4.686 0 0 1-2.887.528c-1.1-.148-1.305-1.349-3.975-1.962a11.346 11.346 0 0 0-9.894 2.043z" transform="translate(-176.595 -71.253)" style={{ opacity: '.3', fill: 'none' }} />
                  </g>
                </> || ""}

              {(isFixture && props.type === 'treatment') ?
                <>
                  <defs>
                    <linearGradient id="v99t26lc6a" x1=".209" y1="-.059" x2="1.176" y2="1.286" gradientUnits="objectBoundingBox">
                      <stop offset={0} stopColor="#e5e5e5" />
                      <stop offset={1} stopColor="#b4b5b5" />
                    </linearGradient>
                    <linearGradient id="1bct1dkyfb" x1="-.342" y1="-.081" x2="1.352" y2={1} gradientUnits="objectBoundingBox">
                      <stop offset={0} stopColor="#e5e5e5" />
                      <stop offset={1} stopColor="#b0b1b1" />
                    </linearGradient>
                  </defs>
                  <g data-name="Group 9496">
                    <path data-name="Path 5283" d="m185.925 168.552-.135-.348" transform="translate(-165.02 -81.469)" style={{ fill: 'none' }} />
                    <g data-name="Group 5190">
                      <path data-name="Path 5291" d="M187.414 129.146a8.384 8.384 0 0 0-2.959 4.59c-1.013 4.069-3.518 13.382-5.8 15.884-2.939 3.23-1.92 13.643 3.13 16.734s14.526 2.158 16.328 2.363c2.86.326 22.4 7.12 23.525-8.455 1.2-16.553-6.146-19.443-6.054-25.673 0 0-2.845-10.783-11.414-6.052a4.686 4.686 0 0 1-2.887.528c-1.1-.148-1.305-1.349-3.975-1.962a11.346 11.346 0 0 0-9.894 2.043z" transform="translate(-177.026 -83.337)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5292" d="M189.152 131.646a7.692 7.692 0 0 0-2.733 4.212c-.935 3.736-3.248 12.282-5.351 14.578-2.714 2.964-1.773 12.522 2.889 15.359s13.413 1.98 15.076 2.169c2.641.3 20.68 6.535 21.721-7.761 1.107-15.192-5.675-17.845-5.59-23.563 0 0-2.628-9.9-10.539-5.554a4.354 4.354 0 0 1-2.666.485c-1.013-.135-1.2-1.238-3.67-1.8a10.521 10.521 0 0 0-9.137 1.875z" transform="translate(-177.927 -84.295)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5293" d="M193.445 135.374a6.731 6.731 0 0 0-2.374 3.683c-.813 3.266-2.823 10.738-4.65 12.745-2.358 2.592-1.54 10.947 2.511 13.428s11.656 1.731 13.1 1.9c2.294.261 17.971 5.713 18.876-6.785.962-13.282-4.932-15.6-4.858-20.6 0 0-2.284-8.653-9.159-4.856a3.76 3.76 0 0 1-2.316.423c-.881-.119-1.047-1.083-3.189-1.575a9.107 9.107 0 0 0-7.941 1.637z" transform="translate(-179.9 -85.719)" style={{ fill: colors.white }} />
                    </g>
                    <path data-name="Path 5291" d="M187.414 129.146a8.384 8.384 0 0 0-2.959 4.59c-1.013 4.069-3.518 13.382-5.8 15.884-2.939 3.23-1.92 13.643 3.13 16.734s14.526 2.158 16.328 2.363c2.86.326 22.4 7.12 23.525-8.455 1.2-16.553-6.146-19.443-6.054-25.673 0 0-2.845-10.783-11.414-6.052a4.686 4.686 0 0 1-2.887.528c-1.1-.148-1.305-1.349-3.975-1.962a11.346 11.346 0 0 0-9.894 2.043z" transform="translate(-177.026 -83.337)" style={{ opacity: '.3', fill: 'none' }} />
                    <g data-name="Group 9540">
                      <g data-name="Path 6490" transform="translate(12.671)" style={{ fill: 'url(#v99t26lc6a)' }}>
                        <path d="M17.343 44.996H1.734c-.422 0-.825-.367-1.135-1.033-.322-.69-.499-1.61-.499-2.59C.845 24.493 1.818 12.58 2.991 5.97 3.682 2.075 4.427.1 5.203.1h8.671c.852 0 5.103 1.99 5.103 41.273 0 .98-.177 1.9-.499 2.59-.31.666-.713 1.033-1.135 1.033z" style={{ stroke: 'none' }} />
                        <path d="M17.343 44.896c.74 0 1.534-1.416 1.534-3.523 0-16.645-.753-28.547-2.238-35.377-.504-2.319-1.081-3.983-1.715-4.946-.486-.74-.867-.85-1.05-.85H5.203c-.406 0-1.22.752-2.114 5.788C1.917 12.593.945 24.498.2 41.378c.001 2.104.794 3.518 1.534 3.518h15.609m0 .2H1.734C.776 45.096 0 43.429 0 41.373 1.734 2.085 4.245 0 5.203 0h8.671c.958 0 5.203 2.085 5.203 41.373 0 2.056-.777 3.723-1.734 3.723z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                      </g>
                      <g data-name="Path 6489" transform="translate(4 42.63)" style={{ strokeLinejoin: 'round', fill: 'url(#1bct1dkyfb)' }}>
                        <path d="M34.685 7.569H1.735c-.898 0-1.628-.807-1.635-1.8C.926 2.761 1.14 1.32 1.243.626 1.321.1 1.33.1 1.413.1c.027 0 .061.002.1.005.06.004.132.008.221.008h33.058l.083-.001c.355 0 .362 0 .626 1.491.152.859.381 2.155.818 4.163-.005.995-.736 1.803-1.634 1.803z" style={{ stroke: 'none' }} />
                        <path d="M34.685 7.469c.84 0 1.524-.758 1.534-1.693a98.161 98.161 0 0 1-.817-4.155c-.127-.72-.197-1.115-.28-1.3-.042-.095-.048-.11-.247-.11l-.19.002H1.735c-.093 0-.168-.005-.228-.008C1.473.202 1.443.2 1.42.2c-.023.073-.049.246-.078.442-.103.694-.317 2.136-1.142 5.14.013.932.696 1.687 1.534 1.687h32.951m0 .2H1.735C.775 7.669 0 6.812 0 5.755 1.734-.555.776.013 1.734.013h32.951c.958 0 .43-.24 1.734 5.742 0 1.057-.776 1.914-1.734 1.914z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                      </g>
                      <path data-name="Path 6491" d="M5.625 21.2s18.057.9 18.962 1.957" transform="translate(7.161 15.597)" style={{ stroke: '#b1b3b4', strokeLinecap: 'round', fill: 'none' }} />
                      <path data-name="Path 6492" d="M5.649 21.2S23.1 22.161 24 23.213" transform="translate(7.447 10.345)" style={{ stroke: '#b1b3b4', strokeLinecap: 'round', fill: 'none' }} />
                      <path data-name="Path 6493" d="M7.08 21.281s14.6.592 15.5 1.645" transform="translate(7.895 -11.973)" style={{ stroke: '#b1b3b4', strokeLinecap: 'round', fill: 'none' }} />
                      <path data-name="Path 6584" d="M5.649 21.2S23.1 22.161 24 23.213" transform="translate(7.703 5.802)" style={{ stroke: '#b1b3b4', strokeLinecap: 'round', fill: 'none' }} />
                      <path data-name="Path 6585" d="M6.115 21.2S23.1 22.185 24 23.237" transform="translate(7.703 -.222)" style={{ stroke: '#b1b3b4', strokeLinecap: 'round', fill: 'none' }} />
                      <path data-name="Path 6586" d="M6.643 21.3s16.036.854 16.941 1.907" transform="translate(7.703 -6.281)" style={{ stroke: '#b1b3b4', strokeLinecap: 'round', fill: 'none' }} />
                      <path data-name="Path 6587" d="M8.144 21.444s12.509.373 13.415 1.426" transform="translate(7.895 -17.717)" style={{ stroke: '#b1b3b4', strokeLinecap: 'round', fill: 'none' }} />
                    </g>
                  </g>
                </> : ""}
            </g>
          </svg>}
      </DivTeethU>
      <PositionTeeth quarter={QUARTER_NAME_TYPE.Q1} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
      <TextNumberU>16</TextNumberU>
    </Div>
  )
}
