/** COMPONENT */
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Avatar from '@mui/material/Avatar'

/** STYLE */
import { styled } from '@mui/material/styles'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { scale } from 'constants/scale'
import { Box, Typography } from '@mui/material'

export const Header = styled('div')(({ theme }) => ({
  padding: '1rem 0',
  [theme.breakpoints.between('sm', 'lg')]: {
    padding: '1.5rem 0'
  }
}))

export const TypoHeader = styled('p')(({ theme }) => ({
  fontSize: 20,
  fontWeight: 500,
  color: colors.darkGray,
  marginBottom: '1.5rem',
  '& .title-schedule': {
    display: 'none'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
    marginBottom: '1rem'
  },
  [theme.breakpoints.up('xl')]: {
    '& .title-schedule': {
      display: 'inline-block'
    }
  }
}))

export const Container = styled('div')(({ theme }) => ({
  padding: '0 1.5rem',
  [theme.breakpoints.down('sm')]: {
    padding: '0 1rem'
  }
}))

export const CustomSectionSchedule = styled('div')(({ theme }) => ({
  '.layout-schedule': {
    marginRight: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      marginRight: '1rem'
    },
    '.container-schedule': {
      '.card-appointment': {
        width: `calc(100% - 0.5rem)`
      }
    }
  }
}))

export const Income = styled('div')(({ theme }) => ({
  height: 48,
  minWidth: 281,
  backgroundColor: colors.themeMainColor06,
  display: 'flex',
  alignItems: 'center',
  padding: '0 0.5rem',
  fontSize: 18,
  color: colors.themeMainColor,
  marginLeft: 'auto',
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}))

export const SliderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    height: 0
  },
  '.service-slider-wrap': {
    paddingRight: '10rem'
  },
  '.slider-area': {
    margin: '0 0.5rem',
    '&:first-of-type': {
      marginLeft: 0
    },
    '&:last-of-type': {
      marginRight: '1.5rem',
      [theme.breakpoints.down('sm')]: {
        marginRight: '1rem'
      }
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 0.25rem'
    }
  },
  [theme.breakpoints.down('lg')]: {
    overflowX: 'auto !important'
  }
}))

export const AreaArrow = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  marginTop: 56,
  width: 88,
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '&.arrow-next': {
    right: 0
  },
  '&:hover .MuiIconButton-root': {
    background: `${colors.disabledGray} !important`
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}))

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: colors.lightGray,
  border: `1px solid ${colors.lightGray}`,
  color: colors.white,
  padding: 0,
  width: 34,
  height: 34,
  transition: 'opacity .3s ease',
  '& svg': {
    fontSize: 26
  }
}))

export const ButtonDropdown = styled(Button)(({ theme }) => ({
  width: '100%',
  height: 72,
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '0 1rem',
  borderRadius: scale.borderRadiusXs,
  border: `1px solid ${colors.lightGray}`,
  background: `${colors.filterDropdown} !important`,
  color: colors.textPrimary,
  textAlign: 'left',
  '& .MuiButton-endIcon': {
    margin: '0 0 0 .5rem',
    '& svg': {
      fontSize: 16,
      '& path': {
        fill: colors.themeSecondColor
      }
    }
  },
  '&[aria-expanded="true"] .MuiButton-endIcon': {
    transform: 'rotate(180deg)'
  },
  '& .title': {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.4
  },
  '& .subtitle': {
    lineHeight: 1.4,
    [theme.breakpoints.up('lg')]: {
      fontSize: 16
    }
  }
}))

export const CustomAvatar = styled(Avatar)(({ theme }) => ({
  border: `1px solid ${colors.white}`,
  '&.active': {
    border: `2px solid ${colors.themeSecondColor}`
  },
  '&.more': {
    backgroundColor: colors.white,
    color: colors.textPrimary,
    border: `1px solid ${colors.white}`,
    fontSize: 14,
    display: 'flex !important'
  }
}))

export const MoreAvatar = styled('div')<{ size?: number }>(({ theme, size }) => ({
  display: 'none',
  marginLeft: 'auto',
  '& .MuiAvatar-root': {
    position: 'relative',
    display: 'none',
    '&:nth-of-type(1)': {
      display: 'flex',
      right: size && size > 3 ? -21 : -((size || 0) - 1) * 7
    },
    '&:nth-of-type(2)': {
      right: size && size > 3 ? -14 : -7,
      display: 'flex'
    },
    '&:nth-of-type(3)': {
      right: size && size > 3 ? -7 : 0,
      display: 'flex'
    },
    '&:last-of-type': {
      right: '0 !important',
      display: 'flex'
    },
    '&:not(.more):not(.active):hover': {
      zIndex: 5
    }
  },
  [theme.breakpoints.up('xl')]: {
    display: 'flex'
  }
}))

export const MenuDropdown = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 4,
    mixBlendMode: 'multiply',
    backgroundBlendMode: 'multiply',
    background: `linear-gradient(to bottom, ${colors.white}, ${colors.white})`,
    boxShadow: `0 5px 5px 0 ${colors.black20}`,
    overflow: 'hidden'
  },
  '& .MuiMenu-list': {
    maxHeight: '320px',
    overflowY: 'auto'
  }
}))

export const MenuDropdownItem = styled(MenuItem)(({ theme }) => ({
  padding: '.5rem 1rem',
  '&:hover': {
    background: colors.themeSecondColor06,
    color: colors.themeSecondColor
  },
  '& .title': {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.4
  },
  '& .subtitle': {
    lineHeight: 1.4,
    [theme.breakpoints.up('lg')]: {
      fontSize: 16
    }
  }
}))
export const BoxStatus = styled(`div`)<{ bg?: string }>(({ theme, bg }) => ({
  backgroundColor: bg,
  display: 'flex',
  flexDirection: 'row',
  justifyItems: 'center',
  fontSize: 11,
  paddingLeft: 5,
  paddingRight: 5,
  paddingTop: 2,
  paddingBottom: 2,
  borderRadius: 3,
  '> svg': {
    marginBottom: 'auto',
    marginTop: 'auto',
  }
}))

export const TextStatus = styled(`div`)(({ theme }) => ({
  color: colors.white,
  width: 'auto',
  paddingLeft: 3,
  fontSize: 11
}))
