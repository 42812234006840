import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/63.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethU, TextNumberU } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginTop: 'auto',
    top: '1.4%',
    left: '45.2%',
    maxWidth: '65px',
}))

export default function Teeth63(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)

    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 30.01, height: 101, viewBox: "0 0 30.01 101" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

    useEffect(() => {
        const operative = props.operative || ``
        const valColor = operative ? props.color || colors.themeSecondColor : ''
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }


        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        setStyleSvg({ width: 30.01, height: 101, viewBox: "0 0 30.01 101" })
    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <DivTeethU className={`div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge width={100} />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`md teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`md ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12339" >
                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ''}
                            <path
                                data-name="Path 5428"
                                d="M0.191406 68.9511C4.39159 60.6546 13.3411 59.9111 18.4867 67.758C20.438 70.7291 22.3536 73.8119 23.9087 77.0266C18.896 54.198 18.9376 30.8626 17.931 7.63005C17.8132 5.0409 15.3622 -1.2433 13.8203 1.58947C10.9113 6.04693 7.45373 39.7975 0.191406 68.9511Z"
                                transform={"translate(1 ,4)"}
                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />
                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5428"
                                    d="M0.191406 68.9511C4.39159 60.6546 13.3411 59.9111 18.4867 67.758C20.438 70.7291 22.3536 73.8119 23.9087 77.0266C18.896 54.198 18.9376 30.8626 17.931 7.63005C17.8132 5.0409 15.3622 -1.2433 13.8203 1.58947C10.9113 6.04693 7.45373 39.7975 0.191406 68.9511Z"
                                    transform={"translate(1 ,4)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}
                            <g data-name="Group 12339" >
                                <path
                                    data-name="Path 5429"
                                    d="M28.2735 32.9316C30.4684 30.4221 31.5273 24.8522 30.5126 20.8506C30.1125 19.284 29.6217 17.742 29.0427 16.2323C29.0355 16.2323 29.0365 16.2252 29.0365 16.218C27.7578 12.8836 26.1079 9.6502 24.4137 6.52494C19.9469 -1.72552 10.9663 -1.73783 6.08302 6.17587C5.93773 6.40679 5.79956 6.64636 5.65967 6.89267C3.27353 11.1983 1.14775 18.8618 0.896825 28.3583C0.860769 29.6618 1.25056 30.9416 2.00715 32.0037C5.62061 37.0581 10.9921 39.9033 14.5844 40.7689C18.6435 41.7466 26.0442 35.3672 28.2735 32.9316Z"
                                    transform={"translate(-2 ,55)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5430"
                                    d="M27.1405 32.7967C29.135 30.5168 30.0964 25.4546 29.1749 21.819C28.8119 20.395 28.366 18.9935 27.8396 17.6215C27.8325 17.6215 27.8334 17.6153 27.8342 17.6092C26.6715 14.5788 25.1723 11.6413 23.6325 8.80143C19.5741 1.30415 11.4131 1.29261 6.97551 8.48412C6.8435 8.69406 6.71765 8.91112 6.59065 9.1355C4.42427 13.0476 2.49188 20.0115 2.26385 28.6412C2.23068 29.8256 2.58469 30.9885 3.27219 31.9535C6.55601 36.5461 11.4366 39.1327 14.7026 39.9186C18.3893 40.8061 25.1138 35.0093 27.1405 32.7967Z"
                                    transform={"translate(-2 ,55)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5421"
                                    d="M26.11 31.7606C27.9202 29.6906 28.794 25.0963 27.9573 21.7956C27.6276 20.5031 27.2228 19.2309 26.745 17.9855C26.7396 17.9855 26.7396 17.9793 26.7406 17.973C25.6853 15.2225 24.3242 12.5562 22.9268 9.97766C19.2423 3.17236 11.8347 3.16178 7.80639 9.68902C7.68689 9.88049 7.5724 10.0772 7.45733 10.28C5.4903 13.8324 3.73572 20.1538 3.52924 27.9868C3.49945 29.0619 3.8208 30.1173 4.44463 30.9934C7.42557 35.1624 11.8559 37.5106 14.8201 38.2239C18.1661 39.032 24.2713 33.7686 26.11 31.7606Z"
                                    transform={"translate(-2 ,55)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />
                                {(isToothRoot && props.type === 'treatment') ?
                                    <path
                                        data-name="Path 6565"
                                        d="M2.68211 52.6654C2.43604 49.0244 2.64239 36.6158 3.30424 33.0109C4.01201 29.1512 5.71692 2.35907 5.58383 1.9603C5.40895 1.34436 4.23641 26.4094 3.45281 33.0099C2.45203 41.3865 1.71073 52.4941 1.71486 52.5271C1.83609 52.5096 2.31378 52.6195 2.68211 52.6654Z"
                                        transform="translate(11 4)"
                                        style={{
                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }}
                                    />
                                    : ''}
                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <path data-name="Path 6581" d="m33.788-.5-27.542 80" transform="translate(-5 10.606)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                        <path data-name="Path 6582" d="m6.246-.5 27.542 80" transform="translate(-5 10.606)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                    </>
                                    : ''}
                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <defs>
                                            <linearGradient id="paint0_linear_4126_154153" x1="16.4825" y1="78.7769" x2="14.2984" y2="91.2434" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4126_154153" x1="16.4825" y1="87.52" x2="16.4825" y2="82.8963" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>
                                        <g data-name="Group 9545">
                                            <path transform={"translate(-1 1)"} d="M5.16406 66.6635C9.23606 58.6202 17.9125 57.8994 22.9011 65.5068C24.7927 68.3873 26.6499 71.3759 28.1575 74.4926C23.2979 52.3607 23.3382 29.7373 22.3623 7.21382C22.2481 4.70369 19.8719 -1.38874 18.377 1.35758C15.5568 5.67901 12.2048 38.3996 5.16406 66.6635Z" fill="#E3D1B4" />
                                            <path transform={"translate(-1 1)"} d="M27.5434 90.8817C29.6713 88.4488 30.698 83.0489 29.7142 79.1694C29.3263 77.6506 28.8505 76.1556 28.2891 74.692C28.2822 74.692 28.2831 74.6851 28.2831 74.6782C27.0435 71.4455 25.4439 68.3108 23.8015 65.2809C19.4709 57.2822 10.7644 57.2703 6.03015 64.9425C5.88929 65.1663 5.75534 65.3986 5.61972 65.6374C3.3064 69.8116 1.2455 77.2413 1.00222 86.4479C0.967268 87.7117 1.34516 88.9524 2.07866 89.9821C5.58185 94.8822 10.7894 97.6407 14.2721 98.4798C18.2073 99.4277 25.3822 93.2429 27.5434 90.8817Z" fill="#EFEDE7" />
                                            <path transform={"translate(-1 1)"} d="M26.4477 90.7509C28.3813 88.5405 29.3134 83.6328 28.4199 80.1081C28.068 78.7276 27.6358 77.3688 27.1254 76.0387C27.1185 76.0387 27.1194 76.0328 27.1202 76.0268C25.993 73.0889 24.5395 70.2411 23.0467 67.4878C19.1122 60.2194 11.2003 60.2082 6.89806 67.1802C6.77008 67.3837 6.64808 67.5942 6.52495 67.8117C4.42468 71.6044 2.55127 78.3558 2.3302 86.7222C2.29803 87.8704 2.64124 88.9978 3.30776 89.9334C6.49137 94.3858 11.223 96.8935 14.3893 97.6554C17.9636 98.5158 24.4828 92.8959 26.4477 90.7509Z" fill="#F7F7F7" />
                                            <path transform={"translate(-1 1)"} d="M25.4482 89.7474C27.2031 87.7406 28.0503 83.2866 27.2391 80.0865C26.9195 78.8334 26.527 77.6001 26.0638 76.3927C26.0586 76.3927 26.0586 76.3867 26.0595 76.3805C25.0364 73.7141 23.7169 71.1291 22.3621 68.6293C18.7901 62.0316 11.6086 62.0214 7.70315 68.3494C7.5873 68.535 7.4763 68.7257 7.36474 68.9223C5.45774 72.3664 3.75671 78.4948 3.55653 86.0888C3.52764 87.1311 3.83919 88.1543 4.44398 89.0036C7.33395 93.0454 11.6291 95.322 14.5028 96.0135C17.7467 96.7969 23.6656 91.6942 25.4482 89.7474Z" fill="white" />
                                            <path transform={"translate(-1 1)"} d="M21.414 75H11.586C10.1578 75 9 76.1578 9 77.586V87.414C9 88.8422 10.1578 90 11.586 90H21.414C22.8422 90 24 88.8422 24 87.414V77.586C24 76.1578 22.8422 75 21.414 75Z" fill="#C1C4C5" />
                                            {/* <path transform={"translate(-1 1)"} d="M21.416 75.6465H11.5879C10.5168 75.6465 9.64844 76.5148 9.64844 77.586V87.414C9.64844 88.4852 10.5168 89.3535 11.5879 89.3535H21.416C22.4871 89.3535 23.3554 88.4852 23.3554 87.414V77.586C23.3554 76.5148 22.4871 75.6465 21.416 75.6465Z" stroke="#C1C4C5" /> */}
                                            <path transform={"translate(-1 1)"} d="M15.3667 89.3314C15.1081 89.7995 17.5338 89.568 19.353 89.5447C22.0605 89.4878 23.6057 90.0413 23.5798 83.7909C23.6501 81.573 23.5814 79.353 23.3742 77.1437C22.6876 76.4765 23.5552 83.9138 21.7981 87.185C20.1405 89.5473 15.8102 88.8297 15.3667 89.3314Z" fill="#B0B1B1" />
                                            <path transform={"translate(-1 1)"} d="M19.0685 83.0352H13.8965C13.5394 83.0352 13.25 83.3246 13.25 83.6817V84.9746C13.25 85.3317 13.5394 85.6211 13.8965 85.6211H19.0685C19.4255 85.6211 19.715 85.3317 19.715 84.9746V83.6817C19.715 83.3246 19.4255 83.0352 19.0685 83.0352Z" fill="#CBCCCC" />
                                            <path transform={"translate(-1 1)"} d="M18.8146 77.6055H13.6426C13.2855 77.6055 12.9961 77.8949 12.9961 78.252V79.545C12.9961 79.902 13.2855 80.1915 13.6426 80.1915H18.8146C19.1716 80.1915 19.4611 79.902 19.4611 79.545V78.252C19.4611 77.8949 19.1716 77.6055 18.8146 77.6055Z" fill="#9E9E9E" />
                                            <path transform={"translate(-1 1)"} d="M19.0685 77.6055H13.8965C13.5394 77.6055 13.25 77.8949 13.25 78.252V79.545C13.25 79.902 13.5394 80.1915 13.8965 80.1915H19.0685C19.4255 80.1915 19.715 79.902 19.715 79.545V78.252C19.715 77.8949 19.4255 77.6055 19.0685 77.6055Z" fill="url(#paint0_linear_4126_154153)" />
                                            <path transform={"translate(-1 1)"} d="M19.0685 82.7773H13.8965C13.5394 82.7773 13.25 83.0668 13.25 83.4238V84.7168C13.25 85.0739 13.5394 85.3633 13.8965 85.3633H19.0685C19.4255 85.3633 19.715 85.0739 19.715 84.7168V83.4238C19.715 83.0668 19.4255 82.7773 19.0685 82.7773Z" fill="url(#paint1_linear_4126_154153)" />
                                            <path transform={"translate(-1 1)"} d="M1.31641 84.2778C1.31641 84.2778 13.6571 79.3061 29.8789 83.246" stroke="#BABBBB" strokeLinecap="round" />
                                            <path transform={"translate(-1 1)"} d="M1.31641 84.7875C10.6101 81.7925 20.4274 81.4293 29.8789 83.7308" stroke="#B5B7B7" strokeLinecap="round" />
                                        </g>
                                    </> || ''}
                            </g>
                        </g>
                    </svg>}
            </DivTeethU>

            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q1} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <TextNumberU>63</TextNumberU>
        </Div >
    )
}
