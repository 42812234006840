import { useState } from 'react'

export default function UseScroll(parentClass: any, childClass: any, scrollUnit = 10, scrollRange = scrollUnit / 4) {
  const [hideArrow, setHideArrow] = useState<'' | 'prev' | 'next' | 'both'>('')
  const [scrollBehavior, setScrollBehavior] = useState<'unset' | 'smooth'>('smooth')
  const [mouseDown, setMouseDown] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)

  const onPressArrow = (type: 'onPrev' | 'onNext' | 'onLoad') => {
    const slider: any = document.querySelector(`${parentClass}`)
    const childSlider: any = document.querySelector(`${childClass}`)
    if (type === 'onLoad') {
      handleScroll(slider.offsetWidth, slider.scrollLeft, childSlider.offsetWidth)
    } else {
      type === 'onNext' ? (slider.scrollLeft += scrollUnit) : (slider.scrollLeft -= scrollUnit)

      setTimeout(() => {
        handleScroll(Math.round(slider.offsetWidth), Math.round(slider.scrollLeft), Math.round(childSlider.offsetWidth))
      }, 500)
    }
  }

  const handleScroll = (width: number, scroll: number, childOffsetWidth: number) => {
    const slider: any = document.querySelector(`${parentClass}`)
    if (childOffsetWidth <= width) {
      setHideArrow('both')
    } else if (scroll < scrollRange && width !== childOffsetWidth) {
      slider.scrollLeft = 0
      setHideArrow('prev')
    } else if (width + scroll >= childOffsetWidth - scrollRange) {
      setHideArrow('next')
      slider.scrollLeft = childOffsetWidth
    } else setHideArrow('')
  }

  const startDragging = (event: any) => {
    const slider: any = document.querySelector(`${parentClass}`)
    setMouseDown(true)
    setStartX(event.pageX - slider.offsetLeft)
    setScrollLeft(slider.scrollLeft)
  }

  const stopDragging = (event: any) => {
    setMouseDown(false)
    setScrollBehavior('smooth')
  }

  const handleDragging = (event: any) => {
    const slider: any = document.querySelector(`${parentClass}`)
    const childSlider: any = document.querySelector(`${childClass}`)
    event.preventDefault()
    setScrollBehavior('unset')
    if (!mouseDown) return
    const x = event.pageX - slider.offsetLeft
    const scroll = x - startX
    slider.scrollLeft = scrollLeft - scroll
    handleScroll(slider.offsetWidth, slider.scrollLeft, childSlider.offsetWidth)
  }

  return { hideArrow, scrollBehavior, onPressArrow, handleScroll, startDragging, stopDragging, handleDragging }
}
