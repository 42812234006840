import { Box } from "@mui/material"
import StarActive from "assets/icon/starActive"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { BoxStarSelect, ContainerEditor } from "../useStyles"
import UploadImage from "component/Upload/UploadImage"
import { fileUrl } from "api/api"
import { ContentState, EditorState, convertToRaw } from "draft-js"
import htmlToDraft from "html-to-draftjs"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import { ReviewProps } from "api/application/manageWeb-interface"
import InputTextField from "component/Input/InputTextField"
import InputTextarea from "component/Input/InputTextarea"
import { Form } from 'react-bootstrap'
import { notiError } from "component/notifications/notifications"
import { ManageWebEditor } from "../ManageWeb"

type ReviewFormProps = {
    dataEdit: any
    setDataEdit: (data: any) => void
}

const ReviewForm = (props: ReviewFormProps) => {
    const { t } = useTranslation()
    const maxHeight = 1080
    const maxWidth = 1080
    const t_REVIEW = "MANAGE_WEB.REVIEW"

    const [dataEdit, setDataEdit] = useState<any>(props.dataEdit)
    const [dataEditId, setDataEditId] = useState<any>(props.dataEdit)

    const [score, setScore] = useState<number>(0)
    const [scoreHover, setScoreHover] = useState<number>(0)
    const [editorState, setEditorState] = useState<any>(EditorState.createEmpty())

    const onLoadEdit = () => {
        const data = {
            ...props.dataEdit,
            status: !props.dataEdit?.reviewId && "ACTIVE" || props.dataEdit?.status
        }
        setDataEdit(data)
        setDataEditId(data)
        setScore(data?.score || 0)
        if (data?.content) {
            const newHTML: any = data?.content
            const blocksFromHTML = htmlToDraft(newHTML);
            const content = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            setEditorState(EditorState.createWithContent(content))
        }
    }

    useEffect(() => {
        onLoadEdit()
    }, [])

    const renderStarScoreClick = () => {
        const newEmptyArray = Array(5).fill(null);
        const onClickStar = (scoreCurrent: number) => {
            if (score === scoreCurrent) {
                setScore(0)
            } else {
                setScore(scoreCurrent)
            }
        }
        const renderStar = newEmptyArray.map((item: any, index: number) => {
            console.log('renderStar renderStar renderStar')
            return (
                <BoxStarSelect
                    key={index}
                    onClick={() => onClickStar(index + 1)}
                >
                    <Box
                        onMouseEnter={() => setScoreHover(index + 1)}
                        onMouseLeave={() => setScoreHover(0)}
                        className="content-star-select"
                        sx={{
                            'svg path': {
                                transition: `all ${0.02 * index + 1}s`,
                            }
                        }}
                    >
                        <StarActive
                            stroke='#F6E331'
                            fill={(scoreHover !== 0 ? scoreHover : score) < index + 1 ? 'transparent' : '#F6E331'}
                        />
                    </Box>
                    <Box>
                        {index + 1}
                    </Box>

                </BoxStarSelect>

            )
        })
        return renderStar
    }

    const uploadNewsImageSign = (files: any) => {
        if (files[0]) {
            const file = files[0];
            const fileName = file.name.split(".");
            const detailImage = {
                base64: "",
                type: dataEditId?.fileType || "",
                fileExtension: fileName[fileName.length - 1],
                fileSize: file.size.toString(),
                fileId: dataEditId?.reviewId || 0,
            };
            const reader: any = new FileReader();
            reader.addEventListener("load", () => {
                const image = new Image();
                image.src = reader?.result as string;
                image.addEventListener("load", () => {
                    const width = image.width;
                    const height = image.height;

                    if (maxHeight < height || maxWidth < width) {
                        const errorWidth = maxWidth < width && ` ${t('MANAGE_WEB.BANNER.ERROR_WIDTH')} ${maxWidth} ` || ''
                        const errorHeight = maxHeight < height && ` ${t('MANAGE_WEB.BANNER.ERROR_HEIGHT')} ${maxHeight} ` || ''
                        const error = `${t('MANAGE_WEB.REVIEW.IMAGE')}  ${errorWidth} ${maxHeight < height && maxWidth < width && ` ${t('MANAGE_WEB.CONNECTOR.AND')} ` || ''} ${errorHeight}`
                        notiError(error)
                    } else {
                        const base64 = reader.result.split(",");
                        detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result;
                        const newData = { ...dataEdit, ...detailImage, base64Show: reader.result, }
                        setDataEdit(newData)
                    }
                });
            });
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        renderDataSave()
    }, [score, editorState, dataEdit])

    const renderDataSave = () => {
        let newDataFile = {}
        if (dataEdit?.base64Show) {
            newDataFile = {
                file: {
                    fileId: dataEdit?.reviewId || 0,
                    base64: dataEdit?.base64 || '',
                    type: dataEdit?.fileType || '',
                    fileExtension: dataEdit?.fileExtension || '',
                    fileSize: dataEdit?.fileSize || ''
                }
            }
        }
        const info: ReviewProps = {
            status: dataEdit?.status,
            score: score || 0,
            content: draftToHtml(convertToRaw(editorState?.getCurrentContent())) || '',
            topic: dataEdit?.topic || "",
            subTopic: dataEdit?.subTopic || ""
        }
        const newData: ReviewProps = { ...info, ...newDataFile }
        props?.setDataEdit(newData)
    }

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingRight: '16px' }}>
                <Box sx={{ paddingTop: '8px' }}>
                    <InputTextField
                        label={t(`${t_REVIEW}.LABEL_TOPIC`)}
                        value={dataEdit?.topic}
                        onchange={(e) => {
                            setDataEdit({ ...dataEdit, topic: e.target.value })
                        }}
                    />
                </Box>
                <Box sx={{ marginTop: '16px' }}>
                    <InputTextarea
                        label={t(`${t_REVIEW}.LABEL_SUBTOPIC`)}
                        value={dataEdit?.subTopic}
                        onchange={(e) => {
                            setDataEdit({ ...dataEdit, subTopic: e.target.value })
                        }}
                    />
                </Box>
                <Box sx={{ paddingTop: '8px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ marginTop: '16px' }}>
                        <Box sx={{ fontWeight: '500' }}>
                            {t(`${t_REVIEW}.SCORE_SERVICE`)}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '12px'
                            }}
                        >
                            {renderStarScoreClick()}
                        </Box>
                    </Box>
                    <Box sx={{ marginTop: '16px' }}>
                        <Form className="mx-0 mt-0 p-0  d-inline-flex w-100">
                            <Form.Label className=" mr-4 mb-0" style={{ marginTop: 'auto' }}>
                                {t(`${t_REVIEW}.LABEL_STATUS`)}
                            </Form.Label>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                onChange={() => setDataEdit({ ...dataEdit, status: dataEdit?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' })}
                                checked={dataEdit?.status === 'ACTIVE' ? true : false}
                            />
                        </Form>
                    </Box>
                </Box>
                <Box sx={{ marginTop: '16px', width: '50%' }}>
                    <Box sx={{ fontWeight: '500' }}>
                        {t(`${t_REVIEW}.UP_PIC`)}
                    </Box>
                    <Box sx={{ marginTop: '12px', height: dataEdit?.filePath || dataEdit?.base64Show ? 'auto' : '350px' }}>
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                ".MuiFormControl-root": {
                                    height: '100%'
                                }
                            }}
                        >
                            <UploadImage
                                setValue={uploadNewsImageSign}
                                pathFile={dataEdit?.base64Show || dataEdit?.filePath && `${fileUrl}/${dataEdit?.filePath}/${dataEdit?.fileName}` || ''}
                            />
                            <Box sx={{ color: "#BBBBBB", marginTop: "8px" }}>
                                {'('}{t(`${t_REVIEW}.FILESIZE`)}{` ${maxWidth} x ${maxHeight} px)`}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ marginTop: '48px' }}>
                    <Box sx={{ fontWeight: '500' }}>
                        {t(`${t_REVIEW}.DETAIL`)}
                    </Box>
                    <ContainerEditor sx={{ height: 'auto !important', '.demo-editor': { maxHeight: '500px', minHeight: '250px' } }}>
                        <ManageWebEditor editorState={editorState} setEditorState={setEditorState} />
                    </ContainerEditor>
                </Box>
            </Box>
        </>
    )
}

export default ReviewForm