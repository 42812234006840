import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch } from 'react-redux'
import _ from 'lodash'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import BanksApi from 'api/setting/finances/bank.api'

/** STYLE */
import useStyles, { DisabledCheckBox } from 'features/setting/finances/banks/style'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

/** UTILS || SLICE || CONSTANTS */
import { submitModal, unSubmitModal, showModal, resetModal } from 'app/slice/modal.slice'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import { imgs, onImgError } from 'constants/images'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextField from 'component/Input/InputTextField'
import InputTextSearch from 'component/Input/InputTextSearch'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import UploadImage from 'component/Upload/UploadImage'
import InputCheckbox from 'component/Input/InputCheckbox'
import TableRowCommon from 'component/Table/TableRowCommon'
import { fileUrl } from 'api/api'
import FilterSelect from 'component/Select/FilterSelect'

export const TableWidth = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        '&:nth-of-type(5)': {
          paddingTop: 9
        },
        '&:nth-of-type(6)': {
          paddingTop: 9
        },
        '&:nth-of-type(7)': {
          paddingTop: 9
        }
      }

    }
  }
}))

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}
const initStateErrorMessage = {
  NOT_FOUND_BANK: ``,
  BANK_ALREADY_SYSTEM: ``,
  INVALID_CREATED: ``,
  INVALID_UPDATED: ``,
  IS_DUPLICATE_BANK_SHORTNAME: ``,
  IS_DUPLICATE_BANK_NAME: ``,
  BANK_SHORTNAME: ``,
  BANK_SHORTNAME_STRING_BASE: ``,
  BANK_SHORTNAME_STRING_EMPTY: ``,
  BANK_SHORTNAME_MAXIMUM_LENGTH: ``,
  BANK_SHORTNAME_ANY_REQUIRED: ``,
  BANK_NAME: ``,
  BANK_NAME_STRING_BASE: ``,
  BANK_NAME_STRING_EMPTY: ``,
  BANK_NAME_MAXIMUM_LENGTH: ``,
  BANK_NAME_ANY_REQUIRED: ``,

  BANK_NAME_EN: ``,
  IS_DUPLICATE_BANK_NAME_EN: ``,
  BANK_NAME_EN_STRING_BASE: ``,
  BANK_NAME_EN_STRING_EMPTY: ``,
  BANK_NAME_EN_MAXIMUM_LENGTH: ``,
  BANK_NAME_EN_ANY_REQUIRED: ``
}
export default function Banks() {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [banks, setBanks] = useState([])

  const [bankId, setBankId] = useState(Number)
  const [bankShortname, setBankShortname] = useState('')
  const [bankName, setBankName] = useState('')
  const [hasTransfer, setHasTransfer] = useState('NOT_SUPPORT')
  const [hasCredit, setHasCredit] = useState('NOT_SUPPORT')
  const [hasDebit, setHasDebit] = useState('NOT_SUPPORT')
  const [bankStatus, setBankStatus] = useState('NOT_SUPPORT')
  const [image, setImage] = useState({
    fileId: null,
    base64: '',
    fileExtension: '',
    fileSize: ''
  })
  const [imgData, setImgData]: any = useState()
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('ALL')

  const loadData = useCallback(async () => {

    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await BanksApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setBanks(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, status])

  useEffect(() => {
    loadData()
  }, [loadData])

  const submit = () => {
    if (!bankShortname || !bankName) {
      return setErrorMessage({ ...errorMessage, ...{ BANK_SHORTNAME: !bankShortname ? t(`BANK.MESSAGE.BANK_SHORTNAME`) : '', BANK_NAME: !bankName ? t(`BANK.MESSAGE.BANK_NAME`) : '' } })
    }

    dispatch(submitModal())
    BanksApi.create({ bankShortname, bankName, hasTransfer, hasCredit, hasDebit, image })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t(`BANK.MESSAGE.SUCCESS.CREATE`), '', null, null)
          resetForm()

          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`BANK.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`BANK.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const submitEdit = () => {

    if (!bankShortname || !bankName) {
      return setErrorMessage({ ...errorMessage, ...{ BANK_SHORTNAME: !bankShortname ? t(`BANK.MESSAGE.BANK_SHORTNAME`) : '', BANK_NAME: !bankName ? t(`BANK.MESSAGE.BANK_NAME`) : '' } })
    }

    dispatch(submitModal())
    BanksApi.update(bankId, { bankShortname, bankName, hasTransfer, hasCredit, hasDebit, status: bankStatus, image })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t(`BANK.MESSAGE.SUCCESS.UPDATE`), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`BANK.MESSAGE.${err.message}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`BANK.MESSAGE.${err.message}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  const resetForm = () => {
    setBankId(0)
    setBankShortname('')
    setBankName('')
    setHasDebit('NOT_SUPPORT')
    setHasCredit('NOT_SUPPORT')
    setHasTransfer('NOT_SUPPORT')
    setImgData('')
    setImage({
      fileId: null,
      base64: '',
      fileExtension: '',
      fileSize: ''
    })
    clearErrorMessage()
  }
  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('BANK.ALERT.CONFIRM_STATUS')}</p>`,
      null,
      (res: any) => {
        if (res) {
          BanksApi.updateStatus(id, valueStatus)
            .then((resp) => {
              notiSuccess(t('BANK.MESSAGE.SUCCESS.UPDATE_STATUS'))
              loadData()
            })
            .catch((e) => {
              notiError(t('BANK.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }
  const onDelete = (data: any) => {
    const { bankId: bkId, bankName: bkName } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('BANK.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('BANK.ALERT.CONFIRM_DELETE_', {
        bankName: bkName
      })}</p>`,
      (res: any) => {
        if (res) {
          BanksApi.remove(bkId)
            .then((resp) => {
              notiSuccess(t('BANK.MESSAGE.SUCCESS.DELETE'))
              loadData()
            })
            .catch((e) => {
              notiError(t('BANK.MESSAGE.ERROR'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
    )
  }

  const onEdit = (data: any) => {
    const { bankId: bkId, bankShortname: bkShortName, bankName: bkName, hasDebit: bkHasDebit, hasCredit: bkHasCredit, hasTransfer: bkHasTransfer, status: bkStatus, image: bkImage } = data
    setBankId(bkId)
    setBankShortname(bkShortName)
    setBankName(bkName)
    setHasDebit(bkHasDebit)
    setHasCredit(bkHasCredit)
    setHasTransfer(bkHasTransfer)
    setBankStatus(bkStatus)
    if (bkImage) {
      setImgData(`${fileUrl}/${bkImage.filePath}/${bkImage.fileName}`)
      setImage({ fileId: data.image.fileId, base64: '', fileExtension: '', fileSize: '' })
    }
    dispatch(showModal())
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const handleChangeStatus = (statusVal: string) => {
    setStatus(statusVal)
  }

  const uploadImage = (files: any) => {
    if (files[0]) {
      const file = files[0]
      const fileName = file.name.split('.')
      const detailImage = {
        fileId: null,
        base64: '',
        fileExtension: fileName[fileName.length - 1],
        fileSize: file.size.toString()
      }
      const reader: any = new FileReader()
      reader.addEventListener('load', () => {
        const base64 = reader.result.split(',')
        setImgData(reader.result)
        detailImage.base64 = base64.length > 0 ? base64[base64.length - 1] : reader.result
      })
      reader.readAsDataURL(file)
      setImage(detailImage)
    }
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'bankLogo', disablePadding: false, align: 'center', label: t('BANK.TABLE.CELL.BANK_LOGO') },
    { id: 'bankShortname', disablePadding: false, label: t('BANK.TABLE.CELL.BANK_SHORTNAME') },
    { id: 'bankName', disablePadding: false, label: t('BANK.TABLE.CELL.BANK_NAME') },
    { id: 'hasTransfer', disablePadding: false, align: 'center', label: t('BANK.TABLE.CELL.HAS_TRANSFER') },
    { id: 'hasDebit', disablePadding: false, align: 'center', label: t('BANK.TABLE.CELL.HAS_CREDIT') },
    { id: 'hasCredit', disablePadding: false, align: 'center', label: t('BANK.TABLE.CELL.HAS_DEBIT') },
    { id: 'status', disablePadding: false, label: t('BANK.TABLE.CELL.STATUS') },
    { id: 'updatedBy', disablePadding: false, label: t('BANK.TABLE.CELL.UPDATED_BY') },
    { id: 'action', disablePadding: false, label: 'Action' }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { bankId: bkId, bankShortname: bkShortName, bankName: bkName, hasTransfer: bkHasTransfer, hasDebit: bkHasDebit, hasCredit: bkHasCredit, updatedBy, updatedAt, status: bkStatus, image: bkImage, bankLogo: bkLogo } = objData

    const statusBtnActive = bkStatus === 'ACTIVE' ? true : permissions.isUpdate.disabled
    const statusBtnInActive = bkStatus === 'INACTIVE' ? true : permissions.isUpdate.disabled
    const renderImage = (img: any, valueBkLogo: string) => {
      if (!_.isEmpty(img) && !_.isEmpty(img.filePath) && !_.isEmpty(img.fileName)) return `${fileUrl}/${img.filePath}/${img.fileName}`
      else if (valueBkLogo) return `${fileUrl}/${valueBkLogo}`
      else return imgs.errNoImg
    }
    const objRenderData = {
      key: bkId,
      id: bkId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        {
          option: 'COMPONENT', align: 'center', component: (
            <div >
              <img style={{ width: '35px', height: '35px', objectFit: 'cover' }} src={renderImage(bkImage, bkLogo)} onError={onImgError} alt="" />
            </div>
          )
        },
        { option: 'TEXT', align: 'left', label: bkShortName },
        { option: 'TEXT', align: 'left', label: bkName },
        {
          option: 'COMPONENT', align: 'center', component: (
            <DisabledCheckBox>
              <InputCheckbox disabled value={bkHasTransfer} checked={bkHasTransfer === 'SUPPORT' ? true : false} />
            </DisabledCheckBox>
          )
        },
        {
          option: 'COMPONENT', align: 'center', component: (
            <DisabledCheckBox>
              <InputCheckbox disabled value={bkHasCredit} checked={bkHasCredit === 'SUPPORT' ? true : false} />
            </DisabledCheckBox>
          )
        },
        {
          option: 'COMPONENT', align: 'center', component: (
            <DisabledCheckBox>
              <InputCheckbox disabled value={bkHasDebit} checked={bkHasDebit === 'SUPPORT' ? true : false} />
            </DisabledCheckBox>
          )
        },
        { option: 'STATUS', align: 'center', label: bkStatus },
        { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ]
    }
    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
        onedit={() => onEdit(objRenderData.obj)}
        ondelete={() => onDelete(objRenderData.obj)}
      />
    )
  }

  return (
    <div className={classes.root}>
      <Card className="border-0 h-100">
        <HeaderCard text={t('BANK.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row className="align-items-center">
              <Col md={8}>
                <Row className="flax-row">
                  <Col sm={12} md={6} xl={4} className="pt-2">
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('INPUT.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Col>
                  <Col sm={12} md={6} xl={4} className="pl-md-0 pt-2">
                    <FilterSelect
                      onchange={(event) => {
                        const value = event.target.value
                        if (value) {
                          handleChangeStatus(value)
                          setPage(1)
                        }
                      }}
                      renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                      label={t('STATUS.LABEL')}
                      selectId="select-status"
                      value={status}
                      labelId="label-status"
                      options={[
                        <MenuItem key="1" value="ALL">
                          {t('STATUS.ALL')}
                        </MenuItem>,
                        <MenuItem key="2" value="ACTIVE">
                          {t('STATUS.ACTIVE')}
                        </MenuItem>,
                        <MenuItem key="3" value="INACTIVE">
                          {t('STATUS.INACTIVE')}
                        </MenuItem>
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mt-2 mt-md-auto">
                <div className="pr-xl-2">
                  <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => dispatch(showModal())} textButton={t('BANK.BUTTON.ADD')} className="w-auto ml-auto d-flex mt-auto" />
                </div>
              </Col>
            </Row>
          </Card.Title>

          <TableWidth className={'mt-3 pl-xl-3 pr-xl-2'}>
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              onSort={onRequestSort}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={banks.map((val, i) => {
                return renderData(val, i)
              })}
            />
          </TableWidth>
        </Card.Body>
      </Card>

      <ModalCustom
        modalStyle={classes.modal}
        title={bankId ? t('BANK.TITLE_UPDATE') : t('BANK.TITLE_CREATE')}
        component={
          <div className="pb-2">
            {bankId ? (
              <div>
                <Form className="mx-0 mt-0 mb-3 p-0  d-inline-flex w-100">
                  <Form.Label className=" mr-auto">{t('INPUT.LABEL_STATUS')}</Form.Label>
                  <Form.Check type="switch" id="custom-switch" onChange={() => setBankStatus(bankStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')} checked={bankStatus === 'ACTIVE' ? true : false} style={{ marginLeft: 'auto' }} />
                </Form>
              </div>
            ) : (
              ''
            )}

            <Row>
              <Col sm={12}>
                <UploadImage setValue={uploadImage} pathFile={imgData} />
              </Col>
              <Col sm={12}>
                <InputTextField
                  onchange={(event) => {
                    setBankShortname(event.target.value)
                    clearErrorMessage()
                  }}
                  inputProps={{ maxLength: 100 }}
                  value={bankShortname}
                  label={t('BANK.INPUT.BANK_SHORTNAME')}
                  helperText={
                    errorMessage.BANK_SHORTNAME ||
                    errorMessage.IS_DUPLICATE_BANK_SHORTNAME ||
                    errorMessage.BANK_SHORTNAME_STRING_BASE ||
                    errorMessage.BANK_SHORTNAME_STRING_EMPTY ||
                    errorMessage.BANK_SHORTNAME_MAXIMUM_LENGTH ||
                    errorMessage.BANK_SHORTNAME_ANY_REQUIRED
                  }
                  required={true}
                  style={{ margin: '1rem 0' }}
                />
              </Col>
              <Col sm={12}>
                <InputTextField
                  onchange={(event) => {
                    setBankName(event.target.value)
                    clearErrorMessage()
                  }}
                  inputProps={{ maxLength: 150 }}
                  value={bankName}
                  label={t('BANK.INPUT.BANK_NAME')}
                  helperText={errorMessage.BANK_NAME ||
                    errorMessage.IS_DUPLICATE_BANK_NAME ||
                    errorMessage.BANK_NAME_STRING_EMPTY ||
                    errorMessage.BANK_NAME_STRING_EMPTY ||
                    errorMessage.BANK_NAME_MAXIMUM_LENGTH ||
                    errorMessage.BANK_NAME_ANY_REQUIRED}
                  required={true}
                  style={{ marginBottom: '1rem' }}
                />
              </Col>

              <Col sm={12}>{t('BANK.LABEL.SERVICE')}</Col>
              <Col sm={12} className=" d-lg-flex flex-sm-row ">
                <InputCheckbox
                  label={t('BANK.LABEL.TRANSFER')}
                  className={classes.inputCheckBox}
                  checked={hasTransfer === 'SUPPORT' ? true : false}
                  onChange={(event) => {
                    setHasTransfer(event.target.checked ? 'SUPPORT' : 'NOT_SUPPORT')
                  }}
                />
                <InputCheckbox
                  className={classes.inputCheckBox}
                  label={t('BANK.LABEL.CREDIT')}
                  checked={hasCredit === 'SUPPORT' ? true : false}
                  onChange={(event) => {
                    setHasCredit(event.target.checked ? 'SUPPORT' : 'NOT_SUPPORT')
                  }}
                />
                <InputCheckbox
                  className={classes.inputCheckBox}
                  label={t('BANK.LABEL.DEBIT')}
                  checked={hasDebit === 'SUPPORT' ? true : false}
                  onChange={(event) => {
                    setHasDebit(event.target.checked ? 'SUPPORT' : 'NOT_SUPPORT')
                  }}
                />
              </Col>
            </Row>
          </div>
        }
        onReset={resetForm}
        onSubmit={bankId ? submitEdit : submit}
        textBtnCancel={t('BANK.BUTTON.CANCEL')}
        textBtnConfirm={t('BANK.BUTTON.SAVE')}
      />
    </div>
  )
}
