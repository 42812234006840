import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { MenuItem, Box } from '@mui/material';
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import AdjustmentApi, { APPROVE_STATUS } from 'api/warehouses/adjustment.api'

/** COMPONENT */
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import FilterSelect from 'component/Select/FilterSelect'
import ButtonCustom from 'component/Button/ButtonCustom'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon';
import { swalActive } from 'component/Alert/Swal'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import InputSecondNewRangePicker from 'component/Input/InputSecondNewRangePicker'
import StatusSelect from 'features/finance/income-expenses/statusSelector-income-expenses'

/** UTILS || SLICE || CONSTANTS */
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import { colors } from 'constants/theme'
import { routeName } from 'routes/routes-name'
import { toBuddhistYear } from 'utils/app.utils'

/** STYLE */
import * as UseStyled from 'features/warehouses/adjustment/styled'

const permissions = {
    isCreate: isCreate(),
    isDelete: isDelete(),
    isUpdate: isUpdate(),
    isView: isView()
}

export default function Adjustment() {
    const { t } = useTranslation()
    const history = useHistory()

    /** PAGINATION  */
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('date')
    const [sortType, setSortType] = useState('DESC')
    const [search, setSearch] = useState('')
    const [status, setStatus] = useState('ALL')
    const [rangeDate, setRangeDate] = useState(['', '']);

    const [adjustments, setAdjustments] = useState([]);

    const loadData = useCallback(async () => {
        let condition: any = {}
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (search) condition = { ...condition, search: search }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }
        if (rangeDate) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }

        const resp = await AdjustmentApi.findAll(condition)
        if (resp.status === 200) {
            setAdjustments(resp.data)
            setRowCount(resp.headers['x-total'])
        }
    }, [page, pageLimit, rowCount, sortBy, sortType, search, status, rangeDate])

    const handleChangeStatus = (statusVal: string) => {
        setStatus(statusVal)
    }

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangeRowsPerPage = (limit: number) => {
        setPageLimit(limit)
    }

    const handleChangePage = (val: number) => {
        setPage(val)
    }

    const onEdit = (id: number) => {
        history.push(routeName.adjustment + '/edit', { adjustmentId: id })
    }

    const onUpdateStatus = (id: number, subject: string, valueStatus: APPROVE_STATUS) => {
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('ADJUSTMENT.ALERT.CONFIRM_STATUS')}</p>`,
            `<p class="text-left mb-0 mx-auto" style="max-width: 306px">${t('ADJUSTMENT.ALERT.CONFIRM_STATUS_', {
                subject: subject || '-',
                status: t(`ADJUSTMENT.FILTER.DOCUMENT_STATUS.${valueStatus}`)
            })}</p>`,
            (res: any) => {
                if (res) {
                    AdjustmentApi.updateAdjustmentStatus(id, valueStatus)
                        .then((resp) => {
                            notiSuccess(t('ADJUSTMENT.MESSAGE.SUCCESS.UPDATE_STATUS'))
                            loadData()
                        })
                        .catch((e) => {
                            notiError(t('ADJUSTMENT.MESSAGE.ERROR.DEFAULT'))
                        })
                }
            },
            'question', t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
        )
    }

    useEffect(() => {
        loadData()
    }, [loadData]);

    const backgroundStatus = (status: APPROVE_STATUS) => {
        if (status === APPROVE_STATUS.APPROVE) return colors.approveStatus.approve
        else if (status === APPROVE_STATUS.PENDING) return colors.approveStatus.pending
        else if (status === APPROVE_STATUS.CANCEL) return colors.approveStatus.cancel
        else return colors.disabledLightGray
    }

    const headCells = [
        { id: 'no', disablePadding: false, label: '#', width: '50px' },
        { id: 'message', disablePadding: false, label: t('ADJUSTMENT.TABLE.CELLS.DATE'), width: '150px' },
        { id: 'message', disablePadding: false, label: t('ADJUSTMENT.TABLE.CELLS.NAME') },
        { id: 'message', disablePadding: false, label: t('ADJUSTMENT.TABLE.CELLS.STATUS'), width: '150px' },
        { id: 'message', disablePadding: false, label: t('ADJUSTMENT.TABLE.CELLS.APPROVER'), width: '200px' },
        { id: 'updatedBy', disablePadding: false, label: t('ADJUSTMENT.TABLE.CELLS.UPDATED_BY'), width: '180px' },
        { id: 'action', disablePadding: false, label: 'Action', width: '100px' }
    ]

    const renderData = (objData: any, no: number) => {
        const { adjustmentReceiptId, date, subject, status: adjustmentStatus, updatedBy, updatedAt, createdBy, createdAt } = objData

        no = page * pageLimit - pageLimit + no + 1
        const objRenderData = {
            key: no.toString(),
            id: no.toString(),
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: no },
                { option: 'TEXT', align: 'left', label: date && date !== '0000-00-00' ? toBuddhistYear(moment(date), 'DD/MM/YYYY') : '-' },
                { option: 'TEXT', align: 'left', label: subject || '-', class: 'text-ellipsis' },
                {
                    option: 'COMPONENT',
                    align: 'left',
                    class: 'py-1',
                    component: (
                        <Box
                            className={`mr-3 ${adjustmentStatus === APPROVE_STATUS.APPROVE || adjustmentStatus === APPROVE_STATUS.CANCEL ? 'pe-none' : ''}`}
                            sx={{
                                maxWidth: '105px',
                                '.MuiSelect-select': {
                                    display: 'block !important',
                                    height: 'fit-content !important'
                                }
                            }}>
                            <StatusSelect
                                onchange={(event) => {
                                    const value = event.target.value
                                    onUpdateStatus(adjustmentReceiptId, subject, value)
                                }}
                                label={''}
                                selectId="select-status"
                                value={adjustmentStatus}
                                labelId="label-status"
                                statusSelectorBgColor={backgroundStatus(adjustmentStatus)}
                                disable={permissions.isUpdate.disabled || adjustmentStatus === APPROVE_STATUS.APPROVE || adjustmentStatus === APPROVE_STATUS.CANCEL}
                                height={'32px'}
                            />
                        </Box>
                    )
                },
                { option: 'TEXT', align: 'left', label: updatedBy || createdBy, class: 'text-ellipsis' },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy || createdBy, updatedAt: updatedAt || createdAt } },
                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: [
                        adjustmentStatus === APPROVE_STATUS.PENDING ?
                            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled } :
                            { option: 'VIEW', label: t(`BUTTON.VIEW`), disabled: permissions.isView.disabled },
                    ]
                }
            ]
        }

        return <TableRowCommon  {...objRenderData} onedit={() => onEdit(adjustmentReceiptId)} onView={() => onEdit(adjustmentReceiptId)} />
    }

    return (
        <Card className={'border-0 h-100'}>
            <HeaderCard text={t('ADJUSTMENT.HEADER')} />
            <Card.Body>
                <Card.Title className={'mb-0 pl-xl-3'}>
                    <Row>
                        <Col lg={9}>
                            <Row>
                                <Col sm={12} md={6} lg={4} className={'pt-2'}>
                                    <InputTextSearch
                                        keyInput={'search'}
                                        label={t('INPUT.SEARCH')}
                                        value={search}
                                        onchange={(event) => {
                                            setSearch(event.target.value)
                                            setPage(1)
                                        }}
                                        onClear={(event) => {
                                            setSearch('')
                                            setPage(1)
                                        }}
                                    />
                                </Col>
                                <Col sm={12} md={6} lg={4} className={'pl-md-0 pt-2'}>
                                    <FilterSelect
                                        onchange={(event) => {
                                            const value = event.target.value
                                            if (value) {
                                                handleChangeStatus(value)
                                                setPage(1)
                                            }
                                        }}
                                        renderValue={() => t('ADJUSTMENT.FILTER.DOCUMENT_STATUS.LABEL') + ': ' + t(`ADJUSTMENT.FILTER.DOCUMENT_STATUS.${status}`)}
                                        label={t('ADJUSTMENT.FILTER.DOCUMENT_STATUS.LABEL')}
                                        selectId="select-status"
                                        value={status}
                                        labelId="label-status"
                                        options={[
                                            <MenuItem key="1" value="ALL">
                                                {t('STATUS.ALL')}
                                            </MenuItem>,
                                            <MenuItem key="1" value={APPROVE_STATUS.APPROVE}>
                                                {t(`ADJUSTMENT.FILTER.DOCUMENT_STATUS.${APPROVE_STATUS.APPROVE}`)}
                                            </MenuItem>,
                                            <MenuItem key="1" value={APPROVE_STATUS.PENDING}>
                                                {t(`ADJUSTMENT.FILTER.DOCUMENT_STATUS.${APPROVE_STATUS.PENDING}`)}
                                            </MenuItem>,
                                            <MenuItem key="1" value={APPROVE_STATUS.CANCEL}>
                                                {t(`ADJUSTMENT.FILTER.DOCUMENT_STATUS.${APPROVE_STATUS.CANCEL}`)}
                                            </MenuItem>
                                        ]}
                                        formControlStyle={{ maxWidth: 'unset !important' }}
                                    />
                                </Col>
                                <Col sm={12} md={6} lg={4} className="pl-lg-0 pt-2">
                                    <InputSecondNewRangePicker
                                        allowClear
                                        value={rangeDate}
                                        onchange={(value: any) => {
                                            setPage(1)
                                            setRangeDate([value[0] ? moment(value[0]).format('YYYY-MM-DD') : '', value[1] ? moment(value[1]).format('YYYY-MM-DD') : ''])
                                        }}
                                        onClear={() => {
                                            setPage(1)
                                            setRangeDate(['', ''])
                                        }}
                                        inputHeight={32}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={3} className="mt-2 mt-lg-auto">
                            <div className="pr-xl-2">
                                <ButtonCustom mode="add" onClick={() => history.push(routeName.adjustment + '/create')} textButton={t('ADJUSTMENT.BUTTON.CHANGE')} className="w-auto ml-auto d-flex mt-auto" />
                            </div>
                        </Col>
                    </Row>
                </Card.Title>

                <UseStyled.ResponsiveTable className={'mt-3 pl-xl-3 pr-xl-2'}>
                    <TableCustom
                        page={page}
                        pageLimit={pageLimit}
                        sortType={sortType}
                        sortBy={sortBy}
                        rowCount={rowCount}
                        onSort={onRequestSort}
                        setPageLimit={handleChangeRowsPerPage}
                        setPage={handleChangePage}
                        headCells={headCells}
                        rowsData={(
                            _.map(adjustments, (item, index: number) => {
                                return renderData(item, index)
                            })
                        )}
                        tableFixedWidth
                        tableMinWidth={1200}
                    />
                </UseStyled.ResponsiveTable>
            </Card.Body>
        </Card>
    )
}
