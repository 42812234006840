import { menuSeconds } from 'constants/menus'
import { routeName } from 'routes/routes-name'
import News from 'features/application/news/news'
import FormNews from 'features/application/news/form-news'
import ManageWeb from 'features/application/manage-web/ManageWeb'

const routeNews = [
  {
    ...menuSeconds.NEWS,
    key: menuSeconds.NEWS.key,
    name: menuSeconds.NEWS.name,
    textName: menuSeconds.NEWS.textName,
    active: false,
    appBar: true,
    component: News
  },
  {
    ...menuSeconds.NEWS,
    packageKey: menuSeconds.NEWS.packageKey,
    path: routeName.news + '/create',
    component: FormNews,
    appBar: true
  },
  {
    ...menuSeconds.NEWS,
    packageKey: menuSeconds.NEWS.packageKey,
    path: routeName.news + '/edit',
    component: FormNews,
    appBar: true
  }
]

export default routeNews
