import { useCallback, useEffect, useState } from 'react'
import { numberFormat } from 'utils/app.utils'
import { ExportProps } from 'features/report/report-constants'
import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import _ from 'lodash'
import * as UseStyled from 'features/report/useStyled'
import { Box, Divider, Typography, TableCell, TableRow } from '@mui/material'
import { colors } from 'constants/theme'
import { dateTimeToView } from 'utils/date.utils'
import { useTranslation } from 'react-i18next'
import { UseSnCode } from 'utils/useSnCode'
export interface FcComponent {
  page: number
  pageLimit: number
  rowCount: number
  sortBy: string
  sortType: string
  data: any
  onRequestSort: (sortBy: string, sortType: string) => void
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
  componentRef: any
  exportData: any
  billStatus: string
  tableFilter: string[][]
}

export default function TransactionRevenueList(props: FcComponent) {
  const { t } = useTranslation()
  const [snType, setSnType] = useState(false)

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])

  const numberNonDigit = (number: any) => {
    const format = Intl.NumberFormat('th-TH', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })

    return format.format(number)
  }

  const headCells = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.DATE', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.RECEIPT_STATUS', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.BILL_ID', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.PATIENT_CN_NUMBER', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.PATIENT_BRANCH_CN_NUMBER', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.PATIENT_SN_CODE', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.PATIENT_NAME', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.DOCTOR', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.OPERATIVE_TYPE', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.OPERATIVE_CODE', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.OPERATIVE_LIST', align: 'left', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.PRICE_UNIT', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.QTY', align: 'center', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.TREATMENT_PRICE', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.DF_PERCENT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.NET_DF', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.CF_PERCENT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.NET_CF', align: 'right', class: 'text-nowrap' },

    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.DF_TREATMENT_RIGHT_DISCOUNT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.CF_TREATMENT_RIGHT_DISCOUNT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.NET_DISCOUNT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.NET_ADDITIONAL', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.NET_DF_AMOUNT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.NET_CF_AMOUNT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.NET_AMOUNT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.IS_PAY', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.IS_PAY_OVERDUE', align: 'right', class: 'text-nowrap' },

    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.IS_PAY_ADVANCE', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.IS_NET_PAY', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.IS_PAY_WITH_CASH', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.IS_PAY_WITH_TRANSFER', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.IS_PAY_WITH_CREDIT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.IS_PAY_WITH_DEBIT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.CREDIT_DF', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.CREDIT_CF', align: 'right', class: 'text-nowrap' },

    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.TREATMENT_RIGHT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.CLEAR_ADVANCE', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.IS_PAY_DF', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.IS_PAY_CF', align: 'right', class: 'text-nowrap' },

    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.CREDIT_PERCENT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.NET_CREDIT_PERCENT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.DF_AMOUNT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.CF_AMOUNT', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.DF_OVERDUE', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.CF_OVERDUE', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.BALANCE', align: 'right', class: 'text-nowrap' },

    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.STAFF', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.REMARK', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.CANCEL_REMARK', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.CANCEL_BY', align: 'left', class: 'text-nowrap' }
  ]
  if (!snType) {
    headCells.splice(5, 1)
  }
  const headSummaryCells = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.DETAIL', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.TOTAL_NET', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.DETAIL', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.TOTAL_NET', align: 'right', class: 'text-nowrap' }
  ]

  const headALLSummaryCells = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.DETAIL', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.TOTAL_NET', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.NOT_SUM_CANCEL', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.SUM_CANCEL', align: 'right', class: 'text-nowrap' }
  ]

  const headSummaryOverdue = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.DETAIL', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.TRANSACTION_REVENUE.TOTAL_NET', align: 'right', class: 'text-nowrap' }
  ]

  const renderData = (data: any, no: number, exportData: boolean, render: 'data' | 'sum') => {
    const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
    if (render === 'data') {
      const objRenderData = {
        key: num.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'TEXT', align: 'left', label: dateTimeToView(data.datePaid) || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', label: t(`REPORT.BILL_STATUS.${data.billStatus}`) || '-' },
          { option: 'TEXT', align: 'left', label: data.billNo || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', label: data.cnNumber || '-' },
          { option: 'TEXT', align: 'left', label: data.branchCnNumber || '-' },
          { option: 'TEXT', align: 'left', label: data.snCode || '-' },
          { option: 'TEXT', align: 'left', label: data.patientFullname || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', label: data.doctorFullname || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', label: data.itemTypeName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', label: data.code || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', label: data.itemName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.price) || '-' },
          { option: 'TEXT', align: 'center', label: numberNonDigit(data.qty) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.price * data.qty) || '-' },
          { option: 'TEXT', align: 'right', label: data?.dfUnit === 'PERCENT' ? data.dfPercentOriginal + '%' : data.dfBahtOriginal + 'บาท' || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.dfOriginal) || '-' },
          { option: 'TEXT', align: 'right', label: data?.dfUnit === 'PERCENT' ? numberNonDigit(data.cfPercentOriginal) + '%' : numberNonDigit(data.cfPercentOriginal) + 'บาท' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.cfOriginal) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.dfDiscount) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.cfDiscount) || '-' },

          { option: 'TEXT', align: 'right', label: numberFormat(data.discountTotal) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.additionalTotal) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.dfNetOriginal) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.cfNetOriginal) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.total) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.amountPaid) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.amountPaidOverdue) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.amountPaidAdvance) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.amountPaid) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.amountCash) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.amountTransfer) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.amountCredit) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.amountDebit) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.dfAmountCredit) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.cfAmountCredit) || '-' },

          { option: 'TEXT', align: 'right', label: numberFormat(data.amountTrementRight) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.amountAdvance) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.dfPaid) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.cfPaid) || '-' },

          { option: 'TEXT', align: 'right', label: numberFormat(data.creditCardFee, 3, 3) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.creditCost, 3, 3) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.dfNet) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.cfNet) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.dfOverdue) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.cfOverdue) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.overdue) || '-' },

          { option: 'TEXT', align: 'left', label: data.staff || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', label: data.remark || '-', class: 'text-ellipsis' },
          { option: 'TEXT', align: 'left', label: data.cancelRemark || '-', class: 'text-ellipsis' },
          { option: 'TEXT', align: 'left', label: data.cancelBy || '-', class: 'text-nowrap' }
        ]
      }

      if (!snType) {
        objRenderData.columns.splice(5, 1)
      }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    } else {
      const objRenderData = {
        key: no.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'MERGE', align: 'center', colSpan: snType ? 10 : 9 },
          { option: 'TEXT', align: 'right', label: <Typography sx={{ fontWeight: 500 }}>{t('REPORT.TITLE.SUM')}</Typography>, class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'price')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', label: numberNonDigit(_.sumBy(data, 'qty')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'total')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'dfOriginal')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberNonDigit(_.sumBy(data, 'cfPercentOriginal')) + '%', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'cfOriginal')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'dfDiscount')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'cfDiscount')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'discountTotal')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'additionalTotal')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'dfNetOriginal')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'cfNetOriginal')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'total')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'amountPaid')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'amountPaidOverdue')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'amountPaidAdvance')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'amountPaid')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'amountCash')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'amountTransfer')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'amountCredit')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'amountDebit')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'dfAmountCredit')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'cfAmountCredit')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'amountTrementRight')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'amountAdvance')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'dfPaid')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'cfPaid')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'creditCardFee')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'creditCost')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'dfNet')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'cfNet')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'dfOverdue')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'cfOverdue')), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, 'overdue')), class: 'text-nowrap' }
        ],
        classes: 'row-summary'
      }

      return <TableRowExport {...objRenderData} />
    }
  }

  const renderDataFilter = (data: any, no: number) => {
    const obj = data.map((item: string) => {
      return { option: 'TEXT', align: 'left', label: item || '-', class: 'text-nowrap' }
    })
    const objRenderData = {
      key: no.toString(),
      id: no.toString(),
      obj: data,
      columns: [...obj]
    }

    return <TableRowExport {...objRenderData} />
  }

  const renderCountBill = (data: any, no: number, exportData: boolean) => {
    const { countPatient, countDate, countBillNo, dfNet, cfNet, creditCost } = data
    return (
      <>
        <TableRow>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.COUNT_PATIENT')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {numberNonDigit(countPatient) || 0}
          </TableCell>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.SUM_DF')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {numberFormat(dfNet) || 0}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.COUNT_DATE')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {numberNonDigit(countDate) || 0}
          </TableCell>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.SUM_CF')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {numberFormat(cfNet) || 0}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.COUNT_BILL')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {numberNonDigit(countBillNo) || 0}
          </TableCell>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.SUM_CREDIT')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {numberFormat(creditCost, 3, 3) || 0}
          </TableCell>
        </TableRow>
      </>
    )
  }

  const renderSummaryAdvance = (data: any, no: number, exportData: boolean) => {
    const { amountPaidAdvance, amountAdvance, amountAdvanceCash, amountAdvanceDebit, amountAdvanceCredit, amountAdvanceTransfer, amountPaidAdvanceCash, amountPaidAdvanceTransfer } = data
    return (
      <>
        <TableRow>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.RECEIVE_ADVANCE')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {numberFormat(amountPaidAdvance || 0)}
          </TableCell>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.SUME_ADVANCE')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {numberFormat(amountAdvance || 0)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.CASH')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {amountPaidAdvance === '0' ? numberFormat(0) : numberFormat(amountAdvanceCash || 0)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.TRANSFER')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {amountPaidAdvance === '0' ? numberFormat(0) : numberFormat(amountAdvanceTransfer || 0)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.DEBIT')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {amountPaidAdvance === '0' ? numberFormat(0) : numberFormat(amountAdvanceDebit || 0)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.CREDIT')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {amountPaidAdvance === '0' ? numberFormat(0) : numberFormat(amountAdvanceCredit || 0)}
          </TableCell>
        </TableRow>
      </>
    )
  }

  const renderSummaryAmountPait = (data: any, no: number, exportData: boolean) => {
    const {
      amountPaidOverdue,
      amountPaid,
      amountCash,
      amountTransfer,
      amountCredit,
      amountDebit,
      amountAdvanceCash,
      amountAdvanceTransfer,
      amountAdvanceDebit,
      amountAdvanceCredit,
      amountTrementRight,
      amountAdvance,
      amountPaidOverdueCash,
      amountPaidOverdueTransfer,
      amountPaidOverdueCredit,
      amountPaidOverdueDebit,
      amountPaidOverdueTrementRight,
      amountPaidOverdueAdvance
    } = data
    return (
      <>
        <TableRow>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.SUM_OVERDUE')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {numberFormat(amountPaidOverdue || 0)}
          </TableCell>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.SUM_TREATMENT')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {numberFormat(amountPaid || 0)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.CASH')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {amountPaidOverdue === 0 ? numberFormat(0) : numberFormat(amountPaidOverdueCash || 0)}
          </TableCell>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.CASH')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {amountPaid === 0 ? numberFormat(0) : numberFormat(parseFloat(amountCash) - parseFloat(amountAdvanceCash) || 0)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.TRANSFER')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {amountPaidOverdue === 0 ? numberFormat(0) : numberFormat(amountPaidOverdueTransfer || 0)}
          </TableCell>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.TRANSFER')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {amountPaid === 0 ? numberFormat(0) : numberFormat(parseFloat(amountTransfer) - parseFloat(amountAdvanceTransfer) || 0)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.DEBIT')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {amountPaidOverdue === 0 ? numberFormat(0) : numberFormat(amountPaidOverdueCredit || 0)}
          </TableCell>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.DEBIT')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {amountPaid === 0 ? numberFormat(0) : numberFormat(amountDebit || 0)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.CREDIT')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {amountPaidOverdue === 0 ? numberFormat(0) : numberFormat(amountPaidOverdueDebit || 0)}
          </TableCell>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.CREDIT')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {amountPaid === 0 ? numberFormat(0) : numberFormat(amountCredit - amountAdvanceCredit || 0)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.TREATMENT')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {amountPaidOverdue === 0 ? numberFormat(0) : numberFormat(amountPaidOverdueTrementRight || 0)}
          </TableCell>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.TREATMENT')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {amountPaid === 0 ? numberFormat(0) : numberFormat(amountTrementRight || 0)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.PAID_ADVANCE')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {amountPaidOverdue === 0 ? numberFormat(0) : numberFormat(amountPaidOverdueAdvance || 0)}
          </TableCell>
          <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {t('REPORT.TABLE.TRANSACTION_REVENUE.PAID_ADVANCE')}
          </TableCell>
          <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
            {amountPaid === 0 ? numberFormat(0) : numberFormat(amountAdvance || 0)}
          </TableCell>
        </TableRow>
      </>
    )
  }

  const calculateAllSummary = (billStatus: string, data: any, key: 'amountPaid' | 'amountCash' | 'amountTransfer' | 'amountDebit' | 'amountCredit' | 'amountTrementRight' | 'amountAdvance') => {
    if (data.length > 0) return _.map(data, (expData: any) => expData[key]).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
    else return 0
  }

  const calculateAllSummaryCancel = (billStatus: string, data: any, key: 'amountPaid' | 'amountCash' | 'amountTransfer' | 'amountDebit' | 'amountCredit' | 'amountTrementRight' | 'amountAdvance') => {
    if (data.length > 0)
      return _.map(data, (expData: any) => {
        if (expData.billStatus === 'CANCEL' && expData[key]) return expData[key]
        else return 0
      }).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
    else return 0
  }

  const allSummary = (exportData: boolean) => {
    if (props.exportData.rows !== undefined) {
      const sumFrontDesk = calculateAllSummary(props.billStatus, props.exportData.rows, 'amountPaid')
      const cash = calculateAllSummary(props.billStatus, props.exportData.rows, 'amountCash')
      const transfer = calculateAllSummary(props.billStatus, props.exportData.rows, 'amountTransfer')
      const debit = calculateAllSummary(props.billStatus, props.exportData.rows, 'amountDebit')
      const credit = calculateAllSummary(props.billStatus, props.exportData.rows, 'amountCredit')
      const treatment = calculateAllSummary(props.billStatus, props.exportData.rows, 'amountTrementRight')
      const paidAdvance = calculateAllSummary(props.billStatus, props.exportData.rows, 'amountAdvance')

      const sumFrontDeskCancel = calculateAllSummaryCancel(props.billStatus, props.exportData.rows, 'amountPaid')
      const cashCancel = calculateAllSummaryCancel(props.billStatus, props.exportData.rows, 'amountCash')
      const transferCancel = calculateAllSummaryCancel(props.billStatus, props.exportData.rows, 'amountTransfer')
      const debitCancel = calculateAllSummaryCancel(props.billStatus, props.exportData.rows, 'amountDebit')
      const creditCancel = calculateAllSummaryCancel(props.billStatus, props.exportData.rows, 'amountCredit')
      const treatmentCancel = calculateAllSummaryCancel(props.billStatus, props.exportData.rows, 'amountTrementRight')
      const paidAdvanceCancel = calculateAllSummaryCancel(props.billStatus, props.exportData.rows, 'amountAdvance')

      return (
        <>
          <TableRow>
            <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {t('REPORT.TABLE.TRANSACTION_REVENUE.SUM_FRONT_DESK')}
            </TableCell>
            <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(sumFrontDesk) || 0}
            </TableCell>
            <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(sumFrontDesk - sumFrontDeskCancel) || 0}
            </TableCell>
            <TableCell align="left" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(sumFrontDeskCancel) || 0}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {t('REPORT.TABLE.TRANSACTION_REVENUE.CASH')}
            </TableCell>
            <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(cash) || 0}
            </TableCell>
            <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(cash - cashCancel) || 0}
            </TableCell>
            <TableCell align="left" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(cashCancel) || 0}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {t('REPORT.TABLE.TRANSACTION_REVENUE.TRANSFER')}
            </TableCell>
            <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(transfer) || 0}
            </TableCell>
            <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(transfer - transferCancel) || 0}
            </TableCell>
            <TableCell align="left" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(transferCancel) || 0}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {t('REPORT.TABLE.TRANSACTION_REVENUE.DEBIT')}
            </TableCell>
            <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(debit) || 0}
            </TableCell>
            <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(debit - debitCancel) || 0}
            </TableCell>
            <TableCell align="left" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(debitCancel) || 0}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {t('REPORT.TABLE.TRANSACTION_REVENUE.CREDIT')}
            </TableCell>
            <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(credit) || 0}
            </TableCell>
            <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(credit - creditCancel) || 0}
            </TableCell>
            <TableCell align="left" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(creditCancel) || 0}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {t('REPORT.TABLE.TRANSACTION_REVENUE.TREATMENT')}
            </TableCell>
            <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(treatment) || 0}
            </TableCell>
            <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(treatment - treatmentCancel) || 0}
            </TableCell>
            <TableCell align="left" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(treatmentCancel) || 0}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {t('REPORT.TABLE.TRANSACTION_REVENUE.PAID_ADVANCE')}
            </TableCell>
            <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(paidAdvance) || 0}
            </TableCell>
            <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(paidAdvance - paidAdvanceCancel) || 0}
            </TableCell>
            <TableCell align="left" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(paidAdvanceCancel) || 0}
            </TableCell>
          </TableRow>
        </>
      )
    }
  }

  const calculateSummaryOverDue = (data: any) => {
    if (data.length > 0) {
      const uniqueData = _.uniqBy(data, 'refItemId')

      const totalAmountTotal = _.sumBy(uniqueData, (expData: any) => Number(expData.total || 0))

      const totalAmountPaid = _.sumBy(data, (expData: any) => Number(expData.amountPaid || 0))

      return totalAmountTotal - totalAmountPaid
    } else {
      return 0
    }
  }

  const allSummaryOverDue = (exportData: boolean) => {
    console.log(props);

    if (props.exportData.rows && props.data.rows) {
      let overdue
      if (props.billStatus === 'CANCEL') {
        overdue = 0
        // } else if (exportData) {
        //   // overdue = calculateSummaryOverDue(props.exportData.rows)
        //   overdue = calculateSummaryOverDue(props.exportData.rows)
      } else {
        // overdue = calculateSummaryOverDue(props.data.rowSummary)
        overdue = props.data.rowSummary.length ? props.data.rowSummary[0]?.overdue : 0
      }
      return (
        <>
          <TableRow>
            <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {t('REPORT.TABLE.TRANSACTION_REVENUE.OVERDUE')}
            </TableCell>
            <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(overdue) || 0}
            </TableCell>
          </TableRow>
        </>
      )
    }
  }

  const allSummaryFrontDesk = (data: any, no: number, exportData: boolean) => {
    const { amountPaidAdvance } = data
    if (props.exportData.rows && props.data.rows) {
      const sumFrontDesk = calculateAllSummary(props.billStatus, props.exportData.rows, 'amountPaid')
      return (
        <>
          <TableRow>
            <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {t('REPORT.TABLE.TRANSACTION_REVENUE.SUM')}
            </TableCell>
            <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(parseFloat(sumFrontDesk || 0) + parseFloat(amountPaidAdvance || 0))}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {t('REPORT.TABLE.TRANSACTION_REVENUE.SUM_FRONT_DESK')}
            </TableCell>
            <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(sumFrontDesk || 0)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" scope="row" style={{ textAlign: 'left', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {t('REPORT.TABLE.TRANSACTION_REVENUE.RECEIVE_ADVANCE')}
            </TableCell>
            <TableCell align="right" scope="row" style={{ textAlign: 'right', border: exportData ? '0px solid black' : '', fontSize: exportData ? 10 : 16 }}>
              {numberFormat(amountPaidAdvance || 0)}
            </TableCell>
          </TableRow>
        </>
      )
    }
  }

  return (
    <>
      <UseStyled.ResponsiveReportTable>
        <TableCustom
          page={props.page}
          pageLimit={props.pageLimit}
          sortType={props.sortType}
          sortBy={props.sortBy}
          rowCount={props.rowCount}
          onSort={props.onRequestSort}
          setPageLimit={props.handleChangeRowsPerPage}
          setPage={props.handleChangePage}
          headCells={headCells}
          rowsData={_.map(props.data.rows, (d: any, index: number) => {
            return renderData(d, index, false, 'data')
          })}
          customScroll
        />
      </UseStyled.ResponsiveReportTable>
      <Divider className="mx-0 mt-3 mb-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
      <Box sx={{ maxWidth: '600px' }} className="pt-3">
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={props.onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headSummaryCells}
            rowsData={_.map(props.data.rowSummary, (sd: any, index: number) => {
              return renderCountBill(sd, index, false)
            })}
            hidePagination={true}
            tableMinWidth={600}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>

      <Box sx={{ maxWidth: '600px' }} className="pt-3">
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={props.onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headSummaryCells}
            rowsData={_.map(props.data.rowSummary, (sd: any, index: number) => {
              return renderSummaryAdvance(sd, index, false)
            })}
            hidePagination={true}
            tableMinWidth={600}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>

      <Box sx={{ maxWidth: '600px' }} className="pt-3">
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={props.onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headSummaryCells}
            rowsData={_.map(props.data.rowSummary, (sd: any, index: number) => {
              return renderSummaryAmountPait(sd, index, false)
            })}
            hidePagination={true}
            tableMinWidth={600}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>

      <Box sx={{ maxWidth: '600px' }} className="pt-3">
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={props.onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headALLSummaryCells}
            rowsData={[allSummary(false)]}
            hidePagination={true}
            tableMinWidth={600}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>

      <Box sx={{ maxWidth: '600px' }} className="pt-3">
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={props.onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headSummaryOverdue}
            rowsData={[allSummaryOverDue(false)]}
            hidePagination={true}
            tableMinWidth={600}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>

      <Box sx={{ maxWidth: '600px' }} className="pt-3">
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={props.onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headSummaryOverdue}
            rowsData={_.map(props.data.rowSummary, (sd: any, index: number) => {
              return allSummaryFrontDesk(sd, index, false)
            })}
            hidePagination={true}
            tableMinWidth={600}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>

      <div className="print-show" ref={props.componentRef}>
        <TransationRevenueExport
          componant={
            <>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      <td style={{ fontWeight: 'bold' }}> {t(`REPORT.REPORT_NAME.TRANSACTION_REVENUE`)}</td>
                    </tr>
                    <tr>
                      {_.map(props.tableFilter[0], (dataTable, indexTable: number) => {
                        return <td style={{ textAlign: 'center', fontSize: 10, fontWeight: 500 }}> {t(dataTable)}</td>
                      })}
                    </tr>
                    <tr>
                      {_.map(props.tableFilter[1], (dataTable, indexTable: number) => {
                        return <td style={{ textAlign: 'center', fontSize: 10, fontWeight: 500 }}> {t(dataTable)}</td>
                      })}
                    </tr>
                  </thead>
                </table>
              </UseStyled.Styles>
              <br></br>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      {_.map(headCells, (val: any, index: number) => {
                        return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {!props.exportData.rows !== undefined &&
                      _.map(props.exportData.rows, (dataTable, indexTable: number) => {
                        return renderData(dataTable, indexTable, true, 'data')
                      })}
                    {renderData(props.exportData.rows, 0, false, 'sum')}
                  </tbody>
                </table>
              </UseStyled.Styles>
              <br></br>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      {_.map(headSummaryCells, (val: any, index: number) => {
                        return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(props.data.rowSummary, (dataTable, index: number) => {
                      return renderCountBill(dataTable, index, true)
                    })}
                  </tbody>
                </table>
              </UseStyled.Styles>
              <br></br>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      {_.map(headSummaryCells, (val: any, index: number) => {
                        return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(props.data.rowSummary, (dataTable, index: number) => {
                      return renderSummaryAdvance(dataTable, index, true)
                    })}
                  </tbody>
                </table>
              </UseStyled.Styles>
              <br></br>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      {_.map(headSummaryCells, (val: any, index: number) => {
                        return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(props.data.rowSummary, (dataTable, index: number) => {
                      return renderSummaryAmountPait(dataTable, index, true)
                    })}
                  </tbody>
                </table>
              </UseStyled.Styles>
              <br></br>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      {_.map(headALLSummaryCells, (val: any, index: number) => {
                        return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                      })}
                    </tr>
                  </thead>
                  <tbody>{[allSummary(true)]}</tbody>
                </table>
              </UseStyled.Styles>
              <br></br>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      {_.map(headSummaryOverdue, (val: any, index: number) => {
                        return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                      })}
                    </tr>
                  </thead>
                  <tbody>{[allSummaryOverDue(true)]}</tbody>
                </table>
              </UseStyled.Styles>
            </>
          }
        />
      </div>
    </>
  )
}

export function TransationRevenueExport(props: ExportProps) {
  return <>{props.componant}</>
}
