import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { treatment, TreatmentStateInterface } from 'app/slice/treatment.slice';

/** STYLED */
import { styled } from '@mui/material/styles'
import { Chip, Collapse, Divider, IconButton } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Dropdown, Image } from 'react-bootstrap'
import { Icons, icons } from "constants/images"

/** CONSTANTS */
import { colors } from 'constants/theme'
import TableCustom from "component/Table/TableCustom"
import printIcon from 'assets/icon/carlendar/print.svg'
import FunctionPrint from "component/Print/print"
import PdfTreatmentPlan from "features/pdf/pdf-treatment-plan"
import { numberFormat } from "utils/app.utils"

const CardInfo = styled('div')(({ theme }) => ({
    border: `1px solid ${colors.lightGray}`,
    color: colors.textPrimary,
    fontSize: 16,
    padding: '0.625rem 1.5rem',
    '.expand-more': {
        backgroundColor: colors.white,
        border: `1px solid ${colors.textLightGray}`,
        color: colors.themeMainColor,
        borderRadius: 100,
        fontSize: 22
    }
}))

const Detail = styled('div')(({ theme }) => ({
    '&:first-of-type': {
        marginRight: '1.25rem',
        width: '100%',
    }
}))

const CustomCollapse = styled(Collapse)(({ theme }) => ({
    '.collapse-wrap': {
        margin: '0.625rem -0.5rem 0',
        padding: '0.5rem 0.5rem 0.25rem',
        borderTop: `1px solid ${colors.lightGray}`
    }
}))

const Title = styled('span')(({ theme }) => ({
    fontWeight: 500,
    fontSize: 16
}))

const TitleSecond = styled('span')(({ theme }) => ({
    color: colors.disabledGray,
    fontSize: 14
}))

const DropdownMenu = styled(Dropdown.Menu)(({ theme }) => ({}))
const DropdownItem = styled(Dropdown.Item)(({ theme }) => ({
    color: colors.textPrimary,
    '&:hover': {
        backgroundColor: colors.themeSecondColor06,
        color: colors.themeSecondColor
    }
}))

const ImageLabel = styled(Image)(({ theme }) => ({
    verticalAlign: 'top',
    marginTop: '5px',
    marginRight: '4px'
}))

export const ChipStatus = styled(Chip)(({ theme }) => ({
    height: 23,
    padding: '0 0.25rem',
    borderRadius: 8,
    marginRight: 4,
    span: {
        fontSize: 14,
        color: colors.white,
        padding: 0
    }
}))

type Data = {
    index?: number
    titleName?: any
    status?: any
    summary?: any
    createdAt?: any
    createdBy?: any
    arrayTable?: any
    headCells?: any
    renderData?: any
    remark?: any
    txp?: boolean
    doctors?: any
    patient?: any
    paid?: any
    balance?: any
    totalPrice?: any
    totalQty?: any
    history?: any
    type?: any
    hasDeleteTreatmentCourse?: 'PERMISS' | 'UNPERMISS'
    hasDeleteTreatmentPlan?: 'PERMISS' | 'UNPERMISS'
    onEdit?: () => void
    onClosePlan?: () => void
    onCancelPlan?: () => void
    onPendingPlan?: () => void
    onShowRecord?: () => void
    onDelete?: () => void
    onShowHistoryPaymentCourses?: () => void
}

export default function TxInformationBox(props: Data) {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const componentRef = useRef<HTMLDivElement>(null)
    const treatments: TreatmentStateInterface = useSelector(treatment)
    const path = window.location.pathname === '/treatments/history' ? true : false

    const [isDel, setIsDel] = useState<boolean>(false)

    const renderLabel = (type: 'label' | 'background') => {
        let status = t('TX_INFORMATION.STATUS.PENDING')
        let bg = colors.memberRanking.rankingGold
        if (props.status === 'CANCEL') {
            status = t('TX_INFORMATION.STATUS.CANCEL')
            bg = colors.themeThirdColor
        }
        else if (props.status === 'CLOSE') {
            status = t('TX_INFORMATION.STATUS.CLOSE')
            bg = colors.disabledGray
        }
        else if (_.every(props.arrayTable, { status: 'DONE' }) || props.status === 'DONE') {
            status = t('TX_INFORMATION.STATUS.DONE')
            bg = colors.sky
        }
        else if (_.some(treatments.summeryTreatments, (tr) => _.some(props.arrayTable, { id: tr.refId })) || _.some(props.arrayTable, { status: 'DONE' })) {
            status = t('TX_INFORMATION.STATUS.IN_PROGRESS')
            bg = colors.themeMainColor
        }

        return type === 'label' ? status : bg
    }

    useEffect(() => {
        setIsDel(handleDisabledDel())
    }, [props.txp, props.arrayTable, treatments.summeryTreatments])

    const handleDisabledDel = () => {
        const hasDel = props.txp ? 'hasDeleteTreatmentPlan' : 'hasDeleteTreatmentCourse'
        if (props?.[hasDel] === 'PERMISS') {
            return _.some(treatments.summeryTreatments, (tr) => _.some(props.arrayTable, { id: tr.refId })) ||
                _.some(props.arrayTable, { status: 'DONE' })
        } else {
            return true
        }
    }

    return (
        <div>
            <CardInfo>
                <div className={'d-flex align-items-start'}>
                    <Detail className="mr-auto">
                        <div>
                            <Title className="d-flex align-items-center">
                                {props.titleName}
                                <ChipStatus
                                    className="ml-1"
                                    style={{ backgroundColor: renderLabel('background') }}
                                    label={renderLabel('label')}
                                    size="small" />
                            </Title>
                        </div>
                        <div>
                            <TitleSecond>{t('BY')} {props.createdBy} ({props.createdAt}) </TitleSecond>
                            {props.summary && <TitleSecond>{props.summary}</TitleSecond>}
                        </div>
                        {!props.txp && <div>
                            <TitleSecond>{t('TX_INFORMATION.FIELD.QTY')} {props.balance}/{props.totalQty} {t('UNIT.TERMS')}, {t('TX_INFORMATION.FIELD.ALL_COURSE_PRICE')} {numberFormat(props.totalPrice)} {t('CURRENCY_CHANGE.TH')}, {t('TX_INFORMATION.FIELD.ALREADY_PAY')} {numberFormat(props.paid)} {t('CURRENCY_CHANGE.TH')}, {t('TX_INFORMATION.FIELD.BALANCE')} {numberFormat(props.totalPrice - props.paid)} {t('CURRENCY_CHANGE.TH')}</TitleSecond>
                        </div>}
                    </Detail>
                    <Detail>
                        <Dropdown>
                            <Dropdown.Toggle variant="link" bsPrefix="p-0  " className="btn-table-action text-black-50 shadow-none">
                                <Icons.Meatball />
                            </Dropdown.Toggle>
                            <DropdownMenu>
                                {props.status === 'PENDING' && (
                                    props.txp && !props.history &&
                                    <DropdownItem onClick={props.onClosePlan} disabled={_.every(props.arrayTable, { status: 'DONE' })}>
                                        <span className="ml-2 align-text-bottom">{t('TX_INFORMATION.BUTTON.CLOSE_PLAN')}</span>
                                    </DropdownItem>
                                ) || (
                                        props.txp && !props.history &&
                                        <DropdownItem onClick={props.onPendingPlan} disabled={props.status === 'CANCEL'}>
                                            <span className="ml-2 align-text-bottom">{t('TX_INFORMATION.BUTTON.PENDING_PLAN')}</span>
                                        </DropdownItem>
                                    )
                                }
                                {props.txp && !props.history && props.status !== 'CANCEL' &&
                                    <DropdownItem onClick={props.onCancelPlan} disabled={_.some(treatments.summeryTreatments, (tr) => _.some(props.arrayTable, { id: tr.refId })) || _.some(props.arrayTable, { status: 'DONE' })}>
                                        <span className="ml-2 align-text-bottom">{t('TX_INFORMATION.BUTTON.CANCEL_PLAN')}</span>
                                    </DropdownItem>
                                }
                                {!props.txp &&
                                    <>
                                        <DropdownItem onClick={props.onShowRecord}>
                                            <span className="ml-2 align-text-bottom">{t('TX_INFORMATION.BUTTON.HISTORY_COURSES')}</span>
                                        </DropdownItem>
                                        {/* <DropdownItem onClick={props.onShowHistoryPaymentCourses}>
                                            <span className="ml-2 align-text-bottom">{t('TX_INFORMATION.BUTTON.HISTORY_PAYMENT_COURSES')}</span>
                                        </DropdownItem> */}
                                    </>
                                }
                                {!path && props.history && !props.txp && <Dropdown.Divider style={{ backgroundColor: 'transparent', borderColor: colors.lightGray, opacity: 1 }} />}
                                {!path && !props.history && <Dropdown.Divider style={{ backgroundColor: 'transparent', borderColor: colors.lightGray, opacity: 1 }} />}
                                {!path && props.type !== 'Payment' &&
                                    <DropdownItem
                                        onClick={props.onEdit}
                                        disabled={props.status === 'CANCEL' || _.every(props.arrayTable, { status: 'DONE' }) || props.status === 'DONE'}>
                                        <img
                                            src={icons.dropdownEdit}
                                            className="align-baseline"
                                            alt="dropdownEdit"
                                            style={{ opacity: props.status === 'CANCEL' || _.every(props.arrayTable, { status: 'DONE' }) || props.status === 'DONE' ? .3 : 1 }}
                                        />
                                        <span className="ml-2 align-text-bottom">{t('TX_INFORMATION.BUTTON.EDIT')}</span>
                                    </DropdownItem>}
                                {!path && props.history && !props.txp &&
                                    <DropdownItem
                                        onClick={props.onDelete}
                                        disabled={isDel}>

                                        <img
                                            src={icons.dropdownTrash}
                                            className="align-baseline"
                                            alt="dropdownTrash"
                                            style={{
                                                opacity: isDel ? .3 : 1
                                            }} />
                                        <span className="ml-2 align-text-bottom">{props.txp === false ? t('TX_INFORMATION.BUTTON.DELETE_TXC') : t('TX_INFORMATION.BUTTON.DELETE_TXP')}</span>
                                    </DropdownItem>}
                                {!path && !props.history &&
                                    <DropdownItem
                                        onClick={props.onDelete}
                                        disabled={isDel}>
                                        <img
                                            src={icons.dropdownTrash}
                                            className="align-baseline"
                                            alt="dropdownTrash"
                                            style={{
                                                opacity: isDel ? .3 : 1
                                            }} />
                                        <span className="ml-2 align-text-bottom">{props.txp === false ? t('TX_INFORMATION.BUTTON.DELETE_TXC') : t('TX_INFORMATION.BUTTON.DELETE_TXP')}</span>
                                    </DropdownItem>}
                                {props.txp &&
                                    <>
                                        <FunctionPrint
                                            content={() => componentRef.current}
                                            docTitle={t('TX_INFORMATION.TITLE')}
                                            removeIfram
                                            trigger={() => <DropdownItem disabled={props.status === 'CANCEL'}>
                                                <ImageLabel src={printIcon} style={{ opacity: props.status === 'CANCEL' ? .3 : 1 }} />
                                                {t('TX_INFORMATION.BUTTON.PRINT')}
                                            </DropdownItem>}
                                            style={'@page { size: 1122.519685px 793.7007874px;  margin: 1cm; } @media print { body { -webkit-print-color-adjust: exact; float: none !important; position: static !important; display: inline; box-sizing: content-box !important;}} '}
                                        />
                                        <div ref={componentRef} className="print-show">
                                            <PdfTreatmentPlan title={props.titleName} data={props.arrayTable} doctors={props.doctors} remark={props?.remark || '-'} patient={props.patient} createAt={props.createdAt} />
                                        </div>
                                    </>
                                }
                            </DropdownMenu>
                        </Dropdown>
                    </Detail>
                    <Detail>
                        <IconButton aria-label="expand row" size="small" className={'ml-3'} onClick={() => setOpen(!open)} style={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                            <ExpandMoreIcon className={'expand-more'} />
                        </IconButton>
                    </Detail>
                </div>
                {
                    props.arrayTable && <CustomCollapse in={open} timeout="auto" unmountOnExit>
                        <div className={'collapse-wrap pt-3'}>
                            <TableCustom
                                customScroll
                                page={0}
                                pageLimit={0}
                                sortType={'ASC'}
                                sortBy={''}
                                rowCount={0}
                                textEmptyData={t('REGISTER.NO_ITEM')}
                                onSort={() => { return }}
                                setPageLimit={() => { return }}
                                setPage={() => { return }}
                                headCells={props.headCells}
                                rowsData={_.map(props.arrayTable, (i, index: number) => {
                                    return props.renderData(i, index, props.status, props.index)
                                }).reverse()}
                                hidePagination
                                tableFixedWidth
                            />
                        </div>
                        <Divider className="mx-0 my-3" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                        <div>
                            <span className="pr-2">{t('TX_INFORMATION.REMARK')}</span> {props?.remark || '-'}
                        </div>
                    </CustomCollapse>
                }
            </CardInfo>
        </div>
    )
}
