import BaseAPI from 'api/api'
const path = 'doctor-fee'
export interface FindManageDoctorAllInterface {
    page?: number
    pageLimit?: number
    dateStart?: string
    dateEnd?: string
    search?: string
}
export interface IFDoctorFeeInterface {
    dfAmount: number
    dfAmountNet: number
    qty?: number
    dfUnit: 'BAHT' | 'PERCENT'
    cfAmount: number
    cfAmountNet: number
    cfUnit: 'BAHT' | 'PERCENT'
    dfRemark: string | null
    doctorId: number
    clinicFeeId: number
}
export interface DoctorFeeInterface {
    orderTreatmentId?: any
    date?: any
    paymentNumber?: string
    doctorFullname?: string
    branchCode?: string
    branchCnNumber?: any
    patientsFullname?: any
    operativeTypeName?: any
    operativeName?: any
    total?: any
    dfAmount?: any
    cfAmount?: any
    dfUnit?: any
    dfDiscountAmount?: any
    dfDiscountUnit?: any
    cfDiscountAmount?: any
    cfDiscountUnit?: any
    dfAmountNet?: any
    cfAmountNet?: any
    dfRemark?: any
    clinicFeeId?: any
    clinicFeeName?: any
    clinicFeePrice?: any
    createdBy?: any
    updatedBy?: any
    updatedAt?: any
}
export default class ManageDoctorFeeApi extends BaseAPI {
    static findAll(props: FindManageDoctorAllInterface): Promise<any> {
        return this.api.get(path, { params: { ...props } }).then((res) => res)
    }
    static update(orderTreatmentId: number, payload: IFDoctorFeeInterface): Promise<any> {
        return this.api.patch(`${path}/${orderTreatmentId}`, { ...payload })
    }
}