import { menuThirds } from 'constants/menus'
import ServicePoint from 'features/setting/branch-service-point/service-point/service-point'
import FormServicePoint from 'features/setting/branch-service-point/service-point/form-service-point'
import Branch from 'features/setting/branch-service-point/branch/branch'
import FormBranch from 'features/setting/branch-service-point/branch/form-branch'

const routeBranchServicePoint = [
  {
    name: menuThirds.SERVICE_POINT.name,
    key: menuThirds.SERVICE_POINT.key,
    textName: `เพิ่ม ${menuThirds.SERVICE_POINT.name}`,
    active: false,
    path: menuThirds.SERVICE_POINT.path + '/create',
    component: FormServicePoint,
    appBar: true
  },
  {
    name: menuThirds.SERVICE_POINT.name,
    key: menuThirds.SERVICE_POINT.key,
    textName: `แก้ไข ${menuThirds.SERVICE_POINT.name}`,
    active: false,
    path: menuThirds.SERVICE_POINT.path + '/update',
    component: FormServicePoint,
    appBar: true
  },
  {
    ...menuThirds.SERVICE_POINT,
    component: ServicePoint,
    appBar: true
  },
  {
    name: menuThirds.BRANCH.name,
    key: menuThirds.BRANCH.key,
    textName: `แก้ไข ${menuThirds.BRANCH.name}`,
    active: false,
    path: menuThirds.BRANCH.path + '/update',
    component: FormBranch,
    appBar: true
  },
  {
    ...menuThirds.BRANCH,
    component: Branch,
    appBar: true
  }
]

export default routeBranchServicePoint
