import BaseAPI from 'api/api'
import { PaginationOnlyFilter } from 'api/api-interface'
export interface PrintMedicalCertificate {
  date: string
  lang?: string
  doctorId?: number
  patientDiagnosisId?: []
  patientRecommendId?: []
  orderTreatmentId?: []
  tempOrderTreatmentId?: []
}

export interface PrintMedicalCertificateEmpty {
  lang: string
}
export interface PrintReferralExamination {
  date: string
  branchId: number
  doctorId?: number
  xray: 'CHECK' | 'UNCHECK',
  opg: 'CHECK' | 'UNCHECK',
  latCaph: 'CHECK' | 'UNCHECK',
  other: 'CHECK' | 'UNCHECK',
  detailOther: string,
  continueTreatment: 'CHECK' | 'UNCHECK',
  consult: 'CHECK' | 'UNCHECK',
  dentalHistory: string
}

export interface PrintMedicalReferralProps {
  referenceNumber: string,
  branchId: number,
  date: string,
  doctorId: number,
  doctorName: string,
  certificate: string,
  patientId: number,
  address: string,
  addressEn: string,
  patientFullName: string,
  age: string,
  cnNumber: string,
  branchCnNumber: string,
  physicalExaminationHistory: string,
  patientDiagnosis: PatientDiagnosisProps[],
  note: string,
  other: string,
  signedByTheExaminer: boolean,
  height: string,
  temperature: string,
  weight: string,
  bmi: string,
  o2Sat: string,
  pulseRate: string,
  rr: string,
  bloodPressure: string,
}

export interface PrintDoctorCertificateProps {
  referenceNumber: string,
  branchId: number,
  date: string,
  doctorId: number,
  doctorName: string,
  certificate: string,
  patientId: number,
  address: string,
  addressEn: string,
  patientFullName: string,
  age: string,
  cnNumber: string,
  branchCnNumber: string,
  physicalExaminationHistory: string,
  patientDiagnosis: PatientDiagnosisProps[],
  note: string,
  doctorOpinion: string,
  actualCheckupDate: string,
  actualCheckupStatus: boolean,
  stayDuration: number,
  startDate: string,
  endDate: string,
  stayDurationStatus: boolean,
  signedByTheExaminer: boolean,
  type?: string,
  height: string,
  temperature: string,
  weight: string,
  bmi: string,
  o2Sat: string,
  pulseRate: string,
  rr: string,
  bloodPressure: string,

}

export interface PatientDiagnosisProps {
  patientDiagnosisId: number,
  diagnosisId: number,
  diagnosisName: string,
}

const path = 'treatment-history'
export default class TreatmentHistoryApi extends BaseAPI {
  static findSummeryAllByPatientId(patientId: number, props: PaginationOnlyFilter): Promise<any> {
    return this.api.get(`${path}/${patientId}`, { params: { ...props } }).then((res) => res)
  }

  static printMedicalCertificate(patientId: number, patientCaseId: number, props: PrintMedicalCertificate): Promise<any> {
    return this.api.get(`${path}/${patientId}/${patientCaseId}/medical-certificate`, { params: { ...props } })
  }

  static printMedicalCertificateEmpty(patientId: number, props: PrintMedicalCertificateEmpty): Promise<any> {
    return this.api.get(`${path}/${patientId}/medical-certificate-empty`, { params: { ...props } })
  }
  static findTreatmentHistoryVisit(patientId?: number): Promise<any> {
    return this.api.get(`${path}/${patientId}/visit`).then((res) => res)
  }

  static findTreatmentHistoryEmr(patientId?: number, patientCaseId?: number): Promise<any> {
    return this.api.get(`${path}/${patientId}/emr/${patientCaseId}`).then((res) => res)
  }
  static findTreatmentHistoryToothChart(patientId?: number, props?: any): Promise<any> {
    return this.api.get(`${path}/${patientId}/tooth-chart`, { params: { ...props } }).then((res) => res)
  }

  static printOPDCover(patientId?: number): Promise<any> {
    return this.api.get(`${path}/${patientId}/pdf-opd-cover`).then((res) => res)
  }

  static printOPD(patientId?: number): Promise<any> {
    return this.api.get(`${path}/${patientId}/pdf-opd`).then((res) => res)
  }

  static printOPDEmpty(patientId?: number, props?: { type?: '0' | '1' }): Promise<any> {
    return this.api.get(`${path}/${patientId}/pdf-opd-empty`, { params: { ...props } }).then((res) => res)
  }

  static printReferralExamination(patientId: number, props: PrintReferralExamination): Promise<any> {
    return this.api.get(`${path}/${patientId}/patient-referral-examination`, { params: { ...props } })
  }

  static findAllHistoryDocument(patientId: number, props?: any): Promise<any> {
    return this.api.get(`${path}/${patientId}/history-document`, { params: { ...props } })
  }
  static printMedicalReferral(patientId: number, props: PrintMedicalReferralProps): Promise<any> {
    return this.api.post(`${path}/${patientId}/medical-referral`, { ...props })
  }
  static printDoctorCertificate(patientId: number, props: PrintDoctorCertificateProps, type: string): Promise<any> {
    return this.api.post(`${path}/${patientId}/doctor-certificate?type=${type}`, { ...props })
  }
}
