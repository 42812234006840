import { menuThirds } from 'constants/menus'
import TemplateForm from 'features/setting/doctor-note/template-form/template-form'
import ImageDoctorNote from 'features/setting/doctor-note/image-doctor-note/image-doctor-note'
import TemplateFormType from 'features/setting/doctor-note/template-form-type/template-form-type'
import DoctorNoteTypePic from 'features/setting/doctor-note/image-doctor-note-type/image-doctor-note-type'

const routeDoctorNote = [
    {
        ...menuThirds.DOCTOR_NOTE_TYPE_FORM,
        component: TemplateFormType,
        appBar: true
    },
    {
        ...menuThirds.DOCTOR_NOTE_FORM,
        component: TemplateForm,
        appBar: true
    },
    {
        ...menuThirds.DOCTOR_NOTE_TYPE_PIC,
        component: DoctorNoteTypePic,
        appBar: true
    },
    {
        ...menuThirds.DOCTOR_NOTE_PIC,
        component: ImageDoctorNote,
        appBar: true
    }
]
export default routeDoctorNote
