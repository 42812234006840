import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface PatientTypeInterface {
  patientTypeName: string
  patientTypeNameEn: string
  status: string
}

export default class PatientTypeApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('patient-types', { params: { ...props } }).then((res) => res)
  }

  static create(patientTypeName: string, patientTypeNameEn: string) {
    return this.api.post('patient-types', { patientTypeName, patientTypeNameEn })
  }

  static findById(id: number): Promise<PatientTypeInterface> {
    return this.api.get(`patient-types/${id}`)
  }

  static update(id: number, body: PatientTypeInterface) {
    return this.api.patch(`patient-types/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`patient-types/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`patient-types/${id}`)
  }
}
