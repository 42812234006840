import { useState, useCallback, useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { Grid, Box, Typography, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

/** API */
import { FindAllInterface } from 'api/api-interface'
import RecallApi, { FindAllListInterface, CancelRecallInterface } from 'api/setting/treatments/recall.api'
import ClinicApi, { BranchInterface, FindPatientInterface, PatientInterface } from 'api/master/clinic.api'
import OperativeTypeApi, { OperativeTypeObjInterface } from 'api/setting/treatments/operative-type.api'
import OperativeApi, { OperativeObjInterface } from 'api/setting/treatments/operative.api'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** UTILS || SLICE */
import { isCreate, isDelete, isUpdate, isView, isExport } from 'utils/verification.utils'
import { SORT_TYPE } from 'constants/common'
import { toBuddhistYear } from 'utils/app.utils'
import { handleShow, resetModal, showModal } from 'app/slice/modal.slice'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import HeaderCard from 'component/CardCustom/HeaderCard'
import ButtonExport from 'component/Button/ButtonExport'
import InputTextSearch from 'component/Input/InputTextSearch'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableRowExport from 'component/Pdf/TableRowExport'
import Loading from 'component/Loading'
import HistoryRecall, { HistoryRecallInterface } from 'features/counter/recall/history-recall'

/** STYLE */
import * as UseStyled from 'features/counter/recall/UseStyled'

const permissions = {
    isCreate: isCreate(),
    isDelete: isDelete(),
    isUpdate: isUpdate(),
    isView: isView(),
    isExport: isExport()
}

interface RecallListsInterface {
    branchCnNumber: string
    branchCode: null | string
    branchId: null | string
    dateOfLastService: string
    dayAmount: number
    doctorFirstname: string
    doctorLastname: string
    firstname: string
    historyRecallId: null | number
    lastRecall: null | string
    lastname: string
    nextRecall: string
    operativeId: number
    operativeName: string
    operativeTypeId: number
    operativeTypeName: string
    patientId: number
    phone: string
    prefixName: string
    recallId: number
    recallUnit: string
    status: string
}

const initialHistoryRecall: HistoryRecallInterface = {
    historyId: 0,
    branchCnNumber: '',
    patientName: '',
    lastService: '',
    operative: '',
    show: false
}

export default function RecallLists() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const modal: boolean = useSelector(handleShow)

    const [recall, setRecall] = useState<RecallListsInterface[]>([]);
    const [exportData, setExportData] = useState<RecallListsInterface[]>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [numberPage, setNumberPage] = useState<number>(1);

    /** default data */
    const [loadingBranches, setLoadingBranches] = useState<boolean>(false);
    const [loadingPatient, setLoadingPatient] = useState<boolean>(false);
    const [loadingOperativeType, setLoadingOperativeType] = useState<boolean>(false);
    const [loadingOperative, setLoadingOperative] = useState<boolean>(false);
    const [branches, setBranches] = useState<BranchInterface[]>([]);
    const [patients, setPatients] = useState<PatientInterface[]>([]);
    const [operativeTypes, setOperativeTypes] = useState<OperativeTypeObjInterface[]>([]);
    const [operatives, setOperatives] = useState<OperativeObjInterface[]>([]);
    const [openPopupHistory, setOpenPopupHistory] = useState<HistoryRecallInterface>(initialHistoryRecall);

    /** pagination */
    const [page, setPage] = useState<number>(1)
    const [pageLimit, setPageLimit] = useState<number>(10)
    const [rowCount, setRowCount] = useState<number>(0)
    const [sortType, setSortType] = useState<SORT_TYPE>(SORT_TYPE.DESC)
    const [sortBy, setSortBy] = useState<string>('nextRecall')
    const [search, setSearch] = useState<string>('')
    const [branchId, setBranchId] = useState<number>(0);
    const [patientId, setPatientId] = useState<number>(0);
    const [operativeTypeId, setOperativeTypeId] = useState<number>(0);
    const [operativeId, setOperativeId] = useState<number>(0);
    const [rangeDate, setRangeDate] = useState<string[]>(emptyRangePicker);

    const handleInputSearch = useCallback(
        _.debounce((type: 'search' | 'branch' | 'patient' | 'operativeType' | 'operative', searchText?: string) => {
            if (type === 'search') {
                loadRecalls(searchText)
                loadDataForExport(searchText)
            }
            if (type === 'branch') loadBranches(searchText)
            if (type === 'patient') loadPatients(searchText)
            if (type === 'operativeType') loadOperativeTypes(searchText)
            if (type === 'operative') loadOperatives(searchText)
        }, 1000), []);

    const loadBranches = (search?: string) => {
        let condition: { search?: string } = {}
        if (search) condition = { ...condition, search: search }
        setLoadingBranches(true)
        ClinicApi.findAllBranches(condition).then(({ data }) => setBranches(data)).catch(() => { return }).finally(() => setLoadingBranches(false))
    }

    const loadPatients = (search?: string) => {
        let condition: FindPatientInterface = { pageLimit: 10, allBranch: true, condition: 'cnNumber' }
        if (search) condition = { ...condition, search: search }
        setLoadingPatient(true)
        ClinicApi.findAllPatients(condition).then(({ data }) => setPatients(data)).catch(() => { return }).finally(() => setLoadingPatient(false))
    }

    const loadOperativeTypes = (search?: string) => {
        let condition: FindAllInterface = { pageLimit: 10 }
        if (search) condition = { ...condition, search: search }
        setLoadingOperativeType(true)
        OperativeTypeApi.findAll(condition).then(({ data }) => setOperativeTypes(data)).catch(() => { return }).finally(() => setLoadingOperativeType(false))
    }

    const loadOperatives = (search?: string) => {
        let condition: FindAllInterface = { pageLimit: 10 }
        if (search) condition = { ...condition, search: search }
        setLoadingOperative(true)
        OperativeApi.findAll(condition).then(({ data }) => setOperatives(data)).catch(() => { return }).finally(() => setLoadingOperative(false))
    }

    const loadRecalls = useCallback((searchText?: string) => {
        setLoading(true)
        let condition: FindAllListInterface = {}
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (searchText) condition = { ...condition, search: searchText }
        if (branchId) condition = { ...condition, branchId: branchId }
        if (patientId) condition = { ...condition, patientId: patientId }
        if (operativeTypeId) condition = { ...condition, operativeTypeId: operativeTypeId }
        if (operativeId) condition = { ...condition, operativeId: operativeId }
        if ((rangeDate[0] !== null && rangeDate[0] !== '') && (rangeDate[1] !== null && rangeDate[1] !== '')) {
            condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
        }
        RecallApi.findAllList(condition)
            .then(({ data, headers }) => {
                setRecall(data)
                setRowCount(Number(headers['x-total']))
                setNumberPage(page)
            })
            .catch(() => setRecall([]))
            .finally(() => setLoading(false))
    }, [page, pageLimit, sortType, sortBy, branchId, patientId, operativeTypeId, operativeId, rangeDate])

    const loadDataForExport = useCallback((searchText?: string) => {
        let condition: FindAllListInterface = {}
        condition.export = 1
        if (page) condition = { ...condition, page: 1 }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (searchText) condition = { ...condition, search: searchText }
        if (branchId) condition = { ...condition, branchId: branchId }
        if (patientId) condition = { ...condition, patientId: patientId }
        if (operativeTypeId) condition = { ...condition, operativeTypeId: operativeTypeId }
        if (operativeId) condition = { ...condition, operativeId: operativeId }
        if ((rangeDate[0] !== null && rangeDate[0] !== '') && (rangeDate[1] !== null && rangeDate[1] !== '')) {
            condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
        }
        RecallApi.findAllList(condition)
            .then(({ data }) => setExportData(data))
            .catch(() => setExportData([]))
            .finally(() => setLoading(false))
    }, [page, pageLimit, sortType, sortBy, branchId, patientId, operativeTypeId, operativeId, rangeDate])

    const headCells = [
        { id: 'id', disablePadding: false, label: t('#'), align: 'center', width: '70px' },
        { id: 'branchCode', disablePadding: false, label: t('RECALL_LISTS.TABLE.CELLS.BRANCH_CODE'), class: 'text-nowrap', width: '125px', sortable: true },
        { id: 'dateOfLastService', disablePadding: false, label: t('RECALL_LISTS.TABLE.CELLS.DATE_OF_LAST_SERVICE'), class: 'text-nowrap', width: '236px', sortable: true },
        { id: 'patientCode', disablePadding: false, label: t('RECALL_LISTS.TABLE.CELLS.PATIENT_CODE'), class: 'text-nowrap' },
        { id: 'patientName', disablePadding: false, label: t('RECALL_LISTS.TABLE.CELLS.PATIENT_NAME'), class: 'text-nowrap' },
        { id: 'phone', disablePadding: false, label: t('RECALL_LISTS.TABLE.CELLS.PHONE'), class: 'text-nowrap', width: '200px' },
        { id: 'operativeTypeName', disablePadding: false, label: t('RECALL_LISTS.TABLE.CELLS.OPERATIVE_TYPE_NAME'), class: 'text-nowrap', sortable: true },
        { id: 'operativeName', disablePadding: false, label: t('RECALL_LISTS.TABLE.CELLS.OPERATIVE_NAME'), class: 'text-nowrap', sortable: true },
        { id: 'doctor', disablePadding: false, label: t('RECALL_LISTS.TABLE.CELLS.DOCTOR'), class: 'text-nowrap' },
        { id: 'duration', disablePadding: false, label: t('RECALL_LISTS.TABLE.CELLS.DURATION'), class: 'text-nowrap', width: '200px' },
        { id: 'nextRecall', disablePadding: false, label: t('RECALL_LISTS.TABLE.CELLS.DATE'), class: 'text-nowrap', width: '200px', sortable: true },
        { id: 'date', disablePadding: false, label: t('RECALL_LISTS.TABLE.CELLS.NEXT_RECALL'), class: 'text-nowrap', width: '200px', },
        { id: 'action', disablePadding: false, label: t('Action'), class: 'text-nowrap', width: '80px' }
    ]

    const filter = [
        { label: `${t('INPUT.SEARCH')}: ${search || '-'}` },
        { label: `${t('RECALL_LISTS.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName') || t('STATUS.ALL')}` },
        { label: `${t('RECALL_LISTS.FILTER.FILTER_PATIENT')}: ${_.get(_.find(patients, { patientId: patientId }), 'fullname') || t('STATUS.ALL')}` },
        { label: `${t('RECALL_LISTS.FILTER.OPERATIVE_TYPE')}: ${_.get(_.find(operativeTypes, { operativeTypeId: operativeTypeId }), 'operativeTypeName') || t('STATUS.ALL')}` },
        { label: `${t('RECALL_LISTS.FILTER.OPERATIVE')}: ${_.get(_.find(operatives, { operativeId: operativeId }), 'operativeName') || t('STATUS.ALL')}` },
        { label: `${t('RECALL_LISTS.FILTER.RANGE_DATE')}: ${(rangeDate[0] && rangeDate[1]) ? `${toBuddhistYear(moment(rangeDate[0]))}-${toBuddhistYear(moment(rangeDate[1]))}` : '-'}` }
    ]

    const renderData = (objData: RecallListsInterface, no: number, exportDataValue: boolean) => {
        const { historyRecallId, branchCode, dateOfLastService, branchCnNumber, firstname, lastname, phone, operativeTypeName, operativeName, doctorFirstname, doctorLastname, dayAmount, recallUnit, lastRecall, nextRecall, status } = objData

        no = numberPage * pageLimit - pageLimit + no + 1

        const objRenderData = {
            key: String(Math.random()),
            id: String(historyRecallId),
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: no, class: 'px-1' },
                { option: 'TEXT', align: 'left', label: branchCode || '-' },
                { option: 'TEXT', align: 'left', label: dateOfLastService ? `${toBuddhistYear(moment(dateOfLastService), 'DD/MM/YYYY HH:mm')} ${t('TIME_UNIT')}` : '-' },
                { option: 'TEXT', align: 'left', label: `${branchCode || ''}${branchCnNumber || '-'}` },
                { option: 'TEXT', align: 'left', label: `${firstname} ${lastname}` },
                { option: 'TEXT', align: 'left', label: phone || '-' },
                { option: 'TEXT', align: 'left', label: operativeTypeName || '-' },
                { option: 'TEXT', align: 'left', label: operativeName || '-' },
                { option: 'TEXT', align: 'left', label: `${doctorFirstname} ${doctorLastname}` },
                { option: 'TEXT', align: 'left', label: `${dayAmount} ${t(`TIME_DURATION_TYPE.${recallUnit}`)}` },
                { option: 'TEXT', align: 'left', label: status === 'NORMAL' && (nextRecall ? `${toBuddhistYear(moment(`${nextRecall}`), 'DD/MM/YYYY')} 07:00 ${t('TIME_UNIT')}` : '-') || t('STATUS_NO_ICON.INACTIVE') },
                { option: 'TEXT', align: 'left', label: status === 'NORMAL' && (lastRecall ? `${toBuddhistYear(moment(lastRecall), 'DD/MM/YYYY HH:MM')} ${t('TIME_UNIT')}` : '-') || t('STATUS_NO_ICON.INACTIVE') },
                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: [
                        { option: 'STATUS_ACTIVE', label: t(`RECALL_LISTS.BUTTON.HISTORY`), disabled: false },
                        { option: 'DIVIDER', label: '', disabled: false },
                        { option: 'STATUS_INACTIVE', label: t(`RECALL_LISTS.BUTTON.CANCEL`), disabled: permissions.isUpdate.disabled || status !== 'NORMAL' },
                    ]
                }
            ]
        }

        return (
            !exportDataValue && (
                <TableRowCommon {...objRenderData} onactive={() => onShowHistoryRecall(objData)} oninactive={() => onCancelRecall(objData)} />
            ) || <TableRowExport {...objRenderData} />
        )
    }

    const onShowHistoryRecall = (data: RecallListsInterface) => {
        setOpenPopupHistory({
            historyId: data.historyRecallId || 0,
            branchCnNumber: `${data.branchCode || ''}${data.branchCnNumber || ''}`,
            patientName: `${data.firstname || ''} ${data.lastname || ''}`,
            lastService: data.dateOfLastService ? `${toBuddhistYear(moment(data.dateOfLastService), 'DD/MM/YYYY HH:mm')} ${t('TIME_UNIT')}` : '',
            operative: data.operativeName || '',
            show: true,
        })
        dispatch(showModal())
    }

    const onCancelRecall = (data: RecallListsInterface) => {
        const payload: CancelRecallInterface = {
            branchId: Number(data.branchId) || 0,
            operativeId: Number(data.operativeId),
            patientId: Number(data.patientId),
            recallId: Number(data.recallId)
        }

        if (!payload.branchId) return notiError(t('RECALL_LISTS.MESSAGE.ERROR.NO_BRANCH'))
        if (!payload.operativeId) return notiError(t('RECALL_LISTS.MESSAGE.ERROR.NO_OPERATIVE'))
        if (!payload.patientId) return notiError(t('RECALL_LISTS.MESSAGE.ERROR.NO_PATIENT'))
        if (!payload.recallId) return notiError(t('RECALL_LISTS.MESSAGE.ERROR.NO_RECALL'))

        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 375px">${t('RECALL_LISTS.MESSAGE.WARNING.TITLE')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 375px">${t('RECALL_LISTS.MESSAGE.WARNING.SUBTITLE')}</p>`,
            (res: any) => {
                if (res) {
                    setLoading(true)
                    RecallApi.cancelRecall(payload)
                        .then((res) => {
                            notiSuccess(t('RECALL_LISTS.MESSAGE.SUCCESS.CANCEL_RECALL'))
                            loadRecalls()
                        })
                        .catch(() => notiError(t('RECALL_LISTS.MESSAGE.ERROR.DEFAULT')))
                        .finally(() => setLoading(false))
                }
            }, 'warning', t('BUTTON.ENTER'), t('BUTTON.CANCEL'))
    }

    const onRequestSort = (sortByVal: string, sortTypeVal: SORT_TYPE) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
        setPage(1)
    }

    const handleChangeRowsPerPage = (limit: number) => {
        setPageLimit(limit)
        setPage(1)
    }

    const handleChangePage = (val: number) => {
        setPage(val)
    }

    useEffect(() => {
        loadBranches()
        loadPatients()
        loadOperativeTypes()
        loadOperatives()
    }, []);

    useEffect(() => {
        loadRecalls()
    }, [loadRecalls]);

    useEffect(() => {
        loadDataForExport()
    }, [loadDataForExport]);

    useEffect(() => {
        if (!modal) {
            setOpenPopupHistory(initialHistoryRecall)
            dispatch(resetModal())
        }
    }, [modal]);

    return (
        <div className='h-100'>
            <Card className={'border-0 h-100 overflow-auto'}>
                <HeaderCard text={t('RECALL_LISTS.HEADER')} />
                <Card.Body>
                    <Card.Title className={'mb-0 pl-xl-3'}>
                        <UseStyled.RowFilter>
                            <Grid container spacing={1}>
                                <Grid item xs={6} md={4} lg={3} xl={2} className='w-100'>
                                    <InputTextSearch
                                        keyInput={'search'}
                                        label={t('INCOME_EXPENSES.INPUT.MAIN_SEARCH')}
                                        value={search}
                                        onchange={(event) => {
                                            setSearch(event.target.value)
                                            handleInputSearch('search', event.target.value)
                                            setPage(1)
                                        }}
                                        onClear={() => {
                                            setPage(1)
                                            handleInputSearch('search', '')
                                            setSearch('')
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4} lg={3} xl={2} className='w-100'>
                                    <UseStyled.AutoCompleteFilter className='position-relative'>
                                        <AutocompleteSelect
                                            labelId={'branchId'}
                                            options={branches}
                                            size={'small'}
                                            noOptionsText={t('NOT_FOUND')}
                                            getOptionLabel={(option: BranchInterface) => `${t('RECALL_LISTS.FILTER.BRANCH')}: ${option.branchCode || option.branchName || t('STATUS.ALL')}`}
                                            renderOption={(props, option: BranchInterface) => (
                                                <Box component="li" {...props}>
                                                    {option.branchCode || option.branchName}
                                                </Box>
                                            )}
                                            onchange={(e, value: BranchInterface) => {
                                                setPage(1)
                                                setBranchId(value?.branchId || 0)
                                            }}
                                            inputProps={{
                                                onChange: (e: any) => {
                                                    setLoadingBranches(true)
                                                    setBranches([])
                                                    handleInputSearch('branch', e.target.value)
                                                }
                                            }}
                                            height={32}
                                            loading={loadingBranches}
                                            onOpen={() => loadBranches()}
                                            value={_.find(branches, { branchId: branchId }) || ''}
                                            disableClearable={!branchId}
                                        />
                                    </UseStyled.AutoCompleteFilter>
                                </Grid>
                                <Grid item xs={6} md={4} lg={3} xl={2} className='w-100'>
                                    <UseStyled.AutoCompleteFilter className='position-relative'>
                                        <AutocompleteSelect
                                            labelId={'patientId'}
                                            options={patients}
                                            size={'small'}
                                            noOptionsText={t('NOT_FOUND')}
                                            getOptionLabel={(option: PatientInterface) => `${t('RECALL_LISTS.FILTER.PATIENT')}: ${option.fullname || t('STATUS.ALL')}`}
                                            renderOption={(props, option: PatientInterface) => (
                                                <Box component="li" {...props}>
                                                    {option.fullname}
                                                </Box>
                                            )}
                                            onchange={(e, value: PatientInterface) => {
                                                setPage(1)
                                                setPatientId(value?.patientId || 0)
                                            }}
                                            inputProps={{
                                                onChange: (e: any) => {
                                                    setLoadingPatient(true)
                                                    setPatients([])
                                                    handleInputSearch('patient', e.target.value)
                                                }
                                            }}
                                            height={32}
                                            loading={loadingPatient}
                                            onOpen={() => loadPatients()}
                                            value={_.find(patients, { patientId: patientId }) || ''}
                                            disableClearable={!patientId}
                                        />
                                    </UseStyled.AutoCompleteFilter>
                                </Grid>
                                <Grid item xs={6} md={4} lg={3} xl={2} className='w-100'>
                                    <UseStyled.AutoCompleteFilter className='position-relative'>
                                        <AutocompleteSelect
                                            labelId={'operativeTypeId'}
                                            options={operativeTypes}
                                            size={'small'}
                                            noOptionsText={t('NOT_FOUND')}
                                            getOptionLabel={(option: OperativeTypeObjInterface) => `${t('RECALL_LISTS.FILTER.OPERATIVE_TYPE')}: ${option.operativeTypeName || t('STATUS.ALL')}`}
                                            renderOption={(props, option: OperativeTypeObjInterface) => (
                                                <Box component="li" {...props}>
                                                    {option.operativeTypeName}
                                                </Box>
                                            )}
                                            onchange={(e, value: OperativeTypeObjInterface) => {
                                                setPage(1)
                                                setOperativeTypeId(value?.operativeTypeId || 0)
                                            }}
                                            inputProps={{
                                                onChange: (e: any) => {
                                                    setLoadingOperativeType(true)
                                                    setOperativeTypes([])
                                                    handleInputSearch('operativeType', e.target.value)
                                                }
                                            }}
                                            height={32}
                                            loading={loadingOperativeType}
                                            onOpen={() => loadOperativeTypes()}
                                            value={_.find(operativeTypes, { operativeTypeId: operativeTypeId }) || ''}
                                            disableClearable={!operativeTypeId}
                                        />
                                    </UseStyled.AutoCompleteFilter>
                                </Grid>
                                <Grid item xs={6} md={4} lg={3} xl={2} className='w-100'>
                                    <UseStyled.AutoCompleteFilter className='position-relative'>
                                        <AutocompleteSelect
                                            labelId={'operativeId'}
                                            options={operatives}
                                            size={'small'}
                                            noOptionsText={t('NOT_FOUND')}
                                            getOptionLabel={(option: OperativeObjInterface) => `${t('RECALL_LISTS.FILTER.OPERATIVE')}: ${option.operativeName || t('STATUS.ALL')}`}
                                            renderOption={(props, option: OperativeObjInterface) => (
                                                <Box component="li" {...props}>
                                                    {option.operativeName}
                                                </Box>
                                            )}
                                            onchange={(e, value: OperativeObjInterface) => {
                                                setPage(1)
                                                setOperativeId(value?.operativeId || 0)
                                            }}
                                            inputProps={{
                                                onChange: (e: any) => {
                                                    setLoadingOperative(true)
                                                    setOperatives([])
                                                    handleInputSearch('operative', e.target.value)
                                                }
                                            }}
                                            height={32}
                                            loading={loadingOperative}
                                            onOpen={() => loadOperatives()}
                                            value={_.find(operatives, { operativeId: operativeId }) || ''}
                                            disableClearable={!operativeId}
                                        />
                                    </UseStyled.AutoCompleteFilter>
                                </Grid>
                                <Grid item xs={6} md={4} lg={3} xl={2} className='w-100'>
                                    <InputSecondNewRangePicker
                                        inputHeight={32}
                                        value={rangeDate || []}
                                        onchange={(val) => {
                                            setPage(1)
                                            if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                                            else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                                        }}
                                        allowClear
                                        onClear={() => {
                                            setPage(1)
                                            setRangeDate(emptyRangePicker)
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} xl={12} className='w-100'>
                                    <Box className={`btn-export ${permissions.isExport.disabled ? `pe-none disabled` : ``}`}>
                                        <ButtonExport
                                            headCells={headCells}
                                            portrait={false}
                                            filter={filter}
                                            fileName={t('RECALL_LISTS.HEADER')}
                                            rowsData={_.map(exportData, (val: RecallListsInterface, i) => renderData(val, i, true))} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </UseStyled.RowFilter>
                        <UseStyled.ResponsiveTable className='mt-3'>
                            <TableCustom
                                tableFixedWidth
                                tableMinWidth={2500}
                                page={page}
                                pageLimit={pageLimit}
                                sortType={sortType}
                                sortBy={sortBy}
                                rowCount={rowCount}
                                onSort={onRequestSort}
                                setPageLimit={handleChangeRowsPerPage}
                                setPage={handleChangePage}
                                headCells={headCells}
                                rowsData={_.map(recall, (val: RecallListsInterface, i) => {
                                    return renderData(val, i, false)
                                })}
                            />
                        </UseStyled.ResponsiveTable>
                    </Card.Title>
                </Card.Body>
            </Card>

            {openPopupHistory.show && (
                <HistoryRecall
                    show={openPopupHistory.show}
                    historyId={openPopupHistory.historyId}
                    branchCnNumber={openPopupHistory.branchCnNumber}
                    patientName={openPopupHistory.patientName}
                    lastService={openPopupHistory.lastService}
                    operative={openPopupHistory.operative} />
            )}
            <Loading show={loading} type={'fullLoading'} />
        </div>
    )
}
