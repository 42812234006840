import { useState, useEffect, useCallback } from 'react'

import { useTranslation } from 'react-i18next'

/** STYLE */
import * as UseStyled from 'features/setting/treatment-courses/treatment-course/style'

import Cash from 'assets/icon/payment-method/cash'
import SplitPay from 'assets/icon/payment-method/splitPay';
import ChoosePay from 'assets/icon/payment-method/choosePay';
import { Box, FormControl, RadioGroup, Typography } from '@mui/material';
import { colors } from 'constants/theme';
import { numberFormat } from 'utils/app.utils';
import InputTextField from 'component/Input/InputTextField';
import InputRadio from 'component/Input/InputRadio';
import TableCustom from 'component/Table/TableCustom';
import TableRowCommon from 'component/Table/TableRowCommon';
import { TreatmentCoursePaymentInterface } from 'api/setting/treatment-courses/treatment-course.api';
import SplitPayActive from 'assets/icon/payment-method/splitPayActive';
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker';
import moment from 'moment';

type CoursePaymentProps = {
    type?: 'setting' | 'modal'
    handlePaymentChannel: (val: TreatmentCoursePaymentInterface) => void
    countCourses?: number
    summaryTableCourses: any
    dataEdit: any,
    statusError: boolean,
    setErrorPaymentChanel: any
    maxHeightTable?: string
}

export const InterfaceTreatmentCoursePayment: TreatmentCoursePaymentInterface = {
    payType: "",
    status: false,
    detail: ""
}

export interface dataPaymentProps {
    payType: 'CASH' | 'CHOOSEPAY' | 'SPLITPAY' | ''
    status: boolean
}

export const DataPayment: dataPaymentProps = {
    payType: "",
    status: false,
}

export const InterfaceDataPayment: dataPaymentProps[] = [
    {
        payType: "CASH",
        status: false,
    },
    {
        payType: "CHOOSEPAY",
        status: false,
    },
    {
        payType: "SPLITPAY",
        status: false,
    }
]

export default function FormTrementCoursePayment(props: CoursePaymentProps) {
    const { t } = useTranslation()
    const InterfaceHeadCells = [
        {
            id: 'no',
            disablePadding: false,
            label: t('TREATMENT_COURSE.FORM.LIST.TABLE.NO'),
            width: "8%"
        },
        {
            id: 'times',
            disablePadding: false,
            label: t('TREATMENT_COURSE.FORM.PAYMENT.TIMES'),
            width: props.type === 'modal' ? "12%" : 'auto'
        },
        {
            id: 'total_paid',
            disablePadding: false,
            label: t('TREATMENT_COURSE.FORM.PAYMENT.TOTAL_PAID'),
            align: 'right',
            width: props.type === 'modal' ? "25%" : '17%'
        },
        {
            id: 'balance',
            disablePadding: false,
            label: t('TREATMENT_COURSE.FORM.PAYMENT.BALANCE'),
            class: 'pr-3',
            align: 'right',
            width: props.type === 'modal' ? "25%" : '17%'
        },
    ]

    const [dataPayment, setDataPayment] = useState<dataPaymentProps[]>(InterfaceDataPayment)
    const [payActive, setPayActive] = useState<dataPaymentProps>(DataPayment)

    const [summary, setSummary] = useState<any>()
    const [countSplitPay, setCountSplitPay] = useState<number>(0)
    const [statusSplitPay, setStatusSplitPay] = useState<string>("")
    const [summaryTable, setSummaryTable] = useState<any>([])
    const [dataEdit, setDataEdit] = useState<any>([])
    const [inputFocus, setInputFocus] = useState<number>(0)

    const [headCells, setHeadCells] = useState<any>(InterfaceHeadCells)

    const handleChangePayment = (type: 'CASH' | 'CHOOSEPAY' | 'SPLITPAY') => {
        const newData = dataPayment.map((item: dataPaymentProps) => {
            if (type === item.payType) {
                return { ...item, status: !item.status }
            } else {
                return { ...item, status: false }
            }
        })
        setDataPayment(newData)
        setStatusSplitPay("")
        setCountSplitPay(0)
        setDataEdit([])
    }

    const handleChangeColor = () => {
        let checkData = DataPayment
        dataPayment.forEach((item: dataPaymentProps) => {
            if (item.status) {
                checkData = item
            }
        })
        setPayActive(checkData)
    }

    const stylePaymentChannels = (type: 'CASH' | 'CHOOSEPAY' | 'SPLITPAY' | '') => {
        const setStyle = {
            borderColor: `${payActive.payType === type && payActive.status ? `${colors.themeSecondColor} ` : ''}`,
            color: `${payActive.payType === type && payActive.status ? `${colors.themeSecondColor} ` : ''}`
        }
        return setStyle
    }

    const handleShowDetailPayment = (type: 'CASH' | 'CHOOSEPAY' | 'SPLITPAY' | '') => {
        return payActive.payType === type && payActive.status
    }

    useEffect(() => {
        handleChangeColor()
    }, [dataPayment])

    useEffect(() => {
        if (props?.type === 'modal') {
            setSummary(props.summaryTableCourses || 0)
        } else {
            if (props.summaryTableCourses) {
                setSummary(props.summaryTableCourses.map(({ amount, priceUnit }: any) => Number(amount) * Number(priceUnit)).reduce((a: any, b: any) => a + b, 0) || 0)
            }
        }

    }, [props.summaryTableCourses])

    const handleSumTreament = useCallback(() => {
        if (summary !== 0 && countSplitPay !== 0 && statusSplitPay !== "" && payActive.payType === "SPLITPAY") {
            const partSize = summary / countSplitPay;
            const summaryClone: any = []

            const summaryTableCourses: any = Array.from({ length: countSplitPay }).map((item: any, index: number) => {
                const reversedIndex = countSplitPay === (index + 1) ? 0 : countSplitPay - index;
                const payPaid = reversedIndex * partSize;
                const payPaid_last = countSplitPay * partSize
                const newSummaryCloneReduce = summaryClone.reduce((a: any, b: any) => a + b.total_paid, 0) || 0
                const newPayPaid_last = payPaid_last - (newSummaryCloneReduce + Number(dataEdit[index]?.total_paid || 0))
                const calBalnce = (payPaid - partSize)
                const newData = {
                    index: (index + 1),
                    total_paid: statusSplitPay === "SEPARATE" ? partSize : Number(dataEdit[index]?.total_paid) || 0,
                    balance: statusSplitPay === "SEPARATE" ? calBalnce > 0 && calBalnce || 0 : newPayPaid_last,
                    total: summary,
                    statusSplitPay: statusSplitPay
                }
                summaryClone.push(newData)
                return newData
            });
            setSummaryTable(summaryTableCourses)
        } else {
            setSummaryTable([])
        }
    }, [summary, countSplitPay, statusSplitPay, payActive.payType, dataEdit])

    useEffect(() => {
        handleSumTreament()
    }, [summary, countSplitPay, statusSplitPay, payActive.payType, dataEdit])

    const handleChangeTotalPaid = (val: any, index: number) => {
        const summaryTableCoursesTemp: any = [...summaryTable]
        const inputValue = val;
        const numericValue = inputValue.replace(/[^0-9.]/g, '');
        const delDataIndex = summaryTableCoursesTemp.filter((item: any, index_m: number) => index !== index_m)
        const totalNum = delDataIndex.reduce((accumulator: any, currentValue: any) => {
            return Number(accumulator) + Number(currentValue.total_paid);
        }, 0);

        if (numericValue <= summaryTableCoursesTemp[index].total && numericValue <= summaryTableCoursesTemp[0].total - totalNum) {
            summaryTableCoursesTemp[index].total_paid = numericValue
            summaryTableCoursesTemp.forEach((data: any, i: number) => {
                if (i === 0) {
                    summaryTableCoursesTemp[i].balance = data.total - data.total_paid
                } else {
                    summaryTableCoursesTemp[i].balance = summaryTableCoursesTemp[i - 1].balance - data.total_paid;
                    summaryTableCoursesTemp[i].total = summaryTableCoursesTemp[i - 1].balance
                }

            })
            if (index + 1 === summaryTableCoursesTemp.length) {
                // if (summaryTableCoursesTemp[summaryTableCoursesTemp.length - 1].balance === 0) {
                props.setErrorPaymentChanel(false)
                // }
            }
            setSummaryTable(summaryTableCoursesTemp)
        }
    }

    const handleSetForm = () => {
        props.setErrorPaymentChanel(false)
        const newData = {
            payType: payActive.payType,
            status: payActive.status,
            detail: JSON.stringify(summaryTable)
        }
        props.handlePaymentChannel(newData)
    }

    useEffect(() => {
        handleSetForm()
    }, [summaryTable, payActive])

    const handleCheckSplitPay = (num: any, index: number) => {
        if (statusSplitPay === "SEPARATE") {
            return numberFormat(num)
        } else if (inputFocus === index) {
            return num
        } else {
            return numberFormat(num || 0)
        }
    }

    const handleInputBlur = (num: any) => {
        if (inputFocus + 1 === summaryTable.length && Number(num) !== 0) {
            summaryTable[inputFocus].total_paid = summaryTable[inputFocus - 1].balance
            handleChangeTotalPaid(String(summaryTable[inputFocus - 1].balance), inputFocus)
        }
        setInputFocus(0)
    }

    const handleInputDate = (date: string, index: number) => {
        const summaryTableCoursesTemp: any = [...summaryTable]
        const newDate = moment(date).format('YYYY-MM-DD')

        summaryTableCoursesTemp[index].date = newDate

        setSummaryTable(summaryTableCoursesTemp)
    }

    const handleInputDateMinMax = (index: number, minmax: 'min' | 'max') => {
        const summaryTableCoursesTemp: any = [...summaryTable]

        const newDate: any = []
        summaryTableCoursesTemp.forEach((item: any, i: number) => {
            if (minmax === 'min' && i < index && item.date) {
                newDate.push(new Date(item.date))
            } else if (minmax === 'max' && i > index && item.date) {
                newDate.push(new Date(item.date))
            }
        });

        if (newDate.length !== 0) {
            if (minmax === 'min') {
                const minDate = new Date(Math.max(...newDate))
                return minDate
            } else {
                const maxDate = new Date(Math.min(...newDate))
                return maxDate
            }
        } else {
            return '-'
        }
    }

    const handleInputDateClear = (index: number) => {
        const summaryTableCoursesTemp: any = [...summaryTable]
        summaryTableCoursesTemp[index].date = ''
        setSummaryTable(summaryTableCoursesTemp)
    }

    const renderData = (objData: any) => {
        const { index, total_paid, balance, date } = objData

        const setError = props.statusError && summaryTable.length === index

        const coloumnDate = {
            option: 'COMPONENT',
            align: 'left',
            component: (
                <Box sx={{ overflow: 'visible !important', '.rmdp-container div': { overflow: 'visible !important' }, '.rmdp-container': { overflow: 'visible !important' } }}>
                    <InputSecondNewDatePicker
                        required
                        key={'date-of-birth'}
                        label={t('TREATMENT_COURSE.FORM.PLEASE_SPECIFY_DATE')}
                        disablePast={true}
                        minDate={handleInputDateMinMax(index - 1, 'min')}
                        disableFuture={true}
                        maxDate={handleInputDateMinMax(index - 1, 'max')}
                        value={date}
                        allowClear
                        onClear={() => handleInputDateClear(index - 1)}
                        // helperText={errorMessage.DATE_STRING_EMPTY || errorMessage.DATE_ANY_REQUIRED}
                        onchange={(e: any) => {
                            handleInputDate(e, index - 1)
                        }}
                    />
                </Box>),
            class: 'text-ellipsis'
        }

        const columns = [
            { option: 'TEXT', align: 'center', label: index, class: 'text-ellipsis' },
            { option: 'TEXT', align: 'left', label: `${t('TREATMENT_COURSE.FORM.PAYMENT.TIMES')}${index}`, class: 'text-ellipsis' },

            {
                option: 'COMPONENT',
                align: 'center',
                class: 'text-ellipsis',
                component: (
                    <div>
                        <InputTextField
                            key={`amount${index}`}
                            name={`amount${index}`}
                            inputTextAlign={'right'}
                            value={handleCheckSplitPay(total_paid, index)}
                            disabled={statusSplitPay === "SEPARATE" ? true : false}
                            onchange={(e) => handleChangeTotalPaid(e.target.value, (index - 1))}
                            onfocus={() => setInputFocus(index)}
                            onblur={(e) => handleInputBlur(e.target.value)}
                            style={{ 'input': { textAlign: 'right' } }}
                            params={{
                                error: props.type === 'modal' && setError ? true : false
                            }}
                        />
                        <div style={{ height: props.type !== 'modal' && setError && '23.91px' || '0px' }}></div>
                    </div>

                )
            },
            {
                option: 'COMPONENT',
                align: 'center',
                class: 'text-ellipsis',
                component: (
                    <InputTextField
                        inputTextAlign={'right'}
                        value={numberFormat(balance)}
                        helperText={props.type !== 'modal' && setError &&
                            t('TREATMENT_COURSE.MESSAGE.ERROR.NOTFULL_SPLITPAY') || ''}
                        disabled
                        style={{ 'input': { textAlign: 'right' } }}
                    />
                )
            },
        ]

        if (props.type === 'modal') {
            columns.splice(2, 0, coloumnDate);
        }

        const objRenderData = {
            key: index,
            id: index,
            obj: objData,
            columns: columns
        }

        return <TableRowCommon {...objRenderData} />
    }

    useEffect(() => {
        EditPayment()
    }, [props.dataEdit])

    const EditPayment = () => {
        const data = props.dataEdit
        if (data) {
            const newData = {
                payType: data?.paymentType,
                status: data?.paymentType !== "" ? true : false,
            }
            const newData_Detail = JSON.parse(data?.paymentDetail)
            setPayActive(newData)
            setSummaryTable(newData_Detail)
            handleChangePayment(data?.paymentType)
            if (newData_Detail) {
                setCountSplitPay(newData_Detail?.length || 0)
                setStatusSplitPay(newData_Detail[0]?.statusSplitPay || "")
            }
            setDataEdit(newData_Detail)

        }
    }

    const handleHeadCells = () => {
        const newHeadCell = [
            {
                id: 'times',
                disablePadding: false,
                label: t('TREATMENT_COURSE.FORM.PAYMENT.TIMES'),
                width: "auto"
            }, {
                id: 'date',
                disablePadding: false,
                label: t('TXC.COURSE.DATE'),
                width: "30%"
            }
        ]
        const updatedHeadCells = [
            ...InterfaceHeadCells.slice(0, 1),
            ...newHeadCell,
            ...InterfaceHeadCells.slice(2)
        ];
        setHeadCells(updatedHeadCells)
    }

    useEffect(() => {
        if (props.type === 'modal') {
            handleHeadCells()
        }
    }, [])

    return (
        <>
            <Box className='pl-md-3 select-payment-chanel' sx={{ marginLeft: { sm: '56px' }, marginTop: '32px' }}>
                <Typography className='mb-3' sx={{ color: colors.themeMainColor, fontWeight: 600 }}>
                    {t('TREATMENT_COURSE.FORM.PAYMENT.PAYMENT')}{` `}
                </Typography>
                <div className='d-flex align-items-center'>
                    <UseStyled.paymentChannels
                        className='mr-4'
                        onClick={() => handleChangePayment('CASH')}
                        style={stylePaymentChannels('CASH')}
                    >
                        <div className='d-block text-center'>
                            <div>
                                <Cash active={payActive.payType === 'CASH' && payActive.status ? colors.themeSecondColor : ''} />
                            </div>
                            <div>
                                {t('TREATMENT_COURSE.FORM.PAYMENT.CASH')}
                            </div>
                        </div>
                    </UseStyled.paymentChannels>
                    <UseStyled.paymentChannels
                        className='mr-4'
                        onClick={() => handleChangePayment('CHOOSEPAY')}
                        style={stylePaymentChannels('CHOOSEPAY')}
                    >
                        <div className='d-block text-center'>
                            <div className='d-flex justify-content-center align-items-center' style={{ height: "40px" }}>
                                <ChoosePay active={payActive.payType === 'CHOOSEPAY' && payActive.status ? colors.themeSecondColor : ''} />
                            </div>
                            <div>
                                {t('TREATMENT_COURSE.FORM.PAYMENT.CHOOSEPAY')}
                            </div>
                        </div>
                    </UseStyled.paymentChannels>
                    <UseStyled.paymentChannels
                        className='mr-4'
                        onClick={() => handleChangePayment('SPLITPAY')}
                        style={stylePaymentChannels('SPLITPAY')}
                    >
                        <div className='d-block text-center'>
                            <div>
                                {payActive.payType === 'SPLITPAY' && payActive.status ? <SplitPayActive /> : <SplitPay />}
                            </div>
                            <div>
                                {t('TREATMENT_COURSE.FORM.PAYMENT.SPLITPAY')}
                            </div>
                        </div>
                    </UseStyled.paymentChannels>
                </div>
            </Box>

            {handleShowDetailPayment('CASH') &&
                <Box className='pl-md-3' sx={{ marginLeft: { sm: '56px' }, marginTop: '32px' }}>
                    <UseStyled.DivHeadSession className='mb-3' sx={{ color: colors.themeMainColor, fontWeight: 600 }}>
                        {t('TREATMENT_COURSE.FORM.PAYMENT.CASH')}
                    </UseStyled.DivHeadSession>

                    <UseStyled.paymentBody>
                        <div className='detailPayment'>
                            {t('TREATMENT_COURSE.FORM.PAYMENT.CASH_SUM')}
                        </div>
                        <div className='sumPayment'>
                            {numberFormat(summary)} {` ${t('TREATMENT_COURSE.PAYMENT.BATH')}`}
                        </div>
                    </UseStyled.paymentBody>
                </Box>
            }

            {handleShowDetailPayment('CHOOSEPAY') &&
                <Box className='pl-md-3' sx={{ marginLeft: { sm: '56px' }, marginTop: '32px' }}>
                    <UseStyled.DivHeadSession className='mb-3' sx={{ color: colors.themeMainColor, fontWeight: 600 }}>
                        {t('TREATMENT_COURSE.FORM.PAYMENT.CHOOSEPAY')}
                    </UseStyled.DivHeadSession>
                    <UseStyled.paymentBody>
                        <div className='detailPayment'>
                            {t('TREATMENT_COURSE.FORM.PAYMENT.CHOOSEPAY')}{`(${t('TREATMENT_COURSE.FORM.PAYMENT.COUNT')} ${props.summaryTableCourses.length || props?.countCourses || 0} ${t('TREATMENT_COURSE.FORM.PAYMENT.LIST')})`}
                        </div>
                        <div className='sumPayment'>
                            {numberFormat(summary)}{` ${t('TREATMENT_COURSE.FORM.PAYMENT.BATH')}`}
                        </div>
                    </UseStyled.paymentBody>
                </Box>
            }

            {handleShowDetailPayment('SPLITPAY') &&
                <Box className='pl-md-3' sx={{ marginLeft: { sm: '56px' }, marginTop: '32px' }}>
                    <UseStyled.DivHeadSession className='mb-3' sx={{ color: colors.themeMainColor, fontWeight: 600 }}>
                        {t('TREATMENT_COURSE.FORM.PAYMENT.SPLITPAY')}
                    </UseStyled.DivHeadSession>

                    <UseStyled.paymentBody_SplitPay>
                        <div className='detailPayment mr-2' style={{ width: "100px" }} >
                            {`${t('TREATMENT_COURSE.FORM.PAYMENT.COUNT')}${t('TREATMENT_COURSE.FORM.PAYMENT.TIME')}`}
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className='sumPayment'>
                            <InputTextField
                                value={countSplitPay || ""}
                                onchange={(e) => {
                                    const inputValue = e.target.value;
                                    const numericValue = inputValue.replace(/[^0-9]/g, '');
                                    if (numericValue <= 100) {
                                        setCountSplitPay(numericValue)
                                    }

                                }}
                            />
                        </div>
                        <div className='detailPayment'>
                            {`${t('TREATMENT_COURSE.FORM.PAYMENT.TIME')}`}
                        </div>
                    </UseStyled.paymentBody_SplitPay>

                    <UseStyled.paymentBody_SplitPay className='mt-lg-2 mt-4'>
                        <div className='detailPayment' style={{ width: "100px" }}>
                            {`${t('TREATMENT_COURSE.FORM.PAYMENT.CHOOSE')}`}
                        </div>
                        <FormControl className="d-block mr-2">
                            <RadioGroup row aria-label="defaultSticker" onChange={(event) => {
                                setStatusSplitPay(event.target.value)
                                setDataEdit([])
                            }} className="flex-row  " name="row-radio-buttons-group">
                                <UseStyled.FormFormatSplitPay
                                    className={`ml-0 mr-2 pr-3  ${statusSplitPay === "SEPARATE" ? 'active' : ''}`}
                                    checked={statusSplitPay === "SEPARATE"}
                                    value="SEPARATE"
                                    control={<InputRadio />}
                                    label={`${t('TREATMENT_COURSE.FORM.PAYMENT.SEPARATE')}`}
                                />
                                <UseStyled.FormFormatSplitPay
                                    className={`ml-0 mr-2 pr-3  ${statusSplitPay === "NOTSEPARATE" ? 'active' : ''}`}
                                    checked={statusSplitPay === "NOTSEPARATE"}
                                    value="NOTSEPARATE"
                                    control={<InputRadio />}
                                    label={`${t('TREATMENT_COURSE.FORM.PAYMENT.NOTSEPARATE')}`}
                                />
                            </RadioGroup>
                        </FormControl>

                    </UseStyled.paymentBody_SplitPay>

                    <Box className='mt-3 mb-3 select-payment-chanel-table' sx={{
                        'div': {
                            maxHeight: props?.maxHeightTable || '750px',
                            overflow: 'auto'
                        },
                        '.MuiTableContainer-root table thead': {
                            position: 'sticky',
                            top: '0',
                            zIndex: '2',
                            borderRight: `1px solid ${colors.themeMainColor} !important`,
                            borderLeft: `1px solid ${colors.themeMainColor} !important`,
                        }
                    }}>
                        <TableCustom
                            page={1}
                            pageLimit={0}
                            sortType={''}
                            sortBy={''}
                            onSort={() => { return }}
                            setPageLimit={() => { return }}
                            setPage={() => { return }}
                            rowCount={0}
                            headCells={headCells}
                            rowsData={summaryTable && summaryTable.map((val: any, i: any) => {
                                return renderData(val)
                            })}
                            tableFixedWidth
                            tableMinWidth={props.type !== 'modal' ? 1280 : undefined}
                            hidePagination
                        />
                    </Box>
                </Box>
            }
        </>
    )
}