import IconProps from 'assets/pick/iconProps'

export default function payment(props: IconProps) {
  return (
    <svg xmlns="https://www.w3.org/2000/svg" width="85" height="70" viewBox="0 0 85 70">
      <path data-name="Rectangle 4340" style={{ fill: 'none' }} d="M0 0h85v70H0z" />
      <g data-name="Group 11683">
        <path data-name="Path 6264" d="M41.431 225.2v30.579A13.638 13.638 0 0 0 49.09 260c8.461 1.566 22.734.767 23.308-4.217.86-7.461-1.075-31.977-1.075-31.977l-15.7-3.548z" transform="translate(-20.315 -208.035)" style={{ fill: '#fff' }} />
        <path d="M23.447 46.866c8.773 0 17.651-3.032 17.651-8.826V11.564c0-5.794-8.878-8.237-17.651-8.237S6.255 5.488 6.255 11.282v26.991c0 5.794 8.42 8.593 17.192 8.593zm0-2.855c-8.212 0-14.052-3.738-14.052-5.294v-3.474c3.4 1.849 9.147 3.029 14.052 3.029a35.945 35.945 0 0 0 14.738-3.6v4.043c0 1.557-6.526 5.296-14.738 5.296zm0-37.526c8.212 0 14.738 4 14.738 5.559s-6.525 4.941-14.738 4.941S9.395 14.66 9.395 12.044s5.84-5.559 14.052-5.559zM9.395 17.592c3.4 1.849 9.147 2.8 14.052 2.8s11.337-.949 14.738-2.8v4.486c0 1.555-6.525 3.987-14.738 3.987S9.395 23.634 9.395 22.078zm0 8.826c3.4 1.849 9.147 2.8 14.052 2.8s11.337-.949 14.738-2.8v4.243c0 1.555-6.525 4.013-14.738 4.013S9.395 32.217 9.395 30.661z" transform="translate(12.745 7.674)" style={{ stroke: 'rgba(28,185,154,.02)', strokeWidth: '.6px', fill: props.color ? props.color : '#9e9e9e' }} />
        <g data-name="Ellipse 1" transform="translate(43.124 35)" style={{ stroke: props.color ? props.color : '#9e9e9e', strokeWidth: '3px', fill: '#fff' }}>
          <circle cx="12" cy="12" r="12" style={{ stroke: 'none' }} />
          <circle cx="12" cy="12" r="10.5" style={{ fill: 'none' }} />
        </g>
        <path data-name="Path 5747" d="M267.807 40.967v1.147a2.935 2.935 0 0 0 .978 5.7.978.978 0 1 1 0 1.957 1.77 1.77 0 0 1-1.271-.74.979.979 0 1 0-1.5 1.259 4.059 4.059 0 0 0 1.79 1.274v1.146a.978.978 0 0 0 1.957 0v-1.147a2.935 2.935 0 0 0-.978-5.7.979.979 0 0 1 0-1.957 1.571 1.571 0 0 1 1.058.512.979.979 0 1 0 1.361-1.406 3.78 3.78 0 0 0-1.441-.9v-1.14a.978.978 0 1 0-1.957 0zm0 0" transform="translate(-213.629 .162)" style={{ fill: props.color ? props.color : '#9e9e9e' }} />
      </g>
    </svg>

  )
}
