import moment from 'moment'
import _ from 'lodash'
import { Box, TableRow, TableCell } from '@mui/material'
import { colors } from 'constants/theme'
import { clinicTheme } from 'api/api'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** INTERFACE */
import { TreatmentPanelProps, ItemRender, NoItem } from 'component/Treatments/PopupEmrOverview/PopupEmrOverview'

/** COMPONENT */
import TableCustom from 'component/Table/TableCustom'

/** UTILS */
import { toBuddhistYear, numberFormat } from 'utils/app.utils'
import useWindowSize from 'utils/useWindowSize'
import { renderSumDiscount, renderSumAdditional, RawDiscountType, RawAdditionalType } from 'features/treatments/SummaryTreatment/SummaryTreatment'

/** STYLE */
import * as UseStyled from 'component/Treatments/PopupEmrOverview/UseStyled'

type RenderDataProps = {
  no: number
  item: any
  renderNextVisit: string
}

export default function EmrTx(props: TreatmentPanelProps) {
  const { t } = useTranslation()
  const [width] = useWindowSize()

  const renderNextVisit = (value: any) => {
    return `${t('TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.TEMPLATE_MESSAGE.NEXT_VISIT', {
      date: value?.date ? toBuddhistYear(moment(value?.date), 'DD/MM/YYYY') : '-',
      time: value?.timeStart ? toBuddhistYear(moment(value.timeStart, 'HH:mm'), 'HH:mm') : '-',
      dentist: value.doctorFullname || '-',
      treatment: renderTreatmentNextVisit(value?.treatment)
    })}`
  }

  const renderTreatmentNextVisit = (value: any) => {
    const treatment = typeof value === 'string' && value ? JSON.parse(value) : value
    if (!_.isEmpty(treatment)) {
      return `${treatment[0]?.operativeName || treatment[0]?.operativeTypeName} ${_.size(treatment) > 1 ? t('TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.TEMPLATE_MESSAGE.NEXT_VISIT_MORE_TREATMENT', {
        treatment_count: _.size(treatment) - 1
      }) : ''}`
    } else return ''
  }

  const headCellsTooth = clinicTheme.theme === clinicTheme.type.DENTAL ? [{ id: 'message', disablePadding: false, label: t('TREATMENT_SUMMARY.TITLE.TOOTH') }] : []

  const headCells = [
    { id: 'no', disablePadding: false, label: '#' },
    ...headCellsTooth,
    { id: 'message', disablePadding: false, label: t('TREATMENT_SUMMARY.TITLE.TYPE') },
    { id: 'message', disablePadding: false, label: t('TREATMENT_SUMMARY.TITLE.SERVICE') },
    { id: 'message', disablePadding: false, align: 'center', label: t('TREATMENT_SUMMARY.TITLE.QUANTITY') },
    { id: 'message', disablePadding: false, align: 'right', label: t('TREATMENT_SUMMARY.TITLE.NET_PRICE') },
    { id: 'message', disablePadding: false, label: t('TREATMENT_SUMMARY.TITLE.DOCTOR') },
    { id: 'message', disablePadding: false, align: 'right', label: t('TREATMENT_SUMMARY.TITLE.DISCOUNT') },
    { id: 'message', disablePadding: false, align: 'right', label: t('TREATMENT_SUMMARY.TITLE.ADDON') },
    { id: 'message', disablePadding: false, align: 'right', label: t('TREATMENT_SUMMARY.TITLE.TOTAL') },
  ]

  return (
    <>
      {
        _.map(props.treatments, (item: ItemRender, index: number) => {
          if (!_.isEmpty(item.treatment))
            return (
              <UseStyled.TreatmentBox key={index}>
                <UseStyled.LabelDate>{toBuddhistYear(moment(item.date), 'DD/MM/YY HH.mm')} {t('TIME_UNIT')}</UseStyled.LabelDate>
                <Box>
                  {width >= 1200 ? (
                    <UseStyled.ResponsiveTable type='tx'>
                      <TableCustom
                        hidePagination
                        page={0}
                        pageLimit={0}
                        sortType={'ASC'}
                        sortBy={''}
                        rowCount={0}
                        textEmptyData={t('REGISTER.NO_ITEM')}
                        onSort={() => { return }}
                        setPageLimit={() => { return }}
                        setPage={() => { return }}
                        headCells={headCells}
                        rowsData={
                          _.map(item.treatment, (treatment, indexTreatment: number) => {
                            return <RenderDataAsTable item={treatment} no={indexTreatment + 1} renderNextVisit={treatment?.nextVisit ? renderNextVisit(treatment?.nextVisit) : ''} />
                          }).reverse()
                        }
                      />
                    </UseStyled.ResponsiveTable>
                  ) : (
                    !_.isEmpty(item.treatment) && (
                      _.map(item.treatment, (treatment, indexTreatment: number) => {
                        return (
                          <Box key={indexTreatment}
                            sx={{
                              '&:not(:first-of-type)': {
                                paddingTop: '2px'
                              },
                              '&:not(:last-of-type)': {
                                borderBottom: `1px solid ${colors.lightGray}`,
                                paddingBottom: '10px'
                              }
                            }}>
                            <RenderDataAsList item={treatment} no={indexTreatment + 1} renderNextVisit={treatment?.nextVisit ? renderNextVisit(treatment?.nextVisit) : ''} />
                          </Box>
                        )
                      }).reverse()
                    ) ||
                    <NoItem />
                  )}
                </Box>
              </UseStyled.TreatmentBox>
            )
        })
      }
    </>
  )
}

function RenderDataAsTable(props: RenderDataProps) {
  const { t } = useTranslation()

  const price = props.item?.price || 0
  const qty = props.item?.qty || 0
  const propAdditional = Number(props.item?.additional || 0)
  const additionalUnit = props.item?.additionalUnit

  const rawAdditional: RawAdditionalType = {
    price: price,
    qty: qty,
    additional: propAdditional,
    additionalUnit: additionalUnit,
  }

  const propDiscount = Number(props.item?.discount || 0)
  const discountUnit = props.item?.discountUnit
  const dfUnit = props.item?.dfUnit || 0
  const dfAmount = Number(props.item?.dfAmount || 0)
  const dfDiscountAmount = Number(props.item?.dfDiscountAmount || 0)
  const dfDiscountUnit = props.item?.dfDiscountUnit || 0
  const cfAmount = Number(props.item?.cfAmount) || 0
  const cfDiscountAmount = Number(props.item?.cfDiscountAmount || 0)
  const cfDiscountUnit = props.item?.cfDiscountUnit
  const additionalNet = renderSumAdditional(rawAdditional)
  const doctorFeeAmount = props.item?.doctorFeeAmount || 0
  const clinicFeeAmount = props.item?.clinicFeeAmount || 0

  const rawDiscount: RawDiscountType = {
    price: price,
    qty: qty,
    discount: propDiscount,
    discountUnit: discountUnit,
    dfUnit: dfUnit,
    dfAmount: dfAmount,
    dfDiscountAmount: dfDiscountAmount,
    dfDiscountUnit: dfDiscountUnit,
    cfAmount: cfAmount,
    cfDiscountAmount: cfDiscountAmount,
    cfDiscountUnit: cfDiscountUnit,
    additionalNet: additionalNet,
    doctorFeeAmount: doctorFeeAmount,
    clinicFeeAmount: clinicFeeAmount
  }

  const additional = renderSumAdditional(rawAdditional)
  const discount = renderSumDiscount(rawDiscount)

  return (
    <>
      <TableRow className='main-row'>
        <TableCell align='center'>{props.no}</TableCell>
        {clinicTheme.theme === clinicTheme.type.DENTAL && (
          <TableCell sx={{ wordBreak: 'break-all' }}>{props.item?.teeth || '-'}</TableCell>
        )}
        <TableCell sx={{ wordBreak: 'break-all' }}>{props.item?.operativeTypeName || props.item?.itemTypeName || '-'}</TableCell>
        <TableCell sx={{ wordBreak: 'break-all' }}>{props.item?.operativeName || props.item?.itemName || '-'}</TableCell>
        <TableCell align='center' sx={{ wordBreak: 'break-all' }}>{props.item?.qty || 0}</TableCell>
        <TableCell align='right' sx={{ wordBreak: 'break-all' }}>{numberFormat(props.item?.price || 0)}</TableCell>
        <TableCell sx={{ wordBreak: 'break-all' }}>{!_.isEmpty(props.item?.user) ? `${props.item?.user?.firstname} ${props.item?.user?.lastname}` : props.item?.createdBy || '-'}</TableCell>
        <TableCell align='right' sx={{ wordBreak: 'break-all' }}>{numberFormat(discount || 0)}</TableCell>
        <TableCell align='right' sx={{ wordBreak: 'break-all' }}>{numberFormat(additional || 0)}</TableCell>
        <TableCell align='right' sx={{ wordBreak: 'break-all' }}>{numberFormat(props.item?.total || 0)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell></TableCell>
        <TableCell colSpan={3}>
          <Box className='d-flex'>
            <UseStyled.Typo type='title' className='pr-3' sx={{ width: 'max-content' }}>{t('TREATMENT_SUMMARY.TITLE.MORE_INFO')}</UseStyled.Typo>
            <UseStyled.Typo sx={{ flex: 1 }}><pre className='note' style={{ fontSize: '16px' }}>{props.item?.note || '-'}</pre></UseStyled.Typo>
          </Box>
        </TableCell>
        <TableCell colSpan={6}>
          <Box className='d-flex'>
            <UseStyled.Typo type='title' className='pr-3' sx={{ width: 'max-content' }}>{t('Next visit')}</UseStyled.Typo>
            <UseStyled.Typo sx={{ flex: 1 }}>{props.item?.noteNextVisit || '-'}</UseStyled.Typo>
          </Box>
          {props.renderNextVisit &&
            <Box className='d-flex'>
              <UseStyled.Typo type='title' className='pr-3' sx={{ width: 'max-content', opacity: 0 }}>{t('Next visit')}</UseStyled.Typo>
              <UseStyled.Typo className='mt-1'>
                <span style={{ fontWeight: 600 }}>{t('TREATMENT_SUMMARY.TITLE.APPOINTMENT')} :</span>
                <span className='ml-1' style={{ color: colors.textLightGray }}>{props.renderNextVisit}</span>
              </UseStyled.Typo>
            </Box>
          }
        </TableCell>
      </TableRow>
    </>
  )
}

function RenderDataAsList(props: RenderDataProps) {
  const { t } = useTranslation()

  const rawAdditional: RawAdditionalType = {
    price: props.item?.price || 0,
    qty: props.item?.qty || 0,
    additional: Number(props.item?.additional || 0),
    additionalUnit: props.item?.additionalUnit,
  }

  const rawDiscount: RawDiscountType = {
    price: props.item?.price || 0,
    qty: props.item?.qty || 0,
    discount: Number(props.item?.discount || 0),
    discountUnit: props.item?.discountUnit,
    dfUnit: props.item?.dfUnit || 0,
    dfAmount: Number(props.item?.dfAmount || 0),
    dfDiscountAmount: Number(props.item?.dfDiscountAmount || 0),
    dfDiscountUnit: props.item?.dfDiscountUnit || 0,
    cfAmount: Number(props.item?.cfAmount) || 0,
    cfDiscountAmount: Number(props.item?.cfDiscountAmount || 0),
    cfDiscountUnit: props.item?.cfDiscountUnit,
    additionalNet: renderSumAdditional(rawAdditional),
    doctorFeeAmount: props.item?.doctorFeeAmount || 0,
    clinicFeeAmount: props.item?.clinicFeeAmount || 0
  }

  const additional = renderSumAdditional(rawAdditional)
  const discount = renderSumDiscount(rawDiscount)

  return (
    <>
      <Box className='d-flex mt-2'>
        <UseStyled.TypoNo>{props.no}</UseStyled.TypoNo>
        {clinicTheme.theme === clinicTheme.type.DENTAL && (
          <>
            <UseStyled.Typo type='title'>{t('TREATMENT_SUMMARY.TITLE.TOOTH')}</UseStyled.Typo>
            <UseStyled.Typo sx={{ flex: 1, wordBreak: 'break-all' }}>{props.item?.teeth || '-'}</UseStyled.Typo>
          </>
        ) || (
            <>
              <UseStyled.Typo type='title'>{t('TREATMENT_SUMMARY.TITLE.TYPE')}</UseStyled.Typo>
              <UseStyled.Typo sx={{ flex: 1, wordBreak: 'break-all' }}>{props.item?.operativeTypeName || props.item?.itemTypeName || '-'}</UseStyled.Typo>
            </>
          )}
      </Box>
      {clinicTheme.theme === clinicTheme.type.DENTAL && (
        <Box className='d-flex mt-2'>
          <UseStyled.TypoNo></UseStyled.TypoNo>
          <UseStyled.Typo type='title'>{t('TREATMENT_SUMMARY.TITLE.TYPE')}</UseStyled.Typo>
          <UseStyled.Typo sx={{ flex: 1, wordBreak: 'break-all' }}>{props.item?.operativeTypeName || props.item?.itemTypeName || '-'}</UseStyled.Typo>
        </Box>
      )}
      <Box className='d-flex mt-2'>
        <UseStyled.TypoNo></UseStyled.TypoNo>
        <UseStyled.Typo type='title'>{t('TREATMENT_SUMMARY.TITLE.SERVICE')}</UseStyled.Typo>
        <UseStyled.Typo sx={{ flex: 1, wordBreak: 'break-all' }}>{props.item?.operativeName || props.item?.itemName || '-'}</UseStyled.Typo>
      </Box>
      <Box className='d-flex flex-column flex-md-row'>
        <Box className='d-flex mt-2' sx={{ flex: 1 }}>
          <UseStyled.TypoNo></UseStyled.TypoNo>
          <UseStyled.Typo type='title'>{t('TREATMENT_SUMMARY.TITLE.QUANTITY')}</UseStyled.Typo>
          <UseStyled.Typo sx={{ flex: 1, wordBreak: 'break-all' }}>{props.item?.qty || 0}</UseStyled.Typo>
        </Box>
        <Box className='d-flex mt-2' sx={{ flex: 1 }}>
          <UseStyled.TypoNo></UseStyled.TypoNo>
          <UseStyled.Typo type='title'>{t('TREATMENT_SUMMARY.TITLE.NET_PRICE')}</UseStyled.Typo>
          <UseStyled.Typo sx={{ flex: 1, wordBreak: 'break-all' }}>{numberFormat(props.item?.price || 0)}</UseStyled.Typo>
        </Box>
      </Box>
      <Box className='d-flex mt-2' sx={{ flex: 1 }}>
        <UseStyled.TypoNo></UseStyled.TypoNo>
        <UseStyled.Typo type='title'>{t('TREATMENT_SUMMARY.TITLE.DOCTOR')}</UseStyled.Typo>
        <UseStyled.Typo sx={{ flex: 1, wordBreak: 'break-all' }}>{!_.isEmpty(props.item?.user) ? `${props.item?.user?.firstname} ${props.item?.user?.lastname}` : props.item?.createdBy || '-'}</UseStyled.Typo>
      </Box>
      <Box className='d-flex flex-column flex-md-row'>
        <Box className='d-flex mt-2' sx={{ flex: 1 }}>
          <UseStyled.TypoNo></UseStyled.TypoNo>
          <UseStyled.Typo type='title'>{t('TREATMENT_SUMMARY.TITLE.DISCOUNT')}</UseStyled.Typo>
          <UseStyled.Typo sx={{ flex: 1, wordBreak: 'break-all' }}>{numberFormat(discount || 0)}</UseStyled.Typo>
        </Box>
        <Box className='d-flex mt-2' sx={{ flex: 1 }}>
          <UseStyled.TypoNo></UseStyled.TypoNo>
          <UseStyled.Typo type='title'>{t('TREATMENT_SUMMARY.TITLE.ADDON')}</UseStyled.Typo>
          <UseStyled.Typo sx={{ flex: 1, wordBreak: 'break-all' }}>{numberFormat(additional || 0)}</UseStyled.Typo>
        </Box>
      </Box>
      <Box className='d-flex mt-2' sx={{ flex: 1 }}>
        <UseStyled.TypoNo></UseStyled.TypoNo>
        <UseStyled.Typo type='title'>{t('TREATMENT_SUMMARY.TITLE.TOTAL')}</UseStyled.Typo>
        <UseStyled.Typo sx={{ flex: 1, wordBreak: 'break-all' }}>{numberFormat(props.item?.total || 0)}</UseStyled.Typo>
      </Box>
      <Box className='d-flex flex-column flex-md-row mt-2' sx={{ flex: 1 }}>
        <Box className='d-flex'>
          <UseStyled.TypoNo></UseStyled.TypoNo>
          <UseStyled.Typo type='title'>{t('TREATMENT_SUMMARY.TITLE.MORE_INFO')}</UseStyled.Typo>
        </Box>
        <Box className='d-flex mt-2 mt-md-0'>
          <UseStyled.TypoNo className='d-md-none'></UseStyled.TypoNo>
          <UseStyled.Typo sx={{ flex: 1, wordBreak: 'break-all' }}><pre className='note' style={{ fontSize: '16px' }}>{props.item?.note || '-'}</pre></UseStyled.Typo>
        </Box>
      </Box>
      <Box className='d-flex flex-column flex-md-row mt-2' sx={{ flex: 1 }}>
        <Box className='d-flex'>
          <UseStyled.TypoNo></UseStyled.TypoNo>
          <UseStyled.Typo type='title'>{t('Next visit')}</UseStyled.Typo>
        </Box>
        <Box className='d-flex mt-2 mt-md-0'>
          <UseStyled.TypoNo className='d-md-none'></UseStyled.TypoNo>
          <UseStyled.Typo sx={{ flex: 1, wordBreak: 'break-all' }}>{props.item?.noteNextVisit || '-'}</UseStyled.Typo>
        </Box>
      </Box>
      {props.renderNextVisit && (
        <Box className='d-flex flex-column flex-md-row' sx={{ flex: 1 }}>
          <Box className='d-none d-md-flex'>
            <UseStyled.TypoNo></UseStyled.TypoNo>
            <UseStyled.Typo type='title' sx={{ opacity: 0 }}>{t('Next visit')}</UseStyled.Typo>
          </Box>
          <Box className='d-flex'>
            <UseStyled.TypoNo className='d-md-none'></UseStyled.TypoNo>
            <UseStyled.Typo className='mt-1' sx={{ flex: 1 }}>
              <span style={{ fontWeight: 600 }}>{t('TREATMENT_SUMMARY.TITLE.APPOINTMENT')} :</span>
              <span className='ml-1' style={{ color: colors.textLightGray }}>{props.renderNextVisit}</span>
            </UseStyled.Typo>
          </Box>
        </Box>
      ) || <></>}
    </>
  )
}