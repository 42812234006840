import { useCallback, useEffect, useState } from 'react'
import { Box, Typography, TableRow, TableCell, Tooltip } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AddBoxIcon from '@mui/icons-material/AddBox'
import _ from 'lodash'
import { clinicTheme } from 'api/api'

/** UTILS || SLICE */
import { treatment, setTreatmentByKey, TreatmentStateInterface, setColorToothChart, setSubmitTootChart, isEditTootChart, setEditTootChart, setEditTootChartType, isEditTootChartType } from 'app/slice/treatment.slice'
import { getClinicInfo, numberFormat } from 'utils/app.utils'
import useWindowSize from 'utils/useWindowSize'
import { handleShow, showModal, resetModal } from 'app/slice/modal.slice'

/** API */
import { DiagnosisDataInterface, DxRecommendDataInterface } from 'api/setting/treatments/diagnosis.api'
import ComponentApi, { Bookmark } from 'api/components-api'
import TreatmentApi from 'api/dentists/treatment.api'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { Icons } from 'constants/images'

/** COMPONENT */
import ToothChart from 'component/ToothChart/ToothChart'
import InputTextSearch from 'component/Input/InputTextSearch'
import TableCustom from 'features/treatments/MainMenu/OPD/Table/TableCustom'
import PopupEmrOverview from 'component/Treatments/PopupEmrOverview/PopupEmrOverview'
import PopupAddDx from 'component/Treatments/PopupAddDx'
import Loading from 'component/Loading'

/** STYLE */
import { IconAddBox, IconBookmark } from 'features/treatments/MainMenu/OPD/style/UseStyle'
import { TxStyled, TypeTabs, ButtonType, ButtonTreatmentOverview, ResponsiveTxTable, FragmentToothChart, FragmentTxTable, ButtonToothChart } from 'features/treatments/MainMenu/OPD/style/TxUseStyle'
import ButtonCustom from 'component/Button/ButtonCustom'
import TeethAdult from 'assets/toothChart/type-teeth/teethAdult'
import TeethChild from 'assets/toothChart/type-teeth/teethChild'
import OperativeApi from 'api/setting/treatments/operative.api'
import { selectMe } from 'app/slice/user.slice'

type TxType = {
  userId?: number
  patientId: number
  disabled?: boolean
  hasCreateTreatment?: 'PERMISS' | 'UNPERMISS'
  type?: 'Treatment' | 'Payment' | 'HistoryTreatment' | 'RetrospectiveOrder' | 'Sentting_TREATMENT_PLAN'
}

export default function TX(props: TxType) {
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const isEditTootCharts: any = useSelector(isEditTootChart)
  const isEditTootChartTypes: any = useSelector(isEditTootChartType)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [width] = useWindowSize()
  const modal: boolean = useSelector(handleShow)
  const branchInfo = getClinicInfo()
  const user = useSelector(selectMe)

  const [isShowPopupEmrOverview, setIsShowPopupEmrOverview] = useState<boolean>(false)
  const [isShowPopupDx, setIsShowPopupDx] = useState<{ show: boolean; data: { dx: DiagnosisDataInterface[]; recommend: DxRecommendDataInterface[] }; tx: any }>({ show: false, data: { dx: [], recommend: [] }, tx: {} })
  const [numberPage, setNumberPage] = useState(0)
  const userId = props.userId
  const [operativeTypes, setOperativeTypes] = useState([])
  const [operatives, setOperatives] = useState([])
  const [typeSelected, setTypeSelected] = useState('All')
  const [isBookmark, setIsBookmark] = useState(0)
  const [typeId, setTypeId] = useState(0)
  const [selectOperativeLoading, setSelectOperativeLoading] = useState(false)

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(6)
  const [rowCount, setRowCount] = useState(0)
  const [search, setSearch] = useState('')

  const [teethType, setTeethType] = useState('ADULT')
  const [doctorHidden, setDoctorHidden] = useState<boolean>(false)

  useEffect(()=>{
    const newDoctor:number[] = []
    treatments?.summeryTreatments?.forEach((item:any)=>{
      if (item?.isEdit === "0" && !newDoctor.includes(item.doctorId)) {
        newDoctor.push(item.doctorId)
      }
    })
    // branchInfo
    setDoctorHidden(branchInfo?.pdfInvoiceTreatmentCondition === '0' ? newDoctor.includes(user?.userId) : false)
  },[treatments,user?.userId])

  const loadDataOperativeType = useCallback(() => {
    TreatmentApi.findAllOperativeType()
      .then(({ data }) => setOperativeTypes(data))
      .catch(() => {
        return
      })
  }, [])

  const loadData = useCallback(
    (valueUserId: number) => {
      let condition: any = {}
      if (page) condition = { ...condition, page: page }
      if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
      if (search) condition = { ...condition, search: search }
      condition = { ...condition, userId: valueUserId }
      if (isBookmark) condition = { ...condition, isBookmark: isBookmark }
      if (typeId) condition = { ...condition, typeId: typeId }

      TreatmentApi.findAllOperative(condition)
        .then(({ headers, data }) => {
          setRowCount(headers['x-total'])
          setOperatives(data)
          setNumberPage(page)
        })
        .catch(() => {
          return
        })
    },
    [page, pageLimit, search, isBookmark, typeId]
  )

  useEffect(() => {
    loadDataOperativeType()
  }, [loadDataOperativeType])

  useEffect(() => {
    if (userId) {
      loadData(userId)
      handleTooltip()
    }
  }, [loadData, userId])

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const handleChangeType = (ele: any) => {
    const type = ele.currentTarget.getAttribute('data-value')
    setTypeSelected(type)
    setIsBookmark(0)
    setTypeId(0)
    setPage(1)

    if (type === 'BOOKMARK') setIsBookmark(1)
    else if (type === 'All') setTypeId(0)
    else setTypeId(parseInt(type))
  }

  const handleBookmark = (id: number, bookmarkId: string) => {
    const payload: Bookmark = {
      bookmarkType: 'OPERATIVE',
      userId: userId || 0,
      itemId: id,
      isBookmark: bookmarkId ? '0' : '1'
    }

    ComponentApi.bookmark(payload)
      .then(() => loadData(userId || 0))
      .catch(() => {
        return
      })
  }

  const handleTooltip = () => {
    const textContainer = document.querySelector('.text-treatment')
    if (textContainer) {
      const textElement = textContainer.querySelector('p')
      if (textElement) {
        if (textElement.scrollWidth > textContainer.clientWidth) {
          console.log('Text is overflowing!')
        } else {
          console.log('Text is not overflowing.')
        }
      }
    }
  }

  const handleAddOperative = (tx: any) => {
    const hasOverdueCondition = _.some(treatments?.summeryTreatments, { isEdit: '0', doctorId: _.get(treatments.summeryTreatments[0], 'pinDoctorId') || userId })
    const hasOverdue = branchInfo?.pdfInvoiceTreatmentCondition === '0' ? hasOverdueCondition : false

    let item = {
      ...tx,
      qty: 1,
      total: tx.price,
      doctorId: !hasOverdue ? _.get(treatments.summeryTreatments[0], 'pinDoctorId') || userId : null,
      discount: 0,
      additional: 0,
      discountUnit: 'BAHT',
      additionalUnit: 'BAHT',
      dfAmount: 0,
      cfAmount: 0,
      dfDiscountAmount: 0,
      cfDiscountAmount: 0,
      dfDiscountUnit: 'BAHT',
      cfDiscountUnit: 'BAHT',
      isEdit: null
    }
    if (props.type === 'Sentting_TREATMENT_PLAN') {
      dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: [...treatments.summeryTreatments, item] }))
      dispatch(setColorToothChart({ value: tx.color }))
      dispatch(setSubmitTootChart(true))
    } else {
      setSelectOperativeLoading(true)
      TreatmentApi.findOperativeById(tx.operativeId)
        .then(async ({ data }) => {
          if (!_.isEmpty(data)) {
            let dxRecommend: DxRecommendDataInterface[] = []
            _.forEach(data, (d: DiagnosisDataInterface) => {
              if (!_.isEmpty(d.diagnosisRecommends)) dxRecommend = [...dxRecommend, ...(d.diagnosisRecommends || [])]
            })
            if (props.type !== 'Sentting_TREATMENT_PLAN') {
              setIsShowPopupDx({ show: true, data: { dx: data, recommend: dxRecommend }, tx: item })
            }
            dispatch(showModal())
            dispatch(setSubmitTootChart(false))
          } else {
            if (tx.operativeId) {
              const resp: any = await OperativeApi.findById(tx.operativeId)
              const data = {
                clinicFeeId: resp?.data?.operativeClinicFees[0]?.clinicFeeId,
                clinicFeeName: resp?.data?.operativeClinicFees[0]?.clinicFeeText,
                clinicFeePrice: resp?.data?.operativeClinicFees[0]?.price
              }
              if (resp.status === 200) item = { ...item, ...data }
            }
            dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: [...treatments.summeryTreatments, item] }))
            dispatch(setColorToothChart({ value: tx.color }))
            dispatch(setSubmitTootChart(true))
          }
        })
        .catch((e) => {
          return
        })
        .finally(() => setSelectOperativeLoading(false))
    }
  }

  const desktopHeadCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'type', disablePadding: false, label: t('TX.TABLE.TYPE') }
  ]

  const mobileHeadCells = [{ id: 'action', disablePadding: false, label: 'Action', align: width >= 768 ? 'center' : 'right', class: 'pr-1 pr-md-3' }]

  const tableHeadCells = [
    { id: 'code', disablePadding: false, label: t('TX.TABLE.CODE'), align: 'center' },
    { id: 'service', disablePadding: false, label: t('TX.TABLE.SERVICE') },
    { id: 'unit', disablePadding: false, label: t('TX.TABLE.UNIT'), align: 'center' },
    { id: 'price', disablePadding: false, label: t('TX.TABLE.PRICE'), align: 'right' }
  ]

  const headCells = width >= 768 ? (width >= 1200 && [...desktopHeadCells, ...tableHeadCells, ...mobileHeadCells]) || [...tableHeadCells, ...mobileHeadCells] : [...mobileHeadCells]

  const renderData = (objData: any, no: number) => {
    no = numberPage * pageLimit - pageLimit + no + 1
    const { bookmarkId, operativeCode, operativeId, operativeName, operativeTypeName, operativeUnitName, price } = objData
    let tooltip = false
    const handleTooltip = () => {
      const textContainer = document.querySelector(`.text-treatment-${operativeId}`)
      if (textContainer) {
        const textElement = textContainer.querySelector('p')
        if (textElement) {
          if (textElement.scrollWidth > textContainer.clientWidth) {
            tooltip = true
          } else {
            tooltip = false
          }
        }
      }
    }

    handleTooltip()

    return (
      <TableRow key={operativeId} className={`treatment-raw-${operativeId}`}>
        {(width >= 768 && (
          <>
            {width >= 1200 && (
              <>
                <TableCell align="center" scope="row">
                  {no}
                </TableCell>
                <TableCell align="left" scope="row" className="text-ellipsis" title={operativeTypeName}>
                  {operativeTypeName}
                </TableCell>
              </>
            )}
            <TableCell align="center" scope="row" className="text-ellipsis">
              {operativeCode}
            </TableCell>
            {!tooltip && (
              <TableCell align="left" scope="row" className={`text-ellipsis text-treatment-${operativeId}`}>
                <p style={{ height: 'fit-content', marginBottom: '-0px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{operativeName}</p>
              </TableCell>
            )}
            {tooltip && (
              <Tooltip title={operativeName} placement="top">
                <TableCell align="left" scope="row" className={`text-ellipsis text-treatment-${operativeId}`}>
                  <p style={{ height: 'fit-content', marginBottom: '-0px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{operativeName}</p>
                </TableCell>
              </Tooltip>
            )}
            <TableCell align="center" scope="row" className="text-ellipsis">
              {operativeUnitName}
            </TableCell>
            <TableCell align="right" scope="row" className="text-ellipsis">
              {numberFormat(price)}
            </TableCell>
            <TableCell align="center" scope="row">
              <IconBookmark key={operativeId} className="p-0 mr-1" onClick={() => handleBookmark(operativeId, bookmarkId)} sx={{ backgroundColor: 'transparent !important' }}>
                {bookmarkId ? <Icons.StarActive width="24" height="24" /> : <Icons.StarInActive width="24" height="24" />}
              </IconBookmark>
              <IconAddBox 
                disabled={(props?.hasCreateTreatment === 'PERMISS' && !doctorHidden ) ? false : true} 
                key={operativeId} className="p-0 ml-1" 
                onClick={() => handleAddOperative(objData)} 
                sx={{ backgroundColor: 'transparent !important' }}
              >
                <AddBoxIcon style={{ width: 30, height: 30 }} />
              </IconAddBox>
            </TableCell>
          </>
        )) || (
            <>
              <TableCell align="left" scope="row">
                <Box className="d-flex justify-content-between">
                  <Box>
                    <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                      {t('TX.TABLE.CODE')}
                    </Typography>
                    <Typography className="d-inline">{operativeCode}</Typography>
                  </Box>
                  <Box>
                    <IconBookmark key={operativeId} className="p-0 mr-1" onClick={() => handleBookmark(operativeId, bookmarkId)} sx={{ backgroundColor: 'transparent !important' }}>
                      {bookmarkId ? <Icons.StarActive width="24" height="24" /> : <Icons.StarInActive width="24" height="24" />}
                    </IconBookmark>
                    <IconAddBox disabled={props?.hasCreateTreatment === 'PERMISS' ? false : true} key={operativeId} className="p-0 ml-1" onClick={() => handleAddOperative(objData)} sx={{ backgroundColor: 'transparent !important' }}>
                      <AddBoxIcon style={{ width: 30, height: 30 }} />
                    </IconAddBox>
                  </Box>
                </Box>
                <Box>
                  <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                    {t('TX.TABLE.SERVICE')}
                  </Typography>
                  {!tooltip && (
                    <Typography className={`d-inline text-treatment-${operativeId}`}>{operativeName}</Typography>
                  )}
                  {tooltip && (
                    <Tooltip title={operativeName} placement="top">
                      <Typography className={`d-inline text-treatment-${operativeId}`}>{operativeName}</Typography>
                    </Tooltip>
                  )}
                </Box>
                <Box>
                  <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                    {t('TX.TABLE.UNIT')}
                  </Typography>
                  <Typography className="d-inline">{operativeUnitName}</Typography>
                </Box>
                <Box>
                  <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                    {t('TX.TABLE.PRICE')}
                  </Typography>
                  <Typography className="d-inline">{numberFormat(price)}</Typography>
                </Box>
              </TableCell>
            </>
          )}
      </TableRow>
    )
  }

  const onViewEmrOverview = () => {
    dispatch(showModal())
    dispatch(setEditTootChart(null))
    dispatch(setEditTootChartType(''))
    setIsShowPopupEmrOverview(true)
  }

  useEffect(() => {
    if (!modal) {
      setIsShowPopupEmrOverview(false)
      setIsShowPopupDx({ show: false, data: { dx: [], recommend: [] }, tx: {} })
      dispatch(resetModal())
    }
  }, [modal])

  useEffect(() => {
    if (isEditTootChartTypes) {
      setTeethType(isEditTootChartTypes)
    }
  }, [isEditTootChartTypes])
  return (
    <div className="w-100 h-100">
      <TxStyled className={`${props.disabled ? 'tx-disabled' : ''}`}>
        {clinicTheme.theme === clinicTheme.type.DENTAL && (
          <FragmentToothChart className="mx-auto mb-3 mb-md-5 mb-xl-0" sx={{ flex: 'none' }}>
            <Box className="d-none d-md-block">
              <ToothChart type={'treatment'} teethType={teethType} patientId={props.patientId} isRetrospectiveOrder={props.type === 'RetrospectiveOrder'} />
            </Box>
            <Box className="d-flex justify-content-center">
              <ButtonToothChart onClick={() => setTeethType('ADULT')} className="mt-0 mt-md-3 " sx={{ flex: 'none' }}>
                <TeethAdult type={teethType} />
              </ButtonToothChart>
              <ButtonToothChart onClick={() => setTeethType('CHILD')} className="mt-0 mt-md-3 pl-1 pr-3" sx={{ flex: 'none' }}>
                <TeethChild type={teethType} />
              </ButtonToothChart>
              {props.type !== 'Sentting_TREATMENT_PLAN' && (
                <ButtonTreatmentOverview variant={'outlined'} onClick={onViewEmrOverview} size="small" className="mt-0 mt-md-3 px-3" disabled={props.disabled}>
                  {t('OPD.BUTTON.TREATMENT_OVERVIEW')}
                </ButtonTreatmentOverview>
              )}
            </Box>
          </FragmentToothChart>
        )}
        {clinicTheme.theme !== clinicTheme.type.DENTAL && (
          <ButtonCustom onClick={onViewEmrOverview} disabled={props.disabled} textButton={t('OPD.BUTTON.TREATMENT_OVERVIEW')} className="d-md-none w-100 px-3" style={{ margin: 0 }} btnStyle={{ height: '36px' }} />
        )}
        <FragmentTxTable className={`${isEditTootCharts !== null || props.disabled ? 'tx-table-disabled pe-none' : ''}`}>
          <Box className="d-flex justify-content-between align-items-center pb-2">
            <Typography sx={{ fontSize: 18, fontWeight: 500, flex: 'none' }}>{t('OPD.TITLE.TX_TYPE')}</Typography>
            <Box className="d-flex align-items-center justify-content-end" sx={{ flex: 1 }}>
              {clinicTheme.theme !== clinicTheme.type.DENTAL && (
                <ButtonCustom onClick={onViewEmrOverview} disabled={props.disabled} textButton={t('OPD.BUTTON.TREATMENT_OVERVIEW')} className="px-3 d-none d-md-flex" style={{ margin: 0 }} btnStyle={{ height: '32px' }} />
              )}
              <Box className="w-100 pl-3" sx={{ maxWidth: 379 }}>
                <InputTextSearch
                  keyInput={'search'}
                  label={t('INPUT.SEARCH')}
                  value={search}
                  onchange={(event) => {
                    setSearch(event.target.value)
                    setPage(1)
                  }}
                  onClear={(event) => {
                    setSearch('')
                    setPage(1)
                  }}
                />
              </Box>
            </Box>
          </Box>
          <TypeTabs className="d-flex flex-wrap custom-scroll">
            <Box className="px-1 pb-2">
              <ButtonType variant="outlined" title={t('ALL')} className={`${typeSelected === 'All' ? 'active' : ''} overflow-hidden`} data-value={'All'} onClick={handleChangeType} key={`btn-operative-typ-all`}>
                <span className="text-ellipsis">{t('ALL')}</span>
              </ButtonType>
            </Box>
            <Box className="px-1 pb-2">
              <ButtonType variant="outlined" title={t('BOOKMARK')} className={`${typeSelected === 'BOOKMARK' ? 'active' : ''}`} data-value={'BOOKMARK'} onClick={handleChangeType} key={`btn-operative-typ-bookmark`}>
                {isBookmark ? <Icons.StarActive fill={colors.white} /> : <Icons.StarInActive fill={colors.textPrimary} />}
              </ButtonType>
            </Box>
            {operativeTypes.map((val: any, i) => {
              return (
                <Box key={`col-operative-typ-${val.operativeTypeId}`} className="px-1 pb-2">
                  <ButtonType
                    variant="outlined"
                    key={val.operativeTypeId}
                    title={val.operativeTypeName || val.operativeTypeNameEn}
                    data-value={val.operativeTypeId}
                    onClick={handleChangeType}
                    className={`${typeSelected === val.operativeTypeId.toString() ? 'active' : ''} text-ellipsis`}
                  >
                    <span className="text-ellipsis">{val.operativeTypeName || val.operativeTypeNameEn}</span>
                  </ButtonType>
                </Box>
              )
            })}
          </TypeTabs>
          <ResponsiveTxTable className="mt-2">
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              setPageLimit={handleChangeRowsPerPage}
              setPage={handleChangePage}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={operatives.map((val, i) => {
                return renderData(val, i)
              })}
            />
          </ResponsiveTxTable>
        </FragmentTxTable>
      </TxStyled>

      {isShowPopupEmrOverview && (
        <PopupEmrOverview
          patientId={props.patientId}
          onClose={() => {
            setIsShowPopupEmrOverview(false)
            dispatch(resetModal())
          }}
        />
      )}

      {isShowPopupDx.show && (
        <PopupAddDx
          type="tx"
          tx={isShowPopupDx.tx}
          dx={{}}
          data={{ dx: isShowPopupDx.data.dx, recommend: isShowPopupDx.data.recommend }}
          isShow={isShowPopupDx.show}
          setIsShow={(show: boolean) => setIsShowPopupDx({ show, tx: {}, data: { dx: [], recommend: [] } })}
        />
      )}

      <Loading show={selectOperativeLoading} type={'fullLoading'} />
    </div>
  )
}
