
const BtnMinus = (props: any) => {
    return (
        <svg data-name="Component 60 – 40" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path data-name="Exclusion 5" d="M21 24H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3v18a3 3 0 0 1-3 3zM4.364 10.909a1.091 1.091 0 1 0 0 2.183h15.273a1.091 1.091 0 0 0 0-2.183z" style={{ fill: props?.fill || '#fb5252' }} />
        </svg>

    )
}

export default BtnMinus