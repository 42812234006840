import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import { colors } from 'constants/theme'

const styles = {
  button: {
    '&.btn-submit': {
      backgroundColor: `${colors.themeSecondColor} !important`,
      color: `${colors.white} !important`,
      width: 'auto',
      marginTop: '15px',
      '&:hover': {
        backgroundColor: `${colors.themeSecondColor} !important`,
        color: `${colors.white} !important`
      }
    }
  }
}

function ButtonSubmit(props: any) {
  const { classes } = props
  return (
    <div style={{ margin: 'auto', ...props.style }}>
      <Button onClick={props.onClick} type={props.type} className={`${props.className} ${classes.button} btn-submit `} disabled={props.disabled} variant="contained">
        {props.textButton}
      </Button>
    </div>
  )
}

ButtonSubmit.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  textButton: PropTypes.string,
  style: PropTypes.any,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'reset', 'submit'])
}

export default withStyles(styles)(ButtonSubmit)
