import { colors } from 'constants/theme'
import { styled } from '@mui/material/styles'
import { Modal } from 'react-bootstrap'
import Button from '@mui/material/Button'

export const ModalStyle = styled(Modal)(({ theme }) => ({
    overflow: 'hidden',
    zIndex: theme.zIndex.modal,
    paddingLeft: '0 !important',
    '.modal-dialog': {
        maxWidth: 1600,
        overflow: 'hidden',
        marginLeft: 'auto',
        marginRight: 'auto',
        // [theme.breakpoints.up('xs')]: {
        //     height: 'calc(100% - 3.5rem)'
        // },
        [theme.breakpoints.between('xs', 'sm')]: {
            margin: '1.75rem'
        },
        [theme.breakpoints.between('sm', 'lg')]: {
            maxWidth: 712
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: 'calc(100% - 2rem)',
            height: 'calc(100% - 2rem)',
            margin: '1rem',
        }
    },
    '.modal-content': {
        height: '100%',
        maxHeight: '100%',
        padding: '1rem 1.5rem 1.5rem',
        borderRadius: 10,
        border: 'none !important',
        backdropFilter: 'saturate(1) blur(40px)',
        backgroundColor: colors.white90
    }
}))


export const Header = styled(Modal.Header)(({ theme }) => ({
    border: 'none !important',
    padding: 0,
    textAlign: 'center',
    margin: 'auto',
    p: {
        fontSize: 20,
        fontWeight: 600,
        color: colors.themeMainColor
    }
}))

export const Body = styled(Modal.Body)(({ theme }) => ({
    padding: '1rem 0 1.5rem',
    color: colors.textPrimary,
    overflowY: 'auto',
    overflowX: 'hidden'
}))

export const Footer = styled(Modal.Footer)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
        flexWrap: 'nowrap'
    }
}))

export const BtnCancel = styled(Button)(({ theme }) => ({
    width: 'max-content',
    height: 44,
    fontSize: 16,
    color: `${colors.themeSecondColor} !important `,
    border: `solid 1px ${colors.themeSecondColor}  !important`,
    padding: '0 16px',
    '&.btn-default': {
        color: `${colors.textPrimary} !important `,
        border: `solid 1px ${colors.textExtraLightGray}  !important`
    },
    [theme.breakpoints.down('sm')]: {
        width: '50%',
        marginRight: '0.25rem'
    },
    [theme.breakpoints.up('sm')]: {
        marginRight: '.5rem'
    }
}))

export const BtnConfirm = styled(Button)(({ theme }) => ({
    width: 'max-content',
    height: 44,
    fontSize: 16,
    backgroundColor: `${colors.themeSecondColor} !important`,
    color: `${colors.white} !important`,
    padding: '0 16px',
    boxShadow: 'none !important',
    '&:hover': {
        backgroundColor: `${colors.themeSecondColor} !important`,
        color: `${colors.white} !important`
    },
    '&:disabled': {
        backgroundColor: `${colors.disabledGray} !important`
    },
    [theme.breakpoints.down('sm')]: {
        width: '50%',
        marginLeft: '0.25rem'
    },
    [theme.breakpoints.up('sm')]: {
        marginLeft: '0.5rem'
    }
}))
