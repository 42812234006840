import { Col, Modal, Row } from 'react-bootstrap'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import 'styles/Switch.css'

/** SLICE */
import { resetModal } from 'app/slice/modal.slice'

/** CONSTANTS */
import { colors } from 'constants/theme'
import closePopup from 'assets/icon/appointment/close.svg'
import FunctionPrint from 'component/Print/print'
import { Box } from '@mui/material'

const ModalStyle = styled(Modal)(({ theme }) => ({
  zIndex: theme.zIndex.modal,
  '& .modal-content': {
    padding: '1rem 1.5rem 1.5rem',
    borderRadius: 10,
    backdropFilter: 'saturate(1) blur(40px)',
    backgroundColor: colors.white90,
    border: 'none !important',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem'
    }
  },
  '& .modal-title': {
    width: '100%',
    h3: {
      textAlign: 'center',
      fontSize: 20,
      fontWeight: 600,
      color: colors.themeMainColor,
      '&.header-danger': {
        // color: colors.white
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 18
      }
    }
  },
  '& .css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled': {
    opacity: 0.5
  },
  '& .modal-footer': {
    display: 'flex',
    flexDirection: 'row',
    '&:not(.footer-inline)': {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    }
  },
  '.btn-close': {
    position: 'absolute',
    top: '16px',
    right: '24px',
    boxShadow: 'none !important',
    background: `url(${closePopup}) center/1em auto no-repeat`,
    opacity: 0.6,
    '&:hover': {
      opacity: 1
    },
    [theme.breakpoints.down('xs')]: {
      right: '16px',
    }
  }
}))

export const BtnConfirm = styled(Button)(({ theme }) => ({
  width: 'max-content !important',
  height: 44,
  fontSize: 16,
  backgroundColor: `${colors.themeSecondColor} !important`,
  color: `${colors.white} !important`,
  padding: '0 16px !important',
  boxShadow: 'none !important',
  borderRadius: `4px !important`,
  '&:hover': {
    backgroundColor: `${colors.themeSecondColor} !important`,
    color: `${colors.white} !important`
  },
  '&.btn-danger': {
    background: `${colors.themeThirdColor} !important`
  },
  '&.Mui-disabled': {
    border: `1px solid ${colors.disabledLightGray} !important`,
    backgroundColor: `${colors.disabledLightGray} !important`,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100% !important'
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: '.5rem !important'
  }
}))

export const BtnDelete = styled(Button)(({ theme }) => ({
  width: 'max-content',
  height: 44,
  fontSize: 16,
  backgroundColor: `${colors.themeThirdColor} !important`,
  color: `${colors.white} !important`,
  padding: '0 16px !important',
  boxShadow: 'none !important',
  borderRadius: `4px !important`,
  '&:hover': {
    backgroundColor: `${colors.themeThirdColor} !important`,
    color: `${colors.white} !important`
  },
  '&.btn-danger': {
    background: `${colors.themeThirdColor} !important`
  },
  '&.Mui-disabled': {
    border: `1px solid ${colors.disabledLightGray} !important`,
    backgroundColor: `${colors.disabledLightGray} !important`,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  [theme.breakpoints.up('sm')]: {

  }
}))

export const BtnCancel = styled(Button)(({ theme }) => ({
  width: 'max-content',
  height: 44,
  fontSize: 16,
  color: `${colors.themeSecondColor} !important `,
  border: `solid 1px ${colors.themeSecondColor} !important`,
  padding: '0 16px !important',
  borderRadius: `4px !important`,
  '&.btn-default': {
    color: `${colors.textPrimary} !important `,
    border: `solid 1px ${colors.textExtraLightGray} !important`
  },
  '&.btn-danger': {
    // background: `${colors.themeThirdColor} !important`,
    color: `${colors.themeThirdColor} !important `,
    border: `solid 1px ${colors.themeThirdColor} !important`
  },
  '&.Mui-disabled': {
    color: `${colors.disabledLightGray} !important`,
    border: `1px solid ${colors.disabledLightGray} !important`,
    // backgroundColor: `${colors.disabledLightGray} !important`,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  [theme.breakpoints.up('sm')]: {
    marginRight: '.5rem !important'
  }
}))

type SubmitPrintProps = {
  content: HTMLDivElement | null
  docTitle?: string
  removeIfram?: boolean
  style?: string
}

type ModalCustomProps = {
  onSubmit?: () => void
  onReset?: () => void
  onDelete?: (id: number, obj?: any) => void
  onClose?: () => void
  closeButton?: boolean
  modalTheme?: 'default' | 'danger'
  title: string
  textBtnConfirm?: string
  textBtnCancel?: string
  btnCancelTheme?: 'default' | 'danger'
  btnConfirmTheme?: 'default' | 'danger'
  id?: number
  obj?: any
  textBtnDelete?: string
  component?: any
  modalStyle?: any
  size?: 'lg' | 'sm' | 'xl'
  alignFooter?: 'start' | 'center' | 'end'
  fullscreen?: true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'
  modalScroll?: boolean
  footerInline?: boolean
  autoResetModal?: boolean
  disabledSubmit?: boolean
  disabledCancel?: boolean
  changeStyle?: any
  submitPrint?: boolean
  printProps?: SubmitPrintProps
}
export default function ModalCustom(props: ModalCustomProps) {
  const isShow = useSelector((state: any) => state.modal.isShow)
  const isSubmit = useSelector((state: any) => state.modal.isSubmit)
  const dispatch = useDispatch()

  const getClassOfModalTheme = () => {
    if (props.modalTheme === 'danger') return 'btn-default'
    else if (props.modalTheme && props.modalTheme !== 'default') return `btn-${props.modalTheme}`
    else return ''
  }

  return (
    props.changeStyle ?
      <props.changeStyle className={`${props.modalStyle || ''}`} fullscreen={props?.fullscreen || ''} returnFocusAfterClose={false} size={props.size || 'sm'} show={isShow} aria-labelledby="contained-modal-title-vcenter" centered >
        <Modal.Header
          className="border-0 text-center mx-auto my-0 p-0 w-100 mb-3"
          closeButton={props.closeButton || false}
          onHide={() => {
            dispatch(resetModal())
            props.onClose && props.onClose()
          }}>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3 className={`m-0 ${props.modalTheme ? `header-${props.modalTheme}` : ''}`}>{props.title}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`px-0 p-0 ${props.modalScroll ? 'custom-scroll overflow-auto' : ''}`}>{props.component}</Modal.Body>
        <Modal.Footer className={`flex-center ${props.alignFooter ? `justify-content-${props.alignFooter}` : 'justify-content-center'} ${props.footerInline ? 'footer-inline' : ''} border-0 p-0 mt-3`}>
          <Row className={`w-100 m-0 ${props.alignFooter ? `justify-content-${props.alignFooter}` : 'justify-content-center'}`}>
            {(props?.onReset || props?.textBtnCancel) && (
              <Col xs={(props.textBtnDelete && props.id) || props.footerInline ? 6 : 12} md={'auto'} className={`mt-0 px-1 px-md-0 ${props.alignFooter === 'end' ? 'ml-auto' : ''}`}>
                <BtnCancel
                  fullWidth
                  className={`${getClassOfModalTheme()} ${props.btnCancelTheme ? `btn-${props.btnCancelTheme}` : ''}`}
                  onClick={() => {
                    props.onReset && props.onReset()
                    if (props?.autoResetModal !== false) dispatch(resetModal())
                  }}>
                  {props.textBtnCancel}
                </BtnCancel>
              </Col>
            )}
            {(props.textBtnDelete && props.id) && (
              <Col xs={6} md={'auto'} className="mt-0 mt-md-0 px-1 px-md-0">
                <BtnDelete
                  fullWidth
                  className={`${getClassOfModalTheme()} ${props.btnCancelTheme ? `btn-${props.btnCancelTheme}` : ''}`}
                  onClick={() => {
                    if (props.obj) {
                      if (props?.onDelete && props?.id && props?.obj) props.onDelete(props?.id, props?.obj)
                    } else if (props?.onDelete && props?.id) props.onDelete(props?.id)
                    if (props?.autoResetModal !== false) dispatch(resetModal())
                  }}>
                  {props.textBtnDelete}
                </BtnDelete>
              </Col>
            )}
            {(props?.onSubmit && props?.textBtnConfirm) && (
              <Col xs={props.footerInline ? 6 : 12} md={'auto'} className={`${!props.footerInline ? 'mt-2' : ''} mt-md-0 px-1 px-md-0`}>
                <BtnConfirm
                  fullWidth
                  className={`btn-submit ${props.modalTheme ? `btn-${props.modalTheme}` : ''} ${props.btnConfirmTheme ? `btn-${props.btnConfirmTheme}` : ''}`}
                  disabled={isSubmit || (props?.disabledSubmit || false)}
                  onClick={() => props.onSubmit && props.onSubmit()}>
                  {props.textBtnConfirm}
                </BtnConfirm>
              </Col>
            )}
          </Row>
        </Modal.Footer>
      </props.changeStyle>
      :
      <ModalStyle className={`${props.modalStyle || ''}`} fullscreen={props?.fullscreen || ''} returnFocusAfterClose={false} size={props.size || 'sm'} show={isShow} aria-labelledby="contained-modal-title-vcenter" centered >
        <Modal.Header
          className="border-0 text-center mx-auto my-0 p-0 w-100 mb-3"
          closeButton={props.closeButton || false}
          onHide={() => {
            dispatch(resetModal())
            props.onClose && props.onClose()
          }}>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3 className={`m-0 ${props.modalTheme ? `header-${props.modalTheme}` : ''}`}>{props.title}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`px-0 p-0 ${props.modalScroll ? 'custom-scroll overflow-auto' : ''}`}>{props.component}</Modal.Body>
        <Modal.Footer className={`flex-center ${props.alignFooter ? `justify-content-${props.alignFooter}` : 'justify-content-center'} ${props.footerInline ? 'footer-inline' : ''} border-0 p-0 mt-3`}>
          <Row className={`w-100 m-0 ${props.alignFooter ? `justify-content-${props.alignFooter}` : 'justify-content-center'}`}>
            {(props?.onReset || props?.textBtnCancel) && (
              <Col xs={(props.textBtnDelete && props.id) || props.footerInline ? 6 : 12} md={'auto'} className={`mt-0 px-1 px-md-0 ${props.alignFooter === 'end' ? 'ml-auto' : ''}`}>
                <BtnCancel
                  fullWidth
                  className={`${getClassOfModalTheme()} ${props.btnCancelTheme ? `btn-${props.btnCancelTheme}` : ''}`}
                  disabled={(props?.disabledCancel || false)}
                  onClick={() => {
                    props.onReset && props.onReset()
                    if (props?.autoResetModal !== false) dispatch(resetModal())
                  }}>
                  {props.textBtnCancel}
                </BtnCancel>
              </Col>
            )}
            {(props.textBtnDelete && props.id) && (
              <Col xs={6} md={'auto'} className="mt-0 mt-md-0 px-1 px-md-0">
                <BtnDelete
                  fullWidth
                  className={`${getClassOfModalTheme()} ${props.btnCancelTheme ? `btn-${props.btnCancelTheme}` : ''}`}
                  onClick={() => {
                    if (props.obj) {
                      if (props?.onDelete && props?.id && props?.obj) props.onDelete(props?.id, props?.obj)
                    } else if (props?.onDelete && props?.id) props.onDelete(props?.id)
                    if (props?.autoResetModal !== false) dispatch(resetModal())
                  }}>
                  {props.textBtnDelete}
                </BtnDelete>
              </Col>
            )}
            {(props?.onSubmit && props?.textBtnConfirm) && (
              <Col xs={props.footerInline ? 6 : 12} md={'auto'} className={`${!props.footerInline ? 'mt-2' : ''} mt-md-0 px-1 px-md-0`}>
                {!props?.submitPrint &&
                  <BtnConfirm
                    fullWidth
                    className={`btn-submit ${props.modalTheme ? `btn-${props.modalTheme}` : ''} ${props.btnConfirmTheme ? `btn-${props.btnConfirmTheme}` : ''}`}
                    disabled={isSubmit || (props?.disabledSubmit || false)}
                    onClick={() => props.onSubmit && props.onSubmit()}>
                    {props.textBtnConfirm}
                  </BtnConfirm> ||
                  <Box onClick={() => props.onSubmit && props.onSubmit()}>
                    <FunctionPrint
                      content={() => props?.printProps?.content}
                      docTitle={props?.printProps?.docTitle}
                      removeIfram={props?.printProps?.removeIfram}
                      trigger={() => <BtnConfirm
                        fullWidth
                        className={`btn-submit ${props.modalTheme ? `btn-${props.modalTheme}` : ''} ${props.btnConfirmTheme ? `btn-${props.btnConfirmTheme}` : ''}`}
                        disabled={isSubmit || (props?.disabledSubmit || false)}
                      >
                        {props.textBtnConfirm}
                      </BtnConfirm>}
                      style={props?.printProps?.style || '@page { size: 302.36220472px 188.97637795px;  margin: 0cm;  } @media print { body { -webkit-print-color-adjust: exact; } '}
                    />
                  </Box>
                }
              </Col>
            )}
          </Row>
        </Modal.Footer>
      </ModalStyle>
  )
}
