import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart, TreatmentStateInterface } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/24.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethU, TextNumberU } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
  height: 'auto',
  marginTop: 'auto',
  top: '1.4%',
  left: '45.2%'
}))

export default function Teeth24(props: TeethProps) {
  const teeth = useSelector(toothChart)
  const teethNumber = props.teethNumber
  const historyToothChart = props.historyToothChart
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const isEditTootCharts: any = useSelector(isEditTootChart)

  const dispatch = useDispatch()

  const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
  const [positions, setPositions]: any = useState([])
  const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

  const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
  const [isTooth, setIsTooth]: any = useState(false)
  const [isXRay, setIsXRay]: any = useState(false)
  const [isToothRoot, setIsToothRoot]: any = useState(false)
  const [isOrthodontics, setIsOrthodontics]: any = useState(false)
  const [isExtract, setIsExtract]: any = useState(false)
  const [isFixture, setIsFixture]: any = useState(false)
  const [isBridge, setIsBridge]: any = useState(false)

  const [color, setColor]: any = useState(``)
  const [styleSvg, setStyleSvg]: any = useState({ width: 38.262, height: 93.001, viewBox: "0 0 38.262 93.001" })

  const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

  useEffect(() => {
    const operative = props.operative || ``
    const valColor = props.color || colors.themeSecondColor
    const selected = teeth[teethNumber]?.isSelect || false
    dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
    setIsSelected(selected)

    renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

    if (props.type === 'treatment') {
      renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
    }

    if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

    return setStyleSvg({ width: 31.03, height: 99.002, viewBox: "0 0 31.03 99.002" })
  }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

  useEffect(() => {
    const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
    const teethJson = treatmentTooth?.teethJson

    if (isEditTootCharts !== null) {
      if (teethJson) {
        const tooths = JSON.parse(teethJson)
        setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
        if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
      } else setIsEditTeeth(false)
    }
  }, [isEditTootCharts]);

  useEffect(() => {
    if (isSelected) {
      dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
      if (teeth[props.teethNumber]?.isSelect) {
        setIsSelected(true)
      } else {
        setIsSelected(false)
      }
    }
  }, [props.historyToothChart]);

  useEffect(() => {
    if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
  }, [isSelected, isEditTootCharts]);

  return (
    <Div>
      <DivTeethU className={`div-teeth-${props.teethNumber} `}>
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray />) || ''}
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge />) || ''}
        {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`sm teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
          <svg className={`sm ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
            <g data-name="Group 9159">
              {(isBridge && props.type === 'treatment') ?
                <g></g>
                : ""}
              <path
                data-name="Path 5263"
                d="M298.01 122.229v.653a2.107 2.107 0 0 0 .208.93 3.3 3.3 0 0 0 1.255 1.435l.013.013h.006c.006.013.019.019.026.032 2.862 3.848 6.026 5.936 9.951 6.374a.049.049 0 0 1 .026.013 4.714 4.714 0 0 0 2.559.638 15.217 15.217 0 0 0 7.554-2.359l.464-.174a.109.109 0 0 0 .045.006c.006-.013.019-.019.026-.032 3.3-.987 5.4-3.118 6.622-6.693a2.2 2.2 0 0 0 .113-.705v-6.13a2.123 2.123 0 0 0-.138-.748c-4.806-12.821-2.476-26.622-5.637-39.469-3.038-12.366.5-25.535-3.3-37.975a2.165 2.165 0 0 0-3.449-1.069c-1.452 1.256-1.715 2.789-2.064 4.234-4.9 20.044-6.961 40.572-10.254 60.893-.735 4.513-2.347 8.738-3.9 13.272a2.2 2.2 0 0 0-.119.71v.233a2.427 2.427 0 0 1-.172.848 6.832 6.832 0 0 0 .072 4.43 2.207 2.207 0 0 1 .093.64z"
                transform="translate(-297.269 -36.469)"
                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
              />
              {(isLowerTeeth && props.type === 'treatment') ?
                <path
                  data-name="Path 6521"
                  d="M300.91 109.537a34.758 34.758 0 0 0 2.063-4.1 14.045 14.045 0 0 1 2.452-5.287c1.7-1.984 2.547-2.469 5.2-4.109a6.279 6.279 0 0 1 4.063-.812c2.245.351 3.406 1.406 4.813 2.484a11.74 11.74 0 0 1 1.906 2.781 33.984 33.984 0 0 1 1.438 6.656 12.459 12.459 0 0 0 2.172 5.344c.485 1.038 1.031 1.969 1.438 2.828l.422 1.031v-.484c-4.944-12.664-2.615-27.006-5.776-39.852-3.038-12.366.5-25.535-3.3-37.975a2.165 2.165 0 0 0-3.449-1.069c-1.452 1.256-1.715 2.789-2.064 4.234-4.9 20.044-6.961 40.572-10.254 60.893-.735 4.513-2.347 8.738-3.9 13.272a58.328 58.328 0 0 1 2.776-5.835z"
                  transform="translate(-297.269 -36.469)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                /> : ''}
              <g data-name="Group 9725">

                {(isFixture && props.type === 'treatment') ? <>
                  <path data-name="Path 5264" d="M296.216 157.633c1.562 5.359 12.172 10.211 15.468 10.211 3.739 0 12.8-5.54 13.29-6.584 2.827-5.962.874-11.765-2.784-17.864-2.58-4.3-.6-10.877-5.83-14.192a6.934 6.934 0 0 1-.4-.275 6.586 6.586 0 0 0-7.208-.579c-3.315 1.878-6.456 4.223-7.474 8.475a12.041 12.041 0 0 1-1.248 3.067c-5.173 9.23-5.54 11.819-3.814 17.741z" transform="translate(-295.288 -88.616)" style={{ fill: isTooth ? treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || color : '#efede7' }} />
                  <path data-name="Path 5265" d="M297.693 158.619c1.462 5.018 11.395 9.56 14.482 9.56 3.5 0 11.979-5.187 12.442-6.165 2.647-5.581.819-11.014-2.606-16.724-2.415-4.027-.558-10.183-5.458-13.287q-.19-.121-.372-.257a6.167 6.167 0 0 0-6.749-.543c-3.1 1.758-6.044 3.954-7 7.935a11.286 11.286 0 0 1-1.169 2.871c-4.842 8.642-5.186 11.066-3.57 16.61z" transform="translate(-295.834 -89.649)" style={{ fill: isTooth ? treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || color : '#f7f7f7' }} />
                  <path data-name="Path 5266" d="M300.354 159.665c1.333 4.575 10.389 8.716 13.2 8.716 3.192 0 10.922-4.729 11.345-5.621 2.413-5.088.746-10.042-2.376-15.249-2.2-3.672-.509-9.284-4.977-12.114a5.146 5.146 0 0 1-.34-.235 5.624 5.624 0 0 0-6.153-.494c-2.83 1.6-5.511 3.6-6.38 7.234a10.29 10.29 0 0 1-1.065 2.619c-4.414 7.879-4.727 10.09-3.254 15.144z" transform="translate(-297.774 -90.905)" style={{ fill: isTooth ? treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || color : colors.white }} />
                </> : <>
                  <path
                    data-name="Path 5264"
                    d="M296.216 157.633c1.562 5.359 12.172 10.211 15.468 10.211 3.739 0 12.8-5.54 13.29-6.584 2.827-5.962.874-11.765-2.784-17.864-2.58-4.3-.6-10.877-5.83-14.192a6.934 6.934 0 0 1-.4-.275 6.586 6.586 0 0 0-7.208-.579c-3.315 1.878-6.456 4.223-7.474 8.475a12.041 12.041 0 0 1-1.248 3.067c-5.173 9.23-5.54 11.819-3.814 17.741z"
                    transform="translate(-295.288 -68.842)"
                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                  />
                  <path
                    data-name="Path 5265"
                    d="M297.693 158.619c1.462 5.018 11.395 9.56 14.482 9.56 3.5 0 11.979-5.187 12.442-6.165 2.647-5.581.819-11.014-2.606-16.724-2.415-4.027-.558-10.183-5.458-13.287q-.19-.121-.372-.257a6.167 6.167 0 0 0-6.749-.543c-3.1 1.758-6.044 3.954-7 7.935a11.286 11.286 0 0 1-1.169 2.871c-4.842 8.642-5.186 11.066-3.57 16.61z"
                    transform="translate(-295.834 -69.875)"
                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                  />
                  <path
                    data-name="Path 5266"
                    d="M300.354 159.665c1.333 4.575 10.389 8.716 13.2 8.716 3.192 0 10.922-4.729 11.345-5.621 2.413-5.088.746-10.042-2.376-15.249-2.2-3.672-.509-9.284-4.977-12.114a5.146 5.146 0 0 1-.34-.235 5.624 5.624 0 0 0-6.153-.494c-2.83 1.6-5.511 3.6-6.38 7.234a10.29 10.29 0 0 1-1.065 2.619c-4.414 7.879-4.727 10.09-3.254 15.144z"
                    transform="translate(-297.774 -71.131)"
                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                  />
                </>}

                {(isToothRoot && props.type === 'treatment') ?
                  <path
                    data-name="Path 6555"
                    d="M7.013 53.319C6.536 46.26 5.778 20.26 7.06 13.27 8.433 5.789 8.691-1.852 8.434-2.625 8.094-3.818 8.585.956 7.06 13.75 5.126 29.988 5.395 53.255 5.4 53.319a6.691 6.691 0 0 1 1.613 0z"
                    transform="translate(10.161 4.955)"
                    style={{

                      fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                      stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                    }}
                  />
                  : ''}

                {(isExtract && props.type === 'treatment') ?
                  <>
                    <g data-name="Group 9460">
                      <path data-name="Path 6581" d="m33.788-.5-27.542 80" transform="translate(-4.245 19.274)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                      <path data-name="Path 6582" d="m6.246-.5 27.542 80" transform="translate(-4.245 19.274)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                    </g>
                  </>
                  : ''}

                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                  <>
                    <defs>
                      <linearGradient id="bc6phfszya" x1=".5" y1=".453" x2="-1.326" y2="4.622" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#b0b1b1" />
                        <stop offset={1} stopColor="#585959" />
                      </linearGradient>
                      <linearGradient id="5k1f2z25ob" x1=".5" y1="1.834" x2=".5" y2=".046" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#c1c4c5" />
                        <stop offset={1} stopColor="#9e9e9e" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9346">
                      <path data-name="Path 5263" d="M298.01 122.229v.653a2.107 2.107 0 0 0 .208.93 3.3 3.3 0 0 0 1.255 1.435l.013.013h.006c.006.013.019.019.026.032 2.862 3.848 6.026 5.936 9.951 6.374a.049.049 0 0 1 .026.013 4.714 4.714 0 0 0 2.559.638 15.217 15.217 0 0 0 7.554-2.359l.464-.174a.109.109 0 0 0 .045.006c.006-.013.019-.019.026-.032 3.3-.987 5.4-3.118 6.622-6.693a2.2 2.2 0 0 0 .113-.705v-6.13a2.123 2.123 0 0 0-.138-.748c-4.806-12.821-2.476-26.622-5.637-39.469-3.038-12.366.5-25.535-3.3-37.975a2.165 2.165 0 0 0-3.449-1.069c-1.452 1.256-1.715 2.789-2.064 4.234-4.9 20.044-6.961 40.572-10.254 60.893-.735 4.513-2.347 8.738-3.9 13.272a2.2 2.2 0 0 0-.119.71v.233a2.427 2.427 0 0 1-.172.848 6.832 6.832 0 0 0 .072 4.43 2.207 2.207 0 0 1 .093.64z" transform="translate(-297.102 -36.469)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                      <path data-name="Path 5264" d="M296.216 157.633c1.562 5.359 12.172 10.211 15.468 10.211 3.739 0 12.8-5.54 13.29-6.584 2.827-5.962.874-11.765-2.784-17.864-2.58-4.3-.6-10.877-5.83-14.192a6.934 6.934 0 0 1-.4-.275 6.586 6.586 0 0 0-7.208-.579c-3.315 1.878-6.456 4.223-7.474 8.475a12.041 12.041 0 0 1-1.248 3.067c-5.173 9.23-5.54 11.819-3.814 17.741z" transform="translate(-295.121 -68.842)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5265" d="M297.693 158.619c1.462 5.018 11.395 9.56 14.482 9.56 3.5 0 11.979-5.187 12.442-6.165 2.647-5.581.819-11.014-2.606-16.724-2.415-4.027-.558-10.183-5.458-13.287q-.19-.121-.372-.257a6.167 6.167 0 0 0-6.749-.543c-3.1 1.758-6.044 3.954-7 7.935a11.286 11.286 0 0 1-1.169 2.871c-4.842 8.642-5.186 11.066-3.57 16.61z" transform="translate(-295.667 -69.875)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5266" d="M300.354 159.665c1.333 4.575 10.389 8.716 13.2 8.716 3.192 0 10.922-4.729 11.345-5.621 2.413-5.088.746-10.042-2.376-15.249-2.2-3.672-.509-9.284-4.977-12.114a5.146 5.146 0 0 1-.34-.235 5.624 5.624 0 0 0-6.153-.494c-2.83 1.6-5.511 3.6-6.38 7.234a10.29 10.29 0 0 1-1.065 2.619c-4.414 7.879-4.727 10.09-3.254 15.144z" transform="translate(-297.607 -71.131)" style={{ fill: colors.white }} />
                      <g data-name="Group 9388">
                        <g data-name="Group 9380" transform="translate(-3.833 76.366)">
                          <g data-name="Rectangle 3927" transform="translate(13.517)" style={{ fill: '#c1c4c5', stroke: '#c1c4c5' }}>
                            <rect width="11.601" height="11.601" rx={2} style={{ stroke: 'none' }} />
                            <rect x=".5" y=".5" width="10.601" height="10.601" rx="1.5" style={{ fill: 'none' }} />
                          </g>
                          <path data-name="Path 6487" d="M11.579 17.547c-.2.362 1.676.183 3.083.165 2.094-.044 3.289.384 3.269-4.45a41.118 41.118 0 0 0-.159-5.141c-.531-.516.14 5.236-1.219 7.766-1.282 1.827-4.631 1.272-4.974 1.66z" transform="translate(6.863 -6.464)" style={{ fill: '#b0b1b1' }} />
                          <rect data-name="Rectangle 3931" width={5} height={2} rx=".5" transform="translate(16.806 6.215)" style={{ fill: '#cbcccc' }} />
                          <rect data-name="Rectangle 3930" width={5} height={2} rx=".5" transform="translate(16.605 2.015)" style={{ fill: '#9e9e9e' }} />
                          <rect data-name="Rectangle 3928" width={5} height={2} rx=".5" transform="translate(16.806 2.015)" style={{ fill: 'url(#bc6phfszya)' }} />
                          <rect data-name="Rectangle 3929" width={5} height={2} rx=".5" transform="translate(16.806 6.015)" style={{ fill: 'url(#5k1f2z25ob)' }} />
                        </g>
                        <path data-name="Path 6488" d="M3.457 10.83S16.63 5.6 33.76 10.237" transform="translate(-2.748 72.786)" style={{ strokeLinecap: 'round', stroke: '#babbbb', fill: 'none' }} />
                        <path data-name="Path 6486" d="M3.563 10.783s15.845-5.117 30.509-.541" transform="translate(-2.934 73.467)" style={{ stroke: '#b5b7b7', strokeLinecap: 'round', fill: 'none' }} />
                      </g>
                    </g>
                  </> || ""}

                {(isFixture && props.type === 'treatment') ?
                  <>
                    <defs>
                      <linearGradient id="mvd5if2bta" x1=".209" y1="-.059" x2="1.176" y2="1.286" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b4b5b5" />
                      </linearGradient>
                      <linearGradient id="mpisg2c3lb" x1="-.342" y1="-.081" x2="1.352" y2={1} gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b0b1b1" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9487">
                      <path data-name="Path 5264" d="M296.216 157.633c1.562 5.359 12.172 10.211 15.468 10.211 3.739 0 12.8-5.54 13.29-6.584 2.827-5.962.874-11.765-2.784-17.864-2.58-4.3-.6-10.877-5.83-14.192a6.934 6.934 0 0 1-.4-.275 6.586 6.586 0 0 0-7.208-.579c-3.315 1.878-6.456 4.223-7.474 8.475a12.041 12.041 0 0 1-1.248 3.067c-5.173 9.23-5.54 11.819-3.814 17.741z" transform="translate(-295.288 -88.616)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5265" d="M297.693 158.619c1.462 5.018 11.395 9.56 14.482 9.56 3.5 0 11.979-5.187 12.442-6.165 2.647-5.581.819-11.014-2.606-16.724-2.415-4.027-.558-10.183-5.458-13.287q-.19-.121-.372-.257a6.167 6.167 0 0 0-6.749-.543c-3.1 1.758-6.044 3.954-7 7.935a11.286 11.286 0 0 1-1.169 2.871c-4.842 8.642-5.186 11.066-3.57 16.61z" transform="translate(-295.834 -89.649)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5266" d="M300.354 159.665c1.333 4.575 10.389 8.716 13.2 8.716 3.192 0 10.922-4.729 11.345-5.621 2.413-5.088.746-10.042-2.376-15.249-2.2-3.672-.509-9.284-4.977-12.114a5.146 5.146 0 0 1-.34-.235 5.624 5.624 0 0 0-6.153-.494c-2.83 1.6-5.511 3.6-6.38 7.234a10.29 10.29 0 0 1-1.065 2.619c-4.414 7.879-4.727 10.09-3.254 15.144z" transform="translate(-297.774 -90.905)" style={{ fill: colors.white }} />
                      <g data-name="Group 9549">
                        <g data-name="Path 6490" transform="translate(5.284)" style={{ fill: 'url(#mvd5if2bta)' }}>
                          <path d="M17.343 44.996H1.734c-.422 0-.825-.367-1.135-1.033-.322-.69-.499-1.61-.499-2.59C.845 24.493 1.818 12.58 2.991 5.97 3.682 2.075 4.427.1 5.203.1h8.671c.852 0 5.103 1.99 5.103 41.273 0 .98-.177 1.9-.499 2.59-.31.666-.713 1.033-1.135 1.033z" style={{ stroke: 'none' }} />
                          <path d="M17.343 44.896c.74 0 1.534-1.416 1.534-3.523 0-16.645-.753-28.547-2.238-35.377-.504-2.319-1.081-3.983-1.715-4.946-.486-.74-.867-.85-1.05-.85H5.203c-.406 0-1.22.752-2.114 5.788C1.917 12.593.945 24.498.2 41.378c.001 2.104.794 3.518 1.534 3.518h15.609m0 .2H1.734C.776 45.096 0 43.429 0 41.373 1.734 2.085 4.245 0 5.203 0h8.671c.958 0 5.203 2.085 5.203 41.373 0 2.056-.777 3.723-1.734 3.723z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <g data-name="Path 6489" transform="translate(3 42.63)" style={{ strokeLinejoin: 'round', fill: 'url(#mpisg2c3lb)' }}>
                          <path d="M23.198 7.569H1.16C.577 7.569.103 6.76.1 5.764.653 2.756.796 1.315.865.622.892.35.912.148.938.1h.007c.016 0 .037.002.062.004.04.004.09.01.153.01h22.038a4.489 4.489 0 0 0 .126-.002c.198 0 .21 0 .385 1.485.102.86.256 2.157.548 4.165-.002.997-.476 1.807-1.06 1.807z" style={{ stroke: 'none' }} />
                          <path d="M23.198 7.469c.517 0 .955-.777.96-1.7a142.268 142.268 0 0 1-.548-4.16C23.518.83 23.456.308 23.39.215a.404.404 0 0 0-.065-.003l-.126.001H1.16C1.1.213 1.052.21 1.013.206a7.67 7.67 0 0 0-.049.426C.895 1.326.752 2.768.2 5.772c.006.922.443 1.697.96 1.697h22.038m0 .2H1.16C.52 7.669 0 6.812 0 5.755 1.16-.555.52.013 1.16.013h22.038c.64 0 .287-.24 1.16 5.742 0 1.057-.52 1.914-1.16 1.914z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <path data-name="Path 6491" d="M5.625 21.2s18.057.9 18.962 1.957" transform="translate(-.227 15.597)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6492" d="M5.649 21.2S23.1 22.161 24 23.213" transform="translate(.06 10.345)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6493" d="M7.08 21.281s14.6.592 15.5 1.645" transform="translate(.508 -11.973)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6584" d="M5.649 21.2S23.1 22.161 24 23.213" transform="translate(.316 5.802)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6585" d="M6.115 21.2S23.1 22.185 24 23.237" transform="translate(.316 -.222)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6586" d="M6.643 21.3s16.036.854 16.941 1.907" transform="translate(.316 -6.281)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6587" d="M8.144 21.444s12.509.373 13.415 1.426" transform="translate(.508 -17.717)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      </g>
                    </g>
                  </> : ""}
              </g>
            </g>
          </svg>}
      </DivTeethU>
      <PositionTeeth quarter={QUARTER_NAME_TYPE.Q2} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
      <TextNumberU>24</TextNumberU>
    </Div>
  )
}
