export default function IconPencil(props: any) {
  return (
    <svg width="27" height="28" viewBox="-5 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="20.8398" y="4.19824" width="3.69263" height="25.4424" transform="rotate(45 20.8398 4.19824)" fill={props.color}/>
<path d="M18.5846 3.27803C19.1384 2.73232 19.8892 2.42578 20.672 2.42578C21.4549 2.42578 22.2057 2.73232 22.7595 3.27803L24.5921 5.08528C25.1455 5.63137 25.4563 6.3718 25.4563 7.14381C25.4563 7.91583 25.1455 8.65626 24.5921 9.20235L10.0627 23.5305C9.70835 23.88 9.2696 24.1363 8.78697 24.2711L3.30091 25.8187C3.08377 25.8799 2.85397 25.8827 2.63537 25.8268C2.41677 25.7709 2.21732 25.6583 2.05771 25.5007C1.89811 25.3432 1.78416 25.1464 1.7277 24.9307C1.67123 24.7151 1.67431 24.4885 1.7366 24.2744L3.30429 18.866C3.44097 18.39 3.70085 17.9557 4.05522 17.6046L18.5846 3.27636V3.27803ZM18.8816 11.3041L16.4533 8.9078L5.84566 19.3702C5.79492 19.4202 5.75778 19.482 5.73766 19.5499L4.79604 22.8L8.09172 21.8714C8.16058 21.8516 8.22326 21.8149 8.27397 21.7649L18.8816 11.3041ZM20.9707 5.04201C20.9315 5.00327 20.885 4.97253 20.8337 4.95156C20.7825 4.93058 20.7275 4.91979 20.672 4.91979C20.6165 4.91979 20.5616 4.93058 20.5103 4.95156C20.4591 4.97253 20.4125 5.00327 20.3733 5.04201L18.2437 7.14381L20.672 9.54017L22.8033 7.43836C22.8426 7.39972 22.8738 7.35381 22.8951 7.30327C22.9163 7.25272 22.9273 7.19854 22.9273 7.14381C22.9273 7.08909 22.9163 7.0349 22.8951 6.98436C22.8738 6.93382 22.8426 6.88791 22.8033 6.84926L20.9707 5.04201Z" fill="black"/>
<line x1="1.09278e-07" y1="28" x2="28" y2="28" stroke={props.color} strokeWidth="2.5"/>
</svg>

  )
}
