import { menuThirds } from 'constants/menus'
import AppointmentDurations from 'features/setting/defaults/appointment-durations/appointment-durations'
import TemplateText from 'features/setting/defaults/template-text/template-text'
import Keyword from 'features/setting/defaults/keyword/keyword'

const routeDefaults = [
  {
    ...menuThirds.APPOINTMENT_DURATION,
    component: AppointmentDurations,
    appBar: true
  },
  {
    ...menuThirds.TEMPLATE_TEXT,
    component: TemplateText,
    appBar: true

  },
  {
    ...menuThirds.KEYWORD,
    component: Keyword,
    appBar: true
  }
]

export default routeDefaults
