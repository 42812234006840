import DoctorFee from 'features/setting/doctor-fee-managements/doctor-fee/doctor-fee'
import FormDoctorFee from 'features/setting/doctor-fee-managements/doctor-fee/form-doctor-fee'
import HandInsurance from 'features/setting/doctor-fee-managements/hand-insurances/hand-insurance'
import FormTemplateDoctorFee from 'features/setting/doctor-fee-managements/template-doctor-fee/form-template-doctor-fee'
import { menuThirds, menuInside } from 'constants/menus'
import TemplateDoctorFee from 'features/setting/doctor-fee-managements/template-doctor-fee/template-doctor-fee'
import HistoryDoctorUseFee from 'features/setting/doctor-fee-managements/doctor-fee/historyDoctorUseFee'

const routeDoctorFeeManagements = [
  {
    ...menuThirds.TEMPLATE_DOCTOR_FEE,
    component: TemplateDoctorFee,
    appBar: true
  },
  {
    ...menuThirds.TEMPLATE_DOCTOR_FEE.name,
    key: menuThirds.TEMPLATE_DOCTOR_FEE.key,
    textname: `เพิ่ม ${menuThirds.TEMPLATE_DOCTOR_FEE.name}`,
    active: false,
    path: menuThirds.TEMPLATE_DOCTOR_FEE.path + '/create',
    component: FormTemplateDoctorFee,
    appBar: true
  },
  {
    ...menuThirds.TEMPLATE_DOCTOR_FEE.name,
    key: menuThirds.TEMPLATE_DOCTOR_FEE.key,
    textname: `แก้ไข ${menuThirds.TEMPLATE_DOCTOR_FEE.name}`,
    active: false,
    path: menuThirds.TEMPLATE_DOCTOR_FEE.path + '/:id',
    component: FormTemplateDoctorFee,
    appBar: true
  },
  {
    ...menuThirds.DOCTOR_FEE,
    component: DoctorFee,
    appBar: true
  },
  {
    ...menuThirds.DOCTOR_FEE.name,
    key: menuThirds.DOCTOR_FEE.key,
    textname: `เพิ่ม ${menuThirds.DOCTOR_FEE.name}`,
    active: false,
    path: menuThirds.DOCTOR_FEE.path + '/create',
    component: FormDoctorFee,
    appBar: true
  },
  {
    ...menuThirds.DOCTOR_FEE.name,
    key: menuThirds.DOCTOR_FEE.key,
    textname: `แก้ไข ${menuThirds.DOCTOR_FEE.name}`,
    active: false,
    path: menuThirds.DOCTOR_FEE.path + '/:id',
    component: FormDoctorFee,
    appBar: true
  },
  {
    ...menuInside.HISTORY_DETAIL_DF,
    key: menuThirds.DOCTOR_FEE.key,
    textname: `รายละเอียดค่า ${menuThirds.DOCTOR_FEE.name}`,
    active: false,
    path: menuInside.HISTORY_DETAIL_DF.path,
    component: HistoryDoctorUseFee,
    appBar: true
  },
  {
    ...menuThirds.HAND_INSURANCES,
    component: HandInsurance,
    appBar: true
  }
]

export default routeDoctorFeeManagements
