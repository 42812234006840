import { makeStyles } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { colors } from 'constants/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  modal: {
    '& .modal-sm': {
      [theme.breakpoints.up('sm')]: {
        maxWidth: '400px !important'
      }
    }
  }
}))

export const CustomTable = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    tableLayout: 'fixed',
    minWidth: 1280,
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        '&:first-of-type': {
          paddingLeft: 4,
          paddingRight: 4
        },
      }
    }
  }
}))

export const AutoNotiButton = styled('button')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: colors.white,
  backgroundColor: colors.black,
  border: 'none',
  borderRadius: 4,
  padding: 4,
  fontWeight: 400,
  fontSize: 12
}))

export const ShowingTag = styled('span')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: colors.white,
  backgroundColor: colors.themeMainColor,
  border: 'none',
  borderRadius: 8,
  padding: '4px 8px',
  fontWeight: 400,
  fontSize: 16
}))

export default useStyles
