import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import _ from 'lodash'
import * as UseStyled from 'features/report/useStyled'
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { isBuddhistYear, numberFormat } from 'utils/app.utils';

interface FcComponent {
    filterDate: any,
    page: number
    pageLimit: number
    rowCount: number
    data: any
    onRequestSort: (sortBy: string, sortType: string) => void
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
}

export default function CommissionSummary(props: FcComponent) {
    const { t } = useTranslation()

    const headCells = [
        { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.USER_NAME', align: 'left', class: 'text-nowrap' },
        { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.SUM_COMMISSION', align: 'right', class: 'text-nowrap' },
    ]

    const renderData = (data: any, no: number) => {
        return <>
            <TableRowCommon
                key={no.toString()}
                id={no.toString()}
                obj={data}
                columns={[
                    { option: 'TEXT', align: 'letf', label: data.userFullName || "-" },
                    { option: 'TEXT', align: 'right', label: numberFormat(data.totalCommission) || "-" }
                ]}
            />
        </>
    }

    return (
        <>
            <Box sx={{ maxWidth: '480px' }}>
                <Typography className='mx-2 my-2' ><span style={{ fontWeight: 500 }}>
                    {t('REPORT.COMMISSION.SUMMARY')}
                    {props.filterDate[0] ?
                        ` ${moment(props.filterDate[0]).add(isBuddhistYear(), 'y').format('DD/MM/YYYY')} >> ${moment(
                            props.filterDate[1] === 'Invalid date'
                                ? props.filterDate[0]
                                : props.filterDate[1]
                        )
                            .add(isBuddhistYear(), 'y')
                            .format('DD/MM/YYYY')
                        }` : t('REPORT.REPORT_GROUP.ALL')}
                </span>
                </Typography>
                <UseStyled.ResponsiveReportTable>
                    <TableCustom
                        page={props.page}
                        pageLimit={props.pageLimit}
                        sortType={''}
                        sortBy={''}
                        rowCount={props.rowCount}
                        onSort={props.onRequestSort}
                        setPageLimit={props.handleChangeRowsPerPage}
                        setPage={props.handleChangePage}
                        headCells={headCells}
                        rowsData={
                            [
                                _.map(props.data.summary, (sd: any, index: number) => {
                                    return (
                                        renderData(sd, index)
                                    )
                                })
                            ]
                        }
                        hidePagination={true}
                        tableMinWidth={480}
                        customScroll
                    />
                </UseStyled.ResponsiveReportTable>
            </Box>
        </>
    )
}
