
import React, { useCallback, useMemo } from 'react'
import { notiError } from 'component/notifications/notifications';
const contentEnabled = Object.prototype.hasOwnProperty.call(React, "createContext");
export interface ContentProp {
    handlePrint: () => void,
}
const PrintContent = contentEnabled ? React.createContext({} as ContentProp) : null;

export interface ValProps<T> {
    onClick: () => void;
    ref: (v: T) => void;
}

type Font = {
    family: string;
    source: string;
};

type PropertyFunction<T> = () => T;

const defaultProps = {
    copyStyles: true,
    removeIfram: false,
    suppressErrors: false,
};

export interface FunctionProps {
    classes?: string;
    content: any
    copyStyles?: boolean;
    docTitle?: string;
    font?: Font[];
    afterPrint?: () => void;
    beforeGetContent?: () => void | Promise<any>;
    beforePrint?: () => void | Promise<any>;
    printError?: (errorLocation: "beforeGetContent" | "beforePrint" | "print", error: Error) => void;
    style?: string | PropertyFunction<string>;
    print?: (target: HTMLIFrameElement) => Promise<any>;
    removeIfram?: boolean;
    suppressErrors?: boolean;
    trigger?: <T>() => React.ReactElement<ValProps<T>>;
    nonce?: string;
}

export default class FunctionPrint extends React.Component<FunctionProps> {
    private link!: number;
    private loaded!: Element[];
    private errored!: Element[];
    private fontsLoaded!: FontFace[];
    private fontsErrored!: FontFace[];

    static defaultProps = defaultProps;

    public startPrint = (target: HTMLIFrameElement) => {
        const {
            afterPrint,
            printError,
            print,
            docTitle,
        } = this.props;

        setTimeout(() => {
            if (target.contentWindow) {
                target.contentWindow.focus();

                if (print) {
                    print(target)
                        .then(this.removeIframe)
                        .catch((error: Error) => {
                            if (printError) {
                                notiError(`${error}`)
                            }
                        });
                } else if (target.contentWindow.print) {
                    const tempTitleOwner = target.ownerDocument.title;
                    if (docTitle) {
                        target.ownerDocument.title = docTitle;
                        if (target.contentDocument) {
                            target.contentDocument.title = docTitle;
                        }
                    }

                    target.contentWindow.print();
                    if (docTitle) {
                        target.ownerDocument.title = tempTitleOwner;
                        if (target.contentDocument) {
                            target.contentDocument.title = tempTitleOwner;
                        }
                    }
                }
                if (afterPrint) {
                    afterPrint();
                }

                this.removeIframe();
            }
        }, 500);
    }

    public triggerPrint = (target: HTMLIFrameElement) => {
        const {
            beforePrint,
            printError,
        } = this.props;

        if (beforePrint) {
            const beforePrints = beforePrint();
            if (beforePrints && typeof beforePrints.then === "function") {
                beforePrints
                    .then(() => {
                        this.startPrint(target);
                    })
                    .catch((error: Error) => {
                        if (printError) {
                            notiError(`${error}`)
                        }
                    });
            } else {
                this.startPrint(target);
            }
        } else {
            this.startPrint(target);
        }
    }

    public handleClick = () => {
        const {
            beforeGetContent,
            printError,
        } = this.props;

        if (beforeGetContent) {
            const getContentOutput = beforeGetContent();
            if (getContentOutput && typeof getContentOutput.then === "function") {
                getContentOutput
                    .then(this.handlePrint)
                    .catch((error: Error) => {
                        if (printError) {
                            notiError(`${error}`)
                        }
                    });
            } else {
                this.handlePrint();
            }
        } else {
            this.handlePrint();
        }
    }

    public handlePrint = () => {
        const {
            classes,
            content,
            copyStyles,
            font,
            style,
            nonce,
        } = this.props;

        const contentEl = content();

        const printWindow = document.createElement("iframe");
        printWindow.style.position = "absolute";
        printWindow.style.top = "-1000px";
        printWindow.style.left = "-1000px";
        printWindow.id = "printWindow";
        printWindow.srcdoc = "<!DOCTYPE html>";
        const contentNodes = contentEl;

        const dataContent = contentNodes.cloneNode(true);
        const isText = dataContent instanceof Text;

        const styleLinkNodes = document.querySelectorAll("link[rel='stylesheet']");
        const componentImg = isText ? [] : (dataContent as Element).querySelectorAll("img");
        const componentVideo = isText ? [] : (dataContent as Element).querySelectorAll("video");

        this.link =
            styleLinkNodes.length +
            componentImg.length +
            componentVideo.length;
        this.loaded = [];
        this.errored = [];
        this.fontsLoaded = [];
        this.fontsErrored = [];

        const markLoaded = (linkNode: Element, loaded: boolean) => {
            if (loaded) {
                this.loaded.push(linkNode);
            }
            const numResourcesManaged =
                this.loaded.length +
                this.errored.length +
                this.fontsLoaded.length +
                this.fontsErrored.length;

            if (numResourcesManaged === this.link) {
                this.triggerPrint(printWindow);
            }
        };

        printWindow.onload = () => {
            printWindow.onload = null;
            const doc = printWindow.contentDocument || printWindow.contentWindow?.document;

            if (doc) {
                doc.body.appendChild(dataContent);

                if (font) {
                    if (printWindow.contentDocument?.fonts && printWindow.contentWindow?.FontFace) {
                        font.forEach((fontItem) => {
                            const fontFace = new FontFace(fontItem.family, fontItem.source);
                            printWindow.contentDocument?.fonts.add(fontFace);
                            fontFace.loaded
                                .then((loadedFontFace) => {
                                    this.fontsLoaded.push(loadedFontFace);
                                })
                                .catch((error: SyntaxError) => {
                                    this.fontsErrored.push(fontFace);
                                });
                        });
                    }
                }

                const defaultStyle = typeof style === "function" ? style() : style;

                if (typeof defaultStyle !== 'string') {
                    notiError(`${typeof defaultStyle} "input not Sting"`)
                } else {
                    const styleAtl = doc.createElement("style");
                    if (nonce) {
                        styleAtl.setAttribute("nonce", nonce);
                        doc.head.setAttribute("nonce", nonce);
                    }
                    styleAtl.appendChild(doc.createTextNode(defaultStyle));
                    doc.head.appendChild(styleAtl);
                }

                if (classes) {
                    doc.body.classList.add(...classes.split(" "));
                }

                if (!isText) {
                    const srcCanvas = isText ? [] : (contentNodes as Element).querySelectorAll("canvas");
                    const targetCanvasEls = doc.querySelectorAll("canvas");

                    for (let i = 0; i < srcCanvas.length; ++i) {
                        const sourceCanvas = srcCanvas[i];

                        const targetCanvas = targetCanvasEls[i];
                        const targetCanvasContext = targetCanvas.getContext("2d");

                        if (targetCanvasContext) {
                            targetCanvasContext.drawImage(sourceCanvas, 0, 0);
                        }
                    }

                    for (let i = 0; i < componentImg.length; i++) {
                        const img = componentImg[i];
                        const imgSrc = img.getAttribute("src");

                        if (!imgSrc) {
                            notiError(`${img} "recheck tag img"`)
                            markLoaded(img, false);
                        } else {
                            const newImg = new Image();
                            newImg.onload = markLoaded.bind(null, img, true);
                            newImg.onerror = markLoaded.bind(null, img, false);
                            newImg.src = imgSrc;
                        }
                    }

                    for (let i = 0; i < componentVideo.length; i++) {
                        const videoNode = componentVideo[i];
                        videoNode.preload = 'auto';

                        const video = videoNode.getAttribute('poster')
                        if (video) {
                            const imgVideo = new Image();
                            imgVideo.onload = markLoaded.bind(null, videoNode, true);
                            imgVideo.onerror = markLoaded.bind(null, videoNode, false);
                            imgVideo.src = video;
                        } else {
                            if (videoNode.readyState >= 2) {
                                markLoaded(videoNode, true);
                            } else {
                                videoNode.onloadeddata = markLoaded.bind(null, videoNode, true);
                                videoNode.onerror = markLoaded.bind(null, videoNode, false);
                                videoNode.onstalled = markLoaded.bind(null, videoNode, false);
                            }
                        }
                    }
                    const input = 'input';
                    const original = (contentNodes as HTMLElement).querySelectorAll(input);
                    const copied = doc.querySelectorAll(input);
                    for (let i = 0; i < original.length; i++) {
                        copied[i].value = original[i].value;
                    }

                    const checked = 'input[type=checkbox],input[type=radio]';
                    const originalRadio = (contentNodes as HTMLElement).querySelectorAll(checked);
                    const copiedRadio = doc.querySelectorAll(checked);
                    for (let i = 0; i < originalRadio.length; i++) {
                        (copiedRadio[i] as HTMLInputElement).checked =
                            (originalRadio[i] as HTMLInputElement).checked;
                    }

                    const select = 'select';
                    const originalSelect = (contentNodes as HTMLElement).querySelectorAll(select);
                    const copiedSelect = doc.querySelectorAll(select);
                    for (let i = 0; i < originalSelect.length; i++) {
                        copiedSelect[i].value = originalSelect[i].value;
                    }
                }

                if (copyStyles) {
                    const headEls = document.querySelectorAll("style, link[rel='stylesheet']");
                    for (let i = 0, headElsLen = headEls.length; i < headElsLen; ++i) {
                        const node = headEls[i];
                        if (node.tagName.toLowerCase() === 'style') {
                            const header = doc.createElement(node.tagName);
                            const sheet = (node as HTMLStyleElement).sheet as CSSStyleSheet;
                            if (sheet) {
                                let styleCSS = "";
                                try {
                                    const cssLength = sheet.cssRules.length;
                                    for (let j = 0; j < cssLength; ++j) {
                                        if (typeof sheet.cssRules[j].cssText === "string") {
                                            styleCSS += `${sheet.cssRules[j].cssText}\r\n`;
                                        }
                                    }
                                } catch (error) {
                                    notiError(`"error copystlyes"`)
                                }

                                header.setAttribute("id", `react-to-print-${i}`);
                                if (nonce) {
                                    header.setAttribute("nonce", nonce);
                                }
                                header.appendChild(doc.createTextNode(styleCSS));
                                doc.head.appendChild(header);
                            }
                        } else {
                            if (node.getAttribute("href")) {
                                const header = doc.createElement(node.tagName);
                                for (let j = 0, attrLen = node.attributes.length; j < attrLen; ++j) {
                                    const attr = node.attributes[j];
                                    if (attr) {
                                        header.setAttribute(attr.nodeName, attr.nodeValue || "");
                                    }
                                }

                                header.onload = markLoaded.bind(null, header, true);
                                header.onerror = markLoaded.bind(null, header, false);
                                if (nonce) {
                                    header.setAttribute("nonce", nonce);
                                }
                                doc.head.appendChild(header);
                            } else {
                                markLoaded(node, true);
                            }
                        }
                    }
                }
            }

            if (this.link === 0 || !copyStyles) {
                this.triggerPrint(printWindow);
            }
        };

        this.removeIframe(true);
        document.body.appendChild(printWindow);
    }

    public removeIframe = (force?: boolean) => {
        const {
            removeIfram,
        } = this.props;

        if (force || removeIfram) {
            const documentPrintWindow = document.getElementById("printWindow");
            if (documentPrintWindow) {
                document.body.removeChild(documentPrintWindow);
            }
        }
    }

    public render() {
        const {
            children,
            trigger,
        } = this.props;

        if (trigger) {
            return React.cloneElement(trigger(), {
                onClick: this.handleClick,
            });
        } else {
            if (!PrintContent) {
                notiError(`"plese contact devloper"`)

                return null;
            }

            const value = { handlePrint: this.handleClick };

            return (
                <PrintContent.Provider value={value as ContentProp}>
                    {children}
                </PrintContent.Provider>
            );
        }
    }
}

type UsePrintHookReturn = () => void;

export const useFunctionPrint = (props: FunctionProps): UsePrintHookReturn => {
    const reactToPrint = useMemo(
        () => new FunctionPrint({ ...defaultProps, ...props }),
        [props]
    );

    return useCallback(() => reactToPrint.handleClick(), [reactToPrint]);
};
