import { colors } from "constants/theme"
import IconProps from "./icon-props"

export default function Register(props: IconProps) {
  return (
    <svg width="40" height="25" viewBox="0 0 40 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.33008 18.716V17.189C6.34532 16.3639 6.68759 15.5785 7.28165 15.0057C7.87571 14.4328 8.67293 14.1193 9.49808 14.134H15.8301C16.6552 14.1193 17.4524 14.4328 18.0465 15.0057C18.6406 15.5785 18.9828 16.3639 18.9981 17.189V18.716C19.0011 19.098 6.33008 18.716 6.33008 18.716Z" fill={props.active && props.color ? props.color : props.active ? colors.themeSecondColor : colors.transparent} stroke={props.color || colors.themeSecondColor} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.5621 8.663C15.5621 9.28146 15.3787 9.88603 15.0351 10.4003C14.6915 10.9145 14.2031 11.3153 13.6318 11.552C13.0604 11.7886 12.4316 11.8506 11.8251 11.7299C11.2185 11.6093 10.6613 11.3114 10.224 10.8741C9.78667 10.4368 9.48885 9.87962 9.36819 9.27304C9.24754 8.66646 9.30946 8.03773 9.54614 7.46634C9.78281 6.89496 10.1836 6.40659 10.6978 6.06299C11.2121 5.71939 11.8166 5.536 12.4351 5.536C13.2644 5.536 14.0598 5.86545 14.6462 6.45187C15.2327 7.0383 15.5621 7.83366 15.5621 8.663V8.663Z" fill={props.active && props.color ? props.color : props.active ? colors.themeSecondColor : colors.transparent} stroke={props.color || colors.themeSecondColor} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.835 7.634C22.835 7.634 30.219 7.564 32.893 7.634" stroke={props.color || colors.themeSecondColor} strokeWidth="1.8" strokeLinecap="round" />
      <path d="M22.835 11.634C22.835 11.634 27.977 11.564 29.835 11.634" stroke={props.color || colors.themeSecondColor} strokeWidth="1.6" strokeLinecap="round" />
      <path d="M22.835 15.634C22.835 15.634 30.219 15.564 32.893 15.634" stroke={props.color || colors.themeSecondColor} strokeWidth="1.6" strokeLinecap="round" />
      <path d="M38.5 1.5H1.5V23.5H38.5V1.5Z" stroke={props.color || colors.themeSecondColor} strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
