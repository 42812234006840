import * as utils from 'component/Excel/function-table'
import { RenderExcel, ExcelReturn } from 'component/Excel/interfaces/render-excel'
import { Model } from 'component/Excel/interfaces/model'
import { LoadDataToTable } from 'component/Excel/function-table/create-table'

function download(fileName: string, model: Model): boolean {
  const element = window.document.createElement('a')
  element.href = utils.uri + utils.base64(utils.format(utils.template, model))
  element.download = fileName
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
  return true
}

function getTable(tableExportRef?: any, loadDatatoTable?: LoadDataToTable) {
  if (tableExportRef) {
    const cloneTable = tableExportRef.cloneNode(true)
    return cloneTable.outerHTML
  }

  if (loadDatatoTable) return utils.createTable(loadDatatoTable)
}

function handleDownload({ fileName, sheetName, loadDataToTable }: { fileName: string; sheetName: string; loadDataToTable?: LoadDataToTable }, tableExportRef?: HTMLElement) {
  const table = getTable(tableExportRef, loadDataToTable)
  const model: Model = {
    worksheet: sheetName || 'Worksheet',
    table
  }

  return download(fileName, model)
}

function excel({ tableExportRef, filename, sheetName }: RenderExcel): ExcelReturn {
  function onDownload(): boolean {
    const table = getTable(tableExportRef)
    const fileName = `${filename}.xls`
    const model: Model = {
      worksheet: sheetName || 'Worksheet',
      table
    }
    return download(fileName, model)
  }
  return { onDownload }
}

export { excel, handleDownload as downloadExcel }
