import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

const news = '/news'

export interface ObjImage {
  fileId?: number | null
  base64: string
  type: string
  fileExtension: string
  fileSize: string
}

export interface NewsType {
  subject: string
  content: string
  datePublic: string
  timePublic: string
  dateEnd: string
  timeEnd: string
  status?: "ACTIVE" | "INACTIVE"
  image: ObjImage
}

export default class NewsApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get(`${news}`, { params: { ...props } }).then((res) => res)
  }

  static findById(newsId: number): Promise<any> {
    return this.api.get(`${news}/${newsId}`).then((res) => res)
  }

  static createNews(payload: NewsType): Promise<any> {
    return this.api.post(`${news}`, payload).then((res) => res)
  }

  static updateNews(newsId: number, payload: NewsType): Promise<any> {
    return this.api.patch(`${news}/${newsId}`, payload).then((res) => res)
  }

  static updateNewsStatus(newsId: number, status: string): Promise<any> {
    return this.api.patch(`${news}/${newsId}/status`, { status: status }).then((res) => res)
  }

  static removeNews(newsId: number): Promise<any> {
    return this.api.delete(`${news}/${newsId}`).then((res) => res)
  }
}