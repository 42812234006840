import { makeStyles } from '@material-ui/core'
import { styled } from '@mui/material/styles'

const useStyles = makeStyles((theme) => ({
  root: {},
  btnAddRecommends: {}
}))

export const ServiceImage = styled('div')(({ theme }) => ({

}))

export const ImageRatio = styled('div')(({ theme }) => ({
  width: '60%',
  maxWidth: 100,
  '.MuiBox-root': {
    paddingTop: '100%',
    svg: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    }
  },
  [theme.breakpoints.down('lg')]: {
    width: '30%'
  }
}))

export default useStyles
