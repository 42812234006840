import { createSlice } from '@reduxjs/toolkit'

export interface ExpiredState {
  expired: boolean
}

const initialState: ExpiredState = {
  expired: false
}

export const expiredSlice = createSlice({
  name: 'expiredState',
  initialState,
  reducers: {
    setExpired: (state, action: any) => {
      state.expired = action.payload
    }
  }
})

export const expired = (state: any) => state.expired.expired

export const { setExpired } = expiredSlice.actions

export default expiredSlice.reducer
