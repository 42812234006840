import { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** UTILS || SLICE */
import { resetModal } from 'app/slice/modal.slice'
import { CHECK_ALL_TYPE } from 'constants/common'
import {
    treatment,
    setTreatmentByKey,
    TreatmentStateInterface,
    setColorToothChart,
    setSubmitTootChart,
    setDiagnosisByKey,
    setRecommendByKey,
    SummaryTreatmentsInterface,
    isSubmitTootChart,
} from 'app/slice/treatment.slice';

/** API */
import ClinicApi from 'api/master/clinic.api'
import { DxRecommendDataInterface, DiagnosisDataInterface } from 'api/setting/treatments/diagnosis.api';

/** COMPONENT */
import { DiagnosisType } from 'features/treatments/MainMenu/OPD/Dx';
import { DoctorsType } from 'features/treatments/treatmentsIndex';
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import AutocompleteSelect from 'component/Select/AutocompleteSelect';

/** STYLE */
import { styled } from '@mui/material/styles'
import Loading from 'component/Loading';
import OperativeApi from 'api/setting/treatments/operative.api';
import { getClinicInfo } from 'utils/app.utils';

const CustomTable = styled(Box)(({ theme }) => ({
    '.MuiTable-root': {
        '.MuiTableBody-root': {
            '.MuiTableCell-root': {
                verticalAlign: 'top !important',
                padding: '15px'
            }
        }
    }
}))

type PopupAddDxType = {
    type: 'tx' | 'dx'
    tx: any
    dx: DiagnosisType
    data: {
        dx: DiagnosisDataInterface[]
        recommend: DxRecommendDataInterface[]
    }
    isShow: boolean
    setIsShow: (show: boolean) => void
}

const initialErrorMessage = {
    EMPTY_DOCTOR: ``
}

export default function PopupAddDx(props: PopupAddDxType) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const isSubmit = useSelector(isSubmitTootChart)

    const treatments: TreatmentStateInterface = useSelector(treatment)

    const [tx, setTx] = useState(props.tx);
    const [dx, setDx] = useState<DiagnosisType>(props.dx);
    const [doctors, setDoctors] = useState<DoctorsType[]>([]);
    const [dxSelect, setDxSelect] = useState<DiagnosisDataInterface[]>(props.data.dx);
    const [recommendSelect, setRecommendSelect] = useState<DxRecommendDataInterface[]>(props.data.recommend);
    const [isDxCheckAll, setIsDxCheckAll] = useState<CHECK_ALL_TYPE | null>(CHECK_ALL_TYPE.CHECKED);
    const [isRecommendCheckAll, setIsRecommendCheckAll] = useState<CHECK_ALL_TYPE | null>(CHECK_ALL_TYPE.CHECKED);
    const [loadingDoctors, setLoadingDoctors] = useState(false);
    const [errorMessage, setErrorMessage] = useState(initialErrorMessage);
    const [submitTx, setSubmitTx] = useState(false);
    const [newRefItemId, setNewRefItemId] = useState<string>('')

    const branchInfo = getClinicInfo()

    const loadDefaultDoctor = () => {
        setLoadingDoctors(true)
        ClinicApi.findAllDoctors()
            .then(({ data }) => setDoctors(data))
            .catch(() => { return })
            .finally(() => setLoadingDoctors(false))
    }

    const dxHeadCells = [
        {
            id: 'dxSelect',
            disablePadding: false,
            label: 'CHECKBOX',
            indeterminate: isDxCheckAll === CHECK_ALL_TYPE.INDETERMINATE,
            checked: isDxCheckAll === CHECK_ALL_TYPE.CHECKED,
            disableDropdown: _.isEmpty(props.data.dx),
            values: [],
            onCheckAll: (event: any) => onDxCheckAll(event.target),
            width: '70px'
        },
        { id: 'message', disablePadding: false, label: t('TX.POPUP_TX.CODE'), class: 'p-3', width: '100px' },
        { id: 'message', disablePadding: false, label: t('TX.POPUP_TX.DX_TITLE'), class: 'p-3' }
    ]

    const renderDxData = (objData: DiagnosisType, no: number) => {
        const rowCheck = _.some(dxSelect, { diagnosisId: objData.diagnosisId })
        const { diagnosisId, diagnosisCode, diagnosisText } = objData;
        const objRenderData = {
            key: no.toString(),
            id: no.toString(),
            obj: objData,
            rowSelect: rowCheck,
            columns: [
                { option: 'CHECKBOX', align: 'center', label: diagnosisId, class: 'pb-0 pt-2', disabled: _.some(treatments.patientDiagnosis, { diagnosisId: objData.diagnosisId }) },
                { option: 'TEXT', align: 'left', label: diagnosisCode || '-' },
                { option: 'TEXT', align: 'left', label: diagnosisText }
            ]
        }

        return <TableRowCommon {...objRenderData} onCheck={(event) => onDxCheck(event.target)} />
    }

    const onDxCheckAll = (event: any) => {
        const { checked } = event
        if (checked) {
            setDxSelect(Array.from(new Set([...dxSelect, ...props.data.dx])))
        } else {
            const dxAlreadySelected: DiagnosisDataInterface[] = _.filter(dxSelect, (d: DiagnosisDataInterface) => _.some(treatments.patientDiagnosis, { diagnosisId: d.diagnosisId }))
            setDxSelect(dxAlreadySelected)
        }

    }

    const onDxCheck = (event: any) => {
        const { checked, value } = event
        if (checked) {
            const item: any = _.find(props.data.dx, { diagnosisId: Number(value) })
            setDxSelect([...dxSelect, item])
        } else {
            setDxSelect(
                _.filter(dxSelect, (d: DiagnosisDataInterface) => {
                    return Number(d.diagnosisId) !== Number(value)
                })
            )
        }
    }

    const recommendHeadCells = [
        {
            id: 'recommendSelect',
            disablePadding: false,
            label: 'CHECKBOX',
            indeterminate: !!props.data.recommend && isRecommendCheckAll === CHECK_ALL_TYPE.INDETERMINATE,
            checked: !!props.data.recommend && isRecommendCheckAll === CHECK_ALL_TYPE.CHECKED,
            disableDropdown: _.isEmpty(props.data.recommend),
            values: [],
            onCheckAll: (event: any) => onRecommendCheckAll(event.target),
            width: '70px'
        },
        { id: 'message', disablePadding: false, label: t('TX.POPUP_TX.RECOMMEND_MESSAGE'), class: 'p-3' }
    ]

    const renderRecommendData = (objData: DxRecommendDataInterface, no: number) => {
        const rowCheck = _.some(recommendSelect, { recommendId: objData.recommendId })

        const objRenderData = {
            key: no.toString(),
            id: no.toString(),
            obj: objData,
            rowSelect: rowCheck,
            columns: [
                { option: 'CHECKBOX', align: 'center', label: objData.recommendId, class: 'pb-0 pt-2', disabled: _.some(treatments.patientRecommends, { recommendId: objData.recommendId }) },
                { option: 'TEXT', align: 'left', label: objData.recommendText },
            ]
        }

        return <TableRowCommon {...objRenderData} onCheck={(event) => onRecommendCheck(event.target)} />
    }

    const onRecommendCheckAll = (event: any) => {
        const { checked } = event
        if (checked) {
            setRecommendSelect(Array.from(new Set([...recommendSelect, ...props.data.recommend])))
        } else {
            const recommendAlreadySelected: DxRecommendDataInterface[] = _.filter(recommendSelect, (r: DxRecommendDataInterface) => _.some(treatments.patientRecommends, { recommendId: r.recommendId }))
            setRecommendSelect(recommendAlreadySelected)
        }

    }

    const onRecommendCheck = (event: any) => {
        const { checked, value } = event
        if (checked) {
            const item: any = _.find(props.data.recommend, { recommendId: Number(value) })
            setRecommendSelect([...recommendSelect, item])
        } else {
            setRecommendSelect(
                _.filter(recommendSelect, (rcm: DxRecommendDataInterface) => {
                    return Number(rcm.recommendId) !== Number(value)
                })
            )
        }
    }

    const handleTxCurrent = () => {
        const newFilter = treatments.summeryTreatments.filter((item: any, index: number) => {
            if (item.operativeId === tx.operativeId) {
                return item
            }
        })
        const newId = newFilter[newFilter?.length - 1]?.refItemId ? Number(newFilter[newFilter?.length - 1]?.refItemId) + 1 : 0

        if (newId === 0) {
            return `${tx.operativeId || 0}${newId}`
        } else {
            return `${newId}`
        }

    }

    const handleRecomCheck = (id: number, data: any) => {
        let checkRecom: any = false
        for (const index of data) {
            if (index.recommendId === id) {
                checkRecom = true
                break
            }
        }
        return checkRecom
    }

    const handleRefItemIdCurrent = (item: any, refId: string) => {

        const newData = { ...item, refItemId: [...item.refItemId, refId] }
        return newData
    }

    const onSubmit = async () => {
        const newRefItemId = handleTxCurrent()
        setNewRefItemId(newRefItemId)
        if ((props.type === 'tx' && !tx?.doctorId) || (props.type === 'dx' && !dx?.doctorId) || (!_.find(doctors, { userId: tx?.doctorId || dx?.doctorId }))) return setErrorMessage({ EMPTY_DOCTOR: t(`TX.POPUP_TX.MESSAGE.EMPTY_DOCTOR`) })
        if (props.type === 'tx') {
            let newData = { ...tx, refItemId: `${newRefItemId}` }
            if (tx.operativeId) {
                const resp: any = await OperativeApi.findById(tx.operativeId)
                const data = {
                    clinicFeeId: resp?.data?.operativeClinicFees[0]?.clinicFeeId,
                    clinicFeeName: resp?.data?.operativeClinicFees[0]?.clinicFeeText,
                    clinicFeePrice: resp?.data?.operativeClinicFees[0]?.price
                }
                if (resp.status === 200) newData = { ...newData, ...data }
            }
            dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: [...treatments.summeryTreatments, newData] }))
            dispatch(setColorToothChart({ value: tx?.color }))
            // props.setIsShow(false)
            setSubmitTx(true)
        } else {
            dispatch(setDiagnosisByKey({ key: 'patientDiagnosis', value: [...treatments.patientDiagnosis, dx] }))
            dispatch(setSubmitTootChart(true))
        }

        let newRecommendSelect: { recommendId: number, recommendText: string, refItemId?: string[] }[] = []
        _.forEach(recommendSelect, (r: DxRecommendDataInterface) => {
            newRecommendSelect = [...newRecommendSelect,
            {
                recommendId: r.recommendId,
                recommendText: r.recommendText,
                refItemId: [newRefItemId]
            }]
        })

        const RecomSelect = [...treatments.patientRecommends, ...newRecommendSelect].filter((item: any) => {
            if (!_.some(treatments.patientRecommends, { recommendId: item.recommendId })) {
                return item
            }
        })

        const newRecom = await treatments.patientRecommends.map((item: any) => {
            if (handleRecomCheck(item.recommendId, newRecommendSelect)) {
                return handleRefItemIdCurrent(item, newRefItemId)
            } else {
                return item
            }
        })

        const newPatientRecommends = newRecom.length !== 0 ? [...newRecom, ...RecomSelect] : [...treatments.patientRecommends, ...newRecommendSelect]
        dispatch(setRecommendByKey({ key: 'patientRecommends', value: newPatientRecommends }))

        if (props.type === 'dx') {
            props.setIsShow(false)
        }
        setErrorMessage(initialErrorMessage)
    }

    const handleDiagnosisCheck = (id: number, data: any) => {
        let checkRecom: any = false
        for (const index of data) {
            if (index.diagnosisId === id) {
                checkRecom = true
                break
            }
        }
        return checkRecom
    }

    const handleAddDxByTx = () => {

        const lastTx: SummaryTreatmentsInterface | undefined = _.last(treatments.summeryTreatments)
        const teeth = lastTx?.teeth || ''
        const teethJson = lastTx?.teethJson || ''
        let newDxSelect: { patientDiagnosisId: number | null, diagnosisId: number, doctorId: number, diagnosisName: string, diagnosisCode: string, teeth: string, teethJson?: string, refItemId?: string[] }[] = []
        _.forEach(dxSelect, (d: DiagnosisType) => {
            const diagnosisId = d.diagnosisId || 0
            const diagnosisText = d.diagnosisText || ''
            const diagnosisCode = d.diagnosisCode || ''
            newDxSelect = [...newDxSelect, {
                teeth: teeth,
                teethJson: teethJson,
                patientDiagnosisId: null,
                diagnosisId: diagnosisId,
                doctorId: tx?.doctorId,
                diagnosisName: diagnosisText,
                diagnosisCode: diagnosisCode,
                refItemId: [newRefItemId]
            }]
        })

        const DXSelect = [...treatments.patientDiagnosis, ...newDxSelect].filter((item: any) => {
            if (!_.some(treatments.patientDiagnosis, { diagnosisId: item.diagnosisId })) {
                return item
            }
        })
        const newDiagnosis = treatments.patientDiagnosis.map((item: any) => {
            if (handleDiagnosisCheck(item.diagnosisId, newDxSelect)) {
                return handleRefItemIdCurrent(item, newRefItemId)
            } else {
                return item
            }
        })

        const newPatientDiagnosis = newDiagnosis.length !== 0 ? [...newDiagnosis, ...DXSelect] : [...treatments.patientDiagnosis, ...newDxSelect]

        dispatch(setDiagnosisByKey({ key: 'patientDiagnosis', value: newPatientDiagnosis }))
        setSubmitTx(false)
        props.setIsShow(false)
    }

    useEffect(() => {
        if (submitTx && !isSubmit) {
            handleAddDxByTx()
            dispatch(setSubmitTootChart(true))

        }
    }, [isSubmit, treatments.summeryTreatments, submitTx]);

    useEffect(() => {
        loadDefaultDoctor()
    }, []);

    useEffect(() => {
        if (_.size(recommendSelect) === _.size(props.data.recommend)) {
            setIsRecommendCheckAll(CHECK_ALL_TYPE.CHECKED)
        }
        else if (_.size(recommendSelect) > 0) {
            setIsRecommendCheckAll(CHECK_ALL_TYPE.INDETERMINATE)
        }
        else {
            setIsRecommendCheckAll(null)
        }
    }, [recommendSelect]);

    useEffect(() => {
        if (_.size(dxSelect) === _.size(props.data.dx)) {
            setIsDxCheckAll(CHECK_ALL_TYPE.CHECKED)
        }
        else if (_.size(dxSelect) > 0) {
            setIsDxCheckAll(CHECK_ALL_TYPE.INDETERMINATE)
        }
        else {
            setIsDxCheckAll(null)
        }
    }, [dxSelect]);

    const checkType = (type: any) => {
        if (type === 'tx') {
            return t(`TX.POPUP_TX.TITLE`)
        } else {
            return t(`TX.POPUP_TX.DX_TITLE`)
        }
    }

    return <>
        {props.isShow && (
            <ModalCustom
                size={'lg'}
                title={checkType(props.type)}
                alignFooter={'end'}
                footerInline
                onSubmit={onSubmit}
                onReset={() => {
                    dispatch(resetModal())
                    props.setIsShow(false)
                    setErrorMessage(initialErrorMessage)
                }}
                textBtnCancel={t('BUTTON.CANCEL')}
                textBtnConfirm={t('BUTTON.SAVE')}
                modalStyle={''}
                component={
                    !loadingDoctors && (
                        <Box>

                            <Box className='d-flex flex-wrap'>
                                {props.type === 'tx' && (
                                    <Typography className='pr-3'>
                                        <span style={{ fontWeight: 500 }}>{t(`TX.TABLE.TYPE`)}:</span>
                                        <span className='pl-1 pl-md-2'>{tx?.operativeTypeName || tx?.operativeTypeNameEn}</span>
                                    </Typography>
                                )}
                                <Typography className='pr-3'>
                                    <span style={{ fontWeight: 500 }}>{t(`TX.TABLE.CODE`)}:</span>
                                    <span className='pl-1 pl-md-2'>{tx?.operativeCode || dx?.diagnosisCode}</span>
                                </Typography>
                                {props.type === 'tx' && (
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t(`TX.TABLE.SERVICE`)}:</span>
                                        <span className='pl-1 pl-md-2'>{tx?.operativeName || tx?.operativeNameEn}</span>
                                    </Typography>
                                )}
                                {props.type === 'dx' && (
                                    <Typography>
                                        <span style={{ fontWeight: 500 }}>{t(`TX.POPUP_TX.DX_TITLE`)}:</span>
                                        <span className='pl-1 pl-md-2'>{dx?.diagnosisName}</span>
                                    </Typography>
                                )}
                            </Box>
                            <Box className='pt-3 d-flex flex-column flex-sm-row'>
                                <Typography className='mr-3 pb-2 pt-sm-2 pb-sm-0' sx={{ fontWeight: 500 }}>{t('TX.POPUP_TX.DOCTOR')}</Typography>
                                <Box className='w-100' sx={{ maxWidth: { md: '320px' } }}>
                                    <AutocompleteSelect
                                        labelId="sl-dentist"
                                        options={doctors}
                                        getOptionLabel={(option: DoctorsType) => option.fullname}
                                        renderOption={(props, option) => {
                                            const isDisabled = treatments?.summeryTreatments?.some((item: any) => {
                                                if (item?.isEdit === '0') {
                                                    return item?.doctorId === option?.userId
                                                }
                                            })

                                            return (
                                                <Box
                                                    {...props}
                                                    key={option.userId}
                                                    component="li"
                                                    aria-disabled={branchInfo?.pdfInvoiceTreatmentCondition === '0' ? isDisabled : false}
                                                >
                                                    {option.fullname}
                                                </Box>
                                            )
                                        }}
                                        onchange={(e: any, value: DoctorsType) => {
                                            if (props.type === 'tx') {
                                                setTx({ ...tx, doctorId: value.userId })
                                            }
                                            else {
                                                setDx({ ...dx, doctorId: value.userId })
                                            }
                                            setErrorMessage(initialErrorMessage)
                                        }}
                                        noOptionsText={t('NOT_FOUND')}
                                        filterOptions={(option: DoctorsType) => option.fullname}
                                        value={_.find(doctors, { userId: tx?.doctorId ?? dx?.doctorId }) || null}
                                        disableClearable
                                        errMessage={errorMessage.EMPTY_DOCTOR || ''}
                                    />
                                </Box>
                            </Box>
                            {props.type === 'tx' && (
                                <CustomTable className='pt-3'>
                                    <Typography className='mb-2' sx={{ fontWeight: 500 }}>{t('TX.POPUP_TX.DX_TITLE')}</Typography>
                                    <TableCustom
                                        hidePagination
                                        tableFixedWidth
                                        page={1}
                                        pageLimit={1}
                                        sortType={''}
                                        sortBy={''}
                                        rowCount={1}
                                        onSort={() => { return }}
                                        setPageLimit={() => { return }}
                                        setPage={() => { return }}
                                        headCells={dxHeadCells}
                                        rowsData={_.map(props.data.dx, (dx, index: number) => {
                                            return renderDxData(dx, index)
                                        })}
                                    />
                                </CustomTable>
                            )}

                            <CustomTable className='pt-3'>
                                <Typography className='mb-2' sx={{ fontWeight: 500 }}>{t('TX.POPUP_TX.RECOMMEND_TITLE')}</Typography>
                                <TableCustom
                                    hidePagination
                                    tableFixedWidth
                                    page={1}
                                    pageLimit={1}
                                    sortType={''}
                                    sortBy={''}
                                    rowCount={1}
                                    onSort={() => { return }}
                                    setPageLimit={() => { return }}
                                    setPage={() => { return }}
                                    headCells={recommendHeadCells}
                                    rowsData={_.map(props.data.recommend, (recommend, index: number) => {
                                        return renderRecommendData(recommend, index)
                                    })}
                                />
                            </CustomTable>
                        </Box>
                    )

                    ||
                    <Box className='w-max mx-auto'>
                        <Loading show type={'softLoading'} />
                    </Box>
                }
            />
        ) || <></>
        }
    </>
}
