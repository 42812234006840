import { colors } from "constants/theme"
import IconProps from "./icon-props"

export default function Chart(props: IconProps) {
  return (
    <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="13" y="8" width="4" height="13" fill={props.active && props.color ? props.color : props.active ? colors.themeSecondColor : colors.transparent} />
      <rect x="5" y="13" width="4" height="8" fill={props.active && props.color ? props.color : props.active ? colors.themeSecondColor : colors.transparent} />
      <rect x="21" y="2" width="4" height="19" fill={props.active && props.color ? props.color : props.active ? colors.themeSecondColor : colors.transparent} />
      <path d="M9.989 20.434H8.549V13.234H5.669V20.434H4.229V11.794H9.989V20.434Z" fill={props.color || colors.themeSecondColor} />
      <path d="M18.114 20.434H16.674V8.914H13.794V20.434H12.354V7.474H18.114V20.434Z" fill={props.color || colors.themeSecondColor} />
      <path d="M26.2331 20.434H24.7931V2.62H21.9131V20.434H20.4731V1H26.2331V20.434Z" fill={props.color || colors.themeSecondColor} />
      <path d="M1 20.434H28.569C28.569 20.434 29.397 20.457 29.397 21.154C29.4064 21.2606 29.3904 21.3679 29.3503 21.4672C29.3102 21.5664 29.2473 21.6548 29.1665 21.725C29.0857 21.7952 28.9895 21.8454 28.8856 21.8712C28.7818 21.8971 28.6733 21.8981 28.569 21.874H1V20.434Z" fill={props.color || colors.themeSecondColor} />
      <path d="M1.78906 1.47699L1.80506 20.434" stroke={props.color || colors.themeSecondColor} strokeWidth="1.6" strokeLinecap="round" />
    </svg>
  )
}
