import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'
import { FormControl, Select, MenuItem } from '@mui/material'
import 'component/Select/Style.css'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import ClearIcon from '@mui/icons-material/Clear';

/** TRANSLATION */
import { useTranslation } from 'react-i18next'
import InputTextField from 'component/Input/InputTextField'
import { useEffect, useState } from 'react'



type BasicSelectProps = {
  labelId: string
  selectId: string
  label: string
  value?: any
  onchange?: (value: any) => void
  renderValue?: (value: any) => void
  options: any
  fullWidth?: boolean
  multiple?: boolean
  required?: boolean
  formControlClass?: any
  register?: any
  name?: any
  style?: any
  helperText?: string
  formControlStyle?: any
  disabled?: boolean
  classesOption?: string
  isClear?: boolean
  onClear?: () => void
}

const StyledFilterSelect = styled(Select)(({ theme }) => ({
  height: '2rem',
  '& .MuiSelect-select': {
    padding: '0 1rem'
  },
  '& .MuiInputBase-root': {
    height: '100%'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.borderInput,
    legend: {
      width: 0
    }
  },
  '& .MuiSvgIcon-root': {
    color: colors.black
  },
  '& fieldset, &.Mui-focused fieldset': {
    borderColor: `${colors.black12} !important`
  },
  '& svg': {
    position: 'absolute',
    fontSize: '16px',
    right: '0.625rem'
  },
  '& [aria-expanded="true"] + input + svg': {
    transform: 'rotate(180deg)'
  },
  '&.Mui-disabled': {
    '.MuiOutlinedInput-notchedOutline': {
      backgroundColor: colors.black12
    },
    '.MuiSelect-select': {
      color: colors.black60,
      '-webkit-text-fill-color': 'unset'
    }
  },
  '&.Mui-focused': {
    '.MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px !important'
    },
  }
}))

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: 285,
    '&.not-max-width': { maxWidth: 9999 }
  },

}))

export default function FilterSelect(props: BasicSelectProps) {
  const { t } = useTranslation()

  const [isOpen, setOpen] = useState<boolean>(false)

  return (
    <CustomFormControl className={`${props.formControlClass}`} size="small" margin="none" fullWidth={props.fullWidth !== undefined ? props.fullWidth : true} disabled={props.disabled} sx={{ ...props.formControlStyle }}>
      <StyledFilterSelect
        MenuProps={{
          classes: { paper: `style-filter-select-search custom-scroll ${props.classesOption} style-select-doctors` },
          PaperProps: {
            sx: {
              '.MuiMenuItem-root': {
                '&:hover': {
                  backgroundColor: `${colors.themeSecondColor06} !important`,
                  color: colors.themeSecondColor
                }
              },
            },
          },
        }}
        onOpen={() => props?.isClear && setOpen(true)}
        onClose={() => props?.isClear && setOpen(false)}
        style={{ ...props.style }}
        {...props.register}
        name={props.name}
        labelId={props.labelId}
        id={props.selectId}
        value={props.value}
        renderValue={props.renderValue}
        label={props.label}
        onChange={props.onchange}
        multiple={props.multiple || false}
        IconComponent={() => {
          return (
            <>
              {props?.isClear && !isOpen &&
                <ClearIcon
                  className='icon-clear-value cursor-pointer'
                  onClick={() => props?.onClear && props.onClear()} />
                || <FontAwesomeIcon icon={faCaretDown} />
              }

            </>
          )
        }}
        disabled={props.disabled}
      >
        {!_.isEmpty(props.options) ? (
          props.options
        ) : (
          <MenuItem
            value={''}
            className="pe-none text-center"
            sx={{
              '&.text-center': {
                justifyContent: 'center !important',
                paddingTop: '0 !important',
                paddingBottom: '0 !important'
              }
            }}>
            {t('REGISTER.FORM.NOT_FOUND')}
          </MenuItem>
        )}
      </StyledFilterSelect>
    </CustomFormControl>
  )
}
