import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { resetModal } from 'app/slice/modal.slice';
import ModalCustom from 'component/ModalCustom/ModalCustom';
import { Box, TableRow, TableCell, TableContainer, Table, TableHead, Paper, Divider, TableBody } from '@mui/material'
import _ from 'lodash';
import { dateToView } from 'utils/date.utils';
import TableCustom from 'component/Table/TableCustom';
import TableRowCommon from 'component/Table/TableRowCommon';
import { numberToCurrencyFormat } from 'utils/app.utils';
import { colors } from 'constants/theme';
import { Col, Row } from 'react-bootstrap';

export interface ICancelPaymentProps {
    isShow: boolean
    data: any
    onReset?: () => void
}
const initStateErrorMessage = {
    USER_NAME_STRING_EMPTY: '',
    PASSWORD_STRING_EMPTY: '',
    CANCEL_REMARK_STRING_EMPTY: '',
    NOT_FOUND_USER: '',
}
export default function HistoryPayment(props: ICancelPaymentProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const resetForm = async () => {
        if (props.onReset) props.onReset()
        dispatch(resetModal())
    }

    const renderHistory = (history: any, index: number) => {
        return (
            <Box>
                {index > 0 && <Divider
                    className="mx-0 my-2 d-none"
                    sx={{
                        backgroundColor: 'transparent',
                        opacity: 1,
                        borderColor: `${colors.lightGray} !important`,
                        '&:last-of-type': {
                            display: 'block !important'
                        }
                    }}
                />}
                <Row>
                    <Col>#{index + 1}</Col>
                </Row>
                <Row>
                    <Col xs={4} style={{ width: '40%' }}>{t('OVERDUE_DETAIL.NO_BILL')} : {`${history.prefix}${history.paymentNumber}`}</Col>
                    <Col xs={4} style={{ marginLeft: '-30px', width: '30%', paddingLeft: 25 }}>{t('OVERDUE_DETAIL.RECEIPT_DATE')} : {dateToView(history.date)}</Col>
                    <Col xs={4}>{t('OVERDUE_DETAIL.PAYEE')} : {history.createdBy}</Col>
                </Row>
                {/* <TableContainer component={Paper} style={{ boxShadow: 'none', minHeight: 'calc(100% - 65px)', overflow: 'hidden', marginBottom: 5 }}>
                    <Table size="small" sx={{ minWidth: 650 }}>
                        <TableHead  >
                            <TableRow>
                                <TableCell>#{index + 1}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody  >
                            <TableRow>
                                <TableCell width={150}>เลขใบเสร็จรับเงิน</TableCell>
                                <TableCell >{`${history.prefix}${history.paymentNumber}`}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width={150}>วันที่รับเงิน</TableCell>
                                <TableCell>{dateToView(history.date)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width={150}>ผู้รับเงิน</TableCell>
                                <TableCell>{history.createdBy}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer> */}
                {renderOrder(history?.orderPaymentChannels || [])}

            </Box>
        )
    }
    const headCell = [
        { id: 'no', disablePadding: false, label: '#' },
        { id: 'paymentChannelId', disablePadding: false, label: t('OVERDUE_DETAIL.TABLE.TYPE_MONEY_RECEICED') },
        { id: 'amountPaid', disablePadding: false, label: t('OVERDUE_DETAIL.TABLE.AMOUNT_PAID') },
    ]
    const renderOrder = (order: any) => {
        return <>
            <TableCustom
                page={1}
                pageLimit={100}
                sortType={'ASC'}
                sortBy={'createAt'}
                rowCount={100}
                onSort={console.log}
                setPageLimit={console.log}
                setPage={console.log}
                headCells={headCell}
                rowsData={
                    _.map(order || [], (data, index: number) => {
                        return [
                            renderPaymentChannel(data, index, 'HEADER'),
                            _.map(data?.orderPaymentItems || [], renderItem),
                            renderPaymentChannel(data, index, 'SUMMARY')
                        ]
                    })
                }
                hidePagination
            />
        </>
    }

    const renderPaymentChannel = (objData: any, index: number, type: "HEADER" | "SUMMARY") => {
        if (!objData) return <></>
        const { paymentChannelId, paymentChannelName, amountPaid } = objData

        let objRenderData: any = {}
        if (type === 'HEADER')
            objRenderData = {
                key: paymentChannelId,
                id: paymentChannelId,
                obj: objData,
                columns: [
                    { option: 'TEXT', align: 'center', label: (index + 1) },
                    { option: 'TEXT', align: 'left', label: paymentChannelName },
                    { option: 'TEXT', align: 'right', label: '' },
                ]
            }
        else
            objRenderData = {
                key: `sum-${paymentChannelId}`,
                id: `sum-${paymentChannelId}`,
                obj: objData,
                columns: [
                    { option: 'TEXT', align: 'center', label: '' },
                    { option: 'TEXT', align: 'left', label: '' },
                    { option: 'TEXT', align: 'right', label: numberToCurrencyFormat(amountPaid) },
                ]
            }
        return <TableRowCommon {...objRenderData} />
    }
    const renderItem = (objData: any) => {
        if (!objData) return <></>

        const { orderPaymentItemId, itemName, amountPaid } = objData
        // const rowCheck = _.some(isCheckDiagnosis, (i: number) => Number(i) === Number(patientDiagnosisId))
        if (!amountPaid) return false
        const objRenderData = {
            key: orderPaymentItemId,
            id: orderPaymentItemId,
            obj: objData,
            // rowSelect: rowCheck,
            columns: [
                { option: 'TEXT', align: 'center', label: '' },
                { option: 'TEXT', align: 'left', label: itemName },
                { option: 'TEXT', align: 'right', label: numberToCurrencyFormat(amountPaid) },
            ]
        }
        return <TableRowCommon {...objRenderData} />
    }

    return props.isShow ? (
        <ModalCustom
            size={'lg'}
            title={t('OVERDUE_DETAIL.TITLE_PAID_HISTORY')}
            alignFooter={'center'}
            component={
                <>

                    {_.map(props.data, renderHistory)}

                </>
            }
            onReset={() => (resetForm())}
            textBtnCancel={t('BUTTON.CLOSE')}
        />
    ) : <></>
}
