import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Box, Typography, TableRow, TableCell, Divider } from '@mui/material'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import { isBuddhistYear, numberFormat, toBuddhistYear } from "utils/app.utils"
import TableRowCommon from 'component/Table/TableRowCommon'
import TableRowExport from 'component/Pdf/TableRowExport'
import TableCustom from 'component/Table/TableCustom'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { colors } from 'constants/theme'

import { UseSnCode } from 'utils/useSnCode'
interface FcComponent {
    page: number
    pageLimit: number
    rowCount: number
    data: any
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
    onRequestSort: (sortBy: string, sortType: string) => void
}

export default function CommissionList(props: FcComponent) {

    const [snType, setSnType] = useState(false);

    const handleSnCode = useCallback(() => {
        const fetchData = async () => {
            const sn = await UseSnCode();
            setSnType(sn);
        };

        fetchData();

    }, [snType]);

    useEffect(() => {
        handleSnCode()
    }, [])

    const headCells = [
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.#NO', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.BILL_ID', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.DATETIME', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.USER_NAME', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.CN', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.SN', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.PATIENT_NAME', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.LIST', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.PRICE', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.QTY', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.TOTAL', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.PAID', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.COMMISSION', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.REMARK', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.COMMISSION.CREATEDBY', align: 'center', class: 'text-nowrap' },
    ]

    if (!snType) {
        headCells.splice(5, 1)
    }
    const renderData = (data: any, no: number) => {
        const num = props.page * props.pageLimit - props.pageLimit + no + 1
        const columns = [
            { option: 'TEXT', align: 'center', label: num, class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.paymentNumber, class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: moment(data.dataTime).add(isBuddhistYear(), 'y').format('DD/MM/YYYY HH:mm น.'), class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.userFullName, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.cnNumber, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: data.snCode ? data.snCode : "-", class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.patientFullName, class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.list, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: numberFormat(data.price) },
            { option: 'TEXT', align: 'center', label: data.qty, class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: numberFormat(data.total), class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: numberFormat(data.amountPaid), class: 'text-nowrap' },
            { option: 'TEXT', align: 'center', label: numberFormat(data.commission), class: 'text-nowrap' },
            { option: 'TEXT', align: 'left', label: data.remark, class: 'text-nowrap' },
            { option: 'UPDATE_BY', align: 'left', label: { updatedAt: data.createdAt, updatedBy: data.createdBy }, class: 'text-nowrap' }
        ]
        if (!snType) {
            columns.splice(5, 1)
        }
        return <>
            <TableRowCommon
                key={num.toString()}
                id={no.toString()}
                obj={data}
                columns={columns} />
        </>
    }

    return <>
        <Box>
            <UseStyled.ResponsiveReportTable>
                <TableCustom
                    tableMinWidth={992}
                    page={props.page}
                    pageLimit={props.pageLimit}
                    sortType={''}
                    sortBy={''}
                    rowCount={props.rowCount}
                    onSort={props.onRequestSort}
                    setPageLimit={props.handleChangeRowsPerPage}
                    setPage={props.handleChangePage}
                    headCells={headCells}
                    rowsData={_.map(props.data.rows, (d: any, index: number) => {
                        return renderData(d, index)
                    })}
                    customScroll
                />
            </UseStyled.ResponsiveReportTable>
        </Box>
    </>
}