export default function IconStar(props: { fill?: string, stroke?: string, strokeWidth?: string }) {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="https://www.w3.org/2000/svg">
      <path d="M6.9216 1.64562C7.1216 1.11813 7.87848 1.11813 8.0791 1.64562L9.37285 5.22937C9.41799 5.34612 9.49746 5.44643 9.60079 5.51706C9.70412 5.58769 9.82644 5.62533 9.9516 5.625H13.131C13.7185 5.625 13.9747 6.35625 13.5129 6.71438L11.2504 8.75C11.149 8.82791 11.0749 8.93592 11.0388 9.05854C11.0026 9.18115 11.0062 9.31207 11.0491 9.4325L11.8754 12.9344C12.0766 13.4969 11.4254 13.98 10.9329 13.6337L7.85973 11.6838C7.75449 11.6098 7.62899 11.5701 7.50035 11.5701C7.37172 11.5701 7.24622 11.6098 7.14098 11.6838L4.06785 13.6337C3.57598 13.98 2.9241 13.4963 3.12535 12.9344L3.9516 9.4325C3.99446 9.31207 3.99808 9.18115 3.96192 9.05854C3.92577 8.93592 3.8517 8.82791 3.75035 8.75L1.48785 6.71438C1.02535 6.35625 1.28285 5.625 1.8691 5.625H5.04848C5.17366 5.62542 5.29602 5.58781 5.39936 5.51717C5.50271 5.44653 5.58217 5.34617 5.62723 5.22937L6.92098 1.64562H6.9216Z" fill={props.fill || "#ffffff"} stroke={props.stroke || "#ffffff"} strokeWidth={props.strokeWidth || "1.2"} strokeLinecap="round" strokeLinejoin="round" />
      <defs>
        <linearGradient id="paint0_linear_647_81214" x1="6.88775" y1="1.25" x2="6.72525" y2="18.9128" gradientUnits="userSpaceOnUse">
          <stop stopColor={props.stroke || "#ffffff"} />
          <stop offset="1" stopColor={props.stroke || "#ffffff"} />
        </linearGradient>
      </defs>
    </svg>
  )
}
