import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart, TreatmentStateInterface } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/25.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethU, TextNumberU } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
  height: 'auto',
  marginTop: 'auto',
  top: '1.4%',
  left: '45.2%'
}))

export default function Teeth25(props: TeethProps) {
  const teeth = useSelector(toothChart)
  const teethNumber = props.teethNumber
  const historyToothChart = props.historyToothChart
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const isEditTootCharts: any = useSelector(isEditTootChart)

  const dispatch = useDispatch()

  const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
  const [positions, setPositions]: any = useState([])
  const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

  const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
  const [isTooth, setIsTooth]: any = useState(false)
  const [isXRay, setIsXRay]: any = useState(false)
  const [isToothRoot, setIsToothRoot]: any = useState(false)
  const [isOrthodontics, setIsOrthodontics]: any = useState(false)
  const [isExtract, setIsExtract]: any = useState(false)
  const [isFixture, setIsFixture]: any = useState(false)
  const [isBridge, setIsBridge]: any = useState(false)

  const [color, setColor]: any = useState(``)
  const [styleSvg, setStyleSvg]: any = useState({ width: 38.262, height: 93.001, viewBox: "0 0 38.262 93.001" })

  const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

  useEffect(() => {
    const operative = props.operative || ``
    const valColor = props.color || colors.themeSecondColor
    const selected = teeth[teethNumber]?.isSelect || false
    dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
    setIsSelected(selected)

    renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

    if (props.type === 'treatment') {
      renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
    }

    if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

    return setStyleSvg({ width: 27.852, height: 93.755, viewBox: "0 0 27.852 93.755" })
  }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

  useEffect(() => {
    const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
    const teethJson = treatmentTooth?.teethJson

    if (isEditTootCharts !== null) {
      if (teethJson) {
        const tooths = JSON.parse(teethJson)
        setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
        if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
      } else setIsEditTeeth(false)
    }
  }, [isEditTootCharts]);

  useEffect(() => {
    if (isSelected) {
      dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
      if (teeth[props.teethNumber]?.isSelect) {
        setIsSelected(true)
      } else {
        setIsSelected(false)
      }
    }
  }, [props.historyToothChart]);

  useEffect(() => {
    if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
  }, [isSelected, isEditTootCharts]);

  return (
    <Div>
      <DivTeethU className={`div-teeth-${props.teethNumber} `}>
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray />) || ''}
        {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge />) || ''}
        {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`sm teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
          <svg className={`sm ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
            <g data-name="Group 9161">

              {(isBridge && props.type === 'treatment') ?
                <g></g>
                : ""}
              <path
                data-name="Path 5268"
                d="M251.12 104.457c3.9-7.7 12.207-8.392 16.983-1.109a76.929 76.929 0 0 1 5.034 8.6c-4.653-21.192-4.615-42.854-5.549-64.419-.109-2.4-2.385-8.237-3.815-5.607-2.702 4.142-5.911 35.472-12.653 62.535z"
                transform="translate(-247.132 -41.259)"
                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
              />

              {(isLowerTeeth && props.type === 'treatment') ?
                <path
                  data-name="Path 6522"
                  d="M251.12 104.457c3.883-8.7 13.29-9.387 17.581-1.9a75.709 75.709 0 0 1 4.436 9.4c-4.653-21.192-4.615-42.854-5.549-64.419-.109-2.4-2.385-8.237-3.815-5.607-2.702 4.133-5.911 35.463-12.653 62.526z"
                  transform="translate(-247.132 -41.259)"
                  style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                /> : ''}

              <g data-name="Group 9726">

                {(isFixture && props.type === 'treatment') ? <>
                  <path data-name="Path 5269" d="M273.667 158.579c2.037-2.329 3.019-7.5 2.078-11.214a38.606 38.606 0 0 0-1.365-4.287c-.006 0-.006-.007-.005-.014a76.9 76.9 0 0 0-4.292-9c-4.146-7.659-12.482-7.671-17.016-.324-.135.215-.264.437-.393.665-2.214 4-4.188 11.111-4.421 19.926a5.562 5.562 0 0 0 1.031 3.384 21.546 21.546 0 0 0 11.675 8.137c3.768.909 10.641-5.013 12.708-7.273z" transform="translate(-248.253 -88.879)" style={{ fill: isTooth ? treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || color : '#efede7' }} />
                  <path data-name="Path 5270" d="M273.319 159.911c1.852-2.117 2.744-6.816 1.889-10.191a35.119 35.119 0 0 0-1.24-3.9c-.006 0-.006-.006-.005-.012a69.919 69.919 0 0 0-3.9-8.176c-3.767-6.959-11.343-6.97-15.462-.295-.122.2-.239.4-.356.6-2.012 3.632-3.805 10.1-4.017 18.107a5.057 5.057 0 0 0 .936 3.075 19.585 19.585 0 0 0 10.61 7.394c3.426.833 9.665-4.548 11.545-6.602z" transform="translate(-248.954 -90.337)" style={{ fill: isTooth ? treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || color : '#f7f7f7' }} />
                  <path data-name="Path 5271" d="M273 159.821c1.681-1.921 2.491-6.187 1.715-9.251a32.109 32.109 0 0 0-1.126-3.536c-.006 0-.005-.006 0-.011a63.436 63.436 0 0 0-3.54-7.422c-3.42-6.318-10.3-6.327-14.036-.267-.111.177-.217.36-.324.549-1.826 3.3-3.454 9.165-3.647 16.436a4.6 4.6 0 0 0 .85 2.791 17.779 17.779 0 0 0 9.631 6.711c3.103.749 8.77-4.136 10.477-6z" transform="translate(-249.597 -91.207)" style={{ fill: isTooth ? treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || color : colors.white }} />
                </> : <>
                  <path
                    data-name="Path 5269"
                    d="M273.667 158.579c2.037-2.329 3.019-7.5 2.078-11.214a38.606 38.606 0 0 0-1.365-4.287c-.006 0-.006-.007-.005-.014a76.9 76.9 0 0 0-4.292-9c-4.146-7.659-12.482-7.671-17.016-.324-.135.215-.264.437-.393.665-2.214 4-4.188 11.111-4.421 19.926a5.562 5.562 0 0 0 1.031 3.384 21.546 21.546 0 0 0 11.675 8.137c3.768.909 10.641-5.013 12.708-7.273z"
                    transform="translate(-248.253 -72.192)"
                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                  />
                  <path
                    data-name="Path 5270"
                    d="M273.319 159.911c1.852-2.117 2.744-6.816 1.889-10.191a35.119 35.119 0 0 0-1.24-3.9c-.006 0-.006-.006-.005-.012a69.919 69.919 0 0 0-3.9-8.176c-3.767-6.959-11.343-6.97-15.462-.295-.122.2-.239.4-.356.6-2.012 3.632-3.805 10.1-4.017 18.107a5.057 5.057 0 0 0 .936 3.075 19.585 19.585 0 0 0 10.61 7.394c3.426.833 9.665-4.548 11.545-6.602z"
                    transform="translate(-248.954 -73.65)"
                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                  />
                  <path
                    data-name="Path 5271"
                    d="M273 159.821c1.681-1.921 2.491-6.187 1.715-9.251a32.109 32.109 0 0 0-1.126-3.536c-.006 0-.005-.006 0-.011a63.436 63.436 0 0 0-3.54-7.422c-3.42-6.318-10.3-6.327-14.036-.267-.111.177-.217.36-.324.549-1.826 3.3-3.454 9.165-3.647 16.436a4.6 4.6 0 0 0 .85 2.791 17.779 17.779 0 0 0 9.631 6.711c3.103.749 8.77-4.136 10.477-6z"
                    transform="translate(-249.597 -74.52)"
                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                  />
                </>}

                {(isToothRoot && props.type === 'treatment') ?
                  <path
                    data-name="Path 6556"
                    d="M6.906 52.921c-.477-7.058-.077-31.112 1.206-38.1C9.484 7.339 9.727 1.68 9.469.907 9.13-.287 9.919 2.024 8.4 14.819c-1.94 16.238-3.377 37.77-3.369 37.834.235-.034 1.161.179 1.875.268z"
                    transform="translate(8.319 2.868)"
                    style={{

                      fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                      stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                    }}
                  />
                  : ''}

                {(isExtract && props.type === 'treatment') ?
                  <>
                    <g data-name="Group 9461">
                      <path data-name="Path 6581" d="m33.788-.5-27.542 80" transform="translate(-4.339 16.187)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                      <path data-name="Path 6582" d="m6.246-.5 27.542 80" transform="translate(-4.339 16.187)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                    </g>
                  </>
                  : ''}

                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                  <>
                    <defs>
                      <linearGradient id="s00bjkqssa" x1=".5" y1=".453" x2="-1.326" y2="4.622" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#b0b1b1" />
                        <stop offset={1} stopColor="#585959" />
                      </linearGradient>
                      <linearGradient id="wldu0b4etb" x1=".5" y1="1.834" x2=".5" y2=".046" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#c1c4c5" />
                        <stop offset={1} stopColor="#9e9e9e" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9348">
                      <path data-name="Path 5268" d="M251.12 104.457c3.9-7.7 12.207-8.392 16.983-1.109a76.929 76.929 0 0 1 5.034 8.6c-4.653-21.192-4.615-42.854-5.549-64.419-.109-2.4-2.385-8.237-3.815-5.607-2.702 4.142-5.911 35.472-12.653 62.535z" transform="translate(-246.546 -41.259)" style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }} />
                      <path data-name="Path 5269" d="M273.667 158.579c2.037-2.329 3.019-7.5 2.078-11.214a38.606 38.606 0 0 0-1.365-4.287c-.006 0-.006-.007-.005-.014a76.9 76.9 0 0 0-4.292-9c-4.146-7.659-12.482-7.671-17.016-.324-.135.215-.264.437-.393.665-2.214 4-4.188 11.111-4.421 19.926a5.562 5.562 0 0 0 1.031 3.384 21.546 21.546 0 0 0 11.675 8.137c3.768.909 10.641-5.013 12.708-7.273z" transform="translate(-247.667 -72.192)" style={{ fill: '#efede7' }} />
                      <path data-name="Path 5270" d="M273.319 159.911c1.852-2.117 2.744-6.816 1.889-10.191a35.119 35.119 0 0 0-1.24-3.9c-.006 0-.006-.006-.005-.012a69.919 69.919 0 0 0-3.9-8.176c-3.767-6.959-11.343-6.97-15.462-.295-.122.2-.239.4-.356.6-2.012 3.632-3.805 10.1-4.017 18.107a5.057 5.057 0 0 0 .936 3.075 19.585 19.585 0 0 0 10.61 7.394c3.426.833 9.665-4.548 11.545-6.602z" transform="translate(-248.368 -73.65)" style={{ fill: '#f7f7f7' }} />
                      <path data-name="Path 5271" d="M273 159.821c1.681-1.921 2.491-6.187 1.715-9.251a32.109 32.109 0 0 0-1.126-3.536c-.006 0-.005-.006 0-.011a63.436 63.436 0 0 0-3.54-7.422c-3.42-6.318-10.3-6.327-14.036-.267-.111.177-.217.36-.324.549-1.826 3.3-3.454 9.165-3.647 16.436a4.6 4.6 0 0 0 .85 2.791 17.779 17.779 0 0 0 9.631 6.711c3.103.749 8.77-4.136 10.477-6z" transform="translate(-249.011 -74.52)" style={{ fill: colors.white }} />
                      <g data-name="Group 9389">
                        <g data-name="Group 9380" transform="translate(-4.32 71.079)">
                          <g data-name="Rectangle 3927" transform="translate(13.517)" style={{ fill: '#c1c4c5', stroke: '#c1c4c5' }}>
                            <rect width="11.601" height="11.601" rx={2} style={{ stroke: 'none' }} />
                            <rect x=".5" y=".5" width="10.601" height="10.601" rx="1.5" style={{ fill: 'none' }} />
                          </g>
                          <path data-name="Path 6487" d="M11.579 17.547c-.2.362 1.676.183 3.083.165 2.094-.044 3.289.384 3.269-4.45a41.118 41.118 0 0 0-.159-5.141c-.531-.516.14 5.236-1.219 7.766-1.282 1.827-4.631 1.272-4.974 1.66z" transform="translate(6.863 -6.464)" style={{ fill: '#b0b1b1' }} />
                          <rect data-name="Rectangle 3931" width={5} height={2} rx=".5" transform="translate(16.806 6.215)" style={{ fill: '#cbcccc' }} />
                          <rect data-name="Rectangle 3930" width={5} height={2} rx=".5" transform="translate(16.605 2.015)" style={{ fill: '#9e9e9e' }} />
                          <rect data-name="Rectangle 3928" width={5} height={2} rx=".5" transform="translate(16.806 2.015)" style={{ fill: 'url(#s00bjkqssa)' }} />
                          <rect data-name="Rectangle 3929" width={5} height={2} rx=".5" transform="translate(16.806 6.015)" style={{ fill: 'url(#wldu0b4etb)' }} />
                        </g>
                        <path data-name="Path 6488" d="M4.9 10.312s10.47-4.978 27.6-.343" transform="translate(-4.235 67.699)" style={{ strokeLinecap: 'round', stroke: '#babbbb', fill: 'none' }} />
                        <path data-name="Path 6486" d="M5.087 10.146a46.9 46.9 0 0 1 27.554-.156" transform="translate(-4.421 68.38)" style={{ stroke: '#b5b7b7', strokeLinecap: 'round', fill: 'none' }} />
                      </g>
                    </g>
                  </> || ""}

                {(isFixture && props.type === 'treatment') ?
                  <>
                    <defs>
                      <linearGradient id="f67tva35ma" x1=".209" y1="-.059" x2="1.176" y2="1.286" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b4b5b5" />
                      </linearGradient>
                      <linearGradient id="ujwsspjnhb" x1="-.342" y1="-.081" x2="1.352" y2={1} gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#e5e5e5" />
                        <stop offset={1} stopColor="#b0b1b1" />
                      </linearGradient>
                    </defs>
                    <g data-name="Group 9489">
                      <g data-name="Group 9550">
                        <g data-name="Path 6490" transform="translate(3.378)" style={{ fill: 'url(#f67tva35ma)' }}>
                          <path d="M17.343 44.996H1.734c-.422 0-.825-.367-1.135-1.033-.322-.69-.499-1.61-.499-2.59C.845 24.493 1.818 12.58 2.991 5.97 3.682 2.075 4.427.1 5.203.1h8.671c.852 0 5.103 1.99 5.103 41.273 0 .98-.177 1.9-.499 2.59-.31.666-.713 1.033-1.135 1.033z" style={{ stroke: 'none' }} />
                          <path d="M17.343 44.896c.74 0 1.534-1.416 1.534-3.523 0-16.645-.753-28.547-2.238-35.377-.504-2.319-1.081-3.983-1.715-4.946-.486-.74-.867-.85-1.05-.85H5.203c-.406 0-1.22.752-2.114 5.788C1.917 12.593.945 24.498.2 41.378c.001 2.104.794 3.518 1.534 3.518h15.609m0 .2H1.734C.776 45.096 0 43.429 0 41.373 1.734 2.085 4.245 0 5.203 0h8.671c.958 0 5.203 2.085 5.203 41.373 0 2.056-.777 3.723-1.734 3.723z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <g data-name="Path 6489" transform="translate(1.094 42.63)" style={{ strokeLinejoin: 'round', fill: 'url(#ujwsspjnhb)' }}>
                          <path d="M23.198 7.569H1.16C.577 7.569.103 6.76.1 5.764.653 2.756.796 1.315.865.622.892.35.912.148.938.1h.007c.016 0 .037.002.062.004.04.004.09.01.153.01h22.038a4.489 4.489 0 0 0 .126-.002c.198 0 .21 0 .385 1.485.102.86.256 2.157.548 4.165-.002.997-.476 1.807-1.06 1.807z" style={{ stroke: 'none' }} />
                          <path d="M23.198 7.469c.517 0 .955-.777.96-1.7a142.268 142.268 0 0 1-.548-4.16C23.518.83 23.456.308 23.39.215a.404.404 0 0 0-.065-.003l-.126.001H1.16C1.1.213 1.052.21 1.013.206a7.67 7.67 0 0 0-.049.426C.895 1.326.752 2.768.2 5.772c.006.922.443 1.697.96 1.697h22.038m0 .2H1.16C.52 7.669 0 6.812 0 5.755 1.16-.555.52.013 1.16.013h22.038c.64 0 .287-.24 1.16 5.742 0 1.057-.52 1.914-1.16 1.914z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                        </g>
                        <path data-name="Path 6491" d="M5.625 21.2s18.057.9 18.962 1.957" transform="translate(-2.133 15.597)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6492" d="M5.649 21.2S23.1 22.161 24 23.213" transform="translate(-1.846 10.345)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6493" d="M7.08 21.281s14.6.592 15.5 1.645" transform="translate(-1.398 -11.973)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6584" d="M5.649 21.2S23.1 22.161 24 23.213" transform="translate(-1.59 5.802)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6585" d="M6.115 21.2S23.1 22.185 24 23.237" transform="translate(-1.59 -.222)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6586" d="M6.643 21.3s16.036.854 16.941 1.907" transform="translate(-1.59 -6.281)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                        <path data-name="Path 6587" d="M8.144 21.444s12.509.373 13.415 1.426" transform="translate(-1.398 -17.717)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                      </g>
                    </g>
                  </> : ""}
              </g>
            </g>
          </svg>}
      </DivTeethU>
      <PositionTeeth quarter={QUARTER_NAME_TYPE.Q2} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
      <TextNumberU>25</TextNumberU>
    </Div>
  )
}
