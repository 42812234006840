export default function Gallery() {
  return (
    <svg xmlns="https://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
      <path data-name="Rectangle 4329" style={{ fill: 'none' }} d="M0 0h80v80H0z" />
      <g data-name="Icon ionic-md-photos">
        <path
          data-name="Path 6710"
          d="M58.359 56.189V14.545a6.687 6.687 0 0 0-6.67-6.67H10.045a6.687 6.687 0 0 0-6.67 6.67v41.66a6.687 6.687 0 0 0 6.67 6.67h41.66a6.707 6.707 0 0 0 6.654-6.686zM20.448 37.866l7.076 10 10.419-15 13.746 19.996H10.045z"
          transform="translate(4.625 9.836)"
          style={{ fill: '#9e9e9e' }}
        />
        <path
          data-name="Path 6711"
          d="M56.2 3.375H14.547a6.688 6.688 0 0 0-6.672 6.672v.828H48.7a6.688 6.688 0 0 1 6.672 6.672v40.828h.828a6.688 6.688 0 0 0 6.675-6.675V10.047A6.688 6.688 0 0 0 56.2 3.375z"
          transform="translate(9.834 4.625)"
          style={{ fill: '#9e9e9e' }}
        />
      </g>
    </svg>
  )
}
