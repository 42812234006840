
import { AccordionDetails, Box } from '@mui/material'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import ManageWebApi from 'api/application/manageWeb'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Loading from 'component/Loading'
import { colors } from 'constants/theme'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import { useTranslation } from 'react-i18next'
import { getBoolean, getBranch } from 'utils/app.utils'
import * as UseStyles from 'features/application/manage-web/contact-us/style'
import _ from 'lodash'
import ButtonCustom from 'component/Button/ButtonCustom'
import BtnEdit from 'assets/button/BtnEdit'
import BtnDelFilled from 'assets/button/BtnDelFilled'
import { swalActive } from 'component/Alert/Swal'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import ContactUsForm from './ContactUsForm'
import { WebContactUsProps } from 'api/application/manageWeb-interface'
import BtnCopy from 'assets/button/BtnCopy'
import BtnWebsite from 'assets/button/BtnWebsite'
import BtnFacebook from 'assets/button/BtnFacebook'
import BtnLine from 'assets/button/BtnLine'
import BtnTiktok from 'assets/button/BtnTiktok'
import BtnIG from 'assets/button/BtnIG'
import InputTextField from 'component/Input/InputTextField'


export const CustomIconArrow = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    backgroundColor: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    '.icon-arrow': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}))

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: 'none',
    padding: '0 1rem',
    marginBottom: '1.5rem !important',
    '&:first-of-type': {
        marginTop: '1rem !important'
    },
    '&:before': {
        opacity: 0
    },
    '.header-text': {
        h5: {
            fontSize: 18,
            fontWeight: 500,
            '&.required::after': {
                color: colors.statusInactive
            }
        }
    },
    [theme.breakpoints.between('sm', 'lg')]: {
        padding: '0 1.5rem',
        '&:first-of-type': {
            marginTop: '1.5rem !important'
        }
    }
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ backgroundColor: colors.white, border: `0.5px solid ${colors.textLightGray}`, borderRadius: '50px', color: colors.themeMainColor }} />} {...props} />
))(({ theme }) => ({
    height: 40,
    backgroundColor: colors.themeSecondColor10,
    '&.error': {
        backgroundColor: colors.statusInactive10
    },
    '.MuiAccordionSummary-content': {
        margin: 0
    }
}))

type DayProps = 'Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat'
const ContactUs = () => {
    const { t } = useTranslation()
    const t_CONTACTUS = "MANAGE_WEB.CONTACT_US"

    const [load, setLoad] = useState<boolean>(true)

    const [branchContact, setBranchContact] = useState<any>([])
    // const [contact, setContact] = useState<any>({})
    const branchId = getBranch()
    // const [pageBranchId, setPageBranchId] = useState<number>(0)
    // const [isEdit, setIsEdit] = useState<boolean>(false)
    const [dataEdit, setDataEdit] = useState<any>({})
    // const [dataEditId, setDataEditId] = useState<any>({})
    const [statusAdd, setStatusAdd] = useState<boolean>(false)
    const [webContact, setWebContact] = useState<any>([]);
    const [officeTimes, setOfficeTimes] = useState<any>([])

    const Day = { Sun: t('MANAGE_WEB.DAY.SUN'), Mon: t('MANAGE_WEB.DAY.MON'), Tue: t('MANAGE_WEB.DAY.TUE'), Wed: t('MANAGE_WEB.DAY.WED'), Thu: t('MANAGE_WEB.DAY.THU'), Fri: t('MANAGE_WEB.DAY.FRI'), Sat: t('MANAGE_WEB.DAY.SAT') }

    const loadData = async () => {
        await ManageWebApi.getContactUs().then(async (res) => {
            setBranchContact(res.data)
            if (res.data?.length !== 0) {
                const arr: any[] = [];
                await Promise.all(res.data.map(async (item: any) => {
                    const res = await ManageWebApi.getWebContactUs(item.branchId);
                    arr.push(Object.assign(res.data, { isEdit: true, isCancel: false }));
                }));
                arr.sort((obj1, obj2) => obj1.branchId - obj2.branchId);
                setWebContact([...arr]);
            }
        }).catch(() => {
            return
        }).finally(() => {
            setLoad(false)
        })
    }

    useEffect(() => {
        loadData()
    }, [])


    const onEdit = (i: number, status: boolean, item?: any) => {
        if (item) {
            setDataEdit(item)
        }
        if (status) {
            setDataEdit({})
        }
        webContact[i].isEdit = status
    }

    const onPull = (i: number, status: boolean, item?: any) => {
        const newPayload: WebContactUsProps = {
            contactCustomerId: item.contactCustomerId ?? 0,
            branchId: item.branchId ?? '',
            name: item.branchName ?? '',
            email: item.branchEmail ?? '',
            phone: item.branchPhone ?? '',
            detail: item.branchFullAddress ?? '',
            addressMap: item.addressMap ?? '',
            website: '',
            tiktok: '',
            facebook: item.branchFacebook ?? '',
            line: item.branchLine ?? '',
            instagram: item.branchInstagram ?? '',
            officeTimes: item.officeTimes
        }

        setDataEdit(newPayload)

        console.log(dataEdit, 'data')
        webContact[i].isEdit = status
        webContact[i].Cancel = status
    }

    const onDel = async (id: number) => {
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 400px">${t(t_CONTACTUS + '.NOTI_DEL_START')}${t(t_CONTACTUS + '.TITLE')}${t(t_CONTACTUS + '.NOTI_DEL_END')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t(t_CONTACTUS + '.NOTI_DEL_START')}สาขา${id}${t(t_CONTACTUS + '.NOTI_DEL_END')}</p>`,
            async (res: any) => {
                if (res) {
                    const resDel = await ManageWebApi.delWebContactUs(id)
                    if (resDel === "pass") {
                        loadData()
                        notiSuccess(t(`${t_CONTACTUS}.DEL_SUC`))
                    } else {
                        notiError(t(`${t_CONTACTUS}.SAVE_ERROR`))
                        return
                    }
                }
            }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
        )
    }


    const onSave = async (i: number, branchId: number) => {
        setStatusAdd(true)
        const dateTimes = officeTimes.map((item: any) => {
            const { typetime, timeStart, timeEnd, days } = item;
            return Object.keys(days).map(day => {
                if (days[day]) {
                    return {
                        dayOfWeek: day.charAt(0).toUpperCase() + day.slice(1),
                        timeIn: typetime === 'OPEN' ? timeStart : '00:00',
                        timeOut: typetime === 'OPEN' ? timeEnd : '00:00',
                        officeStatus: typetime
                    };
                }
                return null;
            }).filter(Boolean);
        }).flat();

        const newPayload: WebContactUsProps = Object.assign(dataEdit, { branchId: branchId, officeTimes: [...dateTimes] })
        const id = dataEdit.contactCustomerId || 0
        console.log(dataEdit, 'dataEdit');
        if (id === 0) {
            const res = await ManageWebApi.createWebContactUs(newPayload)
            if (res === "pass") {
                onEdit(i, false, newPayload)
                loadData()
                notiSuccess(t(`${t_CONTACTUS}.SAVE_SUC`))
            } else {
                notiError(t(`${t_CONTACTUS}.SAVE_ERROR`))
            }
        } else {
            const res = await ManageWebApi.updateWebContactUs(newPayload.branchId, newPayload)
            if (res === "pass") {
                onEdit(i, false, newPayload)
                loadData()
                notiSuccess(t(`${t_CONTACTUS}.SAVE_SUC`))
            } else {
                notiError(t(`${t_CONTACTUS}.SAVE_ERROR`))
            }
        }
        setStatusAdd(false)
    }

    const renderTimeWork = useCallback((officeTimes: any) => {
        const renderTime = (time: string) => { return moment(time, 'HH:mm:ss').format('HH:mm') }
        const renderDay = (day: DayProps[]) => {
            if (day?.length === 7) {
                return t('MANAGE_WEB.CONTACT_US.OPEN')
            } else if (day?.length > 1) {
                return `${Day[day[0]]} - ${Day[day[day.length - 1]]}`
            } else {
                return `${Day[day[0]]}`
            }
        }

        const newTime = officeTimes?.map((item: any) => {
            return `${renderDay(item.day)} ${renderTime(item?.timeIn)}น. - ${renderTime(item?.timeOut)}น.`
        })
        return newTime || []
    }, [])

    // console.log(dataEdit, 'edit')

    return (
        <>
            <Loading show={load} />
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto' }}>
                {webContact.map((item: any, index: number) => {
                    return (
                        <Accordion key={item.branchId} defaultExpanded={true} className={` ${!getBoolean(true) ? 'd-none' : ''} w-100`}>
                            <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                                <div className={'header-text'}>
                                    <h5 className={'m-0'}>{`${t(`${t_CONTACTUS}.BRANCH`)}${item.branchId}`}</h5>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Box sx={{ fontSize: '20px', fontWeight: "600" }}>
                                            {t(`${t_CONTACTUS}.TITLE`)}
                                        </Box>
                                        {item.isEdit && item.name &&
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <ButtonCustom
                                                    startIcon={<BtnEdit />}
                                                    style={{ marginRight: '4px' }}
                                                    variant={'outlined'}
                                                    textButton={t(`${t_CONTACTUS}.BTN_EDIT`)}
                                                    onClick={() => { onEdit(index, !item.isEdit, item) }}
                                                />
                                                <ButtonCustom
                                                    startIcon={<BtnDelFilled />}
                                                    mode={'del'}
                                                    textButton={t(`${t_CONTACTUS}.BTN_DEL`)}
                                                    onClick={() => { onDel(item.branchId) }}
                                                />
                                            </Box>
                                            ||
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <ButtonCustom
                                                    startIcon={<BtnCopy />}
                                                    style={{ marginRight: '4px' }}
                                                    variant={'outlined'}
                                                    textButton={t(`${t_CONTACTUS}.BTN_PULL`)}
                                                    onClick={() => { onPull(index, true, branchContact[index]) }}
                                                />
                                                <ButtonCustom
                                                    style={{ marginRight: '4px' }}
                                                    variant={'outlined'}
                                                    textButton={t(`${t_CONTACTUS}.BTN_CANCEL`)}
                                                    onClick={() => { onEdit(index, !item.isEdit) }}
                                                />
                                                <ButtonCustom
                                                    textButton={t(`${t_CONTACTUS}.BTN_SAVE`)}
                                                    disabled={statusAdd}
                                                    onClick={() => { onSave(index, item?.branchId) }}
                                                />
                                            </Box>
                                        }
                                    </Box >
                                    {item.isEdit && item.name && !item.Cancel && <Box>
                                        <Box sx={{ marginTop: '32px', height: 'fit-content', overflow: 'auto', transition: "all 0.2s", }}>
                                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', minWidth: 'max-content' }}>
                                                <Box sx={{ marginRight: '8px', minWidth: 'fit-content', fontWeight: 'bold' }}>
                                                    {t(`${t_CONTACTUS}.TIME_WORK`)}:
                                                </Box>
                                                <Box>
                                                    {renderTimeWork(item?.officeTimes).map((item: any, index: number) => {
                                                        return (
                                                            <Box key={index} sx={{ marginRight: '8px', minWidth: 'fit-content' }}>
                                                                {item}
                                                            </Box>
                                                        )
                                                    })}
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', minWidth: 'max-content', marginTop: "8px" }}>
                                                <Box sx={{ marginRight: '8px', minWidth: 'fit-content', fontWeight: 'bold' }}>
                                                    {t(`${t_CONTACTUS}.EMAIL`)}:
                                                </Box>
                                                <Box>
                                                    {item?.email || ''}
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', minWidth: 'max-content', marginTop: "8px" }}>
                                                <Box sx={{ marginRight: '8px', minWidth: 'fit-content', fontWeight: 'bold' }}>
                                                    {t(`${t_CONTACTUS}.PHONE`)}:
                                                </Box>
                                                <Box>
                                                    {item?.phone || ''}
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', marginTop: "8px" }}>
                                                <Box sx={{ marginRight: '8px', minWidth: 'fit-content', fontWeight: 'bold' }}>
                                                    {t(`${t_CONTACTUS}.FULL_ADDRESS`)}:
                                                </Box>
                                                <Box>
                                                    {item?.detail || ''}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ paddingTop: '8px', width: '50%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <InputTextField
                                                disabled={true}
                                                value={`${item?.addressMap ?? ''}`}
                                            />
                                        </Box>
                                        <Box sx={{ marginTop: '32px', height: 'fit-content', overflow: 'auto', transition: "all 0.2s", }}>
                                            <Box
                                                sx={{
                                                    iframe: { width: '50%', height: '300px' }
                                                }}
                                                dangerouslySetInnerHTML={{
                                                    __html: `${item?.addressMap ?? ''}`
                                                }}
                                            />
                                        </Box>
                                        <UseStyles.ContainerSocial>
                                            <Box sx={{ width: '10%', paddingRight: '30px' }}><BtnWebsite color="#808080" /></Box>
                                            <Box sx={{ width: '90%' }}>
                                                {item?.website || ''}
                                            </Box>
                                        </UseStyles.ContainerSocial>
                                        <UseStyles.ContainerSocial>
                                            <Box sx={{ width: '10%', paddingRight: '30px' }}><BtnFacebook color="#808080" /></Box>
                                            <Box sx={{ width: '90%' }}>
                                                {item?.facebook || ''}
                                            </Box>
                                        </UseStyles.ContainerSocial>
                                        <UseStyles.ContainerSocial>
                                            <Box sx={{ width: '10%', paddingRight: '30px' }}><BtnLine color="#808080" /></Box>
                                            <Box sx={{ width: '90%' }}>
                                                {item?.line || ''}
                                            </Box>
                                        </UseStyles.ContainerSocial>
                                        <UseStyles.ContainerSocial>
                                            <Box sx={{ width: '10%', paddingRight: '30px' }}><BtnIG color="#808080" width='26' height='26' /></Box>
                                            <Box sx={{ width: '90%' }}>
                                                {item?.instagram || ''}
                                            </Box>
                                        </UseStyles.ContainerSocial>
                                        <UseStyles.ContainerSocial>
                                            <Box sx={{ width: '10%', paddingRight: '30px' }}><BtnTiktok color="#808080" /></Box>
                                            <Box sx={{ width: '90%' }}>
                                                {item?.tiktok || ''}
                                            </Box>
                                        </UseStyles.ContainerSocial>
                                    </Box> ||
                                        <ContactUsForm dataEdit={dataEdit} setDataEdit={setDataEdit} officeTimes={officeTimes} setOfficeTimes={setOfficeTimes} />}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </Box >
        </>
    )
}

export default ContactUs

