import IconProps from 'assets/pick/iconProps'
import { colors } from "constants/theme"

export default function RoomService(props: IconProps) {
  return (
    <svg xmlns="https://www.w3.org/2000/svg" width="85" height="70" viewBox="0 0 85 70">
      <g data-name="Group 11684">
        <path data-name="Rectangle 4341" style={{ fill: 'none' }} d="M0 0h85v70H0z" />
        <path d="M276.525 181.558a29.462 29.462 0 0 0 5.134-17.108c-.155-18.205-17.195-19.058-22.061-13.784-.828.9-2.3 1.263-3 .26-3.442-4.939-21.752-3.951-21.6 13.921a29.445 29.445 0 0 0 5.428 17.01 1.944 1.944 0 0 1 .3 1.6c-1.222 4.968-3.684 12.448 3.38 12.387 7.558-.065 6.442-8.456 14.414-8.524 7.4-.063 7.8 8.1 14.077 8.043 5.891-.05 4.732-7.391 3.619-12.257a1.949 1.949 0 0 1 .309-1.548z" transform="translate(-216.495 -136.35)" style={{ fill: '#fff', stroke: props.color ? props.color : colors.disabledGray, strokeMiterlimit: 10, strokeWidth: '3.3px' }} />
      </g>
    </svg>
  )
}
