import BaseAPI from 'api/api'

export interface FindAllXray {
    page?: number
    pageLimit?: number
    sortType?: number
    sortBy?: string
    search?: string
    dateStart?: string
    dateEnd?: string
    xrayFilmTypeId?: number
}

export interface UpdateXray {
    patientGalleryId: any
    patientId: number
    vnNumber: string
    imageTypeId: number
    xrayImages: []
}

export interface FindXrayPatient {
    search?: string
    xrayFilmTypeId?: number
}

export interface FindXrayPatient {
    search?: string
    xrayFilmTypeId?: number
}

const path = 'x-ray'

export default class xrayApi extends BaseAPI {
    static findAll(props: FindAllXray): Promise<any> {
        return this.api.get(`${path}`, { params: { ...props } })
    }

    static updateXray(payload: UpdateXray): Promise<any> {
        return this.api.post(`${path}`, payload)
    }

    static findXrayPatientGallery(patientGalleryId: string, props: FindXrayPatient): Promise<any> {
        return this.api.get(`${path}/${patientGalleryId}`, { params: { ...props } })
    }

    static deleteXrayPatient(patientGalleryId: string): Promise<any> {
        return this.api.delete(`${path}/${patientGalleryId}`)
    }

    static findXrayPatient(patientId: string, props: FindXrayPatient): Promise<any> {
        return this.api.get(`${path}/patient/${patientId}`, { params: { ...props } })
    }
}