import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface ChangePassword {
  password?: string
  confirmPassword?: string
}

export default class PersonnelApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get('users', { params: { ...props } }).then((res) => res)
  }

  static create(playload: any) {
    return this.api.post('users', playload)
  }

  static findById(id: number): Promise<any> {
    return this.api.get(`users/${id}`)
  }

  static findPermissions(body: any): Promise<any> {
    return this.api.get(`users/check-permission`, { params: { ...body } })
  }
  static findPermissionById(userId: number, body: any): Promise<any> {
    return this.api.get(`users/check-permission/${userId}`, { params: { ...body } })
  }

  static findProfile(): Promise<any> {
    return this.api.get(`users/profile`).then((res) => res)
  }

  static update(id: number, body: any) {
    return this.api.patch(`users/${id}`, body)
  }

  static resetPassword(body: any) {
    return this.api.patch(`reset-password`, body)
  }

  static changePassword(id: number, payload: ChangePassword) {
    return this.api.patch(`users/${id}/password`, payload)
  }
  static changeUsername(id: number, payload: string) {
    return this.api.patch(`users/${id}/username`, {username : payload})
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`users/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`users/${id}`)
  }

  static findUserActive(): Promise<any> {
    return this.api.get(`users/count-active`).then((res) => res)
  }

  static findSignature(): Promise<any> {
    return this.api.get(`users/signature`).then((res) => res)
  }
}
